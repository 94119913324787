import React, { useEffect } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Row, Col, Popconfirm, Modal, Card, Input } from "antd";
import { PlusOutlined, DeleteOutlined, GoldOutlined } from "@ant-design/icons";
import {
  ReTargetingTable,
  SegmentsFilterBar,
  SegmentsForm,
} from "../Segments/components";
import Button from "../../shared/globalStyling/Button";

import { determineUserAccess } from "../../../core/permissions/userLevelAccess";

const { TextArea } = Input;

const styles = {
  mapCard: {
    border: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    minHeight: "400px",
    marginRight: "8px",
    marginBottom: "8px",
  },
  switchLayout: {
    flex: 1,
    bottomBorder: 0,
    padding: 10,
  },
  switch: {
    backgroundColor: "#1890ff",
    fontWeight: "bold",
  },
  card: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

const SegmentsEventsTargetView = props => {
  let eventsTargets = props.eventsTargets;
  const getPermission = props.currentUser && props.currentUser.permission;

  const orgConfig = determineUserAccess(getPermission);
  const { isDemo } = orgConfig;

  if (isDemo) {
    eventsTargets.forEach((c, i) => {
      c.name = `Example Event Targets ${i}`;
    });
  }

  const { WRITE, changeValue } = props;

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    return props.submitForm();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    changeValue("activeKeyTab", "retargeting");
    changeValue("checkTabSegment", "retargeting");
    changeValue("checkSwitchMode", true);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Modal
        title={
          props.checkSwitchMode === true
            ? props.selectedRetargeting.length === 1
              ? "Update Segment"
              : "Create Event Target"
            : "Create Audience"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90%"}
        footer={null}
      >
        {props.selectedRetargeting.length === 1 &&
        props.afterEventsTargetSelected === false ? (
          <Row>
            <Col span={24}>
              <Card style={styles.card} title="Ad Tag Snippet">
                <TextArea rows={8} value={props.displayEventTags} />
              </Card>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col span={24}>
            <Card style={styles.card} title={"Create Event Target Segments"}>
              <SegmentsForm
                submit={handleOk}
                name={props.name}
                startDate={props.startDate}
                endDate={props.endDate}
                changeValue={props.changeValue}
                description={props.description}
                length={props.selectedRetargeting.length}
                checkSwitchMode={props.checkSwitchMode}
                checkTabSegment={props.checkTabSegment}
                buttonText={
                  props.checkSwitchMode === true
                    ? props.selectedRetargeting.length === 1
                      ? "Update Segment"
                      : "Create Event Target"
                    : "Create Audience"
                }
              />
            </Card>
          </Col>
        </Row>
      </Modal>
      <PageHeaderLayout
        title={"Segments - Event Targets"}
        titleIcon={
          <GoldOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <Row
          style={{
            marginTop: "8px",
          }}
        >
          <Col span={24}>
            <PageHeaderLayout
              rightContent={
                WRITE && (
                  <>
                    <Popconfirm
                      placement="topLeft"
                      title={`Are you sure you want to delete ${
                        props.selectedRetargeting.length
                      } selected segment${
                        props.selectedRetargeting.length > 1 ? "s" : ""
                      }?`}
                      onConfirm={props.deleteEventsTarget}
                      okText="Yes"
                      disabled={!props.selectedRetargeting.length}
                      cancelText="No"
                    >
                      <Button
                        shape="round"
                        icon={<DeleteOutlined />}
                        style={{ marginRight: 10 }}
                        disabled={!props.selectedRetargeting.length}
                      >
                        Remove
                      </Button>
                    </Popconfirm>
                    {props.selectedRetargeting.length === 1 ? (
                      <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        style={{ marginRight: 10, width: "auto" }}
                        onClick={showModal}
                      >
                        Update Event
                      </Button>
                    ) : (
                      <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        style={{ marginRight: 10, width: "auto" }}
                        onClick={showModal}
                      >
                        Create Event
                      </Button>
                    )}
                  </>
                )
              }
              title={"Segments"}
            />
            <SegmentsFilterBar history={props.history} match={props.match} />
            <ReTargetingTable
              afterEventsTargetSelected={props.afterEventsTargetSelected}
              changeValue={props.changeValue}
              selectedRowKeysEventsTargets={props.selectedRowKeysEventsTargets}
              {...props}
              eventsTargets={eventsTargets}
            />
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SegmentsEventsTargetView;
