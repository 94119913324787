import React from "react";
import { Modal } from "antd";
//local imports
import ConfirmLocationsTable from "./ConfirmLocationsTable";

const ModalTitle = ({ title, subtitle }) => {
  return (
    <div>
      <h3 style={{ margin: 0, fontWeight: "lighter" }}>{title}</h3>
      <h5 style={{ margin: 0, color: "#adb0b3", fontWeight: "lighter" }}>
        {subtitle}
      </h5>
    </div>
  );
};

const ConfirmLocationsModal = ({
  showModal,
  onChange,
  tableData,
  onConfirmLocationOk,
  selectedModalLocations,
  selectedModalLocationsChanged,
  onLocationTargetsModalOpenChange,
}) => {
  return (
    <Modal
      title={
        <ModalTitle
          title="Import Results"
          subtitle="Based on the source, here's what we found. Previously added locations have been removed to avoid duplication."
        />
      }
      width={800}
      closable={false}
      visible={showModal}
      onCancel={() => {
        // onChange("showModal", false);
        onLocationTargetsModalOpenChange(false);
      }}
      okButtonProps={{
        disabled: !(selectedModalLocations || []).length,
      }}
      onOk={onConfirmLocationOk}
    >
      <ConfirmLocationsTable
        selectedModalLocationsChanged={selectedModalLocationsChanged}
        data={tableData}
        onChange={onChange}
        selectedModalLocations={selectedModalLocations}
      />
    </Modal>
  );
};

export default ConfirmLocationsModal;
