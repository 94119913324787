import React from "react";
import { Row, Card, Progress, Col, Spin } from "antd";
import {
  KPITitleText,
  KpiFlightDate,
  KpiSubText,
} from "../../../../../../shared/globalStyling/styledText";
import moment from "moment";
import { ArrowRightOutlined } from "@ant-design/icons";
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

const DetailBar = ({
  startDate,
  endDate,
  signUps,
  customers,
  clicks,
  loading = false,
}) => {
  const styles = {
    container: {
      backgroundColor: "#fff",
      borderTop: "solid 1px #e8e8e8",
      marginBottom: "1%",
    },
    secondaryContainer: {
      padding: "3% 3% 1% 3%",
      margin: 0,
    },
    title: {
      marginBottom: "10px",
    },
    keyColors: {
      spent: "#1665d8",
      remaining: "#34aa44",
      budget: "#030303",
      total: "#bec0c3",
      value: "#99999",
    },
    datesContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    dates: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  };

  const getProgress = () => {
    const total = () => {
      var a = moment(startDate);
      var b = moment(endDate);
      const total = b.diff(a, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(startDate);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();

    if (progressDays <= 0) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;

    return Math.floor(result);
  };

  const currentposition = getProgress();

  return (
    <React.Fragment>
      <Row style={styles.container} wrap={false}>
        <Col xs={24}>
          <Row>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Dates"} />
                </div>
                <div style={styles.datesContainer}>
                  <div style={styles.dates}>
                    <KpiFlightDate
                      text={
                        startDate
                          ? moment(startDate).format("MM/DD/YYYY")
                          : moment().format("MM/DD/YYYY")
                      }
                    />
                  </div>
                  <div>
                    <ArrowRightOutlined style={{ color: "#636363" }} />
                  </div>
                  <div style={styles.dates}>
                    <KpiFlightDate
                      text={
                        endDate
                          ? moment(endDate).format("MM/DD/YYYY")
                          : moment().format("MM/DD/YYYY")
                      }
                    />
                  </div>
                </div>
                <Progress
                  showInfo={false}
                  percent={currentposition}
                  status={currentposition !== 100 ? "active" : "normal"}
                  strokeColor={"#636363"}
                />
              </Card>
            </Col>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Sign Ups"} />
                </div>
                <KpiSubText text={formatWholeNumber(signUps ? signUps : 0)} />
              </Card>
            </Col>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Page Views"} />
                </div>
                <KpiSubText text={formatWholeNumber(clicks ? clicks : 0)} />
              </Card>
            </Col>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Spin spinning={loading}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Unique Users"} />
                  </div>
                  <KpiSubText
                    text={formatWholeNumber(customers ? customers : 0)}
                  />
                </Card>
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailBar;
