import { isEmpty } from "lodash";

const aggregateDisplayReports = displayReports => {
  const byDateMap = {};
  // byDayMap = {},
  // deviceTypeMap = {},
  // creativesMap = {};
  displayReports.forEach(report => {
    if (!isEmpty(report) && !isEmpty(report.byDate)) {
      report.byDate.forEach(date => {
        if (byDateMap[date.xAxis]) {
          const previous = byDateMap[date.xAxis];
          byDateMap[date.xAxis] = {
            xAxis: previous.xAxis,
            cost: previous.cost + date.cost,
            clicks: previous.clicks + date.clicks,
            impressions: previous.impressions + date.impressions,
            totalCost: previous.totalCost + date.totalCost,
            ctr: previous.ctr + date.ctr,
            cpc: previous.cpc + date.cpc,
            cpm: previous.cpm + date.cpm,
            ecpm: previous.ecpm + date.ecpm,
          };
        } else {
          byDateMap[date.xAxis] = date;
        }
      });
    }
    // if (!isEmpty(report.byDay)) {
    //   report.byDay.forEach(day => {
    //     if (byDayMap[day.xAxis]) {
    //       const previous = byDayMap[day.xAxis];
    //       byDayMap[day.xAxis] = {
    //         xAxis: previous.xAxis,
    //         cost: previous.cost + day.cost,
    //         clicks: previous.cost + day.cost,
    //         impressions: previous.cost + day.cost,
    //         totalCost: previous.cost + day.cost,
    //         ctr: previous.cost + day.cost,
    //         cpc: previous.cost + day.cost,
    //         cpm: previous.cost + day.cost,
    //         ecpm: previous.cost + day.cost,
    //       };
    //     } else {
    //       byDayMap[day.xAxis] = day;
    //     }
    //   });
    // }
    // if (!isEmpty(report.deviceType)) {
    //   report.deviceType.forEach(device => {
    //     if (deviceTypeMap[device.xAxis]) {
    //       const previous = deviceTypeMap[device.xAxis];
    //       deviceTypeMap[device.xAxis] = {
    //         xAxis: previous.xAxis,
    //         cost: previous.cost + device.cost,
    //         clicks: previous.cost + device.cost,
    //         impressions: previous.cost + device.cost,
    //         totalCost: previous.cost + device.cost,
    //         ctr: previous.cost + device.cost,
    //         cpc: previous.cost + device.cost,
    //         cpm: previous.cost + device.cost,
    //         ecpm: previous.cost + device.cost,
    //       };
    //     } else {
    //       deviceTypeMap[device.xAxis] = device;
    //     }
    //   });
    // }
    // if (!isEmpty(report.creatives)) {
    //   report.creatives.forEach(creative => {
    //     if (creativesMap[creative.value]) {
    //       const previous = creativesMap[creative.value];
    //       creativesMap[creative.value] = {
    //         value: previous.value,
    //         cost: previous.cost + creative.cost,
    //         clicks: previous.clicks + creative.clicks,
    //         impressions: previous.impressions + creative.impressions,
    //         totalCost: previous.totalCost + creative.totalCost,
    //         events: {
    //           conversions:
    //             previous.events.conversions + creative.events.conversions,
    //         },
    //       };
    //     } else {
    //       creativesMap[creative.value] = creative;
    //     }
    //   });
    // }
  });
  return {
    byDate: Object.values(byDateMap),
    // byDay: Object.values(byDayMap),
    // deviceType: Object.values(deviceTypeMap),
    // creatives: Object.values(creativesMap),
    chartsBuilt: new Date(),
  };
};

export { aggregateDisplayReports };
