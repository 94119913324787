import gql from "graphql-tag";

const DELETE_IAB_CATEGORY = gql`
  mutation deleteIABCategoryGroup($Id: ID!) {
    deleteIABCategoryGroup(where: { id: $Id }) {
      id
    }
  }
`;

export default DELETE_IAB_CATEGORY;
