import React from "react";
import { isEmpty } from "lodash";
import { Col, Divider, Input, Modal, Row, Select, Button } from "antd";
import { featureOptions, permissionsOptions } from "./constants";

const CategoryModal = ({
  category,
  isModalOpen,
  handleOk,
  handleCancel,
  categoryName,
  categoryFeature,
  categoryPermission,
  handleInputChange,
  handleFeatureChange,
  handlePermissionChange,
  isEdit,
}) => {
  if (!category) {
    return <></>;
  }

  return (
    <Modal
      title={isEdit ? "Edit Category" : "Add Category"}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={
            isEmpty(categoryName) ||
            isEmpty(categoryFeature) ||
            isEmpty(categoryPermission)
          }
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Divider orientation="left">Category Name</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Input
            name="categoryName"
            placeholder="Category Name"
            onChange={handleInputChange}
            value={categoryName}
          />
        </Col>
      </Row>
      <Divider orientation="left">Features</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Select
            name="categoryFeatures"
            mode="tags"
            placeholder="Please select at least one feature"
            value={categoryFeature}
            onChange={handleFeatureChange}
            style={{ width: "100%" }}
            options={featureOptions}
          />
        </Col>
      </Row>
      <Divider orientation="left">Permissions</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Select
            name="categoryPermissions"
            mode="tags"
            placeholder="Please select at least one permission"
            value={categoryPermission}
            onChange={handlePermissionChange}
            style={{ width: "100%" }}
            options={permissionsOptions}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CategoryModal;
