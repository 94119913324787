/* 
  Poor - FF00000
  Mid - F7D41A
  Good - 1EBA24
  Poor - FF0000
  Base - CDCDCD
  */

export const graphConfiguration = {
  percent: 0,
  startAngle: Math.PI,
  endAngle: 2 * Math.PI,
  indicator: null,
  range: {
    color: ["#CDCDCD"],
  },
  animation: true,
  radius: 1,
  innerRadius: 0.95,
  statistic: {
    title: {
      offsetY: -36,
      style: {
        fontSize: "20px",
        color: "#4B535E",
      },
      formatter: () => 0,
    },
  },
};

export const matchOptions = [
  {
    label: "Match Characteristics",
    value: "=",
  },
  {
    label: "Do not match characteristics",
    value: "!=",
  },
  {
    label: "Greater than",
    value: ">",
  },
  {
    label: "Less than",
    value: "<",
  },
  {
    label: "Greater than or equal to",
    value: ">=",
  },
  {
    label: "Less than or equal to",
    value: "<=",
  },
];

export const dataTypeValues = {
  sci_owner_renter: {
    values: [
      { label: "H", value: "Homeowner" },
      { label: "O", value: "Probable Homeowner" },
      { label: "P", value: "Probable Renter" },
      { label: "R", value: "Renter" },
      { label: "U", value: "Unknown" },
    ],
  },
  sci_estimated_income_range: {
    values: [
      { label: "A", value: "Less than $20,000" },
      { label: "B", value: "$20,000-$29,999" },
      { label: "C", value: "$30,000-$39,999" },
      { label: "D", value: "$40,000-$49,999" },
      { label: "E", value: "$50,000-$74,999" },
      { label: "F", value: "$75,000-$99,999" },
      { label: "G", value: "$100,000-$124,999" },
      { label: "H", value: "$125,000-$149,999" },
      { label: "I", value: "$150,000-$174,999" },
      { label: "J", value: "$175,000-$199,999" },
      { label: "K", value: "$200,000-$249,999" },
      { label: "L", value: "$250,000+" },
    ],
  },
  sci_networth_range: {
    values: [
      { label: "A", value: "$0 Or Less" },
      { label: "B", value: "$1 - $4,999" },
      { label: "C", value: "$5,000 - $24,999" },
      { label: "D", value: "$25,000 - $49,999" },
      { label: "E", value: "$50,000 - $74,999" },
      { label: "F", value: "$75,000 - $99,999" },
      { label: "G", value: "$100,000 - $149,999" },
      { label: "H", value: "$150,000 - $249,999" },
      { label: "I", value: "$250,000 - $374,999" },
      { label: "J", value: "$375,000 - $499,999" },
      { label: "K", value: "$500,000 - $749,999" },
      { label: "L", value: "$750,000 - $999,999" },
      { label: "M", value: "$1,000,000 +" },
    ],
  },
  sci_home_value_range: {
    values: [
      { label: "A", value: "$1,000 - $24,999" },
      { label: "B", value: "$25,000 - $49,999" },
      { label: "C", value: "$50,000 - $74,999" },
      { label: "D", value: "$75,000 - $99,999" },
      { label: "E", value: "$100,000 - $124,999" },
      { label: "F", value: "$125,000 - $149,999" },
      { label: "G", value: "$150,000 - $174,999" },
      { label: "H", value: "$175,000 - $199,999" },
      { label: "I", value: "$200,000 - $224,999" },
      { label: "J", value: "$225,000 - $249,999" },
      { label: "K", value: "$250,000 - $274,999" },
      { label: "L", value: "$275,000 - $299,999" },
      { label: "M", value: "$300,000 - $349,999" },
      { label: "N", value: "$350,000 - $399,999" },
      { label: "O", value: "$400,000 - $449,999" },
      { label: "P", value: "$450,000 - $499,999" },
      { label: "Q", value: "$500,000 - $749,999" },
      { label: "R", value: "$750,000 - $999,999" },
      { label: "S", value: "$1,000,000 Plus" },
    ],
  },
  sci_occupation: {
    values: [
      { label: "1", value: "Government & Military/Civil Servant" },
      { label: "2", value: "Legal" },
      { label: "3", value: "Medical" },
      { label: "4", value: "Non-Employed/Under-Employed" },
      { label: "5", value: "Professional Blue Collar" },
      { label: "A", value: "Prof/Tech" },
      { label: "B", value: "Doctor" },
      { label: "C", value: "Lawyer/Judge" },
      { label: "D", value: "Teacher/Librarian" },
      { label: "E", value: "Admin/Mgmt" },
      { label: "F", value: "Management" },
      { label: "G", value: "Proprietor" },
      { label: "H", value: "Supervisor" },
      { label: "I", value: "Sales/Service" },
      { label: "J", value: "Clerical" },
      { label: "K", value: "Blue Collar" },
      { label: "L", value: "Forman" },
      { label: "M", value: "Operatives" },
      { label: "N", value: "Farm" },
      { label: "O", value: "Unskilled" },
      { label: "P", value: "Service Worker" },
      { label: "Q", value: "Military" },
      { label: "R", value: "Student" },
      { label: "S", value: "Retired" },
      { label: "T", value: "Homemaker" },
      { label: "U", value: "Unknown" },
      { label: "V", value: "Self-Employed" },
      { label: "X", value: "Agriculture/Environment" },
      { label: "Y", value: "Disabled" },
      { label: "Z", value: "Finance" },
    ],
  },
  sci_education: {
    values: [
      { label: "A", value: "Completed High School" },
      { label: "B", value: "Completed College" },
      { label: "C", value: "Completed Graduate School" },
      { label: "D", value: "Attended Vocational/Technical" },
      { label: "E", value: "Some College or Higher" },
    ],
  },
  sci_ethnicity: {
    values: [
      { label: "A", value: "Southeast Asian" },
      { label: "C", value: "Central & Southwest Asian" },
      { label: "E", value: "Eastern European" },
      { label: "F", value: "African American" },
      { label: "I", value: "Middle Eastern" },
      { label: "J", value: "Jewish" },
      { label: "M", value: "Mediterranean" },
      { label: "N", value: "Native American" },
      { label: "O", value: "Far Eastern" },
      { label: "P", value: "Polynesian" },
      { label: "S", value: "Scandinavian" },
      { label: "T", value: "Other" },
      { label: "W", value: "Western European" },
      { label: "Y", value: "Hispanic" },
      { label: "Z", value: "Uncoded (No Group)" },
    ],
  },
  sci_religion: {
    values: [
      { label: "B", value: "Buddhist" },
      { label: "C", value: "Catholic" },
      { label: "E", value: "Ethiopian Orthodox" },
      { label: "G", value: "Greek Orthodox" },
      { label: "H", value: "Hindu" },
      { label: "I", value: "Islamic" },
      { label: "J", value: "Jewish" },
      { label: "K", value: "Siku" },
      { label: "L", value: "Lutheran" },
      { label: "M", value: "Mormon" },
      { label: "O", value: "Eastern Orthodox" },
      { label: "P", value: "Protestant" },
      { label: "S", value: "Shinto" },
    ],
  },
  sci_language: {
    values: [
      { label: "A2", value: "Albanian" },
      { label: "A3", value: "Amharic" },
      { label: "A4", value: "Arabic" },
      { label: "A5", value: "Armenian" },
      { label: "A6", value: "Ashanti" },
      { label: "B4", value: "Bulgarian" },
      { label: "B5", value: "Burmese" },
      {
        label: "C1",
        value: "Chinese (Mandarin, Cantonese and other dialects)",
      },
      { label: "C3", value: "Czech" },
      { label: "D1", value: "Danish" },
      { label: "D2", value: "Dutch" },
      { label: "E1", value: "English" },
      { label: "E2", value: "Estonian" },
      { label: "F1", value: "Farsi" },
      { label: "F2", value: "Finnish" },
      { label: "F3", value: "French" },
      { label: "G2", value: "German" },
      { label: "G3", value: "Ga" },
      { label: "G4", value: "Greek" },
      { label: "H2", value: "Hebrew" },
      { label: "H3", value: "Hindi" },
      { label: "H4", value: "Hungarian" },
      { label: "I2", value: "Indonesian" },
      { label: "I3", value: "Italian" },
      { label: "J1", value: "Japanese" },
      { label: "K2", value: "Khmer" },
      { label: "K4", value: "Korean" },
      { label: "L1", value: "Laotian (Include Hmong)" },
      { label: "L2", value: "Latvian" },
      { label: "L3", value: "Lithuanian" },
      { label: "N2", value: "Norwegian" },
      { label: "O1", value: "Oromo" },
      { label: "P1", value: "Pashto" },
      { label: "P2", value: "Polish" },
      { label: "P3", value: "Portuguese" },
      { label: "R1", value: "Romanian" },
      { label: "R2", value: "Russian" },
      { label: "S2", value: "Serbo-Croatian" },
      { label: "S3", value: "Sinhalese" },
      { label: "S4", value: "Slovakian" },
      { label: "S5", value: "Slovenian" },
      { label: "S8", value: "Spanish" },
      { label: "S9", value: "Swahili" },
      { label: "SB", value: "Swedish" },
      { label: "T1", value: "Tagalog" },
      { label: "T3", value: "Thai" },
      { label: "T4", value: "Tibetan" },
      { label: "T6", value: "Turkish" },
      { label: "U1", value: "Urdu" },
      { label: "V1", value: "Vietnamese" },
    ],
  },
  sci_number_of_children_in_household: {
    values: [
      { label: "A", value: "No Children" },
      { label: "B", value: "At least 1, less than 3 children present" },
      { label: "C", value: "3 to 5 children present" },
      { label: "D", value: "6 or more children present" },
    ],
  },

  sci_connex_household_clusters: {
    values: [
      { label: "A01", value: "Single Start-Ups" },
      { label: "A02", value: "Hard-Working Mamas" },
      { label: "A03", value: "Road-Weary Renters" },
      { label: "A04", value: "Fun for the Whole Family" },
      { label: "A05", value: "Single in Suburbia" },
      { label: "A06", value: "Family Focused" },
      { label: "A07", value: "Humble Homebodies" },
      { label: "A08", value: "Road to Retirement" },
      { label: "A09", value: "Middle Class Mommies" },
      { label: "A10", value: "Middle-Aged Middles" },
      { label: "A11", value: "Sweet Life in Suburbia" },
      { label: "B01", value: "Small Town Singles" },
      { label: "B02", value: "Millennial Mommies" },
      { label: "B03", value: "Single and Ready to Mingle" },
      { label: "B04", value: "Tweeners" },
      { label: "B05", value: "Simply Retired" },
      { label: "B06", value: "Boomerangers" },
      { label: "B07", value: "Reaching for Retirement" },
      { label: "B08", value: "Hearth and Home" },
      { label: "B09", value: "Prime Time" },
      { label: "B10", value: "Family Ties" },
      { label: "B11", value: "Happy Homeowners" },
      { label: "B12", value: "Small Town Suburbia" },
      { label: "B13", value: "Free At Last" },
      { label: "C01", value: "Hittin' the Ground Running" },
      { label: "C02", value: "Mama's In Charge" },
      { label: "C03", value: "Aging Gracefully" },
      { label: "C04", value: "Sunny Suburbia" },
      { label: "C05", value: "Parents Without Partners" },
      { label: "C06", value: "Relaxation Maximization" },
      { label: "C07", value: "Slowin' Their Roll" },
      { label: "C08", value: "Climbing the Ladder" },
      { label: "C09", value: "Reaping the Reward" },
      { label: "C10", value: "Single Savvy Savers" },
      { label: "C11", value: "Carpool Lane" },
      { label: "C12", value: "Pinnacle of Success" },
      { label: "C13", value: "Single Sophisticates" },
      { label: "C14", value: "Sittin' Pretty in the Suburbs" },
      { label: "C15", value: "Suburban Splendor" },
      { label: "D01", value: "Fast Trackers" },
      { label: "D02", value: "Momagers" },
      { label: "D03", value: "Success in the City" },
      { label: "D04", value: "Renaissance Retirees" },
      { label: "D05", value: "Affluent Aficionados" },
      { label: "E01", value: "Young and Hungry" },
      { label: "E02", value: "Making It Matriarchs" },
      { label: "E03", value: "Retiring Retirees" },
      { label: "E04", value: "Stuck in the Middle" },
      { label: "E05", value: "Home Sweet Home" },
      { label: "E06", value: "Retirement Ready" },
      { label: "E07", value: "Frontline Families" },
      { label: "E08", value: "Settled Singles" },
      { label: "F01", value: "Low Income Laborers" },
      { label: "F02", value: "She Works Hard for the Money" },
      { label: "F03", value: "Fixed Income" },
      { label: "F04", value: "Flying Solo" },
      { label: "F05", value: "Gettin' By" },
      { label: "F06", value: "La Familia" },
      { label: "F07", value: "Daily Grind" },
      { label: "F08", value: "Mamas and Papas" },
      { label: "F09", value: "American Dream" },
      { label: "G01", value: "Single Simplicity" },
      { label: "G02", value: "Modest Means Mommies" },
      { label: "G03", value: "Suburban Sunsetters" },
      { label: "G04", value: "Mid-Life Matriarchs" },
      { label: "G05", value: "Handy Homeowners" },
      { label: "G06", value: "Family-Friendly" },
      { label: "G07", value: "Home Alone" },
      { label: "G08", value: "Rest and Relaxation" },
      { label: "G09", value: "Sublime Suburbia" },
      { label: "G10", value: "Parenthood the Sequel" },
      { label: "G11", value: "Family First" },
      { label: "G12", value: "Suburban Satisfaction" },
      { label: "H01", value: "Overalls and Workboots" },
      { label: "H02", value: "Making Ends Meet" },
      { label: "H03", value: "Rural Roots" },
      { label: "H04", value: "Small Town Struggles" },
      { label: "H05", value: "Rustic Reality" },
      { label: "H06", value: "Small Town Sensibility" },
      { label: "H07", value: "Carefree in the Country" },
      { label: "H08", value: "Kids Country" },
      { label: "H09", value: "Rural Retirees" },
      { label: "H10", value: "Small Town USA" },
      { label: "J01", value: "Rural Rigors" },
      { label: "J02", value: "Heartland Hardships" },
      { label: "J03", value: "Twilight Time" },
      { label: "J04", value: "Farmhouse Families" },
      { label: "J05", value: "Heartland Homes" },
      { label: "J06", value: "Peace and Plenty" },
      { label: "K01", value: "Single Serenity" },
      { label: "K02", value: "Single Moms Club" },
      { label: "K03", value: "Rustic Retired Renters" },
      { label: "K04", value: "Cozy in the Country" },
      { label: "K05", value: "Small Town Seniors" },
      { label: "K06", value: "Aging Xers" },
      { label: "K07", value: "Country Comfort" },
      { label: "K08", value: "Relaxed to the Max" },
      { label: "L01", value: "Senior Struggles in the City" },
      { label: "L02", value: "Unassuming Urbanites" },
      { label: "L03", value: "Paycheck-to-Paycheck" },
      { label: "L04", value: "City Standards" },
      { label: "M01", value: "Singular Focus" },
      { label: "M02", value: "Raring for Retirement" },
      { label: "M03", value: "Fortuitous Families" },
      { label: "M04", value: "Life in the Fam Lane" },
      { label: "N01", value: "Suburban Challenges" },
      { label: "N02", value: "Foundering Families" },
      { label: "N03", value: "Surviving in Suburbia" },
      { label: "N04", value: "Cash-Strapped Seniors" },
      { label: "N05", value: "Middle-Aged Matrons" },
      { label: "N06", value: "True Blue" },
      { label: "N07", value: "Retired Repose" },
      { label: "N08", value: "Middle-Aged Mavericks" },
      { label: "N09", value: "Punch Card Parents" },
      { label: "N10", value: "Working Class Warriors" },
      { label: "P01", value: "Rooms for Improvement" },
      { label: "P02", value: "Workin' for a Livin'" },
      { label: "P03", value: "Final Frontier" },
      { label: "P04", value: "Kiddies in the City" },
      { label: "P05", value: "Everyday Earners" },
      { label: "Q01", value: "Suburban Surplus" },
      { label: "Q02", value: "Regal Retirees" },
      { label: "Q03", value: "Posh Professionals" },
      { label: "Q04", value: "Family Fortune" },
      { label: "Q05", value: "Singular Sensations" },
      { label: "R01", value: "City Survivors" },
      { label: "R02", value: "Urban Underdogs" },
      { label: "R03", value: "Underprivileged Urbanites" },
      { label: "R04", value: "One Day at a Time" },
      { label: "R05", value: "Livin' Large" },
    ],
  },
  sci_connex_family_clusters: {
    values: [
      { value: "A", label: "Only Just Begun" },
      { value: "B", label: "Small Town Satisfaction" },
      { value: "C", label: "Success in the Suburbs" },
      { value: "D", label: "Road to Success" },
      { value: "E", label: "Average Joes" },
      { value: "F", label: "Laborer Pains" },
      { value: "G", label: "Keeping It Simple" },
      { value: "H", label: "Modest Homemakers" },
      { value: "J", label: "Farmland Values" },
      { value: "K", label: "Comfy Country Livin'" },
      { value: "L", label: "Big City Burdens" },
      { value: "M", label: "Fast-Lane Families" },
      { value: "N", label: "Blue Collar Blues" },
      { value: "P", label: "Low-Rent Singles" },
      { value: "Q", label: "Lux and Leisure" },
      { value: "R", label: "Urban Struggles" },
    ],
  },
  sci_connex_neighborhood_clusters: {
    values: [
      { value: 1, label: "No Place Like Home" },
      { value: 10, label: "Middle-Aged Moderates" },
      { value: 11, label: "Blue Bayou" },
      { value: 12, label: "Suburban Setbacks" },
      { value: 13, label: "Southern Sensibility" },
      { value: 14, label: "Coupon Clippers" },
      { value: 15, label: "Clock Watchers" },
      { value: 16, label: "Community Cogwheels" },
      { value: 17, label: "Carpools and Little League" },
      { value: 18, label: "Single-Minded" },
      { value: 19, label: "Career Couples" },
      { value: 2, label: "Full House" },
      { value: 20, label: "Made in the USA" },
      { value: 21, label: "Nine-to-Fivers" },
      { value: 22, label: "Southern Comfort" },
      { value: 23, label: "Salt of the Earth" },
      { value: 24, label: "Country Kin" },
      { value: 25, label: "Almost Free" },
      { value: 26, label: "Median Middles" },
      { value: 27, label: "Growing Pains" },
      { value: 28, label: "Serve and Protect" },
      { value: 29, label: "Gracious Homes" },
      { value: 3, label: "Blissful in the Burbs" },
      { value: 30, label: "Working Class Woes" },
      { value: 31, label: "Farmland Frugals" },
      { value: 32, label: "Urban Challenges" },
      { value: 33, label: "Golden Age" },
      { value: 34, label: "Small Town Travails" },
      { value: 35, label: "Struggling Suburban Singles" },
      { value: 36, label: "Dressed in Success" },
      { value: 37, label: "At the Starting Gate" },
      { value: 38, label: "Under Paid Urbanites" },
      { value: 39, label: "Mid-Life Metros" },
      { value: 4, label: "Up and Comers" },
      { value: 40, label: "High Rise Hipsters" },
      { value: 41, label: "Lap of Luxury" },
      { value: 42, label: "Backwoods Blues" },
      { value: 43, label: "City Limited" },
      { value: 44, label: "Down and Out Downtown" },
      { value: 45, label: "Solo Suburbanites" },
      { value: 46, label: "Between Sunset and Dusk" },
      { value: 47, label: "Small Town Toilers" },
      { value: 48, label: "Suburban Security" },
      { value: 49, label: "Provincial Paupers" },
      { value: 5, label: "Modest Middle-Agers" },
      { value: 50, label: "Plugged-In Players" },
      { value: 51, label: "IntenseCities" },
      { value: 52, label: "Country Money Crunch" },
      { value: 53, label: "Downtown Digs" },
      { value: 54, label: "Textbook Townies" },
      { value: 55, label: "Country Classics" },
      { value: 56, label: "Middle-Aged Misfortune" },
      { value: 57, label: "Small Town Blues" },
      { value: 58, label: "Labored Force" },
      { value: 59, label: "Old Guard" },
      { value: 6, label: "Suburban Serenity" },
      { value: 60, label: "Hard Row to Hoe" },
      { value: 61, label: "Family Portrait" },
      { value: 62, label: "Urban Utopia" },
      { value: 63, label: "Simply Suburbia" },
      { value: 64, label: "Dinner's at Five" },
      { value: 65, label: "Day by Day" },
      { value: 66, label: "Utterly Urban" },
      { value: 67, label: "Teenage Transitions" },
      { value: 68, label: "Careers in the City" },
      { value: 7, label: "Main Street Middles" },
      { value: 8, label: "Melting Pot" },
      { value: 9, label: "Middle Class Mainstream" },
    ],
  },
  sci_connex_messaging_clusters: {
    values: [
      { value: "1", label: "Young and Free" },
      { value: "2", label: "Mature Empty Nesters" },
      { value: "3", label: "Me and My Kids" },
      { value: "4", label: "Up and Coming Families" },
      { value: "5", label: "Affluent Emerging Retirees" },
    ],
  },
  sci_connex_digital_clusters: {
    values: [
      { value: "1", label: "Ape for Apps" },
      { value: "10", label: "Swipers Who Diaper" },
      { value: "11", label: "Real-Time Rigors" },
      { value: "12", label: "Facebook Families" },
      { value: "13", label: "Social Chimers" },
      { value: "14", label: "Parental Controls" },
      { value: "15", label: "American Streamers" },
      { value: "16", label: "Passive About Pixels" },
      { value: "17", label: "Shopping Cart Savvy" },
      { value: "18", label: "Suburban Surfers" },
      { value: "19", label: "Offline Realities" },
      { value: "2", label: "Downtown Digitals" },
      { value: "20", label: "E-Comm Couples" },
      { value: "21", label: "Off The Grid" },
      { value: "22", label: "Plugged-In Parents" },
      { value: "23", label: "Bundled in the Burbs" },
      { value: "24", label: "Digitally Remastered" },
      { value: "25", label: "Small Town Satellites" },
      { value: "26", label: "High Speed Homes" },
      { value: "27", label: "Digital Dinosaurs" },
      { value: "28", label: "Cell Conscious" },
      { value: "29", label: "Social Lites" },
      { value: "3", label: "Game On!" },
      { value: "30", label: "Unplugged" },
      { value: "31", label: "Retirement Reboot" },
      { value: "32", label: "Dreaming of Analog" },
      { value: "33", label: "Timed Out" },
      { value: "34", label: "Digital Detours" },
      { value: "35", label: "Digital Distractions" },
      { value: "4", label: "Me, Myselfie and I" },
      { value: "5", label: "Upwardly Mobiles" },
      { value: "6", label: "Buffering in the Backwoods" },
      { value: "7", label: "Websession" },
      { value: "8", label: "Mobile Mamas" },
      { value: "9", label: "Social Butterflies" },
    ],
  },
  sci_connex_generation_clusters: {
    values: [
      { value: "B1", label: "Junior Boomers" },
      { value: "B2", label: "Boomers Without Babies" },
      { value: "B3", label: "Fringe Boomers" },
      { value: "B4", label: "Blue Collar Boomers" },
      { value: "B5", label: "Transitional Boomers" },
      { value: "B6", label: "Red, White and Boom" },
      { value: "B7", label: "Bucolic Boomers" },
      { value: "M1", label: "Modern Millennials" },
      { value: "M2", label: "Mature Millennials" },
      { value: "S1", label: "Sole Silents" },
      { value: "S2", label: "Silent Sets" },
      { value: "S3", label: "Sunsetting Silents" },
      { value: "X1", label: "X in the City" },
      { value: "X2", label: "maXed Mommies" },
      { value: "X3", label: "X-Y-Z Families" },
      { value: "X4", label: "Gen X Execs" },
      { value: "X5", label: "Great Xpectations" },
      { value: "X6", label: "X's and O's" },
    ],
  },
  sci_connex_generation_groups: {
    values: [
      { value: "B", label: "Baby Boomer" },
      { value: "M", label: "Millennial" },
      { value: "S", label: "Silent Generation" },
      { value: "X", label: "Generation X" },
    ],
  },
  sci_connex_stage_clusters: {
    values: [
      { value: "B", label: "Baby Boomer" },
      { value: "M", label: "Millennial" },
      { value: "S", label: "Silent Generation" },
      { value: "X", label: "Generation X" },
      { value: "MO1", label: "Approaching the Finish Line" },
      { value: "MO2", label: "Middle-Aged Blues" },
      { value: "MO3", label: "Second Honeymooners" },
      { value: "MO4", label: "Tweens and Teens" },
      { value: "MY1", label: "Me to We" },
      { value: "MY2", label: "Mama Mia" },
      { value: "MY3", label: "School Daze" },
      { value: "SR1", label: "Me Time" },
      { value: "SR2", label: "Swingin' Silvers" },
      { value: "SR3", label: "College Crunch Time" },
      { value: "YG1", label: "Newly Minted" },
    ],
  },
  sci_connex_stage_groups: {
    values: [
      { value: "MO", label: "Older Middle-Age" },
      { value: "MY", label: "Younger Middle-Age" },
      { value: "SR", label: "Mature" },
      { value: "YG", label: "Young" },
    ],
  },
};
