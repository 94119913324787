import { Button, Modal } from "antd";
import React, { useState } from "react";
import { TagsManagerMeasurements } from "../../../../../core/components/tags/manager/TagsManagerWrapper";

const CreateMeasurementTags = () => {
  const [visible, setVisible] = useState(false);
  return (
    <React.Fragment>
      <Modal
        visible={visible}
        width={"70%"}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        title={"Create Measurement Tags"}
      >
        <TagsManagerMeasurements headerSubmit={false} />
      </Modal>
      <Button
        shape="round"
        type="primary"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        Create Measurement Tags
      </Button>
    </React.Fragment>
  );
};

export default CreateMeasurementTags;
