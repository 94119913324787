import gql from "graphql-tag";

const CREATE_NOTIFICATION = gql`
  mutation createNotification($userID: ID!, $message: String!) {
    createNotification(
      data: { user: { connect: { id: $userID } }, message: $message }
    ) {
      id
      message
      user {
        name
      }
    }
  }
`;

export default CREATE_NOTIFICATION;
