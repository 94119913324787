import gql from "graphql-tag";
import { CampaignRequestorFields } from "../Fragments/CampaignRequestorFragments";

const GET_CAMPAIGN_ORDERS_REQUESTOR = gql`
  ${CampaignRequestorFields}

  query campaignRequestorCampaignOrdersConnection(
    $where: CampaignOrderWhereInput!
    $first: Int
    $skip: Int
    $orderBy: CampaignOrderOrderByInput
  ) {
    campaignOrdersConnection: campaignOrdersConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...CampaignRequestorFields
        }
      }
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAIGN_ORDERS_REQUESTOR;
