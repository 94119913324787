import { useQuery } from "@apollo/react-hooks";
import { useState, useEffect } from "react";
import REPROCESS_CAMPAIGN_STATUS from "../../../../GraphQl/Queries/REPROCESS_CAMPAIGN_STATUS";
//import REPROCESS_STATUS_SUBSCRIPTION from "../../../../GraphQl/Subscriptions/REPROCESS_STATUS_SUBSCRIPTION";

const ReprocessHandler = id => {
  const [reprocessStatus, setReprocessStatus] = useState();
  const reprocessKey = `display-campaign-${id}`;
  const { data, refetch } = useQuery(REPROCESS_CAMPAIGN_STATUS, {
    variables: {
      key: reprocessKey,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      if (data.reprocessStatus) {
        setReprocessStatus(data.reprocessStatus.status);
      }
    }
  }, [data]);

  // subscribeToMore({
  //   document: REPROCESS_STATUS_SUBSCRIPTION,
  //   variables: {
  //     where: {
  //       node: {
  //         key: reprocessKey,
  //       },
  //     },
  //   },
  //   updateQuery: (prev, data) => {
  //     if (data.subscriptionData.data.reprocessStatus.node) {
  //       const { status } = data.subscriptionData.data.reprocessStatus.node;
  //       setReprocessStatus(status);
  //     }
  //   },
  // });

  return {
    reprocessStatus,
    refetchStatus: refetch,
  };
};

export default ReprocessHandler;
