import React from "react";
import { Row, Col, Popconfirm } from "antd";
//Global Styles
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { EditModal } from "./EditModal";
import {
  DeleteOutlined,
  EditOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

const Frag = React.Fragment;

export const ActionButtons = props => {
  const title = () => {
    return `Are you sure you want to delete ${
      props.selectedForBatch.length
    } selected target${props.selectedForBatch.length > 1 ? "s" : ""}?`;
  };

  return (
    <Frag>
      {false && (
        <Col xxl={12} xl={17} lg={19} md={24}>
          <Row type="flex" justify="space-between">
            <Button shape="round" icon={<PlayCircleOutlined />}>
              <ButtonText text={"Resume"} />
            </Button>
            <Button shape="round" icon={<PauseCircleOutlined />}>
              <ButtonText text={"Pause"} />
            </Button>
            <Button shape="round" icon={<DeleteOutlined />}>
              <ButtonText text={"Remove"} />
            </Button>
          </Row>
        </Col>
      )}
      <Row span={12} style={{ float: "right" }}>
        <Popconfirm
          placement="topLeft"
          title={title()}
          onConfirm={props.batchDeleteClicked}
          okText="Yes"
          disabled={!props.selectedForBatch.length}
          cancelText="No"
        >
          <Button
            shape="round"
            type="primary"
            ghost
            icon={<DeleteOutlined />}
            style={{ marginRight: 10 }}
            disabled={!props.selectedForBatch.length}
          >
            <ButtonText text={"Delete"} />
          </Button>
        </Popconfirm>
        {props.isTrafficker && (
          <Button
            shape="round"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              props.toggleEditModal(true);
            }}
            disabled={!props.selectedForBatch.length}
          >
            Edit
          </Button>
        )}
      </Row>
      {props.editModalVisible && (
        <EditModal
          editModalVisible={props.editModalVisible}
          optionsToUpdateChanged={props.optionsToUpdateChanged}
          toggleEditModal={props.toggleEditModal}
          onOk={props.updateGeoTargetsConfirmed}
          selected={props.selectedForBatch}
          targetType={props.targetType}
        />
      )}
    </Frag>
  );
};
