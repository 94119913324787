import gql from "graphql-tag";
const CREATE_IAB_CATEGORY_GROUP = gql`
  mutation createIABCategoryGroup(
    $name: String!
    $iabCategories: [String!]
    $orgID: ID!
  ) {
    createIABCategoryGroup(
      data: {
        name: $name
        iabCategories: { set: $iabCategories }
        orgs: { connect: [{ id: $orgID }] }
      }
    ) {
      name
      iabCategories
    }
  }
`;

export default CREATE_IAB_CATEGORY_GROUP;
