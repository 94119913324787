import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useSearchParams = (defaultParams = {}) => {
  const location = useLocation();
  const history = useHistory();

  // Initialize the state with the current URL's search parameters or default values
  const urlSearchParams = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );
  const initialSearchParams = { ...defaultParams, ...urlSearchParams };

  const [searchParams, setSearchParamsState] = useState(initialSearchParams);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParamsState(prev => ({
      ...prev,
      ...Object.fromEntries(params.entries()),
    }));
  }, [location.search]);

  const setSearchParams = newParams => {
    const updatedParams = new URLSearchParams(location.search);

    for (const [key, value] of Object.entries(newParams)) {
      updatedParams.set(key, value);
    }

    history.push({
      pathname: location.pathname,
      search: updatedParams.toString(),
    });
  };

  return [searchParams, setSearchParams];
};

export default useSearchParams;
