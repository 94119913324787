import React, { useState } from "react";
import { Card, Row } from "antd";
import moment from "moment";
// util functions
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";
import PaginationTable from "../../../../../../shared/PaginationTable";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
  CardTitleText,
} from "../../../../../../shared/globalStyling/styledText";

const pageSize = 10;
const Frag = React.Fragment;

const WalkinByLocation = ({ data, attributionUpdatedOn }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const footerData = data.slice(
    currentPage * pageSize - pageSize,
    currentPage * pageSize
  );

  const cols = [
    {
      title: <TableColumnTitle text={"Location"} />,
      dataIndex: "LOCATION",
      key: "location",
      width: "30%",
      align: "left",
      footerContent: "Totals + Averages",
      sorter: (a, b) => {
        if (a.LOCATION > b.LOCATION) return 1;
        if (a.LOCATION < b.LOCATION) return -1;
        return 0;
      },
      render: (text, record) => {
        return (
          <Frag>
            <Row>
              <PrimaryTableRowText text={text} />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Walk-Ins"} />,
      dataIndex: "COUNT",
      key: "COUNT",
      width: "15%",
      footerSum: true,
      sorter: (a, b) => {
        return a.COUNT - b.COUNT;
      },
      align: "left",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Unique Walk-Ins"} />,
      dataIndex: "UNIQUE",
      key: "UNIQUE",
      footerSum: true,
      width: "15%",
      sorter: (a, b) => {
        return a.UNIQUE - b.UNIQUE;
      },
      align: "left",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
  ];

  return (
    <Card
      title={<CardTitleText text={"Visitors - By Location"} />}
      extra={
        <FlightDateText
          text={
            attributionUpdatedOn
              ? `Last updated on ${moment(attributionUpdatedOn).format(
                  "MMMM Do, YYYY [at] h:mm A"
                )}`
              : "This report has not been updated"
          }
        />
      }
      bodyStyle={{ padding: 0 }}
      headStyle={{ borderWidth: "3px" }}
    >
      <PaginationTable
        rowKey={record => record.key}
        bordered={false}
        columns={cols}
        scroll={{ y: 300, x: 1025 }}
        total={data.length}
        data={data.map((record, index) => {
          return {
            ...record,
            key: index,
          };
        })}
        paginationSize={"default"}
        size={"default"}
        pageSize={pageSize}
        current={currentPage}
        footer={footerData}
        onChange={paginationObject => {
          setCurrentPage(paginationObject.current);
        }}
      />
    </Card>
  );
};

export default WalkinByLocation;
