import moment from "moment";
import React from "react";

const EditorialDetailsPageViewController = ({ ...props }) => {
  let pageViewsListNormalized = [];
  const filterByDate = (startDate, endDate) => {
    const pageViewsList = pageViewsListNormalized.filter(item => {
      return (
        moment(item.pageViewTime, "YYYY-MM-DD")
          .utc(true)
          .isSameOrAfter(startDate.utc(true)) &&
        moment(item.pageViewTime, "YYYY-MM-DD")
          .utc(true)
          .isSameOrBefore(endDate.utc(true))
      );
    });

    let summary = pageViewsList.reduce(
      (acc, cur) => {
        const {
          COOKIE,
          FINGERPRINT,
          DEVICE_FINGERPRINT,
          IP,
        } = cur.attributionMethod;
        if (COOKIE) {
          if (!acc.uniqueUsers.userIds.find(user => user === COOKIE))
            acc.uniqueUsers.userIds.push(COOKIE);
        }
        if (FINGERPRINT) {
          if (!acc.uniqueUsers.fingerprints.find(user => user === FINGERPRINT))
            acc.uniqueUsers.fingerprints.push(FINGERPRINT);
        }
        if (DEVICE_FINGERPRINT) {
          if (
            !acc.uniqueUsers.deviceFingerprint.find(
              user => user === DEVICE_FINGERPRINT
            )
          )
            acc.uniqueUsers.deviceFingerprint.push(DEVICE_FINGERPRINT);
        }

        if (IP) {
          if (!acc.uniqueUsers.ipAddresses.find(user => user === IP))
            acc.uniqueUsers.ipAddresses.push(IP);
        }
        return {
          pageViews: (acc.pageViews += 1),
          clicks: acc.clicks + cur.clicksList.length,
          customers:
            acc.uniqueUsers.userIds.length +
            acc.uniqueUsers.ipAddresses.length +
            acc.uniqueUsers.fingerprints.length +
            acc.uniqueUsers.deviceFingerprint.length,
          uniqueUsers: acc.uniqueUsers,
        };
      },
      {
        pageViews: 0,
        clicks: 0,
        customers: 0,
        uniqueUsers: {
          //Use set to prevent duplication on the values
          ipAddresses: [],
          userIds: [],
          fingerprints: [],
          deviceFingerprint: [],
        },
      }
    );

    return { summary, pageViewsList };
  };
  if (props.reportData) {
    const { pageViewsList } = props.reportData;
    if (pageViewsList) {
      pageViewsListNormalized = pageViewsList.map(pageView => {
        return {
          pageUrl: pageView.PAGE_URL,
          pageViewTime: pageView.COLLECTOR_TSTAMP,
          impressions: pageView.CLICKS.length,
          firstImpression: new Date(
            Math.max(...pageView.CLICKS.map(e => new Date(e.COLLECTOR_TSTAMP)))
          ),
          clicksList: pageView.CLICKS,
          attributionMethod: pageView.ATTRIBUTION_METHOD,
          id: pageView.EVENT_ID,
        };
      });
    }
  }
  return React.cloneElement(props.children, {
    ...props.children.props,
    ...props,
    pageViewsListNormalized,
    filterByDate,
  });
};

export default EditorialDetailsPageViewController;
