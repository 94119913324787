import gql from "graphql-tag";

const GET_REGION_GROUPS = gql`
  query regionGroups($orgs: OrgWhereInput) {
    regionGroups(
      first: 1000
      orderBy: updatedAt_DESC
      where: { OR: [{ orgs_some: $orgs }] }
    ) {
      id
      name
      regions
      isPresetList
      orgs {
        id
        name
      }
      createdAt
    }
  }
`;

export default GET_REGION_GROUPS;
