import React from "react";
import { useMutation, graphql, useQuery } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import ACTIVATE_HASHED_EMAIL_LIST from "../../../GraphQl/Mutations/ACTIVATE_HASHED_EMAIL_LIST";
import CREATE_HASHED_EMAIL_LIST from "../../../GraphQl/Mutations/CREATE_HASHED_EMAIL_LIST";
import UPDATE_HASHED_EMAIL_LIST from "../../../GraphQl/Mutations/UPDATE_HASHED_EMAIL_LIST";
import BULK_CREATE_HASHED_EMAIL_ADDRESS from "../../../GraphQl/Mutations/BULK_CREATE_HASHED_EMAIL_ADDRESS";
import GET_HASHED_EMAIL_LIST_WITH_COUNT from "../../../GraphQl/Queries/GET_HASHED_EMAIL_LIST_WITH_COUNT";
import GET_HASHED_EMAIL_LIST from "../../../GraphQl/Queries/GET_HASHED_EMAIL_LIST";
import DELETE_HASHED_EMAIL_LIST from "../../../GraphQl/Mutations/DELETE_HASHED_EMAIL_LIST";
import DELETE_MANY_HASHED_EMAIL_ADDRESS from "../../../GraphQl/Mutations/DELETE_MANY_HASHED_EMAILS";
import PROCESS_DEVICE_IDS from "../../../GraphQl/Mutations/PROCESS_DEVICE_IDS";

const FirstPartyDataMainLoader = props => {
  const { currentUser } = props;
  const currentOrg = currentUser.role.org;
  const [activateHashedEmailList] = useMutation(ACTIVATE_HASHED_EMAIL_LIST, {
    variables: {
      orgID: currentOrg.id,
      orgName: currentOrg.name,
    },
  });

  const [createHashedEmailList] = useMutation(CREATE_HASHED_EMAIL_LIST, {
    variables: {
      orgID: currentOrg.id,
    },
  });

  const [updateHashedEmailList] = useMutation(UPDATE_HASHED_EMAIL_LIST);

  const [createHashedEmailAddress] = useMutation(
    BULK_CREATE_HASHED_EMAIL_ADDRESS,
    {
      variables: {
        orgID: currentOrg.id,
      },
    }
  );

  const {
    data: getHashedEmailListWithCount,
    loading: loadingHashedEmailLists,
    refetch: refetchHashedEmailLists,
  } = useQuery(GET_HASHED_EMAIL_LIST_WITH_COUNT, {
    variables: { orgId: currentOrg.id },
  });

  const { data: hashedEmailList, refetch: refetchHashedEmailList } = useQuery(
    GET_HASHED_EMAIL_LIST,
    {
      variables: { id: props.id },
      skip: true,
    }
  );

  const [deleteHashedEmailList] = useMutation(DELETE_HASHED_EMAIL_LIST, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_HASHED_EMAIL_LIST_WITH_COUNT,
        variables: { orgId: currentOrg.id },
      },
    ],
  });

  const [deleteManyHashedEmailAddresses] = useMutation(
    DELETE_MANY_HASHED_EMAIL_ADDRESS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_HASHED_EMAIL_LIST_WITH_COUNT,
          variables: { orgId: currentOrg.id },
        },
      ],
    }
  );

  const [processDeviceIds] = useMutation(PROCESS_DEVICE_IDS);

  return React.cloneElement(props.children, {
    ...props.children.props,
    activateHashedEmailList,
    createHashedEmailList,
    updateHashedEmailList,
    createHashedEmailAddress,
    hashedEmailLists: getHashedEmailListWithCount,
    loadingHashedEmailLists,
    refetchHashedEmailLists,
    deleteHashedEmailList,
    deleteManyHashedEmailAddresses,
    processDeviceIds,
    hashedEmailList,
    refetchHashedEmailList,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(FirstPartyDataMainLoader);
