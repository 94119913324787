import moment from "moment";

export const getMobileOperatingSystem = userAgent => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad/.test(userAgent) && !window.MSStream) {
    return "iPad";
  }

  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return "iPhone";
  }

  if (/iPod/.test(userAgent) && !window.MSStream) {
    return "iPod";
  }

  if (/CrOS/.test(userAgent) && !window.MSStream) {
    return "Chromebook Computer";
  }

  if (/Macintosh/.test(userAgent)) {
    return "Apple Computer";
  }

  if (/Windows NT/.test(userAgent)) {
    return "Windows Computer";
  }

  //Default Response
  return "Computer";
};

export const determineAdServer = str => {
  //detects if page served is a known ad server
  if (
    str.includes("amazon-adsystem") ||
    str.includes("mopub") ||
    str.includes("adsbynimbus") ||
    str.includes("smartadserver") ||
    str.includes("localhost") ||
    str.includes("googlesyndication") ||
    str.includes("doubleclick") ||
    str.includes("inner-active.mobi") ||
    str.includes("nexage") ||
    str.includes("adcolony") ||
    str.includes("sdk")
  ) {
    return "Ad Server";
  } else if (
    str.includes("itunes") ||
    str.includes("play.google") ||
    str.includes("apps.apple.com")
  ) {
    return "Application";
  }

  return "Website";
};
export const groupByKeys = (data, keys) => {
  let finalResult = keys.map(key => {
    return Object.values(
      data.reduce((result, obj) => {
        let objKey = obj[key];
        result[objKey] = result[objKey] || {
          key: key,
          count: 0,
          xAxis: objKey,
        };
        result[objKey].count += 1;
        return result;
      }, {})
    );
  });
  return finalResult;
};

export const signUpsResponseData = data => {
  const sortedData = data.sort((a, b) => {
    if (a.xAxis === b.xAxis) return 0;
    if (moment(a.xAxis).isAfter(b.xAxis)) return 1;
    return -1;
  });

  const startDate = sortedData[0].xAxis;
  const endDate = sortedData[sortedData.length - 1].xAxis;

  let dates = [];

  const enumerateDaysBetweenDates = () => {
    var currDate = moment(startDate)
      .startOf("day")
      .add(-1, "days");
    var lastDate = moment(endDate)
      .startOf("day")
      .add(+1, "days");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(moment(currDate).format("YYYY-MM-DD"));
    }

    return dates;
  };

  enumerateDaysBetweenDates();

  return dates.map(date => {
    return {
      count: sortedData.filter(
        sorted => moment(sorted.xAxis).format("YYYY-MM-DD") === date
      ).length
        ? sortedData.filter(
            sorted => moment(sorted.xAxis).format("YYYY-MM-DD") === date
          )[0].count
        : 0,
      xAxis: moment(date).format(),
    };
  });
};
