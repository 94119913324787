import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_WITH_CPM = gql`
  mutation updateCampaignOrderWithCPM(
    $campaignOrderID: ID!
    $data: CampaignOrderUpdateInput!
  ) {
    updateCampaignOrder(where: { id: $campaignOrderID }, data: $data) {
      id
      status
      name
      type
      cpm
      attributionWindow
      geoTargets {
        id
        name
        circles {
          id
          radius
          coordinate {
            lat
            lng
          }
        }
        polygons {
          geometry
        }
      }
      attributionTargets {
        id
        name
        circles {
          id
          radius
          coordinate {
            lat
            lng
          }
        }
        polygons {
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
      orgs {
        id
        name
        phone
        logo {
          id
          key
        }
      }
      brands {
        id
        name
      }
      language
      category
      isRegulated
      age
      income
      targetAudiences {
        name
        deviceIdCount
        observationCount
      }
      attributionAudiences {
        name
        deviceIdCount
        observationCount
      }
      tags
      devices
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        id
        name
        cpm
        demandSidePlatform
        lineItems {
          id
          name
          cpm
          adUnits {
            id
            banners {
              id
              adId
              cpm
              media {
                id
                name
              }
            }
          }
        }
      }
      advertiser {
        id
        name
      }
      changeLog {
        id
        description
        createdAt
        changedBy {
          username
          avatar {
            key
          }
        }
      }
    }
  }
`;

export { UPDATE_CAMPAIGN_ORDER_WITH_CPM };
