import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import GET_ROLL_UP_DISPLAY from "../../../../GraphQl/Queries/GET_ROLL_UP_DISPLAY";
import RollUpDisplayController from "./RollUpDisplayController";
import {
  advertiserIdParamKey,
  parentAdvertiserIdParamKey,
} from "../../../../../platform/shared/AdvertiserFilter/AdvertiserFilter.container";
import { defaultAttributionWindow } from "../../../../utils/constants/constants";

const RollUpDisplayDataLoader = ({ children, ...props }) => {
  const dataConfig =
    (props.currentUser &&
      props.currentUser.role.org.dataConfig &&
      props.currentUser.role.org &&
      props.currentUser.role.org.dataConfig) ||
    {};

  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;
  const loggedInRole = props.currentUser.role;
  let params = new URLSearchParams(props.location.search);
  const orgId = loggedInRole.org.id;
  const {
    startDate,
    endDate,
    loadProgress,
    setLoadProgress,
    totals,
    setTotals,
    productOn,
  } = props;
  const org = params.get(`${advertiserIdParamKey}`);
  const parentOrg = params.get(`${parentAdvertiserIdParamKey}`);
  const [advertiserIds, setAdvertiserIds] = useState([orgId]);

  useEffect(() => {
    if (!org) {
      return setAdvertiserIds([orgId]);
    }
    setAdvertiserIds(org.split("+"));
  }, [org, orgId]);

  const computeTotals = data => {
    if (data && data.getRollUpDisplay) {
      let totalImpressions =
        (totals.impressions || 0) + data.getRollUpDisplay.display.impressions;
      let spend = (totals.spend || 0) + data.getRollUpDisplay.attribution.spend;
      let revenue =
        (totals.revenue || 0) + data.getRollUpDisplay.attribution.revenue;
      setTotals({
        ...totals,
        impressions: totalImpressions,
        spend: spend,
        revenue: revenue,
        roas: (revenue / spend) * 100,
      });
    }
  };

  const { loading, data, refetch } = useQuery(GET_ROLL_UP_DISPLAY, {
    variables: {
      orgId,
      startDate: startDate
        ? moment(startDate)
            .startOf("day")
            .utc()
            .toISOString()
        : undefined,
      endDate: endDate
        ? moment(endDate)
            .endOf("day")
            .utc()
            .toISOString()
        : undefined,
      parentOrgId: parentOrg ? parentOrg : undefined,
      advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
      attributionWindow: defaultDisplayAttributionWindow,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setLoadProgress(loadProgress - 1);
      computeTotals(data);
    },
    fetchPolicy: "network-only",
  });

  const [
    getPrevRangeData,
    {
      loading: loadingPrevRangeData,
      data: prevRangeData,
      refetch: refetchPrevRangeData,
    },
  ] = useLazyQuery(GET_ROLL_UP_DISPLAY);

  useEffect(() => {
    if (startDate && endDate) {
      const diff = endDate.diff(startDate, "days");
      const prevStart = moment(startDate)
        .subtract(diff, "days")
        .startOf("day")
        .utc();
      const prevEnd = moment(endDate)
        .subtract(1, "day")
        .endOf("day")
        .utc();
      getPrevRangeData({
        variables: {
          orgId,
          startDate: prevStart,
          endDate: prevEnd,
          parentOrgId: parentOrg ? parentOrg : undefined,
          advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
        },
        notifyOnNetworkStatusChange: true,
      });
    }
  }, [
    advertiserIds,
    data,
    endDate,
    getPrevRangeData,
    org,
    orgId,
    parentOrg,
    startDate,
  ]);

  useEffect(() => {
    refetch();
  }, [productOn, refetch]);

  return (
    <RollUpDisplayController
      {...props}
      org={org}
      refetch={refetch}
      startDate={startDate}
      endDate={endDate}
      data={data}
      loading={loading}
      prevRangeData={prevRangeData}
      loadingPrevRangeData={loadingPrevRangeData}
      refetchPrevRangeData={refetchPrevRangeData}
    >
      {children}
    </RollUpDisplayController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(withApollo(RollUpDisplayDataLoader)));
