import React, { useState } from "react";
import { Form, Typography, Row, Col, Space, Input, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import PaginationTable from "../../../shared/PaginationTable";
import Button from "../../../shared/globalStyling/Button";

const LaunchCampaignConfigDomains = ({ domains, setDomains }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => name,
    },
    {
      title: "Include/Exclude",
      dataIndex: "isInclude",
      key: "isInclude",
      render: (isInclude, record) => {
        const handleChange = value => {
          const index = domains.findIndex(
            domain => domain.name === record.name
          );
          const updatedDomain = JSON.parse(JSON.stringify(domains));
          updatedDomain[index] = { ...record, isInclude: value };
          setDomains(updatedDomain);
        };
        return (
          <Select
            value={isInclude}
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: true,
                label: "Include",
              },
              {
                value: false,
                label: "Exclude",
              },
            ]}
          />
        );
      },
    },
    {
      title: "",
      key: "delete",

      render: (_, record) => {
        const handleRemoveDomain = () => {
          setDomains(domains.filter(domain => domain.name !== record.name));
        };
        return (
          <Button
            onClick={handleRemoveDomain}
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  const handleSubmit = values => {
    form.resetFields();
    const name = Object.values(values)[0];
    if (!domains.find(domain => domain.name === name)) {
      setDomains([
        ...domains,
        {
          name: Object.values(values)[0],
          isInclude: true,
        },
      ]);
    }
  };

  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          width: "100%",
        }}
      >
        <Typography.Title level={5} style={{ margin: 0 }}>
          Domains
        </Typography.Title>

        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row>
            <Col span={10}>
              <Form.Item
                name=""
                rules={[
                  {
                    type: "url",
                    required: true,
                    message:
                      'Please add "http://" or "https://" for example "http://www.example.com"',
                  },
                ]}
              >
                <Input
                  style={{
                    margin: "10px 10px 10px 0",
                    height: 38,
                    width: 300,
                  }}
                  placeholder="Enter Domain"
                />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </Form>

        <PaginationTable
          columns={columns}
          data={domains}
          bordered={false}
          rowSelection={null}
          paginationSize={"default"}
          scroll={{ x: 1200 }}
          size={"default"}
          current={currentPage}
          pageSize={10}
          onChange={(paginationObject, filtersObject, sorterObject) => {
            setCurrentPage(paginationObject.current);
          }}
          rowKey="name"
          style={{ marginTop: 12 }}
        />
      </Space>
    </div>
  );
};

export default LaunchCampaignConfigDomains;
