import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
//locals
import App from "./App";
import MaintenanceWhiteLabel from "./MaintenanceWhiteLabel";
import { unregister } from "./registerServiceWorker";

// NOTE: Toggle this to put the in Maintenance mode
const maintenance = false;

ReactDOM.render(
  maintenance ? <MaintenanceWhiteLabel /> : <App />,
  document.getElementById("root")
);
unregister();
