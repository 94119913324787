import React from "react";
import { cloneDeep } from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import uuid from "uuid";

const EditorialDetailsController = ({
  data,
  transactions,
  loading,
  error,
  transactionsError,
  transactionLoading,
  allTransactions,
  allTransactionLoading,
  allTransactionsError,
  getEditorialDomain,
  currentOrgId,
  selectedStartEndDate,
  deleteEditorial,
  pausePlayEditorial,
  triggerDag,
  ...props
}) => {
  //Early return if no data is found. This is to prevent errors when the page is refreshed
  if (!data || !transactions) {
    return (
      <React.Fragment>
        {React.cloneElement(props.children, {
          data: null,
          name: "--",
          startDate: null,
          endDate: null,
          loading,
          error,
          transactionsError,
          transactionLoading,
          transactions: null,
          allTransactions: null,
          allTransactionLoading,
          allTransactionsError,
        })}
      </React.Fragment>
    );
  }
  const {
    editorial,
    pageViewsReport,
    signUpsSummaryReport,
    signUpsListReport,
    transactionsReport,
  } = data;
  const { editorialTransactionsesConnection, full } = transactions;
  //Format by date and transactions data for chart and for easier data manipulation
  const formattedByDate =
    editorial &&
    editorial.byDate.map(item => {
      return {
        ...item,
        xAxis: item.date,
      };
    });

  //There are a bunch of uncessary data on connection query. This will filter it out
  const formattedTransactions = editorialTransactionsesConnection.edges.map(
    t => {
      //Node holds the actual data for transactions
      const { node } = t;
      return node;
    }
  );

  //Hook for date changes. This will return filtered by date and transactions data
  const filterByDate = dates => {
    const byDate = cloneDeep(editorial.byDate);
    const filteredByDate = byDate
      .filter(item => {
        return (
          moment(item.date, "YYYY-MM-DD") >= dates[0] &&
          moment(item.date, "YYYY-MM-DD") <= dates[1]
        );
      })
      .map(item => {
        return {
          ...item,
          xAxis: item.date,
        };
      });

    const filteredData = filteredByDate.reduce(
      (acc, curr) => {
        return {
          transactions: acc.transactions + curr.transactions,
          revenue: acc.revenue + curr.revenue,
          customers: acc.customers + curr.customers,
          clicks: acc.clicks + curr.clicks,
        };
      },
      {
        transactions: 0,
        revenue: 0,
        customers: 0,
        clicks: 0,
      }
    );

    const reportData = filteredByDate.map(byDate => {
      return {
        Date: moment(byDate.xAxis)
          .utc(true)
          .format("YYYY-MM-DD"),
        Transactions: byDate.transactions,
        Revenue: byDate.revenue,
        Customers: byDate.customers,
        "Page Views": byDate.clicks,
      };
    });
    return {
      dates,
      filteredByDate,
      filteredData,
      reportData,
    };
  };

  const filterByDropdown = () => {
    const transactions = cloneDeep(allTransactions.editorialTransactionses);
    let byDates = [];
    let customers = {
      ip: [],
      cookies: [],
      fingerprint: [],
      deviceFingerprint: [],
    };
    transactions.forEach(transaction => {
      const { orderTime, attributionMethod, attributionValue } = transaction;
      const date = moment(orderTime)
        .utc(true)
        .format("YYYY-MM-DD");
      if (attributionMethod === "IP") {
        const customer = customers.ip.find(
          customer => customer === attributionValue
        );
        if (!customer) {
          customers.ip.push(attributionValue);
        }
      } else if (attributionMethod === "COOKIES") {
        const customer = customers.cookies.find(
          customer => customer === attributionValue
        );
        if (!customer) {
          customers.cookies.push(attributionValue);
        }
      } else if (attributionMethod === "DEVICE_FINGERPRINT") {
        const customer = customers.deviceFingerprint.find(
          customer => customer === attributionValue
        );
        if (!customer) {
          customers.deviceFingerprint.push(attributionValue);
        }
      } else if (attributionMethod === "FINGERPRINT") {
        const customer = customers.fingerprint.find(
          customer => customer === attributionValue
        );
        if (!customer) {
          customers.fingerprint.push(attributionValue);
        }
      }
      const byDate = byDates.find((byDate, index) => byDate.xAxis === date);
      if (byDate) {
        byDate.transactions += 1;
        byDate.revenue += transaction.total;
        byDate.clicks += transaction.clicks;
      } else {
        byDates.push({
          xAxis: date,
          transactions: 1,
          revenue: transaction.total,
          clicks: transaction.clicks,
        });
      }
    });

    const summary = byDates.reduce(
      (acc, curr) => {
        return {
          transactions: acc.transactions + curr.transactions,
          revenue: acc.revenue + curr.revenue,
          clicks: acc.clicks + curr.clicks,
        };
      },
      {
        transactions: 0,
        revenue: 0,
        customers: 0,
        clicks: 0,
      }
    );

    summary.customers =
      customers.ip.length +
      customers.cookies.length +
      customers.fingerprint.length +
      customers.deviceFingerprint.length;
    return {
      byDates,
      summary,
    };
  };

  const domainViewClick = async () => {
    await getEditorialDomain({
      variables: {
        where: {
          clicksTag: {
            id: editorial.clicksTag.id,
          },
          transactionsTag: {
            id: editorial.transactionsTag.id,
          },
          url: null,
          OR: [
            {
              sharedOrgs_some: {
                org: {
                  id: currentOrgId,
                },
              },
            },
            {
              sharedOrgs_some: {
                org: {
                  parentOrg_some: { id: currentOrgId },
                },
              },
            },
          ],
        },
      },
    });
  };

  let hasReport = true;
  if (
    (!transactionsReport || transactionsReport.reports.length === 0) &&
    (!pageViewsReport || pageViewsReport.reports.length === 0) &&
    (!signUpsSummaryReport || signUpsSummaryReport.reports.length === 0)
  ) {
    hasReport = false;
  }

  const pausePlayEditorialEvent = async status => {
    await pausePlayEditorial({
      variables: {
        status,
      },
    });
  };

  const reprocessPublisher = async () => {
    const generateId = uuid.v4();
    await triggerDag({
      variables: {
        where: {
          dag_id: "attention-signals-tools",
          body: {
            dag_run_id: generateId,
            conf: {
              id: `function=reprocessPublisher id=${editorial.id}`,
            },
          },
        },
      },
    });
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        data: editorial,
        name: editorial.name,
        startDate: editorial.startDate,
        endDate: editorial.endDate,
        status: editorial.status,
        formattedByDate,
        formattedTransactions,
        filterByDate,
        filterByDropdown,
        loading,
        error,
        transactionsError,
        transactions,
        transactionLoading,
        totalTransactions: full.aggregate.count,
        allTransactions: allTransactions
          ? allTransactions.editorialTransactionses
          : null,
        allTransactionLoading,
        allTransactionsError,
        domainViewClick,
        selectedStartEndDate,
        pageViewsReport,
        signUpsSummaryReport,
        signUpsListReport,
        transactionsReportKey: transactionsReport,
        hasReport,
        deleteEditorial,
        pausePlayEditorial: pausePlayEditorialEvent,
        reprocessPublisher,
      })}
    </React.Fragment>
  );
};

export default withRouter(EditorialDetailsController);
