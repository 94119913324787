import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AdvertiserMainView from "../main/AdvertiserMainView";
import AdvertiserMainLoader from "../../../../core/components/advertiser/AdvertiserMainLoader";
import AdvertiserDetailsView from "../details/AdvertiserDetailsView";
import AdvertiserDetailsLoader from "../../../../core/components/advertiser/advertiserdetails/AdvertiserDetailsLoader";
import AdvertiserDetailsTransactionsReportsLoader from "../../../../core/components/advertiser/advertiserdetails/transactions/AdvertiserDetailsTransactionsReportsLoader";
import AdvertiserDetailsTransactionsReportController from "../../../../core/components/advertiser/advertiserdetails/transactions/AdvertiserDetailsTransactionsReportController";

const AdvertiserRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${path}/details/:id?`}
          render={() => (
            <AdvertiserDetailsLoader>
              <AdvertiserDetailsTransactionsReportsLoader>
                <AdvertiserDetailsTransactionsReportController>
                  <AdvertiserDetailsView />
                </AdvertiserDetailsTransactionsReportController>
              </AdvertiserDetailsTransactionsReportsLoader>
            </AdvertiserDetailsLoader>
          )}
        />
        <Route
          path={`${path}/main/:pageNumber?`}
          exact
          render={() => (
            <AdvertiserMainLoader>
              <AdvertiserMainView />
            </AdvertiserMainLoader>
          )}
        />
        {/* Fallback */}
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}/main/1`} />}
        />
      </Switch>
    </div>
  );
};

export default AdvertiserRoutes;
