import React from "react";
import { Table, Switch, Divider, Tooltip } from "antd";

import { orgFeaturesInfo } from "../../../../core/utils/constants/constants";

const AppearanceToggler = props => {
  const { config } = props;

  const style = {
    container: {
      margin: "5px",
    },
  };
  const columns = [
    {
      title: "Appearance",
      width: "60%",
      key: "feature",
      dataIndex: "feature",
      render: text => <div>{text.replace(/_/g, " ")}</div>,
    },
    {
      title: "",
      width: "15%",
      dataIndex: "feature",
      key: "write",

      render: text => (
        <Tooltip title={orgFeaturesInfo[text]} key={text}>
          <Switch
            checked={
              config["horizontalNavigation"]
                ? config["horizontalNavigation"]
                : false
            }
            name={`${text}-WRITE`}
            onChange={checked => {
              props.onSwitchChange(checked, `horizontalNavigation-CONFIG`);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <div style={style.container}>
      <Table
        columns={columns}
        bordered
        dataSource={[{ feature: "HORIZONTAL_NAVIGATION", key: 1 }]}
        pagination={false}
      />
      <Divider />
    </div>
  );
};

export default AppearanceToggler;
