import React from "react";
import { Card, Row, Spin } from "antd";
import SummaryDetailBar from "./components/SummaryDetailBar";
import { isEmpty } from "lodash";

const LoadingCard = () => {
  return (
    <Card>
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    </Card>
  );
};
const SummaryTab = props => {
  const {
    colStyle,
    selectedCampaign,
    chartsLoading,
    campaignOrder,
    chartData,
    showTransactions,
    showAttribution,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    dataSummaryRangeDate,
    setDataSummaryRangeDate,
    signUpCampaign,
    setRangeType,
    rangeType,
    priceVisible,
    currentUser,
  } = props;
  if (chartsLoading) {
    return <LoadingCard />;
  }

  return (
    <SummaryDetailBar
      colStyle={colStyle}
      selectedCampaign={selectedCampaign}
      campaignOrder={campaignOrder}
      chartData={chartData}
      signUpCampaign={signUpCampaign}
      showTransactions={showTransactions}
      showAttribution={showAttribution}
      bySummaryRangeDate={bySummaryRangeDate}
      setBySummaryRangeDate={setBySummaryRangeDate}
      dataSummaryRangeDate={dataSummaryRangeDate}
      setDataSummaryRangeDate={setDataSummaryRangeDate}
      setRangeType={setRangeType}
      rangeType={rangeType}
      priceVisible={priceVisible}
      dataConfig={
        !isEmpty(currentUser.role.org) ? currentUser.role.org.dataConfig : {}
      }
      {...props}
    />
  );
};

export default SummaryTab;
