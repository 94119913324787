import gql from "graphql-tag";

const CREATE_SEGMENT = gql`
  mutation createSegment(
    $memberId: Int!
    $short_name: String!
    $description: String
    $code: String!
    $price: Float!
  ) {
    createSegment(
      where: { member_id: $memberId }
      data: {
        short_name: $short_name
        code: $code
        price: $price
        description: $description
      }
    ) {
      appnexusId
      short_name
      member_id
      advertiser_id
      description
      code
      category
      price
      active
      error
    }
  }
`;

export default CREATE_SEGMENT;
