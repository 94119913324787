import gql from "graphql-tag";

const GET_ROLL_UP_PAID_SEARCH_ANALYTICS = gql`
  query getRollUpPaidSearchAnalytics(
    $orgId: String!
    $startDate: DateTime
    $endDate: DateTime
    $advertiserIds: [String]
  ) {
    getRollUpPaidSearchAnalytics(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      advertiserIds: $advertiserIds
    )
  }
`;

export default GET_ROLL_UP_PAID_SEARCH_ANALYTICS;
