import gql from "graphql-tag";

const EDIT_USER_SELF = gql`
  mutation updateUser($avatar: MediaUpdateOneWithoutUsersInput, $id: ID!) {
    updateUser(where: { id: $id }, data: { avatar: $avatar }) {
      id
      avatar {
        id
        key
      }
    }
  }
`;

export default EDIT_USER_SELF;
