import gql from "graphql-tag";
const DELETE_ADUNIT = gql`
  mutation updateCampaignOrder($id: ID!, $adUnitId: ID!) {
    updateCampaignOrder(
      data: { adUnits: { delete: { id: $adUnitId } } }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export default DELETE_ADUNIT;
