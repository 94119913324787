//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Timeline, Row, Col, Tooltip, Typography } from "antd";

const { Item } = Timeline;
const { Text } = Typography;

const ClicksSubTable = ({ clicks, attributionMethod }) => {
  const [clicksData, setClicksData] = useState(clicks);

  useEffect(() => {
    const sortedClicks = clicks.sort((a, b) => {
      if (moment(a.viewTime).isBefore(moment(b.viewTime))) return -1;
      return 1;
    });

    setClicksData(sortedClicks);
  }, [clicks]);

  const TruncatedURL = ({ url }) => {
    const maxLength = 50;
    const displayUrl =
      url.length > maxLength ? `${url.substring(0, maxLength)}...` : url;

    return (
      <Tooltip
        title={url}
        placement="topLeft"
        overlayStyle={{ maxWidth: "800px" }}
      >
        <Text style={{ maxWidth: "100%" }}>{displayUrl}</Text>
      </Tooltip>
    );
  };

  const makeTimeLine = clicksData.map((click, i) => {
    const { viewTime, pageURL } = click;

    //For debugging purposes only. Don't delete
    // let value;
    // switch (attributionMethod) {
    //   case "COOKIES":
    //     value = click.domainUserId;
    //     break;
    //   case "FINGERPRINT":
    //     value = click.fingerprint;
    //     break;
    //   case "IP":
    //     value = click.ipAddress;
    //     break;
    //   case "DEVICE_FINGERPRINT":
    //     value = click.useragent;
    //     break;
    // }
    return (
      <Item key={i}>
        <Row gutter={16} align="middle">
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Page Viewed at - ${moment(viewTime).format(
                "YYYY-MM-DD - HH:mm:ss"
              )}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
                display: "flex",
                alignItems: "center",
              }}
            >
              Page - <TruncatedURL url={pageURL} />
            </div>
          </Col>
          <Col span={4}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <a
                href={pageURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Visit Link
              </a>
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Timeline mode="left" reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ClicksSubTable;
