import React from "react";
import { Space } from "antd";
import { Page, Text, Document } from "@react-pdf/renderer";
import {
  GoogleTagManagerInstructions,
  GoogleTagManagerInstructionsPDF,
} from "./GoogleTagManagerInstructions";
import {
  WordPressInstructions,
  WordPressInstructionsPDF,
} from "./WordPressInstructions";
import { WixInstructions, WixInstructionsPDF } from "./WixInstructions";

export const TymberInstructions = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <Space direction="vertical">
        <GoogleTagManagerInstructions generatedTag={generatedTag} />
        <WordPressInstructions generatedTag={generatedTag} />
        <WixInstructions />
      </Space>
    </React.Fragment>
  );
};

export const TymberInstructionsPDF = ({ generatedTag }) => {
  return (
    <Document>
      <Page
        wrap={false}
        size="A4"
        style={{
          padding: "40px",
        }}
      >
        <Text
          style={{
            fontSize: 15,
            marginBottom: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          Cart Instruction for Tymber
        </Text>
        <GoogleTagManagerInstructionsPDF generatedTag={generatedTag} />
        <WordPressInstructionsPDF generatedTag={generatedTag} />
        <WixInstructionsPDF />
      </Page>
    </Document>
  );
};
