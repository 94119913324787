import gql from "graphql-tag";

const CREATE_TEST_SIGNAL_LOGS = gql`
  mutation createTestSignalLogs(
    $id: ID!
    $createLogs: [EventsTargetTestLogsCreateWithoutEventsTargetInput!]!
  ) {
    updateEventsTarget(
      where: { id: $id }
      data: { testLogs: { create: $createLogs } }
    ) {
      id
      name
    }
  }
`;

export default CREATE_TEST_SIGNAL_LOGS;
