import React from "react";

import { Spin } from "antd";
import "antd/dist/antd.css";

const Loader = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(255, 255, 255, 0.5)",
      zIndex: 9999,
    }}
  >
    <Spin size="large" />
  </div>
);

export default Loader;
