import React from "react";
import { Button, Col, Row, Select, message } from "antd";
import { isEmpty } from "lodash";

export const CampaignStatusApprovalForm = ({
  selectedStateLaw,
  selectedScaleBudget,
  adUnits,
  geoTargets,
  setSelectedStateLaw,
  setSelectedScaleBudget,
  handleSubmitCampaignOrderReview,
}) => {
  const selectOptions = [
    { value: "approved", label: "Approved" },
    { value: "partiallyApproved", label: "Partially Approved" },
    { value: "rejected", label: "Rejected" },
  ];

  const extractNotes = (collection, bannerField, innerField) => {
    return (collection || []).flatMap(val => {
      return (val[bannerField] || []).map(res => {
        if (res && res[innerField]) {
          return res[innerField].mediaHasNotes;
        }
        return undefined;
      });
    });
  };

  const adUnitNotes = extractNotes(adUnits, "banners", "media");
  const locationNotes = extractNotes(geoTargets, "circles", "location");

  const notes = [...adUnitNotes, ...locationNotes];

  const handleSubmit = async () => {
    try {
      message.loading("Submitting Campaign Order Review...", 0);
      const result = await handleSubmitCampaignOrderReview({
        stateLaw: selectedStateLaw,
        scaleBudget: selectedScaleBudget,
      });

      if (result) {
        message.destroy();
        message.success("Campaign Order Review Submitted!", 2);
      }
    } catch (error) {
      message.destroy();
      message.error("Error submitting Campaign Order Review", 2);
    }
  };

  const getLabelButton = (stateLaw, scaleBudget) => {
    if (
      stateLaw === "partiallyApproved" ||
      scaleBudget === "partiallyApproved"
    ) {
      return "Partially Approve";
    } else if (stateLaw === "approved" && scaleBudget === "approved") {
      return "Approve";
    } else if (isEmpty(stateLaw) && isEmpty(scaleBudget)) {
      return "Approve";
    }
    return "Reject";
  };

  return (
    <Row style={{ marginTop: "20px", marginBottom: "40px" }}>
      <Col span={12}>Do the creatives comply with State Law?</Col>
      <Col span={12}>Is there enough scale to spend the whole budget?</Col>
      <Col span={12} style={{ marginTop: "10px" }}>
        <Select
          placeholder="Select Option"
          style={{ width: 150 }}
          options={selectOptions}
          value={selectedStateLaw}
          onChange={value => setSelectedStateLaw(value)}
        />
      </Col>

      <Col span={12} style={{ marginTop: "10px" }}>
        <Select
          placeholder="Select Option"
          style={{ width: 150 }}
          options={selectOptions}
          value={selectedScaleBudget}
          onChange={value => setSelectedScaleBudget(value)}
        />
      </Col>
      <Col span={12} offset={9} style={{ marginTop: "25px" }}>
        <Button
          disabled={
            (!notes.includes(true) &&
              (selectedStateLaw === "rejected" ||
                selectedScaleBudget === "rejected")) ||
            !selectedStateLaw ||
            !selectedScaleBudget
          }
          type="primary"
          style={{
            marginTop: "30px",
            marginRight: "15px",
          }}
          onClick={() => handleSubmit()}
        >
          {getLabelButton(selectedStateLaw, selectedScaleBudget)}
        </Button>
      </Col>
    </Row>
  );
};
