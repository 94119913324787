import gql from "graphql-tag";

const DELETE_MANY_NOTIFICATIONS = gql`
  mutation deleteManyNotifications($ids: [ID!]) {
    deleteManyNotifications(where: { id_in: $ids }) {
      count
    }
  }
`;

export default DELETE_MANY_NOTIFICATIONS;
