import gql from "graphql-tag";

const GET_ADVERTISERS = gql`
  query advertisers($where: AdvertiserWhereInput) {
    advertisers(where: $where) {
      id
      name
    }
  }
`;

export default GET_ADVERTISERS;
