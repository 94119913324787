import React, { createContext } from "react";
import { useLazyQuery, useMutation } from "react-apollo";

// MUTATIONS
import CREATE_LOCATION from "../../GraphQl/Mutations/CREATE_LOCATION";

// QUERIES
import LOCATIONS from "../../GraphQl/Mutations/LOCATIONS";

const LocationContext = createContext({});

export const LocationProvider = props => {
  const { children } = props;
  const [
    fetchLocations,
    { data: dataLocations, loading: loadingLocations },
  ] = useLazyQuery(LOCATIONS, {
    fetchPolicy: "no-cache",
  });

  const [createLocation] = useMutation(CREATE_LOCATION);

  return (
    <LocationContext.Provider
      value={{
        fetchLocations,
        locations: dataLocations ? dataLocations.locations : [],
        loadingLocations,
        createLocation,
      }}
      {...props}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
