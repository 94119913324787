import React from "react";
import { Typography, Input } from "antd";

const styles = {
  container: {
    position: "relative",
  },
  copyable: {
    position: "absolute",
    top: "-30px",
    right: "20px",
  },
};

// NOTE: Was trying to get the copy button to sit over the text area, but it seems to disappear onHover.
// Moved above text area for now

const CopyableTextField = ({ value, rows }) => {
  return (
    <div style={styles.container}>
      <Input.TextArea rows={rows || 4} value={value} />
      {value && (
        <Typography.Paragraph
          copyable={{ text: `${value}` }}
          style={styles.copyable}
        />
      )}
    </div>
  );
};

export default CopyableTextField;
