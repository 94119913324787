import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import EditorialMainView from "../EditorialMainView";
import EditorialMainLoader from "../../../../core/components/editorial/editorialmain/EditorialMainLoader";
import EditorialDetailsLoader from "../../../../core/components/editorial/editorialdetails/EditorialDetailsLoader";
import EditorialDetailsView from "../editorialdetails/EditorialDetailsView";

// Feature level subroutes
const EditorialRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <EditorialMainLoader>
            <EditorialMainView />
          </EditorialMainLoader>
        )}
      />
      <Route
        path={`${path}/details/:id?/:pageNumber?`}
        exact
        render={() => (
          <EditorialDetailsLoader>
            <EditorialDetailsView />
          </EditorialDetailsLoader>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default EditorialRoutes;
