import React, { createContext, useContext, useState } from "react";
import { formatWholeNumber } from "../../../../utils/campaigns";
import { useParams } from "react-router-dom";
import moment from "moment";

const SEOLinkAcquisitionsContext = createContext();

export const SEOLinkAcquisitionsProvider = ({ dataRaw, children }) => {
  const { id } = useParams();
  const [data, setData] = useState(dataRaw);

  const onDateChange = dates => {
    const acqLinks = dataRaw.acquiredLinks;
    const filteredLinks = acqLinks.filter(link => {
      return (
        moment(link.createdAt, "YYYY-MM-DD").isSameOrAfter(dates[0]) &&
        moment(link.createdAt, "YYYY-MM-DD").isSameOrBefore(dates[1])
      );
    });

    setData({
      ...dataRaw,
      acquiredLinks: filteredLinks,
    });
  };
  const generateLinkAcquisitionsSummary = links => {
    return {
      visible: true,
      values: [
        {
          title: "Total Links Acquired",
          value: formatWholeNumber(links.total),
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const totalLinks = {
    total: data ? data.acquiredLinks.length : null,
  };

  const linkAcquisitions = data
    ? data.acquiredLinks.map(link => ({
        url: link.sourceURL,
        type: link.contentType.replace(/_/g, " ").toLowerCase(),
        destinationURL: link.destinationURL,
        createdAt: link.createdAt,
      }))
    : [];

  const breakdownColumns = [
    {
      title: "Content Type",
      dataIndex: "type",
      render: text => text.toUpperCase(),
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Links",
      dataIndex: "count",
      render: (text, record) => typeCounts[record.type] || 0,
      sorter: (a, b) => (typeCounts[a.type] || 0) - (typeCounts[b.type] || 0),
    },
  ];

  const scale = {
    date: {
      tickCount: 5,
    },
    value: {
      min: 0,
      tickCount: 5,
    },
  };

  const lineChartData = linkAcquisitions
    .reduce((acc, link) => {
      const date = link.createdAt.split("T")[0];
      const existingEntry = acc.find(item => item.date === date);
      if (existingEntry) {
        existingEntry.value++;
        existingEntry.types[link.type] =
          (existingEntry.types[link.type] || 0) + 1;
      } else {
        acc.push({
          date,
          value: 1,
          types: { [link.type]: 1 },
        });
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const calculateTypeCounts = linkAcquisitions => {
    return linkAcquisitions.reduce((acc, item) => {
      acc[item.type] = (acc[item.type] || 0) + 1;
      return acc;
    }, {});
  };

  const typeCounts = calculateTypeCounts(linkAcquisitions);

  // Create an array of unique types from linkAcquisitions
  const uniqueTypes = [...new Set(linkAcquisitions.map(item => item.type))];

  // Use this array to create the data source for the table
  const dataSource = uniqueTypes.map(type => ({ type }));

  // if (!data) {
  //   return <OverlaySpinner />;
  // }

  return (
    <SEOLinkAcquisitionsContext.Provider
      value={{
        generateLinkAcquisitionsSummary,
        totalLinks,
        linkAcquisitions,
        breakdownColumns,
        scale,
        lineChartData,
        dataSource,
        id,
        onDateChange,
      }}
    >
      {children}
    </SEOLinkAcquisitionsContext.Provider>
  );
};

export const useSEOLinkAcquisitionsContext = () => {
  const context = useContext(SEOLinkAcquisitionsContext);
  if (!context) {
    throw new Error(
      "useSEOLinkAcquisitionsContext must be used within a SEOLinkAcquisitionsProvider"
    );
  }
  return context;
};
