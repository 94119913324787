import React, { useState } from "react";
import { Card, Row, Col, message } from "antd";
import { cardMargin, cardBody } from "../../../shared/globalStyling/style";
import Button from "../../../shared/globalStyling/Button";

import { PlusOutlined } from "@ant-design/icons";
import CreateRegionGroup from "./CreateRegionGroup";
import RegionGroupListView from "./RegionGroupListView";

const RegionGroup = ({
  regionGroups,
  handleSubmitRegionGroup,
  handleUpdateRegionGroup,
  deleteRegionGroup,
  searchRegionLiquidM,
  searchRegionLiquidMData,
  searchRegionLiquidMLoading,
  presetRegionGroups,
  presetRegionGroupsLoading,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentRegion, setCurrentRegion] = useState(null);

  const toggleForm = val => {
    setIsFormOpen(val);
  };

  const onDeleteRegionGroup = Id => {
    toggleForm(false);

    message.loading("Action in progress..", 0);

    deleteRegionGroup(Id)
      .then(() => {
        message.destroy();
        message.success(`Successfully Deleted Region Group`);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: Cannot Delete Region Group`);
      });
  };

  const handleCreateRegion = () => {
    setCurrentRegion(null);
    toggleForm(true);
  };

  return (
    <>
      {isFormOpen && (
        <CreateRegionGroup
          isFormOpen={isFormOpen}
          handleSubmitRegionGroup={handleSubmitRegionGroup}
          handleUpdateRegionGroup={handleUpdateRegionGroup}
          searchRegionLiquidM={searchRegionLiquidM}
          searchRegionLiquidMData={searchRegionLiquidMData}
          searchRegionLiquidMLoading={searchRegionLiquidMLoading}
          currentRegion={currentRegion}
          closeForm={toggleForm}
          presetRegionGroups={presetRegionGroups}
          presetRegionGroupsLoading={presetRegionGroupsLoading}
        />
      )}

      <Row gutter={[18, 18]}>
        <Col xs={24} lg={24}>
          <Card
            style={cardMargin}
            bodyStyle={cardBody}
            title="Region Group"
            extra={
              <>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => handleCreateRegion()}
                >
                  Create Region Group
                </Button>
              </>
            }
          >
            <RegionGroupListView
              onDeleteRegionGroup={onDeleteRegionGroup}
              regionGroups={regionGroups}
              setCurrentRegion={setCurrentRegion}
              toggleForm={toggleForm}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RegionGroup;
