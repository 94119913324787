import React, { useState, useRef } from "react";
import { Row, Col, Input, Button } from "antd";
import { isEmpty } from "lodash";
const Search = Input.Search;

const styles = {
  row: {
    borderRight: "0.8px solid #e8e8e8",
    borderLeft: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    padding: "1.5%",
  },
  search: {
    fontSize: "14px",
    width: "100%",
  },
};

export const SegmentsFilterBar = ({ history, match }) => {
  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const url = match.url;
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      gutter={[8, 16]}
      style={styles.row}
    >
      <Col xs={{ span: 22 }} md={{ span: 8 }}>
        <Search
          ref={searchRef}
          style={styles.search}
          enterButton
          value={searchValue}
          placeholder="Search for segment with name or description"
          onChange={e => setSearchValue(e.target.value)}
          onSearch={value => {
            const whereFilter = {
              OR: [
                {
                  name_contains: encodeURIComponent(
                    value.replace(
                      String.fromCharCode(92),
                      String.fromCharCode(92, 92)
                    )
                  ),
                },
                {
                  description_contains: encodeURIComponent(
                    value.replace(
                      String.fromCharCode(92),
                      String.fromCharCode(92, 92)
                    )
                  ),
                },
              ],
            };
            if (url.includes("create-audience")) {
              history.push(
                `/audiences/create-audience/new/1/updatedAt_DESC/${JSON.stringify(
                  whereFilter
                )}`
              );
            } else {
              history.push(
                `${
                  url.includes("events-targets")
                    ? "/segments/events-targets"
                    : url.includes("geo-targets")
                    ? "/segments/geo-targets"
                    : "/segments"
                }/new/1/updatedAt_DESC/${JSON.stringify(whereFilter)}`
              );
            }
          }}
        />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 2 }}>
        <Button
          style={{ width: "100%" }}
          shape="round"
          onClick={() => {
            setSearchValue("");
            if (url.includes("create-audience")) {
              history.push(
                `/audiences/create-audience/new/1/updatedAt_DESC/{}`
              );
            } else {
              history.push(
                `${
                  url.includes("events-targets")
                    ? "/segments/events-targets"
                    : url.includes("geo-targets")
                    ? "/segments/geo-targets"
                    : "/segments"
                }/new/1/updatedAt_DESC/{}`
              );
            }
          }}
          disabled={isEmpty(
            JSON.parse(decodeURIComponent(match.params.whereFilter))
          )}
        >
          Reset
        </Button>
      </Col>
    </Row>
  );
};
