import React from "react";
import { Row, Col, Button } from "antd";

import { PageHeaderLayout, PageContentLayout } from "../../../shared/layout";
//components
import {
  SourceTable,
  AudiencesTable,
  DetailsCard,
  EditAudienceModal,
  SharingRecordsCard,
} from "./components";
// Utility
import { parseInitialSharingRecords } from "../../../../core/utils/dataManipulating/audiences";
import { PlusCircleOutlined } from "@ant-design/icons";

const AudiencesView = ({
  tablet,
  detailsCardData,
  audiencesTableData,
  onAudiencesTableRowClick,
  segments,
  onChange,
  modalVisible,
  sharingRecords,
  whichModal,
  onAddSharingRecordOrSegmentOk,
  sharingRecordTableData,
  onDeleteSegmentPopConfirmClick,
  onCreateSegmentButtonClick,
  onCreateSegmentClick,
  onEditSegmentOk,
  audienceName,
  onDeleteSharingRecordPopConfirmClick,
  onSharingRecordRowClick,
  priceCategories,
  isSegmentSelected,
  isSharingRecordSelected,
  mappingRecords,
  taxonomy,
  onMapSegmentToPriceOk,
  getAllSegmentsFromSharingRecord,
  selectedSharingRecord,
  onAudiencesTableRowSelect,
  onRemoveSegmentFromSharingRecordPopConfirmOk,
}) => {
  return (
    <React.Fragment>
      <PageHeaderLayout
        title="Audiences"
        centerContent={""}
        rightContent={
          <Button
            disabled={true} //temporarily disabled
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={onCreateSegmentButtonClick}
          >
            Add Segment
          </Button>
        }
      />
      <PageContentLayout>
        <Row>
          {tablet && (
            <Row>
              <Col
                style={{
                  backgroundColor: "white",
                  border: "1px solid #e8e8e8",
                  marginBottom: "15px",
                }}
                span={24}
              >
                <SourceTable />
              </Col>
            </Row>
          )}
          {!tablet && (
            <Col
              style={{
                backgroundColor: "white",
                border: "1px solid #e8e8e8",
                borderRightWidth: 0,
              }}
              span={3}
            >
              <SourceTable />
            </Col>
          )}
          {tablet && (
            <Row>
              <Col
                style={{
                  backgroundColor: "white",
                  border: "1px solid #e8e8e8",
                  marginBottom: "15px",
                }}
                span={24}
              >
                <AudiencesTable
                  tableData={audiencesTableData(
                    segments,
                    mappingRecords,
                    taxonomy
                  )}
                  onClick={onAudiencesTableRowClick}
                  onSelect={onAudiencesTableRowSelect}
                />
              </Col>
            </Row>
          )}
          {!tablet && (
            <Col
              style={{ backgroundColor: "white", border: "1px solid #e8e8e8" }}
              span={11}
            >
              <AudiencesTable
                tableData={audiencesTableData(
                  segments,
                  mappingRecords,
                  taxonomy
                )}
                onClick={onAudiencesTableRowClick}
                onSelect={onAudiencesTableRowSelect}
              />
            </Col>
          )}
          {tablet && (
            <Row>
              <Col span={24}>
                <DetailsCard
                  data={detailsCardData}
                  onChange={onChange}
                  popConfirm={onDeleteSegmentPopConfirmClick}
                />
                <br />
                <SharingRecordsCard
                  tableData={sharingRecordTableData(
                    parseInitialSharingRecords(sharingRecords)
                  )}
                  data={sharingRecords}
                  onChange={onChange}
                  popConfirmRemove={
                    onRemoveSegmentFromSharingRecordPopConfirmOk
                  }
                  popConfirmDelete={onDeleteSharingRecordPopConfirmClick}
                  onAddSharingRecordOrSegmentOk={onAddSharingRecordOrSegmentOk}
                  onRowClick={onSharingRecordRowClick}
                  isSegmentSelected={isSegmentSelected}
                  isSharingRecordSelected={isSharingRecordSelected}
                  sharedSegmentsData={getAllSegmentsFromSharingRecord(
                    sharingRecords,
                    selectedSharingRecord
                  )}
                />
              </Col>
            </Row>
          )}
          {!tablet && (
            <Col span={8} push={1}>
              <DetailsCard
                data={detailsCardData}
                onChange={onChange}
                popConfirm={onDeleteSegmentPopConfirmClick}
              />
              <br />
              <SharingRecordsCard
                tableData={sharingRecordTableData(
                  parseInitialSharingRecords(sharingRecords)
                )}
                data={sharingRecords}
                onChange={onChange}
                popConfirmRemove={onRemoveSegmentFromSharingRecordPopConfirmOk}
                popConfirmDelete={onDeleteSharingRecordPopConfirmClick}
                onAddSharingRecordOrSegmentOk={onAddSharingRecordOrSegmentOk}
                onRowClick={onSharingRecordRowClick}
                isSegmentSelected={isSegmentSelected}
                isSharingRecordSelected={isSharingRecordSelected}
                sharedSegmentsData={getAllSegmentsFromSharingRecord(
                  sharingRecords,
                  selectedSharingRecord
                )}
              />
            </Col>
          )}
        </Row>
        <EditAudienceModal
          priceCategories={priceCategories}
          onChange={onChange}
          modal={whichModal}
          visible={modalVisible}
          onAddSharingRecordOrSegmentOk={onAddSharingRecordOrSegmentOk}
          onEditSegmentOk={onEditSegmentOk}
          recordData={detailsCardData}
          audienceName={audienceName}
          onCreateSegmentClick={onCreateSegmentClick}
          onMapSegmentToPriceOk={onMapSegmentToPriceOk}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default AudiencesView;
