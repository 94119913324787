import gql from "graphql-tag";

const UPDATE_CAMPAIGN_ORDER_AMPLIFY = gql`
  mutation updateCampaignOrder(
    $id: ID!
    $adUnitId: ID!
    $clickthrough: String!
  ) {
    updateCampaignOrder(
      where: { id: $id }
      data: {
        adUnits: {
          update: {
            where: { id: $adUnitId }
            data: { clickthrough: $clickthrough }
          }
        }
      }
    ) {
      id
      name
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
          }
        }
      }
      isAmplify
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_AMPLIFY;
