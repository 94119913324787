import React, { useState } from "react";
import { graphql } from "react-apollo";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";
import PaidSearchMainController from "./PaidSearchMainController";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import { unset, isEmpty } from "lodash";

import { possibleFieldsPaidSearch } from "../../../utils/constants/constants";

import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import PAID_SEARCH_ORGS_CONNECTION from "../../../GraphQl/Queries/PAID_SEARCH_ORGS_CONNECTION";
import PAID_SEARCH_SHARE_ORGS from "../../../GraphQl/Queries/PAID_SEARCH_SHARE_ORGS";
import PAID_SEARCH_SHARING_DETAILS from "../../../GraphQl/Queries/PAID_SEARCH_SHARING_DETAILS";
import TRIGGER_AIRFLOW_DAG from "../../../GraphQl/Queries/TRIGGER_AIRFLOW_DAG";
import REPROCESS_STATUS from "../../../GraphQl/Queries/REPROCESS_STATUS";
import PAID_SEARCH_UPDATE_SHARING_DETAILS from "../../../GraphQl/Mutations/PAID_SEARCH_UPDATE_SHARING_DETAILS";
import UPSERT_REPROCESS_STATUS from "../../../GraphQl/Mutations/UPSERT_REPROCESS_STATUS";
import TAG_ORGS from "../../../GraphQl/Queries/TAG_ORGS"; //Tags used for launching of paid search

import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import moment from "moment";
import { filterNames } from "../../../utils/paidSearch/campaigns";
import PAID_SEARCH_LAUNCH from "../../../GraphQl/Mutations/PAID_SEARCH_LAUNCH";

//CONSTANTS
const allowedOrderBys = {
  id_ASC: true,
  id_DESC: true,
  startDate_ASC: true,
  startDate_DESC: true,
};

const PaidSearchMainLoader = props => {
  // Handle URL parameters
  const { currentUser, location, history, match, children } = props;

  const config = currentUser.permission;
  const orgConfig = determineUserAccess(config);

  const pageNumber = match.params.pageNumber;
  if (isNaN(pageNumber) || Number(pageNumber) < 1)
    location.search
      ? history.push(`paid_search/main/1${location.search}`)
      : history.push("paid_search/main/1");

  const currentOrgId = currentUser.role.org.id;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  let where = {
    AND: [
      //{ org: { dataConfig: { googleCustomerId_not: null } } },
      { id_not: null },
    ],
    OR: [
      { org: { id: currentOrgId } },
      { sharedOrgs_some: { id: currentOrgId } },
      { org: { parentOrg_some: { id: currentOrgId } } },
    ],
  };

  let byDateCount = 100;
  let byDateWhere = null;

  const params = new URLSearchParams(location.search);

  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;

  const search = params.get("search");
  const status = params.get("status");
  const filter = params.get("filter");

  if (search) {
    where["AND"].push({ org: { name_contains: search } });
  }

  if (status) {
    where["AND"].push({ status: status });
  }

  if (filter) {
    byDateWhere = {
      xAxis_gte: moment()
        .utc()
        .startOf("month"),
    };
  }

  let orderBy = "createdAt_DESC";
  const sort = params.get("sort");
  if (sort && allowedOrderBys[sort]) orderBy = sort;

  const { data: paidSearchOrgs, loading: loadingCampaign, refetch } = useQuery(
    PAID_SEARCH_ORGS_CONNECTION,
    {
      variables: {
        first: pageSize,
        skip: (Number(pageNumber) - 1) * pageSize,
        where,
        orderBy,
        aggregateDataWhereInput: byDateWhere,
        aggregateDataFirst: byDateCount,
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: childOrgs, loading: childOrgsLoading } = useQuery(
    PAID_SEARCH_SHARE_ORGS,
    {
      variables: { parentid: currentOrgId, first: 1000 },
    }
  );

  //Tags option for Paid Search Creation
  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  const currentOrg =
    childOrgs && childOrgs.orgs.find(org => org.id === currentOrgId);

  const isChannelPartner = currentOrg && currentOrg.level === "CHANNEL_PARTNER";

  const [
    getReprocessStatus,
    { data: reprocessStatus, loading: reporcessStatusLoading },
  ] = useLazyQuery(REPROCESS_STATUS, { fetchPolicy: "no-cache" });

  const [
    getSharedDetails,
    { data: sharedOrgsDetails, loading: sharedOrgsLoading },
  ] = useLazyQuery(PAID_SEARCH_SHARING_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      ids: selectedRowKeys,
    },
  });

  const [reprocessPaidSearch] = useLazyQuery(TRIGGER_AIRFLOW_DAG);

  const [updatePaidSearchSharedDetails] = useMutation(
    PAID_SEARCH_UPDATE_SHARING_DETAILS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [updateReprocessStatus] = useMutation(UPSERT_REPROCESS_STATUS, {
    fetchPolicy: "no-cache",
  });

  const [launchPaidSearch] = useMutation(PAID_SEARCH_LAUNCH);

  if (loadingCampaign || isEmpty(paidSearchOrgs)) return <OverlaySpinner />;

  // if (
  //   paidSearchOrgs &&
  //   paidSearchOrgs.paidSearchSummariesConnection.edges.length === 1 &&
  //   !search &&
  //   Number(pageNumber) <= 1
  // ) {
  //   history.push(
  //     `/paid_search/details/${paidSearchOrgs.paidSearchSummariesConnection.edges[0].node.id}/1`
  //   );
  // }

  const total = paidSearchOrgs.full.aggregate.count;

  !orgConfig.priceVisible && unset(possibleFieldsPaidSearch, "Budget");

  const defaultColumns =
    JSON.parse(sessionStorage.getItem("defaultColumnsPaidSearch")) ||
    Object.keys(possibleFieldsPaidSearch);

  let paidSearchOrgsConnection =
    paidSearchOrgs &&
    paidSearchOrgs.paidSearchSummariesConnection &&
    paidSearchOrgs.paidSearchSummariesConnection.edges &&
    paidSearchOrgs.paidSearchSummariesConnection.edges.map(edge => edge.node);
  if (props.currentUser.isDemo) {
    paidSearchOrgsConnection = filterNames(paidSearchOrgsConnection);
  }

  return (
    <PaidSearchMainController
      {...props}
      currentUser={currentUser}
      paidSearchOrgs={paidSearchOrgsConnection}
      possibleFields={possibleFieldsPaidSearch}
      defaultColumns={defaultColumns}
      childOrgs={childOrgs}
      childOrgsLoading={childOrgsLoading}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      sharedOrgsDetails={sharedOrgsDetails}
      getSharedDetails={getSharedDetails}
      sharedOrgsLoading={sharedOrgsLoading}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      currentOrgId={currentOrgId}
      updatePaidSearchSharedDetails={updatePaidSearchSharedDetails}
      current={Number(pageNumber)}
      pageSize={pageSize}
      filter={filter ? filter : "allTime"}
      total={total}
      reprocessPaidSearch={reprocessPaidSearch}
      getReprocessStatus={getReprocessStatus}
      reprocessStatus={reprocessStatus}
      reporcessStatusLoading={reporcessStatusLoading}
      updateReprocessStatus={updateReprocessStatus}
      isChannelPartner={isChannelPartner}
      eventTags={eventTags}
      eventTagsLoading={eventTagsLoading}
      launchPaidSearch={launchPaidSearch}
      refetch={refetch}
    >
      {children}
    </PaidSearchMainController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(PaidSearchMainLoader));
