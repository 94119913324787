import React from "react";
import { Row, Select, Button, Table, Card } from "antd";
import { isEmpty } from "lodash";
import { MoreOutlined } from "@ant-design/icons";
const Option = Select.Option;
const style = {
  tableHeaderStyle: {
    color: "rgb(144, 142, 142)",
    fontWeight: 0,
    fontSize: "0.8em",
  },
};

const getColumns = onRadiusChange => [
  {
    title: () => <div style={style.tableHeaderStyle}>#</div>,
    width: "5%",
    dataIndex: "order",
    key: "order",
  },
  {
    title: () => <div style={style.tableHeaderStyle}>TARGET</div>,
    width: "20%",
    dataIndex: "target",
    key: "target",
  },
  {
    title: () => <div style={style.tableHeaderStyle}>ADDRESS</div>,
    width: "40%",
    dataIndex: "address",
    key: "address",
    render: (text, record, index) => {
      return (
        <React.Fragment>
          <Row
            display="flex"
            align="middle"
            justify="center"
            style={{ textAlign: "center" }}
          >
            <div>{record.name}</div>
          </Row>
          <Row
            display="flex"
            align="middle"
            justify="center"
            style={{ fontSize: "12px", textAlign: "center" }}
          >
            {record.address}
          </Row>
        </React.Fragment>
      );
    },
  },
  {
    title: () => <div style={style.tableHeaderStyle}>RADIUS</div>,
    key: "radius",
    width: "30%",
    render: (text, record, index) => {
      return (
        <div>
          {text.radius ? (
            <Select
              defaultValue={Math.round(text.radius / 1609.34)}
              style={{ width: 120 }}
              onChange={value => onRadiusChange(value, index)}
              disabled={!text.radius}
            >
              <Option value={1}>{"1 mi"}</Option>
              <Option value={3}>{"3 mi"}</Option>
              <Option value={5}>{"5 mi"}</Option>
              <Option value={7}>{"7 mi"}</Option>
              <Option value={10}>{"10 mi"}</Option>
            </Select>
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
  {
    title: "",
    key: "more",
    width: "5%",
    render: record => {
      // TODO: Add onClick dropdown menu with more options
      return (
        <Button style={{ border: "0px" }} ghost disabled>
          <MoreOutlined style={{ color: "black" }} rotate={90} />
        </Button>
      );
    },
  },
];

const getDataSource = geoTargets => {
  return geoTargets.map((elem, index) => {
    // If there is a polygon it is a geoframe
    // If all circles have same radius it is a place
    // If there is only an address it is a region
    let radius = isEmpty(elem.circles)
      ? null
      : elem.circles.every(circle => circle.radius === elem.circles[0].radius)
      ? elem.circles[0].radius
      : null;
    let target = "";
    let address = "";
    let name = "";
    if (!isEmpty(elem.addresses) && elem.addresses.length > 1) {
      target = "Region";
      name = elem.addresses[0];
      address = "Region";
      radius = null;
      // TODO use geojson mapping to figure out type of region
    } else if (
      radius &&
      !isEmpty(elem.circles) &&
      elem.addresses.length === 1
    ) {
      target = "Place";
      name = elem.circles[0].name;
      address = elem.addresses[0];
    } else if (
      radius &&
      !isEmpty(elem.circles) &&
      !isEmpty(elem.circles[0].address)
    ) {
      target = "Place";
      name = elem.circles[0].name;
      address = elem.circles.length > 1 ? "Multiple" : elem.circles[0].address;
    } else if (
      !isEmpty(elem.polygons) ||
      !elem.radius ||
      (!isEmpty(elem.circles) && isEmpty(elem.circles[0].address))
    ) {
      target = "GeoFrame";
      address = `${
        !isEmpty(elem.polygons) ? `${elem.polygons.length} Polygons` : ""
      }
      ${isEmpty(elem.circles) || isEmpty(elem.polygons) ? "" : " & "}
      ${!isEmpty(elem.circles) ? `${elem.circles.length} Circles` : ""}`;
      radius = null;
    }
    return {
      order: index + 1,
      key: index,
      radius,
      name,
      address,
      target,
    };
  });
};

const GeoTargetList = ({
  geoTargets,
  onRadiusChange,
  onChange,
  isAttribution,
  selectedTargets,
}) => {
  const rowSelection = {
    selections: true,
    hideDefaultSelections: true,
    selectedRowKeys: selectedTargets.map(el => el.key),
    onSelect: (record, selected, selectedRows) => {
      onChange(
        isAttribution ? "selectedAttributionTargets" : "selectedGeoTargets",
        selectedRows
      );
    },
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table
        scroll={{ x: true, y: 300 }}
        rowSelection={rowSelection}
        pagination={false}
        dataSource={geoTargets ? getDataSource(geoTargets) : []}
        columns={getColumns(onRadiusChange)}
        style={style.tableHeaderStyle}
      />
    </Card>
  );
};

export default GeoTargetList;
