const selectionDisplayType = [
  { datapoint: "sii_age_range", type: "checkbox", columns: 2 },
  {
    datapoint: "sci_connex_stage_clusters",
    type: "checkbox",
    columns: 1,
  },
  {
    datapoint: "sii_gender",
    type: "checkbox",
    columns: 2,
  },
];

export default selectionDisplayType;
