export const colStyle = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

export const styles = {
  chart: {
    display: "flex",
    flexDirection: "column",
  },
};

export const gridLeft = {
  lineStyle: {
    stroke: "#e8e8e8",
    lineDash: [1, 0],
  },
};

export const gridRight = {
  lineStyle: {
    stroke: "#e8e8e8",
    lineDash: [2, 4],
  },
};

export const leftLabel = {
  textStyle: {
    fill: "#7dafff",
  },
};

export const rightLabel = {
  textStyle: {
    fill: "#92d5a7",
  },
};
