import gql from "graphql-tag";
const CREATE_CAMPAIGN_ORDER = gql`
  mutation createCampaignOrder($data: CampaignOrderCreateInput!) {
    createCampaignOrder(data: $data) {
      id
      status
      name
      description
      type
      geoTargets {
        id
        name
        circles {
          radius
          coordinate {
            lat
            lng
          }
          location {
            id
            name
            hasNotes
            notes
          }
        }
        polygons {
          geometry
        }
      }
      attributionTargets {
        id
        name
        circles {
          radius
          coordinate {
            lat
            lng
          }
        }
        polygons {
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
      orgs {
        id
        name
      }
      brands {
        id
        name
      }
      language
      category
      isRegulated
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      attributionWindow
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        demandSidePlatform
      }
      advertiser {
        id
        name
      }
      targetingCriteria
      awarenessCampaign
      signUpCampaign
      campaignChecklistProcess {
        processOne
        processOneText
        processTwo
        processTwoText
        processThree
        processThreeText
        processFour
        processFourText
        processFive
        processFiveText
      }
      signUpConfig {
        id
        appId
      }
      transactionTarget
      transactionsConfig {
        appId
        cart
        traffId
        tracksBasketItems
        gtmAccess
        gtmStatus
        updatedAt
      }
      pageViewConfig {
        id
        appId
        locationId
      }
      region
      isAmplify
      campaignReviewProcess {
        review
        stateLaw
        scaleBudget
      }
      requestorId
      # Line Items
      campaigns {
        id
        name
        startDate
        endDate
        budgetTotal
        budgetByDay
        cpm
        orgs {
          id
        }
      }
    }
  }
`;

export default CREATE_CAMPAIGN_ORDER;
