/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { Row, Col, Card, Descriptions, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  computeROASDisplay,
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../../core/utils/campaigns";
import PctDiff from "../components/PctDiff";
import "../../../../shared/globalStyling/responsive.css";

const RollUpPaidSearchView = ({
  loading,
  data,
  org,
  diffPcts,
  orgConfig,
  roasFormat,
  currencyFormat,
}) => {
  if (!data && !loading) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Card title="Paid Search">
        <Row gutter={[16, 16]}>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              loading={loading}
              title="Impressions"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Total number of Impressions served for all Paid Search channels. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label={formatNumber(data.impressions)}>
                      {diffPcts && <PctDiff value={diffPcts.impressions} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              loading={loading}
              title="Clicks"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Total number of Clicks received for all Paid Search channels. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label={formatNumber(data.clicks)}>
                      {diffPcts && <PctDiff value={diffPcts.clicks} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              title="CTR"
              loading={loading}
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Click Through Ratio - CTR is the number of clicks that your ad receives divided by the number of times your ad is shown: clicks ÷ impressions = CTR. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item
                      label={`${formatNumber3SigFig(data.ctr)}%`}
                    >
                      {diffPcts && <PctDiff value={diffPcts.ctr} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          {orgConfig && orgConfig.priceVisible && (
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Card
                title="CPC"
                loading={loading}
                extra={
                  <Tooltip
                    placement="topLeft"
                    title="Cost Per Click - (CPC) bidding means that you pay for each click on your ads. CPC is the average cost for a single click across all paid search campaigns. (Default: Last 6 Months)"
                  >
                    <QuestionCircleOutlined style={{ color: "lightgray" }} />
                  </Tooltip>
                }
              >
                {data && (
                  <div>
                    <Descriptions
                      contentStyle={{ border: "0" }}
                      labelStyle={{ background: "none" }}
                      layout="horizontal"
                      bordered={true}
                      colon={false}
                      column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                    >
                      <Descriptions.Item
                        label={formatCurrency2SigFig(data.cpc, currencyFormat)}
                      >
                        {diffPcts && <PctDiff value={diffPcts.cpc} />}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
              </Card>
            </Col>
          )}
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              title="Revenue"
              loading={loading}
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Total amount of Revenue attributed from all Paid Search campaigns. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item
                      label={`${formatCurrency2SigFig(
                        data.revenue,
                        currencyFormat
                      )}`}
                    >
                      {diffPcts && <PctDiff value={diffPcts.revenue} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          {orgConfig && orgConfig.priceVisible && (
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Card
                title="Investment"
                loading={loading}
                extra={
                  <Tooltip
                    placement="topLeft"
                    title="Total amount of Investment/Spend attributed from all Paid Search campaigns. (Default: Last 6 Months)"
                  >
                    <QuestionCircleOutlined style={{ color: "lightgray" }} />
                  </Tooltip>
                }
              >
                {data && (
                  <div>
                    <Descriptions
                      contentStyle={{ border: "0" }}
                      labelStyle={{ background: "none" }}
                      layout="horizontal"
                      bordered={true}
                      colon={false}
                      column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                    >
                      <Descriptions.Item
                        label={`${formatCurrency2SigFig(
                          data.cost,
                          currencyFormat
                        )}`}
                      >
                        {diffPcts && <PctDiff value={diffPcts.cost} />}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
              </Card>
            </Col>
          )}
          {orgConfig && orgConfig.priceVisible && (
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Card
                title="ROAS"
                loading={loading}
                extra={
                  <Tooltip
                    placement="topLeft"
                    title="Total Return on Ad Spent attributed from all Paid Search campaigns by percentage. (Default: Last 6 Months)"
                  >
                    <QuestionCircleOutlined style={{ color: "lightgray" }} />
                  </Tooltip>
                }
              >
                {data && (
                  <div>
                    <Descriptions
                      contentStyle={{ border: "0" }}
                      labelStyle={{ background: "none" }}
                      layout="horizontal"
                      bordered={true}
                      colon={false}
                      column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                    >
                      <Descriptions.Item
                        label={`${computeROASDisplay(
                          data.revenue,
                          data.cost,
                          roasFormat.key,
                          currencyFormat
                        )}`}
                      >
                        {diffPcts && <PctDiff value={diffPcts.roas} />}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
              </Card>
            </Col>
          )}
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default RollUpPaidSearchView;
