import React from "react";
import RollUpDisplayDataLoader from "../../../../../core/components/home/RollUp/display/RollUpDisplayDataLoader";
import RollUpDisplayView from "./RollUpDisplayView";

const RollUpDisplay = props => {
  return (
    <RollUpDisplayDataLoader {...props}>
      <RollUpDisplayView></RollUpDisplayView>
    </RollUpDisplayDataLoader>
  );
};

export default RollUpDisplay;
