/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Ant Design
import {
  Row,
  Button,
  Divider,
  Typography,
  Col,
  Select,
  Form,
  message,
} from "antd";

// Custom Components
import TypeBasedInput from "./TypeBasedInput";

// Constants
import { matchOptions } from "../../../../../core/utils/constants/demographicConstants";
import { useAudienceBuilderContext } from "../../context/AudienceBuilderContext";
import selectionDisplayType from "../../utils/selectionDisplayType";

const { Text } = Typography;

const AudienceDataPointInput = props => {
  const {
    metric,
    loading,
    updateAudienceMetrics,
    handleSelectedMetric,
  } = props;

  const [providerDescription, setProviderDescription] = useState(
    "No Description"
  );
  const [dataType, setDataType] = useState("defaultType");

  const {
    inputValue,
    setInputValue,
    matchCharacteristics,
    setMatchCharacteristics,
    metricValue,
    setMetricValue,
    userSelections,
    setColumnCount,
    addAsNewCard,
    setAddAsNewCard,
  } = useAudienceBuilderContext();

  const label =
    (metric &&
      metric.mappings &&
      metric.mappings.inputCriteria &&
      metric.mappings.inputCriteria &&
      metric.mappings.inputCriteria.label) ||
    metric.name;

  const handleMenuClick = e => {
    setMatchCharacteristics(e);
  };

  const validateInput = () => {
    if (matchCharacteristics === 0) {
      message.warning("Please select a match characteristic");
      return false;
    }

    if (
      dataType === "checkbox" &&
      (!metricValue ||
        !metricValue.value ||
        metricValue.value === 0 ||
        metricValue.value.length === 0)
    ) {
      message.warning("Please select a metric");
      return false;
    }

    if (
      dataType === "userSelections" &&
      (!metricValue || !metricValue.value || metricValue.value.length === 0)
    ) {
      message.warning("Please select a metric");
      return false;
    }

    if (
      dataType === "number" &&
      (metricValue == null ||
        metricValue.value == null ||
        metricValue.value === 0 ||
        metricValue.value > metric.mappings.inputCriteria.range.max ||
        metricValue.value < metric.mappings.inputCriteria.range.min)
    ) {
      setInputValue(null);
      message.warning("Invalid value");
      return false;
    }

    if (
      dataType === "string" &&
      (metricValue == null ||
        metricValue.value == null ||
        metricValue.value.trim() === "")
    ) {
      message.warning("Please enter a value");
      return false;
    }

    if (
      dataType === "date" &&
      (metricValue == null ||
        metricValue.value == null ||
        metricValue.value === "")
    ) {
      message.warning("Please enter a value");
      return false;
    }

    return true;
  };

  // Initialize default values for metricValue and matchCharacteristics
  useEffect(() => {
    let inputCriteriaType =
      metric &&
      metric.mappings &&
      metric.mappings.inputCriteria &&
      metric.mappings.inputCriteria.type;

    const displayType = selectionDisplayType.find(
      item => item.datapoint === metric.mappings.providerSubCategory
    );

    const isCheckboxType = displayType && displayType.type === "checkbox";

    if (isCheckboxType) {
      inputCriteriaType = "checkbox";
      setColumnCount(displayType.columns);
    } else if (
      metric &&
      metric.mappings &&
      metric.mappings.userSelections &&
      Array.isArray(metric.mappings.userSelections) &&
      metric.mappings.userSelections.length > 0
    ) {
      inputCriteriaType = "userSelections";
    }

    setDataType(inputCriteriaType);

    if (
      metric.mappings &&
      metric.mappings.inputCriteria &&
      metric.mappings.inputCriteria.providerDescription
    ) {
      setProviderDescription(
        metric &&
          metric.mappings &&
          metric.mappings.inputCriteria &&
          metric.mappings.inputCriteria.providerDescription
      );
    } else if (metric.mappings && metric.mappings.providerMetadata) {
      setProviderDescription(
        metric &&
          metric.mappings &&
          metric.mappings.providerMetadata &&
          metric.mappings.providerMetadata.providerDescription
      );
    }

    if (metricValue) return;

    switch (inputCriteriaType) {
      case "boolean":
        const { mappings } = metric;
        const { providerSubCategory, inputCriteria } = mappings;
        const { type } = inputCriteria;

        setInputValue(false);
        setMetricValue({
          value: 0,
          key: providerSubCategory,
          name: metric.name,
          dataType: type,
        });
        setMatchCharacteristics("=");
        break;
      case "number":
        setInputValue(null);
        setMetricValue({
          value: 0,
          key: metric.key,
          name: metric.name,
          dataType: dataType,
        });
        setMatchCharacteristics("=");
        break;
      case "date":
        setInputValue(null);
        setMetricValue({
          value: undefined,
          key: metric.key,
          name: metric.name,
          dataType: dataType,
        });
        setMatchCharacteristics("=");
        break;
      case "checkbox":
        setInputValue(null);
        setMetricValue({
          value: 0,
          key: metric.key,
          name: metric.name,
          dataType: dataType,
        });
        setMatchCharacteristics("=");
        break;
      default:
        setInputValue([]);
        setMetricValue(null);
        setMatchCharacteristics("=");
    }
  }, [metric]);

  return (
    <>
      <div style={{ padding: "15px" }}>
        <Text style={{ fontSize: "20px" }}>
          {metric ? metric.name : "Metric"}
        </Text>
        <br />
        <Text style={{ fontSize: "14px" }}>{providerDescription}</Text>

        <Row style={{ padding: "10px" }}>
          <Row style={{ minheight: "50px", width: "100%" }} align="middle">
            {dataType !== "checkbox" && (
              <Col span={12} style={{ paddingTop: "11px" }}>
                <p>{label}</p>
              </Col>
            )}

            <Col span={dataType === "checkbox" ? 24 : 12}>
              <TypeBasedInput
                metric={metric}
                dataType={dataType}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setMetricValue={setMetricValue}
                options={userSelections}
              />
            </Col>
          </Row>
        </Row>
        <Divider />
        <Row styles={{ marginTop: "0.3em" }}>
          <Col span={24}>
            <Text>Add People Who</Text>
            <div style={{ width: "100%", marginTop: "0.5em" }}>
              <Select
                defaultValue=""
                loading={loading}
                value={matchCharacteristics}
                disabled={loading}
                style={{ width: "100%" }}
                onChange={(_, val) => {
                  handleMenuClick(val.value);
                }}
                options={
                  dataType === "number" || dataType === "date"
                    ? matchOptions
                    : matchOptions.slice(0, 2)
                }
              />
            </div>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: "0.5em" }} gutter={4}>
          <Col>
            <Form.Item>
              <Button
                loading={loading}
                disabled={
                  loading ||
                  metricValue == null ||
                  (metricValue.value === null && dataType !== "boolean") ||
                  (metricValue.value === 0 && dataType !== "boolean") ||
                  (matchCharacteristics == null && dataType !== "boolean") ||
                  inputValue === null
                }
                onClick={async () => {
                  if (validateInput()) {
                    const { success, uniqueId } = await updateAudienceMetrics({
                      matchCharacteristics,
                      dataPoint: metricValue,
                      addAsNewCard,
                    });

                    if (success) {
                      handleSelectedMetric({
                        ...metricValue,
                        uniqueId,
                        operator: matchCharacteristics,
                      });
                      setAddAsNewCard(false);
                    }
                  }
                }}
              >
                {metricValue && metricValue.uniqueId != null
                  ? "Update Audience"
                  : "Add Audience"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AudienceDataPointInput;
