export const columnsObj = {
  name: {
    title: "Campaign",
    width: 240,
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  status: {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
  },
  daysIntoFlight: {
    title: "Days Into Flight",
    dataIndex: "daysIntoFlight",
    key: "daysIntoFlight",
    width: 120,
  },
  daysLeft: {
    title: "Days Left",
    dataIndex: "daysLeft",
    key: "daysLeft",
    width: 120,
    sorter: true,
  },
  expectedImpressions: {
    title: "Expected Imps",
    dataIndex: "expectedImpressions",
    key: "expectedImpressions",
    width: 120,
  },
  actualImpressions: {
    title: "Actual Imps",
    dataIndex: "actualImpressions",
    key: "actualImpressions",
    width: 120,
  },
  impressionsLeft: {
    title: "Imps Left",
    dataIndex: "impressionsLeft",
    key: "impressionsLeft",
    width: 120,
  },
  impressionsYesterday: {
    title: "Imps Yesterday",
    dataIndex: "impressionsYesterday",
    key: "impressionsYesterday",
    width: 120,
  },
  dailyAvgImpressionsLeft: {
    title: "Daily Avg Left",
    dataIndex: "dailyAvgImpressionsLeft",
    key: "dailyAvgImpressionsLeft",
    width: 120,
  },
  roas: {
    title: "ROAS",
    dataIndex: "roas",
    key: "roas",
    width: 120,
  },
  ctr: {
    title: "CTR",
    dataIndex: "ctr",
    key: "ctr",
    width: 120,
  },
  pace: {
    title: "Pace (All-time)",
    dataIndex: "pace",
    key: "pace",
    width: 80,
    sorter: true,
  },
  paceYesterday: {
    title: "Pace (Yesterday)",
    dataIndex: "paceYesterday",
    key: "paceYesterday",
    width: 80,
  },
  paceTwoDaysAgo: {
    title: "Pace (2 days ago)",
    dataIndex: "paceTwoDaysAgo",
    key: "paceTwoDaysAgo",
    width: 80,
  },
  paceThreeDaysAgo: {
    title: "Pace (3 days ago)",
    dataIndex: "paceThreeDaysAgo",
    key: "paceThreeDaysAgo",
    width: 80,
  },
  paceFourDaysAgo: {
    title: "Pace (4 days ago)",
    dataIndex: "paceFourDaysAgo",
    key: "paceFourDaysAgo",
    width: 80,
  },
  paceFiveDaysAgo: {
    title: "Pace (5 days ago)",
    dataIndex: "paceFiveDaysAgo",
    key: "paceFiveDaysAgo",
    width: 80,
  },
  paceSixDaysAgo: {
    title: "Pace (6 days ago)",
    dataIndex: "paceSixDaysAgo",
    key: "paceSixDaysAgo",
    width: 80,
  },
  paceSevenDaysAgo: {
    title: "Pace (7 days ago)",
    dataIndex: "paceSevenDaysAgo",
    key: "paceSevenDaysAgo",
    width: 80,
  },
  Actions: {
    title: "",
    key: "actions",
    width: 80,
  },
};

export const permanentKeys = [columnsObj.pace.key, columnsObj.name.key];

export const defaultColumnKeys = Object.keys(columnsObj);
