import React from "react";

const SeoMainController = ({
  seo,
  childOrgs,
  currentOrgId,
  childOrgsLoading,
  getSharedDetails,
  sharedOrgsLoading,
  sharedOrgsDetails,
  updateSEOSharedDetails,
  orgCustomization,
  userPermission,
  ...props
}) => {
  let seoCampaigns = seo;
  const currentOrg =
    childOrgs && childOrgs.find(org => org.id === currentOrgId);
  const isChannelPartner = currentOrg && currentOrg.level === "CHANNEL_PARTNER";
  const isShowOnboardingDocs = userPermission.isAdmin || isChannelPartner;

  //When Demo is toggle don't change customer data so only show Expample Name Placeholder
  if (userPermission.isDemo) {
    seoCampaigns = seo.map((c, i) => {
      c.org.name = `Example SEO ${i}`;
      return c;
    });
  }

  const onSubmitSharedModal = async (toBeSavedData, sharedOrgs) => {
    await Promise.all(
      toBeSavedData.map(async paid => {
        let connectIds = [];
        let disconnectIds = [];
        const ids = paid.values;
        const thisSharedOrg = sharedOrgs.find(o => o.id === paid.id).sharedOrgs;
        const thisSharedOrgIds = thisSharedOrg.map(s => s.id);
        ids.forEach(id => {
          if (!thisSharedOrgIds.includes(id)) connectIds.push({ id });
        });
        thisSharedOrgIds.forEach(id => {
          if (!ids.includes(id)) disconnectIds.push({ id });
        });

        return await updateSEOSharedDetails({
          variables: {
            id: paid.id,
            connect: connectIds,
            disconnect: disconnectIds,
          },
        });
      })
    );
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    seoCampaigns,
    isShowOnboardingDocs,
    childOrgsLoading,
    getSharedDetails,
    childOrgs,
    sharedOrgsLoading,
    sharedOrgsDetails,
    currentOrgId,
    onSubmitSharedModal,
    orgCustomization,
  });
};

export default SeoMainController;
