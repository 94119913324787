import React from "react";
import { Switch, Route } from "react-router-dom";

import Loader from "../../../../core/components/billing";

const PaymentRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route path={`${path}`} component={Loader} />
      </Switch>
    </div>
  );
};

export default PaymentRoutes;
