import gql from "graphql-tag";
const PAID_SEARCH_GOOGLE = gql`
  query paidSearchSummaryGoogle(
    $id: ID!
    $first: Int
    $skip: Int
    $orderBy: PaidSearchCampaignOrderByInput!
    $where: PaidSearchCampaignWhereInput!
  ) {
    paidSearchSummary(where: { id: $id }) {
      id
      startDate
      endDate
      overallData {
        id
        impressions
        clicks
        cost
        revenue
        cpc
        cpm
        spend
        ctr
        roas
        conversions
        rawCost
        interactionRate
        conversionRate
        phoneCalls
        byDate(orderBy: date_ASC) {
          keywords
          locations
          conversions
          clicks
          impressions
          ctr
          cost
          cpc
          cpm
          spend
          revenue
          roas
          conversionRate
          date
          xAxis
        }
      }
      overallLocations {
        id
        revenue
        byDate(orderBy: date_ASC) {
          revenue
          date
          location
        }
        byDay(orderBy: date_ASC) {
          revenue
          cost
          conversions
          roas
          date
          location
        }
      }
      paidSearchCampaigns(
        first: $first
        skip: $skip
        orderBy: $orderBy
        where: $where
      ) {
        id
        name
        startDate
        endDate
        adProviderId
        status
        data {
          id
          impressions
          clicks
          spend
          revenue
          conversions
          byDate(orderBy: date_ASC) {
            impressions
            clicks
            spend
            revenue
            conversions
            date
            xAxis
          }
        }
      }
      config {
        locationsKey
      }
    }
  }
`;

export default PAID_SEARCH_GOOGLE;
