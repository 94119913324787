import gql from "graphql-tag";

const GET_CHATWOOT_ID = gql`
  query getChatWootId($orgId: ID!) {
    org(where: { id: $orgId }) {
      id
      name
      chatWootId
    }
  }
`;

export default GET_CHATWOOT_ID;
