/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { Row, Col, Space, Typography, Card } from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { AreaChartOutlined } from "@ant-design/icons";
import CustomRangePicker from "../../../shared/dates/CustomRangePicker";
import RollUpDisplay from "./display";
import RollUpPaidSearch from "./paid-search";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../core/utils/campaigns";
import RollUpSEO from "./SEO";
// import SelectAdvertiser from "./components/SelectAdvertiser";
import RollUpSelector from "./components/RollUpSelector";
import AdvertiserFilter from "../../../shared/AdvertiserFilter";
import "./styles/index.css";
import "../../../shared/globalStyling/responsive.css";
import { orgLevelCustomization } from "../../../../core/permissions/orgLevelCustomization";

const RollUpMainView = ({
  startDateEndDate,
  setStartDateEndDate,
  dateRangeKey,
  setDateRangeKey,
  loadingOrgsList,
  orgsList,
  filteredOrg,
  setFilteredOrg,
  setLoadProgress,
  loadProgress,
  totals,
  setTotals,
  startDate,
  endDate,
  productOn,
  setProductOn,
  userPermission,
  hasFeaturePaidSearch,
  hasFeatureSEO,
  currentUser,
}) => {
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat, roasFormat } = orgCustomization;
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Performance"}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <>
            <Row
              justify={"center"}
              gutter={[
                { md: 8, lg: 0 },
                { xs: 8, md: 8, lg: 0 },
              ]}
              id="roll-up-filters"
            >
              <Col xs={24} lg={4} md={5}>
                <RollUpSelector
                  productOn={productOn}
                  setProductOn={setProductOn}
                  hasFeaturePaidSearch={hasFeaturePaidSearch}
                  hasFeatureSEO={hasFeatureSEO}
                />
              </Col>
              <Col xs={24} lg={9} md={19}>
                {orgsList && (
                  <AdvertiserFilter isDemo={userPermission.isDemo} />
                )}
              </Col>
              <Col xs={24} lg={11}>
                <Row wrap={true}>
                  <CustomRangePicker
                    value={startDateEndDate}
                    defaultValue={startDateEndDate}
                    onChange={setStartDateEndDate}
                    rangeKey={dateRangeKey}
                    setRangeKey={setDateRangeKey}
                  />
                </Row>
              </Col>
            </Row>
          </>
        }
      />
      <PageContentLayout>
        <Space size={16} style={{ width: "100%" }} direction="vertical">
          <Row gutter={[16, 16]}>
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Card title="Impressions Served">
                <Typography>
                  {totals.impressions
                    ? formatNumber(totals.impressions)
                    : "N/A"}
                </Typography>
              </Card>
            </Col>
            {userPermission && userPermission.priceVisible && (
              <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                <Card title="Budget Spent">
                  <Typography>
                    {totals.spend
                      ? formatCurrency2SigFig(totals.spend, currencyFormat)
                      : "N/A"}
                  </Typography>
                </Card>
              </Col>
            )}
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Card title="Revenue">
                <Typography>
                  {totals.revenue
                    ? formatCurrency2SigFig(totals.revenue, currencyFormat)
                    : "N/A"}
                </Typography>
              </Card>
            </Col>
            {userPermission && userPermission.priceVisible && (
              <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                <Card title="ROAS">
                  <Typography>
                    {totals.roas
                      ? formatNumber3SigFig(totals.roas) + "%"
                      : "N/A"}
                  </Typography>
                </Card>
              </Col>
            )}
          </Row>
          {productOn.display && (
            <Row gutter={[16, 16]}>
              <Col flex="auto">
                <RollUpDisplay
                  productOn={productOn}
                  setTotals={setTotals}
                  totals={totals}
                  startDate={startDate}
                  endDate={endDate}
                  loadProgress={loadProgress}
                  setLoadProgress={setLoadProgress}
                  currencyFormat={currencyFormat}
                />
              </Col>
            </Row>
          )}
          {productOn.sem && (
            <Row gutter={[16, 16]}>
              <Col flex="auto">
                <RollUpPaidSearch
                  productOn={productOn}
                  setTotals={setTotals}
                  totals={totals}
                  startDate={startDate}
                  filteredOrg={filteredOrg}
                  endDate={endDate}
                  loadProgress={loadProgress}
                  setLoadProgress={setLoadProgress}
                  currencyFormat={currencyFormat}
                  roasFormat={roasFormat}
                />
              </Col>
            </Row>
          )}
          {productOn.seo && (
            <Row gutter={[16, 16]}>
              <Col flex="auto">
                <RollUpSEO
                  productOn={productOn}
                  setTotals={setTotals}
                  totals={totals}
                  startDate={startDate}
                  endDate={endDate}
                  loadProgress={loadProgress}
                  setLoadProgress={setLoadProgress}
                  currencyFormat={currencyFormat}
                />
              </Col>
            </Row>
          )}
        </Space>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default RollUpMainView;
