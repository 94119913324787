import gql from "graphql-tag";
const GET_ORG_REPORT = gql`
  query getOrgReportSignedURL($reportId: ID!) {
    getOrgReportSignedURL(reportId: $reportId) {
      url
      expiresAt
      error
    }
  }
`;

export default GET_ORG_REPORT;
