import { Table } from "antd";
import moment from "moment";
import React from "react";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatWholeNumber,
} from "../../../../../../core/utils/campaigns";
import { PrimaryTableRowText } from "../../../../../shared/globalStyling/styledText";

const SEOTable = ({ data, currencyFormat }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "xAxis",
      key: "xAxis",
      sorter: (a, b) =>
        moment(new Date(a.xAxis)).unix() - moment(new Date(b.xAxis)).unix(),
      defaultSortOrder: "descend",
      render: text => {
        return (
          <PrimaryTableRowText
            text={moment(text).format("YYYY-MM-DD") || "N/A"}
          />
        );
      },
    },
    {
      title: "Sessions",
      dataIndex: "session",
      key: "session",
      sorter: (a, b) => Number(a.session) - Number(b.session),
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text) || 0} />;
      },
    },
    {
      title: "New Users",
      dataIndex: "newUsers",
      key: "newUsers",
      sorter: (a, b) => Number(a.newUsers) - Number(b.newUsers),
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text) || 0} />;
      },
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      sorter: (a, b) => Number(a.revenue) - Number(b.revenue),
      render: text => {
        return (
          <PrimaryTableRowText
            text={`${formatCurrency2SigFig(text, currencyFormat) || 0}`}
          />
        );
      },
    },
    {
      title: "Online Orders",
      dataIndex: "transactions",
      key: "transactions",
      sorter: (a, b) => Number(a.transactions) - Number(b.transactions),
      render: text => {
        return <PrimaryTableRowText text={`${formatNumber(text) || 0}`} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        pagination={{ position: ["none", "bottomCenter"] }}
        dataSource={data}
        scroll={{ x: 1200 }}
      />
    </React.Fragment>
  );
};

export default SEOTable;
