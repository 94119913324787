import gql from "graphql-tag";

const ADD_SHARING_RECORD_WITH_SEGMENTS = gql`
  mutation addSharingRecordWithSegments(
    $buyer_member_id: Int!
    $segment_exposure: String!
    $segment_id: [ById!]
  ) {
    createSharingRecord(
      data: {
        buyer_member_id: $buyer_member_id
        segment_exposure: $segment_exposure
        segments: $segment_id
      }
    ) {
      id
      data_member_id
      buyer_member_id
      segment_exposure
      segments
      error
    }
  }
`;

export default ADD_SHARING_RECORD_WITH_SEGMENTS;
