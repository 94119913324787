import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

export const OrgSelect = ({
  orgId,
  onChange,
  advertiser,
  style = { width: "75%", margin: "10px 0 10px 0" },
  subOrgsLoading = false,
  subOrgsData = [],
  isLive,
  ...props
}) => {
  const getAdId = advertiser && advertiser.id;

  return (
    <Select
      value={advertiser && advertiser.name}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      onChange={value => {
        if (onChange && value !== getAdId) {
          const selectedOrg =
            !isEmpty(subOrgsData) && subOrgsData.find(org => org.id === value);
          if (selectedOrg) onChange(selectedOrg);
        }
      }}
      loading={subOrgsLoading}
      style={style}
      disabled={isLive}
      {...props}
    >
      {isEmpty(subOrgsData) ? (
        <Select.Option key={getAdId} value={getAdId}>
          {advertiser.name}
        </Select.Option>
      ) : (
        subOrgsData.map(org => (
          <Select.Option key={org.id} value={org.id}>
            {org.name}
          </Select.Option>
        ))
      )}
    </Select>
  );
};

OrgSelect.propTypes = {
  orgId: PropTypes.string,
  onChange: PropTypes.func,
  advertiser: PropTypes.object,
};
