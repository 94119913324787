import gql from "graphql-tag";

const LIST_LOCATIONS_ADMIN = gql`
  query listLocations {
    locations(first: 1000, orderBy: updatedAt_DESC) {
      id
      name
      description
      street
      city
      state
      province
      lat
      lng
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
    }
  }
`;

export default LIST_LOCATIONS_ADMIN;
