import React from "react";
import { Button } from "antd";

const TagActions = ({ generateSnippet, reset, generatedTag }) => {
  return !generatedTag ? (
    <Button type="primary" onClick={generateSnippet}>
      Generate
    </Button>
  ) : (
    <Button onClick={reset}>Reset</Button>
  );
};

export default TagActions;
