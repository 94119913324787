import {
  currencyFormat,
  defaultCurrencyFormat,
  defaultRoasFormat,
  roasFormat,
} from "../utils/constants/constants";

//Determine the Self Service Customization that can usually found at Org Config.
export const orgLevelCustomization = dataConfig => {
  const orgCustomization = {
    roasFormat: defaultRoasFormat,
    currencyFormat: defaultCurrencyFormat,
  };

  if (!dataConfig) {
    return orgCustomization;
  }

  if (dataConfig.roasFormat) {
    const value = roasFormat.find(r => r.key === dataConfig.roasFormat);
    if (value) orgCustomization.roasFormat = value;
  }

  if (dataConfig.currency) {
    const value = currencyFormat.find(
      c => c.key === dataConfig.currency.currency
    );

    if (value) {
      orgCustomization.currencyFormat = value;
      orgCustomization.currencyFormat.conversionValue =
        dataConfig.currency.conversionValue;
    }
  }

  return orgCustomization;
};
