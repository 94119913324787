import React from "react";
import { Layout, BackTop, Affix, Button, Tooltip } from "antd";

// Local imports
import Header from "../../ux/Header";
import Sidebar from "../../ux/Sidebar";
import Footer from "../../ux/Footer";
import { CloseOutlined } from "@ant-design/icons";
import { HasAccess } from "@permify/react-role";

const { Content } = Layout;
const style = {
  layout: {
    background: "#f0f2f5",
    minHeight: "100vh",
    height: "100%",
  },

  widget: {
    position: "absolute",
    marginLeft: "50%",
    marginRight: "50%",
    display: "inline-block",
  },

  showStyle: {
    display: "none",
  },

  footer: { textAlign: "center" },
};

class MainLayout extends React.PureComponent {
  state = {
    collapsed: false,
    direction: "right",
  };

  toggleSideBar = collapsed => {
    this.setState({
      collapsed,
    });
  };

  render() {
    return (
      <Layout>
        {this.props.currentUser.isPreviewOrg ? (
          <div style={{ ...style.widget }}>
            <Affix offsetTop={10}>
              <div style={{ display: "inline-flex" }}>
                <Button
                  style={{ marginLeft: -10 }}
                  type="primary"
                  shape="round"
                >
                  Preview Mode ({this.props.currentUser.role.org.name})
                </Button>
                <Tooltip title={"Revert back to current Org"}>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="danger"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      localStorage.removeItem("userData");
                      window.location.href = `/orgs/${this.props.currentUser.role.org.id}`;
                    }}
                  ></Button>
                </Tooltip>
              </div>
            </Affix>
          </div>
        ) : null}

        {!this.props.currentUser.isPreviewOrg &&
        this.props.currentUser.isDemo ? (
          <div style={{ ...style.widget }}>
            <Affix offsetTop={10}>
              <Button style={{ marginLeft: -10 }} type="primary" shape="round">
                DEMO MODE
              </Button>
            </Affix>
          </div>
        ) : null}

        <Sidebar
          features={this.props.currentUser.features}
          logo={this.props.currentUser.role.org.logo}
          state={this.state}
          toggleSideBar={this.toggleSideBar}
          horizontalNavigation={this.props.horizontalNavigation}
          themeData={this.props.themeData}
          currentUser={this.props.currentUser}
          chatWootId={this.props.chatWootId}
          chatWootLoading={this.props.chatWootLoading}
        />

        <Layout style={style.layout}>
          <Header
            state={this.state}
            toggleSideBar={() => {
              this.toggleSideBar(!this.state.collapsed);
            }}
            accounts={this.props}
          />
          <Content>{this.props.children}</Content>
          <HasAccess permissions={["default", "defaultSSO"]}>
            <Footer />
          </HasAccess>
        </Layout>
        <BackTop id="backtop" />
      </Layout>
    );
  }
}

export default MainLayout;
