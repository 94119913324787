import gql from "graphql-tag";

const EDIT_ALL_TIME_FILTER = gql`
  mutation updateorg($id: ID!, $isAllTimeFiltered: Boolean) {
    updateOrg(
      where: { id: $id }
      data: { isAllTimeFiltered: $isAllTimeFiltered }
    ) {
      id
      name
      isAllTimeFiltered
    }
  }
`;

export default EDIT_ALL_TIME_FILTER;
