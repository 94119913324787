import React, { useState } from "react";
import { Modal, Switch, message, Row } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import Button from "../../../shared/globalStyling/Button";
import { EventTagCard } from "./EventTagCard";

const CampaignTools = props => {
  const { toolsOpen, toggleTools } = props;
  const [testMode, setTestMode] = useState(false);
  const [visible, setVisible] = useState(false);
  const trackerUrlBase = `collector.dmp.cnna.io`;
  const testTrackerUrlBase = `collector.dmp.mediajel.ninja`;
  const hostedTracker = `//dm2q9qfzyjfox.cloudfront.net/sp.js`;

  return (
    <React.Fragment>
      <Button style={{ width: "50px" }} onClick={() => setVisible(!visible)}>
        <ToolOutlined />
      </Button>
      <Modal
        width={800}
        title="Data Tracking Tags"
        visible={toolsOpen === undefined ? visible : toolsOpen}
        onCancel={() => (toggleTools ? toggleTools() : setVisible(!visible))}
        centered
        footer={null}
      >
        <strong>Testing </strong>- use the toggle on the right to generate tags
        that can be sent to the staging collector.
        <div style={{ float: "right" }}>
          <Switch
            onChange={() => {
              setTestMode(!testMode);
              testMode
                ? message.success(
                    "You have switched back to production mode, pixels generated will send events to the datawarehouse"
                  )
                : message.warning(
                    "You have entered test mode, pixels generated will send events to snowplow mini!"
                  );
            }}
          />
        </div>
        <br />
        After testing, you will need to re-generate the tags for production use
        and turn off test mode.
        <div>
          You can access elastic search by visiting - &nbsp;
          <a href="https://search-mj-dmp-es-test-epxbsrhhpuidlirnsbslpkcwuu.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!(_source),index:fa6b7290-8977-11e9-b917-8755acfcdfe8,interval:auto,query:(language:lucene,query:''),sort:!(collector_tstamp,desc))e">
            Kibana
          </a>
        </div>
        <div>
          Reach out to your administrator for elastic search credentials
        </div>
        <br />
        <br />
        <strong>Supported Tags </strong>
        <Row type="flex" justify="center">
          <EventTagCard
            collectorUrl={testMode ? testTrackerUrlBase : trackerUrlBase}
            campaignOrderId={"deprecated"}
            orgId={"loggedinorg-lanchedby"}
            hostedTracker={hostedTracker}
          />
        </Row>
        <br />
        <br />
      </Modal>
    </React.Fragment>
  );
};

export default CampaignTools;
