import React from "react";
import { Card, Typography } from "antd";

import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

const { Title, Text } = Typography;

const UniqueResponseCard = ({ uniqueResponses }) => {
  return (
    <Card
      style={{ width: "100%", borderWidth: "1px 0 1px 1px" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Title style={{ color: "#656565" }}>
        {formatWholeNumber(uniqueResponses)}
      </Title>
      <Text style={{ fontSize: "1.15em", fontStyle: "bold", color: "#bcbdc1" }}>
        Unique Responses
      </Text>
    </Card>
  );
};

export default UniqueResponseCard;
