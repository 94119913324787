import JSZip from "jszip";
import { saveAs } from "file-saver";
import Papa from "papaparse";

export default function exportCampaignTableData(dataExportCampaignTable) {
  const zip = new JSZip();
  const folder = zip.folder("Campaigns-Table-Data");

  const reportData = dataExportCampaignTable.campaignOrdersConnection.edges.map(
    edge => {
      const campaignOrder = edge.node;
      const overallData =
        campaignOrder && campaignOrder.aggregateData
          ? campaignOrder.aggregateData.overallData
          : {};
      const pacingData = campaignOrder.pacingData || {};
      const budgetSpent =
        campaignOrder.cpm * (overallData.aggImpressions / 1000);

      return {
        "Campaign Title": campaignOrder.name,
        "Org Name": campaignOrder.orgs.map(org => org.name).join(", "),
        "Start Date": `${new Date(
          campaignOrder.startDate
        ).toLocaleDateString()}`,
        "End Date": `${new Date(campaignOrder.endDate).toLocaleDateString()}`,
        "No. of Days into flight": Math.max(
          0,
          Math.ceil(
            (new Date(campaignOrder.endDate).getTime() -
              new Date(campaignOrder.startDate).getTime()) /
              (1000 * 60 * 60 * 24) // convert milliseconds to days
          )
        ),
        "Days Remaining": Math.max(
          0,
          Math.ceil(
            (new Date(campaignOrder.endDate).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24) // convert milliseconds to days
          )
        ),
        Budget: campaignOrder.budget,
        CPM: campaignOrder.cpm,
        "Expected Impressions": pacingData.expectedImpressions || 0,
        "Used Impressions": overallData.aggImpressions || 0,
        "Remaining Impressions": pacingData.impressionsLeft || 0,
        Pacing: pacingData.pace || 0,
        "Walk Ins Data": overallData.aggVWI || 0,
        Clicks: overallData.aggClicks || 0,
        CTR: pacingData.ctr || 0,
        Orders:
          overallData &&
          overallData.aggEvents &&
          overallData.aggEvents.transactionsTotalCount
            ? overallData.aggEvents.transactionsTotalCount
            : 0,
        Spend: isNaN(budgetSpent) ? 0 : budgetSpent.toFixed(2),
        Revenue:
          overallData &&
          overallData.aggEvents &&
          overallData.aggEvents.transactionTotalRevenue
            ? overallData.aggEvents.transactionTotalRevenue.toFixed(2)
            : 0,
        ROAS:
          overallData &&
          overallData.aggEvents &&
          overallData.aggEvents.transactionTotalRevenue &&
          budgetSpent !== 0
            ? (
                overallData.aggEvents.transactionTotalRevenue / budgetSpent
              ).toFixed(2) * 100
            : 0,
      };
    }
  );

  const date = new Date();
  const dateString = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;

  folder.file(
    `Campaigns-Table-Data-${dateString}.csv`,
    Papa.unparse(reportData)
  );

  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, `Campaigns Table Data ${dateString}`);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
}
