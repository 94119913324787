import React from "react";
import { withRouter } from "react-router-dom";
// Local Imports
import OrganizationsView from "../../../platform/ux/Orgs/OrganizationsView";
// Utility Imports
import { permissionsFromRoles } from "../../utils/dataManipulating/organization";
import {
  configType,
  permission,
  defaultCPM,
} from "../../utils/constants/constants";

// constants
const { DATACONFIG, CONFIG } = configType;
const { READ, WRITE } = permission;
class Controller extends React.Component {
  state = {
    currentOrg: null,
    currentRole: null,
    selectedOrg: null,
  };
  componentDidMount = () => {
    const {
      match: {
        params: { org },
      },
      currentuser: { role, features },
    } = this.props;
    // NOTE: to have re-usable roles this setup will need to be changed to pull from a single Org attached to the User
    const currentRole = role;
    currentRole.features = features;

    this.onSelectingOrg(currentRole, org);
    // Get current logged in user's org and features
    let currentOrg = this.props.organizations.find(
      org => org.id === currentRole.org.id
    );
    currentOrg = {
      ...currentOrg,
      features: permissionsFromRoles([
        currentOrg.roles.find(role => role.isMaster),
      ]),
    };

    this.setState({
      currentOrg,
      currentRole,
    });
  };

  onSelectingOrg = (currentRole, org) => {
    const { organizations } = this.props;

    let selectedOrg = org
      ? organizations.find(organization => organization.id === org)
      : organizations.find(
          organization => organization.id === currentRole.org.id
        );

    if (selectedOrg) {
      // set a default data for dataConfig
      if (selectedOrg.dataConfig === null) {
        selectedOrg.dataConfig = {
          reTargeting: false,
          appIds: [],
          tradeDeskIdentifier: null,
          storageBucket: null,
          googleCustomerId: null,
          defaultDisplayAttributionWindow: null,
          defaultPaidSearchAttributionWindow: null,
          defaultSEOAttributionWindow: null,
          seo: false,
          semrushEnable: false,
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
          googleAnalytics: {
            viewId: null,
            isGA4: null,
          },
          paidSearchDataConfig: {
            isEnabled: null,
          },
          tagProcessingConfig: {
            processTag: null,
          },
        };
      }

      if (selectedOrg.dataConfig.googleAnalytics === null) {
        selectedOrg.dataConfig.googleAnalytics = { viewId: null };
      }

      if (selectedOrg.dataConfig.semrushConfig == null) {
        selectedOrg.dataConfig.semrushConfig = {
          projectId: null,
          rootDomainUrl: null,
          rootKeyword: null,
          competitor1stRootDomainUrl: null,
          competitor2ndRootDomainUrl: null,
        };
      }

      if (selectedOrg.dataConfig.paidSearchDataConfig === null) {
        selectedOrg.dataConfig.paidSearchDataConfig = { isEnabled: false };
      }

      if (selectedOrg.dataConfig.tagProcessingConfig === null) {
        selectedOrg.dataConfig.tagProcessingConfig = { isEnabled: false };
      }
    }

    const selectedOrgWithFeatures = selectedOrg
      ? {
          ...selectedOrg,
          features: permissionsFromRoles([
            selectedOrg.roles.find(role => role.isMaster),
          ]),
        }
      : null;
    this.setState({
      selectedOrg: selectedOrgWithFeatures,
    });
  };

  onChange = (key, value, type) => {
    let updatedOrg = { ...this.state.selectedOrg };
    if (type === "semrushConfig") {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      } else if (!updatedOrg.dataConfig.semrushConfig) {
        let data = updatedOrg.dataConfig;
        updatedOrg.dataConfig = {
          ...data,
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      updatedOrg.dataConfig.semrushConfig[key] = value.trim();

      this.setState({
        selectedOrg: updatedOrg,
      });
    }

    if (type === "googleAnalytics") {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {
          googleAnalytics: {
            viewId: null,
            isGA4: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      } else if (!updatedOrg.dataConfig.googleAnalytics) {
        let data = updatedOrg.dataConfig;
        updatedOrg.dataConfig = {
          ...data,
          googleAnalytics: {
            viewId: null,
            isGA4: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }
      updatedOrg.dataConfig.googleAnalytics[key] =
        key[0] === "viewId" ? value.trim() : value;

      this.setState({
        selectedOrg: updatedOrg,
      });
    }

    if (type === "bing") {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {
          bing: {
            accountId: null,
            customerId: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      } else if (!updatedOrg.dataConfig.bing) {
        let data = updatedOrg.dataConfig;
        updatedOrg.dataConfig = {
          ...data,
          bing: {
            accountId: null,
            customerId: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      updatedOrg.dataConfig.bing[key] = value.trim();

      this.setState({
        selectedOrg: updatedOrg,
      });
    }
    if (type === "paidSearchDataConfig") {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {
          paidSearchDataConfig: {
            isEnabled: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      } else if (!updatedOrg.dataConfig.paidSearchDataConfig) {
        let data = updatedOrg.dataConfig;
        updatedOrg.dataConfig = {
          ...data,
          paidSearchDataConfig: {
            isEnabled: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });

        updatedOrg.dataConfig.paidSearchDataConfig[key] = value;
        this.setState({
          selectedOrg: updatedOrg,
        });
      }
    }

    if (type === "tagProcessingConfig") {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {
          tagProcessingConfig: {
            processTag: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      } else if (!updatedOrg.dataConfig.tagProcessingConfig) {
        let data = updatedOrg.dataConfig;
        updatedOrg.dataConfig = {
          ...data,
          tagProcessingConfig: {
            processTag: null,
          },
        };
        this.setState({
          selectedOrg: updatedOrg,
        });

        updatedOrg.dataConfig.tagProcessingConfig[key] = value;
        this.setState({
          selectedOrg: updatedOrg,
        });
      }
    }

    if (type === DATACONFIG) {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {};
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      if (key[0] === "appIds") {
        updatedOrg.dataConfig[key] = value;
      } else {
        updatedOrg.dataConfig[key] = value.trim();
      }

      this.setState({
        selectedOrg: updatedOrg,
      });
    } else {
      updatedOrg[key] = value;
      this.setState({
        selectedOrg: updatedOrg,
      });
    }
  };

  onSwitchChange = (checked, data, field) => {
    const [feature, permission] = data.split("-");
    const updatedOrg = { ...this.state.selectedOrg };

    if (permission === CONFIG) {
      if (!updatedOrg.config) {
        updatedOrg.config = {};
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      updatedOrg.config[feature] = checked;
    } else if (permission === DATACONFIG) {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {};
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      if (!updatedOrg.dataConfig.tagProcessingConfig) {
        updatedOrg.dataConfig.tagProcessingConfig = {
          processTag: false,
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      if (!updatedOrg.dataConfig.paidSearchDataConfig) {
        updatedOrg.dataConfig.paidSearchDataConfig = {
          isEnabled: false,
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }
      if (field) {
        updatedOrg.dataConfig[feature][field] = checked;
      } else {
        updatedOrg.dataConfig[feature] = checked;
      }
    } else {
      if (checked) {
        updatedOrg.features[feature] = [READ, WRITE];
      } else {
        delete updatedOrg.features[feature];
      }
    }

    this.setState({
      selectedOrg: updatedOrg,
    });
  };

  createOrg = async () => {
    const { createOrg } = this.props;

    const {
      name,
      description,
      level,
      chatWootId,
      cpm,
      website,
      domain,
      logo,
      features,
      parentOrg,
      config,
      dataConfig,
      signInLogo,
      platformDomain,
      tagDomain,
    } = this.state.selectedOrg;

    const roleitems = Object.entries(features).map(([feature, permission]) => {
      const record = {
        feature: feature,
        actions: { set: permission },
      };

      return record;
    });

    if (roleitems.length === 0) {
      let error = "feature in empty";
      throw error;
    }
    // Adds ENABLED feature with READ and WRITE
    roleitems.push({
      feature: "ENABLED",
      actions: { set: ["READ", "WRITE"] },
    });

    const noUrlWebsite = website.replace(/^https?:\/\//i, "");

    const data = {
      name,
      description,
      level,
      chatWootId,
      cpm: cpm.toLocaleString(),
      website: noUrlWebsite,
      domain,
      logo: logo.id,
      features,
      parentOrgId: parentOrg[parentOrg.length - 1],
      parentOrg,
      roleitems,
      config,
      dataConfig,
      signInLogo,
      platformDomain,
      tagDomain,
    };

    const response = await createOrg(data);
    const { status, error } = response;

    if (status === "success") {
      this.setState({
        selectedOrg: {
          ...response.data,
          features: permissionsFromRoles(response.data.roles),
        },
      });
      return;
    } else if (status === "failure") {
      throw error;
    }
  };

  updateOrg = async () => {
    const { editOrg, organizations } = this.props;

    const {
      selectedOrg: {
        name,
        description,
        level,
        chatWootId,
        cpm,
        website,
        domain,
        logo,
        id,
        features,
        roles,
        config,
        dataConfig,
        signInLogo,
        platformDomain,
        tagDomain,
      },
      currentOrg,
    } = this.state;

    const roleitems = Object.entries(features).map(([feature, permission]) => {
      const record = {
        feature: feature,
        actions: { set: permission },
      };

      return record;
    });

    if (roleitems.length === 1) {
      let error = "feature in empty";
      throw error;
    }

    const noUrlWebsite = website.replace(/^https?:\/\//i, "");

    if (!dataConfig.bing) {
      dataConfig.bing = {
        accountId: null,
        customerId: null,
      };
    }

    const data = {
      name,
      description,
      level,
      chatWootId,
      cpm: cpm ? cpm.toLocaleString() : defaultCPM.cpm,
      noUrlWebsite,
      domain,
      logo,
      id,
      features,
      parentOrgId: currentOrg.id,
      roleitems,
      roles,
      defaultOrg: currentOrg.name,
      config,
      dataConfig,
      signInLogo,
      website,
      platformDomain,
      tagDomain,
    };
    const response = await editOrg(data);

    const { status } = response;
    if (status === "success") {
      const updatedOrg = response.data;
      if (updatedOrg.id === this.state.selectedOrg.id) {
        // Once updated performs an update on the isAdmin role's id if still on the same Org's details
        const indexToUpdate = this.state.selectedOrg.roles.findIndex(
          role => role.isMaster
        );
        const updatedRoles = this.state.selectedOrg.roles.slice();
        updatedRoles[indexToUpdate].id = updatedOrg.roles[0].id;
        const indexToUpdateOrg = organizations.findIndex(
          org => org.id === updatedOrg.id
        );
        organizations.splice(indexToUpdateOrg, 1, updatedOrg);
        this.setState({
          selectedOrg: {
            ...this.state.selectedOrg,
            roles: updatedRoles,
          },
        });
        return true;
      }
    } else if (status === "failure") {
      let message = status;
      if (
        response["error"].message ===
        "GraphQL error: Failed to update Org, A unique constraint would be violated on Org. Details: Field name = name"
      ) {
        message = "This name already exists";
      }

      throw message;
    }
  };

  deleteOrg = async () => {
    const { deleteOrg, history } = this.props;
    const { selectedOrg } = this.state;
    const response = await deleteOrg(selectedOrg);
    const { status } = response;
    if (status === "success") {
      this.onSelectingOrg(
        this.state.currentRole,
        this.state.currentRole.org.id
      );
      history.push(`/orgs/${this.state.currentRole.org.id}`);
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the organization has not been deleted"
      );
    }
  };

  disableOrg = async () => {
    const { disableOrg } = this.props;
    const { selectedOrg } = this.state;
    const response = await disableOrg(selectedOrg);
    const { status } = response;

    if (status === "success") {
      let disabledOrg = response.data;
      // set a default data for dataConfig
      if (disabledOrg.dataConfig === null) {
        disabledOrg.dataConfig = {
          reTargeting: false,
          appIds: [],
          tradeDeskIdentifier: null,
          storageBucket: null,
          googleCustomerId: null,
          seo: false,
          semrushEnable: false,
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
          googleAnalytics: {
            viewId: null,
          },
          paidSearchDataConfig: {
            isEnabled: null,
          },
          tagProcessingConfig: {
            processTag: null,
          },
        };
      }

      this.setState({
        selectedOrg: {
          ...disabledOrg,
          features: permissionsFromRoles(disabledOrg.roles),
        },
      });
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the organization has not been disabled"
      );
    }
  };

  enableOrg = async () => {
    const { enableOrg } = this.props;
    const { selectedOrg } = this.state;
    const response = await enableOrg(selectedOrg);
    const { status } = response;

    if (status === "success") {
      let enabledOrg = response.data;
      // set a default data for dataConfig
      if (enabledOrg.dataConfig === null) {
        enabledOrg.dataConfig = {
          reTargeting: false,
          appIds: [],
          tradeDeskIdentifier: null,
          storageBucket: null,
          googleCustomerId: null,
          seo: false,
          semrushEnable: false,
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
          googleAnalytics: {
            viewId: null,
          },
          paidSearchDataConfig: {
            isEnabled: null,
          },
          tagProcessingConfig: {
            processTag: null,
          },
        };
      }

      this.setState({
        selectedOrg: {
          ...enabledOrg,
          features: permissionsFromRoles(enabledOrg.roles),
        },
      });
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the organization has not been enabled"
      );
    }
  };

  clickCreateNewOrg = () => {
    const { selectedOrg } = this.state;
    const parentOrg = selectedOrg.parentOrg.slice();
    parentOrg.push({
      name: selectedOrg.name,
      id: selectedOrg.id,
    });
    this.setState({
      selectedOrg: {
        name: "",
        description: "",
        level: "STANDARD",
        chatWootId: process.env.REACT_APP_CHATWOOT_ID
          ? process.env.REACT_APP_CHATWOOT_ID
          : process.env.REACT_APP_DEFAULT_CHATWOOT_ID,
        cpm: "7",
        logo: {},
        website: "",
        domain: "",
        features: [],
        roles: {},
        parentOrg,
        config: {},
        dataConfig: {
          reTargeting: false,
          appIds: [],
          tradeDeskIdentifier: null,
          storageBucket: null,
          googleCustomerId: null,
          seo: false,
          semrushEnable: false,
          semrushConfig: {
            projectId: null,
            rootDomainUrl: null,
            rootKeyword: null,
            competitor1stRootDomainUrl: null,
            competitor2ndRootDomainUrl: null,
          },
          googleAnalytics: {
            viewId: null,
          },
          paidSearchDataConfig: {
            isEnabled: null,
          },
          tagProcessingConfig: {
            processTag: null,
          },
        },
      },
    });
  };

  onOrgSelect = org => {
    const { match, history } = this.props;

    // set a default data for dataConfig
    if (org.dataConfig === null) {
      org.dataConfig = {
        reTargeting: false,
        appIds: [],
        tradeDeskIdentifier: null,
        storageBucket: null,
        semrushEnable: false,
        semrushConfig: {
          projectId: null,
          rootDomainUrl: null,
          rootKeyword: null,
          competitor1stRootDomainUrl: null,
          competitor2ndRootDomainUrl: null,
        },
        googleCustomerId: null,
        tagProcessingConfig: {
          processTag: false,
        },
        paidSearchDataConfig: {
          isEnabled: false,
        },
      };
    }

    this.setState({
      selectedOrg: {
        ...org,
        features: permissionsFromRoles([org.roles.find(role => role.isMaster)]),
      },
    });
    history.push(`${match.path.replace(/:org\?\/:user\?/gi, org.id)}`);
  };

  render() {
    return (
      <OrganizationsView
        deleteOrg={this.deleteOrg}
        disableOrg={this.disableOrg}
        updateOrg={this.updateOrg}
        createOrg={this.createOrg}
        enableOrg={this.enableOrg}
        currentuser={this.props.currentuser}
        onChange={this.onChange}
        onSwitchChange={this.onSwitchChange}
        clickCreateNewOrg={this.clickCreateNewOrg}
        organizations={this.props.organizations}
        onOrgSelect={this.onOrgSelect}
        selectedOrg={this.state.selectedOrg}
        currentRole={this.state.currentRole}
        currentOrg={this.state.currentOrg}
        match={this.props.match}
        assumeRole={this.props.assumeRole}
        updateOrgSendEmailConfig={this.props.updateOrgSendEmailConfig}
        refetchOrganizations={this.props.refetchOrganizations}
        loadingOrgs={this.props.loadingOrgs}
        dataDealIds={this.props.dataDealIds}
      />
    );
  }
}

export default withRouter(Controller);
