import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse, Divider, Tag, Typography } from "antd";
import React from "react";
import QuickDocs from "../../QuickDocs/QuickDocs";

const { Link } = Typography;

export const PaidSearchLatencyDoc = () => {
  const [isOpen, setIsDocOpen] = React.useState(false);

  const toggleDocs = () => setIsDocOpen(prev => !prev);
  return (
    <>
      <Link onClick={toggleDocs}>Reporting is not real-time</Link>

      <QuickDocs
        title={"Reporting is not real-time"}
        handleClick={toggleDocs}
        open={isOpen}
        onCancel={toggleDocs}
        onOk={toggleDocs}
      >
        Our system processes and consumes data on a scheduled basis rather than
        in real-time. This means that if you compare certain metrics between
        different Paid Search providers (such as Google or Bing), the data
        displayed on our dashboard may not immediately match the data displayed
        on the corresponding Paid Search provider's interface.
        <br />
        <br />
        It's important to note that we consume and process data on a daily
        basis, specifically at <b>12:00 AM midnight UTC</b>. This means that the
        data displayed on our dashboard will be updated daily with the most
        recent data available from each Paid Search provider.
        <br />
        <br />
        <b>Paid Search Providers:</b>
        <ul>
          <li>Google Analytics</li>
          <li>Bing Ads</li>
          <li>Google Ads</li>
        </ul>
      </QuickDocs>
    </>
  );
};

export const PaidSearchOnboardingDocs = () => {
  const [isOpen, setIsDocOpen] = React.useState(false);

  const toggleDocs = () => setIsDocOpen(prev => !prev);
  return (
    <>
      <Tag
        style={{ cursor: "pointer", borderRadius: "100px" }}
        color="blue"
        onClick={toggleDocs}
      >
        <InfoCircleOutlined /> onboarding
      </Tag>

      <QuickDocs
        title={"Paid Search Onboarding"}
        handleClick={toggleDocs}
        open={isOpen}
        onCancel={toggleDocs}
        onOk={toggleDocs}
      >
        <p>
          This documentation is a guide for channel partners who want to onboard
          their sub-organizations' paid search accounts to a platform. By doing
          so, they can report on the paid search provider data on the dashboard.
        </p>
        <p>
          The platform supports three paid search providers: Google Ads, Google
          Analytics, and Bing Ads (Microsoft Ads).
        </p>

        <Divider orientation="left">Instructions</Divider>
        <Collapse size="small">
          <Collapse.Panel header="Google Analytics">
            <ol>
              <li>
                Grant admin access to{" "}
                <Typography.Link>
                  seosemadministrator@mediajel.com
                </Typography.Link>{" "}
                for your sub-org's Google Analytics container.
              </li>
              <li>
                Navigate to your sub-org's{" "}
                <Typography.Link href="\orgs">settings page</Typography.Link>
              </li>
              <li>
                Scroll down to find the <b>Data Config</b> section.
              </li>
              <li>
                Fill in the <b>ViewId</b> field with the correct Google
                Analytics Universal Analytics view id or GA4 view id.
              </li>
              <li>
                Adjust the switch to UA if you provided a Universal analytics
                view id or GA4 if you provided a GA4 view id.
              </li>
              <li>Click on the Update button.</li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Google Ads">
            <ol>
              <li>
                Grant admin access to{" "}
                <Typography.Link>
                  seosemadministrator@mediajel.com
                </Typography.Link>{" "}
                for your sub-org's Google Ads account.
              </li>
              <li>
                Navigate to your sub-org's{" "}
                <Typography.Link href="\orgs">settings page</Typography.Link>.
              </li>
              <li>
                Scroll down to find the <b>Data Config</b> section.
              </li>
              <li>
                Fill in the <b>Google Customer Id</b> field with the correct
                Google Ads Customer Id.
              </li>
              <li>Click on the Update button.</li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Bing Ads">
            <ol>
              <li>
                Grant admin access to{" "}
                <Typography.Link>
                  seosemadministrator@mediajel.com
                </Typography.Link>{" "}
                for your sub-org's Google Ads account.
              </li>
              <li>
                Navigate to your sub-org's{" "}
                <Typography.Link href="\orgs">settings page</Typography.Link>.
              </li>
              <li>
                Scroll down to find the <b>Data Config</b> section.
              </li>
              <li>
                Fill in the Bing <b>Customer Id</b> field and <b>Account Id</b>{" "}
                with the correct values.
              </li>
              <li>Click on the Update button.</li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <p>
          After following the instructions above, the platform will start
          consuming data from the paid search providers after 24 hours.
        </p>
      </QuickDocs>
    </>
  );
};
