//Boiler plate all routes first will received a loader Component
//All necessary assets will be loaded with the HOC Apollo "lodash/flowRight" pattern
import React from "react";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";

//locals core
import LIST_LOCATIONS_ADMIN from "../.././GraphQl/Queries/LIST_LOCATIONS_ADMIN";
import CREATE_LOCATION_ADMIN from "../.././GraphQl/Mutations/CREATE_LOCATION_ADMIN";
import EDIT_LOCATION_ADMIN from "../.././GraphQl/Mutations/EDIT_LOCATION_ADMIN";
import DELETE_LOCATION_ADMIN from "../.././GraphQl/Mutations/DELETE_LOCATION_ADMIN";
//locals platform
import Locations from "../../.././platform/ux/Locations";
import OverlaySpinner from "../../.././platform/shared/OverlaySpinner";

const Loader = props => {
  const { locations } = props;
  if (!locations) {
    return <OverlaySpinner />;
  }
  const data = locations.map((item, i) => {
    const record = {
      ...item,
      key: i,
    };

    return record;
  });

  //--------------------------------------
  const createLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      hours,
      license,
      type,
      orgId,
    } = data;

    try {
      const response = await props.createLocationMutation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          timezone: "N/A",
          hours,
          country: "USA",
          licenseType: license,
          type,
          orgId,
        },
        update: (store, { data: { createLocation } }) => {
          const data = store.readQuery({
            query: LIST_LOCATIONS_ADMIN,
          });
          data.locations.splice(0, 0, createLocation);
          store.writeQuery({
            query: LIST_LOCATIONS_ADMIN,
            data,
          });
        },
      });
      if (response) {
        return {
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const editLocation = async data => {
    const {
      id,
      name,
      description,
      street,
      city,
      lat,
      lng,
      hours,
      license,
      type,
      orgId,
    } = data;

    try {
      const response = await props.editLocationMutation({
        variables: {
          id,
          name,
          description,
          street,
          city,
          state: "CALIFORNIA",
          lat,
          lng,
          timezone: "N/A",
          hours,
          country: "USA",
          licenseType: license,
          type,
          orgId,
        },
      });
      if (response) {
        return {
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const deleteLocation = async Id => {
    try {
      const response = props.deleteLocationMutation({
        variables: {
          Id,
        },
        update: (store, { data: { deleteLocation } }) => {
          const data = store.readQuery({
            query: LIST_LOCATIONS_ADMIN,
          });
          data.locations = data.locations.filter(
            location => location.id !== deleteLocation.id
          );
          store.writeQuery({
            query: LIST_LOCATIONS_ADMIN,
            data,
          });
        },
      });
      if (response) {
        return {
          status: "success",
          error: null,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  return (
    <Locations
      data={data}
      createLocation={createLocation}
      editLocation={editLocation}
      deleteLocation={deleteLocation}
      graphql={props}
      query={LIST_LOCATIONS_ADMIN}
    />
  );
};

export default compose(
  graphql(LIST_LOCATIONS_ADMIN, {
    options: { fetchPolicy: "cache-and-network" },
    props: props => {
      return {
        locations: props.data.locations ? props.data.locations : null,
      };
    },
  }),

  graphql(CREATE_LOCATION_ADMIN, { name: "createLocationMutation" }),
  graphql(EDIT_LOCATION_ADMIN, { name: "editLocationMutation" }),
  graphql(DELETE_LOCATION_ADMIN, { name: "deleteLocationMutation" })
)(Loader);
