import React, { useEffect, useMemo, useState } from "react";
import { useByDateFilter } from "./hooks/ByDateFilter";
import { isEmpty } from "lodash";
import DatePickerField from "./components/DatePickerField";
import { Card, Empty, Row, Select, Spin, Tooltip, Col } from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import { defaultCurrencyFormat } from "../../../core/utils/constants/constants";
import moment from "moment";
import { MenuItemTextColor } from "../globalStyling/styledText";
import { colStyle } from "../styles/chart";

const { Option } = Select;
//Currently only support byDate charts
//TODO: Make this more robust to support other charts
const DualAxisChart = ({
  id, //id of the chart. If this changes, the chart will be re-rendered
  byDate = [], //By Date data for chart
  options = [
    { key: "clicks", display: "Clicks" },
    { key: "impressions", display: "Impressions" },
  ], //Options to be shown on yAxis dropdown
  xAxis = "date", //xAxis field on by date data
  defaultLeftYValue = { key: "clicks", display: "Clicks" }, //default value for left yAxis dropdown
  defaultRightYValue = { key: "impressions", display: "Impressions" }, //default value for right yAxis dropdown
  has6MonDisables = true, //has 6 months disable on date picker
  onDatePickerChange = () => {}, //on date picker change callback
  defaultSummaryDates, //default date range for summary
  title = "Summary", //chart title
  hasTooltip = false, //Show tooltip on right upper side of chart
  tooltip = "", //Tooltip text if enabled
  showDatePicker = true, //Show date picker
  showLeftYAxis = true, //Show left yAxis dropdown
  showRightYAxis = true, //Show right yAxis dropdown
  currencyFormat = defaultCurrencyFormat, //Currency format
  customScale, //Custom scale for chart
  isRightYBar = false, //is right yAxis a bar chart
  loading = false, //Show a loading overlay
  showEmpty = false, //Show empty chart
  showDropdown = true, //Show dropdown on date picker
  allTimeDateRange, //All time date range
  defaultRangeKey, //Default range key
}) => {
  //Hooks for byDate charts
  const {
    rangeKey, //Selected Dropdown Value
    setRangeKey, //Set Dropdown Values on Date Picker
    filteredData, //filtered data based on date range
    startDateEndDate, //Date ranges selected
    customDateRange, //Custom Date Range
    setCustomDateRange, //Custom Date Range not on the dropdown but on the date picker
    emptyData, //No Data found on the selected date ranges
  } = useByDateFilter(byDate, xAxis, id, defaultSummaryDates);

  //Sort by date date properly
  byDate = byDate.sort(
    (a, b) =>
      moment(a[xAxis])
        .utc(true)
        .unix() -
      moment(b[xAxis])
        .utc(true)
        .unix()
  );

  //If no default summary dates, get the first and last date
  if (!defaultSummaryDates && byDate.length > 0) {
    defaultSummaryDates = [
      moment(byDate[0][xAxis]).utc(true),
      moment(byDate[byDate.length - 1][xAxis]).utc(true),
    ];
  }

  //This tracks external changes from the componet. If the byDate changes from the parent, this will re-render the chart.
  //Default Summary Dates and Default Range Key are used to set the date picker to the default values
  useMemo(() => {
    if (byDate.length === 0 && defaultSummaryDates && defaultRangeKey) {
      setCustomDateRange(defaultSummaryDates);
      setRangeKey(defaultRangeKey);
    }
  }, [
    byDate,
    defaultRangeKey,
    defaultSummaryDates,
    setCustomDateRange,
    setRangeKey,
  ]);

  const [leftY, setLeftY] = useState(defaultLeftYValue.key);
  const [rightY, setRightY] = useState(defaultRightYValue.key);
  const [dateChanged, setDateChanged] = useState(false);

  //Filter options based on data on by date. This is to make sure that the options are only those that are available on the data
  const byDateKeys = Object.keys(
    byDate && byDate.length && byDate[0] ? byDate[0] : {}
  );
  let keys = options.filter(option => byDateKeys.includes(option.key));
  let isDropdownDisabled = false;
  let isPickerDisabled = false;
  if (keys.length === 0) {
    keys = options;
    isDropdownDisabled = true;
  } else isDropdownDisabled = false;

  isDropdownDisabled = emptyData ? true : false;
  isPickerDisabled = emptyData ? true : false;

  useEffect(() => {
    if (dateChanged) {
      onDatePickerChange({
        data: filteredData,
        dates: [
          moment(customDateRange[0], "YYYY-MM-DD"),
          moment(customDateRange[1], "YYYY-MM-DD"),
        ],
        rangeKey: rangeKey,
      });
      setDateChanged(false);
    }
  }, [
    dateChanged,
    filteredData,
    onDatePickerChange,
    customDateRange,
    rangeKey,
  ]);

  //Date Picker Field Component
  const DateSelector = () => {
    if (!showDatePicker) return <></>;
    // map xAxis dates
    return (
      <>
        <Row justify={"center"}>
          <DatePickerField
            setCustomDateRange={setCustomDateRange}
            setDateChanged={setDateChanged}
            data={filteredData}
            defaultPickerValue={startDateEndDate}
            defaultValue={startDateEndDate}
            value={startDateEndDate}
            dropDownValue={rangeKey}
            setDropDownValue={setRangeKey}
            has6MonDisables={has6MonDisables}
            showDropdown={showDropdown}
            allTimeDateRange={
              allTimeDateRange ? allTimeDateRange : defaultSummaryDates
            }
            disabled={isPickerDisabled}
          />
        </Row>
      </>
    );
  };

  //Left Y Axis Selector Component
  const LeftYSelector = () => {
    if (!showLeftYAxis) return <></>;
    return (
      <Select
        onSelect={value => setLeftY(value)}
        style={{
          width: "100%",
        }}
        defaultValue={leftY}
        disabled={isDropdownDisabled}
      >
        {keys
          .filter(option => option.key !== rightY) //filter out the rightY value to prevent same values on both dropdowns
          .map(option => {
            return (
              <Option key={option.key} value={option.key}>
                <MenuItemTextColor text={option.display} color={"#7dafff"} />
              </Option>
            );
          })}
      </Select>
    );
  };

  //Right Y Axis Selector Component
  const RightYSelector = () => {
    if (!showRightYAxis) return <></>;
    return (
      <Select
        onSelect={value => setRightY(value)}
        style={{
          width: "100%",
        }}
        defaultValue={rightY}
        disabled={isDropdownDisabled}
      >
        {keys
          .filter(option => option.key !== leftY) //filter out the lefty value to prevent same values on both dropdowns
          .map(option => {
            return (
              <Option key={option.key} value={option.key}>
                <MenuItemTextColor text={option.display} color={"#92d5a7"} />
              </Option>
            );
          })}
      </Select>
    );
  };

  return (
    <Card
      style={colStyle.container}
      bodyStyle={{ padding: 18 }}
      title={title}
      extra={
        hasTooltip ? (
          <Row justify="space-evenly" align="middle" gutter={[16, 16]}>
            <Tooltip
              title={tooltip}
              arrowPointAtCenter={true}
              placement="leftBottom"
            >
              <QuestionCircleOutlined
                type="question-circle"
                style={{ margin: "0 auto" }}
              />
            </Tooltip>
          </Row>
        ) : (
          <></>
        )
      }
    >
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Spin spinning={loading}>
          <Row style={{ marginBottom: "2%" }} justify="space-between">
            <Col xs={24} md={5} lg={4} xl={4}>
              <LeftYSelector />
            </Col>
            <Col xs={24} md={14} lg={10} xl={8}>
              <DateSelector />
            </Col>
            <Col xs={24} md={5} lg={4} xl={4}>
              <RightYSelector />
            </Col>
          </Row>
          {!showEmpty ? (
            <DualAxisAreaChart
              leftY={isEmpty(leftY) ? defaultLeftYValue.key || "" : leftY}
              rightY={isEmpty(rightY) ? defaultRightYValue.key || "" : rightY}
              dualChartData={filteredData}
              currencyFormat={currencyFormat}
              customScale={customScale}
              xAxis={xAxis}
              isRightYBar={isRightYBar}
            />
          ) : (
            <Empty description="No Data To Display" />
          )}
        </Spin>
      </Card>
    </Card>
  );
};

export default DualAxisChart;
