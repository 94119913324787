import { useQuery, useMutation } from "@apollo/react-hooks";
//Queries
import GET_CHAT from "../../../GraphQl/Queries/GET_CHAT";
//Mutations
import SEND_MESSAGE from "../../../GraphQl/Mutations/SEND_CHAT_MESSAGE";
//Subscription
import CHAT_SUBSCRIPTION from "../../../GraphQl/Subscriptions/CHAT_SUBSCRIPTION";
//to be used as id
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import { useEffect, useState } from "react";

//
const useAIChatHandler = props => {
  const [messages, setMessages] = useState([]);
  const { data: currentUserData } = useQuery(CURRENT_USER);
  const user = currentUserData && currentUserData.currentUser.username;

  const [sendMessageMutation] = useMutation(SEND_MESSAGE);

  const { data, subscribeToMore } = useQuery(GET_CHAT, {
    variables: {
      from: user,
    },
  });

  useEffect(() => {
    if (data) setMessages(data.chat);
  }, [data]);

  // Sets up subscription. When a new message is received, the updateQuery function is called. 
  // If the new message is not null, it adds the message to the list of messages and sets the role of the message to "system".
  const subscribeToNewMessages = () =>
    subscribeToMore({
      document: CHAT_SUBSCRIPTION,
      variables: {
        from: user,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.chat) return prev;

        const newMessage = subscriptionData.data.chat.message;
        setMessages([
          ...prev.chat,
          {
            role: "system", // statically define that role is system
            message: newMessage,
          },
        ]);
      },
    });

  // Sends a message using a sendMessageMutation. After sending the message, 
  // it refetches the GET_CHAT query with the updated variables and waits for the refetch to complete before continuing.
  const sendMessage = async message => {
    await sendMessageMutation({
      variables: {
        from: user,
        message,
      },
      refetchQueries: [
        {
          query: GET_CHAT,
          variables: {
            from: user,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return {
    messages,
    sendMessage,
    subscribeToNewMessages,
  };
};

export default useAIChatHandler;
