import React, { useState } from "react";
import {
  Form,
  Col,
  Row,
  Switch,
  Table,
  message,
  Typography,
  Tooltip,
  Spin,
  Card,
} from "antd";
import { cardMargin } from "../../../shared/globalStyling/style";
import GlobalButton from "../../../shared/globalStyling/Button";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import {
  updatePrimaryColor,
  updateSidebarColor,
} from "../../../shared/globalStyling/ColorChanger";

const { Title } = Typography;

const OrgUISettings = ({
  tableConfig, //Values to be displayed on the table Config
  themeConfig, //Values to be displayed on the theme Config color pickers
  onSubmit = () => {}, //Hook for submission of the form
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false); //Set true to show spinner for this card

  //List of table config values to be displayed on the table. If new config is needed add it here.
  //Important note: the key must match the key on the tableConfig object since the same key is used on the form
  const dataTable =
    tableConfig && tableConfig.getOrgTableConfig
      ? [
          {
            key: "isShowStartDate", //Unique key for the table.
            name: "Start Date", //Displayed Name on the table. See `columnsTable` key `name` for reference
            isShow: {
              //Values and key to be displayed on the table. See `columnsTable` key `isShow` for reference
              key: "isShowStartDate", //Key used by the form. This must match the key on `tableConfig`. This is used to update the value on the form as well as the intial values
              value: tableConfig.getOrgTableConfig.isShowStartDate, //Currently unused but added here for future use
            },
          },
          {
            key: "isShowEndDate",
            name: "End Date",
            isShow: {
              key: "isShowEndDate",
              value: tableConfig.getOrgTableConfig.isShowEndDate,
            },
          },
          {
            key: "isShowDaysRemaining",
            name: "Days Remaining",
            isShow: {
              key: "isShowDaysRemaining",
              value: tableConfig.getOrgTableConfig.isShowDaysRemaining,
            },
          },
          {
            key: "isShowImpressions",
            name: "Impressions",
            isShow: {
              key: "isShowImpressions",
              value: tableConfig.getOrgTableConfig.isShowImpressions,
            },
          },
          {
            key: "isShowWalkins",
            name: "Walkins",
            isShow: {
              key: "isShowWalkins",
              value: tableConfig.getOrgTableConfig.isShowWalkins,
            },
          },
          {
            key: "isShowPerformance",
            name: "Performance",
            isShow: {
              key: "isShowPerformance",
              value: tableConfig.getOrgTableConfig.isShowPerformance,
            },
          },
          {
            key: "isShowPacing",
            name: "Pacing",
            isShow: {
              key: "isShowPacing",
              value: tableConfig.getOrgTableConfig.isShowPacing,
            },
          },
          {
            key: "isShowSales",
            name: "Sales",
            isShow: {
              key: "isShowSales",
              value: tableConfig.getOrgTableConfig.isShowSales,
            },
          },
          {
            key: "isShowSignUps",
            name: "SignUps",
            isShow: {
              key: "isShowSignUps",
              value: tableConfig.getOrgTableConfig.isShowSignUps,
            },
          },
          {
            key: "isShowBudget",
            name: "Budget",
            isShow: {
              key: "isShowBudget",
              value: tableConfig.getOrgTableConfig.isShowBudget,
            },
          },
          {
            key: "isShowAttribution",
            name: "Attribution",
            isShow: {
              key: "isShowAttribution",
              value: tableConfig.getOrgTableConfig.isShowAttribution,
            },
          },
        ]
      : [];

  const columnsTable = [
    {
      title: "Column",
      width: "60%",
      dataIndex: "name",
      key: "name",
      render: text => <div>{text}</div>,
    },
    {
      title: "Default",
      width: "15%",
      dataIndex: "isShow",
      key: "isShow",

      render: ({ key }) => (
        <Form.Item name={key} noStyle valuePropName="checked">
          <Switch />
        </Form.Item>
      ),
    },
  ];

  const defaultPrimary = "#1890ff";
  const defaultSidebar = "#001529";

  return (
    <Card style={{ ...cardMargin }} title={"Display Settings"}>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          name="orgUISettingsForm"
          onFinish={async values => {
            setIsLoading(true);
            try {
              await onSubmit(values);
              message.success("Organization Display updated successfully");
            } catch (err) {
              message.error("Error updating organization data");
            }
            setIsLoading(false);
          }}
          initialValues={{
            ...tableConfig.getOrgTableConfig,
            primaryColor:
              themeConfig && themeConfig.getOrgThemeConfig
                ? themeConfig.getOrgThemeConfig.primaryColor
                : defaultPrimary,
            sidebarColor:
              themeConfig && themeConfig.getOrgThemeConfig
                ? themeConfig.getOrgThemeConfig.sidebarColor
                : defaultSidebar,
          }}
        >
          <Row gutter={[18, 18]}>
            <Col xs={24} xl={12}>
              <Title level={5}>
                Campaign Table Columns
                <Tooltip
                  title="Campaign Table's Default Visible Columns"
                  arrowPointAtCenter={true}
                  placement="right"
                >
                  <QuestionCircleOutlined style={{ paddingLeft: "5px" }} />
                </Tooltip>
              </Title>
              <Table
                columns={columnsTable}
                dataSource={dataTable}
                bordered
                pagination={false}
              />
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[18, 18]}>
                <Col xs={24} xl={12}>
                  <Title level={5}>
                    Primary Color
                    <Tooltip
                      title="Button Color"
                      arrowPointAtCenter={true}
                      placement="right"
                    >
                      <QuestionCircleOutlined style={{ paddingLeft: "5px" }} />
                    </Tooltip>
                  </Title>
                  <Form.Item name="primaryColor" noStyle valuePropName="color">
                    <SketchPicker
                      presetColors={["#1890ff", "#25b864", "#ff6f00"]}
                      onChange={({ hex }) => {
                        //Manually set the value to the form since we only needed the hex value
                        form.setFieldsValue({
                          [`primaryColor`]: hex,
                        });
                        //Update the color on the global styling realtime while picking the color
                        updatePrimaryColor(hex);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={12}>
                  <Title level={5}>
                    Sidebar Color
                    <Tooltip
                      title="Sidebar Color"
                      arrowPointAtCenter={true}
                      placement="right"
                    >
                      <QuestionCircleOutlined style={{ paddingLeft: "5px" }} />
                    </Tooltip>
                  </Title>
                  <Form.Item name="sidebarColor" noStyle valuePropName="color">
                    <SketchPicker
                      presetColors={["#001529", "#290015", "#002901"]}
                      onChange={({ hex }) => {
                        //Manually set the value to the form since we only needed the hex value
                        form.setFieldsValue({
                          [`sidebarColor`]: hex,
                        });
                        //Update the color on the global styling realtime while picking the color
                        updateSidebarColor(hex);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <GlobalButton
                    onClick={() => {
                      form.setFieldsValue({
                        [`primaryColor`]: defaultPrimary,
                      });
                      updatePrimaryColor(defaultPrimary);
                      form.setFieldsValue({
                        [`sidebarColor`]: defaultSidebar,
                      });
                      updateSidebarColor(defaultSidebar);
                    }}
                    type="primary"
                  >
                    Reset Color to Default
                  </GlobalButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col style={{ textAlign: "right" }} xs={24}>
            <GlobalButton htmlType="submit" type="primary">
              Update
            </GlobalButton>
          </Col>
        </Form>
      </Spin>
    </Card>
  );
};

export default OrgUISettings;
