import React, { useEffect, useState } from "react";
import SEOAnalyticsController from "./SEOAnalyticsController";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import compose from "lodash/flowRight";
import { graphql, useLazyQuery, useQuery, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import GET_ROLL_UP_SEO_ANALYTICS from "../../../GraphQl/Queries/GET_ROLL_UP_SEO_ANALYTICS";
import moment from "moment";
import { isEmpty } from "lodash";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { featurePermissions } from "../../../utils/general/user";

const SEOAnalyticsLoader = ({ children, history, currentUser, ...props }) => {
  const loggedInRole = currentUser.role;
  const orgId = loggedInRole.org.id;
  const { READ, WRITE } = featurePermissions(loggedInRole, "AUDIENCES");

  const [advertiserIds, setAdvertiserIds] = useState([orgId]);

  const searchParams = new URLSearchParams(props.location.search);
  const advertiserParams = searchParams.get("advertiserId");
  const selectedStartDate = searchParams.get("startDate");
  const selectedEndDate = searchParams.get("endDate");

  const config = currentUser.permission;
  const orgConfig = determineUserAccess(config);

  useEffect(() => {
    if (!advertiserParams) {
      return setAdvertiserIds([orgId]);
    }
    setAdvertiserIds(advertiserParams.split("+"));
  }, [advertiserParams, orgId]);

  const { loading, data, error } = useQuery(GET_ROLL_UP_SEO_ANALYTICS, {
    variables: {
      orgId,
      startDate: selectedStartDate
        ? moment(new Date(selectedStartDate))
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined,
      endDate: selectedEndDate
        ? moment(new Date(selectedEndDate))
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined,
      advertiserIds,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [getPrevRangeData, { data: prevRangeData }] = useLazyQuery(
    GET_ROLL_UP_SEO_ANALYTICS
  );

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = moment(selectedStartDate);
      const endDate = moment(selectedEndDate);

      const diff = endDate.diff(startDate, "days");
      const prevStart = selectedStartDate
        ? moment(startDate)
            .subtract(diff, "days")
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined;
      const prevEnd = selectedEndDate
        ? moment(endDate)
            .subtract(1, "day")
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined;
      getPrevRangeData({
        variables: {
          orgId,
          startDate: prevStart,
          endDate: prevEnd,
          advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
        },
        notifyOnNetworkStatusChange: true,
      });
    }
  }, [
    advertiserIds,
    data,
    selectedEndDate,
    getPrevRangeData,
    orgId,
    selectedStartDate,
  ]);

  if (!data && loading) {
    return <OverlaySpinner />;
  }

  const dataExist = !isEmpty(data) && !isEmpty(data.getRollUpSEOAnalytics);

  return (
    <SEOAnalyticsController
      loading={loading}
      error={error}
      data={dataExist && data.getRollUpSEOAnalytics}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      orgConfig={orgConfig}
      advertiserParams={advertiserParams}
      READ={READ}
      WRITE={WRITE}
      prevRangeData={prevRangeData}
      currentUser={currentUser}
      {...props}
    >
      {children}
    </SEOAnalyticsController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(withApollo(SEOAnalyticsLoader)));
