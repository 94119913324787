import gql from "graphql-tag";

const GET_ROLL_UP_SEO = gql`
  query getRollUpSEO(
    $orgId: String!
    $startDate: DateTime
    $endDate: DateTime
    $advertiserIds: [String]
  ) {
    getRollUpSEO(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      advertiserIds: $advertiserIds
    )
  }
`;

export default GET_ROLL_UP_SEO;
