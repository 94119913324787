import React from "react";
import { useMutation, useQuery } from "react-apollo";
import EVENTS_TARGETS from "../../../GraphQl/Queries/EVENTS_TARGETS";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import TAG_REQUEST_ASSISTANCE from "../../../GraphQl/Mutations/TAG_REQUEST_ASSISTANCE";
import moment from "moment";
import { withRouter } from "react-router-dom";

//This loader handles all reusable queries for SEO
const TagsMainLoader = props => {
  const { currentUser, location } = props;

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const name = params.get("name");

  let disableFilter = { OR: [{ isDisabled: false }, { isDisabled: null }] };
  if (status === "disabled") {
    disableFilter = { isDisabled: true };
  }

  let nameFilter = {};
  if (name) {
    nameFilter = { name_contains: name };
  }

  const currentOrgId = currentUser.role.org.id;
  const { data: dataMultiOrgTags, loading } = useQuery(EVENTS_TARGETS, {
    variables: {
      where: {
        OR: [
          {
            orgs_some: {
              id: currentOrgId,
            },
          },
          {
            orgs_some: {
              parentOrg_some: { id: currentOrgId },
            },
          },
        ],
        AND: [disableFilter, nameFilter],
      },
    },
    fetchPolicy: "no-cache",
  });

  const [requestAssistance] = useMutation(TAG_REQUEST_ASSISTANCE, {
    variables: {
      data: {
        status: "Incoming",
        advertiser: currentUser.role.org.name,
        date: moment(),
      },
    },
  });

  if (loading) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    dataMultiOrgTags,
    requestAssistance,
  });
};

export default withRouter(TagsMainLoader);
