import gql from "graphql-tag";

const CREATE_MANY_CAMPAIGN_CREATIVES = gql`
  mutation updateAdUnit($id: ID!, $data: AdUnitUpdateInput!) {
    updateAdUnit(where: { id: $id }, data: $data) {
      id
      name
      clickthrough
      banners {
        id
        status
        adId
        media {
          id
          key
          name
          height
          width
          url
          size
          fileType
          mediaNotes
          mediaHasNotes
        }
      }
    }
  }
`;

export default CREATE_MANY_CAMPAIGN_CREATIVES;
