/** @jsx jsx */
import { useEffect, useState } from "react";
import { Col, Row, Card, Button } from "antd";
import { FileTextOutlined, RiseOutlined } from "@ant-design/icons";
import moment from "moment";
import { isEmpty } from "lodash";
//local
import BasicsCard from "./components/BasicsCard";
import StepsCard from "./components/StepsCard";
import NavigationButtons from "./components/NavigationButtons";
import CpmCard from "./components/CpmCard";
import { BudgetCard } from "./components/BudgetCard";
import {
  defaultCPM,
  iabCategories,
} from "../../../core/utils/constants/constants";
import SelectorCard from "./components/lineItems/SelectorCard";
import SelectorLineItems from "./components/lineItems/SelectorLineItems";
import { css, jsx } from "@emotion/react";

const responsiveMenu = css`
@media only screen and (max-width: 1920px) {
  .responsive-menu {
    display: block;
  }

  .responsive-menu .span-mobile{
    width: 75% !important;
  }

  @media only screen and (max-width: 768px) {
    .responsive-menu {
      display: block;
    }
    .responsive-menu .span-mobile {
      width: 100% !important;
      margin-bottom: 25px;
    }
    .mobile-nav{
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-bottom: 25px;
    }
}
`;

const styles = {
  rows: {
    marginBottom: "15px",
  },
  card: {
    marginBottom: "20px",
  },
  col: {
    marginTop: "15px",
  },
};

const CampaignLauncherViewBasics = ({
  currentUser,
  campaignOrder,
  handleCpmBlur,
  onChange,
  onStepChange,
  handleUpdateCampaignOrder,
  currentStep,
  campaignName,
  startDate,
  endDate,
  category,
  budget,
  tablet,
  isLive,
  isEdit,
  type,
  advertiser,
  isRegulated,
  cpm,
  changeValue,
  onSubmitLoad,
  campaigns,
  description,
  campaignOrderList,
  campaignOrderListLoading,
  setSearchName,
  onCampaignOrderListChange,
  selectedCampaignListData,
  setIsCloningClicked,
  selectedCampaignLineItemsData,
  selectedCampaignLineItemsLoading,
  currentPagination,
  setPagination,
  pagination,
  total,
  selectedCampaignOrderList,
  onCreateLineItemCampaign,
  urlCampaignOrderID,
  onCreateLineItemCampaignLoading,
  setSearchLineName,
  searchLineName,
  onUpdateCampaignOrderLineItem,
  orgID,
  orgName,
  isUserTrafficker,
  getcpm,
  handleCloneCampaignOrder,
}) => {
  const [isLineItemCreate, setIsLineItemCreate] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const [budgetState, setBudgetState] = useState(budget);
  const [impressions, setImpressions] = useState(0);

  const getCPM = getcpm && getcpm.org && getcpm.org.cpm;

  const [cpmState, setCpmState] = useState(
    cpm ? cpm : getCPM ? parseFloat(getcpm.org.cpm) : defaultCPM.cpm
  );

  // Convert category to array if it's string.
  let arrayCategory = Array.isArray(category) ? category : Array(category);

  // Check if category has a value not.
  const cat = arrayCategory.length ? arrayCategory : [];

  // Get the category name of that category code.
  const foundCategory = cat.length
    ? iabCategories.find(c => c.code === cat[0])
    : null;
  const categoryName = foundCategory ? foundCategory.category : "";

  const currentDateTime = moment()
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  const getStartDate = startDate
    ? moment(startDate)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    : null;
  const getEndDate = endDate
    ? moment(endDate)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0)
    : null;

  //check if live
  let checkIsLive = isLive;
  if (!checkIsLive)
    checkIsLive = currentDateTime.isBetween(getStartDate, getEndDate);

  const steps = [
    {
      title: "Campaign Name",
      descriptionTitle: [`Name:`],
      description: [`${campaignName}`],
      complete: !isEmpty(campaignName),
    },
    {
      title: "Flight Dates",
      descriptionTitle: [`Start Date:`, `End Date:`],
      description: [
        `${
          !isEmpty(startDate) ? moment(startDate).format("MM/DD/YYYY") : "ASAP"
        }`,
        `${
          !isEmpty(endDate)
            ? moment(endDate).format("MM/DD/YYYY")
            : "Continuous"
        }`,
      ],
      complete: !isEmpty(startDate),
    },
    {
      title: "Categories",
      descriptionTitle: [`Categories:`],
      description: [`${cat} - ${categoryName}`],
      complete: !isEmpty(cat),
    },
    {
      title: "Impression Goals",
      descriptionTitle: [`Total:`],
      description: [impressions],
      complete: budgetState > 0 && cpmState > 0,
    },
  ];

  useEffect(() => {
    if (currentUser && currentUser.permission) {
      const { isProcessor, isTrafficker } = currentUser.permission;
      setCanEdit(isProcessor || isTrafficker);
    }
  }, [currentUser]);

  useEffect(() => {
    // Compute impression when there are changes detected in budget and cpm state
    if (isNaN(cpmState)) setCpmState(defaultCPM.cpm);

    setImpressions(
      budgetState && cpmState
        ? Math.ceil((budgetState / cpmState) * 1000).toLocaleString()
        : 0
    );
  }, [budgetState, cpmState]);

  return (
    <Row gutter={12} css={responsiveMenu}>
      {tablet && (
        <Col style={styles.col} span={12} xs={24} className="mobile-nav">
          <StepsCard stepsToComplete={steps} />
        </Col>
      )}
      <Col xs={24} md={24} lg={18}>
        {urlCampaignOrderID === undefined && (
          <Card
            title={
              <Col>
                <FileTextOutlined />
                <span> Campaign Order Assignment </span>
              </Col>
            }
            bodyStyle={{ padding: 0 }}
            style={styles.card}
          >
            <SelectorCard
              campaignOrderList={campaignOrderList}
              campaignOrderListLoading={campaignOrderListLoading}
              setSearchName={setSearchName}
              onCampaignOrderListChange={onCampaignOrderListChange}
              changeValue={changeValue}
              selectedCampaignListData={selectedCampaignListData}
              campaignName={campaignName}
              setIsCloningClicked={setIsCloningClicked}
              defaultAdvertiser={{ id: orgID, name: orgName }}
              handleUpdateCampaignOrder={handleUpdateCampaignOrder}
              handleCloneCampaignOrder={handleCloneCampaignOrder}
            />
          </Card>
        )}
        <Card
          title={
            <Col>
              <FileTextOutlined />
              <span> Details</span>
            </Col>
          }
          bodyStyle={{ padding: 0 }}
          style={styles.card}
          extra={
            <Button
              type="primary"
              disabled={isLineItemCreate}
              loading={onCreateLineItemCampaignLoading}
              onClick={() => {
                onCreateLineItemCampaign();
              }}
            >
              Save Line Item
            </Button>
          }
        >
          {urlCampaignOrderID !== undefined && (
            <SelectorLineItems
              changeValue={changeValue}
              selectedCampaignLineItemsData={selectedCampaignLineItemsData}
              selectedCampaignLineItemsLoading={
                selectedCampaignLineItemsLoading
              }
              selectedCampaignOrderList={selectedCampaignOrderList}
              setIsLineItemCreate={setIsLineItemCreate}
              urlCampaignOrderID={urlCampaignOrderID}
              setIsCloningClicked={setIsCloningClicked}
              currentPagination={currentPagination}
              setPagination={setPagination}
              total={total}
              pagination={pagination}
              setSearchLineName={setSearchLineName}
              searchLineName={searchLineName}
              onUpdateCampaignOrderLineItem={onUpdateCampaignOrderLineItem}
              isLive={isLive}
              canEdit={canEdit}
            />
          )}
          <div className="responsive-menu">
            <BasicsCard
              onChange={onChange}
              campaignName={campaignName}
              startDate={startDate ? startDate : null}
              endDate={endDate ? endDate : null}
              category={cat}
              isLive={isLive}
              isEdit={isEdit}
              type={type}
              currentUser={currentUser}
              advertiser={advertiser}
              isRegulated={isRegulated}
              description={description}
              campaignOrder={campaignOrder}
              canEdit={canEdit}
              isTrafficker={isUserTrafficker}
            />
          </div>
        </Card>
        <Row style={styles.rows} className="responsive-menu">
          <Col span={24}>
            <BudgetCard
              budget={budget}
              onChange={onChange}
              border={true}
              campaigns={campaigns}
              tablet={tablet}
              currentUser={currentUser}
              isLive={isLive}
              canEdit={canEdit}
              setBudgetState={setBudgetState}
            />
          </Col>
        </Row>

        <Row style={styles.rows} className="responsive-menu">
          <Col span={24}>
            {/** <LineItemCPMCard
                campaignOrder={
                  campaignOrder ? campaignOrder : { name: campaignName, id: "" }
                }
                handleCpmBlur={handleCpmBlur}
                changeValue={changeValue}
              /> **/}

            <CpmCard
              isUserTrafficker={isUserTrafficker}
              changeValue={changeValue}
              cpm={cpmState}
              getCPM={getCPM}
              isLive={isLive}
              canEdit={canEdit}
              defaultCPM={defaultCPM}
              setCpmState={setCpmState}
              onChange={onChange}
            />
          </Col>
        </Row>

        <Row style={styles.row}>
          <Col span={24}>
            <Card
              bordered={true}
              title={
                <Col>
                  <RiseOutlined />
                  <span> Impressions</span>
                </Col>
              }
            >
              <div style={{ padding: 0, backgroundColor: "#fff" }}>
                {impressions}
              </div>
            </Card>
          </Col>
        </Row>

        {tablet && (
          <Col style={styles.col} span={12} xs={24} className="mobile-nav">
            <NavigationButtons
              onStepChange={onStepChange}
              handleUpdateCampaignOrder={handleUpdateCampaignOrder}
              disabled={isEmpty(campaignName) || isEmpty(cat)}
              currentStep={currentStep}
              isEdit={isEdit}
              onChange={onChange}
              onSubmitLoad={onSubmitLoad}
              isLive={isLive}
              canEdit={canEdit}
            />
          </Col>
        )}
      </Col>
      {!tablet && (
        <Col xs={24} md={24} lg={6}>
          <StepsCard stepsToComplete={steps} />
          <NavigationButtons
            onStepChange={onStepChange}
            handleUpdateCampaignOrder={handleUpdateCampaignOrder}
            disabled={isEmpty(campaignName)}
            currentStep={currentStep}
            isLive={isLive}
            isEdit={isEdit}
            complete={steps.complete}
            onChange={onChange}
            onSubmitLoad={onSubmitLoad}
            canEdit={canEdit}
          />
        </Col>
      )}
    </Row>
  );
};

export default CampaignLauncherViewBasics;
