import React from "react";
import { Card, Tabs, DatePicker, Row, Empty } from "antd";
import MultiSourceChart from "./components/MultiSourceChart";
import { TabText } from "../../../../../shared/globalStyling/styledText";
import moment from "moment";
import { isEmpty } from "lodash";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const LocationChart = ({ data, onChangeMonthRange, currencyFormat }) => {
  const { byDate, byDay } = data;
  if (byDate.length < 1)
    return (
      <Card title={"Revenue by Location"}>
        <Empty />
      </Card>
    );
  const getColors = () => {
    const locations = byDate.reduce((prev, curr) => {
      if (!prev.includes(curr.location)) {
        prev.push(curr.location);
      }
      return prev;
    }, []);
    const colors = locations.map(() => {
      const h = Math.floor(Math.random() * (360 - 0 + 1) + 0);
      const s = 47;
      let l = 68;

      l /= 100;
      const a = (s * Math.min(l, 1 - l)) / 100;
      const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, "0"); // convert to Hex and prefix "0" if needed
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    });
    return colors;
  };
  const colors = getColors();
  const getTodaysDate = moment(new Date());
  const defaultValue = [
    moment(!isEmpty(byDate) ? byDate[0].date : getTodaysDate),
    moment(!isEmpty(byDate) ? byDate.at(-1).date : getTodaysDate),
  ];

  return (
    <Card title={"Revenue by Location"}>
      <Tabs>
        {byDay && byDay.length > 0 && (
          <TabPane tab={<TabText text={"By Date"} />} key="byDate">
            <MultiSourceChart
              data={byDay}
              colors={colors}
              isByDay={true}
              currencyFormat={currencyFormat}
            />
          </TabPane>
        )}
        {byDate && byDate.length > 0 && (
          <TabPane tab={<TabText text={"By Month"} />} key="byMonth">
            <Row align="middle" justify="center">
              <RangePicker
                picker="month"
                value={defaultValue}
                onChange={date => {
                  onChangeMonthRange([date[0].utc(), date[1].utc()]);
                }}
              />
            </Row>
            <MultiSourceChart data={byDate} colors={colors} />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default LocationChart;
