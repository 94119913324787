import { useContext } from "react";
import TargetingContext from "./TargetingProvider";

export const useTargetingContext = () => {
  const context = useContext(TargetingContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
