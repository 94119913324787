import React from "react";
import { Card, Row, Spin } from "antd";
import BasketItemsLayout from "./components/BasketItemsLayout";

const LoadingCard = () => (
  <Card>
    <Row type="flex" justify="center" align="middle">
      <Spin size="large" />
    </Row>
  </Card>
);

const BasketItemsTab = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  campaignOrder,
  chartData,
  bySignUpRangeDate,
  setSignUpRangeDate,
  dataSignUpRangeDate,
  setDataSignUpRangeDate,
  setbySignUpsAttributionWindow,
  setdataSignUpsDate,
  bySignUpsAttributionWindow,
  dataSignUpsDate,
  reportName,
  attributionWindow,
  setAttributionWindow,
  searchTerm,
  setSearchTerm,
  campaignOrderId,
  currentUser,
}) => {
  if (chartsLoading) {
    return <LoadingCard />;
  }

  return (
    <BasketItemsLayout
      colStyle={colStyle}
      selectedCampaign={selectedCampaign}
      campaignOrder={campaignOrder}
      chartData={chartData}
      bySignUpRangeDate={bySignUpRangeDate}
      setSignUpRangeDate={setSignUpRangeDate}
      dataSignUpRangeDate={dataSignUpRangeDate}
      setDataSignUpRangeDate={setDataSignUpRangeDate}
      setbySignUpsAttributionWindow={setbySignUpsAttributionWindow}
      setdataSignUpsDate={setdataSignUpsDate}
      bySignUpsAttributionWindow={bySignUpsAttributionWindow}
      dataSignUpsDate={dataSignUpsDate}
      permission={currentUser.permission}
      reportName={reportName}
      attributionWindow={attributionWindow}
      setAttributionWindow={setAttributionWindow}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      campaignOrderId={campaignOrderId}
    />
  );
};

export default BasketItemsTab;
