/** @jsx jsx */
import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Tooltip,
  Popconfirm,
  Select,
  Form,
  Space,
  Spin,
  message,
  DatePicker,
} from "antd";
import {
  CloudUploadOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  FileImageOutlined,
  LayoutOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import DragUploader from "./components/DragUploader";
import BannersUploadTable from "./components/BannersUploadTable";
import BannerUploader from "./components/BannerUploader";
import StepsCard from "./components/StepsCard";
import NavigationButtons from "./components/NavigationButtons";
import { ButtonText } from "../../shared/globalStyling/styledText";
import { css, jsx } from "@emotion/react";
import RequiredLabel from "../../../core/components/utils/RequiredLabel";
import { getImageDimensionsByURL } from "../../../core/utils/general/image";
import { multiImageZip } from "../../../core/utils/dataloading/multiImageZip";
import moment from "moment";

const responsiveMenu = css`
@media only screen and (max-width: 1920px) {
  .responsive-menu {
    display: block;
  }
  .responsive-menu .span-mobile{
    width: 75% !important;
  }
  @media only screen and (max-width: 768px) {
    .responsive-menu {
      display: block;
    }
    .responsive-menu .span-mobile {
      width: 100% !important;
      margin-bottom: 25px;
    }
    .mobile-nav{
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-bottom: 25px;
      margin-left: 0px;
    }
    .mobile-row{
      display:block
    }
}
`;

const Frag = React.Fragment;
const { Text, Title } = Typography;
const styles = {
  row: {
    marginBottom: "15px",
  },
  rowTwo: {
    width: "100%",
  },
  internalRow: {
    marginBottom: "50px",
  },
  title: {
    color: "#636363",
    marginBottom: "10px",
    fontWeight: "normal",
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
  div: {
    paddingBottom: "5px",
  },
  col: {
    marginTop: "5px",
  },
  cardBody: {
    padding: 0,
  },
  clickThroughSubtitle: {
    margin: "0px auto",
    paddingTop: "5px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
};

const BannerUploaderButton = ({
  onChangeArrayOfObj,
  adUnit,
  index,
  removeBannerMultiple,
  onChange,
  handleUpdateCampaignOrder,
  bulkCreateCreatives,
  isTrafficker,
  type,
  status,
}) => {
  return (
    <Row justify="end" gutter={10} className="mobile-row">
      <Col style={styles.col} span={24} xs={8} className="mobile-nav">
        <>
          <Tooltip title="Delete selected banners.">
            <Button
              type="secondary"
              disabled={isEmpty(adUnit.selectedRows)}
              icon={<DeleteOutlined />}
              shape="round"
              onClick={() => removeBannerMultiple(adUnit)}
            >
              <ButtonText text="Remove"></ButtonText>
            </Button>
          </Tooltip>
          <></>
        </>
      </Col>
      {isTrafficker && (
        <Col style={styles.col} span={12} xs={10} className="mobile-nav">
          <BannerUploader
            onChangeArrayOfObj={onChangeArrayOfObj}
            onChange={onChange}
            handleUpdateCampaignOrder={handleUpdateCampaignOrder}
            adUnit={adUnit}
            button={
              <Tooltip
                title={
                  type === 8
                    ? "Uploading of Video not yet available here"
                    : "Accepted file types: .jpg, .jpeg, .png, and .gif"
                }
              >
                <Button
                  shape="round"
                  icon={<CloudUploadOutlined />}
                  type="primary"
                  disabled={type === 8}
                >
                  Upload Creative
                </Button>
              </Tooltip>
            }
            index={index}
            bulkCreateCreatives={bulkCreateCreatives}
            type={type}
          />
        </Col>
      )}
    </Row>
  );
};

const CampaignLauncherViewMedia = ({
  removeAdUnit,
  onChangeArrayOfObj,
  onChange,
  onStepChange,
  handleUpdateCampaignOrder,
  currentStep,
  adUnits,
  addAdUnit,
  loading,
  tablet,
  isLive,
  isEdit,
  setSelectedCreative,
  selectedCreative,
  orgAdgroups,
  isLoading,
  setAdgroupOnCompleted,
  onSubmitLoad,
  resetAdUnit,
  orgAdgroup,
  createMediaMutation,
  removeBannerMultiple,
  currentUser,
  addCreativeNotes,
  bulkCreateCreatives,
  campaignOrder,
  status,
  type,
  handleUpdateStatusCreatives,
  removeCreativeById,
  startDate,
  endDate,
}) => {
  const isAdmin =
    currentUser && currentUser.permission
      ? currentUser.permission.isAdmin
      : false;

  const { isTrafficker } =
    currentUser && currentUser.permission ? currentUser.permission : {};

  const [adUnitIndex, setAdUnitIndex] = useState(0);
  const [getURL, setGetURL] = useState("");
  const [isloading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [creativeForm] = Form.useForm();

  const [adGroups, setAdGroups] = useState([]);
  const [pendingDeleteCreativeId, setPendingDeleteCreativeId] = useState(null);

  const httpPlaceholder =
    'Please add "http://" or "https://" for example "http://www.example.com"';
  const notAValidURL = "Not a valid url";

  /* eslint-disable */
  useEffect(() => {
    if (
      orgAdgroup &&
      orgAdgroup.adGroupCreatives &&
      orgAdgroup.adGroupCreatives.medias &&
      selectedCreative
    ) {
      const medias = orgAdgroup.adGroupCreatives.medias.map(media => ({
        type: media.type,
        key: media.key,
        url: media.url,
        width: media.width,
        height: media.height,
        name: media.name,
        size: media.size,
        fileType: media.fileType,
      }));

      setSelectedCreative("");
      bulkCreateCreatives({ medias, adUnitIndex });
    }
  }, [orgAdgroup]);

  const handleRemoveBannerMultiple = async adUnit => {
    try {
      message.loading("Removing Creative(s)...", 0);
      await removeBannerMultiple(adUnit);
      message.destroy();
      message.success("Creative(s) Successfully Deleted.");
    } catch (error) {
      message.destroy();
      message.error("Error Deleting Creative(s).");
    }
  };

  const onFinish = async values => {
    setLoading(true);

    let nextBannersState = adUnits[adUnitIndex].banners.slice();

    (await values) &&
      values.creativesurls &&
      values.creativesurls.forEach(async val => {
        const image = await getImageDimensionsByURL(val.creativeURL)
          .then(image => image)
          .catch(() => {
            return;
          });

        if (image && image.isValidImage) {
          await createMediaMutation({
            variables: {
              name: "creative_url_" + val.creativeURL,
              url: val.creativeURL,
              key: val.creativeURL,
              mediatype: "STILL",
              height: image.height,
              width: image.width,
              size: image.size,
            },
          }).then(val => {
            const getResults = val.data.createMedia;
            nextBannersState.push(getResults);
            onChangeArrayOfObj(
              "adUnits",
              adUnitIndex,
              "banners",
              nextBannersState
            );
            setLoading(false);
          });
          await handleUpdateCampaignOrder({ step: "creatives" });
        } else {
          // message.error(
          //   "Some URLs are not valid images. Please check and try again."
          // );
          setLoading(false);
          await createMediaMutation({
            variables: {
              name: "creative_url_" + val.creativeURL,
              url: val.creativeURL,
              key: val.creativeURL,
              mediatype: "STILL",
              height: 0,
              width: 0,
              size: 0,
            },
          }).then(val => {
            const getResults = val.data.createMedia;
            nextBannersState.push(getResults);
            onChangeArrayOfObj(
              "adUnits",
              adUnitIndex,
              "banners",
              nextBannersState
            );
          });
        }
      });

    await creativeForm.resetFields();
  };

  const steps = adUnits.map((adUnit, index) => {
    return {
      title: adUnit.name,
      descriptionTitle: ["Link:"],
      description: [
        ...(isEmpty(adUnit.clickthrough)
          ? ["None Added"]
          : [adUnit.clickthrough]),
      ],
      complete: !(isEmpty(adUnit.clickthrough) || isEmpty(adUnit.banners)),
    };
  });

  const renderItem = (title, count, id) => ({
    key: id,
    value: `${title}-${id}`,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <Tooltip title="Number of Media">
          <span>
            <PictureOutlined /> {count}
          </span>
        </Tooltip>
      </div>
    ),
  });

  useEffect(() => {
    if (orgAdgroups && orgAdgroups.length) {
      let filteredAdgroups = orgAdgroups;

      // Display Type in Step 1 is selected as Video
      if (type === 8) {
        filteredAdgroups = orgAdgroups
          .filter(item => Array.isArray(item.medias) && item.medias.length)
          .filter(item => item.medias.every(media => media.fileType === "MP4"));
      }

      setAdGroups(
        filteredAdgroups
          ? filteredAdgroups.reduce((acc, item, currentIndex) => {
              const orgName =
                item.org.length > 1
                  ? item.org[item.org.length - 1].name
                  : item.org[0].name; // Extract org name from orgs array
              if (Array.isArray(acc)) {
                // Add safeguard to check if acc is an array
                const existingGroup = acc.find(
                  group => group.label === orgName
                );
                // const locations = getAudienceValidLocation(item);
                const newItem = renderItem(
                  item.name,
                  item.medias.length,
                  item.id
                ); // Create a new item with id and name properties

                if (existingGroup) {
                  existingGroup.options.push(newItem); // Push the new item to options array of existing group
                } else if (item.medias && item.medias.length > 0) {
                  acc.push({
                    key: `${item.id}-${currentIndex}`,
                    label: orgName,
                    options: [newItem], // Create a new options array with the new item
                  });
                }
              }
              return acc; // Return the accumulated value in each iteration
            }, [])
          : []
      );
    }
  }, [orgAdgroups, type]);

  const handlePlayPauseCreatives = async ({ creative, status }) => {
    try {
      message.loading("Updating Creatives...", 0);
      await handleUpdateStatusCreatives({ creatives: [creative], status });

      message.destroy();
      message.success("Creatives updated successfully.", 5);
    } catch (error) {
      message.destroy();
      message.error("Error updating creatives.", 5);
    }
  };

  const handleRemoveCreativeById = async ({ creativeId, adUnitId }) => {
    try {
      setPendingDeleteCreativeId(creativeId);

      message.loading("Removing Creative...", 0);
      await removeCreativeById({ creativeId, adUnitId });
      setPendingDeleteCreativeId(null);

      message.destroy();
      message.success("Creative removed successfully.", 5);
    } catch (error) {
      setPendingDeleteCreativeId(null);
      message.destroy();
      message.error("Error removing creative.", 5);
    }
  };

  const handleStartDateChange = ({ date, index, adUnitEndDate }) => {
    if (date) {
      if (date.isAfter(adUnitEndDate)) {
        onChangeArrayOfObj("adUnits", index, "endDate", null);
      }
    }

    onChangeArrayOfObj(
      "adUnits",
      index,
      "startDate",
      date ? date.toISOString() : null
    );

    // Check first if the end date is set and end date is greater than 2 days after start date and if so don't change end date
    if (
      adUnitEndDate &&
      moment(adUnitEndDate).isAfter(moment(date).add(2, "days"))
    ) {
      return;
    }

    // Set end date 2 days after start date
    if (date) {
      setTimeout(() => {
        onChangeArrayOfObj(
          "adUnits",
          index,
          "endDate",
          date.add(2, "days").toISOString()
        );
      });
    }
  };

  const startDisabledDate = ({ date, adUnitEndDate }) => {
    // Disable dates after the endDate and 2 days before the endDate
    return (
      (date &&
        (date > moment(adUnitEndDate) ||
          date >=
            moment(adUnitEndDate)
              .clone()
              .subtract(2, "days"))) ||
      date < moment(startDate)
    );
  };

  const endDisabledDate = ({ date, adUnitStartDate, adUnitEndDate }) => {
    // Disable dates before the startDate
    return (
      date <
        moment(adUnitStartDate)
          .clone()
          .add(2, "days") || date > moment(endDate)
    );
  };
  return (
    <Frag>
      <Spin spinning={isloading}>
        <Row gutter={12} css={responsiveMenu}>
          {tablet && (
            <Col style={styles.col} span={12} xs={24} className="mobile-nav">
              <StepsCard stepsToComplete={steps} />
            </Col>
          )}
          <Col span={tablet ? 24 : 18}>
            <Row gutter={12} style={styles.row}>
              <Col span={24}>
                <Card
                  title={
                    <Col>
                      <FileImageOutlined />
                      <span> Creatives</span>
                    </Col>
                  }
                >
                  <>
                    <Title style={styles.title} level={4}>
                      Group Banners Into Ad Units
                    </Title>
                    <Text type="secondary">
                      Ad Units are unique banner groupings within any campaign.
                    </Text>
                    <br />
                    <Text type="secondary">
                      Each Ad Unit can have a unique combination of banners as
                      well as a different click-through link if desired.
                    </Text>
                  </>
                  {isTrafficker && (
                    <Row>
                      <Button
                        style={{ marginTop: "1.5em" }}
                        type="primary"
                        shape="round"
                        disabled={
                          !adUnits.some(item => item.banners.length > 0)
                        }
                        onClick={async () => {
                          setLoading(true);
                          await multiImageZip(campaignOrder.name, adUnits);
                          setLoading(false);
                        }}
                      >
                        Download Creatives
                      </Button>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
            {adUnits &&
              adUnits.map((adUnit, index) => {
                const adUnitStartDate = adUnit.startDate || startDate;
                const adUnitEndDate = adUnit.endDate || endDate;
                return (
                  <Spin
                    tip="Loading..."
                    spinning={isLoading}
                    key={`Ad Unit ${index + 1}${adUnit.id}`}
                  >
                    <Row
                      key={`Ad Unit ${index + 1}${adUnit.id}`}
                      style={styles.row}
                    >
                      <Col span={24}>
                        <Card
                          title={
                            <Col>
                              <LayoutOutlined />
                              <span>{` Ad Unit ${
                                index + 1 < 10 ? "0" : ""
                              }${index + 1}`}</span>
                            </Col>
                          }
                          extra={
                            // hide delete button if creatives have adIds
                            adUnits[0].banners && adUnits[0].banners.some(banner => banner.adId) ? null : (
                              <Tooltip
                                placement="bottom"
                                title="Click here to delete this Ad Unit."
                              >
                                {adUnits.length === 1 ? (
                                  <Popconfirm
                                    title="Are you sure you want to clear the values on this Ad Unit?"
                                    onConfirm={() => {
                                      form.setFieldsValue({
                                        name: "",
                                        URL: "",
                                      });
                                      resetAdUnit(0);
                                    }}
                                  >
                                    <Button
                                      icon={<CloseOutlined />}
                                      shape="circle"
                                    />
                                  </Popconfirm>
                                ) : (
                                  <Popconfirm
                                    title="Are you sure you want to delete this Ad Unit?"
                                    onConfirm={() => removeAdUnit(index)}
                                  >
                                    <Button
                                      icon={<CloseOutlined />}
                                      shape="circle"
                                    />
                                  </Popconfirm>
                                )}
                              </Tooltip>
                            )
                          }
                        >
                          <Row gutter={24}>
                            <Form layout="inline" style={styles.input}>
                              <Col xs={24} md={24} lg={12}>
                                <Row>
                                  <Title style={styles.title} level={4}>
                                    Name <RequiredLabel />
                                  </Title>
                                </Row>
                                <Row style={styles.rowTwo}>
                                  <Form.Item
                                    name="name"
                                    rules={[{ required: true }]}
                                    style={styles.input}
                                  >
                                    <Input
                                      onChange={e => {
                                        onChangeArrayOfObj(
                                          "adUnits",
                                          index,
                                          "name",
                                          e.target.value
                                        );
                                      }}
                                      defaultValue={adUnit.name}
                                      style={styles.input}
                                      placeholder="Ad Unit Name"
                                    />
                                  </Form.Item>
                                </Row>
                              </Col>
                              <Col xs={24} md={24} lg={12}>
                                <Row
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    width: "100%",
                                  }}
                                >
                                  <Title
                                    style={{ ...styles.title, flexShrink: 0 }}
                                    level={4}
                                  >
                                    Click-Through Link <RequiredLabel />
                                  </Title>
                                  <Tooltip title="Where users arrive after clicking a banner in this Ad Unit.">
                                    <Text
                                      type="secondary"
                                      style={styles.clickThroughSubtitle}
                                    >
                                      Where users arrive after clicking a banner
                                      in this Ad Unit.
                                    </Text>
                                  </Tooltip>
                                </Row>
                                <Row>
                                  <Form.Item
                                    name="URL"
                                    rules={[
                                      { required: true },
                                      {
                                        type: "url",
                                        message: httpPlaceholder,
                                      },
                                    ]}
                                    style={styles.input}
                                  >
                                    <Input
                                      onChange={e => {
                                        onChangeArrayOfObj(
                                          "adUnits",
                                          index,
                                          "clickthrough",
                                          e.target.value
                                        );
                                      }}
                                      defaultValue={adUnit.clickthrough}
                                      style={styles.input}
                                      placeholder={httpPlaceholder}
                                    />
                                  </Form.Item>
                                </Row>
                              </Col>
                            </Form>
                          </Row>
                          <Row gutter={24} style={styles.internalRow}>
                            <Form
                              layout="inline"
                              style={styles.input}
                              values={{
                                startDate: adUnitStartDate
                                  ? moment(adUnitStartDate)
                                  : null,
                                endDate: adUnitEndDate
                                  ? moment(adUnitEndDate)
                                  : null,
                              }}
                            >
                              <Col xs={24} md={24} lg={12}>
                                <Row>
                                  <Title style={styles.title} level={4}>
                                    Start Date <RequiredLabel />
                                  </Title>
                                </Row>
                                <Row style={styles.rowTwo}>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    value={
                                      adUnitStartDate
                                        ? moment(adUnitStartDate)
                                        : null
                                    }
                                    onChange={date => {
                                      handleStartDateChange({
                                        date,
                                        index,
                                        adUnitEndDate,
                                      });
                                    }}
                                    disabledDate={date =>
                                      startDisabledDate({ date, adUnitEndDate })
                                    }
                                  />
                                </Row>
                              </Col>
                              <Col xs={24} md={24} lg={12}>
                                <Row>
                                  <Title style={styles.title} level={4}>
                                    End Date <RequiredLabel />
                                  </Title>
                                </Row>
                                <Row style={styles.rowTwo}>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    value={
                                      adUnitEndDate
                                        ? moment(adUnitEndDate)
                                        : null
                                    }
                                    onChange={date => {
                                      onChangeArrayOfObj(
                                        "adUnits",
                                        index,
                                        "endDate",
                                        date ? date.toISOString() : null
                                      );
                                    }}
                                    disabledDate={date =>
                                      endDisabledDate({
                                        date,
                                        adUnitStartDate,
                                        adUnitEndDate,
                                      })
                                    }
                                  />
                                  {/* <Input
                                      onChange={e => {
                                        onChangeArrayOfObj(
                                          "adUnits",
                                          index,
                                          "name",
                                          e.target.value
                                        );
                                      }}
                                      defaultValue={adUnit.name}
                                      style={styles.input}
                                      placeholder="Ad Unit Name"
                                    /> */}
                                </Row>
                              </Col>
                            </Form>
                          </Row>
                          <Row>
                            <Col xs={24} md={24} lg={12}>
                              <Title style={styles.title} level={4}>
                                Banners
                              </Title>
                              <div style={styles.div}>
                                <Text type="secondary">
                                  These are the banners that will be displayed
                                  to users
                                </Text>
                              </div>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                              <BannerUploaderButton
                                adUnit={adUnit}
                                onChangeArrayOfObj={onChangeArrayOfObj}
                                index={index}
                                removeBannerMultiple={
                                  handleRemoveBannerMultiple
                                }
                                onChange={onChange}
                                handleUpdateCampaignOrder={
                                  handleUpdateCampaignOrder
                                }
                                bulkCreateCreatives={bulkCreateCreatives}
                                campaignOrder={campaignOrder}
                                isTrafficker={isTrafficker}
                                type={type}
                                status={status}
                                handleUpdateStatusCreatives={
                                  handleUpdateStatusCreatives
                                }
                              />
                            </Col>
                          </Row>
                          {isTrafficker && (
                            <>
                              {isEmpty(adUnit.banners) ? (
                                <Row style={{ marginTop: "1em" }}>
                                  <Col xs={24}>
                                    <DragUploader
                                      adUnit={adUnit}
                                      index={index}
                                      onChangeArrayOfObj={onChangeArrayOfObj}
                                      loading={loading}
                                      onChange={onChange}
                                      handleUpdateCampaignOrder={
                                        handleUpdateCampaignOrder
                                      }
                                      addCreativeNotes={addCreativeNotes}
                                      bulkCreateCreatives={bulkCreateCreatives}
                                      type={type}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col xs={24}>
                                    <Card bodyStyle={styles.cardBody}>
                                      <BannersUploadTable
                                        status={status}
                                        isShowRowSelection={true}
                                        onChangeArrayOfObj={onChangeArrayOfObj}
                                        adUnit={adUnit}
                                        index={index}
                                        isAdmin={isAdmin}
                                        addCreativeNotes={addCreativeNotes}
                                        isTrafficker={isTrafficker}
                                        handlePlayPauseCreatives={
                                          handlePlayPauseCreatives
                                        }
                                        handleRemoveCreativeById={
                                          handleRemoveCreativeById
                                        }
                                        pendingDeleteCreativeId={
                                          pendingDeleteCreativeId
                                        }
                                      />
                                    </Card>
                                  </Col>
                                </Row>
                              )}
                              <Row
                                type="flex"
                                justify="center"
                                style={{ marginTop: "15px" }}
                              >
                                <div>OR</div>
                              </Row>
                              <Row type="flex" justify="center">
                                <Col
                                  xs={24}
                                  md={24}
                                  lg={10}
                                  style={{ padding: "20px" }}
                                >
                                  <Input.Group compact>
                                    <Select
                                      showSearch
                                      placeholder="Select from an existing Adgroup"
                                      style={{
                                        width: "100%",
                                      }}
                                      defaultActiveFirstOption={false}
                                      showArrow={true}
                                      notFoundContent={"No Records found."}
                                      filterOption={(inputValue, option) => {
                                        return (
                                          (option &&
                                            option.label &&
                                            typeof option.label === "string" &&
                                            option.label
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0) ||
                                          (option &&
                                            option.value &&
                                            typeof option.value === "string" &&
                                            option.value
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0)
                                        );
                                      }}
                                      options={adGroups}
                                      onChange={(_, option) => {
                                        setAdgroupOnCompleted(false);
                                        setSelectedCreative(option.key);
                                        setAdUnitIndex(index);
                                      }}
                                    ></Select>
                                  </Input.Group>
                                </Col>
                              </Row>
                              <Row
                                type="flex"
                                justify="center"
                                style={{ marginTop: "15px" }}
                              >
                                <div>OR</div>
                              </Row>
                              <Row type="flex" justify="center">
                                <Col
                                  xs={24}
                                  md={24}
                                  lg={10}
                                  style={{ padding: "20px" }}
                                >
                                  <Row justify="center">
                                    <Title style={styles.title} level={4}>
                                      Creative URL Link
                                    </Title>
                                  </Row>
                                  <Row style={styles.rowTwo} justify="center">
                                    <Form
                                      form={creativeForm}
                                      name="dynamic_form_nest_item"
                                      onFinish={onFinish}
                                      autoComplete="off"
                                    >
                                      <Form.List name="creativesurls">
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map(
                                              ({ key, name, ...restField }) => (
                                                <Space
                                                  key={key}
                                                  style={{
                                                    display: "flex",
                                                    marginBottom: 8,
                                                  }}
                                                  align="baseline"
                                                >
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "creativeURL"]}
                                                    rules={[
                                                      {
                                                        type: "url",
                                                        message: notAValidURL,
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder="Creative URL Link"
                                                      onChange={val => {
                                                        setGetURL(
                                                          val.target.value
                                                        );
                                                      }}
                                                      style={{ width: "200px" }}
                                                    />
                                                  </Form.Item>
                                                  <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                  />
                                                </Space>
                                              )
                                            )}
                                            <Form.Item>
                                              <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                                style={{ width: "300px" }}
                                              >
                                                Add field
                                              </Button>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                      <Form.Item
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          disabled={isEmpty(getURL)}
                                          onClick={() => {
                                            setAdUnitIndex(index);
                                          }}
                                        >
                                          Submit Creative URL
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                          {!isTrafficker && (
                            <>
                              <Row type="flex" justify="center">
                                <Col
                                  xs={24}
                                  md={24}
                                  lg={10}
                                  style={{ padding: "20px" }}
                                >
                                  <Input.Group compact>
                                    <Select
                                      showSearch
                                      placeholder="Select from an existing adgroup creative"
                                      style={{
                                        width: "100%",
                                      }}
                                      defaultActiveFirstOption={false}
                                      initialvalues={
                                        "Select from an existing adgroup creative"
                                      }
                                      showArrow={true}
                                      notFoundContent={"No Records found."}
                                      filterOption={(inputValue, option) => {
                                        return (
                                          (option &&
                                            option.label &&
                                            typeof option.label === "string" &&
                                            option.label
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0) ||
                                          (option &&
                                            option.value &&
                                            typeof option.value === "string" &&
                                            option.value
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0)
                                        );
                                      }}
                                      options={adGroups}
                                      onSelect={(_, option) => {
                                        setAdgroupOnCompleted(false);
                                        setSelectedCreative(option.key);
                                        setAdUnitIndex(index);
                                      }}
                                    ></Select>
                                  </Input.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24}>
                                  <Card bodyStyle={styles.cardBody}>
                                    <BannersUploadTable
                                      status={status}
                                      isShowRowSelection={true}
                                      onChangeArrayOfObj={onChangeArrayOfObj}
                                      adUnit={adUnit}
                                      index={index}
                                      isAdmin={isAdmin}
                                      addCreativeNotes={addCreativeNotes}
                                      isTrafficker={isTrafficker}
                                      handlePlayPauseCreatives={
                                        handlePlayPauseCreatives
                                      }
                                      handleRemoveCreativeById={
                                        handleRemoveCreativeById
                                      }
                                      pendingDeleteCreativeId={
                                        pendingDeleteCreativeId
                                      }
                                    />
                                  </Card>
                                </Col>
                              </Row>
                              <Row
                                type="flex"
                                justify="center"
                                style={{ marginTop: "15px" }}
                              >
                                <div>OR</div>
                              </Row>
                              <Row type="flex" justify="center">
                                <Col
                                  xs={24}
                                  md={24}
                                  lg={10}
                                  style={{ padding: "20px" }}
                                >
                                  <Input.Group compact>
                                    <Select
                                      showSearch
                                      placeholder={
                                        type === 8
                                          ? "Select from an existing video Ad Group"
                                          : "Select from an existing image Ad Group"
                                      }
                                      style={{
                                        width: "100%",
                                      }}
                                      defaultActiveFirstOption={false}
                                      showArrow={true}
                                      notFoundContent={"No Records found."}
                                      filterOption={(inputValue, option) => {
                                        return (
                                          (option &&
                                            option.label &&
                                            typeof option.label === "string" &&
                                            option.label
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0) ||
                                          (option &&
                                            option.value &&
                                            typeof option.value === "string" &&
                                            option.value
                                              .toLowerCase()
                                              .indexOf(
                                                inputValue.toLowerCase()
                                              ) >= 0)
                                        );
                                      }}
                                      options={adGroups}
                                      onChange={(_, option) => {
                                        setSelectedCreative(option.key);
                                      }}
                                    ></Select>
                                  </Input.Group>
                                </Col>
                              </Row>
                              <Row
                                type="flex"
                                justify="center"
                                style={{ marginTop: "15px" }}
                              >
                                <div>OR</div>
                              </Row>
                              <Row type="flex" justify="center">
                                <Col
                                  xs={24}
                                  md={24}
                                  lg={10}
                                  style={{ padding: "20px" }}
                                >
                                  <Row justify="center">
                                    <Title style={styles.title} level={4}>
                                      Creative URL Link
                                    </Title>
                                  </Row>
                                  <Row style={styles.rowTwo} justify="center">
                                    <Form
                                      form={creativeForm}
                                      name="dynamic_form_nest_item"
                                      onFinish={onFinish}
                                      autoComplete="off"
                                    >
                                      <Form.List name="creativesurls">
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map(
                                              ({ key, name, ...restField }) => (
                                                <Space
                                                  key={key}
                                                  style={{
                                                    display: "flex",
                                                    marginBottom: 8,
                                                  }}
                                                  align="baseline"
                                                >
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "creativeURL"]}
                                                    rules={[
                                                      {
                                                        type: "url",
                                                        message: notAValidURL,
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder="Creative URL Link"
                                                      onChange={val => {
                                                        setGetURL(
                                                          val.target.value
                                                        );
                                                      }}
                                                      style={{ width: "200px" }}
                                                    />
                                                  </Form.Item>
                                                  <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                  />
                                                </Space>
                                              )
                                            )}
                                            <Form.Item>
                                              <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                                style={{ width: "300px" }}
                                              >
                                                Add field
                                              </Button>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                      <Form.Item
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          disabled={isEmpty(getURL)}
                                          onClick={() => {
                                            setAdUnitIndex(index);
                                          }}
                                        >
                                          Submit Creative URL
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Card>
                      </Col>
                    </Row>
                  </Spin>
                );
              })}
            {!(
              isEmpty(adUnits[adUnits.length - 1].clickthrough) ||
              isEmpty(adUnits[adUnits.length - 1].banners)
            ) && (
              <Row gutter={12} style={styles.row}>
                <Col span={24}>
                  <Button block onClick={addAdUnit}>
                    <PlusCircleFilled />
                    Ad Unit {adUnits.length + 1 < 10 ? "0" : ""}
                    {adUnits.length + 1}
                  </Button>
                </Col>
              </Row>
            )}

            {tablet && (
              <Col style={styles.col} span={12} xs={24} className="mobile-nav">
                <NavigationButtons
                  onStepChange={onStepChange}
                  handleUpdateCampaignOrder={handleUpdateCampaignOrder}
                  disabled={
                    isEmpty(adUnits[0].clickthrough) || isEmpty(adUnits[0].name)
                  }
                  currentStep={currentStep}
                  isEdit={isEdit}
                  onChange={onChange}
                  onSubmitLoad={onSubmitLoad}
                />
              </Col>
            )}
          </Col>
          {!tablet && (
            <Col span={6}>
              <div>
                <StepsCard stepsToComplete={steps} />
                <NavigationButtons
                  onStepChange={onStepChange}
                  handleUpdateCampaignOrder={handleUpdateCampaignOrder}
                  disabled={
                    isEmpty(adUnits[0].clickthrough) || isEmpty(adUnits[0].name)
                  }
                  currentStep={currentStep}
                  isLive={isLive}
                  isEdit={isEdit}
                  onChange={onChange}
                  onSubmitLoad={onSubmitLoad}
                />
              </div>
            </Col>
          )}
        </Row>
      </Spin>
    </Frag>
  );
};

export default CampaignLauncherViewMedia;
