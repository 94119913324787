import React, { useEffect } from "react";
// import { useLazyQuery } from "@apollo/react-hooks";
import { withApollo, graphql, useQuery, useMutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";

// Queries
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GEOTARGETS_CONNECTION from "../../../GraphQl/Queries/GEOTARGETS_CONNECTION";
import UPDATE_SEGMENTOBSERVATION from "../../../GraphQl/Mutations/UPDATE_SEGMENTOBSERVATION";

// Mutations
import CREATE_GEOTARGET from "../../../GraphQl/Mutations/CREATE_GEOTARGET";
import DELETE_GEOTARGET from "../../../GraphQl/Mutations/DELETE_GEOTARGET";
import UPDATE_SEGMENT from "../../../GraphQl/Mutations/UPDATE_SEGMENT";
import START_GEOTARGET_OBSERVATIONS from "../../../GraphQl/Mutations/START_GEOTARGET_OBSERVATIONS";

//local
import Controller from "./Controller";
import { featurePermissions } from "../../../utils/general/user";

let combineSyncSegments = [];

const getGeoTargetsToRender = data => {
  const { edges } = data;
  const geoTargets = edges.map(item => {
    return item.node;
  });

  return geoTargets;
};

const onChange = (key, value) => {
  this.setState({ [key]: value });
};

const getQueryVariables = (
  tableConfig,
  location,
  match,
  customPageSize,
  currentUser
) => {
  const { pageSize } = tableConfig;
  const { pageNumber, orderByFilter, whereFilter } = match.params;
  const query = new URLSearchParams(location.search);

  const pageCustom = query.get("pageSize")
    ? parseInt(query.get("pageSize"))
    : customPageSize
    ? customPageSize
    : pageSize;

  const isNewPage = location.pathname.includes("new");

  const page = isNaN(pageNumber)
    ? location.pathname.split("/")[4]
    : parseInt(pageNumber, 10);

  const skip = page > 1 ? (page - 1) * pageCustom : 0;
  const first = isNewPage ? pageCustom : 100;
  const orderBy = orderByFilter || "updatedAt_DESC";

  const search = whereFilter
    ? JSON.parse(decodeURIComponent(whereFilter))
    : null;

  const where = {
    AND: [
      {
        orgs_some: {
          OR: [
            { name: currentUser ? currentUser.role.org.name : "" },
            {
              parentOrg_some: {
                name: currentUser ? currentUser.role.org.name : "",
              },
            },
          ],
        },
        isBase: true,
      },
      search,
    ].filter(item => {
      return Object && item && Object.keys(item).length > 0;
    }),
  };

  if (window.localStorage.getItem("showNewSegments")) {
    where.AND[0].isBase = true;
  }

  return { first, skip, orderBy, where };
};

const Loader = props => {
  const { tableConfig, client } = props;
  const { pageNumber, orderByFilter, whereFilter } = props.match.params;

  let getGeotargets = [];
  let getGeotargetsCount = 0;
  const query = new URLSearchParams(props.location.search);

  let pageSizeNumber = query.get("pageSize")
    ? parseInt(query.get("pageSize"))
    : 10;

  const { READ, WRITE } = featurePermissions(
    props.currentUser.role,
    "SEGMENTS"
  );

  const { loading: loadingGeoTargets, error, data } = useQuery(
    GEOTARGETS_CONNECTION,
    {
      variables: getQueryVariables(
        props.tableConfig,
        props.location,
        props.match,
        pageSizeNumber,
        props.currentUser
      ),
    }
  );

  // TODO: continue updating obs
  const [updateObservation] = useMutation(UPDATE_SEGMENTOBSERVATION);

  useEffect(() => {
    if (!loadingGeoTargets) {
      const { geoTargetsConnection } = data;
      const geoTargets = getGeoTargetsToRender(geoTargetsConnection);
      let ids = geoTargets.map(geoTarget => `'${geoTarget.id}'`).join(", ");

      const flightIds = geoTargets
        .map(geoTarget =>
          geoTarget.flights.map(flight => `'${flight.id}'`).join(", ")
        )
        .filter(function(el) {
          return el;
        })
        .join(", ");
      ids = ids + flightIds;

      try {
        updateObservation({
          variables: { ids: ids },
        });
      } catch (e) {
        console.log("error" + e);
      }
    }
  }, [loadingGeoTargets, data, updateObservation]);

  if (data && data.full && data.full.aggregate) {
    getGeotargetsCount = data.full.aggregate.count;
  }
  combineSyncSegments = getGeotargets;

  if (error) return `Error (Query: GEOTARGETS_CONNECTION)! ${error.message} `;

  let count = 0;
  let geoTargets = [];
  let countCombineSegments = getGeotargetsCount;

  const onPage = parseInt(pageNumber, 10);

  if (!loadingGeoTargets) {
    const { geoTargetsConnection, full } = data;
    count = full.aggregate.count;
    geoTargets = getGeoTargetsToRender(geoTargetsConnection);
  }

  return (
    <Controller
      {...props}
      client={client}
      loadingGeoTargets={loadingGeoTargets}
      geoTargets={geoTargets}
      combineSyncSegments={combineSyncSegments}
      pageSizeNumber={pageSizeNumber}
      tableConfig={tableConfig}
      getQueryVariables={getQueryVariables}
      onPage={onPage}
      count={count}
      countCombineSegments={countCombineSegments}
      onChange={onChange}
      orderByFilter={orderByFilter}
      whereFilter={whereFilter}
      READ={READ}
      WRITE={WRITE}
    />
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser } }) => {
      return { currentUser };
    },
  }),
  graphql(CREATE_GEOTARGET, {
    name: "createGeoTarget",
    options: props => ({
      refetchQueries: () => [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match,
            () => {
              const query = new URLSearchParams(props.location.search);
              return query.get("pageSize")
                ? parseInt(query.get("pageSize"))
                : 10;
            },
            props.currentUser
          ),
        },
      ],
    }),
  }),
  graphql(DELETE_GEOTARGET, {
    name: "deleteGeoTarget",
    options: props => ({
      refetchQueries: () => [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match,
            () => {
              const query = new URLSearchParams(props.location.search);
              return query.get("pageSize")
                ? parseInt(query.get("pageSize"))
                : 10;
            },
            props.currentUser
          ),
        },
      ],
    }),
  }),
  graphql(UPDATE_SEGMENT, {
    name: "updateSegment",
    options: props => ({
      refetchQueries: () => [
        {
          query: GEOTARGETS_CONNECTION,
          variables: getQueryVariables(
            props.tableConfig,
            props.location,
            props.match,
            () => {
              const query = new URLSearchParams(props.location.search);
              return query.get("pageSize")
                ? parseInt(query.get("pageSize"))
                : 10;
            },
            props.currentUser
          ),
        },
      ],
    }),
  }),
  graphql(START_GEOTARGET_OBSERVATIONS, {
    name: "startGeoTargetObservations",
  })
  // graphql(GEOTARGETS_CONNECTION, {
  //   name: "geotargetSync",
  //   options: props => {
  //     const query = new URLSearchParams(props.location.search);
  //     const pageCustom = query.get("pageSize")
  //       ? parseInt(query.get("pageSize"))
  //       : 10;
  //     console.log(props);
  //     return {
  //       variables: getQueryVariables(
  //         props.tableConfig,
  //         props.location,
  //         props.match,
  //         pageCustom,
  //         props.currentUser
  //       ),
  //     };
  //   },
  // })
)(withApollo(withRouter(Loader)));
