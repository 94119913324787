import { cloneDeep, isEmpty, isEqual } from "lodash";

export const parseOverlayToGeoTarget = (overlay, leaveCircleOpen) => {
  const addresses = [],
    circles = [],
    polygons = [];
  overlay.forEach(shape => {
    if (shape.type === "circle") {
      circles.push({
        coordinate: {
          create: {
            lat: shape.overlay.center.lat(),
            lng: shape.overlay.center.lng(),
          },
        },
        radius: shape.overlay.radius / 1000,
        name: shape.name,
        address: shape.address,
      });
    }
    if (shape.type === "region") addresses.push(shape.address);
    if (shape.type === "polygon") {
      const coordinates = [];
      for (let i = 0; i < shape.overlay.getPath().getLength(); i++) {
        let lng = shape.overlay
          .getPath()
          .getAt(i)
          .lng();
        let lat = shape.overlay
          .getPath()
          .getAt(i)
          .lat();
        coordinates.push([lng, lat]);
      }
      if (
        !leaveCircleOpen &&
        !isEqual(coordinates[0], coordinates[coordinates.length - 1])
      )
        coordinates.push(coordinates[0]);
      let geometry = { coordinates: [coordinates] };
      polygons.push({ geometry });
    }
  });
  return { addresses, circles, polygons };
};

export const flattenGeoTargets = geoTargets =>
  geoTargets.reduce(
    (acc, curr) => {
      const output = cloneDeep(acc);
      if (!isEmpty(curr)) {
        if (!isEmpty(curr.addresses))
          output.addresses = [...curr.addresses, ...acc.addresses];
        if (!isEmpty(curr.circles))
          output.circles = [...curr.circles, ...acc.circles];
        if (!isEmpty(curr.polygons))
          output.polygons = [
            ...curr.polygons.map(polygon => {
              return { geometry: polygon.geometry };
            }),
            ...acc.polygons,
          ];
      }
      return output;
    },
    {
      addresses: [],
      circles: [],
      polygons: [],
    }
  );

export const isEqualGeometry = (geoTargetCreate, flattenedGeoTargets) => {
  const geoTargetCreateGeo = {
    circles: geoTargetCreate.circles.map(circle => {
      return {
        radius: circle.radius,
        coordinate: circle.coordinate.create,
      };
    }),
    polygons: geoTargetCreate.polygons,
    addresses: geoTargetCreate.addresses,
  };
  const flattenedGeoTargetsGeo = {
    circles: flattenedGeoTargets.circles.map(circle => {
      const { radius, coordinate } = circle;
      return {
        radius,
        coordinate: {
          lat: coordinate.lat,
          lng: coordinate.lng,
        },
      };
    }),
    polygons: flattenedGeoTargets.polygons,
    addresses: flattenedGeoTargets.addresses,
  };
  return isEqual(geoTargetCreateGeo, flattenedGeoTargetsGeo);
};
