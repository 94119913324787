import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Row, Card, Form, Input, Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
//Deprecated does not use update user
import UPDATE_USER from "../../../.././core/GraphQl/Mutations/UPDATE_USER";
import WhiteLabel from "../../../shared/WhiteLabel";

const result = WhiteLabel();
const logo = result;

const RecoverPasswordForm = props => {
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [form] = Form.useForm();

  const { validateFields } = form;

  const forgotPassword = () => {
    setLoading(true);
    validateFields()
      .then(values => {
        const { username } = values;
        if (username) {
          Auth.forgotPassword(username)
            .then(data => {
              setLoading(false);
              setSubmit(true);
              message.success("Recovery code sent.");
            })
            .catch(err => {
              setLoading(false);
              console.log(err);
              message.warning("Invalid user.");
            });
        }
      })
      .catch(errorInfo => {
        setLoading(false);
      });
  };

  const forgotPasswordSubmit = () => {
    setLoading(true);
    validateFields()
      .then(values => {
        const { username, code, newpassword, verifypassword } = values;
        if (newpassword === verifypassword) {
          Auth.forgotPasswordSubmit(username, code, newpassword)
            .then(data => {
              setLoading(false);
              message.success("Password changed. Please log in again");
              props.history.push("/signin");
            })
            .catch(err => {
              setLoading(false);
              message.warning("Invalid code.");
            });
        } else {
          setLoading(false);
          message.warning("Passwords do not match");
        }
      })
      .catch(errorInfo => {
        setLoading(false);
      });
  };

  return (
    <div style={styles.landingPage}>
      <Card
        hoverable
        style={{ padding: 20 }}
        cover={
          <img
            alt="example"
            src={logo}
            onClick={() => props.history.push("/")}
          />
        }
      >
        <Form form={form}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                pattern: /^[A-Za-z0-9]+$/,
                message:
                  "User name must be all one word and not contain spaces",
                validateTrigger: "blur",
              },
            ]}
          >
            <Input addonBefore={<UserOutlined />} placeholder="User name" />
          </Form.Item>
          {!submit ? (
            <Form.Item>
              <Row type="flex" justify="center">
                <Button
                  style={{ background: "black", borderColor: "grey" }}
                  type="primary"
                  onClick={forgotPassword}
                >
                  Recover Password
                </Button>
              </Row>
            </Form.Item>
          ) : (
            <React.Fragment>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input addonBefore={<LockOutlined />} placeholder="Code" />
              </Form.Item>

              <Form.Item
                name="newpassword"
                rules={[
                  {
                    required: true,
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+=[{\]};:<>|./?,-]).{8,}$/,
                    message:
                      "Password must have a mix of upper and lowercase letters, numbers and special characters. eg: Test12345@#",
                    validateTrigger: "blur",
                  },
                ]}
              >
                <Input
                  addonBefore={<LockOutlined />}
                  placeholder="New Password"
                  type="password"
                />
              </Form.Item>

              <Form.Item
                name="verifypassword"
                rules={[
                  {
                    required: true,
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+=\\[{\]};:<>|./?,-]).{8,}$/,
                    message:
                      "Password must have a mix of upper and lowercase letters, numbers and special characters. eg: Test12345@#",
                    validateTrigger: "blur",
                  },
                ]}
              >
                <Input
                  addonBefore={<LockOutlined />}
                  placeholder="Verify new password"
                  type="password"
                />
              </Form.Item>

              <Form.Item>
                <Row type="flex" justify="center">
                  <Button
                    style={{ background: "black", borderColor: "grey" }}
                    type="primary"
                    onClick={forgotPasswordSubmit}
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </Row>
              </Form.Item>
            </React.Fragment>
          )}
        </Form>
      </Card>
    </div>
  );
};

const styles = {
  landingPage: {
    top: 20,
    position: "relative",
    margin: " auto",
    width: "300px",
    padding: "5px",
  },
};

export default compose(graphql(UPDATE_USER, { name: "updateUser" }))(
  withRouter(RecoverPasswordForm)
);
