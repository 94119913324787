import { Card, Col, Row, Table } from "antd";
import React from "react";
import {
  computeSpend,
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../../core/utils/campaigns";
import { PrimaryTableRowText } from "../../../../shared/globalStyling/styledText";
import { defaultCPM } from "../../../../../core/utils/constants/constants";

function CampaignOrderTable({ tableData, currencyFormat, priceVisible }) {
  const dataSource = tableData
    .map((t, i) => ({
      key: i,
      campaignOrderId: t.campaignOrderId,
      xAxis: t.xAxis,
      date: t.date,
      campaignName: t.campaignName,
      impressions: t.impressions,
      clicks: t.clicks,
      ctr: t.ctr,
      walkIns: t.walkIns,
      observations: t.observations,
      transactionCount: t.transactionCount,
      transactionTotal: t.transactionTotal,
      spend: priceVisible
        ? computeSpend(t.impressions, t.cpm ? t.cpm : defaultCPM.cpm)
        : 0,
    }))
    .sort((a, b) => a.date < b.date);

  const columns = [
    {
      title: "Date",
      dataIndex: "xAxis",
      key: "xAxis",
      sorter: (a, b) => a.date < b.date,
    },
    {
      title: "Name",
      dataIndex: "campaignName",
      key: "campaignName",
      width: "35%",
      align: "left",
      ellipsis: true,
      sorter: (a, b) => a.campaignName < b.campaignName,
      render: (text, row) => {
        return (
          // Left a comment here to document why this is change -- to fix the scroll issue where the first row of the table and name column remains on top
          // But I maintained its functionality with the Link
          <a href={`/campaigns/details/${row.campaignOrderId}`} title={text}>
            <PrimaryTableRowText text={text} />
          </a>
        );
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      render: value => formatNumber(value),
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: value => formatNumber(value),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
      render: value => `${formatNumber3SigFig(value)}%`,
      sorter: (a, b) => a.ctr - b.ctr,
    },
    {
      title: "Walk-ins",
      dataIndex: "walkIns",
      key: "walkIns",
      sorter: (a, b) => a.walkIns - b.walkIns,
    },

    {
      title: "Observations",
      dataIndex: "observations",
      render: value => formatNumber(value),
      sorter: (a, b) => a.observations - b.observations,
    },
    {
      title: "Transactions",
      dataIndex: "transactionCount",
      key: "transactionCount",
      render: value => formatNumber(value),
      sorter: (a, b) => a.transactionCount - b.transactionCount,
    },
    {
      title: "Revenue",
      dataIndex: "transactionTotal",
      key: "transactionTotal",
      render: value => formatCurrency2SigFig(value, currencyFormat),
      sorter: (a, b) => a.transactionTotal - b.transactionTotal,
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: value => formatCurrency2SigFig(value, currencyFormat),
      sorter: (a, b) => a.spend - b.spend,
    },
  ];

  const columnsWithoutSpendColumn =
    columns && columns.length && columns.filter(col => col.key !== "spend");

  return (
    <>
      <Row>
        <Col xs={24}>
          <Card>
            <Table
              dataSource={dataSource}
              columns={priceVisible ? columns : columnsWithoutSpendColumn}
              scroll={{ x: 1200 }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CampaignOrderTable;
