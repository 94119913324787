import React, { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Divider,
  Select,
  Radio,
  Button,
  notification,
  Tag,
  Tooltip,
  Modal,
} from "antd";
import CREATE_OPTOUT from "../../../../core/GraphQl/Mutations/CREATE_OPTOUT";
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-apollo";
import ModalContents from "./ModalContents";

function OptOutForm(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optOut, setOptOut] = React.useState(null);
  const [maid, setMaid] = useState();
  const [ipAddress, setIpAddress] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [stateProvince, setStateProvince] = useState();
  const [zipPostal, setZipPostal] = useState();
  const [country, setCountry] = useState("United States");
  const [emailAddress, setEmailAddress] = useState();

  const { companyName } = props;

  const [createOptout, { loading }] = useMutation(CREATE_OPTOUT, {
    onCompleted() {
      notification.success({
        message: "Form submitted succesfully",
        icon: <CheckOutlined style={{ color: "#0AA793" }} />,
      });
    },
    onError(e) {
      notification.error({
        message: "Error submitting the form, please try again later!",
        icon: <CloseCircleOutlined style={{ color: "#DE4C4C" }} />,
      });
    },
  });

  const sampleJson = `{
    "first_name": "${firstName}",
    "last_name": "${lastName}",
    "first_address": "${address1}",
    "second_address": "${address2}",
    "city": "${city}",
    "state/province": "${stateProvince}",
    "zip/postal": "${zipPostal}",
    "country": "${country}",
    "email_address": "${emailAddress}"
  }`;
  const formDetails = JSON.parse(sampleJson);

  function handleChange(event) {
    setOptOut(event.target.value);
  }

  function addOptout() {
    //Checks if maid is selected and executes function
    if (maid) {
      createOptout({
        variables: {
          maid: maid,
          formDetails: formDetails,
          token: "vScsWxkAg8Q96pJS",
        },
      });

      //Checks if ipAddress is selected and executes function
    } else if (ipAddress) {
      createOptout({
        variables: {
          ipAddress: ipAddress,
          formDetails: formDetails,
          token: "vScsWxkAg8Q96pJS",
        },
      });
    }
  }

  return (
    <>
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Typography.Paragraph style={{ fontSize: "15px" }}>
            <Row type="flex" justify="space-between">
              <Col>
                <b>
                  DO NOT SELL MY PERSONAL INFORMATION / OPT OUT REQUEST FORM
                </b>
              </Col>
              <Col>
                <Tooltip title="Use our GraphQL API endpoint to send us opt-outs">
                  <Tag
                    color="purple"
                    style={{ cursor: "pointer", borderRadius: "100px" }}
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    <CloudSyncOutlined /> API
                  </Tag>
                </Tooltip>
                <Modal
                  title="GraphQL Opt-out endpoint Usage guide!"
                  visible={isModalVisible}
                  footer={null}
                  width="55vw"
                  onCancel={() => {
                    setIsModalVisible(false);
                  }}
                >
                  <ModalContents />
                </Modal>
              </Col>
            </Row>
            <br />
            <br />
            <b>{companyName}</b> is a strong supporter of individual privacy and
            protector of personally identifiable information. Our complete
            Privacy Policy can be found{" "}
            <a
              href={`${window.location.hostname}/privacy-policy`}
              rel="noopener noreferrer"
              target="_blank"
            >
              HERE
            </a>
            , and our Addendum for California Residents can be found HERE, which
            includes full details on California Residents’ rights and how to
            exercise those rights.
            <br />
            <br />
            <b>{companyName}</b> observes user privacy by configuring our pixel
            to <b>NOT</b> track user data when the <b>"Do not track"</b>
            setting in your browser is enabled. For more information about DNT.
            You may refer to this{" "}
            <a
              href="https://allaboutdnt.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              link
            </a>
            .
            <br />
            <br />
            While <b>{companyName}'s</b> IP Targeting is inherently less
            invasive than targeting via cookies, we understand that some clients
            may not wish to have internet ads served to them.
            <br />
            <br />
            <b>
              To opt out of {companyName}'s IP Targeting and request that we do
              not sell your personal information, please complete the form
              below.
            </b>
            The information you supply on this form will only be used to process
            your request.
          </Typography.Paragraph>
        </Col>
      </Row>

      {/* NAME SECTION */}
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Divider orientation="left">Name</Divider>
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={16} md={8}>
          <Input
            onChange={e => setFirstName(e.target.value)}
            placeholder="First Name"
          />
        </Col>
        <Col xs={16} md={8}>
          <Input
            onChange={e => setLastName(e.target.value)}
            placeholder="Last Name"
          />
        </Col>
      </Row>
      {/* ADDRESS SECTION */}
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Divider orientation="left">Address</Divider>
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={16} md={8}>
          <Input
            onChange={e => setAddress1(e.target.value)}
            placeholder="Address 1"
          />
        </Col>
        <Col xs={16} md={8}>
          <Input
            onChange={e => setAddress2(e.target.value)}
            placeholder="Address 2"
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={16} md={4}>
          <Input onChange={e => setCity(e.target.value)} placeholder="City" />
        </Col>
        <Col xs={16} md={4}>
          <Input
            onChange={e => setStateProvince(e.target.value)}
            placeholder="State/Province"
          />
        </Col>
        <Col xs={16} md={4}>
          <Input
            onChange={e => setZipPostal(e.target.value)}
            placeholder="Zip/Postal"
          />
        </Col>
        <Col xs={16} md={4}>
          <Select
            onChange={e => setCountry(e.target.value)}
            defaultValue={country}
            style={{ width: "100%" }}
          >
            <Select.Option value={country}>United States</Select.Option>
          </Select>
        </Col>
      </Row>
      {/* OPT-OUT METHOD SECTION */}
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Divider orientation="left">Opt out Method</Divider>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={[24, 16]}>
        <Col xs={16} md={4} push={4}>
          <Radio.Group value={optOut} onChange={handleChange}>
            <Radio value="IP Address">IP Address</Radio>
            <Radio value="Mobile Advertising ID">Mobile Advertising ID</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={16} md={8}>
          <Input
            disabled={!optOut}
            placeholder={optOut}
            onChange={e => {
              optOut === "IP Address"
                ? setIpAddress(e.target.value) && setMaid(null)
                : setMaid(e.target.value) && setIpAddress(null);
            }}
          />
        </Col>
        <Col xs={16} md={8}>
          <Input
            onChange={e => setEmailAddress(e.target.value)}
            placeholder="Email Address"
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Button
            type="primary"
            onClick={addOptout}
            disabled={!optOut}
            loading={loading}
          >
            Submit
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      {/* <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col span={16}>
          <Typography.Paragraph style={{ fontSize: "15px" }}>
            You also may “opt out” of receiving advertising from the online
            Marketing Platforms that we partner with by visiting the opt-out
            pages offered by the NAI (here) and the DAA (here). In addition,
            this NAI page explains how consumers may opt out of cross-app
            advertising through their device settings.
          </Typography.Paragraph>
        </Col>
      </Row> */}
    </>
  );
}

export default OptOutForm;
