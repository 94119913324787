import { isEmpty, uniq } from "lodash";
import { adminOrganizations } from "../constants/constants";

// INPUT: An array of organization objects and the current Org's name
// OUTPUT: An array containing the tree of organizations, each Org has its children in an array as an added property
export const buildOrganizationTree = (arr, topOrgName) => {
  var rootElements = [];
  var hashTable = {};

  arr &&
    arr.forEach(org => {
      hashTable[org.name] = { ...org };
    });

  arr &&
    arr.forEach(org => {
      let parentName = org.parentOrg[org.parentOrg.length - 1]
        ? org.parentOrg[org.parentOrg.length - 1].name
        : null;
      if (org.name === topOrgName) parentName = null;
      if (parentName != null) {
        const parentObject = hashTable[parentName];
        if (parentObject) {
          if (parentObject.hasOwnProperty("children")) {
            parentObject.children.push(hashTable[org.name]);
          } else parentObject.children = [hashTable[org.name]];
        } else {
          rootElements.push(hashTable[org.name]);
        }
      } else {
        rootElements.push(hashTable[org.name]);
      }
    });

  return rootElements;
};

// Adds keys of an ANT Table in a tree fashion
export const buildAntTree = (nodes, parentKey) =>
  nodes.map((node, i) => {
    const key = `${parentKey}${i}`;
    return node.children
      ? {
          ...node,
          children: buildAntTree(node.children, key),
          key,
        }
      : { ...node, key };
  });

// INPUT: An array of roles (as objects) from an Org or User
// OUTPUT: Hashtable of all roles' combined features with their respective permissions as strings in an array
export const permissionsFromRoles = roles => {
  const permissions = {};
  roles.forEach(role => {
    if (!role) return;
    role.roleItems.forEach(item => {
      if (permissions[item.feature]) {
        item.actions.forEach(action => {
          if (!permissions[item.feature].includes(action)) {
            permissions[item.feature].push(action);
          }
        });
      } else {
        permissions[item.feature] = item.actions;
      }
    });
  });
  return permissions;
};

// This function grabs the parent-most Role on a User. This might eventually be set as a "default org" on the user.
export const getTopRole = roles => {
  if (isEmpty(roles)) throw Error("No roles given");
  let top = roles[0];
  roles.forEach(role => {
    if (role.org.parentOrg.length < top.org.parentOrg.length) top = role;
  });
  return top;
};

/**
 * Checks if a set of roles contains an administrator role
 * @param {array} roles The roles contained on a User
 */
export const isAdminOrg = roles =>
  roles.some(role =>
    adminOrganizations.some(orgName => orgName === role.org.name)
  );

/**
 * Checks if a set of roles contains config for a Demonstration Accunt
 * @param {array} roles The roles contained on a User
 */
export const isDemoOrg = role => {
  let isDemo = false;

  if (role) {
    if (role.org) {
      if (role.org.config) {
        isDemo = role.org.config.isDemo;
      }
    }
  }
  return isDemo ? true : false;
};

/**
 * Get features that are enabled across all roles on a User
 * @param {array} roles The roles contained on a User
 */
export const getRolesFeatures = roles => {
  let features = [];
  roles.forEach(role => {
    role.roleItems.forEach(item => {
      if (features.includes(item)) {
        return;
      }
      if (item.actions.includes("READ")) features.push(item.feature);
    });
  });
  return uniq(features);
};
