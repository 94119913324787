import { useState } from "react";

const getInitialValue = (initialValue, locations) => {
  if (locations && locations.length) {
    return locations[0].id;
  }

  return initialValue instanceof Function ? initialValue() : initialValue;
};

export default function useLocationsDropdown(initialValue, locations) {
  const [currentLocationId, setCurrentLocationId] = useState(() =>
    getInitialValue(initialValue, locations)
  );

  return [currentLocationId, setCurrentLocationId];
}
