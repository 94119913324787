import React from "react";
import { DatePicker, Menu, Row, Col, Dropdown, Button } from "antd";
import moment from "moment";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { ranges } from "../hooks/ByDateFilter";
const { RangePicker } = DatePicker;

const DatePickerField = ({
  defaultPickerValue,
  defaultValue,
  value,
  onChange,
  data,
  dropDownValue,
  setDropDownValue,
  setCustomDateRange,
  has6MonDisables = true,
  screens,
  defaultSummaryDates,
}) => {
  /**
   * 6 months lookback only
   */
  const disabledDates = current => {
    if (has6MonDisables) {
      if (current <= moment().subtract(6, "months")) {
        return true;
      } else if (current >= moment()) {
        return true;
      } else {
        return false;
      }
    } else {
      return current >= moment();
    }
  };

  const onHandleDropdown = e => {
    setDropDownValue(e.key);
    const dropdownDates =
      e.key === "All Time" ? defaultSummaryDates : ranges[e.key];
    handleOnChange(dropdownDates);
  };

  const menuItems = Object.entries(ranges).map(r => (
    <Menu.Item key={r[0]} icon={<CalendarOutlined />}>
      {r[0]}
    </Menu.Item>
  ));

  const handleOnChange = v => {
    const getSelectedStartDate = moment(v[0] || value[0]).unix();
    const getSelectedEndDate = moment(v[1] || value[1]).unix();

    //make sure to only have valid date ranges
    if (
      getSelectedStartDate <= getSelectedEndDate &&
      getSelectedEndDate >= getSelectedStartDate
    ) {
      // filter data to get only between range dates
      const getFilteredData = data.filter(obj => {
        const objDate = obj.date;
        return objDate >= getSelectedStartDate && objDate <= getSelectedEndDate;
      });

      onChange({
        data: getFilteredData,
        dates: v,
      });
    }
  };

  return (
    <>
      <Row>
        <Col span="24">
          <RangePicker
            allowClear={false}
            disabledDate={disabledDates}
            defaultPickerValue={defaultPickerValue}
            defaultValue={defaultValue}
            value={value}
            onChange={handleOnChange}
            style={{
              marginBottom: "0.4rem",
              width: screens && screens.xs === true ? "100%" : "",
            }}
            onCalendarChange={v => {
              const getSelectedStartDate = moment(v[0] || value[0]).unix();
              const getSelectedEndDate = moment(v[1] || value[1]).unix();
              //make sure to only have valid date ranges
              if (
                getSelectedStartDate <= getSelectedEndDate &&
                getSelectedEndDate >= getSelectedStartDate
              ) {
                setCustomDateRange(v);
                setDropDownValue("Custom");
              }
            }}
          />
          <Dropdown
            overlay={<Menu onClick={onHandleDropdown}>{menuItems}</Menu>}
            style={{ marginBottom: "0.4rem" }}
          >
            <Button
              style={{
                marginBottom: "0.4rem",
                width: screens && screens.xs === true ? "100%" : "",
              }}
            >
              <CalendarOutlined />
              {dropDownValue} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default DatePickerField;
