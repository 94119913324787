import moment from "moment";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";

export const printPDF = async (elementID, name) => {
  const domElement = await document.getElementById(elementID);
  const elementHeight = domElement.offsetHeight;
  const elementWidth = domElement.offsetWidth;
  const aspectRatio = elementHeight / elementWidth;

  if (elementID === "printable-page") {
    const pdf = new jsPdf("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    html2canvas(domElement, {
      logging: false,
      foreignObjectRendering: true,
      x: domElement.offsetLeft / 2,
      y: domElement.offsetTop / 2,
      width: elementWidth,
      height: elementHeight,
      scale: 2,
    }).then(canvas => {
      const img = canvas.toDataURL("image/png", 1.0);
      pdf.setTextColor(0).text(`${name}`, 5, 10);
      pdf.addImage(
        img,
        "PNG",
        5,
        15,
        pageWidth - 10,
        (pageHeight - 10) / aspectRatio
      );
      pdf.save(`${name}${moment().format()}.pdf`);
    });
  } else {
    const pdf = new jsPdf("l", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    html2canvas(domElement, {
      logging: true,
      foreignObjectRendering: true,
      x: 0,
      y: 0,
      width: elementWidth,
      height: elementHeight,
      scale: 2,
      useCORS: true,
      allowTaint: true,
    }).then(canvas => {
      const img = canvas.toDataURL("image/png", 3.0);
      pdf.addImage(img, "PNG", 0, 0, pageWidth, pageHeight);
      pdf.save(`${name}${moment().format()}.pdf`);
    });
  }
};
