import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_WITH_ATTRIBUTION_LOCATIONS = gql`
  mutation updateCampaignOrderWithAttributionLocations(
    $campaignOrderID: ID!
    $geoTargets: GeoTargetUpdateManyInput!
  ) {
    updateCampaignOrder(
      where: { id: $campaignOrderID }
      data: { attributionTargets: $geoTargets }
    ) {
      id
      repullVWI
      attributionWindow
      startDate
      endDate
      type
      aggregateData {
        overallData {
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
        }
        dayPacingData {
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
        }
      }
      attributionTargets {
        vwiUpdated
        id
        name
        start
        end
        status
        orgs {
          id
          name
        }
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
        }
      }
    }
  }
`;

export { UPDATE_CAMPAIGN_ORDER_WITH_ATTRIBUTION_LOCATIONS };
