import gql from "graphql-tag";

const EDITORIALS = gql`
  query Editorials($where: EditorialWhereInput!) {
    editorials(where: $where) {
      id
      name
      transactionsTag {
        name
        orgs(first: 1) {
          id
          name
        }
      }
    }
  }
`;

export default EDITORIALS;
