import React from "react";
import { formatNumber } from "../../../../../../core/utils/campaigns";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";
import DataSet from "@antv/data-set";

const SegmentsRingChart = ({ height, data, selectedSegmentsDimension }) => {
  const ctrdata =
    data &&
    data.map(item => ({
      value: item.value,
      percentctr: item.ctr,
      ais: item.ais,
      clicks: item.clicks,
    }));

  const { DataView } = DataSet;
  const dv = new DataView();

  dv.source(ctrdata).transform({
    as: "percent",
    type: "percent",
    dimension: "value",
    field: "percentctr",
  });

  const cols = {
    percent: {
      formatter: function(val) {
        val = val * 100;
        return formatNumber(val) + "%";
      },
    },
    impressions: { formatter: val => val.toLocaleString() },
    clicks: { formatter: val => val.toLocaleString() },
  };

  return (
    <div>
      <Chart
        forceFit
        data={selectedSegmentsDimension === "ctr" ? dv : ctrdata}
        padding={"auto"}
        scale={cols}
        height={height / 1.1}
      >
        <Coord type="theta" radius={0.75} innerRadius={0.65} />
        {selectedSegmentsDimension === "ctr" && <Axis name="percent" />}
        {selectedSegmentsDimension === "ais" && <Axis name="ais" />}
        {selectedSegmentsDimension === "clicks" && <Axis name="clicks" />}

        <Tooltip showTitle={false} />

        <Geom
          color={[
            "value",
            value => {
              const index = data.findIndex(el => el.value === value);
              if (data[index]) return data[index].color;
              else return "black";
            },
          ]}
          position={
            selectedSegmentsDimension === "ctr"
              ? "percent"
              : selectedSegmentsDimension === "ais"
              ? "ais"
              : "clicks"
          }
          type="intervalStack"
        />
      </Chart>
    </div>
  );
};

export default SegmentsRingChart;
