import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Input,
  InputNumber,
  Tooltip,
  DatePicker,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { maxRadius } from "../../../../../core/utils/constants/constants";
import moment from "moment";
import { kmToFt } from "../../../../../core/utils/campaigns";

const { RangePicker } = DatePicker;
const { Title } = Typography;

export const EditModal = ({
  editModalVisible,
  toggleEditModal,
  onOk,
  selected,
  optionsToUpdateChanged,
  targetType,
}) => {
  const styles = {
    title: {
      color: "#595959",
      marginTop: "0.75em",
      marginBottom: "0.25em",
      fontWeight: 500,
      fontSize: "1.15em",
    },
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const visibleChanged = visible => {
    if (selected.length < 2) return;
    setTooltipVisible(visible);
  };

  const datePickerChanged = vals => {
    if (!isEmpty(vals)) {
      optionsToUpdateChanged("flightLength", {
        start: vals[0].format(),
        end: vals[1].format(),
      });
    }
  };

  // const selectedHasPolygons = () => {
  //   return !!selected.filter(item => {
  //     return item.polygons.length;
  //   }).length;
  // };

  const ModalTitle = ({ title, subtitle }) => {
    return (
      <div>
        <h3 style={{ margin: 0, fontWeight: "lighter" }}>{title}</h3>
        <h5 style={{ margin: 0, color: "#adb0b3", fontWeight: "lighter" }}>
          {subtitle}
        </h5>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        title={
          <ModalTitle
            title={`Edit ${selected.length} selected Geo Target${
              selected.length > 1 ? "s" : ""
            }`}
          />
        }
        width={700}
        closable={false}
        visible={editModalVisible}
        onCancel={() => {
          toggleEditModal(false);
        }}
        onOk={onOk}
      >
        <Tooltip
          visible={tooltipVisible}
          onVisibleChange={visibleChanged}
          title="Address field unavailable when editing more than one location"
          placement="topLeft"
        >
          <Col xs={24} xl={24}>
            <Title level={4} style={styles.title}>
              Address (Required)
            </Title>
            <Row>
              <Input
                placeholder={"Address"}
                disabled={selected.length > 1}
                defaultValue={
                  selected.length === 1 ? selected[0].address : null
                }
                onChange={e => {
                  optionsToUpdateChanged("address", e.target.value);
                }}
                style={{ width: "100%", marginBottom: 10 }}
              />
            </Row>
          </Col>
        </Tooltip>

        <Col xs={24} xl={24}>
          <Title level={4} style={styles.title}>
            Name
          </Title>
          <Row>
            <Input
              placeholder={"Name"}
              style={{ marginBottom: 10 }}
              defaultValue={selected.length === 1 ? selected[0].name : null}
              onChange={e => {
                optionsToUpdateChanged("name", e.target.value);
              }}
            />
          </Row>
        </Col>

        {selected.length && selected[0].type === "Circle" && (
          <Col xs={24} xl={24}>
            <Title level={4} style={styles.title}>
              Radius (Meters)
            </Title>
            <Row>
              <InputNumber
                min="0"
                max={maxRadius}
                disabled={selected.length > 1}
                defaultValue={
                  selected.length === 1
                    ? Math.round(kmToFt(selected[0].radius))
                    : null
                }
                placeholder={"Radius (Meters)"}
                style={{ width: "100%" }}
                onChange={val => {
                  optionsToUpdateChanged("radius", val);
                }}
              />
            </Row>
          </Col>
        )}

        {targetType === "Attribution" && (
          <Col xs={24} xl={24}>
            <Title level={4} style={styles.title}>
              Attribution
            </Title>
            <Row>
              <RangePicker
                style={{ width: "100%", marginTop: 10 }}
                defaultValue={
                  selected.length === 1
                    ? [
                        moment(selected[0].start, "YYYY-MM-DD"),
                        moment(selected[0].end, "YYYY-MM-DD"),
                      ]
                    : []
                }
                onChange={vals => {
                  datePickerChanged(vals);
                }}
              />
            </Row>
          </Col>
        )}
      </Modal>
    </React.Fragment>
  );
};
