import React from "react";
import ReactDOM from "react-dom";
import { Table, Col, Row, Space } from "antd";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { isNumber } from "@turf/helpers";
import { formatCurrency } from "../../core/utils/campaigns";
import { StandardTableRowText } from "./globalStyling/styledText";

class PaginationTable extends React.Component {
  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);
    const table = dom.querySelectorAll(".ant-table-body");
    let l = table[0];
    let r = table[1];
    if (r) {
      l.addEventListener("scroll", function() {
        r.scrollLeft = l.scrollLeft;
      });
      r.style.overflowX = "hidden";
      r.addEventListener("scroll", function() {
        l.scrollLeft = r.scrollLeft;
      });
    }
  }

  render() {
    const {
      columns,
      data,
      scroll,
      pageSize,
      onRow,
      bordered,
      title,
      total,
      onChange,
      current,
      size,
      paginationSize,
      itemRender,
      rowSelection,
      footer,
      rowKey,
      expandedRowRender,
      rowClassName,
      loading,
      showHeader,
      summary,
      showAllPaginationOption,
      currencyFormat,
      showSorterTooltip,
      sortDirections,
    } = this.props;

    const buildFooterColumns = () => {
      let footerCols = [];

      if (rowSelection) {
        footerCols.push({
          dataIndex: "",
          key: "rowSelectionPlaceHolder",
          width: rowSelection.columnWidth,
        });
      }

      columns.forEach((col, indx) => {
        footerCols.push({
          dataIndex: col.dataIndex,
          width: col.width,
          key: col.key,
          footerContent: col.footerContent || false,
          footerSum: col.footerSum || false,
          footerSumFormatter: col.footerSumFormatter || false,
          footerSums: col.footerSums || false,
          footerStyle: col.footerStyle || "inline",
        });
      });

      return footerCols;
    };

    const computePageSizeOptions = total => {
      const defaultOptions = [10, 20, 50, 100, 200]; // existing page options
      const options = defaultOptions.filter(o => Number(o) <= total * 2); // filter out option greater than the total
      if (showAllPaginationOption) options.push(total);
      return options.map(o => o.toString());
    };

    const propertyDriller = (record, dotSeperatedKey) => {
      let output = record;
      const properties = dotSeperatedKey.split(".");
      properties.forEach(prop => {
        if (isEmpty(output)) return 0;
        output = output[prop];
      });
      if (isNumber(output)) {
        return output;
      }
      return 0;
    };

    const buildFooterData = () => {
      const buildFooterCalculations = (cur, footer) => {
        const { footerSums, footerStyle } = cur;

        if (footerSums.length > 0) {
          let strs = footerSums.map((cur, i) => {
            const content = `${cur.title ? cur.title + ":" : ""} ${
              cur.formatter ? cur.formatter : ""
            }${Number(
              footer
                .reduce((sum, record) => {
                  return (
                    sum + parseFloat(propertyDriller(record, cur.property))
                  );
                }, 0)
                .toFixed(cur.footerSumFixed || 0)
            ).toLocaleString()}`;

            if (footerStyle === "block")
              return (
                <Col span={24} key={i}>
                  {content}
                </Col>
              );
            else return <Col key={i}>{content}</Col>;
          });
          if (footerStyle === "inline")
            strs = (
              <Space direction="horizontal" size={10}>
                {strs}
              </Space>
            );
          return <Row>{strs}</Row>;
        }
      };

      return buildFooterColumns().reduce(
        (acc, cur) => {
          const colData = [
            {
              [cur.dataIndex]: cur.footerContent
                ? cur.footerContent
                : cur.footerSums
                ? buildFooterCalculations(cur, footer)
                : cur.footerSum
                ? `${
                    cur.footerSumFormatter ? cur.footerSumFormatter : ""
                  }${Number(
                    footer
                      .reduce((sum, record) => {
                        return (
                          sum + parseFloat(propertyDriller(record, cur.key))
                        );
                      }, 0)
                      .toFixed(cur.footerSumFixed || 0)
                  ).toLocaleString()}`
                : "",
            },
          ];
          acc.splice(0, 1, {
            ...acc[0],
            ...colData[0],
          });
          return acc;
        },
        [{ key: "data" }]
      );
    };

    //Note to use the pagination object all values must be passed as props
    let paginationObject = {};
    let chartSize = "default";

    const options = computePageSizeOptions(total);

    paginationObject = {
      hideOnSinglePage: options.length !== 0 ? false : true,
      itemRender,
      pageSize,
      total,
      current,
      showSizeChanger: total > 10,
      pageSizeOptions: options,
    };

    if (size) {
      chartSize = size;
    }
    //Summary represents to overall count displayed on the row
    const buildSummary = () => {
      const summaryRow = columns.map((col, idx) => {
        const content = buildSummaryContent(col);
        return <Table.Summary.Cell key={idx}>{content}</Table.Summary.Cell>;
      });
      return summaryRow;
    };

    const buildSummaryContent = col => {
      let content;
      //col.footerSums means that there more than one field that needs to be aggregated
      if (col.footerSums) {
        content = col.footerSums.map((cur, i) => {
          let value = 0;
          summary.forEach(row => {
            value += parseFloat(propertyDriller(row, cur.property));
          });
          const sumContent =
            (cur.title ? cur.title + ": " : "") +
            (cur.formatter || "") +
            (cur.isCurrency
              ? formatCurrency(
                  Number(value.toFixed(cur.footerSumFixed || 0)),
                  currencyFormat
                )
              : Number(
                  value.toFixed(cur.footerSumFixed || 0)
                ).toLocaleString());
          if (col.footerStyle === "block") {
            return (
              <Col span={24} key={i}>
                <StandardTableRowText text={sumContent} />
              </Col>
            );
          } else
            return (
              <Col key={i}>
                <StandardTableRowText text={sumContent} />
              </Col>
            );
        });
      }
      //col.footerSum means that there is one field that needs to be aggregated
      else if (col.footerSum) {
        let value = 0;
        summary.forEach(row => {
          value += parseFloat(propertyDriller(row, col.key));
        });
        const sumContent =
          (col.footerSumFormatter || "") +
          (col.isCurrency
            ? formatCurrency(
                Number(value.toFixed(col.footerSumFixed || 0)),
                currencyFormat
              )
            : Number(value.toFixed(col.footerSumFixed || 0)).toLocaleString());
        content = (
          <Col key={col.key}>{sumContent === "0" ? "" : sumContent}</Col>
        );
      } else if (col.footerContent) {
        content = col.footerContent;
      }
      return col.footerStyle === "block" ? (
        <Row>{content}</Row>
      ) : (
        <Row>
          <Space>{content}</Space>
        </Row>
      );
    };

    const defaultRowKey = record => record.id;
    return (
      <Table
        id="global-pagination-table"
        className="paginationtable"
        rowClassName={rowClassName ? rowClassName : () => ""}
        rowKey={rowKey || defaultRowKey}
        rowSelection={rowSelection}
        title={title ? title : undefined}
        bordered={bordered || bordered === undefined ? true : false}
        loading={loading || !data ? true : false}
        size={chartSize}
        showHeader={showHeader}
        onRow={onRow}
        columns={columns}
        dataSource={data}
        showSorterTooltip={showSorterTooltip}
        sortDirections={sortDirections}
        scroll={
          !scroll
            ? { x: 1600, scrollToFirstRowOnChange: true }
            : { ...scroll, scrollToFirstRowOnChange: true }
        }
        pagination={{
          ...paginationObject,
          size: paginationSize ? paginationSize : "",
        }}
        onChange={onChange}
        footer={
          footer && footer.length > 1
            ? () => {
                return (
                  <div>
                    <Table
                      pagination={false}
                      showHeader={false}
                      dataSource={buildFooterData()}
                      columns={buildFooterColumns()}
                      scroll={
                        !scroll
                          ? { x: 1600, scrollToFirstRowOnChange: true }
                          : { ...scroll, scrollToFirstRowOnChange: true }
                      }
                    />
                  </div>
                );
              }
            : null
        }
        summary={
          summary && summary.length > 1
            ? summary => {
                return (
                  <>
                    <Table.Summary.Row>
                      {rowSelection ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      {buildSummary(summary)}
                    </Table.Summary.Row>
                  </>
                );
              }
            : null
        }
        expandedRowRender={expandedRowRender}
      />
    );
  }
}

export default PaginationTable;

PaginationTable.propTypes = {
  total: PropTypes.number,
};
