import JSZip from "jszip";
import { saveAs } from "file-saver";
import { fetchAWSBlob } from "./fetchAWSBlob";
import { message } from "antd";

function getRandomIntWithMinDigits(minDigits) {
  const min = Math.pow(10, minDigits - 1);
  const max = Math.pow(10, minDigits) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Takes in creative data that contains a key into the AWS S3 bucket and downloads each image, zips, them up, and downloads the zip.
export const multiImageZip = async (name, adUnits) => {
  if (!adUnits.length) return;

  const hasAtLeastOneParentWithBanners = adUnits.some(
    item => item.banners.length > 0
  );

  if (!hasAtLeastOneParentWithBanners) return false;

  const zip = new JSZip();
  const folder = zip.folder(`${name}`);
  const downloadPromises = [];

  adUnits.forEach((adUnit, i) => {
    // Could store text info here, maybe campaign info
    const subFolder = folder.folder(
      adUnit.name ? adUnit.name : `Ad_Unit_${i + 1}`
    );
    adUnit.banners &&
      adUnit.banners.map(async banner => {
        const bannerMediaKey = banner.media.key;
        const fileType = bannerMediaKey.split(".").pop();
        const type = fileType.split("-");

        // Generate filename based on creative name, random number, and file extension
        // random is number is added in case there are multiple files with the same name
        const fileName = `${banner.media.name}-${getRandomIntWithMinDigits(
          4
        )}.${type[0]}`;

        // Contain all the download promises so we can wait for them all to finish before zipping
        const downloadPromise = fetchAWSBlob(bannerMediaKey).then(blob => {
          subFolder.file(fileName, blob, { binary: true });
        });

        downloadPromises.push(downloadPromise);
      });
  });

  await Promise.all(downloadPromises)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then(
        blob => {
          saveAs(blob, `${name}`);
        },
        function(e) {
          console.log("error", e);
        }
      );
    })
    .catch(error => {
      console.error("Error downloading images:", error);
    });
};

// Use in the new campaign requestor to download all images in a zip file
export const multiImageZipV2 = async (name, medias = []) => {
  if (!medias.length) return;

  const zip = new JSZip();
  const folder = zip.folder(`${name}`);
  const downloadPromises = [];

  medias.forEach((media, i) => {
    const fileType = media.key.split(".").pop();
    const type = fileType.split("-");
    const fileName = `${media.name}-${getRandomIntWithMinDigits(4)}.${type[0]}`;

    const downloadPromise = fetchAWSBlob(media.key).then(blob => {
      folder.file(fileName, blob, { binary: true });
    });

    downloadPromises.push(downloadPromise);
  });

  await Promise.all(downloadPromises)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then(
        blob => {
          saveAs(blob, `${name}`);
        },
        function(e) {
          message.error("Error downloading images");
        }
      );
    })
    .catch(error => {
      message.error("Error downloading images");
    });
};
