import { Modal } from "antd";
import React from "react";
import TargetingLocationPreviewImportTable from "./TargetingLocationPreviewImportTable";

const ModalTitle = ({ title, subtitle }) => {
  return (
    <div>
      <h3 style={{ margin: 0, fontWeight: "lighter" }}>{title}</h3>
      <h5 style={{ margin: 0, color: "#adb0b3", fontWeight: "lighter" }}>
        {subtitle}
      </h5>
    </div>
  );
};

export default function TargetingLocationPreviewImport({
  bulkImportTargetingLocations,
  closeTargetingPreviewModal,
  isOpen,
  selectTargetingLocationPreview,
  targetingLocationsPreviewData,
}) {
  return (
    <div>
      <Modal
        title={
          <ModalTitle
            title="Import Results"
            subtitle="Based on the source, here's what we found."
          />
        }
        width={800}
        closable={false}
        visible={isOpen}
        onCancel={() => {
          closeTargetingPreviewModal();
        }}
        okButtonProps={{
          disabled: !targetingLocationsPreviewData.some(
            location => location.selected
          ),
        }}
        onOk={async () => {
          await bulkImportTargetingLocations(targetingLocationsPreviewData);
          closeTargetingPreviewModal();
        }}
      >
        <TargetingLocationPreviewImportTable
          data={targetingLocationsPreviewData}
          selectLocation={selectTargetingLocationPreview}
        />
      </Modal>
    </div>
  );
}
