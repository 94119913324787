import React from "react";
import { Comment, Tooltip, List, Avatar } from "antd";
import AvatarS3 from "../../../shared/images/AvatarS3";

import moment from "moment";

const ChangeLog = ({ changeLog, tablet }) => {
  let data = [];

  if (changeLog) {
    data = changeLog.map(item => {
      const avatar = item.changedBy.avatar;

      const name = item.changedBy.username;
      const record = {
        author: name,
        avatar: avatar ? (
          <AvatarS3 imgKey={avatar.key} avatarProps={{ size: "medium" }} />
        ) : (
          <Avatar size="medium">
            {item.changedBy.username.substring(0, 1)}
          </Avatar>
        ),
        content: <p>{item.description}</p>,
        datetime: (
          <Tooltip title={moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}>
            <span>{moment(item.createdAt).fromNow()}</span>
          </Tooltip>
        ),
      };

      return record;
    });
  }

  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <List
        grid={tablet ? { column: 2 } : {}}
        className="comment-list"
        itemLayout="horizontal"
        dataSource={changeLog ? data : []}
        renderItem={item => (
          <List.Item>
            <Comment
              author={item.author}
              avatar={item.avatar}
              content={item.content}
              datetime={item.datetime}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default ChangeLog;
