import gql from "graphql-tag";

const EDIT_ORG_DATA_CONFIG = gql`
  mutation updateOrgDataConfig(
    $id: ID!
    $googleCustomerId: String
    $attributionWindow: Int
    $menuId: String
    $dspAdvertiserId: String
    $defaultDisplayAttributionWindow: Int
    $defaultPaidSearchAttributionWindow: Int
    $defaultSEOAttributionWindow: Int
    $appIds: [String!]
    $tradeDeskIdentifier: String
    $storageBucket: String
    $semrushEnable: Boolean
    $projectId: String
    $rootDomainUrl: String
    $rootKeyword: String
    $competitor1stRootDomainUrl: String
    $competitor2ndRootDomainUrl: String
    $viewId: String
    $isGA4: Boolean
    $processTag: Boolean
    $paidSearchIsEnable: Boolean
    $bingAccountId: String
    $bingCustomerId: String
    $seo: Boolean
  ) {
    updateOrg(
      data: {
        dataConfig: {
          update: {
            appIds: { set: $appIds }
            googleCustomerId: $googleCustomerId
            attributionWindow: $attributionWindow
            menuId: $menuId
            dspAdvertiserId: $dspAdvertiserId
            defaultDisplayAttributionWindow: $defaultDisplayAttributionWindow
            defaultPaidSearchAttributionWindow: $defaultPaidSearchAttributionWindow
            defaultSEOAttributionWindow: $defaultSEOAttributionWindow
            tradeDeskIdentifier: $tradeDeskIdentifier
            storageBucket: $storageBucket
            semrushEnable: $semrushEnable
            googleAnalytics: { create: { viewId: $viewId, isGA4: $isGA4 } }
            semrushConfig: {
              create: {
                projectId: $projectId
                rootDomainUrl: $rootDomainUrl
                rootKeyword: $rootKeyword
                competitor1stRootDomainUrl: $competitor1stRootDomainUrl
                competitor2ndRootDomainUrl: $competitor2ndRootDomainUrl
              }
            }
            bing: {
              create: { accountId: $bingAccountId, customerId: $bingCustomerId }
            }
            tagProcessingConfig: { create: { processTag: $processTag } }
            paidSearchDataConfig: { create: { isEnabled: $paidSearchIsEnable } }
            seo: $seo
          }
        }
      }
      where: { id: $id }
    ) {
      id
      dataConfig {
        googleCustomerId
        attributionWindow
        menuId
        dspAdvertiserId
        defaultDisplayAttributionWindow
        defaultPaidSearchAttributionWindow
        defaultSEOAttributionWindow
        tradeDeskIdentifier
        storageBucket
        semrushEnable
      }
    }
  }
`;

export default EDIT_ORG_DATA_CONFIG;
