import gql from "graphql-tag";

const GET_ADGROUP_CREATIVES_BY_MEDIA = gql`
  query adgroupmedias($media: [ID!]) {
    adGroupCreativeses(where: { medias_some: { id_in: $media }, flag: true }) {
      id
      medias {
        id
        flag
        name
      }
    }
  }
`;

export default GET_ADGROUP_CREATIVES_BY_MEDIA;
