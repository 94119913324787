import uuid from "uuid";
//Function to parse data resolved from AppNexus query to display on the audiences/segment table
export const audiencesTableData = (segments, mapRec, taxonomy) => {
  if (!segments) {
    return [];
  }
  const parse = parseDataCategories(taxonomy);

  const result = segments.map((seg, index) => {
    let rec = {};
    mapRec.forEach(record => {
      if (record.segment_id === Number(seg.appnexusId)) {
        rec = {
          data_segment_type_id: record.data_segment_type_id,
          is_public: record.is_public,
          recommend_include: record.recommend_include,
          data_category_id: parse.find(item => {
            return item.id === record.data_category_id;
          }).price,
          data_cat_raw_id: parse.find(item => {
            return item.id === record.data_category_id;
          }).id,
          hasMap: true,
        };
      }
    });

    return {
      key: index,
      id: Number(seg.appnexusId),
      lastActivity: seg.last_activity,
      segmentTypeId: rec.data_segment_type_id
        ? rec.data_segment_type_id
        : "None",
      isRecommended: rec.recommend_include ? rec.recommend_include : false,
      data_cat_raw_id: rec.data_cat_raw_id ? rec.data_cat_raw_id : null,
      dataCatId: rec.data_category_id ? rec.data_category_id : "None",
      isPublic: rec.is_public ? rec.is_public : false,
      active: seg.active,
      description: seg.description,
      price: seg.price,
      audienceName: seg.short_name,
      code: seg.code,
      hasMap: rec.hasMap ? true : false,
    };
  });
  return result;
};

//Function to parse data resolved from AppNexus query to display on the sharing record table
export const sharingRecordTableData = data => {
  return data.map((rec, index) => {
    return {
      key: index,
      memberId: rec.record.buyer_member_id,
      recordId: rec.record.id,
      segments: rec.record.segments,
    };
  });
};

//Function to parse initial sharingRecord data to be the same as filtered data.
export const parseInitialSharingRecords = data => {
  let array = [];
  if (!data) {
    return array;
  }
  data.forEach(record => {
    array.push({ record });
  });
  return array;
};

//function to filter sharing records and display only the records that have the selected segment.
export const filterSharingRecords = (segment, records) => {
  let array = [];
  records.forEach(record => {
    record.segments.forEach(seg => {
      if (segment.id === seg.id.toString()) {
        array.push({ record });
      }
    });
  });
  return array;
};

//Function to create a code for a new segment out of the audience name.  This function is only temporary
export const createCodeFromUserName = data => {
  const code = data.replace(/ /g, "_");
  const uniqueCode = uuid.v4().toString();
  const returnCode = code.concat(uniqueCode);

  return returnCode;
};

//Function to determine if the data buyer and the data owner already have a sharing record, and return that record.
export const isDataSharedAlready = (records, buyerId, dataId) => {
  let toggle;
  records.forEach(rec => {
    if (rec.buyer_member_id === buyerId && rec.data_member_id === dataId) {
      toggle = true;
    }
  });

  return toggle;
};

// Function to parse data_categories also known as pricing categories
export const parseDataCategories = taxonomy => {
  if (!taxonomy) {
    return [];
  }
  const result = taxonomy[0].data_publishers[0].data_categories.map(cat => {
    const { node_name, id } = cat;

    return {
      id: id,
      price: node_name,
    };
  });

  return result;
};

export const getAllSegmentsFromSharingRecord = (
  sharingRecords,
  sharingRecord
) => {
  let segs = [];
  if (!sharingRecords || sharingRecord) {
    return segs;
  }

  sharingRecords.forEach(record => {
    if (record.segments) {
      if (sharingRecord.recordId === record.id) {
        record.segments.forEach((segment, index) => {
          segs.push({
            key: index,
            name: segment.name,
            segment_id: segment.id,
          });
        });
      }
    }
  });
  return segs;
};
