import gql from "graphql-tag";

const GET_DSP = gql`
  query getDSP {
    getDSP {
      label
      value
    }
  }
`;

export default GET_DSP;
