import gql from "graphql-tag";

const CREATE_OPTOUT = gql`
  mutation createOptout(
    $maid: String
    $ipAddress: String
    $formDetails: Json
    $token: String!
  ) {
    createOptout(
      data: {
        maid: $maid
        ipAddress: $ipAddress
        formDetails: $formDetails
        token: $token
      }
    ) {
      id
      maid
      ipAddress
      formDetails
      token
    }
  }
`;

export default CREATE_OPTOUT;
