import gql from "graphql-tag";

const CREATE_EVENTS_TARGET = gql`
  mutation createEventsTarget(
    $name: String!
    $description: String
    $start: DateTime!
    $end: DateTime!
    $eventTags: [AdTagCreateInput!]
  ) {
    createEventsTarget(
      data: {
        name: $name
        description: $description
        start: $start
        end: $end
        eventTags: { create: $eventTags }
      }
    ) {
      id
      name
      description
      start
      end
      tags
      eventTags {
        id
        data
      }
      observations
    }
  }
`;

export default CREATE_EVENTS_TARGET;
