import gql from "graphql-tag";
const CREATE_HASHED_EMAIL_LIST = gql`
  mutation createHashedEmailList(
    $name: String!
    $hashedEmailIds: [HashedEmailAddressWhereUniqueInput!]
    $orgID: ID!
  ) {
    createHashedEmailList(
      data: {
        name: $name
        hashedEmails: { connect: $hashedEmailIds }
        orgs: { connect: [{ id: $orgID }] }
      }
    ) {
      id
      name
    }
  }
`;

export default CREATE_HASHED_EMAIL_LIST;
