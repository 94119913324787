export const options = {
  stable: {
    ecommerce: [
      { value: "jane", text: "IHeartJane" },
      { value: "dutchie-subdomain", text: "Dutchie (Subdomain)" },
      { value: "dutchie-iframe", text: "Dutchie (Iframe)" },
      { value: "meadow", text: "Meadow" },
      { value: "tymber", text: "Tymber" },
      { value: "woocommerce", text: "WooCommerce" },
      { value: "shopify", text: "Shopify" },
      { value: "olla", text: "Olla" },
      { value: "lightspeed", text: "Lightspeed eCommerce" },
      { value: "greenrush", text: "Greenrush" },
      { value: "buddi", text: "Buddi" },
      { value: "-1", text: "No cart provider" },
    ],
    impressions: [
      { value: "liquidm", text: "LiquidM Impression pixel" },
      { value: "-1", text: "No impression provider" },
    ],
  },
  beta: {
    ecommerce: [
      { value: "jane", text: "IHeartJane" },
      { value: "dutchie-subdomain", text: "Dutchie (Subdomain)" },
      { value: "dutchie-iframe", text: "Dutchie (Iframe)" },
      { value: "meadow", text: "Meadow" },
      { value: "tymber", text: "Tymber" },
      { value: "woocommerce", text: "WooCommerce" },
      { value: "shopify", text: "Shopify" },
      { value: "olla", text: "Olla" },
      { value: "lightspeed", text: "Lightspeed eCommerce" },
      { value: "greenrush", text: "Greenrush" },
      { value: "buddi", text: "Buddi" },
      { value: "-1", text: "No cart provider" },
    ],
    impressions: [
      { value: "liquidm", text: "LiquidM Impression pixel" },
      { value: "tdd", text: "Trade Desk Impression pixel" },
      { value: "-1", text: "No impression provider" },
    ],
  },
};
