import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Tooltip,
  Popconfirm,
  Typography,
  Spin,
  message,
  Menu,
  Dropdown,
} from "antd";
import Button from "../../../shared/globalStyling/Button";
import PaginationTable from "../../../shared/PaginationTable";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../shared/globalStyling/styledText";
import { hasIsAmplifyDefaultSelection } from "../../../../core/utils/orgSettings";
import {
  LoadingOutlined,
  DeleteOutlined,
  EditOutlined,
  NotificationOutlined,
  PlusOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { EllipsisOutlined } from "@ant-design/icons";
import { CreativeAdGroupForm } from "./CreativeAdGroupForm";
import { CreativeAdGroupAddCreativesModal } from "./CreativeAdGroupAddCreativesModal";
import { multiImageZipKey } from "../../../../core/utils/dataloading/multiImageZipKey";

const { Title } = Typography;

const CreativeAdGroupTable = ({
  adGroupCreatives,
  onDeleteAdgroup,
  onLoadingDeleteAdGroup,
  setDefaultAmplifyCreatives,
  amplify,
  selectedOrg,
  updateAdGroup,
  orgMedias,
  setCreativeKeys,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ids, setIds] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filteredAdGroups, setFilteredAdGroups] = useState(adGroupCreatives);
  const [visibleEditAdGroupForm, setVisibleEditAdGroupForm] = useState(false);
  const [visibleAddAdGroupForm, setVisibleAddAdGroupForm] = useState(false);

  useEffect(() => {
    if (adGroupCreatives && adGroupCreatives.length) {
      setFilteredAdGroups(
        adGroupCreatives
          .filter(
            adGroup =>
              adGroup.org.length === 1 || selectedOrg.parentOrg.length > 0
          )
          .filter(
            adGroup =>
              !(
                adGroup.hasOwnProperty("isAmplifyDefault") &&
                adGroup.isAmplifyDefault === true &&
                !amplify
              )
          )
      );
    }
  }, [adGroupCreatives, amplify, selectedOrg.parentOrg.length]);

  const downloadCreatives = async record => {
    const fileKeys = record.medias.map(media => media.key);
    if (!fileKeys.length) {
      return message.warning("No creatives to download");
    }

    const rawFilename = record && record.name ? record.name : "creatives";

    // Sanitize filename
    const filename = rawFilename.replace(/[^a-z0-9]/gi, "_").toLowerCase();

    // Download files and generate zipped file
    const results = await multiImageZipKey(filename, fileKeys);

    if (!results.success) {
      message.warn(
        `Unable to download ${results.failedDownloads} files due to invalid file keys. This could be because the files are deleted or access is blocked.`
      );
    }
  };

  let columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "50px",
      align: "left",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => {
        return (
          <>
            <PrimaryTableRowText text={name} />{" "}
            {record.isAmplifyDefault && amplify ? (
              <Tag color="green">Amplify Default</Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Creatives"} />,
      dataIndex: "media",
      key: "media",
      width: "200px",
      align: "left",
      render: (text, record) => {
        return record.medias.map((val, index) => {
          const getMedias = val.name;

          return (
            <Tag
              color="blue"
              key={index.toString()}
              style={{
                maxWidth: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getMedias}
            </Tag>
          );
        });
      },
    },
    {
      title: <TableColumnTitle text={"Actions"} />,
      dataIndex: "actions",
      key: "actions",
      width: "60px",
      align: "center",
      render: (text, record) => {
        const handleMenuClick = event => {
          const { key } = event;

          switch (key) {
            // AMPLIFY DEFAULT
            case "1":
              selectDefaultCreative(record);
              break;

            case "2":
              setVisibleEditAdGroupForm(true);
              setSelectedRows([record]);
              break;

            case "3":
              setVisibleAddAdGroupForm(true);
              setSelectedRows([record]);
              break;
            case "4":
              downloadCreatives(record);
              break;

            default:
              break;
          }
        };

        const menu = (
          <Menu onClick={handleMenuClick}>
            {amplify && (
              <Menu.Item
                key="1"
                icon={<NotificationOutlined />}
                disabled={record.isAmplifyDefault}
              >
                Set as Amplify Default
              </Menu.Item>
            )}
            <Menu.Item key="2" icon={<EditOutlined />}>
              Edit
            </Menu.Item>
            <Menu.Item key="3" icon={<PlusOutlined />}>
              Add Creatives
            </Menu.Item>
            <Menu.Item key="4" icon={<DownloadOutlined />}>
              Download Creatives
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button style={{ minWidth: 0 }}>
              <EllipsisOutlined style={{ transform: "rotate(90deg)" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      setSelectedRows(_selectedRows);
      const ids = _selectedRows.map(row => {
        return row.id;
      });
      setIds(ids);
    },
  };

  const selectDefaultCreative = async record => {
    setLoading(true);
    await setDefaultAmplifyCreatives(record);
    setLoading(false);
  };

  return (
    <>
      <Row>
        <Col xs={24} lg={24}>
          <Card bodyStyle={{ padding: 5 }}>
            <Row align="middle">
              <Col
                className="ant-col-align-self-center"
                xs={24}
                xl={4}
                style={{ paddingLeft: 15, paddingTop: 10 }}
              >
                <Title level={5}>Ad Groups</Title>
              </Col>
              <Col xs={24} xl={20}>
                <Row
                  align="right"
                  className="ant-row-justify-center ant-row-justify-end"
                >
                  <Popconfirm
                    placement="topLeft"
                    title={`Are you sure you want delete the selected Ad Group?`}
                    onConfirm={() => {
                      if (
                        amplify &&
                        hasIsAmplifyDefaultSelection(selectedRows, true)
                      ) {
                        return message.error(
                          "One of the selection is an Amplify Default and can't be deleted. Please update the selection and try again."
                        );
                      }
                      setIds(onDeleteAdgroup(ids));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Delete selected creatives`}
                    >
                      <Button
                        type="primary"
                        disabled={ids && ids.length > 0 ? false : true}
                        icon={
                          onLoadingDeleteAdGroup === true ? (
                            <LoadingOutlined />
                          ) : (
                            <DeleteOutlined />
                          )
                        }
                        ghost
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Spin tip="Loading..." spinning={isLoading}>
        <PaginationTable
          bordered={false}
          footer={
            filteredAdGroups &&
            filteredAdGroups.slice(currentPage * 10 - 10, currentPage * 10)
          }
          columns={columns}
          data={filteredAdGroups}
          scroll={{ x: 1200, y: 600 }}
          paginationSize={"default"}
          pageSize={pageSize}
          size={"default"}
          total={filteredAdGroups && filteredAdGroups.length}
          current={currentPage}
          rowSelection={rowSelection}
          onChange={paginationObject => {
            setPageSize(paginationObject.pageSize);
            setCurrentPage(paginationObject.current);
          }}
        />
      </Spin>

      {visibleEditAdGroupForm && (
        <CreativeAdGroupForm
          visible={visibleEditAdGroupForm}
          setVisible={setVisibleEditAdGroupForm}
          selectedAdGroup={selectedRows[0]}
          updateAdGroup={updateAdGroup}
        />
      )}
      {visibleAddAdGroupForm && (
        <CreativeAdGroupAddCreativesModal
          visible={visibleAddAdGroupForm}
          setVisible={setVisibleAddAdGroupForm}
          selectedAdGroup={selectedRows[0]}
          orgMedias={orgMedias}
          setCreativeKeys={setCreativeKeys}
          updateAdGroup={updateAdGroup}
        />
      )}
    </>
  );
};

export default CreativeAdGroupTable;
