import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import "./styles.css";
import { CopyOutlined } from "@ant-design/icons";

const { Title } = Typography;

const kpiDefaultOptions = [
  {
    key: "transactions",
    kpi: "Transactions",
  },
  {
    key: "signups",
    kpi: "Sign-Ups",
  },
];

export const CampaignRequestorKpiTable = ({
  form,
  handleSelectMeasurementTag,
  editable = false,
}) => {
  const [kpis, setKpis] = useState(kpiDefaultOptions);
  const awarenessCampaign = form.getFieldValue("awarenessCampaign");
  const transactionsConfig = form.getFieldValue("transactionsConfig");
  const signUpConfig = form.getFieldValue("signUpConfig");

  const handleAwarenessCampaignChange = e => {
    const value = e.target.checked;
    if (value) {
      setKpis([]);
    } else {
      setKpis(kpiDefaultOptions);
    }
  };

  const processTag = ({ eventType, config, updatedKpis }) => {
    const matchedTag = updatedKpis.find(tag => tag.key === eventType);
    const newTag = {
      ...matchedTag,
      ...config,
    };

    // Replace the matched tag with the updated tag
    const updatedTags = updatedKpis.map(tag =>
      tag.key === eventType ? newTag : tag
    );
    return updatedTags;
  };

  useEffect(() => {
    setKpis(awarenessCampaign ? [] : kpiDefaultOptions);
  }, [awarenessCampaign]);

  useEffect(() => {
    let updatedKpis = [...kpis];
    if (transactionsConfig) {
      updatedKpis = processTag({
        eventType: "transactions",
        config: transactionsConfig,
        updatedKpis,
      });
    }

    if (signUpConfig) {
      updatedKpis = processTag({
        eventType: "signups",
        config: {
          ...signUpConfig,
          tag: {
            name: signUpConfig.tag
              ? signUpConfig.tag.name
              : signUpConfig.name || "",
          },
        },
        updatedKpis,
      });
    }

    setKpis(updatedKpis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsConfig, signUpConfig]);

  return (
    <div className="requestor-table-wrapper">
      <div className="requestor-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>KPI MEASUREMENT</Title>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "end" }}>
              <Space size="middle" align="center">
                {editable && (
                  <Form.Item
                    name="awarenessCampaign"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox onChange={handleAwarenessCampaignChange}>
                      Awareness-Only Campaign
                    </Checkbox>
                  </Form.Item>
                )}
              </Space>
            </div>
          </Col>
        </Row>
      </div>
      {kpis.length ? (
        <Table
          key="kpi"
          columns={[
            {
              title: "KPI",
              dataIndex: "kpi",
              key: "kpi",
              render: (_, { kpi }) => (
                <Tag color="green" key={kpi}>
                  {kpi}
                </Tag>
              ),
            },
            {
              title: "Corresponding Tag",
              dataIndex: "correspondingTag",
              key: "correspondingTag",
              ellipsis: true, // Enables ellipsis for text overflow
              width: 300, // Set a fixed width for the column
              render: (_, { tag, appId, key }) => {
                if (tag && tag.name) {
                  return tag.name;
                } else if (appId || !editable) {
                  return "";
                }

                return (
                  <Button onClick={() => handleSelectMeasurementTag(key)}>
                    Select Measurement Tag
                  </Button>
                );
              },
            },
            {
              title: "Tag ID",
              dataIndex: "tagId",
              key: "tagId",
              render: (_, { appId }) => {
                if (appId) {
                  return appId;
                }
                return "";
              },
            },
            {
              title: "",
              dataIndex: "actions",
              key: "actions",
              render: (_, { appId, key }) => {
                if (!appId) {
                  return "";
                }

                const handleCopyToClipBoard = () => {
                  navigator.clipboard.writeText(appId);
                  message.success("Copied to clipboard", 3);
                };

                return (
                  <Space size="small" align="end" style={{ width: "100%" }}>
                    <Button
                      type="text"
                      icon={<CopyOutlined />}
                      onClick={() => handleCopyToClipBoard()}
                    />
                    {editable && (
                      <Button onClick={() => handleSelectMeasurementTag(key)}>
                        Edit
                      </Button>
                    )}
                  </Space>
                );
              },
            },
          ]}
          dataSource={kpis}
          pagination={false}
          className="requestor-table"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                2 Tags
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      ) : (
        []
      )}

      {/* Create hidden input for transactionsConfig */}
      <Form.Item
        name="transactionsConfig"
        hidden
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const signUpConfig = getFieldValue("signUpConfig");
              const awarenessCampaign = getFieldValue("awarenessCampaign");

              if (signUpConfig || awarenessCampaign || value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Please select a tag for transactions or sign-ups")
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      {/* Create hidden input signUpConfig */}
      <Form.Item
        name="signUpConfig"
        hidden
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const transactionsConfig = getFieldValue("transactionsConfig");
              const awarenessCampaign = getFieldValue("awarenessCampaign");
              if (transactionsConfig || awarenessCampaign || value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Please select a tag for transactions or sign-ups")
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
