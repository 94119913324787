import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { graphql, Query } from "react-apollo";
import compose from "lodash/flowRight";
import GET_CAMPAIGN_REPORT from "../../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";
import GET_TRANSACTION_PAGINATION from "../../../../GraphQl/Queries/GET_TRANSACTION_PAGINATION";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import Controller from "./TransactionsTabController";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import { Empty } from "antd";

const TransactionsTabDataLoader = ({ children, dataConfig, ...props }) => {
  const reportId = props.transactionReportId
    ? props.transactionReportId
    : "null";
  const csvReportId = props.transactionCSVReportId
    ? props.transactionCSVReportId
    : null;
  const searchParams = new URLSearchParams(props.location.search);
  const pageSize = searchParams.get("pageSize")
    ? parseInt(searchParams.get("pageSize"))
    : 10;

  const [
    getCSVReport,
    { loading: csvReportLoading, data: csvReportDataSignedURL },
  ] = useLazyQuery(GET_CAMPAIGN_REPORT, {
    variables: {
      reportId: csvReportId,
    },
    fetchPolicy: "no-cache",
  });

  const [
    getTransactionsTable,
    { loading: transactionsTableLoading, data: transactionsTable },
  ] = useLazyQuery(GET_TRANSACTION_PAGINATION, {
    fetchPolicy: "cache-first",
  });

  try {
    return (
      <Query
        query={GET_CAMPAIGN_REPORT}
        variables={{
          reportId: reportId,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <OverlaySpinner />;
          if (error)
            return `Error (Query: GET_CAMPAIGN_REPORT)! ${error.message}`;

          const { url, expiresAt } = data.getCampaignReportSignedURL;

          // Check if URL is invalid or empty
          if (!url || !isValidURL(url)) {
            return <Empty description="No transactions report available!" />;
          }

          const s3url = new URL(url);
          const pathnames = s3url.pathname.split("/");
          const reportName = pathnames && pathnames[3];

          return (
            <Controller
              {...props}
              dataConfig={dataConfig}
              refetch={refetch}
              url={url}
              expiresAt={expiresAt}
              reportName={reportName}
              pageSize={pageSize}
              getCSVReport={getCSVReport}
              csvReportLoading={csvReportLoading}
              csvReportDataSignedURL={csvReportDataSignedURL}
              getTransactionsTable={getTransactionsTable}
              transactionsTableLoading={transactionsTableLoading}
              transactionsTable={
                transactionsTable
                  ? transactionsTable.getTransactionPagination
                  : null
              }
            >
              {children}
            </Controller>
          );
        }}
      </Query>
    );
  } catch (err) {
    console.log("err", err);
  }
};

// Helper function to validate URL
function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(TransactionsTabDataLoader);
