import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import { isEmpty } from "lodash";
import moment from "moment";
import { Empty } from "antd";
import {
  formatNumber4SigFig,
  formatWholeNumber,
} from "../../../../../../../../../core/utils/campaigns";
import { daysWeek } from "../../../../../../../../../core/utils/constants/constants";

const DualAxisAreaChart = ({ dualChartData }) => {
  // styles
  const styles = {
    chart: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const gridLeft = {
    lineStyle: {
      stroke: "#e8e8e8",
      lineDash: [1, 0],
    },
  };

  const gridRight = {
    lineStyle: {
      stroke: "#e8e8e8",
      lineDash: [2, 4],
    },
  };

  const leftLabel = {
    textStyle: {
      fill: "#7dafff",
    },
  };

  const rightLabel = {
    textStyle: {
      fill: "#92d5a7",
    },
    formatter(text, item, index) {
      return "$" + text;
    },
  };

  // identify the scale for the graph
  const cols = {
    transactions: {
      type: "linear",
      min: 0,
      alias: "Transactions: ",
    },
    transactionRevenue: {
      type: "linear",
      min: 0,
      alias: "Revenue: ",
    },
    xAxis: {
      tickCount: 8,
    },
  };

  const formatXaxis = (day, format) => {
    if (day === daysWeek.Sun || day === daysWeek.Sunday) return daysWeek.Sunday;
    if (day === daysWeek.Mon || day === daysWeek.Monday) return daysWeek.Monday;
    if (day === daysWeek.Tue || day === daysWeek.Tuesday)
      return daysWeek.Tuesday;
    if (day === daysWeek.Wed || day === daysWeek.Wednesday)
      return daysWeek.Wednesday;
    if (day === daysWeek.Thu || day === daysWeek.Thursday)
      return daysWeek.Thursday;
    if (day === daysWeek.Fri || day === daysWeek.Friday) return daysWeek.Friday;
    if (day === daysWeek.Sat || day === daysWeek.Saturday)
      return daysWeek.Saturday;
    return moment(day, "MM/DD/YYYY").format(format);
  };

  // check if chart data is empty
  if (isEmpty(dualChartData)) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div>
      <Chart
        height={400}
        padding="auto"
        style={styles.chart}
        data={dualChartData}
        scale={cols}
        forceFit
        onTooltipChange={e => {
          let items = e.items;
          const origin1 = items[0];
          const origin2 = items[1];
          items.splice(0);
          items.push({
            name: origin1.name,
            size: origin1.size,
            showMarker: origin1.showMarker,
            title: formatXaxis(origin1.point._origin.xAxis, "dddd MMMM D"),
            marker: origin1.marker,
            color: origin1.color,
            value: formatWholeNumber(origin1.value),
            x: origin1.x,
            y: origin1.y,
            point: origin1.point,
          });
          if (origin2) {
            items.push({
              name: origin2.name,
              size: origin2.size,
              showMarker: origin2.showMarker,
              title: "",
              marker: origin2.marker,
              color: origin2.color,
              value: `$${formatNumber4SigFig(origin2.value)}`,
              x: origin2.x,
              y: origin2.y,
              point: origin2.point,
            });
          }
        }}
      >
        <Legend
          custom={true}
          clickable={false}
          hoverable={true}
          items={[
            {
              value: "Transactions",
              marker: {
                symbol: "circle",
                fill: "#b2d5ff",
                radius: 5,
              },
            },
            {
              value: "Revenue",
              marker: {
                symbol: "circle",
                fill: "#89d2d4",
                radius: 5,
              },
            },
          ]}
        />
        <Axis
          name="xAxis"
          label={{
            formatter: xAxis => formatXaxis(xAxis, "M/D/YY"),
          }}
        />
        <Axis name="transactions" grid={gridLeft} label={leftLabel} />
        <Axis name="transactionRevenue" grid={gridRight} label={rightLabel} />
        <Tooltip
          crosshairs={{
            type: "line",
          }}
        />
        <Geom
          type="area"
          position="xAxis*transactions"
          color="#b2d5ff"
          size={0}
        ></Geom>
        <Geom
          type="line"
          position="xAxis*transactions"
          color="#b2d5ff"
          size={0}
        />
        <Geom
          type="area"
          position="xAxis*transactionRevenue"
          color="#89d2d4"
          size={0}
        />
        <Geom
          type="line"
          position="xAxis*transactionRevenue"
          color="#89d2d4"
          size={0}
        />
      </Chart>
    </div>
  );
};

export default DualAxisAreaChart;
