import gql from "graphql-tag";

const UPDATE_AUDIENCE_LOCATION = gql`
  mutation updateAudience($id: ID!, $geoTargets: [GeoTargetWhereUniqueInput!]) {
    updateAudience(
      where: { id: $id }
      data: { geoTargets: { connect: $geoTargets } }
    ) {
      id
    }
  }
`;

export default UPDATE_AUDIENCE_LOCATION;
