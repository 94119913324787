import gql from "graphql-tag";

const GET_SEGMENTS_OBSERVATIONS = gql`
  query geoTargetsConnection(
    $first: Int
    $skip: Int
    $orderBy: GeoTargetOrderByInput
    $where: GeoTargetWhereInput
  ) {
    getSegmentsWithObservations(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          description
          start
          end
          isBase
          observations
          uniqueDevices
          orgs {
            id
          }
          vwiUpdated
          vwiUpdatedOnSpot
          tags
          circles {
            id
            radius
            location {
              name
              formattedAddress
              zip
              street
              city
              county
              state
              country
              lat
              lng
            }
            coordinate {
              lat
              lng
            }
          }
          location {
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          polygons {
            id
            geometry
          }
          demographicReport: reports(
            first: 1
            orderBy: createdAt_DESC
            where: { type: DEMOGRAPHICS }
          ) {
            id
            type
            key
          }
          householdReport: reports(
            first: 1
            orderBy: createdAt_DESC
            where: { type: HOUSEHOLD }
          ) {
            id
            type
            key
          }
          observationReport: reports(
            first: 1
            orderBy: createdAt_DESC
            where: { type: OBSERVATION }
          ) {
            id
            type
            key
          }
        }
      }
      aggregate {
        count
      }
    }
    full: geoTargetsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_SEGMENTS_OBSERVATIONS;
