import { Storage } from "aws-amplify";

export const fetchAWSBlob = url => {
  return Storage.get(url)
    .then(result => {
      return fetch(result).then(async response => {
        return response.blob();
      });
    })
    .catch(err => console.log("Error fetching AWS", err));
};
