import gql from "graphql-tag";
const UPDATE_MEDIA = gql`
  mutation updateMedia(
    $data: MediaUpdateInput!
    $where: MediaWhereUniqueInput!
  ) {
    updateMedia(data: $data, where: $where) {
      id
      mediaNotes
      status
    }
  }
`;

export default UPDATE_MEDIA;
