import React from "react";
import { Breadcrumb as ANTBreadcrumb } from "antd";
import { Link } from "react-router-dom";
import breadcrumbNameMap from "../../../routes/sitemap";
import { RightOutlined } from "@ant-design/icons";

const style = {
  link: {
    color: "#262626",
    fontWeight: "500",
  },
};

const Breadcrumb = ({ location }) => {
  const pathSnippets = location.pathname.split("/").filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    return breadcrumbNameMap[url] ? (
      <ANTBreadcrumb.Item key={url}>
        <Link style={style.link} to={url}>
          {breadcrumbNameMap[url]}
        </Link>
      </ANTBreadcrumb.Item>
    ) : null;
  });
  const breadcrumbItems = [
    <ANTBreadcrumb.Item key="home">
      <Link style={style.link} to="/">
        Home
      </Link>
    </ANTBreadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <ANTBreadcrumb
      separator={<RightOutlined />}
      style={{ minWidth: "190px", margin: "20px" }}
    >
      {breadcrumbItems}
    </ANTBreadcrumb>
  );
};

export default Breadcrumb;
