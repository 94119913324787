import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import GET_ROLL_UP_SEO from "../../../../GraphQl/Queries/GET_ROLL_UP_SEO";
import RollUpSEOController from "./RollUpSEOController";
import moment from "moment";
import { advertiserIdParamKey } from "../../../../../platform/shared/AdvertiserFilter/AdvertiserFilter.container";

const RollUpSEODataLoader = ({ children, ...props }) => {
  const loggedInRole = props.currentUser.role;
  let params = new URLSearchParams(props.location.search);
  const org = params.get(`${advertiserIdParamKey}`);
  const orgId = loggedInRole.org.id;
  const {
    startDate,
    endDate,
    setLoadProgress,
    loadProgress,
    totals,
    setTotals,
    productOn,
  } = props;

  const [advertiserIds, setAdvertiserIds] = useState([orgId]);

  useEffect(() => {
    if (!org) {
      return setAdvertiserIds([orgId]);
    }
    setAdvertiserIds(org.split("+"));
  }, [org, orgId]);

  const { loading, data, refetch } = useQuery(GET_ROLL_UP_SEO, {
    variables: {
      orgId,
      startDate: startDate
        ? moment(startDate)
            .startOf("day")
            .utc()
        : undefined,
      endDate: endDate
        ? moment(endDate)
            .endOf("day")
            .utc()
        : undefined,
      advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setLoadProgress(loadProgress - 1);
      if (data && data.getRollUpSEO) {
        let revenue = (totals.revenue || 0) + data.getRollUpSEO.revenue;
        let spend = (totals.spend || 0) + data.getRollUpSEO.cost;
        setTotals({
          ...totals,
          spend: spend,
          revenue: revenue,
          roas: (revenue / spend) * 100,
        });
      }
    },
    fetchPolicy: "network-only",
  });

  const [
    getPrevRangeData,
    {
      loading: loadingPrevRangeData,
      data: prevRangeData,
      refetch: refetchPrevRangeData,
    },
  ] = useLazyQuery(GET_ROLL_UP_SEO);

  useEffect(() => {
    if (startDate && endDate) {
      const diff = endDate.diff(startDate, "days");
      const prevStart = moment(startDate)
        .subtract(diff, "days")
        .startOf("day")
        .utc();
      const prevEnd = moment(endDate)
        .subtract(1, "day")
        .endOf("day")
        .utc();
      // TODO ORG Filter
      getPrevRangeData({
        variables: {
          orgId,
          startDate: prevStart,
          endDate: prevEnd,
          advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
        },
      });
    }
  }, [advertiserIds, endDate, getPrevRangeData, org, orgId, startDate]);

  useEffect(() => {
    refetch();
  }, [productOn, refetch]);

  return (
    <RollUpSEOController
      {...props}
      refetch={refetch}
      data={data}
      loading={loading}
      prevRangeData={prevRangeData}
      loadingPrevRangeData={loadingPrevRangeData}
      refetchPrevRangeData={refetchPrevRangeData}
    >
      {children}
    </RollUpSEOController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(withApollo(RollUpSEODataLoader)));
