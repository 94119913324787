import moment from "moment";
import React from "react";

const EditorialDetailsSignUpsController = ({ ...props }) => {
  let signUpsListNormalized = [];
  if (props.reportDataList) {
    signUpsListNormalized = props.reportDataList.map(signUp => {
      return {
        signUpId: signUp.SIGN_UP_USER_ID,
        signUpTime: signUp.COLLECTOR_TSTAMP,
        impressions: signUp.CLICKS.length,
        firstImpression: new Date(
          Math.max(...signUp.CLICKS.map(e => new Date(e.COLLECTOR_TSTAMP)))
        ),
        clicksList: signUp.CLICKS,
        attributionMethod: signUp.ATTRIBUTION_METHOD,
        id: signUp.EVENT_ID,
      };
    });
  }

  const filterByDate = (startDate, endDate) => {
    const signUpList = signUpsListNormalized.filter(item => {
      return (
        moment(item.signUpTime, "YYYY-MM-DD")
          .utc(true)
          .isSameOrAfter(startDate.utc(true)) &&
        moment(item.signUpTime, "YYYY-MM-DD")
          .utc(true)
          .isSameOrBefore(endDate.utc(true))
      );
    });

    let summary = signUpList.reduce(
      (acc, cur) => {
        const {
          COOKIE,
          FINGERPRINT,
          DEVICE_FINGERPRINT,
          IP,
        } = cur.attributionMethod;
        if (COOKIE) {
          if (!acc.uniqueUsers.userIds.find(user => user === COOKIE))
            acc.uniqueUsers.userIds.push(COOKIE);
        }
        if (FINGERPRINT) {
          if (!acc.uniqueUsers.fingerprints.find(user => user === FINGERPRINT))
            acc.uniqueUsers.fingerprints.push(FINGERPRINT);
        }
        if (DEVICE_FINGERPRINT) {
          if (
            !acc.uniqueUsers.deviceFingerprint.find(
              user => user === DEVICE_FINGERPRINT
            )
          )
            acc.uniqueUsers.deviceFingerprint.push(DEVICE_FINGERPRINT);
        }

        if (IP) {
          if (!acc.uniqueUsers.ipAddresses.find(user => user === IP))
            acc.uniqueUsers.ipAddresses.push(IP);
        }
        return {
          signUps: (acc.signUps += 1),
          clicks: acc.clicks + cur.clicksList.length,
          customers:
            acc.uniqueUsers.userIds.length +
            acc.uniqueUsers.ipAddresses.length +
            acc.uniqueUsers.fingerprints.length +
            acc.uniqueUsers.deviceFingerprint.length,
          uniqueUsers: acc.uniqueUsers,
        };
      },
      {
        signUps: 0,
        clicks: 0,
        customers: 0,
        uniqueUsers: {
          //Use set to prevent duplication on the values
          ipAddresses: [],
          userIds: [],
          fingerprints: [],
          deviceFingerprint: [],
        },
        loading: false,
      }
    );

    // If the signups list is still loading use by dates instead. This will no produce the unique users
    if (
      signUpsListNormalized.length === 0 &&
      props.reportDataSummary &&
      props.reportDataSummary.byDates
    ) {
      const { byDates } = props.reportDataSummary;
      const filteredByDates = byDates.filter(item => {
        return (
          moment(item.date, "YYYY-MM-DD")
            .utc(true)
            .isSameOrAfter(startDate.utc(true)) &&
          moment(item.date, "YYYY-MM-DD")
            .utc(true)
            .isSameOrBefore(endDate.utc(true))
        );
      });
      filteredByDates.forEach(date => {
        summary.signUps += date.signUps;
        summary.clicks += date.clicks;
        summary.customers = 0; // It is not possible to compute the unique users with only the by date
      });
      summary.loading = true;
    }

    return { summary, signUpList };
  };
  return React.cloneElement(props.children, {
    ...props.children.props,
    ...props,
    signUpsListNormalized,
    filterByDate,
  });
};

export default EditorialDetailsSignUpsController;
