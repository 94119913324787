import { message } from "antd";

const sizeValidation = size => {
  if (size / 1024 / 1024 > 2) {
    message.error("Image must be smaller than 2MB!");
    return false;
  }
  return true;
};

export default sizeValidation;
