import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import _, { isEmpty } from "lodash";
import googleMapsConfig from "./googlemaps";
import LoadingContainer from "../OverlaySpinner";
import * as turf from "@turf/turf";
import {
  getShapes,
  getLatLngBounds,
  getFocusPoints,
  infoWindowLayout,
  attachShapeInfoWindow,
  computeHeatMapWeight,
} from "./utils/mapUtils";

const defaultLocation = {
  lat: 37.8272,
  lng: -122.2913,
};

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      heatMapData: [],
      mapCenter: null,
      defaultLocation: defaultLocation,
      bounds: null,
    };
  }

  componentDidMount() {
    const { latLngs, locations } = this.props;

    if (!isEmpty(latLngs) && !isEmpty(locations)) {
      this.setLatLngs();
      this.getGeoLocations();
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { currentLocationId, mapTypeId } = this.props;
    if (
      prevProps.currentLocationId !== currentLocationId ||
      prevProps.mapTypeId !== mapTypeId
    ) {
      this.getGeoLocations();
    }
    this.drawMap();
  }

  drawMap = () => {
    const { mapCenter, heatMapData, bounds } = this.state;
    const {
      google,
      zoom,
      latLngs,
      mapTypeId,
      locations,
      observationsByGeoTarget,
    } = this.props;
    let circles = [];
    this.map = new google.maps.Map(this.mapNode, {
      center: mapCenter,
      mapTypeId,
      tilt: 45,
      zoom,
      minZoom: 2,
      maxZoom: 19,
      mapTypeControl: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      fullscreenControl: false,
    });

    switch (mapTypeId) {
      case "hybrid":
        // DISPLAY HEAT MAP
        this.setHeatMapData({ latLngs, google, heatMapData, zoom, circles });
        break;

      case "roadmap":
        // DISPLAY POLYGONS/SHAPES
        const { newShapes, points } = getShapes({
          latLngs,
          google,
          geoTargets: locations,
          map: this.map,
        });

        attachShapeInfoWindow({
          observationsByGeoTarget,
          points,
          newShapes,
          google,
          map: this.map,
        });
        break;

      default:
        break;
    }

    if (bounds) {
      this.map.fitBounds(bounds);
      new google.maps.event.addListenerOnce(this.map, "zoom_changed", () => {
        const newZoom = this.map.getZoom();
        const maxZoom = this.map.maxZoom;
        this.map.setOptions({
          zoom: newZoom > maxZoom ? maxZoom : newZoom,
        });
      });
    }
  };

  setHeatMapData = ({ latLngs, google, heatMapData, zoom, circles }) => {
    const maxIntensity =
      latLngs &&
      latLngs.reduce((acc, curr) => (curr.COUNT > acc ? curr.COUNT : acc), 0);

    const heatMap = new google.maps.visualization.HeatmapLayer({
      data: heatMapData,
      dissipating: true,
      radius: zoom,
      maxIntensity: maxIntensity * (maxIntensity / 2) || 20,
    });

    heatMapData.forEach(circle => {
      const { name, count, observations } = circle;
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowLayout({ name, count, observations }),
        position: circle.location,
        disableAutoPan: true,
      });
      const c = new google.maps.Marker({
        position: circle.location,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 10,
          strokeWeight: 1,
          strokeOpacity: 0,
        },
        cursor: "default",
        map: this.map,
      });
      c.addListener("mouseover", e => {
        infoWindow.open(this.map);
      });
      c.addListener("mouseout", e => {
        setTimeout(() => {
          infoWindow.close();
        }, 100);
      });
      circles.push(c);
    });

    this.map.addListener("zoom_changed", () => {
      const currentZoom = this.map.getZoom();
      circles.forEach(cir => {
        if (currentZoom <= 9) {
          cir.setMap(null);
        } else {
          cir.setMap(this.map);
        }
      });
      heatMap.setOptions({
        radius: currentZoom,
      });
    });

    heatMap.setMap(this.map);
  };

  setLatLngs = () => {
    const { defaultLocation } = this.state;
    const { latLngs, google, observationsByGeoTarget } = this.props;
    const data = latLngs.map(location => {
      const local = new google.maps.LatLng(location.lat, location.lng);
      const geoTarget = observationsByGeoTarget.find(
        observation => observation.GEO_TARGET_ID === location.GEOTARGETID
      );
      let observations = 0;

      if (geoTarget) {
        observations = geoTarget.TOTAL;
      }

      return {
        name: location.location,
        location: local,
        weight: location.weight,
        count: location.count,
        geoTargetId: geoTarget ? geoTarget.GEO_TARGET_ID : "",
        observations,
      };
    });

    const bounds = getLatLngBounds({ latLngs: data, google });
    const boundsCenter = bounds.getCenter();
    this.setState({
      loading: false,
      bounds: bounds,
      latLngs: false,
      heatMapData: !isEmpty(latLngs) ? data : [],
      mapCenter: !isEmpty(latLngs)
        ? new google.maps.LatLng(boundsCenter.lat(), boundsCenter.lng())
        : new google.maps.LatLng(defaultLocation.lat, defaultLocation.lng),
    });
  };

  getGeoLocations = async () => {
    const { defaultLocation } = this.state;
    const {
      locations,
      google,
      latLngs,
      currentLocationId,
      observationsByGeoTarget,
    } = this.props;
    const largestCount = _.max(latLngs.map(latLng => latLng.COUNT));
    const points = [];

    locations.forEach(location => {
      let weight = 1;
      let count = 0;
      const locationData = latLngs.find(loc => loc.GEOTARGETID === location.id);
      const geoTarget = observationsByGeoTarget.find(
        observation => observation.GEO_TARGET_ID === location.id
      );
      let observations = 0;

      if (geoTarget) {
        observations = geoTarget.TOTAL;
      }

      if (locationData) {
        weight = computeHeatMapWeight(locationData.COUNT, largestCount);
        count = locationData.COUNT;
      }
      if (location.circles) {
        location.circles.forEach(circle => {
          points.push({
            name: location.name,
            location: new google.maps.LatLng(
              circle.coordinate.lat,
              circle.coordinate.lng
            ),
            weight,
            count,
            geoTargetId: geoTarget ? geoTarget.GEO_TARGET_ID : "",
            observations,
          });
        });
      }
      if (location.polygons) {
        location.polygons.forEach(polygon => {
          if (polygon.geometry && polygon.geometry.coordinates) {
            polygon.geometry.coordinates.forEach(coordinates => {
              const features = turf.featureCollection(
                coordinates.map(coordinatePair => turf.point(coordinatePair))
              );
              const center = turf.center(features);
              coordinates.forEach(([lng, lat]) =>
                points.push({
                  name: location.name,
                  location: new google.maps.LatLng(
                    center.geometry.coordinates[1],
                    center.geometry.coordinates[0]
                  ),
                  weight,
                  count,
                  geoTargetId: geoTarget ? geoTarget.GEO_TARGET_ID : "",
                  observations,
                })
              );
            });
          }
        });
      }
    });
    const focusPoints = getFocusPoints({
      currentLocationId,
      locations,
      points,
    });
    const bounds = getLatLngBounds({ latLngs: focusPoints, google });
    const boundsCenter = bounds.getCenter();
    this.setState({
      loading: false,
      bounds: bounds,
      locations: false,
      heatMapData: points,
      mapCenter: !isEmpty(locations)
        ? new google.maps.LatLng(boundsCenter.lat(), boundsCenter.lng())
        : new google.maps.LatLng(defaultLocation.lat, defaultLocation.lng),
    });
  };

  mapMounted = node => {
    this.mapNode = node;
  };

  render() {
    const { styles } = this.props;
    if (this.state.loading) return <LoadingContainer />;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          position: "relative",
          ...styles.outerContainer,
        }}
      >
        <div ref={this.mapMounted} style={styles.map} />
      </div>
    );
  }
}

export default GoogleApiWrapper(googleMapsConfig)(HeatMap);
