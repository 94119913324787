import React from "react";
import { Row, Col, Card } from "antd";

// Global Styles
import {
  KPITitleText,
  KpiSubText,
} from "../../../../shared/globalStyling/styledText";

import {
  formatWholeNumber,
  formatNumber,
  formatCurrency2SigFig,
} from "../../../../../core/utils/campaigns";

const DetailBar = ({
  impressions = 0,
  clicks = 0,
  phoneCalls = 0,
  searches = 0,
  locations = 0,
  transactions = 0,
  conversionRate = 0,
  ctr = 0,
  cpc = 0,
  roas = 0,
  revenue = 0,
  spend = 0,
  showPrices,
  currencyFormat,
}) => {
  const styles = {
    container: {
      backgroundColor: "#fff",
      borderTop: "solid 1px #e8e8e8",
      marginBottom: "1%",
    },
    secondaryContainer: {
      padding: "3% 3% 1% 3%",
      margin: 0,
    },
    title: {
      marginBottom: "10px",
    },
    keyColors: {
      spent: "#1665d8",
      remaining: "#34aa44",
      budget: "#030303",
      total: "#bec0c3",
      value: "#99999",
    },
  };
  return (
    <>
      <Row style={styles.container} wrap={false}>
        <Col xs={24}>
          <Row>
            {impressions !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Impressions"} />
                  </div>
                  <KpiSubText text={`${formatWholeNumber(impressions)}`} />
                </Card>
              </Col>
            )}
            {clicks !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Clicks"} />
                  </div>
                  <KpiSubText text={`${formatWholeNumber(clicks)}`} />
                </Card>
              </Col>
            )}
            {phoneCalls !== 0 && false && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Phone Calls"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(phoneCalls) ? formatWholeNumber(phoneCalls) : 0
                    }`}
                  />
                </Card>
              </Col>
            )}

            {transactions !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Transactions"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(transactions) ? formatWholeNumber(transactions) : 0
                    }`}
                  />
                </Card>
              </Col>
            )}
            {ctr !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"CTR"} />
                  </div>
                  <KpiSubText
                    text={`${!isNaN(ctr) ? formatNumber(ctr) : 0}%`}
                  />
                </Card>
              </Col>
            )}
            {conversionRate !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Conv. Rate"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(conversionRate) ? formatNumber(conversionRate) : 0
                    }%`}
                  />
                </Card>
              </Col>
            )}
            {showPrices && cpc !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"CPC"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(cpc)
                        ? formatCurrency2SigFig(cpc, currencyFormat)
                        : 0
                    }`}
                  />
                </Card>
              </Col>
            )}
            {showPrices && roas !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"ROAS"} />
                  </div>
                  <KpiSubText
                    text={`${!isNaN(roas) ? formatNumber(roas) : 0}%`}
                  />
                </Card>
              </Col>
            )}
            {revenue !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Revenue"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(revenue)
                        ? formatCurrency2SigFig(revenue, currencyFormat)
                        : 0
                    }`}
                  />
                </Card>
              </Col>
            )}
            {showPrices && spend !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Spend"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(spend)
                        ? formatCurrency2SigFig(spend, currencyFormat)
                        : 0
                    }`}
                  />
                </Card>
              </Col>
            )}
            {searches !== 0 && false && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Searches"} />
                  </div>
                  <KpiSubText text={`${formatWholeNumber(searches)}`} />
                </Card>
              </Col>
            )}
            {locations !== 0 && false && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Location Clicks"} />
                  </div>
                  <KpiSubText text={`${formatWholeNumber(locations)}`} />
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DetailBar;
