import React, { useState } from "react";
//import SquarePaymentCard from "../../../../shared/SquarePayments"; //Coming Soon
import { Modal, Select, message } from "antd";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";

import Button from "../../../../shared/globalStyling/Button";
const { Option } = Select;

const AmplifyView = props => {
  const [visible, setVisible] = useState(false);
  const [amplify, setAmplifyAmount] = useState(1000);

  const { campaignOrder, updateCampaignOrder } = props;
  const {
    name,
    budget,
    id,
    devices,
    kpi,
    status,
    cpm,
    type,
    advertiser,
  } = campaignOrder;

  const updateOrder = async () => {
    const total = parseInt(amplify) + parseInt(budget);

    const variables = {
      id,
      budget: parseInt(total),
      campaignName: name,
      devices,
      kpi,
      status,
      type,
      advertiser: advertiser.id,
    };

    const response = await updateCampaignOrder({ variables });

    if (response) {
      setVisible(false);
      message.success(
        "Your Boost has been submitted - Your Boost will activate on the next businesss day"
      );
    }
    // Your payment was unsuccesful due to the following error - call error from square
  };

  return (
    <div>
      {/* <Tooltip title="Boost refers to adding more funds to your campaign budget">
        <Button type="primary" ghost onClick={() => setVisible(true)}>
          Boost Campaign
        </Button>
      </Tooltip> */}

      <Modal
        title={name}
        visible={visible}
        centered
        onCancel={() => setVisible(false)}
        footer={null}
      >
        How much do you want to Boost your Campaign?
        <br />
        <br />
        Current Average CPM = ${cpm}
        <br />
        <br />
        <Select
          defaultValue={amplify}
          style={{ width: 120 }}
          onChange={val => {
            setAmplifyAmount(val);
            console.log(val);
          }}
        >
          <Option value={500}>$500</Option>
          <Option value={1000}>$1,000</Option>
          <Option value={1500}>$1,500</Option>
          <Option value={2000}>$2,000</Option>
          <Option value={2500}>$2,500</Option>
        </Select>
        <br />
        <br />
        <br />
        Total Esitmated Impressions -{" "}
        {formatWholeNumber((amplify / cpm) * 1000)}
        <br />
        <br />
        <Button
          type="primary"
          ghost
          onClick={() => {
            setVisible(true);
            updateOrder();
          }}
        >
          {`Confirm Boost!`}
        </Button>
        <br />
        <br />
        <br />
      </Modal>
    </div>
  );
};

export default AmplifyView;
