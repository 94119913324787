import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import GlobalButton from "../../../../shared/globalStyling/Button";
import { Row, Space } from "antd";
import { isEmpty } from "lodash";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import moment from "moment";

//Export Buttons on the top right of the page
const ExportButtons = ({
  exportData = false,
  reportData,
  currentTab = "summary",
  name = "",
}) => {
  if (exportData) {
    // Exported File Name
    const filename = encodeURIComponent(
      `${
        currentTab ? currentTab.replace(/[\W_]/g, "_") : "summary"
      }_${name}_${moment.utc().format("MMMM DD YYYY")}`
    );

    // Export Excel
    const handleExportExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(reportData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelData = new Blob([excelBuffer], { type: fileType });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(excelData);
      const decodedFileName = decodeURIComponent(filename) + ".xlsx";
      link.download = decodedFileName;
      link.click();
    };

    return (
      <React.Fragment>
        <Row>
          <Space className="ant-space-xs-vertical ant-space-md-horizontal ant-space-xs-gap-small ant-space-md-gap-middle">
            <GlobalButton disabled={isEmpty(reportData)} type="primary">
              <CSVLink filename={`${filename}.csv`} data={reportData}>
                <Space size="small" direction="horizontal">
                  <DownloadOutlined /> Export CSV
                </Space>
              </CSVLink>
            </GlobalButton>
            <GlobalButton
              disabled={isEmpty(reportData)}
              type="primary"
              onClick={handleExportExcel}
            >
              <Space size="small" direction="horizontal">
                <DownloadOutlined /> Export Excel
              </Space>
            </GlobalButton>
          </Space>
        </Row>
      </React.Fragment>
    );
  }
  //Don't show anything if exportData is false
  return <></>;
};

export default ExportButtons;
