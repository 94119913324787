import React from "react";
import { Card, Row, Spin } from "antd";
import TransactionsDetailBarV2 from "./components/TransactionsDetailBarV2";
import TransactionsDetailBar from "./components/TransactionsDetailBar";

const LoadingCard = () => {
  return (
    <Card>
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    </Card>
  );
};

const TransactionsTab = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  campaignOrder,
  chartData,
  byTransactionsRangeDate,
  setByTransactionsRangeDate,
  dataTransactionsRangeDate,
  setDataTransactionsRangeDate,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  dataAttributionWindow,
  setDataAttributionWindow,
  transactionTarget,
  dateTrue,
  setDateTrue,
  priceVisible,
  reportName,
  transactionsTable,
  transactionsTableLoading,
  attributionWindow,
  setCurrentIdx,
  setCurrentStartEndDate,
  setAttributionWindow,
  isDemo,
  currentUser,
  currencyFormat,
  embeddableConfig,
  attributionMethod,
  setAttributionMethod,
}) => {
  if (chartsLoading || chartsLoading === undefined || chartData === null) {
    return <LoadingCard />;
  }
  if (chartData && chartData.byDate) {
    return (
      <TransactionsDetailBarV2
        selectedCampaign={selectedCampaign}
        chartDataRaw={chartData}
        campaignOrder={campaignOrder}
        transactionTarget={transactionTarget}
        dateTrue={dateTrue}
        priceVisible={priceVisible}
        reportName={reportName}
        setDataAttributionWindow={setDataAttributionWindow}
        transactionsTable={transactionsTable}
        transactionsTableLoading={transactionsTableLoading}
        setCurrentIdx={setCurrentIdx}
        setCurrentStartEndDate={setCurrentStartEndDate}
        attributionWindow={attributionWindow}
        setAttributionWindow={setAttributionWindow}
        isDemo={isDemo}
        currentUser={currentUser}
        currencyFormat={currencyFormat}
        attributionMethod={attributionMethod}
        setAttributionMethod={setAttributionMethod}
      />
    );
  } else {
    return (
      <TransactionsDetailBar
        colStyle={colStyle}
        selectedCampaign={selectedCampaign}
        campaignOrder={campaignOrder}
        chartData={chartData}
        byTransactionsRangeDate={byTransactionsRangeDate}
        setByTransactionsRangeDate={setByTransactionsRangeDate}
        dataTransactionsRangeDate={dataTransactionsRangeDate}
        setDataTransactionsRangeDate={setDataTransactionsRangeDate}
        byCampaignRangeDate={byCampaignRangeDate}
        setByCampaignRangeDate={setByCampaignRangeDate}
        dataCampaignRangeDate={dataCampaignRangeDate}
        dataAttributionWindow={dataAttributionWindow}
        setDataAttributionWindow={setDataAttributionWindow}
        setDataCampaignRangeDate={setDataCampaignRangeDate}
        transactionTarget={transactionTarget}
        dateTrue={dateTrue}
        setDateTrue={setDateTrue}
        priceVisible={priceVisible}
        reportName={reportName}
        isDemo={isDemo}
      />
    );
  }
};

export default TransactionsTab;
