import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

// CONFIGS
import { TableConfig } from "../../../platform/shared/configs/TableConfig";

// QUERIES
import GET_REGION_GROUPS from "../../GraphQl/Queries/GET_REGION_GROUPS";
import GET_TARGETING_GROUPS from "../../GraphQl/Queries/GET_TARGETING_GROUPS";

const TargetingContext = createContext({});

export const TargetingProvider = props => {
  const { children } = props;
  const [targetingGroupsPagination, setTargetingGroupsPagination] = useState(
    TableConfig.pagination
  );

  // QUERIES
  const [
    fetchRegionGroups,
    { data: dataRegionGroups, loading: loadingRegionGroups },
  ] = useLazyQuery(GET_REGION_GROUPS, {});

  const [
    fetchTargetingGroups,
    { data: dataTargetingGroups, loading: loadingTargetingGroups },
  ] = useLazyQuery(GET_TARGETING_GROUPS, {});

  // EFFECTS
  useEffect(() => {
    if (dataTargetingGroups) {
      setTargetingGroupsPagination({
        ...targetingGroupsPagination,
        total: dataTargetingGroups.full.aggregate.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTargetingGroups]);

  return (
    <TargetingContext.Provider
      value={{
        fetchRegionGroups,
        regionGroups:
          dataRegionGroups && dataRegionGroups.regionGroups
            ? dataRegionGroups.regionGroups
            : [],
        loadingRegionGroups,
        fetchTargetingGroups,
        targetingGroups:
          dataTargetingGroups && dataTargetingGroups.audiencesConnection
            ? dataTargetingGroups.audiencesConnection.edges.map(
                edge => edge.node
              )
            : [],
        loadingTargetingGroups,
        targetingGroupsPagination,
        setTargetingGroupsPagination,
      }}
      {...props}
    >
      {children}
    </TargetingContext.Provider>
  );
};

export default TargetingContext;
