import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RetailStoresMainLoader from "../../../../core/components/retailStores/main/RetailStoresMainLoader";
import RetailStoresMainController from "../../../../core/components/retailStores/main/RetailStoresMainController";
import RetailStoresMainView from "../main/RetailStoresMainView";
import RetailStoreDetailsController from "../../../../core/components/retailStores/retailStoresDetails/RetailStoreDetailsController";
import RetailStoresDetailsView from "../main/RetailStoresDetailsView";
import { RetailStoresDetailsProvider } from "../context/RetailStoresContext";

const RetailStoresRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <RetailStoresMainLoader {...props}>
            <RetailStoresMainController>
              <RetailStoresMainView />
            </RetailStoresMainController>
          </RetailStoresMainLoader>
        )}
      />
      <Route
        exact
        path={`${path}/details/:id?`}
        render={() => (
          <RetailStoresDetailsProvider>
            <RetailStoreDetailsController>
              <RetailStoresDetailsView />
            </RetailStoreDetailsController>
          </RetailStoresDetailsProvider>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default RetailStoresRoutes;
