import gql from "graphql-tag";

const GET_ADGROUP_CREATIVES_LAUNCHER = gql`
  query adgroupmedias($orgId: ID!) {
    adGroupCreativeses(
      where: {
        OR: [
          { org_some: { id: $orgId } }
          { org_some: { parentOrg_some: { id: $orgId } } }
        ]
      }
    ) {
      id
      adProviderId
      name
      isAmplifyDefault
      medias(where: { flag: true }) {
        id
        name
        size
        fileType
        height
        width
        key
        url
        type
        org {
          id
          name
        }
        sharedWith {
          name
        }
        mediaNotes
        mediaHasNotes
      }
      flag
      org {
        id
        name
      }
    }
  }
`;

export default GET_ADGROUP_CREATIVES_LAUNCHER;
