/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Dropdown, Menu } from "antd";
import { GlobalContext } from "../../../../../core/context/GlobalContext";
import { isEmpty, cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import JSZip from "jszip";
// Global Styles
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { fillNamesWalkInByLineItemCreativeDates } from "../../../../../core/utils/campaigns";
import {
  prepareByDateDisplayExport,
  prepareByDateSummaryExport,
  prepareByDayDisplayExport,
  prepareByDeviceTypeDisplayExport,
  prepareByCreativeDisplayExport,
  prepareByPublisherDisplayExport,
  prepareTransactionsWithEventsExport,
  prepareTransactionsWithEventsDailyTotalExport,
  prepareByDateObservationsExport,
  exportDropdownKeyToText,
  prepareSignUpsWithImpressions,
  prepareInstallsWithImpressions,
  preparefirstTransactionsWithImpressions,
  prepareOnspotWalkInReports,
  prepareTransactionEventsCreatives,
  prepareTransactionEventsPublishers,
} from "../utils";
import { DownloadOutlined } from "@ant-design/icons";

const startDownload = (
  filteredExportData,
  tabExportKeys,
  hasData,
  getCampaignSummary,
  hasInsights,
  campaignName,
  chartData,
  campaigns,
  byTransactionsRangeDate,
  dataAttributionWindow,
  byCampaignRangeDate,
  bySummaryRangeDate,
  bySignUpRangeDate,
  key,
  data,
  rangeType
) => {
  try {
    const zip = new JSZip();

    const folder = zip.folder(`${campaignName.replace(/[\W_]/g, "_")}-${key}`); // replace special chars and spaces to underscore
    let fileData = data;

    Array.isArray(fileData) &&
      fileData.map(row => {
        delete row.cost;
        delete row.totalCost;
        delete row.orgs;
        return row;
      });

    let dupFileData = cloneDeep(fileData);
    if (key === "walkinByLineItemCreativeDate") {
      dupFileData = fillNamesWalkInByLineItemCreativeDates(
        chartData.creatives,
        campaigns,
        chartData.walkinByLineItemCreativeDate
      );
    } else if (key === "transactionsWithEvents") {
      dupFileData = prepareTransactionsWithEventsExport(
        dupFileData,
        byTransactionsRangeDate,
        dataAttributionWindow
      );
    } else if (key === "transactionsWithEventsDailyTotal") {
      dupFileData = prepareTransactionsWithEventsDailyTotalExport(
        chartData.transactionsWithEventsDailyTotal
      );
    } else if (key === "transactionEventsCreatives") {
      dupFileData = prepareTransactionEventsCreatives(
        chartData.transactionEventsCreatives
      );
    } else if (key === "transactionEventsPublishers") {
      dupFileData = prepareTransactionEventsPublishers(
        chartData.transactionEventsPublishers
      );
    } else if (key === "byDate") {
      dupFileData = prepareByDateDisplayExport(
        dupFileData,
        byCampaignRangeDate
      );
    } else if (key === "observationsByGeoTarget") {
      dupFileData = dupFileData.map(row => {
        delete row.ONSPOT_COUNT;
        delete row.UNACAST_COUNT;
        delete row.KOCHAVA_COUNT;
        delete row.BEACONS_COUNT;
        delete row.ONSPOT_COUNT;

        return row;
      });
    } else if (key === "completeAttributionSummary") {
      dupFileData = prepareByDateSummaryExport(
        dupFileData,
        bySummaryRangeDate,
        rangeType
      );
    } else if (key === "byDay") {
      dupFileData = prepareByDayDisplayExport(dupFileData);
    } else if (key === "deviceType") {
      dupFileData = prepareByDeviceTypeDisplayExport(dupFileData);
    } else if (key === "creatives") {
      dupFileData = prepareByCreativeDisplayExport(dupFileData);
    } else if (key === "publishers") {
      dupFileData = prepareByPublisherDisplayExport(dupFileData);
    } else if (key === "observationsByDate") {
      dupFileData = prepareByDateObservationsExport(dupFileData);
    } else if (key === "signUpsWithImpressions") {
      dupFileData = prepareSignUpsWithImpressions(
        dupFileData,
        bySignUpRangeDate
      );
    } else if (key === "installsWithImpressions") {
      dupFileData = prepareInstallsWithImpressions(dupFileData);
    } else if (key === "firstTransactionsWithImpressions") {
      dupFileData = preparefirstTransactionsWithImpressions(dupFileData);
    } else if (key === "onspotWalkInReports") {
      dupFileData = prepareOnspotWalkInReports(dupFileData);
    }

    if (key === "onspotWalkInReports") {
      folder.file(
        `${campaignName.replace(/[\W_]/g, "_")}-${exportDropdownKeyToText(
          key
        )}.csv`, // replace special chars and spaces to underscore
        dupFileData
      );
    } else {
      folder.file(
        `${campaignName.replace(/[\W_]/g, "_")}-${exportDropdownKeyToText(
          key
        )}.csv`, // replace special chars and spaces to underscore
        Papa.unparse(dupFileData)
      );
    }
    zip.generateAsync({ type: "blob" }).then(
      blob => {
        saveAs(
          blob,
          `${campaignName.replace(/[\W_]/g, "_")}-${exportDropdownKeyToText(
            // replace special chars and spaces to underscore
            key
          )}`
        );
        return true;
      },
      function(e) {
        console.log("error", e);
        return false;
      }
    );
  } catch (E) {
    console.log("CSV error:", E);
  }
};

const DropdownCSVButton = ({
  campaignName,
  campaignId,
  campaignType,
  chartsLoading,
  chartData,
  campaigns,
  isAdmin,
  byCampaignRangeDate,
  byTransactionsRangeDate,
  bySummaryRangeDate,
  bySignUpRangeDate,
  dataAttributionWindow,
  orgConfig,
  hasInsights,
  tabKey,
  rangeType,
  amplify,
  sitesAppsTransactions,
}) => {
  let hasData = false;
  let transactions = chartData.transactionsWithEvents;
  const context = useContext(GlobalContext);
  const getCampaignSummary = orgConfig && orgConfig.campaignSummary;

  const transactionEvents = sitesAppsTransactions
    ? [
        "transactionsWithEvents",
        "transactionsWithEventsDailyTotal",
        "transactionEventsCreatives",
        "transactionEventsPublishers",
      ]
    : ["transactionsWithEvents", "transactionsWithEventsDailyTotal"];

  // TODO : I initially tried to use the useEffect on this initialization but it requires me to refactor the whole component to suit the changes
  // specifically how the menu are constructed. I will leave this as is for now and will refactor this in the future.
  if (transactions) {
    let groupedTransactions = transactions.reduce(
      (accumulator, transaction) => {
        // Extract date from transaction timestamp
        const transactionDate = transaction.transaction.split(" ")[0];

        // Check if the date exists in the accumulator
        if (!accumulator[transactionDate]) {
          accumulator[transactionDate] = {
            transactionDate: transactionDate,
            numberOfTransactions: 0,
            transactionTotal: 0,
            transactionTax: 0,
            transactionShipping: 0,
          };
        }

        // Update values for the current date
        accumulator[transactionDate].numberOfTransactions += 1;
        accumulator[transactionDate].transactionTotal +=
          transaction.transactionTotal;
        accumulator[transactionDate].transactionTax +=
          transaction.transactionTax;
        accumulator[transactionDate].transactionShipping +=
          transaction.transactionShipping;

        return accumulator;
      },
      {}
    );

    // Convert the grouped transactions object to an array
    let newTransactions = Object.values(groupedTransactions);

    // Update the ChartData object with the new transactions array
    chartData = {
      ...chartData,
      transactionsWithEventsDailyTotal: newTransactions,
      transactionEventsCreatives: transactions,
      transactionEventsPublishers: transactions,
    };
  }

  const tabKeyExportMap = {
    transactions: transactionEvents,
    overview: ["byDate", "deviceType", "publishers", "creatives", "byDay"],
    summary: ["completeAttributionSummary", "lineItems"],
    signups: [
      "signUpsWithImpressions",
      "installsWithImpressions",
      "firstTransactionsWithImpressions",
    ],
    attribution: ["observationsByDate", "onspotWalkInReports"],
  };

  const tabExportKeys = tabKeyExportMap[tabKey] || [];
  const filteredExportData = chartData
    ? Object.keys(chartData)
        .filter(key => tabExportKeys.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: chartData[key],
          };
        }, {})
    : {};

  const checkDisableExport = () => {
    let disabled = false;
    if (Object.keys(filteredExportData).length < 1) {
      disabled = true;
    } else {
      Object.values(filteredExportData).forEach(i => {
        if (
          (Array.isArray(i) && i.length > 0) ||
          (typeof i === "object" && Object.keys(i).length > 0)
        ) {
          disabled = false;
          return disabled;
        } else {
          disabled = true;
        }
      });
    }
    return disabled;
  };

  const menu = (
    <Menu>
      {filteredExportData &&
        Object.entries(filteredExportData)
          .filter(i => i[0] !== "displayReports")
          .reduce((acc, [key, data], index) => {
            if (tabExportKeys.indexOf(key) < 0) {
              return acc;
            }
            if (
              (Array.isArray(data) && !isEmpty(data)) ||
              (typeof data === "object" && Object.keys(data).length > 0)
            ) {
              hasData = true;
              if (
                (key === "completeAttributionSummary" &&
                  getCampaignSummary !== true) ||
                key === "lineItems"
              ) {
                return acc;
              }
              if ((key !== "publishers" && !hasInsights) || hasInsights) {
                acc.push(
                  <Menu.Item key={`setting:${index + 1}`}>
                    <div
                      onClick={async () => {
                        await startDownload(
                          filteredExportData,
                          tabExportKeys,
                          hasData,
                          getCampaignSummary,
                          hasInsights,
                          campaignName,
                          chartData,
                          campaigns,
                          byTransactionsRangeDate,
                          dataAttributionWindow,
                          byCampaignRangeDate,
                          bySummaryRangeDate,
                          bySignUpRangeDate,
                          key,
                          data,
                          rangeType
                        );
                      }}
                    >
                      {exportDropdownKeyToText(key)}
                    </div>
                  </Menu.Item>
                );
              }
            }
            return acc;
          }, [])}
      {isAdmin &&
        campaignType === "DISPLAY" &&
        !isEmpty(chartData.walkinByLineItem) && (
          // TODO: Make this work for all campaign types
          <Menu.Item key={"setting:999"}>
            <div
              onClick={() =>
                context.requestDisplayReportFull(
                  {
                    variables: { campaignOrderId: campaignId },
                  },
                  `${campaignName}-Admin_Data_Complete-${new Date().toISOString()}`
                )
              }
            >
              Admin Data Complete
            </div>
          </Menu.Item>
        )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      disabled={checkDisableExport()}
      trigger={["click"]}
      placement="bottomCenter"
    >
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        disabled={!hasData}
        loading={chartsLoading}
      >
        <ButtonText text={"Export CSV"} />
      </Button>
    </Dropdown>
  );
};

DropdownCSVButton.propTypes = {
  attributionReportId: PropTypes.string,
  displayReportId: PropTypes.string,
  displayReport: PropTypes.object,
  attributionReport: PropTypes.object,
  campaignName: PropTypes.string.isRequired,
};

export default DropdownCSVButton;
