import React from "react";
import {
  Table,
  Switch,
  Input,
  Select,
  Divider,
  Row,
  Col,
  Typography,
} from "antd";

import { dataConfigNaming } from "../../../../core/utils/constants/constants";

const Option = Select.Option;
const InputGroup = Input.Group;

const ORDER_KEY = [
  "tradeDeskIdentifier",
  "storageBucket",
  "googleCustomerId",
  "seo",
  "semrushEnable",
  "semrushConfig",
  "googleAnalytics",
  "bing",
  "tagProcessingConfig",
  "paidSearchDataConfig",
];

// List of configs to be excluded from the table
const EXCLUDED_CONFIG = [
  "roasFormat",
  "defaultDisplayAttributionWindow",
  "defaultPaidSearchAttributionWindow",
  "defaultSEOAttributionWindow",
];

const swapItemsByOrder = (data, orderKey) => {
  const indexMap = new Map();

  // Create a map of item names to their current indices in the data array
  data.forEach((item, index) => {
    const name = item.name[0]; // Assuming name is always an array with one item
    indexMap.set(name, index);
  });

  // Sort the data array based on the orderKey array
  data.sort((a, b) => {
    const nameA = a.name[0];
    const nameB = b.name[0];

    const indexA = orderKey.indexOf(nameA);
    const indexB = orderKey.indexOf(nameB);

    // If the item is not found in the orderKey array, keep the original order
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });

  return data;
};

const DataConfig = props => {
  const {
    dataConfig,
    loggedInPermissions,
    disabled,
    onSwitchChange,
    hideFields,
  } = props;

  const hiddenFields = hideFields
    ? hideFields
    : [
        "menuId",
        "attributionWindow",
        "dspAdvertiserId",
        "reTargeting",
        "currency",
      ];

  const style = {
    container: {
      margin: "5px",
      overflow: "auto",
    },
  };

  const columns = [
    {
      title: "Data Configs",
      width: "20%",
      key: "name",
      dataIndex: "name",
      render: text => <div>{dataConfigNaming[text]}</div>,
    },

    {
      title: "Permissions",
      width: "50%",
      dataIndex: "name",
      key: "checked",
      value: "value",
      render: text => (
        <div>
          {(() => {
            if (text[0] === "reTargeting") {
              return (
                <Switch
                  checked={dataConfig[text] ? loggedInPermissions[text] : false}
                  name={`${text}-DATACONFIG`}
                  onChange={checked => {
                    onSwitchChange(checked, `${text}-DATACONFIG`);
                  }}
                  disabled={disabled}
                />
              );
            } else if (text[0] === "appIds" && dataConfig["reTargeting"]) {
              return (
                <InputGroup compact>
                  <Select
                    style={{ minWidth: 200 }}
                    value={loggedInPermissions[text]}
                    onChange={value => {
                      props.onChange(text, value, "DATACONFIG");
                    }}
                    mode="tags"
                    disabled={disabled}
                  >
                    {props.loggedInPermissions[text]
                      ? props.loggedInPermissions[text].map((option, index) => {
                          return (
                            <Option key={index} value={option}>
                              {option}
                            </Option>
                          );
                        })
                      : []}
                  </Select>
                </InputGroup>
              );
            } else if (text[0] === "semrushEnable") {
              return (
                <Switch
                  checked={dataConfig[text] ? loggedInPermissions[text] : false}
                  name={`${text}-DATACONFIG`}
                  onChange={checked => {
                    onSwitchChange(checked, `${text}-DATACONFIG`);
                  }}
                  disabled={disabled}
                />
              );
            } else if (text[0] === "semrushConfig") {
              const { semrushConfig } = dataConfig;

              return (
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Input
                      placeholder={"Project ID"}
                      value={semrushConfig ? semrushConfig["projectId"] : null}
                      name={"projectId"}
                      disabled={disabled || !dataConfig["semrushEnable"]}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "semrushConfig"
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      placeholder={"Root Domain URL (Optional)"}
                      value={
                        semrushConfig ? semrushConfig["rootDomainUrl"] : null
                      }
                      name={"rootDomainUrl"}
                      disabled={disabled || !dataConfig["semrushEnable"]}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "semrushConfig"
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      placeholder={"Keyword (Optional)"}
                      value={
                        semrushConfig ? semrushConfig["rootKeyword"] : null
                      }
                      name={"rootKeyword"}
                      disabled={disabled || !dataConfig["semrushEnable"]}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "semrushConfig"
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      placeholder={"Competitor Root Domain URL (Optional)"}
                      value={
                        semrushConfig
                          ? semrushConfig["competitor1stRootDomainUrl"]
                          : null
                      }
                      name={"competitor1stRootDomainUrl"}
                      disabled={disabled || !dataConfig["semrushEnable"]}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "semrushConfig"
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      placeholder={"Competitor Root Domain URL (Optional)"}
                      value={
                        semrushConfig
                          ? semrushConfig["competitor2ndRootDomainUrl"]
                          : null
                      }
                      name={"competitor2ndRootDomainUrl"}
                      disabled={disabled || !dataConfig["semrushEnable"]}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "semrushConfig"
                        );
                      }}
                    />
                  </Col>
                </Row>
              );
            } else if (text[0] === "bing") {
              const { bing } = dataConfig;
              return (
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Input
                      placeholder={"Customer ID"}
                      value={bing ? bing["customerId"] : null}
                      name={"customerId"}
                      disabled={disabled}
                      onChange={({ target }) => {
                        props.onChange([target.name], target.value, "bing");
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      placeholder={"Account ID"}
                      value={bing ? bing["accountId"] : null}
                      name={"accountId"}
                      disabled={disabled}
                      onChange={({ target }) => {
                        props.onChange([target.name], target.value, "bing");
                      }}
                    />
                  </Col>
                </Row>
              );
            } else if (text[0] === "tagProcessingConfig") {
              return (
                <Row gutter={[12, 12]}>
                  <Col>
                    <Switch
                      checked={
                        dataConfig[text]
                          ? loggedInPermissions[text].processTag
                          : false
                      }
                      name={`${text}-DATACONFIG`}
                      onChange={checked => {
                        onSwitchChange(
                          checked,
                          `${text}-DATACONFIG`,
                          `processTag`
                        );
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              );
            } else if (text[0] === "paidSearchDataConfig") {
              return (
                <Row gutter={[12, 12]}>
                  <Col>
                    <Switch
                      checked={
                        dataConfig[text]
                          ? loggedInPermissions[text].isEnabled
                          : false
                      }
                      name={`${text}-DATACONFIG`}
                      onChange={checked => {
                        onSwitchChange(
                          checked,
                          `${text}-DATACONFIG`,
                          `isEnabled`
                        );
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              );
            } else {
              if (text[0] !== "appIds") {
                if (text[0] === "seo") {
                  return (
                    <Switch
                      checked={
                        dataConfig[text] ? loggedInPermissions[text] : false
                      }
                      name={`${text}-DATACONFIG`}
                      onChange={checked => {
                        onSwitchChange(checked, `${text}-DATACONFIG`);
                      }}
                      disabled={disabled}
                    />
                  );
                } else if (text[0] === "googleAnalytics") {
                  const viewId = loggedInPermissions[text]
                    ? loggedInPermissions[text].viewId
                    : "";
                  const isGA4 = loggedInPermissions[text]
                    ? loggedInPermissions[text].isGA4
                    : false;
                  return (
                    <Row gutter={[16, 16]}>
                      <Col lg={24}>
                        <Input
                          placeholder={"ViewId"}
                          value={viewId}
                          name={"viewId"}
                          disabled={disabled}
                          onChange={({ target }) => {
                            props.onChange(
                              [target.name],
                              target.value,
                              "googleAnalytics"
                            );
                          }}
                        />
                      </Col>
                      <Col lg={24}>
                        <Row gutter={[12, 12]}>
                          <Col>
                            <Typography.Text>UA</Typography.Text>
                          </Col>
                          <Col>
                            <Switch
                              disabled={disabled}
                              checked={isGA4}
                              onChange={checked => {
                                props.onChange(
                                  ["isGA4"],
                                  checked,
                                  "googleAnalytics"
                                );
                              }}
                            />
                          </Col>
                          <Col>
                            <Typography.Text>GA4</Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Input
                      placeholder={text}
                      value={loggedInPermissions[text]}
                      name={text}
                      disabled={disabled}
                      onChange={({ target }) => {
                        props.onChange(
                          [target.name],
                          target.value,
                          "DATACONFIG"
                        );
                      }}
                    />
                  );
                }
              }
            }
          })()}
        </div>
      ),
    },
  ];

  let tableDataPermissions = [];
  let hideAppIds = false;

  try {
    if (!loggedInPermissions.reTargeting) {
      hideAppIds = true;
    }
    Object.values(loggedInPermissions).forEach((val, i) => {
      if (!EXCLUDED_CONFIG.includes(Object.keys(loggedInPermissions)[i])) {
        if (
          Object.keys(loggedInPermissions)[i] === "__typename" ||
          (Object.keys(loggedInPermissions)[i] === "appIds" && hideAppIds)
        ) {
        } else {
          const record = {
            name: [Object.keys(loggedInPermissions)[i]],
            checked: dataConfig[Object.keys(loggedInPermissions)[i]],
            key: i,
            value: val,
          };

          tableDataPermissions.push(record);

          if (hiddenFields.includes(record.name[0])) {
            tableDataPermissions.pop(record);
          }
        }
      }
    });
  } catch (e) {
    console.log(e);
  }

  tableDataPermissions = swapItemsByOrder(tableDataPermissions, ORDER_KEY);

  const table = (
    <div style={style.container}>
      <Table
        columns={columns}
        bordered
        dataSource={tableDataPermissions}
        pagination={false}
      />
      <Divider />
    </div>
  );

  return tableDataPermissions.length ? table : null;
};

export default DataConfig;
