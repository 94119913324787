import React, { useEffect, useState } from "react";
import { Card, Row, Select, Empty, Tabs, Spin, Grid, Col } from "antd";
import { isEmpty, startCase } from "lodash";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import DatePickerField from "../../../../../../../shared/DatePickerField";
import moment from "moment";
// Global Styles
import {
  MenuItemTextColor,
  TabText,
  TooltipText,
} from "../../../../../../../shared/globalStyling/styledText";
import { useByDateFilter } from "../../../../../../../../core/hooks/ByDateFilter";
import { styles } from "./Charts.style";

const Frag = React.Fragment;
const { Option } = Select;

const TabPane = Tabs.TabPane;

const SummaryChart = ({
  byDay,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  setDataSummaryRangeDate,
  reportData,
  showTransactions,
  showAttribution,
  chartsBuilt,
  setRangeType,
  rangeType,
  dataSummaryRangeDate,
  currencyFormat,
}) => {
  // map the chart and return the dates with total summary
  let validOptions = ["clicks", "impressions", "ctr"];

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const walkInsOptions = ["walkIns", "observations"];
  const transactionOptions = ["transactionTotal", "transactionCount"];

  const {
    rangeKey,
    setRangeKey,
    filteredData,
    startDateEndDate,
    setCustomDateRange,
  } = useByDateFilter(reportData, rangeType);

  useEffect(() => {
    setRangeType(rangeKey);
    setDataSummaryRangeDate(filteredData);
  }, [filteredData, rangeKey, setDataSummaryRangeDate, setRangeType]);

  if (showTransactions) {
    validOptions = validOptions.concat(transactionOptions);
  }

  if (showAttribution) {
    validOptions = validOptions.concat(walkInsOptions);
  }

  const [leftY, setLeftY] = useState(startCase(validOptions[0]));
  const [rightY, setRightY] = useState(startCase(validOptions[1]));

  const DateSelector = ({ data, screens }) => {
    // map xAxis dates
    return (
      <>
        <Row>
          <DatePickerField
            setCustomDateRange={setCustomDateRange}
            data={filteredData}
            defaultPickerValue={startDateEndDate}
            defaultValue={startDateEndDate}
            value={
              isEmpty(bySummaryRangeDate)
                ? startDateEndDate
                : bySummaryRangeDate
            }
            onChange={v => {
              setBySummaryRangeDate(v.dates);
            }}
            dropDownValue={rangeKey}
            screens={screens}
            setDropDownValue={setRangeKey}
          />
        </Row>
      </>
    );
  };

  const operations = (
    <span style={{ fontSize: 10 }}>
      {chartsBuilt ? (
        <TooltipText text={`Updated ${moment(chartsBuilt).calendar()}`} />
      ) : (
        <Spin />
      )}
    </span>
  );

  const YAxisSelector = ({
    displayDateSelector,
    displayRightSelector,
    data,
  }) => {
    const keys = Object.keys(data[0])
      .filter(el => validOptions.includes(el))
      .map(option => {
        return option === "cpc" ||
          option === "cpm" ||
          option === "ctr" ||
          option === "ecpm"
          ? option.toUpperCase()
          : startCase(option);
      })
      .filter(el => el !== "Creativeurl");

    return (
      <Row style={{ marginBottom: "2%" }} justify="space-between" between="xs">
        <Col xs={24} md={6} lg={5} xl={6} xxl={6}>
          <Select
            onSelect={value => setLeftY(value)}
            style={{
              width: screens.xs === true ? "100%" : "50%",
              marginBottom: "0.4em",
            }}
            defaultValue={leftY}
          >
            {keys
              .filter(op => op !== rightY)
              .map(option => {
                return (
                  <Option key={option} value={option}>
                    <MenuItemTextColor text={option} color={"#7dafff"} />
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col
          xs={24}
          md={12}
          lg={13}
          xl={12}
          xxl={12}
          style={styles.dateRangeColStyle}
        >
          {displayDateSelector ? (
            <DateSelector data={reportData} screens={screens} />
          ) : null}
        </Col>
        <Col xs={24} md={5} lg={5} xl={6} xxl={6}>
          {" "}
          {displayRightSelector ? (
            <Select
              onSelect={value => {
                setRightY(value);
              }}
              style={{
                width: screens.xs === true ? "100%" : "50%",
                float: screens.xs === true ? "left" : "right",
                marginBottom: "0.4em",
                marginLeft: "auto",
                marginRight: 0,
              }}
              defaultValue={rightY}
            >
              {keys
                .filter(op => op !== leftY)
                .map(option => {
                  return (
                    <Option key={option} value={option}>
                      <MenuItemTextColor text={option} color={"#92d5a7"} />
                    </Option>
                  );
                })}
            </Select>
          ) : null}
        </Col>
      </Row>
    );
  };

  // if chartdata is equal to empty
  const emptyCharts = isEmpty(reportData);
  if (emptyCharts)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <DateSelector data={filteredData} />
        </Row>
        {filteredData && filteredData.length <= 0 && (
          <>
            <Row type="flex" justify="center" align="middle">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          </>
        )}
      </Card>
    );
  return (
    <div>
      <Card style={styles.container} bodyStyle={{ padding: 18 }}>
        <Tabs
          tabBarExtraContent={operations}
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={styles.cssStyle}
          onChange={activeKey => {
            if (activeKey === "4") setLeftY("Clicks");
            if (activeKey !== "4") {
              setLeftY("Impressions");
              setRightY("Transaction Count");
            }
          }}
        >
          {!isEmpty(reportData) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                <Frag>
                  <YAxisSelector
                    displayRightSelector={true}
                    displayDateSelector={true}
                    data={reportData}
                  />
                  <Row
                    style={{ marginBottom: "2%" }}
                    type="flex"
                    justify="center"
                  ></Row>
                  {dataSummaryRangeDate.length !== 0 &&
                    validOptions.length > 1 && (
                      <DualAxisAreaChart
                        leftY={isEmpty(leftY) ? validOptions[0] || "" : leftY}
                        rightY={
                          isEmpty(rightY) ? validOptions[1] || "" : rightY
                        }
                        dualChartData={dataSummaryRangeDate}
                      />
                    )}
                </Frag>

                {(filteredData.length === 0 ||
                  (bySummaryRangeDate &&
                    bySummaryRangeDate.length &&
                    filteredData.length === 0)) && <Empty />}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDay) ? (
            <TabPane tab={<TabText text={"Day Of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDay.length === 0 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDay.length !== 0 && (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={false}
                      data={byDay}
                    />
                    <Row
                      style={{ marginBottom: "2%" }}
                      type="flex"
                      justify="center"
                    ></Row>
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? "Impressions" : leftY}
                      rightY={isEmpty(rightY) ? "Clicks" : rightY}
                      dualChartData={byDay}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default SummaryChart;
