import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useRetailStoresDetailsContext } from "../../../../platform/ux/RetailStores/context/RetailStoresContext";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";
import { message } from "antd";
import { parseOverlayToGeoTarget } from "../../../utils/geoTargets";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import moment from "moment";

const RetailStoresDetailsController = props => {
  const {
    locations,
    updateLocation,
    updateLocationWithGeotargets,
    createGeoTargetLocation,
    refetch,
    currentUser,
    loading,
    permission,
    userLoading,
  } = useRetailStoresDetailsContext();

  const client = useApolloClient();

  if (loading || !locations || !currentUser || !permission || userLoading) {
    return <OverlaySpinner />;
  }

  const geoframe = locations.locations[0].geoframe;
  let shapes = [];
  shapes.push(geoframe);

  const onUpdateRetailLocation = async data => {
    const { role, id: userId } = currentUser;

    const inProgressOverlay = data.inProgressOverlay;

    let circles = [],
      polygons = [];

    if (inProgressOverlay && inProgressOverlay.length > 0) {
      const parsedData = parseOverlayToGeoTarget(inProgressOverlay);
      circles = parsedData.circles;
      polygons = parsedData.polygons;
    }

    try {
      message.loading("Updating location...", 0);

      await client.clearStore();

      let geoId = "";

      await createGeoTargetLocation({
        variables: {
          name: data.locationName,
          orgID: role.org.id,
          isBase: true,
          circles: circles,
          polygons: polygons,
          start: moment()
            .subtract(90, "days")
            .format(),
          end: moment().format(),
        },
      })
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.createGeoTarget &&
            response.data.createGeoTarget.id
          ) {
            geoId = response.data.createGeoTarget.id;
          }
        })
        .catch(error => {
          console.error("Failed to create GeoTarget:", error);
        });

      const variables = {
        id: locations.locations[0].id,
        name: data.locationName,
        description: data.locationDescription,
        street: data.locationAddress,
        city: data.locationCity,
        state: data.locationState,
        country: data.locationCountry,
        email: data.email || "",
        url: data.url || "",
        phone: data.phone || "",
        contact: data.contact || "",
        formattedAddress: data.formattedAddress,
        zip: data.locationZip,
        cart: data.locationCart,
        menuDataKey: data.menuDataKey || null,
        locationKey: data.locationKey,
        lat: data.lat,
        lng: data.lng,
        audienceType: data.locationAudienceType,
        updatedBy: userId,
        geoId,
      };

      if (variables.country !== "USA" && variables.country !== "CANADA") {
        delete variables.state;
      } else {
        delete variables.province;
      }
      const result =
        circles.length !== 0 || polygons.length !== 0
          ? await updateLocationWithGeotargets({
              variables: variables,
            })
          : await updateLocation({ variables });

      if (result.data) {
        message.destroy();
        message.success("Location updated successfully");
        refetch();
        window.location.reload();
      }
    } catch (err) {
      message.destroy();
      message.error("Error updating location:", err);
      throw err;
    }
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    onUpdateRetailLocation,
    google: props.google,
    shapes,
  });
};

export default GoogleApiWrapper(googleMapsConfig)(
  RetailStoresDetailsController
);
