import React from "react";
import { Table, Avatar, Checkbox } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export default function TargetingLocationPreviewImportTable({
  data,
  selectLocation,
}) {
  const tableColumns = [
    {
      title: "MATCH?",
      dataIndex: "matched",
      key: "matched",
      width: "15%",
      render: (text, record) => {
        const { matched } = record;
        const styles = {
          match: {
            color: "#57ae62",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
          partial: {
            color: "#f6ae3b",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
          noMatch: {
            color: "#c43b40",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
        };
        return (
          <Avatar
            style={matched ? styles.match : styles.noMatch}
            icon={matched ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          />
        );
      },
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "23.33%",
      render: text => {
        return <div>{text}</div>;
      },
    },
    {
      title: "SOURCE ADDRESS",
      dataIndex: "sourceAddress",
      key: "sourceAddress",
      width: "23.33%",
      render: text => {
        return <div>{text}</div>;
      },
    },
    {
      title: "FOUND ADDRESS",
      dataIndex: "formattedAddress",
      key: "formattedAddress",
      width: "20%",
      render: (text, record) => {
        return (
          <div>
            {text ? (
              text
            ) : (
              <span style={{ fontStyle: "italic" }}>
                Google could not find a corresponding address.
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "WARNINGS",
      dataIndex: "errorMessage",
      key: "errorMessage",
      width: "15%",
      render: text => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "ADD ADDRESS?",
      dataIndex: "addAddress",
      key: "addAddress",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            {record.matched && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Checkbox
                  onChange={() => {
                    selectLocation(record.id);
                  }}
                  defaultChecked={record.matched ? true : false}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <Table
      pagination={false}
      columns={tableColumns}
      dataSource={data.sort((a, b) => a.id - b.id)}
      // Setting scroll causes an issue with the way the table renders. Will use when figured out
      scroll={{ y: 300 }}
      rowKey="id"
    />
  );
}
