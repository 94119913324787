import React, { useState, useEffect } from "react";
import PaginationTable from "../../../shared/PaginationTable";
import { Input, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const CampaignTrackings = ({ selectedCampaignOrder }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (selectedCampaignOrder && selectedCampaignOrder.transactionsConfig) {
      const { transactionsConfig } = selectedCampaignOrder;
      const tagData = transactionsConfig.tag || null;
      const tag = {
        name: tagData ? tagData.name : null,
        appId: transactionsConfig.appId,
        cart: transactionsConfig.cart,
        generatedTag: tagData ? tagData.generatedTag : null,
        segmentName: tagData ? tagData.segmentName : null,
        segmentId: tagData ? tagData.segmentId : null,
      };
      setTags([tag]);
    }
  }, [selectedCampaignOrder]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "App ID",
      dataIndex: "appId",
      key: "appId",
    },
    {
      title: "Cart Platform",
      dataIndex: "cart",
      key: "cart",
    },
    {
      title: "Generated Tag",
      dataIndex: "generatedTag",
      key: "generatedTag",
      render: (generatedTag, val) => {
        const getEnvironment = cart => {
          let environment = "";
          switch (cart) {
            case "":
            case "NOCART":
            case "THIRDPARTY":
              break;
            case "DUTCHIEJS":
              environment = `&environment=dutchie-iframe`;
              break;
            case "DUTCHIE":
              environment = `&environment=dutchie-subdomain`;
              break;
            case "SHOPIFYUNIVERSAL":
              environment = `&environment=shopify`;
              break;
            default:
              environment = `&environment=${cart.toLowerCase()}`;
              break;
          }
          return environment;
        };

        const customSnippets = snippet => {
          const woocommerce = `function woocommerce_tracker() { ?>
        
        <!-- Header scripts go here -->
          ${snippet}
        <!-- Header scripts go here -->    
        
        <?php
          $product = array();
            global $wp_query;
            if( !empty( $wp_query->query_vars[ 'order-received' ] ) ) {
            $order = wc_get_order( $wp_query->query_vars[ 'order-received' ] );
            $items = $order->get_items();
            if(!empty($items)) {
              foreach($items as $item) {
                $product[] = $item->get_data();
              }
            }
        
            $check = get_post_meta( $order->get_id(), 'thank_you_page_check', true );
            if ( $check ) {
              return;
            }
            update_post_meta( $order->get_id(), 'thank_you_page_check', true );
        ?>
            <script> 
              var transactionOrder = <?php echo $order; ?>;
              var transactionItems = <?php echo json_encode($product); ?>;
            </script>
        <?php }
        }
        add_action( 'wp_head', 'woocommerce_tracker' );`;

          return { woocommerce };
        };

        const newShopify = shopifySnippet => {
          const shopifyCode = `
          analytics.subscribe("checkout_completed", (event) => {
            try {
                var products = event.data.checkout;
                var lineItems = products.lineItems;
        
                window.transactionOrder = products;
                window.transactionItems = lineItems;
              
                window.tracker(
                  "addTrans",
                  products.order.id,
                  "N/A",
                  parseFloat(products.totalPrice.amount),
                  parseFloat(products.totalTax.amount) || 0,
                  parseFloat(products.shippingLine.price.amount) || 0,
                  "N/A",
                  "N/A",
                  "N/A",
                  products.currencyCode || "USD"
                );
                lineItems.forEach(function (item) {
                  const productItem = item.variant;
        
                  window.tracker(
                    "addItem",
                    item.id,
                    productItem.sku || "N/A",
                    productItem.product.title || "N/A",
                    "N/A",
                    parseFloat(productItem.price.amount) || 0,
                    parseInt(item.quantity) || 1,
                    products.currencyCode || "USD"
                  );
                });
                window.tracker("trackTrans");
              } catch (error) {
              }
            });
        
            var tag = window.document.createElement("script");
            tag.src = ${shopifySnippet}
            tag.defer = true;
            document.head.appendChild(tag);
          `;

          return { shopifyCode };
        };

        const environment = getEnvironment(val.cart);
        const shopifySnippet = `"https://tags.cnna.io/?segmentId=${val.segmentId}&appId=${val.appId}${environment}";`;

        const evaluateSnippet = ({ snippet }) => {
          if (val.cart === "WOOCOMMERCE")
            return customSnippets(snippet).woocommerce;
          if (val.cart === "SHOPIFYUNIVERSAL")
            return newShopify(shopifySnippet).shopifyCode;
          return snippet;
        };

        const baseUrl = `https://tags.cnna.io/?segmentId=${val.segmentId}&appId=${val.appId}${environment}`;
        const snippet = `<script type="text/javascript" src="${baseUrl}"></script>`;
        const evalutedSnippet = evaluateSnippet({ snippet: snippet });

        const handleCopyToClipBoard = () => {
          navigator.clipboard.writeText(evalutedSnippet);
          message.success("Copied to clipboard");
        };
        return (
          <Input
            value={evalutedSnippet}
            suffix={<CopyOutlined onClick={handleCopyToClipBoard} />}
          />
        );
      },
    },
    {
      title: "Segment Name",
      dataIndex: "segmentName",
      key: "segmentName",
    },
  ];
  return (
    <div>
      <PaginationTable
        columns={columns}
        data={tags}
        bordered={false}
        rowSelection={null}
        paginationSize={"default"}
        scroll={{ x: 1200 }}
        size={"default"}
        current={currentPage}
        pageSize={10}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          setCurrentPage(paginationObject.current);
        }}
        rowKey="appId"
        style={{ marginTop: 12 }}
      />
    </div>
  );
};

export default CampaignTrackings;
