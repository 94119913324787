import React from "react";
import { message } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { parseOverlayToGeoTarget } from "../../utils/geoTargets";
import { isEmpty, camelCase } from "lodash";
import { GoogleApiWrapper } from "google-maps-react";

// Local Imports
import OrgConfigView from "../../../platform/ux/OrgConfig/OrgConfigView";

import GET_LOCATIONS from "../../../core/GraphQl/Queries/GET_LOCATIONS";
import GET_ORG_TAGS from "../../GraphQl/Queries/GET_ORG_TAGS";

import {
  defaultRadius,
  errorType,
  stateList,
  defaultRoasFormat,
  AmplifyDefault,
  mediajelOrgIdProduction,
  mediajelOrgIdStaging,
  defaultAttributionWindow,
  defaultCurrencyFormat,
} from "../../utils/constants/constants";
import { OrganizationConfig } from "../../../platform/ux/OrgConfig/configs/organizationConfig";
import googleMapsConfig from "../../../platform/shared/maps/googlemaps";
import {
  getFormattedAddress,
  getGeoCodes,
} from "../../../platform/shared/maps/utils/mapUtils";
import { MapConfig } from "../../../platform/shared/maps/configs/mapConfigs";
import { encryptStringSHA256 } from "../../utils/encryptString";
import { configType, permission } from "../../utils/constants/constants";

const { DATACONFIG, CONFIG } = configType;
const { READ, WRITE } = permission;

class Controller extends React.Component {
  state = {
    selectedOrg: null,
    retailLocationList: [],
    loading: true,
    // TODO: Storing state(parentState) from the form can be enhance,
    // we can useForm from antd and just pass the data from the form to the controller
    // and perform actions
    parentState: {
      selectedLicense: {},
      zoomLevel: 10,
      initialLocation: {
        latitude: "",
        longitude: "",
      },
      polygons: [],
      name: "",
      street: "",
      city: "",
      zip: "",
      tags: [],
      start: "",
      end: "",
      lat: null,
      lng: null,
      formattedAddress: "",
      state: "",
      country: "",
      description: "",
      locationLoading: false,
      email: "",
      contact: "",
      phone: "",
      url: "",
      hrs: "",
      locationId: "",
      geoFrameId: "",
      tablet: window.matchMedia("(max-width: 1100px)").matches,
      locationLoaded: false,
      id: "",
      tempName: "",
      selectedGeoTargets: [],
      inProgressOverlay: [],
      cart: "",
      menuDataKey: "",
      geoframe: [],
      province: "",
    },
    shouldClearShapes: false,
    creativeName: "",
    onLoadingDeleteMedias: false,
    adGroupName: "",
    adGroupMedias: [],
    selectedLocations: [],
    attributionAudienceName: "",
    refreshGeotargetDates: false,
    onLoadingDeleteAdGroup: false,
    onLocationUpdate: false,
    onTagsConfigUpdate: false,
    appId: "",
    //retailId: "",
    //environment: "none",
    //tag: {},
    onPageViewShow: "none",
    //pageViews: 0,
    //transactions: 0,
    //tagUpdateDate: "",
    multipleTags: [],
    zone: "UTC",
    googleCustomerId: "",
    attributionWindow: 0,
    menuId: "",
    dspAdvertiserId: "",
    defaultDisplayAttributionWindow: 7,
    defaultPaidSearchAttributionWindow: 7,
    defaultSEOAttributionWindow: 7,
    confirmLocationsTableData: [],
    isTargetingPreviewModalOpen: false,
    targetingLocationsPreviewData: [],
    targetingAudiences: [],
    parentOrgTargetingAudiences: [],
    currentAmplifyCampaign: null,
    dealIds: null,
  };

  timeout = null;

  componentDidMount = () => {
    let loggedInOrg = this.props.loggedInOrg;

    const {
      currentuser: { role },
    } = this.props;

    // this.handlePaymentInfo();
    // this.handleInvoice();

    this.props.client
      .query({
        query: GET_ORG_TAGS,
        variables: { orgId: loggedInOrg.id },
        cachePolicy: "no-cache",
      })
      .then(res => {
        this.setState({
          selectedOrg: {
            ...loggedInOrg,
            description: res.data.org.description,
            website: res.data.org.website,
            zone: res.data.org.zone,
            level: res.data.org.level,
            roles: res.data.org.roles,
            roasFormat:
              res.data.org.dataConfig.roasFormat || defaultRoasFormat.key,
            defaultDisplayAttributionWindow:
              res.data.org.dataConfig.defaultDisplayAttributionWindow ||
              defaultAttributionWindow.key,
            defaultPaidSearchAttributionWindow:
              res.data.org.dataConfig.defaultPaidSearchAttributionWindow ||
              defaultAttributionWindow.key,
            defaultSEOAttributionWindow:
              res.data.org.dataConfig.defaultSEOAttributionWindow ||
              defaultAttributionWindow.key,
            currency:
              res.data.org.dataConfig.currency || defaultCurrencyFormat.key,
            dataConfig: {
              ...loggedInOrg.dataConfig,
              menuId: res.data.org.dataConfig.menuId,
              dspAdvertiserId: res.data.org.dataConfig.dspAdvertiserId,
              semrushEnable: res.data.org.dataConfig.semrushEnable,
              semrushConfig: res.data.org.dataConfig.semrushConfig,
              googleAnalytics: { ...res.data.org.dataConfig.googleAnalytics },
              tagProcessingConfig: {
                ...res.data.org.dataConfig.tagProcessingConfig,
              },
              paidSearchDataConfig: {
                ...res.data.org.dataConfig.paidSearchDataConfig,
              },
              bing: {
                ...res.data.org.dataConfig.bing,
              },
              seo: res.data.org.dataConfig.seo,
              seoDomainRankings: res.data.org.dataConfig.seoDomainRankings,
            },
          },
        });
      });

    this.props.client
      .query({
        query: GET_ORG_TAGS,
        variables: { orgId: role.org.id },
        fetchPolicy: "network-only",
      })
      .then(res => {
        const getTags =
          res && res.data && res.data.org && res.data.org.eventsTarget;

        this.setState({
          multipleTags: getTags,
        });
      });
  };

  componentDidUpdate(prevProps) {
    const {
      currentuser: { role },
      locationList,
      loadingTargetingAudiences,
      targetingAudiences,
      amplifyCampaigns,
      orgDealIds,
    } = this.props;

    if (!this.props.loadingLocation && !this.state.locationLoaded) {
      const locations =
        locationList && locationList.locations ? locationList.locations : [];

      this.setState({
        locationLoaded: true,
        retailLocationList: locations,
      });
    }

    if (this.state.onLocationUpdate === true) {
      this.props.client
        .query({
          query: GET_LOCATIONS,
          variables: { id: role.org.id },
          cachePolicy: "no-cache",
        })
        .then(res => {
          const getLocation = res && res.data && res.data.locations;

          this.setState({
            onLocationUpdate: false,
            retailLocationList: getLocation,
          });
        });
    }

    if (
      (!loadingTargetingAudiences && !this.state.targetingAudiences.length) ||
      (targetingAudiences &&
        prevProps.targetingAudiences !== targetingAudiences)
    ) {
      const { audiencesConnection } = targetingAudiences;
      if (
        audiencesConnection &&
        audiencesConnection.edges &&
        audiencesConnection.edges.length
      ) {
        const formattedTargetingAudiences = audiencesConnection.edges.map(
          edge => edge.node
        );

        this.setState({
          targetingAudiences: formattedTargetingAudiences,
          parentOrgTargetingAudiences: formattedTargetingAudiences.filter(
            audience => audience.orgs && audience.orgs.length === 1
          ),
        });
      }
    }

    if (
      amplifyCampaigns &&
      amplifyCampaigns.campaignOrders &&
      amplifyCampaigns.campaignOrders.length &&
      !this.state.currentAmplifyCampaign
    ) {
      this.setState({
        currentAmplifyCampaign: amplifyCampaigns.campaignOrders[0],
      });
    }

    if (
      (orgDealIds &&
        orgDealIds.org &&
        orgDealIds.org.dealIds &&
        !this.state.dealIds) ||
      (prevProps.orgDealIds &&
        prevProps.orgDealIds.org &&
        prevProps.orgDealIds.org.dealIds !== orgDealIds.org.dealIds)
    ) {
      this.setState({
        dealIds: orgDealIds.org.dealIds,
      });
    }
  }

  formatCurrency2SigFig = number => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Org Update Info
  onChangeOrg = (key, value, type) => {
    let updatedOrg = { ...this.state.selectedOrg };

    updatedOrg[key] = value;
    this.setState({
      selectedOrg: updatedOrg,
    });
  };

  updateOrg = async () => {
    const { editOrg } = this.props;

    const {
      selectedOrg: {
        name,
        description,
        level,
        website,
        domain,
        logo,
        id,
        zone,
        roasFormat,
        defaultDisplayAttributionWindow,
        defaultPaidSearchAttributionWindow,
        defaultSEOAttributionWindow,
        currency,
      },
    } = this.state;

    const data = {
      id,
      name,
      description,
      level,
      website,
      domain,
      logo,
      zone,
      roasFormat,
      defaultDisplayAttributionWindow,
      defaultPaidSearchAttributionWindow,
      defaultSEOAttributionWindow,
      currency,
    };

    const response = await editOrg(data);
    const { status } = response;

    if (status === "success") {
      const updatedOrg = response.data;

      if (updatedOrg.id === this.state.selectedOrg.id) {
        // Once updated performs an update on the isAdmin role's id if still on the same Org's details
        const indexToUpdate = this.state.selectedOrg.roles.findIndex(
          role => role.isMaster
        );
        const updatedRoles = this.state.selectedOrg.roles.slice();
        updatedRoles[indexToUpdate].id = updatedOrg.roles[0].id;

        this.setState({
          selectedOrg: response.data,
        });

        return response.data;
      }
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the organization has not been updated"
      );
    }
  };

  updateOrgSendEmailConfig = async () => {
    const { updateOrgSendEmailConfig } = this.props;
    let updatedOrg = { ...this.state.selectedOrg };
    const { campaignOrders, email, frequency, dayOfWeek } = updatedOrg;
    return updateOrgSendEmailConfig({
      campaignOrders,
      email,
      frequency,
      dayOfWeek,
    });
  };

  // Retail Location
  onChange = (key, value) => {
    let updateParentState = { ...this.state.parentState };

    updateParentState[key] = value;

    this.setState({
      parentState: updateParentState,
    });
  };

  toggleShouldClearShapes = () => {
    this.setState({ shouldClearShapes: !this.state.shouldClearShapes });
  };

  changeLocationMap = place => {
    let tempAdd;

    if (place.length === 1) {
      const {
        name,
        address_components,
        geometry,
        formatted_address,
      } = place[0];

      if (formatted_address) {
        tempAdd = formatted_address.split(",");
        tempAdd.shift();

        const joinAdd = tempAdd.join(",");
        const country =
          tempAdd && tempAdd.length && tempAdd[tempAdd.length - 1]
            ? // Check for string equality
              typeof tempAdd[tempAdd.length - 1] === "string"
              ? tempAdd[tempAdd.length - 1].trim()
              : tempAdd[tempAdd.length - 1]
            : "";

        this.onChange("country", country ? country : "USA");
        this.onChange("formattedAddress", joinAdd);
      }

      if (geometry) {
        const { location } = geometry;
        if (location) {
          this.onChange("lat", location.lat());
          this.onChange("lng", location.lng());
        }
      }

      if (address_components) {
        this.onChange("tempName", name ? name : "");
        this.onChange(
          "street",
          address_components[0] && address_components[1]
            ? `${address_components[0].long_name} ${address_components[1].long_name}`
            : ""
        );
        this.onChange(
          "city",
          address_components[3] ? `${address_components[3].long_name}` : ""
        );
        const getState =
          address_components[5] && address_components[5].long_name
            ? stateList.filter(
                state => state === address_components[5].long_name.toUpperCase()
              )
            : "";

        this.onChange(
          "state",
          address_components[5] && address_components[5].long_name
            ? `${getState}`
            : ""
        );

        this.onChange(
          "country",
          address_components[6] && address_components[6].short_name
            ? `${
                address_components[6].short_name.includes("CA")
                  ? "CANADA"
                  : "USA"
              }`
            : ""
        );

        this.onChange(
          "zip",
          address_components[7] ? `${address_components[7].long_name}` : ""
        );
      }

      //can add in everything we can on a places match
    } else {
      console.log("we have many need exact");
    }
  };

  onCsvUploadComplete = async parsedData => {
    let results = [];
    const { countryType } = MapConfig;
    const formattedData = parsedData.map((data, key) => {
      const formattedKeyValue = {};
      Object.keys(data).map(key => {
        return (formattedKeyValue[camelCase(key)] = data[key]);
      });

      return formattedKeyValue;
    });

    results = this.getTableData(formattedData);

    Promise.all(results).then(values => {
      const { retailLocationList, selectedOrg } = this.state;
      const filteredLocations = retailLocationList
        .filter(location => location.org && location.org.id === selectedOrg.id)
        .filter(location => location.audienceType === "TARGETING");

      const previewData = formattedData
        .map((data, index, array) => {
          const isDuplicate =
            array.findIndex(name => name.name === data.name) !== index;
          return isDuplicate ? { ...data, duplicate: true } : data;
        })
        .map((data, key) => {
          if (values[key].status === "OK") {
            const place = values[key].results[0];
            const { state, city, zip, country, address } = getFormattedAddress({
              result: place,
            });

            const matchedLocation = filteredLocations.find(
              location => location.name === data.name
            );

            return {
              ...data,
              id: key,
              matched: matchedLocation || data.duplicate ? false : true,
              selected: matchedLocation || data.duplicate ? false : true,
              state: state.toUpperCase(),
              city,
              zip,
              street: address,
              sourceAddress: data.address,
              formattedAddress: place.formatted_address,
              country: countryType[country],
              coordinate: place.geometry.location,
              errorMessage:
                matchedLocation || data.duplicate
                  ? "Location name already exists"
                  : "",
            };
          }

          return {
            ...data,
            id: key,
            matched: false,
            selected: false,
            sourceAddress: data.address,
            formattedAddress: "",
          };
        });

      this.setState({
        isTargetingPreviewModalOpen: true,
        targetingLocationsPreviewData: previewData,
      });
    });
  };

  onBulkImportTargetingLocations = async () => {
    try {
      const { bulkUploadLocations, currentuser } = this.props;

      const orgId = currentuser.role.org.id;
      const userId = currentuser.id;
      const radius = defaultRadius / 1000;
      const locations = [];
      message.loading("Action in progress..", 0);

      this.setState({
        isTargetingPreviewModalOpen: false,
      });

      this.state.targetingLocationsPreviewData
        .filter(location => location.selected)
        .forEach(location => {
          const {
            name,
            formattedAddress,
            city,
            state,
            zip,
            street,
            country,
            coordinate,
            radius,
          } = location;

          locations.push({
            audienceType: "TARGETING",
            name,
            description: "",
            street,
            city,
            state: state.replace(/ /g, "_"),
            lat: coordinate.lat,
            lng: coordinate.lng,
            country,
            email: "",
            url: "",
            phone: "",
            contact: "",
            formattedAddress,
            zip,
            radius: radius / 1000,
          });
        });

      const response = await bulkUploadLocations({
        variables: {
          data: {
            orgId,
            radius,
            locations,
            createdBy: userId,
          },
        },
      });

      const { data } = response;

      const ids = data.bulkUploadLocations.map(value => value.id);
      const filteredLocations = this.state.retailLocationList.filter(
        location => !ids.includes(location.id)
      );
      this.setState({
        retailLocationList: [...data.bulkUploadLocations, ...filteredLocations],
      });

      message.destroy();
      message.success("Location imported successfully");
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error(error.message);
    }
  };

  getCircleOverlay = ({ coordinate }) => {
    const { google } = this.props;
    const position = new google.maps.LatLng(coordinate.lat, coordinate.lng);

    return {
      overlay: new google.maps.Circle({
        center: { lat: position.lat(), lng: position.lng() },
        radius: defaultRadius,
      }),
      type: "circle",
    };
  };

  getTableData = locations => {
    const results = [];
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      const fullAddress = `${location.address} ${location.city} ${location.state} ${location.zip}`
        .split(" ")
        .join("+");
      results.push(getGeoCodes(fullAddress));
    }

    return results;
  };

  createTargetingLocation = async location => {
    message.loading("Action in progress..", 0);

    this.setState({
      parentState: {
        ...OrganizationConfig.csvTemplate.defaultValues,
        ...location,
        inProgressOverlay: location.circles,
      },
    });

    this.createOrgLocation();
  };

  updateTargetingLocation = async location => {
    this.setState({
      parentState: {
        inProgressOverlay: location.circles,
        ...location,
      },
    });

    this.updateOrgLocation();
  };

  createOrgLocation = async () => {
    const {
      currentuser: { id, role },
      createLocation,
      createLocationWithGeotarget,
    } = this.props;

    const { parentState, retailLocationList } = this.state;

    let tempRetailLocationList = retailLocationList.filter(() => {
      return true;
    });

    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      inProgressOverlay,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = null;

      if (circles.length !== 0 || polygons.length !== 0) {
        await this.props
          .createGeoTargetLocation({
            variables: {
              name: name,
              orgID: role.org.id,
              isBase: true,
              circles: circles,
              polygons: polygons,
              start: moment()
                .subtract(90, "days")
                .format(),
              end: moment().format(),
            },
          })
          .then(val => {
            geoId =
              val &&
              val.data &&
              val.data.createGeoTarget &&
              val.data.createGeoTarget.id;
          });
      }

      const data = {
        audienceType,
        cart: cart === "" ? "NOCART" : cart,
        circles,
        city,
        contact,
        country: country || "USA",
        description,
        email,
        end: moment().format(),
        formattedAddress: `${street},${formattedAddress}`,
        geoId,
        isBase: true,
        lat,
        lng,
        locationKey,
        menuDataKey,
        name,
        orgId: role.org.id,
        phone,
        polygons,
        start: moment()
          .subtract(90, "days")
          .format(),
        state,
        street,
        url,
        zip,
        province,
        createdBy: id,
      };

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await createLocationWithGeotarget(data)
          : await createLocation(data);

      if (response.status === "success") {
        tempRetailLocationList.unshift(response.data);

        this.setState({
          retailLocationList: tempRetailLocationList,
        });

        message.destroy();
        message.success(`Successfully created new Location`);

        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteBulkLocation = async () => {
    try {
      message.loading("Action in progress..", 0);
      const { retailLocationList } = this.state;
      const { deleteManyLocations } = this.props;
      const ids = this.state.selectedLocations.map((location, index) => {
        return location.id;
      });

      const response = await deleteManyLocations({
        variables: {
          ids: ids,
        },
      });

      if (response && response.data) {
        let updatedLocations = retailLocationList.filter(targetLocation => {
          return !this.state.selectedLocations
            .map(value => value.id)
            .includes(targetLocation.id);
        });

        this.setState({
          retailLocationList: updatedLocations,
          selectedLocations: [],
        });

        message.destroy();
        message.success(`Targeting locations successfully deleted.`);
      }
    } catch (error) {
      message.destroy();
      message.success(`Failed to delete targeting location(s).`);
    }
  };

  deleteLocation = async Id => {
    const { deleteLocation } = this.props;

    const { retailLocationList } = this.state;

    let tempRetailLocationList = retailLocationList.filter(function(location) {
      return location.id !== Id;
    });

    const response = await deleteLocation({
      variables: {
        Id,
      },
    });

    if (response) {
      this.setState({
        retailLocationList: tempRetailLocationList,
      });

      return true;
    } else {
      throw response.error;
    }
  };

  editRetailLocation = async data => {
    let updateParentState = { ...this.state.parentState };

    const {
      id,
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      geoframe,
      transConfig,
      locationKey,
    } = data;

    let shapes = [];
    shapes.push(geoframe);

    const start = geoframe ? geoframe.start : null;
    const end = geoframe ? geoframe.end : null;

    const { cart, menuDataKey } = transConfig;

    updateParentState["id"] = id;
    updateParentState["name"] = name;
    updateParentState["description"] = description;
    updateParentState["street"] = street;
    updateParentState["city"] = city;
    updateParentState["state"] = state;
    updateParentState["country"] = country;
    updateParentState["lat"] = lat;
    updateParentState["lng"] = lng;
    updateParentState["email"] = email;
    updateParentState["url"] = url;
    updateParentState["phone"] = phone;
    updateParentState["contact"] = contact;
    updateParentState["formattedAddress"] = formattedAddress;
    updateParentState["zip"] = zip;
    updateParentState["selectedGeoTargets"] = shapes;
    updateParentState["inProgressOverlay"] = [];
    updateParentState["cart"] = cart;
    updateParentState["menuDataKey"] = menuDataKey;
    updateParentState["start"] = start;
    updateParentState["end"] = end;
    updateParentState["geoframe"] = geoframe;
    updateParentState["locationKey"] = locationKey;

    this.setState({
      parentState: updateParentState,
    });
  };

  updateOrgLocation = async () => {
    message.loading("Action in progress..", 0);

    const {
      updateLocation,
      updateLocationWithGeotargets,
      currentuser: { role, id: userId },
    } = this.props;

    const { parentState } = this.state;

    const {
      name,
      description,
      street,
      city,
      state,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      inProgressOverlay,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = "";

      await this.props
        .createGeoTargetLocation({
          variables: {
            name: name,
            orgID: role.org.id,
            isBase: true,
            circles: circles,
            polygons: polygons,
            start: moment()
              .subtract(90, "days")
              .format(),
            end: moment().format(),
          },
        })
        .then(val => {
          geoId =
            val &&
            val.data &&
            val.data.createGeoTarget &&
            val.data.createGeoTarget.id;
        });

      const data = {
        name,
        description,
        street,
        city,
        state,
        country: country || "USA",
        email,
        url,
        phone,
        contact,
        formattedAddress: `${formattedAddress}`,
        zip,
        id,
        cart,
        menuDataKey,
        geoId,
        locationKey,
        audienceType,
        province,
        updatedBy: userId,
      };

      if (data.country !== "USA" && data.country !== "CANADA") {
        delete data.state;
      } else {
        delete data.province;
      }

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await updateLocationWithGeotargets({
              variables: data,
            })
          : await updateLocation(data);

      if (!isEmpty(response)) {
        this.setState({
          onLocationUpdate: true,
        });

        this.resetParentState();
        message.destroy();
        message.success(`Targeting location successfully updated.`);
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      message.destroy();
      message.success(`Failed to update targeting location.`);
    }
  };

  resetParentState = () => {
    this.setState({
      shouldClearShapes: false,
      parentState: {
        selectedLicense: {},
        zoomLevel: 10,
        initialLocation: {
          latitude: "39.462407",
          longitude: "-105.642816",
        },
        polygons: [],
        name: "",
        street: "",
        city: "",
        zip: "",
        tags: [],
        start: "",
        end: "",
        lat: null,
        lng: null,
        formattedAddress: "",
        state: "",
        country: "",
        description: "",
        locationLoading: false,
        email: "",
        contact: "",
        phone: "",
        url: "",
        hrs: "",
        locationId: "",
        geoFrameId: "",
        tablet: window.matchMedia("(max-width: 1100px)").matches,
        locationLoaded: false,
        id: "",
        tempName: "",
        selectedGeoTargets: [],
        inProgressOverlay: [],
        cart: "",
        menuDataKey: "",
        refreshGeotargetDates: false,
        locationKey: "",
      },
    });
  };

  deleteAudienceLocation = async audiences => {
    try {
      message.loading("Action in progress..", 0);
      const results = [];
      const { deleteAudienceLocation } = this.props;
      const { targetingAudiences } = this.state;

      audiences.forEach(audience => {
        const Id = audience.id;
        results.push(
          deleteAudienceLocation({
            variables: {
              Id,
            },
          })
        );
      });

      Promise.all(results).then(values => {
        let updatedTargetAudiences = targetingAudiences.filter(
          targetAudience => {
            return !values
              .filter(value => value && value.data)
              .map(value => value.data.deleteAudience.id)
              .includes(targetAudience.id);
          }
        );

        this.setState({
          targetingAudiences: updatedTargetAudiences,
        });

        message.destroy();
        message.success(`Audiences successfully deleted.`);
      });
    } catch (error) {
      message.destroy();
      message.success(`Failed to delete audience(s).`);
    }
  };

  onChangeStateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  onDeleteMedia = async id => {
    try {
      await this.props.deleteMedia({
        variables: {
          id: id,
        },
      });
    } catch (err) {
      message.destroy();
      if (err.message === errorType.deleteMedia) {
        message.error(`Cannot Delete Campaign, Already Synced with a Creative`);
      }
    }
  };

  onDeleteMedias = async ids => {
    const { updateManyMedias } = this.props;

    try {
      message.loading("Action in progress..", 0);

      this.setState({
        onLoadingDeleteMedias: true,
      });

      await updateManyMedias({
        variables: {
          ids,
          data: { flag: false },
        },
      });
      message.destroy();
      message.success(`${ids.length} Creatives(s) successfully deleted.`);
      this.setState({
        onLoadingDeleteMedias: false,
      });

      // Get all adgroups associated with this Creative
      const adGroupCreativesResult = await this.props.getAdGroupsByMedia({
        media: ids,
      });

      // Check each adgroup if all creatives is deleted, return the adgroup ids that meets the condition for deletion
      const mappedAdGroupCreativeIds = adGroupCreativesResult.data.adGroupCreativeses
        .filter(obj => obj.medias.every(media => !media.flag))
        .map(obj => obj.id);

      // Delete all adgroups that has no creatives
      if (mappedAdGroupCreativeIds) {
        mappedAdGroupCreativeIds.map(x =>
          this.props.deleteAdGroupCreatives({ variables: { id: x } })
        );
      }
    } catch (err) {
      message.destroy();
      if (err.message === errorType.deleteMedia) {
        // TODO need to clarify if statement appies
        message.error(`Cannot Delete Campaign, Already Synced with a Creative`);
      }
    } finally {
      return [];
    }
  };

  onCreateAdgroup = async () => {
    message.loading("Action in progress..", 0);

    const {
      currentuser: {
        role,
        permission: { amplify },
      },
    } = this.props;

    const orgs = [role.org.id];

    try {
      // TODO: Add `createdBy` property when creating ad group
      await this.props.createAdGroupCreatives({
        variables: {
          name: this.state.adGroupName,
          medias: this.state.adGroupMedias,
          org: orgs.map(o => {
            return { id: o };
          }),
        },
      });

      // Must only set the first created adgroup if only amplify is turned on
      if (
        this.props.adGroupCreatives &&
        this.props.adGroupCreatives.length === 1 &&
        amplify
      ) {
        this.setAdGroupAsAmplifyDefault(this.props.adGroupCreatives[0]);
      }
      message.destroy();
      message.success(`Successfully Created Ad Group`);
    } catch (err) {
      console.log("error creating adgroup");
    }
  };

  onDeleteAdgroup = async ids => {
    try {
      message.loading("Action in progress..", 0);
      this.setState({
        onLoadingDeleteAdGroup: true,
      });
      const results = [];
      ids.forEach(id => {
        results.push(
          this.props.deleteAdGroupCreatives({
            variables: {
              id: id,
            },
          })
        );
      });

      Promise.all(results).then(values => {
        message.destroy();
        message.success(`${values.length} Ad group(s) successfully deleted.`);
        this.setState({
          onLoadingDeleteAdGroup: false,
        });
      });
    } catch (err) {
      message.destroy();
      message.success("Failed to delete ad group(s).");
      console.log(err);
    }
  };

  onSubmitAudience = async type => {
    message.loading("Action in progress..", 0);

    const {
      createSelectedAudienceLocation,
      currentuser: { id, role },
    } = this.props;
    const { selectedLocations, attributionAudienceName } = this.state;

    try {
      const getAllGeotargetIds =
        selectedLocations &&
        selectedLocations.map(val => {
          const geotargets = val.geoframe;
          const geotargetsId = geotargets && geotargets.id;

          return {
            id: geotargetsId,
          };
        });

      console.log(id);

      const res = await createSelectedAudienceLocation({
        variables: {
          name: attributionAudienceName,
          type: type,
          geoTargets: getAllGeotargetIds,
          orgid: role.org.id,
          createdBy: id,
        },
      });

      if (res && res.data) {
        if (this.state.targetingAudiences.length === 1) {
          this.setAudienceAmplifyDefault(this.state.targetingAudiences[0]);
        }

        this.setState({
          selectedLocations: [],
        });
        message.destroy();
        message.success(`Successfully created audience`);
      } else {
        throw Error;
      }
    } catch (err) {
      message.destroy();
      message.error(`Failed to create audience`);
    }
  };

  onCloseTargetingPreviewModal = () => {
    this.setState({
      isTargetingPreviewModalOpen: false,
    });
  };

  onSelectTargetingLocationPreview = id => {
    const updatedLocation = this.state.targetingLocationsPreviewData.find(
      location => location.id === id
    );

    this.setState({
      targetingLocationsPreviewData: [
        ...this.state.targetingLocationsPreviewData.filter(
          location => location.id !== id
        ),
        { ...updatedLocation, selected: !updatedLocation.selected },
      ],
    });
  };

  handleSubmitRegionGroup = async val => {
    const {
      createRegionGroup,
      currentuser: { role },
    } = this.props;

    return await createRegionGroup({
      variables: {
        name: val.name,
        regions: val.regions,
        orgID: role.org.id,
        isPresetList: val.isPresetList,
      },
    });
  };

  handleUpdateRegionGroup = async value => {
    const { updateRegionGroup } = this.props;

    const { id, name, regions, isPresetList } = value;

    return await updateRegionGroup({
      variables: {
        id,
        name,
        regions,
        isPresetList,
      },
    });
  };

  onDeleteRegionGroup = async Id => {
    await this.props.deleteRegionGroup({
      variables: {
        Id: Id,
      },
    });
  };

  handleSubmitIABCategoryGroup = async val => {
    const {
      createIABCategoryGroup,
      currentuser: { role },
    } = this.props;
    return await createIABCategoryGroup({
      variables: {
        name: val.name,
        iabCategories: val.iabCategories,
        orgID: role.org.id,
      },
    });
  };

  onDeleteIABCategoryGroup = async Id => {
    await this.props.deleteIABCategoryGroup({
      variables: {
        Id: Id,
      },
    });
  };

  createCampaignAmplify = async ({ clickthrough }) => {
    try {
      const { createCampaignOrder, adGroupCreatives, currentuser } = this.props;
      const { selectedOrg, multipleTags } = this.state;

      const advertiserId =
        process.env.REACT_APP_ENV === "production"
          ? mediajelOrgIdProduction
          : mediajelOrgIdStaging;

      const defaultAdGroupCreative =
        (adGroupCreatives && adGroupCreatives.length
          ? adGroupCreatives.find(adGroup => adGroup.isAmplifyDefault)
          : null) || adGroupCreatives[0];

      const tag =
        multipleTags.find(tag => tag.isAmplifyDefault) || multipleTags[0];

      const adUnits = {
        clickthrough,
        name: defaultAdGroupCreative.name,
        banners: {
          create: (defaultAdGroupCreative.medias || []).map(media => ({
            media: {
              connect: {
                id: media.id,
              },
            },
          })),
        },
      };

      const startDate = moment().toISOString();
      const endDate = moment()
        .add(1, "year")
        .toISOString();
      const variables = {
        ...AmplifyDefault,
        orgID: selectedOrg.id,
        adUnits,
        campaignName: `${selectedOrg.name} - AMPLIFY`,
        startDate,
        endDate,
        advertiser: advertiserId,
        transactionTarget: tag ? tag.appId : "",
        transactionsConfig: {
          create: {
            ...AmplifyDefault.transactionsConfig.create,
            appId: tag ? tag.appId : "",
            cart: tag ? tag.cart : "",
          },
        },
        tags: [tag.id],
        isAmplify: true,
        requestorId: currentuser.id,
      };
      return await createCampaignOrder({ variables });
    } catch (error) {
      message.destroy();
      message.error("Failed to create amplify campaign.");
    }
  };

  updateCampaignAmplifyGeoTargets = async ({ campaignOrder }) => {
    const { updateCampaignOrderWithGeoLocations } = this.props;
    const { parentOrgTargetingAudiences } = this.state;

    const defaultTargetingAudience =
      (parentOrgTargetingAudiences
        ? parentOrgTargetingAudiences.find(
            audience => audience.isAmplifyDefault
          )
        : null) || parentOrgTargetingAudiences[0];

    try {
      const geoTargets = defaultTargetingAudience.geoTargets
        .filter(geoTarget => geoTarget.location)
        .map(geoTarget => {
          const { circles, location } = geoTarget;
          const circle = circles[0];

          delete location.__typename;
          delete location.id;
          delete circle.__typename;

          return {
            circles: {
              create: {
                radius: circle.radius,
                coordinate: {
                  create: {
                    lat: circle.coordinate.lat,
                    lng: circle.coordinate.lng,
                  },
                },
                location: {
                  create: {
                    ...location,
                    audienceType: "ADDRESS",
                  },
                },
              },
            },
          };
        });
      await updateCampaignOrderWithGeoLocations({
        variables: {
          campaignOrderID: campaignOrder.id,
          geoTargets: {
            create: geoTargets,
          },
        },
      });
    } catch (error) {
      message.destroy();
      message.error("Failed to update campaign.");
    }
  };

  handleAmplifyCampaign = async values => {
    message.loading("Action in progress...", 0);

    const { clickthrough } = values;

    try {
      // CREATE CAMPAIGN
      const result = await this.createCampaignAmplify({
        clickthrough,
      });

      const campaignOrder = result.data.createCampaignOrder;
      // UPDATE CAMPAIGN WITH GEO TARGET
      await this.updateCampaignAmplifyGeoTargets({ campaignOrder });

      this.setState({
        currentAmplifyCampaign: result.data.createCampaignOrder,
      });
      message.destroy();
      message.success("Campaign successfully created.");
    } catch (error) {}
  };

  handleUpdateAmplify = async variables => {
    message.loading("Action in progress...", 0);
    const { updateCampaignOrderAmplify } = this.props;
    try {
      const res = await updateCampaignOrderAmplify({
        variables,
      });

      this.setState({ currentAmplifyCampaign: res.data.updateCampaignOrder });
      message.destroy();
      message.success("Campaign order successfully updated.");
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  };

  setAdGroupAsAmplifyDefault = async record => {
    await this.props.updateManyAdGroupCreatives({
      variables: {
        orgId: record.org[0].id,
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAdGroupCreatives({
      variables: {
        id: record.id,
        data: { isAmplifyDefault: true },
      },
    });
  };

  setAudienceAmplifyDefault = async record => {
    await this.props.updateManyAudiences({
      variables: {
        orgId: [record.orgs[0].id],
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAudience({
      variables: {
        id: record.id,
        name: record.name,
        description: "",
        type: record.type,
        isAmplifyDefault: true,
      },
    });
  };

  createHashedEmailAddress = async value => {
    return this.props.createHashedEmailAddress({
      variables: {
        emails: [encryptStringSHA256(value.email)],
      },
    });
  };

  createHashedEmailList = async values => {
    return this.props.createHashedEmailList({
      variables: {
        name: values.name,
        hashedEmailIds: values.hashedEmailIds,
      },
    });
  };

  updateHashedEmailList = async values => {
    return this.props.updateHashedEmailList({
      variables: {
        id: values.id,
        activated: values.activated,
      },
    });
  };

  activateHashedEmailList = async data => {
    return this.props.activateHashedEmailList({
      variables: {
        emailListId: data.emailListId,
      },
    });
  };

  bulkCreateHashedEmailAddress = async data => {
    return this.props.createHashedEmailAddress({
      variables: {
        emailListId: data.emailListId,
        emails: data.emails,
      },
    });
  };

  deleteHashedEmailAddress = async record => {
    await this.props.deleteHashedEmailAddress({
      variables: { id: record.id },
    });
  };

  deleteHashedEmailList = async record => {
    await this.props.deleteHashedEmailList({
      variables: { id: record.id },
    });
    await this.props.deleteManyHashedEmailAddresses({
      variables: { id: record.id },
    });
  };

  bulkCreateOrgMedia = async (
    medias,
    invalidMedias,
    calledFromOrgConfig = false
  ) => {
    try {
      if (!calledFromOrgConfig) {
        message.loading("Uploading creatives...", 0);
      }

      const { createManyOrgMedia } = this.props;
      const chunkSize = 10;
      const totalChunks = Math.ceil(medias.length / chunkSize);

      for (let i = 0; i < totalChunks; i++) {
        const startIndex = i * chunkSize;
        const endIndex = startIndex + chunkSize;
        const chunkMedias = medias.slice(startIndex, endIndex);

        const data = {
          media: {
            create: chunkMedias,
          },
        };

        const result = await createManyOrgMedia({
          variables: {
            id: this.state.selectedOrg.id,
            data,
          },
        });

        if (!(result && result.data)) {
          throw new Error("Failed to upload media");
        }
      }

      if (!calledFromOrgConfig) {
        message.destroy();
        if (invalidMedias.length) {
          message.error(
            `Failed to upload ${invalidMedias.length} creative(s).`
          );
        }
        message.success(`${medias.length} Creative(s) successfully uploaded`);
      }
    } catch (error) {}
  };

  updateAdGroup = async ({ adGroup, type }) => {
    try {
      message.loading("Updating Ad Group..", 0);
      const { updateAdGroupCreatives } = this.props;
      const { id, name, medias } = adGroup;
      await updateAdGroupCreatives({
        variables: {
          id,
          data: {
            name,
            medias: {
              [type]: medias.map(id => ({ id })),
            },
          },
        },
      });
      message.destroy();
      message.success(`Successfully updated ad group`);
    } catch (error) {
      message.destroy();
      message.error("Failed to update ad group.");
    }
  };

  createDealId = async ({ name, dealId }) => {
    try {
      const { createOrgDealId } = this.props;
      const result = await createOrgDealId({
        variables: {
          id: this.state.selectedOrg.id,
          data: {
            dealIds: {
              create: [
                {
                  name,
                  dealId,
                },
              ],
            },
          },
        },
      });

      const dealIds = result.data.updateOrg.dealIds;
      this.setState({
        dealIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  deleteDealIds = async ({ ids }) => {
    try {
      const { deleteOrgDealIds } = this.props;
      await deleteOrgDealIds({
        variables: {
          ids,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  updateDealId = async ({ id, name, dealId }) => {
    try {
      const { updateDealId } = this.props;
      const result = await updateDealId({
        variables: {
          id,
          data: {
            name,
            dealId,
          },
        },
      });
      const updatedDealId = result.data.updateDealId;
      const dealIds = this.state.dealIds.map(dealId => {
        if (dealId.id === updatedDealId.id) {
          return updatedDealId;
        }
        return dealId;
      });
      this.setState({
        dealIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  onSwitchChange = async (checked, data, field) => {
    const [feature, permission] = data.split("-");
    const updatedOrg = { ...this.state.selectedOrg };

    if (permission === CONFIG) {
      if (!updatedOrg.config) {
        updatedOrg.config = {};
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      updatedOrg.config[feature] = checked;
    } else if (permission === DATACONFIG) {
      if (!updatedOrg.dataConfig) {
        updatedOrg.dataConfig = {};
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      if (!updatedOrg.dataConfig.tagProcessingConfig) {
        updatedOrg.dataConfig.tagProcessingConfig = {
          processTag: false,
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }

      if (!updatedOrg.dataConfig.paidSearchDataConfig) {
        updatedOrg.dataConfig.paidSearchDataConfig = {
          isEnabled: false,
        };
        this.setState({
          selectedOrg: updatedOrg,
        });
      }
      if (field) {
        updatedOrg.dataConfig[feature][field] = checked;
      } else {
        updatedOrg.dataConfig[feature] = checked;
      }
    } else {
      if (checked) {
        updatedOrg.features[feature] = [READ, WRITE];
      } else {
        delete updatedOrg.features[feature];
      }
    }

    this.setState({
      selectedOrg: updatedOrg,
    });
  };

  render() {
    return (
      <OrgConfigView
        activateHashedEmailList={this.activateHashedEmailList}
        createHashedEmailList={this.createHashedEmailList}
        updateHashedEmailList={this.updateHashedEmailList}
        hashedEmailLists={this.props.hashedEmailLists}
        refetchHashedEmailLists={this.props.refetchHashedEmailLists}
        deleteHashedEmailAddress={this.deleteHashedEmailAddress}
        deleteHashedEmailList={this.deleteHashedEmailList}
        createHashedEmailAddress={this.createHashedEmailAddress}
        bulkCreateHashedEmailAddress={this.bulkCreateHashedEmailAddress}
        hashedEmailListsLoading={this.hashedEmailListsLoading}
        setDefaultAmplifyCreatives={this.setAdGroupAsAmplifyDefault}
        setAudienceAmplifyDefault={this.setAudienceAmplifyDefault}
        handleSubmitRegionGroup={this.handleSubmitRegionGroup}
        handleUpdateRegionGroup={this.handleUpdateRegionGroup}
        deleteRegionGroup={this.onDeleteRegionGroup}
        regionGroups={this.props.regionGroups}
        handleSubmitIABCategoryGroup={this.handleSubmitIABCategoryGroup}
        deleteIABCategoryGroup={this.onDeleteIABCategoryGroup}
        iABCategoryGroups={this.props.iABCategoryGroups}
        currentuser={this.props.currentuser}
        org={this.props.org}
        selectedOrg={this.state.selectedOrg}
        loading={this.state.loading}
        onChangeOrg={this.onChangeOrg}
        updateOrg={this.updateOrg}
        updateOrgSendEmailConfig={this.updateOrgSendEmailConfig}
        parentState={this.state.parentState}
        onChange={this.onChange}
        changeLocationMap={this.changeLocationMap}
        locationList={this.state.retailLocationList}
        onCsvUploadComplete={this.onCsvUploadComplete}
        createLocation={this.createOrgLocation}
        createTargetingLocation={this.createTargetingLocation}
        updateTargetingLocation={this.updateTargetingLocation}
        deleteLocation={this.deleteLocation}
        editRetailLocation={this.editRetailLocation}
        resetParentState={this.resetParentState}
        updateOrgLocation={this.updateOrgLocation}
        shouldClearShapes={this.state.shouldClearShapes}
        toggleShouldClearShapes={this.toggleShouldClearShapes}
        cartProvider={this.props.cartProvider}
        orgMedias={this.props.orgMedias}
        createOrgMedia={this.props.createOrgMedia}
        onChangeStateValue={this.onChangeStateValue}
        creativeName={this.state.creativeName}
        updateOrgMedia={this.props.updateOrgMedia}
        orgMediasLoading={this.props.orgMediasLoading}
        onDeleteMedia={this.onDeleteMedia}
        onDeleteMedias={this.onDeleteMedias}
        onLoadingDeleteMedias={this.state.onLoadingDeleteMedias}
        onCreateAdgroup={this.onCreateAdgroup}
        onDeleteAdgroup={this.onDeleteAdgroup}
        onLoadingDeleteAdGroup={this.state.onLoadingDeleteAdGroup}
        adGroupCreatives={this.props.adGroupCreatives}
        orgSendEmailConfig={this.props.orgSendEmailConfig}
        selectedLocations={this.state.selectedLocations}
        attributionAudienceName={this.state.attributionAudienceName}
        onSubmitAudience={this.onSubmitAudience}
        handleSubmitTags={this.handleSubmitTags}
        onPageViewShow={this.state.onPageViewShow}
        selectedTab={this.props.selectedTab}
        google={this.props.google}
        isTargetingPreviewModalOpen={this.state.isTargetingPreviewModalOpen}
        targetingLocationsPreviewData={this.state.targetingLocationsPreviewData}
        closeTargetingPreviewModal={this.onCloseTargetingPreviewModal}
        bulkImportTargetingLocations={this.onBulkImportTargetingLocations}
        selectTargetingLocationPreview={this.onSelectTargetingLocationPreview}
        targetingAudiences={this.state.targetingAudiences}
        deleteAudienceLocation={this.deleteAudienceLocation}
        deleteBulkLocation={this.deleteBulkLocation}
        campaignOrders={this.props.campaignOrders}
        options={this.props.options}
        multipleTags={this.state.multipleTags}
        handleAmplifyCampaign={this.handleAmplifyCampaign}
        parentOrgTargetingAudiences={this.state.parentOrgTargetingAudiences}
        currentAmplifyCampaign={this.state.currentAmplifyCampaign}
        handleUpdateAmplify={this.handleUpdateAmplify}
        attributionAudiences={this.props.attributionAudiences}
        bulkCreateOrgMedia={this.bulkCreateOrgMedia}
        loadingHashedEmailList={this.props.loadingHashedEmailList}
        orgId={this.props.orgId}
        updateAdGroup={this.updateAdGroup}
        dealIds={this.state.dealIds}
        createDealId={this.createDealId}
        deleteDealIds={this.deleteDealIds}
        updateDealId={this.updateDealId}
        onSwitchChange={this.onSwitchChange}
        presetRegionGroups={this.props.presetRegionGroups}
        presetRegionGroupsError={this.props.presetRegionGroupsError}
        presetRegionGroupsLoading={this.props.presetRegionGroupsLoading}
        orgVideoMedias={this.props.orgVideoMedias}
        refetchVideoMedias={this.props.refetchVideoMedias}
        refetchMedias={this.props.refetchMedias}
        processDeviceIds={this.props.processDeviceIds}
      />
    );
  }
}

export default GoogleApiWrapper(googleMapsConfig)(withRouter(Controller));
