//Component is self contained and manages state internally for impressions sub table data

import React from "react";
import { Table } from "antd";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../../shared/globalStyling/styledText";
import {
  formatCurrency2SigFig,
  formatWholeNumber,
} from "../../../../../../../core/utils/campaigns";

const ItemsSubTable = ({ items }) => {
  const columns = [
    {
      title: <TableColumnTitle text={"SKU"} />,
      index: "sku",
      key: "sku",
      render: (text, { sku }) => {
        return <PrimaryTableRowText text={sku} />;
      },
    },
    {
      title: <TableColumnTitle text={"Name"} />,
      index: "name",
      key: "name",
      render: (text, { name }) => {
        return <PrimaryTableRowText text={name} />;
      },
    },
    {
      title: <TableColumnTitle text={"Quantity"} />,
      index: "quantity",
      key: "quantity",
      render: (text, { quantity }) => {
        return <PrimaryTableRowText text={formatWholeNumber(quantity)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Price"} />,
      index: "price",
      key: "price",
      render: (text, { price }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(price)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Total"} />,
      index: "total",
      key: "total",
      render: (text, { quantity, price }) => {
        return (
          <PrimaryTableRowText text={formatCurrency2SigFig(quantity * price)} />
        );
      },
    },
  ];
  return (
    <Table
      dataSource={items}
      columns={columns}
      pagination={{
        position: ["none", "bottomCenter"],
        total: items.length,
        responsive: true,
        showSizeChanger: false,
        showLessItems: true,
        size: "default",
      }}
      rowKey={({ sku }) => sku}
    />
  );
};

export default ItemsSubTable;
