import QuickDocs from "../../../../platform/ux/QuickDocs/QuickDocs";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";

const AttributionRetailIdDocs = () => {
  const [opened, setOpened] = React.useState(false);

  const toggle = () => setOpened(prev => !prev);

  return (
    <>
      <QuestionCircleOutlined onClick={toggle} />
      <QuickDocs title={"Setting a Retail ID"} open={opened} onCancel={toggle}>
        Setting a Retail ID for your campaign can help you keep track of results
        and analyze data specific to certain locations. By setting a Retail ID,{" "}
        <b> you can segregate results for specific location URLs. </b> This
        means that all of the data you see on the campaign will belong to that
        specific menu location.
        <br />
        <br />
        For example, if you have two menu locations on your website:
        <br />
        <br />
        <ul>
          <li>
            <Typography.Link>
              https://yourwebsite.com/arizona-store
            </Typography.Link>
          </li>
          <li>
            <Typography.Link>
              https://yourwebsite.com/nevada-store
            </Typography.Link>
          </li>
        </ul>
        and you want to display results only for the Arizona location in your
        campaign, you would set the Retail ID of this campaign to be{" "}
        <Typography.Text mark>arizona-store</Typography.Text>. The Retail ID
        must be a segment of the URL that uniquely identifies the menu.
        <br />
        <br />
        After setting the Retail ID, it is important to note that changes may
        not take effect immediately. You should allow for 24 hours for the
        changes to take effect before analyzing data or results for the selected
        location.
      </QuickDocs>
    </>
  );
};
export default AttributionRetailIdDocs;
