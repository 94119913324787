import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, DatePicker, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
//import ExportCSVFoottraffik from "../../components/ExportCSVFoottraffik";
import { isEmpty } from "lodash";
import ExportReportMenu from "./ExportReportMenu";

// Global styles
import moment from "moment";
import { filterByDate } from "../../utils";
import AdvertiserFilter from "../../../../shared/AdvertiserFilter";

const { RangePicker } = DatePicker;

const styles = {
  row: {
    borderRight: "0.8px solid #e8e8e8",
    borderLeft: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  buttonL: {
    fontSize: "14px",
    width: "150px",
    marginRight: "10px",
    height: "36px",
    margin: "12px 10px 12px 0",
  },
  buttonS: {
    width: "100%",
    fontSize: "14px",
  },
  search: {
    fontSize: "14px",
    width: "180px",
    height: "36px",
  },
};

const DropDownOrgToggle = ({ orgs, filteredOrg, setFilteredOrg }) => {
  const options = orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  return (
    <>
      <Select
        allowClear
        showSearch
        placeholder="Filter by Advertiser"
        onClear={() => {
          setFilteredOrg(null);
        }}
        onChange={org => {
          setFilteredOrg({ id: org });
        }}
        options={options}
        filterOption={(input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      />
    </>
  );
};

const SelectCampaignOrder = ({
  orgs,
  selectedCOIds,
  setSelectedCOIds,
  campaignOrders,
  isDemo = false,
}) => {
  const handleChange = e => {
    setSelectedCOIds(e);
  };

  const options = campaignOrders.map((c, i) => {
    return {
      value: c.id,
      label: !isDemo ? c.name : `Example Campaign Order ${i}`,
    };
  });

  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Filter by Campaign Order"
      value={selectedCOIds}
      onChange={handleChange}
      options={options}
      filterOption={(input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    />
  );
};

DropDownOrgToggle.propTypes = {
  orgs: PropTypes.array,
};

const FiltersBar = withRouter(
  ({
    orgs,
    filteredOrg,
    setFilteredOrg,
    selectedCOIds,
    setSelectedCOIds,
    campaignOrders,
    onExportCSV,
    onExportExcel,
    onExportWeeklyReport,
    onExportWeeklyReportExcel,
    orgConfigAccess,
    isExportCompleted,
    loadingReport,
    setIsExportCompleted,
    reportData,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    setDataSummaryRangeDate,
    byDates,
    weeklyReportData,
    tableData,
    currentUser,
    startEndDateParams,
    history,
  }) => {
    //first sort the orgs
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = a.name.toUpperCase();
      const bandB = b.name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
    if (orgs && orgs.length > 0) {
      orgs.sort(compare);
    }

    // if (!isEmpty(byDates)) {
    //   console.log(compute(byDates));
    // }

    const DateSelector = ({ data }) => {
      // map xAxis dates
      const getDate = reportData.map(a => ({
        timestamp: a.date,
        date: a.xAxis,
      }));

      // sort them by desc dates
      getDate.sort(function(a, b) {
        return a.timestamp - b.timestamp;
      });

      // get startdate and end date
      const startDate =
        startEndDateParams && startEndDateParams[0]
          ? new Date(startEndDateParams[0])
          : getDate[0].date; // last 30 days
      const endDate =
        startEndDateParams && startEndDateParams[1]
          ? moment(new Date(startEndDateParams[1]))
          : moment().subtract(1, "days"); // get data until yesterday; we dont include partial days
      // combined start date & end date
      const startEndDate = [moment(startDate), endDate];

      // find the dates between startdate and end date
      const findBetweenDates = current => {
        if (current <= moment(getDate[0].date)) {
          return true;
        } else if (current > moment().subtract(1, "days")) {
          return true;
        } else {
          return false;
        }
      };

      const setStartAndEndDateParams = dates => {
        if (startEndDateParams && startEndDateParams.length) {
          const params = new URLSearchParams(window.location.search);
          if (startEndDateParams[0]) params.delete("startDate");
          if (startEndDateParams[1]) params.delete("endDate");

          params.append("startDate", dates[0]);
          params.append("endDate", dates[1]);

          history.push(
            `/home/analytics/display${params ? `?${params.toString()}` : ""}`
          );
        }
      };

      return (
        <RangePicker
          style={{ width: "100%" }}
          disabledDate={findBetweenDates}
          defaultPickerValue={startEndDate}
          defaultValue={startEndDate}
          value={
            isEmpty(bySummaryRangeDate) ? startEndDate : bySummaryRangeDate
          }
          onChange={v => {
            if (!v) {
              setBySummaryRangeDate(startEndDate);
              setDataSummaryRangeDate(reportData);
              setStartAndEndDateParams(startEndDate);
              return;
            }
            // get selected range dates
            const getSelectedStartDate = moment(v[0])
              .hour(0)
              .minute(0)
              .second(0)
              .unix();
            const getSelectedEndDate = moment(v[1])
              .hour(23)
              .minute(59)
              .second(59)
              .unix();
            // filter data to get only between range dates
            const filteredData = filterByDate(
              reportData,
              getSelectedStartDate,
              getSelectedEndDate
            );
            // set the date range on the selected date in calendar
            setBySummaryRangeDate(v);
            // set the data into filtered ones with the selected range dates
            setDataSummaryRangeDate(filteredData);

            setStartAndEndDateParams([moment(v[0]), moment(v[1])]);
          }}
        />
      );
    };

    return (
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          md={{ span: 8, order: 1 }}
          lg={{ span: 7, order: 1 }}
          xl={{ span: 4, order: 1 }}
        >
          {!isEmpty(orgs) && (
            <AdvertiserFilter
              isSinglePage={true}
              isDemo={orgConfigAccess.isDemo}
            />
          )}
        </Col>
        <Col
          xs={24}
          md={{ span: 11, order: 4 }}
          lg={{ span: 10, order: 4 }}
          xl={{ span: 11, order: 5 }}
        >
          {campaignOrders && campaignOrders.length > 0 && (
            <SelectCampaignOrder
              campaignOrders={campaignOrders}
              selectedCOIds={selectedCOIds}
              setSelectedCOIds={setSelectedCOIds}
              isDemo={orgConfigAccess.isDemo}
            />
          )}
        </Col>
        <Col
          xs={24}
          md={{ span: 10, order: 2 }}
          lg={{ span: 9, order: 2 }}
          xl={{ span: 4, order: 2 }}
        >
          {reportData && reportData.length > 0 && <DateSelector />}
        </Col>
        <Col
          xs={24}
          md={{ span: 4, order: 3, offset: 2 }}
          lg={{ span: 3, order: 3, offset: 2 }}
          xl={{ span: 3, offset: 10, order: 3 }}
        >
          <Button
            style={styles.buttonS}
            shape="round"
            type={"default"}
            onClick={() => {
              setBySummaryRangeDate([]);
              setFilteredOrg({
                id: null,
                name: null,
              });
              setSelectedCOIds([]);
              const currentUrlParams = new URLSearchParams(
                window.location.search
              );
              currentUrlParams.set("advertiserId", "");
              history.push(
                window.location.pathname + "?" + currentUrlParams.toString()
              );
            }}
            disabled={
              filteredOrg
                ? filteredOrg.id === null &&
                  isEmpty(bySummaryRangeDate) &&
                  isEmpty(selectedCOIds)
                : false
            }
            icon={<CloseOutlined />}
          >
            Clear
          </Button>
        </Col>
        <Col
          xs={24}
          md={{ span: 4, order: 5, offset: 9 }}
          lg={{ span: 3, order: 5, offset: 9 }}
          xl={{ span: 3, order: 4, offset: 0 }}
        >
          {orgConfigAccess.exportData && (
            <ExportReportMenu
              loading={loadingReport}
              disabled={!reportData || reportData.length === 0}
              onExportCSV={onExportCSV}
              onExportExcel={onExportExcel}
              isExportCompleted={isExportCompleted}
              setIsExportCompleted={setIsExportCompleted}
              onExportWeeklyReport={onExportWeeklyReport}
              onExportWeeklyReportExcel={onExportWeeklyReportExcel}
              weeklyReportData={weeklyReportData}
              filteredOrg={filteredOrg}
              currentUser={currentUser}
            />
          )}
        </Col>
      </Row>
    );
  }
);

FiltersBar.propTypes = {
  orgs: PropTypes.array,
  selectedKey: PropTypes.string,
};

export default FiltersBar;
