export function cleanseNumericValue(value, defaultValue) {
  // return 0 if not valid
  if (!Number.isFinite(value)) {
    return defaultValue;
  }

  if (isNaN(value)) {
    return defaultValue;
  }
  return value;
}
