import gql from "graphql-tag";

const DELETE_AUDIENCE_LOCATION = gql`
  mutation deleteAudience($Id: ID!) {
    deleteAudience(where: { id: $Id }) {
      id
    }
  }
`;

export default DELETE_AUDIENCE_LOCATION;
