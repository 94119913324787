import gql from "graphql-tag";

const DELETE_HASHED_EMAIL_LIST = gql`
  mutation deleteHashedEmailList($id: ID!) {
    deleteHashedEmailList(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_HASHED_EMAIL_LIST;
