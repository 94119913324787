import gql from "graphql-tag";
const UPDATE_MANY_AD_GROUP_CREATIVES = gql`
  mutation updateManyAdGroupCreativeses(
    $orgId: ID!
    $isAmplifyDefault: Boolean
  ) {
    updateManyAdGroupCreativeses(
      where: { org_some: { id: $orgId }, isAmplifyDefault: true }
      data: { isAmplifyDefault: $isAmplifyDefault }
    ) {
      count
    }
  }
`;

export default UPDATE_MANY_AD_GROUP_CREATIVES;
