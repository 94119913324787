import React from "react";
import { graphql } from "react-apollo";
import { withRouter, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import compose from "lodash/flowRight";
import PaidSearchDetailsController from "./PaidSearchDetailsController";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import { unset } from "lodash";
import { Auth } from "aws-amplify";

import { possibleFieldsPaidSearchCampaign } from "../../../utils/constants/constants";

import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import PAID_SEARCH_SUMMARY_ORG from "../../../GraphQl/Queries/PAID_SEARCH_SUMMARY_ORG";
import PAID_SEARCH_REPORTS from "../../../GraphQl/Queries/PAID_SEARCH_REPORTS";
import PAID_SEARCH_UPDATE_CONFIG from "../../../GraphQl/Mutations/PAID_SEARCH_UPDATE_CONFIG";

import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import TRIGGER_AIRFLOW_DAG from "../../../GraphQl/Queries/TRIGGER_AIRFLOW_DAG";
import TAG_ORGS from "../../../GraphQl/Queries/TAG_ORGS";

const PaidSearchDetailsLoader = props => {
  const { match, history, currentUser } = props;
  const { params } = match;
  const { id } = params;
  const location = useLocation();

  const config = currentUser.permission;
  const orgConfig = determineUserAccess(config);
  const currentOrgId = currentUser.role.org.id;

  const searchParams = new URLSearchParams(props.location.search);

  let where = {
    name_contains: "",
  };

  where = {
    AND: [],
  };

  const search = searchParams.get("search");
  if (search) where["AND"].push({ name_contains: search });

  const status = searchParams.get("status");
  if (status) where["AND"].push({ status: status.toUpperCase() });

  const excluded = searchParams.get("excluded");
  if (excluded) where["AND"].push({ name_not_contains: excluded });

  //Bing Where clause;
  let bingWhere = {
    AND: [],
  };

  const searchBing = searchParams.get("searchBing");
  if (searchBing) bingWhere["AND"].push({ name_contains: searchBing });

  const statusBing = searchParams.get("statusBing");
  if (statusBing) bingWhere["AND"].push({ status: statusBing.toUpperCase() });

  const excludedBing = searchParams.get("excludedBing");
  if (excludedBing) bingWhere["AND"].push({ name_not_contains: excludedBing });

  let {
    data: paidSearchSummary,
    loading: loadingSummary,
    error: errorSummary,
    refetch,
    subscribeToMore,
    ...result
  } = useQuery(PAID_SEARCH_SUMMARY_ORG, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  //Query Campaigns used in reports seperately. since it has byDate on it that has a lot of rows
  const {
    data: paidSearchCampaigns,
    loading: loadingPaidSearchCampaigns,
  } = useQuery(PAID_SEARCH_REPORTS, {
    variables: {
      id,
      where,
      bingWhere,
    },
  });

  const [updateConfig] = useMutation(PAID_SEARCH_UPDATE_CONFIG);

  const [reprocessPaidSearch] = useLazyQuery(TRIGGER_AIRFLOW_DAG, {
    fetchPolicy: "no-cache",
  });

  if (
    errorSummary &&
    errorSummary.graphQLErrors &&
    errorSummary.graphQLErrors[0].extensions &&
    errorSummary.graphQLErrors[0].extensions.code === "401"
  ) {
    //Force signout if user has no access
    Auth.signOut();
    window.location.reload();
  }

  //Tags option for Paid Search Creation
  const { data: eventTags, loading: eventTagsLoading } = useQuery(TAG_ORGS, {
    variables: {
      orgId: currentOrgId,
    },
  });

  if (errorSummary) return null;
  if (loadingSummary) return <OverlaySpinner />;

  const selectedTab = searchParams.get("tab")
    ? searchParams.get("tab")
    : paidSearchSummary
    ? "summary"
    : "";

  const selectedSubTab = searchParams.get("subTab")
    ? searchParams.get("subTab")
    : "summary";

  // handling when summary is manually added to URL
  if (paidSearchSummary === false && selectedTab === "summary") {
    searchParams.delete("tab");
    searchParams.append("tab", "overview");
    history.push(`${location.pathname}?${searchParams.toString()}`);
  }

  !orgConfig.priceVisible && unset(possibleFieldsPaidSearchCampaign, "Budget");

  const defaultColumns =
    JSON.parse(sessionStorage.getItem("defaultColumnsPaidSearch")) ||
    Object.keys(possibleFieldsPaidSearchCampaign);

  const selectedStartDate =
    searchParams.get("startDate") ||
    paidSearchSummary.paidSearchSummary.startDate;

  const selectedEndDate =
    searchParams.get("endDate") || paidSearchSummary.paidSearchSummary.endDate;

  const hasDateParams =
    searchParams.get("startDate") && searchParams.get("endDate");
  if (props.currentUser.isDemo) {
    paidSearchSummary.paidSearchSummary.org.name = "Paid Search Example";
  }
  return (
    <PaidSearchDetailsController
      reports={
        paidSearchCampaigns ? paidSearchCampaigns.paidSearchSummary : null
      }
      loadingCampaigns={loadingPaidSearchCampaigns}
      paidSearchSummary={paidSearchSummary}
      selectedTab={selectedTab || "overview"}
      possibleFields={possibleFieldsPaidSearchCampaign}
      defaultColumns={defaultColumns}
      orgId={
        paidSearchSummary ? paidSearchSummary.paidSearchSummary.org.id : null
      }
      orgConfig={orgConfig}
      refetch={refetch}
      updateConfigMutation={updateConfig}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      hasDateParams={hasDateParams}
      selectedSubTab={selectedSubTab}
      reprocessPaidSearch={reprocessPaidSearch}
      eventTags={eventTags}
      eventTagsLoading={eventTagsLoading}
      {...result}
      {...props}
    >
      {props.children}
    </PaidSearchDetailsController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(PaidSearchDetailsLoader));
