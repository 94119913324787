import React, { useState } from "react";
import { Row, Col, Card, Select } from "antd";

// Global Styling
import {
  CardTitleText,
  MenuItemTextColor,
} from "../../../../../../shared/globalStyling/styledText";
import TransactionsTable from "./TransactionsTable";
import { isEmpty } from "lodash";
import moment from "moment";
import TransactionsChartV2 from "./charts/TransactionChartV2";
import DetailBar4ColV2 from "./DetailBar4ColV2";
import {
  computeROAS,
  computeSpend,
} from "../../../../../../../core/utils/campaigns";
import {
  attributionWindowSelections,
  attributionTypeSelections,
} from "../../../../../../../core/utils/constants/constants";
import { cloneDeep } from "lodash";
import { HasAccess } from "@permify/react-role";
import TransactionCreativeDetails from "./transactionCreatives/TransactionCreativeDetails";
import TransactionPublisherDetails from "./transactionPublishers/TransactionPublisherDetails";

const { Option } = Select;

const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const { campaignCardStyleCreatives } = Style;

const TransactionDetailBarV2 = ({
  selectedCampaign,
  chartDataRaw,
  campaignOrder,
  transactionTarget,
  dateTrue,
  priceVisible,
  reportName,
  setDataAttributionWindow,
  transactionsTable,
  transactionsTableLoading,
  setCurrentIdx,
  attributionWindow,
  setAttributionWindow,
  isDemo,
  currentUser,
  currencyFormat,
  embeddableConfig,
  attributionMethod,
  setAttributionMethod,
  setCurrentStartEndDate,
}) => {
  const sitesAppsTransactions = currentUser.permission.sitesAppsTransactions
    ? currentUser.permission.sitesAppsTransactions
    : false;
  const { startDate, endDate } = selectedCampaign ? selectedCampaign : [];
  const { type, transactionsConfig } = campaignOrder;
  const [transactionData, setTransactionData] = useState([]);
  const [byDate, setByDate] = useState([]);
  let getDate =
    chartDataRaw && chartDataRaw.byDate
      ? cloneDeep(chartDataRaw.byDate).map(a => moment(a.xAxis, "MM/DD/YYYY"))
      : [];

  // sort them by desc dates
  getDate = !isEmpty(getDate) && getDate.sort((a, b) => a.unix() - b.unix());
  const [dateFilter, setDateFilter] = useState([
    getDate && getDate.length && getDate[0],
    getDate && getDate.length && getDate.slice(-1)[0],
  ]);
  const initialSpend = computeSpend(
    0,
    selectedCampaign ? selectedCampaign.cpm : 0
  );
  const [detailBarValues, setDetailBarValues] = useState({
    startDate,
    endDate,
    transactions: 0,
    transactionRevenue: 0,
    customers: 0,
    impressions: 0,
    spend: initialSpend,
    roas: computeROAS(0, initialSpend),
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [creativeDimension, setCreativeDimension] = useState("impressions");
  const [publisherDimension, setPublisherDimension] = useState("impressions");

  const isSimplified =
    chartDataRaw && chartDataRaw.hasSimplifiedReport
      ? chartDataRaw.hasSimplifiedReport
      : false;

  const changeData = (
    selectedByDate,
    selectedAttributionWindow,
    selectedAttributionMethod
  ) => {
    //Clone and isolate date needed to prevent mutation due to state changes
    const byDateChangingData = chartDataRaw.byDate.map(byDate => {
      byDate.xAxis = moment(byDate.xAxis).format("MM/DD/YYYY");
      return byDate;
    });
    const chartData = cloneDeep(chartDataRaw);
    let detailBarFiltered = {
      startDate,
      endDate,
      transactions: 0,
      transactionRevenue: 0,
      customers: 0,
      impressions: 0,
      spend: 0,
      roas: 0,
    };
    //Filtering of transactions data by attribution window
    let filteredTransactionsWithAttribution = chartData.transactionsWithEvents.filter(
      trans => {
        const { transaction, latestDate } = trans;
        const impressionDate = new Date(moment(latestDate).format());
        const outOfRange = new Date(moment(transaction).format());
        outOfRange.setDate(outOfRange.getDate() - selectedAttributionWindow);
        if (impressionDate < outOfRange) {
          return false;
        } else if (impressionDate > outOfRange) {
          return true;
        }
        return false;
      }
    );

    if (selectedAttributionMethod && selectedAttributionMethod !== "ALL") {
      filteredTransactionsWithAttribution = filteredTransactionsWithAttribution.filter(
        a => {
          const { attributionMethod } = a;
          if (attributionMethod.hasOwnProperty(selectedAttributionMethod)) {
            return true;
          }
          return false;
        }
      );
    }

    const filteredTransactions = filteredTransactionsWithAttribution.filter(
      trans => {
        return (
          moment(trans.transaction, "YYYY-MM-DD").isSameOrAfter(
            moment(selectedByDate[0]).format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ) &&
          moment(trans.transaction, "YYYY-MM-DD").isSameOrBefore(
            moment(moment(selectedByDate[1]).format("YYYY-MM-DD"), "YYYY-MM-DD")
          )
        );
      }
    );
    //Generate a new by date object since there is a new set transactions due to attribution change
    const attributedByDate = [];
    filteredTransactions.forEach(t => {
      const date = moment(t.transaction, "YYYY-MM-DD").format("MM/DD/YYYY");
      const chartDataIdx = byDateChangingData.findIndex(c => c.xAxis === date);
      if (attributedByDate.find(c => c.xAxis === date)) {
        const idx = attributedByDate.findIndex(c => c.xAxis === date);
        attributedByDate[idx].transactions += 1;
        attributedByDate[idx].transactionRevenue += t.transactionTotal
          ? t.transactionTotal
          : 0;
      } else {
        attributedByDate.push({
          transactions: 1,
          impressions: byDateChangingData[chartDataIdx]
            ? byDateChangingData[chartDataIdx].impressions
            : 0, //Import the impressions on respective date in the default chart data
          latestDate: t.latestDate
            ? t.latestDate
            : t.impressions.reduce((acc, curr) => {
                if (moment(curr.IMPRESSION_TIME)) {
                  return moment(curr.IMPRESSION_TIME) >
                    moment(acc.IMPRESSION_TIME)
                    ? curr
                    : acc;
                }
                return acc;
              }).IMPRESSION_TIME,
          transactionRevenue: t.transactionTotal ? t.transactionTotal : 0,
          xAxis: date,
        });
      }
    });
    //The data is being malformed withoud cloning it. The reason for this is unknown
    const attributedByDateFinal = cloneDeep(byDateChangingData).map(b => {
      const attributedIdx = attributedByDate.findIndex(
        a => a.xAxis === b.xAxis
      );

      const attributed = attributedByDate[attributedIdx];
      if (attributed) {
        b.transactions = attributed.transactions;
        b.transactionRevenue = attributed.transactionRevenue;
      } else {
        b.transactions = 0;
        b.transactionRevenue = 0;
      }
      detailBarFiltered.transactions += b.transactions;
      detailBarFiltered.transactionRevenue += b.transactionRevenue;
      return b;
    });

    byDateChangingData.forEach(b => {
      if (
        moment(b.xAxis, "MM/DD/YYYY") >=
          moment(selectedByDate[0], "YYYY-MM-DD") &&
        moment(b.xAxis, "MM/DD/YYYY") <= moment(selectedByDate[1], "YYYY-MM-DD")
      ) {
        detailBarFiltered.impressions += b.impressions;
      }
    });

    detailBarFiltered.customers = determineUniques(filteredTransactions);
    detailBarFiltered.spend = computeSpend(
      detailBarFiltered.impressions,
      selectedCampaign ? selectedCampaign.cpm : 0
    );
    detailBarFiltered.roas = computeROAS(
      detailBarFiltered.transactionRevenue,
      detailBarFiltered.spend
    );
    setByDate(attributedByDateFinal);
    setTransactionData(filteredTransactions);
    setDataAttributionWindow(filteredTransactions);
    setDetailBarValues(detailBarFiltered);
    setCurrentIdx(0);
    setCurrentPage(1);
    setDateFilter(selectedByDate);
    setAttributionWindow(selectedAttributionWindow);
    setAttributionMethod(selectedAttributionMethod);
  };

  const determineUniques = data => {
    const uniques = [];
    data.forEach(item => {
      const { attributionMethod } = item;

      //format the attribution method to be stringified
      let val = JSON.stringify(attributionMethod);

      if (!uniques.includes(val)) {
        uniques.push(val);
      }
    });

    return uniques.length;
  };

  if (byDate.length === 0 && chartDataRaw.byDate.length > 0) {
    changeData(dateFilter, attributionWindow, attributionMethod);
  }

  return (
    <>
      <HasAccess permissions={["default", "defaultSSO"]}>
        <DetailBar4ColV2
          data={detailBarValues}
          priceVisible={priceVisible}
          dataConfig={
            !isEmpty(currentUser.role.org)
              ? currentUser.role.org.dataConfig
              : {}
          }
          currencyFormat={currencyFormat}
        />
      </HasAccess>

      <Row>
        <Col span={24}>
          <HasAccess
            permissions={["default", "defaultSSO", "widgetSSOtransactionChart"]}
          >
            {chartDataRaw && !isEmpty(chartDataRaw.byDate) && (
              <TransactionsChartV2
                chartData={byDate}
                defaultStartEndDate={[
                  getDate && getDate.length && getDate[0],
                  getDate && getDate.length && getDate.slice(-1)[0],
                ]}
                selectedStartEndDate={dateFilter}
                dateChange={val => {
                  changeData(val, attributionWindow);
                  setCurrentStartEndDate(val);
                }}
              />
            )}
          </HasAccess>
          <HasAccess
            permissions={["default", "defaultSSO", "widgetSSOtransactionTable"]}
          >
            {sitesAppsTransactions && (
              <>
                <TransactionCreativeDetails
                  transactionsCreatives={
                    chartDataRaw && chartDataRaw.transactionsCreatives
                      ? chartDataRaw && chartDataRaw.transactionsCreatives
                      : []
                  }
                  creativeDimension={creativeDimension}
                  setCreativeDimension={setCreativeDimension}
                />
                <TransactionPublisherDetails
                  transactionsPublishers={
                    chartDataRaw && chartDataRaw.transactionsPublishers
                      ? chartDataRaw && chartDataRaw.transactionsPublishers
                      : []
                  }
                  publisherDimension={publisherDimension}
                  setPublisherDimension={setPublisherDimension}
                />
              </>
            )}

            <Card
              style={campaignCardStyleCreatives}
              headStyle={{
                borderWidth: "3px",
                display: "flex",
                flexDirection: "column",
              }}
              bodyStyle={{
                padding: 0,
              }}
              title={<CardTitleText text={"Transactions"} />}
              extra={
                <Row gutter={16}>
                  <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                    {`Attribution Window`}
                    <Row>
                      <Select
                        placeholder="Please select days"
                        value={attributionWindow}
                        style={{ width: "200px" }}
                        onChange={val => {
                          changeData(dateFilter, val, attributionMethod);
                        }}
                      >
                        {attributionWindowSelections.map(option => {
                          return (
                            <Option key={option.key} value={option.key}>
                              <MenuItemTextColor text={option.name} />
                            </Option>
                          );
                        })}
                      </Select>
                    </Row>
                  </Col>
                  <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                    {`Attribution Method`}
                    <Row>
                      <Select
                        placeholder="Attribution method"
                        style={{ width: "200px" }}
                        value={attributionMethod}
                        onChange={val => {
                          changeData(dateFilter, attributionWindow, val);
                        }}
                      >
                        {attributionTypeSelections.map(option => {
                          return (
                            <Option key={option.key} value={option.key}>
                              <MenuItemTextColor text={option.name} />
                            </Option>
                          );
                        })}
                      </Select>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <TransactionsTable
                reportName={reportName}
                campaignOrderId={campaignOrder.id}
                transactionData={
                  !isSimplified
                    ? transactionData
                    : transactionsTable
                    ? transactionsTable.record
                    : []
                }
                transactionTotalRecord={
                  isSimplified && transactionsTable
                    ? transactionsTable.count
                    : 0
                }
                transactionTarget={transactionTarget}
                type={type}
                transactionsConfig={transactionsConfig}
                dateTrue={dateTrue}
                dateFilter={dateFilter}
                isSimplified={isSimplified}
                setCurrentIdx={setCurrentIdx}
                loading={transactionsTableLoading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isDemo={isDemo}
              />
            </Card>
          </HasAccess>
        </Col>
      </Row>
    </>
  );
};

export default TransactionDetailBarV2;
