import React from "react";
import moment from "moment";
import { Table, Card } from "antd";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../../shared/globalStyling/styledText";
import {
  formatCurrency2SigFig,
  formatWholeNumber,
} from "../../../../../../../core/utils/campaigns";
import ItemsSubTable from "./ItemsSubTable";

const TransactionTable = ({
  transactions,
  hasItems = false,
  location,
  history,
  pageNumber,
}) => {
  const { edges, aggregate } = transactions;
  let columns = [
    {
      title: <TableColumnTitle text={"Order"} />,
      index: "orderId",
      key: "orderId",
      render: (text, { orderId }) => {
        return <PrimaryTableRowText text={orderId} />;
      },
    },
    {
      title: <TableColumnTitle text={"Ordered On"} />,
      index: "orderedOn",
      key: "orderedOn",
      render: (text, { orderedOn }) => {
        return (
          <PrimaryTableRowText
            text={moment(orderedOn)
              .utc()
              .format("YYYY-MM-DD H:mm:ss")}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Total Items"} />,
      index: "items",
      key: "items",
      render: (text, { totalItems }) => {
        return <PrimaryTableRowText text={formatWholeNumber(totalItems)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Item Total"} />,
      index: "baseTotal",
      key: "baseTotal",
      render: (text, { baseTotal }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(baseTotal)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Tax"} />,
      index: "tax",
      key: "tax",
      render: (text, { tax }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(tax)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Total"} />,
      index: "total",
      key: "total",
      render: (text, { revenue }) => {
        return <PrimaryTableRowText text={formatCurrency2SigFig(revenue)} />;
      },
    },
  ];

  if (!hasItems) {
    columns = [
      {
        title: <TableColumnTitle text={"Order"} />,
        index: "orderId",
        key: "orderId",
        render: (text, { orderId }) => {
          return <PrimaryTableRowText text={orderId} />;
        },
      },
      {
        title: <TableColumnTitle text={"Ordered On"} />,
        index: "orderedOn",
        key: "orderedOn",
        render: (text, { orderedOn }) => {
          return (
            <PrimaryTableRowText
              text={moment(orderedOn)
                .utc()
                .format("YYYY-MM-DD H:mm:ss")}
            />
          );
        },
      },
      {
        title: <TableColumnTitle text={"Total"} />,
        index: "total",
        key: "total",
        render: (text, { revenue }) => {
          return <PrimaryTableRowText text={formatCurrency2SigFig(revenue)} />;
        },
      },
    ];
  }
  return (
    <Card title={"Transactions"}>
      <Table
        dataSource={edges}
        columns={columns}
        pagination={{
          position: ["none", "bottomCenter"],
          total: aggregate.count,
          current: Number(pageNumber),
          responsive: true,
          showSizeChanger: false,
          showLessItems: true,
        }}
        rowKey={({ id }) => id}
        expandable={
          hasItems
            ? {
                expandedRowRender: record => {
                  return <ItemsSubTable items={record.items} />;
                },
              }
            : null
        }
        onChange={(paginationObject, filtersObject, sorterObject) => {
          const nextPage = location.pathname.split("/");

          nextPage[4] = paginationObject.current;
          const searchParams = new URLSearchParams(window.location.search);
          history.push({
            pathname: nextPage.join("/"),
            search: `?${searchParams.toString()}`,
          });
        }}
      />
    </Card>
  );
};

export default TransactionTable;
