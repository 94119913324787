import gql from "graphql-tag";

const LIQUID_M_REGIONS = gql`
  query liquidMRegions(
    $filter: LiquidMRegionsFilterInput
    $page: LiquidMRegionsPageInput
  ) {
    liquidMRegions(filter: $filter, page: $page)
  }
`;

export default LIQUID_M_REGIONS;
