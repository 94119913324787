import gql from "graphql-tag";
const BULK_UPLOAD_LOCATIONS = gql`
  mutation bulkUploadLocations($data: JSON!) {
    bulkUploadLocations(data: $data) {
      id
      name
      description
      street
      city
      state
      lat
      lng
      formattedAddress
      email
      phone
      contact
      url
      locationKey
      zip
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
      geoframe {
        id
        name
        start
        end
        polygons {
          id
          geometry
        }
        circles {
          id
          coordinate {
            lat
            lng
          }
          radius
        }
      }
      transConfig {
        id
        cart
        menuDataKey
      }
      audienceType
      createdBy {
        name
      }
    }
  }
`;

export default BULK_UPLOAD_LOCATIONS;
