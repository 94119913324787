import gql from "graphql-tag";

const GET_ORG_THEME = gql`
  query getOrgThemeConfig($orgID: ID) {
    getOrgThemeConfig(where: { id: $orgID }) {
      sidebarColor
      primaryColor
    }
  }
`;

export default GET_ORG_THEME;
