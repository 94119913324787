import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
import { withRouter } from "react-router-dom";
import GET_ROLL_UP_PAID_SEARCH_ANALYTICS from "../../../GraphQl/Queries/GET_ROLL_UP_PAID_SEARCH_ANALYTICS";
import PAID_SEARCH_PACING from "../../../GraphQl/Queries/PAID_SEARCH_PACING";
import PAID_SEARCH_PACING_GOOGLE from "../../../GraphQl/Queries/PAID_SEARCH_PACING_GOOGLE";
import PAID_SEARCH_PACING_BING from "../../../GraphQl/Queries/PAID_SEARCH_PACING_BING";
import PAID_SEARCH_PACING_CAMPAIGNS from "../../../GraphQl/Queries/PAID_SEARCH_PACING_CAMPAIGNS";
import PaidSearchAnalyticsController from "./PaidSearchAnalyticsController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import moment from "moment";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import { isEmpty } from "lodash";

const PaidSearchAnalyticsLoader = ({ children, ...props }) => {
  const { currentUser } = props;

  const loggedInRole = currentUser.role;
  const orgId = loggedInRole.org.id;

  const searchParams = new URLSearchParams(props.location.search);
  const advertiserParams = searchParams.get("advertiserId");
  const selectedStartDate = searchParams.get("startDate");
  const selectedEndDate = searchParams.get("endDate");
  const selectedPacingDate = searchParams.get("pacingDate");
  const status = searchParams.get("status");

  const [advertiserIds, setAdvertiserIds] = useState([orgId]);
  useEffect(() => {
    if (!advertiserParams) {
      return setAdvertiserIds([orgId]);
    }
    setAdvertiserIds(advertiserParams.split("+"));
  }, [advertiserParams, orgId, selectedStartDate, selectedEndDate]);

  const config = currentUser.permission;
  const orgConfig = determineUserAccess(config);

  const { loading, data, error, refetch } = useQuery(
    GET_ROLL_UP_PAID_SEARCH_ANALYTICS,
    {
      variables: {
        orgId,
        startDate: selectedStartDate
          ? moment(new Date(selectedStartDate))
              .startOf("day")
              .utc()
              .format("YYYY-MM-DD")
          : undefined,
        endDate: selectedEndDate
          ? moment(new Date(selectedEndDate))
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD")
          : undefined,
        advertiserIds,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const [getPrevRangeData, { data: prevRangeData }] = useLazyQuery(
    GET_ROLL_UP_PAID_SEARCH_ANALYTICS
  );

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = moment(selectedStartDate);
      const endDate = moment(selectedEndDate);

      const diff = endDate.diff(startDate, "days");
      const prevStart = selectedStartDate
        ? moment(startDate)
            .subtract(diff, "days")
            .startOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined;
      const prevEnd = selectedEndDate
        ? moment(endDate)
            .subtract(1, "day")
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD")
        : undefined;
      getPrevRangeData({
        variables: {
          orgId,
          startDate: prevStart,
          endDate: prevEnd,
          advertiserIds: !isEmpty(advertiserIds) ? advertiserIds : undefined,
        },
        notifyOnNetworkStatusChange: true,
      });
    }
  }, [
    advertiserIds,
    data,
    selectedEndDate,
    getPrevRangeData,
    orgId,
    selectedStartDate,
  ]);

  let [getPacing, { loading: pacingLoading, data: pacingData }] = useLazyQuery(
    PAID_SEARCH_PACING,
    {
      variables: {
        orgId,
        advertiserIds,
        date: selectedPacingDate,
        status,
      },
      fetchPolicy: "no-cache",
    }
  );

  let [
    getPacingGoogle,
    { loading: pacingGoogleLoading, data: pacingGoogleData },
  ] = useLazyQuery(PAID_SEARCH_PACING_GOOGLE, {
    variables: {
      orgId,
      advertiserIds,
      date: selectedPacingDate,
      status,
    },
    fetchPolicy: "no-cache",
  });

  let [
    getPacingBing,
    { loading: pacingBingLoading, data: pacingBingData },
  ] = useLazyQuery(PAID_SEARCH_PACING_BING, {
    variables: {
      orgId,
      advertiserIds,
      date: selectedPacingDate,
      status,
    },
    fetchPolicy: "no-cache",
  });

  let [
    getPacingAllCampaigns,
    { loading: pacingAllCampaignsLoading, data: pacingAllCampaignsData },
  ] = useLazyQuery(PAID_SEARCH_PACING_CAMPAIGNS, {
    variables: {
      orgId,
      advertiserIds,
      date: selectedPacingDate,
      status,
    },
    fetchPolicy: "no-cache",
  });

  let [
    getSingleCampaigns,
    { loading: pacingSingleCampaignsLoading, data: pacingSingleCampaignsData },
  ] = useLazyQuery(PAID_SEARCH_PACING_CAMPAIGNS, { fetchPolicy: "no-cache" });

  if (!data && loading) return <OverlaySpinner />;

  const defaultSummaryDates =
    selectedStartDate && selectedEndDate
      ? [
          moment(new Date(selectedStartDate))
            .startOf("d")
            .utc(),
          moment(new Date(selectedEndDate))
            .endOf("d")
            .utc(),
        ]
      : !isEmpty(data.getRollUpPaidSearchAnalytics) &&
        !isEmpty(data.getRollUpPaidSearchAnalytics.byDate)
      ? [
          moment(data.getRollUpPaidSearchAnalytics.byDate[0].xAxis).utc(),
          moment(data.getRollUpPaidSearchAnalytics.byDate.at(-1).xAxis).utc(),
        ]
      : [
          moment()
            .startOf("d")
            .utc(),
          moment()
            .endOf("d")
            .utc(),
        ];

  if (orgConfig.isDemo) {
    if (pacingData) {
      pacingData.getPaidSearchPacing = pacingData.getPaidSearchPacing.map(
        (c, i) => {
          c.org.name = `Example Paid Seach ${i}`;
          return c;
        }
      );
    }
    if (pacingGoogleData) {
      pacingGoogleData.getPaidSearchPacingGoogle = pacingGoogleData.getPaidSearchPacingGoogle.map(
        (c, i) => {
          c.org.name = `Example Google Paid Seach ${i}`;
          return c;
        }
      );
    }
    if (pacingBingData) {
      pacingBingData.getPaidSearchPacingBing = pacingBingData.getPaidSearchPacingBing.map(
        (c, i) => {
          c.org.name = `Example Bing Paid Seach ${i}`;
          return c;
        }
      );
    }
    if (pacingAllCampaignsData) {
      pacingAllCampaignsData.getPaidSearchPacingCampaigns = pacingAllCampaignsData.getPaidSearchPacingCampaigns.map(
        (c, i) => {
          c.name = `Example Paid Search ${i}`;
          c.campaigns = c.campaigns.map((cc, idx) => {
            cc.name = `Example Paid Search Campaign ${idx}`;
            return cc;
          });
          return c;
        }
      );
    }
    if (pacingSingleCampaignsData) {
      pacingSingleCampaignsData.getPaidSearchPacingCampaigns = pacingSingleCampaignsData.getPaidSearchPacingCampaigns.map(
        (c, i) => {
          c.name = `Example Paid Search ${i}`;
          c.campaigns = c.campaigns.map((cc, idx) => {
            cc.name = `Example Paid Search Campaign ${idx}`;
            return cc;
          });
          return c;
        }
      );
    }
  }
  return (
    <PaidSearchAnalyticsController
      {...props}
      refetch={refetch}
      data={data}
      prevRangeData={prevRangeData}
      error={error}
      loading={loading}
      orgConfig={orgConfig}
      advertiserParams={advertiserParams}
      defaultSummaryDates={defaultSummaryDates}
      orgId={orgId}
      pacingData={pacingData}
      pacingGoogleData={pacingGoogleData}
      pacingBingData={pacingBingData}
      pacingAllCampaignsData={pacingAllCampaignsData}
      pacingSingleCampaignsData={pacingSingleCampaignsData}
      startDateParams={selectedStartDate}
      endDateParams={selectedEndDate}
      getPacing={getPacing}
      getPacingGoogle={getPacingGoogle}
      getPacingBing={getPacingBing}
      getPacingAllCampaigns={getPacingAllCampaigns}
      getSingleCampaigns={getSingleCampaigns}
      pacingLoading={pacingLoading}
      pacingGoogleLoading={pacingGoogleLoading}
      pacingBingLoading={pacingBingLoading}
      pacingAllCampaignsLoading={pacingAllCampaignsLoading}
      pacingSingleCampaignsLoading={pacingSingleCampaignsLoading}
    >
      {children}
    </PaidSearchAnalyticsController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(withApollo(PaidSearchAnalyticsLoader)));
