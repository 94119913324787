import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Popconfirm,
  message,
  Modal,
  Tooltip,
  Select,
  Card,
} from "antd";

//locals from shared
import PermissionToggler from "./PermissionToggler";
import ConfigurationToggler from "./ConfigurationToggler";
import SingleImageUploader from "../../../shared/images/SingleImageUploader";
import { errorType } from "../../../../core/utils/constants/constants";
// Utility imports
import { QuestionCircleOutlined } from "@ant-design/icons";
import { determineUserAccess } from "../../../../core/permissions/userLevelAccess";
import has from "lodash/has";
import CryptoJS from "crypto-js";
import AppearanceToggler from "./AppearanceToggler";
import { UserEmbeddableConfigModal } from "./UserEmbeddableConfigModal";
import EmbeddableUserLoader from "../../../../core/components/embeddableUsers";

const { Option } = Select;

const UsersDetails = ({
  createUser,
  updateUser,
  deleteUser,
  disableUser,
  enableUser,
  onChange,
  onSwitchChange,
  selectedUser,
  isMediaJelAdmin,
  resendTempPassword,
  resetPassword,
  currentuser,
  orgs,
  moveUser,
  setUserForm,
  updateUserEmbeddableConfig,
  ...props
}) => {
  const {
    id,
    features,
    avatar: _avatar,
    username,
    status,
    config,
    roles,
  } = selectedUser;
  const { canResetPasswords, isAdmin } = determineUserAccess(
    currentuser.permission
  );

  const tempConfig = {
    priceVisible: false,
    isDemo: false,
    isAdmin: false,
    isPartner: false,
    isTrafficker: false,
    exportData: false,
    isSelfService: false,
    hasInsights: false,
    providersVisible: false,
    isProcessor: false,
    canResetPasswords: false,
    campaignSummary: false,
    isPacing: false,
    pastData: false,
    segment: false,
    technicalSupport: false,
    horizontalNavigation: false,
    articles: false,
    amplify: false,
    configurator: false,
    billing: false,
    sitesAppsTransactions: false,
    isBeta: false,
  };

  // Configuration
  const permissionsConfig = props.selectedOrg.config;
  const [showModal, setShowModal] = useState(false);

  const [tempPassword, setTempPassword] = useState("");

  const [showModalMoveUser, setShowModalMoveUser] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  const isDisabled = id === currentuser.id;

  const userIsEnabled = Object.keys(features).includes("ENABLED");

  const [userForm] = Form.useForm();

  const [confirmGenerateToken, setConfirmGenerateToken] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [visibleEmbeddableForm, setVisibleEmbeddableForm] = useState(false);

  const isDisplayFeatureDisabled =
    !Array.isArray(
      selectedUser.features ? selectedUser.features.CAMPAIGNS : {}
    ) || !selectedUser.features.CAMPAIGNS.length;

  useEffect(() => {
    setInitialValues({
      ...selectedUser,
      phone: formatPhoneNumber(selectedUser.phone),
    });
  }, [selectedUser]);

  // Handles the validation of the form and submission of data to GQL
  const handleSubmit = e => {
    setIsUpdating(true);

    userForm
      .validateFields()
      .then(() => {
        // Validation is successful
        message.loading("Action in progress..", 0);

        // Update state based on the values of the form
        Object.entries(userForm.getFieldsValue()).forEach(([key, value]) =>
          onChange(key, value)
        );

        // If the selected customer already has an ID we know they are in the DB
        if (id) {
          updateUser()
            .then(() => {
              message.destroy();
              message.success(`User ${username} successfully updated`);
              setIsUpdating(false);
            })
            .catch(err => {
              message.destroy();
              if (err === "feature in empty") {
                message.error(
                  `Please select at least one feature to update the user!`
                );
              } else {
                message.error(`Error: ${username} not updated`);
              }
              setIsUpdating(false);
            });
        } else {
          createUser()
            .then(() => {
              message.destroy();
              message.success(`User ${username} successfully created`);
              setIsUpdating(false);
            })
            .catch(err => {
              message.destroy();
              if (err === errorType.usernameDuplication) {
                message.error(`Error: '${username}' username already exists!`);
                setIsUpdating(true);
              } else if (err === "feature in empty") {
                message.error(
                  `Please select at least one feature to create a user!`
                );
              } else {
                message.error(`${err}`);
              }
              setIsUpdating(false);
            });
        }
      })
      .catch(errors => {
        // Errors in the fields
        setIsUpdating(false);
      });
  };

  const onClickDisable = e => {
    e.stopPropagation();

    setIsUpdating(true);
    message.loading("Action in progress..", 0);
    disableUser()
      .then(() => {
        message.destroy();
        message.success(`User ${username} successfully disabled`);
        setIsUpdating(false);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} not disabled`);
        setIsUpdating(false);
      });
  };

  const onClickDelete = e => {
    e.stopPropagation();

    setIsUpdating(true);
    message.loading("Action in progress..", 0);
    deleteUser()
      .then(() => {
        message.destroy();
        message.success(`User ${username} successfully deleted`);
        setIsUpdating(false);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} not deleted`);
        setIsUpdating(false);
      });
  };

  const onClickEnabled = e => {
    setIsUpdating(true);
    message.loading("Action in progress..", 0);
    enableUser()
      .then(() => {
        message.destroy();
        message.success(`User ${username} successfully enabled`);
        setIsUpdating(false);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} not enabled`);
        setIsUpdating(false);
      });
  };

  const handleResendTempPassword = e => {
    setIsUpdating(true);
    message.loading("Resending Temporary Password..", 0);
    resendTempPassword(username)
      .then(res => {
        message.destroy();
        message.success(
          `User ${username} successfully resend temporary password`
        );
        setIsUpdating(false);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} not enabled`);
        setIsUpdating(false);
      });
  };

  const handleResetPassword = e => {
    setIsUpdating(true);
    message.loading("Reseting Password..", 0);

    resetPassword({ username, permanent: false })
      .then(res => {
        message.destroy();
        message.success(`User ${username} successfully reset password`);
        setShowModal(true);
        setTempPassword(res);
        setIsUpdating(false);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} not enabled`);
        setIsUpdating(false);
      });
  };

  const handleGenerateSSOToken = async () => {
    try {
      message.loading("Generating token...", 0);
      setIsUpdating(true);
      const password = await resetPassword({ username, permanent: true });
      const token = CryptoJS.AES.encrypt(
        `username=${username}|password=${password}`,
        process.env.REACT_APP_TOKEN_SECRET
      ).toString();

      const data = {
        embeddableConfig: {
          create: {
            ssoToken: token,
          },
        },
      };

      await updateUserEmbeddableConfig({
        id: selectedUser.config.id,
        data,
      });
      setIsUpdating(false);
      setVisibleEmbeddableForm(true);

      message.destroy();
      message.success("Token generated successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to generate token!");
    }
  };

  const handleOk = e => {
    setIsUpdating(true);
    setShowModal(false);
    navigator.clipboard.writeText(tempPassword);
    message.success(`Successfully copied temporary password`);
    setIsUpdating(false);
  };

  const handleChange = value => {
    setSelectedOrg(value);
  };

  const handleMoveUser = e => {
    setIsUpdating(true);
    setShowModalMoveUser(false);
    message.loading("Moving User..", 0);

    moveUser(selectedOrg, roles[0].id, username, id)
      .then(res => {
        message.destroy();
        message.success(`User ${username} successfully move`);
        setIsUpdating(false);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(err => {
        message.destroy();
        message.error(`Error: ${username} cannot be move`);
        setIsUpdating(false);
      });
  };

  const handleConfirmGenerateToken = () => {
    handleGenerateSSOToken();
    setConfirmGenerateToken(false);
  };

  const formatPhoneNumber = phoneNumber => {
    if (!phoneNumber) return "";
    if (phoneNumber.toString().startsWith("+1")) {
      return phoneNumber.slice(2);
    }

    return phoneNumber;
  };

  // Initialize the form if it is a pristine form or
  // an update form
  useEffect(() => {
    if (!has(selectedUser, "id") && !selectedUser.isNewForm) {
      userForm.resetFields();
      onChange("isNewForm", true);
    } else if (selectedUser.isExistingUser) {
      userForm.setFieldsValue(selectedUser);
    }
  }, [onChange, selectedUser, userForm]);

  return (
    <>
      {confirmGenerateToken && (
        <Modal
          title="Generate Token"
          visible={confirmGenerateToken}
          onOk={handleConfirmGenerateToken}
          onCancel={() => setConfirmGenerateToken(false)}
        >
          <p>Generating token will reset current selected user password.</p>
          <b style={{ marginTop: 12 }}>
            Are you sure you want to generate token?
          </b>
        </Modal>
      )}

      {visibleEmbeddableForm && (
        <EmbeddableUserLoader>
          <UserEmbeddableConfigModal
            visible={visibleEmbeddableForm}
            setVisible={setVisibleEmbeddableForm}
            selectedOrg={props.selectedOrg}
            selectedUser={selectedUser}
            updateUserEmbeddableConfig={updateUserEmbeddableConfig}
            isDisplayFeatureDisabled={isDisplayFeatureDisabled}
            handleGenerateSSOToken={handleGenerateSSOToken}
          />
        </EmbeddableUserLoader>
      )}

      <Card
        headStyle={{
          border: "0.5px solid #e8e8e8",
          background: "#FAFAFA",
        }}
        title="Details"
        extra={
          <Row type="flex" justify="end" gutter={10}>
            {isAdmin && id ? (
              <React.Fragment>
                {isMediaJelAdmin && (
                  <Col>
                    <Popconfirm
                      title="This is permanent. Delete this user?"
                      onConfirm={e => onClickDelete(e)}
                      okText="Yes"
                      cancelText="No"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      disabled={isDisabled}
                    >
                      <Button
                        size="small"
                        type="danger"
                        ghost={true}
                        disabled={isDisabled}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
                {userIsEnabled ? (
                  <Col>
                    <Popconfirm
                      title="Disable this user?"
                      onConfirm={e => onClickDisable(e)}
                      okText="Yes"
                      cancelText="No"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      disabled={isDisabled}
                    >
                      <Button
                        size="small"
                        type="danger"
                        ghost={true}
                        disabled={isDisabled}
                      >
                        Disable
                      </Button>
                    </Popconfirm>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      size="small"
                      type="primary"
                      disabled={isDisabled}
                      style={{
                        borderColor: "#52c41a",
                        background: "#52c41a",
                      }}
                      onClick={onClickEnabled}
                    >
                      Enable
                    </Button>
                  </Col>
                )}
                <Col>
                  <Tooltip title={"Move user to other Org"}>
                    <Button
                      size="small"
                      type="info"
                      onClick={() => setShowModalMoveUser(true)}
                      disabled={isUpdating}
                    >
                      Move User
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Button
                    size="small"
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                    disabled={isUpdating}
                  >
                    Update
                  </Button>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>
        }
      >
        <Form
          onSubmit={handleSubmit}
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          form={userForm}
          scrollToFirstError
        >
          <Row type="flex" justify="center" gutter={40}>
            <Col span={12} xs={24} xl={12} style={{ minWidth: "180px" }}>
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    pattern: /^[A-Za-z0-9]+$/,
                    message:
                      "Username must be one word containing letters and numbers",
                  },
                  {
                    required: true,
                    message: "Username is required!",
                  },
                ]}
              >
                <Input disabled={has(selectedUser, "id")} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} xl={12} style={{ minWidth: "180px" }}>
              <Form.Item label="Avatar">
                <SingleImageUploader
                  onChange={onChange}
                  image={_avatar}
                  statekey="avatar"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={22} xs={24} xl={12} style={{ minWidth: "180px" }}>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" justify="center" gutter={40}>
            <Modal
              width={400}
              title={`Temporary Password for ${username}`}
              visible={showModal}
              closable={false}
              centered
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                  disabled={isUpdating}
                >
                  Copy & Close
                </Button>,
              ]}
              onCancel={() => setShowModal(false)}
            >
              <div style={{ textAlign: "center" }}>
                <h1>{tempPassword}</h1>
              </div>
            </Modal>

            <Modal
              width={400}
              title={`Move user:  ${username}`}
              visible={showModalMoveUser}
              footer={[
                <Button
                  key="back"
                  disabled={isUpdating}
                  onClick={() => setShowModalMoveUser(false)}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  disabled={isUpdating}
                  onClick={handleMoveUser}
                >
                  Move User
                </Button>,
              ]}
              onCancel={() => setShowModalMoveUser(false)}
            >
              <div style={{ textAlign: "center" }}>
                <Input.Group compact>
                  <Select
                    style={{ width: "100%", borderRadius: 0 }}
                    defaultValue={"Select Organization"}
                    value={selectedOrg ? selectedOrg : "Select Organization"}
                    onChange={handleChange}
                  >
                    {orgs &&
                      orgs.map(val => {
                        const { id, name } = val;
                        return (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        );
                      })}
                  </Select>
                </Input.Group>
              </div>
            </Modal>
            <Col span={12} xs={24} xl={12} style={{ minWidth: "180px" }}>
              <Form.Item
                // Note: Update email disabled until implemented on the backend
                // disabled={!!id}
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} xl={12} style={{ minWidth: "180px" }}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value && !/^[0-9]{10}$/.test(value)) {
                        return Promise.reject(
                          new Error(
                            `Format must be 10 digits and not contain spaces or special characters.`
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  addonBefore="+1"
                  maxLength={10}
                  placeholder="1112223333"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Preferences">
            <AppearanceToggler
              config={!config ? tempConfig : config}
              disabled={isDisabled}
              onSwitchChange={onSwitchChange}
            />
          </Form.Item>
          <Form.Item label="Account Roles">
            <PermissionToggler
              loggedInPermissions={props.selectedOrg.features}
              currentPermissions={features}
              disabled={isDisabled}
              onSwitchChange={onSwitchChange}
            />
          </Form.Item>
          <Form.Item>
            <ConfigurationToggler
              isAdmin={false}
              isMJParent={isDisabled}
              loggedInPermissions={permissionsConfig}
              config={!config ? tempConfig : config}
              disabled={isDisabled}
              onSwitchChange={onSwitchChange}
              isSelfService={true}
              isProcessor={true}
              orgTab={false}
            />
          </Form.Item>
          {isAdmin && (
            <Form.Item>
              <Row
                type="flex"
                justify="end"
                gutter={10}
                style={{ marginTop: 16 }}
              >
                {isAdmin && selectedUser.id && (
                  <Button
                    type="primary"
                    onClick={() =>
                      selectedUser.config &&
                      selectedUser.config.embeddableConfig
                        ? setVisibleEmbeddableForm(true)
                        : setConfirmGenerateToken(true)
                    }
                    loading={isUpdating}
                  >
                    {selectedUser.config && selectedUser.config.embeddableConfig
                      ? "Edit Embeddable Config"
                      : "Enable Embeddable User"}
                  </Button>
                )}

                {id ? (
                  <React.Fragment>
                    {id &&
                    status === "FORCE_CHANGE_PASSWORD" &&
                    canResetPasswords ? (
                      <Col>
                        <Tooltip title={"Resend Temporary Password via SMS"}>
                          <Button
                            type="primary"
                            disabled={isDisabled || isUpdating}
                            onClick={handleResendTempPassword}
                          >
                            Re-send Temporary Password
                          </Button>
                        </Tooltip>
                      </Col>
                    ) : null}

                    {id && canResetPasswords && (
                      <Col>
                        <Tooltip title={"Set Temporary Password"}>
                          <Button
                            type="primary"
                            disabled={isDisabled || isUpdating}
                            onClick={handleResetPassword}
                          >
                            Set Password
                          </Button>
                        </Tooltip>
                      </Col>
                    )}
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                        disabled={isUpdating}
                      >
                        Update
                      </Button>
                    </Col>
                  </React.Fragment>
                ) : (
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                      disabled={isUpdating}
                    >
                      Create
                    </Button>
                  </Col>
                )}
              </Row>
            </Form.Item>
          )}
        </Form>
      </Card>
    </>
  );
};

export default UsersDetails;
