import gql from "graphql-tag";
const PAID_SEARCH_BING = gql`
  query paidSearchSummaryBing(
    $id: ID!
    $bingWhere: PaidSearchBingCampaignsWhereInput!
  ) {
    paidSearchSummary(where: { id: $id }) {
      id
      startDate
      endDate
      overallBing {
        id
        impressions
        clicks
        cost
        revenue
        cpc
        cpm
        ctr
        roas
        conversions
        conversionRate
        byDate(orderBy: date_ASC) {
          impressions
          clicks
          cost
          revenue
          cpc
          cpm
          ctr
          roas
          spend
          conversions
          conversionRate
          date
          xAxis
        }
      }
      paidSearchBingCampaigns(where: $bingWhere) {
        id
        name
        startDate
        endDate
        adProviderId
        status
        data {
          id
          impressions
          clicks
          spend
          revenue
          conversions
          byDate(orderBy: date_ASC) {
            impressions
            clicks
            spend
            revenue
            conversions
            date
            xAxis
          }
        }
      }
    }
  }
`;

export default PAID_SEARCH_BING;
