import gql from "graphql-tag";

const EDITORIAL_PAUSE_PLAY = gql`
  mutation editorialPausePlay($id: ID!, $status: EditorialStatus!) {
    updateEditorial(where: { id: $id }, data: { status: $status }) {
      id
      status
    }
  }
`;

export default EDITORIAL_PAUSE_PLAY;
