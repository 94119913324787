import gql from "graphql-tag";

const GET_ORG = gql`
  query org($where: OrgWhereUniqueInput!) {
    org(where: $where) {
      id
      name
      description
      website
      zone
      level
      roles {
        id
        isMaster
      }
      dataConfig {
        menuId
        googleCustomerId
        tradeDeskIdentifier
        storageBucket
        dspAdvertiserId
        roasFormat
        defaultDisplayAttributionWindow
        defaultPaidSearchAttributionWindow
        defaultSEOAttributionWindow
        currency {
          currency
          conversionValue
        }
        semrushEnable
        semrushConfig {
          projectId
          rootKeyword
          rootDomainUrl
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        seo
        seoDomainRankings
        googleAnalytics {
          viewId
          isGA4
          keyFileName
        }
        tagProcessingConfig {
          processTag
        }
        paidSearchDataConfig {
          isEnabled
        }
        bing {
          accountId
          customerId
        }
      }
    }
  }
`;

export default GET_ORG;
