import React from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../../../../core/components/accounts/Loader";
import SetupMFA from "../components/SetupMFA";
import AccountsView from "../AccountsView";

// Feature level subroutes
const AccountsRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route exact path={`${path}/mfa`} component={SetupMFA} />
        <Route
          path={`${path}`}
          render={() => (
            <Loader>
              <AccountsView />
            </Loader>
          )}
        />
      </Switch>
    </div>
  );
};

export default AccountsRoutes;
