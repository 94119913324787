import gql from "graphql-tag";

const ORG_SEO = gql`
  query orgsSEO($id: ID) {
    orgSeoSummary(where: { id: $id }) {
      id
      createdAt
      updatedAt
      startDate
      endDate
      eCommerceTracking
      enhancedECommerceTracking
      seoSummaryData
      key
      commerceKey
      linkAcquisition {
        id
        createdAt
        updatedAt
        acquiredLinks {
          id
          sourceURL
          destinationURL
          contentType
          createdAt
          updatedAt
        }
      }
      byDate {
        newUsers
        session
        bounceRate
        revenue
        pageView
        conversionRate
        transactions
        transactionsPerSession
        avgSessionDuration
        avgOrderValue
        date
      }
      commerce {
        id
      }
      org {
        id
        name
        domain
      }
    }
    keywordsReport: orgSeoSummary(where: { id: $id }) {
      id
      reports(
        where: { type: SEO_KEYWORDS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
      }
    }
    trafficReport: orgSeoSummary(where: { id: $id }) {
      id
      reports(where: { type: SEO_TRAFFIC }, orderBy: createdAt_DESC, first: 1) {
        id
        key
      }
    }
    conversionsReport: orgSeoSummary(where: { id: $id }) {
      id
      reports(
        where: { type: SEO_CONVERSIONS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
      }
    }
  }
`;

export default ORG_SEO;
