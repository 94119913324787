import gql from "graphql-tag";

const GET_TARGETING_GROUPS = gql`
  query targetingGroupsConnections(
    $where: AudienceWhereInput
    $orderBy: AudienceOrderByInput = createdAt_DESC
    $first: Int
    $skip: Int
  ) {
    audiencesConnection(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          type
          isAmplifyDefault
          geoTargets {
            id
            name
            location {
              id
              name
              formattedAddress
              street
              state
              city
              zip
              lat
              lng
              country
              county
            }
            polygons {
              geometry
            }
            circles {
              radius
              coordinate {
                lat
                lng
              }
              location {
                id
                name
                formattedAddress
                zip
                street
                city
                county
                state
                country
                lat
                lng
              }
            }
          }
          eventsTargets {
            id
          }
          createdAt
          updatedAt
          createdBy {
            id
            name
          }
          orgs {
            id
            name
          }
        }
      }
    }
    full: audiencesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_TARGETING_GROUPS;
