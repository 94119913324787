import React, { useEffect, useMemo, useState } from "react";
import { columnsObj } from "../../../../platform/ux/Home/components/ContractTermsTab/utils";
import { TABLE_ORDER_ASC } from "../../../utils/constants/constants";
import { calculateImpressionsGoal } from "../../../../core/utils/campaigns";

function toTableData(edges) {
  return edges.map(i => {
    const campaignOrder = i.node;
    return {
      key: i.cursor,
      id: campaignOrder.id,
      name: campaignOrder.name,
      startDate: campaignOrder.startDate,
      endDate: campaignOrder.endDate,
      cpm: campaignOrder.cpm,
      budget: campaignOrder.budget,
      impressionsGoal: calculateImpressionsGoal(campaignOrder),
    };
  });
}

const ContractTermsController = ({
  data,
  loading,
  refetch,
  error,
  filterOptions,
  dataExport,
  loadingExport,
  getExport,
  defaultWhere,
  getContractTerms,
  ...props
}) => {
  const [skip, setSkip] = useState(0);
  const [first, setFirst] = useState(10);
  const [orderBy, setOrderBy] = useState("startDate_DESC");

  const [selectedCIDs, setSelectedCIDs] = useState([]);
  const [pagination, setPagination] = useState({
    current: skip / first < 1 ? 1 : skip / first,
    pageSize: first,
  });

  let tableData = [];

  if (data && data.campaignOrdersConnection.edges) {
    const edges = data.campaignOrdersConnection.edges;
    tableData = toTableData(edges);
  }

  const total =
    data && data.full && data.full.aggregate ? data.full.aggregate.count : 0;

  const filterDataOptions = useMemo(() => {
    if (
      filterOptions &&
      filterOptions.campaignOrdersConnection &&
      filterOptions.campaignOrdersConnection.edges
    ) {
      return filterOptions.campaignOrdersConnection.edges.map(e => ({
        id: e.node.id,
        name: e.node.name,
      }));
    }
    return [];
  }, [filterOptions]);

  const onExport = () => {
    const whereIncludedCampaignOrderIds = {
      AND: [{ id_in: selectedCIDs.length > 0 ? selectedCIDs : undefined }],
    };

    getExport({
      variables: {
        where:
          selectedCIDs.length > 0
            ? whereIncludedCampaignOrderIds
            : defaultWhere,
      },
    });
  };

  const exportTableData = useMemo(() => {
    if (dataExport && dataExport.campaignOrdersConnection) {
      return toTableData(dataExport.campaignOrdersConnection.edges);
    }
  }, [dataExport]);

  const handleSorterChange = (key, order) => {
    let orderByInput;
    switch (key) {
      case columnsObj.name.key:
        orderByInput = order === TABLE_ORDER_ASC ? "name_ASC" : "name_DESC";
        break;
      case columnsObj.startDate.key:
        orderByInput =
          order === TABLE_ORDER_ASC ? "startDate_ASC" : "startDate_DESC";
        break;
      case columnsObj.endDate.key:
        orderByInput =
          order === TABLE_ORDER_ASC ? "endDate_ASC" : "endDate_DESC";
        break;
      case columnsObj.budget.key:
        orderByInput = order === TABLE_ORDER_ASC ? "budget_ASC" : "budget_DESC";
        break;
      case columnsObj.cpm.key:
        orderByInput = order === TABLE_ORDER_ASC ? "cpm_ASC" : "cpm_DESC";
        break;
      default:
        orderByInput = "createdAt_DESC";
        break;
    }
    setOrderBy(orderByInput);
  };

  useEffect(() => {
    setFirst(pagination.pageSize);
    setSkip(pagination.current * pagination.pageSize - pagination.pageSize);
  }, [setSkip, setFirst, pagination]);

  useEffect(() => {
    getContractTerms({
      variables: {
        where:
          selectedCIDs.length > 0
            ? {
                AND: [
                  { id_in: selectedCIDs.length > 0 ? selectedCIDs : undefined },
                ],
              }
            : defaultWhere,
        first,
        skip,
        orderBy,
      },
      notifyOnNetworkStatusChange: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getContractTerms,
    refetch,
    selectedCIDs,
    pagination,
    first,
    skip,
    orderBy,
  ]);

  return React.cloneElement(props.children, {
    data: tableData,
    loading,
    refetch,
    first,
    skip,
    orderBy,
    total,
    pagination,
    setPagination,
    setSkip,
    setFirst,
    setOrderBy,
    error,
    handleSorterChange,
    filterDataOptions,
    selectedCIDs,
    setSelectedCIDs,
    onExport,
    loadingExport,
    exportTableData,
    ...props,
  });
};

export default ContractTermsController;
