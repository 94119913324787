import gql from "graphql-tag";
const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $id: ID!
    $startDate: DateTime
    $endDate: DateTime
    $budgetTotal: Float
    $orgs: OrgUpdateManyWithoutCampaignsInput
  ) {
    updateCampaign(
      data: {
        startDate: $startDate
        endDate: $endDate
        budgetTotal: $budgetTotal
        orgs: $orgs
      }
      where: { id: $id }
    ) {
      id
      startDate
      endDate
      budgetTotal
      orgs {
        id
        name
      }
    }
  }
`;

export default UPDATE_CAMPAIGN;
