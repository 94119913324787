import React from "react";
import { Button, Col, Form, Input, Row, Space, Table, Typography } from "antd";
import "./styles.css";
import moment from "moment";

const { Text, Title } = Typography;

const columns = [
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  // {
  //   title: "Email Count",
  //   dataIndex: "hashedEmailsCount",
  //   key: "hashedEmailsCount",
  //   render: count => (count ? count.toLocaleString() : "N/A"),
  // },
  {
    title: "Devices",
    dataIndex: "deviceCount",
    key: "deviceCount",
    render: count => (count ? count.toLocaleString() : "N/A"),
  },
  {
    title: "Match %",
    dataIndex: "matchRate",
    key: "matchRate",
    render: rate => (rate ? rate.toFixed(2).toLocaleString() : "N/A"),
  },
  {
    title: "Status",
    dataIndex: "activated",
    key: "activated",
    render: activated => (activated ? "Activated" : "Deactivated"),
  },
  // {
  //   title: "Device IDs",
  //   dataIndex: "deviceIds",
  //   key: "deviceIds",
  // },
  // {
  //   title: "Info",
  //   dataIndex: "info",
  //   key: "info",
  // },
  {
    title: "Uploaded",
    dataIndex: "createdAt",
    key: "createdAt",
    render: date => moment(date).format("MM/DD/YYYY hh:mma"),
  },
];
export const CampaignRequestorAudienceTable = ({
  name,
  restField,
  fieldKey,
  setShowFirstPartyDataModal,
  editable = false,
  form,
  index,
  audienceType,
}) => {
  const campaigns = form.getFieldValue("campaigns");
  const firstPartyDataAudiences =
    campaigns[index].firstPartyDataAudiences || [];

  return (
    <div className="requestor-table-wrapper" style={{ marginTop: 20 }}>
      <>
        <div className="requestor-table-header">
          <Row>
            <Col span={12}>
              <Title level={5}>FIRST PARTY AUDIENCES</Title>
            </Col>
            <Col span={12}>
              {firstPartyDataAudiences.length && editable ? (
                <div style={{ textAlign: "end" }}>
                  <Button onClick={() => setShowFirstPartyDataModal(true)}>
                    Edit Audiences
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
        {!firstPartyDataAudiences.length ? (
          <div style={{ padding: 20 }}>
            <Space
              direction="vertical"
              size="small"
              align="center"
              style={{ width: "100%" }}
            >
              {editable ? (
                <>
                  <Text>
                    Select a first party audience to target from your library
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowFirstPartyDataModal(true);
                    }}
                  >
                    Select Audience
                  </Button>
                </>
              ) : (
                <Text>No audiences selected</Text>
              )}
            </Space>
          </div>
        ) : (
          <Table
            key="name"
            columns={columns}
            dataSource={firstPartyDataAudiences}
            pagination={false}
            className="requestor-table"
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  {firstPartyDataAudiences.length} Audiences
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={1}>
                  {firstPartyDataAudiences
                    .reduce(
                      (acc, audience) => acc + audience.hashedEmailsCount,
                      0
                    )
                    .toLocaleString()}
                </Table.Summary.Cell> */}
                <Table.Summary.Cell index={2}>
                  {firstPartyDataAudiences
                    .reduce((acc, audience) => acc + audience.deviceCount, 0)
                    .toLocaleString()}
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3} index={3}>
                  {firstPartyDataAudiences
                    .reduce((acc, audience) => acc + audience.matchRate, 0)
                    .toFixed(2)
                    .toLocaleString()}{" "}
                  %
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        )}
      </>
      {/* Create hidden input for firstPartyDataAudiences */}
      <Form.Item
        {...restField}
        name={[name, "firstPartyDataAudiences"]}
        fieldKey={[fieldKey, "firstPartyDataAudiences"]}
        hidden
        rules={[
          {
            required: audienceType === "FIRST_PARTY_DATA",
            message: "Please select a at least one first party audience",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
