import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS = gql`
  mutation updateCampaignOrderWithGeoLocations(
    $campaignOrderID: ID!
    $geoTargets: GeoTargetUpdateManyWithoutCampaignOrderInput!
  ) {
    updateCampaignOrder(
      where: { id: $campaignOrderID }
      data: { geoTargets: $geoTargets }
    ) {
      id
      status
      type
      name
      description
      attributionWindow
      transactionTarget
      transactionsConfig {
        id
        appId
        cart
        traffId
        tracksBasketItems
        gtmAccess
        gtmStatus
        updatedAt
        tag
      }
      cpm
      region
      geoTargets {
        vwiUpdated
        id
        name
        status
        orgs {
          id
          name
        }
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            province
            country
            lat
            lng
            audienceType
            hasNotes
            notes
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
        }
      }
      attributionTargets {
        id
        name
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            lat
            lng
            state
            province
            country
            county
            audienceType
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
          status
        }
      }
      orgs {
        id
        name
        phone
        logo {
          id
          key
        }
      }
      advertiser {
        id
        name
      }
      language
      category
      isRegulated
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        id
        name
        cpm
        budgetTotal
        adProviderId
        demandSidePlatform
        lineItems {
          id
          name
          cpm
          adUnits {
            id
            banners {
              id
              adId
              cpm
              media {
                id
                name
              }
            }
          }
        }
      }
      changeLog {
        id
        description
        createdAt
        changedBy {
          id
          username
          avatar {
            id
            key
          }
        }
      }
      targetingCriteria
      awarenessCampaign
      signUpCampaign
      signUpConfig {
        id
        appId
        locationId
      }
      pageViewConfig {
        id
        appId
        locationId
      }
      requestorId
      campaignReviewProcess {
        id
        review
        stateLaw
        scaleBudget
      }
      reviewerId
    }
  }
`;

export { UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS };
