import gql from "graphql-tag";

const DELETE_REGION_GROUP = gql`
  mutation deleteRegionGroup($Id: ID!) {
    deleteRegionGroup(where: { id: $Id }) {
      id
    }
  }
`;

export default DELETE_REGION_GROUP;
