import React from "react";
import { Progress, Row, Col, Card } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiFlightDate,
} from "../../../../shared/globalStyling/styledText";

import { formatWholeNumber } from "../../../../../core/utils/campaigns";
import { ArrowRightOutlined } from "@ant-design/icons";

const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};

export const DetailBar = ({ selectedAudience }) => {
  let range01 = new Date(),
    range02 = new Date();

  let val02 = 0,
    val03 = 0,
    val04 = 0;
  if (selectedAudience) {
    const {
      startDate,
      endDate,
      uniqueCount,
      deviceIdCount,
      geoTargets,
      eventsTargets,
    } = selectedAudience;
    let deviceCount = 0;
    if (geoTargets && !isEmpty(geoTargets)) {
      geoTargets.forEach(target => {
        const { uniqueDevices } = target;
        if (uniqueDevices) {
          deviceCount = deviceCount + uniqueDevices;
        }
      });
    }
    if (eventsTargets && !isEmpty(eventsTargets)) {
      eventsTargets.forEach(target => {
        const { uniqueDevices } = target;
        if (uniqueDevices) {
          deviceCount = deviceCount + uniqueDevices;
        }
      });
    }

    let expandedDeviceCount = 0;
    if (geoTargets && !isEmpty(geoTargets)) {
      geoTargets.forEach(target => {
        const { expandedReach } = target;
        if (expandedReach) {
          expandedDeviceCount = expandedDeviceCount + expandedReach;
        }
      });
    }
    if (eventsTargets && !isEmpty(eventsTargets)) {
      eventsTargets.forEach(target => {
        const { expandedReach } = target;
        if (expandedReach) {
          expandedDeviceCount = expandedDeviceCount + expandedReach;
        }
      });
    }

    //From this point - component functions in a generic manner
    range01 = startDate ? startDate : new Date();
    range02 = endDate ? endDate : new Date();
    val02 = uniqueCount;
    val03 = deviceIdCount;
    val04 = expandedDeviceCount;
  }

  //Break into utils
  const getProgress = () => {
    const total = () => {
      var a = moment(range01);
      var b = moment(range02);
      const total = a.diff(b, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(range01);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();
    if (progressDays > totaldays || progressDays === totaldays) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;
    return Math.floor(result);
  };

  const currentposition = getProgress();

  const audienceData = [val02, val03, val04];
  const isEmptyData = audienceData.every(item => item === 0);

  return (
    !isEmptyData && (
      <Row style={styles.container} wrap={true}>
        <Col span={24}>
          <Row>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Date Range"} />
                </div>
                <div style={styles.datesContainer}>
                  <div style={styles.dates}>
                    <KpiFlightDate
                      text={range01 ? moment(range01).format("MM/DD/YYYY") : ""}
                    />
                  </div>
                  <div>
                    <ArrowRightOutlined style={{ color: "#636363" }} />
                  </div>
                  <div style={styles.dates}>
                    <KpiFlightDate
                      text={range02 ? moment(range02).format("MM/DD/YYYY") : ""}
                    />
                  </div>
                </div>
                <Progress
                  showInfo={false}
                  percent={currentposition}
                  status={currentposition !== 100 ? "active" : "normal"}
                  strokeColor={"#636363"}
                />
              </Card>
            </Col>

            {val02 > 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Audience Signals"} />
                  </div>
                  <KpiSubText
                    text={val02 ? ` ${formatWholeNumber(val02)}` : " N/A"}
                  />
                </Card>
              </Col>
            )}
            {val03 > 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Devices"} />
                  </div>
                  <KpiSubText
                    text={val03 ? ` ${formatWholeNumber(val03)}` : " N/A"}
                  />
                </Card>
              </Col>
            )}
            {val04 > 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Extended Devices"} />
                  </div>
                  <KpiSubText
                    text={val04 ? ` ${formatWholeNumber(val04)}` : " N/A"}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  );
};
