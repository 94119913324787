import React from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import { advertiserTypeMap } from "../../../core/utils/constants/constants";

import "./styles.css";
import { CampaignRequestorRetailStoreTable } from "./CampaignRequestorRetailStoreTable";

const { Text } = Typography;

const CampaignRequestorCategory = ({ form }) => {
  const isRegulated = form.getFieldValue("isRegulated");
  const category = form.getFieldValue("category");
  const advertiserType = form.getFieldValue("advertiserType");

  return (
    <Card title="CATEGORY">
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Regulated Industry</Text>
              <Text>{isRegulated ? "Yes" : "No"}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Industry Type</Text>
              <Text>{category}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Advertiser Type</Text>
              <Text>{advertiserTypeMap[advertiserType]}</Text>
            </Space>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <CampaignRequestorRetailStoreTable
              form={form}
              title="RETAIL STORE LOCATION"
            />
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default CampaignRequestorCategory;
