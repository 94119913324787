import gql from "graphql-tag";

const CREATE_RETAIL_LOCATION = gql`
  # TODO: Enhance payload handling for this mutation
  # Redundant with CREATE_LOCATION_WITH_GEOTARGET?
  # @params: data: LocationCreateInput!
  mutation createLocation(
    $name: String!
    $description: String!
    $street: String!
    $city: String!
    $state: StateType!
    $lat: Float!
    $lng: Float!
    $country: CountryType!
    $email: String!
    $url: String!
    $phone: String!
    $contact: String!
    $formattedAddress: String!
    $zip: String!
    $orgId: ID!
    $cart: CartProvider
    $menuDataKey: String
    $locationKey: String
    $audienceType: AudienceType
    $province: String
  ) {
    createLocation(
      data: {
        name: $name
        description: $description
        street: $street
        city: $city
        state: $state
        lat: $lat
        lng: $lng
        country: $country
        email: $email
        url: $url
        phone: $phone
        contact: $contact
        formattedAddress: $formattedAddress
        zip: $zip
        org: { connect: { id: $orgId } }
        transConfig: { create: { cart: $cart, menuDataKey: $menuDataKey } }
        locationKey: $locationKey
        audienceType: $audienceType
        province: $province
      }
    ) {
      id
      name
      description
      street
      city
      state
      province
      lat
      lng
      formattedAddress
      email
      phone
      contact
      url
      locationKey
      zip
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
      geoframe {
        id
        name
      }
      transConfig {
        id
        cart
        menuDataKey
      }
      audienceType
    }
  }
`;

export default CREATE_RETAIL_LOCATION;
