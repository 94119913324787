import gql from "graphql-tag";

const CREATE_ARTICLE = gql`
  mutation createArticle($title: String!, $content: String!, $categoryId: ID!) {
    createArticle(
      data: {
        title: $title
        content: $content
        category: { connect: { id: $categoryId } }
      }
    ) {
      id
      createdAt
      updatedAt
      category {
        id
        createdAt
        updatedAt
        title
      }
      title
      content
    }
  }
`;

export default CREATE_ARTICLE;
