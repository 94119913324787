import React, { useState, useEffect } from "react";
import { groupBy, cloneDeep, isEmpty } from "lodash";
import {
  hideChartDataPrice,
  isDemoWalkins,
  prepareTransactions,
} from "../../../utils/campaigns";
import { determineUserAccess } from "../../../../core/permissions/userLevelAccess";
import { defaultAttributionWindow } from "../../../utils/constants/constants";

const CampaignChartsController = ({
  children,
  url,
  expiresAt,
  refetch,
  ...props
}) => {
  const dataConfig = props.currentUser.role.org.dataConfig;
  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const [rawAttributionData, setRawAttributionData] = useState(null);
  const [loadingRawAttributionData, setLoadingRawAttributionData] = useState(
    false
  );
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  // TODO: Change let to const: You're not supposed to directly modify state values.
  // Instead, you should use the setter function returned by useState
  let [chartData, setChartData] = useState({});

  //Time check happens first
  useEffect(() => {
    const currentTime = new Date().toUTCString();
    if (currentTime <= expiresAt) {
      //url is valid - set url
      setS3Url(url);
      setIsValidUrl(true);
    } else {
      //url is valid - refetch
      setIsValidUrl(false);
      refetch();
      setS3Url(null);
    }
  }, [expiresAt, s3Url, setS3Url, refetch, url]);

  useEffect(() => {
    const { campaignOrder } = props;
    if (
      campaignOrder &&
      campaignOrder.transactionsConfig &&
      campaignOrder.transactionsConfig.cart &&
      !chartData.byDate
    ) {
      setChartData(
        prepareTransactions(
          chartData,
          campaignOrder.transactionsConfig.cart,
          campaignOrder.version || 0
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campaignOrder]);

  //If check happens - we have a url (from reload or first load)
  useEffect(() => {
    if (s3Url) {
      setLoadingRawAttributionData(true);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              //If defualt attribution is set on org config. override the existing attibution summary depending on the default attribution window
              if (
                defaultDisplayAttributionWindow !== 30 &&
                json.attributionWindowReports &&
                json.attributionWindowReports.length > 0
              ) {
                const attributionWindowReport = json.attributionWindowReports.find(
                  report =>
                    report.attributionWindow === defaultDisplayAttributionWindow
                );
                json.completeAttributionSummary =
                  attributionWindowReport.byDate;
                json.transactionsOverallData =
                  attributionWindowReport.transactionsOverallData;
              }

              setRawAttributionData(json);
              setLoadingRawAttributionData(false);
            });
          } catch (err) {
            setLoadingRawAttributionData(false);
          }
        }
      });
    }
  }, [defaultDisplayAttributionWindow, s3Url]);

  //priceVisible is customer facing - All data will have DSP costs stripped unless the logged in organization is MediaJelAdmin

  const userPermission = determineUserAccess(props.currentUser.permission);

  const { priceVisible, isDemo, providersVisible } = userPermission;

  useEffect(() => {
    if (rawAttributionData) {
      // might need loading state here to prevent page jumps
      let provider = props.selectedProvider;
      let nextChartData = cloneDeep(rawAttributionData);

      if (props.currentUser.role.org.name !== "MediaJelAdmin") {
        //Always strip DSP costs - except for organization MediaJelAdmin - (Can be used for feature preview, billing etc.)
        nextChartData = hideChartDataPrice(nextChartData, [
          "byDate",
          "byDay",
          "deviceType",
          "creatives",
          "publishers",
          "lineItems",
          "overallData",
          "dailyData",
          "byLineItemCreativeDate",
        ]);
      }

      if (provider !== "All")
        nextChartData =
          nextChartData.byProvider &&
          nextChartData.byProvider[String(provider).toLowerCase()];

      if (isDemo && nextChartData) {
        nextChartData = isDemoWalkins(nextChartData);
      }

      if (nextChartData) {
        setChartData({
          ...nextChartData,
          attributionByLocation: groupBy(
            nextChartData.walkinByLocationComplete,
            "GEOTARGETID"
          ),
          observationsByLocation: groupBy(
            nextChartData.observationsByGeoTarget,
            "GEO_TARGET_ID"
          ),
        });
      }
    }
  }, [
    rawAttributionData,
    props.selectedProvider,
    priceVisible,
    isDemo,
    props.currentUser.role.org.name,
  ]);

  if (isDemo && !isEmpty(chartData)) {
    chartData = isDemoWalkins(chartData);
  }

  if (isValidUrl === true) {
    return React.cloneElement(children, {
      ...props,
      providersVisible,
      chartData,
      setChartData,
      chartsLoading: loadingRawAttributionData,
      loadingRawAttributionData,
      isValidUrl,
    });
  } else {
    return React.cloneElement(children, {
      ...props,
      chartData,
      setChartData,
      isValidUrl,
      chartsLoading: loadingRawAttributionData,
    });
  }
};

export default CampaignChartsController;
