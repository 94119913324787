import gql from "graphql-tag";

const EDIT_SEGMENT = gql`
  mutation editSegment(
    $memberId: Int!
    $short_name: String!
    $code: String!
    $price: Float!
    $segmentId: Int!
    $description: String!
  ) {
    editSegment(
      where: { member_id: $memberId, segment_id: $segmentId }
      data: {
        short_name: $short_name
        code: $code
        price: $price
        description: $description
      }
    ) {
      appnexusId
      short_name
      member_id
      advertiser_id
      description
      last_activity
      provider
      code
      category
      price
      active
    }
  }
`;

export default EDIT_SEGMENT;
