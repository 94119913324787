import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import DemographicsMainView from "../DemographicsMainView";
import AudienceBuilderPage from "../components/AudienceBuilderPage";

import DemographicsMainLoader from "../../../../core/components/demographics/DemographicsMainLoader";
import DemographicsController from "../../../../core/components/demographics/DemographicsController";
import { AudienceBuilderProvider } from "../context/AudienceBuilderContext";

// Feature level subroutes
const DemographicRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}`}
        exact
        render={() => (
          <AudienceBuilderProvider>
            <DemographicsMainLoader>
              <DemographicsController>
                <DemographicsMainView />
              </DemographicsController>
            </DemographicsMainLoader>
          </AudienceBuilderProvider>
        )}
      />
      <Route
        path={`${path}/audience_builder/`}
        exact
        render={() => (
          <AudienceBuilderProvider>
            <DemographicsMainLoader>
              <DemographicsController>
                <AudienceBuilderPage />
              </DemographicsController>
            </DemographicsMainLoader>
          </AudienceBuilderProvider>
        )}
      />
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default DemographicRoutes;
