import gql from "graphql-tag";

const SEND_MESSAGE = gql`
  mutation SEND_CHAT_MESSAGE($from: String!, $message: String!) {
    sendChatMessage(from: $from, message: $message) {
      id
      from
      message
    }
  }
`;

export default SEND_MESSAGE;
