import React, { useEffect, useState } from "react";
import {
  Form,
  Col,
  Row,
  Affix,
  Card,
  Modal,
  Tooltip,
  Select,
  Typography,
  Space,
  Button,
  Spin,
  message,
} from "antd";
import { CSVLink } from "react-csv";
import { flatten, isEmpty, capitalize } from "lodash";
import { stateList } from "../../../core/utils/constants/stateList";
import { kmToMiles, metersToMiles } from "../../../core/utils/campaigns";
import {
  FileTextOutlined,
  PlusOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  EnvironmentOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

// Local Component Imports
import LauncherMap from "../../shared/maps/LauncherMap";
import { GeoTargetManager } from "../Campaigns/geotargetmanager/GeoTargetManager";
import useLocationsDropdown from "../../shared/maps/hooks/useLocationsDropdown";
import LocationsDropdown from "../../shared/maps/LocationsDropdown";
import TextArea from "antd/lib/input/TextArea";
import CustomButton from "../../shared/globalStyling/Button";
// import { OrganizationConfig } from "../OrgConfig/configs/organizationConfig";
// import TemplateCSVDownloader from "../../shared/TemplateCSVDownloader";

// COMPONENTS
import GeotargetManagerController from "../../../core/components/campaigns/geotargetmanager/GeotargetManagerController";
import NavigationButtons from "./components/NavigationButtons";
import StepsCard from "./components/StepsCard";
// import OrganizationCsvLocationUpload from "../OrgConfig/components/OrganizationCsvLocationUpload";
import TargetingLocationPreviewImport from "../OrgConfig/components/Targeting/TargetingLocationPreviewImport";
import TargetingLocationListView from "../OrgConfig/components/Targeting/TargetingLocationListView";
import TargetingLocationForm from "../OrgConfig/components/Targeting/TargetingLocationForm";
import TargetingAddressSearch from "../OrgConfig/components/Targeting/TargetingAddressSearch";
import TargetingAudienceSearch from "../OrgConfig/components/Targeting/TargetingAudienceSearch";
import { RegionConfig } from "../../shared/configs/RegionConfig";
import { debounce } from "lodash";
import RegionForm from "./components/RegionsForm";
//import TargetingLocationAudiences from "../OrgConfig/components/Targeting/TargetingLocationAudiences";
export const advertiserIdParamKey = "advertiserId";
export const parentAdvertiserIdParamKey = "parentAdvertiserId";
const { Text } = Typography;

const styles = {
  rows: {
    marginBottom: "15px",
  },
  map: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  mapCard: {
    padding: 0,
  },
  input: {
    position: "relative",
    height: "100%",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #1AA89D",
  },
  outerContainer: {
    height: "80vh",
  },
  innerContainer: {
    padding: 4,
    backgroundColor: "#fff",
  },
  tableHeaderRow: {
    marginBottom: "20px",
  },
  cardBody: {
    padding: 0,
  },
  col: {
    marginTop: "15px",
  },
  card: {
    marginBottom: "15px",
  },
  cardSuccess: {
    border: "1px solid rgb(42, 203, 112)",
    marginBottom: "15px",
  },
};

const CampaignLauncherViewTargeting = props => {
  const {
    tablet,
    type,
    changeValue,
    geoTargetData,
    updateCampaignOrderWithGeoLocations,
    isModalVisibleTargeting,
    audienceTargetingToAdd,
    targetingLocations,
    updateFromExistingAudience,
    onChange,
    targetingCriteria,
    onSubmitLoad,
    cloneGeoTargetData,
    isCloningClicked,
    searchRegionLiquidM,
    searchRegionLiquidMData,
    searchRegionLiquidMLoading,
    region,
    google,
    // onTargetingLocationCsvUpload,
    previewTargetingLocations,
    setPreviewTargetingLocations,
    bulkImportTargetingLocations,
    selectTargetingLocationPreview,
    targetingAudiences,
    //deleteAudienceLocation,
    regionGroups,
    selectedTargetingLocations,
    handleAddTargetFromAddresses,
    handleUpdateTargtingAddress,
    addLocationNotes,
    currentUser,
    handleDeleteGeoTarget,
    geoTargets,
    refetchGeoTarget,
    campaignName,
    status,
  } = props;

  const isAdmin = currentUser.permission.isAdmin;
  const { isTrafficker } =
    currentUser && currentUser.permission ? currentUser.permission : {};

  const geoData =
    isCloningClicked === true
      ? cloneGeoTargetData.concat(geoTargetData)
      : geoTargetData;

  const [currentLocationId, setCurrentLocationId] = useLocationsDropdown(
    "",
    geoData
  );

  const [loading, setLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormRegionOpen, setIsFormRegionOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedRegionGroup, setSelectedRegionGroup] = useState(null);
  const [selectedRegionCountry, setSelectedRegionCountry] = useState(null);

  const [selectedRegions, setSelectedRegions] = useState([]);

  const [geoTargetingAreas, setGeoTargetingAreas] = useState([]);
  const [geoTargetingAddresses, setGeoTargetingAddresses] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  const regionGroupsExtracted =
    regionGroups && Array.isArray(regionGroups.getRegionGroupWithPresetList)
      ? regionGroups.getRegionGroupWithPresetList
      : [];

  useEffect(() => {
    setSelectedRegions(region);
  }, [region]);

  useEffect(() => {
    if (
      searchRegionLiquidMData &&
      searchRegionLiquidMData.liquidMRegions &&
      searchRegionLiquidMData.liquidMRegions
    ) {
      let options = searchRegionLiquidMData.liquidMRegions.map(val => ({
        value: `${val.id}: ${val.attributes.name}`,
        label: `${val.id}: ${val.attributes.name}`,
      }));

      setRegionOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRegionLiquidMData]);

  const handleOk = () => {
    updateFromExistingAudience(audienceTargetingToAdd);
    changeValue("isModalVisibleTargeting", false);
  };

  const handleCancel = () => {
    changeValue("isModalVisibleTargeting", false);
  };

  const toggleForm = val => {
    setIsFormOpen(val);
  };

  const onCloseForm = () => {
    setSelectedLocation(null);
    toggleForm(false);
  };

  const onSubmitForm = async location => {
    setLoading(true);
    try {
      toggleForm(false);

      let radius = location.radius;
      if (location.radiusUnit === "kilometers") {
        radius = kmToMiles(radius);
      } else if (location.radiusUnit === "meters") {
        radius = metersToMiles(radius);
      }
      radius = radius / 1000;

      if (selectedLocation) {
        await handleUpdateTargtingAddress({
          ...selectedLocation,
          ...location,
          radius: radius,
          geoframe: {
            ...selectedLocation.geoframe,
            circles: [
              {
                ...selectedLocation.geoframe.circles[0],
                radius: radius,
              },
            ],
          },
        });
      } else {
        toggleForm(false);
        const {
          name,
          lat,
          lng,
          city,
          street,
          state,
          formattedAddress,
          zip,
          country,
          province,
        } = location;

        const target = {
          name,
          street,
          city,
          state,
          lat,
          lng,
          formattedAddress,
          zip,
          country,
          province,
          geoframe: {
            circles: [
              {
                coordinate: {
                  lat,
                  lng,
                },
                radius: radius,
              },
            ],
          },
        };
        await handleAddTargetFromAddresses([target]);
      }
    } catch (error) {
      console.error("Error found:", error);
    } finally {
      console.log("Refetching Geo Target");
      await refetchGeoTarget();
    }
    setLoading(false);
  };

  const onEditLocation = location => {
    setSelectedLocation(location);
    toggleForm(true);
  };

  const addRegionsToSelection = regionGroupId => {
    const selectedRegions = regionGroups.getRegionGroupWithPresetList.find(
      regionGroup => regionGroup.id === regionGroupId
    );
    changeValue("region", [...region, ...selectedRegions.regions]);
  };

  const getGeoTargetByType = (geoData, locationType) => {
    return (geoData || [])
      .map(data => ({
        ...data,
        circles:
          data && data.circles
            ? data.circles.filter(
                circle =>
                  circle.location &&
                  circle.location.audienceType === locationType
              )
            : {},
      }))
      .filter(data => data.circles.length);
  };

  useEffect(() => {
    if (geoTargets) {
      const geoData =
        isCloningClicked === true
          ? cloneGeoTargetData.concat(geoTargets)
          : geoTargets;

      // TODO: Filter should happen on the api call not the front-end/component
      const geoAddresses = getGeoTargetByType(geoData, "ADDRESS");
      const geoAreas = getGeoTargetByType(geoData, "ATTRIBUTION");

      setGeoTargetingAreas(
        geoAreas.filter(area => area.status !== "PENDING_DELETE")
      );
      setGeoTargetingAddresses(
        geoAddresses
          .filter(address => address.status !== "PENDING_DELETE")
          .map(address => ({
            ...address.circles[0].location,
            geoframe: {
              circles: address.circles,
            },
            radius: address.circles[0].radius,
            id: address.id,
            locationId: address.circles[0].location.id,
            status: address.status,
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoTargets]);

  const steps = (() => {
    const demographic = [
      {
        title: "Targeting Regions",
        descriptionTitle: ["Regions Added"],
        description: [!isEmpty(region) ? region.length.toString() : "0"],
        complete: !isEmpty(region),
      },
      {
        title: "Targeting Audiences",
        descriptionTitle: ["Audiences Added"],
        description: [!isEmpty(geoData) ? geoData.length.toString() : "0"],
        complete: !isEmpty(geoData),
      },
    ];

    const geotargets = [];

    switch (type) {
      case 1:
        return [...demographic, ...geotargets];
      case 2:
        return [...geotargets];
      default:
        return [];
    }
  })();

  const renderItem = (title, count, id, orgName, isPresetList = false) => ({
    key: id,
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <Tooltip title={"Number of Regions"}>
          <span>
            <EnvironmentOutlined /> {count}
          </span>
        </Tooltip>
      </div>
    ),
  });

  const addGeoTargetToSelectionList = async targetingLocations => {
    try {
      setLoading(true);
      await handleAddTargetFromAddresses(targetingLocations);
      await refetchGeoTarget();
      setLoading(false);
    } catch (error) {
      // Handle the error here
      message.error("Error occurred while adding geo target:", error);
      // Optionally, you can set loading to false here if needed
      setLoading(false);
    }
  };

  const groupedData = regionGroupsExtracted
    .reduce((acc, item) => {
      if (Array.isArray(item.orgs) && item.orgs.length) {
        const orgName = item.orgs[0].name; // Extract org name from orgs array
        const newItem = renderItem(
          item.name,
          item.regions.length,
          item.id,
          orgName,
          item.isPresetList
        ); // Create a new item with id and name properties

        // Check if isPresetList is true, then add the item to a "Preset List" group
        if (item.isPresetList) {
          const presetGroup = acc.find(group => group.label === "Preset List");
          if (presetGroup) {
            presetGroup.options.push(newItem);
          } else {
            acc.push({
              label: "Preset List",
              options: [newItem],
            });
          }
        } else {
          // Only add to regular groups if it's not a preset list item
          const existingGroup = acc.find(group => group.label === orgName);
          if (existingGroup) {
            existingGroup.options.push(newItem); // Push the new item to the options array of the existing group
          } else {
            acc.push({
              label: orgName,
              options: [newItem], // Create a new options array with the new item
            });
          }
        }

        return acc;
      }
      return acc;
    }, [])
    .sort((a, b) => {
      // Sort by putting 'Preset List' at the top
      if (a.label === "Preset List") return -1;
      if (b.label === "Preset List") return 1;
      return a.label.localeCompare(b.label);
    });

  const debouncedSearchRegionLiquidM = debounce(val => {
    searchRegionLiquidM({
      variables: {
        filter: {
          name: val,
        },
      },
    });
  }, 500);

  const processCircles = geoTarget => {
    return geoTarget.circles.map(circle => {
      let state = "";
      let getCode = "";

      if (circle.location && circle.location.state) {
        state = capitalize(circle.location.state);

        const matchedState = stateList.find(val => state === val.State);
        getCode = matchedState ? matchedState.Code : "";
      }

      return {
        Name: circle && circle.location ? circle.location.name : "",
        Type: "CIRCLE",
        Address:
          circle && circle.location ? circle.location.formattedAddress : "",
        Street: circle && circle.location ? circle.location.street : "",
        City: circle && circle.location ? circle.location.city : "",
        County: circle && circle.location ? circle.location.county : "",
        State: getCode,
        Country: circle && circle.location ? circle.location.country : "",
        Zip: circle && circle.location ? circle.location.zip : "",
        Latitude: circle.coordinate.lat,
        Longitude: circle.coordinate.lng,
        Radius: circle.radius * 1000,
      };
    });
  };

  const processPolygons = geoTarget => {
    const output = [];

    geoTarget.polygons.forEach((polygon, i) => {
      if (!isEmpty(polygon.coordinates)) {
        output.push({
          GeoTarget: i,
          Type: "POLYGON",
          Latitude: polygon.coordinates[0].lat,
          Longitude: polygon.coordinates[0].lng,
        });

        polygon.coordinates.slice(1).forEach(coordinate => {
          output.push({
            Latitude: coordinate.lat,
            Longitude: coordinate.lng,
          });
        });
      }
    });

    return output;
  };

  const processedData = flatten(
    geoTargets.flatMap(geoTarget => {
      return [...processCircles(geoTarget), ...processPolygons(geoTarget)];
    })
  );

  const regionReport = selectedRegions.map(region => {
    return { Region: region };
  });

  return (
    <div>
      {isFormOpen && (
        <TargetingLocationForm
          selectedLocation={selectedLocation}
          isFormOpen={isFormOpen}
          targetingLocations={targetingLocations}
          google={google}
          closeForm={onCloseForm}
          submitForm={onSubmitForm}
        />
      )}
      <RegionForm
        isFormOpen={isFormRegionOpen}
        searchRegionLiquidMLoading={searchRegionLiquidMLoading}
        regionOptions={regionOptions}
        debouncedSearchRegionLiquidM={debouncedSearchRegionLiquidM}
        selectedRegions={selectedRegions}
        setSelectedRegions={setSelectedRegions}
        changeValue={changeValue}
        setIsFormOpen={setIsFormRegionOpen}
      />

      {previewTargetingLocations.length > 0 && (
        <TargetingLocationPreviewImport
          isOpen={previewTargetingLocations.length}
          targetingLocationsPreviewData={previewTargetingLocations}
          closeTargetingPreviewModal={() => setPreviewTargetingLocations([])}
          bulkImportTargetingLocations={bulkImportTargetingLocations}
          selectTargetingLocationPreview={selectTargetingLocationPreview}
        />
      )}
      {(type === 1 || type === 7 || type === 8) && (
        <div>
          <Row gutter={12}>
            <Col span={tablet ? 24 : 18}>
              <Card
                title={
                  <Col>
                    <FileTextOutlined />
                    <span> Targeting Criteria (Note: Optional)</span>
                  </Col>
                }
                //headStyle={!isEmpty(targetingCriteria) ? styles.cardTitleSuccess : styles.cardTitle }
                bodyStyle={styles.mapCard}
                extra={
                  <span>
                    <Tooltip
                      title={
                        "Targeting Criteria is just a note for the campaign, doesn't have any bearing on the actual targeting used in the campaign setup"
                      }
                      arrowPointAtCenter={true}
                      placement="leftBottom"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                style={styles.card}
              >
                <Col span={18}>
                  <Form.Item
                    style={{
                      marginBottom: "0px",
                      marginTop: "20px",
                      marginLeft: "15px",
                    }}
                  >
                    <TextArea
                      value={targetingCriteria}
                      onChange={e => {
                        onChange("targetingCriteria", e.target.value);
                      }}
                      placeholder="Enter Targeting Criteria"
                      rows={4}
                      allowClear
                    />
                  </Form.Item>
                  <br />
                </Col>
              </Card>
              <Card
                title={
                  <Col>
                    <PushpinOutlined /> <span>Targeting Regions</span>
                  </Col>
                }
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
                bodyStyle={{
                  padding: "8px",
                }}
                extra={
                  <span>
                    <Tooltip
                      title={"Select a region to target"}
                      arrowPointAtCenter={true}
                      placement="leftBottom"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                {isTrafficker && (
                  <Row justify="center" align="end">
                    <CustomButton
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => setIsFormRegionOpen(true)}
                    >
                      Add Regions
                    </CustomButton>
                    <CustomButton
                      type="primary"
                      icon={<DownloadOutlined />}
                      disabled={
                        !isTrafficker ||
                        (regionReport && regionReport.length === 0)
                      }
                      style={{
                        color:
                          !isTrafficker ||
                          (regionReport && regionReport.length === 0)
                            ? "#B8B8B8"
                            : "white",
                      }}
                    >
                      <CSVLink
                        style={{ color: "inherit" }}
                        filename={`${campaignName}-regions.csv`}
                        data={regionReport ? regionReport : []}
                      >
                        {" "}
                        Download CSV
                      </CSVLink>
                    </CustomButton>
                  </Row>
                )}

                <Col span={18}>
                  {
                    <Space
                      direction="vertical"
                      size="small"
                      style={{
                        display: "flex",
                        marginLeft: "1.2em",
                        marginTop: "0.9em",
                      }}
                    >
                      <Text strong>Regions</Text>

                      <Select
                        mode="multiple"
                        value={selectedRegions}
                        placeholder="Regions"
                        style={{
                          width: "100%",
                        }}
                        onChange={val => {
                          changeValue("region", val);
                        }}
                        dropdownStyle={{ display: "none" }} //Don't Show dropdown since we are not allowed to select regions from here
                      ></Select>
                      {selectedRegions && selectedRegions.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="link"
                            onClick={() => {
                              changeValue("region", []);
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      )}
                    </Space>
                  }

                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      display: "flex",
                      marginBottom: "1em",
                      marginLeft: "1.2em",
                      marginTop: "0.9em",
                    }}
                  >
                    <Text strong>Select Regions by Country</Text>
                    <Row>
                      <Select
                        placeholder="Select Region by Country"
                        style={{
                          width: "100%",
                        }}
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        value={selectedRegionCountry}
                        options={[
                          {
                            label: "United States",
                            value: "usa",
                          },
                          {
                            label: "Canada",
                            value: "canada",
                          },
                          {
                            label: "United Kingdom",
                            value: "united_kingdom",
                          },
                          {
                            label: "Spain",
                            value: "spain",
                          },
                        ]}
                        onSelect={(_, option) => {
                          switch (option.value) {
                            case "usa":
                              const unitedStateRegions = [
                                ...new Set([
                                  ...region,
                                  ...RegionConfig.USA.regions,
                                ]),
                              ];

                              changeValue("region", unitedStateRegions);
                              break;

                            case "canada":
                              const canadaRegions = [
                                ...new Set([
                                  ...region,
                                  ...RegionConfig.CANADA.regions,
                                ]),
                              ];

                              changeValue("region", canadaRegions);
                              break;

                            case "united_kingdom":
                              const ukRegions = [
                                ...new Set([
                                  ...region,
                                  ...RegionConfig.UK.regions,
                                ]),
                              ];

                              changeValue("region", ukRegions);
                              break;

                            case "spain":
                              const spainRegions = [
                                ...new Set([
                                  ...region,
                                  ...RegionConfig.SPAIN.regions,
                                ]),
                              ];

                              changeValue("region", spainRegions);
                              break;

                            default:
                              break;
                          }
                          setSelectedRegionCountry(null);
                        }}
                      ></Select>
                    </Row>
                  </Space>

                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      display: "flex",
                      marginBottom: "1em",
                      marginLeft: "1.2em",
                      marginTop: "0.9em",
                    }}
                  >
                    <Text strong>Select Regions by Region Groups</Text>
                    <Row>
                      <Select
                        showSearch
                        placeholder="Search and Select Region Group List"
                        style={{
                          width: "100%",
                        }}
                        defaultActiveFirstOption={false}
                        value={selectedRegionGroup}
                        showArrow={true}
                        notFoundContent={"No Records found."}
                        filterOption={(inputValue, option) => {
                          return (
                            (option &&
                              option.label &&
                              typeof option.label === "string" &&
                              option.label
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0) ||
                            (option &&
                              option.value &&
                              typeof option.value === "string" &&
                              option.value
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0)
                          );
                        }}
                        options={groupedData}
                        onSelect={(_, option) => {
                          addRegionsToSelection(option.key);
                          setSelectedRegionGroup(null);
                        }}
                      ></Select>
                    </Row>
                  </Space>
                </Col>
              </Card>
              <Card
                title={
                  <Col>
                    <PushpinOutlined /> <span>Targeting Addresses</span>
                  </Col>
                }
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
                bodyStyle={{
                  padding: "8px",
                }}
                extra={
                  <>
                    <span>
                      <Tooltip
                        title={
                          "Select from a pre existing audience. Adding these audiences will add new instances of the locations to this campaign order and will assign the default dates of the top level campaign order."
                        }
                        arrowPointAtCenter={true}
                        placement="leftBottom"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  </>
                }
              >
                {isTrafficker && (
                  <Row justify="center" align="end">
                    <CustomButton
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => toggleForm(true)}
                    >
                      Add Targeting Location
                    </CustomButton>
                    <CustomButton
                      type="primary"
                      icon={<DownloadOutlined />}
                      disabled={!geoTargetingAddresses.length || !isTrafficker}
                    >
                      <CSVLink
                        style={{
                          color:
                            !geoTargetingAddresses.length || !isTrafficker
                              ? "#B8B8B8"
                              : "#fff",
                        }}
                        filename={`${campaignName}-Geo.csv`}
                        data={processedData}
                      >
                        {" "}
                        Download CSV
                      </CSVLink>
                    </CustomButton>
                  </Row>
                )}
                <Row
                  align="right"
                  className="ant-row-justify-center ant-row-justify-end"
                ></Row>
                <Row style={{ marginBottom: "16px" }}>
                  <Col span={18}>
                    <Space
                      direction="vertical"
                      size="small"
                      style={{ marginLeft: "1.2em", width: "100%" }}
                    >
                      <TargetingAddressSearch
                        addresses={targetingLocations}
                        selectedTargetingLocations={selectedTargetingLocations}
                        handleAddTargetFromAddresses={async targetingLocations => {
                          addGeoTargetToSelectionList(targetingLocations);
                        }}
                        targetingLocationList={geoTargetingAddresses}
                      />
                      <TargetingAudienceSearch
                        addresses={targetingLocations}
                        audiences={targetingAudiences}
                        selectedTargetingLocations={selectedTargetingLocations}
                        handleAddTargetFromAddresses={async targetingLocations => {
                          addGeoTargetToSelectionList(targetingLocations);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
                <Row justify="center" align="middle">
                  <Col lg={24} md={24}>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin tip="Loading..." />
                      </div>
                    ) : (
                      <TargetingLocationListView
                        deleteLocation={handleDeleteGeoTarget}
                        refetchGeoTarget={refetchGeoTarget}
                        setGeoTargetingAddresses={setGeoTargetingAddresses}
                        editLocation={onEditLocation}
                        targetingLocationList={geoTargetingAddresses}
                        audienceType="ADDRESS"
                        selectedTargetingLocations={selectedTargetingLocations}
                        hideRowSelection={true}
                        isAdmin={isAdmin}
                        addLocationNotes={addLocationNotes}
                        status={status}
                        hiddenColumns={["updatedBy", "createdBy"]}
                      />
                    )}
                  </Col>
                </Row>
                {/* </TabPane> */}
                {/* <TabPane tab={<span>Audiences</span>} key="audience">
                    <TargetingLocationAudiences
                      targetingAudiences={targetingAudiences}
                      deleteAudienceLocation={deleteAudienceLocation}
                      style={{
                        card: {
                          border: 0,
                        },
                        cardBody: {
                          border: 0,
                        },
                        cardExtra: {
                          marginTop: -28,
                        },
                      }}
                    />
                  </TabPane> */}
                {/* </Tabs> */}
              </Card>

              <Card
                bodyStyle={styles.cardBody}
                style={styles.card}
                title={
                  <Col>
                    <PushpinOutlined /> <span>Targeting Areas</span>
                    <small>
                      <i> - (Maximum of 5 polygons/circles)</i>
                    </small>
                  </Col>
                }
              >
                <Row style={styles.rows}>
                  <Col xs={24}>
                    <div
                      style={{
                        margin: "12px",
                        textAlign: "end",
                      }}
                    >
                      <LocationsDropdown
                        currentLocationId={currentLocationId}
                        locations={geoTargetingAreas}
                        setCurrentLocationId={setCurrentLocationId}
                        title={"View Location(s)"}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <LauncherMap
                      zoom={5}
                      styles={styles}
                      addGeoTarget={(item, isAttribution) => {
                        props.addTargetFromMapClicked(item, isAttribution);
                      }}
                      geoTargets={geoTargetingAreas}
                      currentLocationId={currentLocationId}
                      disabledSearch={true}
                      disablePolygonCreationTool={true}
                      disabledDrawingManagerTool={
                        geoTargetingAreas && geoTargetingAreas.length >= 5
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Row>
                <Row styles={styles.rows}>
                  <Col xs={24}>
                    <GeoTargetManager
                      {...props}
                      updateCampaignOrderWithLocations={
                        updateCampaignOrderWithGeoLocations
                      }
                      locationTargets={geoTargetingAreas}
                      targetType="Targeting"
                      enableHeader={true}
                      refetchGeoTarget={refetchGeoTarget}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            {!tablet && (
              <Col span={6}>
                <Affix offsetTop={20}>
                  <div>
                    <StepsCard stepsToComplete={steps} />
                    <NavigationButtons
                      {...props}
                      disabled={
                        isEmpty(region) &&
                        isEmpty(geoData) &&
                        isEmpty(targetingCriteria)
                      }
                      onChange={onChange}
                      onSubmitLoad={onSubmitLoad}
                    />
                  </div>
                </Affix>
              </Col>
            )}
          </Row>
        </div>
      )}

      <Row>
        {tablet && (
          <Col style={styles.col} offset={6} span={12}>
            <NavigationButtons
              {...props}
              onChange={onChange}
              onSubmitLoad={onSubmitLoad}
            />
          </Col>
        )}
      </Row>

      {isModalVisibleTargeting && (
        <Modal
          title="Add targeting locations"
          visible={isModalVisibleTargeting}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p>
            Are you sure you want to add {audienceTargetingToAdd.length}{" "}
            targeting locations to this campaign order?{" "}
          </p>
        </Modal>
      )}
    </div>
  );
};

const CampaignLauncherViewTargetingWithController = props => {
  return (
    <GeotargetManagerController
      {...props}
      targetType={"Geo"}
      updateCampaignOrderWithLocations={
        props.updateCampaignOrderWithGeoLocations
      }
      targets={
        props.isCloningClicked === true
          ? props.cloneGeoTargetData.concat(props.geoTargetData)
          : props.geoTargetData
      }
    >
      <CampaignLauncherViewTargeting />
    </GeotargetManagerController>
  );
};

export default CampaignLauncherViewTargetingWithController;
