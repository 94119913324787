import React from "react";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";

//queries
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_ALL_SEGMENTS from "../../../GraphQl/Queries/GET_ALL_SEGMENTS";
import GET_ALL_SHARING_RECORDS from "../../../GraphQl/Queries/GET_ALL_SHARING_RECORDS";
import VIEW_ALL_TAXONOMIES from "../../../GraphQl/Queries/VIEW_ALL_TAXONOMIES";
import VIEW_MAPPING_RECORDS from "../../../GraphQl/Queries/VIEW_MAPPING_RECORDS";
//mutations
import CREATE_SEGMENT from "../../../GraphQl/Mutations/CREATE_SEGMENT";
import EDIT_SEGMENT from "../../../GraphQl/Mutations/EDIT_SEGMENT";
import DELETE_SEGMENT from "../../../GraphQl/Mutations/DELETE_SEGMENT";
import DELETE_SHARING_RECORD from "../../../GraphQl/Mutations/DELETE_SHARING_RECORD";
import ADD_SHARING_RECORD_WITH_SEGMENTS from "../../../GraphQl/Mutations/ADD_SHARING_RECORD_WITH_SEGMENTS";
import EDIT_SHARING_RECORD from "../../../GraphQl/Mutations/EDIT_SHARING_RECORD";
import CREATE_MAPPING_RECORD from "../../../GraphQl/Mutations/CREATE_MAPPING_RECORD";
import EDIT_MAPPING_RECORD from "../../../GraphQl/Mutations/EDIT_MAPPING_RECORD";
import DELETE_MAPPING_RECORD from "../../../GraphQl/Mutations/DELETE_MAPPING_RECORD";
//local
import Controller from "./Controller";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";

const Loader = props => {
  const { segments } = props;

  if (!segments) {
    return <OverlaySpinner />;
  }

  return <Controller>{props.children}</Controller>;
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(GET_ALL_SEGMENTS, {
    //Note this resolver fails if undefined is passed as data / number that does not match member id - to be fixed
    options: {
      fetchPolicy: "cache-and-network",
      variables: {
        member_id: 10415,
      },
    },
    props: props => {
      return {
        segments: props.data ? props.data : null,
      };
    },
  }),
  graphql(GET_ALL_SHARING_RECORDS, {
    //Note this resolver fails if undefined is passed as data / number that does not match member id - to be fixed
    options: {
      fetchPolicy: "cache-and-network",
      variables: {
        member_id: 10415,
      },
    },
    props: props => {
      return {
        sharingRecords: props.data ? props.data : null,
      };
    },
  }),

  graphql(VIEW_ALL_TAXONOMIES, {
    //Note this resolver fails if undefined is passed as data / number that does not match member id - to be fixed
    options: {
      fetchPolicy: "cache-and-network",
      variables: {
        member_id: 10415,
      },
    },
    props: props => {
      return {
        taxonomies: props.data ? props.data : null,
      };
    },
  }),
  graphql(VIEW_MAPPING_RECORDS, {
    //Note this resolver fails if undefined is passed as data / number that does not match member id - to be fixed
    options: {
      fetchPolicy: "cache-and-network",
      variables: {
        member_id: 10415,
      },
    },
    props: props => {
      return {
        mappingRecords: props.data ? props.data : null,
      };
    },
  }),

  graphql(CREATE_SEGMENT, { name: "createSegment" }),
  graphql(EDIT_SEGMENT, { name: "editSegment" }),
  graphql(DELETE_SEGMENT, { name: "deleteSegment" }),
  graphql(DELETE_SHARING_RECORD, { name: "deleteSharingRecord" }),
  graphql(ADD_SHARING_RECORD_WITH_SEGMENTS, {
    name: "addSharingRecordWithSegments",
  }),
  graphql(EDIT_SHARING_RECORD, { name: "editSharingRecord" }),
  graphql(CREATE_MAPPING_RECORD, { name: "addMappingRecord" }),
  graphql(EDIT_MAPPING_RECORD, { name: "editMappingRecord" }),
  graphql(DELETE_MAPPING_RECORD, { name: "deleteMappingRecord" })
)(Loader);
