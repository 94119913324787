import React from "react";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import CreativeDetails from "./components/CreativeDetails";
import { CardText } from "../../../../../shared/globalStyling/styledText";
import CampaignDetailsCreativeLoader from "../../../../../../core/components/campaigns/campaigndetails/creatives/CampaignDetailsCreativeLoader";

const Creatives = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  creativeData,
}) => {
  if (chartsLoading)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Card>
    );
  if (isEmpty(creativeData))
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <CardText text={""} />
        </Row>
      </Card>
    );

  return (
    <CampaignDetailsCreativeLoader
      creativeAdProviderIds={creativeData.map(row => {
        return String(row.id);
      })}
      creativeDataReport={creativeData}
    >
      <CreativeDetails
        colStyle={colStyle}
        selectedCampaign={selectedCampaign}
      />
    </CampaignDetailsCreativeLoader>
  );
};

export default Creatives;
