import gql from "graphql-tag";

const UPDATE_CREATIVE_STATUS = gql`
  mutation updateCreative($id: ID!, $status: CampaignPropertyStatus) {
    updateCreative(where: { id: $id }, data: { status: $status }) {
      id
      status
    }
  }
`;

export default UPDATE_CREATIVE_STATUS;
