import gql from "graphql-tag";

const LIST_ORGS = gql`
  query listOrgs(
    $parentid: ID!
    $skip: Int
    $first: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    orgs(
      first: $first
      skip: $skip
      where: {
        OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }]
        AND: [
          {
            campaignOrders_some: {
              OR: [
                { startDate_gte: $startDate, startDate_lte: $endDate }
                { endDate_gte: $startDate }
              ]
            }
          }
        ]
      }
      orderBy: name_ASC
    ) {
      id
      domain
      name
      description
      parentOrg {
        name
        id
      }
    }
  }
`;

export { LIST_ORGS };
