import React from "react";
import { Row, Select, Typography } from "antd";
//local
import {
  languages,
  ages,
  incomes,
} from "../../../../core/utils/constants/constants";

const { Title, Text } = Typography;
const Option = Select.Option;
const Frag = React.Fragment;
const styles = {
  deviceSelector: {
    borderRadius: "4px",
    padding: "10px",
    border: "1px solid #e8e8e8",
    margin: "15px 10px 10px 0",
  },
  title: {
    color: "#595959",
    margin: 0,
    fontWeight: 400,
    fontSize: "1.15em",
  },
  titleSecondary: {
    color: "#a7a7a7",
    margin: 0,
    fontWeight: 500,
    fontSize: ".95em",
  },
};

const BasicsDemographicsCard = ({
  onChange,
  targetAudiences,
  language,
  income,
  age,
  title,
}) => {
  return (
    <Row
      style={{
        padding: "15px",
      }}
    >
      {title ? (
        <Frag>
          <Title level={4} style={styles.title}>
            Demographic
          </Title>
          <Text style={{ color: "#bababa" }} type="secondary">
            Who are you targeting with this campaign?
          </Text>
        </Frag>
      ) : (
        <div />
      )}
      <Title
        level={4}
        style={{ ...styles.titleSecondary, margin: "25px 0 10px 0" }}
      >
        Age Ranges
      </Title>
      <Select
        value={age}
        onChange={value => {
          onChange("age", value);
        }}
        mode="multiple"
        style={{ width: "75%" }}
      >
        {ages.map(option => {
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
      <Title
        level={4}
        style={{ ...styles.titleSecondary, margin: "25px 0 10px 0" }}
      >
        Language
      </Title>
      <Select
        value={language}
        onChange={value => {
          onChange("language", value);
        }}
        mode="multiple"
        style={{ width: "75%" }}
      >
        {languages.map(option => {
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
      <Title
        level={4}
        style={{ ...styles.titleSecondary, margin: "25px 0 10px 0" }}
      >
        Income
      </Title>
      <Select
        value={income}
        onChange={value => {
          onChange("income", value);
        }}
        mode="multiple"
        style={{ width: "75%", marginBottom: "10px" }}
      >
        {incomes.map(option => {
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    </Row>
  );
};

export default BasicsDemographicsCard;
