import React from "react";
import { Card, Row, Col, Collapse, Typography, Radio, InputNumber } from "antd";
import { ThunderboltOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Panel } = Collapse;

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <ThunderboltOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{titleText}</h4>
      </Col>
    </Row>
  );
};

const PanelHeader = ({ styles }) => {
  return (
    <Radio style={{ width: "100%" }} value="YES">
      <Text>Yes</Text>
      <br />
      <Text style={styles.secondaryText} type="secondary">
        When your audience is likely to convert multiple times (continuous
        demand).
      </Text>
    </Radio>
  );
};

const styles = {
  options: {
    border: "1px solid",
    borderRadius: "4px",
    marginBottom: "15px",
    width: "100%",
  },
  collapseOption: {
    border: "1px solid #ebebeb",
    borderRadius: "4px",
    padding: 0,
  },
  panelRow: {
    marginLeft: "15px",
  },
  titleRow: {
    marginBottom: "10px",
  },
  secondaryText: {
    fontSize: "10px",
    marginLeft: "23px",
  },
};

const RetargetingCard = ({
  retargetDayCap,
  retargetTotalCap,
  onChange,
  border,
}) => {
  const onRadioClick = e => {
    const target = e.target.value;
    if (target === "YES") {
      onChange("retargetDayCap", 5);
      onChange("retargetTotalCap", 15);
    }
    if (target === "NO") {
      onChange("retargetDayCap", null);
      onChange("retargetTotalCap", null);
    }
  };
  const retargetingOff = retargetDayCap === null || retargetDayCap === null;
  return (
    <Card bordered={border} title={<Title titleText="Retargeting" />}>
      <Row style={styles.titleRow}>
        <Col span={24}>
          <Text>
            Would you like to enable retargeting within your campaign?
          </Text>
          <br />
          <Text type="secondary">Choose an option below.</Text>
        </Col>
      </Row>
      <Radio.Group
        style={{ width: "100%" }}
        value={retargetingOff ? "NO" : "YES"}
        onChange={onRadioClick}
      >
        <Row
          style={{
            ...styles.options,
            borderColor: retargetingOff ? "#0078fe" : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="NO">
              <Text>No</Text>
              <br />
              <Text type="secondary" style={styles.secondaryText}>
                Use if your audience is only likely to convert once (sign-ups,
                single purchases, etc.).
              </Text>
            </Radio>
          </Col>
        </Row>
        <Row
          style={{
            ...styles.collapseOption,
            borderColor: retargetingOff ? "#ebebeb" : "#0078fe",
          }}
        >
          <Col span={24}>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              accordion
              // onChange={() => {
              //   if (retargetCap === "NO" || retargetCap === null) {
              //     const e = {
              //       target: {
              //         value: "YES",
              //       },
              //     };
              //     onRadioClick(e);
              //   }
              //   if (retargetCap === "YES") {
              //     const e = {
              //       target: {
              //         value: "NO",
              //       },
              //     };
              //     onRadioClick(e);
              //   }
              // }}
              activeKey={retargetingOff ? null : "1"}
            >
              <Panel
                style={{ border: 0 }}
                header={<PanelHeader styles={styles} />}
                key="1"
              >
                <Row type="flex" justify="space-around" align="middle">
                  <Col span={12} style={{ minWidth: "200px" }}>
                    <Row style={styles.panelRow}>
                      <Text>Daily Frequency Cap</Text>
                    </Row>
                    <Row style={styles.panelRow}>
                      <InputNumber
                        value={retargetDayCap}
                        min={0}
                        onChange={val => {
                          if (val) onChange("retargetDayCap", val);
                        }}
                        style={{ width: "70px" }}
                      />
                    </Row>
                    <Row style={styles.panelRow}>
                      <Text style={{ fontSize: "10px" }} type="secondary">
                        How many times a user will see your campaign within a 24
                        hour period.
                      </Text>
                    </Row>
                  </Col>
                  <Col span={12} style={{ minWidth: "200px" }}>
                    <Row style={styles.panelRow}>
                      <Text>Lifetime Frequency Cap</Text>
                    </Row>
                    <Row style={styles.panelRow}>
                      <InputNumber
                        value={retargetTotalCap}
                        min={0}
                        onChange={val => {
                          if (val) onChange("retargetTotalCap", val);
                        }}
                        style={{ width: "70px" }}
                      />
                    </Row>
                    <Row style={styles.panelRow}>
                      <Text style={{ fontSize: "10px" }} type="secondary">
                        How many times a user will see your campaign over the
                        entire run time of the campaign.
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Radio.Group>
    </Card>
  );
};

export default RetargetingCard;
