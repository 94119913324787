import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import LIST_CAMPAIGN_ORDERS_ID from "../../../GraphQl/Queries/LIST_CAMPAIGN_ORDERS_ID";
import LIST_ORGS_ADVERTISERS from "../../../GraphQl/Queries/LIST_ORGS_ADVERTISERS";
import GET_ORG_REPORT from "../../../GraphQl/Queries/GET_ORG_REPORT";
import GET_ORG_SUMMARY from "../../../GraphQl/Queries/GET_ORG_SUMMARY";
import DisplayChartsController from "./DisplayChartsController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import GET_ORG_SUMMARIES from "../../../GraphQl/Queries/GET_ORG_SUMMARIES";
import GET_SUMMARY_DEVICE_CLICKS from "../../../GraphQl/Queries/GET_SUMMARY_DEVICE_CLICKS";
import TRIGGER_AIRFLOW_DAG from "../../../GraphQl/Queries/TRIGGER_AIRFLOW_DAG";
import DisplaySummaryController from "./DisplaySummaryController";
import moment from "moment";

const DisplayChartsDataLoader = ({ children, showAnalytics, ...props }) => {
  const loggedInRole = props.currentUser.role;
  const orgId = loggedInRole.org.id;

  const searchParams = new URLSearchParams(props.location.search);
  const advertiserParams = searchParams.get("advertiserId");

  const [advertiserIds, setAdvertiserIds] = useState([orgId]);

  const [selectedCOIds, setSelectedCOIds] = useState([]);

  useEffect(() => {
    if (!advertiserParams) {
      return setAdvertiserIds([orgId]);
    }
    const advertisers = advertiserParams.split("+");
    setAdvertiserIds(advertisers);
  }, [advertiserParams, orgId]);

  let where = {
    AND: [],
  };

  const startDate = moment()
    .subtract(6, "months")
    .seconds(0)
    .milliseconds(0)
    .toISOString();

  const endDate = moment()
    .add(1, "days")
    .seconds(0)
    .milliseconds(0)
    .toISOString();

  where["AND"].push({
    AND: [
      {
        status_not_in: ["DRAFT", "PENDING"],
        type_not: "CPC",
        orgs_some: {
          OR: [
            {
              id_in: advertiserIds,
            },
            {
              parentOrg_some: {
                id_in: advertiserIds,
              },
            },
          ],
        },
        campaigns_some: {
          OR: [
            {
              startDate_gte: startDate,
            },
            {
              startDate_lte: endDate,
            },
            {
              endDate_gte: startDate,
            },
            {
              endDate_lte: endDate,
            },
          ],
        },
      },
    ],
  });

  const { data: org } = useQuery(GET_ORG_SUMMARY, {
    variables: { loggedInOrg: advertiserIds[0] },
  });

  // Use for getting multiple org summaries
  const { loading: loadingOrgSummaries, data: orgSummaries } = useQuery(
    GET_ORG_SUMMARIES,
    {
      variables: {
        orgIds: advertiserIds,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  // Use for getting multiple device clicks in any org
  const { loading: loadingDeviceClicks, data: deviceClicks } = useQuery(
    GET_SUMMARY_DEVICE_CLICKS,
    {
      variables: {
        orgId,
        advertiserIds,
        campaignOrderIds: selectedCOIds.length ? selectedCOIds : undefined,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const selectedReportMetaDataId =
    org && org.orgSummary && org.orgSummary.reports.length
      ? org.orgSummary.reports[0]
      : props.reportMetaData.id;

  const { loading: loadingOrgReport, data: getSignedURL, refetch } = useQuery(
    GET_ORG_REPORT,
    {
      variables: {
        reportId: !advertiserParams
          ? props.reportMetaData.id
          : selectedReportMetaDataId,
      },
    }
  );

  // Load Sub-Orgs of the current user's Org for filtering the Campaign Main Table by Org
  const { loading: loadingOrgsAdmin, data: orgsData } = useQuery(
    LIST_ORGS_ADVERTISERS,
    {
      variables: { orgIds: advertiserIds, first: 1000 },
    }
  );

  const {
    loading: loadingCampaignOrdersData,
    data: campaignOrdersData,
  } = useQuery(LIST_CAMPAIGN_ORDERS_ID, {
    variables: { where },
  });

  const [reprocessOrg, { loading: reprocessOrgLoading }] = useLazyQuery(
    TRIGGER_AIRFLOW_DAG,
    {
      fetchPolicy: "no-cache",
    }
  );

  if (
    !orgsData ||
    !deviceClicks ||
    !getSignedURL ||
    !orgSummaries ||
    !campaignOrdersData ||
    loadingOrgSummaries ||
    loadingDeviceClicks ||
    loadingOrgReport ||
    loadingOrgsAdmin ||
    loadingCampaignOrdersData
  )
    return <OverlaySpinner />;

  if (showAnalytics) {
    return (
      <DisplayChartsController
        {...props}
        children={children}
        refetch={refetch}
        loading={loadingOrgSummaries}
        orgs={orgsData.orgs || []}
        orgSummaries={orgSummaries.orgs || []}
        deviceClicks={deviceClicks.getSummaryDeviceClicks}
        campaignOrdersData={campaignOrdersData}
        signedUrl={getSignedURL && getSignedURL.getOrgReportSignedURL}
        expiresAt={getSignedURL && getSignedURL.expiresAt}
        advertiserIds={advertiserIds}
        orgId={orgId}
        selectedCOIds={selectedCOIds}
        setSelectedCOIds={setSelectedCOIds}
        showAnalytics={showAnalytics}
      />
    );
  } else {
    return (
      <DisplaySummaryController
        {...props}
        children={children}
        refetch={refetch}
        loading={loadingOrgSummaries}
        orgs={orgsData.orgs || []}
        orgSummaries={orgSummaries.orgs || []}
        deviceClicks={deviceClicks.getSummaryDeviceClicks}
        campaignOrdersData={campaignOrdersData}
        advertiserIds={advertiserIds}
        orgId={orgId}
        selectedCOIds={selectedCOIds}
        setSelectedCOIds={setSelectedCOIds}
        loadingDeviceClicks={loadingDeviceClicks}
        showAnalytics={showAnalytics}
        signedUrl={getSignedURL && getSignedURL.getOrgReportSignedURL}
        expiresAt={getSignedURL && getSignedURL.expiresAt}
        reprocessOrg={reprocessOrg}
        reprocessOrgLoading={reprocessOrgLoading}
      />
    );
  }
};

export default DisplayChartsDataLoader;
