import gql from "graphql-tag";

const DELETE_MANY_LOCATIONS = gql`
  mutation deleteManyLocations($ids: [ID!]) {
    deleteManyLocations(where: { id_in: $ids }) {
      count
    }
  }
`;

export default DELETE_MANY_LOCATIONS;
