import React, { useEffect } from "react";
import { Form, Typography, Row, Col, Space, Input } from "antd";

export const LaunchCampaignConfigBudget = ({
  budgetConfig,
  setBudgetConfig,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(budgetConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetConfig]);

  const onValuesChange = (changedValues, allValues) => {
    // Check if the changed values include overall_units or daily_units
    if (
      changedValues.hasOwnProperty("overall_units") ||
      changedValues.hasOwnProperty("daily_units")
    ) {
      form.setFieldsValue({ threshold_percent: 0 });
      const { overall_units, daily_units } = allValues;
      setBudgetConfig({
        ...budgetConfig,
        overall_units: Number(overall_units),
        daily_units: Number(daily_units),
        threshold_percent: 0,
        threshold_units: 0,
      });
    } else {
      setBudgetConfig({ ...budgetConfig, ...allValues });
    }
  };

  const handleThresholdChange = value => {
    if (!value) {
      setBudgetConfig({
        ...budgetConfig,
        overall_units: budgetConfig.original_overall_units,
        daily_units: budgetConfig.original_daily_units,
        threshold_units: 0,
        threshold_percent: 0,
      });
      return;
    }
    const { original_daily_units, original_overall_units } = budgetConfig;
    const newOverallUnits =
      original_overall_units + original_overall_units * (value / 100);
    const newDailyUnits =
      original_daily_units + original_daily_units * (value / 100);
    const newThresholdUnits = newOverallUnits - original_overall_units;

    setBudgetConfig({
      ...budgetConfig,
      overall_units: Math.ceil(newOverallUnits),
      daily_units: Math.ceil(newDailyUnits),
      threshold_percent: value,
      threshold_units: Math.ceil(newThresholdUnits),
    });
  };

  return (
    <div>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Budget
        </Typography.Title>

        <Form form={form} layout="vertical" onValuesChange={onValuesChange}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="overall_units"
                label="Overall Unit Impressions"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter Overall Unit Impressions"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="daily_units"
                label="Daily Impressions"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" placeholder="Enter Daily Impressions" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="threshold_percent"
                label="Threshold Impressions %"
              >
                <Input
                  type="number"
                  placeholder="Enter Threshold"
                  onChange={e => handleThresholdChange(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="threshold_units" label="Threshold Units">
                <Input type="number" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Space>
    </div>
  );
};
