import gql from "graphql-tag";

const GET_SIGNUP_IMPRESSIONS = gql`
  query getSignupImpressions(
    $campaignOrderId: String!
    $reportName: String!
    $signupId: String!
  ) {
    getSignupImpressions(
      campaignOrderId: $campaignOrderId
      reportName: $reportName
      signupId: $signupId
    )
  }
`;

export default GET_SIGNUP_IMPRESSIONS;
