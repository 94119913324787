import { isEmpty } from "lodash";
import {
  formatCurrency2SigFig,
  formatNumber,
} from "../../../../core/utils/campaigns";

export const summaryScale = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["impressions"] : 1000,
            alias: "Attributed Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : leftY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "totalImpressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["totalImpressions"] : 0,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["impressions"] : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : rightY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "totalImpressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["totalImpressions"] : 0,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};
