import React from "react";
import { Table, Checkbox } from "antd";
import { cloneDeep } from "lodash";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const ConfirmLocationsTable = ({
  data,
  onChange,
  selectedModalLocations,
  selectedModalLocationsChanged,
}) => {
  const tableColumns = [
    {
      title: "MATCH?",
      dataIndex: "isMatch",
      key: "isMatch",
      width: "15%",
      render: (text, record) => {
        const { isMatch, isPartial } = record;
        const styles = {
          match: {
            color: "#57ae62",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
          partial: {
            color: "#f6ae3b",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
          noMatch: {
            color: "#c43b40",
            backgroundColor: "white",
            border: "1px solid #eef0f2",
          },
        };
        return isMatch && record.foundAddress ? (
          <CheckCircleOutlined style={styles.match} />
        ) : isPartial ? (
          <QuestionCircleOutlined style={styles.partial} />
        ) : (
          <CloseCircleOutlined style={styles.noMatch} />
        );
      },
    },
    {
      title: "WARNINGS",
      dataIndex: "errorMessage",
      key: "errorMessage",
      width: "15%",
      render: text => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {text}
          </div>
        );
      },
    },
    {
      title: "SOURCE ADDRESS",
      dataIndex: "sourceAddress",
      key: "sourceAddress",
      width: "30%",
      render: text => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {text}
          </div>
        );
      },
    },
    {
      title: "FOUND ADDRESS",
      dataIndex: "foundAddress",
      key: "foundAddress",
      width: "20%",
      render: text => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {text ? text : "Google could not find a corresponding address..."}
          </div>
        );
      },
    },
    {
      title: "ADD ADDRESS?",
      dataIndex: "addAddress",
      key: "addAddress",
      width: "20%",
      render: (text, record) => {
        let records = cloneDeep(selectedModalLocations);
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              onChange={e => {
                if (e.target.checked) {
                  records.push(record);
                  // onChange("selectedLocations", records);
                  selectedModalLocationsChanged(records);
                }
                if (!e.target.checked) {
                  records.splice(
                    records.findIndex(rec => rec.key === record.key),
                    1
                  );
                  // onChange("selectedLocations", records);
                  selectedModalLocationsChanged(records);
                }
              }}
              disabled={record.notFound || !record.foundAddress ? true : false}
              defaultChecked={
                record.isMatch && record.foundAddress ? true : false
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      pagination={false}
      columns={tableColumns}
      dataSource={data}
      // Setting scroll causes an issue with the way the table renders. Will use when figured out
      scroll={{ y: 300 }}
    />
  );
};

export default ConfirmLocationsTable;
