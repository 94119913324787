import { isEmpty } from "lodash";

export const UPDATEDAT_DESC = "updatedAt_DESC";

export const deviceCount = (geoTargets, eventsTargets) => {
  let count = 0;
  if (geoTargets && !isEmpty(geoTargets)) {
    geoTargets.forEach(target => {
      const { uniqueDevices } = target;
      if (uniqueDevices) {
        count = count + uniqueDevices;
      }
    });
  }
  if (eventsTargets && !isEmpty(eventsTargets)) {
    eventsTargets.forEach(target => {
      const { uniqueDevices } = target;
      if (uniqueDevices) {
        count = count + uniqueDevices;
      }
    });
  }
  return count;
};

export const extendedCount = (geoTargets, eventsTargets) => {
  let count = 0;
  if (geoTargets && !isEmpty(geoTargets)) {
    geoTargets.forEach(target => {
      const { expandedReach } = target;
      if (expandedReach) {
        count = count + expandedReach;
      }
    });
  }
  if (eventsTargets && !isEmpty(eventsTargets)) {
    eventsTargets.forEach(target => {
      const { expandedReach } = target;
      if (expandedReach) {
        count = count + expandedReach;
      }
    });
  }
  return count;
};

export const observationsCount = (geoTargets, eventsTargets) => {
  let count = 0;
  if (geoTargets && !isEmpty(geoTargets)) {
    geoTargets.forEach(target => {
      const { observations } = target;
      if (observations) {
        count = count + observations;
      }
    });
  }
  if (eventsTargets && !isEmpty(eventsTargets)) {
    eventsTargets.forEach(target => {
      const { observations } = target;
      if (observations) {
        count = count + observations;
      }
    });
  }
  return count;
};

export const buildDeviceTable = (geoTargets, eventsTargets) => {
  let dataToReturn = [];

  const count = deviceCount(geoTargets, eventsTargets);
  const extended = extendedCount(geoTargets, eventsTargets);
  const observations = observationsCount(geoTargets, eventsTargets);

  dataToReturn.push({
    xAxis: "Device Match",
    impressions: observations,
    IDENTIFIERS: count,
    TYPE: "HARDWARE",
  });

  dataToReturn.push({
    xAxis: "Extended Device Match",
    impressions: observations,
    IDENTIFIERS: extended,
    TYPE: "HARDWARE",
  });

  return dataToReturn;
};

export const buildTapadBreakdownTable = TAPAD => {
  let dataToReturn = [];

  dataToReturn = TAPAD.map(record => {
    const { TYPE, IDENTIFIERS } = record;
    let xAxis = TYPE;
    if (TYPE === "APN") {
      xAxis = "Regulated Tier 02";
    } else if (TYPE === "TTD") {
      xAxis = "Regulated Tier 01";
    } else if (TYPE === "CNT") {
      xAxis = "Deregulated Tier 01";
    }
    return {
      xAxis,
      impressions: IDENTIFIERS,
      ...record,
    };
  }).filter(fitem => {
    return (
      fitem.TYPE !== "MJ" &&
      fitem.TYPE !== "MJ_HASH" &&
      fitem.TYPE !== "HOUSEHOLD_CLUSTER_ID" &&
      fitem.TYPE !== "HARDWARE_IDFA" &&
      fitem.TYPE !== "HARDWARE_ANDROID_AD_ID" &&
      fitem.TYPE !== "INDIVIDUAL_CLUSTER_ID"
    );
  });

  return dataToReturn;
};
