import React from "react";
import moment from "moment";
import { Row, Col, Result, Tooltip, Card, Skeleton, Space, Empty } from "antd";
import { isEmpty } from "lodash";
import { AreaChartOutlined } from "@ant-design/icons";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";

import Button from "../../shared/globalStyling/Button";
import { ButtonText } from "../../shared/globalStyling/styledText";
import { FiltersBar } from "./components/OrgSummary";

import OrgSummaryChart from "./components/OrgSummary/OrgSummaryChart";
import CampaignOrderTable from "./components/OrgSummary/CampaignOrderTable";
import { useHistory } from "react-router-dom";
import { orgLevelCustomization } from "../../../core/permissions/orgLevelCustomization";
import OverlaySpinner from "../../shared/OverlaySpinner";

const DisplayPerformanceView = ({
  totalOrgSummaries,
  advertiserIds,
  orgId,
  loading,
  rawHomeChartData,
  setIsLoading,
  isLoading,
  onRefreshOrgSummary,
  errorOrgSummary,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  setDataSummaryRangeDate,
  loadingReport,
  orgs,
  byDateReportChartData,
  byDayReport,
  byDateReportRaw,
  currentUser,
  errorLoadingReport,
  tableData,
  userPermission,
  filterBarProps,
  initChartLoaded,
  weeklyReportData,
  disableRefresh,
  startDateParams,
  endDateParams,
}) => {
  // Set Document Title
  document.title = "Display Analytics";

  // user permission
  const history = useHistory();
  const refreshPage = () => {
    history.push("/");
    history.goBack();
  };

  if (!userPermission) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button onClick={() => refreshPage()} type="primary">
              Reload
            </Button>
          }
        />
      </>
    );
  }

  const onClickRefresh = () => {
    if (advertiserIds.includes(orgId)) {
      setIsLoading(true);
      onRefreshOrgSummary();
    }
  };

  if (errorOrgSummary) {
    return (
      <>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </>
    );
  }

  const ReportLoadingView = () => {
    if (errorLoadingReport) {
      return (
        <Card>
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Button onClick={() => refreshPage()} type="primary">
                Reload
              </Button>
            }
          />
        </Card>
      );
    }
    return <LoadingCard />;
  };

  const LoadingCard = () => {
    return (
      <>
        <Card loading={loading} style={{ marginBottom: 18 }}>
          <Row type="flex" justify="center" align="middle">
            <Skeleton paragraph={{ rows: 6 }} active={true} />
          </Row>
        </Card>
        <Card loading={loading}>
          <Row type="flex" justify="center" align="middle">
            <Skeleton paragraph={{ rows: 10 }} active={true} />
          </Row>
        </Card>
      </>
    );
  };

  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;

  const updatedAt = () => {
    if (totalOrgSummaries && totalOrgSummaries.updatedAt) {
      return `Updated ${moment(
        totalOrgSummaries.updatedAt,
        moment.DATETIME_LOCAL_MS
      ).calendar()}`;
    }
    return "";
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Display Performance"}
        subtitle={updatedAt()}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Col style={{ display: "flex" }} span={24}>
            {!disableRefresh && advertiserIds.includes(orgId) && (
              <>
                <Tooltip
                  placement="left"
                  title="Request the latest summary data for your own organization."
                >
                  <Button
                    loading={isLoading}
                    onClick={onClickRefresh}
                    disabled={!advertiserIds.includes(orgId)}
                  >
                    <ButtonText text="Refresh" />
                  </Button>
                </Tooltip>
              </>
            )}
          </Col>
        }
      />
      <PageContentLayout>
        {loading ? (
          <OverlaySpinner />
        ) : (
          <Space size={16} style={{ width: "100%" }} direction="vertical">
            <>
              <Row gutter={[16, 16]}>
                {(rawHomeChartData && rawHomeChartData.length > 0) ||
                loadingReport ||
                errorLoadingReport ||
                initChartLoaded ? (
                  <Col xs={24}>
                    <Row gutter={[18, 18]}>
                      <Col xs={24}>
                        {loadingReport || isLoading || errorLoadingReport ? (
                          <ReportLoadingView />
                        ) : (
                          <Row gutter={[18, 18]}>
                            <Col xs={24}>
                              <Card loading={loading} bordered={false}>
                                <FiltersBar
                                  weeklyReportData={weeklyReportData}
                                  tableData={byDateReportChartData}
                                  orgs={orgs}
                                  currentUser={currentUser}
                                  startEndDateParams={[
                                    startDateParams,
                                    endDateParams,
                                  ]}
                                  history={history}
                                  {...filterBarProps}
                                />
                              </Card>
                              <Card
                                loading={loading}
                                bordered={false}
                                style={{ minHeight: 150 }}
                              >
                                {loadingReport ? (
                                  <OverlaySpinner />
                                ) : (
                                  <OrgSummaryChart
                                    byDates={
                                      isEmpty(byDateReportChartData)
                                        ? []
                                        : byDateReportChartData
                                    }
                                    byDay={
                                      isEmpty(byDayReport) ? [] : byDayReport
                                    }
                                    bySummaryRangeDate={bySummaryRangeDate}
                                    setBySummaryRangeDate={
                                      setBySummaryRangeDate
                                    }
                                    dataSummaryRangeDate={byDateReportChartData}
                                    setDataSummaryRangeDate={
                                      setDataSummaryRangeDate
                                    }
                                    SummaryRangeDate={setDataSummaryRangeDate}
                                    reportData={byDateReportRaw}
                                  />
                                )}
                              </Card>
                              <Card
                                loading={loading}
                                bordered={false}
                                style={{ minHeight: 300 }}
                              >
                                {loadingReport ? (
                                  <OverlaySpinner />
                                ) : (
                                  <CampaignOrderTable
                                    tableData={tableData}
                                    currencyFormat={currencyFormat}
                                    priceVisible={userPermission.priceVisible}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {(!loadingReport && !rawHomeChartData) ||
                (!loadingReport &&
                  rawHomeChartData &&
                  rawHomeChartData.length < 1 && (
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Card loading={loading} bodyStyle={{ height: 400 }}>
                          <Row
                            style={{ height: "100%" }}
                            align="middle"
                            justify="center"
                          >
                            <Col xs={24}>
                              <Empty description="Analytics Not Available" />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  ))}
            </>
          </Space>
        )}
      </PageContentLayout>
    </React.Fragment>
  );
};

export default DisplayPerformanceView;
