import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";
import GET_SHARED_ORGS from "../../GraphQl/Queries/GET_SHARED_ORGS";

const OrgContext = createContext();

export const OrgProvider = ({ children }) => {
  const [
    getShardOrgs,
    { data: sharedOrgs, loading: sharedOrgsLoading, error },
  ] = useLazyQuery(GET_SHARED_ORGS);

  return (
    <OrgContext.Provider
      value={{
        getShardOrgs,
        sharedOrgs: (sharedOrgs && sharedOrgs.orgs) || [],
        sharedOrgsLoading,
        error,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export default OrgContext;
