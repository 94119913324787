import React from "react";
import { Row, Col, Card, Affix, Typography, Button } from "antd";
import moment from "moment";
import LauncherMapSimple from "../../../shared/maps/LauncherMapSimple";
import S3Image from "../components/S3Image";
import PaymentCard from "../../../shared/PaymentCard";
import StepsCard from "./StepsCard";
import GeoTargetsReviewList from "./GeoTargetsReviewList";
import { isEmpty } from "lodash";
import { CheckOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const styles = {
  cardBody: {
    padding: 0,
  },
  cardRow: {
    padding: "15px",
    borderBottom: "1px solid #e8e8e8",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
  title: {
    color: "#636363",
    margin: 0,
    fontWeight: "normal",
  },
  bannerNameText: {
    maxWidth: "90%",
  },
  bannerCard: {
    height: "150px",
    width: "150px",
  },
  banner: {
    height: "75px",
    width: "75px",
  },
  map: {
    position: "relative",
    height: "300px",
    width: "100%",
  },
};

const CardTitle = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <CheckOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{titleText}</h4>
      </Col>
    </Row>
  );
};

const Review = ({
  currentStep,
  onStepChange,
  brands,
  campaignName,
  category,
  tags,
  startDate,
  endDate,
  age,
  language,
  income,
  segments,
  devices,
  objectiveNewCustomers,
  objectiveExistingCustomers,
  retargetCap,
  kpi,
  cpm,
  budget,
  adUnits,
  geoTargets,
  handleOrderSubmit,
}) => {
  const {
    cardBody,
    cardRow,
    title,
    textRow,
    secondaryRow,
    bannerNameText,
    bannerCard,
    banner,
  } = styles;

  const steps = [
    {
      title: "Financial Summary",
      descriptionTitle: [`Inventory:`],
      description: [``],
      complete: true,
    },
    {
      title: "Estimated Cost",
      descriptionTitle: [`Total:`, `Media Price:`, `Fees: `],
      description: [`$ 0`, `$ 0`, `$ 0`],
      complete: true,
    },
  ];

  return (
    <Row gutter={12}>
      <Col span={16}>
        <Card title={<CardTitle titleText="Summary" />} bodyStyle={cardBody}>
          <Row style={cardRow}>
            <Title level={4} style={title}>
              Basics
            </Title>
            <Row style={secondaryRow}>
              <Col span={8}>
                <Row style={textRow}>
                  <Text type="secondary">Brand</Text>
                </Row>
                {brands.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={8}>
                <Row style={textRow}>
                  <Text type="secondary">Campaign Name</Text>
                </Row>
                <Row>
                  <Text>{campaignName}</Text>
                </Row>
              </Col>
              <Col span={8}>
                <Col span={10}>
                  <Row style={textRow}>
                    <Text type="secondary">Start</Text>
                  </Row>
                  <Row>
                    <Text>
                      {startDate
                        ? moment(startDate).format("MM/DD/YYYY")
                        : "ASAP"}
                    </Text>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row style={textRow}>
                    <Text type="secondary">End</Text>
                  </Row>
                  <Row>
                    <Text>
                      {endDate
                        ? moment(endDate).format("MM/DD/YYYY")
                        : "Run Continuously"}
                    </Text>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Row>
          <Row style={cardRow}>
            <Title level={4} style={title}>
              Categories
            </Title>
            <Row style={secondaryRow}>
              <Col span={8}>
                <Row style={textRow}>
                  <Text type="secondary">Categories</Text>
                </Row>
                {category.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={8}>
                <Row style={textRow}>
                  <Text type="secondary">Tags</Text>
                </Row>
                <Row>
                  {tags.map((value, index) => {
                    return (
                      <Row key={index}>
                        <Text>{value}</Text>
                      </Row>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Row>
          <Row style={cardRow}>
            <Title level={4} style={title}>
              Demographic
            </Title>
            <Row style={secondaryRow}>
              <Col span={4}>
                <Row style={textRow}>
                  <Text type="secondary">Age Ranges</Text>
                </Row>
                {age.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={6}>
                <Row style={textRow}>
                  <Text type="secondary">Household Incomes</Text>
                </Row>
                {income.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={4}>
                <Row style={textRow}>
                  <Text type="secondary">Languages</Text>
                </Row>
                {language.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={4}>
                <Row style={textRow}>
                  <Text type="secondary">Segments</Text>
                </Row>
                {segments.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
              <Col span={4}>
                <Row style={textRow}>
                  <Text type="secondary">Devices</Text>
                </Row>
                {devices.map((value, index) => {
                  return (
                    <Row key={index}>
                      <Text>{value}</Text>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Row>
          <Row style={cardRow}>
            <Title level={4} style={title}>
              Optimization Details
            </Title>
            <Row style={secondaryRow}>
              <Col span={6}>
                <Row style={textRow}>
                  <Text type="secondary">Campaign Model</Text>
                </Row>
                {objectiveNewCustomers === 100 &&
                !objectiveExistingCustomers ? (
                  <Text>Prospecting</Text>
                ) : !objectiveNewCustomers &&
                  objectiveExistingCustomers === 100 ? (
                  <Text>Bring Back Visitors</Text>
                ) : (
                  <div>
                    <Text>Combination</Text>
                    <br />
                    <Text>Prospecting & Bring Back Visitors</Text>
                  </div>
                )}
              </Col>
              <Col span={6}>
                <Row style={textRow}>
                  <Text type="secondary">Retargeting</Text>
                </Row>
                {!retargetCap ? <Text>Not Enabled</Text> : <Text>Enabled</Text>}
              </Col>
              <Col span={6}>
                <Row style={textRow}>
                  <Text type="secondary">Budget Allocation</Text>
                </Row>
                {!objectiveNewCustomers && !objectiveExistingCustomers ? (
                  <Text>Automatic</Text>
                ) : (
                  <Text>Manual</Text>
                )}
              </Col>
              <Col span={6}>
                <Row style={textRow}>
                  <Text type="secondary">Optimization</Text>
                </Row>
                <Row>
                  {kpi === "CPM" ? (
                    <Text>CPM</Text>
                  ) : kpi === "CPC" ? (
                    <Text>CPC</Text>
                  ) : (
                    <Text>CPA</Text>
                  )}
                </Row>
                <Row>
                  <Text>Daily Budget: ${budget}</Text>
                  <br />
                  <Text>
                    {kpi} Goal: ${cpm}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Row>
          {adUnits.map((unit, index) => {
            const { clickthrough, banners } = unit;
            return (
              <Row key={index} style={cardRow}>
                <Title level={4} style={title}>
                  Ad Group {index + 1}
                </Title>
                <Text>{clickthrough}</Text>
                <Row gutter={2}>
                  {banners.map((image, index) => {
                    const { key, name, height, width } = image;
                    return (
                      <Col key={index} span={4}>
                        <Card style={bannerCard}>
                          <Row>
                            <Text
                              style={bannerNameText}
                              ellipsis={true}
                              type="secondary"
                            >
                              {name}
                            </Text>
                          </Row>
                          <Row>
                            <Col span={12} style={banner}>
                              <S3Image imgKey={key} />
                            </Col>
                          </Row>
                          <Row>
                            <Text type="secondary">
                              {width} X {height}
                            </Text>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Row>
            );
          })}
          <Row style={cardRow}>
            <Title level={4} style={title}>
              Geo-Targets
            </Title>
            <LauncherMapSimple
              geoTargets={geoTargets}
              zoom={5}
              styles={styles}
            />
            <GeoTargetsReviewList geoTargets={geoTargets} />
          </Row>
        </Card>
      </Col>
      <Col span={6}>
        <Affix offsetTop={20} affix={"false"}>
          <Row style={{ marginBottom: "15px" }}>
            <StepsCard stepsToComplete={steps} />
          </Row>
          <Row
            style={{
              borderRight: "1px solid #64c87a",
              borderLeft: "1px solid #64c87a",
              borderTop: "1px solid #64c87a",
            }}
          >
            <PaymentCard />
          </Row>
          <Row
            style={{
              borderRight: "1px solid #64c87a",
              borderLeft: "1px solid #64c87a",
              borderBottom: "1px solid #64c87a",
            }}
          >
            <Card
              size="small"
              style={{
                marginTop: "0px",
                width: "100%",
              }}
            >
              <Row type="flex" justify="space-around">
                <Button
                  style={{ fontSize: "12px", width: "90px" }}
                  shape="round"
                  onClick={() => onStepChange(currentStep - 1)}
                >
                  Back
                </Button>
                <Button
                  style={{
                    fontSize: "12px",
                    width: "90px",
                    background: "#64c87a",
                    border: "1px solid #64c87a",
                  }}
                  type="primary"
                  shape="round"
                  onClick={() => handleOrderSubmit()}
                  disabled={
                    isEmpty(campaignName) ||
                    isEmpty(language) ||
                    isEmpty(devices) ||
                    isEmpty(category) ||
                    isEmpty(age) ||
                    isEmpty(income) ||
                    isEmpty(budget) ||
                    isEmpty(cpm) ||
                    isEmpty(adUnits[0].clickthrough) ||
                    isEmpty(adUnits[0].banners) ||
                    isEmpty(geoTargets)
                    // TODO: Check if payment specified here
                  }
                >
                  Submit
                </Button>
              </Row>
            </Card>
          </Row>
        </Affix>
      </Col>
    </Row>
  );
};

export default Review;
