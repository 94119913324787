import gql from "graphql-tag";

const CAMPAIGN_ORDER_DETAILS_ADMIN = gql`
  query CampaignOrderDetails($ID: ID) {
    signUpReports: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_SIGNUPS }
      ) {
        id
        type
        key
      }
    }
    displayReports: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_DISPLAY }
      ) {
        id
        type
        key
      }
    }
    transactionReports: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_TRANSACTIONS }
      ) {
        id
        type
        key
      }
    }
    transactionReportsCSV: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_TRANSACTIONS_CSV_REPORT }
      ) {
        id
        type
        key
      }
    }
    attributionReports: campaignOrder(where: { id: $ID }) {
      id
      reports(orderBy: createdAt_DESC, first: 1, where: { type: ATTRIBUTION }) {
        id
        type
        key
      }
    }
    basketItemsReports: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_BASKET_ITEMS }
      ) {
        id
        type
        key
      }
    }
    pageViewReports: campaignOrder(where: { id: $ID }) {
      id
      reports(
        orderBy: createdAt_DESC
        first: 1
        where: { type: CAMPAIGN_ORDER_PAGE_VIEW }
      ) {
        id
        type
        key
      }
    }
    campaignOrder(where: { id: $ID }) {
      id
      status
      budget
      name
      version
      description
      devices
      startDate
      endDate
      attributionWindow
      type
      repullVWI
      cpm
      kpi
      requesterVersion
      attributionTargets {
        vwiUpdated
        id
        name
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      aggregateData {
        id
        overallData {
          id
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
          aggVWI
        }
        topPublishers
        topCreatives
        dayPacingData {
          id
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
        }
      }
      orgs {
        id
        name
        roles {
          users {
            id
            name
          }
          isMaster
        }
      }
      advertiser {
        id
        name
      }

      transactionTarget
      signUpCampaign
      transactionsConfig {
        id
        cart
        traffId
        gtmAccess
        gtmStatus
        tracksBasketItems
      }
      pageViewConfig {
        appId
      }
      campaigns {
        id
        name
        demandSidePlatform
        budgetTotal
        budgetByDay
        cpm
        startDate
        endDate
        data
        adProviderId
        aggregateData {
          id
          overallData {
            id
            aggEvents
            aggCost
            aggTotalCost
            aggImpressions
            aggClicks
            aggVWI
          }
          dayPacingData {
            id
            aggEvents
            aggCost
            aggTotalCost
            aggImpressions
            aggClicks
          }
        }
        lineItems {
          id
          name
          demandSidePlatform
          budgetTotal
          startDate
          endDate
          cpm
          adProviderId
          aggregateData {
            id
            overallData {
              id
              aggEvents
              aggCost
              aggTotalCost
              aggImpressions
              aggClicks
              aggVWI
            }
            dayPacingData {
              id
              aggEvents
              aggCost
              aggTotalCost
              aggImpressions
              aggClicks
            }
          }
        }
        createdAt
        updatedAt
      }
      isEditCampaign
      isRequestChange
      processorId
      campaignReviewProcess {
        id
        review
        stateLaw
        scaleBudget
      }
      reviewerId
      requestorId
      version
    }
  }
`;

export default CAMPAIGN_ORDER_DETAILS_ADMIN;
