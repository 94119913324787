import { LoadingOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Col } from "antd";
import React from "react";
import GlobalButton from "../../../../shared/globalStyling/Button";

const UtilsContent = ({
  childOrgsLoading,
  selectedRowKeys,
  setSharedOrgsToggle,
  getSharedDetails,
}) => {
  return (
    <Col flex="auto" xs={24}>
      <GlobalButton
        icon={childOrgsLoading ? <LoadingOutlined /> : <ShareAltOutlined />}
        shape="round"
        type="primary"
        ghost
        disabled={childOrgsLoading || (selectedRowKeys || []).length < 1}
        onClick={() => {
          setSharedOrgsToggle(true);
          getSharedDetails({
            variables: {
              ids: selectedRowKeys,
            },
          });
        }}
      >
        Share SEO
      </GlobalButton>
    </Col>
  );
};

export default UtilsContent;
