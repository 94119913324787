import gql from "graphql-tag";

const EDIT_MEDIA_FLAG = gql`
  mutation updateMedia($id: ID!, $flag: Boolean) {
    updateMedia(where: { id: $id }, data: { flag: $flag }) {
      id
      flag
    }
  }
`;

export default EDIT_MEDIA_FLAG;
