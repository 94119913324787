import gql from "graphql-tag";

const DELETE_LOCATION = gql`
  mutation deleteLocation($Id: ID!) {
    deleteLocation(where: { id: $Id }) {
      id
    }
  }
`;

export default DELETE_LOCATION;
