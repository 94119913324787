import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Row, Card, Form, Input, Button, message } from "antd";
import { LockFilled, UserOutlined } from "@ant-design/icons";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import UPDATE_USER from "../../../.././core/GraphQl/Mutations/UPDATE_USER";
import AUTHENTICATED_USER from "../../../../core/GraphQl/Mutations/AUTHENTICATED_USER";
import { getRolesFeatures } from "../../../../core/utils/dataManipulating/organization";
import WhiteLabel from "../../../shared/WhiteLabel";
import PasswordChecklist from "react-password-checklist";

const result = WhiteLabel();
const logo = result;

const NewPasswordForm = props => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [verifyPassword, setVerifyPassword] = useState(""); // State for verify password

  const [form] = Form.useForm();
  const { validateFields } = form;

  const loginUser = async user => {
    try {
      const response = await props.authenticatedUser({
        variables: {},
      });

      const { authenticatedUser } = response.data;
      const {
        id,
        username,
        email,
        cognitoUserId,
        avatar,
        roles,
        notifications,
      } = authenticatedUser;

      let features = getRolesFeatures(roles);

      if (features.includes("ENABLED")) {
        const allowedFeatures = getRolesFeatures(roles).filter(feature => {
          return feature !== "ENABLED";
        });
        const userData = {
          id,
          username,
          email,
          cognitoUserId,
          avatar,
          roles,
          isAuthenticated: true,
          features: allowedFeatures,
          notifications,
        };

        await props.updateUser({
          variables: { ...userData },
        });

        setLoading(false);
        props.history.push("/signin");
      } else {
        Auth.signOut();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    validateFields()
      .then(async values => {
        await Auth.signOut()
          .then(() => {
            const { username, password } = values;
            if (!Auth || typeof Auth.signIn !== "function") {
              throw new Error(
                "No Auth module found, please ensure @aws-amplify/auth is imported"
              );
            }

            Auth.signIn(username, password)
              .then(user => {
                if (
                  user.challengeName === "SMS_MFA" ||
                  user.challengeName === "SOFTWARE_TOKEN_MFA"
                ) {
                  props.history.push({
                    pathname: "/mfa",
                    state: { username, password },
                  });
                } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                  if (newPassword !== verifyPassword) {
                    setLoading(false);
                  } else if (newPassword === verifyPassword) {
                    Auth.completeNewPassword(user, newPassword)
                      .then(async userWithNewPass => {
                        loginUser(userWithNewPass);
                      })
                      .catch(err => {
                        setLoading(false);
                      });
                  }
                } else if (user.challengeName === "MFA_SETUP") {
                  props.history.push("/setup");
                } else {
                  loginUser(user);
                }
              })
              .catch(err => {
                console.log(err);
                if (err.code === "UserNotConfirmedException") {
                  message.warning("Sign up confirmation not completed!");
                } else if (err.code === "PasswordResetRequiredException") {
                  this.props.history.push("/mfa");
                }
                setLoading(false);
              });
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
          });
      })
      .catch(errorInfo => {
        setLoading(false);
        console.log(errorInfo);
      });
  };

  const handleNewPasswordChange = value => {
    setNewPassword(value);
  };

  const handleVerifyPasswordChange = value => {
    setVerifyPassword(value);
  };

  return (
    <div style={styles.landingPage}>
      <Card
        hoverable
        style={{ padding: 20 }}
        cover={
          <img
            alt="example"
            src={logo}
            onClick={() => props.history.push("/")}
          />
        }
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                pattern: /^[A-Za-z0-9]+$/,
                message:
                  "User name must be all one word and not contain spaces",
                validateTrigger: "blur",
              },
            ]}
            initialValue={props.location && props.location.state.username}
          >
            <Input addonBefore={<UserOutlined />} placeholder="User name" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                validateTrigger: "blur",
                message: "Password is required",
              },
            ]}
            initialValue={props.location && props.location.state.password}
          >
            <Input.Password
              addonBefore={<LockFilled />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="newpassword"
            rules={[
              {
                required: true,
                validateTrigger: "blur",
              },
            ]}
          >
            <Input.Password
              addonBefore={<LockFilled />}
              placeholder="New Password"
              onChange={e => handleNewPasswordChange(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="verifypassword"
            rules={[
              {
                required: true,
                validateTrigger: "blur",
              },
            ]}
          >
            <Input.Password
              addonBefore={<LockFilled />}
              placeholder="Verify new password"
              onChange={e => handleVerifyPasswordChange(e.target.value)}
            />
          </Form.Item>

          <div className="checklistContainer">
            <PasswordChecklist
              rules={[
                "minLength",
                "specialChar",
                "number",
                "capital",
                "lowercase",
                "match",
              ]}
              minLength={8}
              value={newPassword}
              valueAgain={verifyPassword}
              iconSize={13}
              invalidColor={"#ff0000"}
              onChange={isValid => {}}
            />
            <style>
              {`
              .checklistContainer li.invalid {
                align-items: center !important
              }

              .checklistContainer li.valid {
                align-items: center !important
              }
            `}
            </style>
          </div>

          <Form.Item>
            <Row type="flex" justify="center">
              <Button
                style={{ background: "black", borderColor: "grey" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Change Password
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

const styles = {
  landingPage: {
    top: 20,
    position: "relative",
    margin: "auto",
    width: "300px",
    padding: "5px",
  },
};

export default compose(
  graphql(UPDATE_USER, { name: "updateUser" }),
  graphql(AUTHENTICATED_USER, { name: "authenticatedUser" })
)(NewPasswordForm);
