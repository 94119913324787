import React, { useEffect } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Tabs, Row, Col, Card, Switch, Popconfirm, Input } from "antd";
import { GoldOutlined, DeleteOutlined } from "@ant-design/icons";
import Button from "../../shared/globalStyling/Button";
import MapDrawing from "../../shared/maps/MapDrawing";
import {
  GeoTargetsTable,
  ReTargetingTable,
  SegmentsForm,
  SegmentsFilterBar,
  DetailsCard,
  SegmentsAudienceTable,
} from "./components";
import { isEmpty } from "lodash";
import {
  parseOverlayToGeoTarget,
  flattenGeoTargets,
  isEqualGeometry,
} from "../../../core/utils/geoTargets";

import { determineUserAccess } from "../../../core/permissions/userLevelAccess";

const styles = {
  mapCard: {
    border: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    minHeight: "400px",
    marginRight: "8px",
    marginBottom: "8px",
  },
  switchLayout: {
    flex: 1,
    bottomBorder: 0,
    padding: 10,
  },
  switch: {
    backgroundColor: "#1890ff",
    fontWeight: "bold",
  },
  card: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

const { TextArea } = Input;
const { TabPane } = Tabs;

const SegmentLayout = props => {
  const {
    selectedGeoTargets,
    selectedRetargeting,
    shouldClearShapes,
    toggleShouldClearShapes,
    inProgressOverlay,
    submitForm,
    name,
    description,
    type,
    startDate,
    endDate,
    eventTags,
    changeValue,
    history,
    deleteGeoTargets,
    deleteEventsTarget,
    match,
    selectedRowKeys,
    selectedRowKeysEventsTargets,
    selectTableRow,
    WRITE,
    checkSwitchMode,
    checkTabSegment,
    activeKeyTab,
    afterEventsTargetSelected,
  } = props;
  let geoTargets = props.geoTargets;

  let combineSyncSegments = props.combineSyncSegments;

  const getPermission = props.currentUser && props.currentUser.permission;

  const orgConfig = determineUserAccess(getPermission);
  const { isDemo } = orgConfig;

  const params = new URLSearchParams(window.location.search);
  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;

  if (isDemo) {
    geoTargets.forEach((c, i) => {
      c.name = `Example Geo-Target ${i}`;
    });

    combineSyncSegments.forEach((c, i) => {
      c.name = `Example Audience ${i}`;
    });
  }

  const getEventTags =
    eventTags === undefined
      ? undefined
      : eventTags.find(x => {
          return x;
        });

  let displayEventTags = null;

  try {
    displayEventTags = getEventTags.data[0];
  } catch (e) {
    displayEventTags = null;
  }

  // useEffect to reset page size and current page when tab changes
  useEffect(() => {
    history.push(`/segments/new/1/updatedAt_DESC/{}?pageSize=${pageSize}`);
  }, [activeKeyTab, history, pageSize]);

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Segments"}
        titleIcon={
          <GoldOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Row>
            <Col>
              <Switch
                style={styles.switch}
                checkedChildren="Segments"
                unCheckedChildren="Audiences"
                defaultChecked={true}
                onChange={val => {
                  changeValue("checkSwitchMode", val);
                  changeValue("checkTabSegment", activeKeyTab);
                  changeValue("name", "");
                  changeValue("description", "");
                  changeValue("startDate", null);
                  changeValue("endDate", null);
                  changeValue("selectedGeoTargets", []);
                  changeValue("selectedRetargeting", []);
                }}
              />
            </Col>
          </Row>
        }
      />

      <PageContentLayout>
        <React.Fragment>
          {checkSwitchMode === true && (
            <Row>
              <Col span={24}>
                <Tabs
                  activeKey={activeKeyTab}
                  onChange={selected => {
                    changeValue("activeKeyTab", selected);
                    changeValue("checkTabSegment", selected);
                    changeValue("name", "");
                    changeValue("description", "");
                    changeValue("startDate", null);
                    changeValue("endDate", null);
                  }}
                >
                  <TabPane tab="Geo-Targets" key="geotargets">
                    <Row>
                      <Col md={20} xs={24}>
                        <div style={styles.mapCard}>
                          <MapDrawing
                            zoom={5}
                            shapes={selectedGeoTargets}
                            overlayChanged={val => {
                              changeValue("inProgressOverlay", val);
                            }}
                            shouldClearShapes={shouldClearShapes}
                            clearedCallback={toggleShouldClearShapes}
                            toolOptions={{
                              fillColor: "#BCDCF9",
                              fillOpacity: 0.5,
                              strokeWeight: 3,
                              strokeColor: "#57ACF9",
                              clickable: true,
                              editable: true,
                              zIndex: 1,
                            }}
                            drawing={WRITE}
                          />
                        </div>
                      </Col>
                      <Col md={4} xs={24}>
                        <DetailsCard {...props} />
                      </Col>
                    </Row>
                    {WRITE && !isDemo && (
                      <Row>
                        <Col span={24}>
                          <Card
                            style={styles.card}
                            title={
                              checkSwitchMode === true
                                ? "Create Geo-Target Segments"
                                : "Create Audiences"
                            }
                          >
                            <SegmentsForm
                              submit={submitForm}
                              name={name}
                              startDate={startDate}
                              endDate={endDate}
                              changeValue={changeValue}
                              description={description}
                              length={selectedGeoTargets.length}
                              hasInProgressShape={!isEmpty(inProgressOverlay)}
                              checkSwitchMode={checkSwitchMode}
                              checkTabSegment={checkTabSegment}
                              buttonText={
                                checkSwitchMode === true
                                  ? selectedGeoTargets.length === 1
                                    ? "Update Segment"
                                    : "Create Geo-Target"
                                  : "Create Audience"
                              }
                            />
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tab="Event Targets" key="retargeting">
                    {selectedRetargeting.length === 1 &&
                    afterEventsTargetSelected === false &&
                    !isDemo ? (
                      <Row>
                        <Col span={24}>
                          <Card style={styles.card} title="Ad Tag Snippet">
                            <TextArea rows={8} value={displayEventTags} />
                          </Card>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      {!isDemo && (
                        <Col span={24}>
                          <Card
                            style={styles.card}
                            title="Create Event Target Segments"
                          >
                            <SegmentsForm
                              submit={submitForm}
                              name={name}
                              startDate={startDate}
                              endDate={endDate}
                              changeValue={changeValue}
                              description={description}
                              length={selectedRetargeting.length}
                              checkSwitchMode={checkSwitchMode}
                              checkTabSegment={checkTabSegment}
                              buttonText={
                                checkSwitchMode === true
                                  ? selectedRetargeting.length === 1
                                    ? "Update Segment"
                                    : "Create Event Target"
                                  : "Create Audience"
                              }
                            />
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          )}

          {checkSwitchMode === false && !isDemo && (
            <Row>
              <Col span={24}>
                <Card style={styles.card} title="Create Audience">
                  <SegmentsForm
                    submit={submitForm}
                    name={name}
                    type={type}
                    changeValue={changeValue}
                    description={description}
                    length={selectedGeoTargets.length}
                    hasInProgressShape={!isEmpty(inProgressOverlay)}
                    checkSwitchMode={checkSwitchMode}
                    buttonText={
                      checkSwitchMode === true
                        ? selectedGeoTargets.length === 1
                          ? "Update Segment"
                          : "Create Segment"
                        : "Create Audience"
                    }
                  />
                </Card>
              </Col>
            </Row>
          )}

          {checkTabSegment === "geotargets" && checkSwitchMode === true ? (
            <Row
              style={{
                marginTop: "8px",
              }}
            >
              <Col span={24}>
                <PageHeaderLayout
                  rightContent={
                    WRITE && (
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to delete ${
                          selectedGeoTargets.length
                        } selected segment${
                          selectedGeoTargets.length > 1 ? "s" : ""
                        }?`}
                        onConfirm={deleteGeoTargets}
                        okText="Yes"
                        disabled={!selectedGeoTargets.length}
                        cancelText="No"
                      >
                        {!isDemo && (
                          <Button
                            shape="round"
                            icon={<DeleteOutlined />}
                            style={{ marginRight: 10 }}
                            disabled={!selectedGeoTargets.length}
                          >
                            Remove
                          </Button>
                        )}
                      </Popconfirm>
                    )
                  }
                  title={"Segments"}
                />
                <SegmentsFilterBar history={history} match={match} />
                <GeoTargetsTable
                  selectedRowKeys={selectedRowKeys}
                  selectTableRow={selectTableRow}
                  hasInProgressShape={
                    checkSwitchMode === true &&
                    selectedGeoTargets.length < 2 &&
                    !isEqualGeometry(
                      parseOverlayToGeoTarget(inProgressOverlay, true),
                      flattenGeoTargets(selectedGeoTargets)
                    )
                  }
                  {...props}
                  geoTargets={geoTargets}
                  isDemo={isDemo}
                  pageSize={pageSize}
                />
              </Col>
            </Row>
          ) : null}

          {checkTabSegment === "retargeting" && checkSwitchMode === true ? (
            <Row
              style={{
                marginTop: "8px",
              }}
            >
              <Col span={24}>
                <PageHeaderLayout
                  rightContent={
                    WRITE && (
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to delete ${
                          selectedRetargeting.length
                        } selected segment${
                          selectedRetargeting.length > 1 ? "s" : ""
                        }?`}
                        onConfirm={deleteEventsTarget}
                        okText="Yes"
                        disabled={!selectedRetargeting.length}
                        cancelText="No"
                      >
                        {!isDemo && (
                          <Button
                            shape="round"
                            icon={<DeleteOutlined />}
                            style={{ marginRight: 10 }}
                            disabled={!selectedRetargeting.length}
                          >
                            Remove
                          </Button>
                        )}
                      </Popconfirm>
                    )
                  }
                  title={"Segments"}
                />
                <ReTargetingTable
                  afterEventsTargetSelected={afterEventsTargetSelected}
                  changeValue={changeValue}
                  selectedRowKeysEventsTargets={selectedRowKeysEventsTargets}
                  pageSize={pageSize}
                  {...props}
                />
              </Col>
            </Row>
          ) : null}

          {checkSwitchMode === false ? (
            <SegmentsAudienceTable
              {...props}
              combineSyncSegments={combineSyncSegments}
            />
          ) : null}
        </React.Fragment>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SegmentLayout;
