import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";

const ExportExcelButton = ({ orgName, generateReport }) => {
  const handleExport = () => {
    try {
      const reportName = `${orgName.replace(
        /\//g,
        " "
      )}-Pacing Report ${moment()
        .utc()
        .format("MMMM D")}`;

      const zip = new JSZip();
      const folder = zip.folder(reportName);
      const fileData = generateReport();

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(fileData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const excelData = new Blob([excelBuffer], { type: fileType });

      folder.file(`${reportName}.xlsx`, excelData);

      zip.generateAsync({ type: "blob" }).then(
        blob => {
          saveAs(blob, reportName);
          return true;
        },
        function(e) {
          console.log("error", e);
          return false;
        }
      );
    } catch (err) {
      console.log(`CSV error: ${err}`);
    }
  };

  return (
    <Button
      style={{ width: "100%" }}
      icon={<DownloadOutlined />}
      type="primary"
      onClick={handleExport}
    >
      <ButtonText text={"Export Excel"} />
    </Button>
  );
};

export default ExportExcelButton;
