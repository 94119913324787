import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Modal,
  Select,
  message,
  Spin,
  Checkbox,
} from "antd";
import Button from "../../../shared/globalStyling/Button";
import debounce from "lodash/debounce";
import LIQUID_M_REGIONS from "../../../../core/GraphQl/Queries/LIQUID_M_REGIONS";
import { withApollo } from "react-apollo";

const CreateRegionGroup = ({
  isFormOpen,
  closeForm,
  handleSubmitRegionGroup,
  handleUpdateRegionGroup,
  currentRegion,
  debounceTimeout = 800,
  client,
  presetRegionGroups,
  presetRegionGroupsLoading,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [mode, setMode] = useState("Create");

  const fetchRef = useRef(0);

  useEffect(() => {
    if (currentRegion) {
      setMode("Update");
      form.setFieldsValue({
        name: currentRegion.name,
        regions: currentRegion.regions.map(region => ({
          value: region,
          label: region,
        })),
        isPresetList: currentRegion.isPresetList,
      });
    } else {
      setMode("Create");
      form.resetFields();
      setInitialValues({});
    }
  }, [currentRegion, form]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async name => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      try {
        const { data } = await client.query({
          query: LIQUID_M_REGIONS,
          variables: {
            filter: {
              name,
            },
          },
        });

        if (data && data.liquidMRegions && data.liquidMRegions) {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          const regions = data.liquidMRegions.map(region => ({
            label: `${region.id}: ${region.attributes.name}`,
            value: `${region.id}: ${region.attributes.name}`,
          }));
          setOptions(regions);
          setFetching(false);
        } else {
          throw new Error();
        }
      } catch (error) {
        setFetching(false);
        message.error("Failed to fetch regions. Try again");
      }
    };
    return debounce(loadOptions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTimeout]);

  const handleSubmit = async values => {
    setLoading(true);
    message.loading("Action in progress...", 0);
    let payload = {
      ...values,
      regions: values.regions.map(region => region.value),
    };

    let request = null;

    if (currentRegion) {
      payload = { ...payload, id: currentRegion.id };
      request = handleUpdateRegionGroup;
    } else {
      request = handleSubmitRegionGroup;
    }

    await request(payload)
      .then(() => {
        message.destroy();
        message.success(`Successfully ${mode}d Region Group`);
      })
      .catch(() => {
        message.destroy();
        message.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
        closeForm(false);
        form.resetFields();
      });
  };

  const preset =
    presetRegionGroups &&
    presetRegionGroups.getRegionGroupWithPresetList
      .filter(p => p.isPresetList)
      .map(p => {
        return { label: p.name, value: p.id };
      });
  return (
    <Modal
      title={`${mode}  Region Group`}
      visible={isFormOpen}
      centered
      footer={null}
      onCancel={() => {
        closeForm(false);
        form.resetFields();
      }}
    >
      {presetRegionGroupsLoading ? (
        <Spin />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input
                  placeholder={"Name of Region Group"}
                  name="name"
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          {preset.length > 0 && (
            <Row
              type="flex"
              justify="space-around"
              style={{ marginBottom: "0.5em" }}
            >
              <Col span={24}>
                <Form.Item
                  label="Presets"
                  name="presets"
                  help="Select a list to populate the data. This serves as a template and will not record the previously selected list."
                >
                  <Select
                    defaultValue="none"
                    onChange={value => {
                      if (value !== "none") {
                        const preset = presetRegionGroups.getRegionGroupWithPresetList.find(
                          p => p.id === value
                        );
                        form.setFieldsValue({
                          regions: preset.regions.map(region => ({
                            value: region,
                            label: region,
                          })),
                        });
                      } else {
                        form.setFieldsValue({
                          regions: [],
                        });
                      }
                    }}
                    options={[{ label: "None", value: "none" }, ...preset]}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item
                label="Region"
                name="regions"
                rules={[{ required: true }]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  labelInValue
                  filterOption={false}
                  onSearch={debounceFetcher}
                  notFoundContent={
                    fetching ? (
                      <span>
                        <Spin size="small" style={{ marginRight: 4 }} />
                        Please wait this might take a while
                      </span>
                    ) : null
                  }
                  name="regions"
                  options={options}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item name="isPresetList" valuePropName="checked">
                <Checkbox>Preset</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button htmlType="submit" type="primary" disabled={isLoading}>
                  {mode}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default withApollo(CreateRegionGroup);
