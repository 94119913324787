import React from "react";
import { Progress, Row, Col, Card } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import { ExtraInfo } from "../../attribution/components";
// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiFlightDate,
} from "../../../../../../../platform/shared/globalStyling/styledText";

import {
  formatCurrency2SigFig,
  formatWholeNumberNaN,
  computeROAS,
} from "../../../../../../../core/utils/campaigns";
import { ArrowRightOutlined } from "@ant-design/icons";

//TODO - break all styles out and store in central location
const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};

const DetailBar4Col = ({
  customers,
  transactions,
  displayData,
  startDate,
  endDate,
  cogs,
  selectedCampaign,
  transactionData,
  byTransactionsRangeDate,
  dataTransactionsRangeDate,
  type,
  determineUniques,
  dateTrue,
  priceVisible,
  budget = 0,
}) => {
  const getByDate = transactionData.map(item => {
    const keyValue = Object.entries(item);
    const transactionRevenue =
      keyValue[keyValue.findIndex(pair => pair[0] === "transactionTotal")];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "transaction")];
    return {
      totalImpressions: item.totalImpressions,
      impressionsFromDisplay: item.impressionsFromDisplay,
      transactionRevenue: transactionRevenue[1],
      xAxis: moment(x[1]).format("MM/DD/YYYY"),
    };
  });

  // Sort Dates
  const byDates =
    !isEmpty(getByDate) &&
    getByDate.sort((a, b) =>
      moment(a.xAxis, "MM/DD/YYYY").diff(moment(b.xAxis, "MM/DD/YYYY"))
    );

  // count foreach duplicate in Dates for transactions
  const counterTransactionsDates = {};

  if (!isEmpty(byDates)) {
    byDates.forEach(obj => {
      const key = JSON.stringify(obj.xAxis);
      counterTransactionsDates[key] = (counterTransactionsDates[key] || 0) + 1;
    });
  }

  const reduceByDate = getByDate.reduce((map, item) => {
    var summarizedItem = map.get(item.xAxis);
    if (!summarizedItem) {
      summarizedItem = item;
    } else {
      summarizedItem.transactionRevenue += item.transactionRevenue;
    }
    map.set(item.xAxis, summarizedItem);
    return map;
  }, new Map());

  // get the map values from the reduced
  const getArrayByDate = Array.from(reduceByDate.values());

  // get the sorted transactions values in counter to be mapped with the byDates Objects
  const setCounterTransactionsDates = Object.values(counterTransactionsDates);
  const initialTransactionData = getArrayByDate.map((obj, i) => {
    return {
      transactions: setCounterTransactionsDates[i],
      ...obj,
    };
  });

  // get selected start and end date from selected date picker
  const getSelectedStartDate =
    byTransactionsRangeDate && byTransactionsRangeDate !== null
      ? moment(byTransactionsRangeDate[0], moment.DATETIME_LOCAL_MS).format(
          "MM/DD/YYYY"
        )
      : null;
  const getSelectedEndDate =
    dataTransactionsRangeDate && byTransactionsRangeDate !== null
      ? moment(byTransactionsRangeDate[1], moment.DATETIME_LOCAL_MS).format(
          "MM/DD/YYYY"
        )
      : null;

  // get Transactions object and Revenue

  const getRevenue = item => {
    return item.transactionRevenue;
  };

  const getImpressions = item => {
    return item.impressionsFromDisplay;
  };

  const sumTotal = (prev, next) => {
    return prev + next;
  };

  // get total transactions or revenue by the selected dates
  const getTotalTransactions = isEmpty(dataTransactionsRangeDate)
    ? null
    : transactions;
  const getTotalRevenue = !isEmpty(initialTransactionData)
    ? initialTransactionData.map(getRevenue).reduce(sumTotal)
    : 0;
  const getTotalImpressions = !isEmpty(initialTransactionData)
    ? initialTransactionData.map(getImpressions).reduce(sumTotal)
    : 0;
  const mediaSpend = (getTotalImpressions / 1000) * selectedCampaign.cpm;

  const newTransactionData =
    transactionData &&
    transactionData.filter(obj => {
      return (
        moment(obj.transaction).format("MM/DD/YYYY") >= getSelectedStartDate &&
        moment(obj.transaction).format("MM/DD/YYYY") <= getSelectedEndDate
      );
    });
  const getCustomer = dateTrue
    ? determineUniques(newTransactionData)
    : determineUniques(transactionData);

  //Break into utils aklsjd jnuapaualo
  const getProgress = () => {
    const total = () => {
      var a = isEmpty(byTransactionsRangeDate)
        ? moment(endDate, moment.DATETIME_LOCAL_MS)
        : moment(byTransactionsRangeDate[1]);
      var b = isEmpty(byTransactionsRangeDate)
        ? moment(startDate, moment.DATETIME_LOCAL_MS)
        : moment(byTransactionsRangeDate[0]);
      const total = a.diff(b, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = isEmpty(byTransactionsRangeDate)
        ? moment(startDate, moment.DATETIME_LOCAL_MS)
        : moment(byTransactionsRangeDate[1]);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();
    if (progressDays > totaldays || progressDays === totaldays) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;
    return Math.floor(result);
  };

  const currentposition = getProgress();

  return (
    <Row style={styles.container} justify="space-between">
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Flight Dates"} />
            <span>
              <ExtraInfo
                title={
                  "Flight Dates refers to the Start & End Dates of the individual campaign"
                }
                placement={"top"}
              />
            </span>
          </div>
          <div style={styles.datesContainer}>
            <div style={styles.dates}>
              <KpiFlightDate
                text={
                  startDate
                    ? moment(startDate, moment.DATETIME_LOCAL_MS).format(
                        "MM/DD/YYYY"
                      )
                    : ""
                }
              />
            </div>
            <div>
              <ArrowRightOutlined style={{ color: "#636363" }} />
            </div>
            <div style={styles.dates}>
              <KpiFlightDate
                text={
                  endDate
                    ? moment(endDate, moment.DATETIME_LOCAL_MS).format(
                        "MM/DD/YYYY"
                      )
                    : ""
                }
              />
            </div>
          </div>
          <Progress
            showInfo={false}
            percent={currentposition}
            status={currentposition !== 100 ? "active" : "normal"}
            strokeColor={"#636363"}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Transactions"} />
          </div>
          <KpiSubText
            text={
              transactions
                ? !dateTrue
                  ? `${formatWholeNumberNaN(transactionData.length)}`
                  : `${formatWholeNumberNaN(getTotalTransactions)}`
                : "0"
            }
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Influenced Sales"} />
            <span>
              <ExtraInfo
                title={
                  "Multi-Touch Attribution (MTA). Using device-level data, this MTA model shows impressions have been served to device identifiers that have also made transactions. This model does not take into account other factors that may have influenced the purchase such as search, newsletters or other marketing techniques. MTA should also not be confused with other models. ie: Last-Interaction or Position-based"
                }
                placement={"top"}
              />
            </span>
          </div>
          <KpiSubText
            text={
              cogs
                ? !dateTrue
                  ? `${formatCurrency2SigFig(cogs)}`
                  : getTotalTransactions
                  ? `${formatCurrency2SigFig(getTotalRevenue)}`
                  : "0"
                : "0"
            }
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Customers"} />
          </div>
          <KpiSubText text={`${formatWholeNumberNaN(getCustomer)}`} />
        </Card>
      </Col>

      {true ? null : (
        <>
          <Col lg={4} xs={24}>
            <Card
              style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
              bodyStyle={{ borderLeft: 0 }}
            >
              <div style={styles.title}>
                <KPITitleText text={"Spend"} />
              </div>
              <KpiSubText text={`${formatCurrency2SigFig(mediaSpend)}`} />
            </Card>
          </Col>
          <Col lg={4} xs={24}>
            <Card
              style={{
                height: "10em",
                borderLeft: 0,
                borderTop: 0,
              }}
              bodyStyle={{ borderLeft: 0 }}
            >
              <div style={styles.title}>
                <KPITitleText text={"ROAS"} />
              </div>
              <KpiSubText
                text={
                  !dateTrue
                    ? computeROAS(getTotalRevenue, mediaSpend)
                    : getTotalTransactions
                    ? computeROAS(getTotalRevenue, mediaSpend)
                    : "0%"
                }
              />
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};
export default DetailBar4Col;
