import React from "react";
import { Card, Row, DatePicker } from "antd";
import { isEmpty } from "lodash";
import DualAxisArea from "./DualAxisArea";
import moment from "moment";
// Global Styles
import {
  CardTitleText,
  CardText,
} from "../../../../../../shared/globalStyling/styledText";
// Helper functions
// import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

import { ExtraInfo } from "./";
const { RangePicker } = DatePicker;
const Frag = React.Fragment;

const DateSelector = ({
  data,
  byWalkinRangeDate,
  setByWalkinRangeDate,
  dataWalkinRangeDate,
  setDataWalkinRangeDate,
}) => {
  // map xAxis dates
  const getDate = data.map(a => a.xAxis);

  // sort them by desc dates
  getDate.sort(function(a, b) {
    return moment(a.date) - moment(b.date);
  });

  // get startdate and end date
  const startDate = getDate[0];
  const endDate = getDate.slice(-1)[0];

  // combined start date & end date
  const startEndDate = [moment(startDate), moment(endDate)];

  // find the dates between startdate and enddate
  const findBetweenDates = current => {
    if (current <= moment(startDate).add(-2, "days")) {
      return true;
    } else if (current >= moment(endDate).add(+2, "days")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <RangePicker
      disabledDate={findBetweenDates}
      defaultPickerValue={startEndDate}
      defaultValue={startEndDate}
      // value={isEmpty(byCampaignRangeDate) ? startEndDate : byCampaignRangeDate}
      onChange={v => {
        // get selected range dates
        const getSelectedStartDate = moment(v[0]).format();
        const getSelectedEndDate = moment(v[1])
          .add(+1, "days")
          .format();

        // filter data to get only between range dates
        const getFilteredData = data.filter(obj => {
          return (
            obj.xAxis >= getSelectedStartDate && obj.xAxis <= getSelectedEndDate
          );
        });

        // set the date range on the selected date in calendar
        setByWalkinRangeDate(v);
        // set the data into filtered ones with the selected range dates
        setDataWalkinRangeDate(getFilteredData);
      }}
    />
  );
};

const explanation =
  "This chart displays the cumulative number of responses per day for the duration fo the campaign.";

const WalkinsByDateCard = ({
  data,
  flightDates,
  byWalkinRangeDate,
  setByWalkinRangeDate,
  dataWalkinRangeDate,
  setDataWalkinRangeDate,
}) => {
  return (
    <Frag>
      <Card
        title={<CardTitleText text={"Cumulative Response"} />}
        style={{ height: "22em" }}
        headStyle={{ borderWidth: "2px" }}
        bodyStyle={{ padding: "2%", paddingBottom: "3%" }}
        bordered
        extra={<ExtraInfo placement="topRight" title={explanation} />}
      >
        {data.length > 1 && (
          <Row type="flex" justify="center">
            <DateSelector
              data={data}
              byWalkinRangeDate={byWalkinRangeDate}
              setByWalkinRangeDate={setByWalkinRangeDate}
              dataWalkinRangeDate={dataWalkinRangeDate}
              setDataWalkinRangeDate={setDataWalkinRangeDate}
            />
          </Row>
        )}
        {isEmpty(data) ? (
          <Row type="flex" justify="center" align="middle">
            <CardText
              text={
                "Please refresh your browser to receive the latest updates."
              }
            />
          </Row>
        ) : (
          <Row>
            {data.length !== 1 && (
              <Frag>
                <DualAxisArea
                  flightDates={flightDates}
                  leftY={"Count"}
                  rightY={"Unique"}
                  data={
                    !dataWalkinRangeDate || !dataWalkinRangeDate.length
                      ? data.sort((a, b) => {
                          if (moment(a.xAxis).isBefore(b.xAxis)) return -1;
                          return 1;
                        })
                      : dataWalkinRangeDate
                  }
                />
              </Frag>
            )}
          </Row>
        )}
      </Card>
    </Frag>
  );
};

export default WalkinsByDateCard;
