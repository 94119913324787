import gql from "graphql-tag";
const CREATE_MANY_ORG_MEDIA = gql`
  mutation updateOrg($id: ID!, $data: OrgUpdateInput!) {
    updateOrg(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export default CREATE_MANY_ORG_MEDIA;
