import gql from "graphql-tag";

const UPDATE_BULK_CAMPAIGN_ORDER = gql`
  mutation updateCampaignBulkOrder(
    $id: ID!
    $budget: Float
    $cpm: Float
    $startDate: DateTime
    $endDate: DateTime
    $transactionTarget: String
    $transactionsConfig: TransactionsConfigUpdateOneWithoutCampaignOrdersInput
    $signUpCampaign: Boolean
    $signUpConfig: SignUpConfigUpdateOneWithoutCampaignOrdersInput
  ) {
    updateCampaignOrder(
      data: {
        budget: $budget
        cpm: $cpm
        startDate: $startDate
        endDate: $endDate
        transactionTarget: $transactionTarget
        transactionsConfig: $transactionsConfig
        signUpCampaign: $signUpCampaign
        signUpConfig: $signUpConfig
      }
      where: { id: $id }
    ) {
      id
      name
    }
  }
`;

export default UPDATE_BULK_CAMPAIGN_ORDER;
