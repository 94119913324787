//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Timeline, Row, Col } from "antd";

const { Item } = Timeline;

const ClicksSubTable = ({ clicks }) => {
  const [clicksData, setClicksData] = useState(clicks);

  useEffect(() => {
    const sortedClicks = clicks.sort((a, b) => {
      if (moment(a.clickTime).isBefore(moment(b.clickTime))) return -1;
      return 1;
    });

    setClicksData(sortedClicks);
  }, [clicks]);

  const makeTimeLine = clicksData.map((click, i) => {
    const { clickTime, campaign, pageUrl } = click;

    return (
      <Item key={i}>
        <Row>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Clicked at - ${moment(clickTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Campaign - ${campaign}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <a href={`${pageUrl}`} target="_blank" rel="noopener noreferrer">
                Visit Link
              </a>
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Timeline mode={"left"} reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ClicksSubTable;
