import React from "react";
import { Breadcrumb } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { BreadCrumbText } from "./globalStyling/styledText";

const PageHeaderChild = props => {
  let breadcrumb1 = "";
  let breadcrumb2 = "";
  props.breadcrumb1
    ? (breadcrumb1 = props.breadcrumb1)
    : (breadcrumb1 = "no title was passed");
  props.breadcrumb2
    ? (breadcrumb2 = props.breadcrumb2)
    : (breadcrumb2 = "no title was passed");

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, textAlign: "left", bottomBorder: 0, padding: 10 }}>
        <div
          style={{ flex: 1, textAlign: "left", bottomBorder: 0, padding: 10 }}
        >
          <h2>{breadcrumb2}</h2>
        </div>
        <Breadcrumb.Item onClick={props.showForm}>
          <BreadCrumbText text={breadcrumb1} />
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <BreadCrumbText text={breadcrumb2} />
        </Breadcrumb.Item>
      </div>
      <div
        style={{ flex: 1, textAlign: "right", bottomBorder: 0, padding: 10 }}
      >
        <CloseOutlined onClick={props.showForm} />
      </div>
    </div>
  );
};

export default PageHeaderChild;
