import React, { useEffect } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Row, Col, Card } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import Button from "../../../shared/globalStyling/Button";
// import MapDrawing from "../../../shared/maps/MapDrawing";
import {
  // GeoTargetsTable,
  // ReTargetingTable,
  SegmentsForm,
  SegmentsFilterBar,
  // DetailsCard,
  SegmentsAudienceTable,
} from "../../Segments/components";
import { isEmpty } from "lodash";
// import {
//   parseOverlayToGeoTarget,
//   flattenGeoTargets,
//   isEqualGeometry,
// } from "../../../../core/utils/geoTargets";

import { determineUserAccess } from "../../../../core/permissions/userLevelAccess";

const styles = {
  mapCard: {
    border: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    minHeight: "400px",
    marginRight: "8px",
    marginBottom: "8px",
  },
  switchLayout: {
    flex: 1,
    bottomBorder: 0,
    padding: 10,
  },
  switch: {
    backgroundColor: "#1890ff",
    fontWeight: "bold",
  },
  card: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

const SegmentLayout = props => {
  const {
    selectedGeoTargets,
    inProgressOverlay,
    submitForm,
    name,
    description,
    type,
    // eventTags,
    changeValue,
    checkSwitchMode,
    match,
    history,
  } = props;

  const config = props.currentUser.permission;
  // user permission
  const orgConfig = determineUserAccess(config);

  useEffect(() => {
    if (!orgConfig.isTrafficker) {
      history.push("/audiences");
    }
    // eslint-disable-next-line
  }, []);
  // const getEventTags =
  //   eventTags === undefined
  //     ? undefined
  //     : eventTags.find(x => {
  //         return x;
  //       });

  // let displayEventTags = null;

  // try {
  //   displayEventTags = getEventTags.data[0];
  // } catch (e) {
  //   displayEventTags = null;
  // }

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Audiences"}
        titleIcon={
          <UsergroupAddOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <>
            <Col style={{ display: "flex" }} span={24}>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  history.push(`/audiences`);
                }}
              >
                Back to list
              </Button>
            </Col>
          </>
        }
      />

      <PageContentLayout>
        <React.Fragment>
          <Row>
            <Col span={24}>
              <Card style={styles.card} title="Create Audience">
                <SegmentsForm
                  submit={submitForm}
                  name={name}
                  type={type}
                  changeValue={changeValue}
                  description={description}
                  length={selectedGeoTargets.length}
                  hasInProgressShape={!isEmpty(inProgressOverlay)}
                  checkSwitchMode={checkSwitchMode}
                  history={history}
                  buttonText={
                    checkSwitchMode === true
                      ? selectedGeoTargets.length === 1
                        ? "Update Segment"
                        : "Create Segment"
                      : "Create Audience"
                  }
                />
              </Card>
            </Col>
          </Row>
          <SegmentsFilterBar history={history} match={match} />
          <SegmentsAudienceTable {...props} />
        </React.Fragment>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SegmentLayout;
