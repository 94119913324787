import React from "react";
import { Card, Typography, Row, Col, Radio, Collapse } from "antd";
import Slider from "./ManualSpendingSlider";
import { BarChartOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { Panel } = Collapse;

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <BarChartOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{titleText}</h4>
      </Col>
    </Row>
  );
};

const PanelHeader = ({ styles }) => {
  return (
    <Radio value="BOTH">
      <Text>Both</Text>
      <br />
      <Text style={styles.secondaryText} type="secondary">
        Combine both campaign objective types to target new customers, as well
        as existing ones.
      </Text>
    </Radio>
  );
};

const styles = {
  options: {
    display: "flex",
    border: "1px solid",
    borderRadius: "4px",
    marginBottom: "15px",
    width: "100%",
  },
  collapseOption: {
    border: "1px solid",
    borderRadius: "4px",
    padding: 0,
  },
  panelRow: {
    marginLeft: "15px",
  },
  titleRow: {
    marginBottom: "10px",
  },
  secondaryText: {
    fontSize: "10px",
    marginLeft: "23px",
  },
};

const CampaignModelCard = ({
  onSlider,
  objectiveNewCustomers,
  objectiveExistingCustomers,
  radioTopValue,
  radioBothValue,
  border,
}) => {
  const onRadioSelect = e => {
    const target = e.target.value;
    if (target === "NEW") onSlider(100, null);
    if (target === "EXISTING") onSlider(null, 100);
    if (target === "BOTH") onSlider(null, null);
    if (target === "AUTOMATIC") onSlider(null, null);
    if (target === "MANUAL") onSlider(50, 50);
  };

  return (
    <Card bordered={border} title={<Title titleText="Campaign Model" />}>
      <Row style={styles.titleRow}>
        <Col span={24}>
          <Text>What is the objective of your campaign?</Text>
          <br />
          <Text type="secondary">
            Choose from the 3 options below. Need help? Have a look at our
            guidelines.
          </Text>
        </Col>
      </Row>
      <Radio.Group
        style={{ width: "100%" }}
        onChange={onRadioSelect}
        value={radioTopValue}
      >
        <Row
          style={{
            ...styles.options,
            borderColor: objectiveNewCustomers === 100 ? "#0078fe" : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="NEW">
              <Text>Find New Customers</Text>
              <br />
              <Text style={styles.secondaryText} type="secondary">
                Show ads to people that are likely to become customers, but do
                not yet know about your business.
              </Text>
            </Radio>
          </Col>
        </Row>

        <Row
          style={{
            ...styles.options,
            borderColor:
              !objectiveNewCustomers && objectiveExistingCustomers === 100
                ? "#0078fe"
                : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="EXISTING">
              <Text>Bring Back Existing Customers</Text>
              <br />
              <Text style={styles.secondaryText} type="secondary">
                Close more deals by showing ads to people who left your website
                without buying.
              </Text>
            </Radio>
          </Col>
        </Row>
        <Row
          style={{
            ...styles.collapseOption,
            borderColor:
              (!objectiveNewCustomers && !objectiveExistingCustomers) ||
              (objectiveNewCustomers && objectiveExistingCustomers)
                ? "#0078fe"
                : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              accordion
              onChange={() => {
                const e = {
                  target: {
                    value: "BOTH",
                  },
                };
                onRadioSelect(e);
              }}
              activeKey={radioTopValue === "BOTH" ? "1" : null}
            >
              <Panel
                style={{ border: 0 }}
                header={<PanelHeader styles={styles} />}
                key="1"
              >
                <Radio.Group onChange={onRadioSelect} value={radioBothValue}>
                  <Text style={{ margin: "15px 0 15px 15px" }}>
                    Budget Allocation
                  </Text>
                  <Row style={styles.panelRow}>
                    <Radio value="AUTOMATIC">
                      <Text>Automatic</Text>
                      <br />
                      <Text style={styles.secondaryText} type="secondary">
                        Maximize your budget allocation based on the amount of
                        available retargetable users, which changes day by day.
                      </Text>
                      <br />
                      <Text style={styles.secondaryText} type="secondary">
                        Additional budget is applied toward prospecting.
                      </Text>
                    </Radio>
                  </Row>
                  <Row style={styles.panelRow}>
                    <Col span={6}>
                      <Radio value="MANUAL">
                        <Text>Manual</Text>
                        <br />
                        <Text style={styles.secondaryText} type="secondary">
                          Allot your spending:
                        </Text>
                      </Radio>
                    </Col>
                    <Col span={18}>
                      <Slider
                        onChange={onSlider}
                        amount={
                          objectiveNewCustomers && objectiveExistingCustomers
                            ? objectiveNewCustomers
                            : 50
                        }
                      />
                    </Col>
                  </Row>
                </Radio.Group>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Radio.Group>
    </Card>
  );
};

export default CampaignModelCard;
