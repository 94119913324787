import React from "react";
import { cloneDeep } from "lodash";
import moment from "moment";

const AudienceCommerceController = ({
  children,
  rawCommerce,
  transactions,
  startDate,
  endDate,
  ...props
}) => {
  let commerce = rawCommerce || [];
  const summaryDate = [startDate, endDate];

  if (rawCommerce && summaryDate && summaryDate.length) {
    let cloneCommerce = cloneDeep(rawCommerce);
    const cByDate = cloneCommerce.byDate;

    const filterByDate = cByDate.filter(obj => {
      if (typeof obj.date === "string")
        obj.date = moment(obj.date, "YYYY-MM-DD")
          .utc()
          .unix();
      return (
        obj.date >= summaryDate[0].unix() && obj.date <= summaryDate[1].unix()
      );
    });

    const consolidatedCount = filterByDate.reduce(
      (acc, curr) => {
        acc.totalTransactions += curr.transactions;
        acc.revenue += curr.revenue;
        acc.totalItems += curr.items;

        return acc;
      },
      {
        totalTransactions: 0,
        revenue: 0,
        totalItems: 0,
      }
    );

    cloneCommerce.totalTransactions = consolidatedCount.totalTransactions;
    cloneCommerce.revenue = consolidatedCount.revenue;
    cloneCommerce.totalItems = consolidatedCount.totalItems;
    cloneCommerce.byDate = filterByDate;

    commerce = cloneCommerce;
  }
  return React.cloneElement(children, {
    commerce,
    summaryDate,
    transactions,
    ...props,
  });
};

export default AudienceCommerceController;
