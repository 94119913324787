import React from "react";
import { Button, Breadcrumb } from "antd";

const Seeder = props => {
  const SeedDb = async () => {
    const { action, list, processor } = props;
    const processed = processor(list);
    processed.forEach(async (item, i) => {
      try {
        //console.log("from seeder", item);
        //const response = await action(item);
        //console.log(response);

        if (i > 780 && i < 820) {
          await action(item);
        }
      } catch (err) {
        console.log("Error", err);
        return;
      }
    });
  };

  let name = "";
  props.name ? (name = props.name) : (name = "no name was passed");

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, textAlign: "left", bottomBorder: 0, padding: 10 }}>
        <Breadcrumb.Item>{name}</Breadcrumb.Item>
      </div>
      <div
        style={{ flex: 1, textAlign: "right", bottomBorder: 0, padding: 10 }}
      >
        <Button onClick={SeedDb} type="primary">
          {name}
        </Button>
      </div>
    </div>
  );
};

export default Seeder;
