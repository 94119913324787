import React from "react";
import { Table, Badge } from "antd";
import moment from "moment";

const columns = [
  {
    title: "Name",
    dataIndex: "audienceName",
    key: "audienceName",
    render: (text, record) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Badge
            dot={true}
            status={record.active ? "success" : "error"}
            text={text}
          />
          <h5 style={{ color: "#b3bacd", fontWeight: "normal" }}>
            Active: {moment(record.lastActivity).fromNow()}
          </h5>
        </div>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "segmentTypeId",
    key: "segmentTypeId",
  },
  {
    title: "Price Category",
    dataIndex: "dataCatId",
    key: "dataCatId",
  },
];

const AudiencesTable = props => {
  const { tableData, onClick, onSelect } = props;

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={tableData}
      rowSelection={{
        onSelect: (record, selected, selectedRowKeys, selectedRows) => {
          onSelect(record, selected);
          // console.log(record)
        },
      }}
      onRow={(record, index) => {
        return {
          onClick: e => {
            onClick(record);
          },
        };
      }}
    />
  );
};

export default AudiencesTable;
