import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import moment from "moment";
import { iabCategories } from "../../../../core/utils/constants/constants";

const { Text } = Typography;
const Frag = React.Fragment;
const styles = {
  cardRow: {
    padding: "15px",
    borderBottom: "1px solid #e8e8e8",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
  rowBasic: {
    borderBottom: "1px solid #e8e8e8",
    paddingTop: "25px",
    paddingLeft: "45px",
    paddingRight: "45px",
    paddingBottom: "45px",
  },
  divider: {
    margin: "0px",
  },
};

const ReviewBasics = ({
  campaignName,
  description,
  startDate,
  endDate,
  category,
  isRegulated,
  budget,
  cpm,
  targetingCriteria,
  awarenessCampaign,
  cart,
  transactionTarget,
  traffId,
  gtmAccess,
  gtmStatus,
  type,
  tracksBasketItems,
  name,
}) => {
  const { rowBasic, secondaryRow, textRow, divider } = styles;
  const impressionGoal = Math.round((budget / cpm) * 1000).toLocaleString();

  // If category value is string -> convert it to array
  const arrayCategory = Array.isArray(category) ? category : Array(category);

  let cats = [];
  if (arrayCategory.length) {
    for (let i in arrayCategory) {
      // Find category ID in categories collection
      const cat = iabCategories.find(c => c.code === arrayCategory[i]);

      // Check if category has found some category then push it to "cats" array.
      cats = typeof cat !== "undefined" ? [...cats, cat] : [];
    }
  }

  return (
    <Frag>
      <Row style={rowBasic}>
        <Col span={24}>
          <Row style={secondaryRow}>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Campaign Name</Text>
              </Row>
              <Row>
                <Text style={{ overflowWrap: "anywhere" }} strong>
                  {campaignName ? campaignName : "None Added"}
                </Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Description</Text>
              </Row>
              <Row>
                <Text strong>{description ? description : "None Added"}</Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Type</Text>
              </Row>
              <Row>
                <Text strong>
                  {type === 1
                    ? "Display"
                    : type === 6
                    ? "Paid Search"
                    : "None Added"}
                </Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Advertiser</Text>
              </Row>
              <Row>
                <Text strong>{name ? name : "None Added"}</Text>
              </Row>
            </Col>
          </Row>
          <Divider style={divider} />
          <Row style={secondaryRow}>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Start Date</Text>
              </Row>
              <Row>
                <Text strong>
                  {startDate ? moment(startDate).format("MM/DD/YY") : "ASAP"}
                </Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">End Date</Text>
              </Row>
              <Row>
                <Text strong>
                  {endDate
                    ? moment(endDate).format("MM/DD/YY")
                    : "Run Continuously"}
                </Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Categories</Text>
              </Row>
              {cats.length ? (
                cats.map(cat => (
                  <Row style={textRow} key={cat.code}>
                    <Text strong>{cat.category}</Text>
                  </Row>
                ))
              ) : (
                <Text strong>No category selected</Text>
              )}
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Campaign for Regulated Product</Text>
              </Row>
              <Row>
                <Text strong>{isRegulated ? "Yes" : "No"}</Text>
              </Row>
            </Col>
          </Row>
          <Divider style={divider} />
          <Row style={secondaryRow}>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Budget</Text>
              </Row>
              <Row>
                <Text strong>{budget ? "$ " + budget : "$ 0"}</Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">CPM</Text>
              </Row>
              <Row>
                <Text strong>{cpm ? cpm : "0"}</Text>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <Row style={textRow}>
                <Text type="secondary">Impressions Goal</Text>
              </Row>
              <Row>
                <Text strong>{budget && cpm ? impressionGoal : "0"}</Text>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Frag>
  );
};

export default ReviewBasics;
