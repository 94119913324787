import { Button, Checkbox, Col, Row, message } from "antd";
import React, { useState } from "react";

export const CampaignOrderQueueLaunchManually = ({
  name,
  handleLaunchCampaignOrderManually,
  stateLaw,
  scaleBudget,
  status,
}) => {
  const [
    financialResponsibilityChecked,
    setFinancialResponsibilityChecked,
  ] = useState(false);

  const [campaignNameMatchesChecked, setCampaignNameMatchesChecked] = useState(
    false
  );
  const [
    isCampaignMetAllrequirements,
    setIsCampaignMetAllrequirements,
  ] = useState(false);

  const [isCampaignLaunch, setIsCampaignLaunch] = useState(false);

  const handleSubmit = async () => {
    try {
      message.loading("Submitting Campaign Order...", 0);
      const result = await handleLaunchCampaignOrderManually({
        stateLaw,
        scaleBudget,
      });
      if (result) {
        message.destroy();
        message.success("Campaign Order Submitted!", 2);
      }
    } catch (error) {
      console.log(error);
      message.destroy();
      message.error("Error submitting Campaign Order", 2);
    }
  };

  return (
    <>
      {status === "APPROVED" ? (
        <Row>
          <Col span={1}>
            <Checkbox
              defaultChecked={financialResponsibilityChecked}
              onChange={event =>
                setFinancialResponsibilityChecked(event.target.checked)
              }
            />
          </Col>
          <Col span={23}>
            Has this advertiser met all financial responsibilities required to
            launch this campaign ?
          </Col>
          <Col span={1}>
            <Checkbox
              disabled={!financialResponsibilityChecked}
              onChange={event =>
                setCampaignNameMatchesChecked(event.target.checked)
              }
            />
          </Col>
          <Col span={23}>
            This campaign has been launched and the name given to the DSP
            matches "{name ? name : "campaign name is missing"}" exactly.
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: "20px", marginBottom: "40px" }}>
            <Col span={24}>
              <Checkbox
                onChange={event => {
                  setIsCampaignMetAllrequirements(event.target.checked);
                }}
              >
                Have all the requested changes met the requirements?
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                onChange={event => {
                  setIsCampaignLaunch(event.target.checked);
                }}
              >
                Have all the changes made to this campaign already been deployed
                to the DSP?
              </Checkbox>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            disabled={
              (!financialResponsibilityChecked ||
                !campaignNameMatchesChecked) &&
              (!isCampaignMetAllrequirements || !isCampaignLaunch)
            }
            onClick={handleSubmit}
          >
            Campaign has been {status === "APPROVED" ? "launched" : "updated"}
          </Button>
        </Col>
      </Row>
    </>
  );
};
