import gql from "graphql-tag";

// TODO: Remove this mutation once the new updateCampaignOrder mutation is fully ready and tested
// This payload is not configurable
const UPDATE_CAMPAIGN_ORDER = gql`
  mutation updateCampaignOrder(
    $id: ID!
    $status: CampaignStatus!
    $campaignName: String!
    $description: String
    $startDate: DateTime
    $endDate: DateTime
    $devices: [DeviceType!]!
    $objectiveNewCustomers: Int
    $objectiveExistingCustomers: Int
    $retargetDayCap: Int
    $retargetTotalCap: Int
    $isRegulated: Boolean
    $kpi: KPI!
    $kpiGoal: Float
    $budget: Float
    $geoTargets: [GeoTargetWhereUniqueInput!]
    $attributionTargets: [GeoTargetWhereUniqueInput!]
    $previousAdUnits: [AdUnitWhereUniqueInput!]
    $adUnits: [AdUnitCreateWithoutCampaignOrderInput!]
    $brands: [BrandWhereUniqueInput!]
    $language: [Language!]
    $category: [String!]
    $age: [String!]
    $income: [String!]
    $targetAudiences: AudienceUpdateManyInput
    $tags: [String!]
    $advertiser: ID!
    $attributionWindow: Int
    $type: CampaignType
    $transactionTarget: String
    $transactionsConfig: TransactionsConfigUpdateOneWithoutCampaignOrdersInput
    $cpm: Float
    $targetingCriteria: String
    $awarenessCampaign: Boolean
    $signUpCampaign: Boolean
    $signUpConfig: SignUpConfigUpdateOneWithoutCampaignOrdersInput
    $pageViewConfig: PageViewConfigUpdateOneWithoutCampaignOrdersInput
    $requestorId: String
    $region: [String!]
  ) {
    updateCampaignOrder(
      data: {
        status: $status
        name: $campaignName
        description: $description
        startDate: $startDate
        endDate: $endDate
        devices: { set: $devices }
        objectiveNewCustomers: $objectiveNewCustomers
        objectiveExistingCustomers: $objectiveExistingCustomers
        retargetDayCap: $retargetDayCap
        retargetTotalCap: $retargetTotalCap
        kpi: $kpi
        attributionWindow: $attributionWindow
        kpiGoal: $kpiGoal
        budget: $budget
        adUnits: { delete: $previousAdUnits, create: $adUnits }
        geoTargets: { connect: $geoTargets }
        attributionTargets: { connect: $attributionTargets }
        brands: { connect: $brands }
        language: { set: $language }
        category: { set: $category }
        age: { set: $age }
        income: { set: $income }
        targetAudiences: $targetAudiences
        tags: { set: $tags }
        isRegulated: $isRegulated
        advertiser: { connect: { id: $advertiser } }
        type: $type
        transactionTarget: $transactionTarget
        transactionsConfig: $transactionsConfig
        cpm: $cpm
        targetingCriteria: $targetingCriteria
        awarenessCampaign: $awarenessCampaign
        signUpCampaign: $signUpCampaign
        signUpConfig: $signUpConfig
        pageViewConfig: $pageViewConfig
        requestorId: $requestorId
        region: { set: $region }
      }
      where: { id: $id }
    ) {
      id
      status
      name
      description
      attributionWindow
      transactionTarget
      type
      geoTargets {
        id
        name
        circles {
          id
          radius
          coordinate {
            lat
            lng
          }
          location {
            id
            name
            hasNotes
            notes
          }
        }
        polygons {
          geometry
        }
      }
      attributionTargets {
        id
        name
        circles {
          id
          radius
          coordinate {
            lat
            lng
          }
        }
        polygons {
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
      orgs {
        id
        name
      }
      brands {
        id
        name
      }
      language
      category
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      kpi
      kpiGoal
      startDate
      isRegulated
      endDate
      budget
      campaigns {
        demandSidePlatform
      }
      advertiser {
        id
        name
      }
      cpm
      targetingCriteria
      awarenessCampaign
      signUpCampaign
      signUpConfig {
        id
        appId
      }
      pageViewConfig {
        id
        appId
        locationId
      }
      requestorId
      region
      reviewerId
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER;
