import React from "react";
import moment from "moment";
import { Row, Col, Input, Select, TimePicker } from "antd";

import CopyableTextField from "../../../../../../shared/CopyableTextField";

const GeneratedTagComponent = ({ generatedTag, vast }) => {
  return (
    <React.Fragment>
      <CopyableTextField value={generatedTag} />
      <br />
      <h4>Preview</h4>
      {vast && (
        <iframe
          src={`${vast.ads[0].creatives[0].mediaFiles[0].fileURL}`}
          style={{
            width: "100%",
            height: "250px",
            border: "0",
            borderRadius: "4px",
            overflow: "hidden",
          }}
          title={`${vast.ads[0].title}`}
        ></iframe>
      )}
      <br />
      <br />
    </React.Fragment>
  );
};

const TagComponent = ({
  adId,
  setAdId,
  adSystem,
  setAdSystem,
  adTitle,
  setAdTitle,
  duration,
  setDuration,
  trackingEvent,
  setTrackingEvent,
  clickThrough,
  setClickThrough,
  mediaFile,
  setMediaFile,
  onKeyDown,
  generatedTag,
  vast,
}) => {
  const { height, width, bitrate, type, delivery, url } = mediaFile;
  return generatedTag ? (
    <GeneratedTagComponent generatedTag={generatedTag} vast={vast} />
  ) : (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Col span={24}>
          <h3 style={{ marginBottom: "0" }}>Ad</h3>
          <p style={{ marginBottom: "5px" }}>
            Top-level element, wraps each ad in the response
          </p>
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4 style={{ marginBottom: "0" }}>Ad ID</h4>
          <p style={{ marginBottom: "5px" }}>Required identifier</p>
          <Input
            placeholder="Ad ID"
            value={adId}
            onChange={({ target }) => setAdId(target.value)}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4 style={{ marginBottom: "0" }}>Ad System</h4>
          <p style={{ marginBottom: "5px" }}>Indicates source ad server</p>
          <Input
            placeholder="Ad System"
            value={adSystem}
            onChange={({ target }) => setAdSystem(target.value)}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4 style={{ marginBottom: "0" }}>Ad Title</h4>
          <p style={{ marginBottom: "5px" }}>Common name of ad</p>
          <Input
            placeholder="Ad Title"
            value={adTitle}
            onChange={({ target }) => setAdTitle(target.value)}
            onKeyDown={onKeyDown}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Col span={24}>
          <h3 style={{ marginBottom: "0" }}>Creatives</h3>
          <p style={{ marginBottom: "5px" }}>
            Container for one Creative Linear element
          </p>
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4 style={{ marginBottom: "0" }}>Duration</h4>
          <p style={{ marginBottom: "5px" }}>
            Duration, in standard time format hh:mm:ss{" "}
          </p>
          <TimePicker
            placeholder="Duration"
            defaultValue={moment("00:00:30", "HH:mm:ss")}
            showNow={false}
            allowClear={false}
            onChange={(_, timeString) => setDuration(timeString)}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4 style={{ marginBottom: "0" }}>Tracking Event</h4>
          <p style={{ marginBottom: "5px" }}>
            URL to track various events during playback
          </p>
          <Input
            placeholder="Tracking Event"
            value={trackingEvent}
            onChange={({ target }) => setTrackingEvent(target.value)}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={24} style={{ padding: "5px 10px" }}>
          <h4>Click Through</h4>
          <Row type="flex" justify="space-between">
            <Col span={24} style={{ padding: "5px 10px" }}>
              <h4 style={{ marginBottom: "0" }}>ID</h4>
              <p style={{ marginBottom: "5px" }}>Optional identifier</p>
              <Input
                placeholder="ID"
                value={clickThrough.id}
                onChange={({ target }) =>
                  setClickThrough({ ...clickThrough, id: target.value })
                }
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col span={24} style={{ padding: "5px 10px" }}>
              <h4 style={{ marginBottom: "0" }}>URL</h4>
              <p style={{ marginBottom: "5px" }}>
                URL to open as destination page when user clicks on the video
              </p>
              <Input
                placeholder="URL"
                value={clickThrough.data}
                onChange={({ target }) =>
                  setClickThrough({ ...clickThrough, data: target.value })
                }
                onKeyDown={onKeyDown}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col span={24} style={{ padding: "5px 10px" }}>
        <h4>Media File</h4>
        <Row type="flex" justify="space-between">
          <Col style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>Height</h4>
            <p style={{ marginBottom: "5px" }}>Pixel dimensions of video</p>
            <Input
              type="number"
              placeholder="Height"
              value={height}
              onChange={({ target }) =>
                setMediaFile({ ...mediaFile, height: target.value })
              }
              onKeyDown={onKeyDown}
            />
          </Col>
          <Col style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>Width</h4>
            <p style={{ marginBottom: "5px" }}>Pixel dimensions of video</p>
            <Input
              type="number"
              placeholder="Width"
              value={width}
              onChange={({ target }) =>
                setMediaFile({ ...mediaFile, width: target.value })
              }
              onKeyDown={onKeyDown}
            />
          </Col>
          <Col style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>Bitrate</h4>
            <p style={{ marginBottom: "5px" }}>Bitrate of video in Kbps</p>
            <Input
              type="number"
              placeholder="Bitrate"
              value={bitrate}
              onChange={({ target }) =>
                setMediaFile({ ...mediaFile, bitrate: target.value })
              }
              onKeyDown={onKeyDown}
            />
          </Col>
          <Col style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>Type</h4>
            <p style={{ marginBottom: "5px" }}>Type of video</p>
            <Select
              placeholder="Type"
              defaultValue={type}
              onChange={value =>
                setMediaFile({ ...mediaFile, delivery: value })
              }
              style={{ width: "183px" }}
              onKeyDown={onKeyDown}
            >
              <Select.Option key="video/mp4" value="video/mp4">
                MPEG-4 (.mp4)
              </Select.Option>
              <Select.Option key="video/x-flv" value="video/x-flv">
                Flash (.flv)
              </Select.Option>
              <Select.Option key="video/3gpp" value="video/3gpp">
                3GP (.3gp)
              </Select.Option>
              <Select.Option key="video/quicktime" value="video/quicktime">
                QuickTime (.mov)
              </Select.Option>
              <Select.Option key="video/x-msvideo" value="video/x-msvideo">
                A/V Interleave (.avi)
              </Select.Option>
              <Select.Option key="	video/x-ms-wmv" value="	video/x-ms-wmv">
                Windows Media (.wmv)
              </Select.Option>
            </Select>
          </Col>
          <Col style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>Delivery</h4>
            <p style={{ marginBottom: "5px" }}>Method of delivery of ad</p>
            <Select
              placeholder="Delivery"
              defaultValue={delivery}
              onChange={value =>
                setMediaFile({ ...mediaFile, delivery: value })
              }
              style={{ width: "183px" }}
              onKeyDown={onKeyDown}
            >
              <Select.Option key="progressive" value="progressive">
                progressive
              </Select.Option>
              <Select.Option key="streaming" value="streaming">
                streaming
              </Select.Option>
            </Select>
          </Col>
          <Col span={24} style={{ padding: "5px 10px" }}>
            <h4 style={{ marginBottom: "0" }}>File/URL</h4>
            <p style={{ marginBottom: "5px" }}>Location of the file</p>
            <Input
              placeholder="File/URL"
              value={url}
              onChange={({ target }) =>
                setMediaFile({ ...mediaFile, url: target.value })
              }
              onKeyDown={onKeyDown}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TagComponent;
