import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { PrimaryTableRowText } from "../../../shared/globalStyling/styledText";

export const deriveStatus = (
  startDate,
  endDate,
  attributionWindow,
  statusCampaign,
  checkStatus
) => {
  const currentDateTime = moment()
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  startDate = startDate
    ? moment(startDate, moment.ISO_8601)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    : null;
  endDate = endDate
    ? moment(endDate, moment.ISO_8601)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0)
    : null;
  if (currentDateTime.isBetween(startDate, endDate)) {
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    return "LIVE";
  }
  if (currentDateTime.isAfter(endDate)) {
    const attributionWindowEndDate = moment(endDate).add(
      attributionWindow,
      "days"
    );
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    if (currentDateTime.isAfter(attributionWindowEndDate)) return "FINISHED";
    return "ATTRIBUTION";
  }
  if (currentDateTime.isBefore(startDate)) {
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    return "QUEUED";
  }
  if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
  if (statusCampaign === "PENDING" || checkStatus === "PENDING")
    return "PENDING";
  if (statusCampaign === "PAUSED" || checkStatus === "PAUSED") return "PAUSED";
};

const StatusText = ({
  startDate,
  endDate,
  attributionWindow,
  statusCampaign,
  checkStatus,
}) => {
  const statusText = useMemo(
    () =>
      deriveStatus(
        startDate,
        endDate,
        attributionWindow,
        statusCampaign,
        checkStatus
      ),
    [startDate, endDate, attributionWindow, statusCampaign, checkStatus]
  );

  return <PrimaryTableRowText text={statusText} />; // Display the status as plain text
};

StatusText.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  attributionWindow: PropTypes.string || PropTypes.number,
  statusCampaign: PropTypes.string.isRequired,
};

export default StatusText;
