import React from "react";
import { Row, Tag, Popover, Col } from "antd";

const tagStatusConfig = {
  SERVING: {
    backgroundColor: "#00DE5133",
    color: "#00DE51",
    text: "Ongoing",
    width: "70px",
  },
  ACTIVE: {
    backgroundColor: "#00DE5133",
    color: "#00DE51",
    text: "Active",
    width: "70px",
  },
  ENDED: {
    backgroundColor: "#b9b6b633",
    color: "#b9b6b6",
    text: "Finished",
    width: "78px",
  },
  INACTIVE: {
    backgroundColor: "#b9b6b633",
    color: "#b9b6b6",
    text: "Inactive",
    width: "78px",
  },
  PENDING: {
    backgroundColor: "#faad1433",
    color: "#faad14",
    text: "Pending",
    width: "70px",
  },
  SUSPENDED: {
    backgroundColor: "orange",
    color: "white",
    text: "Paused",
    width: "70px",
  },
  NO_ACCESS: {
    backgroundColor: "orange",
    color: "white",
    text: "No Access",
    width: "90px",
  },
  UNKNOWN: {
    backgroundColor: "lightgray",
    color: "#9cada0",
    text: "Unknown",
    width: "70px",
  },
  UNSPECIFIED: {
    backgroundColor: "lightgray",
    color: "#9cada0",
    text: "Unspecified",
    width: "100px",
  },
};

const noAccessConfig = {
  BING: {
    text: "Bing",
  },
  GOOGLE_ADS: {
    text: "Google Ads",
  },
  GOOGLE_ANALYTICS: {
    text: "Google Analytics",
  },
};

const StatusTag = ({ status, noAccessChannels }) => {
  const { backgroundColor, width, color, text } = tagStatusConfig[status];
  const popupMessage = () => {
    return (
      <Row>
        {noAccessChannels && noAccessChannels.length > 0
          ? noAccessChannels.map(channel => {
              return (
                <Col xs={24} key={channel}>
                  {noAccessConfig[channel].text}
                </Col>
              );
            })
          : "--"}
      </Row>
    );
  };

  return (
    <Popover content={status === "NO_ACCESS" ? popupMessage() : null}>
      <Tag
        style={{
          width,
          backgroundColor,
          color,
          border: 0,
          borderRadius: "25px",
        }}
      >
        <Row justify="space-around" type="flex" align="middle">
          <div
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50px",
              backgroundColor: color,
            }}
          />
          {text}
        </Row>
      </Tag>
    </Popover>
  );
};

export default StatusTag;
