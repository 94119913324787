import { isEmpty } from "lodash";

export const featurePermissions = (role, feature) => {
  const permissionMap = { READ: false, WRITE: false };
  if (isEmpty(role) || isEmpty(role.roleItems)) return permissionMap;
  const roleItem = role.roleItems.find(
    roleItem => roleItem.feature === feature
  );
  if (roleItem && roleItem.actions)
    roleItem.actions.forEach(action => (permissionMap[action] = true));
  return permissionMap;
};
