import React from "react";
import { Chart, Axis, Tooltip, Geom, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { isEmpty } from "lodash";
import { chartspallete } from "../../../../../../../../../core/utils/constants/constants";
import { getScale } from "../../../../../../../../../core/utils/campaigns";
import moment from "moment";

const style = chartspallete;

const SingleAxisLineChart = props => {
  const { filtered, data, yAxisLeft, height } = props.state;
  const ds = new DataSet();
  const dv = ds.createView().source(data);
  dv.transform({
    type: "fold",
    fields: filtered,
    key: "type",
    value: "value",
  });

  const scale = getScale(data);

  if (isEmpty(data)) {
    return <div />;
  }

  return (
    <Chart
      height={height || 400}
      width={500}
      forceFit
      data={dv}
      scale={scale}
      padding="auto"
    >
      <Legend
        custom
        allowAllCanceled
        postion="top"
        items={[
          {
            value: yAxisLeft,
            marker: {
              symbol: "circle",
              fill: style.blue,
              radius: 5,
              lineWidth: 3,
            },
          },
        ]}
      />
      <Axis
        name="xAxis"
        label={{
          formatter: xAxis =>
            isNaN(xAxis) ? xAxis : moment(Number(xAxis)).format("M/D/YY"),
        }}
      />
      <Axis name="value" position={"left"} />
      <Tooltip title={true} />

      <Geom
        shape={"smooth"}
        type="line"
        position={`xAxis*${yAxisLeft}`}
        color={style.blue}
      />

      <Geom
        type="point"
        position={`xAxis*${yAxisLeft}`}
        size={4}
        shape={"circle"}
        color={style.green}
        style={{
          stroke: "#fff",
          lineWidth: 1,
        }}
      />
    </Chart>
  );
};

export default SingleAxisLineChart;
