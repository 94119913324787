import React from "react";
import { Chart, Axis, Tooltip, Geom } from "bizcharts";
import { isEmpty } from "lodash";
import {
  getLeftRightScale,
  formatWholeNumber,
  formatNumber4SigFig,
} from "../../../../../../../../../core/utils/campaigns";

const SingleAxisBarChart = ({ data, leftY }) => {
  const axisData = data.map(orgin => {
    const keyValue = Object.entries(orgin);
    const left =
      keyValue[keyValue.findIndex(pair => pair[0] === leftY.toLowerCase())] ||
      [];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "xAxis")] || [];
    return {
      left: left[1],
      xAxis: x[1],
    };
  });
  const scale = getLeftRightScale(data, leftY.toLowerCase(), null);
  const gridLeft = {
    lineStyle: {
      stroke: "#e8e8e8",
      lineDash: [1, 0],
    },
  };
  const leftLabel = {
    textStyle: {
      fill: "#7dafff",
    },
  };

  if (isEmpty(data)) {
    return <div />;
  }

  return (
    <Chart
      height={400}
      width={500}
      forceFit
      data={axisData}
      scale={scale}
      padding="auto"
      onTooltipChange={ev => {
        let items = ev.items;
        const origin = items[0];
        items.splice(0);
        items.push({
          name: origin.name,
          size: origin.size,
          showMarker: origin.showMarker,
          title: origin.point._origin.xAxis,
          marker: origin.marker,
          color: origin.color,
          value:
            origin.name === "Click through ratio (%)"
              ? `${formatNumber4SigFig(origin.value)}%`
              : formatWholeNumber(origin.value),
          x: origin.x,
          y: origin.y,
          point: origin.point,
        });
      }}
    >
      <Axis
        name="xAxis"
        label={{
          formatter: xAxis => (isEmpty(xAxis) ? "UNKNOWN" : xAxis),
        }}
      />
      <Axis name="left" grid={gridLeft} label={leftLabel} />

      <Tooltip title={true} />

      <Geom type="interval" position={`xAxis*left`} color="#b2d5ff" />
    </Chart>
  );
};

export default SingleAxisBarChart;
