//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Timeline, Row, Col, Tooltip } from "antd";
import { getMobileOperatingSystem } from "./utils";
import { isEmpty } from "lodash";

import {
  DesktopOutlined,
  TabletOutlined,
  MobileOutlined,
} from "@ant-design/icons";

const { Item } = Timeline;

const ClicksSubTable = ({ clicks }) => {
  const [clicksData, setClicksData] = useState([]);

  useEffect(() => {
    const sortedClicks =
      !isEmpty(clicks) &&
      clicks.sort((a, b) => {
        if (
          moment(a.CLICK_TIME, moment.DATETIME_LOCAL_MS).isBefore(
            new Date(b.CLICK_TIME)
          )
        )
          return -1;
        return 1;
      });

    setClicksData(sortedClicks);
  }, [clicks]);

  const makeTimeLine = clicksData.map((click, i) => {
    const { CLICK_TIME, CLICK_USERAGENT, ID_CLICK } = click;

    let OS = "";

    OS = getMobileOperatingSystem(CLICK_USERAGENT);

    let icon = () => {
      if (
        OS === "Unknown" ||
        OS === "Chromebook Computer" ||
        OS === "Windows Computer" ||
        OS === "Computer"
      ) {
        return <DesktopOutlined />;
      }

      if (OS === "iPhone" || OS === "Android" || OS === "Windows Phone") {
        return <MobileOutlined />;
      }

      if (OS === "iPad") {
        return <TabletOutlined />;
      }
    };

    return (
      <Item key={i}>
        <Row>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Clicked at - ${moment(
                CLICK_TIME,
                moment.DATETIME_LOCAL_MS
              ).format("MM/DD/YY HH:mm:ss.SSSZ")}`}
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {icon()}
              <Tooltip title={CLICK_USERAGENT ? CLICK_USERAGENT : ""}>
                {`  ${OS}`}
              </Tooltip>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <Tooltip title={ID_CLICK ? ID_CLICK : ""}>Click Id</Tooltip>
              <br />

              {`${ID_CLICK}`}
              <br />
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Timeline mode={"left"} reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ClicksSubTable;
