import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  message,
  Table,
  Modal,
  Row,
  Col,
  Progress,
  Tooltip,
  Button,
  Typography,
} from "antd";
import { formatWholeNumber } from "../../../../core/utils/campaigns";
import {
  ReloadOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../shared/globalStyling/styledText";

const Frag = React.Fragment;
const { Title, Text } = Typography;
const { confirm } = Modal;

const GeoTargetsBaseTable = ({
  count,
  onPage,
  geoTargets,
  selectTableRow,
  selectedGeoTargets,
  history,
  orderByFilter,
  whereFilter,
  hasInProgressShape,
  loadingGeoTargets,
  checkSwitchMode,
  pageSizeNumber,
  updateSelectedRowStartEndDate,
  isDemo,
}) => {
  const expandedRowRender = geotargets => {
    const cols = [
      {
        title: <TableColumnTitle text={"Date Range"} />,
        dataIndex: "start",
        key: "daterange",
        width: "20%",
        render: (text, record) => {
          if (!text) {
            return <div />;
          }
          const currentDate = moment();
          const startDate = moment(record.start);
          const endDate = moment(record.end);
          let flightProgress = 0;
          if (currentDate > endDate) flightProgress = 100;
          else if (currentDate < endDate && currentDate > startDate)
            flightProgress =
              (currentDate.diff(startDate, "minutes") /
                endDate.diff(startDate, "minutes")) *
              100;
          return (
            <Frag>
              <Row type="flex" justify="start">
                {record.start && (
                  <Col>
                    <FlightDateText text={startDate.format("MM/DD/YY")} />
                  </Col>
                )}
                <Col>
                  <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
                </Col>
                {record.end && (
                  <Col>
                    <FlightDateText text={endDate.format("MM/DD/YY")} />
                  </Col>
                )}
              </Row>
              <Row type="flex" justify="start">
                <Progress
                  id="main-campaign-table-progress"
                  type={"line"}
                  strokeWidth={3}
                  percent={flightProgress ? flightProgress : 0}
                  showInfo={false}
                  strokeColor={"#636363"}
                />
              </Row>
            </Frag>
          );
        },
      },
      {
        title: <TableColumnTitle text={"Shapes"} />,
        dataIndex: "end",
        key: "shapes",
        width: "10%",
        render: (text, record) => {
          const shapeCount = record.polygons.length + record.circles.length;
          return <PrimaryTableRowText text={formatWholeNumber(shapeCount)} />;
        },
      },
      {
        title: <TableColumnTitle text={"Devices"} />,
        dataIndex: "uniqueDevices",
        key: "uniqueDevices",
        width: "15%",
        render: (text, record) =>
          record.vwiUpdatedOnSpot ? (
            <PrimaryTableRowText text={formatWholeNumber(text)} />
          ) : (
            <PrimaryTableRowText text={0} />
          ),
      },

      {
        title: <TableColumnTitle text={"Observations"} />,
        dataIndex: "observations",
        key: "observations",
        width: "15%",
        render: (text, record) =>
          record.vwiUpdatedOnSpot ? (
            <PrimaryTableRowText text={formatWholeNumber(text)} />
          ) : (
            <PrimaryTableRowText text={0} />
          ),
      },
    ];

    return (
      <Row>
        <Col span={24}>
          <Title level={4}>{geotargets.name}</Title>
          <Text>{geotargets.description}</Text>
          {geotargets.location && (
            <Text>Location: {geotargets.location.name}</Text>
          )}
          <br />
          {geotargets.location && (
            <Text disabled>
              Lat: {geotargets.location.lat} - Lng: {geotargets.location.lng}
            </Text>
          )}
          <Table
            title={() => "List of flights"}
            bordered={true}
            style={{ backgroundColor: "#dcecfc" }}
            columns={cols}
            // rowSelection={rowChildSelection}
            dataSource={geotargets.flights.map(geo => {
              return { ...geo, key: geo.id };
            })}
            pagination={false}
          />
        </Col>
      </Row>
    );
  };

  const showDataLossConfirm = (record, selected, multiple) => {
    confirm({
      title: "There are in progress shapes that have not been saved. Continue?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        selectTableRow(record, selected, multiple);
      },
      onCancel() {
        return;
      },
    });
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (hasInProgressShape && checkSwitchMode === true)
        return showDataLossConfirm(record, selected);
      return selectTableRow(record, selected);
    },
    selectedRowKeys: selectedGeoTargets.map(row => row.id),
    columnWidth: `${String(selectedGeoTargets.length).length * 2}`,
    // columnTitle: selectedGeoTargets.length,
    hideDefaultSelections: true,
    selections: [
      {
        text: "Select current page",
        onSelect: changableRowKeys => {
          selectTableRow(geoTargets, true, true);
        },
      },
      {
        text: "De-select all",
        onSelect: changableRowKeys => {
          if (hasInProgressShape)
            return showDataLossConfirm(selectedGeoTargets, false, true);
          return selectTableRow(selectedGeoTargets, false, true);
        },
      },
    ],
  };

  const columns = [
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "expander",
      key: "expander",
      width: "5%",
      render: text => {
        return <div />;
      },
    },
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: text => {
        if (!text) {
          return <div />;
        }
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "start",
      key: "daterange",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row>
              <Col span={14}>
                <Row type="flex" justify="start">
                  {record.start && (
                    <Col>
                      <FlightDateText text={startDate.format("MM/DD/YY")} />
                    </Col>
                  )}
                  <Col>
                    <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
                  </Col>
                  {record.end && (
                    <Col>
                      <FlightDateText text={endDate.format("MM/DD/YY")} />
                    </Col>
                  )}
                </Row>
                <Row type="flex" justify="start">
                  <Progress
                    id="main-campaign-table-progress"
                    type={"line"}
                    strokeWidth={3}
                    percent={flightProgress ? flightProgress : 0}
                    showInfo={false}
                    strokeColor={"#636363"}
                  />
                </Row>
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Shapes"} />,
      dataIndex: "end",
      key: "shapes",
      width: "10%",
      render: (text, record) => {
        const shapeCount = record.polygons.length + record.circles.length;
        return <PrimaryTableRowText text={formatWholeNumber(shapeCount)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Devices"} />,
      dataIndex: "uniqueDevices",
      key: "uniqueDevices",
      width: "15%",
      render: (text, record) => {
        const totalUniqueDevicesPerFlights = record.flights.reduce((acc, a) => {
          return acc + a.uniqueDevices;
        }, 0);

        return (
          <PrimaryTableRowText
            text={formatWholeNumber(totalUniqueDevicesPerFlights)}
          />
        );
      },
    },

    {
      title: <TableColumnTitle text={"Observations"} />,
      dataIndex: "observations",
      key: "observations",
      width: "15%",
      render: (text, record) => {
        const totalObservationsPerFlights = record.flights.reduce((acc, a) => {
          return acc + a.observations;
        }, 0);

        return (
          <PrimaryTableRowText
            text={formatWholeNumber(totalObservationsPerFlights)}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Flights"} />,
      dataIndex: "flight",
      key: "fligths",
      width: "10%",
      render: (text, record) => {
        const shapeCount = record.flights ? record.flights.length : 0;
        return <PrimaryTableRowText text={formatWholeNumber(shapeCount)} />;
      },
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "dateRange",
      key: "date",
      width: "10%",
      render: (text, record) => {
        return (
          <Frag>
            <Row>
              <Col>
                <Tooltip title={isDemo ? "" : "Update date range"}>
                  <Button
                    disabled={isDemo}
                    size={"small"}
                    onClick={async () => {
                      message.loading(`Processing update..`, 0);
                      const response = await updateSelectedRowStartEndDate(
                        record
                      );

                      if (response) {
                        message.destroy();
                        message.success(`update success!`);
                      } else {
                        message.destroy();
                        message.error(`Could not update`);
                      }
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Frag>
        );
      },
    },
  ];

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <Table
        expandedRowRender={expandedRowRender}
        expandIconColumnIndex={1}
        rowSelection={rowSelection}
        expandIcon={({ expanded, onExpand, record }) =>
          record.flights.length > 0 ? (
            expanded ? (
              <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
            ) : (
              <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
            )
          ) : (
            ""
          )
        }
        expandIconAsCell={false}
        indentSize={20}
        dataSource={geoTargets.map(geo => {
          return {
            ...geo,
            key: geo.id,
          };
        })}
        columns={columns}
        scroll={{ x: 700 }}
        pagination={{
          total: count,
          pageSize: pageSizeNumber,
          current: onPage,
          showSizeChanger: true,
          onShowSizeChange: (current, pageSize) => {
            history.push(
              `/segments/geo-targets/new/${current}/${orderByFilter}/${whereFilter}?pageSize=${pageSize}`
            );
          },
        }}
        onChange={pagination => {
          const { current, pageSize } = pagination;
          history.push(
            `/segments/geo-targets/new/${current}/${orderByFilter}/${whereFilter}?pageSize=${pageSize}`
          );
        }}
        bordered={false}
        loading={loadingGeoTargets}
      />
    </div>
  );
};

export default withRouter(GeoTargetsBaseTable);
