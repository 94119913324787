import React from "react";
import { Row, Col } from "antd";
import { isEmpty, upperFirst } from "lodash";
import { Chart, Geom, Axis, Label, Coord } from "bizcharts";
import DataSet from "@antv/data-set";
import { PageHeaderLayout } from "../../../../shared/layout";
import OverlaySpinner from "../../../../shared/OverlaySpinner";

const Donut = ({ height, width, key, data }) => {
  const formattedData = Object.entries(data).map(([key, value]) => {
    return { key, value };
  });

  const ds = new DataSet();

  const dv = ds
    .createView()
    .source(formattedData)
    .transform({
      type: "percent",
      field: "value",
      dimension: "key",
      as: "percent",
    });

  const cols = {
    percent: {
      formatter: val => {
        val = (val * 100).toFixed(1) + "%";
        return val;
      },
    },
  };

  return (
    <Chart height={height} data={dv} scale={cols} renderer="svg" forceFit>
      <Coord type="theta" radius={0.75} />
      <Axis name="percent" />
      <Geom type="intervalStack" position="percent" color="key">
        <Label
          content="percent"
          formatter={(val, item) =>
            `${item.point.key.replace(/,\d\d\d/gi, "K")} \n ${val}`
          }
        />
      </Geom>
    </Chart>
  );
};

const Demographics = ({ data, loading }) => {
  return (
    (!isEmpty(data) || loading) && (
      <Row>
        <PageHeaderLayout title="Demographics" />
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #e8e8e8",
            padding: "20px",
            minHeight: "664px",
          }}
        >
          {loading ? (
            <Col>
              <OverlaySpinner />
            </Col>
          ) : (
            <React.Fragment>
              {Object.entries(data).map(([key, value]) => {
                return (
                  <Col span={7} key={key} style={{ minWidth: "400px" }}>
                    <Row
                      style={{
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      {key
                        .split("_")
                        .map(str => upperFirst(str))
                        .join(" ")}
                    </Row>
                    <Donut height={250} data={value} />
                  </Col>
                );
              })}
            </React.Fragment>
          )}
        </Row>
      </Row>
    )
  );
};

export { Demographics };
