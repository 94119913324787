import React, { useState, useEffect, useMemo } from "react";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import { defaultAttributionWindow } from "../../../utils/constants/constants";

const DisplaySummaryController = ({
  children,
  refetch,
  campaignOrdersData,
  showAnalytics,
  orgSummaries,
  deviceClicks,
  advertiserIds,
  orgId,
  selectedCOIds,
  setSelectedCOIds,
  loadingDeviceClicks,
  reprocessOrg,
  reprocessOrgLoading,
  ...props
}) => {
  const dataConfig = props.currentUser.role.org.dataConfig;
  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const [totalOrgSummaries, setTotalOrgSummaries] = useState({
    mediaSpendImpressionsServed: 0,
    mediaSpendClicks: 0,
    mediaSpendCTR: 0,
    goal: 0,
    roas: 0,
    budget: 0,
    impressions: 0,
    inAttributionCampaigns: 0,
    totalCampaigns: 0,
    liveCampaigns: 0,
    activeCampaigns: 0,
    totalWalkIns: 0,
    totalOrders: 0,
    totalObservations: 0,
    mediaSpendBudgetSpent: 0,
    totalRevenue: 0,
    updatedAt: null,
  });

  const [filteredOrg, setFilteredOrg] = useState({
    id: null,
    name: null,
  });

  const searchParams = new URLSearchParams(props.location.search);
  const startDateParams = searchParams.get("startDate");
  const endDateParams = searchParams.get("endDate");

  useEffect(() => {
    if (orgSummaries.length) {
      let orgSummary = {
        mediaSpendImpressionsServed: 0,
        mediaSpendClicks: 0,
        mediaSpendCTR: 0,
        goal: 0,
        roas: 0,
        budget: 0,
        impressions: 0,
        inAttributionCampaigns: 0,
        totalCampaigns: 0,
        liveCampaigns: 0,
        activeCampaigns: 0,
        totalWalkIns: 0,
        totalOrders: 0,
        totalObservations: 0,
        mediaSpendBudgetSpent: 0,
        totalRevenue: 0,
        updatedAt: null,
      };

      if (orgSummaries && orgSummaries.length) {
        orgSummaries.forEach(obj => {
          if (!obj.orgSummary) return;

          let transactions = 0;
          let revenue = 0;

          if (
            obj.orgSummary.attributionWindowReport &&
            defaultDisplayAttributionWindow !== 30
          ) {
            const report = obj.orgSummary.attributionWindowReport.find(
              r => r.attributionWindow === defaultDisplayAttributionWindow
            );
            if (report) {
              const aggEvents = report.aggEvents;
              transactions = aggEvents.transactionsTotalCount;
              revenue = aggEvents.transactionTotalRevenue;
            }
          } else {
            transactions = obj.orgSummary.totalOrders;
            revenue = obj.orgSummary.totalRevenue;
          }
          orgSummary.mediaSpendImpressionsServed += Number(
            obj.orgSummary.mediaSpendImpressionsServed || 0
          );
          orgSummary.mediaSpendClicks += Number(
            obj.orgSummary.mediaSpendClicks || 0
          );
          orgSummary.mediaSpendCTR += Number(obj.orgSummary.mediaSpendCTR || 0);
          orgSummary.goal += Number(obj.orgSummary.goal || 0);
          orgSummary.budget += Number(obj.orgSummary.budget || 0);
          orgSummary.impressions += Number(obj.orgSummary.impressions || 0);
          orgSummary.inAttributionCampaigns += Number(
            obj.orgSummary.inAttributionCampaigns || 0
          );
          orgSummary.totalCampaigns += Number(
            obj.orgSummary.totalCampaigns || 0
          );
          orgSummary.liveCampaigns += Number(obj.orgSummary.liveCampaigns || 0);
          orgSummary.activeCampaigns += Number(
            obj.orgSummary.activeCampaigns || 0
          );
          orgSummary.totalWalkIns += Number(obj.orgSummary.totalWalkIns || 0);
          orgSummary.totalOrders += Number(transactions || 0);
          orgSummary.totalObservations += Number(
            obj.orgSummary.totalObservations || 0
          );
          orgSummary.mediaSpendBudgetSpent += Number(
            obj.orgSummary.mediaSpendBudgetSpent || 0
          );
          orgSummary.totalRevenue += Number(revenue || 0);
          orgSummary.updatedAt = obj.orgSummary.updatedAt;
          orgSummary.roas +=
            (orgSummary.totalRevenue / orgSummary.mediaSpendBudgetSpent) * 100;
        });
      }

      setTotalOrgSummaries(orgSummary);
    }
  }, [defaultDisplayAttributionWindow, orgSummaries]);

  useEffect(() => {
    if (filteredOrg.id) {
      setSelectedCOIds([]);
    }
  }, [filteredOrg, setSelectedCOIds]);

  const filteredCampaignOrders = useMemo(() => {
    if (campaignOrdersData) {
      if (filteredOrg.id) {
        const edges = campaignOrdersData.campaignOrdersConnection.edges;
        const filtered = edges.filter(edge => {
          return edge.node.orgs.filter(o => o.id === filteredOrg.id).length > 0;
        });
        return filtered.map(f => ({ ...f.node }));
      } else {
        const edges = campaignOrdersData.campaignOrdersConnection.edges;
        return edges.map(f => ({ ...f.node }));
      }
    } else {
      return [];
    }
  }, [campaignOrdersData, filteredOrg]);

  //priceVisible is customer facing - All data will have DSP costs stripped unless the logged in organization is MediaJelAdmi
  const userPermission = determineUserAccess(props.currentUser.permission);

  return React.cloneElement(children, {
    ...props,
    startDateParams,
    endDateParams,
    userPermission,
    disableRefresh: false,
    totalOrgSummaries,
    filteredOrg,
    setFilteredOrg,
    deviceClicks,
    advertiserIds,
    orgId,
    selectedCOIds,
    setSelectedCOIds,
    loadingDeviceClicks,
    campaignOrders: filteredCampaignOrders,
    reprocessOrg,
    reprocessOrgLoading,
  });
};

export default DisplaySummaryController;
