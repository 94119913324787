import React, { useState } from "react";
import { Row, Col, Card, Tooltip, Dropdown, Menu } from "antd";
import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiPercentageText,
} from "../../../../../../shared/globalStyling/styledText";

import {
  formatCurrency2SigFig,
  formatWholeNumberNaN,
  computeROASDisplay,
} from "../../../../../../../core/utils/campaigns";

import { roasFormat } from "../../../../../../../core/utils/constants/constants";

//TODO - break all styles out and store in central location
const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};

const DetailBarDynamicCol = ({
  clicks = 0,
  impressions = 0,
  transactions = 0,
  totalWalkIns = 0,
  transactionTotalRevenue = 0,
  showTransactions,
  showAttribution,
  signUpCampaign,
  signUpsData,
  summaryData,
  bySummaryRangeDate,
  budget = 0,
  signUps = 0,
  selectedCampaign,
  priceVisible,
  kpiPercentage = {
    percentages: {},
    previousValues: {},
    ranges: "",
  },
  dataConfig,
  orgCurrencyFormat,
  orgRoasFormat,
}) => {
  const [roasFormatSelected, setRoasFormatSelected] = useState(
    orgRoasFormat && orgRoasFormat ? orgRoasFormat.key : null
  );
  const mediaSpend =
    (impressions / 1000) * (selectedCampaign ? selectedCampaign.cpm : 0);
  const { percentages, previousValues, ranges } = kpiPercentage;
  return (
    <Row style={styles.container} wrap={true}>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Impressions"} />
          </div>
          <KpiSubText text={`${formatWholeNumberNaN(impressions)}`} />
          <KpiPercentageText
            text={percentages.impressions}
            prev={formatWholeNumberNaN(previousValues.impressions)}
            ranges={ranges}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Clicks"} />
          </div>
          <KpiSubText text={`${formatWholeNumberNaN(clicks)}`} />
          <KpiPercentageText
            text={percentages.clicks}
            prev={formatWholeNumberNaN(previousValues.clicks)}
            ranges={ranges}
          />
        </Card>
      </Col>
      {showTransactions && (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <Tooltip title="Multi-Touch Attribution (MTA). Using device-level data, this MTA model shows impressions have been served to device identifiers that have also made summary. This model does not take into account other factors that may have influenced the purchase such as search, newsletters or other marketing techniques. MTA should also not be confused with other models. ie: Last-Interaction or Position-based">
              <div style={styles.title}>
                <KPITitleText text={"Transactions"} />
              </div>
            </Tooltip>
            <KpiSubText text={`${formatWholeNumberNaN(transactions)}`} />
            <KpiPercentageText
              text={percentages.transactions}
              prev={formatWholeNumberNaN(previousValues.transactions)}
              ranges={ranges}
            />
          </Card>
        </Col>
      )}
      {/* {showAttribution && (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Walkins"} />
            </div>
            <KpiSubText text={`${formatWholeNumberNaN(totalWalkIns)}`} />
            <KpiPercentageText
              text={percentages.walkIns}
              prev={formatWholeNumberNaN(previousValues.walkIns)}
              ranges={ranges}
            />
          </Card>
        </Col>
      )} */}
      {/* {showAttribution && (
        <Col flex={4}>
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Observations"} />
            </div>
            <KpiSubText text={`${formatWholeNumber(observations)}`} />
          </Card>
        </Col>
      )} */}
      {!priceVisible ? null : (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Spend"} />
            </div>
            <KpiSubText
              text={`${formatCurrency2SigFig(mediaSpend, orgCurrencyFormat)}`}
            />
            <KpiPercentageText
              text={percentages.spend}
              prev={formatCurrency2SigFig(
                previousValues.spend,
                orgCurrencyFormat
              )}
              ranges={ranges}
            />
          </Card>
        </Col>
      )}
      {showTransactions && (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Revenue"} />
            </div>
            {/* improve shopify calculation in backend instead here */}
            <KpiSubText
              text={
                !isNaN(parseFloat(transactionTotalRevenue))
                  ? `${formatCurrency2SigFig(
                      parseFloat(transactionTotalRevenue),
                      orgCurrencyFormat
                    )}`
                  : ""
              }
            />
            <KpiPercentageText
              text={percentages.revenue}
              prev={formatCurrency2SigFig(
                previousValues.revenue,
                orgCurrencyFormat
              )}
              ranges={ranges}
            />
          </Card>
        </Col>
      )}
      {!priceVisible ? null : (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <Row align="top">
                <Col xs={19}>
                  <KPITitleText text={"ROAS"} />
                </Col>
                <Col xs={4}>
                  <Dropdown
                    placement="bottomLeft"
                    overlay={
                      <Menu>
                        {roasFormat.map(d => {
                          return (
                            <Menu.Item
                              key={d.key}
                              onClick={() => {
                                setRoasFormatSelected(d.key);
                              }}
                            >
                              {d.name}
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    }
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Col>
                <Col xs={1}>
                  <Tooltip
                    title={`ROAS Format can be changed by pressing the more icon besides this tooltip. 
                          Select the desired format of the ROAS on the dropdown. If the account is admin it can be changed in the org config`}
                    arrowPointAtCenter={true}
                    placement="rightBottom"
                  >
                    <QuestionCircleOutlined
                      type="question-circle"
                      style={{ margin: "0 auto" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <KpiSubText
              text={computeROASDisplay(
                transactionTotalRevenue,
                mediaSpend,
                roasFormatSelected,
                orgCurrencyFormat
              )}
            />
            <KpiPercentageText
              text={percentages.roas}
              prev={computeROASDisplay(
                previousValues.revenue,
                previousValues.spend,
                roasFormatSelected,
                orgCurrencyFormat
              )}
              ranges={ranges}
            />
          </Card>
        </Col>
      )}
      {signUpCampaign && (
        <Col flex="auto">
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Sign Ups"} />
            </div>
            <KpiSubText text={`${formatWholeNumberNaN(signUps)}`} />
            <KpiPercentageText
              text={percentages.signUps}
              prev={formatWholeNumberNaN(previousValues.signUps)}
              ranges={ranges}
            />
          </Card>
        </Col>
      )}
    </Row>
  );
};
export default DetailBarDynamicCol;
