import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, Select } from "antd";
import {
  CardTitleText,
  MenuItemTextColor,
} from "../../../../../../shared/globalStyling/styledText";
import TopBar from "./TopBar";
import SignUpsTable from "./SignUpsTable";
import SignUpsChart from "./SignUpsChart";
import { isEmpty } from "lodash";
import { signUpsResponseData, groupByKeys } from "./utils";
import moment from "moment";
import { attributionWindowSelections } from "../../../../../../../core/utils/constants/constants";
const { Option } = Select;

// Global Styling
const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const { campaignCardStyleCreatives } = Style;

const SignUpsLayout = ({
  selectedCampaign,
  colStyle,
  chartData,
  bySignUpRangeDate,
  setSignUpRangeDate,
  dataSignUpRangeDate,
  setDataSignUpRangeDate,
  setbySignUpsAttributionWindow,
  setdataSignUpsDate,
  reportName,
  signupsTable,
  signupsTableLoading,
  setCurrentIdx,
  setCurrentStartEndDate,
  attributionWindow,
  setAttributionWindow,
}) => {
  //Sets up variables
  const { startDate, endDate } = selectedCampaign ? selectedCampaign : [];

  const [currentPage, setCurrentPage] = useState(1);

  let campaignSelected = selectedCampaign ? selectedCampaign : [];
  const { signupsWithImpressions, hasSimplifiedReport } = chartData;

  const [signupsData, setSignupsData] = useState(
    chartData &&
      chartData.signupsWithImpressions &&
      chartData.signupsWithImpressions.length
      ? chartData.signupsWithImpressions
      : []
  );

  const isSimplified =
    chartData && chartData.hasSimplifiedReport
      ? chartData.hasSimplifiedReport
      : false;

  const attributionChange = useCallback(selectedAttributionWindow => {
    let detailBarFiltered = {
      startDate,
      endDate,
      signupsTotalCount: 0,
    };

    // Filtering of signups data by attribution
    const filteredSignupsWithAttribution =
      chartData &&
      chartData.signupsWithImpressions &&
      chartData.signupsWithImpressions.filter(signup => {
        const latestDate = signup.latestDate
          ? signup.latestDate
          : signup.impressions &&
            signup.impressions.reduce((prev, current) => {
              const prevDate = new Date(prev.IMPRESSION_TIME);
              const currentDate = new Date(current.IMPRESSION_TIME);
              return prevDate > currentDate ? prev : current;
            }).IMPRESSION_TIME;

        const { signups_tstamp } = signup;
        const impressionDate = new Date(moment(latestDate).format());
        const outOfRange = new Date(moment(signups_tstamp).format());
        outOfRange.setDate(outOfRange.getDate() - selectedAttributionWindow);
        if (impressionDate < outOfRange) {
          return false;
        } else if (impressionDate > outOfRange) {
          return true;
        }
        return false;
      });

    const filteredSignups = filteredSignupsWithAttribution.filter(signup => {
      return (
        moment(signup.signups_tstamp).format("YYYY/MM/DD") >=
          moment(startDate, "YYYY/MM/DD").format("YYYY/MM/DD") &&
        moment(signup.signups_tstamp).format("YYYY/MM/DD") <=
          moment(endDate, "YYYY/MM/DD").format("YYYY/MM/DD")
      );
    });

    //Generate a new by date object since there is a new set transactions due to attribution change
    const attributedByDate = [];
    filteredSignups.forEach(s => {
      const latestDate = s.latestDate
        ? s.latestDate
        : s.impressions &&
          s.impressions.reduce((prev, current) => {
            const prevDate = new Date(prev.IMPRESSION_TIME);
            const currentDate = new Date(current.IMPRESSION_TIME);
            return prevDate > currentDate ? prev : current;
          }).IMPRESSION_TIME;

      const date = moment(new Date(s.signups_tstamp)).format("MM/DD/YYYY");

      if (attributedByDate.find(c => c.xAxis === date)) {
        const idx = attributedByDate.findIndex(c => c.xAxis === date);
        attributedByDate[idx].signups_tstamp = date;
        attributedByDate[idx].count += 1;
        detailBarFiltered.signupsTotalCount += s.totalImpressions;
      } else {
        attributedByDate.push({
          signups_tstamp: date,
          latestDate: latestDate
            ? latestDate
            : s.impressions.reduce((acc, curr) => {
                if (moment(curr.IMPRESSION_TIME)) {
                  return moment(curr.IMPRESSION_TIME) >
                    moment(acc.IMPRESSION_TIME)
                    ? curr
                    : acc;
                }
                return acc;
              }).IMPRESSION_TIME,
          count: 1,
          xAxis: date,
        });

        detailBarFiltered.signupsTotalCount += s.totalImpressions;

        setDataSignUpRangeDate(attributedByDate);
        setSignupsData(filteredSignups);
        setdataSignUpsDate(attributedByDate);
        setbySignUpsAttributionWindow(selectedAttributionWindow);
        setAttributionWindow(selectedAttributionWindow);
        setCurrentIdx(0);
        setCurrentPage(1);
      }
    });
  });

  // Set Initial Report data
  useEffect(() => {
    if (
      chartData &&
      chartData.signupsWithImpressions &&
      chartData.signupsWithImpressions.length
    ) {
      attributionChange(attributionWindow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributionWindow, chartData]);

  useEffect(() => {
    if (bySignUpRangeDate && bySignUpRangeDate.length) {
      let detailBarFiltered = {
        startDate,
        endDate,
        signupsTotalCount: 0,
      };

      // Filtering of signups data by attribution
      const filteredSignupsWithAttribution =
        chartData &&
        chartData.signupsWithImpressions &&
        chartData.signupsWithImpressions.filter(signup => {
          const latestDate = signup.latestDate
            ? signup.latestDate
            : signup.impressions &&
              signup.impressions.reduce((prev, current) => {
                const prevDate = new Date(prev.IMPRESSION_TIME);
                const currentDate = new Date(current.IMPRESSION_TIME);
                return prevDate > currentDate ? prev : current;
              }).IMPRESSION_TIME;

          const { signups_tstamp } = signup;
          const impressionDate = new Date(moment(latestDate).format());
          const outOfRange = new Date(moment(signups_tstamp).format());
          outOfRange.setDate(outOfRange.getDate() - attributionWindow);
          if (impressionDate < outOfRange) {
            return false;
          } else if (impressionDate > outOfRange) {
            return true;
          }
          return false;
        });

      const filteredSignups = filteredSignupsWithAttribution.filter(signup => {
        return (
          moment(signup.signups_tstamp).format("YYYY/MM/DD") >=
            moment(bySignUpRangeDate[0], "YYYY/MM/DD").format("YYYY/MM/DD") &&
          moment(signup.signups_tstamp).format("YYYY/MM/DD") <=
            moment(bySignUpRangeDate[1], "YYYY/MM/DD").format("YYYY/MM/DD")
        );
      });

      const attributedByDate = [];
      filteredSignups.forEach(s => {
        const latestDate = s.latestDate
          ? s.latestDate
          : s.impressions &&
            s.impressions.reduce((prev, current) => {
              const prevDate = new Date(prev.IMPRESSION_TIME);
              const currentDate = new Date(current.IMPRESSION_TIME);
              return prevDate > currentDate ? prev : current;
            }).IMPRESSION_TIME;

        const date = moment(new Date(s.signups_tstamp)).format("MM/DD/YYYY");

        if (attributedByDate.find(c => c.xAxis === date)) {
          const idx = attributedByDate.findIndex(c => c.xAxis === date);
          attributedByDate[idx].signups_tstamp = date;
          attributedByDate[idx].count += 1;
          detailBarFiltered.signupsTotalCount += s.totalImpressions;
        } else {
          attributedByDate.push({
            signups_tstamp: date,
            latestDate: latestDate
              ? latestDate
              : s.impressions.reduce((acc, curr) => {
                  if (moment(curr.IMPRESSION_TIME)) {
                    return moment(curr.IMPRESSION_TIME) >
                      moment(acc.IMPRESSION_TIME)
                      ? curr
                      : acc;
                  }
                  return acc;
                }).IMPRESSION_TIME,
            count: 1,
            xAxis: date,
          });

          detailBarFiltered.signupsTotalCount += s.totalImpressions;
        }
      });
      setDataSignUpRangeDate(attributedByDate);
      setSignupsData(filteredSignups);
      setdataSignUpsDate(attributedByDate);
      setCurrentIdx(0);
      setCurrentPage(1);
    }
  }, [
    attributionWindow,
    bySignUpRangeDate,
    chartData,
    endDate,
    setCurrentIdx,
    setDataSignUpRangeDate,
    setdataSignUpsDate,
    startDate,
  ]);
  return (
    <>
      <Row gutter={35}>
        <Col xs={24}>
          <TopBar
            colStyle={colStyle}
            transactions={signupsData.length}
            startDate={startDate}
            endDate={endDate}
            selectedCampaign={campaignSelected}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <SignUpsChart
            bySignUpRangeDate={bySignUpRangeDate}
            setSignUpRangeDate={setSignUpRangeDate}
            dataSignUpRangeDate={dataSignUpRangeDate}
            setDataSignUpRangeDate={setDataSignUpRangeDate}
            setCurrentStartEndDate={setCurrentStartEndDate}
            colStyle={colStyle}
            data={
              isEmpty(signupsWithImpressions)
                ? []
                : signUpsResponseData(
                    groupByKeys(
                      signupsWithImpressions.map(signUp => {
                        return {
                          ...signUp,
                          dateChart: moment(
                            new Date(signUp.signups_tstamp)
                          ).format("YYYY-MM-DD"),
                        };
                      }),
                      ["dateChart"]
                    )[0]
                  ).sort((a, b) => {
                    if (a.xAxis === b.xAxis) return 0;
                    if (moment(a.xAxis).isAfter(b.xAxis)) return 1;
                    return -1;
                  })
            }
            setCurrentPage={setCurrentPage}
            setCurrentIdx={setCurrentIdx}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            style={campaignCardStyleCreatives}
            headStyle={{
              borderWidth: "3px",
              display: "flex",
              flexDirection: "column",
            }}
            bodyStyle={{ padding: 0 }}
            title={<CardTitleText text={"Sign Ups"} textAlign={"left"} />}
            extra={
              <span>
                {`Select Attribution Window`}
                &nbsp;&nbsp;
                <Select
                  value={attributionWindow}
                  placeholder="Please select days"
                  style={{ width: 120 }}
                  onChange={val => {
                    attributionChange(val);
                  }}
                >
                  {attributionWindowSelections.map(option => {
                    return (
                      <Option key={option.key} value={option.key}>
                        <MenuItemTextColor text={option.name} />
                      </Option>
                    );
                  })}
                </Select>
              </span>
            }
          >
            <SignUpsTable
              signUpsData={
                !isSimplified
                  ? signupsData
                  : signupsTable
                  ? signupsTable.record
                  : []
              }
              signupTotalRecord={
                isSimplified && signupsTable ? signupsTable.count : 0
              }
              hasSimplifiedReport={hasSimplifiedReport}
              isSimplified={isSimplified}
              setCurrentIdx={setCurrentIdx}
              loading={signupsTableLoading}
              reportName={reportName}
              campaignOrderId={
                selectedCampaign && selectedCampaign.id
                  ? selectedCampaign.id
                  : null
              }
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SignUpsLayout;
