import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import "./styles.css";
import moment from "moment";

const { Text, Title } = Typography;

export const CampaignRequestorTargetingLocalTable = ({
  name,
  restField,
  fieldKey,
  form,
  setShowTargetingGroupsModal,
  editable = false,
  index,
}) => {
  const targetingLocations = form.getFieldValue("campaigns")[index]
    .targetingLocations;
  const targetingType = form.getFieldValue("campaigns")[index].targetingType;

  return (
    <div className="requestor-table-wrapper" style={{ marginTop: 20 }}>
      <div className="requestor-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>TARGETING: LOCAL</Title>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "end" }}>
              {setShowTargetingGroupsModal &&
              targetingLocations &&
              targetingLocations.length ? (
                <Button onClick={() => setShowTargetingGroupsModal(true)}>
                  Edit Targetings
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {targetingLocations && targetingLocations.length ? (
        <Table
          rowKey={record => record.name}
          columns={[
            {
              title: "Targeting Group Name",
              dataIndex: "name",
              key: "name",
              ellipsis: true, // Enables ellipsis for text overflow
              width: 200, // Set a fixed width for the column
            },
            {
              title: "Locations",
              dataIndex: "locations",
              key: "locations",
              width: "40%",
              render: (_, { geoTargets }) => (
                <div>
                  {geoTargets &&
                    geoTargets.map(target => {
                      return target.location ? (
                        <Tag color="blue" key={target.id}>
                          {target.location.name}
                        </Tag>
                      ) : (
                        <></>
                      );
                    })}
                </div>
              ),
            },
            {
              title: "Created On",
              dataIndex: "createdAt",
              key: "createdAt",
              render: text => moment(text).format("MM/DD/YYYY hh:mma"),
            },
          ]}
          dataSource={targetingLocations}
          pagination={false}
          className="requestor-table"
          summary={() =>
            targetingLocations && targetingLocations.length ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  {targetingLocations.length} Targeting Groups
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {targetingLocations.reduce(
                    (acc, targetingGroup) =>
                      acc + targetingGroup.geoTargets.length,
                    0
                  )}{" "}
                  Targeting Locations
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <>
          <div style={{ padding: 20 }}>
            <Space
              direction="vertical"
              size="small"
              align="center"
              style={{ width: "100%" }}
            >
              {editable ? (
                <>
                  <Text>Select Targeting Groups from your library</Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowTargetingGroupsModal(true);
                    }}
                  >
                    Select Targeting Groups
                  </Button>
                </>
              ) : (
                <Text>No targeting groups selected</Text>
              )}
            </Space>
          </div>
        </>
      )}

      {/* Create hidden input for targetingLocations */}
      <Form.Item
        {...restField}
        name={[name, "targetingLocations"]}
        fieldKey={[fieldKey, "targetingLocations"]}
        hidden
        rules={[
          {
            required: targetingType === "LOCAL",
            message: "Please select at least one targeting location",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
