import React, { useState } from "react";
import { ClusterOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Tabs,
  Skeleton,
  Table,
  Badge,
  Button,
  Empty,
  Popover,
  Tag,
} from "antd";

//locals from shared
import { PageTabContentLayout, PageHeaderLayout } from "../../shared/layout";
//locals
import OrganizationDetails from "./components/OrganizationDetails";
import HeaderBar from "./components/HeaderBar";
import SearchBar from "./components/SearchBar";
import UsersLoader from "../../../core/components/orgs/users/UsersLoader";
import { permissionsFromRoles } from "../../../core/utils/dataManipulating/organization";
//utils
import {
  buildOrganizationTree,
  buildAntTree,
} from "../../../core/utils/dataManipulating/organization";
import _, { isEmpty } from "lodash";
import "./styles/index.css";
import SendEmailConfigView from "./SendEmailConfigView";
import {
  // mediajelOrgIdProduction,
  mediajelOrgIdStaging,
} from "../../../core/utils/constants/constants";

const style = {
  sideBar: {
    width: "100%",
    backgroundColor: "#fff",
    border: "0.8px solid #e8e8e8",
    marginTop: "42px",
  },
  header: {
    padding: "10px 0px 0px 30px",
  },
  container: {
    border: "0.5px solid #e8e8e8",
    padding: "0.5px",
  },
  customerName: {
    marginLeft: 4,
  },
};

const OrganizationsView = ({
  onOrgSelect,
  createOrg,
  deleteOrg,
  disableOrg,
  enableOrg,
  updateOrg,
  onChange,
  onSwitchChange,
  currentRole,
  clickCreateNewOrg,
  organizations,
  currentOrg,
  selectedOrg,
  currentuser,
  match,
  assumeRole,
  updateOrgSendEmailConfig,
  refetchOrganizations,
  dataDealIds,
}) => {
  let showShareReport = false;

  if (selectedOrg && selectedOrg.parentOrg) {
    // note: before merging to production, replace mediajelOrgIdStaging to mediajelOrgIdProduction
    showShareReport =
      selectedOrg === mediajelOrgIdStaging ||
      selectedOrg.parentOrg
        .map(org => org.id === mediajelOrgIdStaging)
        .some(val => val === true) ||
      selectedOrg.id === mediajelOrgIdStaging;
  }

  let [activeTab, setActiveTab] = useState("1");

  const [openOrgSendEmail, setOpenOrgSendEmail] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // const showModal = () => {
  //   setOpenOrgSendEmail(true);
  // };

  const handleOk = () => {
    setOpenOrgSendEmail(false);
  };

  const handleCancel = () => {
    setOpenOrgSendEmail(false);
  };

  const { isAdmin } = currentuser;
  // check selectedOrg value if it is already saved by checking if ID exist
  const isOrgAlreadySaved = selectedOrg => {
    return _.get(selectedOrg, "id", false);
  };

  const preview = () => {
    assumeRole(selectedOrg);
  };
  const [displayedOrganization, setDisplayedOrganization] = useState(
    organizations
  );
  const columns = [
    {
      title: "Users",
      dataIndex: "name",
      render: (text, record, i) => {
        //Filter out the parent organizations thats only available for the login org/user
        const showableParent = record.parentOrg.filter(parent => {
          return organizations.find(org => org.name === parent.name);
        });
        const popupMessage = () => {
          return (
            <Row>
              {showableParent && showableParent.length > 0
                ? showableParent.map(org => {
                    return (
                      <Col xs={24} key={org.id}>
                        {org.name}
                      </Col>
                    );
                  })
                : "--"}
            </Row>
          );
        };
        return (
          <>
            <Row justify="center" align="middle">
              <Col xs={22}>
                <Badge
                  dot
                  style={
                    Object.keys(
                      permissionsFromRoles([
                        record.roles.find(role => role.isMaster),
                      ])
                    ).includes("ENABLED")
                      ? { backgroundColor: "#52c41a" }
                      : null
                  }
                />
                <span style={style.customerName}> {` ${record.name} `}</span>
                {record && selectedOrg && record.id === selectedOrg.id ? (
                  <Tag color="#87d068">Selected</Tag>
                ) : null}
              </Col>
              <Col xs={2}>
                {isSearching && showableParent && showableParent.length > 0 ? (
                  <Popover title="Parent Org/s" content={popupMessage()}>
                    <ClusterOutlined />
                  </Popover>
                ) : null}
              </Col>
            </Row>
          </>
        );
      },
      sorter: (a, b) => a.name < b.name,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
    },
  ];

  const searchOrganization = orgName => {
    let searchOrganizations = [];
    organizations.forEach(org => {
      if (
        org.name.toLowerCase().includes(orgName.toLowerCase()) ||
        org.parentOrg.find(parent =>
          parent.name.toLowerCase().includes(orgName.toLowerCase())
        )
      ) {
        searchOrganizations.push(org);
      }
    });
    if (orgName) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }

    searchOrganizations = searchOrganizations.sort();
    setDisplayedOrganization(searchOrganizations);
  };
  return (
    <React.Fragment>
      {showShareReport && selectedOrg && selectedOrg.id && (
        <SendEmailConfigView
          selectedOrg={selectedOrg}
          currentOrg={currentOrg}
          open={openOrgSendEmail}
          setOpenOrgSendEmail={setOpenOrgSendEmail}
          handleOk={handleOk}
          handleCancel={handleCancel}
          updateOrgSendEmailConfig={updateOrgSendEmailConfig}
        />
      )}
      <PageHeaderLayout
        data={{}}
        title="Organizations"
        rightContent={
          <Row>
            <Col>
              {isAdmin &&
              isOrgAlreadySaved(selectedOrg) &&
              activeTab !== "2" ? (
                <>
                  {/* {showShareReport && (
                    <Button
                      type="primary"
                      onClick={showModal}
                      style={{ margin: "0 1rem" }}
                    >
                      Share Reports
                    </Button>
                  )} */}
                  <Button type="primary" onClick={clickCreateNewOrg}>
                    Create Org
                  </Button>
                </>
              ) : null}
            </Col>
          </Row>
        }
      />
      <PageTabContentLayout>
        <Row gutter={8}>
          <Col md={24} lg={8} xl={8} xxl={8} style={{ marginBottom: "10px" }}>
            <div style={style.sideBar}>
              <HeaderBar title="Org Name" />
              <SearchBar onSearch={searchOrganization} />
              <Table
                style={{ position: "relative" }}
                showHeader={false}
                scroll={{ y: 800 }}
                columns={columns}
                bordered={false}
                dataSource={buildAntTree(
                  buildOrganizationTree(
                    displayedOrganization,
                    currentOrg ? currentOrg.name : ""
                  ),
                  ""
                )}
                rowClassName={(record, index) => {
                  if (record && selectedOrg) {
                    if (record.id === selectedOrg.id) return "tableSelectedRow";
                  }
                }}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: e => {
                      onOrgSelect(record);
                    },
                  };
                }}
                rowKey={record => `${record.id}`}
              />
            </div>
          </Col>

          <Col md={24} lg={16} xl={16} xxl={16}>
            <Tabs
              defaultActiveKey={
                match.params.user && match.params.org ? "2" : "1"
              }
              tabBarStyle={{ margin: "0 10px" }}
              onChange={key => setActiveTab(key.toString())}
            >
              <Tabs.TabPane
                tab="Org Details"
                key="1"
                disabled={selectedOrg === null}
              >
                <React.Fragment>
                  {selectedOrg ? (
                    <OrganizationDetails
                      createOrg={createOrg}
                      deleteOrg={deleteOrg}
                      disableOrg={disableOrg}
                      enableOrg={enableOrg}
                      updateOrg={updateOrg}
                      onChange={onChange}
                      onSwitchChange={onSwitchChange}
                      selectedOrg={selectedOrg}
                      currentRole={currentRole}
                      currentOrg={currentOrg}
                      clickCreateNewOrg={clickCreateNewOrg}
                      organizations={organizations}
                      preview={preview}
                      currentuser={currentuser}
                      setDisplayedOrganization={setDisplayedOrganization}
                      refetchOrganizations={refetchOrganizations}
                      dataDealIds={dataDealIds}
                    />
                  ) : (
                    <Skeleton avatar paragraph={{ rows: 10 }} />
                  )}
                </React.Fragment>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Org Users"
                key="2"
                disabled={selectedOrg ? isEmpty(selectedOrg.id) : true}
              >
                {selectedOrg ? (
                  selectedOrg.id ? (
                    <UsersLoader
                      organizations={organizations}
                      currentRole={currentRole}
                      currentuser={currentuser}
                      selectedOrg={selectedOrg}
                    />
                  ) : (
                    <Empty />
                  )
                ) : (
                  <Empty />
                )}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </PageTabContentLayout>
    </React.Fragment>
  );
};

export default OrganizationsView;
