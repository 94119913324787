import moment from "moment";
import { cleanseNumericValue } from "../../../../core/utils/general/numbers";

export const TRENDS = {
  downward: -1,
  flat: 0,
  upward: 1,
};

/**
 * Generate the dates
 *  - `currentTime - 7 days`
 *  - `-7 days - 7 days`
 */
function getWeekDateRange() {
  const dateRanges = {
    now: [],
    before: [],
  };
  const today = moment().subtract(1, "days"); // get data until yesterday; we dont include partial days
  const lastWeek = moment().subtract(1, "w");
  const twoWeeks = moment().subtract(2, "w");
  //start date, end date
  dateRanges.before = [twoWeeks, lastWeek];
  dateRanges.now = [lastWeek, today];

  return dateRanges;
}

/**
 * Generate the dates
 *  - `currentTime - 7 days`
 *  - `-7 days - 7 days`
 */
function getMonthDateRange() {
  const dateRanges = {
    now: [],
    before: [],
  };
  const today = moment().subtract(1, "days"); // get data until yesterday; we dont include partial days
  const lastMonth = moment().subtract(4, "w");
  const twoMonths = moment().subtract(8, "w");

  //start date, end date
  dateRanges.before = [twoMonths, lastMonth];
  dateRanges.now = [lastMonth, today];

  return dateRanges;
}

/**
 *
 * @param {*} data byDate Report
 */
export function compute(data) {
  const weekDateRange = getWeekDateRange();
  const monthDateRange = getMonthDateRange();

  return {
    weekly: computeStats(data, weekDateRange),
    monthly: computeStats(data, monthDateRange),
  };
}

function computeStats(data, dateRanges) {
  const prevTotals = getDisplayTotalsByDateRange(data, dateRanges.before);
  const currentTotals = getDisplayTotalsByDateRange(data, dateRanges.now);

  const diffs = {
    impressions: currentTotals.impressions - prevTotals.impressions,
    clicks: currentTotals.clicks - prevTotals.clicks,
    ctr: currentTotals.ctr - prevTotals.ctr,
    userCost: currentTotals.userCost - prevTotals.userCost,
  };

  const roas =
    currentTotals.userCost > 0
      ? `${cleanseNumericValue(
          Number(
            (currentTotals.transactionTotal / currentTotals.userCost) * 100
          ),
          null
        )}`
      : null;
  const stats = {
    ...currentTotals,
    pctChangeImpressions: {
      value: `${computePCTChange(
        diffs.impressions,
        currentTotals.impressions
      )}`,
      trend: computeTrend(diffs.impressions),
    },
    pctChangeClicks: {
      value: `${computePCTChange(diffs.clicks, currentTotals.clicks)}`,
      trend: computeTrend(diffs.clicks),
    },
    pctChangeCTR: {
      value: `${computePCTChange(diffs.ctr, currentTotals.ctr) || 0}`,
      trend: computeTrend(diffs.ctr),
    },
    currentDateRange: `${dateRanges.now
      .map(d => d.format("YYYY-MM-DD"))
      .join(" - ")}`,
    prevDateRange: `${dateRanges.before
      .map(d => d.format("YYYY-MM-DD"))
      .join(" - ")}`,
    roas,
  };

  return stats;
}

function computeTrend(diff) {
  if (diff < 0) {
    return TRENDS.downward;
  } else if (diff > 0) {
    return TRENDS.upward;
  }

  return TRENDS.flat;
}

function computePCTChange(diff, total) {
  const result = ((diff / total) * 100).toFixed(2);
  const output = cleanseNumericValue(parseFloat(result), "N/A");
  if (output !== "N/A") {
    return `${output}%`;
  }
  return output;
}

/**
 *  Get the totals
 *
 * @param {*} data
 * @param {*} dateRange
 * @return { impressions: number, ctr: number, clicks: number, transactionCount: number, transactionTotal: number, roas: number, cost: number  }
 */
function getDisplayTotalsByDateRange(data, dateRange) {
  const totals = {
    impressions: 0,
    clicks: 0,
    ctr: 0,
    transactionCount: 0,
    transactionTotal: 0,
    roas: 0,
    cost: 0,
    userCost: 0,
  };

  const start = dateRange[0]
    .hour(0)
    .minute(0)
    .second(0)
    .unix();
  const end = dateRange[1]
    .hour(23)
    .minute(59)
    .second(59)
    .unix();

  const filteredData = data.filter(obj => {
    return obj.timestamp >= start && obj.timestamp <= end;
  });

  filteredData.forEach(f => {
    totals.impressions += f.impressions || 0;
    totals.clicks += f.clicks || 0;
    totals.transactionCount += f.transactionCount || 0;
    totals.transactionTotal += f.transactionTotal || 0;
    totals.cost += f.cost || 0;
    totals.userCost += f.userCost || 0;
  });

  totals.ctr = (totals.clicks / totals.impressions) * 100;
  totals.ctr = cleanseNumericValue(parseFloat(totals.ctr.toFixed(3), null));

  if (totals.userCost <= 0 && totals.impressions > 0) {
    totals.userCost = null;
  }

  return totals;
}
