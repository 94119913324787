import { Col, Row } from "antd";
import React from "react";
import DetailBarCard from "../../../../../../shared/DetailBarCard";
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";
import DetailBarCardDateRange from "../../../../../../shared/DetailBarCardDateRange";

const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
};
const DetailBar = ({
  pageViews,
  impressions,
  uniqueUsers,
  startDate,
  endDate,
}) => {
  return (
    <React.Fragment>
      <Row style={styles.container} wrap={true}>
        <Col key="title" flex="auto">
          <DetailBarCardDateRange
            title="Flight Dates"
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col key="pageViews" flex="auto">
          <DetailBarCard
            title="Page Views"
            value={formatWholeNumber(pageViews)}
          />
        </Col>
        <Col key="clicks" flex="auto">
          <DetailBarCard
            title="Impressions"
            value={formatWholeNumber(impressions)}
          />
        </Col>
        <Col key="uniqueUsers" flex="auto">
          <DetailBarCard
            title="Unique Users"
            value={formatWholeNumber(uniqueUsers)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailBar;
