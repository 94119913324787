import React from "react";
import { Card, Row, Col, DatePicker } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import SingleAxisArea from "./SingleAxisArea";
// Global Styles
import {
  CardTitleText,
  CardText,
} from "../../../../../../shared/globalStyling/styledText";
// Helper functions
// import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";
import { ExtraInfo } from "./ExtraInfo";
const { RangePicker } = DatePicker;
const Frag = React.Fragment;

const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
};

const DateSelector = ({
  data,
  bySignUpRangeDate,
  setSignUpRangeDate,
  setDataSignUpRangeDate,
  setCurrentStartEndDate,
  setCurrentPage,
  setCurrentIdx,
}) => {
  // map xAxis dates
  const getDate = data.map(a => a.xAxis);

  // sort them by desc dates
  getDate.sort(function(a, b) {
    return moment(a.date) - moment(b.date);
  });

  // get startdate and end date
  const startDate = getDate[0];
  const endDate = getDate.slice(-1)[0];

  // combined start date & end date
  const startEndDate = [
    moment(startDate, moment.DATETIME_LOCAL_MS),
    moment(endDate, moment.DATETIME_LOCAL_MS),
  ];

  // find the dates between startdate and enddate
  const findBetweenDates = current => {
    if (
      current <= moment(startDate, moment.DATETIME_LOCAL_MS).add(-1, "days")
    ) {
      return true;
    } else if (
      current >= moment(endDate, moment.DATETIME_LOCAL_MS).add(+1, "days")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <RangePicker
      disabledDate={findBetweenDates}
      defaultPickerValue={startEndDate}
      defaultValue={startEndDate}
      value={isEmpty(bySignUpRangeDate) ? startEndDate : bySignUpRangeDate}
      onChange={v => {
        // get selected range dates
        const getSelectedStartDate = moment(v ? v[0] : startDate).format(
          "YYYY-MM-DD"
        );
        const unixSelectedStartDate = moment(v ? v[0] : startDate).unix();

        const getSelectedEndDate = moment(v ? v[1] : endDate)
          .add(+1, "days")
          .format("YYYY-MM-DD");
        const unixSelectedEndDate = moment(v ? v[1] : endDate)
          .add(+1, "days")
          .unix();

        // filter data to get only between range dates
        const getFilteredData = data.filter(obj => {
          return (
            obj.xAxis >= getSelectedStartDate && obj.xAxis <= getSelectedEndDate
          );
        });

        // set the date range on the selected date in calendar
        setSignUpRangeDate(v);
        // set the data into filtered ones with the selected range dates
        setDataSignUpRangeDate(getFilteredData);

        setCurrentStartEndDate([unixSelectedStartDate, unixSelectedEndDate]);

        setCurrentPage(1); // Reset the current page to first page

        setCurrentIdx(0); // Reset the current index to 1
      }}
    />
  );
};

const explanation =
  "This chart displays the sign-ups number of responses per day for the duration of the campaign.";

const SignUpsChart = ({
  data,
  flightDates,
  bySignUpRangeDate,
  setSignUpRangeDate,
  dataSignUpRangeDate,
  setDataSignUpRangeDate,
  setCurrentStartEndDate,
  setCurrentPage,
  setCurrentIdx,
}) => {
  return (
    <Row style={styles.container} type="flex">
      <Col xs={24}>
        <Card
          title={<CardTitleText text={"SignUp chart by date"} />}
          style={{ height: "35em" }}
          headStyle={{ borderWidth: "2px" }}
          bodyStyle={{ padding: "2%", paddingBottom: "3%" }}
          bordered
          extra={<ExtraInfo placement="topRight" title={explanation} />}
        >
          <Row type="flex" justify="center">
            <DateSelector
              data={data}
              bySignUpRangeDate={bySignUpRangeDate}
              setSignUpRangeDate={setSignUpRangeDate}
              dataSignUpRangeDate={dataSignUpRangeDate}
              setDataSignUpRangeDate={setDataSignUpRangeDate}
              setCurrentStartEndDate={setCurrentStartEndDate}
              setCurrentPage={setCurrentPage}
              setCurrentIdx={setCurrentIdx}
            />
          </Row>
          {isEmpty(data) ? (
            <Row type="flex" justify="center" align="middle">
              <CardText
                text={
                  "Please refresh your browser to receive the latest updates."
                }
              />
            </Row>
          ) : (
            <Row>
              {data.length !== 1 && (
                <Frag>
                  <SingleAxisArea
                    tooltipName={"Sign Ups"}
                    leftY={"count"}
                    height={350}
                    data={
                      dataSignUpRangeDate.length > 0
                        ? dataSignUpRangeDate.sort((a, b) => {
                            if (moment(a.xAxis).isBefore(b.xAxis)) return -1;
                            return 1;
                          })
                        : data.sort((a, b) => {
                            if (moment(a.xAxis).isBefore(b.xAxis)) return -1;
                            return 1;
                          })
                    }
                  />
                </Frag>
              )}
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SignUpsChart;
