import React from "react";
import { List } from "antd";

const CategoryList = props => {
  const {
    list,
    onCategoryClick,
    listItemStyle,
    titleFontStyle,
    selectedStyle,
    selectedCategory,
    selectedFontStyle,
  } = props;

  // Function to convert text to Title Case
  const toTitleCase = str => {
    if (str === null || str === undefined) return "";

    return str.toLowerCase().replace(/(^|\s)\S/g, function(letter) {
      return letter.toUpperCase();
    });
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item
          style={{
            cursor: "pointer",
            ...listItemStyle,
            ...(selectedCategory && selectedCategory.name === item.name
              ? selectedStyle
              : {}),
          }}
          onClick={() => {
            onCategoryClick(item);
          }}
        >
          <List.Item.Meta
            title={
              <p
                style={{
                  titleFontStyle,
                  ...(selectedCategory && selectedCategory.name === item.name
                    ? selectedFontStyle
                    : { fontSize: "17px" }),
                }}
              >
                {toTitleCase(item.name)}
              </p>
            } // Adjust the font size here
            description={
              <>
                {item.description.length > 0 ? (
                  <span
                    style={{
                      fontSize: "15px",
                      ...(selectedCategory &&
                      selectedCategory.name === item.name
                        ? { color: "white" }
                        : { color: "black" }),
                    }}
                  >
                    {item.description}
                  </span>
                ) : (
                  <i
                    style={{
                      ...(selectedCategory &&
                      selectedCategory.name === item.name
                        ? { color: "white" }
                        : { color: "black" }),
                    }}
                  >
                    &nbsp;
                  </i>
                )}
              </>
            }
          />
        </List.Item>
      )}
    ></List>
  );
};

export default CategoryList;
