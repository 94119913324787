import gql from "graphql-tag";

const CREATE_ORG_MEDIA = gql`
  mutation createOrgMedia(
    $url: String!
    $mediatype: MediaType!
    $key: String!
    $width: Int
    $height: Int
    $size: Int
    $name: String
    $fileType: FileType
    $orgId: ID
  ) {
    createMedia(
      data: {
        url: $url
        key: $key
        type: $mediatype
        width: $width
        height: $height
        size: $size
        name: $name
        fileType: $fileType
        sharedWith: { connect: { id: $orgId } }
      }
    ) {
      id
      url
      key
      width
      height
      fileType
      size
      name
      sharedWith {
        name
      }
    }
  }
`;

export default CREATE_ORG_MEDIA;
