import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import LIST_ORGS_SHARING from "../../../GraphQl/Queries/LIST_ORGS_SHARING";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import CREATE_EVENT_TAG from "../../../GraphQl/Mutations/CREATE_EVENT_TAG";

const TagsManagerPublisherLoader = props => {
  const { currentUser, history } = props;
  const currentOrgId = currentUser.role.org.id;

  const { data: orgs, loading: orgsLoading } = useQuery(LIST_ORGS_SHARING, {
    variables: {
      parentid: currentOrgId,
    },
  });

  const [createEventTag] = useMutation(CREATE_EVENT_TAG, {
    onCompleted: values => {
      history.push(`/tags/details/${values.createEventTag.id}`);
    },
  });

  if (orgsLoading) return <LoadingPage />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    orgs,
    currentOrgId,
    createEventTag,
  });
};

export default withRouter(TagsManagerPublisherLoader);
