import gql from "graphql-tag";
const PAID_SEARCH_REPORTS = gql`
  query paidSearchReports(
    $id: ID!
    $where: PaidSearchCampaignWhereInput!
    $bingWhere: PaidSearchBingCampaignsWhereInput!
  ) {
    paidSearchSummary(where: { id: $id }) {
      id
      overallData {
        id
        impressions
        clicks
        cost
        revenue
        cpc
        cpm
        spend
        ctr
        roas
        conversions
        rawCost
        interactionRate
        conversionRate
        phoneCalls
        byDate(orderBy: date_ASC) {
          keywords
          locations
          conversions
          clicks
          impressions
          ctr
          cost
          cpc
          cpm
          spend
          revenue
          roas
          date
          xAxis
        }
      }
      overallLocations {
        id
        revenue
        byDate(orderBy: date_ASC) {
          revenue
          date
          location
        }
        byDay(orderBy: date_ASC) {
          revenue
          cost
          conversions
          roas
          date
          location
        }
      }
      paidSearchCampaigns(where: $where) {
        id
        name
        startDate
        endDate
        adProviderId
        data {
          id
          impressions
          clicks
          cost
          revenue
          cpc
          cpm
          spend
          ctr
          roas
          conversions
          rawCost
          interactionRate
          conversionRate
          phoneCalls
          byDate(orderBy: date_ASC) {
            keywords
            locations
            conversions
            clicks
            impressions
            ctr
            cost
            cpc
            cpm
            spend
            revenue
            roas
            date
            xAxis
          }
        }
      }
      overallBing {
        id
        impressions
        clicks
        cost
        revenue
        cpc
        cpm
        ctr
        roas
        conversions
        byDate(orderBy: date_ASC) {
          impressions
          clicks
          cost
          revenue
          cpc
          cpm
          ctr
          roas
          spend
          conversions
          date
          xAxis
        }
      }
      paidSearchBingCampaigns(where: $bingWhere) {
        id
        name
        startDate
        endDate
        adProviderId
        status
        data {
          id
          impressions
          clicks
          spend
          revenue
          conversions
          byDate(orderBy: date_ASC) {
            impressions
            clicks
            spend
            revenue
            conversions
            date
            xAxis
          }
        }
      }
    }
  }
`;

export default PAID_SEARCH_REPORTS;
