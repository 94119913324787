import gql from "graphql-tag";

// Get the details of the orgs that are shared with the org seo
//$ids is an array of orgSeoSummary ids that needs to have the details of the orgs that are shared with it
//Make sure the orgSeoSummary has an org id and is not null.
//This is to avoid getting the details of the seo that are not owned by any org which is common for legacy SEO but will cause an error for the new SEO
const ORG_SEO_SHARING_DETAILS = gql`
  query($ids: [ID!]!) {
    orgSeoSummaries(where: { id_in: $ids, org: { id_not: null } }) {
      id
      sharedOrgs {
        id
        name
      }
      org {
        id
        name
      }
    }
  }
`;

export default ORG_SEO_SHARING_DETAILS;
