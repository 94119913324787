import JSZip from "jszip";
import { saveAs } from "file-saver";
import Papa from "papaparse";

export default function prepareExport(tableData) {
  const zip = new JSZip();
  const folder = zip.folder("PacingReport");

  let reportToExport = tableData.slice(0);
  const copy = reportToExport.map(row => {
    const r = { ...row };
    return {
      Campaign: r.name,
      Status: r.status,
      "Days Into Flight": r.daysIntoFlight,
      "Days Left": r.daysLeft,
      "Expected Imps": r.expectedImpressions,
      "Actual Imps": r.actualImpressions,
      "Imps Left": r.impressionsLeft,
      "Imps Yesterday": r.impressionsYesterday,
      "Daily Avg Left": r.dailyAvgImpressionsLeft,
      ROAS: r.roas,
      CTR: r.ctr,
      "Pace (All-time)": r.pace,
      "Pace (Yesterday)": r.paceYesterday,
      "Pace (2 days ago)": r.paceTwoDaysAgo,
      "Pace (3 days ago)": r.paceThreeDaysAgo,
      "Pace (4 days ago)": r.paceFourDaysAgo,
      "Pace (5 days ago)": r.paceFiveDaysAgo,
      "Pace (6 days ago)": r.paceSixDaysAgo,
      "Pace (7 days ago)": r.paceSevenDaysAgo,
    };
  });

  folder.file(`Pacing-Report.csv`, Papa.unparse(copy));
  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, "Pacing Report");
      // setIsExportCompleted(true);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
}
