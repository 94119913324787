import JSZip from "jszip";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import moment from "moment";

export default function prepareExport(data) {
  const zip = new JSZip();
  const folder = zip.folder("Campaign_Order_Contract_Terms");

  let reportToExport = data.slice(0);
  const copy = reportToExport.map(row => {
    const r = { ...row };
    return {
      Name: r.name,
      "Start Date": r.startDate
        ? moment.utc(r.startDate).format("MM/DD/YYYY")
        : "",
      "End Date": r.endDate ? moment.utc(r.endDate).format("MM/DD/YYYY") : "",
      Budget: r.budget,
      CPM: r.cpm,
      "Impressions Goal": r.impressionsGoal,
    };
  });

  folder.file(`Campaign_Order_Contract_Terms.csv`, Papa.unparse(copy));
  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, "Contract Terms");
      // setIsExportCompleted(true);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
}
