import React, { useState } from "react";
import { Row, Col, Button, Modal, Input, Typography } from "antd";
import CampaignOrderListTable from "./CampaignOrderListTable";

const { Title } = Typography;

const styles = {
  title: {
    color: "#595959",
    marginBottom: "15px",
    fontWeight: 500,
    fontSize: "1.15em",
  },
};

const SelectorCard = ({
  campaignOrderList,
  campaignOrderListLoading,
  setSearchName,
  onCampaignOrderListChange,
  changeValue,
  campaignName,
  selectedCampaignListData,
  setIsCloningClicked,
  handleUpdateCampaignOrder,
  handleCloneCampaignOrder,
}) => {
  const [cloneModal, setCloneModal] = useState(false);
  const [newCampaignOrderModal, setNewCampaignOrderModal] = useState(false);

  const isSelectorChange = option => {
    if (option === "cloneCampaignOrder") {
      setCloneModal(true);
    } else if (option === "newCampaignOrder") {
      setNewCampaignOrderModal(true);
      changeValue("selectedCampaignOrderListId", "");
      changeValue("campaignName", "");
      changeValue("description", "");
      changeValue("type", 1); // If type is equal to display = 1 else type is equal to Paid Search = 6
      changeValue("startDate", null);
      changeValue("endDate", null);
      changeValue("isRegulated", true);
      changeValue("category", "IAB7-5");
      changeValue("budget", null);
      changeValue("cpm", 7);
      changeValue("targetingCriteria", null);
      changeValue("geoTargets", []);
      changeValue("cloneGeoTargetData", []);
      changeValue("adUnits", [
        { banners: [], clickthrough: null, name: null, selectedRows: [] },
      ]);
      changeValue("awarenessCampaign", false);
      changeValue("transactionTarget", "");
      changeValue("cart", "");
      changeValue("tracksBasketItems", false);
      changeValue("traffId", "");
      changeValue("gtmAccess", false);
      changeValue("gtmStatus", "");
      changeValue("appId", "");
      changeValue("cloneAttributionTargets", []);
    }
  };

  return (
    <>
      <Row
        style={{
          padding: "30px",
        }}
      >
        <Col span={24}>
          <Button
            style={{ marginRight: "8px" }}
            onClick={() => isSelectorChange("newCampaignOrder")}
          >
            New Campaign Order
          </Button>
          <Button onClick={() => isSelectorChange("cloneCampaignOrder")}>
            Clone Existing Campaign Order
          </Button>
        </Col>
      </Row>

      <Modal
        title="My Campaigns"
        centered
        visible={cloneModal}
        width={1000}
        onCancel={() => setCloneModal(false)}
        closable={true}
        footer={null}
      >
        <CampaignOrderListTable
          campaignOrderList={campaignOrderList}
          campaignOrderListLoading={campaignOrderListLoading}
          setSearchName={setSearchName}
          onCampaignOrderListChange={onCampaignOrderListChange}
          changeValue={changeValue}
          selectedCampaignListData={selectedCampaignListData}
          setIsCloningClicked={setIsCloningClicked}
          setCloneModal={setCloneModal}
          handleUpdateCampaignOrder={handleUpdateCampaignOrder}
          handleCloneCampaignOrder={handleCloneCampaignOrder}
        />
      </Modal>

      <Modal
        title="Create New Campaign Order"
        centered
        visible={newCampaignOrderModal}
        onOk={() => setNewCampaignOrderModal(false)}
        onCancel={() => setNewCampaignOrderModal(false)}
        width={1000}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Title level={4} style={styles.title}>
                Name of Campaign Order
              </Title>
            </Row>
            <Row>
              <Input
                value={campaignName}
                onChange={e => {
                  changeValue("campaignName", e.target.value);
                }}
                //removed white spaces from the start and end of the string
                onBlur={e => {
                  changeValue("campaignName", e.target.value.trim());
                }}
                placeholder="Enter a New Campaign Order Name"
              />
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SelectorCard;
