import React from "react";
import { Col, Row } from "antd";
//local
import OptimizationTypeCard from "./OptimizationTypeCard";
import RetargetingCard from "./RetargetingCard";
import CampaignModelCard from "./CampaignModelCard";

const Frag = React.Fragment;
const styles = {
  rows: {
    borderBottom: "1px solid #e8e8e8",
  },
};

const selectedRadioTop = (
  objectiveExistingCustomers,
  objectiveNewCustomers
) => {
  if (objectiveNewCustomers === 100) return "NEW";
  if (objectiveExistingCustomers === 100) return "EXISTING";
  else return "BOTH";
};

const selectedRadioBoth = (
  objectiveExistingCustomers,
  objectiveNewCustomers
) => {
  if (objectiveExistingCustomers === null && objectiveNewCustomers === null)
    return "AUTOMATIC";
  if (objectiveNewCustomers > 0 && objectiveExistingCustomers < 100)
    return "MANUAL";
  return null;
};

const OptimizationModalCard = ({
  onChange,
  onSlider,
  objectiveNewCustomers,
  objectiveExistingCustomers,
  retargetDayCap,
  retargetTotalCap,
  kpiGoal,
  kpi,
  budget,
  whichActive,
}) => {
  const CampaignModalCardTopRadio = selectedRadioTop(
    objectiveExistingCustomers,
    objectiveNewCustomers
  );

  const CampaignModalCardBothRadio = selectedRadioBoth(
    objectiveExistingCustomers,
    objectiveNewCustomers
  );

  return (
    <Frag>
      <Row gutter={12}>
        <Col span={24}>
          <Row style={styles.rows}>
            <Col span={24}>
              <CampaignModelCard
                objectiveExistingCustomers={objectiveExistingCustomers}
                objectiveNewCustomers={objectiveNewCustomers}
                onSlider={onSlider}
                radioTopValue={CampaignModalCardTopRadio}
                radioBothValue={CampaignModalCardBothRadio}
                onChange={onChange}
                whichActive={whichActive}
                border={false}
              />
            </Col>
          </Row>
          <Row style={styles.rows}>
            <Col span={24}>
              <RetargetingCard
                retargetDayCap={retargetDayCap}
                retargetTotalCap={retargetTotalCap}
                onChange={onChange}
                whichActive={whichActive}
                border={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OptimizationTypeCard
                kpi={kpi}
                kpiGoal={kpiGoal}
                budget={budget}
                onChange={onChange}
                whichActive={whichActive}
                border={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Frag>
  );
};

export default OptimizationModalCard;
