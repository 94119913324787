import gql from "graphql-tag";

const CAMPAIGN_ORDER_ATTRIBUTION_TARGETS = gql`
  query CampaignOrderDetailsAttributionTargets($ID: ID) {
    campaignOrder(where: { id: $ID }) {
      id
      type
      repullVWI
      attributionWindow
      startDate
      endDate
      aggregateData {
        overallData {
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
        }
        dayPacingData {
          aggEvents
          aggCost
          aggTotalCost
          aggImpressions
          aggClicks
        }
      }
      attributionTargets {
        vwiUpdated
        id
        name
        start
        end
        status
        orgs {
          id
          name
        }
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
        }
      }
    }
  }
`;

export default CAMPAIGN_ORDER_ATTRIBUTION_TARGETS;
