import gql from "graphql-tag";

const GET_ATTRIBUTION_REPORTS_FOR_ALL_CAMPAIGNS = gql`
  query campaignOrders(
    $orderBy: CampaignOrderOrderByInput
    $where: CampaignOrderWhereInput!
  ) {
    campaignOrders(orderBy: $orderBy, where: $where) {
      id
      reports(orderBy: createdAt_DESC, first: 1, where: { type: ATTRIBUTION }) {
        id
        type
        key
        createdAt
      }
    }
  }
`;

export default GET_ATTRIBUTION_REPORTS_FOR_ALL_CAMPAIGNS;
