import React from "react";

import { Col, Typography } from "antd";

function ArticleContent({ title, content }) {
  return (
    <Col>
      <Typography.Title>{title}</Typography.Title>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Col>
  );
}

export default ArticleContent;
