import React from "react";
import { Menu, Dropdown, Space, Checkbox } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import { columnsObj, permanentKeys } from ".";

const menu = ({ selected, setSelected }) => (
  <Menu>
    {Object.entries(columnsObj)
      .map(c => c[1])
      .filter(
        c =>
          permanentKeys.indexOf(c.key) < 0 && c.key !== columnsObj.Actions.key
      )
      .map(c => (
        <Menu.Item
          key={c.key}
          onClick={() => {
            const idx = selected.indexOf(c.key);
            if (idx >= 0) {
              // already active
              setSelected(selected.filter((x, i) => i !== idx));
            } else {
              // add
              setSelected([...selected, c.key]);
            }
          }}
        >
          <Space size={4} direction="horizontal">
            <Checkbox checked={selected.indexOf(c.key) >= 0} />
            {c.title}
          </Space>
        </Menu.Item>
      ))}
  </Menu>
);

const ColumnsDropdown = ({ selected, setSelected }) => {
  return (
    <Dropdown trigger={["click"]} overlay={menu({ selected, setSelected })}>
      <MoreOutlined />
    </Dropdown>
  );
};

export default ColumnsDropdown;
