import gql from "graphql-tag";

const UPDATE_ORG_DEAL_ID = gql`
  mutation updateDealId($id: ID!, $data: DealIdUpdateInput!) {
    updateDealId(where: { id: $id }, data: $data) {
      id
      name
      dealId
    }
  }
`;

export default UPDATE_ORG_DEAL_ID;
