import React from "react";
import { Card, Col, Tag, Row, Space, Typography } from "antd";
import moment from "moment";
import { formatWholeNumberNaN } from "../../../../../core/utils/campaigns";
import SignUpsIntroductionsModal from "./SignUpsInstructionsModal";
import { CopyBlock } from "react-code-blocks";

const { Text } = Typography;

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }

  return color;
};

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }

  return color;
};

const SignUpsCard = ({ signUpsTag }) => {
  const { signups, date } = signUpsTag;

  return (
    <Card title={`SIGN-UPS TAG`} extra={<SignUpsIntroductionsModal />}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Text type="secondary">
            Results shown below may be delayed up to 1 hour
          </Text>
        </Col>
        <Col span={6}>
          <Tag
            key={1}
            color={colorPicker(date, signups)}
            style={{ width: "100%" }}
          >
            <Space direction="vertical" size={0}>
              <Text type={typePicker(date, signups)} strong>
                Sign-Ups
              </Text>
              <Text type={typePicker(date, signups)} strong>
                {formatWholeNumberNaN(signups)}
              </Text>
              <Text type={typePicker(date, signups)} disabled>
                {date
                  ? moment(date).format("YYYY/MM/DD - HH:mm:ss")
                  : "Signal Unknown"}
              </Text>
            </Space>
          </Tag>
        </Col>
        <Col span={24}>
          <Card>
            <CopyBlock
              text={`<script>
  window.tracker("trackSelfDescribingEvent", {
    schema: "iglu:com.mediajel.events/sign_up/jsonschema/1-0-2",
    data: {
      uuid: “1234”, // user identifier - string,
      firstName: “john”, // user first name - string,
      lastName: “evans”, // user last name - string,
      emailAddress: “evans@gmail.com”, // email address - string,
      hashedEmailAddress: “evans@gmail.com”, // email address - string,
      phoneNumber: “+13056863198” // optional, phone number - string,
    },
  });
</script>
`}
              language="javascript"
              wrapLongLines
              theme="dracula"
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default SignUpsCard;
