import React, { useState, useEffect } from "react";
import OptOutForm from "./components/OptOutForm";
import WhiteLabel from "../../shared/WhiteLabel";

function OptOutView() {
  const [imageURL, setImageURL] = useState("");
  const [companyName, setCompanyName] = useState("");

  const result = WhiteLabel();

  function extractHostname(url) {
    let hostname;
    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }
    hostname = hostname.split(":")[0];
    hostname = hostname.split("?")[0];
    return hostname;
  }

  useEffect(() => {
    const extractRootDomainNoExt = url => {
      let domain = extractHostname(url),
        splitArr = domain.split("."),
        arrLen = splitArr.length;

      if (arrLen === 2) {
        domain = splitArr[0];
      } else if (arrLen > 2) {
        domain = splitArr[arrLen - 2];

        if (
          splitArr[arrLen - 2].length === 2 &&
          splitArr[arrLen - 1].length === 2
        ) {
          domain = splitArr[arrLen - 3];
        }
      }
      return domain;
    };
    setImageURL(result);
    setCompanyName(extractRootDomainNoExt(window.location.hostname));
  }, [result]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={imageURL} alt="logo" style={{ maxHeight: "70%" }} />
      </div>
      <div
        style={{
          width: "100%",
          height: "30%",
          backgroundColor: "#EFEEED",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: "#262421",
            opacity: "0.9",
            display: "flex",
            fontSize: "6vmin",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          DO NOT SELL MY PERSONAL <br /> INFORMATION / OPT OUT REQUEST FORM
        </h1>
      </div>

      <br />
      <br />
      <br />
      <OptOutForm companyName={companyName} />
    </div>
  );
}

export default OptOutView;
