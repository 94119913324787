import React, { useState } from "react";
import moment from "moment";
import { cloneDeep, isEqual } from "lodash";
import {
  computeCPC,
  computeROAS,
  computeCTR,
  computeCPM,
  calculatePercentageDifference,
  computeConversionRate,
} from "../../../../utils/paidSearch/campaigns";

const PaidSearchGoogleController = ({
  data,
  orgId,
  children,
  selectedStartDate,
  selectedEndDate,
  possibleFields,
  defaultColumns,
  total,
  pageSize,
  current,
  hasDateParams,
  id,
  ...props
}) => {
  const { reportCampaigns, setGoogleReport, setGoogleRevenueLocations } = props;
  const [summary, setSummary] = useState(data || []);
  const [campaigns, setCampaigns] = useState(data.paidSearchCampaigns || []);
  const [revenueLocations, setRevenueLocations] = useState(
    data.overallLocations || null
  );
  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);
  const [kpiPercentage, setKpiPercentage] = useState({
    percentages: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      ctr: 0,
      cpc: 0,
      roas: 0,
      revenue: 0,
      conversionRate: 0,
      cost: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      conversions: 0,
      ctr: 0,
      cpc: 0,
      roas: 0,
      revenue: 0,
      conversionRate: 0,
      cost: 0,
    },
    ranges: "",
  });

  const defaultRevLocMonthDates =
    revenueLocations && revenueLocations.byDate.length
      ? [
          moment(data.overallLocations.byDate[0].date).utc(),
          moment(data.overallLocations.byDate.at(-1).date).utc(),
        ]
      : [];

  const [revLocMonthlyDates, setRevLocMonthlyDates] = useState(
    defaultRevLocMonthDates
  );
  const allLocations = [
    ...((summary.config && summary.config.locationsKey) || []),
    "Others",
  ];
  const [selectedLocations, setSelectedLocations] = useState(allLocations);
  const [selectedTableColumns, setSelectedTableColumns] = useState(
    defaultColumns
  );

  const defaultSummaryDates = data.overallData
    ? [
        moment(data.overallData.byDate[0].xAxis).utc(),
        moment(data.overallData.byDate.at(-1).xAxis).utc(),
      ]
    : [];

  const processPercentage = (dates, current, prev = null) => {
    let daysDiff = moment(dates[1]).diff(moment(dates[0]), "days") || 0;
    if (daysDiff > 0) daysDiff++;
    const datesToCompare =
      [
        moment(dates[0]).subtract(daysDiff, "days"),
        moment(dates[1]).subtract(daysDiff, "days"),
      ] || [];

    const cloneSummary = prev !== null ? prev : cloneDeep(data);
    const cByDate = cloneSummary.overallData.byDate;
    const filterByDate = cByDate.filter(obj => {
      if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
      return (
        obj.date >= datesToCompare[0].unix() &&
        obj.date <= datesToCompare[1].unix()
      );
    });
    const previous =
      filterByDate.length > 0 &&
      filterByDate.reduce((conso, obj) => ({
        clicks: conso.clicks + obj.clicks,
        impressions: conso.impressions + obj.impressions,
        conversions: conso.conversions + obj.conversions,
        cost: conso.cost + obj.cost,
        spend: conso.spend + obj.spend,
        revenue: conso.revenue + obj.revenue,
      }));

    if (previous) {
      if (isNaN(previous.cost)) previous.cost = previous.spend;
      previous.ctr = computeCTR(previous.clicks, previous.impressions);
      previous.cpc = computeCPC(previous.cost, previous.clicks);
      previous.roas = computeROAS(previous.revenue, previous.cost);
      previous.conversionRate = computeConversionRate(
        previous.clicks,
        previous.conversions
      );
      setKpiPercentage({
        percentages: {
          impressions: calculatePercentageDifference(
            current.impressions,
            previous.impressions
          ),
          clicks: calculatePercentageDifference(
            current.clicks,
            previous.clicks
          ),
          transactions: calculatePercentageDifference(
            current.conversions,
            previous.conversions
          ),
          ctr: calculatePercentageDifference(current.ctr, previous.ctr),
          cpc: calculatePercentageDifference(current.cpc, previous.cpc),
          roas: calculatePercentageDifference(current.roas, previous.roas),
          revenue: calculatePercentageDifference(
            current.revenue,
            previous.revenue
          ),
          conversionRate: calculatePercentageDifference(
            current.conversionRate,
            previous.conversionRate
          ),
          spend: calculatePercentageDifference(current.cost, previous.cost),
        },
        previousValues: previous,
        ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
      });
    } else {
      setKpiPercentage({
        percentages: {
          impressions: 0,
          clicks: 0,
          transactions: 0,
          ctr: 0,
          cpc: 0,
          roas: 0,
          revenue: 0,
          conversionRate: 0,
          cost: 0,
        },
        previousValues: {
          impressions: 0,
          clicks: 0,
          conversions: 0,
          ctr: 0,
          cpc: 0,
          roas: 0,
          revenue: 0,
          conversionRate: 0,
          cost: 0,
        },
        ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
      });
    }
  };

  const onChangeMonthRange = dates => {
    const _dates = dates || defaultRevLocMonthDates;
    setRevLocMonthlyDates(_dates);
    const cloneLocationRev = cloneDeep(data.overallLocations);
    const filterByDateLocationRev = cloneLocationRev.byDate.filter(obj => {
      if (typeof obj.date === "string") obj.date = moment(obj.date).utc();
      return (
        obj.date >= _dates[0].utc() &&
        obj.date <= _dates[1].utc() &&
        selectedLocations.find(l => obj.location === l)
      );
    });

    cloneLocationRev.byDate = filterByDateLocationRev;
    cloneLocationRev.byDay = revenueLocations.byDay;
    setRevenueLocations(cloneLocationRev);
    setGoogleRevenueLocations(cloneLocationRev);
  };

  const setStartDateEndDate = dates => {
    const url = window.location.pathname.split("/");

    const params = new URLSearchParams(window.location.search);
    if (params.get("startDate")) params.delete("startDate");
    if (params.get("endDate")) params.delete("endDate");

    params.append("startDate", dates[0].utc().format());
    params.append("endDate", dates[1].utc().format());

    props.history.push(
      `/paid_search/details/${id}/${url[4]}?${params.toString()}`
    );
  };

  const processDatePickerChange = dates => {
    let cloneSummary = cloneDeep(data);
    const cByDate = cloneSummary.overallData.byDate;

    const filterByDate = cByDate.filter(obj => {
      if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
      return obj.date >= dates[0].unix() && obj.date <= dates[1].unix();
    });

    cloneSummary.overallData.byDate = filterByDate;
    if (filterByDate && filterByDate.length > 0) {
      const consolidatedCount = filterByDate.reduce((conso, obj) => ({
        clicks: conso.clicks + obj.clicks,
        impressions: conso.impressions + obj.impressions,
        keywords: conso.keywords + obj.keywords,
        locations: conso.locations + obj.locations,
        conversions: conso.conversions + obj.conversions,
        cost: conso.cost + obj.cost,
        revenue: conso.revenue + obj.revenue,
      }));

      const cpm = computeCPM(
        consolidatedCount.cost,
        consolidatedCount.impressions
      );
      const ctr = computeCTR(
        consolidatedCount.clicks,
        consolidatedCount.impressions
      );
      const cpc = computeCPC(consolidatedCount.cost, consolidatedCount.clicks);
      const roas = computeROAS(
        consolidatedCount.revenue,
        consolidatedCount.cost
      );
      const conversionRate = computeConversionRate(
        consolidatedCount.clicks,
        consolidatedCount.conversions
      );

      cloneSummary.overallData.clicks = consolidatedCount.clicks;
      cloneSummary.overallData.impressions = consolidatedCount.impressions;
      cloneSummary.overallData.conversions = consolidatedCount.conversions;
      cloneSummary.overallData.keywords = consolidatedCount.keywords;
      cloneSummary.overallData.locations = consolidatedCount.locations;
      cloneSummary.overallData.cost = consolidatedCount.cost;
      cloneSummary.overallData.revenue = consolidatedCount.revenue;
      cloneSummary.overallData.cpm = cpm;
      cloneSummary.overallData.spend = consolidatedCount.cost;
      cloneSummary.overallData.ctr = ctr;
      cloneSummary.overallData.cpc = cpc;
      cloneSummary.overallData.roas = roas;
      cloneSummary.overallData.conversionRate = conversionRate;
    } else {
      cloneSummary.overallData.clicks = 0;
      cloneSummary.overallData.impressions = 0;
      cloneSummary.overallData.conversions = 0;
      cloneSummary.overallData.keywords = 0;
      cloneSummary.overallData.locations = 0;
      cloneSummary.overallData.cost = 0;
      cloneSummary.overallData.revenue = 0;
      cloneSummary.overallData.cpm = 0;
      cloneSummary.overallData.spend = 0;
      cloneSummary.overallData.ctr = 0;
      cloneSummary.overallData.cpc = 0;
      cloneSummary.overallData.roas = 0;
      cloneSummary.overallData.conversionRate = 0;
    }

    setSummary(cloneSummary);
    setGoogleReport(cloneSummary);
    let cloneCampaigns = cloneDeep(data.paidSearchCampaigns);
    const filteredCampaign = cloneCampaigns.map(c => {
      let cloneCampaign = c;
      const camByDate = cloneCampaign.data.byDate;

      const camFilterByDate = camByDate.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
        return obj.date >= dates[0].unix() && obj.date <= dates[1].unix();
      });
      cloneCampaign.data.byDate = camFilterByDate;

      if (camFilterByDate && camFilterByDate.length > 0) {
        const consolidatedCount = camFilterByDate.reduce((conso, obj) => ({
          clicks: conso.clicks + obj.clicks,
          impressions: conso.impressions + obj.impressions,
          keywords: conso.keywords + obj.keywords,
          locations: conso.locations + obj.locations,
          conversions: conso.conversions + obj.conversions,
          spend: conso.spend + obj.spend,
          revenue: conso.revenue + obj.revenue,
        }));

        cloneCampaign.data.clicks = consolidatedCount.clicks;
        cloneCampaign.data.impressions = consolidatedCount.impressions;
        cloneCampaign.data.conversions = consolidatedCount.conversions;
        cloneCampaign.data.revenue = consolidatedCount.revenue;
        cloneCampaign.data.spend = consolidatedCount.spend;
      } else {
        cloneCampaign.data.clicks = 0;
        cloneCampaign.data.impressions = 0;
        cloneCampaign.data.conversions = 0;
        cloneCampaign.data.revenue = 0;
        cloneCampaign.data.spend = 0;
      }

      return cloneCampaign;
    });
    setCampaigns(filteredCampaign);

    const cloneLocationRev = cloneDeep(data.overallLocations);
    if (cloneLocationRev) {
      const filterByDateLocationRev = cloneLocationRev.byDay.filter(obj => {
        let tempDate =
          typeof obj.date === "string"
            ? moment(obj.date).utc()
            : obj.date.utc();
        return tempDate >= dates[0].utc() && tempDate <= dates[1].utc();
      });
      cloneLocationRev.byDay = filterByDateLocationRev;
      setRevenueLocations(cloneLocationRev);
      setGoogleRevenueLocations(cloneLocationRev);
    }

    processPercentage(dates, cloneSummary.overallData);
  };

  const processLocationsChange = (
    dates,
    selectLocations = selectedLocations
  ) => {
    let cloneCampaigns = cloneDeep(reportCampaigns);
    //Don't check for others
    const locations = selectLocations.filter(e => e !== "Others");
    const campaignsOthers =
      selectLocations.includes("Others") &&
      cloneCampaigns.filter(e => !allLocations.find(l => e.name.includes(l)));
    const campaignsFilteredByName = [
      ...cloneCampaigns.filter(e => locations.find(l => e.name.includes(l))),
      ...(campaignsOthers || []),
    ];

    const filteredCampaign = campaignsFilteredByName.map(c => {
      let cloneCampaign = c;
      const camByDate = cloneCampaign.data.byDate;

      const camFilterByDate = camByDate.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
        return obj.date >= dates[0].unix() && obj.date <= dates[1].unix();
      });
      cloneCampaign.data.byDate = camFilterByDate;

      if (camFilterByDate && camFilterByDate.length > 0) {
        const consolidatedCount = camFilterByDate.reduce((conso, obj) => ({
          clicks: conso.clicks + obj.clicks,
          impressions: conso.impressions + obj.impressions,
          keywords: conso.keywords + obj.keywords,
          locations: conso.locations + obj.locations,
          conversions: conso.conversions + obj.conversions,
          spend: conso.spend + obj.spend,
          revenue: conso.revenue + obj.revenue,
        }));

        cloneCampaign.data.clicks = consolidatedCount.clicks;
        cloneCampaign.data.impressions = consolidatedCount.impressions;
        cloneCampaign.data.conversions = consolidatedCount.conversions;
        cloneCampaign.data.revenue = consolidatedCount.revenue;
        cloneCampaign.data.spend = consolidatedCount.spend;
      } else {
        cloneCampaign.data.clicks = 0;
        cloneCampaign.data.impressions = 0;
        cloneCampaign.data.conversions = 0;
        cloneCampaign.data.revenue = 0;
        cloneCampaign.data.spend = 0;
      }

      return cloneCampaign;
    });

    let cloneCampaignsDisplay = cloneDeep(data.paidSearchCampaigns);
    const filteredCampaignDisplay = cloneCampaignsDisplay.map(c => {
      let cloneCampaign = c;
      const camByDate = cloneCampaign.data.byDate;

      const camFilterByDate = camByDate.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
        return obj.date >= dates[0].unix() && obj.date <= dates[1].unix();
      });
      cloneCampaign.data.byDate = camFilterByDate;

      if (camFilterByDate && camFilterByDate.length > 0) {
        const consolidatedCount = camFilterByDate.reduce((conso, obj) => ({
          clicks: conso.clicks + obj.clicks,
          impressions: conso.impressions + obj.impressions,
          keywords: conso.keywords + obj.keywords,
          locations: conso.locations + obj.locations,
          conversions: conso.conversions + obj.conversions,
          spend: conso.spend + obj.spend,
          revenue: conso.revenue + obj.revenue,
        }));

        cloneCampaign.data.clicks = consolidatedCount.clicks;
        cloneCampaign.data.impressions = consolidatedCount.impressions;
        cloneCampaign.data.conversions = consolidatedCount.conversions;
        cloneCampaign.data.revenue = consolidatedCount.revenue;
        cloneCampaign.data.spend = consolidatedCount.spend;
      } else {
        cloneCampaign.data.clicks = 0;
        cloneCampaign.data.impressions = 0;
        cloneCampaign.data.conversions = 0;
        cloneCampaign.data.revenue = 0;
        cloneCampaign.data.spend = 0;
      }

      return cloneCampaign;
    });

    setCampaigns(filteredCampaignDisplay);

    let cloneSummary = cloneDeep(data);
    if (filteredCampaign.length) {
      const consolidatedCount = filteredCampaign.reduce(
        (prev, curr) => {
          return {
            byDate: [...prev.byDate, ...curr.data.byDate],
            clicks: prev.clicks + curr.data.clicks,
            impressions: prev.impressions + curr.data.impressions,
            conversions: prev.conversions + curr.data.conversions,
            spend: prev.spend + curr.data.spend,
            revenue: prev.revenue + curr.data.revenue,
          };
        },
        {
          byDate: [],
          clicks: 0,
          impressions: 0,
          conversions: 0,
          spend: 0,
          revenue: 0,
        }
      );

      const cpm = computeCPM(
        consolidatedCount.spend,
        consolidatedCount.impressions
      );
      const ctr = computeCTR(
        consolidatedCount.clicks,
        consolidatedCount.impressions
      );
      const cpc = computeCPC(consolidatedCount.spend, consolidatedCount.clicks);
      const roas = computeROAS(
        consolidatedCount.revenue,
        consolidatedCount.spend
      );
      const conversionRate = computeConversionRate(
        consolidatedCount.clicks,
        consolidatedCount.conversions
      );
      cloneSummary.overallData.clicks = consolidatedCount.clicks;
      cloneSummary.overallData.impressions = consolidatedCount.impressions;
      cloneSummary.overallData.locations = consolidatedCount.locations;
      cloneSummary.overallData.cost = consolidatedCount.spend;
      cloneSummary.overallData.revenue = consolidatedCount.revenue;
      cloneSummary.overallData.cpm = cpm;
      cloneSummary.overallData.spend = consolidatedCount.spend;
      cloneSummary.overallData.ctr = ctr;
      cloneSummary.overallData.cpc = cpc;
      cloneSummary.overallData.roas = roas;
      cloneSummary.overallData.conversions = consolidatedCount.conversions;
      cloneSummary.overallData.conversions = conversionRate;
      cloneSummary.overallData.byDate = consolidateByDate(
        consolidatedCount.byDate
      );
    } else {
      cloneSummary.overallData.clicks = 0;
      cloneSummary.overallData.impressions = 0;
      cloneSummary.overallData.conversions = 0;
      cloneSummary.overallData.cost = 0;
      cloneSummary.overallData.revenue = 0;
      cloneSummary.overallData.cpm = 0;
      cloneSummary.overallData.spend = 0;
      cloneSummary.overallData.ctr = 0;
      cloneSummary.overallData.cpc = 0;
      cloneSummary.overallData.roas = 0;
      cloneSummary.overallData.byDate = [];
    }
    setSummary(cloneSummary);
    setGoogleReport(cloneSummary);

    const cloneLocationRev = cloneDeep(data.overallLocations);
    if (cloneLocationRev) {
      const filterByDateLocationRev = cloneLocationRev.byDay.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date);
        return (
          obj.date >= dates[0] &&
          obj.date <= dates[1] &&
          locations.find(l => obj.location === l)
        );
      });

      const filterByMonthLocationRev = cloneLocationRev.byDate.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date);
        return (
          obj.date >= revLocMonthlyDates[0] &&
          obj.date <= revLocMonthlyDates[1] &&
          locations.find(l => obj.location === l)
        );
      });

      cloneLocationRev.byDay = filterByDateLocationRev;
      cloneLocationRev.byDate = filterByMonthLocationRev;

      setRevenueLocations(cloneLocationRev);
      setGoogleRevenueLocations(cloneLocationRev);

      const campaignsFilteredByNameP = [
        ...reportCampaigns.filter(e => locations.find(l => e.name.includes(l))),
        ...(campaignsOthers || []),
      ];

      let percentageSummary = cloneDeep(data);
      if (campaignsFilteredByNameP.length) {
        const consolidatedCount = campaignsFilteredByNameP.reduce(
          (prev, curr) => {
            return {
              byDate: [...prev.byDate, ...curr.data.byDate],
              clicks: prev.clicks + curr.data.clicks,
              impressions: prev.impressions + curr.data.impressions,
              conversions: prev.conversions + curr.data.conversions,
              spend: prev.spend + curr.data.spend,
              revenue: prev.revenue + curr.data.revenue,
            };
          },
          {
            byDate: [],
            clicks: 0,
            impressions: 0,
            conversions: 0,
            spend: 0,
            revenue: 0,
          }
        );

        const cpm = computeCPM(
          consolidatedCount.spend,
          consolidatedCount.impressions
        );
        const ctr = computeCTR(
          consolidatedCount.clicks,
          consolidatedCount.impressions
        );
        const cpc = computeCPC(
          consolidatedCount.spend,
          consolidatedCount.clicks
        );
        const roas = computeROAS(
          consolidatedCount.revenue,
          consolidatedCount.spend
        );
        const conversionRate = computeConversionRate(
          consolidatedCount.clicks,
          consolidatedCount.conversions
        );
        percentageSummary.overallData.clicks = consolidatedCount.clicks;
        percentageSummary.overallData.impressions =
          consolidatedCount.impressions;
        percentageSummary.overallData.locations = consolidatedCount.locations;
        percentageSummary.overallData.cost = consolidatedCount.spend;
        percentageSummary.overallData.revenue = consolidatedCount.revenue;
        percentageSummary.overallData.cpm = cpm;
        percentageSummary.overallData.spend = consolidatedCount.spend;
        percentageSummary.overallData.ctr = ctr;
        percentageSummary.overallData.cpc = cpc;
        percentageSummary.overallData.roas = roas;
        percentageSummary.overallData.roas = conversionRate;
        percentageSummary.overallData.byDate = consolidateByDate(
          consolidatedCount.byDate
        );
      } else {
        percentageSummary.overallData.clicks = 0;
        percentageSummary.overallData.impressions = 0;
        percentageSummary.overallData.conversions = 0;
        percentageSummary.overallData.cost = 0;
        percentageSummary.overallData.revenue = 0;
        percentageSummary.overallData.cpm = 0;
        percentageSummary.overallData.spend = 0;
        percentageSummary.overallData.ctr = 0;
        percentageSummary.overallData.cpc = 0;
        percentageSummary.overallData.roas = 0;
        percentageSummary.overallData.conversionRate = 0;
        percentageSummary.overallData.byDate = [];
      }

      processPercentage(dates, cloneSummary.overallData, percentageSummary);
    }
  };

  const consolidateByDate = byDates => {
    let consolidated = [];
    byDates.forEach(d => {
      let conso = consolidated.find(o => o.xAxis === d.xAxis);
      if (conso) {
        const index = consolidated.findIndex(o => o.xAxis === d.xAxis);

        consolidated[index].clicks += d.clicks;
        consolidated[index].impressions += d.impressions;
        consolidated[index].conversions += d.conversions;
        consolidated[index].spend += d.spend;
        consolidated[index].revenue += d.revenue;
        consolidated[index].cpm = computeCPM(d.spend, d.impressions);
        consolidated[index].ctr = computeCTR(d.clicks, d.impressions);
        consolidated[index].cpc = computeCPC(d.search, d.clicks);
        consolidated[index].roas = computeROAS(d.revenue, d.spend);
        consolidated[index].conversionRate = computeConversionRate(
          d.clicks,
          d.conversions
        );
      } else {
        consolidated.push({
          clicks: d.clicks ? Number(d.clicks) : 0,
          impressions: d.impressions ? Number(d.impressions) : 0,
          conversions: d.conversions ? Number(d.conversions) : 0,
          spend: d.spend ? Number(d.spend) : 0,
          revenue: d.revenue ? Number(d.revenue) : 0,
          cpm: computeCPM(d.spend, d.impressions) || 0,
          ctr: computeCTR(d.clicks, d.impressions) || 0,
          cpc: computeCPC(d.search, d.clicks) || 0,
          roas: computeROAS(d.revenue, d.spend) || 0,
          conversionRate: computeConversionRate(d.clicks, d.conversions) || 0,
          date: d.xAxis,
          xAxis: d.xAxis,
        });
      }
    });
    return consolidated;
  };

  const setBySummaryRangeDate = dates => {
    setStartDateEndDate(dates);
    setControllerbySummaryRangeDate(dates);

    if (
      isEqual(
        [...((summary.config && summary.config.locationsKey) || []), "Others"],
        selectedLocations
      )
    ) {
      processDatePickerChange(dates);
    }
    // If the selected locations are changed we will use the campaigns by date data
    // for the display in summary
    else if (!isEqual(allLocations, selectedLocations)) {
      processLocationsChange(dates);
    }
  };

  const onChangeLocationDropdown = checkedLocation => {
    setSelectedLocations(checkedLocation);

    processLocationsChange(
      bySummaryRangeDate.length
        ? bySummaryRangeDate
        : [moment(selectedStartDate), moment(selectedEndDate)],
      checkedLocation
    );
  };

  if (hasDateParams && !bySummaryRangeDate.length) {
    const dates =
      [moment(selectedStartDate).utc(), moment(selectedEndDate).utc()] || [];
    setControllerbySummaryRangeDate(dates);

    processDatePickerChange(dates);
  }

  return React.cloneElement(children, {
    summary,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    revenueLocations,
    onChangeLocationDropdown,
    setSelectedTableColumns,
    onChangeMonthRange,
    defaultSummaryDates,
    kpiPercentage,
    selectedLocations,
    campaigns,
    possibleFields,
    selectedTableColumns,
    total,
    orgId,
    pageSize,
    current,
    ...props,
  });
};

export default PaidSearchGoogleController;
