/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Typography,
  Divider,
  message,
} from "antd";
import {
  FileTextOutlined,
  EnvironmentOutlined,
  FileImageOutlined,
  CheckCircleTwoTone,
  ExclamationOutlined,
  CaretLeftOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
//local
import CardTitle from "../../shared/CardTitle";
import ReviewBasics from "./components/ReviewBasics";
// import ReviewOptimization from "./components/ReviewOptimization";
import ReviewGeotargets from "./components/ReviewGeotargets";
import ReviewCreatives from "./components/ReviewCreatives";
import StepsCard from "./components/StepsCard";
import BasicsCard from "./components/BasicsCard";
import BasicsDevicesCard from "./components/BasicsDevicesCard";
import OptimizationModalCard from "./components/OptimizationModalCard";
// import GeoTargetsModalCard from "./components/GeoTargetsModalCard";
import CreativesModalCard from "./components/CreativesModalCard";
import { GeoTargetManager } from "../Campaigns/geotargetmanager/GeoTargetManager";
import { campaignTypeMap } from "../../../core/utils/constants/constants";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";
import { css, jsx } from "@emotion/react";
import { CampaignLauncherReview } from "./components/CampaignLauncherReview";
const { Text } = Typography;
const responsiveMenu = css`
@media only screen and (max-width: 1920px) {
  .responsive-menu {
    display: block;
  }
  .responsive-menu .span-mobile{
    width: 80% !important;
  }
  @media only screen and (max-width: 768px) {
    .responsive-menu {
      display: block;
    }
    .responsive-menu .span-mobile {
      width: 100% !important;
      margin-bottom: 25px;
    }
    .mobile-nav{
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-bottom: 25px;
    }
}
`;

const styles = {
  card: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  cardBody: {
    padding: 0,
  },
  cardRow: {
    padding: "15px",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
  map: {
    position: "relative",
    height: "300px",
    width: "100%",
  },
  titleStyles: {
    iconStyle: {
      color: "#9b9b9b",
      fontSize: "22px",
    },
    textStyle: {
      color: "#9b9b9b",
    },
  },
  editButton: {
    border: 0,
    height: "25px",
    width: "25px",
  },
  modalBody: {
    padding: 0,
  },
  rows: {
    borderRight: "1px solid #64c87a",
    borderLeft: "1px solid #64c87a",
    borderTop: "1px solid #64c87a",
  },
  cardOne: {
    marginTop: "0px",
    width: "100%",
  },
  buttonOne: {
    fontSize: "12px",
    width: "90px",
  },
  buttonTwo: {
    fontSize: "12px",
    width: "90px",
    background: "#64c87a",
    border: "1px solid #64c87a",
    color: "white",
  },
  buttonTwoDisabled: {
    fontSize: "12px",
    width: "90px",
    background: "gray",
    border: "1px solid darkgray",
    color: "white",
  },
  rowOne: {
    marginBottom: "15px",
  },
  rowBasic: {
    borderBottom: "1px solid #e8e8e8",
    paddingTop: "25px",
    paddingLeft: "45px",
    paddingRight: "45px",
    paddingBottom: "45px",
  },
};

const CampaignLauncherViewReview = props => {
  const {
    currentStep,
    onStepChange,
    brands,
    campaignName,
    description,
    category,
    startDate,
    endDate,
    income,
    objectiveNewCustomers,
    objectiveExistingCustomers,
    retargetDayCap,
    retargetTotalCap,
    kpi,
    kpiGoal,
    budget,
    adUnits,
    geoTargetData,
    attributionTargets,
    handleOrderSubmit,
    showEditModal,
    whichModal,
    onChange,
    onSlider,
    whichActive,
    onChangeArrayOfObj,
    addAdUnit,
    removeBanners,
    handleUpdateCampaignOrder,
    isRegulated,
    removeAdUnit,
    tablet,
    type,
    targetingCriteria,
    cpm,
    transactionTarget,
    changeValue,
    onSubmitLoad,
    awarenessCampaign,
    cart,
    traffId,
    gtmAccess,
    gtmStatus,
    tracksBasketItems,
    advertiser,
    region,
    currentUser,
    signUpCampaign,
    handleGetBasicTagsActivities,
    campaignOrderTagsActivitiesLoading,
    campaignOrderTagsActivitiesData,
    campaignOrder,
  } = props;
  const {
    card,
    cardBody,
    titleStyles,
    modalBody,
    rowBasic,
    secondaryRow,
    textRow,
    divider,
  } = styles;

  const isAdmin =
    currentUser && currentUser.permission
      ? currentUser.permission.isAdmin
      : false;

  // TODO: Refactor this validation. Ternaly operators are hard to read on a complex validation.
  const validateThis =
    isEmpty(campaignName) ||
    isEmpty(startDate) ||
    !budget > 0 ||
    !cpm > 0 ||
    (campaignTypeMap[type] === "DISPLAY" &&
      (isEmpty(adUnits) ||
        isEmpty(adUnits[0].clickthrough) ||
        isEmpty(adUnits[0].name) ||
        isEmpty(adUnits[0].banners))) ||
    (isEmpty(transactionTarget) &&
      isEmpty(attributionTargets) &&
      awarenessCampaign === false &&
      signUpCampaign === false &&
      isEmpty(cart));

  const impressionGoalsTotal =
    budget && cpm ? Math.round((budget / cpm) * 1000).toLocaleString() : 0;

  const steps = [
    {
      title: "Total",
      descriptionTitle: [`Budget:`, `CPM: `, `Impression Goals Total: `],
      description: [
        budget ? `$ ` + budget : 0,
        cpm ? cpm.toString() : 0,
        impressionGoalsTotal,
      ],
      complete: true,
    },
  ];

  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleCampaignReview = async () => {
    if (campaignOrder.status === "DRAFT") {
      setShowReviewModal(true);
      handleGetBasicTagsActivities();
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const result = await handleOrderSubmit();

    if (result) {
      message.success(`Campaign Order has been updated`);
      changeValue("isSucessfullySubmitted", true);
    }
  };

  return onSubmitLoad === false ? (
    <Row gutter={12} css={responsiveMenu}>
      {tablet && (
        <Col span={12} xs={24} className="mobile-nav">
          <StepsCard stepsToComplete={steps} />
        </Col>
      )}
      <Col xs={24} md={24} lg={18} className="mobile-nav">
        <Card
          style={{ padding: "0px" }}
          bodyStyle={{ padding: "0px" }}
          title={
            <Col xs={24} md={24} lg={18}>
              <FileTextOutlined />
              <span> Basics & Info</span>
            </Col>
          }
        >
          <ReviewBasics
            brands={brands}
            campaignName={campaignName}
            description={description}
            startDate={startDate}
            endDate={endDate}
            category={category}
            isRegulated={isRegulated}
            budget={budget}
            cpm={cpm}
            targetingCriteria={targetingCriteria}
            awarenessCampaign={awarenessCampaign}
            cart={cart}
            transactionTarget={transactionTarget}
            traffId={traffId}
            gtmAccess={gtmAccess}
            gtmStatus={gtmStatus}
            type={type}
            tracksBasketItems={tracksBasketItems}
            name={advertiser ? advertiser.name : ""}
          />
        </Card>
        <Card
          style={card}
          title={
            <Col>
              <EnvironmentOutlined />
              <span> Targeting Addresses</span>
            </Col>
          }
          bodyStyle={cardBody}
        >
          <Col
            xs={24}
            md={24}
            lg={24}
            style={{ paddingLeft: "34px", paddingTop: "20px" }}
          >
            <Row style={secondaryRow}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Text type="secondary">Targeting Criteria</Text>
                <Row style={textRow}>
                  <Text strong>
                    {targetingCriteria ? targetingCriteria : "None Added"}
                  </Text>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Text type="secondary">Targeting Regions</Text>
                <Row style={textRow}>
                  <Text strong>{region ? region : "None Added"}</Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={24} lg={22}>
            <ReviewGeotargets
              geoTargets={geoTargetData}
              hideRowSelection={true}
            />
          </Col>
        </Card>
        {type !== 2 && (
          <Card
            style={card}
            bodyStyle={{ padding: "0px" }}
            title={
              <Col>
                <FileImageOutlined />
                <span> Creatives</span>
              </Col>
            }
          >
            <ReviewCreatives
              adUnits={adUnits}
              isAdmin={isAdmin}
              startDate={startDate}
              endDate={endDate}
            />
          </Card>
        )}
        {tablet && (
          <div>
            <Row style={styles.rows}>
              <Card size="small" style={styles.cardOne}>
                <Row type="flex" justify="space-around">
                  <Button
                    style={styles.buttonOne}
                    shape="round"
                    onClick={() => onStepChange(currentStep - 1)}
                  >
                    Back
                  </Button>
                  <Button
                    style={styles.buttonTwo}
                    type="primary"
                    shape="round"
                    onClick={() => handleCampaignReview()}
                    disabled={
                      validateThis && type !== 7
                      // 7 Means External
                      // TODO: Check if payment specified here
                    }
                  >
                    Submit
                  </Button>
                </Row>
              </Card>
            </Row>
          </div>
        )}
      </Col>
      {!tablet && (
        <Col span={6}>
          <div>
            <Row style={styles.rowOne}>
              <Col xs={24}>
                <StepsCard stepsToComplete={steps} />
              </Col>
            </Row>
            <Row>
              <Card size="small" style={styles.cardOne}>
                <Row type="flex" justify="space-around">
                  <Button
                    style={styles.buttonOne}
                    shape="round"
                    onClick={() => onStepChange(currentStep - 1)}
                    icon={<CaretLeftOutlined />}
                  >
                    Back
                  </Button>
                  <Button
                    style={
                      validateThis ? styles.buttonTwoDisabled : styles.buttonTwo
                    }
                    type="primary"
                    shape="round"
                    onClick={() => handleCampaignReview()}
                    disabled={
                      validateThis && type !== 7
                      // TODO: Check if payment specified here
                    }
                    icon={
                      validateThis ? (
                        <ExclamationOutlined />
                      ) : (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      )
                    }
                  >
                    Submit
                  </Button>
                </Row>
              </Card>
            </Row>
          </div>
        </Col>
      )}
      <Col xs={24} md={24} lg={18}>
        {!isEmpty(attributionTargets) && (
          <Card
            style={card}
            title={
              <Col>
                <EnvironmentOutlined />
                <span> Walk ins</span>
              </Col>
            }
          >
            <Row style={rowBasic}>
              <Col span={24}>
                <Row style={secondaryRow}>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">Awareness Campaign</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>
                        {awarenessCampaign === true ? "True" : "False"}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">Basket Items</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>
                        {tracksBasketItems === true ? "True" : "False"}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">Ecommerce - Cart</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>{cart ? cart : "None"}</Text>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">App ID/MAID</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>
                        {transactionTarget ? transactionTarget : "None"}
                      </Text>
                    </Row>
                  </Col>
                </Row>
                <Divider style={divider} />
                <Row style={secondaryRow}>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">Retail ID</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>{traffId ? traffId : "None"}</Text>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">GTM Access</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>
                        {gtmAccess === true ? "True" : "False"}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 6 }}>
                    <Row style={textRow}>
                      <Text type="secondary">GTM Status</Text>
                    </Row>
                    <Row style={textRow}>
                      <Text strong>
                        {gtmStatus ? gtmStatus : "Not Enabled"}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ReviewGeotargets
              geoTargets={attributionTargets}
              hideRowSelection={true}
            />
          </Card>
        )}
      </Col>

      {showReviewModal && (
        <CampaignLauncherReview
          visible={showReviewModal}
          loading={campaignOrderTagsActivitiesLoading}
          setVisible={setShowReviewModal}
          handleProceed={handleSubmit}
          adveriserName={advertiser ? advertiser.name : ""}
          activities={
            campaignOrderTagsActivitiesData &&
            campaignOrderTagsActivitiesData.campaignOrderTagsActivities
          }
        />
      )}

      <Modal
        onOk={() => {
          handleUpdateCampaignOrder();
          onChange("showEditModal", false);
        }}
        destroyOnClose={true}
        visible={showEditModal}
        onCancel={() => {
          onChange("showEditModal", false);
        }}
        width={tablet ? 750 : whichModal === "geoTargets" ? 1200 : 800}
        closable={false}
        bodyStyle={modalBody}
        okButtonProps={{
          disabled:
            isEmpty(campaignName) ||
            isEmpty(category) ||
            isEmpty(income) ||
            !budget > 0 ||
            !kpiGoal > 0 ||
            isEmpty(adUnits[0].clickthrough) ||
            isEmpty(adUnits[0].banners),
        }}
      >
        {whichModal === "basics" ? (
          <Card
            bordered={false}
            style={card}
            title={
              <CardTitle
                titleText="Basics"
                iconType="question-circle"
                gutter={10}
                styles={titleStyles}
              />
            }
            bodyStyle={cardBody}
          >
            <BasicsCard
              onChange={onChange}
              campaignName={campaignName}
              startDate={startDate}
              endDate={endDate}
              brands={brands}
              category={category}
              isRegulated={isRegulated}
            />
          </Card>
        ) : whichModal === "demographics" ? (
          <Card
            bordered={false}
            style={card}
            title={
              <CardTitle
                titleText="Devices"
                iconType="search"
                gutter={10}
                styles={titleStyles}
              />
            }
            bodyStyle={cardBody}
          >
            <BasicsDevicesCard border={false} />
          </Card>
        ) : whichModal === "optimization" ? (
          <OptimizationModalCard
            onChange={onChange}
            onSlider={onSlider}
            objectiveNewCustomers={objectiveNewCustomers}
            objectiveExistingCustomers={objectiveExistingCustomers}
            retargetDayCap={retargetDayCap}
            retargetTotalCap={retargetTotalCap}
            kpi={kpi}
            kpiGoal={kpiGoal}
            budget={budget}
            whichActive={whichActive}
          />
        ) : whichModal === "geoTargets" ? (
          <GeoTargetManager modal {...props} />
        ) : whichModal === "creatives" ? (
          <CreativesModalCard
            onChangeArrayOfObj={onChangeArrayOfObj}
            adUnits={adUnits}
            addAdUnit={addAdUnit}
            removeBanners={removeBanners}
            removeAdUnit={removeAdUnit}
            isAdmin={isAdmin}
          />
        ) : (
          <div />
        )}
      </Modal>
    </Row>
  ) : (
    <OverlaySpinner />
  );
};

export default CampaignLauncherViewReview;
