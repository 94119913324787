import React from "react";
import { Row, Col, Card, message, Popover } from "antd";
import moment from "moment";
import Button from "../../../shared/globalStyling/Button";
import { isEmpty, upperFirst } from "lodash";
import { SyncOutlined } from "@ant-design/icons";

const styles = {
  text: {
    fontSize: "12px",
  },
};

const DetailsCard = ({
  startGeoTargetObservations,
  selectedGeoTargets,
  inProgressOverlay,
  demographicsData,
  demographicsLoading,
}) => {
  return (
    <Card
      style={{
        height: "100%",
      }}
    >
      <div style={{ marginBottom: "5px" }}>Details</div>
      {selectedGeoTargets && selectedGeoTargets.length > 0 && (
        <div style={styles.text}>
          Segments: {selectedGeoTargets && selectedGeoTargets.length}
        </div>
      )}
      <div style={styles.text}>
        Shapes:{" "}
        {selectedGeoTargets && selectedGeoTargets.length > 0
          ? selectedGeoTargets &&
            selectedGeoTargets.reduce(
              (acc, curr) => acc + curr.circles.length + curr.polygons.length,
              0
            )
          : inProgressOverlay && inProgressOverlay.length}
      </div>
      <div style={styles.text}>
        Observations:{" "}
        {selectedGeoTargets &&
          selectedGeoTargets.reduce(
            (acc, curr) =>
              isNaN(parseInt(curr.observations))
                ? acc
                : acc + parseInt(curr.observations),
            0
          )}
      </div>
      {selectedGeoTargets && selectedGeoTargets.length === 1 && (
        <React.Fragment>
          <Row
            type="flex"
            align="middle"
            gutter={20}
            style={{ marginTop: "50px" }}
          >
            <Col>Last Updated:</Col>
            <Button
              icon={<SyncOutlined />}
              style={{
                width: "32px",
                height: "32px",
                padding: 0,
                margin: 0,
              }}
              onClick={async () => {
                const launch = await startGeoTargetObservations({
                  variables: { id: selectedGeoTargets[0].id },
                });
                if (launch) {
                  message.success(
                    "Updating Segment - check again in a couple minutes"
                  );
                }
              }}
            />
          </Row>
          <Row style={styles.text}>
            {selectedGeoTargets[0].vwiUpdatedOnSpot
              ? moment(selectedGeoTargets[0].vwiUpdatedOnSpot).format(
                  "MM/DD/YYYY h:mm a"
                )
              : "Never"}
          </Row>
          <Row style={{ marginTop: "50px" }}>Demographics:</Row>
          <Row>
            <Popover
              placement="left"
              content={
                <Row style={{ padding: "20px", width: "100%" }} type="flex">
                  {Object.entries(demographicsData).map(([key, value]) => {
                    return (
                      <Col key={key} style={{ margin: "0 20px" }}>
                        <Row style={{ fontWeight: 500, fontSize: "18px" }}>
                          {key
                            .split("_")
                            .map(str => upperFirst(str))
                            .join(" ")}
                        </Row>
                        {Object.entries(value)
                          .sort((a, b) => {
                            if (a[1] === b[1]) return 0;
                            if (a[1] > b[1]) return -1;
                            return 1;
                          })
                          .map(([k, val]) => (
                            <Row key={k}>{k + " - " + val}</Row>
                          ))}
                      </Col>
                    );
                  })}
                </Row>
              }
              title={null}
              trigger="click"
            >
              <Button
                loading={demographicsLoading}
                disabled={isEmpty(demographicsData)}
              >
                {demographicsLoading
                  ? ""
                  : isEmpty(demographicsData)
                  ? "None"
                  : "View"}
              </Button>
            </Popover>
          </Row>
        </React.Fragment>
      )}
    </Card>
  );
};

export { DetailsCard };
