import React from "react";

const TagsManagerPublisherController = ({
  orgs,
  currentOrgId,
  createEventTag,
  onSuccess = () => {},
  ...props
}) => {
  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  const onSubmit = async values => {
    await createEventTag({
      variables: {
        data: {
          name: values.name,
          orgId: currentOrgId,
          publisherDomain:
            values.domainProtocol + values.domainUrl + values.domainExtension,
          isPublisherDomain: true,
          isEcommerce: false,
          isSignUps: false,
        },
      },
    });
    onSuccess(values);
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentOrgId,
    advertisers,
    orgs,
    onSubmit,
  });
};

export default TagsManagerPublisherController;
