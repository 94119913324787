import React from "react";
import { Switch, Route } from "react-router-dom";

import AIMainView from "../AIMainView";
import AIMainLoader from "../../../../core/components/ai/AIMainLoader";

const AiRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={() => (
            <AIMainLoader>
              <AIMainView />
            </AIMainLoader>
          )}
        />
      </Switch>
    </div>
  );
};

export default AiRoutes;
