import React, { Component } from "react";
import cubejs from "@cubejs-client/core";
import { isEmpty, cloneDeep, times } from "lodash";
import { parseOverlayToGeoTarget } from "../../../utils/geoTargets";
import { ReportLoader } from "./ReportLoader";
import UGLIFYJS from "../../../GraphQl/Queries/UGLIFYJS";
import moment from "moment";
import { stateList } from "../../../../core/utils/constants/constants";

class Controller extends Component {
  state = {
    selectedGeoTargets: [],
    selectedRetargeting: [],
    inProgressOverlay: [],
    shouldClearShapes: false,
    name: "",
    description: "",
    tags: [],
    eventTags: [],
    startDate: null,
    endDate: null,
    checkSwitchMode: true,
    checkTabSegment: "geotargets",
    activeKeyTab: "geotargets",
    type: "ATTRIBUTION",
    afterEventsTargetSelected: false,
  };

  fetchGeoTargetData = async () => {
    // DEPRECATED: For use with Cube.js
    const { token, geoTargets } = this.props;
    if (isEmpty(geoTargets)) {
      this.setState({
        geoTargetsWithObservations: geoTargets,
      });

      return;
    }

    let geoTargetsWithObservations = [];

    try {
      const cubejsApi = cubejs(token, {
        apiUrl: process.env.REACT_APP_CUBE_JS_ENDPOINT,
      });

      const ResultSet = await cubejsApi.load({
        measures: ["VWI.count"],
        timeDimensions: [
          {
            dimension: "VWI.timestamp",
          },
        ],
        dimensions: ["VWI.geoTargetId"],
        filters: [
          {
            dimension: "VWI.geoTargetId",
            operator: "equals",
            values: geoTargets.map(t => {
              return t.id;
            }),
          },
        ],
      });

      if (ResultSet) {
        geoTargetsWithObservations = geoTargets.map(geotarget => {
          let observationCount = 0;
          const { id } = geotarget;
          const { data } = ResultSet.loadResponse;
          data.forEach(d => {
            if (id === d["VWI.geoTargetId"]) {
              observationCount = d["VWI.count"];
            }
          });
          const record = {
            ...geotarget,
            observations: observationCount,
          };

          return record;
        });
      }

      this.setState({
        geoTargetsWithObservations: geoTargetsWithObservations,
      });
    } catch (error) {
      console.log(error);
    }
  };

  changeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  deleteGeoTargets = async () => {
    const deleteMany = await Promise.all(
      this.state.selectedGeoTargets.map(async geoTarget => {
        await this.props.deleteGeoTarget({
          variables: { id: geoTarget.id },
        });
      })
    );
    this.setState({
      inProgressOverlay: [],
      selectedGeoTargets: [],
      name: "",
      description: "",
      tags: [],
      startDate: null,
      endDate: null,
    });
    if (deleteMany) return true;
    return false;
  };

  deleteEventsTarget = async () => {
    const deleteMany = await Promise.all(
      this.state.selectedRetargeting.map(async eventTarget => {
        await this.props.deleteEventsTarget({
          variables: { id: eventTarget.id },
        });
      })
    );
    this.setState({
      selectedRetargeting: [],
      name: "",
      description: "",
      eventTags: [],
      startDate: null,
      endDate: null,
    });
    if (deleteMany) return true;
    return false;
  };

  selectTableRow = (record, selected, multiple) => {
    const { name, description, tags, start, end, location } = record;
    let selectedGeoTargets = cloneDeep(this.state.selectedGeoTargets);
    if (selected)
      selectedGeoTargets = multiple
        ? [...selectedGeoTargets, ...record]
        : [...selectedGeoTargets, record];
    else {
      const recordsToRemove = multiple ? record : [record];
      recordsToRemove.forEach(record => {
        const indexToUpdate = selectedGeoTargets.findIndex(
          elem => elem.id === record.id
        );
        selectedGeoTargets.splice(indexToUpdate, 1);
      });
    }

    const nextState = {
      selectedGeoTargets,
      name: "",
      description: "",
      tags: [],
      startDate: null,
      endDate: null,
      shouldClearShapes: true,
      state: "",
      tempName: "",
      country: "",
      formattedAddress: "",
      lat: 0,
      lng: 0,
      street: "",
      city: "",
      zip: "",
      locationId: null,
    };
    if (
      selectedGeoTargets.length === 1 &&
      this.state.checkSwitchMode === true &&
      this.state.checkTabSegment === "geotargets"
    ) {
      if (name) nextState.name = name;
      if (description) nextState.description = description;
      if (tags) nextState.tags = tags;
      if (start) nextState.startDate = start;
      if (end) nextState.endDate = end;
      if (location) {
        nextState.locationId = location.id;
        nextState.tempName = location.name;
        nextState.country = location.country;
        nextState.formattedAddress = location.formattedAddress;
        nextState.lat = location.lat;
        nextState.lng = location.lng;
        nextState.street = location.street;
        nextState.city = location.city;
        nextState.state = location.state;
        nextState.zip = location.zip;
      }
    }
    this.setState(nextState);
  };

  selectTableRowRetargeting = (record, selected, multiple) => {
    const { name, description, start, end, eventTags } = record;
    const {
      checkSwitchMode,
      checkTabSegment,
      afterEventsTargetSelected,
    } = this.state;
    let selectedRetargeting = cloneDeep(this.state.selectedRetargeting);
    if (selected)
      selectedRetargeting = multiple
        ? [...selectedRetargeting, ...record]
        : [...selectedRetargeting, record];
    else {
      const recordsToRemove = multiple ? record : [record];
      recordsToRemove.forEach(record => {
        const indexToUpdate = selectedRetargeting.findIndex(
          elem => elem.id === record.id
        );
        selectedRetargeting.splice(indexToUpdate, 1);
      });
    }
    const nextState = {
      selectedRetargeting,
      name: "",
      description: "",
      eventTags: [],
      startDate: null,
      endDate: null,
    };
    if (
      selectedRetargeting.length === 1 &&
      checkSwitchMode === true &&
      checkTabSegment === "retargeting" &&
      afterEventsTargetSelected === false
    ) {
      if (name) nextState.name = name;
      if (description) nextState.description = description;
      if (start) nextState.startDate = start;
      if (end) nextState.endDate = end;
      if (eventTags) nextState.eventTags = eventTags;
    }
    this.setState(nextState);
  };

  createGeoTargetFromOverlay = async () => {
    try {
      const { circles, polygons } = parseOverlayToGeoTarget(
        this.state.inProgressOverlay
      );
      const {
        name,
        description,
        tags,
        start,
        end,
        tempName,
        country,
        formattedAddress,
        lat,
        lng,
        street,
        city,
        state,
        zip,
      } = this.state;

      const varStart =
        window.localStorage.getItem("showNewSegments") == null
          ? start
          : moment()
              .subtract(30, "days")
              .format();
      const varEnd =
        window.localStorage.getItem("showNewSegments") == null
          ? end
          : moment().format();
      const createdGeoTarget = await this.props.createGeoTarget({
        variables: {
          name,
          description,
          start: varStart,
          end: varEnd,
          tags,
          circles,
          polygons,
          orgid: this.props.currentUser.role.org.id,
          isBase: true,
          location: tempName
            ? {
                name: tempName,
                country,
                formattedAddress: formattedAddress
                  ? formattedAddress
                  : `${tempName}, ${state}, ${zip}`,
                lat: lat ? lat : 39.8097343,
                lng: lng ? lng : -98.5556199,
                street,
                city,
                state,
                zip,
              }
            : null,
        },
      });
      if (createdGeoTarget) {
        const {
          data: { createGeoTarget },
        } = createdGeoTarget;

        this.props.startGeoTargetObservations({
          variables: { id: createGeoTarget.id },
        });
        this.setState({
          inProgressOverlay: [],
          selectedGeoTargets: [],
          name: "",
          description: "",
          tags: [],
          startDate: null,
          endDate: null,
        });
        this.toggleShouldClearShapes();
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  createEventsTarget = async () => {
    try {
      const { name, description, startDate, endDate, tags } = this.state;
      const genRandomString = times(8, () =>
        ((Math.random() * 0xf) << 0).toString(16)
      ).join("");
      const appId = String(name.replace(/[^0-9a-zA-Z]/g, "") + genRandomString);
      const client = this.props.client;

      const trackerUrlBase = `collector.dmp.cnna.io`;
      const hostedTracker = `//dm2q9qfzyjfox.cloudfront.net/sp.js`;

      const jsCode = `(function(p, l, o, w, i, n, g) {
         if (!p[i]) {
           p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
           p.GlobalSnowplowNamespace.push(i);
           p[i] = function() {
             (p[i].q = p[i].q || []).push(arguments);
           };
           p[i].q = p[i].q || [];
           n = l.createElement(o);
           g = l.getElementsByTagName(o)[0];
           n.async = 1;
           n.src = w;
           g.parentNode.insertBefore(n, g);
         }
       })(
         window,
         document,
         "script",
         "${hostedTracker}",
         "${appId}"
       );
       window.${appId}("newTracker", "cf", "${trackerUrlBase}", {
         // Initialise a tracker
         appId: "${appId}",
         discoverRootDomain: true,
         stateStorageStrategy: "localStorage",
         respectDoNotTrack: true,
       });
       window.${appId}("enableActivityTracking", 30, 10);
       window.${appId}("trackPageView");
       window.${appId}("enableLinkClickTracking");

       setTimeout(
         function(p, l, o, w, i, n, g) {
           if (!p[i]) {
             p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
             p.GlobalSnowplowNamespace.push(i);
             p[i] = function() {
               (p[i].q = p[i].q || []).push(arguments);
             };
             p[i].q = p[i].q || [];
             n = l.createElement(o);
             g = l.getElementsByTagName(o)[0];
             n.decoding = "async";
             n.src = w;
             n.height = 0;
             n.width = 0;
             n.border = 0;
             // g is potentially undefined. don't actually add the img to the DOM
             //g.parentNode.insertBefore(n, g);
           }
         },
         1000,
         window,
         document,
         "img",
         "https://sync.dmp.cnna.io/cs",
         "Sync"
       );`;

      const uglifiedJsCode = await client.query({
        query: UGLIFYJS,
        variables: {
          jscode: jsCode,
        },
      });

      const uglifiedString = uglifiedJsCode.data.uglifyJS.code;
      const snippet = `<script type='text/javascript'>${uglifiedString}</script>`;

      const createdEventsTarget = await this.props.createEventsTarget({
        variables: {
          name,
          description,
          start: startDate,
          end: endDate,
          tags,
          eventTags: [{ data: [snippet] }],

          // To review with org and createEventsTarget
          // orgid: this.props.currentUser.role.org.id,
        },
      });

      if (createdEventsTarget) {
        this.setState({
          name: "",
          description: "",
          startDate: null,
          endDate: null,
          tags: [],
          eventTags: [],
        });

        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  createAudienceFromSelectedSegments = async () => {
    try {
      const { name, description, tags, type, selectedGeoTargets } = this.state;

      const getFilteredSegGeo = selectedGeoTargets.filter(val => {
        return val.__typename === "GeoTarget";
      });

      const getFilteredSegEvents = selectedGeoTargets.filter(val => {
        return val.__typename === "EventsTarget";
      });

      const audience = await this.props.createAudience({
        variables: {
          name,
          description,
          tags,
          type,
          geoTargets: getFilteredSegGeo.map(geoTarget => {
            return { id: geoTarget.id };
          }),
          eventsTargets: getFilteredSegEvents.map(geoTarget => {
            return { id: geoTarget.id };
          }),
          orgid: this.props.currentUser.role.org.id,
        },
      });
      if (audience) {
        this.setState({
          inProgressOverlay: [],
          selectedGeoTargets: [],
          name: "",
          description: "",
          tags: [],
          startDate: null,
          endDate: null,
          type: "ATTRIBUTION",
        });
        this.toggleShouldClearShapes();
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  editEventTargetsFromSelection = async () => {
    try {
      const targetToUpdate = this.state.selectedRetargeting[0];
      const { name, description, startDate, endDate } = this.state;
      const updatedEventsTarget = await this.props.updateEventsTarget({
        variables: {
          id: targetToUpdate.id,
          name,
          description,
          start: startDate,
          end: endDate,
        },
      });
      if (updatedEventsTarget) {
        this.setState({
          name: "",
          description: "",
          startDate: null,
          endDate: null,
        });
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  editGeoTargetFromSelection = async () => {
    // NOTE: deletes and re-creates the changed Geo Target so that it has a new ID to populate the snowflake tables for
    try {
      const targetToUpdate = this.state.selectedGeoTargets[0];
      const { circles, polygons } = parseOverlayToGeoTarget(
        this.state.inProgressOverlay
      );
      const {
        name,
        description,
        startDate,
        endDate,
        tags,
        locationId,
        flights,
        tempName,
        country,
        formattedAddress,
        lat,
        lng,
        street,
        city,
        state,
        zip,
      } = this.state;

      const locationMap = locationId
        ? {
            connect: {
              id: locationId,
            },
          }
        : {
            create: {
              name: tempName,
              country,
              formattedAddress: formattedAddress
                ? formattedAddress
                : `${tempName}, ${state}, ${zip}`,
              lat: lat ? lat : 39.8097343,
              lng: lng ? lng : -98.5556199,
              street,
              city,
              state,
              zip,
            },
          };

      const updatedGeoTarget = await this.props.updateSegment({
        variables: {
          id: targetToUpdate.id,
          name,
          description,
          start: startDate,
          end: endDate,
          tags,
          circles,
          polygons,
          location: locationMap,
          flights: flights
            ? flights.map(item => ({
                id: item.id,
              }))
            : null,
          orgid: this.props.currentUser.role.org.id,
        },
      });

      if (updatedGeoTarget) {
        const {
          data: { updateSegment },
        } = updatedGeoTarget;
        this.props.startGeoTargetObservations({
          variables: { id: updateSegment.id },
        });
        this.setState({
          inProgressOverlay: [],
          selectedGeoTargets: [],
          name: "",
          description: "",
          tags: [],
          startDate: null,
          endDate: null,
        });
        this.toggleShouldClearShapes();
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  toggleShouldClearShapes = () => {
    this.setState({ shouldClearShapes: !this.state.shouldClearShapes });
  };

  updateSelectedRowStartEndDate = async record => {
    try {
      const variables = {
        ...record,
        description: record.description !== null ? record.description : "",
        flights: record.flights.map(item => ({
          id: item.id,
        })),
        start: moment()
          .subtract(30, "days")
          .format(),
        end: moment().format(),
        tags: record.tags,
        location: record.location
          ? {
              connect: {
                id: record.location.id,
              },
            }
          : null,
        circles: record.circles.map(circle => ({
          coordinate: {
            create: {
              lat: circle.coordinate.lat,
              lng: circle.coordinate.lng,
            },
          },
          radius: circle.radius,
        })),
        polygons: record.polygons.map(polygon => ({
          geometry: polygon.geometry,
        })),
        orgid: this.props.currentUser.role.org.id,
      };

      const updatedGeoTarget = await this.props.updateSegment({
        variables,
      });
      if (updatedGeoTarget) {
        const {
          data: { updateSegment },
        } = updatedGeoTarget;
        this.props.startGeoTargetObservations({
          variables: { id: updateSegment.id },
        });
        this.setState({
          inProgressOverlay: [],
          selectedGeoTargets: [],
          name: "",
          description: "",
          tags: [],
          startDate: null,
          endDate: null,
        });
        this.toggleShouldClearShapes();
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  changeLocationMap = place => {
    let tempAdd;

    if (place.length === 1) {
      const {
        name,
        address_components,
        geometry,
        formatted_address,
      } = place[0];

      if (formatted_address) {
        tempAdd = formatted_address.split(",");
        tempAdd.shift();

        const joinAdd = tempAdd.join(",");
        const country = tempAdd[tempAdd.length - 1].trim();

        this.changeValue("country", country ? country : "USA");
        this.changeValue("formattedAddress", joinAdd);
      }

      if (geometry) {
        const { location } = geometry;
        if (location) {
          this.changeValue("lat", location.lat());
          this.changeValue("lng", location.lng());
        }
      }

      if (address_components) {
        this.changeValue("tempName", name ? name : "");
        this.changeValue(
          "street",
          address_components[0] && address_components[1]
            ? `${address_components[0].long_name} ${address_components[1].long_name}`
            : ""
        );
        this.changeValue(
          "city",
          address_components[3] ? `${address_components[3].long_name}` : ""
        );
        const getState =
          address_components[5] && address_components[5].long_name
            ? stateList.filter(
                state => state === address_components[5].long_name.toUpperCase()
              )
            : "";

        this.changeValue(
          "state",
          address_components[5] && address_components[5].long_name
            ? `${getState}`
            : ""
        );

        this.changeValue(
          "country",
          address_components[6] && address_components[6].short_name
            ? `${
                address_components[6].short_name.includes("CA")
                  ? "CANADA"
                  : "USA"
              }`
            : ""
        );

        this.changeValue(
          "zip",
          address_components[7] ? `${address_components[7].long_name}` : ""
        );
      }

      //can add in everything we can on a places match
    } else {
      console.log("we have many need exact");
    }
  };

  submitForm = async () => {
    const length = this.state.selectedGeoTargets.length;
    const lengthEventsTarget = this.state.selectedRetargeting.length;
    const checkMode = this.state.checkSwitchMode;
    const checkTabSegment = this.state.checkTabSegment;

    if (length >= 1 && checkMode === false) {
      return await this.createAudienceFromSelectedSegments();
    } else if (
      length === 1 &&
      checkMode === true &&
      checkTabSegment === "geotargets"
    ) {
      return await this.editGeoTargetFromSelection();
    } else if (
      lengthEventsTarget === 1 &&
      checkMode === true &&
      checkTabSegment === "retargeting"
    ) {
      return await this.editEventTargetsFromSelection();
    } else if (
      length === 0 &&
      checkMode === true &&
      checkTabSegment === "geotargets"
    ) {
      return await this.createGeoTargetFromOverlay();
    } else if (
      lengthEventsTarget === 0 &&
      checkMode === true &&
      checkTabSegment === "retargeting"
    ) {
      return await this.createEventsTarget();
    }
  };

  render() {
    return (
      <ReportLoader
        {...this.state}
        {...this.props}
        changeLocationMap={this.changeLocationMap}
        updateSelectedRowStartEndDate={this.updateSelectedRowStartEndDate}
        changeValue={this.changeValue}
        selectTableRow={this.selectTableRow}
        selectTableRowRetargeting={this.selectTableRowRetargeting}
        createGeoTargetFromOverlay={this.createGeoTargetFromOverlay}
        createEventsTarget={this.createEventsTarget}
        toggleShouldClearShapes={this.toggleShouldClearShapes}
        submitForm={this.submitForm}
        deleteGeoTargets={this.deleteGeoTargets}
        deleteEventsTarget={this.deleteEventsTarget}
      />
    );
  }
}

export default Controller;
