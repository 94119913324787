import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SettingLoader from "../../../../core/components/settings/SettingLoader";
import SettingController from "../../../../core/components/settings/SettingController";
import SettingTab from "../SettingTab";

// Feature level subroutes
const SettingsRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <SettingLoader {...props}>
            <SettingController>
              <SettingTab />
            </SettingController>
          </SettingLoader>
        )}
      />
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default SettingsRoutes;
