import React from "react";
import { Dropdown, Menu } from "antd";
import * as XLSX from "xlsx";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";

import { saveAs } from "file-saver";
import JSZip from "jszip";

const ExportExcelButton = ({
  orgName,
  generateReport,
  loadingCampaigns,
  config,
  hasBing,
  hasGoogle,
}) => {
  const hasLocationRevenue = (config && config.hasLocationRevenue) || false;
  let reports = [];

  if (hasGoogle) {
    const googleReports = [
      {
        name: `Summary Google - By Date`,
        reportName: `${orgName.replace(/\//g, " ")}-Summary - By Date`,
        key: `byDate`,
      },
      {
        name: `Summary Google - By Campaign`,
        reportName: `${orgName.replace(/\//g, " ")}-Summary - By Campaign`,
        key: `byCampaign`,
      },
    ];

    reports = [...reports, ...googleReports];
  }

  if (hasBing) {
    const bingReports = [
      {
        name: `Summary Bing - By Date`,
        reportName: `${orgName.replace(/\//g, " ")}-Summary - By Date`,
        key: `byDateBing`,
      },
      {
        name: `Summary Bing - By Campaign`,
        reportName: `${orgName.replace(/\//g, " ")}-Summary - By Campaign`,
        key: `byCampaignBing`,
      },
    ];

    reports = [...reports, ...bingReports];
  }

  if (hasLocationRevenue) {
    const locationReports = [
      {
        name: `Location - By Month`,
        reportName: `${orgName.replace(
          /\//g,
          " "
        )}-Revenue By Location - Month`,
        key: `byRevenueLoc`,
      },
      {
        name: `Location - By Date`,
        reportName: `${orgName.replace(/\//g, " ")}-Revenue By Location - Date`,
        key: `byRevenueLocByDate`,
      },
    ];

    reports = [...reports, ...locationReports];
  }

  const menu = (
    <Menu>
      {reports.map(report => {
        return (
          <Menu.Item
            key={report.key}
            onClick={() => {
              try {
                const name = report.reportName;
                const zip = new JSZip();
                const folder = zip.folder(name);
                const fileData = generateReport(report.key);

                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const ws = XLSX.utils.json_to_sheet(fileData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const excelData = new Blob([excelBuffer], { type: fileType });

                folder.file(`${name}.xlsx`, excelData);
                zip.generateAsync({ type: "blob" }).then(
                  blob => {
                    saveAs(blob, name);
                    return true;
                  },
                  function(e) {
                    console.log("error", e);
                    return false;
                  }
                );
              } catch (err) {
                console.log(`Excel error: ${err}`);
              }
            }}
          >
            {report.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomCenter"
      disabled={loadingCampaigns}
    >
      <Button
        style={{ margin: "0px" }}
        icon={loadingCampaigns ? <LoadingOutlined /> : <DownloadOutlined />}
        type="primary"
      >
        <ButtonText text={"Export Excel"} />
      </Button>
    </Dropdown>
  );
};

export default ExportExcelButton;
