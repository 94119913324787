import { useContext } from "react";
import CampaignOrderQueueContext from "./CampaingOrderQueueProvider";

export const useCampaignOrderQueueContext = () => {
  const context = useContext(CampaignOrderQueueContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
