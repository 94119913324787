import { Chart, Coord, Axis, Geom, Tooltip } from "bizcharts";
import React from "react";

const DonutChart = ({
  axisName = "value",
  position = "counts",
  showTooltip = false,
  chartData = [{ value: "Placeholder", counts: 10, color: "Blue" }],
  height = 300,
  totalCount,
}) => {
  const cols = {
    percent: {
      formatter: val => {
        val = (val / totalCount) * 100;
        return `${val.toFixed(2)}%`;
      },
    },
    counts: {
      formatter: val => {
        const percentage = totalCount
          ? `(${((val / totalCount) * 100).toFixed(2)}%)`
          : "";
        return `${val.toLocaleString()} ${percentage}`;
      },
    },
  };

  return (
    <Chart
      forceFit
      data={chartData}
      padding={"auto"}
      height={height / 1.1}
      scale={cols}
    >
      <Coord type="theta" radius={0.75} innerRadius={0.65} />
      <Axis name={axisName} />
      {showTooltip && <Tooltip showTitle={false} />}
      <Geom
        color={[
          axisName,
          value => {
            const index = chartData.findIndex(el => el[axisName] === value);
            if (chartData[index]) return chartData[index].color;
            else return "black";
          },
        ]}
        position={position}
        type="intervalStack"
      />
    </Chart>
  );
};

export default DonutChart;
