import gql from "graphql-tag";

const GET_ROLL_UP_DISPLAY = gql`
  query getRollUpDisplay(
    $orgId: String!
    $startDate: DateTime
    $endDate: DateTime
    $parentOrgId: String
    $advertiserIds: [String]
    $attributionWindow: Int
  ) {
    getRollUpDisplay(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      parentOrgId: $parentOrgId
      advertiserIds: $advertiserIds
      attributionWindow: $attributionWindow
    )
  }
`;

export default GET_ROLL_UP_DISPLAY;
