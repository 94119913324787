import React, { useState } from "react";
import { Row, Col, Card, Dropdown, Menu, Tooltip } from "antd";
import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiPercentageText,
} from "../../../../../../../../shared/globalStyling/styledText";

import {
  formatWholeNumber,
  formatNumber,
  formatCurrency2SigFig,
  computeROAS,
} from "../../../../../../../../../core/utils/campaigns";

import { roasFormat } from "../../../../../../../../../core/utils/constants/constants";
const DetailBar = ({
  impressions = 0,
  clicks = 0,
  phoneCalls = 0,
  transactions = 0,
  ctr = 0,
  cpc = 0,
  roas = 0,
  revenue = 0,
  spend = 0,
  conversionRate = 0,
  showPrices,
  kpiPercentage,
  currencyFormat,
  roasSelectedFormat,
}) => {
  const [roasFormatSelected, setRoasFormatSelected] = useState(
    roasSelectedFormat.key
  );
  const styles = {
    container: {
      backgroundColor: "#fff",
      borderTop: "solid 1px #e8e8e8",
      marginBottom: "1%",
    },
    secondaryContainer: {
      padding: "3% 3% 1% 3%",
      margin: 0,
    },
    title: {
      marginBottom: "10px",
    },
    keyColors: {
      spent: "#1665d8",
      remaining: "#34aa44",
      budget: "#030303",
      total: "#bec0c3",
      value: "#99999",
    },
  };
  const { percentages, previousValues, ranges } = kpiPercentage;
  return (
    <>
      <Row style={styles.container} wrap={false}>
        <Col xs={24}>
          <Row>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Impressions"} />
                </div>
                <KpiSubText text={`${formatWholeNumber(impressions)}`} />
                <KpiPercentageText
                  text={percentages.impressions}
                  prev={formatWholeNumber(previousValues.impressions)}
                  ranges={ranges}
                />
              </Card>
            </Col>
            <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
              <Card
                style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                bodyStyle={{ borderLeft: 0 }}
              >
                <div style={styles.title}>
                  <KPITitleText text={"Clicks"} />
                </div>
                <KpiSubText text={`${formatWholeNumber(clicks)}`} />
                <KpiPercentageText
                  text={percentages.clicks}
                  prev={formatWholeNumber(previousValues.clicks)}
                  ranges={ranges}
                />
              </Card>
            </Col>
            {phoneCalls !== 0 && false && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Phone Calls"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(phoneCalls) ? formatWholeNumber(phoneCalls) : 0
                    }`}
                  />
                </Card>
              </Col>
            )}

            {transactions !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Transactions"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(transactions) ? formatWholeNumber(transactions) : 0
                    }`}
                  />
                  <KpiPercentageText
                    text={percentages.transactions}
                    prev={formatWholeNumber(previousValues.conversions)}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {ctr !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"CTR"} />
                  </div>
                  <KpiSubText
                    text={`${!isNaN(ctr) ? formatNumber(ctr) : 0}%`}
                  />
                  <KpiPercentageText
                    text={percentages.ctr}
                    prev={formatNumber(previousValues.ctr) + "%"}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {cpc !== 0 && showPrices && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"CPC"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(cpc)
                        ? formatCurrency2SigFig(cpc, currencyFormat)
                        : 0
                    }`}
                  />
                  <KpiPercentageText
                    text={percentages.cpc}
                    prev={formatCurrency2SigFig(
                      previousValues.cpc,
                      currencyFormat
                    )}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {conversionRate !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Conv. Rate"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(conversionRate) ? formatNumber(conversionRate) : 0
                    }%`}
                  />
                  <KpiPercentageText
                    text={percentages.conversionRate}
                    prev={formatNumber(previousValues.conversionRate) + "%"}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {roas !== 0 && showPrices && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <Row align="top">
                      <Col xs={19}>
                        <KPITitleText text={"ROAS"} />
                      </Col>
                      <Col xs={4}>
                        <Dropdown
                          placement="bottomLeft"
                          overlay={
                            <Menu>
                              {roasFormat.map(d => {
                                return (
                                  <Menu.Item
                                    key={d.key}
                                    onClick={() => {
                                      setRoasFormatSelected(d.key);
                                    }}
                                  >
                                    {d.name}
                                  </Menu.Item>
                                );
                              })}
                            </Menu>
                          }
                        >
                          <MoreOutlined />
                        </Dropdown>
                      </Col>
                      <Col xs={1}>
                        <Tooltip
                          title={`ROAS Format can be changed by pressing the more icon besides this tooltip. 
                          Select the desired format of the ROAS on the dropdown. If the account is admin it can be changed in the org config`}
                          arrowPointAtCenter={true}
                          placement="rightBottom"
                        >
                          <QuestionCircleOutlined
                            type="question-circle"
                            style={{ margin: "0 auto" }}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                  <KpiSubText
                    text={computeROAS(
                      revenue,
                      spend,
                      roasFormatSelected,
                      true,
                      currencyFormat
                    )}
                  />
                  <KpiPercentageText
                    text={percentages.roas}
                    prev={computeROAS(
                      percentages.revenue,
                      percentages.spend,
                      roasFormatSelected,
                      true,
                      currencyFormat
                    )}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {revenue !== 0 && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Revenue"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(revenue)
                        ? formatCurrency2SigFig(revenue, currencyFormat)
                        : 0
                    }`}
                  />
                  <KpiPercentageText
                    text={percentages.revenue}
                    prev={formatCurrency2SigFig(
                      previousValues.revenue,
                      currencyFormat
                    )}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
            {spend !== 0 && showPrices && (
              <Col className="ant-col-md-flex-auto" xs={{ span: 24 }}>
                <Card
                  style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
                  bodyStyle={{ borderLeft: 0 }}
                >
                  <div style={styles.title}>
                    <KPITitleText text={"Spend"} />
                  </div>
                  <KpiSubText
                    text={`${
                      !isNaN(spend)
                        ? formatCurrency2SigFig(spend, currencyFormat)
                        : 0
                    }`}
                  />
                  <KpiPercentageText
                    text={percentages.spend}
                    prev={formatCurrency2SigFig(
                      previousValues.cost,
                      currencyFormat
                    )}
                    ranges={ranges}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DetailBar;
