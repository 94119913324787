/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Typography,
  Col,
  Row,
  message,
  Radio,
} from "antd";
import moment from "moment";
import { useAudienceBuilderContext } from "../../context/AudienceBuilderContext";
import { METRICS_ORDER_BY_VALUE } from "../../constants/constants";

const { Text } = Typography;

const TypeBasedInput = props => {
  const {
    inputValue,
    setInputValue,
    setMetricValue,
    metric,
    options,
    dataType,
  } = props;

  let minNumber = 0;
  let maxNumber = 0;
  let rangeNumber = null;

  const {
    metricValue,
    selectedCheckboxes,
    setSelectedCheckboxes,
    columnCount,
    addAsNewCard,
    setAddAsNewCard,
  } = useAudienceBuilderContext();

  const handleInputChange = props => {
    try {
      const { value, providerSubCategory, uniqueId } = props;
      setMetricValue({
        value: value,
        key: providerSubCategory,
        name: metric.name,
        categoryName: metric.categoryName,
        dataType: dataType,
        uniqueId: uniqueId,
      });
    } catch (error) {
      message.error("Error setting metric value");
    }
  };

  const handleChange = (values, providerSubCategory, uniqueId) => {
    setSelectedCheckboxes(values);

    handleInputChange({
      value: values.map(label => ({
        label: label.label,
        value:
          label.providerDataPoint != null
            ? label.providerDataPoint
            : label.value,
      })),
      providerSubCategory,
      uniqueId,
    });
  };

  const handleCheckboxChange = (
    checked,
    item,
    providerSubCategory,
    uniqueId
  ) => {
    const newValues = checked
      ? [...selectedCheckboxes, item]
      : selectedCheckboxes.filter(
          selectedItem => selectedItem.label !== item.label
        );

    handleChange(newValues, providerSubCategory, uniqueId);
  };

  const renderUI = () => {
    try {
      const { inputCriteria, providerSubCategory } = metric.mappings;
      const uniqueId = metric.uniqueId ? metric.uniqueId : null;

      if (inputCriteria) {
        const { range = null } = inputCriteria;
        rangeNumber = range;
      }

      if (rangeNumber) {
        const { min = 0, max = 100 } = rangeNumber;
        minNumber = min;
        maxNumber = max;
      }

      switch (dataType) {
        case "string":
          return (
            <Input
              placeholder="Enter a value"
              allowClear
              value={inputValue}
              onChange={e => {
                const value = e.target.value;
                setInputValue(value);
                handleInputChange({ value, providerSubCategory, uniqueId });
              }}
            />
          );
        case "date":
          // Initialize datePicker Value to null
          let datePickerValue = null;

          // If inputValue has value, initialize datePickerValue to moment object
          // so that it can be displayed in the DatePicker
          if (inputValue != null) {
            datePickerValue = moment(inputValue, "YYYY/MM/DD");
          }

          return (
            <DatePicker
              value={datePickerValue}
              format="YYYY/MM/DD"
              style={{ width: "100%" }}
              onChange={e => {
                const value = e ? e.format("YYYY/MM/DD") : null;
                handleInputChange({
                  value,
                  providerSubCategory,
                  uniqueId,
                });
                if (e) {
                  setInputValue(e);
                }
              }}
            />
          );
        case "boolean":
          return (
            <Radio.Group
              value={inputValue}
              onChange={v => {
                const value = v.target.value;
                setInputValue(value);
                handleInputChange({
                  value: value ? 1 : 0,
                  providerSubCategory,
                  uniqueId,
                });
              }}
            >
              <Radio value={true}>True</Radio>
              <Radio value={false}>False</Radio>
            </Radio.Group>
          );
        case "number":
          return (
            <>
              <InputNumber
                placeholder={`Enter a value between ${minNumber} and ${maxNumber}`}
                style={{ width: "100%" }}
                type="number"
                value={inputValue}
                onChange={value => {
                  if (value > maxNumber) {
                    message.error(`Value cannot be greater than ${maxNumber}`);
                    setInputValue(null);
                  } else if (value < minNumber) {
                    message.error(`Value cannot be less than ${minNumber}`);
                    setInputValue(null);
                  } else {
                    setInputValue(value);
                    handleInputChange({
                      value,
                      providerSubCategory,
                      uniqueId,
                    });
                  }
                }}
              />
              {(minNumber !== undefined || maxNumber !== undefined) && (
                <div style={{ marginTop: 4 }}>
                  {minNumber !== undefined && (
                    <Text type="secondary" style={{ marginRight: 8 }}>
                      Min: {minNumber}
                    </Text>
                  )}
                  {maxNumber !== undefined && (
                    <Text type="secondary">Max: {maxNumber}</Text>
                  )}
                </div>
              )}

              {metricValue && metricValue.uniqueId && (
                <Checkbox
                  checked={addAsNewCard}
                  onChange={e => {
                    setAddAsNewCard(!addAsNewCard);
                  }}
                >
                  Add as a new Card
                </Checkbox>
              )}
            </>
          );
        case "checkbox":
          return (
            <div>
              {options.reduce((result, item, index) => {
                const columnSpan = columnCount === 2 ? 12 : 24;
                const column = (
                  <Col
                    span={columnSpan}
                    key={item.label}
                    styles={{ marginRight: "0.1em" }}
                  >
                    <Checkbox
                      checked={selectedCheckboxes.some(
                        selectedItem => selectedItem.label === item.label
                      )}
                      onChange={e => {
                        handleCheckboxChange(
                          e.target.checked,
                          item,
                          providerSubCategory,
                          uniqueId
                        );
                        setInputValue(e.target.checked);
                      }}
                      className="custom-checkbox"
                    >
                      {item.label}
                    </Checkbox>
                  </Col>
                );

                if (columnCount === 2) {
                  if (index % 2 === 0) {
                    result.push(<Row key={index}>{[column]}</Row>);
                  } else {
                    result[result.length - 1].props.children.push(column);
                  }
                } else {
                  result.push(<Row key={index}>{[column]}</Row>);
                }

                return result;
              }, [])}
            </div>
          );
        default:
          let formattedValue = [];

          if (inputValue && !Array.isArray(inputValue)) {
            formattedValue = [inputValue];
          } else if (inputValue === undefined || inputValue === null) {
            formattedValue = [];
          } else {
            formattedValue = inputValue;
          }

          return (
            <Select
              mode={"multiple"}
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              value={formattedValue}
              placeholder="Please select"
              onChange={(value, option) => {
                setInputValue(value);
                handleInputChange({
                  value: option,
                  providerSubCategory,
                  uniqueId,
                });
              }}
              options={
                METRICS_ORDER_BY_VALUE.includes(
                  metric.mappings.providerSubCategory
                )
                  ? options.map(option => {
                      return {
                        label: option.label,
                        value: option.providerDataPoint,
                        key: option.providerLabel,
                      };
                    })
                  : options
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map(option => {
                        return {
                          label: option.label,
                          value: option.providerDataPoint,
                          key: option.providerLabel,
                        };
                      })
              }
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          );
      }
    } catch (err) {
      console.log(err);
      return <></>;
    }
  };

  return renderUI();
};

export default TypeBasedInput;
