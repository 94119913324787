import React from "react";

const TagsManagerController = ({
  orgs,
  currentOrgId,
  createEventTag,
  carts,
  ...props
}) => {
  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  const onSubmit = values => {
    const advertiser = orgs.orgs.find(org => values.advertiser === org.id);
    let domain = null;
    const parentChannelPartner = advertiser.parentOrg.find(
      org => org.level === "CHANNEL_PARTNER" && org.tagDomain !== null
    );
    if (advertiser.level === "CHANNEL_PARTNER") {
      domain = advertiser.tagDomain;
    } else if (parentChannelPartner) {
      domain = parentChannelPartner.tagDomain;
    }
    createEventTag({
      variables: {
        data: {
          name: values.name,
          orgId: values.advertiser,
          isEcommerce: values.ecommerce,
          cart: values.platform,
          isSignUps: values.signups,
          domain,
        },
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertisers,
    currentOrgId,
    carts: carts.getCarts,
    onSubmit,
  });
};

export default TagsManagerController;
