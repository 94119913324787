import React from "react";
import { Card, Skeleton } from "antd";

//locals
import SettingDetailsView from "./tabs/setting/SettingDetailsView";

const OrgConfigView = ({ selectedOrg }) => {
  return (
    <React.Fragment>
      {selectedOrg ? (
        <Card>
          <SettingDetailsView selectedOrg={selectedOrg} />
        </Card>
      ) : (
        <Skeleton avatar paragraph={{ rows: 10 }} />
      )}
    </React.Fragment>
  );
};

export default OrgConfigView;
