import React from "react";
import { graphql, Query } from "react-apollo";
import compose from "lodash/flowRight";
import GET_CAMPAIGN_REPORT from "../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import Controller from "./CampaignDetailsChartsController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";

const CampaignChartsDataLoader = ({ children, ...props }) => {
  const attributionReportId = props.attributionReportId
    ? props.attributionReportId
    : "null";

  try {
    return (
      <Query
        query={GET_CAMPAIGN_REPORT}
        variables={{
          reportId: attributionReportId,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <OverlaySpinner />;
          if (error)
            return `Error (Query: GET_CAMPAIGN_REPORT)! ${error.message}`;

          const { url, expiresAt } = data.getCampaignReportSignedURL;

          return (
            <Controller
              refetch={refetch}
              url={url}
              expiresAt={expiresAt}
              {...props}
            >
              {children}
            </Controller>
          );
        }}
      </Query>
    );
  } catch (err) {
    console.log("err", err);
  }
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(CampaignChartsDataLoader);
