import React from "react";
import { graphql, withApollo } from "react-apollo";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import Controller from "../ContractTerms/ContractTermsController";
import LIST_CAMPAIGNORDERS_CONTRACT_TERMS from "../../../GraphQl/Queries/LIST_CAMPAIGNORDERS_CONTRACT_TERMS";
import moment from "moment";

const ContractTermsLoader = ({ advertiserIds, ...props }) => {
  const { currentUser } = props;
  const loggedInRole = currentUser.role;
  const orgID = loggedInRole.org.id;

  const where = {
    AND: [
      {
        type_not: "CPC",
      },
      {
        OR: [
          {
            status_not_in: ["DRAFT", "PENDING"],
            AND: {
              orgs_some: {
                OR: [
                  {
                    id_in:
                      advertiserIds && advertiserIds.length
                        ? advertiserIds
                        : [orgID],
                  },
                ],
                NOT: [
                  {
                    parentOrg_some: {
                      id_in:
                        advertiserIds && advertiserIds.length
                          ? advertiserIds
                          : [orgID],
                    },
                  },
                ],
              },
            },
          },
          {
            status_not_in: ["DRAFT", "PENDING"],
            AND: {
              orgs_some: {
                OR: [
                  {
                    id_in:
                      advertiserIds && advertiserIds.length
                        ? advertiserIds
                        : [orgID],
                  },
                  {
                    parentOrg_some: {
                      id_in:
                        advertiserIds && advertiserIds.length
                          ? advertiserIds
                          : [orgID],
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      {
        AND: [
          {
            startDate_lte: moment()
              .seconds(0)
              .milliseconds(0)
              .toISOString(),
            endDate_gte: moment()
              .seconds(0)
              .milliseconds(0)
              .toISOString(),
            status_not: "DRAFT",
          },
          {
            status_not: "PENDING",
          },
          {
            status_not: "PAUSED",
          },
        ],
      },
    ],
  };

  const [
    getExport,
    { loading: loadingExport, data: dataExport },
  ] = useLazyQuery(LIST_CAMPAIGNORDERS_CONTRACT_TERMS, {
    fetchPolicy: "no-cache",
    variables: {
      where,
      orgID: advertiserIds && advertiserIds.length ? advertiserIds[0] : orgID,
      orderBy: "startDate_DESC",
    },
  });

  const { loading: loadingFilterOptions, data: filterOptions } = useQuery(
    LIST_CAMPAIGNORDERS_CONTRACT_TERMS,
    {
      variables: {
        where,
        orderBy: "name_ASC",
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [getContractTerms, { loading, error, data, refetch }] = useLazyQuery(
    LIST_CAMPAIGNORDERS_CONTRACT_TERMS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <Controller
      data={data}
      loading={loading || loadingFilterOptions}
      refetch={refetch}
      error={error}
      filterOptions={filterOptions}
      getExport={getExport}
      dataExport={dataExport}
      loadingExport={loadingExport}
      defaultWhere={where}
      getContractTerms={getContractTerms}
      {...props}
    >
      {props.children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => ({
      currentUser: ownProps.currentUser || currentUser,
    }),
  })
)(withApollo(ContractTermsLoader));
