import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";

const ConversionBarChart = ({ data }) => {
  // Process and filter data
  const sortedData = (data || [])
    .map(item => ({
      ...item,
      conversions: Number(item.conversions) || 0,
      trafficSource: item.trafficSource || "Unknown",
    }))
    .filter(item => item.conversions > 0)
    .sort((a, b) => b.conversions - a.conversions);

  // Determine tick interval according to maxValue
  const maxConversion =
    sortedData.length > 0
      ? Math.max(...sortedData.map(item => item.conversions))
      : 100;
  const getTickInterval = maxValue => {
    if (maxValue < 100) return 10;
    if (maxValue < 1000) return 100;
    if (maxValue < 10000) return 1000;
    return Math.pow(10, Math.floor(Math.log10(maxValue)) - 1);
  };
  const tickInterval = getTickInterval(maxConversion);
  const axisMax = Math.ceil(maxConversion / tickInterval) * tickInterval;

  const chartScale = {
    trafficSource: { alias: "Traffic Source" },
    conversions: {
      alias: "Conversions",
      min: 0,
      max: axisMax,
      tickInterval: tickInterval,
    },
  };

  return (
    <Chart
      height={400}
      data={sortedData}
      scale={chartScale}
      forceFit
      padding={[20, 60, 80, 100]} // Increased left padding for labels
    >
      <Axis
        name="trafficSource"
        label={{
          autoRotate: true,
          offset: 20,
          formatter: text =>
            text.length > 10 ? `${text.slice(0, 10)}...` : text,
        }}
      />
      <Axis
        name="conversions"
        position="right"
        label={{
          formatter: val => val,
        }}
      />
      <Tooltip />
      <Geom type="interval" position="trafficSource*conversions" />
    </Chart>
  );
};

export default ConversionBarChart;
