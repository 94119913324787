import React, { useState } from "react";
import { CampaignOrderQueueReviewQueueSummary } from "./CampaignOrderQueueReviewQueueSummary";
import { CampaignOrderQueueReviewLaunchSummary } from "./CampaignOrderQueueReviewLaunchSummary";
import { Button, Modal, Typography } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { Text } = Typography;

export const CampaignOrderQueueReviewSummary = ({ currentCampaignOrder }) => {
  const [showApprovedDialogSuccess, setShowApprovedDialogSuccess] = useState(
    false
  );
  const history = useHistory();

  return (
    <>
      {["PENDING", "LIVE_PENDING", "REJECTED"].includes(
        currentCampaignOrder.status
      ) && (
        <CampaignOrderQueueReviewQueueSummary
          setShowApprovedDialogSuccess={setShowApprovedDialogSuccess}
        />
      )}
      {["APPROVED", "LIVE_APPROVED"].includes(currentCampaignOrder.status) && (
        <CampaignOrderQueueReviewLaunchSummary />
      )}

      {showApprovedDialogSuccess && (
        <Modal
          visible={showApprovedDialogSuccess}
          title="CAMPAIGN APPROVED"
          onCancel={() => setShowApprovedDialogSuccess(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setShowApprovedDialogSuccess(false);
                // Redirect to campaign order queue ready tab
                history.push("/campaigns/v2/orderqueue/ready-to-launched");
              }}
            >
              Okay
            </Button>,
          ]}
        >
          <Text>
            This campaign has been approved and is now ready to launch.
          </Text>
        </Modal>
      )}
    </>
  );
};
