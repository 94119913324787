import React, { useState } from "react";

// Ant Design
import { Table, Row, Col, Grid } from "antd";
import SelectedMetricList from "../components/SelectedMetricList";
import AudienceDetails from "../components/AudienceDetails";
import AudienceDetailsModal from "../components/Modals/AudienceDetailsModal.js";

import {
  demographicsAudienceTableColumn,
  demographicsAudienceTableColumnMobile,
} from "../utils/tableColumns";

import { isMobileScreen } from "../utils/mobileUtils";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext.js";

const DemographicsAudienceTable = props => {
  const {
    audienceDemographs,
    handleUpdateAudience,
    handleFetchAudienceDemographs,
  } = props;

  const { setSetSelectedAudiences } = useAudienceBuilderContext();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { useBreakpoint } = Grid;

  const breakPoint = useBreakpoint();
  const [visbile, setVisible] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const [audienceDetails, setAudienceDetails] = useState({});

  const defaultExpandable = {
    expandedRowRender: record => {
      const { metrics } = record;
      return (
        <>
          <Row style={{ minHeight: "700px" }} gutter={16}>
            <Col span={6}>
              <SelectedMetricList
                list={metrics}
                loading={false}
                disableDrag={true}
                displayOnly={true}
              ></SelectedMetricList>
            </Col>
            <Col span={18} style={{ height: "700px" }}>
              <AudienceDetails
                audienceDetails={record}
                record={record}
                handleUpdateAudience={handleUpdateAudience}
                handleFetchAudienceDemographs={handleFetchAudienceDemographs}
              />
            </Col>
          </Row>
        </>
      );
    },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (record, selected, selectedRows) => {
      setSelectedRowKeys(record);
    },
    onSelect: (record, selected, selectedRows) => {
      setSetSelectedAudiences(selectedRows);
    },
  };

  const tableProps = isMobileScreen(breakPoint)
    ? {}
    : {
        rowSelection,
        expandable: defaultExpandable,
      };

  const tableColumns = isMobileScreen(breakPoint)
    ? demographicsAudienceTableColumnMobile
    : demographicsAudienceTableColumn;

  const handleRowClick = record => {
    setAudienceDetails(record);
    setMetrics(record.metrics);
    setVisible(true);
  };

  const rowProps = record => ({
    onClick: () => handleRowClick(record),
  });

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <AudienceDetailsModal
        visible={visbile}
        audienceDetails={audienceDetails}
        metrics={metrics}
        handleCancel={handleCancel}
      />
      <Table
        {...tableProps}
        columns={tableColumns}
        styles={{ minHeight: "600px", height: "600px" }}
        dataSource={audienceDemographs.map(i => ({ ...i, key: i.id }))}
        onRow={isMobileScreen(breakPoint) ? rowProps : null}
      />
    </>
  );
};

export default DemographicsAudienceTable;
