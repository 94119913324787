import React from "react";
import { Alert } from "antd";
import PropTypes from "prop-types";

const PendingCampaignOrder = ({ data, history }) => {
  return (
    <div
      onClick={() => history.push(`/campaigns/audit/${data.id}`)}
      style={{ cursor: "pointer", marginBottom: "20px" }}
    >
      <Alert
        message="Pending for review"
        type="info"
        showIcon
        description={`Your Campaign Order ${data.name} has requested changes.`}
        banner
      />
    </div>
  );
};

PendingCampaignOrder.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default PendingCampaignOrder;
