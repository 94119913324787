import React from "react";
import AttributionReport from "./AttributionReport";
import { Card, Row, Spin } from "antd";

const AttributionTab = ({ campaign, chartData, chartsLoading }) => {
  if (chartsLoading)
    return (
      <Card style={{ height: "300px" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: "300px" }}
        >
          <Spin size="large" />
        </Row>
      </Card>
    );

  return <AttributionReport campaign={campaign} chartData={chartData} />;
};

export default AttributionTab;
