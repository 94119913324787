import React, { useState } from "react";
import { Card, Col, List, Row, Space, Typography } from "antd";
import {
  computeROAS,
  formatCurrency2SigFig,
  formatNumber,
} from "../../../../../core/utils/campaigns";
import Pacing from "../PacingTab";
import SelectCampaigns from "../PacingTab/SelectCampaigns";
import ContractTerms from "../ContractTermsTab";

const { Text } = Typography;

function DataTabsCard({
  orgSummary,
  loadingDeviceClicks,
  deviceClicks,
  isLoading,
  userPermission,
  advertiserIds,
  campaignDataOptions,
  selectedCIDs,
  setSelectedCIDs,
  currencyFormat,
}) {
  const [tabKey, setTabKey] = useState("mediaSpend");

  let tabList = [
    {
      key: "mediaSpend",
      tab: "Media Spend",
    },
    {
      key: "ecommerce",
      tab: "Ecommerce",
    },
    {
      key: "walkins",
      tab: "Walk Ins",
    },
    {
      key: "device_clicks",
      tab: "Device Clicks",
    },
  ];

  if (userPermission && userPermission.isPacing && advertiserIds.length === 1) {
    tabList.push({
      key: "pacing",
      tab: "Pacing",
    });
  }

  tabList.push({
    key: "contract_terms",
    tab: "Contract Terms",
  });

  if (
    orgSummary &&
    (!orgSummary.totalWalkIns || !orgSummary.totalObservations)
  ) {
    tabList = tabList.filter(i => i.key !== "walkins");
  }

  const mediaSpend = [
    {
      label: "Impressions Served",
      value:
        orgSummary && orgSummary.mediaSpendImpressionsServed
          ? formatNumber(orgSummary.mediaSpendImpressionsServed)
          : 0,
    },
    {
      label: "Clicks",
      value:
        orgSummary && orgSummary.mediaSpendClicks
          ? formatNumber(orgSummary.mediaSpendClicks)
          : 0,
    },
    {
      label: "CTR",
      value:
        orgSummary && orgSummary.mediaSpendCTR
          ? `${orgSummary.mediaSpendCTR.toFixed(3)}%`
          : 0,
    },
    userPermission.priceVisible
      ? {
          label: "Budget Spent",
          value: formatCurrency2SigFig(
            orgSummary && orgSummary.mediaSpendBudgetSpent
              ? orgSummary.mediaSpendBudgetSpent
              : 0,
            currencyFormat
          ),
        }
      : null,
  ];

  const ecommerce = [
    {
      label: "Total Revenue",
      value:
        orgSummary && orgSummary.totalRevenue
          ? formatCurrency2SigFig(orgSummary.totalRevenue, currencyFormat)
          : 0,
    },
    {
      label: "Total Orders",
      value:
        orgSummary && orgSummary.totalOrders
          ? formatNumber(orgSummary.totalOrders)
          : 0,
    },
    userPermission.priceVisible
      ? {
          label: "Roas",
          value:
            orgSummary &&
            orgSummary.totalRevenue &&
            orgSummary.mediaSpendBudgetSpent
              ? computeROAS(
                  orgSummary.totalRevenue,
                  orgSummary.mediaSpendBudgetSpent
                )
              : 0,
        }
      : null,
  ];

  const walkins = [
    {
      label: "Total Walk Ins",
      value:
        orgSummary && orgSummary.totalWalkIns
          ? formatNumber(orgSummary.totalWalkIns)
          : 0,
    },
    {
      label: "Total Observations",
      value:
        orgSummary && orgSummary.totalObservations
          ? formatNumber(orgSummary.totalObservations)
          : 0,
    },
  ];

  const device_clicks = [
    {
      label: "Total",
      value:
        deviceClicks && deviceClicks.total
          ? orgSummary && orgSummary.mediaSpendClicks
            ? orgSummary.mediaSpendClicks < deviceClicks.total
              ? formatNumber(orgSummary.mediaSpendClicks)
              : formatNumber(deviceClicks.total)
            : 0
          : 0,
    },
    {
      label: "Phone",
      value:
        deviceClicks && deviceClicks.phone
          ? deviceClicks.total
            ? orgSummary && orgSummary.mediaSpendClicks
              ? orgSummary.mediaSpendClicks < deviceClicks.total
                ? formatNumber(
                    deviceClicks.phone -
                      (deviceClicks.total - orgSummary.mediaSpendClicks)
                  )
                : formatNumber(deviceClicks.phone)
              : 0
            : 0
          : 0,
    },
    {
      label: "Tablet",
      value:
        deviceClicks && deviceClicks.tablet
          ? formatNumber(deviceClicks.tablet)
          : 0,
    },
    {
      label: "Personal Computer",
      value:
        deviceClicks && deviceClicks.personalComputer
          ? formatNumber(deviceClicks.personalComputer)
          : 0,
    },
    {
      label: "Mobile Tablet",
      value:
        deviceClicks && deviceClicks.mobileTablet
          ? formatNumber(deviceClicks.mobileTablet)
          : 0,
    },
    {
      label: "Mobile",
      value:
        deviceClicks && deviceClicks.mobile
          ? formatNumber(deviceClicks.mobile)
          : 0,
    },
    {
      label: "PC",
      value:
        deviceClicks && deviceClicks.pc ? formatNumber(deviceClicks.pc) : 0,
    },
    {
      label: "Set top box",
      value:
        deviceClicks && deviceClicks.setTopBox
          ? formatNumber(deviceClicks.setTopBox)
          : 0,
    },
    {
      label: "Connected TV",
      value:
        deviceClicks && deviceClicks.connectedTv
          ? formatNumber(deviceClicks.connectedTv)
          : 0,
    },
    {
      label: "Other",
      value:
        deviceClicks && deviceClicks.other
          ? formatNumber(deviceClicks.other)
          : 0,
    },
    {
      label: "Unknown",
      value:
        deviceClicks && deviceClicks.unknown
          ? formatNumber(deviceClicks.unknown)
          : 0,
    },
  ];

  const contentList = {
    mediaSpend: (
      <List
        size="default"
        itemLayout="horizontal"
        dataSource={mediaSpend.filter(i => i !== null)}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              style={{ display: "flex", flexDirection: "row" }}
              title={item.label}
              description={item.value || "0"}
            />
          </List.Item>
        )}
      />
    ),
    ecommerce: (
      <List
        size="default"
        itemLayout="horizontal"
        dataSource={ecommerce.filter(i => i != null)}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              style={{ display: "flex", flexDirection: "row" }}
              title={item.label}
              description={item.value || "0"}
            />
          </List.Item>
        )}
      />
    ),
    walkins: (
      <List
        size="default"
        itemLayout="horizontal"
        dataSource={walkins.filter(i => i != null && i.value !== 0)}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              style={{ display: "flex", flexDirection: "row" }}
              title={item.label}
              description={item.value || "0"}
            />
          </List.Item>
        )}
      />
    ),
    device_clicks:
      (campaignDataOptions && campaignDataOptions.length && (
        <Row style={{ marginBottom: "0.75rem" }} gutter={[16, 16]}>
          <Col>
            <Space size={[24, 8]} direction="vertical">
              <Text>Filter by Campaign Order</Text>
              <SelectCampaigns
                campaignDataOptions={campaignDataOptions}
                selectedCIDs={selectedCIDs}
                setSelectedCIDs={setSelectedCIDs}
              />
            </Space>
          </Col>
        </Row>
      ),
      (
        <Card
          bodyStyle={{ padding: 0 }}
          loading={loadingDeviceClicks}
          bordered={false}
        >
          <List
            size="default"
            itemLayout="horizontal"
            dataSource={device_clicks.filter(i => i != null && i.value !== 0)}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  style={{ display: "flex", flexDirection: "row" }}
                  title={item.label}
                  description={item.value || "0"}
                />
              </List.Item>
            )}
          />
        </Card>
      )),
    pacing: <Pacing advertiserIds={advertiserIds} />,
    contract_terms: <ContractTerms advertiserIds={advertiserIds} />,
  };
  return (
    <Card
      loading={isLoading}
      style={{ width: "100%" }}
      tabList={tabList}
      activeTabKey={tabKey}
      onTabChange={key => {
        setTabKey(key);
      }}
    >
      {contentList[tabKey]}
    </Card>
  );
}

export default DataTabsCard;
