import React from "react";
import { Modal, Input, DatePicker, Spin, Button, Form } from "antd";
import { isEmpty } from "lodash";

const { RangePicker } = DatePicker;

export const TargetDetailsModal = ({
  targetDetailsModalOpen,
  toggleTargetDetailsModal,
  changeTargetDetails,
  onOk,
  targetType,
  loading,
}) => {
  const [form] = Form.useForm();
  const datePickerChanged = vals => {
    if (!isEmpty(vals)) {
      changeTargetDetails("flightLength", {
        start: vals[0].format(),
        end: vals[1].format(),
      });
    }
  };

  const ModalTitle = ({ title, subtitle }) => {
    return (
      <div>
        <h3 style={{ margin: 0, fontWeight: "lighter" }}>{title}</h3>
        <h5 style={{ margin: 0, color: "#adb0b3", fontWeight: "lighter" }}>
          {subtitle}
        </h5>
      </div>
    );
  };

  return (
    <React.Fragment>
      {targetDetailsModalOpen && (
        <Modal
          title={<ModalTitle title={"Target Details"} />}
          width={800}
          closable={false}
          visible={targetDetailsModalOpen}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                toggleTargetDetailsModal(false);
              }}
              disabled={loading}
            >
              Cancel
            </Button>,
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                form.validateFields().then(values => {
                  onOk("targeting");
                });
              }}
              disabled={loading || !form.getFieldValue("name")}
            >
              OK
            </Button>,
          ]}
        >
          <Spin size={"large"} spinning={loading}>
            <Form form={form}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Target Name is Required" }]}
              >
                <Input
                  placeholder={"Name"}
                  // style={{ marginBottom: 10 }}
                  onChange={e => {
                    changeTargetDetails("name", e.target.value);
                    form.setFieldsValue({ name: e.target.value });
                  }}
                />
              </Form.Item>
              {targetType === "Attribution" && (
                <RangePicker
                  style={{ width: "100%" }}
                  onChange={vals => {
                    datePickerChanged(vals);
                  }}
                />
              )}
            </Form>
          </Spin>
        </Modal>
      )}
    </React.Fragment>
  );
};
