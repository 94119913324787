import React from "react";
import MainView from "./MainView";
import Loader from "../../../../../core/components/home/Pacing/Loader";

// Feature level subroutes
const Pacing = props => {
  return (
    <Loader {...props}>
      <MainView />
    </Loader>
  );
};

export default Pacing;
