import React from "react";
import { Upload, message, Modal } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import PropTypes from "prop-types";
//locals core
import { getUploadedFileDimensions } from "../../../core/utils/dataManipulating/images";
import CREATE_MEDIA_ADMIN from "../../../core/GraphQl/Mutations/CREATE_MEDIA_ADMIN";
import DELETE_MEDIA from "../../../core/GraphQl/Mutations/DELETE_MEDIA";
import { isEmpty } from "lodash";
import { getImageType } from "../../../core/utils/general/strings";

class SingleImageUploader extends React.Component {
  state = {
    loading: false,
    previewVisible: false,
    previewImage: "",
    imageUrl: "",
  };

  componentDidMount = async () => {
    if (!isEmpty(this.props.image)) {
      let url = this.props.image.url;

      // Temporary disable using images hosted in yocannadev
      if (
        !url ||
        url.includes("yocannadev") ||
        url.includes("yocannaproduction")
      ) {
        url = await Storage.get(this.props.image.key, {
          expires: 600000,
        });
      }
      this.setState({ imageUrl: url, loading: false });
    }
  };

  componentDidUpdate(prevProps) {
    // If props are updated, such as from new data getting added, will recalculate
    if (this.props.image !== prevProps.image) {
      if (!isEmpty(this.props.image)) {
        this.setState({
          loading: true,
        });

        let url = this.props.image.url;

        // Temporary disable using images hosted in yocannadev
        if (
          !url ||
          url.includes("yocannadev") ||
          url.includes("yocannaproduction")
        ) {
          Storage.get(this.props.image.key, {
            expires: 600000,
          })
            .then(url => {
              this.setState({
                imageUrl: url,
                loading: false,
              });
            })
            .catch(err => console.log(err));
        } else {
          this.setState({
            imageUrl: this.props.image.url,
            loading: false,
          });
        }
      } else
        this.setState({
          imageUrl: "",
        });
    }
  }

  beforeUpload = async file => {
    if (file.size / 1024 / 1024 > 2) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    // Check file type
    const allowedTypes = ["JPEG", "PNG", "JPG"];
    const fileType = getImageType(file.type);
    if (!allowedTypes.includes(fileType)) {
      message.error("Only PNG, JPEG, and JPG file types are allowed!");
      return false;
    }

    this.setState({ loading: true });

    // If previous image, remove it.
    if (!isEmpty(this.props.image) && !this.props.isSubmitted) {
      const remove = await Storage.remove(this.props.image.key);
      if (remove)
        this.props.deleteMedia({ variables: { id: this.props.image.id } });
      else console.log("Couldn't remove image from Storage");
    }

    // Continue with the upload process
    const { name, type, size } = file;
    if (file.size / 1024 / 1024 > 2) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }
    const fname = `${Date.now()}-${name}`;
    const response = await Storage.put(fname, file, {
      contentType: "image/*",
    });
    const { key } = response;
    const url = await Storage.get(response.key, { expires: 600000 });

    // Get image width and height
    let imageWidth = 0;
    let imageHeight = 0;
    getUploadedFileDimensions(file).then(({ width, height }) => {
      imageWidth = width;
      imageHeight = height;
    });

    // Put S3 image into MySQL DB
    const result = await this.props.createMediaMutation({
      variables: {
        mediatype: "STILL",
        key,
        url,
        width: imageWidth,
        height: imageHeight,
        name: file.name,
        size,
        fileType: getImageType(type),
      },
    });

    // Add to local state
    const { statekey, onChange } = this.props;
    onChange(statekey, result.data.createMedia);
    this.setState({ imageUrl: url, loading: false });
  };

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  render() {
    const { previewVisible, previewImage } = this.state;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-uploadh-text">Upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    return (
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={null}
          beforeUpload={this.beforeUpload}
          disabled={this.props.disabled ? this.props.disabled : false}
          accept={".png, .jpeg, .jpg"}
          customRequest={async () => {
            setTimeout(() => {
              return true;
            }, 0);
          }}
        >
          {imageUrl && !this.state.loading ? (
            <img
              style={{
                width: "100%",
                opacity: this.props.disabled ? 0.4 : 1.0,
              }}
              src={imageUrl}
              alt="avatar"
            />
          ) : (
            uploadButton
          )}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="avatar" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

SingleImageUploader.propTypes = {
  statekey: PropTypes.string.isRequired,
  image: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default compose(
  graphql(CREATE_MEDIA_ADMIN, { name: "createMediaMutation" }),
  graphql(DELETE_MEDIA, { name: "deleteMedia" })
)(SingleImageUploader);
