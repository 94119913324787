import React from "react";
import { graphql, useMutation, useQuery, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import UPDATE_MANY_AUDIENCES from "../../../GraphQl/Mutations/UPDATE_MANY_AUDIENCES";
import CREATE_RETAIL_LOCATION from "../../../GraphQl/Mutations/CREATE_RETAIL_LOCATION";
import CREATE_GEOTARGET_LOCATION from "../../../GraphQl/Mutations/CREATE_GEOTARGET_LOCATION";
import GET_LOCATIONS from "../../../GraphQl/Queries/GET_LOCATIONS";
import CREATE_LOCATION_WITH_GEOTARGET from "../../../GraphQl/Mutations/CREATE_LOCATION_WITH_GEOTARGET";
import DELETE_LOCATION from "../../../GraphQl/Mutations/DELETE_LOCATION_ADMIN";
import ATTRIBUTION_AUDIENCES_ORG_CONFIG from "../../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_ORG_CONFIG";
import CREATE_AUDIENCE_LOCATION from "../../../GraphQl/Mutations/CREATE_AUDIENCE_LOCATION";
import EDIT_LOCATION from "../../../GraphQl/Mutations/EDIT_LOCATION_ADMIN";
import EDIT_LOCATION_ADMIN_WITH_GEOTARGETS from "../../../GraphQl/Mutations/EDIT_LOCATION_ADMIN_WITH_GEOTARGETS";
import DELETE_AUDIENCE_LOCATION from "../../../GraphQl/Mutations/DELETE_AUDIENCE_LOCATION";
import DELETE_MANY_LOCATIONS from "../../../GraphQl/Mutations/DELETE_MANY_LOCATIONS";
import CREATE_CITY_LIST from "../../../GraphQl/Mutations/CREATE_CITY_LIST";
import GET_REGION_GROUPS from "../../../GraphQl/Queries/GET_REGION_GROUPS";
import UPDATE_REGION_GROUP from "../../../GraphQl/Mutations/UPDATE_REGION_GROUP";
import DELETE_REGION_GROUP from "../../../GraphQl/Mutations/DELETE_REGION_GROUP";
import CREATE_IAB_CATEGORY_GROUP from "../../../GraphQl/Mutations/CREATE_IAB_CATEGORY_GROUP";
import GET_IAB_CATEGORY_GROUPS from "../../../GraphQl/Queries/GET_IAB_CATEGORY_GROUPS";
import DELETE_IAB_CATEGORY from "../../../GraphQl/Mutations/DELETE_IAB_CATEGORY";
import CREATE_ORG_DEAL_ID from "../../../GraphQl/Mutations/CREATE_ORG_DEAL_ID";
import DELETE_ORG_DEAL_IDS from "../../../GraphQl/Mutations/DELETE_ORG_DEAL_IDS";
import GET_ORG_DEAL_IDS from "../../../GraphQl/Queries/GET_ORG_DEAL_IDS";
import UPDATE_ORG_DEAL_ID from "../../../GraphQl/Mutations/UPDATE_ORG_DEAL_ID";
import GET_REGION_GROUPS_WITH_PRESET_LIST from "../../../GraphQl/Queries/GET_REGION_GROUPS_WITH_PRESET_LIST";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";

const TargetingMainLoader = props => {
  const { currentUser, client } = props;
  const loggedInOrg = currentUser.role.org;
  const [updateManyAudiences] = useMutation(UPDATE_MANY_AUDIENCES);

  const createLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const createLocationWithGeotarget = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      geoId,
      locationKey,
      audienceType,
      province,
      createdBy,
    } = data;
    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocationWithGeotarget({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          geoId,
          locationKey,
          audienceType,
          province,
          createdBy,
        },
        refetchQueries: [
          {
            query: GET_LOCATIONS,
            variables: { id: loggedInOrg.id },
          },
        ],
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const [createGeoTargetLocation] = useMutation(CREATE_GEOTARGET_LOCATION);

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [createSelectedAudienceLocation] = useMutation(
    CREATE_AUDIENCE_LOCATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
        },
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "ATTRIBUTION", ORGID: loggedInOrg.id },
        },
      ],
    }
  );

  const updateLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      circles,
      polygons,
      start,
      end,
      isBase,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationUpdateInput!
      const response = await props.updateLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          country,
          lat,
          lng,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          id,
          circles,
          polygons,
          start,
          end,
          isBase,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.updateLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  // TODO: Enhance payload handling for this mutation
  // @params: data: LocationUpdateInput!
  const [updateLocationWithGeotargets] = useMutation(
    EDIT_LOCATION_ADMIN_WITH_GEOTARGETS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_LOCATIONS,
          variables: { id: loggedInOrg.id },
        },
      ],
    }
  );

  const [deleteAudienceLocation] = useMutation(DELETE_AUDIENCE_LOCATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [deleteManyLocations] = useMutation(DELETE_MANY_LOCATIONS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [createRegionGroup] = useMutation(CREATE_CITY_LIST, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [updateRegionGroup] = useMutation(UPDATE_REGION_GROUP, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [deleteRegionGroup] = useMutation(DELETE_REGION_GROUP, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const { data: regionGroups } = useQuery(GET_REGION_GROUPS, {
    variables: { orgs: { id: loggedInOrg.id } },
  });

  const [createIABCategoryGroup] = useMutation(CREATE_IAB_CATEGORY_GROUP, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_IAB_CATEGORY_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const { data: iABCategoryGroups } = useQuery(GET_IAB_CATEGORY_GROUPS, {
    variables: { orgs: { id: loggedInOrg.id } },
  });

  const [deleteIABCategoryGroup] = useMutation(DELETE_IAB_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_IAB_CATEGORY_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [createOrgDealId] = useMutation(CREATE_ORG_DEAL_ID);

  const [deleteOrgDealIds] = useMutation(DELETE_ORG_DEAL_IDS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_DEAL_IDS,
        variables: { id: loggedInOrg.id },
      },
    ],
  });

  const [updateDealId] = useMutation(UPDATE_ORG_DEAL_ID);

  const {
    data: presetRegionGroups,
    loading: presetRegionGroupsLoading,
  } = useQuery(GET_REGION_GROUPS_WITH_PRESET_LIST, {
    variables: { orgId: loggedInOrg.id },
  });

  const { loading: loadingLocation, data: locationData } = useQuery(
    GET_LOCATIONS,
    {
      variables: { id: loggedInOrg.id },
    }
  );

  const {
    loading: loadingTargetingAudiences,
    data: targetingAudiences,
  } = useQuery(ATTRIBUTION_AUDIENCES_ORG_CONFIG, {
    variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
  });

  const { data: orgDealIds } = useQuery(GET_ORG_DEAL_IDS, {
    variables: { id: loggedInOrg.id },
  });

  if (loadingLocation) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentuser: props.currentUser,
    updateManyAudiences,
    createLocation,
    createGeoTargetLocation,
    createLocationWithGeotarget,
    deleteLocation,
    createSelectedAudienceLocation,
    updateLocation,
    updateLocationWithGeotargets,
    deleteAudienceLocation,
    deleteManyLocations,
    createRegionGroup,
    updateRegionGroup,
    deleteRegionGroup,
    regionGroups,
    createIABCategoryGroup,
    iABCategoryGroups,
    deleteIABCategoryGroup,
    createOrgDealId,
    deleteOrgDealIds,
    updateDealId,
    presetRegionGroups,
    presetRegionGroupsLoading,
    loggedInOrg,
    loadingLocation,
    locationList: locationData,
    loadingTargetingAudiences,
    targetingAudiences,
    orgDealIds,
    client,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(CREATE_RETAIL_LOCATION, { name: "createLocation" }),
  graphql(CREATE_LOCATION_WITH_GEOTARGET, {
    name: "createLocationWithGeotarget",
  }),
  graphql(EDIT_LOCATION, { name: "updateLocation" })
)(withApollo(TargetingMainLoader));
