import gql from "graphql-tag";

const UPDATE_ARTICLE_CATEGORY = gql`
  mutation updateArticleCategory(
    $id: ID!
    $title: String!
    $feature: [Feature!]
    $permission: [String!]
  ) {
    updateArticleCategory(
      where: { id: $id }
      data: {
        title: $title
        permission: { set: $permission }
        feature: { set: $feature }
      }
    ) {
      id
      createdAt
      updatedAt
      title
      permission
      feature
    }
  }
`;

export default UPDATE_ARTICLE_CATEGORY;
