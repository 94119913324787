import gql from "graphql-tag";

const ACQUIRED_LINK_MUTATION = gql`
  mutation UpdateOrgSeoSummary(
    $data: OrgSeoSummaryUpdateInput!
    $where: OrgSeoSummaryWhereUniqueInput!
  ) {
    updateOrgSeoSummary(data: $data, where: $where) {
      linkAcquisition {
        id
        createdAt
        updatedAt
        acquiredLinks {
          id
          sourceURL
          destinationURL
          contentType
        }
      }
    }
  }
`;

export default ACQUIRED_LINK_MUTATION;
