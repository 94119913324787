import React from "react";
import { Spin } from "antd";

const OverlaySpinner = () => {
  return <Spin size="large" style={styles.root} />;
};

const styles = {
  root: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 10,
  },
};

export default OverlaySpinner;
