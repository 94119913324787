export const styles = {
  overviewBar: {
    width: "100%",
    borderTop: "solid 1px #e8e8e8",
    margin: "1% 0",
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};
