import gql from "graphql-tag";

const DELETE_CREATIVE = gql`
  mutation deleteCreative($id: ID!) {
    deleteCreative(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_CREATIVE;
