import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Row, Divider, Col, Card, Table, Button, Empty } from "antd";
import { useSEOLinkAcquisitionsContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOLinkAcquisitionsContext";
import DonutChart from "../../components/DonutChart";
import LinksAcquiredLineChart from "../../components/LinksAcquiredLineChart";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../shared/globalStyling/styledText";
import moment from "moment";

const LinkAcquisitionsView = ({
  selectedDate,
  setSelectedDate,
  setReportData,
}) => {
  const {
    linkAquisitionsTypes,
    linkAcquisitions,
    dataSource,
    breakdownColumns,
    scale,
    lineChartData,
    id,
    onDateChange,
  } = useSEOLinkAcquisitionsContext();
  const [initialLoad, setInitialLoad] = useState(true);
  const [startDateEnDate, setStartDateEnDate] = useState([]);
  const [hasData, setHasData] = useState(false);

  // check if there is data
  useEffect(() => {
    if (lineChartData && lineChartData.length > 0) {
      setHasData(true);
    } else {
      setHasData(false);
    }
  }, [lineChartData]);

  useEffect(() => {
    if (
      !initialLoad &&
      selectedDate &&
      selectedDate.length > 0 &&
      startDateEnDate.length > 0 &&
      (!selectedDate[0].isSame(startDateEnDate[0]) ||
        !selectedDate[1].isSame(startDateEnDate[1]))
    ) {
      onDateChange(selectedDate);
      setStartDateEnDate(selectedDate);
    }
  }, [initialLoad, onDateChange, selectedDate, startDateEnDate]);
  useEffect(() => {
    if (lineChartData && lineChartData.length > 0 && initialLoad) {
      setSelectedDate([
        moment(lineChartData[0].date),
        moment(lineChartData[lineChartData.length - 1].date),
      ]);
      setStartDateEnDate([
        moment(lineChartData[0].date),
        moment(lineChartData[lineChartData.length - 1].date),
      ]);
      if (linkAcquisitions && linkAcquisitions.length > 0) {
        setReportData(
          linkAcquisitions.map(item => ({
            URL: item.url,
            Type: item.type,
            "Destination URL": item.destinationUrl,
            "Acquisition Date": item.createdAt,
          }))
        );
      } else {
        setReportData([]);
      }
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    lineChartData,
    linkAcquisitions,
    setReportData,
    setSelectedDate,
  ]);

  const history = useHistory();

  const handleRedirect = () => {
    history.push(`/seo/link_maintenance/${id}`);
  };

  const [totalAcquisitions, setTotalAcquisitions] = useState(0);

  useEffect(() => {
    if (linkAquisitionsTypes && linkAquisitionsTypes.length > 0) {
      const total = linkAquisitionsTypes.reduce((acc, curr) => {
        return acc + curr.counts;
      }, 0);
      setTotalAcquisitions(total);
    }
  }, [linkAquisitionsTypes]);

  const totalLinksAcquired = useMemo(() => {
    return lineChartData.reduce((sum, item) => sum + item.value, 0);
  }, [lineChartData]);

  const createDonutChartData = linkAcquisitions => {
    const typeCounts = linkAcquisitions.reduce((acc, item) => {
      acc[item.type] = (acc[item.type] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(typeCounts).map(([type, count]) => ({
      value: type,
      counts: count,
    }));
  };

  const donutChartData = createDonutChartData(linkAcquisitions);

  // return blank page if there is no data
  if (!hasData) {
    return (
      <Card title="LINKS ACQUIRED">
        <Empty description="No acquired links have been setup">
          <Button type="primary" onClick={handleRedirect}>
            Link Acquisition Setup
          </Button>
        </Empty>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            title={"LINKS ACQUIRED"}
            extra={
              <Button onClick={handleRedirect}>Link Acquisition Setup</Button>
            }
          >
            <div style={{ textAlign: "left", marginBottom: "20px" }}>
              <KPITitleText text={"Total Links Acquired"} />
              <KpiSubText text={totalLinksAcquired} />
            </div>
            <Divider />
            <LinksAcquiredLineChart data={lineChartData} scale={scale} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24} lg={10}>
          <Card title={"TOTAL LINK ACQUISITIONS"} style={{ height: "100%" }}>
            <div style={{ textAlign: "left", marginBottom: "20px" }}>
              <KPITitleText text={"Total Links Acquisitions"} />
              <KpiSubText text={totalLinksAcquired} />
            </div>
            <Divider />
            <DonutChart
              chartData={donutChartData}
              totalCount={totalAcquisitions}
              height={400}
              showCenterText={true}
              centerTextTitle="Total Links Acquired"
              centerTextValue={totalLinksAcquired}
            />
          </Card>
        </Col>
        <Col xs={24} lg={14}>
          <Card
            title={"LINK BREAKDOWN BY CONTENT TYPE"}
            style={{ height: "100%" }}
          >
            <Table
              style={{ width: "100%" }}
              columns={breakdownColumns}
              dataSource={dataSource}
              size={"default"}
              scroll={{ x: 300 }}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LinkAcquisitionsView;
