import React from "react";
import { Modal, Card, Row, Col, Button, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

const AdminModal = ({ visible, setVisible, reprocess }) => {
  return (
    <Modal
      title={`Paid Search Admin Tools`}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Row type="flex" style={{ justifyContent: "center" }}>
        <Col xs={24} sm={12} md={11}>
          <Card title="Reprocess Paid Search">
            <Row type="flex" justify="center" align="middle">
              <ReloadOutlined
                style={{ fontSize: "80px", marginBottom: "20px" }}
              />
              <div style={{ marginBottom: "20px" }}>
                This will reprocess the current Paid Search
              </div>
              <Button
                icon={<ReloadOutlined />}
                type="primary"
                onClick={() => {
                  try {
                    reprocess();
                  } catch (err) {
                    message.err("Failed Reprocessing Paid Search");
                  }
                }}
              >
                Reprocess Paid Search
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default AdminModal;
