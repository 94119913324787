import { cloneDeep } from "lodash";
import moment from "moment";
import React from "react";

const PageViewController = ({
  reportData,
  reportError,
  reportLoading,
  signedUrlError,
  signedUrlLoading,
  setExportButtonDisable,
  setExportButtonLoading,
  ...props
}) => {
  const dataFilterChange = (startDate, endDate) => {
    const byDate = cloneDeep(reportData.byDate ? reportData.byDate : []);
    const attributedPageViews = cloneDeep(
      reportData.attributedPageViews ? reportData.attributedPageViews : []
    );

    if (startDate && endDate) {
      const filteredByDate = byDate.filter(item => {
        return (
          moment(item.date)
            .utc(true)
            .isSameOrAfter(startDate.utc(true)) &&
          moment(item.date)
            .utc(true)
            .isSameOrBefore(endDate.utc(true))
        );
      });

      const filteredAttributedPageViews = attributedPageViews.filter(item => {
        return (
          moment(item.pageViewTime, "YYYY-MM-DD")
            .utc(true)
            .isSameOrAfter(startDate.utc(true)) &&
          moment(item.pageViewTime, "YYYY-MM-DD")
            .utc(true)
            .isSameOrBefore(endDate.utc(true))
        );
      });

      const summary = filteredAttributedPageViews.reduce(
        (acc, curr) => {
          const cookie = curr.attributionMethod.COOKIE
            ? curr.attributionMethod.COOKIE
            : null;
          const fingerprint = curr.attributionMethod.FINGERPRINT
            ? curr.attributionMethod.FINGERPRINT
            : null;
          const deviceFingerprint = curr.attributionMethod.DEVICE_FINGERPRINT
            ? curr.attributionMethod.DEVICE_FINGERPRINT
            : null;
          const ip = curr.attributionMethod.IP
            ? curr.attributionMethod.IP
            : null;

          if (cookie) acc.attributionMethods.COOKIE.add(cookie);
          if (fingerprint) acc.attributionMethods.FINGERPRINT.add(fingerprint);
          if (deviceFingerprint)
            acc.attributionMethods.DEVICE_FINGERPRINT.add(deviceFingerprint);
          if (ip) acc.attributionMethods.IP.add(ip);

          acc.impressions += curr.totalImpressions;
          acc.pageViews += 1;

          return acc;
        },
        {
          pageViews: 0,
          impressions: 0,
          uniqueUsers: 0,
          attributionMethods: {
            COOKIE: new Set(),
            FINGERPRINT: new Set(),
            IP: new Set(),
            DEVICE_FINGERPRINT: new Set(),
          },
        }
      );

      summary.uniqueUsers =
        summary.attributionMethods.COOKIE.size +
        summary.attributionMethods.FINGERPRINT.size +
        summary.attributionMethods.IP.size +
        summary.attributionMethods.DEVICE_FINGERPRINT.size;

      return {
        summary,
        byDate: filteredByDate,
        attributedPageViews: filteredAttributedPageViews,
      };
    }
  };
  return React.cloneElement(props.children, {
    ...props.children.props,
    reportData,
    reportError,
    reportLoading,
    signedUrlError,
    signedUrlLoading,
    dataFilterChange,
  });
};

export default PageViewController;
