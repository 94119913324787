import React from "react";
import { Modal, Select, Spin, Row, Typography, Col, Button } from "antd";
import _ from "lodash";
const { Option } = Select;
const { Title } = Typography;

const SharedOrgsModal = ({
  childOrgs,
  modalToggle,
  sharedOrgsLoading,
  sharedOrgs,
  selectedRows,
  currentOrgId,
  onCancel,
  onSubmit,
  setToBeSavedData,
  toBeSavedData,
}) => {
  const dispalyOrgs =
    !_.isNil(sharedOrgs) &&
    sharedOrgs.map((o, idx) => {
      const orgDetails = selectedRows.find(s => s.id === o.id);
      if (_.isNil(orgDetails)) return null;

      o.name = orgDetails.org.name;

      const defaultDisabled = [orgDetails.org.id, currentOrgId];

      const orgs = [];
      const orgsId = [];
      !_.isNil(childOrgs) &&
        childOrgs.forEach(org => {
          orgs.push(
            <Option
              key={org.id}
              value={org.id}
              disabled={defaultDisabled.includes(org.id)}
            >
              {org.name}
            </Option>
          );
          orgsId.push(org.id);
        });

      //Don't Show values that the sign in org has no access with
      const displayValues = toBeSavedData[idx].displayValues.filter(s =>
        orgsId.includes(s)
      );

      const onDeselect = value => {
        const cloneTobeSaved = toBeSavedData && _.cloneDeep(toBeSavedData);
        const filterValue = cloneTobeSaved[idx].values.filter(
          item => item !== value
        );
        cloneTobeSaved[idx].values = filterValue;
        cloneTobeSaved[idx].displayValues = cloneTobeSaved[
          idx
        ].displayValues.filter(item => item !== value);
        setToBeSavedData(cloneTobeSaved);
      };

      const onSelect = value => {
        const cloneTobeSaved = toBeSavedData && _.cloneDeep(toBeSavedData);
        if (_.isNil(cloneTobeSaved[idx].values)) {
          cloneTobeSaved[idx].values = [];
        }
        cloneTobeSaved[idx].values.push(value);
        cloneTobeSaved[idx].displayValues.push(value);
        setToBeSavedData(cloneTobeSaved);
      };
      return (
        <Row key={o.id} style={{ marginBottom: `10px` }}>
          <Col xs={24}>
            <Title level={5}>Share {o.name}'s Paid Search with:</Title>
            <Select
              showSearch
              key={record => record.id}
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please Select Organization"
              value={displayValues}
              onSelect={onSelect}
              onDeselect={onDeselect}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {orgs}
            </Select>
          </Col>
        </Row>
      );
    });
  return (
    <Modal
      title={`Share Paid Search`}
      visible={modalToggle}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          {"Cancel"}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          {"Update"}
        </Button>,
      ]}
    >
      {sharedOrgsLoading ? (
        <Row justify="center">
          {" "}
          <Spin />{" "}
        </Row>
      ) : (
        dispalyOrgs
      )}
    </Modal>
  );
};

export default SharedOrgsModal;
