import gql from "graphql-tag";

const EDIT_ORG_ADMIN = gql`
  mutation updateParentOrg(
    $id: ID!
    $roleId: ID!
    $description: String
    $name: String
    $website: String
    $domain: String
    $platformDomain: String
    $tagDomain: String
    $level: PartnerLevel!
    $logoId: MediaWhereUniqueInput
    $parentOrgId: ID
    $roleitems: [RoleItemCreateWithoutRoleInput!]
    $createConfig: OrgConfigCreateWithoutOrgsInput!
    $updateConfig: OrgConfigUpdateWithoutOrgsDataInput!
    $reTargeting: Boolean!
    $appIds: [String!]
    $tradeDeskIdentifier: String
    $storageBucket: String
    $googleCustomerId: String
    $signInLogoId: MediaWhereUniqueInput
    $seo: Boolean!
    $semrushEnable: Boolean
    $projectId: String
    $rootDomainUrl: String
    $rootKeyword: String
    $competitor1stRootDomainUrl: String
    $competitor2ndRootDomainUrl: String
    $viewId: String
    $chatWootId: String
    $cpm: String
    $isGA4: Boolean
    $bingCustomerId: String
    $bingAccountId: String
    $processTag: Boolean
    $paidSearchIsEnable: Boolean
  ) {
    updateParentOrg(
      data: {
        name: $name
        description: $description
        website: $website
        domain: $domain
        platformDomain: $platformDomain
        tagDomain: $tagDomain
        logo: { connect: $logoId }
        signInLogo: { connect: $signInLogoId }
        level: $level
        chatWootId: $chatWootId
        cpm: $cpm
        parentOrg: { connect: { id: $parentOrgId } }
        config: { upsert: { create: $createConfig, update: $updateConfig } }
        dataConfig: {
          upsert: {
            create: {
              reTargeting: $reTargeting
              appIds: { set: $appIds }
              tradeDeskIdentifier: $tradeDeskIdentifier
              storageBucket: $storageBucket
              googleCustomerId: $googleCustomerId
              seo: $seo
              semrushEnable: $semrushEnable
              semrushConfig: {
                create: {
                  projectId: $projectId
                  rootDomainUrl: $rootDomainUrl
                  rootKeyword: $rootKeyword
                  competitor1stRootDomainUrl: $competitor1stRootDomainUrl
                  competitor2ndRootDomainUrl: $competitor2ndRootDomainUrl
                }
              }
              googleAnalytics: { create: { viewId: $viewId, isGA4: $isGA4 } }
              bing: {
                create: {
                  accountId: $bingAccountId
                  customerId: $bingCustomerId
                }
              }
              tagProcessingConfig: { create: { processTag: $processTag } }
              paidSearchDataConfig: {
                create: { isEnabled: $paidSearchIsEnable }
              }
            }
            update: {
              reTargeting: $reTargeting
              appIds: { set: $appIds }
              tradeDeskIdentifier: $tradeDeskIdentifier
              storageBucket: $storageBucket
              googleCustomerId: $googleCustomerId
              seo: $seo
              semrushEnable: $semrushEnable
              semrushConfig: {
                create: {
                  projectId: $projectId
                  rootDomainUrl: $rootDomainUrl
                  rootKeyword: $rootKeyword
                  competitor1stRootDomainUrl: $competitor1stRootDomainUrl
                  competitor2ndRootDomainUrl: $competitor2ndRootDomainUrl
                }
              }
              googleAnalytics: { create: { viewId: $viewId, isGA4: $isGA4 } }
              bing: {
                create: {
                  accountId: $bingAccountId
                  customerId: $bingCustomerId
                }
              }
              tagProcessingConfig: { create: { processTag: $processTag } }
              paidSearchDataConfig: {
                create: { isEnabled: $paidSearchIsEnable }
              }
            }
          }
        }
        roles: {
          delete: { id: $roleId }
          create: {
            roleItems: { create: $roleitems }
            name: $name
            isMaster: true
          }
        }
      }
      where: { id: $id }
    ) {
      id
      domain
      brands(first: 5) {
        name
        logo {
          key
        }
      }
      name
      config {
        priceVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        providersVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
      }
      dataConfig {
        reTargeting
        appIds
        tradeDeskIdentifier
        storageBucket
        semrushEnable
        semrushConfig {
          projectId
          rootDomainUrl
          rootKeyword
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        googleAnalytics {
          viewId
          isGA4
        }
        bing {
          accountId
          customerId
        }
        tagProcessingConfig {
          processTag
        }
        paidSearchDataConfig {
          isEnabled
        }
      }
      description
      locations(first: 5) {
        street
        city
        state
      }
      logo {
        id
        key
      }
      signInLogo {
        id
        key
      }
      roles(first: 50, where: { isMaster: true }) {
        id
        name
        roleItems(first: 15) {
          id
          feature
          actions
        }
        users(first: 30) {
          id
        }
        isMaster
      }
      level
      chatWootId
      cpm
      website
      platformDomain
      tagDomain
      parentOrg {
        name
        id
      }
    }
  }
`;

export default EDIT_ORG_ADMIN;
