import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import { isEmpty } from "lodash";
import { getSummaryScale } from "../../../../../../../core/utils/campaigns";
import { daysWeek } from "../../../../../../../core/utils/constants/constants";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
  styles,
  gridLeft,
  gridRight,
  leftLabel,
  rightLabel,
} from "../../../../shared/styles/chart";

const DualAxisAreaChart = ({
  dualChartData,
  leftY,
  rightY,
  currencyFormat,
}) => {
  //Most key values are a single word and will match if converted to lower case
  let LeftAxis = leftY.toLowerCase();
  let RightAxis = rightY.toLowerCase();

  let axisData = dualChartData.map(item => {
    const keyValue = Object.entries(item);

    //Key values that contain spaces are multiple words and must be converted to camel case
    //Ie: Total Cost -> totalCost
    if (leftY.includes(" ")) {
      //converts to camel case by making the first letter lower case and removing spaces
      LeftAxis = `${leftY.charAt(0).toLowerCase()}${leftY.slice(1)}`.replace(
        / /g,
        ""
      );
    }
    if (rightY.includes(" ")) {
      //converts to camel case by making the first letter lower case and removing spaces
      RightAxis = `${rightY.charAt(0).toLowerCase()}${rightY.slice(1)}`.replace(
        / /g,
        ""
      );
    }

    const left = keyValue[keyValue.findIndex(pair => pair[0] === LeftAxis)];
    const right = keyValue[keyValue.findIndex(pair => pair[0] === RightAxis)];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "xAxis")];

    return {
      left: left && left[1] ? left[1] : "",
      right: right && right[1] ? right[1] : "",
      xAxis: x[1],
    };
  });

  const scale = getSummaryScale(
    dualChartData,
    LeftAxis,
    RightAxis,
    currencyFormat
  );

  const formatXaxis = (day, format) => {
    if (day === daysWeek.Sun || day === daysWeek.Sunday) return daysWeek.Sunday;
    if (day === daysWeek.Mon || day === daysWeek.Monday) return daysWeek.Monday;
    if (day === daysWeek.Tue || day === daysWeek.Tuesday)
      return daysWeek.Tuesday;
    if (day === daysWeek.Wed || day === daysWeek.Wednesday)
      return daysWeek.Wednesday;
    if (day === daysWeek.Thu || day === daysWeek.Thursday)
      return daysWeek.Thursday;
    if (day === daysWeek.Fri || day === daysWeek.Friday) return daysWeek.Friday;
    if (day === daysWeek.Sat || day === daysWeek.Saturday)
      return daysWeek.Saturday;
    return moment(day)
      .utc()
      .format(format);
  };

  // check if chart data is empty
  if (isEmpty(dualChartData)) {
    return <div />;
  }

  let singleXAxis = true;
  for (const [idx, d] of axisData.entries()) {
    const dataLength = axisData.length - 1;
    if (
      idx !== dataLength &&
      moment(d.xAxis).format("YYYY-MM-DD") !==
        moment(axisData[idx + 1].xAxis).format("YYYY-MM-DD")
    ) {
      singleXAxis = false;
      break;
    }
  }

  if (singleXAxis) {
    const cloneD = cloneDeep(axisData);
    const duplicate = cloneD.map(d => {
      const date = moment(d.xAxis)
        .utc()
        .format("YYYY-MM-DD");
      d.xAxis = moment(date, "YYYY-MM-DD")
        .add(1, "minute")
        .format("YYYY-MM-DDThh:mm:ss");
      return d;
    });
    axisData = axisData.concat(duplicate);
  }

  return (
    <div>
      <Chart
        height={400}
        padding="auto"
        style={styles.chart}
        data={axisData}
        scale={scale}
        forceFit
        onTooltipChange={e => {
          let items = e.items;
          const origin1 = items[0];
          const origin2 = items[1];
          items.splice(0);
          items.push({
            name: origin1.name,
            size: origin1.size,
            showMarker: origin1.showMarker,
            title: formatXaxis(origin1.point._origin.xAxis, "dddd MMMM D"),
            marker: origin1.marker,
            color: origin1.color,
            value: origin1.value,
            x: origin1.x,
            y: origin1.y,
            point: origin1.point,
          });
          if (origin2) {
            items.push({
              name: origin2.name,
              size: origin2.size,
              showMarker: origin2.showMarker,
              title: "",
              marker: origin2.marker,
              color: origin2.color,
              value: origin2.value,
              x: origin2.x,
              y: origin2.y,
              point: origin2.point,
            });
          }
        }}
      >
        <Axis
          name="xAxis"
          label={{
            formatter: xAxis => formatXaxis(xAxis, "M/D/YY"),
          }}
        />

        <Axis
          name="left"
          grid={gridLeft}
          label={leftY === "None" ? null : leftLabel}
        />
        <Axis
          visible={rightY === "None" ? false : true}
          name="right"
          grid={gridRight}
          label={rightY === "None" ? null : rightLabel}
        />

        <Tooltip title={true} />
        <Legend
          position="bottom-center"
          visible={true}
          items={[
            {
              value: leftY,
              marker: {
                symbol: "circle",
                fill: "#b2d5ff",
                radius: 5,
                lineWidth: 3,
              },
            },
            {
              value: rightY,
              marker: {
                symbol: "circle",
                fill: "#89d2d4",
                radius: 5,
                lineWidth: 3,
              },
            },
          ]}
        />
        <Geom
          type="lineStack"
          position={`xAxis*left`}
          color="#b2d5ff"
          size={0}
        />
        <Geom
          type="point"
          position={`xAxis*left`}
          color="#b2d5ff"
          shape="circle"
          size={dualChartData.length > 400 ? 1.7 : 3}
        />
        <Geom
          type="lineStack"
          position={`xAxis*right`}
          color="#89d2d4"
          size={0}
        />
        <Geom
          type="point"
          position={`xAxis*right`}
          color="#89d2d4"
          shape="circle"
          size={dualChartData.length > 400 ? 1.7 : 3}
        />
      </Chart>
    </div>
  );
};

export default DualAxisAreaChart;
