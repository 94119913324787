import gql from "graphql-tag";

const GET_ORG_ALL_TIME_FILTER = gql`
  query getAllTimeFilter($id: ID!) {
    org(where: { id: $id }) {
      id
      name
      isAllTimeFiltered
    }
  }
`;

export default GET_ORG_ALL_TIME_FILTER;
