import React from "react";
import { Tooltip, Typography, Row } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { isNumber } from "lodash";

const { Text, Title } = Typography;

// Font sizes
const xS = "80%%"; //11
const s = "95%%"; //12
const mS = "100%"; //13
const m = "105%"; //14
const l = "120%"; //16
const xL = "220%"; //26

// Colors
const dark = "#636363";
const middle = "#808080";
const light = "#CCCCCC";

// Font weights
const lite = 400;
const medium = 500;
const bold = 700;

// Fonts
const font = "roboto";

// Page text: page titles etc..
export const PageTitleText = ({ text }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: l,
        fontWeight: bold,
        overflowWrap: "break-word",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "600px", // Adjust this to change the text length that can be shown
      }}
    >
      {text}
    </span>
  );
};

// Card Text
export const CardTitleText = ({ text, alignment }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: m,
        fontWeight: bold,
        color: dark,
        cursor: "default",
        textAlign: alignment,
        overflowWrap: "break-word",
        whiteSpace: "normal",
      }}
    >
      {text}
    </div>
  );
};

export const CardText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: middle,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

// Instructional/Tooltip text
export const TooltipText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: light,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

// Table Text
export const TableColumnTitle = ({ text }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: dark,
        cursor: "default",
        marginRight: "12px",
      }}
    >
      {text}
    </span>
  );
};

export const PrimaryTableRowText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: m,
        fontWeight: bold,
        color: dark,
        cursor: "default",
        // This code sets a CSS property value to "keep-all" if the length of a variable text is 10 characters or less,
        // otherwise, it sets it to "break-word" to control text wrapping in web pages.
        wordBreak: text && text.length <= 10 ? "keep-all" : "break-word",
      }}
    >
      {text}
    </div>
  );
};

export const StandardTableRowText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: m,
        //fontWeight: bold,
        //color: dark,
        cursor: "default",
        wordBreak: "break-word",
      }}
    >
      {text}
    </div>
  );
};

export const SecondaryTableRowText = ({ text, color }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: m,
        fontWeight: lite,
        color: color || light,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

// Details/KPI bar Text
export const KPITitleText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: m,
        fontWeight: medium,
        color: light,
        cursor: "default",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {text.toUpperCase()}
    </div>
  );
};

export const CustomKpiSubTextLarge = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: "150%",
        fontWeight: bold,
        color: dark,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

export const KpiSubText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: xL,
        fontWeight: bold,
        color: dark,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

export const KpiPercentageText = ({ text, prev = "", ranges = "" }) => {
  const value = text ? parseFloat(text) : 0;
  let status = "same";
  let icon;
  let color;

  if (isNumber(value) && isFinite(value)) {
    if (value < 0) status = "decrease";
    else if (value > 0) status = "increase";
  } else if (!isFinite(value)) {
    status = "infinity";
  } else status = "unknown";

  switch (status) {
    case "increase":
      icon = <ArrowUpOutlined />;
      color = "success";
      text += "%";
      break;
    case "decrease":
      icon = <ArrowDownOutlined />;
      color = "danger";
      text += "%";
      break;
    case "infinity":
      color = "secondary";
      text = "N/A";
      break;
    default:
      color = "secondary";
      break;
  }

  if (status === "same" || status === "unknown") return <div />;
  return (
    <Tooltip
      placement="bottomLeft"
      title={
        <Row>
          <span>Prev. Values: {prev}</span>
          <span>{ranges !== "" ? `Ranges: ${ranges}` : ""}</span>
        </Row>
      }
    >
      <Title level={5}>
        <Text type={color} strong>
          {icon}
          {isNumber(value) ? text : 0}
        </Text>
      </Title>
    </Tooltip>
  );
};

export const KpiSubTextOneOff = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: l,
        fontWeight: bold,
        color: dark,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

export const KpiFlightDate = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: middle,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

//BreadCrumb/Tab text
export const BreadCrumbText = ({ text }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: bold,
        color: middle,
      }}
    >
      {text}
    </span>
  );
};

export const InactiveBreadCrumbText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: light,
      }}
    >
      {text}
    </div>
  );
};

// This is used for all tabs generically, Ant Designs handles active tab cases automatically.
export const TabText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: middle,
        cursor: "inherit",
      }}
    >
      {text}
    </div>
  );
};

// Button text
export const ButtonText = ({ text }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
      }}
    >
      {text}
    </span>
  );
};

// Selector/dropdown menu Text
export const MenuItemText = ({ text }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: middle,
      }}
    >
      {text}
    </span>
  );
};

export const MenuItemTextColor = ({ text, color }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: color,
      }}
    >
      {text}
    </span>
  );
};

// Charts
export const YAxisColored = ({ text, color }) => {
  return (
    <span
      style={{
        fontFamily: font,
        fontSize: mS,
        fontWeight: medium,
        color: color,
      }}
    >
      {text}
    </span>
  );
};

// Misc text: The name of the component will attempt to explicitly describe what it's used for
export const FlightDateText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: xS,
        fontWeight: bold,
        color: middle,
        cursor: "default",
      }}
    >
      {text}
    </div>
  );
};

export const PaginationText = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: font,
        fontSize: s,
        fontWeight: medium,
        color: middle,
      }}
    >
      {text}
    </div>
  );
};
