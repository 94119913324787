import React from "react";
import { formatNumber } from "../../../../../../../../core/utils/campaigns";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";
import DataSet from "@antv/data-set";

const Donut = ({ height, data, selectedCreativeDimension }) => {
  const ctrdata = data.map(item => ({
    value: item.value,
    impressions: item.impressions,
    totalTransactions: item.totalTransactions,
  }));

  const { DataView } = DataSet;
  const dv = new DataView();

  dv.source(ctrdata).transform({
    as: "percent",
    type: "percent",
    dimension: "value",
    field: "percentctr",
  });

  const cols = {
    percent: {
      formatter: function(val) {
        val = val * 100;
        return formatNumber(val) + "%";
      },
    },
    impressions: { formatter: val => val.toLocaleString() },
    totalTransactions: { formatter: val => val.toLocaleString() },
  };

  return (
    <div>
      <Chart
        forceFit
        data={selectedCreativeDimension === "CTR" ? dv : ctrdata}
        padding={"auto"}
        scale={cols}
        height={height / 1.1}
      >
        <Coord type="theta" radius={0.75} innerRadius={0.65} />
        {selectedCreativeDimension === "impressions" && (
          <Axis name="impressions" />
        )}
        {selectedCreativeDimension === "totalTransactions" && (
          <Axis name="totalTransactions" />
        )}

        <Tooltip showTitle={false} />

        <Geom
          color={[
            "value",
            value => {
              const index = data.findIndex(el => el.value === value);
              if (data[index]) return data[index].color;
              else return "black";
            },
          ]}
          position={
            selectedCreativeDimension === "CTR"
              ? "percent"
              : selectedCreativeDimension === "impressions"
              ? "impressions"
              : "totalTransactions"
          }
          type="intervalStack"
        />
      </Chart>
    </div>
  );
};

export default Donut;
