import gql from "graphql-tag";

const EDIT_USER = gql`
  mutation editDashboardUser(
    $roleId: ID!
    $id: ID!
    $orgId: ID!
    $username: String!
    $email: String!
    $phone: String!
    $roleitems: [RoleItemCreateWithoutRoleInput!]
    $name: String
    $avatar: ID
    $config: UserConfigCreateInput!
    $ssoToken: String
  ) {
    editDashboardUser(
      data: {
        orgId: $orgId
        roleId: $roleId
        roleItems: $roleitems
        avatar: $avatar
        name: $name
        config: $config
        email: $email
        phone: $phone
        ssoToken: $ssoToken
      }
      where: { id: $id, username: $username }
    ) {
      id
      name
      username
      email
      phone
      cognitoUserId
      config {
        id
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        horizontalNavigation
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
        embeddableConfig {
          type
          ssoToken
          widgetConfig {
            transactionChart
            transactionTable
          }
          campaignOrder {
            id
            name
          }
          advertiser {
            id
            name
          }
          publisher {
            id
            name
          }
        }
        isBeta
      }
      roles(first: 5) {
        id
        roleItems(first: 20) {
          id
          feature
          actions
        }
        org {
          id
          name
          parentOrg {
            id
            name
          }
          logo {
            id
            key
          }
        }
      }
      avatar {
        id
        key
      }
      ssoToken
    }
  }
`;

export default EDIT_USER;
