import React from "react";

const SettingController = ({
  selectedOrg,
  updateOrg,
  orgTableConfig,
  themeConfig,
  orgTableConfigLoading,
  themeConfigLoading,
  orgTableConfigError,
  themeConfigError,
  orgTableConfigRefetch,
  currentUser,
  ...props
}) => {
  //Hook for updating the org info. This will be used to update basic info on the org as well as the logo
  const updateOrgInfo = async values => {
    const {
      name,
      description,
      website,
      zone,
      roasFormat,
      logo,
      imageKey,
    } = values;

    await updateOrg({
      variables: {
        where: {
          id: selectedOrg.id,
        },
        data: {
          name,
          description,
          website,
          zone,
          logo: {
            connect: logo ? { id: logo } : null,
          },
          dataConfig: {
            update: {
              roasFormat,
            },
          },
        },
      },
    });

    if (imageKey) {
      //Use local storage to update the logo on the sidebar. This will prevent the need to refresh the page
      window.localStorage.setItem("imageKey", imageKey);
      window.dispatchEvent(new Event("imageKey"));
    }
  };

  //Hook for updating the org UI. This will be used to update the table config and theme config
  const updateOrgUI = async values => {
    const {
      isShowFlight,
      isShowDaysRemaining,
      isShowStartDate,
      isShowEndDate,
      isShowImpressions,
      isShowWalkins,
      isShowPerformance,
      isShowPacing,
      isShowSales,
      isShowSignUps,
      isShowBudget,
      isShowAttribution,
      sidebarColor,
      primaryColor,
    } = values;
    // Org-level campaign table display column settings
    await updateOrg({
      variables: {
        where: {
          id: selectedOrg.id,
        },
        data: {
          tableConfig: {
            create: {
              isShowFlight,
              isShowDaysRemaining,
              isShowStartDate,
              isShowEndDate,
              isShowImpressions,
              isShowWalkins,
              isShowPerformance,
              isShowPacing,
              isShowSales,
              isShowSignUps,
              isShowBudget,
              isShowAttribution,
            },
          },
          themeConfig: {
            create: {
              sidebarColor,
              primaryColor,
            },
          },
        },
      },
    });

    // Refetch the org table config again after updating.
    await orgTableConfigRefetch();
  };

  //Hook for updating the org data. This will be used to update the data config
  const updateOrgData = async values => {
    const {
      dspAdvertiserId,
      menuId,
      defaultDisplayAttributionWindow,
      defaultPaidSearchAttributionWindow,
      defaultSEOAttributionWindow,
      tradeDeskIdentifier,
      storageBucket,
      seo,
      seoDomainRankings,
      googleAnalyticsViewId,
      googleAnalyticsViewIdIsGA4,
      semrushEnable,
      semrushProjectID,
      semrushRootDomainUrl,
      semrushRootKeyword,
      semrushCompetitor1stRootDomainUrl,
      semrushCompetitor2ndRootDomainUrl,
    } = values;

    await updateOrg({
      variables: {
        where: {
          id: selectedOrg.id,
        },
        data: {
          dataConfig: {
            update: {
              dspAdvertiserId,
              menuId,
              defaultDisplayAttributionWindow,
              defaultPaidSearchAttributionWindow,
              defaultSEOAttributionWindow,
              tradeDeskIdentifier,
              storageBucket,
              seo,
              seoDomainRankings,
              googleAnalytics: {
                create: {
                  viewId: googleAnalyticsViewId,
                  isGA4: googleAnalyticsViewIdIsGA4,
                },
              },
              semrushEnable,
              semrushConfig: {
                create: {
                  projectId: semrushProjectID,
                  rootDomainUrl: semrushRootDomainUrl,
                  rootKeyword: semrushRootKeyword,
                  competitor1stRootDomainUrl: semrushCompetitor1stRootDomainUrl,
                  competitor2ndRootDomainUrl: semrushCompetitor2ndRootDomainUrl,
                },
              },
            },
          },
        },
      },
    });

    //If the default display attribution window has changed, reload the page to remove all the cached data
    const selectedDefaultDisplayAttributionWindow =
      selectedOrg.dataConfig.defaultDisplayAttributionWindow;
    if (
      selectedDefaultDisplayAttributionWindow !==
      defaultDisplayAttributionWindow
    ) {
      window.location.reload();
    }
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    selectedOrg,
    updateOrgInfo,
    updateOrgUI,
    updateOrgData,
    orgTableConfig,
    themeConfig,
    orgTableConfigLoading,
    themeConfigLoading,
    orgTableConfigError,
    themeConfigError,
    currentUser,
  });
};

export default SettingController;
