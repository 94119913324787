import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Button } from "antd";
import { Storage } from "aws-amplify";
import { WeeklyReportPDFLink } from "../../utils/WeeklyReportPdf";

const ExportReportMenu = ({
  onExportCSV,
  onExportExcel,
  loading,
  onExportWeeklyReport,
  onExportWeeklyReportExcel,
  weeklyReportData,
  filteredOrg,
  currentUser,
}) => {
  const [logo, setLogo] = useState(null);
  useEffect(() => {
    if (
      currentUser &&
      currentUser.role &&
      currentUser.role.org &&
      currentUser.role.org.logo &&
      currentUser.role.org.logo.key
    ) {
      Storage.get(currentUser.role.org.logo.key)
        .then(result => {
          setLogo(result);
        })
        .catch(err => console.log("ycimage error", err));
    }
  }, [currentUser]);

  const menu = (
    <Menu>
      <Menu.ItemGroup title="PDF">
        <Menu.Item key={1}>
          <WeeklyReportPDFLink
            data={weeklyReportData}
            filteredOrg={filteredOrg}
            logo={logo}
          />
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="CSV">
        <Menu.Item key={2} onClick={onExportWeeklyReport}>
          Weekly Report
        </Menu.Item>
        <Menu.Item key={3} onClick={onExportCSV}>
          Advertiser Performance Report
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="EXCEL">
        <Menu.Item key={4} onClick={onExportWeeklyReportExcel}>
          Weekly Report
        </Menu.Item>
        <Menu.Item key={5} onClick={onExportExcel}>
          Advertiser Performance Report
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown trigger={["click"]} overlay={menu}>
      <Button
        style={{ width: "100%" }}
        loading={loading}
        shape="round"
        type="primary"
        icon={<DownloadOutlined />}
      >
        Export
      </Button>
    </Dropdown>
  );
};

export default ExportReportMenu;
