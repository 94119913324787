import React from "react";
import { DatePicker, Menu, Row, Col, Dropdown, Button } from "antd";
import moment from "moment";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { ranges } from "../hooks/ByDateFilter";
const { RangePicker } = DatePicker;

const DatePickerField = ({
  defaultPickerValue = [],
  defaultValue = [],
  value = [],
  dropDownValue = "Custom",
  setDropDownValue = () => {},
  setCustomDateRange = () => {},
  setDateChanged = () => {},
  has6MonDisables = true,
  showDropdown = true, //show dropdown or not
  allTimeDateRange = ["2020-01-01", moment().format("YYYY-MM-DD")],
  disabled = false,
}) => {
  /**
   * 6 months lookback only
   */
  const disabledDates = current => {
    if (has6MonDisables) {
      if (
        current <=
        moment()
          .utc(true)
          .subtract(6, "months")
      ) {
        return true;
      } else if (current >= moment().utc(true)) {
        return true;
      } else {
        return false;
      }
    } else {
      return current >= moment().utc(true);
    }
  };

  const onHandleDropdown = e => {
    setDropDownValue(e.key);
    const dropdownDates =
      e.key === "All Time" ? allTimeDateRange : ranges[e.key];
    setCustomDateRange(dropdownDates);
    setDateChanged(true);
  };

  const menuItems = Object.entries(ranges).map(r => (
    <Menu.Item key={r[0]} icon={<CalendarOutlined />}>
      {r[0]}
    </Menu.Item>
  ));
  const sDate = moment(value[0], "YYYY-MM-DD").format("YYYY-MM-DD");
  const eDate = moment(value[1], "YYYY-MM-DD").format("YYYY-MM-DD");

  return (
    <Row justify={"center"}>
      <Col xs={24} md={15} lg={!showDropdown ? 24 : 15}>
        <RangePicker
          disabled={disabled}
          style={{ width: "230px" }}
          allowClear={false}
          disabledDate={disabledDates}
          defaultPickerValue={defaultPickerValue}
          defaultValue={defaultValue}
          value={[moment(sDate), moment(eDate)]}
          onCalendarChange={v => {
            const getSelectedStartDate = moment(v[0] || value[0]).utc();
            const getSelectedEndDate = moment(v[1] || value[1]).utc();
            //make sure to only have valid date ranges
            if (
              getSelectedStartDate <= getSelectedEndDate &&
              getSelectedEndDate >= getSelectedStartDate
            ) {
              setCustomDateRange(v);
              setDropDownValue("Custom");
              setDateChanged(true);
            }
          }}
        />
      </Col>
      {showDropdown && (
        <Col xs={24} md={9}>
          <Dropdown
            overlay={<Menu onClick={onHandleDropdown}>{menuItems}</Menu>}
            disabled={disabled}
          >
            <Button style={{ width: "100%" }}>
              <CalendarOutlined />
              {dropDownValue} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};

export default DatePickerField;
