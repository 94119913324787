import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tooltip, Button, Grid } from "antd";
import { truncate } from "lodash";
// Styled Text
import { TabText } from "../../../../shared/globalStyling/styledText";

const ProviderTabs = ({
  providersTab,
  setProviderIndex,
  providersVisible,
  data,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <Tabs
      tabPosition={screens.xs === true ? "left" : "top"}
      onChange={activeKey => {
        setProviderIndex(activeKey);
      }}
      destroyInactiveTabPane={true}
    >
      <Tabs.TabPane tab={<TabText text={"All"} />} key="All"></Tabs.TabPane>
      {providersTab &&
        providersTab.map((provider, i) => {
          const idProvider = provider && provider[2] && provider[2].id;
          const dataCampaigns = data && data.campaigns;
          const selectedCampaignName = dataCampaigns.find(
            val => val.id === idProvider
          );
          const getSelectedName =
            selectedCampaignName && selectedCampaignName.name
              ? ` - ${selectedCampaignName.name}`
              : "";

          const dspProvider = provider && provider.length ? provider[0] : "";

          const combinedName = `${dspProvider}${getSelectedName}`;

          return (
            <Tabs.TabPane
              tab={
                <Tooltip
                  placement="topLeft"
                  title={
                    providersVisible === true
                      ? combinedName
                      : getSelectedName && `${getSelectedName} - Tier ( ${i} )`
                  }
                >
                  <Button
                    style={{
                      background: "transparent",
                      border: "0px",
                      boxShadow: "none",
                    }}
                  >
                    <TabText
                      text={
                        providersVisible === true
                          ? truncate(combinedName, { length: 20 })
                          : getSelectedName &&
                            getSelectedName + " - Tier (" + i + ")"
                      }
                    />
                  </Button>
                </Tooltip>
              }
              key={i.toString()}
            ></Tabs.TabPane>
          );
        })}
    </Tabs>
  );
};

ProviderTabs.propTypes = {
  providers: PropTypes.array,
  changeData: PropTypes.func,
};

export { ProviderTabs };
