import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//Temporary route to preserve legacy functionality
import DistributionTabView from "../distribution/DistributionTabView";
import Loader from "../../../../core/components/audiences/distribution/Loader";
//-----------------------------------------------------------------------------
import AudienceLayout from "../AudienceLayout";
import AudienceLayoutLoader from "../../../../core/components/audiences/Loader";
import AudienceDetailsView from "../details/AudienceDetailsView";
import AudienceDetailsLayoutLoader from "../../../../core/components/audiences/details/AudienceDetailsLoader";
import SegmentsLoader from "../../../../core/components/audiences/targets/Loader";
import GeoEventView from "../targets/GeoEventView";

import { UPDATEDAT_DESC } from "../utils";

const defaultOrderBy = UPDATEDAT_DESC;

// Feature level subroutes
const AudiencesRoutes = props => {
  const { path } = props.match;
  return (
    <Switch>
      <Route
        exact
        path={`${path}/create-audience/new/:pageNumber/:orderByFilter/:whereFilter`}
        render={props => (
          <SegmentsLoader
            tableConfig={{
              pageSize: 10,
              datakeys: [],
            }}
            {...props}
          >
            <GeoEventView checkSwitchMode={true} />
          </SegmentsLoader>
        )}
      />
      <Route
        exact
        path={`${path}/new/:pageNumber`}
        render={() => (
          <AudienceLayoutLoader
            tableConfig={{
              pageSize: 10,
              datakeys: [],
            }}
            {...props}
          >
            <AudienceLayout />
          </AudienceLayoutLoader>
        )}
      />

      <Route
        exact
        path={`${path}/details/:id/:pageNumber?`}
        render={() => (
          <AudienceDetailsLayoutLoader>
            <AudienceDetailsView />
          </AudienceDetailsLayoutLoader>
        )}
      />

      <Route
        exact
        path={`${path}/distribution`}
        render={() => (
          <Loader>
            <DistributionTabView />
          </Loader>
        )}
      />
      <Route
        path={`${path}`}
        component={() => (
          <Redirect to={`${path}/new/1?orderBy=${defaultOrderBy}`} />
        )}
      />
    </Switch>
  );
};

export default AudiencesRoutes;
