import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Loader from "../../../../core/components/invoice";
import InvoiceMainView from "../InvoiceMainView";
import InvoiceDetailsView from "../InvoiceDetailsView";

const InvoiceRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          path={`${path}/main/:pageNumber?`}
          exact
          render={() => (
            <Loader {...props}>
              <InvoiceMainView />
            </Loader>
          )}
        />
        <Route
          exact
          path={`${path}/details/:id?`}
          render={() => (
            <Loader {...props}>
              <InvoiceDetailsView />
            </Loader>
          )}
        />
        {/* Fallback */}
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}/main/1`} />}
        />
      </Switch>
    </div>
  );
};

export default InvoiceRoutes;
