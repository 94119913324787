import gql from "graphql-tag";

// TODO: Remove this mutation once the new createCampaignOrder mutation is fully ready and tested
// This payload is not configurable
const CREATE_CAMPAIGN_ORDER_OLD = gql`
  mutation createCampaignOrder(
    $orgID: ID!
    $status: CampaignStatus!
    $campaignName: String!
    $description: String
    $startDate: DateTime
    $endDate: DateTime
    $devices: [DeviceType!]!
    $objectiveNewCustomers: Int
    $objectiveExistingCustomers: Int
    $retargetDayCap: Int
    $retargetTotalCap: Int
    $kpi: KPI!
    $kpiGoal: Float
    $budget: Float
    $geoTargets: [GeoTargetCreateWithoutCampaignOrderInput!]
    $attributionTargets: [GeoTargetCreateInput!]
    $adUnits: [AdUnitCreateWithoutCampaignOrderInput!]
    $brands: [BrandWhereUniqueInput!]
    $language: [Language!]
    $category: [String!]
    $age: [String!]
    $income: [String!]
    $targetAudiences: [AudienceWhereUniqueInput!]
    $tags: [String!]
    $isRegulated: Boolean
    $advertiser: ID!
    $type: CampaignType!
    $attributionWindow: Int
    $cpm: Float
    $targetingCriteria: String
    $awarenessCampaign: Boolean
    $signUpCampaign: Boolean
    $processOne: Boolean
    $processOneText: Json
    $processTwo: Boolean
    $processTwoText: Json
    $processThree: Boolean
    $processThreeText: Json
    $processFour: Boolean
    $processFourText: Json
    $processFive: Boolean
    $processFiveText: Json
    $signUpConfig: SignUpConfigCreateOneWithoutCampaignOrdersInput
    $pageViewConfig: PageViewConfigCreateOneWithoutCampaignOrdersInput
    $transactionTarget: String
    $transactionsConfig: TransactionsConfigCreateOneWithoutCampaignOrdersInput
    $region: [String!]
    $isAmplify: Boolean
    $review: String
    $stateLaw: String
    $scaleBudget: String
    $requestorId: String
  ) {
    createCampaignOrder(
      data: {
        status: $status
        orgs: { connect: { id: $orgID } }
        name: $campaignName
        description: $description
        startDate: $startDate
        endDate: $endDate
        devices: { set: $devices }
        objectiveNewCustomers: $objectiveNewCustomers
        objectiveExistingCustomers: $objectiveExistingCustomers
        retargetDayCap: $retargetDayCap
        retargetTotalCap: $retargetTotalCap
        kpi: $kpi
        kpiGoal: $kpiGoal
        budget: $budget
        adUnits: { create: $adUnits }
        geoTargets: { create: $geoTargets }
        attributionTargets: { create: $attributionTargets }
        brands: { connect: $brands }
        language: { set: $language }
        category: { set: $category }
        age: { set: $age }
        income: { set: $income }
        targetAudiences: { connect: $targetAudiences }
        tags: { set: $tags }
        isRegulated: $isRegulated
        advertiser: { connect: { id: $advertiser } }
        type: $type
        attributionWindow: $attributionWindow
        cpm: $cpm
        targetingCriteria: $targetingCriteria
        awarenessCampaign: $awarenessCampaign
        signUpCampaign: $signUpCampaign
        campaignChecklistProcess: {
          create: {
            processOne: $processOne
            processOneText: $processOneText
            processTwo: $processTwo
            processTwoText: $processTwoText
            processThree: $processThree
            processThreeText: $processThreeText
            processFour: $processFour
            processFourText: $processFourText
            processFive: $processFive
            processFiveText: $processFiveText
          }
        }
        signUpConfig: $signUpConfig
        pageViewConfig: $pageViewConfig
        transactionTarget: $transactionTarget
        transactionsConfig: $transactionsConfig
        region: { set: $region }
        isAmplify: $isAmplify
        campaignReviewProcess: {
          create: {
            review: $review
            stateLaw: $stateLaw
            scaleBudget: $scaleBudget
          }
        }
        requestorId: $requestorId
      }
    ) {
      id
      status
      name
      description
      type
      geoTargets {
        id
        name
        circles {
          radius
          coordinate {
            lat
            lng
          }
          location {
            id
            name
            hasNotes
            notes
          }
        }
        polygons {
          geometry
        }
      }
      attributionTargets {
        id
        name
        circles {
          radius
          coordinate {
            lat
            lng
          }
        }
        polygons {
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
      orgs {
        id
        name
      }
      brands {
        id
        name
      }
      language
      category
      isRegulated
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      attributionWindow
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        demandSidePlatform
      }
      advertiser {
        id
        name
      }
      targetingCriteria
      awarenessCampaign
      signUpCampaign
      campaignChecklistProcess {
        processOne
        processOneText
        processTwo
        processTwoText
        processThree
        processThreeText
        processFour
        processFourText
        processFive
        processFiveText
      }
      signUpConfig {
        id
        appId
      }
      transactionTarget
      transactionsConfig {
        appId
        cart
        traffId
        tracksBasketItems
        gtmAccess
        gtmStatus
        updatedAt
      }
      pageViewConfig {
        id
        appId
        locationId
      }
      region
      isAmplify
      campaignReviewProcess {
        review
        stateLaw
        scaleBudget
      }
      requestorId
    }
  }
`;

export default CREATE_CAMPAIGN_ORDER_OLD;
