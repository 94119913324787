import React from "react";
import { useQuery } from "@apollo/react-hooks";
import PaidSearchCommerceController from "./PaidSearchCommerceController";

import PAID_SEARCH_COMMERCE from "../../../../GraphQl/Queries/PAID_SEARCH_COMMERCE";
import PAID_SEARCH_COMMERCE_TRANSACTION from "../../../../GraphQl/Queries/PAID_SEARCH_COMMERCE_TRANSACTION";

import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import moment from "moment";

const PaidSearchCommerceLoader = ({ children, ...props }) => {
  const { location, match } = props;
  const params = new URLSearchParams(location.search);
  const pageNumber = match.params.pageNumber;

  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  const attribution =
    params.get("attribution") && params.get("attribution") !== "ALL"
      ? params.get("attribution")
      : null;
  const attributionWindow = params.get("attributionWindow")
    ? params.get("attributionWindow")
    : 30;

  const startDate = params.get("startDate")
    ? params.get("startDate")
    : moment()
        .utc()
        .subtract(30, "days");
  const endDate = params.get("endDate")
    ? params.get("endDate")
    : moment().utc();
  let whereTransactions = { AND: [] };
  if (attribution) {
    whereTransactions["AND"].push({ attributionMethod: attribution });
  }
  // if (startDate) {
  //   whereTransactions["AND"].push({
  //     orderedOn_gte: moment(startDate)
  //       .utc()
  //       .startOf("day")
  //       .format(),
  //   });
  // }
  // if (endDate) {
  //   whereTransactions["AND"].push({
  //     orderedOn_lte: moment(endDate)
  //       .utc()
  //       .endOf("day")
  //       .format(),
  //   });
  // }

  const {
    data: paidSearchSummary,
    loading: loadingCommerce,
    error: errorCommerce,
  } = useQuery(PAID_SEARCH_COMMERCE, {
    variables: {
      orgId: props.orgId,
      whereTransactions,
      attributionWindow: Number(attributionWindow),
    },
    fetchPolicy: "no-cache",
  });
  let where = {
    AND: [
      {
        paidSearchCommerceUnit: {
          id: paidSearchSummary
            ? paidSearchSummary.getPaidSearchCommerce.commerce.overallCommerce
                .id
            : null,
        },
      },
    ],
  };
  if (attribution) {
    where["AND"].push({ attributionMethod: attribution });
  }
  if (startDate) {
    where["AND"].push({
      orderedOn_gte: moment(startDate)
        .utc()
        .startOf("day")
        .format(),
    });
  }
  if (endDate) {
    where["AND"].push({
      orderedOn_lte: moment(endDate)
        .utc()
        .endOf("day")
        .format(),
    });
  }

  if (attributionWindow) {
    where["AND"].push({
      id_not_in: paidSearchSummary
        ? paidSearchSummary.getPaidSearchCommerce.excludedTransactions
        : [],
    });
  }

  const {
    data: paidSearchTransactions,
    loading: loadingPaidSearchTransactions,
  } = useQuery(PAID_SEARCH_COMMERCE_TRANSACTION, {
    variables: {
      first: pageSize,
      skip: (Number(pageNumber) - 1) * pageSize,
      where,
    },
    fetchPolicy: "no-cache",
  });

  if (errorCommerce) console.error(errorCommerce);
  if (loadingCommerce || loadingPaidSearchTransactions)
    return <OverlaySpinner />;

  const hasDateParams =
    params.get("startDate") && params.get("endDate") ? true : false;
  return (
    <PaidSearchCommerceController
      paidSearchSummary={paidSearchSummary.getPaidSearchCommerce.commerce}
      orgName={paidSearchSummary.getPaidSearchCommerce.commerce.org.name}
      loadingCommerce={loadingCommerce}
      paidSearchTransactions={paidSearchTransactions}
      totalTransactions={paidSearchTransactions.full.aggregate.count}
      pageNumber={pageNumber}
      startDate={startDate}
      endDate={endDate}
      attribution={attribution}
      hasDateParams={hasDateParams}
      attributionWindow={attributionWindow}
      {...props}
    >
      {children}
    </PaidSearchCommerceController>
  );
};

export default PaidSearchCommerceLoader;
