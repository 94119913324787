import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo";
import ORG_SEO from "../../../GraphQl/Queries/ORG_SEO";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { Auth } from "aws-amplify";

const SeoDetailsLoader = props => {
  const { match, userPermission } = props;

  const id = match.params.id;

  const { data, loading, error } = useQuery(ORG_SEO, {
    variables: { id },
  });

  if (loading) return <OverlaySpinner />;
  if (error && error.graphQLErrors[0].extensions.code === "401") {
    //Force signout if user has no access
    Auth.signOut();
    window.location.reload();
  }

  const seoSummary = data ? data.orgSeoSummary : null;
  const keywordsReport = data ? data.keywordsReport : null;
  const trafficReport = data ? data.trafficReport : null;
  const conversionsReport = data ? data.conversionsReport : null;

  return React.cloneElement(props.children, {
    ...props.children.props,
    userPermission,
    seoLoading: loading,
    seoSummary,
    seoName: seoSummary.org.name,
    keywordsReport,
    trafficReport,
    conversionsReport,
  });
};

export default withRouter(SeoDetailsLoader);
