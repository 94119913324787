import React from "react";
import { Button, Tooltip } from "antd";

class ClearAllShapesButton extends React.Component {
  render() {
    return (
      <Tooltip title="Clears all Shapes">
        <Button size={"small"} style={{ width: 120 }} type="normal">
          Clear All
        </Button>
      </Tooltip>
    );
  }
}

export default ClearAllShapesButton;
