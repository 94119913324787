import gql from "graphql-tag";

// Deprecated this mutation is not configurable
const UPDATE_GEOTARGET = gql`
  mutation updateGeoTarget(
    $id: ID!
    $circles: [CircleUpdateWithWhereUniqueNestedInput!]!
  ) {
    updateGeoTarget(
      where: { id: $id }
      data: { circles: { update: $circles } }
    ) {
      id
      circles {
        id
        coordinate {
          lat
          lng
        }
        radius
      }
      polygons {
        geometry
      }
    }
  }
`;

export default UPDATE_GEOTARGET;
