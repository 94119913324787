import gql from "graphql-tag";
import { CampaignRequestorFields } from "../Fragments/CampaignRequestorFragments";

const UPDATE_CAMPAIGN_ORDER_REQUESTOR = gql`
  ${CampaignRequestorFields}

  mutation campaignRequestorUpdateCampaignOrder(
    $data: CampaignOrderUpdateInput!
    $where: CampaignOrderWhereUniqueInput!
  ) {
    updateCampaignOrderRequestor(data: $data, where: $where) {
      ...CampaignRequestorFields
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_REQUESTOR;
