import React from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { Row, Col, Space } from "antd";
import DetailBar from "./components/DetailBar";
import SummaryChart from "../../charts/SummaryChart";
import TransactionTable from "./components/TransactionTable";

const CommerceTab = ({
  commerce,
  summaryDate,
  transactions,
  location,
  history,
  pageNumber,
}) => {
  const { revenue, totalTransactions, byDate, totalItems } = commerce;
  return (
    <>
      <Row gutter={35}>
        <Col xs={24}>
          <DetailBar
            transactions={totalTransactions}
            revenue={revenue}
            items={totalItems}
          />
          <SummaryChart
            reportData={isEmpty(byDate) ? [] : byDate}
            bySummaryRangeDate={
              !isEmpty(summaryDate)
                ? [moment(summaryDate[0]).utc(), moment(summaryDate[1]).utc()]
                : null
            }
            setBySummaryRangeDate={dates => {
              const nextPage = location.pathname.split("/");

              nextPage[4] = 1;
              const params = new URLSearchParams(location.search);
              params.delete("startDate");
              params.delete("endDate");
              params.set("startDate", dates[0].utc().format());
              params.set("endDate", dates[1].utc().format());
              history.push({
                pathname: nextPage.join("/"),
                search: `?${params.toString()}`,
              });
            }}
            defaultXValue="transactions"
            defaultYValue="revenue"
          />
          <Space />
          <TransactionTable
            transactions={transactions}
            hasItems={totalItems !== 0}
            location={location}
            history={history}
            pageNumber={pageNumber}
          />
          <Space />
        </Col>
      </Row>
    </>
  );
};

export default CommerceTab;
