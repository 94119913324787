import gql from "graphql-tag";

const CAMPAIGN_ORDER_AMPLIFY = gql`
  query campaignOrders($orgId: ID!, $isAmplify: Boolean) {
    campaignOrders(
      where: { isAmplify: $isAmplify, orgs_some: { OR: [{ id: $orgId }] } }
    ) {
      id
      name
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
          }
        }
      }
      isAmplify
    }
  }
`;

export default CAMPAIGN_ORDER_AMPLIFY;
