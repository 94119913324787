import { Card, Col, Row, Tabs } from "antd";
import * as XLSX from "xlsx";
import React from "react";
import SEOTable from "./SEOTable";
import { isEmpty, cloneDeep } from "lodash";
import { DownloadOutlined } from "@ant-design/icons";
import JSZip from "jszip";
import moment from "moment";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import Button from "../../../../../shared/globalStyling/Button";
import DualAxisChart from "../../../../../shared/charts/DualAxisChart";
import { summaryScale } from "../../../utils/summaryScale";

const { TabPane } = Tabs;

const AnalyticsTab = ({
  loading,
  data,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  priceVisible,
  exportData,
  orgConfig,
  defaultSummaryDates,
  isGoogleAds,
  WRITE,
  startDate,
  endDate,
  diffPcts,
  currencyFormat,
  selectedRangeKey,
}) => {
  const exportFile = fileExtension => {
    try {
      const zip = new JSZip();
      const folder = zip.folder("SEO Analytics");
      let fileName = "seo-analytics";
      let dupFileData =
        isEmpty(data) && isEmpty(data.byDate) ? [] : cloneDeep(data.byDate);
      dupFileData = dupFileData.map(item => {
        item.date = moment(item.xAxis).format("YYYY-MM-DD");
        return item;
      });
      const newData = dupFileData.map(({ key, xAxis, ...rest }) => rest);

      if (fileExtension === "csv") {
        folder.file(
          `${fileName}-${moment.utc().format("MMMM DD YYYY")}.csv`,
          Papa.unparse(newData)
        );
      } else if (fileExtension === "xlsx") {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });

        folder.file(
          `${fileName}-${moment.utc().format("MMMM DD YYYY")}.xlsx`,
          data
        );
      }

      zip.generateAsync({ type: "blob" }).then(
        blob => {
          saveAs(blob, "seo-analytics.zip");
          return true;
        },
        function(e) {
          console.log("error", e);
          return false;
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const ExportCSVButton = () => {
    return (
      <Row justify={{ xs: "center", md: "end" }} gutter={[16, 16]}>
        <Col xs={24} md={7} xl={4}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => exportFile("csv")}
          >
            <DownloadOutlined />
            {`Export CSV`}
          </Button>
        </Col>
        <Col xs={24} md={7} xl={4}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => exportFile("xlsx")}
          >
            <DownloadOutlined />
            {`Export Excel`}
          </Button>
        </Col>
      </Row>
    );
  };

  const dropDownOptions = [
    { key: "session", display: "Sessions" },
    { key: "pagesPerSession", display: "Page View" },
    { key: "bounceRate", display: "Bounce Rate" },
    { key: "revenue", display: "Revenue" },
    { key: "avgSessionDuration", display: "Average Session Duration" },
    { key: "goalConversionRate", display: "Goal Conversion Rate" },
    { key: "transactions", display: "Transactions" },
    { key: "avgOrderValue", display: "Average Order Value" },
    { key: "user", display: "New Users" },
  ];
  return (
    <React.Fragment>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ fontSize: "22px", marginTop: "1rem" }}
      >
        <TabPane tab="Summary" key="summary">
          <Row>
            <Col xs={24}>
              <Card loading={loading}>
                {exportData && <ExportCSVButton />}
                <DualAxisChart
                  byDate={isEmpty(data.byDate) ? [] : data.byDate}
                  xAxis="date"
                  options={dropDownOptions}
                  defaultLeftYValue={dropDownOptions[0]} //Sessions
                  defaultRightYValue={dropDownOptions[3]} //Revenue
                  defaultRangeKey={selectedRangeKey}
                  defaultSummaryDates={defaultSummaryDates}
                  onDatePickerChange={({ data, dates, rangeKey }) => {
                    setBySummaryRangeDate(dates, rangeKey);
                  }}
                  hasTooltip
                  tootip={
                    <span>
                      Data is processed daily and is not in real time. Google
                      analytics uses data sampling which is designed to speed up
                      reporting. In addition, revenue and transactions are
                      subject to a default 30 day conversion window. Minor
                      discrepancies between this dashboard and the Google
                      Interface are to be expected.
                      <br />
                      <br />
                      Data Sampling - Links to
                      <br />
                      <a href="https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article">
                        https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article
                      </a>
                    </span>
                  }
                  customScale={summaryScale}
                />
              </Card>
            </Col>
            <Col xs={24}>
              <Card loading={loading}>
                <SEOTable data={data.byDate} currencyFormat={currencyFormat} />
              </Card>
            </Col>
          </Row>
        </TabPane>
        {/** Removing Trends Temporarily since Summary and Trends are basically the same */}
        <TabPane tab="Trends" key="trends">
          <Row>
            <Col xs={24}>
              <Card loading={loading}>
                {exportData && <ExportCSVButton />}
                <DualAxisChart
                  byDate={isEmpty(data.byDate) ? [] : data.byDate}
                  xAxis="date"
                  options={dropDownOptions}
                  defaultSummaryDates={defaultSummaryDates}
                  defaultLeftYValue={dropDownOptions[0]} //Sessions
                  defaultRightYValue={dropDownOptions[3]} //Revenue
                  onDatePickerChange={({ data, dates, rangeKey }) => {
                    setBySummaryRangeDate(dates, rangeKey);
                  }}
                  isRightYBar={true}
                  hasTooltip
                  tootip={
                    <span>
                      Data is processed daily and is not in real time. Google
                      analytics uses data sampling which is designed to speed up
                      reporting. In addition, revenue and transactions are
                      subject to a default 30 day conversion window. Minor
                      discrepancies between this dashboard and the Google
                      Interface are to be expected.
                      <br />
                      <br />
                      Data Sampling - Links to
                      <br />
                      <a href="https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article">
                        https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article
                      </a>
                    </span>
                  }
                  customScale={summaryScale}
                />
              </Card>
            </Col>
            <Col xs={24}>
              <Card loading={loading}>
                <SEOTable data={data.byDate} currencyFormat={currencyFormat} />
              </Card>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default AnalyticsTab;
