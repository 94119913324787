import React from "react";
import BingTab from "./BingTab";
import PaidSearchBingLoader from "../../../../../../core/components/paidSearch/paidsearchdetails/BingTab/PaidSearchBingLoader";

const BingDetailsView = props => {
  return (
    <PaidSearchBingLoader {...props}>
      <BingTab id={props.id} />
    </PaidSearchBingLoader>
  );
};

export default BingDetailsView;
