import gql from "graphql-tag";

const CREATE_ADGROUP_CREATIVES = gql`
  mutation createAdGroupCreatives(
    $name: String
    $medias: [MediaWhereUniqueInput!]
    $flag: Boolean
    $org: [OrgWhereUniqueInput!]
    $adProviderId: String
  ) {
    createAdGroupCreatives(
      data: {
        name: $name
        medias: { connect: $medias }
        org: { connect: $org }
        flag: $flag
        adProviderId: $adProviderId
      }
    ) {
      id
      name
      medias {
        id
        name
      }
      flag
      org {
        id
        name
      }
      adProviderId
    }
  }
`;

export default CREATE_ADGROUP_CREATIVES;
