import React from "react";
import { Component } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
// import logo from "../../../../assets/Mj.png";
import bluebg from "../../../../assets/bluebg.png";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

class MyDocumentPDF extends Component {
  constructor(props) {
    super(props);

    this.titleName = "Insights and ";
    this.titleName2 = "Analytics Report";
    this.BoxesmarginLeft = 15;
    this.lineSpacing = 0.5;
    this.headerTitles = ["creative", "impressions", "clicks", "ctr"];
    this.NUMBER_OF_COL = this.headerTitles.length - 1;
    this.BORDER_COLOR = "#bfbfbf";
    this.BORDER_STYLE = "solid";
    this.COL1_WIDTH = 30;
    this.COLN_WIDTH = (100 - this.COL1_WIDTH) / this.NUMBER_OF_COL;

    this.styles = StyleSheet.create({
      box_title_top: {
        color: "#e8eef5",
        fontSize: 13,
        textAlign: "left",
        marginBottom: 10,
        fontWeight: "bold",
        position: "relative",
        top: -40,
      },
      box_title_bottom: {
        color: "#e8eef5",
        fontSize: 10,
        marginTop: 200,
        textAlign: "right",
        marginLeft: 20,
        position: "absolute",
        right: 1,
      },
      header: {
        padding: 10,
        margin: "1%",
        // backgroundColor: "#3277b3",
        height: 120,
        textAlign: "center",
        fontSize: 24,
        color: "#464646",
        width: "98%",
      },
      mediaJelLogo: {
        height: 150,
        width: 150,
        position: "absolute",
        left: 20,
        top: 10,
      },
      mediaJelName: {
        color: "#e8eef5",
        textAlign: "left",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: 40,
        position: "absolute",
        left: 180,
        top: 50,
      },
      advertiserLogo: {
        height: 50,
        width: 50,
        position: "absolute",
        left: 350,
        top: 10,
      },
      advertiserName: {
        color: "#e8eef5",
        textAlign: "right",
        marginTop: -80,
        marginRight: 0,
        fontFamily: "Open Sans",
        fontWeight: 600,
      },

      image: {
        height: 50,
        width: 50,
        marginLeft: 20,
      },
      TextTitle: {
        color: "#e8eef5",
        marginTop: 15,
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: 30,
        position: "absolute",
        right: 340,
        top: 50,
        letterSpacing: "3px",
      },
      TextTitle2: {
        color: "#e8b33d",
        marginTop: 15,
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: 30,
        position: "absolute",
        right: 55,
        top: 50,
        letterSpacing: "3px",
      },
      TextTitle3: {
        color: "#e8eef5",
        marginTop: 15,
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: 25,
        position: "absolute",
        right: 55,
        top: 110,
        letterSpacing: "3px",
      },
      Client_Name: {
        marginTop: -80,
        textAlign: "right",
      },
      body: {
        margin: "0 auto",
        padding: 50,
        backgroundColor: "#3277b3",
        height: 290,
        textAlign: "center",
        width: "73%",
        marginTop: 10,
        borderRadius: 5,
      },
      boxes: {
        marginTop: "70px",
        backgroundColor: "#d2d2d2",
        height: 75,
        width: 116,
        position: "absolute",
        borderRadius: 5,
      },
      box1: {
        left: this.BoxesmarginLeft,
        top: 10,
        transactions: {
          fontSize: 13,
          marginTop: 10,
          color: "#464646",
          marginLeft: 5,
          number: {
            fontSize: 15,
            marginLeft: 13,
            color: "#464646",
          },
        },
      },
      box2: {
        left: this.BoxesmarginLeft + 150,
        top: 10,
        transactions: {
          fontSize: 13,
          marginTop: 10,
          textAlign: "left",
          color: "#464646",
          number: {
            fontSize: 15,
            marginLeft: 10,
            color: "#464646",
            textAlign: "left",
          },
        },
      },
      box3: {
        left: this.BoxesmarginLeft + 150 + 150,
        top: 10,
        transactions: {
          fontSize: 13,
          marginTop: 10,
          color: "#464646",
          number: {
            fontSize: 20,
            marginLeft: 10,
            color: "#464646",
          },
        },
        roas: {
          marginLeft: 10,
        },
      },
      boxes_white: {
        backgroundColor: "#d2d2d2",
        left: this.BoxesmarginLeft,
        top: 30 + 75,
        TextTitle: {
          color: "#464646",
          fontSize: 23,
          marginTop: 5,
          marginDown: 5,
          marginLeft: 60,
        },
        TextTitleSpend: {
          color: "#464646",
          fontSize: 13,
          marginTop: 5,
          marginDown: 5,
          marginLeft: 10,
          textAlign: "left",
        },
        BoxSpend_TextData: {
          color: "#464646",
          fontSize: 13,
          marginTop: -15,
          marginRight: 50,
          textAlign: "right",
        },
        TextData: {
          color: "#464646",
          fontSize: 20,
        },
        TextSub: {
          marginTop: 0,
        },
        TrendUp: {
          color: "#388e3c",
        },
        TrendDown: {
          color: "#f44336",
        },
        TrendFlat: {
          color: "#4D4D50",
        },
      },
      box_clicks: {
        left: this.BoxesmarginLeft + 150,
      },
      box_ctr: {
        left: this.BoxesmarginLeft + 150 + 150,
      },
      box_spend_text: {
        left: this.BoxesmarginLeft,
        width: "96%",
        marginTop: 5,
        color: "#e0e9f2",
        position: "absolute",
      },
      box_spend_value: {
        left: this.BoxesmarginLeft,
        marginTop: 15,
        color: "#464646",
      },
      box_spend: {
        left: this.BoxesmarginLeft,
        marginTop: 35,
        width: "120%",
        backgroundColor: "#d2d2d2",
        height: 100,
        position: "absolute",
        borderRadius: 5,
      },
      textWhenData: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "right",
        marginRight: -40,
        marginTop: -40,
        subText: {
          fontSize: 8,
          marginTop: -40 + 40,
          marginRight: -40,
        },
      },
      last_box: {
        box1: {
          border: "2 solid #393A39",
          height: 180,
          position: "absolute",
          left: 10,
          width: "93%",
          top: 10,
          borderRadius: 5,
        },
      },
      topCreative: {
        position: "absolute",
        marginTop: 150 / 2,
        textAlign: "right",
        right: 8,
        fontWeight: 600,
        whiteSpace: "wrap",
      },
      table: {
        display: "table",
      },
      tableRowHeader: {
        flexDirection: "row",
        backgroundColor: "#AC5773",
        height: "27",
      },
      tableRow: {
        flexDirection: "row",
      },
      tableCol1Header: {
        width: this.COL1_WIDTH + "%",
        borderStyle: this.BORDER_STYLE,
        borderColor: this.BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableColHeader: {
        width: this.COLN_WIDTH + "%",
        borderStyle: this.BORDER_STYLE,
        borderColor: this.BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol1: {
        width: this.COL1_WIDTH + "%",
        borderStyle: this.BORDER_STYLE,
        borderColor: this.BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol: {
        width: this.COLN_WIDTH + "%",
        borderStyle: this.BORDER_STYLE,
        borderColor: this.BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        flexDirection: "row",
      },
      tableCellHeader: {
        fontSize: 13,
        color: "#464646",
        margin: "4px",
        textAlign: "left",
      },
      tableCell: {
        margin: 5,
        fontSize: 10,
        textAlign: "left",
      },
      i: {
        backgroundColor: "#7B4069",
        height: 10,
        width: 50,
        marginTop: 5,
        marginBottom: 5,
      },
    });
  }

  formatNumberToString(numb) {
    try {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    } catch (err) {
      return "0";
    }
  }

  render() {
    return (
      <Document>
        <Page size={{ width: "1920px", height: "1080px" }}>
          <Image
            src={bluebg}
            style={{
              position: "absolute",
              minWidth: "100%",
              minHeight: "100%",
              display: "block",
              height: "100%",
              width: "100%",
            }}
          />
          <View style={this.styles.header} wrap={true}>
            <Text
              style={this.styles.TextTitle}
              render={() => `${this.titleName}`}
            />
            <Text
              style={this.styles.TextTitle2}
              render={() => `${this.titleName2}`}
            />
            <Text
              style={this.styles.TextTitle3}
              render={() => this.props.advertiserName}
            />
            {this.props.orgLogoUrl &&
              this.props.orgLogoUrl !== null &&
              this.props.orgLogoUrl !== "" && (
                <Image
                  style={this.styles.mediaJelLogo}
                  src={this.props.orgLogoUrl}
                />
              )}
          </View>

          <View
            style={{
              backgroundColor: "white",
              opacity: "0.1",
              height: "250px",
              width: "450px",
              border: "3px solid white",
              position: "absolute",
              top: 260,
              left: 100,
              borderRadius: "15%",
            }}
          >
            <Text
              style={{
                opacity: 1,
                color: "white",
                fontSize: 30,
                position: "absolute",
                top: 30,
                left: 50,
              }}
            >
              LAST 7 DAYS
            </Text>
            <Text
              style={{
                opacity: 1,
                color: "white",
                fontSize: 43,
                position: "absolute",
                top: 110,
                left: 50,
              }}
            >
              {this.props.weekly.userCost !== null
                ? "$ " +
                  this.formatNumberToString(
                    parseFloat(this.props.weekly.userCost).toFixed(2)
                  )
                : "N/A"}
              <Text></Text>
            </Text>
            <Text
              style={{
                opacity: 1,
                color: "#e8b33d",
                fontSize: 30,
                position: "absolute",
                top: 170,
                left: 50,
              }}
            >
              Spend
            </Text>
          </View>

          <View
            style={{
              backgroundColor: "white",
              opacity: "0.1",
              height: "250px",
              width: "450px",
              border: "3px solid white",
              position: "absolute",
              top: 580,
              left: 100,
              borderRadius: "15%",
            }}
          >
            <Text
              style={{
                opacity: 1,
                color: "white",
                fontSize: 30,
                position: "absolute",
                top: 30,
                left: 50,
              }}
            >
              MONTH-TO-DATE
            </Text>
            <Text
              style={{
                opacity: 1,
                color: "white",
                fontSize: 43,
                position: "absolute",
                top: 110,
                left: 50,
              }}
            >
              {this.props.monthly.userCost !== null
                ? "$ " +
                  this.formatNumberToString(
                    parseFloat(this.props.monthly.userCost).toFixed(2)
                  )
                : "N/A"}
            </Text>
            <Text
              style={{
                opacity: 1,
                color: "#e8b33d",
                fontSize: 30,
                position: "absolute",
                top: 170,
                left: 50,
              }}
            >
              Spend
            </Text>
          </View>

          <View
            style={{
              height: "650px",
              width: "1200px",
              marginLeft: "600px",
              marginTop: "100px",
              color: "white",
            }}
          >
            <div id="weekly container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "2px solid #929299",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    TRANSACTION
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(
                      this.props.weekly.transactionCount
                    )}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    INFLUENCED SALES
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    $
                    {this.formatNumberToString(
                      parseFloat(this.props.weekly.transactionTotal).toFixed(2)
                    )}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    ROAS
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.props.weekly.roas !== null
                      ? this.formatNumberToString(
                          parseFloat(this.props.weekly.roas).toFixed(2)
                        )
                      : "N/A"}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "2px solid #929299",
                  paddingBottom: "10px",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    IMPRESSION
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.weekly.impressions)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.weekly.pctChangeImpressions.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.weekly.pctChangeImpressions.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.weekly.pctChangeImpressions.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.weekly.pctChangeImpressions.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.weekly.pctChangeImpressions.value
                    )}
                  </Text>
                </div>
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    CLICKS
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.weekly.clicks)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.weekly.pctChangeClicks.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.weekly.pctChangeClicks.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.weekly.pctChangeClicks.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.weekly.pctChangeClicks.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.weekly.pctChangeClicks.value
                    )}
                  </Text>
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    CTR
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.weekly.ctr)} %
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.weekly.pctChangeCTR.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.weekly.pctChangeCTR.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.weekly.pctChangeCTR.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.weekly.pctChangeCTR.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.weekly.pctChangeCTR.value
                    )}
                  </Text>
                </div>
              </div>
            </div>

            <div id="monthly container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "2px solid #929299",
                  paddingBottom: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    TRANSACTION
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(
                      this.props.monthly.transactionCount
                    )}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    INFLUENCED SALES
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    $
                    {this.formatNumberToString(
                      parseFloat(this.props.monthly.transactionTotal).toFixed(2)
                    )}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    ROAS
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.props.monthly.roas !== null
                      ? this.formatNumberToString(
                          parseFloat(this.props.monthly.roas).toFixed(2)
                        )
                      : "N/A"}
                  </Text>
                  {/* <Text
                    style={{
                      fontSize: 20,
                      marginTop: "15px",
                    }}
                  >
                    +12.52%
                  </Text> */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "2px solid #929299",
                  paddingBottom: "10px",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    IMPRESSION
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.monthly.impressions)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.monthly.pctChangeImpressions.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.monthly.pctChangeImpressions.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.monthly.pctChangeImpressions.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.monthly.pctChangeImpressions.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.monthly.pctChangeImpressions.value
                    )}
                  </Text>
                </div>
                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    CLICKS
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.monthly.clicks)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.monthly.pctChangeClicks.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.monthly.pctChangeClicks.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.monthly.pctChangeClicks.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.monthly.pctChangeClicks.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.monthly.pctChangeClicks.value
                    )}
                  </Text>
                </div>

                <div
                  style={{
                    height: "120px",
                    width: "400px",
                    padding: "5px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                    }}
                  >
                    CTR
                  </Text>
                  <Text
                    style={{
                      fontSize: 40,
                      marginTop: "25px",
                    }}
                  >
                    {this.formatNumberToString(this.props.monthly.ctr)} %
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: 20,
                        marginTop: "15px",
                      },
                      this.props.monthly.pctChangeCTR.trend === -1 &&
                        this.styles.boxes_white.TrendDown,
                      this.props.monthly.pctChangeCTR.trend === 1 &&
                        this.styles.boxes_white.TrendUp,
                      this.props.monthly.pctChangeCTR.trend === 0 &&
                        this.styles.boxes_white.TrendFlat,
                    ]}
                  >
                    {this.props.monthly.pctChangeCTR.trend === 1 && "+"}
                    {this.formatNumberToString(
                      this.props.monthly.pctChangeCTR.value
                    )}
                  </Text>
                </div>
              </div>
            </div>
          </View>

          <View
            style={{
              color: "white",
              position: "absolute",
              bottom: "100px",
              left: "100px",
            }}
          >
            <Text>All Rights Reserved {new Date().getFullYear()}</Text>
          </View>
          {/* <View
            style={{
              color: "white",
              position: "absolute",
              bottom: "100px",
              right: "100px",
            }}
          >
            <Text>
              <a href="https://www.mediajel.com/">www.mediajel.com</a>
            </Text>
          </View> */}
        </Page>
      </Document>
    );
  }
}

export const WeeklyReportPDFLink = data => {
  return (
    <PDFDownloadLink
      document={
        <MyDocumentPDF
          topCreatives={data.data.topCreatives}
          weekly={data.data.weekly}
          monthly={data.data.monthly}
          advertiserName={
            data.filteredOrg.name
              ? data.filteredOrg.name
              : data.data.advertiserName
          }
          orgLogoUrl={data.logo}
        />
      }
      fileName="Weekly Report.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Weekly Report"
      }
    </PDFDownloadLink>
  );
};
