import React from "react";
import { formatNumber } from "../../../../../../core/utils/campaigns";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";

const Donut = ({ height, data, selectedPublisherDimension }) => {
  const chartData = data.map(item => ({
    value: item.value,

    IDENTIFIERS: item.IDENTIFIERS,
  }));

  const cols = {
    percent: {
      formatter: function(val) {
        val = val * 100;
        return formatNumber(val) + "%";
      },
    },
    IDENTIFIERS: { formatter: val => val.toLocaleString() },
  };

  return (
    <div>
      <Chart
        forceFit
        data={chartData}
        padding={"auto"}
        scale={cols}
        height={height / 1.1}
      >
        <Coord type="theta" radius={0.75} innerRadius={0.65} />

        {selectedPublisherDimension === "IDENTIFIERS" && (
          <Axis name="IDENTIFIERS" />
        )}

        <Tooltip showTitle={false} />

        <Geom
          color={[
            "value",
            value => {
              const index = data.findIndex(el => el.value === value);
              if (data[index]) return data[index].color;
              else return "black";
            },
          ]}
          position={"IDENTIFIERS"}
          type="intervalStack"
        />
      </Chart>
    </div>
  );
};

export default Donut;
