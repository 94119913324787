//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Timeline, Row, Col, Tooltip, Avatar, Modal } from "antd";
import { getMobileOperatingSystem, determineAdServer } from "./utils";

import {
  DesktopOutlined,
  TabletOutlined,
  MobileOutlined,
  AppleOutlined,
  AndroidOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

const { Item } = Timeline;

const ImpressionSubTable = ({ impressions }) => {
  const [impressionsData, setImpressionsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImpression, setCurrentImpression] = useState("");

  const showModal = impression => {
    if (impression.CREATIVEURL) {
      setIsModalVisible(true);
      setCurrentImpression(impression);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const sortedImpressions = impressions.sort((a, b) => {
      if (
        moment(a.IMPRESSION_TIME, moment.DATETIME_LOCAL_MS).isBefore(
          new Date(b.IMPRESSION_TIME)
        )
      )
        return -1;
      return 1;
    });

    setImpressionsData(sortedImpressions);
  }, [impressions]);

  const makeTimeLine = impressionsData.map((impression, i) => {
    const {
      IMPRESSION_TIME,
      IMPRESSION_USERAGENT,
      IMPRESSION_PAGE_URL,
      PAGE_URLHOST,
      IMPRESSION_REFERRER,
      IMPRESSION_PUBLISHERNAME,
      IMPRESSION_APPNAME,
      IMPRESSION_APPSTORE_ID,
      IMPRESSION_SITE_ID,
      IMPRESSION_SITENAME,
    } = impression;

    let APPSITENAME = "";
    let APPSITELINK = "";
    let APPSITENAMETOOLTIP = "";
    let APPLICATION_PLATFORM = "";
    let OS = "";

    OS = getMobileOperatingSystem(IMPRESSION_USERAGENT);

    let PUBLISHER =
      IMPRESSION_PUBLISHERNAME && IMPRESSION_PUBLISHERNAME !== "N/A"
        ? `Published by - ${decodeURI(IMPRESSION_PUBLISHERNAME).replace(
            /[+_]/g,
            " "
          )}`
        : "";

    //First case checked - no pub, site or app macros resolve at all - TTD, CENTRO and LiquidM legacy tags revert to page url defaults
    if (
      (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
      (!IMPRESSION_APPNAME || IMPRESSION_APPNAME === "N/A") &&
      (!IMPRESSION_SITE_ID || IMPRESSION_SITE_ID === "N/A") &&
      (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
    ) {
      //No new macros resolve - we process with out of the box page view information - often only have ad server level information
      APPSITENAME = PAGE_URLHOST
        ? `Served on ${decodeURIComponent(PAGE_URLHOST)}`
        : "";
      APPSITELINK = IMPRESSION_REFERRER
        ? `Referred by ${decodeURIComponent(IMPRESSION_REFERRER).substring(
            0,
            60
          )}`
        : "";
      APPSITENAMETOOLTIP = IMPRESSION_PAGE_URL
        ? decodeURIComponent(IMPRESSION_PAGE_URL).substring(0, 60)
        : "";
      //Chacks the available page and referrer information to determine application platform
      APPLICATION_PLATFORM = determineAdServer(PAGE_URLHOST);
      //Even if we determine the page url to be an ad server - the referrer may still give better resolution
      if (IMPRESSION_REFERRER && APPLICATION_PLATFORM !== "Application") {
        APPLICATION_PLATFORM = determineAdServer(IMPRESSION_REFERRER);
      }
    } else if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
      //Highly confident we have a mobile application based on new macros
      APPSITENAME = `Served on - ${decodeURIComponent(IMPRESSION_APPNAME)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop()}`;
      APPSITELINK = `${decodeURIComponent(IMPRESSION_APPSTORE_ID)}`;
      APPLICATION_PLATFORM = "Application";
    } else if (
      IMPRESSION_SITENAME &&
      IMPRESSION_SITE_ID &&
      !IMPRESSION_SITE_ID
    ) {
      //No url from IMPRESSION_SITE_ID suggests mobile application
      APPSITENAME = `Served on - ${decodeURIComponent(IMPRESSION_SITENAME)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop()}`;

      console.log("Maybe there id no IMPRESSION_SITE_ID", IMPRESSION_SITE_ID);
      APPSITELINK = IMPRESSION_REFERRER
        ? `Referred by ${IMPRESSION_REFERRER.substring(0, 30)}`
        : "";
      APPLICATION_PLATFORM = "Application";
    } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
      //suggests website - better resolution than page level information
      APPSITENAME = `Served on - ${decodeURIComponent(IMPRESSION_SITENAME)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop()}`;
      APPLICATION_PLATFORM = "Website";
    }

    let appTypeIcon = () => {
      if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
        if (IMPRESSION_APPSTORE_ID.includes("itunes")) {
          return <AppleOutlined />;
        } else if (IMPRESSION_APPSTORE_ID.includes("google")) {
          return <AndroidOutlined />;
        } else {
          return <MobileOutlined />;
        }
      } else if (
        IMPRESSION_SITENAME &&
        IMPRESSION_SITE_ID &&
        !IMPRESSION_SITE_ID
      ) {
        return <MobileOutlined />;
      } else if (APPLICATION_PLATFORM.includes("Ad Server")) {
        return <MobileOutlined />;
      } else if (APPLICATION_PLATFORM.includes("Application")) {
        if (
          PAGE_URLHOST.includes("itunes") ||
          IMPRESSION_REFERRER.includes("itunes")
        ) {
          return <AppleOutlined />;
        } else if (
          PAGE_URLHOST.includes("play.google") ||
          IMPRESSION_REFERRER.includes("play.google")
        ) {
          return <AndroidOutlined />;
        } else return <MobileOutlined />;
      }

      return <DesktopOutlined />;
    };

    let icon = () => {
      if (
        OS === "Unknown" ||
        OS === "Chromebook Computer" ||
        OS === "Windows Computer" ||
        OS === "Computer"
      ) {
        return <DesktopOutlined />;
      }

      if (OS === "iPhone" || OS === "Android" || OS === "Windows Phone") {
        return <MobileOutlined />;
      }

      if (OS === "iPad") {
        return <TabletOutlined />;
      }
    };
    return (
      <Item key={i}>
        <Row>
          <Col span={6}>
            <div>
              <Avatar
                shape="square"
                alt={impression.CREATIVEID}
                size={50}
                icon={<FileImageOutlined />}
                onClick={() => {
                  showModal(impression);
                }}
                src={
                  impression.CREATIVEURL === "" ? false : impression.CREATIVEURL
                }
              ></Avatar>
            </div>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Impression served - ${moment(
                IMPRESSION_TIME,
                moment.DATETIME_LOCAL_MS
              ).format("MM/DD/YY HH:mm:ss.SSSZ")}`}
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {icon()}
              <Tooltip title={IMPRESSION_USERAGENT ? IMPRESSION_USERAGENT : ""}>
                {`  ${OS}`}
              </Tooltip>
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <Tooltip
                title={APPSITENAMETOOLTIP ? APPSITENAMETOOLTIP : ""}
              >{`${APPSITENAME}`}</Tooltip>
              <br />
              <a
                href={`${APPSITELINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Application
              </a>
              <br />
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`${APPLICATION_PLATFORM}`} {appTypeIcon()}
              <br />
              {`${PUBLISHER}`}
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Modal
        title={currentImpression.CREATIVEID}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImpression.CREATIVEURL}
          alt={currentImpression.CREATIVEID}
          width="100%"
        />
      </Modal>
      <Timeline mode={"left"} reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ImpressionSubTable;
