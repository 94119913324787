import React from "react";
import { Query, Mutation } from "react-apollo";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import EDIT_USER_SELF from "../../GraphQl/Mutations/EDIT_USER_SELF";
import Controller from "./Controller";
import OverlaySpinner from "../../.././platform/shared/OverlaySpinner";

const Loader = props => {
  return (
    <Mutation
      mutation={EDIT_USER_SELF}
      update={(store, { data: { updateUser } }) => {
        const data = store.readQuery({
          query: CURRENT_USER,
        });
        const updatedUser = {
          currentUser: { ...data.currentUser, avatar: updateUser.avatar },
        };
        store.writeQuery({
          query: CURRENT_USER,
          data: updatedUser,
        });
      }}
    >
      {(editUserSelf, { data }) => {
        return (
          <Query query={CURRENT_USER}>
            {({ loading, error, data }) => {
              if (loading) return <OverlaySpinner />;
              if (error) return `Error (Query: CURRENT_USER)! ${error.message}`;
              const currentUser = data && data.currentUser;
              return (
                <Controller
                  currentUser={currentUser}
                  editUserSelf={editUserSelf}
                >
                  {props.children}
                </Controller>
              );
            }}
          </Query>
        );
      }}
    </Mutation>
  );
};

export default Loader;
