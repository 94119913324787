import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import ErrorPage from "../../../../../shared/ErrorPage";
import { Row, Col } from "antd";
import DetailBar from "./components/DetailBar";
import DualAxisChart from "../../../../../shared/charts/DualAxisChart";
import { summaryScale } from "../../utils/summaryScale";
import PageViewsTable from "./components/PageViewsTable";
import JSZip from "jszip";
import moment from "moment";
import { saveAs } from "file-saver";
import Papa from "papaparse";

const PageViewTabView = ({
  campaignOrder,
  reportData,
  reportError,
  reportLoading,
  signedUrlError,
  signedUrlLoading,
  setExportButtonLoading,
  setExportButtonDisable,
  setExportButtonMenuItems,
  dataFilterChange = (startDate, endDate) => {},
}) => {
  document.title = `${campaignOrder.name} - Page View`;
  const { startDate, endDate, name } = campaignOrder;
  const [byDate, setByDate] = useState();
  const [summary, setSummary] = useState();
  const [pageViews, setPageViews] = useState();

  useEffect(() => {
    if (reportLoading || signedUrlLoading) setExportButtonLoading(true);
    else setExportButtonLoading(false);
  }, [reportLoading, signedUrlLoading, setExportButtonLoading]);

  useEffect(() => {
    if (reportData) {
      setByDate(reportData.byDate);
      setSummary(reportData.summary);
      setPageViews(reportData.attributedPageViews);
      setExportButtonDisable(false);
    }
  }, [reportData, setExportButtonDisable]);

  useEffect(() => {
    if (byDate) {
      setExportButtonMenuItems([
        {
          key: "pageViewByDate",
          text: "Page View By Date",
          onClick: () => {
            setExportButtonLoading(true);
            const key = "Page View By Date";
            const zip = new JSZip();
            const folder = zip.folder(`${name.replace(/\//g, " ")}-${key}}`);
            const formattedData = byDate.map(item => {
              return {
                Date: moment(item.date, "YYYY-MM-DD")
                  .utc(true)
                  .format("YYYY-MM-DD"),
                "Page Views": item.pageViews,
                Impressions: item.impressions,
                "Unique Users": item.uniqueUsers,
              };
            });
            folder.file(
              `${name} - Page View By Date.csv`,
              Papa.unparse(formattedData)
            );
            zip.generateAsync({ type: "blob" }).then(
              blob => {
                saveAs(blob, `${name.replace(/\//g, " ")}-Page View By Date`);
                return true;
              },
              function(e) {
                console.log("error", e);
                return false;
              }
            );
            setExportButtonLoading(false);
          },
        },
        {
          key: "pageViewImpressions",
          text: "Page View Impressions",
          onClick: () => {
            setExportButtonLoading(true);
            const key = "Page View Impressions";
            const zip = new JSZip();
            const folder = zip.folder(`${name.replace(/\//g, " ")}-${key}}`);
            const formattedData = pageViews.map(item => {
              return {
                "Page URL": item.pageUrl,
                "Page View Time": item.pageViewTime,
                Impressions: item.totalImpressions,
                "First Impressions Time": item.firstImpressionTime,
              };
            });
            folder.file(
              `${name} - Page View Impressions.csv`,
              Papa.unparse(formattedData)
            );
            zip.generateAsync({ type: "blob" }).then(
              blob => {
                saveAs(
                  blob,
                  `${name.replace(/\//g, " ")}-Page View Impressions`
                );
                return true;
              },
              function(e) {
                console.log("error", e);
                return false;
              }
            );
            setExportButtonLoading(false);
          },
        },
      ]);
    }
  }, [
    byDate,
    name,
    pageViews,
    setExportButtonLoading,
    setExportButtonMenuItems,
  ]);

  const dropDownOptions = [
    { key: "pageViews", display: "Page Views" },
    { key: "impressions", display: "Impressions" },
    { key: "uniqueUsers", display: "Unique Users" },
  ];

  if (reportError || signedUrlError) {
    return <ErrorPage errorMessage="Unable to load Report" />;
  }

  if (reportLoading || signedUrlLoading) {
    return (
      <React.Fragment>
        <LoadingPage />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row gutter={35}>
        <Col xs={24}>
          <DetailBar
            pageViews={summary.pageViews}
            impressions={summary.impressions}
            uniqueUsers={summary.uniqueUsers}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
      <Row gutter={35}>
        <Col xs={24}>
          <DualAxisChart
            byDate={reportData.byDate}
            xAxis="date"
            options={dropDownOptions}
            defaultLeftYValue={dropDownOptions[0]}
            defaultRightYValue={dropDownOptions[1]}
            customScale={summaryScale}
            onDatePickerChange={({ data, dates }) => {
              const report = dataFilterChange(dates[0], dates[1]);
              setByDate(report.byDate);
              setSummary(report.summary);
              setPageViews(report.attributedPageViews);
            }}
          />
        </Col>
      </Row>
      <Row gutter={35}>
        <Col xs={24}>
          <PageViewsTable data={pageViews} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PageViewTabView;
