import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Spin,
  Tooltip,
  Card,
  Tag,
  Popconfirm,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import PaginationTable from "../../../shared/PaginationTable";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../shared/globalStyling/styledText";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Button from "../../../shared/globalStyling/Button";
import moment from "moment";

const { Title } = Typography;

const AdVideosGroupTable = ({
  adVideosGroups,
  updateOrgMedia,
  amplify,
  selectedOrg,
  onDeleteAdgroup,
  onLoadingDeleteAdGroup,
}) => {
  const [ids, setIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filteredAdGroups, setFilteredAdGroups] = useState(adVideosGroups);

  // TODO Optimize backend to filter ad groups
  useEffect(() => {
    if (adVideosGroups && adVideosGroups.length) {
      setFilteredAdGroups(
        adVideosGroups
          .filter(
            adGroup =>
              adGroup.org.length === 1 || selectedOrg.parentOrg.length > 0
          )
          .filter(
            adGroup =>
              !(
                adGroup.hasOwnProperty("isAmplifyDefault") &&
                adGroup.isAmplifyDefault === true &&
                !amplify
              )
          )
          .filter(item => Array.isArray(item.medias) && item.medias.length)
          .filter(item => item.medias.every(media => media.fileType === "MP4"))
      );
    }
  }, [adVideosGroups, amplify, selectedOrg.parentOrg.length]);

  const processImages = async orgVideoMedias => {
    await Promise.all(
      orgVideoMedias.map(async media => {
        const image = await new Promise(resolve => {
          setTimeout(async () => {
            resolve(
              <Image
                alt="creative images"
                src={media.url}
                style={{ maxHeight: 80, maxWidth: 140 }}
              />
            );
          }, 500);
        });
        media.image = image;
        return media;
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(adVideosGroups)) {
      processImages(adVideosGroups);
    }
  }, [adVideosGroups]);

  let columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "50px",
      align: "left",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => {
        return (
          <>
            <PrimaryTableRowText text={name} />{" "}
            {record.isAmplifyDefault && amplify ? (
              <Tag color="green">Amplify Default</Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Creatives"} />,
      dataIndex: "media",
      key: "media",
      width: "200px",
      align: "left",
      render: (text, record) => {
        return record.medias.map((val, index) => {
          const getMedias = val.name;

          return (
            <Tag
              color="blue"
              key={index.toString()}
              style={{
                maxWidth: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getMedias}
            </Tag>
          );
        });
      },
    },
    {
      title: <TableColumnTitle text={"Date Uploaded"} />,
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      align: "left",
      sorter: (a, b) => {
        const dateA = moment(a.createdAt);
        const dateB = moment(b.createdAt);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      render: createdAt => {
        return (
          <PrimaryTableRowText
            text={moment(createdAt).format("MM/DD/YYYY hh:mm A")}
          />
        );
      },
    },
  ];

  if (!updateOrgMedia) {
    // Filter edit column if updateOrgMedia is not passed
    columns = columns.filter(column => column.key !== "edit");
  }

  const rowSelection = {
    onChange: (selectedRowKeys, _selectedRows) => {
      const ids = _selectedRows.map(row => {
        return row.id;
      });
      setIds(ids);
    },
  };

  return (
    <>
      <Row>
        <Col xs={24} lg={24}>
          <Card bodyStyle={{ padding: 5 }}>
            <Row align="middle">
              <Col
                className="ant-col-align-self-center"
                xs={24}
                xl={4}
                style={{ paddingLeft: 15, paddingTop: 10 }}
              >
                <Title level={5}>Video Ad Groups</Title>
              </Col>
              <Col xs={24} xl={20}>
                <Row
                  align="right"
                  className="ant-row-justify-center ant-row-justify-end"
                >
                  <Popconfirm
                    placement="topLeft"
                    title={`Are you sure you want delete the selected Ad Group?`}
                    onConfirm={() => {
                      setLoading(true);
                      setIds(onDeleteAdgroup(ids));
                      setLoading(false);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Delete selected creatives`}
                    >
                      <Button
                        type="primary"
                        disabled={ids && ids.length > 0 ? false : true}
                        icon={
                          onLoadingDeleteAdGroup === true ? (
                            <LoadingOutlined />
                          ) : (
                            <DeleteOutlined />
                          )
                        }
                        ghost
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Spin tip="Loading..." spinning={isLoading}>
        <PaginationTable
          bordered={false}
          footer={
            filteredAdGroups &&
            filteredAdGroups.slice(currentPage * 10 - 10, currentPage * 10)
          }
          columns={columns}
          data={filteredAdGroups}
          scroll={{ x: 1200, y: 600 }}
          paginationSize={"default"}
          pageSize={pageSize}
          size={"default"}
          total={filteredAdGroups && filteredAdGroups.length}
          current={currentPage}
          rowSelection={rowSelection}
          onChange={paginationObject => {
            setPageSize(paginationObject.pageSize);
            setCurrentPage(paginationObject.current);
          }}
        />
      </Spin>
    </>
  );
};

export default AdVideosGroupTable;
