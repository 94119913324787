const polygonOverlay = {
  fillColor: "#90EE90",
  fillOpacity: 0.5,
  strokeWeight: 3,
  strokeColor: "#008000",
  clickable: true,
  // editable: true,
  zIndex: 1,
};

const circleOverlay = {
  strokeColor: "#008000",
  strokeOpacity: 0.8,
  strokeWeight: 8,
  fillColor: "#90EE90",
  fillOpacity: 0.5,
};

const formattedAddress = [
  "address",
  "state",
  "city",
  "zip",
  "county",
  "country",
];

const countryType = {
  CA: "CANADA",
  US: "USA",
  UK: "UNITED KINGDOM",
  GB: "UNITED_KINGDOM",
  ES: "SPAIN",
};

const componentRestrictions = {
  country: ["us", "ca", "uk", "es"],
};

export const MapConfig = {
  polygonOverlay,
  circleOverlay,
  formattedAddress,
  countryType,
  componentRestrictions,
};
