import gql from "graphql-tag";

const UPDATE_CONVERSATION = gql`
  mutation updateConversation(
    $conversationID: ID
    $message: String!
    $currentOrgID: String
  ) {
    updateConversation(
      where: { id: $conversationID }
      data: {
        messages: { create: { message: $message, currentOrgID: $currentOrgID } }
      }
    ) {
      id
      messages {
        message
        currentOrgID
      }
    }
  }
`;

export default UPDATE_CONVERSATION;
