import gql from "graphql-tag";

const CREATE_MANY_AD_UNITS = gql`
  mutation updateCampaignOrder($id: ID!, $data: CampaignOrderUpdateInput!) {
    updateCampaignOrder(where: { id: $id }, data: $data) {
      id
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          status
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
    }
  }
`;

export default CREATE_MANY_AD_UNITS;
