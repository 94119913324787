import gql from "graphql-tag";

const UPDATE_CAMPAIGN_ORDER_REVIEW = gql`
  mutation updateCampaignOrderReview(
    $campaignOrderId: ID
    $stateLaw: String
    $scaleBudget: String
    $reviewerId: String
    $status: CampaignStatus!
  ) {
    updateCampaignOrder(
      where: { id: $campaignOrderId }
      data: {
        status: $status
        campaignReviewProcess: {
          upsert: {
            create: { stateLaw: $stateLaw, scaleBudget: $scaleBudget }
            update: { stateLaw: $stateLaw, scaleBudget: $scaleBudget }
          }
        }
        reviewerId: $reviewerId
      }
    ) {
      id
      status
      campaignReviewProcess {
        id
        review
        stateLaw
        scaleBudget
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_REVIEW;
