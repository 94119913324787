import React from "react";
import { Row, Col, Card } from "antd";
import ReferrerTable from "./ReferrerTable";
import ReferrerRingChart from "./ReferrerRingChart";

// Global Styling
import { CardTitleText } from "../../../../../../../../shared/globalStyling/styledText";

const Style = {
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  xs: {
    span: 24,
  },
  md: {
    span: 16,
  },
  xsPie: {
    span: 24,
  },
  mdPie: {
    span: 8,
  },
};

const { xs, md, xsPie, mdPie, keyColors } = Style;

const ReferrerDetails = props => {
  const { referrerData } = props;

  return (
    <Row gutter={35}>
      <Col xs={xs} md={md}>
        <Card
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Basket Items By Revenue Table"} />}
        >
          <ReferrerTable referrerData={referrerData} />
        </Card>
      </Col>
      <Col xs={xsPie} md={mdPie}>
        <Card
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Basket Items by Revenue Chart"} />}
        >
          <ReferrerRingChart data={referrerData} keyColors={keyColors} />
        </Card>
      </Col>
    </Row>
  );
};

export default ReferrerDetails;
