import React, { useState } from "react";
import { graphql, withApollo } from "react-apollo";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import compose from "lodash/flowRight";
import Controller from "./Controller";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_PACING_DATA_CONNECTION, {
  PacingDataObjectOrderByInput,
} from "../../../GraphQl/Queries/GET_PACING_DATA_CONNECTION";
import LIST_CAMPAIGNORDERS_WITH_PACING from "../../../GraphQl/Queries/LIST_CAMPAIGNORDERS_WITH_PACING";

const Loader = ({ advertiserIds, ...props }) => {
  const loggedInRole = props.currentUser.role;
  const orgID = loggedInRole.org.id;
  const [skip, setSkip] = useState(0);
  const [first, setFirst] = useState(10);
  const [orderBy, setOrderBy] = useState(
    PacingDataObjectOrderByInput.daysLeft_DESC
  );
  const [
    getExport,
    { loading: loadingExport, data: exportData },
  ] = useLazyQuery(GET_PACING_DATA_CONNECTION, {
    fetchPolicy: "no-cache",
    variables: {
      orgID: advertiserIds && advertiserIds.length ? advertiserIds[0] : orgID,
      orderBy,
    },
  });

  const { loading, data, refetch } = useQuery(GET_PACING_DATA_CONNECTION, {
    notifyOnNetworkStatusChange: true,
    variables: {
      skip,
      first,
      orgID: advertiserIds && advertiserIds.length ? advertiserIds[0] : orgID,
      orderBy,
    },
  });

  const { loading: loadingFilterOptions, data: filterOptions } = useQuery(
    LIST_CAMPAIGNORDERS_WITH_PACING,
    {
      variables: {
        where: {
          AND: [
            {
              type_not: "CPC",
              cpm_not: null,
              id_not: null,
            },
            {
              OR: [
                {
                  status_not_in: ["DRAFT", "PENDING"],
                  AND: {
                    orgs_some: {
                      OR: [
                        {
                          id_in:
                            advertiserIds && advertiserIds.length
                              ? advertiserIds
                              : [orgID],
                        },
                      ],
                      NOT: [
                        {
                          parentOrg_some: {
                            id_in:
                              advertiserIds && advertiserIds.length
                                ? advertiserIds
                                : [orgID],
                          },
                        },
                      ],
                    },
                    pacingData: {
                      id_not: null,
                      daysLeft_gte: 0,
                    },
                  },
                },
                {
                  status_not_in: ["DRAFT", "PENDING"],
                  AND: {
                    orgs_some: {
                      OR: [
                        {
                          id_in:
                            advertiserIds && advertiserIds.length
                              ? advertiserIds
                              : [orgID],
                        },
                        {
                          parentOrg_some: {
                            id_in:
                              advertiserIds && advertiserIds.length
                                ? advertiserIds
                                : [orgID],
                          },
                        },
                      ],
                    },
                    pacingData: {
                      id_not: null,
                      daysLeft_gte: 0,
                    },
                  },
                },
              ],
            },
            {
              AND: [
                {
                  startDate_lte: moment()
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString(),
                  endDate_gte: moment()
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString(),
                  status_not: "DRAFT",
                },
                {
                  status_not: "PENDING",
                },
              ],
            },
          ],
        },
        orderBy: "name_ASC",
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <Controller
      data={data}
      isLoading={loading || loadingFilterOptions}
      refetch={refetch}
      loadingFilterOptions={loadingFilterOptions}
      filterOptions={filterOptions}
      setSkip={setSkip}
      setFirst={setFirst}
      first={first}
      setOrderBy={setOrderBy}
      skip={skip}
      getExport={getExport}
      exportData={exportData}
      loadingExport={loadingExport}
      {...props}
    >
      {props.children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withApollo(Loader));
