import { Modal, message, Select, Spin } from "antd";
import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";

export const LaunchCampaignConfigSupplyTemplateModal = ({
  visible,
  setVisible,
  fetchSupplyTemplateOptions,
  fetchSupplyTemplateSelected,
  debounceTimeout = 800,
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const [currentSelectedOption, setCurrentSelectedOption] = useState([]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = name => {
      if (name) {
        setCurrentSelectedOption(name);
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        try {
          fetchSupplyTemplateOptions({ name }).then(newOptions => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            if (!newOptions || newOptions.length === 0) {
              message.warning("No supply templates found");
            }
            setOptions(newOptions);
            setFetching(false);
          });
        } catch (error) {
          setFetching(false);
          message.error("Failed to fetch sites. Try again");
        }
      }
    };
    return debounce(loadOptions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSupplyTemplateOptions, debounceTimeout]);

  const handleOk = e => {
    if (currentSelectedOption && currentSelectedOption.value) {
      fetchSupplyTemplateSelected({
        filter: {
          id: currentSelectedOption.value,
        },
        include:
          "incl-publishers,excl-publishers,incl-sites,excl-sites,incl-domains,excl-domains,incl-app-ids,excl-app-ids",
      });
    }
    setVisible(false);
  };

  const handleCancel = e => {
    setVisible(false);
  };
  return (
    <Modal
      title="Supply Template"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Select
        showSearch
        labelInValue
        value={currentSelectedOption}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={
          fetching ? (
            <span>
              <Spin size="small" style={{ marginRight: 4 }} />
              Please wait this might take a while
            </span>
          ) : null
        }
        options={options}
        onSelect={option => {
          setCurrentSelectedOption(option);
        }}
        style={{ width: "100%" }}
      />
    </Modal>
  );
};
