import React from "react";
import { Table, Switch, Tooltip, Divider } from "antd";

import {
  orgConfigInfo,
  orgConfigNaming,
} from "../../../../core/utils/constants/constants";

const ConfigurationToggler = props => {
  const {
    config,
    loggedInPermissions,
    disabled,
    onSwitchChange,
    isAdmin,
    isMJParent,
    isSelfService,
    isProcessor,
    orgTab,
  } = props;
  let selfService = false;

  if (isProcessor) {
    selfService = false;
  } else if (isSelfService) {
    selfService = true;
  } else {
    selfService = true;
  }

  const style = {
    container: {
      margin: "5px",
    },
  };

  const columns = [
    {
      title: "Configuration",
      width: "60%",
      key: "name",
      dataIndex: "name",
      render: text => <div>{orgConfigNaming[text]}</div>,
    },

    {
      title: "Permissions",
      width: "15%",
      dataIndex: "name",
      key: "checked",

      render: text => (
        <Tooltip title={orgConfigInfo[text]} key={text}>
          <Switch
            checked={config[text] ? config[text] : false}
            name={`${text}-CONFIG`}
            disabled={
              orgConfigNaming[text] === "Self Service" && !isMJParent && !orgTab
                ? selfService
                : disabled
            }
            onChange={checked => {
              onSwitchChange(checked, `${text}-CONFIG`);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  let allowedKeys = [];

  if (loggedInPermissions && !disabled) {
    Object.values(loggedInPermissions).forEach((val, i) => {
      if (orgTab) {
        if (
          val ||
          Object.keys(loggedInPermissions)[i] === "isDemo" ||
          Object.keys(loggedInPermissions)[i] === "isSelfService" ||
          (isMJParent
            ? Object.keys(loggedInPermissions)[i] === "isPartner"
            : null)
        ) {
          allowedKeys.push(Object.keys(loggedInPermissions)[i]);
        }

        if (
          loggedInPermissions.isProcessor &&
          Object.keys(loggedInPermissions)[i] === "isSelfService"
        ) {
          allowedKeys.push(Object.keys(loggedInPermissions)[i]);
        }
      } else {
        if (
          val ||
          Object.keys(loggedInPermissions)[i] === "isDemo" ||
          (isMJParent
            ? Object.keys(loggedInPermissions)[i] === "isPartner"
            : null)
        ) {
          allowedKeys.push(Object.keys(loggedInPermissions)[i]);
        }
      }
      if (
        val ||
        Object.keys(loggedInPermissions)[i] === "isDemo" ||
        //  Object.keys(loggedInPermissions)[i] === "isSelfService" ||
        (isMJParent
          ? Object.keys(loggedInPermissions)[i] === "isPartner"
          : null)
      ) {
        allowedKeys.push(Object.keys(loggedInPermissions)[i]);
      }

      // if (
      //   loggedInPermissions.isProcessor &&
      //   Object.keys(loggedInPermissions)[i] === "isSelfService"
      // ) {
      //   allowedKeys.push(Object.keys(loggedInPermissions)[i]);
      // }
    });
  } else {
    if (loggedInPermissions) {
      Object.values(loggedInPermissions).forEach((val, i) => {
        if (val || isAdmin) {
          allowedKeys.push(Object.keys(loggedInPermissions)[i]);
        }
      });
    }
  }
  let newAllowedKeys = [...new Set(allowedKeys)];
  let data = [];

  newAllowedKeys.forEach((key, i) => {
    if (key === "articles") {
      // remove articles toggle
      return;
    }
    if (key === "__typename") {
    } else {
      const record = {
        name: [key],
        checked: config[key],
        key: i,
      };
      data.push(record);
    }
  });

  const table = (
    <div style={style.container}>
      <Table columns={columns} bordered dataSource={data} pagination={false} />
      <Divider />
    </div>
  );

  return data.length ? table : null;
};

export default ConfigurationToggler;
