import React from "react";
import { Button, Modal, Spin } from "antd";
import { CampaignLauncherViewTagActivities } from "../CampaignLauncherViewTagActivities";

export const CampaignLauncherReview = ({
  visible,
  setVisible,
  handleProceed,
  adveriserName,
  activities,
  loading,
}) => {
  const hasNullOrZeroActivities = data => {
    for (const tagName in data) {
      if (Object.hasOwnProperty.call(data, tagName)) {
        const tag = data[tagName];
        if (
          !tag.activities ||
          Object.values(tag.activities).every(val => val === 0)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <Modal
      title="CONFIRM CAMPAIGN"
      visible={visible}
      width={800}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
          }}
        >
          Back
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          loading={loading}
          onClick={handleProceed}
        >
          Submit
        </Button>,
      ]}
    >
      <p>
        You are creating a campaign for <b>[{adveriserName}]</b>. Reporting for
        this campaign will be shared with them.
      </p>

      {loading && (
        <div style={{ textAlign: "center" }}>
          <Spin />
          <div>Checking Tag Activities...</div>
        </div>
      )}

      {!loading && hasNullOrZeroActivities(activities) && (
        <>
          <p>
            <b>Note: </b> We aren't receiving a signal from the tag you
            selected. Please review the status below before submitting this
            campaign request.
          </p>

          <CampaignLauncherViewTagActivities activities={activities} />
        </>
      )}
    </Modal>
  );
};
