import gql from "graphql-tag";
const ACTIVATE_HASHED_EMAIL_LIST = gql`
  mutation activateHashedEmailList(
    $emailListId: ID
    $orgID: ID
    $orgName: String
  ) {
    activateHashedEmailList(
      data: { emailListId: $emailListId, orgID: $orgID, orgName: $orgName }
    )
  }
`;

export default ACTIVATE_HASHED_EMAIL_LIST;
