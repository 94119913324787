import React from "react";
import { Row, Input, InputNumber, Button, Col, DatePicker } from "antd";
import { isEmpty } from "lodash";
import {
  maxRadius,
  minRadius,
} from "../../../../../core/utils/constants/constants";

const InputGroup = Input.Group;
const { RangePicker } = DatePicker;

export const AddGeoTargetContent = ({
  toggleAddGeoTargetPopover,
  newGeoTargetConfirmed,
  newGeoTargetChanged,
  newGeoTarget,
  isPopover,
  type,
}) => {
  const datePickerChanged = vals => {
    if (!isEmpty(vals)) {
      newGeoTargetChanged("flightLength", {
        start: vals[0].format(),
        end: vals[1].format(),
      });
    }
  };

  return (
    <Row style={{ width: 320 }}>
      <InputGroup>
        <Row gutter={8}>
          <Col span={14}>
            <Input
              value={newGeoTarget["NAME"]}
              placeholder={"Name"}
              onChange={e => {
                newGeoTargetChanged("NAME", e.target.value);
              }}
              style={{ width: "100%", marginBottom: 10 }}
            />
          </Col>
          <Col span={10}>
            <InputNumber
              value={newGeoTarget["RADIUS"]}
              min={minRadius}
              max={maxRadius}
              placeholder={"Radius (Meters)"}
              style={{ width: "100%" }}
              step={15}
              onChange={val => {
                newGeoTargetChanged("RADIUS", val);
              }}
            />
          </Col>
        </Row>
      </InputGroup>

      <Input
        value={newGeoTarget["ADDRESS"]}
        placeholder={"Address (Required)"}
        onChange={e => {
          newGeoTargetChanged("ADDRESS", e.target.value);
        }}
        style={{ width: "100%", marginBottom: 10 }}
      />

      {type === "Attribution" && (
        <RangePicker
          style={{ width: "100%", marginBottom: 10 }}
          onChange={vals => {
            datePickerChanged(vals);
          }}
        />
      )}

      <div>OR</div>

      <InputGroup>
        <Row gutter={8}>
          <Col span={12}>
            <InputNumber
              value={newGeoTarget["LAT"]}
              min={-180}
              max={180}
              placeholder={"Latitude"}
              style={{ width: "100%", marginTop: 10 }}
              step={0.0001}
              onChange={val => {
                newGeoTargetChanged("LAT", val);
              }}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              value={newGeoTarget["LON"]}
              min={-180}
              max={180}
              placeholder={"Longitude"}
              style={{ width: "100%", marginTop: 10 }}
              step={0.0001}
              onChange={val => {
                newGeoTargetChanged("LON", val);
              }}
            />
          </Col>
        </Row>
      </InputGroup>

      {isPopover && (
        <Button
          type="primary"
          style={{ width: "100%", marginTop: 10 }}
          disabled={
            !(newGeoTarget["ADDRESS"] && newGeoTarget["ADDRESS"].length) &&
            !(newGeoTarget["LAT"] && newGeoTarget["LON"])
          }
          onClick={() => {
            newGeoTargetConfirmed();
            toggleAddGeoTargetPopover();
          }}
          block
        >
          Search for Location
        </Button>
      )}
    </Row>
  );
};
