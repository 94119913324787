const initialLocation = {
  latitude: 39.462407,
  longitude: -105.642816,
};

const csvTemplate = {
  rows: [
    ["NAME", "ADDRESS", "CITY", "STATE", "ZIP", "RADIUS"],
    [
      "Target Name",
      "210 East 400 South",
      "Salt Lake City",
      "UTAH",
      "84103",
      "100",
    ],
  ],
  reuiredFields: ["NAME", "ADDRESS", "CITY", "STATE", "ZIP", "RADIUS"],
  templateName: "Locations_Template",
  defaultValues: {
    inProgressOverlay: [],
    selectedLicense: {},
    zoomLevel: 10,
    initialLocation,
    polygons: [],
    tags: [],
    start: "",
    end: "",
    locationLoading: false,
    locationId: "",
    geoFrameId: "",
    tablet: false,
    locationLoaded: false,
    id: "",
    selectedGeoTargets: [],
    refreshGeotargetDates: false,
    email: "",
    url: "",
    phone: "",
    contact: "",
    description: "",
  },
};

export const OrganizationConfig = {
  csvTemplate,
  initialLocation,
};
