import gql from "graphql-tag";

const UPDATE_CAMPAIGN_ORDER_PENDING = gql`
  mutation updateCampaignOrder($campaignID: ID!, $isEditCampaign: Boolean) {
    updateCampaignOrder(
      where: { id: $campaignID }
      data: { isEditCampaign: $isEditCampaign }
    ) {
      isEditCampaign
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_PENDING;
