import React, { useState } from "react";
import { Modal, Button } from "antd";
import CreativeTable from "./CreativeTable";

export const CreativeAdGroupAddCreativesModal = ({
  visible,
  setVisible,
  selectedAdGroup,
  orgMedias,
  setCreativeKeys,
  updateAdGroup,
}) => {
  const [ids, setIds] = useState([]);
  const handleCancel = () => {
    setIds([]);
    setCreativeKeys([]);
    setVisible(false);
  };

  const handleOk = async () => {
    await updateAdGroup({
      adGroup: {
        ...selectedAdGroup,
        medias: ids,
      },
      type: "connect",
    });
    setVisible(false);
    setIds([]);
    setCreativeKeys([]);
  };
  return (
    <>
      <Modal
        title={`${selectedAdGroup.name} - Add Creatives`}
        visible={visible}
        width={800}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <CreativeTable
          orgMedias={orgMedias}
          setCreativeKeys={setCreativeKeys}
          setIds={setIds}
        />
      </Modal>
    </>
  );
};
