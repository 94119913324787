import gql from "graphql-tag";

const ATTRIBUTION_AUDIENCES = gql`
  query audiences($TYPE: AudienceType, $ORGID: ID!) {
    audiencesConnection(
      where: {
        AND: [
          { type: $TYPE }
          {
            orgs_some: {
              OR: [{ id: $ORGID }, { parentOrg_some: { id: $ORGID } }]
            }
          }
        ]
      }
      orderBy: createdAt_DESC
    ) {
      edges {
        node {
          id
          name
          type
          isAmplifyDefault
          geoTargets {
            id
            name
            location {
              id
              name
              formattedAddress
              street
              state
              city
              zip
              lat
              lng
              country
              county
            }
            polygons {
              geometry
            }
            circles {
              radius
              coordinate {
                lat
                lng
              }
              location {
                id
                name
                formattedAddress
                zip
                street
                city
                county
                state
                country
                lat
                lng
              }
            }
          }
          eventsTargets {
            id
          }
          createdAt
          updatedAt
          orgs {
            id
            name
          }
        }
      }
    }
  }
`;

export default ATTRIBUTION_AUDIENCES;
