import { useState, useEffect } from "react";

// Can be decoupled further to be more generic
const useChatInterfaceMessages = props => {
  const { messages = [], onSendChatMessage = () => {} } = props;
  const [chatMessages, setChatMessages] = useState(messages);

  // Updates the chat messages when the messages array changes. 
  // If there are messages in the array, it maps over each message and creates a new array of user 
  // interface messages with specific properties like type, sender, and direction. Then it updates the 
  // chat messages state with the new user interface messages.
  useEffect(() => {
    if (messages.length > 0) {
      const userInterfaceMessages = messages.map((message, index) => {
        const isUserMessage = message.role === "user";

        return {
          ...message,
          type: "text",
          sender: isUserMessage ? "user" : "bot",
          direction: isUserMessage ? "outgoing" : "incoming",
        };
      });
      setChatMessages(userInterfaceMessages);
    }
  }, [messages]);

  // Updates the chatMessages state by appending a new message object to the existing array. 
  const sendChatMessage = message => {
    onSendChatMessage(message);
    setChatMessages(prev => [
      ...prev,
      {
        type: "text",
        message: message,
        direction: "outgoing",
        // avatar:
        //   "https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png",
        // Uncomment the avatar and link to change the avatar of the user. By default, please use user avatar on the navbar.
      },
    ]);
  };

  return {
    chatMessages,
    sendChatMessage,
  };
};

export default useChatInterfaceMessages;
