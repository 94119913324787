import React from "react";
import { Tabs, Card, Row, DatePicker } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import { orderDays } from "../../../../../../../../core/utils/constants/constants";

// Global Styles
import {
  TabText,
  CardText,
} from "../../../../../../../shared/globalStyling/styledText";

const Frag = React.Fragment;
const { RangePicker } = DatePicker;

// Styles
const colStyle = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

const TransactionsChart = ({
  chartData,
  chartDataDisplay,
  byTransactionsRangeDate,
  setByTransactionsRangeDate,
  dataTransactionsRangeDate,
  setDataTransactionsRangeDate,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  dateTrue,
  setDateTrue,
  setDateFilter,
  providersVisible,
}) => {
  // map the chart and return the dates with total transactions
  const getByDate = chartData.map(item => {
    const keyValue = Object.entries(item);
    const transactionRevenue =
      keyValue[keyValue.findIndex(pair => pair[0] === "transactionTotal")];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "transaction")];
    return {
      totalImpressions: item.totalImpressions,
      impressionsFromDisplay: item.impressionsFromDisplay,
      transactionRevenue: transactionRevenue[1],
      xAxis: moment(x[1]).format("MM/DD/YYYY"),
    };
  });

  // Sort Dates
  const byDates =
    !isEmpty(getByDate) &&
    getByDate.sort((a, b) =>
      moment(a.xAxis, "MM/DD/YYYY").diff(moment(b.xAxis, "MM/DD/YYYY"))
    );

  // count foreach duplicate in Dates for transactions
  const counterTransactionsDates = {};
  byDates.forEach(obj => {
    const key = JSON.stringify(obj.xAxis);
    counterTransactionsDates[key] = (counterTransactionsDates[key] || 0) + 1;
  });

  // map the chart and return the days with total transactions
  const getByDay = chartData.map(item => {
    const keyValue = Object.entries(item);
    const transactionRevenue =
      keyValue[keyValue.findIndex(pair => pair[0] === "transactionTotal")];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "transaction")];
    return {
      transactionRevenue: transactionRevenue[1],
      xAxis: moment(x[1]).format("dddd"),
    };
  });

  // Sort by day Mon - Sun
  const byDays =
    !isEmpty(getByDay) &&
    getByDay.sort((a, b) => orderDays[a.xAxis] - orderDays[b.xAxis]);

  // Count duplicate foreach object Weekdays for transactions
  const counterTransactionsDays = {};
  byDays.forEach(obj => {
    const key = JSON.stringify(obj.xAxis);
    counterTransactionsDays[key] = (counterTransactionsDays[key] || 0) + 1;
  });

  // reduce the map and add the transaction value with the same dates
  const reduceByDate = getByDate.reduce((map, item) => {
    var summarizedItem = map.get(item.xAxis);
    if (!summarizedItem) {
      summarizedItem = item;
    } else {
      summarizedItem.transactionRevenue += item.transactionRevenue;
    }
    map.set(item.xAxis, summarizedItem);
    return map;
  }, new Map());

  // reduce the map and add the transaction value with the same dates
  const reduceByDay = getByDay.reduce((map, item) => {
    var summarizedItem = map.get(item.xAxis);
    if (!summarizedItem) {
      summarizedItem = item;
    } else {
      summarizedItem.transactionRevenue += item.transactionRevenue;
    }
    map.set(item.xAxis, summarizedItem);
    return map;
  }, new Map());

  // get the map values from the reduced
  const getArrayByDate = Array.from(reduceByDate.values());
  const getArrayByDay = Array.from(reduceByDay.values());

  // get the sorted transactions values in counter to be mapped with the byDates Objects
  const setCounterTransactionsDates = Object.values(counterTransactionsDates);
  const byDateWithTransactions = getArrayByDate.map((obj, i) => {
    return {
      transactions: setCounterTransactionsDates[i],
      ...obj,
    };
  });

  // get the sorted transactions values in counter to be mapped with the byDay Objects
  const setCounterTransactionsDay = Object.values(counterTransactionsDays);
  const byDayWithTransactions = getArrayByDay.map((obj, i) => {
    return {
      transactions: setCounterTransactionsDay[i],
      ...obj,
    };
  });

  // Date selector start date and end date for transactions
  const DateSelector = () => {
    // map transaction dates
    let getDate = byDateWithTransactions.map(a =>
      moment(a.xAxis, "MM/DD/YYYY")
    );

    // sort them by desc dates
    getDate = !isEmpty(getDate) && getDate.sort((a, b) => a.unix() - b.unix());

    // get startdate and end date
    const startDate = getDate[0];
    const endDate = getDate.slice(-1)[0];
    // combined start date & end date
    const startEndDate = [moment(startDate), moment(endDate)];

    // find the dates between startdate and enddate
    // const findBetweenDates = current => {
    //   if (current <= moment(startDate).add(-2, "days")) {
    //     return true;
    //   } else if (current >= moment(endDate).add(+1, "days")) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };

    return (
      <RangePicker
        //disabledDate={findBetweenDates}
        defaultPickerValue={startEndDate}
        defaultValue={startEndDate}
        value={
          isEmpty(byTransactionsRangeDate)
            ? startEndDate
            : byTransactionsRangeDate
        }
        onChange={v => {
          // get selected range dates
          const getSelectedStartDate = v
            ? moment(v[0]).format("YYYY/MM/DD")
            : startDate;
          const getSelectedEndDate = v
            ? moment(v[1]).format("YYYY/MM/DD")
            : endDate;

          // filter data to get only between range dates
          const getFilteredData = byDateWithTransactions.filter(obj => {
            return (
              moment(obj.xAxis).format("YYYY/MM/DD") >= getSelectedStartDate &&
              moment(obj.xAxis).format("YYYY/MM/DD") <= getSelectedEndDate
            );
          });

          const getFilteredDataDisplay = chartDataDisplay.filter(obj => {
            return (
              obj.xAxis >= getSelectedStartDate &&
              obj.xAxis <= getSelectedEndDate
            );
          });

          // set the date range on the selected date in calendar
          setByTransactionsRangeDate(v);
          setByCampaignRangeDate(v);
          // set the data into filtered ones with the selected range dates
          setDataTransactionsRangeDate(getFilteredData);
          setDataCampaignRangeDate(getFilteredDataDisplay);
          setDateTrue(true);
          setDateFilter([getSelectedStartDate, getSelectedEndDate]);
        }}
      />
    );
  };

  const TabPane = Tabs.TabPane;

  // if chartdata is equal to empty
  const emptyCharts = isEmpty(chartData);
  if (emptyCharts)
    return (
      <Card style={{ height: "300px" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: "300px" }}
        >
          <CardText text={""} />
        </Row>
      </Card>
    );

  return (
    <div>
      <Card style={colStyle.container} bodyStyle={{ padding: 0 }}>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
        >
          {!isEmpty(byDateWithTransactions) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDateWithTransactions.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDateWithTransactions.length !== 1 && (
                  <Frag>
                    <Row
                      style={{ marginBottom: "2%", marginTop: "1%" }}
                      type="flex"
                      justify="center"
                    >
                      <DateSelector dateData={byDateWithTransactions} />
                    </Row>
                    <DualAxisAreaChart dualChartData={byDateWithTransactions} />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDayWithTransactions) ? (
            <TabPane tab={<TabText text={"Day of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDayWithTransactions.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDayWithTransactions.length !== 1 && (
                  <Frag>
                    <DualAxisAreaChart dualChartData={byDayWithTransactions} />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default TransactionsChart;
