import gql from "graphql-tag";
import { CampaignRequestorFields } from "../Fragments/CampaignRequestorFragments";

const GET_CAMPAIGN_ORDER_REQUESTOR = gql`
  ${CampaignRequestorFields}

  query campaignRequestorCampaignOrder($where: CampaignOrderWhereUniqueInput!) {
    campaignOrder(where: $where) {
      ...CampaignRequestorFields
    }
  }
`;

export default GET_CAMPAIGN_ORDER_REQUESTOR;
