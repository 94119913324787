import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { getImageType } from "../../../../core/utils/general/strings";

import {
  Row,
  Col,
  Card,
  Tooltip,
  Popconfirm,
  Typography,
  Upload,
  Form,
  Modal,
  Input,
  Spin,
  message,
} from "antd";
import Button from "../../../shared/globalStyling/Button";
import {
  LoadingOutlined,
  DeleteOutlined,
  FolderAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const VideoTab = ({
  createOrgMedia,
  videoCreativeIds,
  onLoadingDeleteMedias,
  onCreateAdgroup,
  onChangeStateValue,
  onDeleteMedias,
  refetchVideoMedias,
}) => {
  const [form] = Form.useForm();
  const [formAdGroup] = Form.useForm();
  const [uploadModal, setUploadModal] = useState(false);
  const [adGroupModal, setAdGroupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoDimensions, setVideoDimensions] = useState(null);
  const [key, setKey] = useState(null);

  // Execute query to fetch videos
  const refetchVideos = async () => {
    refetchVideoMedias({
      variables: {
        type: "VIDEO",
      },
    });
  };

  const clearFields = async () => {
    setVideoUrl(null);
    setFileList([]);
    setKey(null);
    form.resetFields();
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Validate the form fields
      if (!fileList.length) {
        message.error("Please select a file to upload");
        setLoading(false);
        return;
      }

      const { type, size } = fileList[0];

      const fileName = form.getFieldValue("name");

      const CapitalizeCreativeName =
        fileName.charAt(0).toUpperCase() + fileName.slice(1);

      await createOrgMedia({
        variables: {
          mediatype: "VIDEO",
          key: key,
          url: videoUrl,
          width: videoDimensions.width,
          height: videoDimensions.height,
          name: CapitalizeCreativeName,
          size,
          fileType: getImageType(type),
        },
      });

      message.success("Video Creative saved successfully!");

      await refetchVideos();

      clearFields();
      setUploadModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(`File upload failed: ${error.message}}`);
    }
  };

  const handleCreateAdGroup = async () => {
    setLoading(true);
    formAdGroup.resetFields();
    setAdGroupModal(false);

    const remakeIds = videoCreativeIds.map(idVal => {
      const getId = idVal;
      return { id: getId };
    });

    onChangeStateValue("adGroupMedias", remakeIds);

    await onCreateAdgroup();
    await refetchVideos();

    setLoading(false);
  };

  const handleDelete = async videoCreativeIds => {
    setLoading(true);
    await onDeleteMedias(videoCreativeIds);
    await refetchVideos();
    setLoading(false);
  };

  const customRequest = async ({ onSuccess, onError, file }) => {
    try {
      setUploading(false);
      const allowedVideoTypes = ["video/mp4"];

      const isVideo = allowedVideoTypes.includes(file.type);

      if (!isVideo) {
        message.error("Please upload a valid MP4 file");
        setUploading(false);
        setFileList([]);
        return false;
      }

      // Check if the name field is empty
      // Set the name of the creative if it hasn't been set
      if (
        file &&
        file.name &&
        file.name.length > 0 &&
        !form.getFieldValue("name")
      ) {
        const fileName = getFileNameWithoutExtension(file.name);
        form.setFieldsValue({ name: fileName });
      }

      // Read the file and set dimensions (optional)
      const dimensions = await getVideoDimensions(file);

      const response = await uploadToS3Storage(file);

      // Set the video URL for preview
      setVideoDimensions(dimensions);
      setVideoUrl(response.url);
      setKey(response.key);

      onSuccess();
      setUploading(false);
      message.success("File uploaded successfully!");
    } catch (error) {
      onError(error);
      message.error("File upload failed");
    }
  };

  const getFileNameWithoutExtension = fileName => {
    // Use the split method to separate the filename and extension
    var parts = fileName.split(".");

    // Remove the last part (the extension) and join the remaining parts
    parts.pop();
    var filenameWithoutExtension = parts.join(".");

    return filenameWithoutExtension;
  };

  const getVideoDimensions = file => {
    return new Promise(resolve => {
      if (!file) {
        resolve({ width: 0, height: 0 });
      }

      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = function() {
        const dimensions = {
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        };
        resolve(dimensions);
      };
    });
  };

  const uploadToS3Storage = async file => {
    const fileName = transformFileName(file.name);
    const response = await Storage.put(fileName, file, {
      contentType: "video/mp4",
    });

    const url = await Storage.get(response.key, { expires: 600000 });

    return {
      url: url,
      key: response.key,
    };
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); // Allow only one file in the list
  };

  function transformFileName(originalFileName) {
    const fileParts = originalFileName.split(".");
    const fileExtension = fileParts.pop();
    const fileNameWithoutExtension = fileParts.join(".");

    // Remove spaces and replace with underscore
    let fileNameWithoutSpaces = fileNameWithoutExtension.replace(/\s+/g, "_");

    // Remove non-alphanumeric characters
    let alphanumericFileName = fileNameWithoutSpaces.replace(
      /[^a-zA-Z0-9_]/g,
      ""
    );

    // Convert to lowercase
    alphanumericFileName = alphanumericFileName.toLowerCase();

    // Add a date stamp in Unix format with milliseconds
    const currentDate = new Date();
    const unixTimestamp = currentDate.getTime(); // Timestamp in milliseconds
    const formattedFileName = `${alphanumericFileName}_${unixTimestamp}.${fileExtension}`;

    return formattedFileName;
  }

  useEffect(() => {
    // Clear the preview when the file has been removed
    if (!fileList.length) {
      setVideoUrl(null);
    }
  }, [fileList]);

  return (
    <>
      <Modal
        title="Create Video Media"
        visible={uploadModal}
        closable={!isUploading}
        maskClosable={!isUploading}
        keyboard={!isUploading}
        centered
        footer={null}
        onCancel={() => {
          clearFields();
          setUploadModal(false);
        }}
      >
        <Spin spinning={loading}>
          <Form form={form} onFinish={handleSubmit}>
            <Row justify="center" align="middle">
              <Col span={24}>
                <center>
                  <div>
                    <Upload
                      customRequest={customRequest}
                      fileList={fileList}
                      onChange={handleChange}
                      showUploadList={{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                      }}
                      accept=".mp4"
                      maxCount={1} // Allow only one file to be selected
                    >
                      <Button icon={<UploadOutlined />}>Select Video</Button>
                    </Upload>

                    {videoUrl && (
                      <video controls width="100%" height="auto">
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </center>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-around"
              style={{ marginTop: "1em" }}
            >
              <Col span={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Name of Creative" />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={loading || isUploading}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={`Create Video Ad Group - ${videoCreativeIds.length} Creative(s) Selected`}
        visible={adGroupModal}
        centered
        footer={null}
        onCancel={() => {
          setAdGroupModal(false);
          formAdGroup.resetFields();
        }}
      >
        <Form
          layout="vertical"
          onFinish={handleCreateAdGroup}
          form={formAdGroup}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input
                  placeholder="Name of Video Ad Group"
                  name="name"
                  value="replace this"
                  onChange={e => {
                    onChangeStateValue("adGroupName", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button htmlType="submit" type="primary">
                  Create
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Spin tip="Loading..." spinning={loading}>
        <Row>
          <Col xs={24} lg={24}>
            <Card bodyStyle={{ padding: 5 }}>
              <Row align="middle" justify="space-between">
                <Col
                  className="ant-col-align-self-center"
                  xs={24}
                  xl={4}
                  style={{ paddingLeft: 15, paddingTop: 10 }}
                >
                  <Title level={5}>Video Creative</Title>
                </Col>
                <Col xs={24} xl={20}>
                  <Row
                    align="right"
                    className="ant-row-flex-column ant-row-justify-center ant-row-justify-end"
                  >
                    <Popconfirm
                      placement="topLeft"
                      title={`Are you sure you want delete the selected creatives?`}
                      onConfirm={() => {
                        handleDelete(videoCreativeIds);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Delete selected creatives`}
                      >
                        <Button
                          type="primary"
                          disabled={
                            !videoCreativeIds || !videoCreativeIds.length
                          }
                          icon={
                            onLoadingDeleteMedias === true ? (
                              <LoadingOutlined />
                            ) : (
                              <DeleteOutlined />
                            )
                          }
                          // style={{ marginLeft: "auto" }}
                          ghost
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                    <Button type="primary" onClick={() => setUploadModal(true)}>
                      Upload Video Creative
                    </Button>
                    <Button
                      type="primary"
                      icon={<FolderAddOutlined />}
                      disabled={
                        videoCreativeIds && videoCreativeIds.length > 0
                          ? false
                          : true
                      }
                      onClick={() => setAdGroupModal(true)}
                    >
                      Create Video Ad Group
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default VideoTab;
