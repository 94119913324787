import React, { useState } from "react";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
  computeCPC,
  computeROAS,
  computeCTR,
  computeCPM,
  calculatePercentageDifference,
  computeConversionRate,
} from "../../../../utils/paidSearch/campaigns";

const PaidSearchAggregatedController = ({ data, children, ...props }) => {
  const [aggregated, setAggregated] = useState(data.aggregateData);
  const [kpiPercentage, setKpiPercentage] = useState({
    percentages: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      ctr: 0,
      cpc: 0,
      roas: 0,
      revenue: 0,
      cost: 0,
      conversionRate: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      conversions: 0,
      ctr: 0,
      cpc: 0,
      roas: 0,
      revenue: 0,
      cost: 0,
      conversionRate: 0,
    },
    ranges: "",
  });
  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);

  const setBySummaryRangeDate = dates => {
    setControllerbySummaryRangeDate(dates);

    processDatePickerChange(dates);
  };

  const processPercentage = (dates, current, prev = null) => {
    let daysDiff = moment(dates[1]).diff(moment(dates[0]), "days") || 0;
    if (daysDiff > 0) daysDiff++;
    const datesToCompare =
      [
        moment(dates[0]).subtract(daysDiff, "days"),
        moment(dates[1]).subtract(daysDiff, "days"),
      ] || [];

    const cloneSummary = prev !== null ? prev : cloneDeep(data.aggregateData);
    const cByDate = cloneSummary.byDate;
    const filterByDate = cByDate
      ? cByDate.filter(obj => {
          if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
          return (
            obj.date >= datesToCompare[0].unix() &&
            obj.date <= datesToCompare[1].unix()
          );
        })
      : [];
    const previous =
      filterByDate.length > 0 &&
      filterByDate.reduce((conso, obj) => ({
        clicks: conso.clicks + obj.clicks,
        impressions: conso.impressions + obj.impressions,
        conversions: conso.conversions + obj.conversions,
        cost: conso.cost + obj.cost,
        spend: conso.spend + obj.spend,
        revenue: conso.revenue + obj.revenue,
      }));

    if (previous) {
      if (isNaN(previous.cost)) previous.cost = previous.spend;
      previous.ctr = computeCTR(previous.clicks, previous.impressions);
      previous.cpc = computeCPC(previous.cost, previous.clicks);
      previous.roas = computeROAS(previous.revenue, previous.cost);
      previous.conversionRate = computeConversionRate(
        previous.clicks,
        previous.conversions
      );
      setKpiPercentage({
        percentages: {
          impressions: calculatePercentageDifference(
            current.impressions,
            previous.impressions
          ),
          clicks: calculatePercentageDifference(
            current.clicks,
            previous.clicks
          ),
          transactions: calculatePercentageDifference(
            current.conversions,
            previous.conversions
          ),
          ctr: calculatePercentageDifference(current.ctr, previous.ctr),
          cpc: calculatePercentageDifference(current.cpc, previous.cpc),
          roas: calculatePercentageDifference(current.roas, previous.roas),
          revenue: calculatePercentageDifference(
            current.revenue,
            previous.revenue
          ),
          spend: calculatePercentageDifference(current.cost, previous.cost),
          conversionRate: calculatePercentageDifference(
            current.conversionRate,
            previous.conversionRate
          ),
        },
        previousValues: previous,
        ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
      });
    } else {
      setKpiPercentage({
        percentages: {
          impressions: 0,
          clicks: 0,
          transactions: 0,
          ctr: 0,
          cpc: 0,
          roas: 0,
          revenue: 0,
          cost: 0,
          conversionRate: 0,
        },
        previousValues: {
          impressions: 0,
          clicks: 0,
          conversions: 0,
          ctr: 0,
          cpc: 0,
          roas: 0,
          revenue: 0,
          cost: 0,
          conversionRate: 0,
        },
        ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
      });
    }
  };

  const processDatePickerChange = dates => {
    let cloneSummary = cloneDeep(data.aggregateData);
    const cByDate = cloneSummary.byDate;

    const filterByDate = cByDate.filter(obj => {
      if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
      return obj.date >= dates[0].unix() && obj.date <= dates[1].unix();
    });

    cloneSummary.byDate = filterByDate;

    if (filterByDate && filterByDate.length > 0) {
      const consolidatedCount = filterByDate.reduce((conso, obj) => ({
        clicks: conso.clicks + obj.clicks,
        impressions: conso.impressions + obj.impressions,
        keywords: conso.keywords + obj.keywords,
        locations: conso.locations + obj.locations,
        conversions: conso.conversions + obj.conversions,
        cost: conso.cost + obj.cost,
        revenue: conso.revenue + obj.revenue,
      }));

      const cpm = computeCPM(
        consolidatedCount.cost,
        consolidatedCount.impressions
      );
      const ctr = computeCTR(
        consolidatedCount.clicks,
        consolidatedCount.impressions
      );
      const cpc = computeCPC(consolidatedCount.cost, consolidatedCount.clicks);
      const roas = computeROAS(
        consolidatedCount.revenue,
        consolidatedCount.cost
      );
      const conversionRate = computeConversionRate(
        consolidatedCount.clicks,
        consolidatedCount.conversions
      );

      cloneSummary.clicks = consolidatedCount.clicks;
      cloneSummary.impressions = consolidatedCount.impressions;
      cloneSummary.conversions = consolidatedCount.conversions;
      cloneSummary.cost = consolidatedCount.cost;
      cloneSummary.revenue = consolidatedCount.revenue;
      cloneSummary.cpm = cpm;
      cloneSummary.spend = consolidatedCount.cost;
      cloneSummary.ctr = ctr;
      cloneSummary.cpc = cpc;
      cloneSummary.roas = roas;
      cloneSummary.conversionRate = conversionRate;
    } else {
      cloneSummary.clicks = 0;
      cloneSummary.impressions = 0;
      cloneSummary.conversions = 0;
      cloneSummary.cost = 0;
      cloneSummary.revenue = 0;
      cloneSummary.cpm = 0;
      cloneSummary.spend = 0;
      cloneSummary.ctr = 0;
      cloneSummary.cpc = 0;
      cloneSummary.roas = 0;
      cloneSummary.conversionRate = 0;
    }

    setAggregated(cloneSummary);
    processPercentage(dates, cloneSummary);
  };
  const orgConfig = props.orgConfig;
  return React.cloneElement(children, {
    kpiPercentage,
    orgConfig,
    setBySummaryRangeDate,
    bySummaryRangeDate,
    data: aggregated,
    ...props,
  });
};

export default PaidSearchAggregatedController;
