import React from "react";
import RollUpPaidSearchDataLoader from "../../../../../core/components/home/RollUp/paid-search/RollUpPaidSearchDataLoader";
import RollUpPaidSearchView from "./RollUpPaidSearchView";

const RollUpPaidSearch = props => {
  return (
    <RollUpPaidSearchDataLoader {...props}>
      <RollUpPaidSearchView />
    </RollUpPaidSearchDataLoader>
  );
};

export default RollUpPaidSearch;
