import React from "react";
import { Card } from "antd";

import AudienceHeatMap from "./AudienceHeatMap";

const HeatMapCard = ({ latLngs, cityData, zipData }) => {
  return (
    <Card
      style={{ width: "100%" }}
      bodyStyle={{
        padding: 0,
        height: "30.2em",
      }}
      bordered={false}
    >
      <AudienceHeatMap
        zoom={10}
        latLngs={latLngs}
        zipData={zipData}
        cityData={cityData}
        currentLocation={{ latitude: 37.8272, longitude: -122.2913 }}
      />
    </Card>
  );
};

export default HeatMapCard;
