import gql from "graphql-tag";

const UPDATE_AD_GROUP_CREATIVES = gql`
  mutation updateAdGroupCreatives(
    $id: ID
    $data: AdGroupCreativesUpdateInput!
  ) {
    updateAdGroupCreatives(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export default UPDATE_AD_GROUP_CREATIVES;
