import gql from "graphql-tag";

const DELETE_USER = gql`
  mutation deleteDashboardUser($id: ID!) {
    deleteDashboardUser(where: { id: $id }) {
      id
      name
      username
      email
      phone
      cognitoUserId
      roles(first: 5) {
        id
        roleItems(first: 20) {
          id
          feature
          actions
        }
        org {
          id
          name
          parentOrg {
            id
            name
          }
          logo {
            id
            key
          }
        }
      }
      avatar {
        id
        key
      }
    }
  }
`;

export default DELETE_USER;
