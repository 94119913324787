import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card } from "antd";
import { CompressOutlined } from "@ant-design/icons";
import TargetingLocationTab from "../../OrgConfig/components/Targeting/TargetingLocationTab";
import { determineUserAccess } from "../../../../core/permissions/userLevelAccess";

const TargetingMainView = ({
  currentuser,
  setAudienceAmplifyDefault,
  attributionAudienceName,
  createTargetingLocation,
  deleteLocation,
  google,
  locationList,
  onChangeStateValue,
  onCsvUploadComplete,
  onSubmitAudience,
  selectedLocations,
  updateTargetingLocation,
  isTargetingPreviewModalOpen,
  targetingLocationsPreviewData,
  closeTargetingPreviewModal,
  bulkImportTargetingLocations,
  selectTargetingLocationPreview,
  targetingAudiences,
  deleteAudienceLocation,
  deleteBulkLocation,
  handleSubmitRegionGroup,
  handleUpdateRegionGroup,
  deleteRegionGroup,
  regionGroups,
  handleSubmitIABCategoryGroup,
  iABCategoryGroups,
  deleteIABCategoryGroup,
  selectedOrg,
  dealIds,
  createDealId,
  deleteDealIds,
  updateDealId,
  presetRegionGroups,
  presetRegionGroupsLoading,
}) => {
  //Title shown on browser tab
  document.title = "Targeting Main View";

  const { permission, isPreviewOrg } = currentuser;
  const userPermission = determineUserAccess(permission);

  const { isSelfService, amplify } = userPermission;

  return (
    <React.Fragment>
      <PageHeaderLayout titleIcon={<CompressOutlined />} title={"Targeting"} />
      <PageContentLayout>
        <Card>
          <TargetingLocationTab
            setAudienceAmplifyDefault={setAudienceAmplifyDefault}
            attributionAudienceName={attributionAudienceName}
            createTargetingLocation={createTargetingLocation}
            deleteLocation={deleteLocation}
            google={google}
            locationList={locationList}
            onChangeStateValue={onChangeStateValue}
            onCsvUploadComplete={onCsvUploadComplete}
            onSubmitAudience={onSubmitAudience}
            selectedLocations={selectedLocations}
            updateTargetingLocation={updateTargetingLocation}
            isTargetingPreviewModalOpen={isTargetingPreviewModalOpen}
            targetingLocationsPreviewData={targetingLocationsPreviewData}
            closeTargetingPreviewModal={closeTargetingPreviewModal}
            bulkImportTargetingLocations={bulkImportTargetingLocations}
            selectTargetingLocationPreview={selectTargetingLocationPreview}
            targetingAudiences={targetingAudiences}
            deleteAudienceLocation={deleteAudienceLocation}
            deleteBulkLocation={deleteBulkLocation}
            handleSubmitRegionGroup={handleSubmitRegionGroup}
            handleUpdateRegionGroup={handleUpdateRegionGroup}
            deleteRegionGroup={deleteRegionGroup}
            regionGroups={regionGroups}
            handleSubmitIABCategoryGroup={handleSubmitIABCategoryGroup}
            iABCategoryGroups={iABCategoryGroups}
            deleteIABCategoryGroup={deleteIABCategoryGroup}
            selectedOrg={selectedOrg}
            isSelfService={isSelfService}
            isPreviewOrg={isPreviewOrg}
            amplify={amplify}
            dealIds={dealIds}
            createDealId={createDealId}
            deleteDealIds={deleteDealIds}
            updateDealId={updateDealId}
            presetRegionGroups={presetRegionGroups}
            presetRegionGroupsLoading={presetRegionGroupsLoading}
          />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default TargetingMainView;
