import React from "react";
import { Button, Result } from "antd";

export const RetryButton = params => {
  const { buttonText, buttonFn } = params;

  return (
    <Button
      type="primary"
      key="console"
      onClick={
        buttonFn
          ? buttonFn
          : () => {
              window.location.reload(true);
            }
      }
    >
      {buttonText ? buttonText : "Retry"}
    </Button>
  );
};

export const NotificationResult = params => {
  const { status, title, subTitle, extras } = params;

  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={extras}
    ></Result>
  );
};
