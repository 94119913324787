import gql from "graphql-tag";

const REPROCESS_STATUS = gql`
  query reprocessStatus($keys: [String!]) {
    reprocessStatuses(where: { key_in: $keys }) {
      id
      key
      module
      dateQueued
      dateProcessed
      dateFinished
      status
      requestor {
        id
        name
      }
    }
  }
`;

export default REPROCESS_STATUS;
