import React from "react";

const styles = {
  container: {
    padding: "2%",
  },
};

const PageContentLayout = ({ children, style }) => (
  <div style={style || styles.container}>{children}</div>
);
export default PageContentLayout;
