import React, { useState } from "react";
import { Row, Spin, Badge } from "antd";
import PaginationTable from "../../../shared/PaginationTable";

const InvoiceView = props => {
  let { invoiceList, loading, invoiceFilterList, history } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "Invoice #",
      dataIndex: "invoice_number",
      key: "invoiceNum",
      width: "16%",
    },
    {
      title: "Cost",
      dataIndex: "amount",
      key: "amount",
      className: "column-money",
      width: "16%",
    },
    {
      title: "Due On",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      defaultSortOrder: "descend",
      width: "16%",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "16%",
      render: (text, invoiceFilterList) => (
        <div>
          <Badge
            dot
            style={{
              backgroundColor:
                invoiceFilterList.status === "PAID"
                  ? "green"
                  : invoiceFilterList.status === "SCHEDULED"
                  ? "blue"
                  : "volcano",
            }}
          />
          <span style={{ marginLeft: "10px" }}>{invoiceFilterList.status}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "16%",

      onCell: record => {
        return {
          onClick: event => {
            const { key } = record;
            history.push(`/invoice/details/${key}`);
          },
        };
      },
      render: (text, invoiceFilterList) => (
        <div style={{ color: "#1890ff", cursor: "pointer" }}>
          View Invoice Details
        </div>
      ),
    },
    {
      title: "",
      key: "task",
      render: (text, invoiceFilterList) => (
        <div
          style={
            invoiceFilterList.status === "PAID" ||
            invoiceFilterList.status === "SCHEDULED" ||
            invoiceFilterList.status === "CANCELED" ||
            invoiceFilterList.status === "REFUNDED"
              ? { display: "none" }
              : null
          }
        >
          <a
            href={invoiceFilterList.public_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Pay Invoice
          </a>
        </div>
      ),
    },
  ];

  const table = (
    <>
      <PaginationTable
        // footer={medias && medias.slice(currentPage * 10 - 10, currentPage * 10)}
        bordered={false}
        columns={columns}
        data={invoiceFilterList}
        scroll={{ x: 800, y: 600 }}
        paginationSize={"default"}
        size={"default"}
        total={invoiceFilterList && invoiceFilterList.length}
        current={currentPage}
        pageSize={pageSize}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          setPageSize(paginationObject.pageSize);
          setCurrentPage(paginationObject.current);
        }}
      />
    </>
  );
  if (loading) {
    return (
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  } else {
    return invoiceList && invoiceList.length ? table : null;
  }
};

export default InvoiceView;
