import React from "react";
import { Row, Col } from "antd";
import { isEmpty } from "lodash";
import DetailBar from "./components/DetailBar";
import SummaryChart from "../../../../components/charts/SummaryChart";

const SummaryTab = ({
  data,
  kpiPercentage,
  orgConfig,
  setBySummaryRangeDate,
  bySummaryRangeDate,
  currencyFormat,
  roasFormat,
}) => {
  const {
    impressions,
    clicks,
    conversions,
    ctr,
    cpc,
    roas,
    revenue,
    cost,
    conversionRate,
    byDate,
  } = data;
  const { priceVisible } = orgConfig;
  return (
    <Row gutter={35}>
      <Col xs={24}>
        <DetailBar
          impressions={impressions || 0}
          clicks={clicks || 0}
          transactions={conversions || 0}
          ctr={ctr || 0}
          cpc={cpc || 0}
          roas={roas || 0}
          revenue={revenue || 0}
          spend={cost || 0}
          conversionRate={conversionRate || 0}
          showPrices={priceVisible}
          kpiPercentage={kpiPercentage}
          currencyFormat={currencyFormat}
          roasSelectedFormat={roasFormat}
        />
      </Col>
      <Col xs={24}>
        <SummaryChart
          byDates={isEmpty(byDate) ? [] : byDate}
          reportData={byDate}
          dataSummaryRangeDate={byDate}
          showPrices={priceVisible}
          showTransactions={conversions && conversions !== 0}
          setBySummaryRangeDate={setBySummaryRangeDate}
          bySummaryRangeDate={bySummaryRangeDate}
          hasTooltip={false}
          currencyFormat={currencyFormat}
        />
      </Col>
    </Row>
  );
};

export default SummaryTab;
