import gql from "graphql-tag";

const GET_LOCATIONS = gql`
  query listLocations($id: ID) {
    locations(
      where: {
        AND: [
          {
            OR: [{ org: { id: $id } }, { org: { parentOrg_some: { id: $id } } }]
          }
        ]
      }
      first: 800
      orderBy: updatedAt_DESC
    ) {
      id
      name
      description
      street
      city
      state
      province
      lat
      lng
      formattedAddress
      email
      locationKey
      phone
      contact
      url
      zip
      country
      county
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      updatedAt
      org {
        id
        name
      }
      geoframe {
        id
        name
        start
        end
        polygons {
          id
          geometry
        }
        circles {
          id
          coordinate {
            lat
            lng
          }
          radius
        }
      }
      transConfig {
        id
        cart
        menuDataKey
      }
      audienceType
      createdBy {
        name
      }
      updatedBy {
        name
      }
    }
  }
`;

export default GET_LOCATIONS;
