import gql from "graphql-tag";

const DELETE_ARTICLE_CATEGORY = gql`
  mutation deleteArticleCategory($id: ID) {
    deleteArticleCategory(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_ARTICLE_CATEGORY;
