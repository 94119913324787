import React from "react";
import { Popconfirm, Button, message } from "antd";
//locals from shared
import PageHeader from "../.././shared/PageHeader";
import PageHeaderChild from "../.././shared/PageHeaderChild";
import PaginationTable from "../.././shared/PaginationTable";
import Seeder from "../.././shared/Seeder";
//locals from core
import Catalog from "../../.././core/components/catalog";
//locals
import LocationForm from "./LocationForm";
//utils
import { locationsProcessLicensed } from "../../.././core/utils/dataloading";

import { isEmpty } from "lodash";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ProfileOutlined } from "@ant-design/icons";

class Layout extends React.Component {
  constructor(props) {
    super();
    this.columns = [
      {
        title: "Address",
        dataIndex: "id",
        key: "address",
        width: "20%",
        render: (id, record) => {
          return (
            <div>
              {record.street + ", " + record.city + ", " + record.state}
            </div>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "10%",
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "15%",
        sorter: (a, b) => {
          return a.description.localeCompare(b.description);
        },
      },
      {
        title: "Hours",
        dataIndex: "hours",
        key: "hours",
        width: "10%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: "10%",
      },
      {
        title: "License",
        dataIndex: "licenseType",
        key: "licenseType",
        width: "10%",
      },
      {
        title: "Organization",
        dataIndex: "org",
        key: "org",
        render: org => {
          if (org) {
            return <div>{org.name}</div>;
          }
          return <div>N/A</div>;
        },
        width: "15%",
      },
      {
        title: "",
        dataIndex: "menu",
        key: "menu",
        render: (org, record) => {
          let dispensary = true;
          const id = isEmpty(record.catalogs) ? " " : record.catalogs[0].id;

          return (
            <Button
              disabled={dispensary ? false : true}
              shape="circle"
              icon={<ProfileOutlined />}
              onClick={() => {
                this.setState({
                  menu: true,
                  edit: false,
                  catalogId: id ? id : null,
                });
              }}
            />
          );
        },
        width: "3%",
      },

      {
        title: "",
        key: "edit",
        width: "3%",

        render: (text, record) => (
          <EditOutlined
            onClick={() => {
              this.setState({
                showForm: true,
                ...record,
                license: record.licenseType,
                //orgId: record.org.id ? record.org.id : "",
                edit: true,
              });
            }}
          />
        ),
      },
      {
        title: "",
        key: "delete",
        width: "3%",
        render: (text, record) => (
          <Popconfirm
            title="Are you sure you want to delete this Location?"
            onConfirm={e => {
              this.deleteLocation(record.id);
            }}
            onCancel={e => {
              message.warning("cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        ),
      },
    ];

    this.state = {
      showForm: false,
      name: "",
      description: "",
      street: "",
      city: "",
      state: "",
      lat: "",
      lng: "",
      orgId: "",
      type: "",
      license: "",
      hours: "",
      catalogId: "",
    };
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onLocationRecieved = data => {
    const address = data.result[0].formatted_address;
    const components = data.result[0].address_components;
    const street = components[0].short_name + " " + components[1].short_name;
    const { lat, lng } = data.latlngs;

    let city, state;

    for (let i = 0; i < components.length; i++) {
      if (components[i].types.includes("locality")) {
        city = components[i].short_name;
      }

      if (components[i].types.includes("administrative_area_level_1")) {
        state = components[i].short_name;
      }
    }

    this.setState({
      address,
      street,
      city,
      state,
      lat,
      lng,
    });
  };

  createLocation = async () => {
    const { createLocation } = this.props;
    const {
      name,
      description,
      street,
      city,
      address,
      lat,
      lng,
      orgId,
      type,
      license,
      hours,
    } = this.state;

    if (street === "" || !orgId) {
      console.log("Error - something was not filled out");
      return;
    }

    const data = {
      name,
      description,
      street,
      city,
      state: "CALIFORNIA",
      lat,
      lng,
      orgId,
      type,
      license,
      hours,
      address,
    };

    const response = await createLocation(data);
    const { status } = response;

    if (status === "success") {
      message.success("New location created");
      this.setState({
        name: "",
        description: "",
        street: "",
        showForm: false,
      });
    } else if (status === "failure") {
      message.error(
        "An error has occurred while writing to the db, the location has not been created"
      );
    }
  };

  updateLocation = async () => {
    const { editLocation } = this.props;
    const {
      id,
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      orgId,
      type,
      license,
      hours,
    } = this.state;
    if (!street || !orgId) {
      console.log("Error - something was not filled out");
      return;
    }

    const data = {
      id,
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      orgId,
      type,
      license,
      hours,
    };

    const response = await editLocation(data);
    const { status } = response;
    if (status === "success") {
      message.success("Location has been updated successfully");
      this.setState({
        name: "",
        description: "",
        street: "",
        showForm: false,
      });
    } else if (status === "failure") {
      message.error(
        "An error has occurred while writing to the db, the location has not been updated"
      );
    }
  };

  deleteLocation = async id => {
    const { deleteLocation } = this.props;
    const response = await deleteLocation(id);
    const { status } = response;
    if (status === "success") {
      message.success("Location has been deleted successfully");
    } else if (status === "failure") {
      message.error(
        "An error has occurred while writing to the db, the location has not been removed"
      );
    }
  };

  render() {
    if (this.state.showForm) {
      return (
        <div style={{ width: "100%", padding: 10, backgroundColor: "#fff" }}>
          <PageHeaderChild
            showForm={() => {
              this.setState({
                showForm: false,
                name: "",
                description: "",
                street: "",
                city: "",
                state: "",
                lat: "",
                lng: "",
                orgId: "",
                type: "",
                license: "",
                hours: "",
                edit: false,
              });
            }}
            breadcrumb1="Locations"
            breadcrumb2={this.state.edit ? "Edit" : "Create"}
          />
          <LocationForm
            createLocation={this.createLocation}
            updateLocation={this.updateLocation}
            onChange={this.onChange}
            state={this.state}
            onLocationRecieved={this.onLocationRecieved}
          />
        </div>
      );
    }

    if (this.state.menu) {
      return (
        <div style={{ width: "100%", padding: 10, backgroundColor: "#fff" }}>
          <PageHeaderChild
            showForm={() => {
              this.setState({
                showForm: false,
                name: "",
                description: "",
                street: "",
                city: "",
                state: "",
                lat: "",
                lng: "",
                orgId: "",
                type: "",
                license: "",
                hours: "",
                edit: false,
                menu: false,
              });
            }}
            breadcrumb1="Menu"
            breadcrumb2="Dispensary"
          />
          <div style={{ padding: 10 }}>
            <Catalog id={this.state.catalogId} />
          </div>
        </div>
      );
    }
    const { createLocation, data } = this.props;
    //const { createLocation } = this.props;
    //const data = [];
    return (
      <div style={{ width: "100%", padding: 10, backgroundColor: "#fff" }}>
        <PageHeader
          showForm={() => {
            this.setState({ showForm: true });
          }}
          data={{ name: "Create Location" }}
          title="Locations"
        />
        <PaginationTable
          data={data}
          columns={this.columns}
          scroll={{ x: 700 }}
        />
        <Seeder
          name={"Load licensed Locations"}
          list={data}
          action={createLocation}
          processor={locationsProcessLicensed}
        />
      </div>
    );
  }
}

export default Layout;
