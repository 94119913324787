import gql from "graphql-tag";

const AUDIENCE = gql`
  query audience(
    $id: ID # $first: Int # $skip: Int # $orderBy: GeoTargetOrderByInput # $where: GeoTargetWhereInput
  ) {
    audience(where: { id: $id }) {
      id
      name
      description
      type
      uniqueCount
      deviceIdCount
      observationCount
      startDate
      endDate
      updatedAt
      geoTargets {
        #   (where: $where, orderBy: $orderBy, first: $first, skip: $skip)
        id
        name
        description
        start
        end
        observations
        uniqueDevices
        expandedReach
        orgs {
          id
        }
        vwiUpdated
        vwiUpdatedOnSpot
        tags
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      eventsTargets {
        id
        name
        description
        start
        end
        observations
        uniqueDevices
        expandedReach
        tags
        eventTags {
          id
          appId
          creative {
            id
          }
          data
        }
        tag
        updatedAt
      }
      orgs {
        id
      }
      tags
      updatedAt
      reports(first: 1, orderBy: createdAt_DESC, where: { type: AUDIENCE }) {
        id
        type
        key
      }
    }
  }
`;

export default AUDIENCE;
