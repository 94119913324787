import gql from "graphql-tag";

const GET_ORG_THEME_CONFIG = gql`
  query getOrgThemeConfig($id: ID) {
    getOrgThemeConfig(where: { id: $id }) {
      sidebarColor
      primaryColor
    }
  }
`;

export default GET_ORG_THEME_CONFIG;
