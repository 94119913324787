import React from "react";
import { Row, Col, Table, Card, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  formatCurrency2SigFig,
  formatWholeNumber,
} from "../../../../../core/utils/campaigns";
import { isEmpty } from "lodash";

const TopLandingPages = ({ data, count = 10, domain = "" }) => {
  const columns = [
    {
      title: "Landing Page",
      dataIndex: "landingPage",
      key: "landingPage",
      render: text => {
        return (domain + text).replace(`//`, "/");
      },
    },
    {
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Page Value",
      dataIndex: "pageValue",
      key: "pageValue",
      render: (text, record) => {
        return formatCurrency2SigFig(record.pageValue / record.recordCount);
      },
    },
  ];

  let rows = [];
  if (!isEmpty(data)) {
    data.forEach(item => {
      if (!isEmpty(item.landingPages)) {
        item.landingPages.forEach(row => {
          const rowIndex = rows.findIndex(
            rowsPage => rowsPage.landingPage === row.landingPage
          );
          if (rowIndex === -1) {
            rows.push({
              landingPage: row.landingPage,
              pageValue: parseFloat(row.pageValue),
              pageViews: parseFloat(row.pageViews),
              recordCount: 1,
            });
          } else {
            rows[rowIndex].pageViews += parseFloat(row.pageViews);
            rows[rowIndex].pageValue += parseFloat(row.pageValue);
            rows[rowIndex].recordCount += 1;
          }
        });
      }
    });
  }

  // If no data, return empty
  if (rows.length === 0) return <></>;
  rows = rows.sort((a, b) => b.pageViews - a.pageViews).slice(0, count);
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Top Pages"
          extra={
            <Tooltip
              title={
                <span>
                  This metric lists the ten webpages that most frequently serve
                  as the initial entry points for visitors to your website.
                  Understanding which pages are attracting the most traffic can
                  inform content and optimization strategies.
                </span>
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          }
        >
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            rowKey={row => row.landingPage}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TopLandingPages;
