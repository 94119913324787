import React from "react";
import { Row, Col, Card } from "antd";
import ReferrerTable from "./ReferrerTable";
import ReferrerRingChart from "./ReferrerRingChart";

// Global Styling
import { CardTitleText } from "../../../../../shared/globalStyling/styledText";
//colors
import { colors } from "../../../../../../core/utils/constants/listOfColors";

const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const {
  xs,
  sm,
  md,
  lg,
  xl,
  xsPie,
  smPie,
  mdPie,
  lgPie,
  xlPie,
  keyColors,
  campaignCardStyle,
  campaignCardStyleCreatives,
} = Style;

const ReferrerDetails = props => {
  const { referrerData } = props;

  //const parsedData = prepareCTR(publisherData);
  const colorValuePairs = referrerData.map((el, index) => {
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Unknown Referrer`,
      color: colors[index],
    };
  });

  return (
    <Row gutter={35}>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={campaignCardStyleCreatives}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Top 25 Referring Sources"} />}
        >
          <ReferrerTable referrerData={colorValuePairs} />
        </Card>
      </Col>
      <Col xs={xsPie} sm={smPie} md={mdPie} lg={lgPie} xl={xlPie}>
        <Card
          style={campaignCardStyleCreatives}
          bodyStyle={{ padding: 0, height: "100%" }}
          headStyle={{ borderWidth: "2px", height: "66px" }}
          title={<CardTitleText text={"Source Distribution"} />}
        >
          <ReferrerRingChart
            data={colorValuePairs}
            keyColors={keyColors}
            height={campaignCardStyle.height}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ReferrerDetails;
