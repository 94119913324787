import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import SegmentsLayout from "../SegmentsLayout";
import SegmentsLoader from "../../../../core/components/segments/Loader";
import SegmentsGeoTargetLoader from "../../../../core/components/segments/geotargets/Loader";
import SegmentsGeoTargetView from "../../SegmentsGeoTarget/SegmentsGeoTargetView";
import SegmentsEventsTargetView from "../../SegmentsGeoTarget/SegmentsEventsTargetView";

const SegmentsMainView = props => {
  return (
    <SegmentsLoader
      tableConfig={{
        pageSize: 10,
        datakeys: [],
      }}
      {...props}
    >
      <SegmentsLayout />
    </SegmentsLoader>
  );
};

// Feature level subroutes
const SegmentsRoutes = props => {
  const { path } = props.match;
  return (
    <Switch>
      <Route
        exact
        path={`${path}/geo-targets/new/:pageNumber?/:orderByFilter?/:whereFilter?`}
        render={() => (
          <SegmentsGeoTargetLoader
            tableConfig={{
              pageSize: 10,
              datakeys: [],
            }}
            {...props}
          >
            <SegmentsGeoTargetView {...props} />
          </SegmentsGeoTargetLoader>
        )}
      />
      <Route
        exact
        path={`${path}/events-targets/new/:pageNumber?/:orderByFilter?/:whereFilter?`}
        render={() => (
          <SegmentsLoader
            tableConfig={{
              pageSize: 10,
              datakeys: [],
            }}
            {...props}
          >
            <SegmentsEventsTargetView />
          </SegmentsLoader>
        )}
      />
      <Route
        exact
        path={`${path}/new/:pageNumber?/:orderByFilter?/:whereFilter?`}
        component={SegmentsMainView}
      />
      <Route
        path={`${path}`}
        component={() => <Redirect to={`${path}/new/1/updatedAt_DESC/{}`} />}
      />
    </Switch>
  );
};

export default SegmentsRoutes;
