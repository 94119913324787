import gql from "graphql-tag";

const GET_ROLL_UP_SEO_ANALYTICS = gql`
  query getRollUpSEOAnalytics(
    $orgId: String!
    $startDate: DateTime
    $endDate: DateTime
    $advertiserIds: [String]
  ) {
    getRollUpSEOAnalytics(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      advertiserIds: $advertiserIds
    )
  }
`;

export default GET_ROLL_UP_SEO_ANALYTICS;
