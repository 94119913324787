import React, { useEffect } from "react";
import { Modal, Form, Input, Select, Button } from "antd";

export const CreativeAdGroupForm = ({
  visible,
  setVisible,
  selectedAdGroup,
  updateAdGroup,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedAdGroup) {
      form.setFieldsValue({
        name: selectedAdGroup.name,
        creatives: selectedAdGroup.medias.map(media => media.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdGroup]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = values => {
    updateAdGroup({
      adGroup: {
        ...values,
        medias: selectedAdGroup.medias
          .map(media => media.id)
          .filter(mediaId => !values.creatives.includes(mediaId)),
        id: selectedAdGroup.id,
      },
      type: "disconnect",
    });
    setVisible(false); // Close the modal after form submission
  };
  const handleOk = () => {
    form.submit();
  };

  return (
    <div>
      <Modal
        title="Edit AdGroup"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter a value",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Creatives"
            name="creatives"
            rules={[
              {
                required: true,
                message: "Please select at least one creative",
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch={false}
              options={selectedAdGroup.medias.map(media => ({
                value: media.id,
                label: media.name,
              }))}
            ></Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
