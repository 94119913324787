import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col, Row, Space, Form, message } from "antd";
import moment from "moment";
import { GoogleApiWrapper } from "google-maps-react";
import { useHistory } from "react-router-dom";

import CampaignRequestorSummary from "./CampaignRequestorSummary";
import CampaignRequestorDetails from "./CampaignRequestorDetails";
import CampaignRequestorBudget from "./CampaignRequestorBudget";
import CampaignRequestorCategories from "./CampaignRequestorCategories";
import CampaignRequestorLineItem from "./CampaignRequestorLineItem";
import CampaignRequestorNotes from "./CampaignRequestorNotes";
import CampaignRequestorAttributionReporting from "./CampaignRequestorAttributionReporting";
import OverlaySpinner from "../../shared/OverlaySpinner";

import {
  campaignTypeMap,
  defaultIabCategory,
} from "../../../core/utils/constants/constants";
import googleMapsConfig from "../../../platform/shared/maps/googlemaps";
import { calculateImpressions } from "./CampaignRequestorUtil";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import CampaignRequestorReview from "./CampaignRequestorReview";

const CampaignRequestor = ({ google }) => {
  const [previousValues, setPreviousValues] = useState({});
  const [form] = Form.useForm(); // Create form instance)

  const {
    selectedAdvertiser,
    setSelectedAdvertiser,
    childrenOrg,
    fetchCampaignOrder,
    currentCampaignOrder,
    loadingCampaignOrder,
    currentUserOrg,
  } = useCampaignRequestorContext();
  const history = useHistory();

  const { id } = useParams(); // Extract the ID from the URL
  const query = new URLSearchParams(useLocation().search);
  const mode = query.get("mode") || "edit";

  const handleValuesChange = (changedValues, allValues) => {
    const { budget, cpm } = allValues;
    const updatedLineItems = [...allValues.campaigns];

    if ("advertiserId" in changedValues) {
      const selectedAdvertiser = childrenOrg.find(
        org => org.id === changedValues.advertiserId
      );

      if (selectedAdvertiser) {
        setSelectedAdvertiser(selectedAdvertiser);
      }
    }

    if ("budget" in changedValues) {
      form.setFieldsValue({
        impressions: calculateImpressions({
          budget,
          cpm: cpm || 7,
        }),
      });

      const campaigns = form.getFieldValue("campaigns");
      const updatedCampaigns = campaigns.map(campaign => {
        const budgetTotal = Number(
          Math.round((budget / campaigns.length) * 100) / 100
        );
        return {
          ...campaign,
          budgetTotal,
          impressions: calculateImpressions({
            budget: budgetTotal,
            cpm: Number(campaign.cpm),
          }),
        };
      });
      form.setFieldsValue({ campaigns: updatedCampaigns });
    }

    if ("startDate" in changedValues || "endDate" in changedValues) {
      // If campaigns length is 1, update the lineItem start and end date
      const campaigns = form.getFieldValue("campaigns");
      if (campaigns.length === 1) {
        form.setFieldsValue({
          campaigns: [
            {
              ...campaigns[0],
              startDate: allValues.startDate,
              endDate: allValues.endDate,
            },
          ],
        });
      }
    }

    if ("campaigns" in changedValues) {
      const lineItemIndex = Object.keys(changedValues.campaigns)[0];
      const changedField = Object.keys(
        changedValues.campaigns[lineItemIndex]
      )[0];

      const { cpm, budgetTotal, budgetPercentage } = updatedLineItems[
        lineItemIndex
      ];
      const impressions = calculateImpressions({ cpm, budget: budgetTotal });

      updatedLineItems[lineItemIndex].impressions = impressions;

      if (changedField === "cpm") {
        // Sum all the cpm value from campaigns
        const totalCpm = updatedLineItems.reduce(
          (acc, item) => acc + Number(item.cpm),
          0
        );
        form.setFieldsValue({
          cpm: totalCpm,
        });
      }

      if (changedField === "budgetTotal") {
        // Check if the lineItemIndex is the last index in the campaigns
        const isLastIndex =
          Number(lineItemIndex) === updatedLineItems.length - 1;
        const totalBudget = updatedLineItems.reduce((acc, item, index) => {
          if (index !== updatedLineItems.length - 1) {
            return acc + Number(item.budgetTotal);
          }
          return acc;
        }, 0);

        if (
          (!isLastIndex && Number(budgetTotal) < Number(budget)) ||
          (isLastIndex && totalBudget + Number(budgetTotal) <= Number(budget))
        ) {
          const previuosBudgetTotal = Number(
            previousValues.campaigns[lineItemIndex].budgetTotal
          );

          const budgetRemainder = previuosBudgetTotal - Number(budgetTotal);
          let budgetRemainderPerCampaign = 0;

          budgetRemainderPerCampaign = Number(
            budgetRemainder /
              (updatedLineItems.length - (Number(lineItemIndex) + 1))
          );

          if (
            budgetRemainder &&
            Number(lineItemIndex) !== updatedLineItems.length - 1
          ) {
            updatedLineItems.forEach((item, index) => {
              if (index > Number(lineItemIndex)) {
                updatedLineItems[index] = {
                  ...item,
                  budgetTotal:
                    Number(item.budgetTotal) + budgetRemainderPerCampaign,
                  budgetPercentage: Math.round(
                    ((Number(item.budgetTotal) + budgetRemainderPerCampaign) /
                      budget) *
                      100
                  ),
                  impressions: calculateImpressions({
                    cpm: Number(item.cpm),
                    budget:
                      Number(item.budgetTotal) + budgetRemainderPerCampaign,
                  }),
                };
              }
            });
          } else {
            if (!budgetRemainder) {
              message.warning(
                "The budget total cannot be greater than the campaign budget"
              );
            }
          }

          updatedLineItems[lineItemIndex] = {
            ...updatedLineItems[lineItemIndex],
            budgetTotal: Number(budgetTotal),
            budgetPercentage: Math.round((budgetTotal / budget) * 100),
            impressions: calculateImpressions({
              cpm,
              budget: Number(budgetTotal),
            }),
          };
        } else {
          updatedLineItems[lineItemIndex] = {
            ...updatedLineItems[lineItemIndex],
            budgetTotal: previousValues.campaigns[lineItemIndex].budgetTotal,
            budgetPercentage:
              previousValues.campaigns[lineItemIndex].budgetPercentage,
            impressions: calculateImpressions({
              cpm,
              budget: previousValues.campaigns[lineItemIndex].budgetTotal,
            }),
          };

          // Throw a warning if the budgetTotal is greater than the budget
          message.warning(
            "The budget total cannot be greater than the campaign budget"
          );
        }
      }

      if (changedField === "budgetPercentage") {
        // Check if the lineItemIndex is the last index in the campaigns
        const isLastIndex =
          Number(lineItemIndex) === updatedLineItems.length - 1;
        const totalBudgetPercentage = updatedLineItems.reduce(
          (acc, item, index) => {
            if (index !== updatedLineItems.length - 1) {
              return acc + Number(item.budgetPercentage);
            }
            return acc;
          },
          0
        );

        if (
          (!isLastIndex && Number(budgetPercentage) <= 100) ||
          (isLastIndex &&
            totalBudgetPercentage + Number(budgetPercentage) <= 100)
        ) {
          const previuosBudgetPercentage = Number(
            previousValues.campaigns[lineItemIndex].budgetPercentage
          );
          const budgetPercentageRemainder =
            previuosBudgetPercentage - Number(budgetPercentage);
          let budgetPercentageRemainderPerCampaign = 0;

          budgetPercentageRemainderPerCampaign = Number(
            budgetPercentageRemainder /
              (updatedLineItems.length - (Number(lineItemIndex) + 1))
          );

          if (
            budgetPercentageRemainder &&
            Number(lineItemIndex) !== updatedLineItems.length - 1
          ) {
            updatedLineItems.forEach((item, index) => {
              if (index > Number(lineItemIndex)) {
                updatedLineItems[index] = {
                  ...item,
                  budgetPercentage:
                    Number(item.budgetPercentage) +
                    budgetPercentageRemainderPerCampaign,
                  budgetTotal:
                    Number(item.budgetTotal) +
                    (budgetPercentageRemainderPerCampaign / 100) * budget,
                  impressions: calculateImpressions({
                    cpm: Number(item.cpm),
                    budget:
                      Number(item.budgetTotal) +
                      (budgetPercentageRemainderPerCampaign / 100) * budget,
                  }),
                };
              }
            });
          } else {
            if (!budgetPercentageRemainder) {
              message.warning(
                "The budget percentage cannot be greater than 100"
              );
            }
          }

          updatedLineItems[lineItemIndex] = {
            ...updatedLineItems[lineItemIndex],
            budgetPercentage: Number(budgetPercentage),
            budgetTotal: (Number(budgetPercentage) / 100) * budget,
            impressions: calculateImpressions({
              cpm,
              budget: (Number(budgetPercentage) / 100) * budget,
            }),
          };
        } else {
          updatedLineItems[lineItemIndex] = {
            ...updatedLineItems[lineItemIndex],
            budgetPercentage:
              previousValues.campaigns[lineItemIndex].budgetPercentage,
            budgetTotal:
              (previousValues.campaigns[lineItemIndex].budgetPercentage / 100) *
              budget,
            impressions: calculateImpressions({
              cpm,
              budget:
                (previousValues.campaigns[lineItemIndex].budgetPercentage /
                  100) *
                budget,
            }),
          };

          // Throw a warning if the budgetPercentage is greater than 100
          message.warning("The budget percentage cannot be greater than 100");
        }
      }

      form.setFieldsValue({ campaigns: updatedLineItems });
    }

    setPreviousValues({
      ...allValues,
      campaigns: updatedLineItems,
    });
  };

  useEffect(() => {
    if (id) {
      fetchCampaignOrder({
        variables: {
          where: {
            id,
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitialValues = campaignOrder => {
    const {
      name,
      description,
      startDate,
      endDate,
      type,
      advertiser,
      budget,
      cpm,
      isRegulated,
      category,
      attributionTargets,
      geoTargets,
      measureWalkInTraffic,
      advertiserType,
      transactionTarget,
      transactionsConfig,
      signUpConfig,
      awarenessCampaign,
      notes,
      campaigns,
    } = campaignOrder;

    const values = {
      name: name || "",
      description,
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      type: campaignTypeMap[type],
      advertiserId: advertiser ? advertiser.id : currentUserOrg.id,
      budget: budget || 0,
      cpm: cpm || 7,
      isRegulated,
      category,
      attributionTargets: attributionTargets || [],
      geoTargets: geoTargets || [],
      measureWalkInTraffic,
      advertiserType,
      transactionTarget,
      transactionsConfig: transactionsConfig || null,
      signUpConfig: signUpConfig || null,
      awarenessCampaign,
      notes: notes || null,
      campaigns:
        campaigns && campaigns.length
          ? campaigns.map(campaign => ({
              ...campaign,
              startDate: moment(campaign.startDate),
              endDate: moment(campaign.endDate),
              impressions: calculateImpressions({
                budget: campaign.budgetTotal,
                cpm: campaign.cpm,
              }),
              budgetPercentage:
                campaign.budgetTotal && budget
                  ? Math.round((campaign.budgetTotal / budget) * 100)
                  : 100,
              targetingCountries:
                campaign.targetingCountries &&
                campaign.targetingCountries.length
                  ? campaign.targetingCountries[0].code
                  : null,
              adGroup: campaign.adGroup
                ? {
                    ...campaign.adGroup,
                    adGroupCreatives: campaign.adGroup.adGroupCreatives.map(
                      creative => ({
                        ...creative,
                        startDate: creative.startDate
                          ? moment(creative.startDate)
                          : null,
                        endDate: creative.endDate
                          ? moment(creative.endDate)
                          : null,
                      })
                    ),
                  }
                : null,
            }))
          : [
              {
                name: "Line Item 1",
                startDate: moment(),
                endDate: moment().add(2, "days"),
                budgetTotal: 0,
                budgetPercentage: 100,
                cpm: 7,
                regionGroups: [],
                targetingGroups: [],
                targetingType: null,
                targetingRegions: [],
                targetingLocations: [],
                targetingCountries: null,
                audienceType: null,
                firstPartyDataAudiences: [],
                adGroup: null,
              },
            ],
      impressions: calculateImpressions({ budget, cpm }),
    };

    form.setFieldsValue(values);
    if (campaignOrder.id) {
      history.push(`/campaigns/requestor/${campaignOrder.id}?mode=edit`);
    }
    setTimeout(async () => {
      setPreviousValues(values);
    }, 500);
  };

  useEffect(() => {
    if (currentCampaignOrder) {
      handleInitialValues(currentCampaignOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  useEffect(() => {
    if (selectedAdvertiser && childrenOrg && childrenOrg.length) {
      const matchedAdvertiser = childrenOrg.find(
        org => org.id === selectedAdvertiser.id
      );

      if (matchedAdvertiser) {
        form.setFieldsValue({
          cpm: matchedAdvertiser.cpm,
        });
        const campaigns = form.getFieldValue("campaigns");
        if (campaigns) {
          // Divide update campaigns cpm by the number of campaigns
          const cpm = matchedAdvertiser.cpm / campaigns.length;
          form.setFieldsValue({
            campaigns: campaigns.map(campaign => ({
              ...campaign,
              cpm,
            })),
          });
        }

        setSelectedAdvertiser(matchedAdvertiser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdvertiser, childrenOrg]);

  if (loadingCampaignOrder) {
    return <OverlaySpinner />;
  }

  return (
    <div style={{ margin: 20 }}>
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        initialValues={{
          name: "",
          description: "",
          startDate: moment(),
          endDate: moment().add(2, "days"),
          type: 1,
          advertiserId: selectedAdvertiser ? selectedAdvertiser.id : null,
          budget: 0,
          cpm: 7,
          isRegulated: true,
          category: defaultIabCategory.code,
          attributionTargets: [],
          geoTargets: [],
          measureWalkInTraffic: false,
          advertiserType: null,
          transactionTarget: null,
          transactionsConfig: null,
          signUpConfig: null,
          awarenessCampaign: false,
          notes: null,
          campaigns: [
            {
              name: "Line Item 1",
              startDate: moment(),
              endDate: moment().add(2, "days"),
              budgetTotal: 0,
              budgetPercentage: 100,
              cpm: 7,
              regionGroups: [],
              targetingGroups: [],
              targetingType: null,
              targetingRegions: [],
              targetingLocations: [],
              targetingCountries: null,
              audienceType: null,
              firstPartyDataAudiences: [],
              adGroup: null,
            },
          ],
        }}
      >
        <Row gutter={20}>
          <Col span={6}>
            <CampaignRequestorSummary
              form={form}
              previousValues={previousValues}
              mode={mode}
              handleInitialValues={handleInitialValues}
            />
          </Col>
          <Col span={18}>
            {mode === "edit" && (
              <Row>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ width: "100%" }}
                >
                  <CampaignRequestorDetails />
                  <CampaignRequestorBudget form={form} />
                  <CampaignRequestorCategories
                    form={form}
                    setPreviousValues={setPreviousValues}
                  />
                  <CampaignRequestorAttributionReporting
                    form={form}
                    google={google}
                    setPreviousValues={setPreviousValues}
                  />
                  <Form.List name="campaigns">
                    {(fields, { add, remove }) => (
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: "100%" }}
                      >
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => {
                            return (
                              <CampaignRequestorLineItem
                                key={key}
                                form={form}
                                restField={restField}
                                name={name}
                                fieldKey={fieldKey}
                                index={index}
                                setPreviousValues={setPreviousValues}
                                add={add}
                                remove={remove}
                              />
                            );
                          }
                        )}
                      </Space>
                    )}
                  </Form.List>
                  <CampaignRequestorNotes />
                </Space>
              </Row>
            )}
            {mode === "review" && (
              <CampaignRequestorReview form={form} editable={true} />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GoogleApiWrapper(googleMapsConfig)(CampaignRequestor);
