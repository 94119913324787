import gql from "graphql-tag";
const CREATE_CITY_LIST = gql`
  mutation createRegionGroup(
    $name: String!
    $regions: [String!]
    $orgID: ID!
    $isPresetList: Boolean
  ) {
    createRegionGroup(
      data: {
        name: $name
        regions: { set: $regions }
        orgs: { connect: [{ id: $orgID }] }
        isPresetList: $isPresetList
      }
    ) {
      name
      regions
    }
  }
`;

export default CREATE_CITY_LIST;
