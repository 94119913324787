import React, { useMemo, useState } from "react";
import { Button, Checkbox, Popover, Space } from "antd";
import { ButtonText } from "../../../../shared/globalStyling/styledText";

const styles = {
  buttonL: {
    fontSize: "14px",
    width: "100%",
    height: "32px",
  },
};

const RollUpSelector = ({
  setProductOn,
  loading,
  productOn,
  loadProgress,
  hasFeaturePaidSearch,
  hasFeatureSEO,
}) => {
  const [itemToggle, setItemToggle] = useState(productOn);

  const handleItemToggle = value => {
    setItemToggle(value);
  };

  const disabled = useMemo(() => {
    if (loadProgress > 0) {
      return true;
    }
    if (Object.values(itemToggle).filter(v => v === true).length === 0) {
      return true;
    }
    return false;
  }, [loadProgress, itemToggle]);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <Space direction="vertical">
          <Checkbox
            onClick={() => {
              handleItemToggle({ ...itemToggle, display: !itemToggle.display });
            }}
            checked={itemToggle.display}
          >
            Display
          </Checkbox>

          {hasFeaturePaidSearch ? (
            <Checkbox
              onClick={() => {
                handleItemToggle({ ...itemToggle, sem: !itemToggle.sem });
              }}
              checked={itemToggle.sem}
            >
              SEM
            </Checkbox>
          ) : (
            <></>
          )}
          {hasFeatureSEO ? (
            <Checkbox
              onClick={() => {
                handleItemToggle({ ...itemToggle, seo: !itemToggle.seo });
              }}
              checked={itemToggle.seo}
            >
              SEO
            </Checkbox>
          ) : (
            <></>
          )}

          <Button
            disabled={disabled}
            loading={loadProgress > 0}
            type="primary"
            onClick={() => setProductOn({ ...itemToggle })}
          >
            Apply
          </Button>
        </Space>
      }
    >
      <Button style={styles.buttonL} type="default" loading={loading}>
        <ButtonText text="Roll-Up" />
      </Button>
    </Popover>
  );
};

export default RollUpSelector;
