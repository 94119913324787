import React from "react";
import { Slider, Row } from "antd";

const style = {
  text: {
    color: "#E5E5E5",
    fontSize: "12px",
  },
};

const Label = ({ amount, color }) => {
  return (
    <div>
      <h4 style={{ color: color }}>{amount}%</h4>
    </div>
  );
};

const ManualSpendingSlider = ({ amount, onChange, disabled }) => {
  const marks = {
    0: {
      style: {
        color: "#c8daf4",
      },
      label: (
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ position: "absolute", left: 0, width: "180px" }}
        >
          <Label amount={amount} color="#c8daf4" />
          <div style={style.text}>Finding New Customers</div>
        </Row>
      ),
    },
    100: {
      style: {
        color: "#f4c3c8",
      },
      label: (
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ position: "absolute", right: 0, width: "180px" }}
        >
          <div style={style.text}>Bringing Back Customers</div>
          <Label amount={100 - amount} color="#f4c3c8" />
        </Row>
      ),
    },
  };

  return (
    <Slider
      disabled={disabled || false}
      onChange={value => onChange(value, 100 - value)}
      value={amount}
      marks={marks}
      tooltipVisible={false}
      style={{ position: "relative" }}
      min={1}
      max={99}
    />
  );
};

export default ManualSpendingSlider;
