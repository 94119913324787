import React from "react";
import { Col, Divider, Row, Typography } from "antd";
import NewDetailBarCard from "./NewDetailBarCard";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../shared/globalStyling/styledText";

const NewDetailBar = ({
  title,
  total,
  name = "",
  dataToDisplay = { visible: false, values: [] },
}) => {
  if (!dataToDisplay.visible) return null;

  const DetailBarCards = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginRight: "-16px",
          marginBottom: "-16px",
        }}
      >
        {dataToDisplay.values.map((item, index) => (
          <div
            key={`${name}-${index}`}
            style={{ flexShrink: 0, marginRight: "16px", marginBottom: "16px" }}
          >
            <NewDetailBarCard {...item} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      {name && (
        <Typography.Title level={4} style={{ marginBottom: "16px" }}>
          {name}
        </Typography.Title>
      )}
      <Row align="stretch" style={{ backgroundColor: "white" }}>
        <Col span={3} style={{ display: "flex", padding: "16px 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 16px",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <KPITitleText text={title} style={{ marginBottom: "10px" }} />
            <KpiSubText text={total} />
          </div>
        </Col>
        <Col style={{ display: "flex", alignItems: "stretch" }}>
          <Divider type="vertical" style={{ height: "auto", margin: "0" }} />
        </Col>
        <Col span={20} style={{ padding: "16px 0 16px 16px" }}>
          <DetailBarCards />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NewDetailBar;
