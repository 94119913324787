import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SeoMainLoader from "../../../../core/components/seoRefactored/seoMain/SeoMainLoader";
import SeoDetailsLoader from "../../../../core/components/seoRefactored/seoDetails/SeoDetailsLoader";
//Global Loader for SEO
import SeoLoader from "../../../../core/components/seoRefactored/SeoLoader";
import SeoController from "../../../../core/components/seoRefactored/SeoController";
import SeoMainController from "../../../../core/components/seoRefactored/seoMain/SeoMainController";
import SeoMainView from "../main/SeoMainView";
import SeoDetailsController from "../../../../core/components/seoRefactored/seoDetails/SeoDetailsController";
import SeoDetailsView from "../details/SeoDetailsView";
import { SEOLinkMaintenanceProvider } from "../../../../core/components/seoRefactored/seoDetails/context/SEOLinkMaintenanceContext";
import LinkMaintenanceView from "../details/tabs/LinkMaintenanceView";

const SeoRefactoredRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <SeoLoader {...props}>
            <SeoController>
              <SeoMainLoader>
                <SeoMainController>
                  <SeoMainView />
                </SeoMainController>
              </SeoMainLoader>
            </SeoController>
          </SeoLoader>
        )}
      />
      <Route
        exact
        path={`${path}/details/:id?`}
        render={() => (
          <SeoLoader {...props}>
            <SeoController>
              <SeoDetailsLoader>
                <SeoDetailsController>
                  <SeoDetailsView />
                </SeoDetailsController>
              </SeoDetailsLoader>
            </SeoController>
          </SeoLoader>
        )}
      />
      <Route
        exact
        path={`${path}/link_maintenance/:id?`}
        render={() => (
          <SEOLinkMaintenanceProvider>
            <LinkMaintenanceView />
          </SEOLinkMaintenanceProvider>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default SeoRefactoredRoutes;
