import gql from "graphql-tag";

const AUDIENCE_COMMERCE = gql`
  query audienceCommerce($id: ID) {
    audienceCommerce(where: { id: $id }) {
      id
      totalTransactions
      totalItems
      revenue
      byDate {
        transactions
        items
        revenue
        xAxis
        date
      }
    }
  }
`;

export default AUDIENCE_COMMERCE;
