import React, { useContext } from "react";
import { Dropdown, Menu } from "antd";
import { GlobalContext } from "../../../../../core/context/GlobalContext";
import { isEmpty, cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import JSZip from "jszip";
// Global Styles
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { fillNamesWalkInByLineItemCreativeDates } from "../../../../../core/utils/campaigns";
import {
  prepareByDateDisplayExport,
  prepareByDateSummaryExport,
  prepareByDayDisplayExport,
  prepareByDeviceTypeDisplayExport,
  prepareByCreativeDisplayExport,
  prepareByPublisherDisplayExport,
  prepareTransactionsWithEventsExport,
  prepareByDateObservationsExport,
  exportDropdownKeyToText,
  prepareSignUpsWithImpressions,
  prepareInstallsWithImpressions,
  preparefirstTransactionsWithImpressions,
  prepareOnspotWalkInReports,
} from "../utils";
import { DownloadOutlined } from "@ant-design/icons";

const processJSONData = data => {
  if (!Array.isArray(data)) return data;

  return data.map(row => {
    const processedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      if (typeof value === "string" && value.length > 32000) {
        processedRow[key] = value.substring(0, 32000) + "... [truncated]";
      } else {
        processedRow[key] = value;
      }
    });
    return processedRow;
  });
};

const processCSVData = csvString => {
  const rows = csvString.trim().split("\n");
  return rows.map(row => {
    const columns = row.split(",");
    return columns.map(cell => {
      if (cell && cell.length > 32000) {
        return cell.substring(0, 32000) + "... [truncated]";
      }
      return cell;
    });
  });
};

const startDownload = (
  filteredExportData,
  tabExportKeys,
  hasData,
  getCampaignSummary,
  hasInsights,
  campaignName,
  chartData,
  campaigns,
  byTransactionsRangeDate,
  dataAttributionWindow,
  byCampaignRangeDate,
  bySummaryRangeDate,
  bySignUpRangeDate,
  key,
  data,
  rangeType
) => {
  try {
    const zip = new JSZip();
    const folder = zip.folder(`${campaignName.replace(/\//g, " ")}-${key}`);
    let fileData = data;

    Array.isArray(fileData) &&
      fileData.map(row => {
        delete row.cost;
        delete row.totalCost;
        delete row.orgs;
        return row;
      });

    let dupFileData = cloneDeep(fileData);
    let wb, ws;

    if (key === "walkinByLineItemCreativeDate") {
      dupFileData = fillNamesWalkInByLineItemCreativeDates(
        chartData.creatives,
        campaigns,
        chartData.walkinByLineItemCreativeDate
      );
    } else if (key === "transactionsWithEvents") {
      dupFileData = prepareTransactionsWithEventsExport(
        dupFileData,
        byTransactionsRangeDate,
        dataAttributionWindow
      );
    } else if (key === "byDate") {
      dupFileData = prepareByDateDisplayExport(
        dupFileData,
        byCampaignRangeDate
      );
    } else if (key === "observationsByGeoTarget") {
      dupFileData = dupFileData.map(row => {
        delete row.ONSPOT_COUNT;
        delete row.UNACAST_COUNT;
        delete row.KOCHAVA_COUNT;
        delete row.BEACONS_COUNT;
        delete row.ONSPOT_COUNT;
        return row;
      });
    } else if (key === "completeAttributionSummary") {
      dupFileData = prepareByDateSummaryExport(
        dupFileData,
        bySummaryRangeDate,
        rangeType
      );
    } else if (key === "byDay") {
      dupFileData = prepareByDayDisplayExport(dupFileData);
    } else if (key === "deviceType") {
      dupFileData = prepareByDeviceTypeDisplayExport(dupFileData);
    } else if (key === "creatives") {
      dupFileData = prepareByCreativeDisplayExport(dupFileData);
    } else if (key === "publishers") {
      dupFileData = prepareByPublisherDisplayExport(dupFileData);
    } else if (key === "observationsByDate") {
      dupFileData = prepareByDateObservationsExport(dupFileData);
    } else if (key === "signUpsWithImpressions") {
      dupFileData = prepareSignUpsWithImpressions(
        dupFileData,
        bySignUpRangeDate
      );
    } else if (key === "installsWithImpressions") {
      dupFileData = prepareInstallsWithImpressions(dupFileData);
    } else if (key === "firstTransactionsWithImpressions") {
      dupFileData = preparefirstTransactionsWithImpressions(dupFileData);
    } else if (key === "onspotWalkInReports") {
      dupFileData = prepareOnspotWalkInReports(dupFileData);
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    if (key === "onspotWalkInReports") {
      // Handle CSV data
      const processedData = processCSVData(dupFileData);
      ws = XLSX.utils.aoa_to_sheet(processedData);
      wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    } else {
      // Handle JSON data
      const processedData = processJSONData(dupFileData);
      ws = XLSX.utils.json_to_sheet(processedData);
      wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    }

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });

    folder.file(
      `${campaignName} - ${exportDropdownKeyToText(key)}.xlsx`,
      excelData
    );

    zip.generateAsync({ type: "blob" }).then(
      blob => {
        saveAs(
          blob,
          `${campaignName.replace(/\//g, " ")}-${exportDropdownKeyToText(key)}`
        );
        return true;
      },
      function(e) {
        console.error("Error generating zip:", e);
        return false;
      }
    );
  } catch (E) {
    console.error("Export error:", E);
    throw new Error(`Failed to export data: ${E.message}`);
  }
};

const DropdownExcelButton = ({
  campaignName,
  campaignId,
  campaignType,
  chartsLoading,
  chartData,
  campaigns,
  isAdmin,
  byCampaignRangeDate,
  byTransactionsRangeDate,
  bySummaryRangeDate,
  bySignUpRangeDate,
  dataAttributionWindow,
  orgConfig,
  hasInsights,
  tabKey,
  rangeType,
  amplify,
}) => {
  let hasData = false;
  const context = useContext(GlobalContext);
  const getCampaignSummary = orgConfig && orgConfig.campaignSummary;

  const tabKeyExportMap = {
    transactions: ["transactionsWithEvents"],
    overview: ["byDate", "deviceType", "publishers", "creatives", "byDay"],
    summary: ["completeAttributionSummary", "lineItems"],
    signups: [
      "signUpsWithImpressions",
      "installsWithImpressions",
      "firstTransactionsWithImpressions",
    ],
    attribution: ["observationsByDate", "onspotWalkInReports"],
  };

  const tabExportKeys = tabKeyExportMap[tabKey] || [];
  const filteredExportData = chartData
    ? Object.keys(chartData)
        .filter(key => tabExportKeys.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: chartData[key],
          };
        }, {})
    : {};

  const checkDisableExport = () => {
    let disabled = false;
    if (Object.keys(filteredExportData).length < 1) {
      disabled = true;
    } else {
      Object.values(filteredExportData).forEach(i => {
        if (
          (Array.isArray(i) && i.length > 0) ||
          (typeof i === "object" && Object.keys(i).length > 0)
        ) {
          disabled = false;
          return disabled;
        } else {
          disabled = true;
        }
      });
    }
    return disabled;
  };

  const menu = (
    <Menu>
      {filteredExportData &&
        Object.entries(filteredExportData)
          .filter(i => i[0] !== "displayReports")
          .reduce((acc, [key, data], index) => {
            if (tabExportKeys.indexOf(key) < 0) {
              return acc;
            }
            if (
              (Array.isArray(data) && !isEmpty(data)) ||
              (typeof data === "object" && Object.keys(data).length > 0)
            ) {
              hasData = true;
              if (
                (key === "completeAttributionSummary" &&
                  getCampaignSummary !== true) ||
                key === "lineItems"
              ) {
                return acc;
              }
              if ((key !== "publishers" && !hasInsights) || hasInsights) {
                acc.push(
                  <Menu.Item key={`setting:${index + 1}`}>
                    <div
                      onClick={async () => {
                        try {
                          await startDownload(
                            filteredExportData,
                            tabExportKeys,
                            hasData,
                            getCampaignSummary,
                            hasInsights,
                            campaignName,
                            chartData,
                            campaigns,
                            byTransactionsRangeDate,
                            dataAttributionWindow,
                            byCampaignRangeDate,
                            bySummaryRangeDate,
                            bySignUpRangeDate,
                            key,
                            data,
                            rangeType
                          );
                        } catch (error) {
                          console.error("Failed to export:", error);
                        }
                      }}
                    >
                      {exportDropdownKeyToText(key)}
                    </div>
                  </Menu.Item>
                );
              }
            }
            return acc;
          }, [])}
      {isAdmin &&
        campaignType === "DISPLAY" &&
        !isEmpty(chartData.walkinByLineItem) && (
          // TODO: Make this work for all campaign types
          <Menu.Item key={"setting:999"}>
            <div
              onClick={() =>
                context.requestDisplayReportFull(
                  {
                    variables: { campaignOrderId: campaignId },
                  },
                  `${campaignName}-Admin_Data_Complete-${new Date().toISOString()}`
                )
              }
            >
              Admin Data Complete
            </div>
          </Menu.Item>
        )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      disabled={checkDisableExport()}
      trigger={["click"]}
      placement="bottomCenter"
    >
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        disabled={!hasData}
        loading={chartsLoading}
      >
        <ButtonText text={"Export Excel"} />
      </Button>
    </Dropdown>
  );
};

DropdownExcelButton.propTypes = {
  attributionReportId: PropTypes.string,
  displayReportId: PropTypes.string,
  displayReport: PropTypes.object,
  attributionReport: PropTypes.object,
  campaignName: PropTypes.string.isRequired,
};

export default DropdownExcelButton;
