import gql from "graphql-tag";

const GET_ALL_CAMPAIGNORDERS = gql`
  query campaignOrders($orgID: ID!) {
    campaignOrders(
      where: {
        OR: [
          {
            status_not_in: [DRAFT, LIVE]
            orgs_some: {
              OR: [{ id: $orgID }, { parentOrg_some: { id: $orgID } }]
            }
          }
          { status: DRAFT, orgs_some: { id: $orgID } }
        ]
      }
    ) {
      id
      type
      status
      name
      geoTargets {
        id
        name
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      attributionTargets {
        id
        name
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
            hasNotes
            notes
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        banners {
          id
          adId
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
        }
      }
      orgs {
        id
        name
        phone
        roles {
          users {
            id
            name
          }
          isMaster
        }
        logo {
          id
          key
        }
      }
      brands {
        id
        name
      }
      language
      category
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      isRegulated
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        demandSidePlatform
      }
      createdAt
    }
  }
`;

export default GET_ALL_CAMPAIGNORDERS;
