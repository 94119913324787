import gql from "graphql-tag";

const GET_EDITORIALS = gql`
  query editorials($where: EditorialWhereInput) {
    editorials(where: $where) {
      id
      name
    }
  }
`;

export default GET_EDITORIALS;
