import React from "react";
import { Switch, Route } from "react-router-dom";
import KnowledgeBaseView from "../KnowledgeBaseView";
const KnowledgeBaseRoutes = props => {
  const { path } = props.match;
  console.log("path", path);
  return (
    <div>
      <Switch>
        <Route path={`${path}`} exact render={() => <KnowledgeBaseView />} />
      </Switch>
    </div>
  );
};

export default KnowledgeBaseRoutes;
