import { Row, Col, Card, Typography } from "antd";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber2SigFig,
} from "../../../../../../core/utils/campaigns";
import React from "react";
import PctDiff from "../../../RollUp/components/PctDiff";

const ROIView = ({ loading, data, diffPcts, currencyFormat }) => {
  return (
    <React.Fragment>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12} xxl={6}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>Revenue</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data &&
                data.revenue &&
                formatCurrency2SigFig(data.revenue, currencyFormat)}
            </Typography>
            {diffPcts && (
              <PctDiff
                value={formatCurrency2SigFig(diffPcts.revenue, currencyFormat)}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12} xxl={6}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>Online Orders</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data && data.transactions && formatNumber(data.transactions)}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.transactions} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} xxl={6}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>Average Order</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data &&
                data.avgOrderValue &&
                formatCurrency2SigFig(data.avgOrderValue, currencyFormat)}
            </Typography>
            {diffPcts && (
              <PctDiff
                value={formatCurrency2SigFig(
                  diffPcts.avgOrderValue,
                  currencyFormat
                )}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} sm={12} xxl={6}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>
              Ecom Conversion Rate
            </Typography>
            <Typography style={{ fontSize: "28px" }}>
              {`${data &&
                data.transactionsPerSession &&
                formatNumber2SigFig(data.ecomConversionRate)}%`}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.ecomConversionRate} />}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ROIView;
