import { Button, Card, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { CopyBlock } from "react-code-blocks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SignUpsInstructionPDF from "./pdf/SignUpsInstructionPDF";

const { Title, Paragraph } = Typography;
const SignUpsIntroductionsModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <React.Fragment>
      <Modal
        title="INSTRUCTIONS - MEASURING SIGN UP EVENTS"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={"40%"}
        footer={
          <PDFDownloadLink
            document={<SignUpsInstructionPDF />}
            fileName="Sign Up Instruction.pdf"
          >
            {({ blob, url, loading, error }) => (
              <Button type="primary" shape="round" loading={loading}>
                Download PDF
              </Button>
            )}
          </PDFDownloadLink>
        }
      >
        <Space direction="vertical">
          <Title level={5}>Tracking Sign-Ups</Title>
          <Paragraph>
            Execute this javascript on a Sign Up event. The static values above
            are supposed to represent the variables that pertain to the context
            of data you would pass in programmatically from your site. Swap out
            the static values with your own site’s form properties during a Sign
            Up event. The fastest way to start measuring Sign Ups is to place
            this snippet of javascript yourself, however our Integrations Team
            is here to provide assistance if needed.
          </Paragraph>
          <Card>
            <CopyBlock
              text={`<script>
  window.tracker("trackSelfDescribingEvent", {
    schema: "iglu:com.mediajel.events/sign_up/jsonschema/1-0-2",
    data: {
      uuid: “1234”, // user identifier - string,
      firstName: “john”, // user first name - string,
      lastName: “evans”, // user last name - string,
      emailAddress: “evans@gmail.com”, // email address - string,
      hashedEmailAddress: “evans@gmail.com”, // email address - string,
      phoneNumber: “+13056863198” // optional, phone number - string,
    },
  });
</script>
`}
              language="javascript"
              wrapLongLines
              theme="dracula"
            />
          </Card>
        </Space>
      </Modal>
      <Button
        shape="round"
        type="primary"
        onClick={() => setIsModalVisible(true)}
      >
        View Instructions
      </Button>
    </React.Fragment>
  );
};

export default SignUpsIntroductionsModal;
