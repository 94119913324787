import gql from "graphql-tag";

const GET_CARTS = gql`
  query getCarts {
    getCarts {
      label
      value
      description
      eventsTracked {
        label
        value
      }
    }
  }
`;

export default GET_CARTS;
