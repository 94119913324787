import React from "react";
import { graphql, useQuery, useMutation } from "react-apollo";
import { Redirect, withRouter } from "react-router-dom";
import compose from "lodash/flowRight";
import { isEmpty } from "lodash";
//queries
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import AUDIENCE from "../../../GraphQl/Queries/AUDIENCE";
import REFRESH_AUDIENCE_OBSERVATIONS from "../../../GraphQl/Mutations/REFRESH_AUDIENCE_OBSERVATIONS";
import UPDATE_AUDIENCE from "../../../GraphQl/Mutations/UPDATE_AUDIENCE";
//local
import Controller from "./AudienceDetailsController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { featurePermissions } from "../../../utils/general/user";
import { determineUserAccess } from "../../../permissions/userLevelAccess";

const Loader = props => {
  const { currentUser } = props;
  const org = currentUser.role.org.name;
  const getPermission = currentUser && currentUser.permission;

  // user permission
  const orgConfig = determineUserAccess(getPermission);

  const {
    tableConfig = 10,
    match: {
      params: { id },
    },
  } = props;

  const { READ, WRITE } = featurePermissions(
    props.currentUser.role,
    "AUDIENCES"
  );

  const { loading, data } = useQuery(AUDIENCE, {
    variables: { id },
    skip: !id,
  });

  const [refreshAudienceObservations] = useMutation(
    REFRESH_AUDIENCE_OBSERVATIONS,
    {
      update(cache, { data: { refreshAudienceObservations } }) {
        cache.writeQuery({
          query: AUDIENCE,
          variables: { id },
          data: { audience: refreshAudienceObservations },
          fetchPolicy: "no-cache",
        });
      },
    }
  );

  if (!id) return <Redirect to="/audiences/new/1/updatedAt_DESC/{}" />;

  if (loading) {
    return <OverlaySpinner />;
  }

  if (isEmpty(data.audience))
    return <Redirect to="/audiences/new/1/updatedAt_DESC/{}" />;

  return (
    <Controller
      {...props}
      org={org}
      orgConfig={orgConfig}
      audience={data.audience}
      tableConfig={tableConfig}
      refreshAudienceObservations={refreshAudienceObservations}
      READ={READ}
      WRITE={WRITE}
    />
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser } }) => {
      return { currentUser };
    },
  }),
  graphql(UPDATE_AUDIENCE, {
    name: "updateAudience",
  })
)(withRouter(Loader));
