import React, { useMemo, useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Card, Empty, Space, Tabs, Tooltip, Modal } from "antd";
import {
  ToolOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  AdminModal,
  CampaignDetailBar,
  DropdownCSVButton,
  DropdownExcelButton,
  DynamicDropdownCSVButton,
  ProviderTabs,
} from "./components";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import {
  AttributionTab,
  OverviewTab,
  TransactionsTab,
  SignUpsTab,
  BasketItemsTab,
} from "./tabs";
import Button from "../../../shared/globalStyling/Button";
import StatusTag from "../shared/StatusTag";
import { isEmpty } from "lodash";
import AmplifyView from "./amplify/AmplifyView";
import { isDemoWalkins } from "../../../../core/utils/campaigns";
import CampaignStatus from "../../CampaignOrderQueue/components/CampaignStatus";

// Global Styles
import { TabText } from "../../../shared/globalStyling/styledText";
import SummaryTab from "./tabs/summary/SummaryTab";
import { orgLevelCustomization } from "../../../../core/permissions/orgLevelCustomization";
import { HasAccess, usePermify } from "@permify/react-role";

import ResultPage from "../../../shared/ResultPage";
import PageViewTab from "./tabs/pageView";

const { TabPane } = Tabs;
const { confirm } = Modal;

const colStyle = {
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

const CampaignDetails = ({
  updateUrlWithProvider,
  data,
  selectedProvider,
  attributionReportId,
  currentUser,
  campaignOrder,
  selectedTab,
  history,
  location,
  chartData,
  setChartData,
  chartsLoading,
  modalOpen,
  toggleModal,
  orgConfig,
  dataConfig,
  updateCampaignOrder,
  providersVisible,
  providerIndex,
  setProviderIndex,
  isValidUrl,
  onDeleteCampaignOrder,
  getIsProcessor,
  getIsAdmin,
  getMessage,
  setGetListMessage,
  setGetCurrentConversationId,
  createMessageRemarks,
  message,
  getOrgName,
  loadingCreateMessage,
  setMessage,
  closeEditCampaign,
  signUpReportId,
  displayReportId,
  transactionReportId,
  transactionCSVReportId,
  basketItemsReportId,
  startObservationJob,
  isLoadingRefreshObservation,
  setIsLoadingRefreshObservation,
  selectedSegmentsDimension,
  setSegmentsDimension,
  reprocessCurrentCampaign,
  scrapeDSPCampaigns,
  pauseLiquidMCampaign,
  startLiquidMCampaign,
  //useDynamicExport,
  exportButtonLoading,
  setExportButtonLoading,
  //setUseDynamicExport,
  exportButtonDisable,
  setExportButtonDisable,
  exportButtonMenuItems,
  setExportButtonMenuItems,
  updateCampaignOrderStatus,
  embeddableConfig,
  pageViewReportsKey,
  reprocessStatus,
  pauseOrStartLiquidMCampaign,
  loadingPauseOrStartLiquidMCampaign,
}) => {
  //State controllers for campaigns range date selection Campaigns
  const [byCampaignRangeDate, setByCampaignRangeDate] = useState([]);
  const [dataCampaignRangeDate, setDataCampaignRangeDate] = useState([]);

  const [byWalkinRangeDate, setByWalkinRangeDate] = useState([]);
  const [dataWalkinRangeDate, setDataWalkinRangeDate] = useState([]);

  const [byWalkinRangeDateHead, setByWalkinRangeDateHead] = useState([]);
  const [dataWalkinRangeDateHead, setDataWalkinRangeDateHead] = useState([]);

  const [bySummaryRangeDate, setBySummaryRangeDate] = useState([]);
  const [dataSummaryRangeDate, setDataSummaryRangeDate] = useState([]);

  const [rangeType, setRangeType] = useState("Custom");

  const [bySignUpRangeDate, setSignUpRangeDate] = useState([]);
  const [dataSignUpRangeDate, setDataSignUpRangeDate] = useState([]);

  const [bySignUpsAttributionWindow, setbySignUpsAttributionWindow] = useState(
    []
  );
  const [dataSignUpsDate, setdataSignUpsDate] = useState([]);

  const [currentStatus, setCurrentStatus] = useState("0");

  // State controllers for transactions range date selection
  const [byTransactionsRangeDate, setByTransactionsRangeDate] = useState([]);
  const [dataTransactionsRangeDate, setDataTransactionsRangeDate] = useState(
    []
  );
  const [dataAttributionWindow, setDataAttributionWindow] = useState([]);
  const [dateTrue, setDateTrue] = useState(false);
  const [isBetaEnabled, setIsBetaEnabled] = useState(false);

  const [useDynamicExport, setUseDynamicExport] = useState(false);
  const [hasTransactionConfig, setHasTransactionConfig] = useState(false);
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  const { id, name, startDate, endDate, type } = data;
  // Set Document Title Dynamically
  let searchParams = new URLSearchParams(location.search);
  const selected = searchParams.get("tab");
  document.title = `${name} - ${
    selected ? (selected === "overview" ? "display" : selected) : "summary"
  }`;

  const { isAuthorized } = usePermify();

  useEffect(() => {
    const fetchData = async () => {
      if (await isAuthorized(["isBeta"])) {
        setIsBetaEnabled(true);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cart =
      campaignOrder &&
      campaignOrder.transactionsConfig &&
      campaignOrder.transactionsConfig.cart;

    if (cart) {
      setHasTransactionConfig(cart !== null && cart !== "NOCART");
    }
  }, [campaignOrder]);

  useEffect(() => {
    const TabsWithDynamicExport = ["transactions", "pageView"];
    if (!TabsWithDynamicExport.includes(selected)) {
      setUseDynamicExport(false);
    } else {
      if (
        selected === "transactions" &&
        ((data &&
          data.aggregateData &&
          data.aggregateData.overallData &&
          data.aggregateData.overallData.aggEvents &&
          data.aggregateData.overallData.aggEvents.transactionsTotalCount >=
            5000) ||
          false)
      )
        setUseDynamicExport(true);
      else setUseDynamicExport(true);
    }
  }, [data, selected, selectedTab]);

  const providersTab = useMemo(() => {
    return chartData && chartData.displayReports;
  }, [chartData]);

  const checkStatus = data && data.status;
  const checkIsEditCampaign = data && data.isEditCampaign;
  const description = data && data.description;
  const getDescription = description ? description : "";

  if (isEmpty(data)) return <Redirect to="/campaigns" />;

  const {
    isTrafficker,
    hasInsights,
    isSelfService,
    isAdmin,
    exportData,
    isDemo,
    providersVisible: isProvidersVisible,
    priceVisible,
    campaignSummary,
  } = orgConfig;

  const { amplify, sitesAppsTransactions } = currentUser.permission;

  if (isDemo && !isEmpty(chartData)) {
    // TODO: This should use setChartData
    chartData = isDemoWalkins(chartData);
  }

  const {
    transactionTarget,
    campaigns,
    attributionTargets,
    signUpCampaign,
    pageViewConfig,
  } = campaignOrder;

  const idProvider =
    providerIndex === "All"
      ? name
      : providersTab &&
        providersTab[providerIndex] &&
        providersTab[providerIndex][2] &&
        providersTab[providerIndex][2].id;
  const dataCampaigns = data && data.campaigns && data.campaigns;
  const selectedCampaignName = dataCampaigns.find(val => val.id === idProvider);
  const getSelectedName =
    (selectedCampaignName && selectedCampaignName.name) || "";

  //const getIsRequestChange = data && data.isRequestChange;

  //Kludge check for multiple ios within providers
  let providers = [];

  campaigns.forEach(io => {
    const { demandSidePlatform } = io;
    if (!providers.includes(demandSidePlatform)) {
      providers.push(demandSidePlatform);
    }
  });

  const getDefaultRoleTrafficker =
    currentUser &&
    currentUser.defaultRole &&
    currentUser.defaultRole.permission &&
    currentUser.defaultRole.permission.isTrafficker;

  const TabTopUI = () => {
    return (
      <React.Fragment>
        {!useDynamicExport ? (
          <Space className="ant-space-xs-vertical ant-space-md-horizontal">
            {selectedTab === "basketItems" ? null : (isAdmin || exportData) &&
              checkStatus !== "DRAFT" ? (
              <DropdownCSVButton
                tabKey={selectedTab}
                orgConfig={orgConfig}
                campaignName={name}
                campaignType={type}
                campaignId={id}
                chartsLoading={chartsLoading}
                chartData={chartData}
                attributionReportId={attributionReportId}
                campaigns={campaignOrder.campaigns}
                isAdmin={currentUser.isAdmin}
                hasInsights={hasInsights}
                byCampaignRangeDate={byCampaignRangeDate}
                bySummaryRangeDate={bySummaryRangeDate}
                byTransactionsRangeDate={byTransactionsRangeDate}
                dataAttributionWindow={dataAttributionWindow}
                bySignUpRangeDate={bySignUpRangeDate}
                byWalkinRangeDate={byWalkinRangeDate}
                rangeType={rangeType}
                amplify={amplify}
                sitesAppsTransactions={sitesAppsTransactions}
              />
            ) : null}
            {selectedTab === "basketItems" ? null : (isAdmin || exportData) &&
              checkStatus !== "DRAFT" ? (
              <DropdownExcelButton
                tabKey={selectedTab}
                orgConfig={orgConfig}
                campaignName={name}
                campaignType={type}
                campaignId={id}
                chartsLoading={chartsLoading}
                chartData={chartData}
                attributionReportId={attributionReportId}
                campaigns={campaignOrder.campaigns}
                isAdmin={currentUser.isAdmin}
                hasInsights={hasInsights}
                byCampaignRangeDate={byCampaignRangeDate}
                bySummaryRangeDate={bySummaryRangeDate}
                byTransactionsRangeDate={byTransactionsRangeDate}
                dataAttributionWindow={dataAttributionWindow}
                bySignUpRangeDate={bySignUpRangeDate}
                byWalkinRangeDate={byWalkinRangeDate}
                rangeType={rangeType}
                amplify={amplify}
              />
            ) : null}
          </Space>
        ) : (
          <>
            {(isAdmin || exportData) && checkStatus !== "DRAFT" ? (
              <DynamicDropdownCSVButton
                menuItems={exportButtonMenuItems}
                disabled={exportButtonDisable}
                loading={exportButtonLoading}
              />
            ) : null}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={providerIndex === "All" ? name : getSelectedName}
        titleDescription={getDescription}
        titleIcon={
          <HasAccess permissions={["default", "defaultSSO"]}>
            <StatusTag
              startDate={startDate ? startDate : new Date().toString()}
              endDate={endDate ? endDate : new Date().toString()}
              statusCampaign={data.status && data.status}
              attributionWindow={
                data && data.attributionWindow ? data.attributionWindow : "N/A"
              }
            />
          </HasAccess>
        }
        //Right content currently has 2 modes - isTrafficker and isSelfService - for isTrafficker, edit campaign can in turn boost a campaigns budget so edit will only be diplayed
        //Self service customers that do not also have the isTrafficker role will see boost - to update a campaigns budget
        rightContent={
          <HasAccess permissions={["default", "defaultSSO"]}>
            <Space
              className="ant-space-xs-vertical ant-space-md-horizontal"
              style={{ padding: "8px" }}
            >
              {isSelfService ||
              (isTrafficker && !isDemo) ||
              checkStatus === "DRAFT" ||
              (currentUser.isPreviewOrg === true &&
                getDefaultRoleTrafficker === true) ? (
                <>
                  {" "}
                  <Button
                    style={{ margin: "0" }}
                    type="primary"
                    ghost
                    onClick={() => {
                      history.push(`/campaigns/manager/${id}`);

                      checkStatus !== "DRAFT"
                        ? sessionStorage.setItem("isLaunchCampaign", false)
                        : sessionStorage.setItem("isLaunchCampaign", true);
                    }}
                    icon={<EditOutlined />}
                  >
                    Edit Campaign
                  </Button>
                  {isBetaEnabled && (
                    <Button
                      style={{ margin: "0" }}
                      type="primary"
                      ghost
                      onClick={() => {
                        history.push(`/campaigns/requestor/${id}`);
                      }}
                      icon={<EditOutlined />}
                    >
                      Edit Campaign with New Requester
                    </Button>
                  )}
                </>
              ) : null}
              {checkStatus === "DRAFT" ? (
                <Tooltip title="Discard Draft to Delete the Draft Campaign">
                  <Button
                    style={{ margin: "0" }}
                    type="danger"
                    ghost
                    onClick={() => {
                      confirm({
                        title: "Would you like to delete this draft campaign?",
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                          onDeleteCampaignOrder();
                        },
                      });
                    }}
                  >
                    Discard Draft
                  </Button>
                </Tooltip>
              ) : null}
              {isSelfService &&
              !isTrafficker &&
              checkStatus !== "DRAFT" &&
              checkStatus !== "PENDING" ? (
                <AmplifyView
                  campaignOrder={campaignOrder}
                  updateCampaignOrder={updateCampaignOrder}
                />
              ) : null}
              {isAdmin ? (
                <Button
                  icon={<ToolOutlined />}
                  style={{ width: "100%", margin: "0" }}
                  onClick={() => {
                    toggleModal(1);
                  }}
                />
              ) : null}
            </Space>
          </HasAccess>
        }
      />
      <PageContentLayout>
        {checkStatus !== "PENDING" && checkStatus !== "APPROVED" && (
          <div
            id="printable-page"
            style={{
              fontVariant: "normal",
            }}
          >
            <HasAccess permissions={["default", "defaultSSO", "displaySSO"]}>
              <Tabs
                activeKey={selectedTab}
                onChange={selected => {
                  let searchParams = new URLSearchParams(location.search);
                  if (searchParams.get("tab")) searchParams.delete("tab");
                  searchParams.append("tab", selected);
                  history.push(
                    `${location.pathname}?${searchParams.toString()}`
                  );
                }}
                tabBarExtraContent={{ right: TabTopUI() }}
              >
                {campaignSummary && (
                  <TabPane tab={<TabText text={"Summary"} />} key="summary">
                    {isValidUrl === true ? (
                      <SummaryTab
                        orgConfig={orgConfig}
                        exportData={exportData}
                        campaignOrder={campaignOrder}
                        providerIndex={providerIndex}
                        campaignType={type}
                        checkStatus={checkStatus}
                        currentUser={currentUser}
                        chartsLoading={chartsLoading}
                        campaignId={id}
                        campaignName={name}
                        providersTab={providersTab}
                        chartData={chartData}
                        attributionReportId={attributionReportId}
                        type={type}
                        isAdmin={currentUser.isAdmin}
                        showTransactions={transactionTarget}
                        signUpCampaign={signUpCampaign}
                        showAttribution={
                          attributionTargets && !isEmpty(attributionTargets)
                        }
                        selectedCampaign={data}
                        isTrafficker={isTrafficker}
                        hasInsights={hasInsights}
                        bySummaryRangeDate={bySummaryRangeDate}
                        setBySummaryRangeDate={setBySummaryRangeDate}
                        dataSummaryRangeDate={dataSummaryRangeDate}
                        setDataSummaryRangeDate={setDataSummaryRangeDate}
                        setRangeType={setRangeType}
                        rangeType={rangeType}
                        priceVisible={priceVisible}
                      />
                    ) : (
                      <Card>
                        <Empty
                          description="Please refresh your browser to receive the latest updates."
                          style={{ marginTop: 16 }}
                        />
                      </Card>
                    )}
                  </TabPane>
                )}

                <TabPane tab={<TabText text={"Display"} />} key="overview">
                  {data.type !== "OOH" &&
                    providersTab &&
                    providersTab.length > 1 &&
                    isProvidersVisible && (
                      <ProviderTabs //check to ensure multiple providers not just many IOS
                        updateUrlWithProvider={updateUrlWithProvider}
                        selectedProvider={selectedProvider}
                        providers={providers}
                        providersTab={providersTab}
                        providerIndex={providerIndex}
                        setProviderIndex={setProviderIndex}
                        providersVisible={providersVisible}
                        data={data}
                      />
                    )}
                  <CampaignDetailBar
                    name={name}
                    colStyle={colStyle}
                    selectedCampaign={data}
                    selectedProvider={selectedProvider}
                    byCampaignRangeDate={byCampaignRangeDate}
                    dataCampaignRangeDate={dataCampaignRangeDate}
                    providersTab={providersTab}
                    providerIndex={providerIndex}
                  />
                  {isValidUrl === true && (
                    <OverviewTab
                      providerIndex={providerIndex}
                      providersTab={providersTab}
                      chartData={chartData}
                      setChartData={setChartData}
                      campaignOrder={campaignOrder}
                      chartsLoading={chartsLoading}
                      displayReportId={displayReportId}
                      type={type}
                      selectedCampaign={data}
                      isTrafficker={isTrafficker}
                      hasInsights={hasInsights}
                      byCampaignRangeDate={byCampaignRangeDate}
                      setByCampaignRangeDate={setByCampaignRangeDate}
                      dataCampaignRangeDate={dataCampaignRangeDate}
                      setDataCampaignRangeDate={setDataCampaignRangeDate}
                      selectedSegmentsDimension={selectedSegmentsDimension}
                      setSegmentsDimension={setSegmentsDimension}
                    />
                  )}
                </TabPane>

                {transactionTarget && (
                  <>
                    <TabPane
                      tab={<TabText text={"Transactions"} />}
                      key="transactions"
                    >
                      <TransactionsTab
                        dataConfig={dataConfig}
                        colStyle={colStyle}
                        selectedCampaign={data}
                        campaignOrder={campaignOrder}
                        chartsLoading={chartsLoading}
                        chartData={chartData}
                        setChartData={setChartData}
                        byTransactionsRangeDate={byTransactionsRangeDate}
                        dataAttributionWindow={dataAttributionWindow}
                        setDataAttributionWindow={setDataAttributionWindow}
                        setByTransactionsRangeDate={setByTransactionsRangeDate}
                        dataTransactionsRangeDate={dataTransactionsRangeDate}
                        setDataTransactionsRangeDate={
                          setDataTransactionsRangeDate
                        }
                        byCampaignRangeDate={byCampaignRangeDate}
                        setByCampaignRangeDate={setByCampaignRangeDate}
                        dataCampaignRangeDate={dataCampaignRangeDate}
                        setDataCampaignRangeDate={setDataCampaignRangeDate}
                        transactionTarget={transactionTarget}
                        transactionReportId={transactionReportId}
                        transactionCSVReportId={transactionCSVReportId}
                        dateTrue={dateTrue}
                        setDateTrue={setDateTrue}
                        priceVisible={priceVisible}
                        useDynamicExport={useDynamicExport}
                        setUseDynamicExport={setUseDynamicExport}
                        setExportButtonLoading={setExportButtonLoading}
                        setExportButtonDisable={setExportButtonDisable}
                        setExportButtonMenuItems={setExportButtonMenuItems}
                        location={location}
                        currentUser={currentUser}
                        currencyFormat={currencyFormat}
                      />
                    </TabPane>

                    {campaignOrder.transactionsConfig &&
                      campaignOrder.transactionsConfig.tracksBasketItems &&
                      !isEmpty(basketItemsReportId) && (
                        <TabPane
                          tab={<TabText text={"Basket Items"} />}
                          key="basketItems"
                        >
                          <BasketItemsTab
                            colStyle={colStyle}
                            selectedCampaign={data}
                            campaignOrder={campaignOrder}
                            chartsLoading={chartsLoading}
                            chartData={chartData}
                            setChartData={setChartData}
                            byTransactionsRangeDate={byTransactionsRangeDate}
                            setByTransactionsRangeDate={
                              setByTransactionsRangeDate
                            }
                            dataTransactionsRangeDate={
                              dataTransactionsRangeDate
                            }
                            setDataTransactionsRangeDate={
                              setDataTransactionsRangeDate
                            }
                            byCampaignRangeDate={byCampaignRangeDate}
                            setByCampaignRangeDate={setByCampaignRangeDate}
                            dataCampaignRangeDate={dataCampaignRangeDate}
                            setDataCampaignRangeDate={setDataCampaignRangeDate}
                            transactionTarget={transactionTarget}
                            basketItemsReportId={basketItemsReportId}
                            location={location}
                          />
                        </TabPane>
                      )}
                  </>
                )}

                {signUpCampaign && (
                  <TabPane tab={<TabText text={"Sign Ups"} />} key="signups">
                    <SignUpsTab
                      dataConfig={dataConfig}
                      colStyle={colStyle}
                      chartData={chartData}
                      setChartData={setChartData}
                      selectedCampaign={data}
                      signUpReportId={signUpReportId}
                      campaignOrder={campaignOrder}
                      bySignUpRangeDate={bySignUpRangeDate}
                      setSignUpRangeDate={setSignUpRangeDate}
                      dataSignUpRangeDate={dataSignUpRangeDate}
                      setDataSignUpRangeDate={setDataSignUpRangeDate}
                      setbySignUpsAttributionWindow={
                        setbySignUpsAttributionWindow
                      }
                      setdataSignUpsDate={setdataSignUpsDate}
                      bySignUpsAttributionWindow={bySignUpsAttributionWindow}
                      dataSignUpsDate={dataSignUpsDate}
                      location={location}
                    />
                  </TabPane>
                )}

                {attributionTargets && !isEmpty(attributionTargets) && (
                  <TabPane
                    tab={<TabText text={"Walk Ins"} />}
                    key="attribution"
                  >
                    <AttributionTab
                      campaign={data}
                      chartData={chartData}
                      chartsLoading={chartsLoading}
                      attributionLocations={
                        campaignOrder && campaignOrder.attributionTargets
                      }
                      byWalkinRangeDate={byWalkinRangeDate}
                      setByWalkinRangeDate={setByWalkinRangeDate}
                      dataWalkinRangeDate={dataWalkinRangeDate}
                      setDataWalkinRangeDate={setDataWalkinRangeDate}
                      byWalkinRangeDateHead={byWalkinRangeDateHead}
                      setByWalkinRangeDateHead={setByWalkinRangeDateHead}
                      dataWalkinRangeDateHead={dataWalkinRangeDateHead}
                      setDataWalkinRangeDateHead={setDataWalkinRangeDateHead}
                      amplify={amplify}
                    />
                  </TabPane>
                )}

                {pageViewConfig && pageViewConfig.appId && (
                  <TabPane tab={<TabText text={"Page Views"} />} key="pageView">
                    <PageViewTab
                      reportKey={pageViewReportsKey}
                      campaignOrder={campaignOrder}
                      setExportButtonLoading={setExportButtonLoading}
                      setExportButtonDisable={setExportButtonDisable}
                      setExportButtonMenuItems={setExportButtonMenuItems}
                    />
                  </TabPane>
                )}
              </Tabs>
            </HasAccess>
            <HasAccess permissions={["widgetSSO"]}>
              {hasTransactionConfig === true ? (
                <TransactionsTab
                  dataConfig={dataConfig}
                  colStyle={colStyle}
                  selectedCampaign={data}
                  campaignOrder={campaignOrder}
                  chartsLoading={chartsLoading}
                  chartData={chartData}
                  setChartData={setChartData}
                  byTransactionsRangeDate={byTransactionsRangeDate}
                  dataAttributionWindow={dataAttributionWindow}
                  setDataAttributionWindow={setDataAttributionWindow}
                  setByTransactionsRangeDate={setByTransactionsRangeDate}
                  dataTransactionsRangeDate={dataTransactionsRangeDate}
                  setDataTransactionsRangeDate={setDataTransactionsRangeDate}
                  byCampaignRangeDate={byCampaignRangeDate}
                  setByCampaignRangeDate={setByCampaignRangeDate}
                  dataCampaignRangeDate={dataCampaignRangeDate}
                  setDataCampaignRangeDate={setDataCampaignRangeDate}
                  transactionTarget={transactionTarget}
                  transactionReportId={transactionReportId}
                  transactionCSVReportId={transactionCSVReportId}
                  dateTrue={dateTrue}
                  setDateTrue={setDateTrue}
                  priceVisible={priceVisible}
                  useDynamicExport={useDynamicExport}
                  setUseDynamicExport={setUseDynamicExport}
                  setExportButtonLoading={setExportButtonLoading}
                  setExportButtonDisable={setExportButtonDisable}
                  setExportButtonMenuItems={setExportButtonMenuItems}
                  location={location}
                  currentUser={currentUser}
                  currencyFormat={currencyFormat}
                  embeddableConfig={embeddableConfig}
                />
              ) : (
                ResultPage({
                  status: "warning",
                  title: "No Widgets Available",
                })
              )}
            </HasAccess>
          </div>
        )}
        {(checkStatus === "PENDING" || checkStatus === "APPROVED") && (
          <CampaignStatus
            isCampaignDetails={true}
            getIsProcessor={getIsProcessor}
            getIsAdmin={getIsAdmin}
            //changeValue={changeValue}
            selectedCampaignOrder={data}
            createMessageRemarks={createMessageRemarks}
            message={message}
            getMessage={getMessage}
            getOrgName={getOrgName}
            loadingCreateMessage={loadingCreateMessage}
            setGetListMessage={setGetListMessage}
            setGetCurrentConversationId={setGetCurrentConversationId}
            setMessage={setMessage}
            checkStatus={checkStatus}
            checkIsEditCampaign={checkIsEditCampaign}
            closeEditCampaign={closeEditCampaign}
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
          />
        )}
      </PageContentLayout>
      {modalOpen > 0 && (
        <AdminModal
          isLoadingRefreshObservation={isLoadingRefreshObservation}
          setIsLoadingRefreshObservation={setIsLoadingRefreshObservation}
          startObservationJob={startObservationJob}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          campaignOrder={campaignOrder}
          reprocessCurrentCampaign={reprocessCurrentCampaign}
          onDeleteCampaignOrder={onDeleteCampaignOrder}
          campaignId={id}
          scrapeDSPCampaigns={scrapeDSPCampaigns}
          campaign={data}
          pauseLiquidMCampaign={pauseLiquidMCampaign}
          startLiquidMCampaign={startLiquidMCampaign}
          updateCampaignOrderStatus={updateCampaignOrderStatus}
          reprocessStatus={reprocessStatus}
          pauseOrStartLiquidMCampaign={pauseOrStartLiquidMCampaign}
          loadingPauseOrStartLiquidMCampaign={
            loadingPauseOrStartLiquidMCampaign
          }
        />
      )}
    </React.Fragment>
  );
};

export default CampaignDetails;
