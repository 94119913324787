import gql from "graphql-tag";

const UPDATE_ARTICLE = gql`
  mutation updateArticle($id: ID!, $title: String, $content: String) {
    updateArticle(
      where: { id: $id }
      data: { title: $title, content: $content }
    ) {
      id
      createdAt
      updatedAt
      category {
        id
        createdAt
        updatedAt
        title
      }
      title
      content
    }
  }
`;

export default UPDATE_ARTICLE;
