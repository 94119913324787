import React from "react";
import { useQuery } from "@apollo/react-hooks";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";

//This loader handles all reusable queries for SEO
const TagsLoader = props => {
  //Single Fetch Queries for Seo Use
  const {
    data: currentUser,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(CURRENT_USER);

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentUser,
    currentUserLoading,
    currentUserError,
  });
};

export default TagsLoader;
