import { useContext } from "react";
import TagContext from "./TagProvider";

export const useTagContext = () => {
  const context = useContext(TagContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
