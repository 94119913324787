import React from "react";

const style = {
  container: {
    height: "40px",
    border: "0.5px solid #e8e8e8",
    background: "#FAFAFA",
    padding: "8px 30px",
  },
};
const HeaderBar = props => {
  return <div style={style.container}>{props.title}</div>;
};

export default HeaderBar;
