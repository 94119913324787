import React, { useState } from "react";
import { Tooltip, Row, Col, Avatar } from "antd";
import { isEmpty } from "lodash";
import { PublisherExpandedCard } from "./PublisherExpandedCard";
import { CloudOutlined } from "@ant-design/icons";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
  TooltipText,
} from "../../../../../../shared/globalStyling/styledText";
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";
import uuid from "uuid";
import SimpleTable from "../../../../../../shared/SimpleTable";

const CreativeTable = ({ publisherData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const extraColumn = {
    title: "",
    dataIndex: "imageUrl",
    key: "imageUrl",
    width: "70px",
    align: "left",
    render: url => {
      return (
        <Avatar
          onClick={() => {
            console.log("I have clicked");
          }}
          shape="square"
          size={50}
          icon={<CloudOutlined />}
          src={url === "" || !url ? false : url}
          style={{ marginLeft: "-16px" }}
        />
      );
    },
  };

  const columns = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
                marginLeft: "-16px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "value",
      key: "value",
      width: "250px",
      align: "left",
      footerContent: "Totals + Averages",
      sorter: (a, b) => a.value.localeCompare(b.value),
      render: (text, record) => {
        if (text) {
          const parser = new DOMParser();
          const parseFromString = parser.parseFromString(text, "text/html");
          const decodedText = parseFromString.body.textContent || "";

          const pubUnknown = decodedText.includes("Creative");
          return (
            <Row
              type="flex"
              justify="start"
              align="middle"
              style={{
                width: "100%",
              }}
            >
              <Col span={24}>
                {decodedText.length > 45 ? (
                  <Tooltip
                    title={
                      <TooltipText
                        text={pubUnknown ? "Unknown publisher" : decodedText}
                      />
                    }
                  >
                    <span>
                      <PrimaryTableRowText
                        text={
                          pubUnknown
                            ? "Unknown publisher"
                            : decodedText.slice(0, 46).concat("...")
                        }
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <PrimaryTableRowText
                    text={pubUnknown ? "Unknown publisher" : decodedText}
                  />
                )}
              </Col>
            </Row>
          );
        } else {
          return <div />;
        }
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      footerSum: true,
      key: "impressions",
      width: "130px",
      align: "left",
      sorter: (a, b) => a.impressions - b.impressions,
      render: impressions => (
        <PrimaryTableRowText
          text={impressions && formatWholeNumber(impressions)}
        />
      ),
    },
    {
      title: <TableColumnTitle text={"Clicks"} />,
      footerSum: true,
      dataIndex: "clicks",
      key: "clicks",
      width: "100px",
      align: "left",
      sorter: (a, b) => a.clicks - b.clicks,
      render: clicks => (
        <PrimaryTableRowText text={clicks && formatWholeNumber(clicks)} />
      ),
    },
    {
      title: <TableColumnTitle text={"CTR"} />,
      footerContent: "-",
      dataIndex: "ctr",
      key: "ctr",
      width: "80px",
      align: "left",
      sorter: (a, b) => a.ctr - b.ctr,
      render: ctr => <PrimaryTableRowText text={ctr && `${ctr.toFixed(3)}%`} />,
    },
  ];

  if (publisherData && !isEmpty(publisherData)) {
    let pubImages = false;
    publisherData.forEach(pub => {
      const { imageUrl } = pub;
      if (imageUrl) {
        pubImages = true;
      }
    });

    if (pubImages) {
      columns.unshift(extraColumn);
    }
  }

  return (
    <SimpleTable
      footer={
        publisherData &&
        publisherData.slice(currentPage * 10 - 10, currentPage * 10)
      }
      bordered={false}
      columns={columns}
      data={publisherData
        .filter(publisher => publisher.xAxis !== "[tail aggregate]")
        .map(publisher => {
          return {
            ...publisher,
            key: `${publisher.id}${uuid.v4()}`,
          };
        })}
      pagination={{
        pageSize,
        total: publisherData && publisherData.length,
        current: currentPage,
      }}
      scroll={{ x: 200, y: 400 }}
      onChange={paginationObject => {
        setPageSize(paginationObject.pageSize);
        setCurrentPage(paginationObject.current);
      }}
      expandable={{
        expandedRowRender: record =>
          record && <PublisherExpandedCard record={record} />,
        rowExpandable: record => record.extra,
      }}
      rowClassName={record => {
        // NOTE: THREEDEE needs to be changed to video. Additional enum needed Also handles Images
        return record.extra ||
          (record.media && record.media.type) === "THREEDEE"
          ? ""
          : "hide-expand";
      }}
    />
  );
};

export default CreativeTable;
