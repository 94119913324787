import React from "react";

const FirstPartyDataMainController = props => {
  const {
    hashedEmailLists,
    loadingHashedEmailLists,
    refetchHashedEmailLists,
    refetchHashedEmailList,
    processDeviceIds,
  } = props;

  const activateHashedEmailList = async data => {
    return props.activateHashedEmailList({
      variables: {
        emailListId: data.emailListId,
      },
    });
  };

  const createHashedEmailList = async values => {
    return props.createHashedEmailList({
      variables: {
        name: values.name,
        hashedEmailIds: values.hashedEmailIds,
      },
    });
  };

  const updateHashedEmailList = async values => {
    return props.updateHashedEmailList({
      variables: {
        id: values.id,
        activated: values.activated,
      },
    });
  };

  const bulkCreateHashedEmailAddress = async data => {
    return props.createHashedEmailAddress({
      variables: {
        emailListId: data.emailListId,
        emails: data.emails,
      },
    });
  };

  const deleteHashedEmailList = async record => {
    await props.deleteHashedEmailList({
      variables: { id: record.id },
    });
    await props.deleteManyHashedEmailAddresses({
      variables: { id: record.id },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    activateHashedEmailList,
    createHashedEmailList,
    updateHashedEmailList,
    bulkCreateHashedEmailAddress,
    hashedEmailLists,
    loadingHashedEmailLists,
    refetchHashedEmailLists,
    deleteHashedEmailList,
    processDeviceIds,
    refetchHashedEmailList,
  });
};

export default FirstPartyDataMainController;
