import React from "react";
import { Row, Col, Card, Input, Select, Typography } from "antd";

const { Option } = Select;

const AudienceCard = ({
  changeValue,
  audiences,
  audiencesTargeting,
  attributionAudiences,
  targetingAudiences,
  selectedTab,
}) => {
  const showModal = () => {
    selectedTab === "attribution"
      ? changeValue("isModalVisible", true)
      : changeValue("isModalVisibleTargeting", true);
  };

  const styles = {
    mapCard: {
      padding: 0,
      border: 0,
    },
  };

  if (audiences && audiences.edges) {
    attributionAudiences = audiences.edges;
    attributionAudiences.sort((a, b) => a.node.name.localeCompare(b.node.name));
  }

  if (audiencesTargeting && audiencesTargeting.edges) {
    targetingAudiences = audiencesTargeting.edges;
    targetingAudiences.sort((a, b) => a.node.name.localeCompare(b.node.name));
  }

  const getGeoTargetsFromAudience = audienceId => {
    changeValue("targetingAudiences", []);
    changeValue("attributionAudiences", []);

    let selectedAudience = [];

    if (selectedTab === "attribution") {
      selectedAudience = attributionAudiences.filter(audience => {
        return audience.node.id === audienceId;
      });
    }

    if (selectedTab === "targeting") {
      selectedAudience = targetingAudiences.filter(audience => {
        return audience.node.id === audienceId;
      });
    }

    const { geoTargets } = selectedAudience[0].node;
    return geoTargets;
  };

  return (
    <Card bordered={false} bodyStyle={styles.mapCard} style={styles.card}>
      <Row type="flex">
        <Col xs={24} lg={19} style={{ padding: "30px" }}>
          <Typography
            style={{
              color: "#595959",
              fontWeight: "500",
              fontSize: "1.15em",
              paddingBottom: "5px",
            }}
          >
            Audience
          </Typography>
          <Input.Group compact>
            <Select
              style={{ width: "100%", minWidth: "140px" }}
              placeholder={"Select from an existing audience"}
              showSearch={true}
              onChange={(_, option) => {
                const id = option.title;
                const targetsToAdd = getGeoTargetsFromAudience(id);
                if (selectedTab === "attribution") {
                  changeValue(
                    "audienceGeoTargetsToAdd",
                    targetsToAdd.map(target => {
                      if (target.location && target.location.id) {
                        delete target.location.id;
                      }
                      return target;
                    })
                  );
                }
                if (selectedTab === "targeting") {
                  changeValue("audienceTargetingToAdd", targetsToAdd);
                }
                showModal();
              }}
            >
              {attributionAudiences && selectedTab === "attribution"
                ? attributionAudiences.map((audience, i) => {
                    const { node } = audience;
                    return (
                      <Option
                        key={i}
                        value={node.name}
                        title={node.id}
                      ></Option>
                    );
                  })
                : null}

              {targetingAudiences && selectedTab === "targeting"
                ? targetingAudiences.map((audience, i) => {
                    const { node } = audience;
                    return (
                      <Option
                        key={i}
                        value={node.name}
                        title={node.id}
                      ></Option>
                    );
                  })
                : null}
            </Select>
          </Input.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default AudienceCard;
