import React from "react";
import { Form, Modal, Row, Col, Typography, Select } from "antd";
import { cloneDeep } from "lodash";

const { Title, Text } = Typography;
const { Option } = Select;
const ShareEditorial = ({
  orgs,
  selectedRows,
  open,
  onCancel,
  onCreate,
  getFilteredOrgsRow,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title="Share Editorial"
      visible={open}
      okText={"Share"}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name={"ShareEditorialForm"}>
        {selectedRows.map(row => {
          form.resetFields();

          const initialSharedOrgs = cloneDeep(
            row.sharedOrgs
              .filter(o => {
                return !o.isAdmin && orgs.find(org => org.id === o.org.id);
              })
              .map(o => o.org.id)
          );

          const filteredSelection = getFilteredOrgsRow(row, orgs);

          return (
            <Row key={row.id} style={{ marginBottom: `10px` }}>
              <Col xs={24}>
                <Title level={5}>
                  Share "{row.name}" Editorial Attribution with:
                </Title>
                <Form.Item
                  name={[row.id, "editorialId"]}
                  initialValue={row.id}
                  hidden={true}
                />
                <Text type="secondary">Shared Orgs</Text>
                <Form.Item
                  name={[row.id, "sharedOrgs"]}
                  initialValue={initialSharedOrgs}
                  shouldUpdate={true}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please Select Organization"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {filteredSelection.map(org => {
                      return (
                        <Option key={org.id} value={org.id}>
                          {org.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      </Form>
    </Modal>
  );
};

export default ShareEditorial;
