import React from "react";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import LocationDetails from "./components/LocationDetails";

const Location = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  locationCity,
  locationZip,
  uniqueCount,
}) => {
  if (chartsLoading)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Card>
    );
  if (isEmpty(locationCity)) return <div />;

  return (
    <div>
      <LocationDetails
        cityData={locationCity}
        zipData={locationZip}
        colStyle={colStyle}
        //selectedCampaign={selectedCampaign}
        uniqueCount={uniqueCount}
      />
    </div>
  );
};

export default Location;
