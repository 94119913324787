import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Card, Col, Row, Empty, Typography, Space, Tooltip } from "antd";
import moment from "moment";
import GlobalButton from "../../shared/globalStyling/Button";
import { AuditOutlined } from "@ant-design/icons";
//style
import {
  cardMargin,
  colPadding,
  cardBody,
} from "../../shared/globalStyling/style";

const { Text } = Typography;

const renderLongText = value => {
  const text =
    value && value.length > 40 ? `${value.substring(0, 40)}...` : value;

  return value && value.length > 40 ? (
    <Tooltip title={value} arrowPointAtCenter={true} placement="rightBottom">
      <Text>{text}</Text>
    </Tooltip>
  ) : (
    <Text>{text}</Text>
  );
};

const InvoiceDetailsView = props => {
  let { invoiceList } = props;

  let advertiserData, publisherData, publisherFunnelData;

  if (invoiceList[0]) {
    let invoiceData = invoiceList[0];
    let data = invoiceList[0].details;
    advertiserData = data.advertiser;
    const totalImpressions = advertiserData.reduce(
      (acc, curr) => acc + curr.impressions,
      0
    );
    const totalAdvertiserCost = advertiserData.reduce(
      (acc, curr) => acc + curr.cost,
      0
    );

    publisherData = data.tags;
    const totalPublisherCost = publisherData.reduce(
      (acc, curr) => acc + curr.amt,
      0
    );

    publisherFunnelData = data.publisher;
    const totalPublisherFunnelCost = publisherFunnelData.reduce(
      (acc, curr) => acc + curr.cost,
      0
    );

    let total =
      totalAdvertiserCost + totalPublisherCost + totalPublisherFunnelCost;
    return (
      <React.Fragment>
        <PageHeaderLayout
          title={"Invoices"}
          rightContent={
            invoiceData.status === "UNPAID" ? (
              <Space>
                <GlobalButton
                  type="primary"
                  onClick={() =>
                    window.open(
                      data.invoiceData.public_url,
                      "_blank",
                      "noopener"
                    )
                  }
                >
                  Pay Invoice
                </GlobalButton>
              </Space>
            ) : null
          }
          titleIcon={
            <AuditOutlined
              style={{
                marginRight: "10px",
                fontSize: 20,
                color: "lightgrey",
              }}
            />
          }
        />
        <PageContentLayout>
          <Row>
            <Col span={16} style={colPadding}>
              <Card
                style={cardMargin}
                bodyStyle={{ padding: "20px" }}
                title="INVOICES DETAILS"
                loading={false}
                headerFontSize="20px"
              >
                <Row>
                  <Col span={6} style={{ padding: 16 }}>
                    <Text>Balance</Text>
                    <br />
                    <Text strong>{invoiceData.amount}</Text>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      padding: 16,
                      flexDirection: "row",
                    }}
                  >
                    <Text>Status</Text>
                    <br />
                    <Text strong> {invoiceData.status}</Text>
                  </Col>
                  <Col span={6} style={{ padding: 16 }}>
                    <Text>Due On</Text>
                    <br />
                    <Text strong> {invoiceData.dueDate}</Text>
                  </Col>
                  <Col span={6} style={{ padding: 16 }}>
                    <Text>Invoice #</Text>
                    <br />
                    <Text strong> {invoiceData.invoice_number}</Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} style={{ padding: 16 }}>
                    <Text>Advertising Impression</Text>
                    <br />
                    <Text strong>{totalImpressions.toLocaleString()}</Text>
                  </Col>
                  <Col span={8} style={{ padding: 16 }}>
                    <Text>Publisher Tag</Text>
                    <br />
                    <Text strong> {publisherData.length}</Text>
                  </Col>
                  <Col span={8} style={{ padding: 16 }}>
                    <Text>Publisher Funnels</Text>
                    <br />
                    <Text strong> {publisherFunnelData.length}</Text>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8} style={colPadding}>
              <Card
                style={cardMargin}
                bodyStyle={cardBody}
                title="BALANCE"
                loading={false}
              >
                <Row>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text>Advertiser Impressions</Text>
                  </Col>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text strong> ${totalAdvertiserCost.toFixed(2)}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text>Publisher Tags</Text>
                  </Col>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text strong> ${totalPublisherCost.toFixed(2)}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text>Measurement Domain</Text>
                  </Col>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text strong> ${totalPublisherFunnelCost.toFixed(2)}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text strong>Total Cost:</Text>
                  </Col>
                  <Col span={12} style={{ padding: 16 }}>
                    <Text strong> ${total.toFixed(2)}</Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={16} style={colPadding}>
              <Card
                style={cardMargin}
                bodyStyle={cardBody}
                title="COST BREAKDOWN: ADVERTISER IMPRESSIONS"
                loading={false}
                headerFontSize="20px"
              >
                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Advertiser Funnel Name</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Measurement Tag Name</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Impressions</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Cost</Text>
                  </Col>
                </Row>
                {advertiserData.map((advertiser, index) => (
                  <Row style={{ padding: 16 }}>
                    <Col span={6}>
                      <Text>{advertiser.name}</Text>
                    </Col>
                    <Col span={6}>
                      <Text>{advertiser.tag}</Text>
                    </Col>
                    <Col span={6}>
                      <Text>{advertiser.impressions.toLocaleString()}</Text>
                    </Col>
                    <Col span={6}>
                      <Text>${advertiser.cost.toFixed(2)}</Text>
                    </Col>
                  </Row>
                ))}

                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Total Cost</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong></Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>{totalImpressions.toLocaleString()}</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>${totalAdvertiserCost.toFixed(2)}</Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={16} style={colPadding}>
              <Card
                style={cardMargin}
                bodyStyle={cardBody}
                title="COST BREAKDOWN: PUBLISHER TAGS"
                loading={false}
                headerFontSize="20px"
              >
                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Publisher Tag Name</Text>
                  </Col>
                  {/* <Col span={6}>
                    <Text strong>Author</Text>
                  </Col> */}
                  <Col span={6}>
                    <Text strong>Created On</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Cost</Text>
                  </Col>
                </Row>

                {publisherData.map((publisher, index) => (
                  <Row style={{ padding: 16 }} key={index}>
                    <Col span={6}>
                      <Text>{renderLongText(publisher.name)}</Text>
                    </Col>
                    <Col span={6}>
                      <Text>
                        {moment(publisher.createdAt).format("MM/DD/YY")}
                      </Text>
                    </Col>
                    <Col span={6}>
                      <Text>${publisher.amt.toFixed(2)}</Text>
                    </Col>
                  </Row>
                ))}
                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Total Cost</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong></Text>
                  </Col>

                  <Col span={6}>
                    <Text strong>${totalPublisherCost.toFixed(2)}</Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={16} style={colPadding}>
              <Card
                style={cardMargin}
                bodyStyle={cardBody}
                title="COST BREAKDOWN: PUBLISHER FUNNELS"
                loading={false}
                headerFontSize="20px"
              >
                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Measurement Tag Name</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Funnel Domain</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Placed On</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong>Cost</Text>
                  </Col>
                </Row>
                {publisherFunnelData.map((publisher, index) => (
                  <Row style={{ padding: 16 }}>
                    <Col span={6}>
                      <Text>{renderLongText(publisher.name)}</Text>
                    </Col>
                    <Col span={6}>
                      <Text>
                        <Text>{renderLongText(publisher.url)}</Text>
                      </Text>
                    </Col>
                    <Col span={6}>
                      <Text>
                        {moment(publisher.createdAt).format("MM/DD/YY")}
                      </Text>
                    </Col>
                    <Col span={6}>
                      <Text>${publisher.cost.toFixed(2)}</Text>
                    </Col>
                  </Row>
                ))}
                <Row
                  style={{
                    padding: 16,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Col span={6}>
                    <Text strong>Total Cost</Text>
                  </Col>
                  <Col span={6}>
                    <Text strong></Text>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <Text strong>${totalPublisherFunnelCost.toFixed(2)}</Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </PageContentLayout>
      </React.Fragment>
    );
  } else {
    return () => <Empty />;
  }
};
export default InvoiceDetailsView;
