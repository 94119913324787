import React from "react";
import {
  Card,
  Space,
  Typography,
  Collapse,
  Row,
  Col,
  Divider,
  Table,
  Tag,
  Button,
} from "antd";
import moment from "moment";
import { calculateImpressions } from "../../CampaignRequestor/CampaignRequestorUtil";
import {
  formatCurrency2SigFig,
  kmToFt,
} from "../../../../core/utils/campaigns";
import {
  audienceTypeMap,
  stateList,
} from "../../../../core/utils/constants/constants";
import { CampaignOrderQueueReviewAdGroup } from "./CampaignOrderQueueReviewAdGroup";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { capitalize } from "lodash";

const { Panel } = Collapse;
const { Title, Text } = Typography;

export const CampaignOrderQueueReviewCampaign = ({
  index,
  campaign,
  currentCampaignOrder,
}) => {
  const {
    name,
    startDate,
    endDate,
    budgetTotal,
    cpm,
    adGroup,
    firstPartyDataAudiences,
    audienceType,
    targetingType,
    targetingRegions,
    targetingLocations,
  } = campaign;
  const { isRegulated } = currentCampaignOrder;

  const getStateCode = (state, stateList) => {
    const stateEntry = stateList.find(val => state === val.State);
    return stateEntry ? stateEntry.Code : "";
  };

  const processGeoTargets = (geoTarget, stateList) => {
    return geoTarget.circles.map(circle => {
      const location = circle.location || geoTarget.location || {};
      const state = location.state ? capitalize(location.state) : "";
      const stateCode = getStateCode(state, stateList);

      return {
        Name: location.name || "",
        Type: "CIRCLE",
        Address: location.formattedAddress || "",
        Street: location.street || "",
        City: location.city || "",
        County: location.county || "",
        State: stateCode || location.state || "",
        Country: location.country || "",
        Zip: location.zip || "",
        Latitude: circle.coordinate.lat,
        Longitude: circle.coordinate.lng,
        Radius: Math.round(kmToFt(circle.radius)),
      };
    });
  };

  const flattenLocations = (targetingLocations, stateList) => {
    return targetingLocations.flatMap(targetingLocation =>
      targetingLocation.geoTargets.flatMap(geoTarget =>
        processGeoTargets(geoTarget, stateList)
      )
    );
  };

  const csvData = flattenLocations(targetingLocations, stateList);

  return (
    <div>
      <Collapse bordered={false} defaultActiveKey={[0]}>
        <Panel
          open={true}
          header={<Title level={5}>LINE ITEM {index + 1}</Title>}
        >
          <Card>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Row gutter={20}>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Line Item Name</Text>
                    <Text>{name}</Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Ad Groups</Text>
                    <Text>
                      {adGroup &&
                        adGroup.adGroupCreatives &&
                        adGroup.adGroupCreatives.length}
                    </Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Ad Units</Text>
                    <Text>
                      {adGroup &&
                        adGroup.adGroupCreatives &&
                        adGroup.adGroupCreatives.reduce((acc, curr) => {
                          return acc + curr.medias.length;
                        }, 0)}
                    </Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Start Date</Text>
                    <Text>{moment(startDate).format("MM/DD/YYYY")}</Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">End Date</Text>
                    <Text>{moment(endDate).format("MM/DD/YYYY")}</Text>
                  </Space>
                </Col>
              </Row>
              <Divider plain></Divider>
              <Row gutter={20}>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Wholesale CPM</Text>
                    <Text></Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">CPM</Text>
                    <Text>{cpm}</Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Impressions</Text>
                    <Text>
                      {calculateImpressions({
                        budget: budgetTotal,
                        cpm,
                      }).toLocaleString()}
                    </Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">Line Item Budget</Text>
                    <Text>{formatCurrency2SigFig(budgetTotal)}</Text>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text type="secondary">End Date</Text>
                    <Text>{moment(endDate).format("MM/DD/YYYY")}</Text>
                  </Space>
                </Col>
              </Row>
              {/* AUDIENCES */}
              {/* DISPLAY AUDIENCE DEPENDING AUDIENCE TYPE  */}
              {audienceType === "FIRST_PARTY_DATA" && (
                <div
                  className="requestor-table-wrapper"
                  style={{ marginTop: 20 }}
                >
                  <>
                    <div className="requestor-table-header">
                      <Row>
                        <Col span={12}>
                          <Title level={5}>FIRST PARTY AUDIENCES</Title>
                        </Col>
                      </Row>
                    </div>
                    {!firstPartyDataAudiences.length ? (
                      <div style={{ padding: 20 }}>
                        <Space
                          direction="vertical"
                          size="small"
                          align="center"
                          style={{ width: "100%" }}
                        ></Space>
                      </div>
                    ) : (
                      <Table
                        rowKey="id"
                        columns={[
                          {
                            title: "Audience Name",
                            dataIndex: "name",
                            key: "name",
                            ellipsis: true, // Enables ellipsis for text overflow
                            width: 200, // Set a fixed width for the column
                          },
                          {
                            title: "Devices",
                            dataIndex: "deviceCount",
                            key: "deviceCount",
                            render: count =>
                              count ? count.toLocaleString() : "N/A",
                          },
                          {
                            title: "Match %",
                            dataIndex: "matchRate",
                            key: "matchRate",
                            render: rate =>
                              rate ? rate.toFixed(2).toLocaleString() : "N/A",
                          },
                          {
                            title: "Status",
                            dataIndex: "activated",
                            key: "activated",
                            render: activated =>
                              activated ? "Activated" : "Deactivated",
                          },

                          {
                            title: "Uploaded",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: date =>
                              moment(date).format("MM/DD/YYYY hh:mma"),
                          },
                        ]}
                        dataSource={firstPartyDataAudiences}
                        pagination={false}
                        className="requestor-table"
                        summary={() => (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {firstPartyDataAudiences.length} Audiences
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              {firstPartyDataAudiences
                                .reduce(
                                  (acc, audience) => acc + audience.deviceCount,
                                  0
                                )
                                .toLocaleString()}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={3} index={3}>
                              {firstPartyDataAudiences
                                .reduce(
                                  (acc, audience) => acc + audience.matchRate,
                                  0
                                )
                                .toFixed(2)
                                .toLocaleString()}{" "}
                              %
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      />
                    )}
                  </>
                </div>
              )}

              {audienceType === "ALL_USERS" && (
                <>
                  <Divider plain></Divider>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Space
                        direction="vertical"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <Text type="secondary">Audience Type</Text>
                        <Text>
                          {audienceTypeMap[audienceType]}{" "}
                          {isRegulated ? "aged 21+" : ""}
                        </Text>
                      </Space>
                    </Col>
                  </Row>
                </>
              )}

              {/* TARGETING */}

              {/* TARGETING REGION TABLE */}
              {targetingType === "REGION" && (
                <div
                  className="requestor-table-wrapper"
                  style={{ marginTop: 20 }}
                >
                  <div className="requestor-table-header">
                    <Row>
                      <Col span={12}>
                        <Title level={5}>TARGETING: REGION GROUPS</Title>
                      </Col>
                    </Row>
                  </div>
                  {targetingRegions && targetingRegions.length ? (
                    <Table
                      rowKey="id"
                      columns={[
                        {
                          title: "Region Group Name",
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: "Regions",
                          dataIndex: "regions",
                          key: "regions",
                          render: regions =>
                            regions.map(region => (
                              <Tag color="green" key={region}>
                                {region}
                              </Tag>
                            )),
                        },
                      ]}
                      dataSource={targetingRegions}
                      pagination={false}
                      className="requestor-table"
                      summary={() =>
                        targetingRegions && targetingRegions.length ? (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {targetingRegions.length} Region Groups
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              {targetingRegions.reduce(
                                (acc, regionGroup) =>
                                  acc + regionGroup.regions.length,
                                0
                              )}{" "}
                              Individual Regions
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {/* TARGETING LOCAL TABLE */}
              {targetingType === "LOCAL" && (
                <div
                  className="requestor-table-wrapper"
                  style={{ marginTop: 20 }}
                >
                  <div className="requestor-table-header">
                    <Row>
                      <Col span={12}>
                        <Title level={5}>TARGETING: LOCAL</Title>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button>
                            <CSVLink
                              filename={`${campaign.name}-Geo.csv`}
                              style={{ textDecoration: "none" }}
                              data={csvData}
                            >
                              <DownloadOutlined />
                              Download CSV
                            </CSVLink>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {targetingLocations && targetingLocations.length ? (
                    <Table
                      rowKey="id"
                      columns={[
                        {
                          title: "Targeting Group Name",
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: "Locations",
                          dataIndex: "locations",
                          key: "locations",
                          width: "40%",
                          render: (_, { geoTargets }) => (
                            <div>
                              {geoTargets &&
                                geoTargets.map(target => {
                                  return target.location ? (
                                    <Tag color="blue" key={target.id}>
                                      {target.location.name}
                                    </Tag>
                                  ) : (
                                    <></>
                                  );
                                })}
                            </div>
                          ),
                        },
                        {
                          title: "Created On",
                          dataIndex: "createdAt",
                          key: "createdAt",
                          render: text =>
                            moment(text).format("MM/DD/YYYY hh:mma"),
                        },
                      ]}
                      dataSource={targetingLocations}
                      pagination={false}
                      className="requestor-table"
                      summary={() =>
                        targetingLocations && targetingLocations.length ? (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              {targetingLocations.length} Targeting Groups
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              {targetingLocations.reduce(
                                (acc, targetingGroup) =>
                                  acc + targetingGroup.geoTargets.length,
                                0
                              )}{" "}
                              Targeting Locations
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          </Table.Summary.Row>
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {targetingType === "COUNTRY" && (
                <>
                  <Divider plain></Divider>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Space
                        direction="vertical"
                        size="small"
                        style={{ width: "100%" }}
                      >
                        <Text type="secondary">Targeting Type</Text>
                        <Text>{targetingType}</Text>
                      </Space>
                    </Col>
                  </Row>
                </>
              )}

              {/* AD GROUP TABLE*/}
              {adGroup && adGroup.adGroupCreatives.length ? (
                adGroup.adGroupCreatives.map(
                  (adGroupCreative, adGroupCreativeIndex) => (
                    <>
                      <CampaignOrderQueueReviewAdGroup
                        key={adGroupCreativeIndex}
                        adGroup={adGroup}
                        adGroupCreative={adGroupCreative}
                        adGroupCreativeIndex={adGroupCreativeIndex}
                      />
                    </>
                  )
                )
              ) : (
                <></>
              )}
            </Space>
          </Card>
        </Panel>
      </Collapse>
    </div>
  );
};
