/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, message, Spin } from "antd";

import "./styles/index.css";
import {
  sendEmailDayOfWeek,
  sendEmailFrequency,
} from "../../../core/utils/constants/constants";
import { MenuItemTextColor } from "../../shared/globalStyling/styledText";
import { useLazyQuery } from "react-apollo";
import GET_ALL_CAMPAIGNORDERS_CONNECTION from "../../../core/GraphQl/Queries/GET_ALL_CAMPAIGNORDERS_CONNECTION";
import GET_ORG_SEND_EMAIL_CONFIG from "../../../core/GraphQl/Queries/GET_ORG_SEND_EMAIL_CONFIG";

const { Option } = Select;

// Regular expression to check if string is email
const isValidEmail = /^\S+@\S+\.\S+$/;

export default function SendEmailConfigView({
  selectedOrg,
  currentOrg,
  open,
  setOpenOrgSendEmail,
  handleCancel,
  updateOrgSendEmailConfig,
}) {
  const [orgSendEmailData, setOrgSendEmailData] = useState(null);
  const [options, setOptions] = useState([]);
  const [sendEmailConfigQuery, { loading: loadingOrgSendEmail }] = useLazyQuery(
    GET_ORG_SEND_EMAIL_CONFIG,
    {
      fetchPolicy: "no-cache",
      onCompleted: emailConfig => {
        const campaigns = [];

        const ids =
          emailConfig &&
          emailConfig.getOrgSendEmailConfig &&
          emailConfig.getOrgSendEmailConfig.campaignOrders &&
          emailConfig.getOrgSendEmailConfig.campaignOrders.split(",");

        ids &&
          ids !== [] &&
          ids.map(
            id =>
              options &&
              options.filter(i => {
                if (i.key === id) {
                  campaigns.push(i.label);
                }
                return i;
              })
          );

        setOrgSendEmailData({
          ...emailConfig.getOrgSendEmailConfig,
        });
      },
    }
  );

  const [
    campaignOrdersQuery,
    { loading: loadingCampaignOrders },
  ] = useLazyQuery(GET_ALL_CAMPAIGNORDERS_CONNECTION, {
    fetchPolicy: "no-cache",
    onCompleted: campaignOrders => {
      const cfg =
        campaignOrders &&
        campaignOrders.campaignOrdersConnection &&
        campaignOrders.campaignOrdersConnection.edges;

      const opt = [];

      cfg &&
        cfg.map(({ node }) =>
          opt.push({
            value: node.name,
            label: node.name,
            key: node.id,
          })
        );

      setOptions(opt);

      sendEmailConfigQuery({
        variables: {
          id: selectedOrg.id,
        },
      });
    },
  });

  const handleSubmit = async () => {
    const { campaignOrders, email, frequency, dayOfWeek } = orgSendEmailData;

    if (
      campaignOrders.length === 0 ||
      email === "" ||
      frequency === "" ||
      dayOfWeek === "" ||
      !isValidEmail.test(email)
    ) {
      message.destroy();
      message.error("Missing or Incorrect Values");
      return;
    }

    const res = await updateOrgSendEmailConfig(
      orgSendEmailData,
      selectedOrg.id
    );

    if (!res) {
      message.destroy();
      message.error("Error update organization email report configuration");
      return;
    }
    message.destroy();
    message.success("Success update organization email report configuration");
    setOpenOrgSendEmail(!open);
    return;
  };

  const getData = () => {
    campaignOrdersQuery({
      variables: {
        orgId: selectedOrg.id,
      },
    });
  };

  useEffect(() => {
    setOrgSendEmailData(null);
    if (selectedOrg || currentOrg) {
      getData();
    }
  }, [selectedOrg, currentOrg]);

  if (!orgSendEmailData) {
    return <></>;
  }

  if (loadingCampaignOrders || loadingOrgSendEmail) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "1rem" }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Modal
      title={`Advertiser Name (${selectedOrg.name})`}
      open={open}
      visible={open}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okText="Email Report"
    >
      <Form layout="vertical">
        <Form.Item
          label="Select Campaign(s)"
          rules={[
            {
              required: true,
              message: "Please select a campaign/s",
            },
          ]}
        >
          <Select
            labelInValue
            defaultValue={orgSendEmailData.campaignOrders}
            mode="multiple"
            style={{ width: "100%", marginRight: "10px" }}
            placeholder="Filter by Campaign Order"
            onChange={e => {
              setOrgSendEmailData({
                ...orgSendEmailData,
                campaignOrders: e,
              });
            }}
            options={options}
            filterOption={(input, option) => {
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="Email To"
          rules={[
            {
              required: true,
              message: "Please enter email",
            },
          ]}
        >
          <Input
            value={orgSendEmailData.email}
            placeholder="Email"
            onChange={({ target }) => {
              setOrgSendEmailData({
                ...orgSendEmailData,
                email: target.value,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Frequency"
          rules={[
            {
              required: true,
              message: "Please select frequency",
            },
          ]}
        >
          <Select
            value={orgSendEmailData.frequency}
            placeholder="Frequency"
            name="frequency"
            onChange={e => {
              setOrgSendEmailData({
                ...orgSendEmailData,
                frequency: e,
              });
            }}
          >
            {sendEmailFrequency.map(frequency => {
              return (
                <Option key={frequency} value={frequency}>
                  <MenuItemTextColor text={frequency} />
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Day of week"
          rules={[
            {
              required: true,
              message: "Please select a day of week",
            },
          ]}
        >
          <Select
            value={orgSendEmailData.dayOfWeek}
            placeholder="Day of Week"
            onChange={e => {
              setOrgSendEmailData({
                ...orgSendEmailData,
                dayOfWeek: e,
              });
            }}
          >
            {sendEmailDayOfWeek.map(day => {
              return (
                <Option key={day} value={day}>
                  <MenuItemTextColor text={day} />
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
