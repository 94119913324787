/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Select, Input, Divider, Space, Button, notification } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const SelectTagSignUps = ({
  signUp,
  getSubOrgsTagsData,
  onChange,
  disabled,
}) => {
  const inputRef = useRef(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [orgTags, setOrgTags] = useState([]);

  useEffect(() => {
    if (
      getSubOrgsTagsData &&
      getSubOrgsTagsData.orgs &&
      getSubOrgsTagsData.orgs.length
    ) {
      const tags = [];
      getSubOrgsTagsData.orgs.forEach(org => {
        const { eventsTarget } = org;
        eventsTarget.forEach(event => {
          tags.push({
            name: event.name,
            cart: {
              appId: event.eventTags[0].appId[0],
              cart: event.cart,
              name: event.name,
              generatedTag: (event.tag && event.tag.generatedTag) || null,
              segmentId: event.segmentId,
              segmentName: event.segmentName,
            },
          });
        });
      });
      setOrgTags(tags);
    }
  }, [getSubOrgsTagsData]);

  useEffect(() => {
    if (orgTags && orgTags.length) {
      const options = orgTags
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((tag, i) => {
          const appId = tag.cart.appId;
          return {
            key: `${i}-tag`,
            label: tag.name,
            value: appId || tag.name,
            cart: tag.cart,
          };
        });
      setTagOptions(options);
    }
  }, [orgTags]);

  const [name, setName] = useState("");
  const onNameChange = event => {
    setName(event.target.value);
  };
  const addItem = e => {
    e.preventDefault();
    const tagNames = orgTags.map(item => {
      return item.name;
    });
    if (tagNames.includes(name)) {
      notification.open({
        message: "Warning Duplicate App Id!",
        duration: 15,
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      });
      setName("");
    } else {
      setOrgTags([...orgTags, { name: name, cart: "" }]);
      setName("");
    }
  };

  return (
    <Select
      defaultValue={signUp}
      showSearch
      style={{
        width: "50%",
        minWidth: "50px",
      }}
      optionFilterProp="label"
      placeholder="Select from your Sub Org App Id"
      onChange={value => {
        onChange("appId", value);
      }}
      disabled={disabled}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
      options={tagOptions}
    />
  );
};

export default SelectTagSignUps;
