import React, { useState } from "react";

// Ant Design
import { Button, Modal, Grid, Form, Input, Select, Spin, message } from "antd";

import { isMobileScreen } from "../../utils/mobileUtils";

const AudienceBuilderSaveModal = props => {
  const { handleSubmit, handleCancel, visible } = props;

  const [loading, setLoading] = useState(false);
  const [audienceForm] = Form.useForm();

  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  const handleFormSubmit = async () => {
    setLoading(true);
    await audienceForm
      .validateFields()
      .then(async values => {
        await handleSubmit(audienceForm.getFieldsValue());
        setLoading(false);
      })
      .catch(errorInfo => {
        console.log("Error", errorInfo);
        message.error("Please fill in all required fields");
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={visible}
      title="SAVE AUDIENCE"
      onOk={handleSubmit}
      onCancel={handleCancel}
      width={isMobileScreen(breakPoint) ? "80%" : undefined}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleFormSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={loading} size="large">
        <Form
          layout="vertical"
          name="basic"
          form={audienceForm}
          onFinish={handleFormSubmit}
        >
          <span style={{ fontSize: "12px", color: "rgba(0,0,0,.45)" }}>
            Required name given to this audience
          </span>
          <Form.Item
            label="Audience Name"
            name="name"
            rules={[{ required: true, message: "Please enter audience name" }]}
          >
            <Input placeholder="Enter audience name" />
          </Form.Item>
          <span style={{ fontSize: "12px", color: "rgba(0,0,0,.45)" }}>
            Optional description
          </span>
          <Form.Item label="Audience Description" name="description">
            <Input.TextArea placeholder="Enter audience description" rows={4} />
          </Form.Item>

          <Form.Item label="Audience Tags" name="tags">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select or create tags"
            >
              <Select.Option key="Female">Female</Select.Option>
              <Select.Option key="Bay Area">Bay Area</Select.Option>
              <Select.Option key="Walnut Creek">Walnut Creek</Select.Option>
            </Select>
          </Form.Item>
          <span style={{ fontSize: "12px", color: "rgba(0,0,0,.45)" }}>
            Select or create multiple tags
          </span>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AudienceBuilderSaveModal;
