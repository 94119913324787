import React from "react";
import { Button, Tooltip } from "antd";
import { CompassOutlined } from "@ant-design/icons";
class CrossHairs extends React.Component {
  render() {
    return (
      <Tooltip title="Return to origin">
        <Button
          type="normal"
          shape="circle"
          icon={<CompassOutlined />}
        ></Button>
      </Tooltip>
    );
  }
}

export default CrossHairs;
