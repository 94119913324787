import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import GET_CAMPAIGN_REPORT from "../../GraphQl/Queries/GET_CAMPAIGN_REPORT";

const ReportLoader = props => {
  const [demographicsData, setDemographicsData] = useState({});
  const [demographicsLoading, setDemographicsLoading] = useState(false);

  const [getDemographics] = useLazyQuery(GET_CAMPAIGN_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const url =
        data &&
        data.getCampaignReportSignedURL &&
        data.getCampaignReportSignedURL.url;
      if (url)
        fetch(url).then(async response => {
          if (response) {
            try {
              await response.json().then(json => {
                setDemographicsData(json);
                setDemographicsLoading(false);
              });
            } catch (err) {
              console.log(err);
              setDemographicsLoading(false);
            }
          }
        });
    },
  });

  // TODO: fetch other reports

  useEffect(() => {
    if (
      props.selectedGeoTargets &&
      props.selectedGeoTargets.length === 1 &&
      !isEmpty(props.selectedGeoTargets[0].demographicReport)
    ) {
      setDemographicsLoading(true);
      getDemographics({
        variables: {
          reportId: props.selectedGeoTargets[0].demographicReport[0].id,
        },
      });
    } else setDemographicsData({});
  }, [props.selectedGeoTargets, getDemographics]);

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        demographicsData,
        demographicsLoading,
      })}
    </React.Fragment>
  );
};

export { ReportLoader };
