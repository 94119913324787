import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../core/utils/campaigns";
import { isEmpty } from "lodash";
import { defaultCurrencyFormat } from "../../../../core/utils/constants/constants";

export const summaryScaleLegacy = (
  data,
  leftY,
  rightY,
  currencyFormat = defaultCurrencyFormat
) => {
  const scale = {
    left:
      leftY === "sessions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["sessions"] : 1000,
            alias: "Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "pagesPerSession"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pagesPerSession"] : 0,
            alias: "Pages / Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "bounceRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["bounceRate"] : 0,
            alias: "Bounce Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "avgSessionDuration"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgSessionDuration"] : 0,
            alias: "Average Session Duration",
            formatter: val => {
              return `${formatNumber2SigFig(val)}`;
            },
          }
        : leftY === "goalConversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["goalConversionRate"] : 0,
            alias: "Goal Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatWholeNumber(val)}`;
            },
          }
        : leftY === "avgOrderValue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgOrderValue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : {},
    right:
      rightY === "sessions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["sessions"] : 1000,
            alias: "Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "pagesPerSession"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pagesPerSession"] : 0,
            alias: "Pages / Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "bounceRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["bounceRate"] : 0,
            alias: "Bounce Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "avgSessionDuration"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgSessionDuration"] : 0,
            alias: "Average Session Duration",
            formatter: val => {
              return `${formatNumber2SigFig(val)}`;
            },
          }
        : rightY === "goalConversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["goalConversionRate"] : 0,
            alias: "Goal Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatWholeNumber(val)}`;
            },
          }
        : rightY === "avgOrderValue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgOrderValue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};
