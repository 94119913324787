import gql from "graphql-tag";

const AUDIENCES_LOCATION = gql`
  query audiences($name: String) {
    audiences(where: { name: $name }) {
      id
      name
      isAmplifyDefault
      geoTargets {
        id
        name
        description
        start
        end
        observations
        uniqueDevices
        expandedReach
        orgs {
          id
        }
        vwiUpdated
        vwiUpdatedOnSpot
        tags
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
    }
  }
`;

export default AUDIENCES_LOCATION;
