import { Empty } from "antd";
import React from "react";
import ImpressionSubTable from "../ImpressionsTimeLine";

const TransactionImpressionsView = ({ impressions }) => {
  if (!impressions) {
    return <Empty description="No impressions" />;
  }

  return <ImpressionSubTable impressions={impressions ? impressions : []} />;
};

export default TransactionImpressionsView;
