import React from "react";
import { Tabs, Card, Row, DatePicker } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import { orderDays } from "../../../../../../../../core/utils/constants/constants";

// Global Styles
import {
  TabText,
  CardText,
} from "../../../../../../../shared/globalStyling/styledText";

const Frag = React.Fragment;
const { RangePicker } = DatePicker;

// Styles
const colStyle = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

const TransactionsChartV2 = ({
  chartData,
  defaultStartEndDate,
  selectedStartEndDate,
  dateChange,
}) => {
  // Filter the chart data by selected date range
  chartData = chartData.filter(
    item =>
      moment(item.xAxis).isSameOrAfter(selectedStartEndDate[0]) &&
      moment(item.xAxis).isSameOrBefore(selectedStartEndDate[1])
  );
  // Sort Dates
  const byDates =
    !isEmpty(chartData) &&
    chartData.sort((a, b) =>
      moment(a.xAxis, "MM/DD/YYYY").diff(moment(b.xAxis, "MM/DD/YYYY"))
    );

  // map the chart and return the days with total transactions
  const getByDay = chartData.map(item => {
    return {
      ...item,
      xAxis: moment(item.xAxis).format("dddd"),
    };
  });

  // Sort by day Mon - Sun
  const byDays =
    !isEmpty(getByDay) &&
    getByDay.sort((a, b) => orderDays[a.xAxis] - orderDays[b.xAxis]);

  // Count duplicate foreach object Weekdays for transactions
  const counterTransactionsDays = {};
  byDays.forEach(obj => {
    const key = JSON.stringify(obj.xAxis);
    counterTransactionsDays[key] = (counterTransactionsDays[key] || 0) + 1;
  });

  // reduce the map and add the transaction value with the same dates
  const reduceByDay = byDays.reduce((map, item) => {
    var summarizedItem = map.get(item.xAxis);
    if (!summarizedItem) {
      summarizedItem = item;
    } else {
      summarizedItem.transactions += item.transactions;
      summarizedItem.transactionRevenue += item.transactionRevenue;
    }
    map.set(item.xAxis, summarizedItem);
    return map;
  }, new Map());
  // get the map values from the reduced
  const getArrayByDay = Array.from(reduceByDay.values());

  // get the sorted transactions values in counter to be mapped with the byDay Objects
  const setCounterTransactionsDay = Object.values(counterTransactionsDays);
  const byDayWithTransactions = getArrayByDay.map((obj, i) => {
    return {
      transactions: setCounterTransactionsDay[i],
      ...obj,
    };
  });

  // Date selector start date and end date for transactions
  const DateSelector = () => {
    // map transaction dates
    let getDate = byDates.map(a => moment(a.xAxis, "MM/DD/YYYY"));

    // sort them by desc dates
    getDate = !isEmpty(getDate) && getDate.sort((a, b) => a.unix() - b.unix());

    // get startdate and end date
    const startDate = getDate[0];
    const endDate = getDate.slice(-1)[0];
    // combined start date & end date
    const startEndDate = selectedStartEndDate
      ? [moment(selectedStartEndDate[0]), moment(selectedStartEndDate[1])]
      : [startDate, endDate];
    const disabledDates = current => {
      //Alwats disable dates that is beyond default range
      if (
        current >= moment() ||
        current <= moment(defaultStartEndDate[0], "YYYY-MM-DD")
      ) {
        return true;
      }
    };
    return (
      <RangePicker
        defaultPickerValue={startEndDate}
        defaultValue={startEndDate}
        value={startEndDate}
        disabledDate={disabledDates}
        onChange={v => {
          // get selected range dates
          const getSelectedStartDate = v
            ? moment(v[0]).format("YYYY/MM/DD")
            : moment(defaultStartEndDate[0]).format("YYYY/MM/DD");
          const getSelectedEndDate = v
            ? moment(v[1]).format("YYYY/MM/DD")
            : moment(defaultStartEndDate[1]).format("YYYY/MM/DD");
          dateChange([getSelectedStartDate, getSelectedEndDate]);
        }}
      />
    );
  };

  const TabPane = Tabs.TabPane;

  // if chartdata is equal to empty
  const emptyCharts = isEmpty(chartData);
  if (emptyCharts)
    return (
      <Card style={{ height: "300px" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: "300px" }}
        >
          <CardText text={""} />
        </Row>
      </Card>
    );

  return (
    <div>
      <Card style={colStyle.container} bodyStyle={{ padding: 0 }}>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
        >
          {!isEmpty(byDates) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDates.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDates.length !== 1 && (
                  <Frag>
                    <Row
                      style={{ marginBottom: "2%", marginTop: "1%" }}
                      type="flex"
                      justify="center"
                    >
                      <DateSelector dateData={byDates} />
                    </Row>
                    <DualAxisAreaChart dualChartData={byDates} />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDayWithTransactions) ? (
            <TabPane tab={<TabText text={"Day of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDayWithTransactions.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDayWithTransactions.length !== 1 && (
                  <Frag>
                    <DualAxisAreaChart dualChartData={byDayWithTransactions} />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default TransactionsChartV2;
