import React from "react";
import { Form, Select } from "antd";
import { providers } from "../../../../core/utils/constants/constants";
const { Option } = Select;

const styles = {
  select: {
    width: "50%",
    minWidth: "50px",
  },
};

const CartSelect = ({
  type,
  cartValue,
  cartDefaultValue,
  onChange,
  cartProvidersData,
}) => {
  let cart = cartValue;
  let legacyCtr = value => {
    const provider = providers.find(provider => provider.value === value);
    return provider ? provider.legacyCtr : null;
  };

  if (legacyCtr(cartValue)) {
    cart = legacyCtr(cartValue);
  }

  const handleCartPlatform = () => {
    if (cartProvidersData) {
      const options =
        cartProvidersData &&
        cartProvidersData.map(val => {
          const { label, value } = val;
          return (
            <Option key={value} value={value}>
              {label}
            </Option>
          );
        });
      return options;
    }
  };

  return (
    <>
      {type === "editGenerator" ? (
        <Select
          placeholder="Select an option and change the input text above."
          defaultValue={cartDefaultValue}
          onChange={onChange}
          name="environment"
        >
          {handleCartPlatform()}
        </Select>
      ) : type === "addGenerator" ? (
        <Form.Item
          label="Cart Platform"
          name="tagPlatform"
          rules={[
            {
              required: true,
              message: "Cart Platform is required!",
            },
          ]}
        >
          <Select
            placeholder="Select a Cart"
            name="environment"
            onChange={cart => {
              onChange(cart);
            }}
          >
            {handleCartPlatform()}
          </Select>
        </Form.Item>
      ) : (
        <Select
          placeholder="Select a Cart"
          style={styles.select}
          value={cart}
          defaultValue={cartDefaultValue}
          onChange={onChange}
          name="environment"
        >
          {handleCartPlatform()}
          <Option>No Cart</Option>
        </Select>
      )}
    </>
  );
};

export default CartSelect;
