import gql from "graphql-tag";

const EDIT_ORG_MEDIA = gql`
  mutation updateMedia($creativeID: ID, $newUrl: String, $newName: String) {
    updateMedia(
      where: { id: $creativeID }
      data: { url: $newUrl, name: $newName }
    ) {
      id
      url
      name
    }
  }
`;

export default EDIT_ORG_MEDIA;
