import React from "react";

const AIMainController = props => {
  //functions here

  return React.cloneElement(props.children, {
    ...props,
  });
};

export default AIMainController;
