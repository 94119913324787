import React from "react";
import { Dropdown, Menu, Spin, Popconfirm } from "antd";
// Global Styles
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

const DynamicDropdownCSVButton = ({
  menuItems,
  disabled = false,
  loading = false,
}) => {
  const menu = (
    <Menu>
      {menuItems &&
        menuItems.map(menu => {
          if (menu.hasConfirm) {
            return (
              <Menu.Item key={menu.key}>
                <Popconfirm
                  title={menu.confirmDescription}
                  onConfirm={menu.onClick}
                >
                  <div>{menu.text ? menu.text : ""}</div>
                </Popconfirm>
              </Menu.Item>
            );
          } else {
            return (
              <Menu.Item key={menu.key}>
                <div onClick={menu.onClick}>{menu.text ? menu.text : ""}</div>
              </Menu.Item>
            );
          }
        })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      disabled={disabled}
      trigger={["click"]}
      placement="bottomCenter"
    >
      <Button
        icon={
          loading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <DownloadOutlined />
          )
        }
        type="primary"
        disabled={disabled}
        loading={loading}
      >
        <ButtonText text={"Export CSV"} />
      </Button>
    </Dropdown>
  );
};

export default DynamicDropdownCSVButton;
