import gql from "graphql-tag";

const REPROCESS_CAMPAIGN_STATUS = gql`
  query reprocessStatus($key: String!) {
    reprocessStatus(where: { key: $key }) {
      id
      key
      module
      dateQueued
      dateProcessed
      dateFinished
      status
    }
  }
`;

export default REPROCESS_CAMPAIGN_STATUS;
