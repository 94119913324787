import React from "react";
import { Row, Col, Card, Typography } from "antd";
import {
  formatNumber,
  formatNumber2SigFig,
} from "../../../../../../core/utils/campaigns";
import PctDiff from "../../../RollUp/components/PctDiff";

const DataView = ({ loading, data, diffPcts }) => {
  return (
    <React.Fragment>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>Sessions</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data && data.sessions && formatNumber(data.sessions)}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.sessions} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>New Users</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data && data.newUsers && formatNumber(data.newUsers)}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.newUsers} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>
              Page / Sessions
            </Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data &&
                data.pagesOverSession &&
                formatNumber2SigFig(data.pagesOverSession)}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.pagesOverSession} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>Bounce Rate</Typography>
            <Typography style={{ fontSize: "28px" }}>
              {`${data && data.bounceRate && formatNumber(data.bounceRate)}%`}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.bounceRate} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "14px" }}>
              Average Sessions
            </Typography>
            <Typography style={{ fontSize: "28px" }}>
              {data && data.avgSessions}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.avgSessionDuration} />}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} xxl={4}>
          <Card loading={loading} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "16px" }}>
              Conversion Rate
            </Typography>
            <Typography style={{ fontSize: "28px" }}>
              {`${data &&
                data.conversionRate &&
                formatNumber(data.conversionRate)}%`}
            </Typography>
            {diffPcts && <PctDiff value={diffPcts.conversionRate} />}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DataView;
