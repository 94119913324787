import React from "react";
import { Space, Typography } from "antd";
import InstructionsBuilder from "./InstructionsBuilder";
import { Page, Text, Document } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";

import img1 from "../../../../../../assets/tagsInstructions/dispense/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/dispense/img2.png";
import img3 from "../../../../../../assets/tagsInstructions/dispense/img3.png";
import img4 from "../../../../../../assets/tagsInstructions/dispense/img4.png";

import {
  WordPressInstructions,
  WordPressInstructionsPDF,
} from "./WordPressInstructions";
import { WixInstructionsPDF, WixInstructions } from "./WixInstructions";

const dispenseSteps = generatedTag => [
  {
    instruction: (
      <>
        Login to the{" "}
        <Typography.Link
          href="http://app.dispenseapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dispense Dashboard.
        </Typography.Link>
      </>
    ),
    pdfText: "Login to the Dispense Dashboard",
  },
  {
    instruction: (
      <>
        On the top left side of the page, there is a 3 parallel line icon, click
        on that and go to <Typography.Text strong>Store</Typography.Text>.
      </>
    ),
    image: img1,
    pdfText:
      "On the top left side of the page, there is a 3 parallel line icon, click on that and go to Store.",
  },
  {
    instruction: (
      <>
        Under Settings, go to <Typography.Text strong>Branding</Typography.Text>
        .
      </>
    ),
    image: img2,
    pdfText: "Under Settings, go to Branding.",
  },
  {
    instruction: (
      <>
        Scroll down until you see the{" "}
        <Typography.Text strong>Head Code</Typography.Text> section. Paste the
        tag
      </>
    ),
    image: img3,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          4. Scroll down until you see the Head Code section. Paste the tag
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: "Click on Save on the top right corner of the page",
    image: img4,
  },
];

export const DispenseInstructions = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <Space direction="vertical">
        <InstructionsBuilder steps={dispenseSteps(generatedTag)} />
        <WordPressInstructions generatedTag={generatedTag} />
        <WixInstructions generatedTag={generatedTag} />
      </Space>
    </React.Fragment>
  );
};

export const DispenseInstructionsPDF = ({ generatedTag }) => {
  return (
    <Document>
      <Page
        wrap={false}
        size="A4"
        style={{
          padding: "40px",
        }}
      >
        <Text
          style={{
            fontSize: 15,
            marginBottom: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          Cart Instruction for Dispense
        </Text>
        <GeneratedTagPDF steps={dispenseSteps(generatedTag)} />
        <WordPressInstructionsPDF generatedTag={generatedTag} />
        <WixInstructionsPDF generatedTag={generatedTag} />
      </Page>
    </Document>
  );
};
