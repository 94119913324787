import gql from "graphql-tag";

const CAMPAIGN_REPORTS_CONNECTION = gql`
  query reports($ID: ID, $type: ReportType) {
    campaignReportsConnection(
      where: { campaignOrder: { id: $ID }, type: $type }
      orderBy: createdAt_ASC
    ) {
      edges {
        node {
          id
          createdAt
        }
      }
      aggregate {
        count
      }
    }
  }
`;

export default CAMPAIGN_REPORTS_CONNECTION;
