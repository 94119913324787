import React, { useRef, useState } from "react";
import { Row, Col, Input, Button, Dropdown, Menu, Checkbox } from "antd";
import { withRouter } from "react-router-dom";

import {
  MenuItemText,
  ButtonText,
} from "../../../shared/globalStyling/styledText";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";

const DropDownTypeToggle = withRouter(({ location, history, onTypeSelect }) => {
  const types = ["TARGETING", "RETARGETING", "ATTRIBUTION"];
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const selectedType = type;
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedType]}>
          {types.map((type, i) => {
            const isSelected = selectedType === type;
            return (
              <Menu.Item
                key={`${type}-${i}`}
                onClick={() => {
                  if (!params.get("pageSize")) {
                    params.append("pageSize", 10);
                  }

                  if (selectedType) {
                    params.delete("type");
                  }

                  if (selectedType !== type) {
                    params.append("type", type);
                    onTypeSelect(type);
                  }

                  history.push(`/audiences/new/1?${params.toString()}`);
                }}
              >
                <Checkbox
                  checked={isSelected}
                  style={{ visibility: isSelected ? "visible" : "hidden" }}
                />
                <MenuItemText text={type} />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Button
        style={{
          fontSize: "14px",
          width: "100%",
          height: "36px",
        }}
        shape="round"
        type={!!selectedType ? "primary" : "default"}
        ghost={!!selectedType ? true : false}
      >
        <ButtonText text={"Type"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const AudienceFilterBar = ({ history, location }) => {
  const searchRef = useRef(null);
  const params = new URLSearchParams(location.search);
  const searchTerm = params.get("searchTerm");
  const [searchValue, setSearchValue] = useState(searchTerm || "");
  const [selectedType, setSelectedType] = useState(params.get("type") || "");
  return (
    <Row className="filter-bar-container" gutter={[4, 4]} align={"middle"}>
      <Col
        xs={{ span: 24, order: 2 }}
        md={{ span: 5, order: 1 }}
        xl={{ span: 3 }}
      >
        <DropDownTypeToggle onTypeSelect={setSelectedType} />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 7, order: 2, offset: 7 }}
        lg={{ span: 4, offset: 12 }}
        xl={{ span: 4, offset: 15 }}
      >
        <Input.Search
          placeholder={"Search"}
          ref={searchRef}
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
          onSearch={searchValue => {
            if (searchTerm) {
              params.delete("searchTerm");
            }
            if (searchValue !== "") {
              params.append("searchTerm", searchValue);
            } else {
              params.delete("searchTerm");
            }
            history.push(`/audiences/new/1?${params.toString()}`);
          }}
          defaultValue={searchTerm}
        />
      </Col>
      <Col
        xs={{ span: 24, order: 3 }}
        md={{ span: 4, order: 3 }}
        lg={{ span: 3 }}
        xl={{ span: 2 }}
      >
        <Button
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          shape="round"
          type={"default"}
          disabled={!searchValue && !selectedType}
          onClick={() => {
            params.delete("searchTerm");
            params.delete("type");
            setSelectedType("");
            history.push(`/audiences/new/1?orderBy=updatedAt_DESC`);
            setSearchValue("");
          }}
          icon={<CloseOutlined />}
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
};

export default withRouter(AudienceFilterBar);
