import gql from "graphql-tag";

const GET_NOTIFICATIONS = gql`
  query notifications($userId: ID!) {
    notifications(where: { user: { id: $userId } }, orderBy: createdAt_DESC) {
      id
      message
      type
      featureID
      createdAt
    }
  }
`;

export default GET_NOTIFICATIONS;
