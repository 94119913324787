import gql from "graphql-tag";

const CREATE_ORG_DEAL_ID = gql`
  mutation updateOrg($id: ID!, $data: OrgUpdateInput!) {
    updateOrg(where: { id: $id }, data: $data) {
      id
      name
      dealIds {
        id
        name
        dealId
      }
    }
  }
`;

export default CREATE_ORG_DEAL_ID;
