export const formatDiffPct = value => {
  if (value) {
    if (isFinite(value)) {
      return value.toFixed(2);
    } else {
      return -100;
    }
  }
  return null;
};
