import React from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { Spin } from "antd";
import VideoPlayer from "./VideoPlayer";

import "video.js/dist/video-js.css";

class AvatarS3 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ready: false,
      url: "",
    };
  }

  componentDidMount = () => {
    const { videoKey } = this.props;
    if (videoKey)
      Storage.get(videoKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    else
      this.setState({
        ready: true,
      });
  };

  componentDidUpdate(prevProps) {
    // If props are updated, such as from new data getting added, will recalculate
    if (this.props.videoKey !== prevProps.videoKey) {
      this.setState({
        ready: false,
      });
      Storage.get(this.props.videoKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    }
  }

  render() {
    if (!this.state.ready) {
      return <Spin size="small" />;
    }
    return (
      <VideoPlayer
        autoplay={this.props.autoplay}
        controls={this.props.controls}
        {...this.props.videoProps}
        sources={[
          {
            src: this.state.url,
            type: "video/mp4",
          },
        ]}
      />
    );
  }
}

export default AvatarS3;

AvatarS3.defaultProps = {
  controls: true,
  autoplay: false,
};

AvatarS3.propTypes = {
  videoKey: PropTypes.string,
  videoProps: PropTypes.object,
};
