import React from "react";
import moment from "moment";
import { formatWholeNumberNaN } from "../../../utils/campaigns";

const getSignUpsTag = generatedTag => {
  const start = `">`;
  const end = "</script>";
  const pos = generatedTag.indexOf(start) + start.length;
  return generatedTag.substring(pos, generatedTag.indexOf(end, pos));
};

const TagsDetailsController = ({
  data,
  getBasicTagActivity,
  tagActivityLoading,
  deleteTag,
  disableTag,
  disableEnableLoading,
  regenerateTag,
  userPermission,
  carts,
  ...props
}) => {
  const { eventsTarget } = data;
  const { getCarts } = carts;
  const cart = getCarts.find(cart => cart.value === eventsTarget.cart) || {
    label: "Unsupported Cart",
    value: "thirdparty", //Mark unsupported carts as thirdparty
    eventsTracked: [],
  };
  const details = {
    name: eventsTarget.name,
    appId: eventsTarget.eventTags[0].appId[0],
    cartLabel: cart.label,
    cart: cart.value,
    advertiser: eventsTarget.orgs[0].name,
    supportMeasurements: cart.eventsTracked,
    isDisabled: eventsTarget.isDisabled,
    domain: "https://tags.cnna.io",
  };

  const parentChannelPartner = eventsTarget.orgs[0].parentOrg.find(
    org => org.level === "CHANNEL_PARTNER" && org.tagDomain !== null
  );
  if (eventsTarget.orgs[0].level === "CHANNEL_PARTNER") {
    details.domain = eventsTarget.orgs[0].tagDomain;
  } else if (parentChannelPartner) {
    details.domain = parentChannelPartner.tagDomain;
  }

  const generatedTag = {
    date: eventsTarget.tagUpdateDate,
    transactions: eventsTarget.transactions,
    pageViews: eventsTarget.pageViews,
    basketItems: eventsTarget.basketItems,
    tag: eventsTarget.tag,
    cart: eventsTarget.cart,
  };

  const signUpsTag = {
    date: eventsTarget.tagUpdateDate,
    signups: eventsTarget.signups,
    tag: getSignUpsTag(eventsTarget.tag ? eventsTarget.tag.generatedTag : ""),
    hasSignups: eventsTarget.isSignUps, // Flag for newly created tag to mark it as a sign up tag
  };

  const testLogs = eventsTarget.testLogs.map(log => {
    return {
      date: moment(log.testDate).format("YYYY-MM-DD HH:mm:ss"),
      pageViews: formatWholeNumberNaN(log.pageViews),
      transactions: formatWholeNumberNaN(log.transactions),
      basketItems: formatWholeNumberNaN(log.basketItems),
      signups: formatWholeNumberNaN(log.signups),
    };
  });

  const testTag = async () => {
    await getBasicTagActivity({
      variables: {
        appId: eventsTarget.eventTags[0].appId[0],
        orgId: eventsTarget.orgs[0].id,
        tagId: eventsTarget.id,
      },
    });
  };

  const onDisableEnableTag = async status => {
    await disableTag({
      variables: {
        id: eventsTarget.id,
        isDisabled: status,
      },
    });
  };

  const regenerateTagEvent = async values => {
    await regenerateTag({
      variables: {
        id: eventsTarget.id,
        domain: values.domain,
        cart: values.cart,
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    details,
    generatedTag,
    signUpsTag,
    testTag,
    testLogs,
    tagActivityLoading,
    deleteTag,
    onDisableEnableTag,
    disableEnableLoading,
    regenerateTag: regenerateTagEvent,
    userPermission,
    carts: getCarts,
  });
};

export default TagsDetailsController;
