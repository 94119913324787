import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { Card, Row, Col, Badge, Button, Tooltip, Popconfirm } from "antd";
import {
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const Frag = React.Fragment;

const styles = {
  badgeActive: {
    color: "green",
  },
  badgeInactive: {
    color: "red",
  },
};

const DetailsCard = ({ data, onChange, popConfirm }) => {
  const details = isEmpty(data)
    ? []
    : [
        {
          title: "Audience Type",
          data: `${data.segmentTypeId}`,
        },
        {
          title: "Data Provider",
          data: "AppNexus",
        },
        {
          title: "Price Category",
          data: `${data.dataCatId}`,
        },
        {
          title: "Recommended Audience?",
          data: data.isRecommended ? "Yes" : "No",
        },
        {
          title: "Share In Data Market?",
          data: data.isPublic ? "Yes" : "No",
        },
        {
          title: "Code",
          data: `${data.code}`,
        },
      ];

  return isEmpty(data) ? (
    <Card title="Details">Select an audience for a detailed view.</Card>
  ) : (
    <Card
      extra={
        <Frag>
          <Tooltip
            title="Click here to delete this segment"
            arrowPointAtCenter={true}
            placement="bottom"
          >
            <Popconfirm
              title="Delete this segment"
              onConfirm={e => {
                popConfirm();
              }}
            >
              <Button
                style={{ borderWidth: "0" }}
                icon={<CloseOutlined />}
                disabled={false}
                type="danger"
                ghost={true}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title="Click here to edit this audience"
            arrowPointAtCenter={true}
          >
            <Button
              style={{ borderWidth: "0" }}
              icon={<EditOutlined />}
              disabled={false}
              onClick={() => {
                onChange("whichModal", "editSegment");
                onChange("modalVisible", true);
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              data.hasMap
                ? "This Segement has already been mapped to a pricing category"
                : "Click here to map this segment to a pricing category"
            }
            arrowPointAtCenter={true}
          >
            <Button
              ghost={data.hasMap ? true : false}
              style={{ borderWidth: "0" }}
              icon={<PlusCircleOutlined />}
              disabled={data.hasMap ? true : false}
              onClick={() => {
                onChange("whichModal", "mapPrice");
                onChange("modalVisible", true);
              }}
            />
          </Tooltip>
        </Frag>
      }
      title="Details"
    >
      <Row>
        <Col span={12}>
          <Row type="flex" justify="start">
            <h1 style={{ color: "#959597", margin: 0 }}>{data.audienceName}</h1>
          </Row>
          <Row type="flex" justify="start">
            <h5 style={{ color: "#b4bacc" }}>Id: {data.id}</h5>
          </Row>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end">
            <Badge
              style={data.active ? styles.badgeActive : styles.badgeInactive}
              dot={true}
              status={data.active ? "success" : "error"}
              text={data.active ? "Active" : "Inactive"}
            />
          </Row>
          <Row type="flex" justify="end">
            <h5 style={{ color: "#b4bacc" }}>
              Last Active: {moment(data.lastActivity).format("M/D/YYYY")}{" "}
            </h5>
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        <span>{data.description}</span>
      </Row>
      <br />
      <div style={{ borderBottom: "1px solid #e8e8e8" }} />
      <br />
      <Row type="flex" justify="space-between">
        {details.map((detail, index) => {
          const { title, data } = detail;
          return (
            <React.Fragment key={index}>
              <Col span={12}>
                <h5 style={{ color: "#b4bacc" }}>{title}</h5>
                <h4
                  style={{
                    color: "#959597",
                    fontWeight: "normal",
                    marginBottom: "25px",
                  }}
                >
                  {data}
                </h4>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </Card>
  );
};

export default DetailsCard;
