import React, { useMemo, useState } from "react";
import { graphql, Query, useQuery } from "react-apollo";
import compose from "lodash/flowRight";
import GET_CAMPAIGN_REPORT from "../../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import Controller from "./DisplayTabController";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import { determineUserAccess } from "../../../../../core/permissions/userLevelAccess";
import CAMPAIGN_REPORTS_CONNECTION from "../../../../GraphQl/Queries/CAMPAIGN_REPORTS_CONNECTION";

const DisplayTabDataLoader = ({ children, ...props }) => {
  const config = props.currentUser.permission;
  const orgConfig = determineUserAccess(config);
  const { technicalSupport } = orgConfig;
  const [pastReportId, setPastReportId] = useState(null);
  const [usePastReportId, setUsePastReportId] = useState(null);
  const [pastReportIdx, setPastReportIdx] = useState(1);
  const reportId = props.displayReportId ? props.displayReportId : "null";
  const userPermission = determineUserAccess(props.currentUser.permission);
  const {
    loading: loadingReports,
    error: errorReports,
    data: displayReportsConnection,
  } = useQuery(CAMPAIGN_REPORTS_CONNECTION, {
    variables: {
      ID: props.campaignOrder ? props.campaignOrder.id : null,
      type: "CAMPAIGN_ORDER_DISPLAY",
    },
    onCompleted: data => {
      if (
        data.campaignReportsConnection &&
        data.campaignReportsConnection.edges
      )
        setPastReportIdx(data.campaignReportsConnection.edges.length);
    },
  });

  const currentReportId = useMemo(() => {
    return usePastReportId ? pastReportId : reportId;
  }, [pastReportId, reportId, usePastReportId]);

  try {
    return (
      <Query
        query={GET_CAMPAIGN_REPORT}
        variables={{
          reportId: currentReportId,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <OverlaySpinner />;
          if (error)
            return `Error (Query: GET_CAMPAIGN_REPORT)! ${error.message}`;
          const { url, expiresAt } = data.getCampaignReportSignedURL;
          return (
            <Controller
              refetch={refetch}
              url={url}
              expiresAt={expiresAt}
              userPermission={userPermission}
              setPastReportId={setPastReportId}
              setUsePastReportId={setUsePastReportId}
              displayReportsConnection={displayReportsConnection}
              currentReportId={currentReportId}
              setPastReportIdx={setPastReportIdx}
              pastReportIdx={pastReportIdx}
              usePastReportId={usePastReportId}
              loadingReports={loadingReports}
              errorReports={errorReports}
              technicalSupport={technicalSupport}
              {...props}
            >
              {children}
            </Controller>
          );
        }}
      </Query>
    );
  } catch (err) {
    console.log("err", err);
  }
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(DisplayTabDataLoader);
