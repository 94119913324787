import React from "react";
import Loader from "../../../../../../../../core/components/campaigns/campaigndetails/signupImpressions/SignupImpressionsLoader";
import View from "./SignupImpressionsView";

const SignupImpressions = props => {
  return (
    <Loader {...props}>
      <View />
    </Loader>
  );
};

export default SignupImpressions;
