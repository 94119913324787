import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";

// QUERIES
import GET_ORG_DEAL_IDS from "../../GraphQl/Queries/GET_ORG_DEAL_IDS";

const DealIdContext = createContext({});

export const DealIdProvider = props => {
  const { children } = props;
  const [
    fetchDealIds,
    { data: dataDealIds, loading: loadingDealIds },
  ] = useLazyQuery(GET_ORG_DEAL_IDS);

  return (
    <DealIdContext.Provider
      value={{
        fetchDealIds,
        dealIds:
          dataDealIds && dataDealIds.org && dataDealIds.org.dealIds
            ? dataDealIds.org.dealIds
            : [],
        loadingDealIds,
      }}
      {...props}
    >
      {children}
    </DealIdContext.Provider>
  );
};

export default DealIdContext;
