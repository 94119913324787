import React from "react";
import { Card, Row } from "antd";
import { isEmpty, inRange } from "lodash";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import moment from "moment";
// Global Styles
import {
  CardTitleText,
  CardText,
} from "../../../../../../shared/globalStyling/styledText";
// Helper functions
import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

import { ExtraInfo } from "./";

const Frag = React.Fragment;
const getTickInterval = data => {
  let range = 0;
  let interval = 10;
  const max = data.reduce((a, c) => {
    return Math.max(a, c.count);
  }, 0);

  while (!inRange(max, range, range + 100)) {
    range += 100;
    interval += 10;
  }
  return interval;
};

const explanation =
  "This chart displays the cumulative number of responses per day for the duration fo the campaign.";

const CumulativeResponseCard = ({ data, flightDates }) => {
  const scale = {
    count: {
      tickInterval: getTickInterval(data),
      min: 0,
    },
  };
  return (
    <Frag>
      <Card
        title={<CardTitleText text={"Cumulative Response"} />}
        style={{ height: "22em" }}
        headStyle={{ borderWidth: "2px" }}
        bodyStyle={{ padding: "2%", paddingBottom: "3%" }}
        bordered
        extra={<ExtraInfo placement="topRight" title={explanation} />}
      >
        <Row type="flex" justify="center">
          <CardText
            text={`${flightDates.start} - ${flightDates.end} (+ 30 days)`}
          />
        </Row>
        {isEmpty(data) ? (
          <Row type="flex" justify="center" align="middle">
            <CardText
              text={
                "Please refresh your browser to receive the latest updates."
              }
            />
          </Row>
        ) : (
          <Chart
            scale={scale}
            height={200}
            padding={["auto", "auto", "auto", "auto"]}
            data={data}
            onTooltipChange={ev => {
              let items = ev.items;
              const origin = items[0];
              items.splice(0);
              items.push({
                name: "Count",
                size: origin.size,
                showMarker: origin.showMarker,
                title: moment(origin.title).format("MMM Do YYYY"),
                marker: origin.marker,
                color: origin.color,
                value: formatWholeNumber(origin.value),
                x: origin.x,
                y: origin.y,
                point: origin.point,
              });
            }}
            forceFit
          >
            <Tooltip />
            <Axis
              name="day"
              tickLine={null}
              label={{
                autoRotate: false,
                formatter: text => moment(text).format("MMM DD"),
              }}
            />
            <Axis
              name="count"
              grid={null}
              label={{
                formatter(text) {
                  return formatWholeNumber(text);
                },
              }}
            />
            <Geom type="area" position="day*count" />
            <Geom type="line" position="day*count" />
          </Chart>
        )}
      </Card>
    </Frag>
  );
};

export default CumulativeResponseCard;
