import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-apollo";
import ORG_DETAILS_UPDATE from "../../../GraphQl/Mutations/ORG_DETAILS_UPDATE";
import GET_ORG_TABLE_CONFIG from "../../../GraphQl/Queries/GET_ORG_TABLE_CONFIG";
import GET_ORG_THEME_CONFIG from "../../../GraphQl/Queries/GET_ORG_THEME_CONFIG";

const SettingLoader = ({ selectedOrg, ...props }) => {
  const {
    data: orgTableConfig,
    loading: orgTableConfigLoading,
    error: orgTableConfigError,
    refetch: orgTableConfigRefetch,
  } = useQuery(GET_ORG_TABLE_CONFIG, {
    variables: { id: selectedOrg.id },
  });

  useEffect(() => {
    // Manually trigger refetch when the component mounts
    orgTableConfigRefetch();
  }, [orgTableConfigRefetch]);

  const {
    data: themeConfig,
    loading: themeConfigLoading,
    error: themeConfigError,
  } = useQuery(GET_ORG_THEME_CONFIG, {
    variables: { id: selectedOrg.id },
  });

  const [updateOrg] = useMutation(ORG_DETAILS_UPDATE);

  return React.cloneElement(props.children, {
    ...props.children.props,
    selectedOrg,
    orgTableConfig,
    themeConfig,
    orgTableConfigLoading,
    themeConfigLoading,
    orgTableConfigError,
    themeConfigError,
    updateOrg,
    orgTableConfigRefetch,
  });
};

export default SettingLoader;
