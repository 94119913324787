import React from "react";
import { useQuery } from "@apollo/react-hooks";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import ErrorPage from "../../../../../platform/shared/ErrorPage";

const AdvertiserDetailsTransactionsReportsLoader = props => {
  const { advertiser, deleteAdvertiser, permission } = props;

  const { isSelfService } = permission;
  const {
    data: signedURL,
    loading: signedURLsLoading,
    error: signedURLsError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: advertiser.reports.length > 0 ? advertiser.reports[0].key : `-`,
    },
    fetchPolicy: "no-cache",
  });

  if (signedURLsLoading) return <OverlaySpinner />;
  if (signedURLsError) return <ErrorPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertiser,
    signedURL,
    deleteAdvertiser,
    isSelfService,
  });
};

export default AdvertiserDetailsTransactionsReportsLoader;
