import { useState } from "react";
import { message } from "antd";

const customSnippets = snippet => {
  const woocommerce = `function woocommerce_tracker() { ?>

<!-- Header scripts go here -->
  ${snippet}
<!-- Header scripts go here -->    

<?php
  $product = array();
    global $wp_query;
    if( !empty( $wp_query->query_vars[ 'order-received' ] ) ) {
    $order = wc_get_order( $wp_query->query_vars[ 'order-received' ] );
    $items = $order->get_items();
    if(!empty($items)) {
      foreach($items as $item) {
        $product[] = $item->get_data();
      }
    }

    $check = get_post_meta( $order->get_id(), 'thank_you_page_check', true );
    if ( $check ) {
      return;
    }
    update_post_meta( $order->get_id(), 'thank_you_page_check', true );
?>
    <script> 
      var transactionOrder = <?php echo $order; ?>;
      var transactionItems = <?php echo json_encode($product); ?>;
    </script>
<?php }
}
add_action( 'wp_head', 'woocommerce_tracker' );`;

  const shopify = `{% if first_time_accessed %}
  window.Shopify.checkout.liquid_order_name = {{ order.order_number }}
  ${snippet}
{% endif %}`;

  return { woocommerce, shopify };
};

const useUniversalTag = ({ collectorUrl }) => {
  const [appId, setAppId] = useState(null);
  const [generatedTag, setGeneratedTag] = useState(null);
  const [version, setVersion] = useState("Stable");
  const [environment, setEnvironment] = useState("jane");
  const [withRetailId, setWithRetailId] = useState(false);
  const [retailId, setRetailId] = useState(null);

  const evaluateCollectorUrl = collectorUrl => {
    if (collectorUrl === "collector.dmp.mediajel.ninja") return "&test=true";
    if (collectorUrl === "collector.dmp.cnna.io") return "";
  };

  const evaluateVersion = version => {
    if (version === "Stable") return "tags.cnna.io";
    if (version === "Beta") return "tags.mediajel.ninja";
  };

  const evaluateScriptSrc = ({
    environment,
    versionParams,
    appId,
    collectorUrlParams,
  }) => {
    // No environment selected
    if (environment === "-1")
      return `https://${versionParams}/?appId=${appId}${collectorUrlParams}`;
    if (environment === "liquidm")
      return `https://${versionParams}/?appId=${appId}${collectorUrlParams}&event=impression&environment=liquidm&advertiserId={CUSTOMER_ID}&insertionOrder={CAMPAIGN_ID}&lineItemId=LiquidM_Main&creativeId={AD_NAME}&publisherId={PUBLISHER_ID}&publisherName={PUBLISHER_NAME}&siteId={APP_DOMAIN}&siteName={SITE_NAME}&liquidmAppId={APP_STOREURL}&appName={APP_NAME}&clickId={CLICK_ID}&GAID={GAID}&GAID_MD5={GAID_MD5}&GAID_SHA1={GAID_SHA1}&IDFA={IDFA}&IDFA_MD5={IDFA_MD5}&IDFA_SHA1={IDFA_SHA1}`;
    if (withRetailId && retailId)
      return `https://${versionParams}/?appId=${appId}&retailId=${retailId}&environment=${environment}${collectorUrlParams}`;
    else
      return `https://${versionParams}/?appId=${appId}&environment=${environment}${collectorUrlParams}`;
  };

  const evaluateSnippet = ({ environment, snippet }) => {
    if (environment === "woocommerce")
      return customSnippets(snippet).woocommerce;
    if (environment === "shopify") return customSnippets(snippet).shopify;
    return snippet;
  };

  const generateSnippet = () => {
    if (!appId) {
      message.error("All fields must be filled out to generate this snippet");
      return;
    }

    const collectorUrlParams = evaluateCollectorUrl(collectorUrl);
    const versionParams = evaluateVersion(version);
    const scriptSrc = evaluateScriptSrc({
      environment,
      versionParams,
      appId,
      collectorUrlParams,
    });
    const snippet = `<script src='${scriptSrc}'> </script>`;
    const tagSnippet = evaluateSnippet({ environment, snippet });
    setGeneratedTag(tagSnippet);
  };

  return {
    appId,
    setAppId,
    generatedTag,
    setGeneratedTag,
    version,
    setVersion,
    environment,
    setEnvironment,
    generateSnippet,
    withRetailId,
    setWithRetailId,
    retailId,
    setRetailId,
  };
};

export default useUniversalTag;
