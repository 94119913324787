import React from "react";
import { Table, Avatar } from "antd";

const SourceTable = props => {
  return (
    <Table
      pagination={false}
      columns={[
        {
          title: "Source",
          dataIndex: "source",
          render: (text, record) => {
            return (
              <div style={{ display: "flex" }}>
                <Avatar src={record.src} shape="square" size="large" />
                <div>
                  <h4 style={{ margin: "0 0 0 7px", fontWeight: "normal" }}>
                    {text}
                  </h4>
                  <h5 style={{ margin: "0 0 0 7px", color: "#c2c9d6" }}>
                    id: 1038
                  </h5>
                </div>
              </div>
            );
          },
        },
      ]}
      dataSource={[
        {
          key: "1",
          source: "AppNexus",
          //temp image source
          src: "https://wiredwith.com/images/tech_stack/AppNexus.svg",
        },
      ]}
    />
  );
};

export default SourceTable;
