import React from "react";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import ReferrerDetails from "./components/ReferrerDetails";

const Referrers = ({ colStyle, chartsLoading, referrerData }) => {
  if (chartsLoading)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Card>
    );
  if (isEmpty(referrerData)) return <div />;

  return <ReferrerDetails referrerData={referrerData} colStyle={colStyle} />;
};

export default Referrers;
