import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import TopPageViewTable from "./TopPageViewTable";
import PageViewDonutChart from "./PageViewDonutChart";
import { colors } from "../../../../../core/utils/constants/listOfColors";
import { formatCurrency2SigFig } from "../../../../../core/utils/campaigns";

const TopPageViewed = ({ data = [], loading = true, setTopReport }) => {
  const [pageViewData, setPageViewData] = useState([]);
  const [selectedTop, setSelectedTop] = useState("top5");
  const [title, setTitle] = useState();
  const [topData, setTopData] = useState([]);

  useEffect(() => {
    if (pageViewData && pageViewData.length > 0) {
      if (selectedTop === "top5") {
        setTopData(pageViewData.slice(0, 5));
        setTitle("Top 5 Pages");
        setTopReport(
          pageViewData.slice(0, 5).map(el => {
            return {
              Page: el.value,
              Transactions: el.counts,
              Revenue: formatCurrency2SigFig(el.revenue),
            };
          })
        );
      } else if (selectedTop === "top10") {
        setTopData(pageViewData.slice(0, 10));
        setTitle("Top 10 Pages");
        setTopReport(
          pageViewData.slice(0, 10).map(el => {
            return {
              Page: el.value,
              Transactions: el.counts,
              Revenue: formatCurrency2SigFig(el.revenue),
            };
          })
        );
      } else if (selectedTop === "top20") {
        setTopData(pageViewData.slice(0, 20));
        setTitle("Top 20 Pages");
        setTopReport(
          pageViewData.slice(0, 20).map(el => {
            return {
              Page: el.value,
              Transactions: el.counts,
              Revenue: formatCurrency2SigFig(el.revenue),
            };
          })
        );
      } else {
        //Uncomment if all list is needed
        setTopData(pageViewData);
        setTitle("All Pages");
        setTopReport(
          pageViewData.map(el => {
            return {
              Page: el.value,
              Transactions: el.counts,
              Revenue: formatCurrency2SigFig(el.revenue),
            };
          })
        );
      }
    } else {
      setTopData([]);
      setTitle("Top 5 Pages");
      setTopReport([]);
    }
  }, [pageViewData, selectedTop, setTopReport]);

  useEffect(() => {
    if (data && data.length > 0) {
      let accPageViewData = [];
      data.forEach(el => {
        //Remove duplicate pageURLs
        const clicks = el.clicksList.filter(
          (value, index, self) =>
            index === self.findIndex(t => t.pageURL === value.pageURL)
        );
        let currPageViewData = clicks.reduce((acc, curr) => {
          const idx = acc.findIndex(el => {
            let url = curr.pageURL;
            if (url.endsWith("/")) {
              url = url.slice(0, -1);
            }
            return el.value === url;
          });
          if (idx > -1) {
            acc[idx].counts += 1;
            acc[idx].revenue += Number(el.total);
          } else {
            acc.push({
              value: curr.pageURL,
              counts: 1,
              color: colors[acc.length],
              revenue: Number(el.total),
            });
          }

          return acc;
        }, accPageViewData);
        accPageViewData = currPageViewData;
      });

      accPageViewData = accPageViewData.sort((a, b) => b.counts - a.counts);
      setPageViewData(accPageViewData);
    }
  }, [data]);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={16}>
        <Spin spinning={loading}>
          <TopPageViewTable
            topData={topData}
            title={title}
            setSelectedTop={setSelectedTop}
            selectedTop={selectedTop}
          />
        </Spin>
      </Col>
      <Col xs={24} md={8}>
        <Spin spinning={loading}>
          <PageViewDonutChart data={topData} />
        </Spin>
      </Col>
    </Row>
  );
};

export default TopPageViewed;
