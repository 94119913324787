import { Button, Card, Col, Row, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import DetailBar from "../components/DetailBar";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DesktopOutlined,
  MobileOutlined,
  MonitorOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { formatNumber2SigFig } from "../../../../../core/utils/campaigns";

const { Text } = Typography;
const RankImproveIcon = (currentRank, compareRank) => {
  if (currentRank === compareRank) {
    return compareRank;
  } else if (currentRank < compareRank) {
    const diff = Math.abs(currentRank - compareRank);
    return (
      <Space>
        {compareRank}
        <Text type="success">
          <ArrowUpOutlined />
          {formatNumber2SigFig(diff)}
        </Text>
      </Space>
    );
  } else {
    const diff = Math.abs(compareRank - currentRank);
    return (
      <Space>
        {compareRank}
        <Text type="danger">
          <ArrowDownOutlined />
          {formatNumber2SigFig(diff)}
        </Text>
      </Space>
    );
  }
};

const KeywordRankingsView = ({ rankings, generateKeywordRankingsSummary }) => {
  const {
    keywordReportRankings,
    keywordRankingsSummary,
    desktopKeywordReportRankings,
    desktopKeywordRankingsSummary,
    mobileKeywordReportRankings,
    mobileKeywordRankingsSummary,
    tabletKeywordReportRankings,
    tabletKeywordRankingsSummary,
  } = rankings;

  const [summaryDetails, setSummaryDetails] = useState(
    generateKeywordRankingsSummary(keywordRankingsSummary)
  );
  const [keywordTableRankings, setKeywordTableRankings] = useState(
    keywordReportRankings
  );
  const [selectedDevice, setSelectedDevice] = useState("all");

  let columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: text => text,
    },
    {
      title: "Current Rank",
      dataIndex: "currentRank",
      key: "currentRank",
      render: text => {
        return text ? text : "-";
      },
    },
    {
      title: "Previous Week",
      dataIndex: "lastWeekRank",
      key: "lastWeekRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
      },
    },
    {
      title: "Previous Month",
      dataIndex: "lastMonthRank",
      key: "lastMonthRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
      },
    },
  ];

  if (selectedDevice === "all") {
    columns = [
      ...columns,
      {
        title: "Desktop",
        dataIndex: "desktopRank",
        key: "desktopRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
      {
        title: "Mobile",
        dataIndex: "mobileRank",
        key: "mobileRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
      {
        title: "Tablet",
        dataIndex: "tabletRank",
        key: "tabletRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? <>{RankImproveIcon(currentRank, text)}</> : "-";
        },
      },
    ];
  }
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col xs={24}>
          <DetailBar dataToDisplay={summaryDetails} />
        </Col>
        <Col xs={24}>
          <Card
            title={"Keyword Rankings"}
            extra={
              <Row gutter={6}>
                <Col>
                  <Button
                    icon={<MonitorOutlined />}
                    type={selectedDevice === "all" ? "primary" : "default"}
                    shape="round"
                    onClick={() => {
                      setKeywordTableRankings(keywordReportRankings);
                      setSummaryDetails(
                        generateKeywordRankingsSummary(keywordRankingsSummary)
                      );
                      setSelectedDevice("all");
                    }}
                  >
                    All Devices
                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<DesktopOutlined />}
                    type={selectedDevice === "desktop" ? "primary" : "default"}
                    shape="round"
                    onClick={() => {
                      setKeywordTableRankings(desktopKeywordReportRankings);
                      setSummaryDetails(
                        generateKeywordRankingsSummary(
                          desktopKeywordRankingsSummary
                        )
                      );
                      setSelectedDevice("desktop");
                    }}
                  >
                    Desktop
                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<MobileOutlined />}
                    type={selectedDevice === "mobile" ? "primary" : "default"}
                    shape="round"
                    onClick={() => {
                      setKeywordTableRankings(mobileKeywordReportRankings);
                      setSummaryDetails(
                        generateKeywordRankingsSummary(
                          mobileKeywordRankingsSummary
                        )
                      );
                      setSelectedDevice("mobile");
                    }}
                  >
                    Mobile
                  </Button>
                </Col>
                <Col>
                  <Button
                    icon={<TabletOutlined />}
                    type={selectedDevice === "tablet" ? "primary" : "default"}
                    shape="round"
                    onClick={() => {
                      setKeywordTableRankings(tabletKeywordReportRankings);
                      setSummaryDetails(
                        generateKeywordRankingsSummary(
                          tabletKeywordRankingsSummary
                        )
                      );
                      setSelectedDevice("tablet");
                    }}
                  >
                    Tablet
                  </Button>
                </Col>
              </Row>
            }
          >
            <Table
              rowKey={row => row.keyword}
              columns={columns}
              dataSource={keywordTableRankings}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KeywordRankingsView;
