import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import LocationTab from "../../OrgConfig/components/LocationTab";

const RetailStoresMainView = ({
  currentuser,
  locationList,
  parentState,
  changeLocationMap,
  onChange,
  onCsvUploadComplete,
  createLocation,
  deleteLocation,
  editRetailLocation,
  resetParentState,
  updateOrgLocation,
  cartProvider,
  onChangeStateValue,
  selectedLocations,
  onSubmitAudience,
  attributionAudienceName,
  selectedOrg,
  attributionAudiences,
  google,
  deleteAudience,
}) => {
  //Title shown on browser tab
  document.title = "Retail Stores Main View";

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<EnvironmentOutlined />}
        title={"Retail Stores"}
      />
      <PageContentLayout>
        <Card>
          <LocationTab
            locationList={locationList}
            parentState={parentState}
            changeLocationMap={changeLocationMap}
            onChange={onChange}
            onCsvUploadComplete={onCsvUploadComplete}
            createLocation={createLocation}
            deleteLocation={deleteLocation}
            editRetailLocation={editRetailLocation}
            resetParentState={resetParentState}
            updateOrgLocation={updateOrgLocation}
            cartProvider={cartProvider}
            onChangeStateValue={onChangeStateValue}
            selectedLocations={selectedLocations}
            onSubmitAudience={onSubmitAudience}
            attributionAudienceName={attributionAudienceName}
            selectedOrgId={selectedOrg && selectedOrg.id}
            attributionAudiences={attributionAudiences}
            currentuser={currentuser}
            google={google}
            deleteAudience={deleteAudience}
          />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default RetailStoresMainView;
