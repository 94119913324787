import gql from "graphql-tag";

const CREATE_GEOTARGET_LOCATION = gql`
  mutation createGeoTargetLocation(
    $name: String
    $orgID: ID
    $isBase: Boolean
    $circles: [CircleCreateWithoutGeoTargetInput!]
    $polygons: [PolygonCreateWithoutGeoTargetInput!]
    $start: DateTime
    $end: DateTime
  ) {
    createGeoTarget(
      data: {
        name: $name
        orgs: { connect: { id: $orgID } }
        circles: { create: $circles }
        polygons: { create: $polygons }
        isBase: $isBase
        start: $start
        end: $end
      }
    ) {
      id
      name
      orgs {
        name
      }
      isBase
      start
      end
    }
  }
`;

export default CREATE_GEOTARGET_LOCATION;
