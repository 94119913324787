export const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  dateRangeColStyle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
};
