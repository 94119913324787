import { Card, Tooltip, Row, Progress } from "antd";
import React from "react";
import { KPITitleText, KpiFlightDate } from "./globalStyling/styledText";
import { ArrowRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const styles = {
  title: {
    marginBottom: "10px",
  },
  card: { height: "10em", borderLeft: 0, borderTop: 0 },
  body: { borderLeft: 0 },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
const DetailBarCardDateRange = ({ title, tooltip, startDate, endDate }) => {
  const getProgress = () => {
    const total = () => {
      var a = moment(startDate);
      var b = moment(endDate);
      const total = b.diff(a, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(startDate);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();

    if (progressDays <= 0) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;

    return Math.floor(result);
  };

  const currentposition = getProgress();
  return (
    <Card style={styles.card} bodyStyle={styles.body}>
      <div style={styles.title}>
        <Row justify="space-between" align="top" wrap={true}>
          <KPITitleText text={title} />
          {tooltip && (
            <Tooltip
              title={tooltip}
              arrowPointAtCenter={true}
              placement="rightBottom"
            >
              <QuestionCircleOutlined type="question-circle" />
            </Tooltip>
          )}
        </Row>
      </div>
      <div style={styles.datesContainer}>
        <div style={styles.dates}>
          <KpiFlightDate
            text={
              startDate
                ? moment(startDate)
                    .utc(true)
                    .format("MM/DD/YYYY")
                : moment()
                    .utc(true)
                    .format("MM/DD/YYYY")
            }
          />
        </div>
        <div>
          <ArrowRightOutlined style={{ color: "#636363" }} />
        </div>
        <div style={styles.dates}>
          <KpiFlightDate
            text={
              endDate
                ? moment(endDate)
                    .utc(true)
                    .format("MM/DD/YYYY")
                : moment()
                    .utc(true)
                    .format("MM/DD/YYYY")
            }
          />
        </div>
      </div>
      <Progress
        showInfo={false}
        percent={currentposition}
        status={currentposition !== 100 ? "active" : "normal"}
        strokeColor={"#636363"}
      />
    </Card>
  );
};

export default DetailBarCardDateRange;
