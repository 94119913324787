import React from "react";
import { Progress, Row, Col, Card } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { ExtraInfo } from "../tabs/attribution/components";
import { processPercentageDisplay } from "../utils";
// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiFlightDate,
  KpiPercentageText,
} from "../../../../shared/globalStyling/styledText";

import {
  ctrFromAggregateData,
  formatNumber,
  formatWholeNumber,
} from "../../../../../core/utils/campaigns";
import { ArrowRightOutlined } from "@ant-design/icons";

const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  overflow: {
    height: "70px",
    overflow: "auto",
  },
};

const CampaignDetailBar = ({
  selectedCampaign,
  selectedProvider,
  byCampaignRangeDate,
  dataCampaignRangeDate,
  providerIndex,
  providersTab,
}) => {
  const { startDate, endDate, aggregateData } = selectedCampaign;

  dataCampaignRangeDate = dataCampaignRangeDate.map(data => {
    return {
      ...data,
      xAxis: moment(data.xAxis)
        .utc()
        .format("YYYY-MM-DD"),
    };
  });

  const getProviderIndex = providerIndex !== "All" ? providerIndex : 0;

  let kpiPercentage = {
    percentages: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
    },
    ranges: "",
  };
  //const getValidateProvidersTab = providersTab && providersTab[parseInt(getProviderIndex)][1] && providersTab[parseInt(getProviderIndex)][1].overallData

  const clicks =
    providersTab &&
    !isEmpty(providersTab[parseInt(getProviderIndex)]) &&
    !isEmpty(providersTab[parseInt(getProviderIndex)][1]) &&
    providersTab[parseInt(getProviderIndex)][1].overallData.clicks;

  const impressions =
    providersTab &&
    !isEmpty(providersTab[parseInt(getProviderIndex)]) &&
    !isEmpty(providersTab[parseInt(getProviderIndex)][1]) &&
    providersTab[parseInt(getProviderIndex)][1].overallData.impressions;

  // get selected start and end date from selected date picker
  const getSelectedStartDate =
    byCampaignRangeDate && byCampaignRangeDate.length > 0
      ? moment(byCampaignRangeDate[0]).format("MM/DD/YYYY")
      : null;
  const getSelectedEndDate =
    byCampaignRangeDate && byCampaignRangeDate.length > 0
      ? moment(byCampaignRangeDate[1]).format("MM/DD/YYYY")
      : null;

  if (!isEmpty(providersTab) && dataCampaignRangeDate) {
    kpiPercentage = processPercentageDisplay(
      [getSelectedStartDate, getSelectedEndDate],
      dataCampaignRangeDate,
      providersTab[parseInt(getProviderIndex)][1].byDate
    );
  }

  // get impression object and clicks
  const getImpressions = item => {
    return item.impressions;
  };
  const getClicks = item => {
    return item.clicks;
  };
  const sumTotal = (prev, next) => {
    return prev + next;
  };

  // get total impressions or clicks by the selected dates
  const getTotalImpressions = isEmpty(dataCampaignRangeDate)
    ? null
    : dataCampaignRangeDate.map(getImpressions).reduce(sumTotal);
  const getTotalClicks = isEmpty(dataCampaignRangeDate)
    ? null
    : dataCampaignRangeDate.map(getClicks).reduce(sumTotal);

  // calculate ctr with the selected impressions and clicks by datepicker
  const ctrFromSelectedDate = () => {
    let ctr = 0;
    let clicks = 0;
    let impressions = 0;

    if (getTotalClicks && getTotalImpressions) {
      clicks = clicks + getTotalClicks;
      impressions = impressions + getTotalImpressions;
    }

    if (clicks && impressions) {
      ctr = clicks / impressions;
    }

    return ctr * 100;
  };

  //Break into utils
  const getProgress = () => {
    const total = () => {
      var a = moment(endDate);
      var b = moment(startDate);
      const total = a.diff(b, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(startDate);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();
    if (progressDays > totaldays || progressDays === totaldays) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;
    return Math.floor(result);
  };

  const currentposition = getProgress();

  const buildCTRComponent = () => {
    return (
      <React.Fragment>
        <div style={styles.title}>
          <KPITitleText text={"CTR"} />
        </div>
        <KpiSubText
          text={
            aggregateData
              ? isEmpty(byCampaignRangeDate)
                ? ` ${formatNumber(ctrFromAggregateData(aggregateData))}%`
                : ` ${formatNumber(ctrFromSelectedDate())}%`
              : " N/A "
          }
        />
        <KpiPercentageText
          text={percentages.ctr}
          prev={`${formatNumber(previousValues.ctr)}%`}
          ranges={ranges}
        />
      </React.Fragment>
    );
  };

  const buildImpressionCount = () => {
    return providerIndex === "All"
      ? aggregateData
        ? isEmpty(dataCampaignRangeDate)
          ? `${formatWholeNumber(aggregateData.overallData.aggImpressions)}`
          : `${formatWholeNumber(getTotalImpressions)}`
        : " N/A"
      : !isEmpty(dataCampaignRangeDate)
      ? `${formatWholeNumber(getTotalImpressions)}`
      : formatWholeNumber(impressions);
  };

  const buildClicksCount = () => {
    return providerIndex === "All"
      ? aggregateData
        ? isEmpty(dataCampaignRangeDate)
          ? `${formatWholeNumber(aggregateData.overallData.aggClicks)}`
          : `${formatWholeNumber(getTotalClicks)}`
        : " N/A"
      : !isEmpty(dataCampaignRangeDate)
      ? `${formatWholeNumber(getTotalClicks)}`
      : formatWholeNumber(clicks);
  };
  const { percentages, previousValues, ranges } = kpiPercentage;
  return (
    <Row style={styles.container} type="flex">
      <Col xl={6} lg={6} md={12} xs={24}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Flight Dates"} />
            <span>
              <ExtraInfo
                title={
                  "Flight Dates refers to the Start & End Dates of the individual campaign"
                }
                placement={"top"}
              />
            </span>
          </div>
          <div style={styles.datesContainer}>
            <div style={styles.dates}>
              <KpiFlightDate
                text={startDate ? moment(startDate).format("MM/DD/YYYY") : ""}
              />
            </div>
            <div>
              <ArrowRightOutlined style={{ color: "#636363" }} />
            </div>
            <div style={styles.dates}>
              <KpiFlightDate
                text={endDate ? moment(endDate).format("MM/DD/YYYY") : ""}
              />
            </div>
          </div>
          <Progress
            showInfo={false}
            percent={currentposition}
            status={currentposition !== 100 ? "active" : "normal"}
            strokeColor={"#636363"}
          />
        </Card>
      </Col>
      <Col xl={6} lg={6} md={12} xs={24}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Impressions"} />
          </div>
          <KpiSubText text={buildImpressionCount()} />
          <KpiPercentageText
            text={percentages.impressions}
            prev={formatWholeNumber(previousValues.impressions)}
            ranges={ranges}
          />
        </Card>
      </Col>
      <Col xl={6} lg={6} md={12} xs={24}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Clicks"} />
          </div>
          <KpiSubText text={buildClicksCount()} />
          <KpiPercentageText
            text={percentages.clicks}
            prev={formatWholeNumber(previousValues.clicks)}
            ranges={ranges}
          />
        </Card>
      </Col>
      <Col xl={6} lg={6} md={12} xs={24}>
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          {buildCTRComponent()}
        </Card>
      </Col>
    </Row>
  );
};
export default CampaignDetailBar;
