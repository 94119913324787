import React from "react";
import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";

const RevenueByDateLineChart = ({ data, mediums }) => {
  const colors = {};
  mediums.forEach(m => {
    colors[m.medium] = m.color;
  });

  const dv = new DataSet.View().source(data);
  dv.transform({
    type: "fold",
    fields: [...mediums.map(m => m.medium), "total"],
    key: "channel",
    value: "revenue",
  });

  const scale = {
    revenue: {
      min: 0,
      alias: "Revenue",
    },
    date: {
      range: [0, 1],
    },
  };

  return (
    <Chart height={400} data={dv} scale={scale} forceFit>
      <Legend />
      <Axis name="date" />
      <Axis name="revenue" />
      <Tooltip crosshairs={{ type: "y" }} />
      <Geom
        type="line"
        position="date*revenue"
        size={0}
        color={[
          "channel",
          channel =>
            channel === "total" ? "#FF0000" : colors[channel] || "#000000",
        ]}
      />
      <Geom
        type="point"
        position="date*revenue"
        size={data.length > 400 ? 1.7 : 3}
        shape={"circle"}
        color={[
          "channel",
          channel =>
            channel === "total" ? "#FF0000" : colors[channel] || "#000000",
        ]}
        style={{ stroke: "#fff", lineWidth: 1 }}
      />
    </Chart>
  );
};

export default RevenueByDateLineChart;
