import gql from "graphql-tag";

const GET_PARENT_ORG = gql`
  query org($loggedInOrg: ID, $orgName: String) {
    org(where: { id: $loggedInOrg, name: $orgName }) {
      id
      name
      level
      parentOrg {
        id
        name
        level
      }
    }
  }
`;

export default GET_PARENT_ORG;
