import gql from "graphql-tag";
const CREATE_CAMPAIGN_HISTORY = gql`
  mutation createCampaignHistory(
    $action: CampaignHistoryAction!
    $property: CampaignHistoryProperty!
    $data: Json
    $authorId: ID!
    $campaignOrderId: ID!
  ) {
    createCampaignHistory(
      data: {
        action: $action
        property: $property
        data: $data
        author: { connect: { id: $authorId } }
        campaignOrder: { connect: { id: $campaignOrderId } }
      }
    ) {
      id
      action
      author {
        id
        name
        username
        avatar {
          id
          key
        }
      }
      property
      campaignOrder {
        id
      }
      data
      createdAt
      updatedAt
    }
  }
`;

export default CREATE_CAMPAIGN_HISTORY;
