import gql from "graphql-tag";

const EVENTS_TARGETS_CONNECTION = gql`
  query eventsTargetsConnection(
    $first: Int
    $skip: Int
    $orderBy: EventsTargetOrderByInput
    $where: EventsTargetWhereInput
  ) {
    eventsTargetsConnection(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          description
          start
          end
          observations
          uniqueDevices
          tags
          eventTags {
            id
            data
          }
        }
      }
      aggregate {
        count
      }
    }
    full: eventsTargetsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default EVENTS_TARGETS_CONNECTION;
