import gql from "graphql-tag";

const UPDATE_USER_EMBEDDABLE_CONFIG = gql`
  mutation updateUserConfig($id: ID!, $data: UserConfigUpdateInput!) {
    updateUserConfig(where: { id: $id }, data: $data) {
      id
      embeddableConfig {
        type
        ssoToken
        campaignOrder {
          id
          name
        }
        advertiser {
          id
          name
        }
        publisher {
          id
          name
        }
        widgetConfig {
          transactionChart
          transactionTable
        }
      }
    }
  }
`;

export default UPDATE_USER_EMBEDDABLE_CONFIG;
