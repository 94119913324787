import React, { useState } from "react";
import { capitalize } from "lodash";
import PaginationTable from "../../../../shared/PaginationTable";
import { Row, Col, Progress } from "antd";
import moment from "moment";
import { compact } from "lodash";
//Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  PaginationText,
  FlightDateText,
} from "../../../../shared/globalStyling/styledText";
import { kmToFt } from "../../../../../core/utils/campaigns";

export const GeoTargetsTable = ({
  geoTargets,
  targetType,
  selectedForBatchChanged,
  geoTargetOnClick,
  hideRowSelection,
  showRowStatus,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setCurrentPageSize] = useState(10);

  const getLocationName = record => {
    if (
      record.circles &&
      record.circles[0] &&
      record.circles[0].location &&
      record.circles[0].location.name
    ) {
      return record.circles[0].location.name;
    }
    return "-";
  };

  const cols = [
    {
      title: <TableColumnTitle text={"Name"} />,
      align: "left",
      dataIndex: "name",
      width: "17%",
      key: "name",
      footerContent: "Totals + Averages",
      render: (text, record) => (
        <div style={{ minWidth: "80px" }}>
          <PrimaryTableRowText
            text={text !== "" ? text : getLocationName(record)}
          />
        </div>
      ),
      sorter: (a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      },
    },
    // {
    //   title: <TableColumnTitle text={"Status"} />,
    //   dataIndex: "status",
    //   key: "status",
    // render: text => <PrimaryTableRowText text={text} />,
    // },
    {
      title: <TableColumnTitle text={"Street Address"} />,
      align: "left",
      dataIndex: "address",
      width: "15%",
      key: "address",
      footerContent: "---",
      render: (text, record) => {
        let address = "-";
        if (record.formattedAddress) {
          address = record.formattedAddress;
        } else if (text) {
          address = text;
        } else if (
          record.circles &&
          record.circles[0] &&
          record.circles[0].location &&
          record.circles[0].location.formattedAddress
        ) {
          address = record.circles[0].location.formattedAddress;
        }
        return <PrimaryTableRowText text={address} />;
      },
      sorter: (a, b) => {
        if (a.address > b.address) return 1;
        if (a.address < b.address) return -1;
        return 0;
      },
    },
    {
      title: (
        <div style={{ minWidth: "50px" }}>
          <TableColumnTitle text={"State"} />
        </div>
      ),
      align: "left",
      dataIndex: "state",
      width: "8%",
      key: "state",
      footerContent: "---",
      render: (text, record) => (
        <PrimaryTableRowText
          text={
            text
              ? capitalize(text)
              : (record.circles &&
                  record.circles[0] &&
                  record.circles[0].location &&
                  record.circles[0].location.state) ||
                "-"
          }
        />
      ),
      sorter: (a, b) => {
        if (a.state > b.state) return 1;
        if (a.state < b.state) return -1;
        return 0;
      },
    },
    {
      title: <TableColumnTitle text={"City"} />,
      align: "left",
      dataIndex: "city",
      width: "10%",
      key: "city",
      footerContent: "---",
      sorter: (a, b) => {
        if (a.city > b.city) return 1;
        if (a.city < b.city) return -1;
        return 0;
      },
      render: (text, record) => {
        return (
          <PrimaryTableRowText
            text={
              text
                ? text
                : (record.circles &&
                    record.circles.length &&
                    record.circles[0].location.city) ||
                  "-"
            }
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Zip"} />,
      align: "left",
      dataIndex: "zip",
      width: "8%",
      key: "zip",
      footerContent: "---",
      render: (text, record) => (
        <PrimaryTableRowText
          text={
            text
              ? text
              : (record.circles &&
                  record.circles.length &&
                  record.circles[0].location.city) ||
                "-"
          }
        />
      ),
      sorter: (a, b) => {
        if (a.zip > b.zip) return 1;
        if (a.zip < b.zip) return -1;
        return 0;
      },
    },
    // {
    //   title: <TableColumnTitle text={"Walk-In Rate"} />,
    //   dataIndex: "walkInRate",
    //   align: "left",
    //   key: "walkInRate",
    //   sorter: (a, b) => {
    //     return a.walkInRate - b.walkInRate;
    //   },
    //   render: text => (
    //     <PrimaryTableRowText
    //       text={text ? `${formatNumber4SigFig(text)}%` : "-"}
    //     />
    //   ),
    // },
    // {
    //   title: <TableColumnTitle text={"Avg. Imp. To Walk-In"} />,
    //   dataIndex: "avgImp",
    //   align: "left",
    //   key: "avgImp",
    //   sorter: (a, b) => {
    //     return a.avgImp - b.avgImp;
    //   },
    //   render: text => (
    //     <PrimaryTableRowText text={text ? formatWholeNumber(text) : "-"} />
    //   ),
    // },
    {
      title: <TableColumnTitle text={"Radius (Meters)"} />,
      align: "left",
      dataIndex: "radius",
      width: "11%",
      key: "radius",
      footerContent: "---",
      sorter: (a, b) => {
        return a.radius - b.radius;
      },
      render: (text, record) => {
        let radius = "-";
        if (text) {
          radius = text;
        } else if (
          record.circles &&
          record.circles[0] &&
          record.circles[0].radius
        ) {
          radius = record.circles[0].radius;
        }
        return (
          <PrimaryTableRowText
            text={
              Math.round(kmToFt(radius)) ||
              (record.type === "Circle" ? "-" : "")
            }
          />
        );
      },
    },
  ];

  const attributionCols = [
    {
      title: <TableColumnTitle text={"Flight"} />,
      dataIndex: "start",
      key: "start",
      align: "left",
      width: "14%",
      footerContent: " ",
      render: (text, record) => {
        if (!text) {
          return <PrimaryTableRowText text="-" />;
        }
        const currentDate = moment();
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <React.Fragment>
            <Row type="flex" justify="start">
              {record.start && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.end && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </React.Fragment>
        );
      },
      sorter: true,
    },
  ];

  const getColumns = () => {
    if (targetType === "Attribution") {
      return [...cols, ...attributionCols];
    } else {
      return cols;
    }
  };

  const rowSelection = hideRowSelection
    ? null
    : {
        onChange: (key, row) => {
          if (!selectedForBatchChanged) return;
          selectedForBatchChanged(row);
        },
        columnWidth: "3%",
      };

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        if (geoTargetOnClick) geoTargetOnClick(record);
      },
    };
  };

  const pagination = {
    hideOnSinglePage: true,
    size: "default",
    itemRender: (current, type, originalElement) => {
      if (type === "page") return <PaginationText text={current} />;
      return originalElement;
    },
  };

  const targets = compact(geoTargets);

  return targets.length ? (
    <PaginationTable
      total={targets.length}
      pageSize={pageSize}
      rowKey={record => record.key || record.id}
      footer={false}
      scroll={{ x: 780 }}
      current={currentPage}
      bordered={false}
      data={targets}
      columns={getColumns()}
      rowSelection={rowSelection}
      pagination={pagination}
      onRow={onRow}
      onChange={paginationObject => {
        setCurrentPageSize(paginationObject.pageSize);
        setCurrentPage(paginationObject.current);
      }}
      showSizeChanger={true}
      rowClassName={record => {
        if (showRowStatus) {
          switch (record.status) {
            case "PENDING_CREATE":
              return "tableSuccessRow";
            case "PENDING_DELETE":
              return "tableDangerRow";
            case "PENDING_UPDATE":
              return "tableWarningRow";

            default:
              break;
          }
        }
      }}
    />
  ) : (
    ""
  );
};
