import gql from "graphql-tag";

const DELETE_ARTICLE = gql`
  mutation deleteArticle($id: ID) {
    deleteArticle(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_ARTICLE;
