import React from "react";
import RollUpSEODataLoader from "../../../../../core/components/home/RollUp/SEO/RollUpSEODataLoader";
import RollUpSEOView from "./RollUpSEOView";

const RollUpSEO = props => {
  return (
    <RollUpSEODataLoader {...props}>
      <RollUpSEOView />
    </RollUpSEODataLoader>
  );
};

export default RollUpSEO;
