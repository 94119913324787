import gql from "graphql-tag";

const LOCATION = gql`
  query Locations($where: LocationWhereInput) {
    locations(where: $where) {
      id
      name
      county
      contact
      city
      description
      createdAt
      country
      audienceType
      email
      formattedAddress
      lat
      lng
      locationType
      phone
      province
      state
      street
      updatedAt
      url
      zip
      locationKey
      geoframe {
        id
        name
        start
        end
        polygons {
          id
          geometry
        }
        circles {
          id
          coordinate {
            id
            lat
            lng
          }
          radius
        }
      }
    }
  }
`;

export default LOCATION;
