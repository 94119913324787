import React from "react";

import moment from "moment";
// Local Imports
import PaymentView from "../../../platform/ux/Payment/PaymentView";

import { formatCurrency2SigFig } from "../../../core/utils/campaigns";
class Controller extends React.Component {
  state = {
    paymentInfo: [],
    invoiceList: [],
    loading: true,
    cardModal: false,
    nonce: null,
    tableLoading: true,
    totalPaid: "$0.00",
    totalUnpaid: "$0.00",
    totalOverdue: "$0.00",
    totalTransaction: "0",
    filteredInfo: ["UNPAID"],
    invoiceFilterList: [],
  };

  componentDidMount = () => {
    this.handleInvoice();
  };

  handleInvoice = async () => {
    const {
      currentUser: { role },
      listInvoice,
    } = this.props;
    const {
      org: { paymentConfig },
    } = role;
    this.setState({ tableLoading: true });

    if (paymentConfig && paymentConfig.length > 0) {
      try {
        const response = await listInvoice(paymentConfig[0].providerId);
        let paid = 0.0,
          unpaid = 0.0,
          overdueAmount = 0.0;
        const { filteredInfo } = this.state;
        const dataRaw =
          response.data &&
          response.data.listInvoice &&
          response.data.listInvoice.map(invoice => {
            const {
              invoice_number,
              title,
              public_url,
              status,
              created_at,
              payment_requests,
            } = invoice;

            const {
              due_date,
              computed_amount_money,
              total_completed_amount_money,
            } = payment_requests[0];

            let amount = computed_amount_money.amount / 100;
            let partialPay = total_completed_amount_money.amount / 100;
            let balance = amount - partialPay;
            let duration = moment.duration(
              moment(new Date()).diff(new Date(due_date))
            );
            let overdue = Math.ceil(duration.asDays());

            switch (status) {
              case "PAID":
                paid = paid + parseFloat(amount);
                break;
              case "UNPAID":
                unpaid = unpaid + parseFloat(amount);
                if (overdue > 0) {
                  overdueAmount = overdueAmount + parseFloat(amount);
                }
                break;

              default:
                break;
            }

            return {
              key: invoice_number,
              issuedDate: moment(created_at).format("MM/DD/Y"),
              dueDate: moment(due_date).format("MM/DD/Y"),
              invoice_number,
              title,
              amount: formatCurrency2SigFig(amount),
              balance: balance !== 0 ? formatCurrency2SigFig(balance) : "-",
              overdue:
                status === "PAID" ||
                status === "REFUNDED" ||
                status === "CANCELED" ||
                overdue <= 0 ||
                overdue <= 0
                  ? "-"
                  : overdue + " day",
              status,
              public_url,
            };
          });
        let invoiceFilterList = dataRaw.filter(invoice => {
          return invoice.status.includes(filteredInfo);
        });

        this.setState({
          invoiceList: dataRaw,
          tableLoading: false,
          totalPaid: formatCurrency2SigFig(paid),
          totalUnpaid: formatCurrency2SigFig(unpaid),
          totalOverdue: formatCurrency2SigFig(overdueAmount),
          totalTransaction: dataRaw.length,
          invoiceFilterList: invoiceFilterList,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        invoiceList: [],
        tableLoading: false,
      });
    }
  };

  handleFilter = value => {
    const { invoiceList } = this.state;
    if (value === "All") {
      this.setState({ invoiceFilterList: invoiceList });
    } else {
      let filterInvoice = invoiceList.filter(
        invoice => invoice.status === value
      );
      this.setState({ invoiceFilterList: filterInvoice });
    }
  };

  render() {
    return (
      <PaymentView
        invoiceList={this.state.invoiceList}
        invoiceFilterList={this.state.invoiceFilterList}
        loading={this.state.tableLoading}
        filteredInfo={this.state.filteredInfo}
        tableLoading={this.state.tableLoading}
        totalPaid={this.state.totalPaid}
        totalUnpaid={this.state.totalUnpaid}
        totalOverdue={this.state.totalOverdue}
        totalTransaction={this.state.totalTransaction}
        handleFilter={this.handleFilter}
      />
    );
  }
}

export default Controller;
