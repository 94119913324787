import gql from "graphql-tag";

const GET_SHARED_ORGS = gql`
  query orgs(
    $first: Int
    $skip: Int
    $orderBy: OrgOrderByInput
    $where: OrgWhereInput
  ) {
    orgs(first: $first, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      domain
      name
      description
      parentOrg {
        id
        name
      }
    }
  }
`;

export default GET_SHARED_ORGS;
