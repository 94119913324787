import React from "react";
import { Button, Row, Popover } from "antd";
import { FilterContent } from "./FilterContent";

const Frag = React.Fragment;

export const FilterMenuButtons = ({
  geoLocationFiltersChanged,
  filterAttributeChanged,
  onPerformanceClick,
  filterValueChanged,
  filterModalVisible,
  toggleFilterModal,
  filterAttribute,
  onFilterClick,
  filterValue,
  targetType,
}) => {
  const title = (
    <Row>
      Filter {targetType} Targets
      <Button
        type="link"
        onClick={() => {
          geoLocationFiltersChanged("clear");
          toggleFilterModal();
        }}
        style={{ float: "right" }}
      >
        Clear Filter
      </Button>
    </Row>
  );

  return (
    <Frag>
      <Row type="flex" jusitfy="start">
        <Popover
          content={
            <FilterContent
              geoLocationFiltersChanged={geoLocationFiltersChanged}
              toggleFilterModal={toggleFilterModal}
              filterAttributeChanged={filterAttributeChanged}
              filterValueChanged={filterValueChanged}
              filterAttribute={filterAttribute}
              filterValue={filterValue}
              isPopover={true}
            />
          }
          placement="rightBottom"
          title={title}
          trigger="click"
          visible={filterModalVisible}
        >
          <Button // filter
            style={{ marginRight: "2%" }}
            onClick={onFilterClick}
          >
            Filter
          </Button>
        </Popover>

        {false && (
          <Button // performance filter
            onClick={onPerformanceClick}
          >
            Performance Filter
          </Button>
        )}
      </Row>
    </Frag>
  );
};
