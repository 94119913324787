import React from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import { Button, Card, Col, Row, Select } from "antd";
import DocumentationAlert from "./components/DocumentationAlert";
import SearchWithClear from "../../../shared/SearchWithClear";
import MainTable from "./components/MainTable";
import RequestAssistance from "./components/RequestAssitance";
import { withRouter } from "react-router-dom";

const TagsMainView = ({
  formattedTags,
  history,
  location,
  requestAssistance,
}) => {
  //Title shown on browser tab
  document.title = "Tags Tracking Main View";
  const params = new URLSearchParams(location.search);
  const status = params.get("status") ? params.get("status") : "live";
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Tags"}
        titleIcon={<UsergroupAddOutlined style={mainTitleIcon} />}
        rightContent={
          <RequestAssistance onRequestAssistance={requestAssistance} />
        }
      />
      <PageContentLayout>
        <PageHeaderLayout
          title={"Tags"}
          rightContent={
            <Row align="middle" justify="center" gutter={[5, 5]}>
              <Col>
                <Select
                  defaultValue={"live"}
                  value={status}
                  style={{ width: "120px" }}
                  onChange={val => {
                    if (status) {
                      params.delete("status");
                    }

                    params.append("status", val);
                    history.push({
                      pathname: location.pathname,
                      search: `?${params.toString()}`,
                    });
                  }}
                >
                  <Select.Option value="live">LIVE</Select.Option>
                  <Select.Option value="disabled">DISABLED</Select.Option>
                </Select>
              </Col>
              <Col>
                <SearchWithClear searchFormat={"name"} />
              </Col>
            </Row>
          }
        />
        <Row
          align="middle"
          justify="center"
          style={{ background: "white", padding: "10px" }}
        >
          <Col xl={18} lg={24} offset={1}>
            <DocumentationAlert />
          </Col>
          <Col xl={4} lg={24} offset={1}>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                history.push(`/tags/manager`);
              }}
            >
              Create New Tag
            </Button>
          </Col>
        </Row>
        <Card>
          <MainTable tags={formattedTags} />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TagsMainView);
