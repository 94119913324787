import React from "react";
import { withRouter } from "react-router-dom";
import Controller from "./CampaignOrderAuditController";
import OverlaySpinner from "../../../.././platform/shared/OverlaySpinner";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_ALL_CAMPAIGNORDERS from "../../../GraphQl/Queries/GET_ALL_CAMPAIGNORDERS";
import CAMPAIGN_ORDER from "../../../GraphQl/Queries/CAMPAIGN_ORDER";
import UPDATE_CAMPAIGN_ORDER from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_OLD";
import UPDATE_CAMPAIGN_ORDER_STATUS from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_STATUS";
import UPDATE_CAMPAIGN_ORDER_CHANGELOG from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_CHANGELOG";
import CREATE_MEDIA_ADMIN from "../../../GraphQl/Mutations/CREATE_MEDIA_ADMIN";
import DELETE_MEDIA from "../../../GraphQl/Mutations/DELETE_MEDIA";
import DELETE_ADUNIT from "../../../GraphQl/Mutations/DELETE_ADUNIT";
import CREATE_GEOTARGET from "../../../GraphQl/Mutations/CREATE_GEOTARGET";
import DELETE_GEOTARGET from "../../../GraphQl/Mutations/DELETE_GEOTARGET";
import UPDATE_GEOTARGET from "../../../GraphQl/Mutations/UPDATE_GEOTARGET";
import { graphql, Mutation, Query } from "react-apollo";
import compose from "lodash/flowRight";
import { isEmpty } from "lodash";

const Loader = props => {
  const campaignOrderID = props.match.params.id;
  if (!campaignOrderID) props.history.push("/campaigns");
  const loggedInRole = props.currentUser.role;
  const isMediaJel =
    loggedInRole.org.name === "MediaJel" ||
    loggedInRole.org.name === "MediaJelAdmin" ||
    loggedInRole.org.name === "Sito";
  return (
    <Mutation mutation={UPDATE_CAMPAIGN_ORDER}>
      {(updateCampaignOrder, { data }) => {
        return (
          <Mutation
            mutation={UPDATE_CAMPAIGN_ORDER_STATUS}
            update={(store, { data: { updateCampaignOrderStatus } }) => {
              const data = store.readQuery({
                query: GET_ALL_CAMPAIGNORDERS,
                variables: {
                  orgID: loggedInRole.org.id,
                },
              });
              const indexToUpdate = data.campaignOrders.findIndex(
                order => order.id === updateCampaignOrderStatus.id
              );
              data.campaignOrders[indexToUpdate].status =
                updateCampaignOrderStatus.status;
              store.writeQuery({
                query: GET_ALL_CAMPAIGNORDERS,
                data,
                variables: {
                  orgID: loggedInRole.org.id,
                },
              });
            }}
          >
            {(updateCampaignOrderStatus, { data }) => {
              return (
                <Mutation
                  mutation={UPDATE_CAMPAIGN_ORDER_CHANGELOG}
                  update={(store, { data: { updateCampaignOrder } }) => {
                    const data = store.readQuery({
                      query: CAMPAIGN_ORDER,
                      variables: {
                        id: campaignOrderID,
                      },
                    });
                    data.campaignOrders[0].changeLog =
                      updateCampaignOrder.changeLog;
                    store.writeQuery({
                      query: CAMPAIGN_ORDER,
                      data,
                      variables: {
                        id: campaignOrderID,
                      },
                    });
                  }}
                >
                  {(updateCampaignOrderChangelog, { data }) => {
                    return (
                      <Query
                        query={CAMPAIGN_ORDER}
                        variables={{ id: campaignOrderID }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <OverlaySpinner />;
                          if (error)
                            return `Error (Query: CAMPAIGN_ORDER)! ${error.message}`;
                          const { campaignOrders } = data;
                          if (isEmpty(campaignOrders))
                            props.history.push("/campaigns");
                          const campaignOrder = campaignOrders[0];
                          if (
                            !campaignOrder.orgs.some(
                              org => org.id === loggedInRole.org.id
                            ) &&
                            !isMediaJel
                          )
                            props.history.push("/campaigns");
                          // TODO: if status isn't PENDING, route to correct location
                          return (
                            <Controller
                              {...props}
                              updateCampaignOrderStatus={
                                updateCampaignOrderStatus
                              }
                              updateCampaignOrderChangelog={
                                updateCampaignOrderChangelog
                              }
                              updateCampaignOrder={updateCampaignOrder}
                              orgID={loggedInRole.org.id}
                              campaignOrder={campaignOrder}
                            >
                              {props.children}
                            </Controller>
                          );
                        }}
                      </Query>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(CREATE_MEDIA_ADMIN, { name: "createMediaMutation" }),
  graphql(DELETE_MEDIA, { name: "deleteMedia" }),
  graphql(DELETE_ADUNIT, { name: "deleteAdUnit" }),
  graphql(CREATE_GEOTARGET, { name: "createGeoTarget" }),
  graphql(DELETE_GEOTARGET, { name: "deleteGeoTarget" }),
  graphql(UPDATE_GEOTARGET, { name: "updateGeoTarget" })
)(withRouter(Loader));
