import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";

const EditorialDetailsSignUpsLoader = ({
  reportKeySummary,
  reportKeyList,
  ...props
}) => {
  const {
    data: signedUrlSummary,
    loading: signedUrlSummaryLoading,
    error: signedUrlSummaryError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKeySummary,
    },
  });

  const {
    data: signedUrlList,
    loading: signedUrlListLoading,
    error: signedUrlListError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKeyList,
    },
  });

  const [reportDataSummary, setReportData] = useState();
  const [reportSummaryLoading, setReportLoading] = useState(true);
  const [reportSummaryError, setReportError] = useState();

  const [reportDataList, setReportDataList] = useState();
  const [reportListLoading, setReportListLoading] = useState(true);
  const [reportListError, setReportListError] = useState();

  useEffect(() => {
    if (signedUrlSummary) {
      const s3Url = new URL(signedUrlSummary.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportData(json);
              setReportLoading(false);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [signedUrlSummary]);

  useEffect(() => {
    if (signedUrlList) {
      const s3Url = new URL(signedUrlList.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportDataList(json);
              setReportListLoading(false);
            });
          } catch (err) {
            console.log(err);
            setReportListError(err);
            setReportListLoading(false);
          }
        }
      });
    }
  }, [signedUrlList]);

  if (signedUrlSummaryLoading || signedUrlListLoading)
    return <OverlaySpinner />;

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props.children.props,
        reportDataSummary,
        reportSummaryLoading,
        reportSummaryError,
        reportDataList,
        reportListLoading,
        reportListError,
        signedUrlSummaryError,
        signedUrlListError,
      })}
    </React.Fragment>
  );
};

export default EditorialDetailsSignUpsLoader;
