import React from "react";
import { withRouter } from "react-router-dom";
import PaginationTable from "../../../shared/PaginationTable";
import { Modal, Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";
import { formatWholeNumber } from "../../../../core/utils/campaigns";
import { isEmpty } from "lodash";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../shared/globalStyling/styledText";

const Frag = React.Fragment;
const { confirm } = Modal;

const GeoTargetsTable = ({
  count,
  onPage,
  geoTargets,
  selectTableRow,
  selectedGeoTargets,
  history,
  orderByFilter,
  whereFilter,
  hasInProgressShape,
  loadingGeoTargets,
  checkSwitchMode,
  pageSize,
}) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: text => {
        if (!text) {
          return <div />;
        }
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    // {
    //   title: <TableColumnTitle text={"Description"} />,
    //   dataIndex: "description",
    //   key: "description",
    //   width: "20%",
    //   render: text => {
    //     if (!text) {
    //       return <div />;
    //     }
    //     return (
    //       <Frag>
    //         <Row type="flex" justify="start">
    //           <Col>
    //             <PrimaryTableRowText
    //               text={
    //                 text.length > 249 ? (
    //                   <Tooltip title={text}>
    //                     {text.slice(0, 250).concat("...")}
    //                   </Tooltip>
    //                 ) : (
    //                   text
    //                 )
    //               }
    //             />
    //           </Col>
    //         </Row>
    //       </Frag>
    //     );
    //   },
    // },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "start",
      key: "daterange",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start">
              {record.start && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.end && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },

    {
      title: <TableColumnTitle text={"Shapes"} />,
      dataIndex: "end",
      key: "shapes",
      width: "10%",
      render: (text, record) => {
        const shapeCount = record.polygons.length + record.circles.length;
        return <PrimaryTableRowText text={formatWholeNumber(shapeCount)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Devices"} />,
      dataIndex: "uniqueDevices",
      key: "uniqueDevices",
      width: "15%",
      render: (text, record) =>
        record.vwiUpdatedOnSpot ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },

    {
      title: <TableColumnTitle text={"Observations"} />,
      dataIndex: "observations",
      key: "observations",
      width: "15%",
      render: (text, record) =>
        record.vwiUpdatedOnSpot ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },
  ];

  const showDataLossConfirm = (record, selected, multiple) => {
    confirm({
      title: "There are in progress shapes that have not been saved. Continue?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        selectTableRow(record, selected, multiple);
      },
      onCancel() {
        return;
      },
    });
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (hasInProgressShape && checkSwitchMode === true)
        return showDataLossConfirm(record, selected);
      return selectTableRow(record, selected);
    },
    selectedRowKeys: selectedGeoTargets.map(row => row.id),
    columnWidth: `${String(selectedGeoTargets.length).length * 2}`,
    // columnTitle: selectedGeoTargets.length,
    hideDefaultSelections: true,
    selections: [
      {
        text: "Select current page",
        onSelect: changableRowKeys => {
          selectTableRow(geoTargets, true, true);
        },
      },
      {
        text: "De-select all",
        onSelect: changableRowKeys => {
          if (hasInProgressShape)
            return showDataLossConfirm(selectedGeoTargets, false, true);
          return selectTableRow(selectedGeoTargets, false, true);
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        rowSelection={rowSelection}
        data={geoTargets.map(geo => {
          return { ...geo, key: geo.id };
        })}
        columns={columns}
        scroll={{ x: 700 }}
        pageSize={pageSize}
        onChange={pagination => {
          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.get("pageSize")) searchParams.delete("pageSize");

          if (!isEmpty(pagination) && pagination.pageSize > 10) {
            searchParams.append("pageSize", pagination.pageSize);
          }

          if (history.location.pathname.includes("geo-target")) {
            history.push(
              `/segments/geo-targets/new/${
                pagination.current
              }/${orderByFilter}/${whereFilter}?${searchParams.toString()}`
            );
          } else {
            history.push(
              `/segments/new/${
                pagination.current
              }/${orderByFilter}/${whereFilter}?${searchParams.toString()}`
            );
          }
        }}
        total={count}
        current={onPage}
        bordered={false}
        loading={loadingGeoTargets}
      />
    </div>
  );
};

export default withRouter(GeoTargetsTable);
