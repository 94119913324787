import gql from "graphql-tag";

const UPDATE_SEGMENT = gql`
  mutation updateSegment(
    $id: ID
    $name: String
    $description: String!
    $start: DateTime
    $end: DateTime
    $tags: [String!]
    $circles: [CircleCreateInput!]
    $polygons: [PolygonCreateInput!]
    $orgid: ID
    $isBase: Boolean
    $flights: [GeoTargetWhereUniqueInput!]
    $location: LocationCreateOneWithoutGeoframeInput
  ) {
    updateSegment(
      where: { id: $id }
      data: {
        name: $name
        description: $description
        start: $start
        end: $end
        isBase: $isBase
        location: $location
        circles: { create: $circles }
        polygons: { create: $polygons }
        orgs: { connect: { id: $orgid } }
        tags: { set: $tags }
        flights: { connect: $flights }
      }
    ) {
      id
      name
      description
      start
      end
      observations
      orgs {
        id
      }
      vwiUpdated
      vwiUpdatedOnSpot
      tags
      circles {
        id
        coordinate {
          lat
          lng
        }
        radius
        location {
          name
          formattedAddress
          zip
          street
          city
          county
          state
          country
          lat
          lng
        }
      }
      polygons {
        id
        geometry
      }
    }
  }
`;

export default UPDATE_SEGMENT;
