import React from "react";
import compose from "lodash/flowRight";
import {
  graphql,
  useQuery,
  useLazyQuery,
  withApollo,
  useMutation,
} from "react-apollo";

// CONTROLLERS
import { CampaignOrdersQueueController } from "./CampaignOrdersQueueController";

// MUTATIONS
import UPDATE_CAMPAIGN_ORDER_STATUS from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_STATUS";
import UPDATE_CONVERSATION from "../../../GraphQl/Mutations/UPDATE_CONVERSATION";
import CREATE_MESSAGE from "../../../GraphQl/Mutations/CREATE_MESSAGE";
import CREATE_NOTIFICATION from "../../../GraphQl/Mutations/CREATE_NOTIFICATION";
import UPDATE_CAMPAIGN_ORDER_REVIEW from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_REVIEW";
import UPDATE_PAST_CAMPAIGN_ORDER_REVIEW from "../../../GraphQl/Mutations/UPDATE_PAST_CAMPAIGN_ORDER_REVIEW";
import EDIT_CAMPAIGN_STATUS from "../../../GraphQl/Mutations/EDIT_CAMPAIGN_STATUS";
import LAUNCH_CAMPAIGN_DSP from "../../../GraphQl/Mutations/LAUNCH_CAMPAIGN_DSP";
import UPDATE_CAMPAIGN_DSP from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_DSP";
import CAMPAIGN_ORDER_SLACK_NOTIFICATION from "../../../GraphQl/Mutations/CAMPAIGN_ORDER_SLACK_NOTIFICATION";
import CREATE_CAMPAIGN_HISTORY from "../../../GraphQl/Mutations/CREATE_CAMPAIGN_HISTORY";
import ADD_CREATIVE_NOTES from "../../../GraphQl/Mutations/ADD_CREATIVE_NOTES";

// QUERIES
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_PENDING from "../../../GraphQl/Queries/GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_PENDING";
import GET_CAMPAIGN_ORDER_BY_ID from "../../../GraphQl/Queries/GET_CAMPAIGN_ORDER_BY_ID";
import GET_NOTIFICATIONS from "../../../GraphQl/Queries/GET_NOTIFICATIONS";
import GET_CONVERSATION from "../../../GraphQl/Queries/GET_CONVERSATION";
import GET_LIQUIDM_PUBLISHERS from "../../../GraphQl/Queries/GET_LIQUIDM_PUBLISHERS";
import GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_READY from "../../../GraphQl/Queries/GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_READY";
import GET_CAMPAIGN_HISTORIES from "../../../GraphQl/Queries/GET_CAMPAIGN_HISTORIES";

// SHARED

const CampaignOrdersQueueLoader = props => {
  const [
    getCampaignOrders,
    { loading: loadingCampaignOrders, data: campaignOrdersData },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_PENDING, {
    fetchPolicy: "no-cache",
  });

  const [
    getCampaignOrdersReady,
    { loading: loadingCampaignOrdersReady, data: campaignOrdersDataReady },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_READY, {
    fetchPolicy: "network-only",
  });

  const [
    getCampaignOrder,
    { loading: loadingCampaignOrder, data: campaignOrderData },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_BY_ID, {
    fetchPolicy: "no-cache",
  });

  const {
    data: liquidMPublishers,
    loading: liquidMPublishersLoading,
  } = useQuery(GET_LIQUIDM_PUBLISHERS);

  const [
    getCampaignHistories,
    { data: campaignHistoriesData, loading: campaignHistoriesLoading },
  ] = useLazyQuery(GET_CAMPAIGN_HISTORIES, {
    variables: {
      campaignOrderId: props.campaignOrderId,
    },
    fetchPolicy: "no-cache",
  });

  // ===== END QUERIES ===== //

  // MUTATIONS
  // NOTE: Check if this is redundant with EDIT_CAMPAIGN_STATUS
  const [updateCampaignOrderStatus] = useMutation(
    UPDATE_CAMPAIGN_ORDER_STATUS,
    {
      variables: {
        orgID: props.currentUser.role.org.id,
      },
      onCompleted: data => {
        getCampaignOrders({
          variables: {
            status: ["APPROVED", "LIVE"],
            orgId: props.currentUser.role.org.id,
            orderBy: "createdAt_DESC",
          },
          fetchPolicy: "network-only",
        });
      },
    }
  );

  const [createMessage, { loading: createMessageLoading }] = useMutation(
    CREATE_MESSAGE,
    {
      variables: {
        personality: props.personality,
        context: props.context,
        message: props.message,
        userID: props.userID,
        currentOrgID: props.currentOrgID,
      },
    }
  );

  const [
    updateConversation,
    { loading: updatedConversationLoading },
  ] = useMutation(UPDATE_CONVERSATION, {
    variables: {
      conversationID: props.conversationID,
      message: props.message,
      currentOrgID: props.currentOrgID,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CONVERSATION,
        variables: {
          getConversationId: "", // TODO: Check getCurrentConversationId
        },
      },
    ],
  });

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    variables: {
      userID: props.userID,
      message: props.message,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          userId: props.currentUser.id,
        },
      },
    ],
  });

  const [
    updateCampaignOrderReview,
    { loading: updateCampaignOrderReviewLoading },
  ] = useMutation(UPDATE_CAMPAIGN_ORDER_REVIEW, {
    variables: {
      campaignOrderID: props.campaignOrderID,
      review: props.review,
      stateLaw: props.stateLaw,
      scaleBudget: props.scaleBudget,
      reviewerId: props.reviewerId,
    },
  });

  const [updatePastCampaignOrderReview] = useMutation(
    UPDATE_PAST_CAMPAIGN_ORDER_REVIEW,
    {
      variables: {
        campaignOrderId: props.campaignOrderId,
        stateLaw: props.stateLaw,
        scaleBudget: props.scaleBudget,
        reviewerId: props.reviewerId,
      },
      awaitRefetchQueries: true,
    }
  );

  const [
    updateCampaignOrderWithoutMessage,
    { loading: updateCampaignOrderWithoutMessageLoading },
  ] = useMutation(EDIT_CAMPAIGN_STATUS, {
    variables: {
      id: props.id,
      status: props.status,
    },
  });

  const [
    launchCampaignDSP,
    { loading: launchCampaignDSPLoading },
  ] = useMutation(LAUNCH_CAMPAIGN_DSP, {
    variables: {
      dspType: props.dspType,
      data: props.data,
    },
  });

  const [
    updateCampaignDSP,
    { loading: updateCampaignDSPLoading },
  ] = useMutation(UPDATE_CAMPAIGN_DSP, {
    variables: {
      dspType: props.dspType,
      data: props.data,
    },
  });

  const [campaignOrderSlackNotification] = useMutation(
    CAMPAIGN_ORDER_SLACK_NOTIFICATION,
    {
      variables: {
        id: props.id,
        userId: props.userId,
      },
    }
  );

  const [createCampaignHistory, { data: campaignHistoryData }] = useMutation(
    CREATE_CAMPAIGN_HISTORY,
    {
      variables: {
        action: props.action,
        property: props.property,
        data: props.data,
        authorId: props.authorId,
        campaignOrderId: props.campaignOrderId,
      },
    }
  );

  const [addCreativeNotes] = useMutation(ADD_CREATIVE_NOTES, {
    variables: {
      id: props.id,
      mediaNotes: props.mediaNotes,
      mediaHasNotes: props.mediaHasNotes,
    },
    // awaitRefetchQueries: true,
    // refetchQueries: [
    //   {
    //     query: CAMPAIGN_ORDER,
    //     variables: {
    //       id: campaignOrderID,
    //     },
    //   },
    // ],
  });

  // ===== END MUTATIONS ===== //

  return (
    <>
      <CampaignOrdersQueueController
        currentUser={props.currentUser}
        campaignOrdersData={campaignOrdersData}
        campaignOrderData={campaignOrderData}
        loadingCampaignOrder={loadingCampaignOrder}
        createMessageLoading={createMessageLoading}
        updatedConversationLoading={updatedConversationLoading}
        liquidMPublishers={liquidMPublishers}
        liquidMPublishersLoading={liquidMPublishersLoading}
        loadingCampaignOrders={loadingCampaignOrders}
        updateCampaignOrderReviewLoading={updateCampaignOrderReviewLoading}
        updateCampaignDSPLoading={updateCampaignDSPLoading}
        launchCampaignDSPLoading={launchCampaignDSPLoading}
        loadingCampaignOrdersReady={loadingCampaignOrdersReady}
        campaignOrdersDataReady={campaignOrdersDataReady}
        updateCampaignOrderWithoutMessageLoading={
          updateCampaignOrderWithoutMessageLoading
        }
        campaignHistoriesData={campaignHistoriesData}
        campaignHistoryData={campaignHistoryData}
        campaignHistoriesLoading={campaignHistoriesLoading}
        getCampaignOrdersReady={getCampaignOrdersReady}
        getCampaignOrders={getCampaignOrders}
        getCampaignOrder={getCampaignOrder}
        updateCampaignOrderStatus={updateCampaignOrderStatus}
        createMessage={createMessage}
        updateConversation={updateConversation}
        createNotification={createNotification}
        updateCampaignOrderReview={updateCampaignOrderReview}
        updatePastCampaignOrderReview={updatePastCampaignOrderReview}
        updateCampaignOrderWithoutMessage={updateCampaignOrderWithoutMessage}
        launchCampaignDSP={launchCampaignDSP}
        updateCampaignDSP={updateCampaignDSP}
        campaignOrderSlackNotification={campaignOrderSlackNotification}
        getCampaignHistories={getCampaignHistories}
        createCampaignHistory={createCampaignHistory}
        addCreativeNotes={addCreativeNotes}
        {...props}
      >
        {props.children}
      </CampaignOrdersQueueController>
    </>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  withApollo
)(CampaignOrdersQueueLoader);
