import React from "react";
import { Divider, Row, Col, Card } from "antd";
import { mapValues, groupBy, omit, isEmpty } from "lodash";
import moment from "moment";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";

const aggregateByDate = data => {
  if (isEmpty(data)) return [];

  let aggregatedData;
  if (!isEmpty(data)) {
    aggregatedData = mapValues(groupBy(data, "date"), list =>
      list.map(obj => omit(obj, "date"))
    );
  }

  let res = [];
  for (const i in aggregatedData) {
    let quantity = 0;
    let revenue = 0;
    aggregatedData[i].map(obj => {
      quantity += obj.quantity;
      revenue += obj.revenue * obj.quantity;
      return obj;
    });
    res.push({ quantity, revenue, date: i });
  }
  return res;
};

const BasketItemsBarChart = ({ data, isMobile }) => {
  if (isEmpty(data)) return [];

  const result = data
    .map(obj => ({
      quantity: obj.QUANTITY,
      date: moment(new Date(obj.TIMESTAMP)).format("MM-DD-YY"),
      dateObj: moment(new Date(obj.TIMESTAMP)).toISOString(),
      revenue: obj.PRICE,
    }))
    .sort((a, b) => moment(new Date(a.dateObj)) - moment(new Date(b.dateObj)));

  const newData = aggregateByDate(result);

  const scale = {
    date: {
      tickCount: 10,
    },
  };

  const showChart = () => (
    <Chart
      height={400}
      width={isMobile ? 250 : 500}
      padding="auto"
      style={{
        marginTop: "3rem",
        display: "flex",
        flexDirection: "column",
      }}
      data={newData}
      scale={scale}
      forceFit
    >
      <Axis name="xAxis" />

      <Tooltip title={false} />
      <Geom
        type="interval"
        position={`date*quantity`}
        color="#E1EEFF"
        size={newData.length > 400 ? 3.5 : 12}
        tooltip={[
          `date*quantity`,
          (date, val) => {
            return {
              name: "Quantity",
              value: `${val}`,
            };
          },
        ]}
      />
      <Geom
        type="interval"
        position={`date*revenue`}
        color="#E1EEFF"
        size={newData.length > 400 ? 3.5 : 12}
        tooltip={[
          `date*revenue`,
          (date, val) => {
            return {
              name: "Revenue",
              value: `$${val}`,
            };
          },
        ]}
      />
    </Chart>
  );

  return (
    <Card>
      <Row>
        <h3>Basket Item Chart by Date</h3>
      </Row>
      <Divider />
      <Row>
        <Col span={8}>
          <span style={{ marginTop: "3rem" }}>Quantity</span>
        </Col>
        <Col style={{ textAlign: "right" }} span={8} offset={8}>
          Revenue
        </Col>
      </Row>
      {showChart()}
    </Card>
  );
};

export default BasketItemsBarChart;
