import React from "react";
import { Table, Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";

export const TargetingDealIdsTable = ({
  dealIds,
  setSelectedDealIdKeys,
  handleDeleteDealIds,
  setSelectedDealId,
  setIsFormOpen,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deal Id",
      dataIndex: "dealId",
      key: "dealId",
    },
    {
      title: "",
      key: "action",
      align: "right", // Align the last column to the right
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleUpdate(record)}>
                Update
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => handleDeleteDealIds({ ids: [record.id] })}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ minWidth: 0 }}>
            <EllipsisOutlined style={{ transform: "rotate(90deg)" }} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleUpdate = async record => {
    setSelectedDealId(record);
    setIsFormOpen(true);
  };

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedDealIdKeys(selectedRowKeys);
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={dealIds}
      rowSelection={rowSelection}
      rowKey={"id"}
    />
  );
};
