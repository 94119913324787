import React from "react";
import { Tabs } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import "./styles.css";

import { CampaignOrderQueueQueued } from "./CampaignOrderQueueQueued";
import { CampaignOrderQueueReady } from "./CampaignOrderQueueReady";
import { CampaignOrderDraft } from "./CampaignOrderQueueDraft";
import { CampaignOrderLaunched } from "./CampaignOrderQueueLaunched";

export const CampaignOrderQueue = () => {
  const location = useLocation();
  const history = useHistory();

  // Determine the active tab based on the route
  const getActiveKey = () => {
    switch (location.pathname) {
      case "/campaigns/v2/orderqueue/launched":
        return "1";
      case "/campaigns/v2/orderqueue/ready-to-launched":
        return "2";
      case "/campaigns/v2/orderqueue/queued":
        return "3";
      case "/campaigns/v2/orderqueue/draft":
        return "4";
      default:
        return "1"; // Default to "Launched" if no match is found
    }
  };

  const handleTabChange = key => {
    switch (key) {
      case "1":
        history.push("/campaigns/v2/orderqueue/launched");
        break;
      case "2":
        history.push("/campaigns/v2/orderqueue/ready-to-launched");
        break;
      case "3":
        history.push("/campaigns/v2/orderqueue/queued");
        break;
      case "4":
        history.push("/campaigns/v2/orderqueue/draft");
        break;
      default:
        history.push("/campaigns/v2/orderqueue/launched");
        break;
    }
  };

  return (
    <div>
      <Tabs
        style={{ padding: "8px 24px" }}
        activeKey={getActiveKey()} // Set the active tab key
        onChange={handleTabChange} // Handle tab change
        className="campaign-order-queue-tabs"
      >
        <Tabs.TabPane tab="Launched" key="1" />
        <Tabs.TabPane tab="Ready to Launch" key="2" />
        <Tabs.TabPane tab="Queued" key="3" />
        <Tabs.TabPane tab="Draft" key="4" />
      </Tabs>

      <div style={{ padding: "24px" }}>
        <Switch>
          <Route
            path="/campaigns/v2/orderqueue/launched"
            exact
            component={CampaignOrderLaunched}
          />
          <Route
            path="/campaigns/v2/orderqueue/ready-to-launched"
            exact
            component={CampaignOrderQueueReady}
          />
          <Route
            path="/campaigns/v2/orderqueue/queued"
            exact
            component={CampaignOrderQueueQueued}
          />
          <Route
            path="/campaigns/v2/orderqueue/draft"
            exact
            component={CampaignOrderDraft}
          />
          {/* Add wildcard path */}
          <Route
            path="/campaigns/v2/orderqueue/*"
            render={() => <Redirect to="/campaigns/v2/orderqueue/launched" />}
          />
        </Switch>
      </div>
    </div>
  );
};
