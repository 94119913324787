import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

// CONFIGS
import { TimezonesConfig } from "../../../../platform/shared/configs/timezone.configs";
import { TableConfig } from "../../../../platform/shared/configs/TableConfig";
// LIB
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
import { isEmpty } from "lodash";

// HOOKS
import useSearchParams from "../../../hooks/useSearchParams";

const activeTabStatus = ["PENDING", "READY"]; // TODO: Create a config file for this

export const CampaignOrdersQueueController = ({
  campaignOrderData,
  campaignOrdersData,
  campaignOrdersDataReady,
  children,
  createMessage,
  createMessageLoading,
  createNotification,
  currentUser,
  getCampaignOrder,
  getCampaignOrders,
  getCampaignOrdersReady,
  launchCampaignDSP,
  launchCampaignDSPLoading,
  loadingCampaignOrder,
  loadingCampaignOrders,
  loadingCampaignOrdersReady,
  updateCampaignDSP,
  updateCampaignDSPLoading,
  updateCampaignOrderWithoutMessageLoading,
  updateCampaignOrderReviewLoading,
  updateConversation,
  updateCampaignOrderReview,
  campaignOrderSlackNotification,
  getCampaignHistories,
  campaignHistoriesData,
  campaignHistoryData,
  createCampaignHistory,
  ...props
}) => {
  const { id } = useParams(); // 'id' is the name of the parameter as per your URL structure
  const history = useHistory();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "PENDING",
    origin: "ALL",
    orderBy: "updatedAt_DESC",
  });
  const [campaignOrders, setCampaignOrders] = useState([]);
  const [selectedCampaignOrder, setSelectedCampaignOrder] = useState(null);
  const [pagination, setPagination] = useState(TableConfig.pagination);
  const [config, setConfig] = useState(false);
  const [
    loadingSelectedCampaignOrder,
    setLoadingSelectedCampaignOrder,
  ] = useState(false);
  const message = ""; // TODO: Check if needed, if not remove

  const tabletMode = window.matchMedia("(max-width: 1100px)").matches; // TODO: Refactor this to use media queries
  const [campaignHistories, setCampaignHistories] = useState([]);
  const [formattedTargets, setFormattedTargets] = useState([]);
  const [dspStatus, setDspStatus] = useState(null);
  const [formattedWalkIns, setFormattedWalkIns] = useState([]);
  const [adUnitBannerIds, setAdUnitBannerIds] = useState([]);
  const [tableKey, setTableKey] = useState(Date.now());

  useEffect(() => {
    if (currentUser) {
      setConfig(
        currentUser.role && currentUser.role.org && currentUser.role.org.config
      );

      let params = searchParams;
      // Check if searchParams.tab is in activeTabStatus
      if (!activeTabStatus.includes(searchParams.tab)) {
        params = { ...searchParams, tab: "PENDING" };
        setSearchParams(params);
      }

      const url = constructUrl(id, params);
      fetchCampaignOrders(params);

      if (id) {
        fetchCampaignOrderById(id);
        fetchCampaignOrderHistoriesById(id);
      }
      history.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // TODO: Make a computed value
  useEffect(() => {
    setLoadingSelectedCampaignOrder(
      loadingCampaignOrder ||
        updateCampaignOrderReviewLoading ||
        updateCampaignOrderWithoutMessageLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadingCampaignOrder,
    updateCampaignOrderReviewLoading,
    updateCampaignOrderWithoutMessageLoading,
  ]);

  useEffect(() => {
    if (
      campaignOrdersData &&
      campaignOrdersData.campaignOrdersConnection &&
      campaignOrdersData.campaignOrdersConnection.edges
    ) {
      const { edges } = campaignOrdersData.campaignOrdersConnection;
      setPagination({
        ...pagination,
        total: campaignOrdersData.full.aggregate.count,
      });
      setCampaignOrders(edges.map(({ node }) => node));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignOrdersData]);

  useEffect(() => {
    if (campaignOrdersDataReady) {
      const { campaignOrdersConnection, full } = campaignOrdersDataReady;
      const campaignOrders = campaignOrdersConnection.edges.map(
        ({ node }) => node
      );

      // Sort the campaign orders: APPROVED first, then LIVE_APPROVED, and LIVE
      campaignOrders.sort((a, b) => {
        const order = ["APPROVED", "LIVE_APPROVED", "LIVE"];
        return order.indexOf(a.tab) - order.indexOf(b.tab);
      });

      setCampaignOrders(campaignOrders);
      setPagination({
        ...pagination,
        total: full.aggregate.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignOrdersDataReady]);

  useEffect(() => {
    if (campaignOrderData && campaignOrderData.campaignOrder) {
      const { campaignOrder } = campaignOrderData;
      setSelectedCampaignOrder(campaignOrder);
      // Update campaignOrders with the new data
      const updatedCampaignOrders = campaignOrders.map(order => {
        if (order.id === campaignOrder.id) {
          return campaignOrder;
        }
        return order;
      });

      setCampaignOrders(updatedCampaignOrders);

      if (campaignOrder.dspConfig && campaignOrder.dspConfig.status) {
        setDspStatus(campaignOrder.dspConfig.status);
      } else {
        setDspStatus(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignOrderData]);

  useEffect(() => {
    if (campaignHistoriesData) {
      setCampaignHistories(campaignHistoriesData.campaignHistories);
    }
  }, [campaignHistoriesData]);

  useEffect(() => {
    if (campaignHistoryData) {
      setCampaignHistories([
        ...campaignHistories,
        campaignHistoryData.createCampaignHistory,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignHistoryData]);

  useEffect(() => {
    if (selectedCampaignOrder) {
      setTableKey(Date.now());
      setAdUnitBannerIds([]);
    }

    if (selectedCampaignOrder && selectedCampaignOrder.geoTargets) {
      const { geoTargets } = selectedCampaignOrder;
      setFormattedTargets(
        geoTargets.map(result => {
          const circle = result.circles[0];
          const redotarget = isEmpty(circle) ? result : circle.location;
          const getRadius = isEmpty(circle) ? "-" : result.circles[0].radius;

          const name =
            circle && circle.location ? circle.location.name : result.name;

          return {
            ...redotarget,
            key: result.id,
            name,
            radius: getRadius,
            status: result.status,
          };
        })
      );
    }

    if (selectedCampaignOrder && selectedCampaignOrder.attributionTargets) {
      const { attributionTargets } = selectedCampaignOrder;
      setFormattedWalkIns(
        attributionTargets.map(result => {
          const circle = result.circles[0];
          const redotarget = isEmpty(circle) ? result : circle.location;
          const getRadius = isEmpty(circle) ? "-" : result.circles[0].radius;

          const name =
            circle && circle.location ? circle.location.name : result.name;

          return {
            ...redotarget,
            key: result.id,
            name,
            radius: getRadius,
            status: result.status,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignOrder]);

  const getIsLaunchProgrammatically = origin => {
    switch (origin) {
      case "LAUNCHER":
        return true;

      case "MANUAL":
        return null;

      default:
        return undefined;
    }
  };

  const fetchCampaignOrderById = async id => {
    try {
      await getCampaignOrder({
        variables: {
          id,
        },
      });
    } catch (error) {}
  };

  const fetchCampaignOrderHistoriesById = async id => {
    try {
      await getCampaignHistories({
        variables: {
          campaignOrderId: id,
        },
      });
    } catch (error) {
      return error;
    }
  };

  const fetchCampaignOrders = async ({
    name = "",
    origin = "ALL",
    tab,
    skip,
    first,
    createAtStartDate,
    createdAtEndDate,
    orderBy,
    action = "ALL",
    status,
  }) => {
    try {
      const variables = {
        name,
        orgId: currentUser.role.org.id,
        skip: skip || 0,
        first: first || 10,
        isLaunchProgrammatically: getIsLaunchProgrammatically(origin),
        createAtStartDate: createAtStartDate
          ? moment(createAtStartDate).toISOString()
          : null,
        createdAtEndDate: createdAtEndDate
          ? moment(createdAtEndDate).toISOString()
          : null,
        endDate_gte: moment().toISOString(),
        endDate_lte: null,
        orderBy,
      };

      // Filter variables only values that are not null or undefined
      Object.keys(variables).forEach(key => {
        if (variables[key] === null || variables[key] === undefined) {
          delete variables[key];
        }
      });

      switch (tab) {
        case "PENDING":
          const pendingStatusArray = status
            ? status.split(",")
            : ["PENDING", "LIVE_PENDING", "PAUSED"];

          await getCampaignOrders({
            variables: {
              ...variables,
              status: pendingStatusArray,
            },
          });
          break;

        case "READY":
          const statusArray = status
            ? status.split(",")
            : ["APPROVED", "LIVE", "LIVE_APPROVED"];
          // Check if status includes ATTRIBUTION
          const hasAttributionStatus = statusArray.includes("ATTRIBUTION");
          const hasFinishedStatus = statusArray.includes("FINISHED");
          const hasLiveStatus = statusArray.includes("LIVE");

          if (hasAttributionStatus) {
            statusArray.splice(statusArray.indexOf("ATTRIBUTION"), 1);
            variables["endDate_gte"] = moment()
              .subtract(30, "days") // TODO: Add global config for attribution window value
              .toISOString();
            variables["endDate_lte"] = moment().toISOString();

            if (
              statusArray.includes("LIVE") ||
              statusArray.includes("LIVE_APPROVED")
            ) {
              delete variables["endDate_lte"];
            }
          }

          if (hasFinishedStatus) {
            statusArray.splice(statusArray.indexOf("FINISHED"), 1);
            variables["endDate_lte"] = moment()
              .subtract(30, "days") // TODO: Add global config for attribution window value
              .toISOString();

            delete variables["endDate_gte"];
          }

          if (hasLiveStatus) {
            variables["endDate_gte"] = moment().toISOString();
          }

          if (!statusArray.length) {
            statusArray.push("LIVE", "LIVE_APPROVED");
          }

          await getCampaignOrdersReady({
            variables: {
              ...variables,
              status: statusArray,
            },
          });
          break;

        default:
          break;
      }
    } catch (error) {
      return error;
    }
  };

  const handleTabChange = tab => {
    let updatedStatusFilter = searchParams.status;
    switch (tab) {
      case "PENDING":
        updatedStatusFilter = ["PENDING", "LIVE_PENDING", "PAUSED"];
        break;

      case "READY":
        updatedStatusFilter = ["APPROVED", "LIVE", "LIVE_APPROVED"];
        break;

      default:
        break;
    }

    if (tab === "READY" && !isEmpty(selectedCampaignOrder)) {
      handleSelectCampaignOrder(selectedCampaignOrder);
    }

    const id = getSelectedCampaignOrderId(tab, selectedCampaignOrder);

    setPagination({ ...TableConfig.pagination, current: 1 });

    fetchCampaignOrders({ tab });
    const url = constructUrl(id, {
      ...searchParams,
      status: updatedStatusFilter,
      tab,
    });
    history.push(url);
  };

  const getSelectedCampaignOrderId = (tab, campaignOrder) => {
    if (!campaignOrder) return null;

    if (
      campaignOrder.status === tab ||
      (tab === "READY" &&
        (campaignOrder.status === "LIVE" ||
          campaignOrder.status === "APPROVED"))
    ) {
      return campaignOrder.id;
    }

    return null;
  };

  const handleSelectCampaignOrder = async campaignOrder => {
    const { id, status } = campaignOrder;
    const url = constructUrl(id, {
      ...searchParams,
      tab: ["PENDING", "LIVE_PENDING", "PAUSED"].includes(status)
        ? "PENDING"
        : "READY",
    });
    history.push(url);

    fetchCampaignOrderById(id);
    fetchCampaignOrderHistoriesById(id);
  };

  const handlePaginationChange = currentPage => {
    setPagination(prev => ({ ...prev, current: currentPage }));
    fetchCampaignOrders({
      ...searchParams,
      tab: searchParams.tab,
      skip: (currentPage - 1) * pagination.pageSize,
    });
  };

  const handleFilterChange = filters => {
    let updatedParams = { ...searchParams };

    filters.forEach(({ key, value, obj }) => {
      if (obj) {
        updatedParams = { ...updatedParams, ...obj };
      } else {
        updatedParams = { ...updatedParams, [key]: value };
      }
    });

    setSelectedCampaignOrder(null);
    fetchCampaignOrders(updatedParams);
    setSearchParams(updatedParams);
  };
  const handleSubmitCampaignOrderReview = async ({ stateLaw, scaleBudget }) => {
    try {
      const { id, name } = selectedCampaignOrder;
      const upperCaseName = name.toUpperCase();

      let message = "";

      let status = null;

      if (stateLaw === "rejected" || scaleBudget === "rejected") {
        message = `Campaign has been rejected. Changes are required for this "${upperCaseName}" to be launched.`;
      } else if (stateLaw === "approved" && scaleBudget === "approved") {
        message = `Campaign "${upperCaseName}" has been approved and is ready to launch.`;
      } else {
        message = `Campaign has been Partially Approved. Changes are required for this "${upperCaseName}" to be launched.`;
      }

      if (stateLaw === "rejected" || scaleBudget === "rejected") {
        status = "PENDING";
      } else if (selectedCampaignOrder.status === "LIVE_PENDING") {
        status = "LIVE_APPROVED";
      } else {
        status = "APPROVED";
      }

      createNotification({
        variables: {
          userID: currentUser.id,
          message,
        },
      });

      await updateCampaignOrderReview({
        variables: {
          campaignOrderId: id,
          stateLaw,
          scaleBudget,
          reviewerId: currentUser.id,
          status,
        },
      });

      await fetchCampaignOrders({
        ...searchParams,
      });

      campaignOrderSlackNotification({
        variables: {
          id: id,
          userId: currentUser.id,
        },
      });

      if (
        selectedCampaignOrder.status === "LIVE_PENDING" &&
        status === "LIVE_APPROVED"
      ) {
        createCampaignHistory({
          variables: {
            action: "UPDATED",
            property: "BASICS",
            data: {
              property: "status",
              oldValue: "LIVE_PENDING",
              newValue: "LIVE_APPROVED",
            },
            authorId: currentUser.id,
            campaignOrderId: selectedCampaignOrder.id,
          },
        });
      }

      if (status === "APPROVED") {
        setSelectedCampaignOrder({
          ...selectedCampaignOrder,
          status: "APPROVED",
        });
        return true;
      }
      setSelectedCampaignOrder(null);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLaunchCampaignOrderManually = async ({
    stateLaw,
    scaleBudget,
  }) => {
    let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      await updateCampaignOrderReview({
        variables: {
          campaignOrderId: id,
          stateLaw,
          scaleBudget,
          reviewerId: currentUser.id,
          status: "LIVE",
        },
      });

      const result = await launchCampaignDSP({
        variables: {
          data: {
            campaignOrderId: selectedCampaignOrder.id,
            clientTimezone,
            dspConfig: {},
          },
        },
      });

      fetchCampaignOrderById(id);
      fetchCampaignOrderHistoriesById(id);

      return result;
    } catch (error) {
      return false;
    }
  };

  // TODO: Refactor this function
  const createMessageRemarks = async (messageStatus, isStartReview) => {
    try {
      const messages = [];

      const selectedCampaignOrderId = selectedCampaignOrder.id;
      // TODO: Check if needed, if not remove
      const getCurrentConversationId = "";

      if (messages.length === 0) {
        const getRequestorId =
          selectedCampaignOrder && selectedCampaignOrder.requestorId;

        await createMessage({
          variables: {
            personality: messageStatus,
            context: selectedCampaignOrderId,
            message: isStartReview === true ? "Starting Review..." : message,
            currentOrgID: currentUser.role.org.name,
            userID: getRequestorId,
            campaignOrderId: selectedCampaignOrder.id,
          },
        });

        await createMessage({
          variables: {
            personality: messageStatus,
            context: selectedCampaignOrder.id,
            message: isStartReview === true ? "Starting Review..." : message,
            currentOrgID: currentUser.role.org.name,
            userID: currentUser.id,
            campaignOrderId: selectedCampaignOrder.id,
          },
        });
      } else {
        const updateConversationRes = updateConversation({
          variables: {
            message,
            conversationID: getCurrentConversationId,
            currentOrgID: currentUser.role.org.name,
          },
        });

        if (updateConversationRes) {
          notification.open({
            message: "Message Remark Added",
            description: "Successfully!",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });

          const selectedCampaignOrderName = selectedCampaignOrder.name;

          createNotification({
            variables: {
              userID: selectedCampaignOrder.requestorId,
              message:
                'New Message "' +
                message +
                '" on Campaign: ' +
                selectedCampaignOrderName,
            },
          });

          return true;
        }
      }

      return false;
    } catch (err) {
      return false;
    }
  };

  const constructUrl = (id, searchParams) => {
    const base = `/campaigns/orderqueue/`;
    const path = id ? `${id}?` : "?";

    const urlSearchParams = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(searchParams)) {
      urlSearchParams.set(key, value);
    }

    return base + path + urlSearchParams.toString();
  };

  const handleSubmitCampaignOrder = async ({ dspType, dspConfig }) => {
    try {
      switch (selectedCampaignOrder.status) {
        case "LIVE":
        case "LIVE_APPROVED":
          updateCampaignDSP({
            variables: {
              dspType: selectedCampaignOrder.dspConfig.name,
              data: {
                campaignOrderId: selectedCampaignOrder.id,
                dspConfig: {
                  ...(selectedCampaignOrder.dspConfig || {}),
                  ...dspConfig,
                },
              },
            },
          });
          break;
        default:
          let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (!clientTimezone.includes("America/")) {
            clientTimezone = TimezonesConfig.default;
          }

          launchCampaignDSP({
            variables: {
              dspType,
              data: {
                campaignOrderId: selectedCampaignOrder.id,
                clientTimezone,
                dspConfig,
              },
            },
          });
          break;
      }
      setDspStatus("IN_PROGRESS");
    } catch (error) {
      return error;
    }
  };

  const isVisibleContent = () => {
    if (loadingCampaignOrders || loadingCampaignOrdersReady) return false;

    if (!selectedCampaignOrder) return false;

    if (selectedCampaignOrder) {
      switch (searchParams.tab) {
        case "READY":
          return ["APPROVED", "LIVE", "LIVE_APPROVED"].includes(
            selectedCampaignOrder.status
          );

        default:
          return ["PENDING", "LIVE_PENDING", "PAUSED"].includes(
            selectedCampaignOrder.status
          );
      }
    }

    return selectedCampaignOrder.status === searchParams.tab;
  };

  return (
    <>
      {React.cloneElement(children, {
        ...props,
        campaignOrders,
        searchParams,
        selectedCampaignOrder,
        pagination,
        config,
        createNotification,
        currentUser,
        tabletMode,
        loadingSelectedCampaignOrder,
        isVisibleContent,
        loadingCampaignOrders,
        loadingCampaignOrdersReady,
        launchCampaignDSP,
        campaignHistories,
        formattedTargets,
        launchCampaignDSPLoading,
        dspStatus,
        formattedWalkIns,
        setSelectedCampaignOrder,
        handleTabChange,
        handlePaginationChange,
        createMessageRemarks,
        handleFilterChange,
        handleSelectCampaignOrder,
        handleSubmitCampaignOrder,
        handleSubmitCampaignOrderReview,
        handleLaunchCampaignOrderManually,
        adUnitBannerIds,
        setAdUnitBannerIds,
        tableKey,
      })}
    </>
  );
};
