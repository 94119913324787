/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { Row, Col, Card, Descriptions, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../../core/utils/campaigns";
import PctDiff from "../components/PctDiff";
import "../../../../shared/globalStyling/responsive.css";

const RollUpSEOView = ({ loading, data, diffPcts, currencyFormat }) => {
  if (!data && !loading) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Card title="SEO">
        <Row gutter={[16, 16]}>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              loading={loading}
              title="Sessions"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="The period of time a user is active on your site or app. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label={formatNumber(data.sessions)}>
                      {diffPcts && <PctDiff value={diffPcts.sessions} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              loading={loading}
              title="New Users"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="A 'new user' is a visitor who has never been to your site before and is initiating their first session on your site. Reliant on browser cookies, will identify a 'returning user' if a cookie is present, and a 'new user' if a cookie is not present. Note: Ad / cookie blocking can produce inaccurate results. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label={formatNumber(data.newUsers)}>
                      {diffPcts && <PctDiff value={diffPcts.newUsers} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              title="Transactions"
              loading={loading}
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Total transactions generated from attributed organic search clicks. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item
                      label={`${formatNumber(data.transactions)}`}
                    >
                      {diffPcts && <PctDiff value={diffPcts.transactions} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              title="Ecom Conversion Rate"
              loading={loading}
              extra={
                <Tooltip
                  placement="topLeft"
                  title="The conversion rate is the number of conversions divided by the total number of visitors. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item
                      label={`${formatNumber3SigFig(data.conversionRate)}%`}
                    >
                      {diffPcts && <PctDiff value={diffPcts.conversionRate} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col className="ant-col-xs-100 ant-col-md-flex-auto">
            <Card
              title="Revenue"
              loading={loading}
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Total amount of Revenue attributed from organic search clicks for all SEO properties. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {data && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item
                      label={`${formatCurrency2SigFig(
                        data.revenue,
                        currencyFormat
                      )}`}
                    >
                      {diffPcts && <PctDiff value={diffPcts.revenue} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default RollUpSEOView;
