import React from "react";
import SignUpsTabView from "./SignUpsTab";
import Loader from "../../../../../../core/components/campaigns/campaigndetails/signupsTab/SignUpsTabDataLoader.js";

// Feature level subroutes
const SignUpsTab = props => {
  return (
    <Loader {...props}>
      <SignUpsTabView />
    </Loader>
  );
};

export default SignUpsTab;
