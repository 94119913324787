import React from "react";
import GoogleTab from "./GoogleTab";
import PaidSearchGoogleLoader from "../../../../../../core/components/paidSearch/paidsearchdetails/GoogleTab/PaidSearchGoogleLoader";

const GoogleDetailsView = props => {
  return (
    <PaidSearchGoogleLoader {...props}>
      <GoogleTab id={props.id} />
    </PaidSearchGoogleLoader>
  );
};

export default GoogleDetailsView;
