import React, { useMemo } from "react";

const HomeDetailsController = props => {
  const reportMetaData = useMemo(() => {
    if (props.orgSummary && props.orgSummary.reports) {
      return props.orgSummary.reports[0];
    }
    return {
      id: "",
    };
  }, [props.orgSummary]);
  return React.cloneElement(props.children, {
    ...props,
    reportMetaData,
  });
};

export default HomeDetailsController;
