import gql from "graphql-tag";

const GET_ORG_MEDIAS = gql`
  query orgMedias($orgId: ID, $flag: Boolean = true, $type: MediaType) {
    medias(
      orderBy: createdAt_DESC
      where: {
        AND: [
          { flag: $flag }
          { type: $type }
          { sharedWith_some: { id: $orgId } }
        ]
      }
    ) {
      id
      name
      size
      fileType
      height
      width
      key
      url
      type
      org {
        id
        name
      }
      sharedWith {
        name
      }
      mediaNotes
      mediaHasNotes
      createdAt
    }
  }
`;

export default GET_ORG_MEDIAS;
