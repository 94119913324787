import React, { useState, useEffect } from "react";
import { defaultAttributionWindow } from "../../../../utils/constants/constants";

const CampaignChartsController = ({
  children,
  dataConfig,
  url,
  expiresAt,
  refetch,
  chartData,
  setChartData,
  reportName,
  getSignupsTable,
  signupsTableLoading,
  signupsTable,
  pageSize,
  campaignOrder,
  ...props
}) => {
  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const [rawData, setRawData] = useState(true);
  const [loadingRawData, setLoadingRawData] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(null);

  const [s3Url, setS3Url] = useState(null);

  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentStartEndDate, setCurrentStartEndDate] = useState(null);
  const [attributionWindow, setAttributionWindow] = useState(
    defaultDisplayAttributionWindow
  );

  //Time check happens first

  useEffect(() => {
    const currentTime = new Date().toUTCString();
    if (currentTime <= expiresAt) {
      //url is valid - set url
      setS3Url(url);
      setIsValidUrl(true);
    } else {
      //url is valid - refetch
      setIsValidUrl(false);
      refetch();
      setS3Url(null);
    }
  }, [expiresAt, s3Url, setS3Url, refetch, url]);

  //If check happens - we have a url (from reload or first load)
  useEffect(() => {
    if (s3Url && loadingRawData)
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setRawData(json);
              setLoadingRawData(false);
            });
          } catch (err) {
            setLoadingRawData(false);
          }
        }
      });
  }, [s3Url, loadingRawData]);

  useEffect(() => {
    if (
      rawData &&
      rawData.signupsWithImpressions &&
      !chartData.signUpsWithImpressions
    ) {
      setChartData({
        ...chartData,
        signUpsWithImpressions: rawData.signupsWithImpressions,
        hasSimplifiedReport: rawData.hasSimplifiedReport,
      });
    }
  }, [rawData, setChartData, chartData]);

  useEffect(() => {
    if (rawData && rawData.hasSimplifiedReport) {
      getSignupsTable({
        variables: {
          campaignOrderId: campaignOrder.id,
          reportName,
          index: currentIdx + 1,
          limit: pageSize,
          startDateTimestamp: currentStartEndDate
            ? currentStartEndDate[0]
            : null,
          endDateTimestamp: currentStartEndDate ? currentStartEndDate[1] : null,
          attributionWindow,
        },
      });
    }
  }, [
    campaignOrder,
    currentIdx,
    getSignupsTable,
    pageSize,
    reportName,
    currentStartEndDate,
    attributionWindow,
    rawData,
  ]);

  if (isValidUrl === true) {
    return React.cloneElement(children, {
      chartData: rawData,
      chartsLoading: loadingRawData,
      isValidUrl,
      reportName,
      signupsTable,
      signupsTableLoading,
      attributionWindow,
      setCurrentIdx,
      setCurrentStartEndDate,
      setAttributionWindow,
      ...props,
    });
  } else {
    return React.cloneElement(children, {
      chartData,
      isValidUrl,
      ...props,
    });
  }
};

export default CampaignChartsController;
