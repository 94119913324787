import React from "react";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import ReportProvider from "../../../../shared/ReportProvider";

const PageViewLoader = ({ reportKey, ...props }) => {
  const {
    data: signedUrl,
    loading: signedUrlLoading,
    error: signedUrlError,
  } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  return (
    <ReportProvider
      signedUrl={
        signedUrl && signedUrl.hasOwnProperty("getSignedURL")
          ? signedUrl.getSignedURL
          : null
      }
    >
      {React.cloneElement(props.children, {
        ...props.children.props,
        signedUrlLoading,
        signedUrlError,
      })}
    </ReportProvider>
  );
};

export default PageViewLoader;
