import React, { Component } from "react";
import { ReportLoader } from "./ReportLoader";

class Controller extends Component {
  constructor(props) {
    super(props);
    const { id, name, description, type, tags } = this.props.audience;

    this.state = {
      id: id,
      name: name,
      description: description,
      type: type,
      tags: tags,
      selectedGeoTargets: [],
    };
  }

  changeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  connectGeoTargets = async () => {
    // Update Audience
    console.log("connect");
  };

  disconnectGeoTargets = async () => {
    // Update Audience
    console.log("disconnect");
  };

  submitUpdateAudience = async () => {
    const { updateAudience } = this.props;
    const { id, name, description, type } = this.state;
    // Update Audience
    return updateAudience({
      variables: {
        id: id,
        name: name,
        description: description,
        type: type,
      },
    });
  };

  render() {
    return (
      <ReportLoader
        {...this.state}
        {...this.props}
        changeValue={this.changeValue}
        connectGeoTargets={this.connectGeoTargets}
        disconnectGeoTargets={this.disconnectGeoTargets}
        submitUpdateAudience={this.submitUpdateAudience}
      />
    );
  }
}

export default Controller;
