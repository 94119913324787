import React from "react";
import { Table, Row, Col, Tooltip, Progress, Input, Button } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../../shared/globalStyling/styledText";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";

const { Search } = Input;

const LineItemsTable = ({
  selectedCampaignLineItemsData,
  selectedCampaignLineItemsLoading,
  setCloneModal,
  currentPagination,
  setPagination,
  total,
  searchLineName,
  setSearchLineName,
  onUpdateCampaignOrderLineItem,
}) => {
  const getCampaignLineItems =
    selectedCampaignLineItemsData &&
    selectedCampaignLineItemsData.campaignsConnection &&
    selectedCampaignLineItemsData.campaignsConnection.edges;

  const tableData = !isEmpty(getCampaignLineItems)
    ? getCampaignLineItems.map(val => {
        return {
          key: val.cursor,
          ...val.node,
        };
      })
    : [];

  const handleTableChange = (pagination, filters, sorter) => {
    if (currentPagination.pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, current: 1 });
    } else {
      setPagination(pagination);
    }
  };

  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (text, record) => {
        return (
          <>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Flight Date"} />,
      dataIndex: "startDate",
      key: "dateRange",
      align: "left",
      width: "30%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.startDate);
        const endDate = moment(record.endDate);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <>
            <Row type="flex" justify="start">
              {record.startDate && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.endDate && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      {selectedCampaignLineItemsLoading === false ? (
        <>
          <Row style={{ background: "#f4f4f4" }}>
            <Col span="8">
              <p
                style={{
                  marginTop: "25px",
                  marginLeft: "30px",
                }}
              >
                Reminder: Choose a Line item to Sync to this Campaign Order
              </p>
            </Col>
            <Col span="8" offset="4">
              <Search
                placeholder={
                  !isEmpty(searchLineName)
                    ? searchLineName
                    : "Search a Line Item"
                }
                onSearch={e => {
                  setSearchLineName(e);
                }}
                style={{ width: 200, marginTop: "20px", marginBottom: "20px" }}
              />
              <Button
                onClick={() => {
                  setSearchLineName("");
                }}
                icon={<CloseOutlined />}
                style={{
                  marginLeft: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Clear
              </Button>
            </Col>
            <Col span="24" style={{ background: "white" }}>
              <Table
                scroll={{ y: 610 }}
                columns={columns}
                sortDirections={["ascend", "descend"]}
                pagination={{ ...currentPagination, total }}
                onChange={handleTableChange}
                dataSource={!isEmpty(tableData) ? tableData : []}
                rowSelection={{
                  type: "radio",
                  onSelect: selectedRowKeys => {
                    const {
                      name,
                      demandSidePlatform,
                      clientOrg,
                      budgetTotal,
                      budgetByDay,
                      startDate,
                      endDate,
                      originalStartDate,
                      originalEndDate,
                      logsKey,
                      phoneNumber,
                      forwardingNumber,
                      cpm,
                      rawCost,
                    } = selectedRowKeys;

                    onUpdateCampaignOrderLineItem(
                      name,
                      demandSidePlatform,
                      clientOrg,
                      budgetTotal,
                      budgetByDay,
                      startDate,
                      endDate,
                      originalStartDate,
                      originalEndDate,
                      logsKey,
                      phoneNumber,
                      forwardingNumber,
                      cpm,
                      rawCost
                    );
                    setCloneModal(false);
                  },
                }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <LoadingOutlined />
        </div>
      )}
    </>
  );
};

export default LineItemsTable;
