export const getColorTag = operator => {
  switch (operator) {
    case "=":
      return "#1677FF";
    case "!=":
      return "#F77C47";
    case ">":
      return "#38B000";
    case "<":
      return "#FF5733";
    case ">=":
      return "#2A9500";
    case "<=":
      return "#FF8C66";
    case null:
      return "F77C47";
    default:
      return "#1677FF";
  }
};

export const getGaugeColor = value => {
  if (value > 0 && value < 9999) {
    return ["#BF0F18", "#CDCDCD"];
  } else if (value >= 10000 && value <= 49000) {
    return ["#F5222D", "#CDCDCD"];
  } else if (value >= 50000 && value <= 249000) {
    return ["#F77C47", "#CDCDCD"];
  } else if (value >= 250000 && value <= 999999) {
    return ["#EDC809", "#CDCDCD"];
  } else if (value >= 1000000) {
    return ["#52C41A", "#CDCDCD"];
  } else {
    return ["#CDCDCD"]; // default case
  }
};
