import React from "react";
import { Row, Col, Typography } from "antd";

const { Text } = Typography;
const Frag = React.Fragment;
const styles = {
  cardRow: {
    padding: "15px",
    borderBottom: "1px solid #e8e8e8",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
};

const ReviewDemographics = ({ devices }) => {
  const { secondaryRow, textRow } = styles;
  return (
    <Frag>
      <Row style={secondaryRow}>
        <Col span={4}>
          <Row style={textRow}>
            <Text type="secondary">Devices</Text>
          </Row>
          {devices &&
            devices.map((value, index) => {
              return (
                <Row key={index}>
                  <Text>{value}</Text>
                </Row>
              );
            })}
        </Col>
      </Row>
    </Frag>
  );
};

export default ReviewDemographics;
