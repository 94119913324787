import React from "react";
import { Tooltip } from "antd";
// Global Styles
import { TooltipText } from "../../../../../../shared/globalStyling/styledText";
import { QuestionCircleOutlined } from "@ant-design/icons";

const ExtraInfo = ({ title, placement }) => {
  return (
    <Tooltip
      placement={placement}
      arrowPointAtCenter
      title={<TooltipText text={title} />}
    >
      <QuestionCircleOutlined />
    </Tooltip>
  );
};

export { ExtraInfo };
