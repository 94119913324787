import gql from "graphql-tag";

const EDIT_SHARING_RECORD = gql`
  mutation editSharingRecord($id: Int!, $segment_id: [ById!]) {
    editSharingRecord(where: { id: $id }, data: { segments: $segment_id }) {
      id
      data_member_id
      buyer_member_id
      segment_exposure
      segments
      error
    }
  }
`;

export default EDIT_SHARING_RECORD;
