import React from "react";
import { Col, Result, Row, Typography } from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../../../shared/layout";
import DataView from "./components/DataView";
import ROIView from "./components/ROIView";
import AnalyticsTab from "./components/AnalyticsTab";
import Button from "../../../../shared/globalStyling/Button";
import { Link } from "react-router-dom";
import { AreaChartOutlined } from "@ant-design/icons";
import moment from "moment";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { isEmpty } from "lodash";
import AdvertiserFilter from "../../../../shared/AdvertiserFilter";
import { orgLevelCustomization } from "../../../../../core/permissions/orgLevelCustomization";

const { Title, Text } = Typography;

const SEOAnalyticsView = ({
  history,
  loading,
  error,
  data,
  orgConfig,
  refetch,
  advertiserParams,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  priceVisible,
  exportData,
  defaultSummaryDates,
  isGoogleAds,
  WRITE,
  diffPcts,
  currentUser,
  selectedRangeKey,
}) => {
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  if (!orgConfig) {
    return (
      <React.Fragment>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button onClick={() => refetch()} type="primary">
              Reload
            </Button>
          }
        />
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Link to="/home">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </React.Fragment>
    );
  }

  const refreshPage = () => {
    history.push("/");
    history.goBack();
  };

  const updatedAt = () => {
    if (!isEmpty(data) && !isEmpty(data.updatedAt)) {
      return `Updated ${moment(
        data.updatedAt,
        moment.DATETIME_LOCAL_MS
      ).calendar()}`;
    }
    return "--";
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"SEO Performance"}
        subtitle={updatedAt()}
        titleIcon={
          <AreaChartOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Col style={{ display: "flex" }} span={24}>
            <>
              <Button onClick={refreshPage}>
                <ButtonText text="Update" />
              </Button>
            </>
          </Col>
        }
      />
      <PageContentLayout>
        <Row>
          <Col xs={24} md={9} xl={5} xxl={4}>
            <Text>Filter by Advertiser</Text>
            <AdvertiserFilter isSinglePage={true} isDemo={orgConfig.isDemo} />
          </Col>
        </Row>
        <Title level={4} style={{ fontWeight: "400" }}>
          Data
        </Title>
        <DataView
          loading={loading}
          data={data && data.data}
          diffPcts={diffPcts && diffPcts.data}
        />
        <Title level={4} style={{ fontWeight: "400", marginTop: "1rem" }}>
          ROI
        </Title>
        <ROIView
          loading={loading}
          data={data && data.roi}
          diffPcts={diffPcts && diffPcts.roi}
          currencyFormat={currencyFormat}
        />
        <AnalyticsTab
          loading={loading}
          data={data}
          bySummaryRangeDate={bySummaryRangeDate}
          setBySummaryRangeDate={setBySummaryRangeDate}
          priceVisible={priceVisible}
          exportData={exportData}
          defaultSummaryDates={defaultSummaryDates}
          isGoogleAds={isGoogleAds}
          orgConfig={orgConfig}
          WRITE={WRITE}
          startDate={data.startDate}
          endDate={data.endDate}
          currencyFormat={currencyFormat}
          selectedRangeKey={selectedRangeKey}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SEOAnalyticsView;
