import React from "react";
import { Table } from "antd";

const CampaignsTable = ({ campaigns, columns, rowKey, onRow }) => {
  const defaultRowKey = record => record.id;
  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <Table
        dataSource={campaigns}
        columns={columns}
        rowKey={rowKey || defaultRowKey}
        scroll={{ x: 1200, y: 400 }}
        onRow={onRow}
      />
    </div>
  );
};

export default CampaignsTable;
