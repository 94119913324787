import gql from "graphql-tag";

const GET_BILLING_INVOICES = gql`
  query billingInvoices($where: BillingInvoiceWhereInput) {
    billingInvoices(where: $where) {
      id
      name
      type
      description
      amount
      dueDate
      status
      invoice
      paymentMethod
      details
      createdAt
    }
  }
`;

export default GET_BILLING_INVOICES;
