import gql from "graphql-tag";

const CAMPAIGN_ORDER_SHARING_DETAILS = gql`
  query campaignOrderSharing($ID: ID!) {
    campaignOrder(where: { id: $ID }) {
      orgs {
        id
        name
      }
    }
  }
`;

export default CAMPAIGN_ORDER_SHARING_DETAILS;
