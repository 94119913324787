import React from "react";
import { Modal, Table, Button, Spin } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { formatFileSize } from "../../../../shared/upload/formatFileSize";

const BulkUploadResultsModal = ({
  visible,
  onCancel,
  uploadResults,
  uploadInProgress,
  uploadComplete,
}) => {
  const columns = [
    {
      title: "Name of Image",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
        record.status ? (
          "Success"
        ) : (
          <span style={{ color: "red" }}>Failed</span>
        ),
      sorter: (a, b) => (a.status ? 1 : -1),
    },
    {
      title: "Information",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => {
        const reasonA = a.reason || ""; // If a.reason is undefined, use an empty string
        const reasonB = b.reason || ""; // If b.reason is undefined, use an empty string
        return reasonA.localeCompare(reasonB);
      },
    },
    {
      title: "Dimensions of Image",
      dataIndex: "dimensions",
      key: "dimensions",
      sorter: (a, b) => a.dimensions.localeCompare(b.dimensions),
    },
    {
      title: "Size of File",
      dataIndex: "fileSize",
      key: "fileSize",
      sorter: (a, b) => a.fileSize - b.fileSize,
      render: text => formatFileSize(text), // Assuming you have the formatFileSize function
    },
  ];

  return (
    <Modal
      title="Bulk Upload Results"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="close" type="primary" onClick={onCancel}>
          Close
        </Button>,
      ]}
      width={1000} // You can adjust the width based on your preference
    >
      {uploadInProgress && (
        <>
          <center>
            <p>Uploading...</p>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </center>
        </>
      )}
      {uploadComplete && (
        <>
          <center>
            <CheckCircleOutlined
              style={{ color: "green", fontSize: "24px", marginRight: "8px" }}
            />
            <p>Upload Complete</p>
          </center>
        </>
      )}

      <Table dataSource={uploadResults} columns={columns} pagination={false} />
    </Modal>
  );
};

export default BulkUploadResultsModal;
