import React from "react";
import { Row, Col, Progress, Avatar } from "antd";
import { withRouter } from "react-router-dom";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
  StandardTableRowText,
} from "../../../shared/globalStyling/styledText";
import { isEmpty } from "lodash";
import moment from "moment";
import MiniChart from "../../../shared/MiniChart";
import StatusTag from "./StatusTag";
import PaginationTable from "../../../shared/PaginationTable";
import { formatCurrency2SigFig } from "../../../../core/utils/campaigns";
import bing from "../../../../assets/icons/channels/bing-official.png";
import google from "../../../../assets/icons/channels/google-official.png";
import googleAds from "../../../../assets/icons/channels/googleads-official.png";

const Frag = React.Fragment;

const OrganizationsTable = ({
  data,
  history,
  possibleFields,
  selectedTableColumns,
  selectedRowKeys,
  onRowChange,
  location,
  total,
  current,
  pageSize,
  filter,
  currencyFormat,
}) => {
  const getColumn = {
    Name: field => {
      return {
        title: <TableColumnTitle text={"Name"} />,
        dataIndex: "org.name",
        key: "org.name",
        width: "15%",
        align: "left",

        render: (text, { org }) => {
          return (
            <Frag>
              <Row type="flex" justify="start">
                <Col>
                  <PrimaryTableRowText text={org.name} />
                </Col>
              </Row>
            </Frag>
          );
        },
      };
    },
    Flight: field => {
      return {
        title: <TableColumnTitle text={"Flight"} />,
        dataIndex: "startDate",
        key: "startDate",
        align: "left",
        width: "14%",
        footerContent: " ",
        render: (text, record) => {
          if (!record) {
            return <div />;
          }
          const currentDate = moment().format("MM/DD/YY");
          const startDate =
            filter === "month"
              ? moment()
                  .utc()
                  .startOf("month")
              : record.startDate
              ? moment(record.startDate)
              : null;
          const endDate =
            filter === "month"
              ? moment().utc()
              : record.aggregateData &&
                record.aggregateData.byDate &&
                record.aggregateData.byDate.length > 0
              ? moment(record.aggregateData.byDate[0].xAxis)
              : moment();
          const status = record.status ? record.status : "PENDING";

          let flightProgress = 0;
          if (currentDate > endDate) flightProgress = 100;
          else if (currentDate < endDate && currentDate > startDate)
            flightProgress =
              (currentDate.diff(startDate, "minutes") /
                endDate.diff(startDate, "minutes")) *
              100;

          return (
            <Frag>
              <Row type="flex" justify="start" align="middle" gutter={8}>
                {status !== "PENDING" && (
                  <>
                    {record.startDate && (
                      <Col>
                        <FlightDateText
                          text={startDate ? startDate.format("MM/DD/YY") : null}
                        />
                      </Col>
                    )}
                    <Col>
                      <FlightDateText
                        text={<span>&nbsp; &#8594; &nbsp;</span>}
                      />
                    </Col>
                    {status === "INACTIVE" && record.endDate && (
                      <Col>
                        <FlightDateText
                          text={endDate ? endDate.format("MM/DD/YY") : null}
                        />
                      </Col>
                    )}
                    {status === "NO_ACCESS" && record.endDate && (
                      <Col>
                        <FlightDateText
                          text={endDate ? endDate.format("MM/DD/YY") : null}
                        />
                      </Col>
                    )}
                  </>
                )}

                <Col>
                  <StatusTag
                    status={status}
                    noAccessChannels={
                      status === "NO_ACCESS" ? record.failedChannel : null
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Progress
                  id="main-campaign-table-progress"
                  type={"line"}
                  strokeWidth={3}
                  percent={flightProgress ? flightProgress : 0}
                  showInfo={false}
                  strokeColor={"#636363"}
                />
              </Row>
            </Frag>
          );
        },
      };
    },
    Impressions: field => {
      return {
        title: <TableColumnTitle text={field.title} />,
        dataIndex: field.key,
        key: `overallData.${field.dataAccessor}`,
        align: "center",
        width: `${field.title.length * 1}%`,

        render: (text, { aggregateData }) => {
          return (
            <Row type="flex" justify="center" align="middle">
              <Col>
                <MiniChart
                  data={
                    aggregateData && aggregateData.byDate
                      ? aggregateData.byDate
                      : []
                  }
                  yAxisLeft={field.title.toLowerCase()}
                />
              </Col>
            </Row>
          );
        },
      };
    },
    Performance: field => {
      return {
        title: <TableColumnTitle text={"Performance"} />,
        dataIndex: "performance",
        key: "performanceStatus",
        align: "left",
        width: "12%",
        footerStyle: "block",
        render: (text, { aggregateData }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(aggregateData) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Impressions:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={` ${Number(
                          parseInt(aggregateData.impressions).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Clicks:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(aggregateData.clicks).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    Transactions: field => {
      return {
        title: <TableColumnTitle text={"Sales"} />,
        width: "12%",
        align: "left",
        footerStyle: "block",
        render: (text, { aggregateData }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(aggregateData) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Revenue:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={` ${formatCurrency2SigFig(
                          aggregateData.revenue || 0,
                          currencyFormat
                        )}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Transactions:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(aggregateData.conversions).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    Budget: field => {
      let footerSums = [
        {
          property: "budget",
          formatter: "",
          title: "Budget",
        },
        {
          property: "impressionsGoal",
          formatter: "",
          title: "Impressions Goal",
        },
      ];
      let footerStyle = "block";

      return {
        title: <TableColumnTitle text={"Budget"} />,
        dataIndex: "budget",
        key: `budget`,
        align: "left",
        width: "16%",
        footerSums: footerSums,
        footerStyle: footerStyle,
        render: (budget, { aggregateData, config }) => {
          return (
            <Row type="flex" justify="start">
              <Col>
                <Row type="flex">
                  <PrimaryTableRowText text={`Budget:‏‏‎ ‎`} />
                  <StandardTableRowText
                    text={` ${formatCurrency2SigFig(
                      (config && config.monthlyBudget) || 0,
                      currencyFormat
                    )}`}
                  />
                </Row>

                <Row type="flex">
                  <PrimaryTableRowText text={`ROAS: ‎`} />
                  <StandardTableRowText
                    text={
                      `${Number(
                        parseInt(
                          aggregateData && aggregateData.roas
                            ? aggregateData.roas
                            : 0
                        ).toFixed(2)
                      ).toLocaleString()}%` || "0%"
                    }
                  />
                </Row>

                <Row type="flex">
                  <PrimaryTableRowText text={`Spend: ‎`} />
                  <span style={{ marginLeft: "5px" }}>
                    <StandardTableRowText
                      text={`${formatCurrency2SigFig(
                        aggregateData && aggregateData.spend
                          ? aggregateData.spend
                          : 0,
                        currencyFormat
                      )}`}
                    />
                  </span>
                </Row>
              </Col>
            </Row>
          );
        },
      };
    },
    Channels: field => {
      return {
        title: <TableColumnTitle text={"Channels"} />,
        render: (text, { overallData, overallBing, org, config }) => {
          const isGoogleAds = config && config.processWithGoogleAds;
          const isGoogle = overallData && org.dataConfig.googleCustomerId;
          return (
            <Row type="flex" justify="start">
              {isGoogle && !isGoogleAds && (
                <Avatar src={google} shape="square" size="small" />
              )}
              {isGoogle && isGoogleAds && (
                <Avatar src={googleAds} shape="square" size="small" />
              )}
              {overallBing &&
                org.dataConfig.bing &&
                org.dataConfig.bing.customerId &&
                org.dataConfig.bing.accountId && (
                  <Avatar src={bing} shape="square" size="small" />
                )}
            </Row>
          );
        },
      };
    },
  };

  const fieldsToRender = Object.keys(possibleFields).filter(
    field => selectedTableColumns.indexOf(field) >= 0
  );

  const columns = fieldsToRender.map(key => {
    const field = possibleFields[key];
    return getColumn[field.title]
      ? getColumn[field.title](field)
      : getColumn.generic(field);
  });

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        summary={false}
        onRow={data => ({
          onClick: () => {
            const { id } = data;
            history.push(`/paid_search/details/${id}/1`);
          },
        })}
        data={data}
        paginationSize={pageSize}
        showSizeChanger
        size={"default"}
        columns={columns}
        scroll={{ x: 1200 }}
        total={total}
        current={current}
        pageSize={pageSize}
        bordered={false}
        rowKey={record => record.id}
        pagination={{ position: ["none", "bottomCenter"] }}
        rowSelection={{
          onChange: onRowChange,
          selectedRowKeys,
          columnWidth: "5%",
        }}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          const nextPage = location.pathname.split("/");
          nextPage[3] = paginationObject.current;
          const searchParams = new URLSearchParams(window.location.search);

          if (searchParams.get("sort")) searchParams.delete("sort");
          if (searchParams.get("pageSize")) searchParams.delete("pageSize");

          if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
            searchParams.append("pageSize", paginationObject.pageSize);
          }
          if (!isEmpty(sorterObject))
            searchParams.append(
              "sort",
              `${sorterObject.field}_${
                sorterObject.order === "ascend" ? "ASC" : "DESC"
              }`
            );

          history.push({
            pathname: nextPage.join("/"),
            search: `?${searchParams.toString()}`,
          });
        }}
      />
    </div>
  );
};

export default withRouter(OrganizationsTable);
