import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_STATUS = gql`
  mutation updateCampaignOrderStatus(
    $id: ID!
    $status: CampaignStatus!
    $message: String!
    $type: Feature
    $featureID: String
  ) {
    updateCampaignOrderStatus(
      where: { id: $id }
      data: {
        status: $status
        message: $message
        type: $type
        featureID: $featureID
      }
    ) {
      id
      status
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_STATUS;
