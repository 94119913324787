import gql from "graphql-tag";

const CREATE_ARTICLE_CATEGORY = gql`
  mutation createArticleCategory(
    $title: String!
    $feature: [Feature!]
    $permission: [String!]
  ) {
    createArticleCategory(
      data: {
        title: $title
        permission: { set: $permission }
        feature: { set: $feature }
      }
    ) {
      id
      createdAt
      updatedAt
      title
      permission
      feature
    }
  }
`;

export default CREATE_ARTICLE_CATEGORY;
