import { InfoCircleOutlined } from "@ant-design/icons";
import { Collapse, Divider, Tag, Typography, Modal, Image } from "antd";
import React from "react";
import seo5 from "../../../../../../admin/assets/docs/seo/5-seo.png";
import seo6 from "../../../../../../admin/assets/docs/seo/6-seo.png";
import seo7 from "../../../../../../admin/assets/docs/seo/7-seo.png";
import seo9 from "../../../../../../admin/assets/docs/seo/9-seo.png";
import seo10 from "../../../../../../admin/assets/docs/seo/10-seo.png";
import seo11 from "../../../../../../admin/assets/docs/seo/11-seo.png";
import seo12 from "../../../../../../admin/assets/docs/seo/12-seo.png";
import seo13 from "../../../../../../admin/assets/docs/seo/13-seo.png";
import seoNew1 from "../../../../../../admin/assets/docs/seo/1-seo-new.png";
import seoNew2 from "../../../../../../admin/assets/docs/seo/2-seo-new.png";
import seoNew3 from "../../../../../../admin/assets/docs/seo/3-seo-new.png";
import seoNew4 from "../../../../../../admin/assets/docs/seo/4-seo-new.png";
import seoNew5 from "../../../../../../admin/assets/docs/seo/5-seo-new.png";
import seoNew6 from "../../../../../../admin/assets/docs/seo/6-seo-new.png";
import seoNew7 from "../../../../../../admin/assets/docs/seo/7-seo-new.png";

const SeoOnboardingDocs = () => {
  const [isOpen, setIsDocOpen] = React.useState(false);

  const toggleDocs = () => setIsDocOpen(prev => !prev);
  return (
    <>
      <Tag
        style={{ cursor: "pointer", borderRadius: "100px" }}
        color="blue"
        onClick={toggleDocs}
      >
        <InfoCircleOutlined /> onboarding
      </Tag>

      <Modal
        width={1000}
        title={"What is Google Search Console?"}
        handleClick={toggleDocs}
        open={isOpen}
        onCancel={toggleDocs}
        visible={isOpen}
        onOk={toggleDocs}
        centered
        footer={null}
      >
        <p>
          Google Search Console measures how searchable your site is in a
          numerical and a tabular format.
        </p>

        <p>
          <strong>Note</strong>:{" "}
          <em> ( example@google.com, example.com and yourwebsite.com ) </em> is
          a placeholder email for the whitelabel email you have onboarded with
          us.
        </p>

        <Divider orientation="left">Step 1</Divider>
        <Collapse size="small">
          <Collapse.Panel
            header={
              <>
                <strong>Step 1 (A)</strong>: Onboarding SEO via Google Analytics
              </>
            }
          >
            <ol>
              <li>
                Set up Google Analytics GA4 access{" "}
                <Typography.Link
                  href="https://analytics.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://analytics.google.com/
                </Typography.Link>
                <ol>
                  <li>
                    Navigate to the “<strong>Settings</strong>” page and you
                    should see something like this
                    <Image
                      src={seoNew5}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                  <li>
                    Click on “<strong>Account access management</strong>” and
                    add the “<em>example@google.com</em>” email account and
                    provide the “<strong>Administrator</strong>” account role.
                    <Image
                      src={seoNew6}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Set up GA4 on the Dashboard.
                <ol>
                  <li>
                    Log in to Dashboard and navigate to the "
                    <strong>Data Settings</strong>" section{" "}
                    <Typography.Link href="/settings/main/1">
                      settings page
                    </Typography.Link>
                  </li>
                  <li>
                    Then provide your GA4 ID in the “
                    <strong>Google Anlaytics</strong>” section then click on the
                    “<strong>Update</strong>” button below.
                    <Image
                      src={seoNew3}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                  <li>
                    Note: — Your GA4 ID is visible in Google Analytics Admin
                    section under “<strong>Property Details</strong>”. It’s
                    called <strong>PROPERTY ID</strong>.
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew2}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel
            header={
              <>
                <strong>Step 1 (B)</strong>: Onboarding Google Search Console
                (For existing clients)
              </>
            }
          >
            <p style={{ margin: "30px" }}>
              If domain verification is already completed for the client you are
              onboarding to Google Search Console. Then you’ll need to
              accomplish the following steps:
            </p>
            <ol>
              <li>
                Navigate to the Google Search Console{" "}
                <Typography.Link
                  href="https://search.google.com/search-console/welcome"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://search.google.com/search-console/welcome
                </Typography.Link>
              </li>
              <li>
                Navigate to the site you are onboarding to the dashboard for SEO
              </li>
              <li>
                Click on “<strong>Settings</strong>” on the bottom left
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo5}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Tap on “<strong>Users and permissions</strong>”
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo6}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>

              <li>
                Click on “<strong>Add User</strong>”
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo7}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>

              <li>
                Then add “<em>example@google.com</em>” and set “
                <strong>Permission</strong>” to “<strong>Owner</strong>” and
                click on “<strong>Add</strong>“
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew7}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel
            header={
              <>
                <strong>Step 1 (C)</strong>: Onboarding Google Search Console
                (For new clients)
              </>
            }
          >
            <p style={{ margin: "30px" }}>
              If domain verification has not yet been implemented, these are the
              steps to onboard Google Search SEO.
            </p>
            <ol>
              <li>
                Go to Google Search Console.{" "}
                <Typography.Link
                  href="https://search.google.com/search-console/welcome"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  https://search.google.com/search-console/welcome{" "}
                </Typography.Link>
              </li>
              <li>
                Login to "<em>example@google.com</em>"
              </li>
              <li>
                On the URL Prefix option, input the URL of the site you want to
                add in a format similar to "<em>https://www.yourwebsite.com</em>
                "
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo9}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>Click on Continue.</li>
              <li>
                Find the “<strong>HTML tag</strong>” Verification option
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo10}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Copy the “<strong>meta tag</strong>” which would look something
                like: <br />
                <div style={{ marginTop: "15px" }}>
                  <strong>
                    <pre>
                      {" "}
                      &lt;meta name="google-site-verification"
                      content="vHX_XXXXXXXXXXXXXX" /&gt;{" "}
                    </pre>
                  </strong>
                </div>
              </li>
              <li>
                Paste it within the <br />
                <div style={{ marginTop: "15px" }}>
                  <strong>
                    <pre> &lt;head&gt;&lt;/head&gt; </pre>
                  </strong>
                </div>{" "}
                section of the site you are onboarding to verify the domain.
                Ensure that the tag applies to all pages of the domain.
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Collapse size="small">
          <Collapse.Panel header="Steps to add the meta tag to “Wordpress”">
            <ol>
              <li>
                Find the “
                <strong>Add a meta tag to your site's home page</strong>”, copy
                the meta tag.
              </li>
              <li>
                Login to the Website Manager. (e.g. <em>Wordpress/Wix</em>)
              </li>
              <li>
                Go to Snippets → Add New (Make sure that the "
                <strong>Snippets</strong>" Plugin is installed)
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo11}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Paste the meta tag.
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo12}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Scroll down to the bottom of the screen. Choose the Scope for
                your snippet. Click Save Changes and Activate.
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seo13}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>Go back to Google Search Console and click Verify.</li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
        <Divider orientation="left">Step 2</Divider>
        <Collapse size="small">
          <Collapse.Panel
            header={
              <>
                <strong>Step 2</strong>: Onboarding to the dashboard
              </>
            }
          >
            <ol>
              <li>
                Log in to dashboard with the account that you want to set it up
                with.
              </li>
              <li>
                On the sidebar menu go to {">"} <strong>Admin</strong> {">"}{" "}
                <strong>Settings</strong>
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew4}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Scroll down to the “<strong>Data Settings</strong>” section on
                the bottom of the page. Then provide your GA4 ID in the “
                <strong>Google Analytics</strong>” section then click on the “
                <strong>Update</strong>” button below. Make sure to toggle GA4
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew3}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Note: — Your GA4 ID is visible in Google Analytics Admin section
                under “<strong>Property Details</strong>”. It’s called{" "}
                <strong> PROPERTY ID </strong>.
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew2}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
              <li>
                Make sure the siteUrl value in the image above is the same as
                the Website domain rankings on the Org Config section.
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    <Image
                      src={seoNew1}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  </li>
                </ol>
              </li>
            </ol>
          </Collapse.Panel>
        </Collapse>
        <br />
      </Modal>
    </>
  );
};

export default SeoOnboardingDocs;
