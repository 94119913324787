import React from "react";
import { Card, Row, Col, DatePicker } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import SingleAxisArea from "./SingleAxisArea";
// Global Styles
import {
  CardTitleText,
  CardText,
} from "../../../../../../shared/globalStyling/styledText";
// Helper functions
// import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

import { ExtraInfo } from "./";
const { RangePicker } = DatePicker;
const Frag = React.Fragment;

const DateSelector = ({
  data,
  byWalkinRangeDate,
  setByWalkinRangeDate,
  dataWalkinRangeDate,
  setDataWalkinRangeDate,
}) => {
  // map xAxis dates
  const getDate = data.map(a => a.xAxis);

  // sort them by desc dates
  getDate.sort(function(a, b) {
    return moment(a.date) - moment(b.date);
  });

  // get startdate and end date
  const startDate = getDate[0];
  const endDate = getDate.slice(-1)[0];

  // combined start date & end date
  const startEndDate = [moment(startDate), moment(endDate)];

  // find the dates between startdate and enddate
  const findBetweenDates = current => {
    if (current <= moment(startDate).add(-1, "days")) {
      return true;
    } else if (current >= moment(endDate).add(+1, "days")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <RangePicker
      disabledDate={findBetweenDates}
      defaultPickerValue={startEndDate}
      defaultValue={startEndDate}
      //value={isEmpty(byCampaignRangeDate) ? startEndDate : byCampaignRangeDate}
      onChange={v => {
        // get selected range dates
        const getSelectedStartDate = moment(v[0]).format();
        const getSelectedEndDate = moment(v[1])
          .add(+1, "days")
          .format();

        // filter data to get only between range dates
        const getFilteredData = data.filter(obj => {
          return (
            obj.xAxis >= getSelectedStartDate && obj.xAxis <= getSelectedEndDate
          );
        });

        // set the date range on the selected date in calendar
        setByWalkinRangeDate(v);
        // set the data into filtered ones with the selected range dates
        setDataWalkinRangeDate(getFilteredData);
      }}
    />
  );
};

const explanation =
  "This chart displays the walk-in number of responses per day for the duration fo the campaign.";

const WalkinChartByDate = ({
  data,
  flightDates,
  byWalkinRangeDate,
  setByWalkinRangeDate,
  dataWalkinRangeDate,
  setDataWalkinRangeDate,
}) => {
  // map xAxis dates
  const getDate = data.map(a => a.xAxis);

  // sort them by desc dates
  getDate.sort(function(a, b) {
    return moment(a.date) - moment(b.date);
  });

  // get startdate and end date
  const startDate = moment(getDate[0])
    .add(1, "days")
    .format();
  const endDate = moment(getDate.slice(-1)[0]).format();

  const getStartDate = moment(startDate).format("MMMM DD");
  const getStartFlightDate = flightDates && flightDates.start;

  // filter data to get only between range dates
  const getFilteredData = data.filter(obj => {
    return obj.xAxis >= startDate && obj.xAxis <= endDate;
  });

  return (
    <Frag>
      <Card
        title={<CardTitleText text={"Walk-in chart by date"} />}
        headStyle={{ borderWidth: "2px" }}
        bodyStyle={{ padding: "1%" }}
        bordered
        extra={<ExtraInfo placement="topRight" title={explanation} />}
      >
        <Row type="flex" justify="center">
          <DateSelector
            data={data}
            byWalkinRangeDate={byWalkinRangeDate}
            setByWalkinRangeDate={setByWalkinRangeDate}
            dataWalkinRangeDate={dataWalkinRangeDate}
            setDataWalkinRangeDate={setDataWalkinRangeDate}
          />
        </Row>
        {isEmpty(data) ? (
          <Row type="flex" justify="center" align="middle">
            <CardText
              text={
                "Please refresh your browser to receive the latest updates."
              }
            />
          </Row>
        ) : (
          <Row style={{ marginBottom: "2%" }} type="flex" justify="center">
            {data.length !== 1 && (
              <Col xs={24}>
                <Frag>
                  <SingleAxisArea
                    tooltipName={"Walk Ins"}
                    flightDates={flightDates}
                    leftY={"count"}
                    height={350}
                    data={
                      !dataWalkinRangeDate || !dataWalkinRangeDate.length
                        ? getStartDate >= getStartFlightDate
                          ? getFilteredData
                          : !dataWalkinRangeDate || !dataWalkinRangeDate.length
                          ? data.sort((a, b) => {
                              if (moment(a.xAxis).isBefore(b.xAxis)) return -1;
                              return 1;
                            })
                          : dataWalkinRangeDate
                        : dataWalkinRangeDate
                    }
                  />
                </Frag>
              </Col>
            )}
          </Row>
        )}
      </Card>
    </Frag>
  );
};

export default WalkinChartByDate;
