import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table } from "antd";

import { useAudienceContext } from "../../../core/components/audiences/useAudienceContext";
import moment from "moment";

const columns = [
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  {
    title: "Email Count",
    dataIndex: "hashedEmailsCount",
    key: "hashedEmailsCount",
    render: count => (count ? count.toLocaleString() : "N/A"),
  },
  {
    title: "Devices",
    dataIndex: "deviceCount",
    key: "deviceCount",
    render: count => (count ? count.toLocaleString() : "N/A"),
  },
  {
    title: "Match %",
    dataIndex: "matchRate",
    key: "matchRate",
    render: rate => (rate ? rate.toFixed(2).toLocaleString() : "N/A"),
  },
  {
    title: "Status",
    dataIndex: "activated",
    key: "activated",
    render: activated => (activated ? "Activated" : "Deactivated"),
  },
  // {
  //   title: "Device IDs",
  //   dataIndex: "deviceIds",
  //   key: "deviceIds",
  // },
  // {
  //   title: "Info",
  //   dataIndex: "info",
  //   key: "info",
  // },
  {
    title: "Uploaded",
    dataIndex: "createdAt",
    key: "createdAt",

    render: date => moment(date).format("MM/DD/YYYY hh:mma"),
  },
];

const CampaignRequestorAudienceDialog = ({
  visible,
  setVisible,
  orgId,
  onAddSelections,
  selectedAudiences,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    fetchHashedEmailListWithCount,
    hashedEmailListWithCount,
    loadingHashedEmailListWithCount,
  } = useAudienceContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }
    const selectedHasedEmailList = hashedEmailListWithCount.filter(hasedEmail =>
      selectedRowKeys.includes(hasedEmail.id)
    );
    onAddSelections(selectedHasedEmailList);
    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    fetchHashedEmailListWithCount({
      variables: {
        orgId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAudiences && selectedAudiences.length > 0) {
      const keys = [];
      selectedAudiences.forEach(group => {
        // Find the targeting group in the list by name
        const targeting = hashedEmailListWithCount.find(
          t => t.name === group.name
        );
        if (targeting) {
          keys.push(targeting.id);
        }
      });
      setSelectedRowKeys(keys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashedEmailListWithCount]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>EMAIL AUDIENCES</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => handleAddSelections()}
        footer={null}
        width={1200}
      >
        {loadingHashedEmailListWithCount ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey={record => record.id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={hashedEmailListWithCount}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignRequestorAudienceDialog;
