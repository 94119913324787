import React from "react";
import { Card, Col, Form, Row, Space, Typography } from "antd";

import CampaignRequestorReviewDetails from "./CampaignRequestorReviewDetails";
import CampaignRequestorCategory from "./CampaignRequestorReviewCategory";
import CampaignRequestorReviewAttribution from "./CampaignRequestorReviewAttribution";
import CampaignRequestorReviewLineItem from "./CampaignRequestorReviewLineItem";

const { Text } = Typography;

// Temporarily add editable prop to CampaignRequestorReview to accomodate the old campaign order queue
const CampaignRequestorReview = ({ form, editable = false }) => {
  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <CampaignRequestorReviewDetails form={form} editable={editable} />
      <CampaignRequestorCategory form={form} />
      <CampaignRequestorReviewAttribution form={form} />
      <Form.List name="campaigns">
        {fields => (
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => {
              return (
                <CampaignRequestorReviewLineItem
                  form={form}
                  key={key}
                  restField={restField}
                  name={name}
                  fieldKey={fieldKey}
                  index={index}
                />
              );
            })}
          </Space>
        )}
      </Form.List>
      <Card title="CAMPAIGN NOTES">
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col span={24}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text type="secondary">Additional Details</Text>
                <Text>{form.getFieldValue("notes")}</Text>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </Space>
  );
};

export default CampaignRequestorReview;
