import gql from "graphql-tag";

const START_LIQUIDM_CAMPAIGN = gql`
  mutation startLiquidMCampaign($id: String!) {
    startLiquidMCampaign(id: $id) {
      id
      name
      status
    }
  }
`;

export default START_LIQUIDM_CAMPAIGN;
