import gql from "graphql-tag";

const CREATE_ADVERTISERS = gql`
  mutation CreateAdvertiser($data: AdvertiserCreateInput!) {
    createAdvertiser(data: $data) {
      id
      name
    }
  }
`;

export default CREATE_ADVERTISERS;
