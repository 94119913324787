import gql from "graphql-tag";

const EDIT_LOCATION_ADMIN_WITH_GEOTARGETS = gql`
  # TODO: Enhance payload handling for this mutation
  # Redundant with EDIT_LOCATION?
  # @params: data: LocationUpdateInput!
  mutation updateLocation(
    $id: ID!
    $name: String!
    $description: String!
    $street: String!
    $city: String!
    $state: StateType
    $country: CountryType!
    $email: String!
    $url: String!
    $phone: String!
    $contact: String!
    $formattedAddress: String
    $zip: String!
    $cart: CartProvider
    $menuDataKey: String
    $geoId: ID
    $locationKey: String
    $province: String
    $updatedBy: ID
    $lat: Float
    $lng: Float
  ) {
    updateLocation(
      data: {
        name: $name
        description: $description
        street: $street
        city: $city
        state: $state
        country: $country
        email: $email
        url: $url
        phone: $phone
        contact: $contact
        formattedAddress: $formattedAddress
        zip: $zip
        transConfig: {
          upsert: {
            create: { cart: $cart, menuDataKey: $menuDataKey }
            update: { cart: $cart, menuDataKey: $menuDataKey }
          }
        }
        geoframe: { connect: { id: $geoId } }
        locationKey: $locationKey
        province: $province
        updatedBy: { connect: { id: $updatedBy } }
        lat: $lat
        lng: $lng
      }
      where: { id: $id }
    ) {
      id
      name
      description
      street
      city
      state
      country
      province
      lat
      lng
      formattedAddress
      email
      phone
      contact
      url
      zip
      locationKey
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
      geoframe {
        id
        name
        start
        end
        polygons {
          id
          geometry
        }
        circles {
          id
          coordinate {
            lat
            lng
          }
          radius
        }
      }
      transConfig {
        id
        cart
        menuDataKey
      }
      updatedBy {
        name
      }
    }
  }
`;

export default EDIT_LOCATION_ADMIN_WITH_GEOTARGETS;
