import gql from "graphql-tag";

const TAG_TRACKING_GET_EVENTS_ORGS = gql`
  query getOrgTags($orgId: ID) {
    orgs(
      where: { OR: [{ parentOrg_some: { id: $orgId } }, { id: $orgId }] }
      first: 1000
    ) {
      id
      name
      eventsTarget(where: { OR: [{ isActive: null }, { isActive: true }] }) {
        id
        name
        cart
        eventTags {
          id
          appId
        }
        tag
        segmentId
        segmentName
      }
    }
  }
`;

export default TAG_TRACKING_GET_EVENTS_ORGS;
