import React from "react";
import { Button } from "antd";

const defaultStyle = {
  fontSize: "14px",
  minWidth: "100px",
  marginRight: "10px",
  height: "36px",
  margin: "12px 10px 12px 0",
};

const GlobalButton = ({ style, children, ...props }) => (
  <Button
    style={{ ...defaultStyle, ...style }}
    type="default"
    shape="round"
    {...props}
  >
    {children}
  </Button>
);

export default GlobalButton;
