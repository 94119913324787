import { Typography } from "antd";
import React from "react";
import InstructionsBuilder from "./InstructionsBuilder";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";
import { Text } from "@react-pdf/renderer";

import img1 from "../../../../../../assets/tagsInstructions/googleTagManager/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/googleTagManager/img2.png";
import img3 from "../../../../../../assets/tagsInstructions/googleTagManager/img3.png";
import img4 from "../../../../../../assets/tagsInstructions/googleTagManager/img4.png";
import img5 from "../../../../../../assets/tagsInstructions/googleTagManager/img5.png";
import img6 from "../../../../../../assets/tagsInstructions/googleTagManager/img6.png";
import img7 from "../../../../../../assets/tagsInstructions/googleTagManager/img7.png";
import img8 from "../../../../../../assets/tagsInstructions/googleTagManager/img8.png";

const googleTagManager = generatedTag => [
  {
    instruction: (
      <>
        Go to{" "}
        <Typography.Link
          href="http://tagmanager.google.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tag Manager
        </Typography.Link>
        .
      </>
    ),
    pdfText: "Go to Tag Manager",
  },
  {
    instruction: (
      <>
        Select the GTM container that should already be installed on your
        website. In this example our website is{" "}
        <Typography.Link
          href="http://www.mywebsite.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.mywebsite.com.
        </Typography.Link>
      </>
    ),
    image: img1,
    pdfText:
      "Select the GTM container that should already be installed on your website. In this example our website is www.mywebsite.com.",
  },
  {
    instruction: "Create a new tag by clicking the “Add New Tag” button.",
    image: img2,
  },
  {
    instruction:
      "Rename the Untitled Tag to Attribution Tag or any preferred name of your choosing.",
    image: img3,
  },
  {
    instruction: (
      <>
        Click on the <Typography.Text strong>Tag Configuration</Typography.Text>{" "}
        and select <Typography.Text strong>“Custom HTML”</Typography.Text>.
      </>
    ),
    image: img4,
    pdfText: "Click on the Tag Configuration and select “Custom HTML”.",
  },
  {
    instruction:
      "Click on the HTML sheet and paste the attribution snippet that was generated on the dashboard",
    image: img5,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          6. Click on the HTML sheet and paste the attribution snippet that was
          generated on the dashboard
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: (
      <>
        Set the <Typography.Text strong>Trigger to All Pages.</Typography.Text>
      </>
    ),
    image: img6,
    pdfText: "Set the Trigger to All Pages.",
  },
  {
    instruction: (
      <>
        Click <Typography.Text strong>Save</Typography.Text>
      </>
    ),
    image: img7,
    pdfText: "Click Save",
  },
  {
    instruction: "Click Publish to make the tag live.",
    image: img8,
  },
];

export const GoogleTagManagerInstructions = ({ generatedTag }) => {
  return (
    <InstructionsBuilder
      title="Google Tag Manager"
      steps={googleTagManager(generatedTag)}
    />
  );
};

export const GoogleTagManagerInstructionsPDF = ({ generatedTag }) => {
  return (
    <GeneratedTagPDF
      title="Google Tag Manager"
      steps={googleTagManager(generatedTag)}
    />
  );
};
