export const isMobileScreen = breakPoint => {
  if (
    (breakPoint.xs || breakPoint.sm) &&
    !breakPoint.md &&
    !breakPoint.lg &&
    !breakPoint.xl &&
    !breakPoint.xxl
  ) {
    return true;
  }
};
