import React from "react";
import { withRouter } from "react-router-dom";

// Local Imports
import UsersTab from "../../../../platform/ux/Orgs/components/UsersTab";

// Utility imports
import { permissionsFromRoles } from "../../../utils/dataManipulating/organization";
import { permission } from "../../../utils/constants/constants";

// constants
const { READ, WRITE } = permission;
class UsersController extends React.Component {
  state = {
    selectedUser: null,
    isLoading: false,
  };

  componentDidMount = () => {
    const {
      match: {
        params: { user },
      },
      users,
    } = this.props;

    const userFromParam = users.find(u => u.id === user);
    const selectedUser = userFromParam
      ? {
          ...userFromParam,
          features: permissionsFromRoles(userFromParam.roles),
        }
      : null;

    this.setState({
      selectedUser,
    });

    if (userFromParam) {
      this.getUser(userFromParam);
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedOrg, users } = this.props;
    if (selectedOrg !== prevProps.selectedOrg) {
      this.setState({
        selectedUser: null,
      });
    }
    if (users !== prevProps.users) {
      if (this.state.selectedUser) {
        const currentUser =
          users && users.find(user => user.id === this.state.selectedUser.id);
        this.setState({
          selectedUser: currentUser
            ? {
                ...currentUser,
                features: permissionsFromRoles(currentUser.roles),
              }
            : null,
        });
      }
    }
  }

  updateUserEmbeddableConfig = async ({ id, data }) => {
    try {
      const { updateUserEmbeddableConfig } = this.props;
      const result = await updateUserEmbeddableConfig({
        variables: {
          id,
          data,
        },
      });
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          config: {
            ...this.state.selectedUser.config,
            id: result.data.updateUserConfig.id,
            embeddableConfig: result.data.updateUserConfig.embeddableConfig,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChange = (key, value, type) => {
    let updatedUser = { ...this.state.selectedUser };
    updatedUser[key] = value;
    this.setState({
      selectedUser: updatedUser,
    });
  };

  onSwitchChange = (checked, data) => {
    const [feature, permission] = data.split("-");

    const updatedUser = { ...this.state.selectedUser };

    if (permission === "CONFIG") {
      if (!updatedUser.config) {
        updatedUser.config = {};
        this.setState({
          selectedOrg: updatedUser,
        });
      }

      updatedUser.config[feature] = checked;

      if (feature === "isDemo" && checked) {
        updatedUser.config["providersVisible"] = false;
        updatedUser.config["isSelfService"] = false;
        updatedUser.config["isAdmin"] = false;
        updatedUser.config["isPartner"] = false;
        updatedUser.config["isTrafficker"] = false;
        updatedUser.config["exportData"] = false;
        updatedUser.config["isSelfService"] = false;
        updatedUser.config["hasInsights"] = false;
        updatedUser.config["priceVisible"] = false;
        updatedUser.config["isProcessor"] = false;
        updatedUser.config["canResetPasswords"] = false;
        updatedUser.config["campaignSummary"] = false;
        updatedUser.config["isPacing"] = false;
        updatedUser.config["pastData"] = false;
        updatedUser.config["segment"] = false;
        updatedUser.config["technicalSupport"] = false;
        updatedUser.config["articles"] = false;
        updatedUser.config["horizontalNavigation"] = false;
        updatedUser.config["amplify"] = false;
        updatedUser.config["configurator"] = false;
        updatedUser.config["billing"] = false;
        updatedUser.config["sitesAppsTransactions"] = false;

        delete updatedUser.features["ORGS"];
      } else {
        updatedUser.config["isDemo"] = false;
      }
    } else {
      if (checked) {
        updatedUser.features[feature] = [READ, WRITE];
        if (feature === "ORGS") {
          updatedUser.config["isDemo"] = false;
        }
      } else {
        delete updatedUser.features[feature];
      }
    }
    this.setState({
      selectedUser: updatedUser,
    });
  };

  createUser = async () => {
    const { createUser } = this.props;

    if (Object.keys(this.state.selectedUser.features).length === 0) {
      let error = "feature in empty";
      throw error;
    }
    // Add +1 when creating user
    const updatedUser = {
      ...this.state.selectedUser,
      phone: this.state.selectedUser.phone
        ? `+1${this.state.selectedUser.phone}`
        : "",
    };
    const response = await createUser(updatedUser);
    const { status, error } = response;

    if (status === "success") {
      this.setState({
        selectedUser: null,
      });
      return;
    } else if (status === "failure") {
      throw error;
    }
  };

  updateUser = async () => {
    const { updateUser } = this.props;

    if (Object.keys(this.state.selectedUser.features).length === 1) {
      let error = "feature in empty";
      throw error;
    }
    // Add +1 when updating user
    const updatedUser = {
      ...this.state.selectedUser,
      phone: this.state.selectedUser.phone
        ? `+1${this.state.selectedUser.phone}`
        : "",
    };
    const response = await updateUser(updatedUser);
    const { status } = response;

    if (status === "success") {
      return;
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the user has not been updated"
      );
    }
  };

  disableUser = async () => {
    const { disableUser } = this.props;
    const { selectedUser } = this.state;

    const response = await disableUser(selectedUser);
    const { status } = response;

    if (status === "success") {
      const { ENABLED, ...featuresNoEnabled } = selectedUser.features;
      this.setState({
        selectedUser: {
          ...selectedUser,
          features: featuresNoEnabled,
        },
      });
      return;
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the user has not been disabled"
      );
    }
  };

  deleteUser = async () => {
    const { deleteUser } = this.props;
    const { selectedUser } = this.state;

    const response = await deleteUser(selectedUser);
    const { status } = response;

    if (status === "success") {
      this.setState({
        selectedUser: null,
      });
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the user has not been removed"
      );
    }
  };

  enableUser = async () => {
    const { enableUser } = this.props;
    const { selectedUser } = this.state;

    const response = await enableUser(selectedUser);
    const { status } = response;

    if (status === "success") {
      this.setState({
        selectedUser: {
          ...selectedUser,
          features: {
            ...selectedUser.features,
            ENABLED: ["READ", "WRITE"],
          },
        },
      });
      return;
    } else if (status === "failure") {
      throw new Error(
        "An error has occurred while writing to the db, the user has not been enabled"
      );
    }
  };

  onRowClick = (record, rowIndex, event) => {
    const { match, history, selectedOrg } = this.props;

    // add config if null
    if (!record.config) {
      record.config = {
        priceVisible: false,
        isDemo: false,
        isAdmin: false,
        isPartner: false,
        isTrafficker: false,
        exportData: false,
        isSelfService: false,
        hasInsights: false,
        providersVisible: false,
        isProcessor: false,
        canResetPasswords: false,
        campaignSummary: false,
        isPacing: false,
        pastData: false,
        segment: false,
        technicalSupport: false,
        articles: false,
        horizontalNavigation: false,
        amplify: false,
        configurator: false,
        billing: false,
        sitesAppsTransactions: false,
      };
    }

    this.getUser(record, true);

    history.push(
      `${match.path.replace(
        /:org\?\/:user\?/gi,
        `${selectedOrg.id}/${record.id}`
      )}`
    );
  };

  onCreateNewUser = (record, rowIndex, event) => {
    this.setState({
      selectedUser: {
        avatar: {},
        email: "",
        name: "",
        phone: "",
        username: "",
        features: {},
        config: {
          priceVisible: false,
          isDemo: false,
          isAdmin: false,
          isPartner: false,
          isTrafficker: false,
          exportData: false,
          isSelfService: false,
          hasInsights: false,
          providersVisible: false,
          isProcessor: false,
          canResetPasswords: false,
          campaignSummary: false,
          isPacing: false,
          pastData: false,
          segment: false,
          technicalSupport: false,
          articles: false,
          horizontalNavigation: false,
          amplify: false,
          configurator: false,
          billing: false,
          sitesAppsTransactions: false,
        },
        isNewForm: false,
      },
    });
  };

  getUser = async (user, isTableSelection = false) => {
    this.setState({
      isLoading: true,
    });
    const { getUser } = this.props;

    const response = await getUser(user);

    const userData = isTableSelection
      ? {
          selectedUser: {
            ...user,
            phone: user.phone ? user.phone.replace("+1", "") : "",
            features: permissionsFromRoles(user.roles),
            status: "null",
            isExistingUser: true,
          },
        }
      : {
          selectedUser: {
            ...user,
            phone: user.phone ? user.phone.replace("+1", "") : "",
            features: permissionsFromRoles(user.roles),
            status: response,
          },
        };

    this.setState(userData);
    this.setState({
      isLoading: false,
    });
  };

  resendTempPassword = async username => {
    const { resentTemporaryPassword } = this.props;

    const response = await resentTemporaryPassword(username);

    return response;
  };

  resetPassword = async ({ username, permanent }) => {
    const { resetPassword } = this.props;

    const response = await resetPassword({ username, permanent });

    return response;
  };

  moveUser = async (orgId, roleId, username, userId) => {
    const { moveUser } = this.props;

    const response = await moveUser(orgId, roleId, username, userId);

    return response;
  };

  render() {
    return (
      <UsersTab
        createUser={this.createUser}
        updateUser={this.updateUser}
        enableUser={this.enableUser}
        disableUser={this.disableUser}
        deleteUser={this.deleteUser}
        isAdmin={this.props.currentuser.permission.canResetPasswords}
        canResetPasswords={this.props.currentuser.permission.canResetPasswords}
        isMediaJelAdmin={this.props.currentRole.org.name === "MediaJelAdmin"}
        onChange={this.onChange}
        onSwitchChange={this.onSwitchChange}
        currentRole={this.props.currentRole}
        currentuser={this.props.currentuser}
        selectedOrg={this.props.selectedOrg}
        users={this.props.users}
        onRowClick={this.onRowClick}
        selectedUser={this.state.selectedUser}
        onCreateNewUser={this.onCreateNewUser}
        resendTempPassword={this.resendTempPassword}
        resetPassword={this.resetPassword}
        moveUser={this.moveUser}
        orgs={this.props.orgs}
        isLoading={this.state.isLoading}
        updateUserEmbeddableConfig={this.updateUserEmbeddableConfig}
      />
    );
  }
}

export default withRouter(UsersController);
