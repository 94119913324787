import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../../shared/maps/googlemaps";

//TODO break out style
const styles = {
  map: {
    position: "relative",
    height: "100%",
    width: "100%",
  },

  outerContainer: {
    height: "100%",
    position: "absolute",
    top: 5,
    left: 5,
    right: 5,
    bottom: 5,
  },
  innerContainer: {
    padding: 4,
    backgroundColor: "#fff",
  },
};

class AudienceHeatMap extends React.Component {
  getLatLngBounds = latLngs => {
    // this function finds the farthest ne and sw coordinates of the return geocodes,
    // gets the bounds, and finds the center.
    const { google } = this.props;
    const lats = latLngs.map(pos => pos.location.lat());
    const lngs = latLngs.map(pos => pos.location.lng());

    const sw = new google.maps.LatLng(Math.min(...lats), Math.min(...lngs));
    const ne = new google.maps.LatLng(Math.max(...lats), Math.max(...lngs));

    return new google.maps.LatLngBounds(sw, ne);
  };

  getPoints = () => {
    const { google, zipData } = this.props;
    let bounds = new google.maps.LatLngBounds();
    const data = [];
    //Cuurntly uses zip data
    zipData.forEach(item => {
      const { LATITUDE, LONGITUDE, MATCHES } = item;
      for (var i = 0; i < MATCHES; i++) {
        const point = new google.maps.LatLng(LATITUDE, LONGITUDE);
        data.push(point);
        bounds.extend(point);
      }
    });
    let map = this.map;
    map.fitBounds(bounds);
    /*
   bounds.extend(myLatLng);
  }
  map.fitBounds(bounds);

*/

    return data;
  };

  drawMap = () => {
    const { latitude, longitude } = this.props.currentLocation;
    const { google, zoom } = this.props;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);

    this.currentLocation = new google.maps.LatLng({ lat, lng });
    this.markers = [];

    var USA_BOUNDS = {
      north: 48.940372,
      south: 25.824383,
      west: -123.983245,
      east: -58.944185,
    };

    this.map = new google.maps.Map(this.mapNode, {
      center: {
        lat,
        lng,
      },
      mapTypeId: "hybrid",
      zoom,
      mapTypeControl: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      streetViewControl: false,
      restriction: {
        latLngBounds: USA_BOUNDS,
        strictBounds: false,
      },
    });

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.getPoints(),
      map: this.map,
    });

    let heatmap = this.heatmap;

    heatmap.set("radius", heatmap.get("radius") ? null : 20);
  };

  mapMounted = node => {
    this.mapNode = node;
  };

  componentDidMount() {
    this.drawMap();
  }

  render() {
    return (
      <div style={styles.outerContainer}>
        <div ref={this.mapMounted} style={styles.map} />
      </div>
    );
  }
}

export default GoogleApiWrapper(googleMapsConfig)(AudienceHeatMap);
