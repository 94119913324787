import { useEffect } from "react";
import {
  enableActivityTracking,
  newTracker,
  trackPageView,
} from "@snowplow/browser-tracker";
import { SiteTrackingPlugin } from "@snowplow/browser-plugin-site-tracking";
import { PerformanceTimingPlugin } from "@snowplow/browser-plugin-performance-timing";
import { useLocation } from "react-router-dom";
import { ClientHintsPlugin } from "@snowplow/browser-plugin-client-hints";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  FormTrackingPlugin,
  enableFormTracking,
} from "@snowplow/browser-plugin-form-tracking";

const useRegisterSnowplow = ({ currentUser }) => {
  const location = useLocation();
  const appId =
    process.env.REACT_APP_ENV === "production" ? "cnna-app" : "cnna-app-dev";

  const userContext = {
    schema: "iglu:com.snowplowanalytics.iglu/anything-a/jsonschema/1-0-0",
    data: {
      id: currentUser.id,
      email: currentUser.email,
      username: currentUser.username,
      org: currentUser.defaultRole.org
        ? currentUser.defaultRole.org.name
        : currentUser.defaultRole.defaultRole.org.name,
    },
  };

  newTracker("sp", "https://collector.dmp.cnna.io", {
    appId,
    platform: "web",
    discoverRootDomain: true,
    cookieSameSite: "Lax",
    plugins: [
      PerformanceTimingPlugin(),
      SiteTrackingPlugin(),
      ClientHintsPlugin(),
      FormTrackingPlugin(),
      LinkClickTrackingPlugin(),
    ],
  });

  enableLinkClickTracking({ trackContent: true });
  enableFormTracking();
  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10,
  });

  useEffect(() => {
    trackPageView({ context: [userContext] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {};
};

export default useRegisterSnowplow;
