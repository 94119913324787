import React, { useEffect, useState } from "react";
import { Button, message, Modal, Space, Spin, Table, Tag } from "antd";

import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useTargetingContext } from "../../../core/components/targeting/useTargetingContext";

const CampaignRequestorRegionGroupsDialog = ({
  visible,
  setVisible,
  onAddSelections,
  selectedRegionGroups,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { selectedAdvertiser } = useCampaignRequestorContext();
  const {
    fetchRegionGroups,
    regionGroups,
    loadingRegionGroups,
  } = useTargetingContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      message.destroy();
      message.warning("Please select at least one tag");
      return;
    }
    const selectedRegions = regionGroups.filter(region =>
      selectedRowKeys.includes(region.id)
    );
    onAddSelections(selectedRegions);
    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFetchRegionGroups = () => {
    fetchRegionGroups({
      variables: {
        orgs: { id: selectedAdvertiser.id },
      },
    });
  };

  useEffect(() => {
    handleFetchRegionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRegionGroups && selectedRegionGroups.length > 0) {
      const keys = [];
      selectedRegionGroups.forEach(group => {
        const targeting = regionGroups.find(t => t.name === group.name);
        if (targeting) {
          keys.push(targeting.id);
        }
      });
      setSelectedRowKeys(keys);
    }
  }, [regionGroups, selectedRegionGroups]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>REGION GROUPS</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1200}
      >
        {loadingRegionGroups ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={[
              {
                title: "Region Group Name",
                dataIndex: "name",
                key: "name",
                ellipsis: true, // Enables ellipsis for text overflow
                width: 200, // Set a fixed width for the column
              },
              // {
              //   title: "Type",
              //   dataIndex: "formattedAddress",
              //   key: "formattedAddress",
              // },
              {
                title: "Regions",
                dataIndex: "regions",
                key: "regions",
                width: "80%",
                render: regions => (
                  <div>
                    {regions.map(region => (
                      <Tag color="blue" key={region}>
                        {region}
                      </Tag>
                    ))}
                  </div>
                ),
              },
            ]}
            dataSource={regionGroups}
            scroll={{ x: 1200 }}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignRequestorRegionGroupsDialog;
