import React from "react";
import { Dropdown, Menu } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const ExportExcelButton = ({
  campaignName,
  generateReport,
  loadingCampaigns,
}) => {
  const reports = [
    {
      name: `By Date`,
      reportName: `${campaignName.replace(/\//g, " ")}-By Date`,
      key: `byDate`,
    },
  ];

  const menu = (
    <Menu>
      {reports.map(report => {
        return (
          <Menu.Item
            key={report.key}
            onClick={() => {
              try {
                const name = report.reportName;
                const zip = new JSZip();
                const folder = zip.folder(name);
                const fileData = generateReport(report.key);

                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const ws = XLSX.utils.json_to_sheet(fileData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const excelData = new Blob([excelBuffer], { type: fileType });

                folder.file(`${name}.xlsx`, excelData);
                zip.generateAsync({ type: "blob" }).then(
                  blob => {
                    saveAs(blob, name);
                    return true;
                  },
                  function(e) {
                    console.log("error", e);
                    return false;
                  }
                );
              } catch (err) {
                console.log(`Excel error: ${err}`);
              }
            }}
          >
            {report.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomLeft"
      disabled={loadingCampaigns}
    >
      <Button
        style={{ width: "100%" }}
        icon={loadingCampaigns ? <LoadingOutlined /> : <DownloadOutlined />}
        type="primary"
      >
        <ButtonText text={"Export Excel"} />
      </Button>
    </Dropdown>
  );
};

export default ExportExcelButton;
