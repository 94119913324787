import gql from "graphql-tag";

const DELETE_MANY_CREATIVES = gql`
  mutation deleteManyCreatives($ids: [ID!]) {
    deleteManyCreatives(where: { id_in: $ids }) {
      count
    }
  }
`;

export default DELETE_MANY_CREATIVES;
