export const featureOptions = [
  { value: "ORGS", label: "ORGS" },
  { value: "LOCATIONS", label: "LOCATIONS" },
  { value: "DISPLAY", label: "DISPLAY" },
  { value: "AUDIENCES", label: "AUDIENCES" },
  { value: "SEGMENTS", label: "SEGMENTS" },
  { value: "PAID SEARCH", label: "PAID SEARCH" },
  { value: "SEO", label: "SEO" },
  { value: "EDITORIAL", label: "EDITORIAL" },
  { value: "AI", label: "AI" },
];

export const permissionsOptions = [
  { value: "providersVisible", label: "Providers Visible" },
  { value: "isDemo", label: "Demo" },
  { value: "isAdmin", label: "Advertiser Management" },
  { value: "isAdmin", label: "Partner" },
  { value: "isTrafficker", label: "Trafficker" },
  { value: "exportData", label: "Export Data" },
  { value: "isSelfService", label: "Self Service" },
  { value: "hasInsights", label: "Domains and App" },
  { value: "priceVisible", label: "Price Visible" },
  { value: "isProcessor", label: "Processor" },
  { value: "canResetPasswords", label: "Password Reset" },
  { value: "campaignSummary", label: "Campaign Summary" },
  { value: "isPacing", label: "Pacing" },
  { value: "pastData", label: "Past Data" },
  { value: "segment", label: "Segment" },
  { value: "technicalSupport", label: "Technical Support" },
  { value: "articles", label: "Articles" },
  { value: "amplify", label: "Amplify" },
  { value: "configurator", label: "Configurator" },
  { value: "billing", label: "Billing" },
  { value: "sitesAppsTransactions", label: "Sites and Apps Transactions" },
];
