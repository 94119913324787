import React from "react";
import { useQuery } from "react-apollo";
import AudienceCommerceController from "./AudienceCommerceController";
import AUDIENCE_COMMERCE from "../../../../GraphQl/Queries/AUDIENCE_COMMERCE";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import AUDIENCE_COMMERCE_TRANSACTIONS from "../../../../GraphQl/Queries/AUDIENCE_COMMERCE_TRANSACTIONS";
import moment from "moment";

const connectionParser = data => {
  const { transactions, full } = data;
  return {
    pageInfo: transactions.pageInfo,
    edges: transactions.edges.map(edge => {
      return {
        ...edge.node,
      };
    }),
    aggregate: full.aggregate,
  };
};

const AudienceCommerceLoader = ({ children, ...props }) => {
  const { id, location, match } = props;
  const params = new URLSearchParams(location.search);
  const pageNumber = match.params.pageNumber || 1;

  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;
  const startDate = params.get("startDate")
    ? params.get("startDate")
    : moment()
        .utc()
        .subtract(30, "days");
  const endDate = params.get("endDate")
    ? params.get("endDate")
    : moment().utc();

  const { loading, data } = useQuery(AUDIENCE_COMMERCE, {
    variables: {
      id,
    },
  });
  let whereTransactions = {
    AND: [
      {
        commerce: {
          id,
        },
      },
    ],
  };
  if (startDate) {
    whereTransactions["AND"].push({
      orderedOn_gte: moment(startDate)
        .utc()
        .startOf("day")
        .format("YYYY-MM-DD"),
    });
  }
  if (endDate) {
    whereTransactions["AND"].push({
      orderedOn_lte: moment(endDate)
        .utc()
        .endOf("day")
        .format("YYYY-MM-DD"),
    });
  }

  const { loading: transactionsLoading, data: transactionsData } = useQuery(
    AUDIENCE_COMMERCE_TRANSACTIONS,
    {
      variables: {
        first: pageSize,
        skip: (Number(pageNumber) - 1) * pageSize,
        where: whereTransactions,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (loading || transactionsLoading) {
    return <OverlaySpinner />;
  }
  return (
    <AudienceCommerceController
      {...props}
      rawCommerce={data.audienceCommerce}
      transactions={connectionParser(transactionsData)}
      pageNumber={pageNumber}
      startDate={moment(startDate).utc()}
      endDate={moment(endDate).utc()}
    >
      {children}
    </AudienceCommerceController>
  );
};

export default AudienceCommerceLoader;
