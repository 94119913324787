import React from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "./googlemaps";
import currentLocationIcon from "./assets/currentLocation.png";
import { isEqual, isEmpty } from "lodash";

// This google maps component is self contained.
// This means that any references to Google specific Components
// such as markers, overlays (polygons) will remian within the local state

// Currently this allows for 2 different sources of truth for the overlay:
// 1) Local State which is used to hold an in progress geoTarget
// 2) Controller GeoTarget state which contains finished geoTargets

//Sets a static initial location to the SF Bay Area
const defaultLocation = {
  latitude: "39.8097343",
  longitude: "-98.5556199",
};

class LauncherMapSimple extends React.Component {
  state = {
    previousGeoTargets: [],
    defaultLocation: this.props.defaultLocation || defaultLocation,
  };

  componentDidMount() {
    this.getUserLocation();
    this.drawMap();
  }

  componentDidUpdate(prev) {
    // TODO: Not resizing properly on radius change
    if (!isEqual(prev.geoTargets, this.props.geoTargets)) {
      this.state.previousGeoTargets.forEach(shape => {
        shape.overlay.setMap(null);
      });
      this.drawOverlay();
    }
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          this.setState({
            defaultLocation: {
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            },
          });
        },
        err => console.log(err),
        { enableHighAccuracy: true }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  drawMap = () => {
    const { latitude, longitude } = this.state.defaultLocation;
    const { google, zoom } = this.props;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    let currentLocation = new google.maps.LatLng({ lat, lng });

    //Set up map
    this.map = new google.maps.Map(this.mapNode, {
      center: {
        lat,
        lng,
      },
      mapTypeId: "roadmap",
      zoom,
      mapTypeControl: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      fullscreenControl: false,
      minZoom: 2,
      maxZoom: 19,
    });
    let map = this.map;

    // Display Controller GeoTargets
    this.drawOverlay();

    //Custom current location icon
    // TODO: Refactor into react component state
    this.markers = [];

    const icon = {
      url: currentLocationIcon,
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };

    this.markers.push(
      new google.maps.Marker({
        map: map,
        icon: icon,
        title: "current location",
        position: currentLocation,
      })
    );
  };

  drawOverlay = () => {
    const { google } = this.props;
    const newShapes = [];
    const bounds = new google.maps.LatLngBounds();
    this.props.geoTargets.forEach(geoTarget => {
      geoTarget &&
        geoTarget.circles &&
        geoTarget.circles.forEach((circle, i) => {
          const latlng = new google.maps.LatLng(
            circle.coordinate.lat,
            circle.coordinate.lng
          );
          bounds.extend(latlng);
          newShapes.push({
            type: "circle",
            overlay: new google.maps.Circle({
              strokeColor: "#008000",
              strokeOpacity: 0.8,
              strokeWeight: 8,
              fillColor: "#90EE90",
              fillOpacity: 0.5,
              map: this.map,
              center: {
                lat: circle.coordinate.lat,
                lng: circle.coordinate.lng,
              },
              radius: circle.radius * 1000, // KM -> M
            }),
          });
        });
      geoTarget &&
        geoTarget.polygons &&
        geoTarget.polygons.forEach(polygon => {
          let coordinates;
          if (!isEmpty(polygon.geometry)) {
            coordinates = polygon.geometry.coordinates[0].map(coord => {
              const latlng = new google.maps.LatLng(coord[1], coord[0]);
              bounds.extend(latlng);
              return { lat: coord[1], lng: coord[0] };
            });
          } else {
            polygon.coordinates.forEach(coord => {
              const latlng = new google.maps.LatLng(coord.lat, coord.lng);
              bounds.extend(latlng);
            });
          }
          newShapes.push({
            type: "polygon",
            overlay: new google.maps.Polygon({
              paths: coordinates || polygon.coordinates,
              fillColor: "#90EE90",
              fillOpacity: 0.5,
              strokeWeight: 3,
              strokeColor: "#008000",
              clickable: true,
              // editable: true,
              zIndex: 1,
              map: this.map,
            }),
          });
        });
    });
    if (this.props.geoTargets.length) this.map.fitBounds(bounds);
    this.setState({ previousGeoTargets: newShapes });
  };

  mapMounted = node => {
    this.mapNode = node;
  };

  render() {
    const { styles } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          position: "relative",
          ...styles.outerContainer,
        }}
      >
        <div ref={this.mapMounted} style={styles.map} />
      </div>
    );
  }
}

LauncherMapSimple.propTypes = {
  zoom: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
};

export default GoogleApiWrapper(googleMapsConfig)(LauncherMapSimple);
