import React from "react";
import Loader from "../../../../../../../../core/components/campaigns/campaigndetails/transactionImpressions/TransactionImpressionsLoader";
import View from "./TransactionImpressionsView";

const TransactionImpressions = props => {
  return (
    <Loader {...props}>
      <View />
    </Loader>
  );
};

export default TransactionImpressions;
