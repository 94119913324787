/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Input,
  message,
  Table,
} from "antd";
import { isEmpty } from "lodash";
import { Storage } from "aws-amplify";
import PaginationTable from "../../../shared/PaginationTable";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
  TooltipText,
} from "../../../shared/globalStyling/styledText";
import { EditOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";

const VideoTable = ({
  orgMedias,
  setVideoCreativeKeys,
  setVideoCreativeIds,
  updateOrgMedia,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [medias, setMedias] = useState([]);
  const [editMedia, setEditMedia] = useState(false);
  const [editMediaSubmit, setEditMediaSubmit] = useState(false);
  const [creativeID, setCreativeID] = useState("");
  const [name, setName] = useState("");
  const [cloneName, setCloneName] = useState("");
  const [url, setUrl] = useState("");
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);

  // The purpose of this is it renews the token of the video urls
  const processImages = async orgMedias => {
    const medias = await Promise.all(
      orgMedias.map(async media => {
        const response = await Storage.get(media.key, { expires: 600000 });
        media.image = (
          <VideoPreviewCell
            videoUrl={response}
            onClick={() => handleRowClick(response)}
          />
        );
        return media;
      })
    );
    setMedias(medias);
  };

  useEffect(() => {
    const mediaData = orgMedias ? orgMedias.medias : [];

    if (!isEmpty(mediaData)) {
      processImages(mediaData);
    } else {
      setMedias(mediaData);
    }
  }, [orgMedias]);

  const extraColumn = {
    title: <TableColumnTitle text={"Video Preview"} />,
    dataIndex: "image",
    key: "image",
    width: "15%",
    align: "left",
  };

  let columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "35%",
      align: "left",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        if (text) {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col span={24}>
                {text.length > 45 ? (
                  <Tooltip title={<TooltipText text={text} />}>
                    <span>
                      <PrimaryTableRowText
                        text={text.slice(0, 46).concat("...")}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <PrimaryTableRowText text={text} />
                )}
              </Col>
            </Row>
          );
        } else {
          return <div />;
        }
      },
    },
    {
      title: <TableColumnTitle text={"Width"} />,
      dataIndex: "width",
      key: "width",
      width: "10%",
      align: "left",
      sorter: (a, b) => a.width - b.width,
      render: width => <PrimaryTableRowText text={width + "px"} />,
    },
    {
      title: <TableColumnTitle text={"Height"} />,
      dataIndex: "height",
      key: "height",
      width: "10%",
      align: "left",
      sorter: (a, b) => a.height - b.height,
      render: height => <PrimaryTableRowText text={height + "px"} />,
    },
    {
      title: <TableColumnTitle text={"File Type"} />,
      dataIndex: "fileType",
      key: "fileType",
      width: "15%",
      align: "left",
      sorter: (a, b) => a.fileType.localeCompare(b.fileType),
      render: fileType => <PrimaryTableRowText text={fileType} />,
    },
    {
      title: <TableColumnTitle text={"Date Uploaded"} />,
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      align: "left",
      sorter: (a, b) => {
        const dateA = moment(a.createdAt);
        const dateB = moment(b.createdAt);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      render: createdAt => {
        return (
          <PrimaryTableRowText
            text={moment(createdAt).format("MM/DD/YYYY hh:mm A")}
          />
        );
      },
    },
    {
      title: "",
      key: "edit",
      width: "10%",
      render: creatives => (
        <EditOutlined
          style={{ fontSize: "1.25rem" }}
          onClick={() => {
            editOrgMedia(creatives);
          }}
        />
      ),
    },
  ];

  if (!updateOrgMedia) {
    // Filter edit column if updateOrgMedia is not passed
    columns = columns.filter(column => column.key !== "edit");
  }

  const editOrgMedia = async data => {
    form.resetFields();

    setEditMedia(true);
    setCreativeID(data.id);
    setName(data.name);
    setCloneName(data.name);
    setUrl(data.url);
    form.setFieldsValue({
      name: data.name,
    });
  };

  if (orgMedias && !isEmpty(orgMedias)) {
    columns.unshift(extraColumn);
  }

  const handleSubmit = async () => {
    if (name === cloneName) return;

    try {
      setEditMediaSubmit(true);

      message.loading("Action in progress...", 0);

      const CapitalizeCreativeName =
        name.charAt(0).toUpperCase() + name.slice(1);

      await updateOrgMedia({
        variables: {
          creativeID,
          newUrl: url,
          newName: CapitalizeCreativeName,
        },
      });

      form.resetFields();
      setEditMediaSubmit(false);
      setCreativeID("");
      setUrl("");
      setName("");
      setEditMedia(false);
      message.destroy();
      message.success(`Successfully updated the creative name`);
    } catch (err) {
      message.destroy();
      message.error(`Error updating creative name`);
    }
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      const ids = selectedRows.map(row => {
        return row.id;
      });

      const creativeKeys = selectedRows.map(row => {
        return row.key;
      });

      setVideoCreativeIds(ids);
      setVideoCreativeKeys(creativeKeys);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const VideoModal = ({ videoUrl, visible, onClose }) => {
    return (
      <Modal
        visible={visible}
        title="Video Player"
        onCancel={onClose}
        footer={null}
      >
        <video controls width="100%">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
    );
  };

  const VideoPreviewCell = ({ videoUrl, onClick }) => {
    return (
      <div
        onClick={onClick}
        style={{
          width: "100px", // Adjust the width as needed
          height: "75px", // Adjust the height as needed
        }}
      >
        <video
          src={videoUrl}
          type="video/mp4"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          muted
          autoPlay
          loop
        />
      </div>
    );
  };

  const handleRowClick = videoUrl => {
    setSelectedVideo(videoUrl);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <VideoModal
        videoUrl={selectedVideo}
        visible={modalVisible}
        onClose={handleModalClose}
      />
      <Modal
        title="Edit Media"
        visible={editMedia}
        centered
        footer={null}
        onCancel={() => {
          setEditMedia(false);
          form.resetFields();
        }}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            name,
          }}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <video controls width="100%" height="auto">
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input
                  placeholder="Name of Creative"
                  onChange={e => {
                    const CapitalizeCreativeName =
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1);
                    setName(CapitalizeCreativeName);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={name === cloneName}
                  icon={
                    editMediaSubmit ? <LoadingOutlined /> : <SaveOutlined />
                  }
                  htmlType="submit"
                  type="primary"
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PaginationTable
        // footer={medias && medias.slice(currentPage * 10 - 10, currentPage * 10)}
        bordered={false}
        columns={columns}
        data={medias}
        scroll={{ x: 800, y: 600 }}
        paginationSize={"default"}
        size={"default"}
        total={medias && medias.length}
        current={currentPage}
        pageSize={pageSize}
        rowSelection={rowSelection}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          setPageSize(paginationObject.pageSize);
          setCurrentPage(paginationObject.current);
        }}
      />
    </>
  );
};

export default VideoTable;
