import gql from "graphql-tag";
const UPDATE_MANY_AUDIENCES = gql`
  mutation updateManyAudiences($orgId: [ID!], $isAmplifyDefault: Boolean) {
    updateManyAudiences(
      where: { orgs_every: { id_in: $orgId }, isAmplifyDefault: true }
      data: { isAmplifyDefault: $isAmplifyDefault }
    ) {
      count
    }
  }
`;

export default UPDATE_MANY_AUDIENCES;
