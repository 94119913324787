import gql from "graphql-tag";
const UPDATE_AUDIENCE_SHARING = gql`
  mutation updateAudienceSharing(
    $id: ID!
    $orgs: OrgUpdateManyWithoutAudiencesInput
  ) {
    updateAudience(where: { id: $id }, data: { orgs: $orgs }) {
      id
      orgs {
        id
      }
    }
  }
`;

export default UPDATE_AUDIENCE_SHARING;
