const csvTemplate = {
  rows: [
    ["Brandyglamorous@uol.com.br"],
    ["busyAllen@club-internet.fr"],
    ["wearyCarl@ymail.com"],
    ["Prestonpanicky@yahoo.es"],
    ["adorableMarvin78@club-internet.fr"],
    ["Mayradistinct@yahoo.es"],
  ],

  templateName: "Email list Template",
};

export const HashedEmailConfig = {
  csvTemplate,
};
