import gql from "graphql-tag";

const DISABLE_ENDABLE_TAG = gql`
  mutation disableTag($id: ID!, $isDisabled: Boolean!) {
    updateEventsTarget(where: { id: $id }, data: { isDisabled: $isDisabled }) {
      id
    }
  }
`;

export default DISABLE_ENDABLE_TAG;
