import gql from "graphql-tag";

const GET_ALL_CAMPAIGNORDERS_CONNECTION = gql`
  query campaignOrdersConnection($orgId: ID!) {
    campaignOrdersConnection(
      where: {
        status_not_in: [DRAFT, PENDING]
        orgs_some: { OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }] }
      }
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default GET_ALL_CAMPAIGNORDERS_CONNECTION;
