import { useState } from "react";
import { message } from "antd";
import { VASTParser } from "@dailymotion/vast-client";

const useVASTTag = () => {
  const [adId, setAdId] = useState(null);
  const [adSystem, setAdSystem] = useState("LiquidM");
  const [adTitle, setAdTitle] = useState(null);
  const [duration, setDuration] = useState("00:00:30");
  const [trackingEvent, setTrackingEvent] = useState(null);
  const [clickThrough, setClickThrough] = useState({ id: null, data: null });
  const [mediaFile, setMediaFile] = useState({
    height: "396",
    width: "600",
    bitrate: "496",
    type: "video/mp4",
    delivery: "progressive",
    url: "",
  });
  const [generatedTag, setGeneratedTag] = useState(null);
  const [vast, setVast] = useState(null);

  const generateSnippet = () => {
    if (!adId || !trackingEvent || !clickThrough.data || !mediaFile.url) {
      message.error("All fields must be filled out to generate this snippet");
      return;
    }
    const { height, width, bitrate, type, delivery, url } = mediaFile;
    const clickThroughId = !!clickThrough.id
      ? `<ClickThrough id="${clickThrough.id}">`
      : "<ClickThrough>";
    const snippet = `<VAST version="2.0">
    <Ad id="${adId}">
        <InLine>
        <AdSystem>${adSystem}</AdSystem>
        <AdTitle>${adTitle}</AdTitle>
        <Creatives>
            <Creative>
                <Linear>
                    <Duration>${duration}</Duration>
                    <TrackingEvents>
                    <Tracking event="start"><![CDATA[${trackingEvent}]]></Tracking>
                    </TrackingEvents>
                    <VideoClicks>
                    ${clickThroughId}
                    <![CDATA[${clickThrough.data}]]>
                    </ClickThrough>
                    </VideoClicks>
                    <MediaFiles>
                        <MediaFile height="${height}" width="${width}" bitrate="${bitrate}" type="${type}" delivery="${delivery}">
                            <![CDATA[${url}]]>
                        </MediaFile>
                    </MediaFiles>
                </Linear>
            </Creative>
            <Creative>
            </Creative>
        </Creatives>
        </InLine>
    </Ad>
</VAST>`;

    const parser = new DOMParser();
    const vastXml = parser.parseFromString(snippet, "text/xml");
    const vastParser = new VASTParser();
    vastParser
      .parseVAST(vastXml)
      .then(res => {
        setVast(res);
      })
      .catch(err => {
        message.error(err);
      });
    setGeneratedTag(snippet);
  };

  return {
    adId,
    setAdId,
    adSystem,
    setAdSystem,
    adTitle,
    setAdTitle,
    duration,
    setDuration,
    trackingEvent,
    setTrackingEvent,
    clickThrough,
    setClickThrough,
    mediaFile,
    setMediaFile,
    generatedTag,
    setGeneratedTag,
    generateSnippet,
    vast,
  };
};

export default useVASTTag;
