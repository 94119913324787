import React from "react";
import { Upload, message } from "antd";
import { Storage } from "aws-amplify";
import compose from "lodash/flowRight";
import { graphql } from "react-apollo";
import { getImageType } from "../../../../core/utils/general/strings";

import CREATE_MEDIA_ADMIN from "../../../../core/GraphQl/Mutations/CREATE_MEDIA_ADMIN";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import dimensionValidation from "../../../shared/upload/dimensionValidation";
import { campaignTypeMap } from "../../../../core/utils/constants/constants";

const { Dragger } = Upload;

const getUploadedFileDimensions = file =>
  new Promise((resolve, reject) => {
    try {
      let img = new Image();

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        return resolve({ width, height });
      };

      img.src = window.URL.createObjectURL(file);
    } catch (exception) {
      return reject(exception);
    }
  });

const DragUploader = ({
  adUnit,
  createMediaMutation,
  index,
  loading,
  disabled,
  onChange,
  bulkCreateCreatives,
  type: campaignType,
}) => {
  const results = [];
  const uploaderProps = {
    name: "file",
    action: null,
    multiple: true,
    beforeUpload: async (file, fileList) => {
      // TODO: Refactor code to a single function that will be called in DragUploader and ButtonUploader
      try {
        onChange("loading", true);

        // Put uploaded image into assets S3 bucket
        const { name, type, size } = file;

        if (campaignType !== campaignTypeMap.VIDEO && type === "video/mp4") {
          message.destroy();
          message.error("File must be an image");
          return;
        }

        // If the campaign type is VIDEO, must check if the file is mp4 else don't proceed
        if (campaignType === campaignTypeMap.VIDEO && type !== "video/mp4") {
          message.destroy();
          message.error("File must be mp4");
          return;
        }

        if (size > 2000000) {
          message.destroy();
          message.error("File can't exceed 2MB");
          return;
        }

        const fname = file.name + new Date();
        const response = await Storage.put(fname, file, {
          contentType: "image/*",
        });
        const { key } = response;
        const url = await Storage.get(response.key, { expires: 600000 });

        // Get image width and height
        const {
          width: imageWidth,
          height: imageHeight,
        } = await getUploadedFileDimensions(file);

        // Put S3 image into MySQL DB
        results.push(
          createMediaMutation({
            variables: {
              mediatype: "STILL",
              key,
              url,
              width: imageWidth,
              height: imageHeight,
              name,
              size,
              fileType: getImageType(type),
            },
          })
        );

        if (results.length === fileList.length) {
          let invalidMedias = [];

          // TODO make a function that handles the Drag Uploader and Button Uploader
          // so that this won't be hard to maintain - AVSM
          Promise.all(results).then(async values => {
            const medias = values.map(value => {
              if (dimensionValidation(imageWidth, imageHeight) === false) {
                invalidMedias.push(file.uid);
              }

              if (value && value.data && value.data.createMedia) {
                const media = value.data.createMedia;
                delete media.id;
                delete media.__typename;
                return {
                  ...media,
                  type: "STILL",
                };
              } else {
                return null;
              }
            });

            const validFiles = fileList.filter(
              fList => !invalidMedias.includes(fList.uid)
            );

            if (medias.length === validFiles.length) {
              message.destroy();
              bulkCreateCreatives({
                medias,
                invalidMedias: invalidMedias,
                adUnitIndex: index,
              });
            }
          });
        }
      } catch (error) {
        message.error("Error uploading file");
      } finally {
        onChange("loading", false);
      }
    },
    showUploadList: false,
    accept: ".png, .jpeg, .jpg, .gif, .avif",
    customRequest: async () => {
      setTimeout(() => {
        return true;
      }, 0);
    },
  };
  return (
    <Dragger disabled={disabled || false} {...uploaderProps}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <p>Drag a file to this area to upload a banner.</p>
    </Dragger>
  );
};

export default compose(
  graphql(CREATE_MEDIA_ADMIN, { name: "createMediaMutation" })
)(DragUploader);
