import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class Controller extends Component {
  state = {
    campaignOrders: [],
    advertisers: [],
    publishers: [],
    loadingCampaignOrders: false,
  };

  componentDidUpdate(prevProps) {
    const {
      campaignOrderListData,
      campaignOrderListLoading,
      advertiserListData,
      editorialListData,
    } = this.props;
    if (
      campaignOrderListData !== prevProps.campaignOrderListData &&
      campaignOrderListData &&
      campaignOrderListData.campaignOrders
    ) {
      this.setState({
        campaignOrders: campaignOrderListData.campaignOrders,
      });
    }

    if (
      advertiserListData !== prevProps.advertiserListData &&
      advertiserListData &&
      advertiserListData.advertisers
    ) {
      this.setState({
        advertisers: advertiserListData.advertisers,
      });
    }

    if (
      editorialListData !== prevProps.editorialListData &&
      editorialListData &&
      editorialListData.editorials
    ) {
      this.setState({
        publishers: editorialListData.editorials,
      });
    }

    if (campaignOrderListLoading !== prevProps.campaignOrderListLoading) {
      this.setState({
        loadingCampaignOrders: campaignOrderListLoading,
      });
    }
  }

  searchCampaignOrder = async ({ searchName, orgId }) => {
    const { campaignOrderList } = this.props;
    const result = await campaignOrderList({
      variables: {
        orgId,
        searchName,
      },
    });

    return result;
  };

  searchAdvertiser = ({ searchName, orgId }) => {
    const { getAdvertisers } = this.props;
    getAdvertisers({
      variables: {
        where: {
          AND: [
            {
              OR: [
                {
                  sharedOrgs_some: {
                    org: {
                      id: orgId,
                    },
                  },
                },
              ],
            },
            {
              name_contains: searchName,
            },
          ],
        },
      },
    });
  };

  searchPublisher = ({ searchName, orgId }) => {
    const { getEditorials } = this.props;
    getEditorials({
      variables: {
        where: {
          AND: [
            {
              OR: [
                {
                  sharedOrgs_some: {
                    org: {
                      id: orgId,
                    },
                  },
                },
              ],
            },
            {
              name_contains: searchName,
            },
          ],
        },
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          ...this.props,
          ...this.state,
          searchCampaignOrder: this.searchCampaignOrder,
          searchAdvertiser: this.searchAdvertiser,
          searchPublisher: this.searchPublisher,
        })}
      </React.Fragment>
    );
  }
}

export default withRouter(Controller);
