import React, { useState } from "react";
import { Card, Row, Select, Empty, Tabs, Skeleton } from "antd";
import { isEmpty, startCase } from "lodash";

// Global Styles
import {
  MenuItemTextColor,
  TabText,
} from "../../../../shared/globalStyling/styledText";
import DualAxisAreaChart from "../../../Campaigns/campaigndetails/tabs/summary/components/charts/components/DualAxisAreaChart";

const Frag = React.Fragment;
const { Option } = Select;

const TabPane = Tabs.TabPane;

// Styles
const colStyle = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};
const Org30DayChart = ({ byDates, loading }) => {
  // map the chart and return the dates with total summary
  const [leftY, setLeftY] = useState("Impressions");
  const [rightY, setRightY] = useState("Clicks");
  // if chartdata is equal to empty
  if (loading) {
    return (
      <Card>
        <>
          <Row type="flex" justify="center" align="middle">
            <Skeleton />
          </Row>
        </>
      </Card>
    );
  }

  const emptyCharts = isEmpty(byDates);
  if (emptyCharts)
    return (
      <Card>
        <>
          <Row type="flex" justify="center" align="middle">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Row>
        </>
      </Card>
    );
  // Sort Dates
  byDates = byDates.byDateOrg.sort((a, b) => a.date - b.date);

  const YAxisSelector = ({
    displayDateSelector,
    displayRightSelector,
    data,
  }) => {
    const validOptions = [
      "clicks",
      "impressions",
      "walkIns",
      "transactionTotal",
      "ctr",
      "observations",
      "transactionCount",
    ];

    const keys = Object.keys(data[0])
      .filter(el => validOptions.includes(el))
      .map(option => {
        return option === "cpc" ||
          option === "cpm" ||
          option === "ctr" ||
          option === "ecpm"
          ? option.toUpperCase()
          : startCase(option);
      })
      .filter(el => el !== "Creativeurl");

    return (
      <Row style={{ marginBottom: "2%" }} type="flex" justify="space-between">
        <>
          <Select
            onSelect={value => setLeftY(value)}
            style={{
              width: "10em",
            }}
            defaultValue={leftY}
          >
            {keys
              .filter(op => op !== rightY)
              .map(option => {
                return (
                  <Option key={option} value={option}>
                    <MenuItemTextColor text={option} color={"#7dafff"} />
                  </Option>
                );
              })}
          </Select>
          {displayRightSelector ? (
            <Select
              onSelect={value => {
                setRightY(value);
              }}
              style={{ width: "10em" }}
              defaultValue={rightY}
            >
              {keys
                .filter(op => op !== leftY)
                .map(option => {
                  return (
                    <Option key={option} value={option}>
                      <MenuItemTextColor text={option} color={"#92d5a7"} />
                    </Option>
                  );
                })}
            </Select>
          ) : null}
        </>
      </Row>
    );
  };

  return (
    <div>
      <Card style={colStyle.container} bodyStyle={{ padding: 18 }}>
        <Tabs
          // tabBarExtraContent={operations}
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
          onChange={activeKey => {
            if (activeKey === "4") setLeftY("Clicks");
            if (activeKey !== "4") {
              setLeftY("Impressions");
              setRightY("Transaction Count");
            }
          }}
        >
          {!isEmpty(byDates) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDates.length !== 0 && (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={true}
                      data={byDates}
                    />
                    <Row
                      style={{ marginBottom: "2%" }}
                      type="flex"
                      justify="center"
                    ></Row>
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? "Impressions" : leftY}
                      rightY={isEmpty(rightY) ? "Transaction Count" : rightY}
                      dualChartData={byDates}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default Org30DayChart;
