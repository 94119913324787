import gql from "graphql-tag";

const GET_ARTICLE = gql`
  query articles($categoryId: ID!) {
    articles(where: { AND: { category: { id: $categoryId } } }) {
      id
      createdAt
      updatedAt
      title
      content
    }
  }
`;

export default GET_ARTICLE;
