import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { unset } from "lodash";
import PAID_SEARCH_GOOGLE from "../../../../GraphQl/Queries/PAID_SEARCH_GOOGLE";
import GET_TOTAL_PAID_SEARCH_CAMPAIGNS from "../../../../GraphQl/Queries/GET_TOTAL_PAID_SEARCH_CAMPAIGNS";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";
import PaidSearchGoogleController from "./PaidSearchGoogleController";
import { possibleFieldsPaidSearchCampaign } from "../../../../utils/constants/constants";

const PaidSearchGoogleLoader = ({ children, ...props }) => {
  const { match, id, orgConfig, orgId } = props;
  const { params } = match;
  const { pageNumber } = params;

  const allowedOrderBys = {
    name_ASC: true,
    name_DESC: true,
    startDate_ASC: true,
    startDate_DESC: true,
  };

  const searchParams = new URLSearchParams(props.location.search);
  let where = {
    AND: [],
  };

  const search = searchParams.get("search");
  if (search) where["AND"].push({ name_contains: search });

  const status = searchParams.get("status");
  if (status) where["AND"].push({ status: status.toUpperCase() });

  const excluded = searchParams.get("excluded");
  if (excluded) where["AND"].push({ name_not_contains: excluded });

  const pageSize = searchParams.get("pageSize")
    ? parseInt(searchParams.get("pageSize"))
    : 10;

  let orderBy = "endDate_DESC";
  const sort = searchParams.get("sort");
  if (sort && allowedOrderBys[sort]) orderBy = sort;

  let { data, loading, error } = useQuery(PAID_SEARCH_GOOGLE, {
    variables: {
      id,
      first: pageSize,
      skip: (Number(pageNumber) - 1) * pageSize,
      orderBy,
      where,
    },
    fetchPolicy: "no-cache",
  });

  const {
    data: totalPaidSearchCampaign,
    loading: loadingTotalPaidSearchCampaign,
    error: errorPaidSearchCampaign,
  } = useQuery(GET_TOTAL_PAID_SEARCH_CAMPAIGNS, {
    variables: {
      where: {
        ...where,
        paidSearchSummary: {
          id,
        },
      },
    },
  });

  if (error || errorPaidSearchCampaign)
    return console.log(error || errorPaidSearchCampaign);
  if (loading || loadingTotalPaidSearchCampaign) return <OverlaySpinner />;

  const selectedStartDate =
    searchParams.get("startDate") || data.paidSearchSummary.startDate;

  const selectedEndDate =
    searchParams.get("endDate") || data.paidSearchSummary.endDate;

  !orgConfig.priceVisible && unset(possibleFieldsPaidSearchCampaign, "Budget");

  const defaultColumns =
    JSON.parse(sessionStorage.getItem("defaultColumnsPaidSearch")) ||
    Object.keys(possibleFieldsPaidSearchCampaign);

  const total =
    totalPaidSearchCampaign.paidSearchCampaignsConnection.aggregate.count;

  const hasDateParams =
    searchParams.get("startDate") && searchParams.get("endDate");
  if (props.orgConfig.isDemo) {
    data.paidSearchSummary.paidSearchCampaigns = data.paidSearchSummary.paidSearchCampaigns.map(
      (c, i) => {
        c.name = `Google Campaign Example ${i}`;
        return c;
      }
    );
  }

  return (
    <PaidSearchGoogleController
      data={data.paidSearchSummary}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      possibleFields={possibleFieldsPaidSearchCampaign}
      defaultColumns={defaultColumns}
      total={total}
      orgId={orgId}
      pageSize={pageSize}
      current={Number(pageNumber)}
      hasDateParams={hasDateParams}
      id={id}
      {...props}
    >
      {children}
    </PaidSearchGoogleController>
  );
};

export default PaidSearchGoogleLoader;
