import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Card,
  Typography,
  Space,
  Switch,
  message,
  Button,
} from "antd";
import GlobalButton from "../../../shared/globalStyling/Button";
import {
  CheckOutlined,
  CloseOutlined,
  RiseOutlined,
  UndoOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

const LaunchPaidSearch = ({
  currentOrgId = "", //Current Org ID
  orgs = [], //Child Orgs to be displayed in the modal
  tags = [], //Tags Paid Search Commerce
  onSubmit = () => {}, //Function to be called when the modal is submitted
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false); //Modal Pop up
  const [selectedOrg, setSelectedOrg] = useState(null); //Currently Selected org to be launched by default use login org
  const [isRevenueByLocation, setIsRevenueByLocation] = useState(false); //Revenue by Location enabled
  const [isProcessWithGoogleAds, setIsProcessWithGoogleAds] = useState(false); //Process with Google Ads enabled. When enabled Google Analytics will be disabled
  const [isCommerceEnabled, setIsCommerceEnabled] = useState(false); //Commerce Enabled. When enabled Commerce Tag will be enabled

  //Set default values of the form
  const defaultSet = () => {
    const org = orgs.find(o => o.id === currentOrgId);
    if (org && org.dataConfig) {
      const config = org.dataConfig;
      setSelectedOrg(org);
      form.setFieldsValue({
        organization: currentOrgId,
        googleAdsID: config.googleCustomerId ? config.googleCustomerId : null,
        googleAnalyticsID:
          config.googleAnalytics && config.googleAnalytics.viewId
            ? config.googleAnalytics.viewId
            : null,
        googleAnalyticsViewIdIsGA4:
          config.googleAnalytics && config.googleAnalytics.isGA4
            ? config.googleAnalytics.isGA4
            : null,
        bingCustomerID:
          config.bing && config.bing.customerId ? config.bing.customerId : null,
        bingAccountID:
          config.bing && config.bing.accountId
            ? org.dataConfig.bing.accountId
            : null,
      });
      form.validateFields();
    }
  };

  //Set default values of the form when currentOrgId changes
  useEffect(() => {
    if (currentOrgId) {
      const org = orgs.find(o => o.id === currentOrgId);
      if (org && org.dataConfig) {
        const config = org.dataConfig;
        setSelectedOrg(org);
        form.setFieldsValue({
          organization: currentOrgId,
          googleAdsID: config.googleCustomerId ? config.googleCustomerId : null,
          googleAnalyticsID:
            config.googleAnalytics && config.googleAnalytics.viewId
              ? config.googleAnalytics.viewId
              : null,
          googleAnalyticsViewIdIsGA4:
            config.googleAnalytics && config.googleAnalytics.isGA4
              ? config.googleAnalytics.isGA4
              : null,
          bingCustomerID:
            config.bing && config.bing.customerId
              ? config.bing.customerId
              : null,
          bingAccountID:
            config.bing && config.bing.accountId
              ? org.dataConfig.bing.accountId
              : null,
        });
        form.validateFields();
      }
    }
  }, [currentOrgId, form, orgs]);

  //Event Handler for showing launch modal
  const showModal = () => {
    setVisible(true);
  };

  //Event Handler for Launching Paid Search
  const handleOk = async () => {
    // Logic for handling the launch action
    form.validateFields().then(async values => {
      message.loading("Launching Paid Search");
      await onSubmit(values);
      message.destroy();
      message.success("Paid Search Launched");
      form.resetFields();
      defaultSet();
      setVisible(false);
    });
  };

  //Event Handler for Canceling Paid Search
  const handleCancel = () => {
    form.resetFields();
    defaultSet();
    setVisible(false);
  };
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col className="ant-col-xs-100 ant-col-md-flex-auto">
          <GlobalButton type="primary" onClick={showModal}>
            Launch Paid Search
          </GlobalButton>
        </Col>
      </Row>

      <Modal
        title="Launch Paid Search"
        visible={visible}
        onOk={async () => {
          await handleOk();
        }}
        onCancel={handleCancel}
        okText="Launch"
        width={"80%"}
      >
        <Form form={form} layout="vertical" name={"launchPaidSearch"}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label="Organization"
                name={"organization"}
                rules={[
                  () => ({
                    validator(_, value) {
                      const org = orgs.find(o => o.id === value);
                      if (org) {
                        if (org.paidSearchSummary && org.paidSearchSummary.id) {
                          //If organization has already a paid search throw error
                          return Promise.reject(
                            `Organization already has a paid search`
                          );
                        }
                        return Promise.resolve();
                      }

                      return Promise.reject();
                    },
                  }),
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  disabled={orgs.length === 0}
                  options={orgs.map(org => {
                    return { label: org.name, value: org.id };
                  })}
                  onChange={value => {
                    //Set Values of the form when organization is changed
                    const org = orgs.find(o => o.id === value);
                    if (org && org.dataConfig) {
                      const config = org.dataConfig;
                      setSelectedOrg(org);
                      form.setFieldsValue({
                        googleAdsID: config.googleCustomerId
                          ? config.googleCustomerId
                          : null,
                        googleAnalyticsID:
                          config.googleAnalytics &&
                          config.googleAnalytics.viewId
                            ? config.googleAnalytics.viewId
                            : null,
                        googleAnalyticsViewIdIsGA4:
                          config.googleAnalytics && config.googleAnalytics.isGA4
                            ? config.googleAnalytics.isGA4
                            : null,
                        bingCustomerID:
                          config.bing && config.bing.customerId
                            ? config.bing.customerId
                            : null,
                        bingAccountID:
                          config.bing && config.bing.accountId
                            ? org.dataConfig.bing.accountId
                            : null,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Card title={"Channels"} bordered>
                <Row>
                  <Col span={24}>
                    <Text strong>Google</Text>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      label="Process only with Google Ads"
                      name={"processWithGoogleAds"}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={checked => {
                          if (checked) {
                            //If checked disable google analytics
                            form.setFieldsValue({
                              googleAnalyticsID: null,
                              googleAnalyticsViewIdIsGA4: null,
                            });
                          }
                          setIsProcessWithGoogleAds(checked);
                        }}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={22}>
                        <Form.Item
                          label="Google Ads Customer ID"
                          name={"googleAdsID"}
                          rules={[
                            () => ({
                              validator(_, value) {
                                //If process with google ads is enabled then google ads customer id is required
                                if (isProcessWithGoogleAds) {
                                  if (value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    `Please fill up Google Ads Customer ID`
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Customer ID" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          type="primary"
                          style={{ marginTop: "30px" }}
                          onClick={() => {
                            form.setFieldsValue({
                              googleAdsID:
                                selectedOrg.dataConfig.googleCustomerId,
                            });
                          }}
                        >
                          <UndoOutlined />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={22}>
                        <Form.Item
                          label="Google Analytics View ID"
                          name={"googleAnalyticsID"}
                        >
                          <Input
                            placeholder="Analytics ID"
                            disabled={isProcessWithGoogleAds}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          type="primary"
                          style={{ marginTop: "30px" }}
                          onClick={() => {
                            form.setFieldsValue({
                              googleAnalyticsID:
                                selectedOrg.dataConfig.googleAnalytics.viewId,
                              googleAnalyticsViewIdIsGA4:
                                selectedOrg.dataConfig.googleAnalytics.isGA4,
                            });
                          }}
                        >
                          <UndoOutlined />
                        </Button>
                      </Col>
                    </Row>

                    <Space size="small" direction="horizontal">
                      <Typography.Text>UA</Typography.Text>
                      <Form.Item
                        name={"googleAnalyticsViewIdIsGA4"}
                        valuePropName="checked"
                        noStyle
                      >
                        <Switch disabled={isProcessWithGoogleAds} />
                      </Form.Item>
                      <Typography.Text>GA4</Typography.Text>
                    </Space>
                  </Col>
                  <Col xs={6} md={{ offset: 6 }}>
                    <Form.Item
                      label="Enable Revenue by Location"
                      name={"hasLocationRevenue"}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={checked => {
                          setIsRevenueByLocation(checked);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={"Locations"} name={"locationsKey"}>
                      <Select
                        mode="tags"
                        style={{ width: `100%` }}
                        allowClear={true}
                        disabled={!isRevenueByLocation}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col xs={24}>
                        <Text strong>Bing</Text>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col span={22}>
                            <Form.Item
                              label="Customer ID"
                              name={"bingCustomerID"}
                            >
                              <Input placeholder="Customer ID" />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: "30px" }}
                              onClick={() => {
                                form.setFieldsValue({
                                  bingCustomerID:
                                    selectedOrg.dataConfig &&
                                    selectedOrg.dataConfig.bing &&
                                    selectedOrg.dataConfig.bing.customerId
                                      ? selectedOrg.dataConfig.bing.customerId
                                      : "",
                                });
                              }}
                            >
                              <UndoOutlined />
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={22}>
                            <Form.Item
                              label="Account ID"
                              name={"bingAccountID"}
                            >
                              <Input placeholder="Account ID" />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Button
                              type="primary"
                              style={{ marginTop: "30px" }}
                              onClick={() => {
                                form.setFieldsValue({
                                  bingAccountID:
                                    selectedOrg.dataConfig &&
                                    selectedOrg.dataConfig.bing &&
                                    selectedOrg.dataConfig.bing.accountId
                                      ? selectedOrg.dataConfig.bing.accountId
                                      : "",
                                });
                              }}
                            >
                              <UndoOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <Text strong>Commerce</Text>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name={"hasCommerce"}
                          label={"Enable Commerce Tracking"}
                          valuePropName="checked"
                        >
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={checked => {
                              if (!checked) {
                                form.setFieldsValue({
                                  commerceTags: null,
                                });
                              }
                              setIsCommerceEnabled(checked);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name={"commerceTags"}
                          label={"Commerce Tag"}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator() {
                                if (getFieldValue("hasCommerce")) {
                                  if (getFieldValue("commerceTags")) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    `Please Select a Commerce Tag`
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            allowClear={true}
                            placeholder="Select Commerce Tag"
                            showSearch={true}
                            disabled={!isCommerceEnabled}
                            filterOption={(input, option) => {
                              if (
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              )
                                return true;
                            }}
                            options={
                              tags &&
                              tags.map(tag => {
                                return { value: tag.id, label: tag.name };
                              })
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.label
                                .toLowerCase()
                                .localeCompare(optionB.label.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.Item
                  name="hasChannels"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        const hasGoogle =
                          getFieldValue("googleAdsID") ||
                          getFieldValue("googleAnalyticsID")
                            ? true
                            : false;
                        const hasBing =
                          getFieldValue("bingCustomerID") ||
                          getFieldValue("bingAccountID")
                            ? true
                            : false;
                        const hasCommerce =
                          getFieldValue("hasCommerce") &&
                          getFieldValue("commerceTags")
                            ? true
                            : false;

                        if (hasGoogle || hasBing || hasCommerce) {
                          console.log(`Resolved`);
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          `Please fill up at least one channel`
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={
                  <Row type="flex" gutter={10} align="middle">
                    <Col>
                      <RiseOutlined />
                      <span> Budget</span>
                    </Col>
                  </Row>
                }
              >
                <Row>
                  <Text type="secondary">
                    The amount you set as the overall budget will be the maximum
                    amount that the campaign will spend over the course of its
                    flight dates.
                  </Text>
                </Row>
                <Row>
                  <Form.Item
                    name={"monthlyBudget"}
                    rules={[{ required: true, message: "Budget Required" }]}
                  >
                    <Input addonBefore="$USD" value={0} allowClear />
                  </Form.Item>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default LaunchPaidSearch;
