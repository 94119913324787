import gql from "graphql-tag";

const EDITORIAL_TRANSACTIONS = gql`
  query EditorialTransactions(
    $where: EditorialTransactionsWhereInput
    $orderBy: EditorialTransactionsOrderByInput
  ) {
    editorialTransactionses(where: $where, orderBy: $orderBy) {
      orderId
      orderTime
      pageURL
      total
      clicks
      attributionMethod
      attributionValue
      clicksList(orderBy: viewTime_DESC) {
        viewTime
        pageURL
      }
    }
  }
`;

export default EDITORIAL_TRANSACTIONS;
