import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import JSZip from "jszip";
import moment from "moment";

const ExportCSVButton = ({ orgName, generateReport }) => {
  const handleExport = () => {
    try {
      const reportName = `${orgName.replace(
        /[\W_]/g,
        "_"
      )}-Pacing Report ${moment()
        .utc()
        .format("MMMM D")}`;

      const zip = new JSZip();
      const folder = zip.folder(reportName);
      const fileData = generateReport();
      folder.file(`${reportName}.csv`, Papa.unparse(fileData));

      zip.generateAsync({ type: "blob" }).then(
        blob => {
          saveAs(blob, reportName);
          return true;
        },
        function(e) {
          console.log("error", e);
          return false;
        }
      );
    } catch (err) {
      console.log(`CSV error: ${err}`);
    }
  };

  return (
    <Button
      style={{ width: "100%" }}
      icon={<DownloadOutlined />}
      type="primary"
      onClick={handleExport}
    >
      <ButtonText text={"Export CSV"} />
    </Button>
  );
};

export default ExportCSVButton;
