import gql from "graphql-tag";

const UPDATE_AUDIENCE_DEMOGRAPH = gql`
  mutation updateAudienceDemograph(
    $id: ID!
    $data: AudienceDemographUpdateInput!
  ) {
    updateAudienceDemograph(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export default UPDATE_AUDIENCE_DEMOGRAPH;
