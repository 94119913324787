import gql from "graphql-tag";
const UPDATE_REGION_GROUP = gql`
  mutation updateRegionGroup(
    $id: ID!
    $name: String!
    $regions: [String!]
    $isPresetList: Boolean
  ) {
    updateRegionGroup(
      where: { id: $id }
      data: {
        name: $name
        regions: { set: $regions }
        isPresetList: $isPresetList
      }
    ) {
      id
      name
      regions
      isPresetList
    }
  }
`;

export default UPDATE_REGION_GROUP;
