import gql from "graphql-tag";

const CHAT_SUBSCRIPTION = gql`
  subscription CHAT_SUBSCRIPTION($from: String!) {
    chat(from: $from) {
      message
    }
  }
`;

export default CHAT_SUBSCRIPTION;
