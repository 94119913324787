import React, { createContext, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";

// QUERIES
import LOCATION from "../../../../core/GraphQl/Queries/LOCATION";
import CURRENT_USER from "../../../../core/GraphQl/Queries/CURRENT_USER";
import CART_PROVIDER from "../../../../core/GraphQl/Introspection/CART_PROVIDER";

// MUTATIONS
import DELETE_LOCATION from "../../../../core/GraphQl/Mutations/DELETE_LOCATION_ADMIN";
import EDIT_LOCATION from "../../../../core/GraphQl/Mutations/EDIT_LOCATION_ADMIN";
import EDIT_LOCATION_ADMIN_WITH_GEOTARGETS from "../../../../core/GraphQl/Mutations/EDIT_LOCATION_ADMIN_WITH_GEOTARGETS";
import CREATE_GEOTARGET_LOCATION from "../../../../core/GraphQl/Mutations/CREATE_GEOTARGET_LOCATION";

const RetailStoresDetailsContext = createContext();

export const RetailStoresDetailsProvider = ({ children }) => {
  // QUERIES
  const { loading: userLoading, data: currentUser } = useQuery(CURRENT_USER);
  const { data: cartProvider } = useQuery(CART_PROVIDER);

  const history = useHistory();
  const { id } = useParams();
  let permission;
  if (currentUser) {
    permission = currentUser.currentUser.permission;
  }

  const { data, loading, refetch } = useQuery(LOCATION, {
    variables: {
      where: { id },
    },
  });

  // MUTATIONS
  const [createGeoTargetLocation] = useMutation(CREATE_GEOTARGET_LOCATION);

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    variables: {
      Id: id,
    },
    onCompleted: () => {
      history.push("/retails_stores/main/1");
    },
  });

  const [updateLocation] = useMutation(EDIT_LOCATION);
  const [updateLocationWithGeotargets] = useMutation(
    EDIT_LOCATION_ADMIN_WITH_GEOTARGETS
  );

  return (
    <RetailStoresDetailsContext.Provider
      value={{
        locations: data,
        refetch,
        deleteLocation,
        permission,
        cartProvider,
        updateLocation,
        updateLocationWithGeotargets,
        currentUser: currentUser.currentUser,
        createGeoTargetLocation,
        loading,
        userLoading,
      }}
    >
      {children}
    </RetailStoresDetailsContext.Provider>
  );
};

export const useRetailStoresDetailsContext = () => {
  const context = useContext(RetailStoresDetailsContext);
  if (!context) {
    throw new Error(
      "useRetailStoresDetailsContext must be used within a RetailStoresDetailsProvider"
    );
  }
  return context;
};
