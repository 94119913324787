import React, { useState } from "react";
import { Row, Col, Select } from "antd";
import { options } from "../utils/generator-options";

const EcommerceCartOptions = ({ ecommerceCart, ecommerceOnChange, stable }) => {
  const stableCarts = options.stable.ecommerce.map(({ value, text }) => (
    <Select.Option key={value} value={value}>
      {text}
    </Select.Option>
  ));

  const betaCarts = options.beta.ecommerce.map(({ value, text }) => (
    <Select.Option key={value} value={value}>
      {text}
    </Select.Option>
  ));
  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Col>
        <h5>Ecommerce Cart Provider</h5>
        <Select
          placeholder="Cart"
          value={ecommerceCart}
          onChange={ecommerceOnChange}
          style={{ width: "200px" }}
        >
          {stable ? stableCarts : betaCarts}
        </Select>
      </Col>
    </Row>
  );
};

const DSPOptions = ({ dspProvider, dspOnChange, stable }) => {
  const stableImpressions = options.stable.impressions.map(
    ({ value, text }) => (
      <Select.Option key={value} value={value}>
        {text}
      </Select.Option>
    )
  );

  const betaImpressions = options.beta.impressions.map(({ value, text }) => (
    <Select.Option key={value} value={value}>
      {text}
    </Select.Option>
  ));
  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Col>
        <h5>Impressions Provider (DSP)</h5>
        <Select
          placeholder="DSP"
          value={dspProvider}
          onChange={dspOnChange}
          style={{ width: "200px" }}
        >
          {stable ? stableImpressions : betaImpressions}
        </Select>
      </Col>
    </Row>
  );
};

const SelectOptions = ({ version, setEnvironment, environment, setAppId }) => {
  const stable = version === "Stable";
  const [ecommerceCart, setEcommerceCart] = useState(environment);
  const [dspProvider, setdspProvider] = useState(null);

  const ecommerceOnChange = value => {
    setAppId("");
    setEcommerceCart(value);
    setdspProvider(null);
    setEnvironment(value);
  };

  const dspOnChange = value => {
    if (value === "liquidm") setAppId("LiquidM");
    if (value === "-1") setAppId("");
    setdspProvider(value);
    setEcommerceCart(null);
    setEnvironment(value);
  };

  return (
    <>
      <EcommerceCartOptions
        ecommerceCart={ecommerceCart}
        ecommerceOnChange={ecommerceOnChange}
        stable={stable}
      />
      <DSPOptions
        dspProvider={dspProvider}
        dspOnChange={dspOnChange}
        stable={stable}
      />
    </>
  );
};

export default SelectOptions;
