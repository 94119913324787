import React from "react";
import { Row, Col, Card, Affix, Button, Modal } from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import Changelog from "./components/Changelog";
import OrganizationDetails from "./components/OrganizationDetails";
import { isEmpty } from "lodash";
import { defaultIabCategory } from "../../../core/utils/constants/constants";

import {
  AttributionTargetsModal,
  BasicsCard,
  CardTitle,
  ReviewBasics,
  ReviewDemographics,
  ReviewOptimization,
  ReviewGeotargets,
  ReviewCreatives,
  BasicsDemographicsCard,
  BasicsDevicesCard,
  OptimizationModalCard,
  GeoTargetsModalCard,
  CreativesModalCard,
  ReviewAttribution,
  ConfirmLocationsModal,
} from "../CampaignLauncher/components";
import { EditOutlined } from "@ant-design/icons";

const styles = {
  card: {
    marginBottom: "20px",
  },
  cardBody: {
    padding: 0,
  },
  cardRow: {
    padding: "15px",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
  map: {
    position: "relative",
    height: "300px",
    width: "100%",
  },
  titleStyles: {
    iconStyle: {
      color: "#9b9b9b",
      fontSize: "22px",
    },
    textStyle: {
      color: "#9b9b9b",
    },
  },
  editButton: {
    border: 0,
    height: "25px",
    width: "25px",
  },
  modalBody: {
    padding: 0,
  },
};

const CampaignOrderDetails = ({
  id,
  status,
  brands,
  campaignName,
  category,
  tags,
  isRegulated,
  startDate,
  endDate,
  age,
  language,
  income,
  segments,
  devices,
  objectiveNewCustomers,
  objectiveExistingCustomers,
  retargetDayCap,
  retargetTotalCap,
  kpi,
  kpiGoal,
  budget,
  adUnits,
  geoTargets,
  handleComplete,
  showEditModal,
  whichModal,
  onChange,
  onCheckBoxSelect,
  onSlider,
  whichActive,
  addGeoTarget,
  removeGeoTargets,
  handleChangeRadius,
  onCsvUploadComplete,
  selectedGeoTargets,
  onChangeArrayOfObj,
  addAdUnit,
  removeBanners,
  updateCampaignOrderStatus,
  campaignOrder,
  removeAdUnit,
  history,
  tablet,
  attributionTargets,
  showModal,
  selectedLocations,
  confirmLocationsTableData,
  onConfirmLocationOk,
  onConfirmAttributionTargetOk,
  removeAttributionTargets,
  selectedAttributionTargets,
}) => {
  const {
    card,
    cardBody,
    cardRow,
    titleStyles,
    editButton,
    modalBody,
  } = styles;

  const CardExtra = ({ which }) => {
    return (
      <Button
        style={editButton}
        icon={<EditOutlined />}
        size="large"
        onClick={() => {
          onChange("showEditModal", true);
          onChange("whichModal", which);
        }}
      />
    );
  };

  if (!category) {
    category = defaultIabCategory.code;
  }

  return (
    <React.Fragment>
      <PageHeaderLayout
        title="Campaign Order Audit"
        centerContent={<div></div>}
        rightContent={
          status !== "LIVE" && (
            <Row type="flex">
              <Col>
                <Button
                  onClick={async () => {
                    if (!isEmpty(id)) {
                      updateCampaignOrderStatus({
                        variables: {
                          id,
                          status:
                            status === "PENDING" ? "PENDING_CLIENT" : "PENDING",
                          message:
                            status === "PENDING"
                              ? `Your Campaign Order ${campaignName} has requested changes`
                              : `Your Campaign Order ${campaignName} has been submitted for review`,
                        },
                      });
                      history.push("/campaigns");
                    }
                  }}
                  shape="round"
                >
                  {status === "PENDING" ? "Request Changes" : "Approve Changes"}
                </Button>
              </Col>
            </Row>
          )
        }
      />
      <PageContentLayout>
        {tablet && (
          <Col span={24}>
            <Row>
              <OrganizationDetails tablet={tablet} data={campaignOrder.orgs} />
            </Row>
            <Row>
              <Card
                size="small"
                title={
                  <Row type="flex" justify="center">
                    <h4
                      style={{
                        margin: 0,
                        color: "#9b9b9b",
                        fontSize: "16px",
                      }}
                    >
                      Changelog
                    </h4>
                  </Row>
                }
                style={{
                  margin: "10px 0 10px 0",
                  width: "100%",
                }}
              >
                <Changelog
                  tablet={tablet}
                  changeLog={campaignOrder.changeLog}
                />
              </Card>
            </Row>
          </Col>
        )}
        <Row gutter={12}>
          <Col span={tablet ? 24 : 16}>
            <Card
              extra={<CardExtra which={"basics"} />}
              style={card}
              title={
                <CardTitle
                  titleText="Basics"
                  iconType="question-circle"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <ReviewBasics
                brands={brands}
                campaignName={campaignName}
                startDate={startDate}
                endDate={endDate}
                category={category}
                tags={tags}
                isRegulated={isRegulated}
              />
            </Card>
            <Card
              extra={<CardExtra which="demographics" />}
              style={card}
              title={
                <CardTitle
                  titleText="Demographic"
                  iconType="user"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <Row style={cardRow}>
                <ReviewDemographics
                  age={age}
                  language={language}
                  income={income}
                  segments={segments}
                  devices={devices}
                />
              </Row>
            </Card>
            <Card
              extra={<CardExtra which="optimization" />}
              style={card}
              title={
                <CardTitle
                  titleText="Optimization"
                  iconType="bar-chart"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <ReviewOptimization
                objectiveNewCustomers={objectiveNewCustomers}
                objectiveExistingCustomers={objectiveExistingCustomers}
                retargetDayCap={retargetDayCap}
                retargetTotalCap={retargetTotalCap}
                kpi={kpi}
                kpiGoal={kpiGoal}
                budget={budget}
              />
            </Card>
            <Card
              extra={<CardExtra which="geoTargets" />}
              style={card}
              title={
                <CardTitle
                  titleText="Geo-Targets"
                  iconType="environment"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <ReviewGeotargets geoTargets={geoTargets} />
            </Card>
            <Card
              extra={<CardExtra which="attributionTargets" />}
              style={card}
              title={
                <CardTitle
                  titleText="Attribution Targets"
                  iconType="environment"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <ReviewAttribution geoTargets={attributionTargets} />
            </Card>
            <Card
              extra={<CardExtra which="creatives" />}
              style={card}
              title={
                <CardTitle
                  titleText="Creatives"
                  iconType="layout"
                  gutter={10}
                  styles={titleStyles}
                />
              }
              bodyStyle={cardBody}
            >
              <ReviewCreatives
                adUnits={adUnits}
                startDate={startDate}
                endDate={endDate}
              />
            </Card>
          </Col>
          {!tablet && (
            <Col span={6}>
              <Affix offsetTop={20} affix={"false"}>
                <Row>
                  <OrganizationDetails data={campaignOrder.orgs} />
                </Row>
                <Row>
                  <Card
                    size="small"
                    title={
                      <Row type="flex" justify="center">
                        <h4
                          style={{
                            margin: 0,
                            color: "#9b9b9b",
                            fontSize: "16px",
                          }}
                        >
                          Changelog
                        </h4>
                      </Row>
                    }
                    style={{
                      marginTop: "50px",
                      width: "100%",
                    }}
                  >
                    <Changelog changeLog={campaignOrder.changeLog} />
                  </Card>
                </Row>
              </Affix>
            </Col>
          )}
          <Modal
            onOk={() => {
              handleComplete(whichModal);
              onChange("showEditModal", false);
            }}
            destroyOnClose={true}
            visible={showEditModal}
            onCancel={() => {
              onChange("showEditModal", false);
            }}
            width={
              tablet
                ? 750
                : whichModal === "geoTargets" ||
                  whichModal === "attributionTargets"
                ? 1200
                : 800
            }
            closable={false}
            bodyStyle={modalBody}
          >
            {whichModal === "basics" ? (
              <Card
                bordered={false}
                style={card}
                title={
                  <CardTitle
                    titleText="Basics"
                    iconType="question-circle"
                    gutter={10}
                    styles={titleStyles}
                  />
                }
                bodyStyle={cardBody}
              >
                <BasicsCard
                  onChange={onChange}
                  campaignName={campaignName}
                  startDate={startDate}
                  endDate={endDate}
                  brands={brands}
                  category={category}
                  tags={tags}
                  isRegulated={isRegulated}
                />
              </Card>
            ) : whichModal === "demographics" ? (
              <Card
                bordered={false}
                style={card}
                title={
                  <CardTitle
                    titleText="Demographic"
                    iconType="user"
                    gutter={10}
                    styles={titleStyles}
                  />
                }
                bodyStyle={cardBody}
              >
                <BasicsDemographicsCard
                  onChange={onChange}
                  segments={segments}
                  language={language}
                  income={income}
                  age={age}
                  title={false}
                />
                <BasicsDevicesCard
                  devices={devices}
                  onCheckBoxSelect={onCheckBoxSelect}
                  border={false}
                />
              </Card>
            ) : whichModal === "optimization" ? (
              <OptimizationModalCard
                onChange={onChange}
                onSlider={onSlider}
                objectiveNewCustomers={objectiveNewCustomers}
                objectiveExistingCustomers={objectiveExistingCustomers}
                retargetDayCap={retargetDayCap}
                retargetTotalCap={retargetTotalCap}
                kpi={kpi}
                kpiGoal={kpiGoal}
                budget={budget}
                whichActive={whichActive}
              />
            ) : whichModal === "geoTargets" ? (
              <GeoTargetsModalCard
                addGeoTarget={addGeoTarget}
                removeGeoTargets={removeGeoTargets}
                geoTargets={geoTargets}
                handleChangeRadius={handleChangeRadius}
                onChange={onChange}
                onCsvUploadComplete={onCsvUploadComplete}
                selectedGeoTargets={selectedGeoTargets}
              />
            ) : whichModal === "creatives" ? (
              <CreativesModalCard
                removeAdUnit={removeAdUnit}
                onChangeArrayOfObj={onChangeArrayOfObj}
                adUnits={adUnits}
                addAdUnit={addAdUnit}
                removeBanners={removeBanners}
              />
            ) : whichModal === "attributionTargets" ? (
              <AttributionTargetsModal
                addGeoTarget={addGeoTarget}
                removeGeoTargets={removeAttributionTargets}
                geoTargets={attributionTargets}
                handleChangeRadius={handleChangeRadius}
                onChange={onChange}
                onCsvUploadComplete={onCsvUploadComplete}
                selectedGeoTargets={selectedAttributionTargets}
              />
            ) : (
              <div />
            )}
          </Modal>
          <ConfirmLocationsModal
            onConfirmLocationOk={
              whichModal === "attributionTargets"
                ? onConfirmAttributionTargetOk
                : onConfirmLocationOk
            }
            tableData={confirmLocationsTableData}
            showModal={showModal}
            selectedLocations={selectedLocations}
            onChange={onChange}
          />
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default CampaignOrderDetails;
