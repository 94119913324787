import React from "react";
import ReactDOM from "react-dom";
import { Table } from "antd";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { isNumber } from "@turf/helpers";

class SimpleTable extends React.Component {
  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);
    const table = dom.querySelectorAll(".ant-table-body");
    let l = table[0];
    let r = table[1];
    if (r) {
      l.addEventListener("scroll", function() {
        r.scrollLeft = l.scrollLeft;
      });
      r.style.overflowX = "hidden";
      r.addEventListener("scroll", function() {
        l.scrollLeft = r.scrollLeft;
      });
    }
  }

  render() {
    const {
      columns,
      data,
      scroll,
      onRow,
      bordered,
      title,
      onChange,
      size,
      rowSelection,
      footer,
      expandable,
      rowClassName,
      loading,
      showHeader,
      pagination,
      rowKey,
    } = this.props;

    const buildFooterColumns = () => {
      let footerCols = [];

      if (rowSelection) {
        footerCols.push({
          dataIndex: "",
          key: "rowSelectionPlaceHolder",
          width: rowSelection.columnWidth,
        });
      }

      columns.forEach((col, indx) => {
        footerCols.push({
          dataIndex: col.dataIndex,
          width: col.width,
          key: col.key,
          footerContent: col.footerContent || false,
          footerSum: col.footerSum || false,
          footerSumFormatter: col.footerSumFormatter || false,
          footerSums: col.footerSums || false,
        });
      });

      return footerCols;
    };

    const buildFooterData = () => {
      const propertyDriller = (record, dotSeperatedKey) => {
        let output = record;
        const properties = dotSeperatedKey.split(".");
        properties.forEach(prop => {
          if (isEmpty(output)) return 0;
          output = output[prop];
        });
        if (isNumber(output)) {
          return output;
        }
        return 0;
      };

      const buildFooterCalculations = (arr, footer) => {
        if (arr.length > 0) {
          let strs = "";
          arr.forEach(cur => {
            strs += `${cur.title}: ${
              cur.formatter ? cur.formatter : ""
            }${Number(
              footer
                .reduce((sum, record) => {
                  return (
                    sum + parseFloat(propertyDriller(record, cur.property))
                  );
                }, 0)
                .toFixed(cur.footerSumFixed || 0)
            ).toLocaleString()}\n`;
          });
          return strs;
        }
      };

      return buildFooterColumns().reduce(
        (acc, cur) => {
          const colData = [
            {
              [cur.dataIndex]: cur.footerContent
                ? cur.footerContent
                : cur.footerSums
                ? buildFooterCalculations(cur.footerSums, footer)
                : cur.footerSum
                ? `${
                    cur.footerSumFormatter ? cur.footerSumFormatter : ""
                  }${Number(
                    footer
                      .reduce((sum, record) => {
                        return (
                          sum + parseFloat(propertyDriller(record, cur.key))
                        );
                      }, 0)
                      .toFixed(cur.footerSumFixed || 0)
                  ).toLocaleString()}`
                : "",
            },
          ];
          acc.splice(0, 1, {
            ...acc[0],
            ...colData[0],
          });
          return acc;
        },
        [{ key: "data" }]
      );
    };

    //Note to use the pagination object all values must be passed as props
    // let paginationObject = {};
    let chartSize = "default";

    // const options = computePageSizeOptions(total);

    // paginationObject = {
    //   ...pagination,
    //   hideOnSinglePage: options.length !== 0 ? false : true,
    //   itemRender,
    //   pageSize: parseInt(pageSize),
    //   total,
    //   current,
    //   showSizeChanger,
    //   pageSizeOptions: options,
    // };

    if (size) {
      chartSize = size;
    }

    return (
      <Table
        rowKey={rowKey}
        rowClassName={rowClassName ? rowClassName : () => ""}
        rowSelection={rowSelection}
        title={title ? title : undefined}
        bordered={bordered || bordered === undefined ? true : false}
        loading={loading || !data ? true : false}
        size={chartSize}
        showHeader={showHeader}
        onRow={onRow}
        columns={columns}
        dataSource={data}
        scroll={
          !scroll
            ? { x: 1600, scrollToFirstRowOnChange: true }
            : { ...scroll, scrollToFirstRowOnChange: true }
        }
        pagination={pagination}
        onChange={onChange}
        footer={
          footer && footer.length > 1
            ? () => {
                return (
                  <div>
                    <Table
                      pagination={false}
                      showHeader={false}
                      dataSource={buildFooterData()}
                      columns={buildFooterColumns()}
                      scroll={
                        !scroll
                          ? { x: 1600, scrollToFirstRowOnChange: true }
                          : { ...scroll, scrollToFirstRowOnChange: true }
                      }
                    />
                  </div>
                );
              }
            : null
        }
        expandable={expandable}
      />
    );
  }
}

export default SimpleTable;

SimpleTable.propTypes = {
  total: PropTypes.number,
};
