import React, { useState, useEffect } from "react";
import moment from "moment";
import { determineAdServer } from "../../../../../platform/ux/Advertiser/details/components/utils";
import { colors } from "../../../../utils/constants/listOfColors";
const AdvertiserDetailsTransactionsReportController = props => {
  const { signedURL, advertiser, deleteAdvertiser, isSelfService } = props;
  const [reportDataSummary, setReportData] = useState({
    byDates: [],
    transactions: [],
    domains: [],
    summary: {
      customers: 0,
      impressions: 0,
      revenue: 0,
      transactions: 0,
      roas: 0,
      spend: 0,
      totalImpressions: 0,
    },
  });
  const [reportDataRawSummary, setReportRawData] = useState();
  const [reportDataSummaryLoading, setReportLoading] = useState(true);
  const [reporDataSummarytError, setReportError] = useState();
  const [topCreatives, setTopCreatives] = useState([]);
  const [topImpressions, setTopImpressions] = useState([]);
  const [topInventoryTypes, setTopInventoryTypes] = useState([]);

  useEffect(() => {
    if (signedURL) {
      const s3UrlSummary = new URL(signedURL.getSignedURL);
      fetch(s3UrlSummary).then(async response => {
        if (response && response.ok) {
          try {
            await response.json().then(json => {
              setReportData(json);
              setReportRawData(json);
              setReportLoading(false);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        } else {
          setReportLoading(false);
        }
      });
    }
  }, [signedURL]);

  useEffect(() => {
    if (reportDataSummary) {
      const {
        sortedCreatives,
        sortedImpressionDomains,
        sortedInventoryTypes,
      } = topChartData(
        reportDataSummary.transactions,
        reportDataSummary.domains
      );

      setTopCreatives(sortedCreatives);
      setTopImpressions(sortedImpressionDomains);
      setTopInventoryTypes(sortedInventoryTypes);
    }
  }, [reportDataSummary]);

  const changeDatePicker = dates => {
    const datePickerStartDate = dates[0].format("YYYY-MM-DD");
    const datePickerEndDate = dates[1].format("YYYY-MM-DD");

    const { byDates, transactions } = reportDataRawSummary;

    const newByDates = byDates.filter(
      date =>
        moment(date.date).isSameOrBefore(datePickerEndDate) &&
        moment(date.date).isSameOrAfter(datePickerStartDate)
    );

    const newTransactions = transactions.filter(
      transaction =>
        moment(transaction.transaction).isSameOrAfter(datePickerStartDate) &&
        moment(transaction.transaction).isSameOrBefore(datePickerEndDate)
    );

    let summary = {
      customers: 0,
      impressions: 0,
      revenue: 0,
      transactions: 0,
      totalImpressions: 0,
    };

    const uniqueIPS = [];
    const uniqueCookies = [];
    const uniqueDeviceFingerprints = [];
    const uniqueFingerprints = [];

    newTransactions.forEach(transaction => {
      summary.revenue += parseFloat(transaction.transactionTotal);
      summary.impressions += parseFloat(transaction.totalImpressions);
      summary.transactions += 1;

      const { attributionMethod } = transaction;
      if (attributionMethod && attributionMethod.COOKIE) {
        if (!uniqueCookies.includes(attributionMethod.COOKIE)) {
          uniqueCookies.push(attributionMethod.COOKIE);
        }
      }
      if (attributionMethod && attributionMethod.FINGERPRINT) {
        if (!uniqueFingerprints.includes(attributionMethod.FINGERPRINT)) {
          uniqueFingerprints.push(attributionMethod.FINGERPRINT);
        }
      }

      if (attributionMethod && attributionMethod.DEVICE_FINGERPRINT) {
        if (
          !uniqueDeviceFingerprints.includes(
            attributionMethod.DEVICE_FINGERPRINT
          )
        ) {
          uniqueDeviceFingerprints.push(attributionMethod.DEVICE_FINGERPRINT);
        }
      }
      if (attributionMethod && attributionMethod.IP) {
        if (!uniqueIPS.includes(attributionMethod.IP)) {
          uniqueIPS.push(attributionMethod.IP);
        }
      }
    });

    summary.totalImpressions = newByDates.reduce(
      (acc, curr) => (acc += curr.totalImpressions),
      0
    );

    setReportData({
      byDates: newByDates,
      transactions: newTransactions,
      summary: {
        ...summary,
        customers:
          uniqueCookies.length +
          uniqueFingerprints.length +
          uniqueIPS.length +
          uniqueDeviceFingerprints.length,
      },
    });

    const {
      sortedCreatives,
      sortedImpressionDomains,
      sortedInventoryTypes,
    } = topChartData(newTransactions, reportDataSummary.domains);

    setTopCreatives(sortedCreatives);
    setTopImpressions(sortedImpressionDomains);
    setTopInventoryTypes(sortedInventoryTypes);
  };

  const topChartData = (transactions, domains) => {
    const creatives = [];
    const impressionDomains = [];
    const inventoryTypes = [];
    let index = 0;
    transactions.forEach(transaction => {
      transaction.impressions.forEach(impression => {
        index++;
        let inventoryType = "Application";
        let impressionDomain = decodeURIComponent(
          impression.IMPRESSION_APPNAME
        );
        const {
          IMPRESSION_PAGE_URL,
          PAGE_URLHOST,
          IMPRESSION_REFERRER,
          IMPRESSION_APPNAME,
          IMPRESSION_APPSTORE_ID,
          IMPRESSION_SITE_ID,
          IMPRESSION_SITENAME,
          APP_ID_IMPRESSION,
        } = impression;

        const DSP = APP_ID_IMPRESSION;

        switch (DSP) {
          case "BKO":
          case "GoogleAdsManager":
          case "Smart":
            if (
              (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
              (!IMPRESSION_APPNAME ||
                IMPRESSION_APPNAME === "N/A" ||
                IMPRESSION_APPNAME === "{APPNAME}") &&
              (!IMPRESSION_SITE_ID ||
                IMPRESSION_SITE_ID === "N/A" ||
                IMPRESSION_SITE_ID === "{APPNAME}") &&
              (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
            ) {
              impressionDomain = PAGE_URLHOST
                ? decodeURIComponent(PAGE_URLHOST)
                : "Unknown Domain";
              //Chacks the available page and referrer information to determine application platform
              inventoryType = determineAdServer(PAGE_URLHOST);
              //Even if we determine the page url to be an ad server - the referrer may still give better resolution
              if (IMPRESSION_REFERRER && inventoryType !== "Application") {
                inventoryType = determineAdServer(IMPRESSION_REFERRER);
              }
            } else if (
              IMPRESSION_SITENAME &&
              (!IMPRESSION_APPNAME || !IMPRESSION_APPSTORE_ID)
            ) {
              impressionDomain = decodeURIComponent(IMPRESSION_SITENAME)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Website";
            } else if (
              !IMPRESSION_SITENAME &&
              (IMPRESSION_APPNAME || IMPRESSION_APPSTORE_ID)
            ) {
              const appName = IMPRESSION_APPNAME || IMPRESSION_APPSTORE_ID;
              impressionDomain = decodeURIComponent(appName)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Application";
            } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
              impressionDomain = decodeURIComponent(IMPRESSION_SITENAME)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Website";
            }
            break;
          // Fallback into LIQUIDM DSP
          default:
            if (
              (!IMPRESSION_APPSTORE_ID || IMPRESSION_APPSTORE_ID === "N/A") &&
              (!IMPRESSION_APPNAME || IMPRESSION_APPNAME === "N/A") &&
              (!IMPRESSION_SITE_ID || IMPRESSION_SITE_ID === "N/A") &&
              (!IMPRESSION_SITENAME || IMPRESSION_SITENAME === "N/A")
            ) {
              impressionDomain = decodeURIComponent(PAGE_URLHOST);
              //Chacks the available page and referrer information to determine application platform
              inventoryType = determineAdServer(PAGE_URLHOST);
              //Even if we determine the page url to be an ad server - the referrer may still give better resolution
              if (IMPRESSION_REFERRER && inventoryType !== "Application") {
                inventoryType = determineAdServer(IMPRESSION_REFERRER);
              }
            } else if (IMPRESSION_APPSTORE_ID && IMPRESSION_APPNAME) {
              //Highly confident we have a mobile application based on new macros
              impressionDomain = decodeURIComponent(IMPRESSION_APPNAME)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Application";
            } else if (
              IMPRESSION_SITENAME &&
              IMPRESSION_SITE_ID &&
              !IMPRESSION_SITE_ID
            ) {
              //No url from IMPRESSION_SITE_ID suggests mobile application
              impressionDomain = decodeURIComponent(IMPRESSION_SITENAME)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Application";
            } else if (IMPRESSION_SITENAME && IMPRESSION_APPNAME) {
              //suggests website - better resolution than page level information
              impressionDomain = decodeURIComponent(IMPRESSION_SITENAME)
                .replace(/[+_]/g, " ")
                .split(":")
                .pop();
              inventoryType = "Website";
            } else if (IMPRESSION_PAGE_URL && PAGE_URLHOST) {
              impressionDomain = decodeURIComponent(PAGE_URLHOST);
              inventoryType = "Application";
            }
        }

        const creativeExists = creatives.findIndex(
          c => c.name === impression.CREATIVEID
        );
        if (creativeExists === -1) {
          creatives.push({
            name: impression.CREATIVEID,
            impressions: 1,
            transactions: 1,
            transactionsList: [transaction.transactionId],
            color: colors[index],
          });
        } else {
          const transactionExists = creatives[
            creativeExists
          ].transactionsList.findIndex(t => t === transaction.transactionId);
          if (transactionExists === -1) {
            creatives[creativeExists].transactionsList.push(
              transaction.transactionId
            );
            creatives[creativeExists].transactions += 1;
          }
          creatives[creativeExists].impressions += 1;
        }

        const impressionExists = impressionDomains.findIndex(
          i => i.name === impressionDomain
        );
        if (impressionExists === -1) {
          const domain =
            domains && domains.length
              ? domains.find(d => d.name === impressionDomain.trim())
              : null;
          impressionDomains.push({
            name: impressionDomain,
            transactions: 1,
            type: inventoryType,
            transactionsList: [transaction.transactionId],
            url: domain ? domain.url : null,
            color: colors[index],
          });
        } else {
          const transactionExists = impressionDomains[
            impressionExists
          ].transactionsList.findIndex(t => t === transaction.transactionId);
          if (transactionExists === -1) {
            impressionDomains[impressionExists].transactionsList.push(
              transaction.transactionId
            );
            impressionDomains[impressionExists].transactions += 1;
          }
        }

        const inventoryTypeExists = inventoryTypes.findIndex(
          i => i.type === inventoryType
        );
        if (inventoryTypeExists === -1) {
          inventoryTypes.push({
            type: inventoryType,
            impressions: 1,
            transactions: 1,
            transactionsList: [transaction.transactionId],
            color: colors[index],
          });
        } else {
          const transactionExists = inventoryTypes[
            inventoryTypeExists
          ].transactionsList.findIndex(t => t === transaction.transactionId);
          if (transactionExists === -1) {
            inventoryTypes[inventoryTypeExists].transactionsList.push(
              transaction.transactionId
            );
            inventoryTypes[inventoryTypeExists].transactions += 1;
          }
          inventoryTypes[inventoryTypeExists].impressions += 1;
        }
      });
    });

    const sortedCreatives = creatives.sort(
      (a, b) => b.transactions - a.transactions
    );
    const sortedImpressionDomains = impressionDomains.sort(
      (a, b) => b.transactions - a.transactions
    );
    const sortedInventoryTypes = inventoryTypes.sort(
      (a, b) => b.transactions - a.transactions
    );

    return {
      sortedCreatives,
      sortedImpressionDomains,
      sortedInventoryTypes,
    };
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    reportDataSummary,
    reportDataSummaryLoading,
    reporDataSummarytError,
    advertiser,
    changeDatePicker,
    deleteAdvertiser,
    isSelfService,
    topCreatives,
    topImpressions,
    topInventoryTypes,
  });
};

export default AdvertiserDetailsTransactionsReportController;
