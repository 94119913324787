import React, { useEffect } from "react";
import { Form, Row, Space, Col, Input, Button } from "antd";
import { FundProjectionScreenOutlined } from "@ant-design/icons";

export const LaunchCampaignJamLoopConfig = ({
  selectedCampaignOrder,
  isLaunchCampaignDisabled,
  handleSubmitCampaign,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedCampaignOrder && selectedCampaignOrder.dspConfig) {
      form.setFieldsValue({
        ...selectedCampaignOrder.dspConfig,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignOrder]);

  const handleLaunchCampaign = async () => {
    const isFormValid = await checkFormValidity();
    if (!isFormValid) return;

    const value = form.getFieldsValue();
    const { adProviderId, pageViewTrackingId, locationId } = value;
    handleSubmitCampaign({
      dspConfig: {
        adProviderIds: [adProviderId], // TODO: Prepare for multiple ad providers
        pageViewTrackingId,
        locationId,
      },
    });
  };

  // TODO: Check if there's available hooks else create a custom hook
  const checkFormValidity = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <Space direction="vertical" size="small" style={{ width: "100%" }}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="adProviderId"
              label="Ad Provider Id"
              rules={[
                {
                  required: true,
                  message: "Please input Ad Provider Id",
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Button
          type="primary"
          onClick={handleLaunchCampaign}
          icon={<FundProjectionScreenOutlined />}
          disabled={
            isLaunchCampaignDisabled ||
            (selectedCampaignOrder.status === "LIVE" &&
              (!selectedCampaignOrder.dspConfig ||
                !selectedCampaignOrder.dspConfig.name))
          }
        >
          {selectedCampaignOrder.status === "APPROVED" ? "Launch " : "Update "}
          Campaign
        </Button>
      </Row>
    </Space>
  );
};
