import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_SHARING = gql`
  mutation updateCampaignOrderSharing(
    $id: ID!
    $orgs: OrgUpdateManyWithoutCampaignOrdersInput!
  ) {
    updateCampaignOrder(where: { id: $id }, data: { orgs: $orgs }) {
      id
      orgs {
        id
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_SHARING;
