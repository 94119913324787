import gql from "graphql-tag";

const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($notificationID: ID!) {
    deleteNotification(where: { id: $notificationID }) {
      id
      message
    }
  }
`;

export default DELETE_NOTIFICATION;
