import React, { useState, useEffect } from "react";
import { Row, Col, Card, Select, Grid } from "antd";

// Global Styling
import { CardTitleText } from "../../../../../../shared/globalStyling/styledText";
import DetailBar4Col from "./DetailBar4Col";
import TransactionsTable from "./TransactionsTable";
import TransactionsChart from "./charts/TransactionsChart";
import { isEmpty } from "lodash";
import moment from "moment";

const { Option } = Select;

const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const { campaignCardStyleCreatives } = Style;

const TransactionDetailBar = ({
  selectedCampaign,
  colStyle,
  chartData,
  byTransactionsRangeDate,
  setByTransactionsRangeDate,
  dataTransactionsRangeDate,
  setDataTransactionsRangeDate,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  setDataAttributionWindow,
  campaignOrder,
  transactionTarget,
  dateTrue,
  setDateTrue,
  priceVisible,
  reportName,
}) => {
  const { type, transactionsConfig } = campaignOrder;
  const [attributionWindow, setAttributionWindow] = useState(365);
  const [transactionData, setTransactionData] = useState([]);
  const [uniquesCount, setUniquesCount] = useState(0);
  const [cogs, setCogs] = useState(0);
  const [dateFilter, setDateFilter] = useState([]);
  //Sets up variables
  const { startDate, endDate } = selectedCampaign ? selectedCampaign : [];
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  let campaignSelected = selectedCampaign ? selectedCampaign : [];

  useEffect(() => {
    //Initial effect - sets up data and applies attribution window
    const applyAttributionWindow = transactionData => {
      const dataToReturn = transactionData.filter(item => {
        let check = true;
        const { impressions, transaction, latestDate } = item;
        if (impressions) {
          impressions.forEach(impression => {
            const { IMPRESSION_TIME } = impression;
            const impressionDate = new Date(moment(IMPRESSION_TIME).format());
            const outOfRange = new Date(moment(transaction).format());
            outOfRange.setDate(outOfRange.getDate() - attributionWindow);
            if (impressionDate < outOfRange) {
              check = false;
            } else if (impressionDate > outOfRange) {
              check = true;
            }
          });
        } else if (latestDate) {
          const impressionDate = new Date(moment(latestDate).format());
          const outOfRange = new Date(moment(transaction).format());
          outOfRange.setDate(outOfRange.getDate() - attributionWindow);
          if (impressionDate < outOfRange) {
            check = false;
          } else if (impressionDate > outOfRange) {
            check = true;
          }
        }
        return check;
      });

      return dataToReturn;
    };

    if (chartData && chartData.transactionsWithEvents) {
      const { transactionsWithEvents } = chartData;

      const transactionDataWithAttributionWindow = applyAttributionWindow(
        transactionsWithEvents
      );
      let dateFilteredTransactionData = transactionDataWithAttributionWindow;
      if (byTransactionsRangeDate && byTransactionsRangeDate.length > 0) {
        dateFilteredTransactionData = transactionDataWithAttributionWindow.filter(
          obj => {
            return byTransactionsRangeDate &&
              byTransactionsRangeDate.length > 0 &&
              obj.transaction
              ? moment(obj.transaction).format("YYYY/MM/DD") >=
                  moment(byTransactionsRangeDate[0]).format("YYYY/MM/DD") &&
                  moment(obj.transaction).format("YYYY/MM/DD") <=
                    moment(byTransactionsRangeDate[1]).format("YYYY/MM/DD")
              : obj;
          }
        );
      }

      setTransactionData(dateFilteredTransactionData);
      setDataAttributionWindow(dateFilteredTransactionData);
      const uniques = determineUniques(dateFilteredTransactionData);
      setUniquesCount(uniques);

      if (
        dateFilteredTransactionData &&
        !isEmpty(dateFilteredTransactionData)
      ) {
        const transactionTotalRevenue = dateFilteredTransactionData.reduce(
          (a, b) => ({
            transactionTotal: a.transactionTotal + b.transactionTotal,
          })
        );

        setCogs(transactionTotalRevenue.transactionTotal);
      }
    }
  }, [
    chartData,
    attributionWindow,
    setByTransactionsRangeDate,
    setDataTransactionsRangeDate,
    setDataAttributionWindow,
    byTransactionsRangeDate,
  ]);

  useEffect(() => {}, [byTransactionsRangeDate, transactionData]);

  const determineUniques = data => {
    const uniques = [];
    data.forEach(item => {
      const { attributionMethod } = item;
      Object.values(attributionMethod).forEach(val => {
        if (!uniques.includes(val)) {
          uniques.push(val);
        }
      });
    });

    return uniques.length;
  };

  return (
    <>
      <DetailBar4Col
        colStyle={colStyle}
        customers={uniquesCount}
        transactionData={transactionData}
        displayData={chartData ? chartData.byDate : []}
        transactions={transactionData.length}
        startDate={startDate}
        endDate={endDate}
        cogs={cogs}
        selectedCampaign={campaignSelected}
        byTransactionsRangeDate={byTransactionsRangeDate}
        dataTransactionsRangeDate={dataTransactionsRangeDate}
        type={type}
        determineUniques={determineUniques}
        dateTrue={dateTrue}
        priceVisible={priceVisible}
        budget={campaignOrder.budget}
      />
      <Row>
        <Col>
          {isEmpty(transactionData) ? null : (
            <TransactionsChart
              chartData={isEmpty(transactionData) ? [] : transactionData}
              chartDataDisplay={
                isEmpty(chartData.byDate) ? [] : chartData.byDate
              }
              byTransactionsRangeDate={byTransactionsRangeDate}
              setByTransactionsRangeDate={setByTransactionsRangeDate}
              dataTransactionsRangeDate={dataTransactionsRangeDate}
              setDataTransactionsRangeDate={setDataTransactionsRangeDate}
              byCampaignRangeDate={byCampaignRangeDate}
              setByCampaignRangeDate={setByCampaignRangeDate}
              dataCampaignRangeDate={dataCampaignRangeDate}
              setDataCampaignRangeDate={setDataCampaignRangeDate}
              type={type}
              dateTrue={dateTrue}
              setDateTrue={setDateTrue}
              setDateFilter={setDateFilter}
              priceVisible={priceVisible}
            />
          )}
          <Card
            style={campaignCardStyleCreatives}
            headStyle={{
              borderWidth: "3px",
              display: "flex",
              flexDirection: "column",
            }}
            bodyStyle={{
              padding: 0,
            }}
            title={<CardTitleText text={"Transactions"} />}
            extra={
              <span>
                {`Select Attribution Window`}
                &nbsp;&nbsp;
                <Select
                  defaultValue="All Time"
                  style={{ width: screens.xs === true ? "100%" : 120 }}
                  onChange={val => {
                    setAttributionWindow(val);
                  }}
                >
                  <Option value={365}>All Time</Option>
                  <Option value={30}>30 Days</Option>
                  <Option value={20}>20 Days</Option>
                  <Option value={10}>10 Days</Option>
                  <Option value={5}>5 days</Option>
                  <Option value={2}>2 days</Option>
                  <Option value={1}>1 Day</Option>
                </Select>
              </span>
            }
          >
            <TransactionsTable
              reportName={reportName}
              campaignOrderId={campaignOrder.id}
              transactionData={transactionData}
              transactionTarget={transactionTarget}
              type={type}
              transactionsConfig={transactionsConfig}
              dateTrue={dateTrue}
              dateFilter={dateFilter}
              isSimplified={
                chartData && chartData.hasSimplifiedReport
                  ? chartData.hasSimplifiedReport
                  : false
              }
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TransactionDetailBar;
