import React from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";

// Queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import LIST_ORGS_ADMIN from "../../GraphQl/Queries/LIST_ORGS_ADMIN";
import GET_LOCATIONS from "../../GraphQl/Queries/GET_LOCATIONS";
import GET_ORG_MEDIAS from "../../GraphQl/Queries/GET_ORG_MEDIAS";

//import GET_ADGROUP_CREATIVE from "../../GraphQl/Queries/GET_ADGROUP_CREATIVE";
import GET_ADGROUP_CREATIVES_BY_MEDIA from "../../GraphQl/Queries/GET_ADGROUP_CREATIVES_BY_MEDIA";
import GET_ADGROUP_CREATIVES from "../../GraphQl/Queries/GET_ADGROUP_CREATIVES";
import GET_ALL_CAMPAIGNORDERS_CONNECTION from "../../GraphQl/Queries/GET_ALL_CAMPAIGNORDERS_CONNECTION";

// Org Mutations
import EDIT_ORG_SELF from "../.././GraphQl/Mutations/EDIT_ORG_SELF";
import EDIT_ORG_DATA_CONFIG from "../../GraphQl/Mutations/EDIT_ORG_DATA_CONFIG";

// Retail Location
import CREATE_RETAIL_LOCATION from "../../GraphQl/Mutations/CREATE_RETAIL_LOCATION";
import CREATE_LOCATION_WITH_GEOTARGET from "../../GraphQl/Mutations/CREATE_LOCATION_WITH_GEOTARGET";
import DELETE_LOCATION_ADMIN from "../../GraphQl/Mutations/DELETE_LOCATION_ADMIN";
import EDIT_LOCATION_ADMIN from "../../GraphQl/Mutations/EDIT_LOCATION_ADMIN";
import CART_PROVIDER from "../../GraphQl/Introspection/CART_PROVIDER";

// Local Mutations
import UPDATE_USER from "../../GraphQl/Mutations/UPDATE_USER";
import CREATE_ORG_MEDIA from "../../GraphQl/Mutations/CREATE_ORG_MEDIA";
import EDIT_ORG_MEDIA from "../../GraphQl/Mutations/EDIT_ORG_MEDIA";
import DELETE_MEDIA from "../../GraphQl/Mutations/DELETE_MEDIA";
import EDIT_MEDIA_FLAG from "../../GraphQl/Mutations/EDIT_MEDIA_FLAG";
import CREATE_ADGROUP_CREATIVES from "../../GraphQl/Mutations/CREATE_ADGROUP_CREATIVES";
import DELETE_ADGROUP_CREATIVES from "../../GraphQl/Mutations/DELETE_ADGROUP_CREATIVES";

// Locals
import Controller from "./Controller";
import OverlaySpinner from "../../.././platform/shared/OverlaySpinner";

// Geotarget Queries
import ATTRIBUTION_AUDIENCES_ORG_CONFIG from "../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_ORG_CONFIG";

// Geotarget Mutation
import CREATE_GEOTARGET_LOCATION from "../../GraphQl/Mutations/CREATE_GEOTARGET_LOCATION";
import EDIT_LOCATION_ADMIN_WITH_GEOTARGETS from "../../GraphQl/Mutations/EDIT_LOCATION_ADMIN_WITH_GEOTARGETS";
import UPDATE_LOCATION_SEGMENT from "../../GraphQl/Mutations/UPDATE_LOCATION_SEGMENT";
import CREATE_AUDIENCE_LOCATION from "../../GraphQl/Mutations/CREATE_AUDIENCE_LOCATION";
import AUDIENCES_LOCATION from "../../GraphQl/Queries/AUDIENCES_LOCATION";
import UPDATE_AUDIENCE_LOCATION from "../../GraphQl/Mutations/UPDATE_AUDIENCE_LOCATION";
import DELETE_AUDIENCE_LOCATION from "../../GraphQl/Mutations/DELETE_AUDIENCE_LOCATION";

// Org Settings
import UPDATE_ORG_SEND_EMAIL_CONFIG from "../../GraphQl/Mutations/UPDATE_ORG_SEND_EMAIL_CONFIG";

// Region Group
import CREATE_CITY_LIST from "../../GraphQl/Mutations/CREATE_CITY_LIST";
import GET_REGION_GROUPS from "../../GraphQl/Queries/GET_REGION_GROUPS";
import DELETE_REGION_GROUP from "../../GraphQl/Mutations/DELETE_REGION_GROUP";
import UPDATE_REGION_GROUP from "../../GraphQl/Mutations/UPDATE_REGION_GROUP";

// IAB Category Group
import CREATE_IAB_CATEGORY_GROUP from "../../GraphQl/Mutations/CREATE_IAB_CATEGORY_GROUP";
import GET_IAB_CATEGORY_GROUPS from "../../GraphQl/Queries/GET_IAB_CATEGORY_GROUPS";
import DELETE_IAB_CATEGORY from "../../GraphQl/Mutations/DELETE_IAB_CATEGORY";

// Hashed Email
import GET_HASHED_EMAILS from "../../GraphQl/Queries/GET_HASHED_EMAILS";
import GET_HASHED_EMAIL_LIST_WITH_COUNT from "../../GraphQl/Queries/GET_HASHED_EMAIL_LIST_WITH_COUNT";
import DELETE_HASHED_EMAIL_ADDRESS from "../../GraphQl/Mutations/DELETE_HASHED_EMAIL_ADDRESS";
import BULK_CREATE_HASHED_EMAIL_ADDRESS from "../../GraphQl/Mutations/BULK_CREATE_HASHED_EMAIL_ADDRESS";
import PROCESS_DEVICE_IDS from "../../GraphQl/Mutations/PROCESS_DEVICE_IDS";

import { isEmpty } from "lodash";
import GET_ORG_SEND_EMAIL_CONFIG from "../../GraphQl/Queries/GET_ORG_SEND_EMAIL_CONFIG";
import CREATE_CAMPAIGN_ORDER_OLD from "../../GraphQl/Mutations/CREATE_CAMPAIGN_ORDER_OLD"; // TODO: Refactor to use CAMPAIGN_ORDER
import { UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS } from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS";
import UPDATE_AD_GROUP_CREATIVES from "../../GraphQl/Mutations/UPDATE_AD_GROUP_CREATIVES";
import UPDATE_AUDIENCE from "../../GraphQl/Mutations/UPDATE_AUDIENCE";
import UPDATE_MANY_AUDIENCES from "../../GraphQl/Mutations/UPDATE_MANY_AUDIENCES";
import UPDATE_MANY_AD_GROUP_CREATIVES from "../../GraphQl/Mutations/UPDATE_MANY_AD_GROUP_CREATIVES";
import CAMPAIGN_ORDER_AMPLIFY from "../../GraphQl/Queries/CAMPAIGN_ORDER_AMPLIFY";
import UPDATE_CAMPAIGN_ORDER_AMPLIFY from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_AMPLIFY";
import CREATE_HASHED_EMAIL_LIST from "../../GraphQl/Mutations/CREATE_HASHED_EMAIL_LIST";
import DELETE_HASHED_EMAIL_LIST from "../../GraphQl/Mutations/DELETE_HASHED_EMAIL_LIST";
import UPDATE_HASHED_EMAIL_LIST from "../../GraphQl/Mutations/UPDATE_HASHED_EMAIL_LIST";
import ACTIVATE_HASHED_EMAIL_LIST from "../../GraphQl/Mutations/ACTIVATE_HASHED_EMAIL_LIST";
import DELETE_MANY_HASHED_EMAIL_ADDRESS from "../../GraphQl/Mutations/DELETE_MANY_HASHED_EMAILS";
import DELETE_MANY_LOCATIONS from "../../GraphQl/Mutations/DELETE_MANY_LOCATIONS";
import CREATE_MANY_ORG_MEDIA from "../../GraphQl/Mutations/CREATE_MANY_ORG_MEDIA";
import BULK_UPLOAD_LOCATIONS from "../../GraphQl/Mutations/BULK_UPLOAD_LOCATIONS";
import UPDATE_MANY_MEDIAS from "../../GraphQl/Mutations/UPDATE_MANY_MEDIAS";

// DEAL IDS
import GET_ORG_DEAL_IDS from "../../GraphQl/Queries/GET_ORG_DEAL_IDS";
import CREATE_ORG_DEAL_ID from "../../GraphQl/Mutations/CREATE_ORG_DEAL_ID";
import DELETE_ORG_DEAL_IDS from "../../GraphQl/Mutations/DELETE_ORG_DEAL_IDS";
import UPDATE_ORG_DEAL_ID from "../../GraphQl/Mutations/UPDATE_ORG_DEAL_ID";
import GET_REGION_GROUPS_WITH_PRESET_LIST from "../../GraphQl/Queries/GET_REGION_GROUPS_WITH_PRESET_LIST";

const Loader = props => {
  const { client } = props;

  let searchParams = new URLSearchParams(props.location.search);
  const tab = searchParams.get("tab");
  let selectedTab = "1";
  switch (tab) {
    case "location":
      selectedTab = "1";
      break;
    case "targeting-location":
      selectedTab = "2";
      break;
    case "creatives":
      selectedTab = "3";
      break;
    case "tracking":
      selectedTab = "4";
      break;
    case "email":
      selectedTab = "5";
      break;
    case "settings":
      selectedTab = "6";
      break;
    default:
      selectedTab = "1";
      break;
  }

  const loggedInOrg = props.currentUser.role.org;

  const { data: campaignOrders } = useQuery(GET_ALL_CAMPAIGNORDERS_CONNECTION, {
    variables: { orgId: props.currentUser.role.org.id },
    fetchPolicy: "no-cache",
  });

  const { data: amplifyCampaigns } = useQuery(CAMPAIGN_ORDER_AMPLIFY, {
    variables: { orgId: props.currentUser.role.org.id, isAmplify: true },
    fetchPolicy: "no-cache",
  });

  const { data: sendEmailConfig } = useQuery(GET_ORG_SEND_EMAIL_CONFIG, {
    variables: { id: loggedInOrg.id },
  });

  const emailConfig = sendEmailConfig && sendEmailConfig.getOrgSendEmailConfig;
  // convert campaign ids to campaign names
  const campaigns = [];
  const ids =
    emailConfig &&
    emailConfig.campaignOrders &&
    emailConfig.campaignOrders.split(",");

  const cfg =
    campaignOrders &&
    campaignOrders.campaignOrdersConnection &&
    campaignOrders.campaignOrdersConnection.edges;

  const options = [];

  cfg &&
    cfg.map(({ node }) =>
      options.push({
        value: node.name,
        label: node.name,
        key: node.id,
      })
    );

  ids &&
    ids.map(
      id =>
        options &&
        options.filter(i => {
          if (i.key === id) {
            campaigns.push(i.label);
          }
          return i;
        })
    );

  const orgSendEmailConfig = {
    ...emailConfig,
    campaignOrders: campaigns,
  };

  const { loading, data } = useQuery(LIST_ORGS_ADMIN, {
    variables: { parentid: loggedInOrg.id, first: 1 },
  });

  const { loading: loadingLocation, data: locationData } = useQuery(
    GET_LOCATIONS,
    {
      variables: { id: loggedInOrg.id },
    }
  );

  const {
    loading: loadingTargetingAudiences,
    data: targetingAudiences,
  } = useQuery(ATTRIBUTION_AUDIENCES_ORG_CONFIG, {
    variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
  });

  const { data: orgDealIds } = useQuery(GET_ORG_DEAL_IDS, {
    variables: { id: loggedInOrg.id },
  });

  const [createOrgDealId] = useMutation(CREATE_ORG_DEAL_ID, {
    variables: {
      id: props.id,
      data: props.data,
    },
  });

  const [deleteOrgDealIds] = useMutation(DELETE_ORG_DEAL_IDS, {
    variables: {
      ids: props.ids,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_DEAL_IDS,
        variables: { id: loggedInOrg.id },
      },
    ],
  });

  const [updateDealId] = useMutation(UPDATE_ORG_DEAL_ID, {
    variables: {
      id: props.id,
      data: props.data,
    },
  });

  const {
    loading: loadingAttributionAudiences,
    data: attributionAudiences,
  } = useQuery(ATTRIBUTION_AUDIENCES_ORG_CONFIG, {
    variables: { TYPE: "ATTRIBUTION", ORGID: loggedInOrg.id },
  });

  const updateOrgSendEmailConfig = async data => {
    const { campaignOrders, email, frequency, dayOfWeek } = data;
    try {
      const response = await props.updateOrgSendEmailConfig({
        variables: {
          id: loggedInOrg.id,
          campaignOrders,
          email,
          frequency,
          dayOfWeek,
        },
      });

      if (response) {
        return response;
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const editOrg = async data => {
    const {
      id,
      name,
      description,
      level,
      website,
      domain,
      logo,
      zone,
      googleCustomerId,
      attributionWindow,
      menuId,
      dspAdvertiserId,
      roasFormat,
      defaultDisplayAttributionWindow,
      defaultPaidSearchAttributionWindow,
      defaultSEOAttributionWindow,
      currency,
    } = data;

    try {
      const response = await props.updateOrgBasic({
        variables: {
          id,
          name,
          description,
          level,
          website,
          domain: isEmpty(domain) ? null : domain,
          logoId: isEmpty(logo) ? null : { id: logo.id },
          zone: zone,
          googleCustomerId,
          attributionWindow,
          menuId,
          dspAdvertiserId,
          roasFormat,
          defaultDisplayAttributionWindow,
          defaultPaidSearchAttributionWindow,
          defaultSEOAttributionWindow,
          currency:
            currency && currency.hasOwnProperty("currency")
              ? currency.currency
              : "USD",
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.updateOrg,
          logo,
        };
      }
      return response;
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const createLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const createLocationWithGeotarget = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      geoId,
      locationKey,
      audienceType,
      province,
      createdBy,
    } = data;
    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocationWithGeotarget({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          geoId,
          locationKey,
          audienceType,
          province,
          createdBy,
        },
        refetchQueries: [
          {
            query: GET_LOCATIONS,
            variables: { id: loggedInOrg.id },
          },
        ],
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const [deleteLocation] = useMutation(DELETE_LOCATION_ADMIN, {
    variables: {
      Id: props.Id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [deleteManyLocations] = useMutation(DELETE_MANY_LOCATIONS, {
    variables: {
      ids: props.ids,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [bulkUploadLocations] = useMutation(BULK_UPLOAD_LOCATIONS, {
    variables: {
      data: props.data,
    },
  });

  const updateLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      circles,
      polygons,
      start,
      end,
      isBase,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationUpdateInput!
      const response = await props.updateLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          country,
          lat,
          lng,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          id,
          circles,
          polygons,
          start,
          end,
          isBase,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.updateLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const [deleteAudienceLocation] = useMutation(DELETE_AUDIENCE_LOCATION, {
    variables: {
      Id: props.Id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const { data: cartProvider } = useQuery(CART_PROVIDER);

  const {
    data: orgMedias,
    loading: orgMediasLoading,
    refetch: refetchMedias,
  } = useQuery(GET_ORG_MEDIAS, {
    variables: { orgId: loggedInOrg.id, type: "STILL" },
  });

  const { data: orgVideoMedias, refetch: refetchVideoMedias } = useQuery(
    GET_ORG_MEDIAS,
    {
      variables: { orgId: loggedInOrg.id, type: "VIDEO" },
    }
  );

  const { data: audiencesLocation } = useQuery(AUDIENCES_LOCATION, {
    variables: { name: loggedInOrg.name + " - Retail Locations" },
  });

  const { data: regionGroups } = useQuery(GET_REGION_GROUPS, {
    variables: { orgs: { id: loggedInOrg.id } },
  });

  const { data: iABCategoryGroups } = useQuery(GET_IAB_CATEGORY_GROUPS, {
    variables: { orgs: { id: loggedInOrg.id } },
  });

  const validateOrgMedias = orgMedias && orgMedias.medias;

  const [createOrgMedia] = useMutation(CREATE_ORG_MEDIA, {
    variables: {
      url: props.url,
      mediatype: props.mediatype,
      key: props.key,
      width: props.width,
      height: props.height,
      size: props.size,
      name: props.name,
      fileType: props.fileType,
      orgId: loggedInOrg.id,
    },
  });

  const [updateOrgMedia] = useMutation(EDIT_ORG_MEDIA, {
    variables: {
      creativeID: props.creativeID,
      newUrl: props.newUrl,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id, type: "STILL" },
      },
      { query: GET_ADGROUP_CREATIVES, variables: { orgId: loggedInOrg.id } },
    ],
  });

  const [updateMediaFlag] = useMutation(EDIT_MEDIA_FLAG, {
    variables: {
      id: props.id,
      flag: props.flag,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
      {
        query: GET_ADGROUP_CREATIVES,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const [deleteMedia] = useMutation(DELETE_MEDIA, {
    variables: {
      id: props.id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
      {
        query: GET_ADGROUP_CREATIVES,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const [createGeoTargetLocation] = useMutation(CREATE_GEOTARGET_LOCATION, {
    variables: {
      name: props.name,
      orgID: props.orgID,
      isBase: props.isBase,
      circles: props.circles,
      polygons: props.polygons,
      start: props.start,
      end: props.end,
    },
    // awaitRefetchQueries: true,
    // refetchQueries: [
    //   {
    //     query: AUDIENCES_LOCATION,
    //     variables: { name: loggedInOrg.name + " - Retail Locations" },
    //   },
    // ],
  });

  const [createAudienceLocation] = useMutation(CREATE_AUDIENCE_LOCATION, {
    variables: {
      name: props.name,
      type: props.type,
      geoTargets: props.geoTargets,
      orgid: props.orgid,
    },
    // awaitRefetchQueries: true,
    // refetchQueries: [
    //   {
    //     query: AUDIENCES_LOCATION,
    //     variables: { name: loggedInOrg.name + " - Retail Locations" },
    //   },
    // ],
  });

  const [createSelectedAudienceLocation] = useMutation(
    CREATE_AUDIENCE_LOCATION,
    {
      variables: {
        name: props.name,
        type: props.type,
        geoTargets: props.geoTargets,
        orgid: props.orgid,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
        },
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "ATTRIBUTION", ORGID: loggedInOrg.id },
        },
      ],
    }
  );

  const [updateAudienceLocation] = useMutation(UPDATE_AUDIENCE_LOCATION, {
    variables: {
      id: props.id,
      geoTargets: props.geoTargets,
    },
  });

  const { data: adGroupCreatives } = useQuery(GET_ADGROUP_CREATIVES, {
    variables: {
      orgId: loggedInOrg.id,
    },
  });

  const validateAdGroupCreatives =
    adGroupCreatives && adGroupCreatives.adGroupCreativeses;

  const [createAdGroupCreatives] = useMutation(CREATE_ADGROUP_CREATIVES, {
    variables: {
      name: props.name,
      medias: props.medias,
      org: props.org,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const [deleteAdGroupCreatives] = useMutation(DELETE_ADGROUP_CREATIVES, {
    variables: {
      id: props.name,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const [updateSegment] = useMutation(UPDATE_LOCATION_SEGMENT, {
    variables: {
      id: props.id,
      name: props.name,
      circles: props.circles,
      polygons: props.polygons,
      start: props.start,
      end: props.end,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LOCATIONS,
        variables: { id: loggedInOrg.id },
      },
    ],
  });

  // TODO: Enhance payload handling for this mutation
  // @params: data: LocationUpdateInput!
  const [updateLocationWithGeotargets] = useMutation(
    EDIT_LOCATION_ADMIN_WITH_GEOTARGETS,
    {
      variables: {
        name: props.name,
        description: props.description,
        street: props.street,
        city: props.city,
        state: props.state,
        country: props.country,
        email: props.email,
        url: props.url,
        phone: props.phone,
        contact: props.contact,
        formattedAddress: props.formattedAddress,
        zip: props.zip,
        id: props.id,
        cart: props.cart,
        menuDataKey: props.menuDataKey,
        geoId: props.geoId,
        locationKey: props.locationKey,
        audienceType: props.audienceType,
        province: props.province,
        updatedBy: props.updatedBy,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_LOCATIONS,
          variables: { id: loggedInOrg.id },
        },
      ],
    }
  );

  const [getGeotargetsAudiences] = useLazyQuery(AUDIENCES_LOCATION);

  const {
    data: presetRegionGroups,
    error: presetRegionGroupsError,
    loading: presetRegionGroupsLoading,
  } = useQuery(GET_REGION_GROUPS_WITH_PRESET_LIST, {
    variables: { orgId: loggedInOrg.id },
  });

  const [createRegionGroup] = useMutation(CREATE_CITY_LIST, {
    variables: {
      name: props.name,
      regions: props.regions,
      orgs: props.org,
      isPresetList: props.isPresetList,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [updateRegionGroup] = useMutation(UPDATE_REGION_GROUP, {
    variables: {
      id: props.id,
      name: props.name,
      regions: props.regions,
      isPresetList: props.isPresetList,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [deleteRegionGroup] = useMutation(DELETE_REGION_GROUP, {
    variables: {
      id: props.Id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_REGION_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [createIABCategoryGroup] = useMutation(CREATE_IAB_CATEGORY_GROUP, {
    variables: {
      name: props.name,
      iabCategories: props.iabCategories,
      orgs: props.org,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_IAB_CATEGORY_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [deleteIABCategoryGroup] = useMutation(DELETE_IAB_CATEGORY, {
    variables: {
      id: props.Id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_IAB_CATEGORY_GROUPS,
        variables: { orgs: { id: loggedInOrg.id } },
      },
    ],
  });

  const [createCampaignOrder] = useMutation(CREATE_CAMPAIGN_ORDER_OLD);

  const [updateCampaignOrderWithGeoLocations] = useMutation(
    UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS
  );

  const [updateCampaignOrderAmplify] = useMutation(
    UPDATE_CAMPAIGN_ORDER_AMPLIFY,
    {
      refetchQueries: [
        {
          query: CAMPAIGN_ORDER_AMPLIFY,
          variables: { orgId: props.currentUser.role.org.id, isAmplify: true },
        },
      ],
    }
  );

  const [updateAdGroupCreatives] = useMutation(UPDATE_AD_GROUP_CREATIVES, {
    variables: { id: props.id, data: props.data },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const [updateManyAdGroupCreatives] = useMutation(
    UPDATE_MANY_AD_GROUP_CREATIVES,
    {
      variables: {
        orgId: loggedInOrg.id,
        isAmplifyDefault: props.isAmplifyDefault,
      },
    }
  );

  const [updateAudience] = useMutation(UPDATE_AUDIENCE, {
    variables: {
      id: props.id,
      name: props.name,
      description: props.description,
      type: props.type,
      isAmplifyDefault: props.isAmplifyDefault,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [updateManyAudiences] = useMutation(UPDATE_MANY_AUDIENCES, {
    variables: {
      orgId: props.orgId,
      isAmplifyDefault: props.isAmplifyDefault,
    },
  });

  const [activateHashedEmailList] = useMutation(ACTIVATE_HASHED_EMAIL_LIST, {
    variables: {
      emailListId: props.emailListId,
      emails: props.emails,
      orgID: loggedInOrg.id,
      orgName: loggedInOrg.name,
    },
  });

  const [processDeviceIds] = useMutation(PROCESS_DEVICE_IDS, {
    variables: {
      emailListId: props.emailListId,
    },
  });

  const [createHashedEmailAddress] = useMutation(
    BULK_CREATE_HASHED_EMAIL_ADDRESS,
    {
      variables: {
        emailListId: props.emailListId,
        emails: props.emails,
        orgID: loggedInOrg.id,
      },
    }
  );

  const [createHashedEmailList] = useMutation(CREATE_HASHED_EMAIL_LIST, {
    variables: {
      name: props.name,
      hashedEmails: props.hashedEmailIds,
      orgID: loggedInOrg.id,
    },
  });

  const [updateHashedEmailList] = useMutation(UPDATE_HASHED_EMAIL_LIST, {
    variables: {
      id: props.id,
      activated: props.activated,
    },
  });

  const [deleteHashedEmailAddress] = useMutation(DELETE_HASHED_EMAIL_ADDRESS, {
    variables: {
      id: props.id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_HASHED_EMAILS,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const [deleteManyHashedEmailAddresses] = useMutation(
    DELETE_MANY_HASHED_EMAIL_ADDRESS,
    {
      variables: {
        ids: props.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_HASHED_EMAIL_LIST_WITH_COUNT,
          variables: { orgId: loggedInOrg.id },
        },
      ],
    }
  );

  const [deleteHashedEmailList] = useMutation(DELETE_HASHED_EMAIL_LIST, {
    variables: {
      id: props.id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_HASHED_EMAIL_LIST_WITH_COUNT,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const [createManyOrgMedia] = useMutation(CREATE_MANY_ORG_MEDIA, {
    variables: {
      id: props.id,
      data: props.data,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const { refetch: getAdGroupsByMedia } = useQuery(
    GET_ADGROUP_CREATIVES_BY_MEDIA,
    {
      variables: { media: props.media },
    }
  );

  const {
    data: getHashedEmailListWithCount,
    loading: loadingHashedEmailList,
    refetch: refetchHashedEmailLists,
  } = useQuery(GET_HASHED_EMAIL_LIST_WITH_COUNT, {
    variables: { orgId: loggedInOrg.id },
  });

  const [updateManyMedias] = useMutation(UPDATE_MANY_MEDIAS, {
    variables: {
      ids: props.ids,
      data: props.data,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
      {
        query: GET_ADGROUP_CREATIVES,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  if (loading) return <OverlaySpinner />;

  return (
    <Controller
      campaignOrders={campaignOrders}
      options={options}
      currentuser={props.currentUser}
      org={data}
      loggedInOrg={loggedInOrg}
      locationList={locationData}
      loadingLocation={loadingLocation}
      editOrg={editOrg}
      createLocation={createLocation}
      createLocationWithGeotarget={createLocationWithGeotarget}
      deleteLocation={deleteLocation}
      updateLocation={updateLocation}
      cartProvider={cartProvider}
      orgMedias={validateOrgMedias}
      adGroupCreatives={validateAdGroupCreatives}
      createAdGroupCreatives={createAdGroupCreatives}
      createOrgMedia={createOrgMedia}
      updateOrgMedia={updateOrgMedia}
      updateMediaFlag={updateMediaFlag}
      orgMediasLoading={orgMediasLoading}
      deleteMedia={deleteMedia}
      createGeoTargetLocation={createGeoTargetLocation}
      createAudienceLocation={createAudienceLocation}
      audiencesLocation={audiencesLocation}
      getGeotargetsAudiences={getGeotargetsAudiences}
      updateAudienceLocation={updateAudienceLocation}
      orgSendEmailConfig={orgSendEmailConfig}
      updateOrgSendEmailConfig={updateOrgSendEmailConfig}
      createSelectedAudienceLocation={createSelectedAudienceLocation}
      deleteAdGroupCreatives={deleteAdGroupCreatives}
      updateSegment={updateSegment}
      updateLocationWithGeotargets={updateLocationWithGeotargets}
      client={client}
      selectedTab={selectedTab}
      targetingAudiences={targetingAudiences}
      loadingTargetingAudiences={loadingTargetingAudiences}
      attributionAudiences={attributionAudiences}
      loadingAttributionAudiences={loadingAttributionAudiences}
      deleteAudienceLocation={deleteAudienceLocation}
      createRegionGroup={createRegionGroup}
      deleteRegionGroup={deleteRegionGroup}
      updateRegionGroup={updateRegionGroup}
      regionGroups={regionGroups}
      createIABCategoryGroup={createIABCategoryGroup}
      iABCategoryGroups={iABCategoryGroups}
      deleteIABCategoryGroup={deleteIABCategoryGroup}
      createCampaignOrder={createCampaignOrder}
      updateCampaignOrderWithGeoLocations={updateCampaignOrderWithGeoLocations}
      updateAdGroupCreatives={updateAdGroupCreatives}
      updateManyAdGroupCreatives={updateManyAdGroupCreatives}
      updateAudience={updateAudience}
      updateManyAudiences={updateManyAudiences}
      amplifyCampaigns={amplifyCampaigns}
      updateCampaignOrderAmplify={updateCampaignOrderAmplify}
      activateHashedEmailList={activateHashedEmailList}
      createHashedEmailAddress={createHashedEmailAddress}
      createHashedEmailList={createHashedEmailList}
      updateHashedEmailList={updateHashedEmailList}
      hashedEmailLists={getHashedEmailListWithCount}
      deleteHashedEmailAddress={deleteHashedEmailAddress}
      deleteManyHashedEmailAddresses={deleteManyHashedEmailAddresses}
      deleteHashedEmailList={deleteHashedEmailList}
      refetchHashedEmailLists={refetchHashedEmailLists}
      loadingHashedEmailList={loadingHashedEmailList}
      getAdGroupsByMedia={getAdGroupsByMedia}
      deleteManyLocations={deleteManyLocations}
      createManyOrgMedia={createManyOrgMedia}
      bulkUploadLocations={bulkUploadLocations}
      updateManyMedias={updateManyMedias}
      orgDealIds={orgDealIds}
      createOrgDealId={createOrgDealId}
      deleteOrgDealIds={deleteOrgDealIds}
      updateDealId={updateDealId}
      orgId={loggedInOrg.id}
      presetRegionGroups={presetRegionGroups}
      presetRegionGroupsError={presetRegionGroupsError}
      presetRegionGroupsLoading={presetRegionGroupsLoading}
      orgVideoMedias={orgVideoMedias}
      refetchVideoMedias={refetchVideoMedias}
      refetchMedias={refetchMedias}
      processDeviceIds={processDeviceIds}
    />
  );
};

export default compose(
  graphql(UPDATE_USER, { name: "updateUser" }),
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(EDIT_ORG_SELF, { name: "updateOrgBasic" }),
  graphql(EDIT_ORG_DATA_CONFIG, { name: "updateOrgDataConfig" }),
  graphql(CREATE_RETAIL_LOCATION, { name: "createLocation" }),
  graphql(CREATE_LOCATION_WITH_GEOTARGET, {
    name: "createLocationWithGeotarget",
  }),
  graphql(DELETE_LOCATION_ADMIN, { name: "deleteLocation" }),
  graphql(EDIT_LOCATION_ADMIN, { name: "updateLocation" }),
  graphql(UPDATE_ORG_SEND_EMAIL_CONFIG, { name: "updateOrgSendEmailConfig" }),
  graphql(DELETE_AUDIENCE_LOCATION, { name: "deleteAudienceLocation" }),
  graphql(CREATE_CITY_LIST, { name: "createRegionGroup" }),
  graphql(GET_REGION_GROUPS, { name: "regionGroups" }),
  graphql(DELETE_REGION_GROUP, { name: "deleteRegionGroup" }),
  graphql(CREATE_IAB_CATEGORY_GROUP, { name: "createIABCategoryGroup" }),
  graphql(GET_IAB_CATEGORY_GROUPS, { name: "iabCategories" }),
  graphql(DELETE_IAB_CATEGORY, { name: "deleteIABCategoryGroup" }),
  graphql(UPDATE_AD_GROUP_CREATIVES, { name: "updateAdGroupCreatives" }),
  graphql(UPDATE_AUDIENCE, { name: "updateAudience" }),
  graphql(UPDATE_MANY_AUDIENCES, { name: "updateManyAudiences" }),
  graphql(UPDATE_MANY_AD_GROUP_CREATIVES, {
    name: "updateManyAdGroupCreatives",
  })
)(withApollo(Loader));
