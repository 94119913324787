import gql from "graphql-tag";

const EDIT_EDITORIAL_SHARING = gql`
  mutation updateEditorial(
    $id: ID!
    $sharedOrgs: EditorialOrgsUpdateManyWithoutEditorialInput
  ) {
    updateEditorial(data: { sharedOrgs: $sharedOrgs }, where: { id: $id }) {
      id
    }
  }
`;

export default EDIT_EDITORIAL_SHARING;
