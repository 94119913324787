import gql from "graphql-tag";

const LIST_ORGS_SHARING = gql`
  query listOrgsSharing($parentid: ID!) {
    orgs(
      where: { OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }] }
      first: 1000
      orderBy: name_ASC
    ) {
      id
      name
      level
      tagDomain
      parentOrg {
        id
        name
        level
        tagDomain
      }
    }
  }
`;

export default LIST_ORGS_SHARING;
