import gql from "graphql-tag";

const DELETE_CAMPAIGN_ORDER = gql`
  mutation deleteCampaignOrder($campaignOrderId: ID!) {
    deleteCampaignOrder(where: { id: $campaignOrderId }) {
      id
      name
    }
  }
`;

export default DELETE_CAMPAIGN_ORDER;
