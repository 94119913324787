import React from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import moment from "moment";

import "./styles.css";
import { formatCurrency2SigFig } from "../../../core/utils/campaigns";
import { CampaignRequestorTargetingRegionTable } from "./CampaignRequestorTargetingRegionTable";
import { CampaignRequestorTargetingLocalTable } from "./CampaignRequestorTargetingLocalTable";
import { CampaignRequestorCountriesTable } from "./CampaignRequestorCountriesTable";
import { CampaignRequestorAdGroup } from "./CampaignRequestorAdGroup";
import { CampaignRequestorAudienceTable } from "./CampaignRequestorAudienceTable";
import { audienceTypeMap } from "../../../core/utils/constants/constants";

const { Text } = Typography;

const CampaignRequestorReviewLineItem = ({
  form,
  restField,
  name,
  index,
  fieldKey,
}) => {
  const campaign = form.getFieldValue("campaigns")[index];
  const targetingType = form.getFieldValue("campaigns")[index].targetingType;
  const audienceType = form.getFieldValue("campaigns")[index].audienceType;
  const isRegulated = form.getFieldValue("isRegulated");

  return (
    <Card title={`LINE ITEM ${index + 1}`}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Line Item Name</Text>
              <Text>{campaign.name}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Start Date</Text>
              <Text>{moment(campaign.startDate).format("MM/DD/YYYY")}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">End Date</Text>
              <Text>{moment(campaign.endDate).format("MM/DD/YYYY")}</Text>
            </Space>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Line Item Budget</Text>
              <Text>{formatCurrency2SigFig(campaign.budgetTotal)}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">CPM</Text>
              <Text>{campaign.cpm}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Impressions</Text>
              <Text>{(campaign.impressions || 0).toLocaleString()}</Text>
            </Space>
          </Col>
        </Row>
        {targetingType === "REGION" && (
          <CampaignRequestorTargetingRegionTable
            name={name}
            restField={restField}
            fieldKey={fieldKey}
            form={form}
            index={index}
          />
        )}
        {targetingType === "LOCAL" && (
          <CampaignRequestorTargetingLocalTable
            name={name}
            restField={restField}
            fieldKey={fieldKey}
            form={form}
            index={index}
          />
        )}
        {targetingType === "COUNTRY" && (
          <CampaignRequestorCountriesTable
            form={form}
            restField={restField}
            name={name}
            fieldKey={fieldKey}
            index={index}
          />
        )}

        <Row gutter={20}>
          <Col span={12}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Audience Type</Text>
              <Text>
                {audienceTypeMap[audienceType]} {isRegulated ? "aged 21+" : ""}
              </Text>
            </Space>
          </Col>
        </Row>

        <CampaignRequestorAudienceTable
          name={name}
          restField={restField}
          fieldKey={fieldKey}
          form={form}
          index={index}
        />

        <CampaignRequestorAdGroup
          form={form}
          index={index}
          fieldKey={fieldKey}
          restField={restField}
          name={name}
        />
      </Space>
    </Card>
  );
};

export default CampaignRequestorReviewLineItem;
