import React from "react";
import { Switch, Route } from "react-router-dom";
import DisplayPerformanceView from "../DisplayPerformanceView";
import DisplaySummaryView from "../DisplaySummaryView";
import HomeLoader from "../../../../core/components/home/Loader";
import DisplayChartsDataLoader from "../../../../core/components/home/Display/DisplayChartsDataLoader";
import MainDataLoader from "../../../../core/components/home/RollUp/MainDataLoader";
import RollUpMainView from "../RollUp/RollUpMainView";
import SEOAnalyticsLoader from "../../../../core/components/analytics/seo/SEOAnalyticsLoader";
import SEOAnalyticsView from "../Analytics/SEO/SEOAnalyticsView";
import PaidSearchAnalyticsLoader from "../../../../core/components/analytics/paid-search/PaidSearchAnalyticsLoader";
import PaidSearchAnalyticsView from "../Analytics/PaidSearch/PaidSearchAnalyticsView";

// Feature level subroutes
const HomeRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={() => (
            <HomeLoader>
              <MainDataLoader>
                <RollUpMainView />
              </MainDataLoader>
            </HomeLoader>
          )}
        />
        <Route
          path={`${path}/analytics/display/summary`}
          render={() => (
            <HomeLoader>
              <DisplayChartsDataLoader showAnalytics={false}>
                <DisplaySummaryView />
              </DisplayChartsDataLoader>
            </HomeLoader>
          )}
        />
        <Route
          path={`${path}/analytics/display`}
          index={false}
          render={() => (
            <HomeLoader>
              <DisplayChartsDataLoader showAnalytics={true}>
                <DisplayPerformanceView />
              </DisplayChartsDataLoader>
            </HomeLoader>
          )}
        />
        <Route
          path={`${path}/analytics/paid-search`}
          render={() => (
            <HomeLoader>
              <MainDataLoader>
                <PaidSearchAnalyticsLoader>
                  <PaidSearchAnalyticsView />
                </PaidSearchAnalyticsLoader>
              </MainDataLoader>
            </HomeLoader>
          )}
        />
        <Route
          path={`${path}/analytics/seo`}
          render={() => (
            <HomeLoader>
              <MainDataLoader>
                <SEOAnalyticsLoader>
                  <SEOAnalyticsView />
                </SEOAnalyticsLoader>
              </MainDataLoader>
            </HomeLoader>
          )}
        />
      </Switch>
    </div>
  );
};

export default HomeRoutes;
