import React from "react";

const SeoDetailsController = ({
  seoSummary,
  userPermission,
  seoLoading,
  seoName,
  keywordsReport,
  trafficReport,
  conversionsReport,
  ...props
}) => {
  const { key, commerceKey, org } = seoSummary;
  const { isDemo, exportData } = userPermission;
  const hasGoogle = key ? true : false;
  const hasCommerce = commerceKey ? true : false;
  const isMultiChannel = hasGoogle && hasCommerce ? true : false;
  const domain = org.domain;

  const name = isDemo ? "SEO Example" : seoName;
  return React.cloneElement(props.children, {
    ...props.children.props,
    name,
    seoLoading,
    exportData,
    domain,
    keywordsReportKey:
      keywordsReport.reports.length > 0 ? keywordsReport.reports[0].key : null,
    trafficReportKey:
      trafficReport.reports.length > 0 ? trafficReport.reports[0].key : null,
    conversionsReportKey:
      conversionsReport.reports.length > 0
        ? conversionsReport.reports[0].key
        : null,
    linkAcquisitionData: seoSummary ? seoSummary.linkAcquisition : null,
    //Legacy Props
    googleReportKey: key,
    commerceKey,
    isMultiChannel,
    hasGoogle,
    hasCommerce,
  });
};

export default SeoDetailsController;
