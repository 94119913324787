import React, { useState, useEffect } from "react";
import { Row, Col, Empty, Card } from "antd";
import DetailBarDynamicCol from "./DetailBarDynamicCol";
import SummaryChart from "./charts/SummaryChart";
import { isEmpty, cloneDeep } from "lodash";
import Top from "./top";
import moment from "moment";
import { cleanFormatByDateReport } from "../../../../../Home/utils";
import { detailBarTotalCompute, processPercentage } from "../../../utils";
import {
  signUpsResponseData,
  groupByKeys,
} from "../../signups/components/utils";
import { orgLevelCustomization } from "../../../../../../../core/permissions/orgLevelCustomization";

const SummaryDetailBar = ({
  colStyle,
  chartData,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  dataSummaryRangeDate,
  setDataSummaryRangeDate,
  showTransactions,
  showAttribution,
  signUpCampaign,
  isAdmin,
  orgConfig,
  campaignId,
  campaignType,
  attributionReportId,
  campaignOrder,
  exportData,
  checkStatus,
  hasInsights,
  chartsLoading,
  currentUser,
  setRangeType,
  rangeType,
  selectedCampaign,
  priceVisible,
  dataConfig,
}) => {
  const {
    completeAttributionSummary,
    publishers,
    creatives,
    signUpsWithImpressions,
  } = chartData;
  const [summaryData, setSummaryData] = useState([]);
  const [summaryDataLoading, setSummaryDataLoading] = useState(false);
  const [signUpsData, setSignUpsData] = useState([]);
  const [totals, setTotals] = useState({
    impressions: 0,
    clicks: 0,
    walkIns: 0,
    observations: 0,
    transactions: 0,
    transactionTotalRevenue: 0,
    signUpsWithImpressions: 0,
  });

  const [kpiPercentage, setKpiPercentage] = useState({
    percentages: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      walkIns: 0,
      observations: 0,
      revenue: 0,
      signUps: 0,
      roas: 0,
      spend: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      walkIns: 0,
      observations: 0,
      revenue: 0,
      signUps: 0,
      roas: 0,
      spend: 0,
    },
    ranges: "",
  });

  const orgCustomization = orgLevelCustomization(dataConfig);
  const { currencyFormat, roasFormat } = orgCustomization;

  useEffect(() => {
    if (chartData && completeAttributionSummary) {
      setSummaryDataLoading(true);
      setSummaryData(cleanFormatByDateReport(completeAttributionSummary));
      setSummaryDataLoading(false);

      // setSortedPublishers(
      //   publishers
      //     .filter(publisher => publisher.xAxis !== "[tail aggregate]")
      //     .sort((a, b) => b.impressions - a.impressions)
      // );

      // setSortedCreatives(
      //   creatives.sort((a, b) => b.impressions - a.impressions)
      // );
    }
    if (chartData && !isEmpty(signUpsWithImpressions)) {
      setSignUpsData(
        signUpsResponseData(
          groupByKeys(
            signUpsWithImpressions.map(signUp => {
              return {
                ...signUp,
                dateChart: moment(
                  moment(signUp.signups_tstamp).format("YYYY-MM-DD")
                ).format(),
              };
            }),
            ["dateChart"]
          )[0]
        ).sort((a, b) => {
          if (a.xAxis === b.xAxis) return 0;
          if (moment(a.xAxis).isAfter(b.xAxis)) return 1;
          return -1;
        })
      );
    }
  }, [
    chartData,
    completeAttributionSummary,
    creatives,
    publishers,
    signUpsWithImpressions,
  ]);

  useEffect(() => {
    setTotals(
      detailBarTotalCompute(dataSummaryRangeDate, bySummaryRangeDate, chartData)
    );

    const current = chartData && chartData.completeAttributionSummary;

    const dates = [
      bySummaryRangeDate[0] ||
        moment(!isEmpty(current) ? current[0].utcISODate : null),
      bySummaryRangeDate[1] ||
        moment(!isEmpty(current) ? current.at(-1).utcISODate : null),
    ];

    setKpiPercentage(
      processPercentage(
        dates,
        cloneDeep(dataSummaryRangeDate),
        cloneDeep(current),
        selectedCampaign ? selectedCampaign.cpm : 0
      )
    );
  }, [bySummaryRangeDate, chartData, dataSummaryRangeDate, selectedCampaign]);

  //Filter Data based on selected date picker date
  if (!isEmpty(bySummaryRangeDate)) {
    dataSummaryRangeDate = dataSummaryRangeDate.filter(d => {
      return (
        moment(d.xAxis, "YYYY-MM-DD") >= bySummaryRangeDate[0] &&
        moment(d.xAxis, "YYYY-MM-DD") <= bySummaryRangeDate[1]
      );
    });
  }

  return (
    <>
      {/* <div style={{ position: "relative" }}>
        <div style={styles.container}>
          <div>
            <DropdownCSVButton
              orgConfig={orgConfig}
              campaignName={campaignOrder.name}
              campaignType={campaignType}
              campaignId={campaignId}
              chartsLoading={chartsLoading}
              chartData={chartData}
              attributionReportId={attributionReportId}
              campaigns={campaignOrder.campaigns}
              isAdmin={isAdmin}
              hasInsights={hasInsights}
              bySummaryRangeDate={bySummaryRangeDate}
            />
          </div>
        </div>
      </div> */}
      {summaryDataLoading === true ? (
        <></>
      ) : isEmpty(summaryData) ? (
        <Card>
          <Empty
            description="Please refresh your browser to receive the latest updates."
            style={{ marginTop: 16 }}
          />
        </Card>
      ) : (
        <>
          <Row gutter={35}>
            <Col xs={24}>
              <DetailBarDynamicCol
                showTransactions={showTransactions}
                showAttribution={showAttribution}
                colStyle={colStyle}
                signUpCampaign={signUpCampaign}
                clicks={totals.clicks}
                impressions={totals.impressions}
                totalWalkIns={totals.walkIns}
                transactionTotalRevenue={totals.transactionTotalRevenue}
                transactions={totals.transactions}
                signUpsData={signUpsData}
                signUps={totals.signUps}
                summaryData={summaryData}
                bySummaryRangeDate={bySummaryRangeDate}
                budget={campaignOrder.budget}
                selectedCampaign={selectedCampaign}
                priceVisible={priceVisible}
                kpiPercentage={kpiPercentage}
                dataConfig={dataConfig}
                orgCurrencyFormat={currencyFormat}
                orgRoasFormat={roasFormat}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              {isEmpty(summaryData) ? null : (
                <SummaryChart
                  byDates={isEmpty(summaryData) ? [] : summaryData}
                  showTransactions={showTransactions}
                  showAttribution={showAttribution}
                  bySummaryRangeDate={bySummaryRangeDate}
                  setBySummaryRangeDate={setBySummaryRangeDate}
                  dataSummaryRangeDate={dataSummaryRangeDate}
                  setDataSummaryRangeDate={setDataSummaryRangeDate}
                  reportData={summaryData}
                  signUpsData={signUpsData}
                  chartsBuilt={chartData.chartsBuilt}
                  setRangeType={setRangeType}
                  rangeType={rangeType}
                  currencyFormat={currencyFormat}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[18, 18]}>
            {campaignOrder.aggregateData ? (
              <>
                {campaignOrder.aggregateData.topPublishers &&
                  campaignOrder.aggregateData.topPublishers.length > 0 && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Top
                        imageIndex="imageUrl"
                        title="Publishers"
                        currentUser={currentUser}
                        data={
                          campaignOrder.aggregateData.topPublishers.filter(
                            i =>
                              i.extra !== null && i.xAxis !== "[tail aggregate]"
                          ) || []
                        }
                      />
                    </Col>
                  )}
                {campaignOrder.aggregateData.topCreatives &&
                  campaignOrder.aggregateData.topCreatives.length > 0 && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Top
                        imageIndex="creativeUrl"
                        title="Creatives"
                        currentUser={currentUser}
                        data={campaignOrder.aggregateData.topCreatives || []}
                      />
                    </Col>
                  )}
              </>
            ) : (
              <>
                <Col xs={12}>
                  <Top imageIndex="imageUrl" title="Publishers" data={[]} />
                </Col>
                <Col xs={12}>
                  <Top imageIndex="creativeUrl" title="Creatives" data={[]} />
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default SummaryDetailBar;
