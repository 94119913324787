import gql from "graphql-tag";

const CREATE_LINE_ITEM_CAMPAIGN = gql`
  mutation createCampaignLineItem(
    $name: String
    $adProviderId: String
    $clientOrg: String
    $orgId: ID
    $campaignOrderId: ID
    $startDate: DateTime
    $endDate: DateTime
    $cpm: Float
    $budgetTotal: Float
  ) {
    createCampaign(
      data: {
        name: $name
        adProviderId: $adProviderId
        clientOrg: $clientOrg
        orgs: { connect: { id: $orgId } }
        campaignOrder: { connect: { id: $campaignOrderId } }
        startDate: $startDate
        endDate: $endDate
        cpm: $cpm
        budgetTotal: $budgetTotal
      }
    ) {
      id
      name
      clientOrg
      startDate
      endDate
      cpm
      budgetTotal
    }
  }
`;

export default CREATE_LINE_ITEM_CAMPAIGN;
