import React from "react";
import { Row, Col } from "antd";
import CampaignsTable from "./components/CampaignsTable";
import FiltersBar from "./components/FiltersBar";

const CampaignsTab = ({
  data,
  possibleFields,
  history,
  selectedTableColumns,
  orgId,
  pageSize,
  total,
  current,
  summaryRangeDates,
  defaultRangeDates,
  currencyFormat,
  roasFormat,
  id,
}) => {
  return (
    <>
      <Row>
        <Col xs={24}>
          <FiltersBar orgId={orgId} id={id} />
          <CampaignsTable
            data={data}
            possibleFields={possibleFields}
            history={history}
            selectedTableColumns={selectedTableColumns}
            orgId={orgId}
            id={id}
            pageSize={pageSize}
            current={current}
            total={total}
            summaryRangeDates={summaryRangeDates}
            defaultRangeDates={defaultRangeDates}
            currencyFormat={currencyFormat}
          />
        </Col>
      </Row>
    </>
  );
};

export default CampaignsTab;
