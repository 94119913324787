import gql from "graphql-tag";

const UPDATE_ORG_TABLE_CONFIG = gql`
  mutation updateOrgTable(
    $id: ID!
    $isShowDaysRemaining: Boolean
    $isShowStartDate: Boolean
    $isShowEndDate: Boolean
    $isShowImpressions: Boolean
    $isShowWalkins: Boolean
    $isShowPerformance: Boolean
    $isShowPacing: Boolean
    $isShowSales: Boolean
    $isShowSignUps: Boolean
    $isShowBudget: Boolean
    $isShowAttribution: Boolean
  ) {
    updateOrgTable(
      where: { id: $id }
      data: {
        isShowDaysRemaining: $isShowDaysRemaining
        isShowStartDate: $isShowStartDate
        isShowEndDate: $isShowEndDate
        isShowImpressions: $isShowImpressions
        isShowWalkins: $isShowWalkins
        isShowPerformance: $isShowPerformance
        isShowPacing: $isShowPacing
        isShowSales: $isShowSales
        isShowSignUps: $isShowSignUps
        isShowBudget: $isShowBudget
        isShowAttribution: $isShowAttribution
      }
    ) {
      isShowDaysRemaining
      isShowStartDate
      isShowEndDate
      isShowImpressions
      isShowWalkins
      isShowPerformance
      isShowPacing
      isShowSales
      isShowSignUps
      isShowBudget
      isShowAttribution
    }
  }
`;

export default UPDATE_ORG_TABLE_CONFIG;
