import gql from "graphql-tag";
const PAID_SEARCH_COMMERCE = gql`
  query paidSearchCommerce(
    $orgId: ID!
    $whereTransactions: PaidSearchCommerceTransactionsWhereInput!
    $attributionWindow: Int
  ) {
    getPaidSearchCommerce(
      where: { id: $orgId }
      whereTransactions: $whereTransactions
      attributionWindow: $attributionWindow
    ) {
      commerce {
        id
        startDate
        endDate
        overallCommerce {
          id
          clicks
          customer
          revenue
          conversions
          byDate {
            clicks
            customer
            revenue
            conversions
            uniqueUsers {
              ipAddresses
              fingerprints
              userIds
              deviceFingerprint
            }
            byAttribution {
              cookies {
                clicks
                customer
                revenue
                conversions
              }
              fingerprint {
                clicks
                customer
                revenue
                conversions
              }
              deviceFingerprint {
                clicks
                customer
                revenue
                conversions
              }
              ipAddresses {
                clicks
                customer
                revenue
                conversions
              }
            }
            date
            xAxis
          }
        }
        org {
          id
          name
        }
      }
      excludedTransactions
    }
  }
`;

export default PAID_SEARCH_COMMERCE;
