import gql from "graphql-tag";

const LIST_ORGS_ADMIN_DROPDOWN = gql`
  query listOrgs {
    orgs(first: 1000) {
      id
      name
    }
  }
`;

export default LIST_ORGS_ADMIN_DROPDOWN;
