import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Space,
  Select,
  Tooltip,
  message,
  Popconfirm,
} from "antd";
import { CopyOutlined, EyeOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import SSSOUserConfigurationToggler from "./SSSOUserConfigurationToggler";

export const UserEmbeddableConfigModal = ({
  visible,
  setVisible,
  selectedOrg,
  searchCampaignOrder,
  campaignOrders,
  campaignOrderListLoading,
  selectedUser,
  updateUserEmbeddableConfig,
  handleGenerateSSOToken,
  searchAdvertiser,
  advertisers,
  advertiserListLoading,
  publishers,
  editorialListLoading,
  searchPublisher,
}) => {
  const [options, setOptions] = useState([]);
  const [token, setToken] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [allowedWidgets, setAllowedWidgets] = useState({
    transactionChart: false,
    transactionTable: false,
  });
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [embeddableType, setEmbeddableType] = useState("DEFAULT");
  const [isSearch, setIsSearch] = useState(false); // To prevent search on initial render
  const [types, setTypes] = useState([
    {
      value: "DEFAULT",
      label: "Default",
    },
  ]);

  let hostname = window.location.origin;

  const orgData = selectedUser.roles[0].org;

  const parentChannelPartner = orgData.parentOrg.find(
    org => org.level === "CHANNEL_PARTNER" && org.platformDomain !== null
  );

  if (orgData.level === "CHANNEL_PARTNER" && orgData.platformDomain !== null) {
    hostname = `https://${orgData.platformDomain}`;
  } else if (parentChannelPartner) {
    hostname = `https://${parentChannelPartner.platformDomain}`;
  }

  useEffect(() => {
    switch (embeddableType) {
      case "DEFAULT":
        setIsDisabledSubmit(!token);
        break;

      case "DISPLAY":
      case "ADVERTISER":
      case "PUBLISHER":
        setIsDisabledSubmit(!token || !selectedOption);
        break;

      case "WIDGET":
        setIsDisabledSubmit(
          !token ||
            !selectedOption ||
            !Object.values(allowedWidgets).some(value => value)
        );
        break;

      default:
        break;
    }
  }, [allowedWidgets, selectedOption, token, embeddableType]);

  useEffect(() => {
    if (embeddableType === "DEFAULT") {
      setAllowedWidgets({
        transactionChart: false,
        transactionTable: false,
      });
      setSelectedOption(null);
    }
  }, [embeddableType]);

  useEffect(() => {
    let newOptions = [];
    switch (embeddableType) {
      case "DISPLAY":
      case "WIDGET":
        if (campaignOrders && campaignOrders.length) {
          newOptions = campaignOrders.map(campaignOrder => {
            return {
              value: campaignOrder.id,
              label: campaignOrder.name,
            };
          });

          setOptions(newOptions);
        } else {
          if (isSearch) {
            message.error("No results found!");
            setIsSearch(false);
          }
        }

        break;

      case "ADVERTISER":
        if (advertisers && advertisers.length) {
          newOptions = advertisers.map(advertiser => {
            return {
              value: advertiser.id,
              label: advertiser.name,
            };
          });
          setOptions(newOptions);
        } else {
          if (isSearch) {
            message.error("No results found!");
            setIsSearch(false);
          }
        }
        break;

      case "PUBLISHER":
        if (publishers && publishers.length) {
          newOptions = publishers.map(publisher => {
            return {
              value: publisher.id,
              label: publisher.name,
            };
          });
          setOptions(newOptions);
        } else {
          if (isSearch) {
            message.error("No results found!");
            setIsSearch(false);
          }
        }
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignOrders, advertisers, publishers]);

  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.config &&
      selectedUser.config.embeddableConfig
    ) {
      const { embeddableConfig } = selectedUser.config;
      const { widgetConfig, type } = embeddableConfig;

      if (type) {
        setEmbeddableType(type);
      }
      setToken(embeddableConfig.ssoToken);

      switch (type) {
        case "WIDGET":
        case "DISPLAY":
          if (widgetConfig) {
            delete widgetConfig.__typename;
            setAllowedWidgets(widgetConfig);
          }
          if (embeddableConfig.campaignOrder) {
            setSelectedOption({
              value: embeddableConfig.campaignOrder.id,
              label: embeddableConfig.campaignOrder.name,
            });
          }
          break;

        case "ADVERTISER":
          if (embeddableConfig.advertiser) {
            setSelectedOption({
              value: embeddableConfig.advertiser.id,
              label: embeddableConfig.advertiser.name,
            });
          }
          break;

        case "PUBLISHER":
          if (embeddableConfig.publisher) {
            setSelectedOption({
              value: embeddableConfig.publisher.id,
              label: embeddableConfig.publisher.name,
            });
          }
          break;

        default:
          break;
      }
    }

    let updatedTypes = [...types];

    if (selectedUser && selectedUser.features) {
      const { features } = selectedUser;
      const hasCampaignfeature =
        features.CAMPAIGNS && features.CAMPAIGNS.length ? true : false;
      const hasEditorialFeature =
        features.EDITORIAL && features.EDITORIAL.length ? true : false;

      if (hasCampaignfeature) {
        updatedTypes.push({
          value: "WIDGET",
          label: "Transactions",
        });
      }

      if (hasEditorialFeature) {
        updatedTypes = [
          ...updatedTypes,
          {
            value: "ADVERTISER",
            label: "Advertiser",
          },
          {
            value: "PUBLISHER",
            label: "Publisher",
          },
        ];
      }
    }

    if (selectedUser && selectedUser.config) {
      const { config } = selectedUser;
      if (config.campaignSummary) {
        updatedTypes.push({
          value: "DISPLAY",
          label: "Display",
        });
      }
    }

    setTypes(updatedTypes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const handleOk = ({ isPreview }) => {
    handleUpdateUserEmbeddableConfig({ isPreview });
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleEmbeddableUserTypeChange = value => {
    setOptions([]);
    setSelectedOption(null);
    setEmbeddableType(value);
  };

  const handleSearch = debounce(async value => {
    setIsSearch(true);

    switch (embeddableType) {
      case "DISPLAY":
      case "WIDGET":
        searchCampaignOrder({
          searchName: value,
          orgId: selectedOrg.id,
        });

        break;

      case "ADVERTISER":
        searchAdvertiser({
          searchName: value,
          orgId: selectedOrg.id,
        });
        break;

      case "PUBLISHER":
        searchPublisher({
          searchName: value,
          orgId: selectedOrg.id,
        });
        break;

      default:
        break;
    }
  }, 500); // Debounce delay in milliseconds

  const handleCampaignChange = value => {
    const option = options.find(option => option.value === value);
    setSelectedOption(option);
  };

  const handleCopyToClipBoard = () => {
    let text = "";
    switch (embeddableType) {
      case "DEFAULT":
        text = `<iframe src="${hostname}/signin?token=${token}" title="dashboard" width="1000" height="700"></iframe>`;
        break;

      case "WIDGET":
        text = `<iframe src="${hostname}/signin?token=${token}?redirectUrl=/campaigns/details/${selectedOption.value}?tab=widgets" title="dashboard" width="1000" height="700"></iframe>`;
        break;

      case "DISPLAY":
        text = `<iframe src="${hostname}/signin?token=${token}?redirectUrl=/campaigns/details/${selectedOption.value}" title="dashboard" width="1000" height="700"></iframe>`;
        break;

      case "ADVERTISER":
        text = `<iframe src="${hostname}/signin?token=${token}?redirectUrl=/advertiser/details/${selectedOption.value}" title="dashboard" width="1000" height="700"></iframe>`;
        break;

      case "PUBLISHER":
        text = `<iframe src="${hostname}/signin?token=${token}?redirectUrl=/editorial/details/${selectedOption.value}/1" title="dashboard" width="1000" height="700"></iframe>`;
        break;

      default:
        break;
    }
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard");
  };

  const handleSaveAndPreview = () => {
    handleOk({ isPreview: true });
  };

  const handleConfirmDisable = async () => {
    try {
      setVisible(false);
      message.loading("Disabling user...", 0);
      const data = {
        embeddableConfig: {
          delete: true,
        },
      };

      await updateUserEmbeddableConfig({
        id: selectedUser.config.id,
        data,
      });
      message.destroy();
      message.success("User disabled successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to disable user!");
    }
  };

  const handleUpdateUserEmbeddableConfig = async ({ isPreview }) => {
    try {
      message.loading("Updating user embeddable config...", 0);
      const { embeddableConfig } = selectedUser.config;
      let data = null;

      switch (embeddableType) {
        case "DEFAULT":
          data = {
            embeddableConfig: {
              update: {
                type: embeddableType,
                ssoToken: token,

                widgetConfig: {
                  create: {
                    transactionChart: allowedWidgets.transactionChart,
                    transactionTable: allowedWidgets.transactionTable,
                  },
                },
              },
            },
          };

          if (embeddableConfig.campaignOrder) {
            data.embeddableConfig.update.campaignOrder = {
              disconnect: true,
            };
          }
          break;

        case "WIDGET":
          data = {
            embeddableConfig: {
              update: {
                type: embeddableType,
                ssoToken: token,
                campaignOrder: {
                  connect: {
                    id: selectedOption.value,
                  },
                },
                widgetConfig: {
                  create: {
                    transactionChart: allowedWidgets.transactionChart,
                    transactionTable: allowedWidgets.transactionTable,
                  },
                },
              },
            },
          };
          break;
        case "DISPLAY":
          data = {
            embeddableConfig: {
              update: {
                type: embeddableType,
                ssoToken: token,
                campaignOrder: {
                  connect: {
                    id: selectedOption.value,
                  },
                },
                widgetConfig: {
                  create: {
                    transactionChart: false,
                    transactionTable: false,
                  },
                },
              },
            },
          };
          break;

        case "ADVERTISER":
          data = {
            embeddableConfig: {
              update: {
                type: embeddableType,
                ssoToken: token,
                advertiser: {
                  connect: {
                    id: selectedOption.value,
                  },
                },
                widgetConfig: {
                  create: {
                    transactionChart: false,
                    transactionTable: false,
                  },
                },
              },
            },
          };

          if (embeddableConfig.campaignOrder) {
            data.embeddableConfig.update.campaignOrder = {
              disconnect: true,
            };
          }
          break;

        case "PUBLISHER":
          data = {
            embeddableConfig: {
              update: {
                type: embeddableType,
                ssoToken: token,
                publisher: {
                  connect: {
                    id: selectedOption.value,
                  },
                },
                widgetConfig: {
                  create: {
                    transactionChart: false,
                    transactionTable: false,
                  },
                },
              },
            },
          };

          if (embeddableConfig.campaignOrder) {
            data.embeddableConfig.update.campaignOrder = {
              disconnect: true,
            };
          }
          break;

        default:
          break;
      }

      await updateUserEmbeddableConfig({
        id: selectedUser.config.id,
        data,
      });

      if (isPreview) {
        let url = "";
        switch (embeddableType) {
          case "DISPLAY":
            url = `${hostname}/?token=${token}?redirectUrl=/campaigns/details/${selectedOption.value}`;
            break;

          case "WIDGET":
            url = `${hostname}/?token=${token}?redirectUrl=/campaigns/details/${selectedOption.value}?tab=widgets`;

            break;

          case "ADVERTISER":
            url = `${hostname}/?token=${token}?redirectUrl=/advertiser/details/${selectedOption.value}`;
            break;

          case "PUBLISHER":
            url = `${hostname}/?token=${token}?redirectUrl=/editorial/details/${selectedOption.value}/1`;
            break;

          default:
            url = `${hostname}/?token=${token}`;
            break;
        }

        window.open(url, "_blank");
      }

      message.destroy();
      message.success("User embeddable config updated successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to update user embeddable config!");
    }
  };

  const getSearchTitle = () => {
    switch (embeddableType) {
      case "DISPLAY":
      case "WIDGET":
        return "Search Campaign";

      case "ADVERTISER":
        return "Search Advertiser";

      case "PUBLISHER":
        return "Search Publisher";

      default:
        break;
    }
  };

  return (
    <Modal
      title="SSO Embeddable Config"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      disabled={isDisabledSubmit}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={handleOk}
          disabled={isDisabledSubmit} // Conditionally set the disabled state
        >
          Save
        </Button>,
        <Button
          key="preview"
          icon={<EyeOutlined />}
          type="primary"
          disabled={isDisabledSubmit}
          onClick={handleSaveAndPreview}
        >
          Save & Preview
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>Embeddable User Type</div>
        <div>
          <Select
            defaultValue={embeddableType}
            value={embeddableType}
            options={types}
            onChange={value => handleEmbeddableUserTypeChange(value)}
            style={{ width: 150 }}
          />
        </div>

        {["WIDGET", "DISPLAY", "ADVERTISER", "PUBLISHER"].includes(
          embeddableType
        ) && (
          <>
            <div>{getSearchTitle()}</div>
            <div>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Search..."
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleCampaignChange}
                notFoundContent={
                  campaignOrderListLoading ||
                  advertiserListLoading ||
                  editorialListLoading
                    ? "Loading..."
                    : null
                }
                options={options}
                value={selectedOption}
                key={selectedOption && selectedOption.value}
              />
            </div>

            {embeddableType === "WIDGET" && (
              <SSSOUserConfigurationToggler
                allowedWidgets={allowedWidgets}
                setAllowedWidgets={setAllowedWidgets}
              />
            )}
          </>
        )}

        {token && (
          <>
            <small style={{ marginBottom: 4 }}>
              Embed this code below to your client's application.
            </small>
            <div
              style={{
                backgroundColor: "#f1f3f4",
                padding: "8px 16px 8px 16px",
                borderRadius: 3,
                width: "100%",
                border: "1px solid #e3e2e2",
                position: "relative",
              }}
            >
              <div
                style={{
                  textAlign: "end",
                  marginBottom: 8,
                }}
              >
                <Tooltip title="Copy code snippet">
                  <Button type="text" onClick={handleCopyToClipBoard}>
                    <CopyOutlined />
                  </Button>
                </Tooltip>
              </div>
              <pre>
                {embeddableType === "DEFAULT" && (
                  <code>
                    &lt;iframe src="{hostname}/signin?token={token}"
                    title="dashboard"&gt;&lt;/iframe&gt;
                  </code>
                )}
                {embeddableType === "WIDGET" && (
                  <code>
                    &lt;iframe src="{hostname}/signin?token={token}
                    ?redirectUrl=/campaigns/details/
                    {selectedOption && selectedOption.value}
                    ?tab=widgets" title="dashboard"&gt;&lt;/iframe&gt;
                  </code>
                )}
                {embeddableType === "DISPLAY" && (
                  <code>
                    &lt;iframe src="{hostname}/signin?token={token}
                    ?redirectUrl=/campaigns/details/
                    {selectedOption && selectedOption.value}"
                    title="dashboard"&gt;&lt;/iframe&gt;
                  </code>
                )}
                {embeddableType === "ADVERTISER" && (
                  <code>
                    &lt;iframe src="{hostname}/signin?token={token}
                    ?redirectUrl=/advertiser/details/
                    {selectedOption && selectedOption.value}"
                    title="dashboard"&gt;&lt;/iframe&gt;
                  </code>
                )}
                {embeddableType === "PUBLISHER" && (
                  <code>
                    &lt;iframe src="{hostname}/signin?token={token}
                    ?redirectUrl=/editorial/details/
                    {selectedOption && selectedOption.value}/1"
                    title="dashboard"&gt;&lt;/iframe&gt;
                  </code>
                )}
              </pre>
            </div>
          </>
        )}

        <div style={{ display: "flex", justifyContent: "end", gap: 8 }}>
          <Popconfirm
            title="Are you sure to disable this user?"
            onConfirm={handleConfirmDisable}
            okText="Yes"
            cancelText="No"
          >
            <Button>Disable</Button>
          </Popconfirm>

          <Popconfirm
            title="Regenerating will invalidate the current token."
            onConfirm={handleGenerateSSOToken}
            okText="Yes"
            cancelText="No"
          >
            <Button>Regenerate token</Button>
          </Popconfirm>
        </div>
      </Space>
    </Modal>
  );
};
