import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  Row,
  Col,
  Input,
  Tag,
  Tooltip,
  Select,
  DatePicker,
  Space,
} from "antd";
import { FilterTwoTone } from "@ant-design/icons";
import useSearchParams from "../../../../core/hooks/useSearchParams";

const style = {
  name: { fontWeight: 700, fontSize: "16px" },
  date: { fontSize: "14px", color: "#bcbdc1" },
};

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const defaultStatusReadyOptions = [
  { label: "APPROVED", value: "APPROVED" },
  { label: "LIVE", value: "LIVE" },
  { label: "LIVE APPROVED", value: "LIVE_APPROVED" },
  { label: "ATTRIBUTION", value: "ATTRIBUTION" },
  { label: "FINISHED", value: "FINISHED" },
];

const defaultStatusPendingOptions = [
  { label: "PENDING", value: "PENDING" },
  { label: "LIVE PENDING", value: "LIVE_PENDING" },
  { label: "PAUSED", value: "PAUSED" },
];

const defaultSelectedStatusReady = ["APPROVED", "LIVE", "LIVE_APPROVED"];
const defaultSelectedStatusPending = ["PENDING", "LIVE_PENDING", "PAUSED"];

export const CampaignOrderQueueTable = ({
  campaignOrders,
  pagination,
  selectedCampaignOrder,
  handlePaginationChange,
  handleSelectCampaignOrder,
  handleFilterChange,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    if (selectedCampaignOrder) {
      setSelectedKeys([selectedCampaignOrder.id]);
    } else {
      setSelectedKeys([]);
    }
  }, [selectedCampaignOrder]);

  useEffect(() => {
    handleSetStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.tab]);

  const handleSetStatus = () => {
    const { status, tab } = searchParams;
    switch (tab) {
      case "READY":
        const statusReady = status
          ? status.split(",")
          : defaultSelectedStatusReady;
        setStatusOptions(defaultStatusReadyOptions);
        setSelectedStatus(statusReady);
        setSearchParams({
          ...searchParams,
          status: statusReady.join(","),
        });

        break;
      case "PENDING":
        const statusPending = status
          ? status.split(",")
          : defaultSelectedStatusPending;

        setStatusOptions(defaultStatusPendingOptions);
        setSelectedStatus(statusPending);
        setSearchParams({
          ...searchParams,
          status: statusPending.join(","),
        });
        break;

      default:
        break;
    }
  };

  const handleFilterByAction = value => {
    const { tab } = searchParams;
    const filters = [{ key: "action", value }];

    switch (tab) {
      case "READY":
        switch (value) {
          case "LAUNCH":
            setStatusOptions([
              { label: "APPROVED", value: "APPROVED", disabled: true },
            ]);
            setSelectedStatus(["APPROVED"]);
            filters.push({ key: "status", value: "APPROVED" });

            break;
          case "UPDATE":
            setStatusOptions([
              { label: "LIVE", value: "LIVE", disabled: true },
              {
                label: "LIVE_APPROVED",
                value: "LIVE_APPROVED",
                disabled: true,
              },
            ]);
            setSelectedStatus(["LIVE", "LIVE_APPROVED"]);
            filters.push({ key: "status", value: "LIVE,LIVE_APPROVED" });
            break;
          default:
            setStatusOptions(defaultStatusReadyOptions);
            setSelectedStatus(defaultSelectedStatusReady);
            filters.push({
              key: "status",
              value: defaultSelectedStatusReady.join(","),
            });
            break;
        }

        break;

      case "PENDING":
        switch (value) {
          case "LAUNCH":
            setStatusOptions([
              { label: "PENDING", value: "PENDING", disabled: true },
            ]);
            setSelectedStatus(["PENDING"]);
            filters.push({ key: "status", value: "PENDING" });
            break;
          case "UPDATE":
            setStatusOptions([
              { label: "LIVE_PENDING", value: "LIVE_PENDING", disabled: true },
            ]);
            setSelectedStatus(["LIVE_PENDING"]);
            filters.push({ key: "status", value: "LIVE_PENDING" });
            break;
          default:
            setStatusOptions(defaultStatusPendingOptions);
            setSelectedStatus(defaultSelectedStatusPending);
            filters.push({
              key: "status",
              value: defaultSelectedStatusPending.join(","),
            });
            break;
        }

        break;
      default:
        break;
    }

    handleFilterChange(filters);
  };

  const handleChangeRangePicker = value => {
    handleFilterChange([
      {
        obj: {
          createAtStartDate: value ? value[0] : null,
          createdAtEndDate: value ? value[1] : null,
        },
      },
    ]);
  };

  const columns = [
    {
      title: () => {
        return (
          <Space size="small" direction="vertical" style={{ width: "100%" }}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={8}>
                <div>Name</div>
              </Col>
              <Col span={16}>
                <Row gutter={8} justify="end">
                  <Col span={22}>
                    <Search
                      placeholder="Search a campaign"
                      onSearch={value =>
                        handleFilterChange([{ key: "name", value }])
                      }
                      enterButton
                      defaultValue={searchParams.name || ""}
                    />
                  </Col>
                  <Col span={2}>
                    <Tooltip
                      title={`${
                        showFilters ? "Hide" : "Show"
                      } advanced filters`}
                    >
                      <FilterTwoTone
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => setShowFilters(!showFilters)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={8}>
                <div>Filter By Action</div>
              </Col>
              <Col span={16}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Action"
                  defaultValue={searchParams.action || "ALL"}
                  onChange={value => {
                    handleFilterByAction(value);
                  }}
                >
                  <Option value="ALL">All</Option>
                  <Option value="LAUNCH">Launch</Option>
                  <Option value="UPDATE">Update</Option>
                </Select>
              </Col>
            </Row>
            {showFilters && (
              <>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={8}>
                    <div>Status</div>
                  </Col>
                  <Col span={16}>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      value={selectedStatus}
                      options={statusOptions}
                      onChange={value => {
                        const newStatus = value[value.length - 1];

                        if (
                          ["FINISHED", "ATTRIBUTION"].includes(newStatus) ||
                          value.includes("FINISHED") ||
                          value.includes("ATTRIBUTION")
                        ) {
                          value = [newStatus];
                        } else {
                          value = value.filter(
                            v => v !== "FINISHED" || v !== "ATTRIBUTION"
                          );
                        }
                        setSelectedStatus(value);
                        handleFilterChange([
                          {
                            key: "status",
                            value: value.join(","),
                          },
                        ]);
                      }}
                    ></Select>
                  </Col>
                </Row>
                {/* <Row type="flex" justify="space-between" align="middle">
                  <Col span={8}>
                    <div>Filter By DSP</div>
                  </Col>
                  <Col span={16}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select DSP"
                      value=""
                    >
                      <Option value="">ALL</Option>
                      <Option value="LIQUIDM">LiquidM</Option>
                      <Option value="UNKNOWN">Unknown</Option>
                    </Select>
                  </Col>
                </Row> */}
                {((selectedCampaignOrder &&
                  selectedCampaignOrder.status === "APPROVED") ||
                  (selectedCampaignOrder &&
                    selectedCampaignOrder.status === "LIVE")) && (
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={8}>
                      <div>Filter By Origin</div>
                    </Col>
                    <Col span={16}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select DSP"
                        defaultValue={searchParams.origin || ""}
                        onChange={value => {
                          handleFilterChange([{ key: "origin", value }]);
                        }}
                      >
                        <Option value="ALL">All</Option>
                        <Option value="LAUNCHER">Launcher</Option>
                        <Option value="MANUAL">Manual</Option>
                      </Select>
                    </Col>
                  </Row>
                )}
                <Row type="flex" justify="space-between" align="middle">
                  {moment(searchParams.createAtStartDate).isValid()}
                  <Col span={8}>
                    <div>Date Created</div>
                  </Col>
                  <Col span={16}>
                    <RangePicker
                      style={{ width: "100%" }}
                      defaultValue={[
                        searchParams.createAtStartDate &&
                        moment(searchParams.createAtStartDate).isValid()
                          ? moment(new Date(searchParams.createAtStartDate))
                          : "",
                        searchParams.createdAtEndDate &&
                        moment(searchParams.createdAtEndDate).isValid()
                          ? moment(new Date(searchParams.createdAtEndDate))
                          : "",
                      ]}
                      onChange={handleChangeRangePicker}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  {moment(searchParams.createAtStartDate).isValid()}
                  <Col span={8}>
                    <div>Sort By</div>
                  </Col>
                  <Col span={16}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue={searchParams.orderBy}
                      onChange={value => {
                        handleFilterChange([{ key: "orderBy", value }]); // orderBy is the official gql variable name when sorting by
                      }}
                    >
                      <Select.Option value="updatedAt_ASC">
                        Updated At: ASC
                      </Select.Option>
                      <Select.Option value="updatedAt_DESC">
                        Updated At: DESC
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
              </>
            )}
          </Space>
        );
      },
      dataIndex: "name",
      render: (text, record) => {
        const { status } = record;
        let label = status;
        let color = "blue";
        let dspProvider = "UNKNOWN";
        let action = "LAUNCH";

        switch (status) {
          case "LIVE":
            const attributionStartDate = moment(record.startDate).subtract(
              30,
              "days"
            );
            const attributionEndDate = moment();
            color = "green";
            label = "LIVE";

            // Check if the endDate if before today
            if (
              moment(record.endDate).isBetween(
                attributionStartDate,
                attributionEndDate
              )
            ) {
              color = "darkseagreen";
              label = "ATTRIBUTION";
            } else if (moment(record.endDate).isBefore(attributionStartDate)) {
              color = "gray";
              label = "FINISHED";
            }

            break;

          case "LIVE_PENDING":
            color = "green";
            label = "LIVE";
            action = "UPDATE";
            break;

          case "LIVE_APPROVED":
            color = "green";
            label = "LIVE";
            action = "UPDATE";
            break;

          case "APPROVED":
            color = "orange";
            label = "APPROVED";
            action = "LAUNCH";
            break;

          case "PENDING":
            color = "orange";
            action = "LAUNCH";
            break;

          default:
            break;
        }

        if (record && record.dspConfig) {
          dspProvider = record.dspConfig.name;
        }

        return (
          <>
            <Row>
              <Col span={21}>
                <span style={style.name}>{record.name}</span>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={style.date}>
                    {record && record.advertiser && record.advertiser.name}
                  </span>
                  <span style={style.date}>DSP: {dspProvider}</span>
                </div>
                <br />
                <span style={style.date}>
                  Created: {moment(record.createdAt).format("MM/DD/Y")}
                </span>
                {[
                  "PENDING",
                  "APPROVED",
                  "LIVE",
                  "LIVE_PENDING",
                  "LIVE_APPROVED",
                ].includes(status) && (
                  <>
                    <Tag color={color} style={{ marginLeft: 15 }}>
                      {label}
                    </Tag>

                    {status !== "LIVE" && <Tag color="blue">{action}</Tag>}
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handleTableChange = pagination => {
    handlePaginationChange(pagination.current);
  };

  return (
    <Table
      scroll={{ y: 610 }}
      columns={columns}
      sortDirections={["ascend", "descend"]}
      dataSource={campaignOrders}
      onChange={handleTableChange}
      rowSelection={{
        type: "radio",
        selectedRowKeys: selectedKeys,
        onChange: selectedRowKeys => {
          setSelectedKeys(selectedRowKeys);
        },
        onSelect: record => {
          handleSelectCampaignOrder(record);
        },
      }}
      rowClassName={(record, index) => {
        if (record && selectedCampaignOrder) {
          if (record.id === selectedCampaignOrder.id) return "tableSelectedRow";
        }
      }}
      pagination={pagination}
      rowKey={record => record.id}
    />
  );
};
