import gql from "graphql-tag";
const PAID_SEARCH_PACING = gql`
  query paidSearchPacing(
    $orgId: String!
    $advertiserIds: [String!]!
    $date: String
    $status: String
  ) {
    getPaidSearchPacing(
      orgId: $orgId
      advertiserIds: $advertiserIds
      date: $date
      status: $status
    ) {
      id
      startDate
      endDate
      overallPacingData {
        date
        dayOfMonth
        dayInMonth
        impressions
        clicks
        spend
        pace
        dailyRemainSpend
        ctr
        cpc
        targetPacing
      }
      config {
        id
        monthlyBudget
      }
      org {
        id
        name
      }
    }
  }
`;

export default PAID_SEARCH_PACING;
