import React, { useEffect, useState } from "react";
import { Divider, Button, message, Row } from "antd";
import { FundProjectionScreenOutlined } from "@ant-design/icons";
import { withApollo } from "react-apollo";

// COMPONENTS
import LaunchCampaignConfigSupply from "./LaunchCampaignConfigSupply";
import LaunchCampaignConfigDomains from "./LaunchCampaignConfigDomains";
import LaunchCampaignConfigTargeting from "./LaunchCampaignConfigTargeting";
import LaunchCampaignConfigSettings from "./LaunchCampaignConfigSettings";
import { LaunchCampaignConfigBudget } from "./LaunchCampaignConfigBudget";

// CONFIGS
import { LiquidMConstant } from "../../../../core/utils/constants/liquidMConstant";
import moment from "moment";

export const LaunchCampaignLiquidMConfig = ({
  client,
  publishers,
  selectedCampaignOrder,
  setIsLaunchCampaignDisabled,
  isLaunchCampaignDisabled,
  handleSubmitCampaign,
  launchCampaignDSPLoading,
}) => {
  // TOOD: Convert to use useForm hook
  const { countryISP, defaultCountryISP } = LiquidMConstant;
  const [countries, setCountries] = useState([defaultCountryISP]);
  const [dealIds, setDealIds] = useState([]);
  const [selectedPublishers, setSelectedPublishers] = useState([]);

  const [domains, setDomains] = useState([]);
  const [budgetConfig, setBudgetConfig] = useState({
    overall_units: 0,
    daily_units: 0,
    threshold_units: 0,
    original_overall_units: 0,
    original_daily_units: 0,
    threshold_percent: 0.5,
    overall_units_pacing: "none",
    overall_cents_pacing: "none",
    daily_units_pacing: "optimized",
    daily_cents_pacing: "optimized",
    overall_cents: null,
  });

  useEffect(() => {
    if (selectedCampaignOrder) {
      const { status, dspConfig } = selectedCampaignOrder;
      handleSetBudgetConfig();

      if (["LIVE", "LIVE_APPROVED"].includes(status) && dspConfig) {
        setCountries(dspConfig.countries || []);
        setDealIds(dspConfig.dealIds || []);
        setSelectedPublishers(dspConfig.publishers || []);
        setDomains(dspConfig.domains || []);
        handleSetBudgetConfig(dspConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignOrder]);

  const handleSetBudgetConfig = (dspConfig = null) => {
    const { budget, cpm, startDate, endDate } = selectedCampaignOrder;
    const differenceInDays = moment(endDate).diff(moment(startDate), "days");

    let threshold_percent = 0.5;
    let original_overall_units =
      budget && cpm ? Math.ceil((budget / cpm) * 1000) : 0;
    let overall_units = Math.ceil(
      original_overall_units +
        original_overall_units * (threshold_percent / 100)
    );
    let original_daily_units =
      Math.ceil(original_overall_units / differenceInDays) || 0;
    let daily_units = Math.ceil(overall_units / differenceInDays) || 0;
    let threshold_units = overall_units - original_overall_units;

    if (dspConfig && dspConfig.budget) {
      overall_units = dspConfig.budget.overall_units;
      daily_units = dspConfig.budget.daily_units;
      original_overall_units = dspConfig.budget.original_overall_units;
      original_daily_units = dspConfig.budget.original_daily_units;
      threshold_percent = dspConfig.budget.threshold_percent;
      threshold_units = dspConfig.budget.threshold_units;
    }

    setBudgetConfig({
      ...budgetConfig,
      daily_units,
      overall_units,
      original_overall_units,
      original_daily_units,
      threshold_percent,
      threshold_units,
    });
  };

  const handleLaunchCampaign = () => {
    handleSubmitCampaign({
      dspConfig: {
        publishers: selectedPublishers,
        domains,
        dealIds,
        countries,
        budget: budgetConfig,
      },
    });
  };

  useEffect(() => {
    if (!countries.length) {
      message.error("Please select at least one country ISP.");
      setCountries([LiquidMConstant.defaultCountryISP]);
    }
  }, [countries]);

  return (
    <div>
      <LaunchCampaignConfigTargeting
        countries={countries}
        setCountries={setCountries}
        countryISP={countryISP}
        defaultCountryISP={defaultCountryISP}
      ></LaunchCampaignConfigTargeting>
      <Divider />
      <LaunchCampaignConfigSettings
        dealIds={dealIds}
        setDealIds={setDealIds}
      ></LaunchCampaignConfigSettings>
      <Divider />
      <LaunchCampaignConfigSupply
        publishers={publishers}
        selectedPublishers={selectedPublishers}
        setSelectedPublishers={setSelectedPublishers}
        setIsLaunchCampaignDisabled={setIsLaunchCampaignDisabled}
        client={client}
      ></LaunchCampaignConfigSupply>
      <Divider />
      <LaunchCampaignConfigDomains
        domains={domains}
        setDomains={setDomains}
      ></LaunchCampaignConfigDomains>
      <Divider />
      <LaunchCampaignConfigBudget
        budgetConfig={budgetConfig}
        setBudgetConfig={setBudgetConfig}
      />

      {/* TODO: disabled condition grow to complex simplify and refactor */}
      <Row justify="end">
        <Button
          type="primary"
          onClick={handleLaunchCampaign}
          icon={<FundProjectionScreenOutlined />}
          disabled={
            isLaunchCampaignDisabled ||
            launchCampaignDSPLoading ||
            (selectedCampaignOrder.status === "LIVE" &&
              (!selectedCampaignOrder.dspConfig ||
                !selectedCampaignOrder.dspConfig.name ||
                (selectedCampaignOrder.dspConfig.status &&
                  selectedCampaignOrder.dspConfig.status === "IN_PROGRESS")))
          }
        >
          {selectedCampaignOrder.status === "APPROVED" ? "Launch " : "Update "}
          Campaign
        </Button>
      </Row>
    </div>
  );
};

export default withApollo(LaunchCampaignLiquidMConfig);
