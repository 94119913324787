import gql from "graphql-tag";

const ALL_CAMPAIGNS = gql`
  query AllCampaigns($currentDate: DateTime) {
    campaigns(
      where: { endDate_lte: $currentDate }
      orderBy: createdAt_DESC
      first: 2000
    ) {
      id
      demandSidePlatform
      clientOrg
      adProviderId
      name
      endDate
      aggregateData {
        overallData {
          aggImpressions
          aggTotalCost
          aggEvents
          aggTransactions
          aggVWI
          aggClicks
        }
      }
    }
  }
`;

export default ALL_CAMPAIGNS;
