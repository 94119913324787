import gql from "graphql-tag";

const GET_CAMPAIGN_HISTORIES = gql`
  query campaignHistories($campaignOrderId: ID!) {
    campaignHistories(where: { campaignOrder: { id: $campaignOrderId } }) {
      id
      action
      author {
        id
        name
        username
        avatar {
          id
          key
        }
      }
      property
      campaignOrder {
        id
        name
      }
      data
      createdAt
      updatedAt
    }
  }
`;

export default GET_CAMPAIGN_HISTORIES;
