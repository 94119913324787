import React from "react";
import { Button, Tooltip } from "antd";

class ClearSelectedButton extends React.Component {
  render() {
    return (
      <Tooltip title="Clear Selected Shape">
        <Button size={"small"} style={{ width: 120 }} type="normal">
          Clear Selected
        </Button>
      </Tooltip>
    );
  }
}

export default ClearSelectedButton;
