import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import ErrorPage from "../../../../../shared/ErrorPage";
import DetailBar from "./components/DetailBar";
import DualAxisChart from "../../../../../shared/charts/DualAxisChart";
import { summaryScaleSignUps } from "../../../utils/summaryScale";
import moment from "moment";
import { Col, Row } from "antd";
import EditorialDetailsSignUpsLoader from "../../../../../../core/components/editorial/editorialdetails/signUps/EditorialDetailsSignUpsLoader";
import EditorialDetailsSignUpsController from "../../../../../../core/components/editorial/editorialdetails/signUps/EditorialDetailsSignUpsController";
import SignUpsTable from "./components/SignUpsTable";

const EditorialSignUpsDetailsView = props => {
  return (
    <EditorialDetailsSignUpsLoader {...props}>
      <EditorialDetailsSignUpsController>
        <SignUpsTab />
      </EditorialDetailsSignUpsController>
    </EditorialDetailsSignUpsLoader>
  );
};

const SignUpsTab = ({
  reportDataSummary,
  reportSummaryLoading,
  reportSummaryError,
  startDate,
  endDate,
  signUpsListNormalized,
  reportListLoading,
  reportListError,
  filterByDate,
}) => {
  const [summaryData, setSummaryData] = useState();
  const [signUpList, setSignUpList] = useState();
  const [selectedStartEndDate, setSelectedStartEndDate] = useState();

  useEffect(() => {
    if (reportDataSummary) setSummaryData(reportDataSummary.summary);
    if (signUpsListNormalized) {
      //If the summary data is loading this means that the date picker was used before the list was loaded.
      //Adjust the data accordingly by using the list values
      if (summaryData && summaryData.loading) {
        const result = filterByDate(
          selectedStartEndDate[0],
          selectedStartEndDate[1]
        );
        if (result) {
          setSummaryData(result.summary);
          setSignUpList(result.signUpList);
        }
      } else {
        setSignUpList(signUpsListNormalized);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDataSummary, signUpsListNormalized]);

  if (reportSummaryLoading) return <LoadingPage />;
  if (reportSummaryError || reportListError) return <ErrorPage />;
  const { byDates } = reportDataSummary;

  const dropDownOptions = [
    { key: "clicks", display: "Impressions" },
    { key: "signUps", display: "Sign Ups" },
    { key: "customers", display: "Customers" },
  ];

  return (
    <React.Fragment>
      <DetailBar startDate={startDate} endDate={endDate} {...summaryData} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DualAxisChart
            id={"pageViews"}
            byDate={byDates}
            xAxis="date"
            options={dropDownOptions}
            defaultLeftYValue={dropDownOptions[0]}
            defaultRightYValue={dropDownOptions[1]}
            customScale={summaryScaleSignUps}
            has6MonDisables={false}
            allTimeDateRange={[
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ]}
            onDatePickerChange={({ dates }) => {
              const result = filterByDate(dates[0], dates[1]);
              if (result) {
                setSummaryData(result.summary);
                setSignUpList(result.signUpList);
                setSelectedStartEndDate(dates);
              }
            }}
          />
        </Col>
        <Col xs={24}>
          <SignUpsTable data={signUpList} loading={reportListLoading} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EditorialSignUpsDetailsView;
