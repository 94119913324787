import { Tooltip } from "antd";
import React from "react";

const RequiredLabel = () => {
  return (
    <Tooltip title={"This field is required"}>
      <span style={{ color: "red" }}>*</span>
    </Tooltip>
  );
};

export default RequiredLabel;
