import { Space, Typography } from "antd";
import React from "react";
import InstructionsBuilder from "./InstructionsBuilder";
import { Text } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";

import wix1 from "../../../../../../assets/tagsInstructions/wix/wix1.png";
import wix2 from "../../../../../../assets/tagsInstructions/wix/wix2.png";
import wix3 from "../../../../../../assets/tagsInstructions/wix/wix3.png";
import wix4 from "../../../../../../assets/tagsInstructions/wix/wix4.png";
import wix5 from "../../../../../../assets/tagsInstructions/wix/wix5.png";
import wix6 from "../../../../../../assets/tagsInstructions/wix/wix6.png";

const { Link } = Typography;
const wixSteps = generatedTag => [
  {
    instruction: (
      <>
        Login to{" "}
        <Link href="http://wix.com" target="_blank" rel="noopener noreferrer">
          Wix
        </Link>{" "}
      </>
    ),
    pdfText: "Login to Wix.",
  },
  {
    instruction: (
      <>
        Go to{" "}
        <Link
          href="https://www.wix.com/my-account/site-selector/?buttonText=Open%20Settings&title=Select%20a%20Site&autoSelectOnSingleSite=true&actionUrl=https://www.wix.com/dashboard/{{metaSiteId}}/settings"
          target="_blank"
          rel="noopener noreferrer"
        >
          Settings
        </Link>{" "}
        in your site's dashboard.
      </>
    ),
    image: wix1,
    pdfText: "Go to Settings in your site's dashboard.",
  },
  {
    instruction: (
      <>
        Click the <Typography.Text strong>Custom Code</Typography.Text> tab in
        the <Typography.Text strong>Advanced</Typography.Text> section
      </>
    ),
    image: wix2,
    pdfText: "Click the Custom Code tab in the Advanced section.",
  },
  {
    instruction: (
      <>
        Click <Typography.Text strong>+ Add Custom Code</Typography.Text> at the
        top right.
      </>
    ),
    image: wix3,
    pdfText: "Click + Add Custom Code at the top right.",
  },
  {
    instruction: (
      <>
        Paste the code snippet in the text box. On the “Add Code to Pages”,
        choose the <Typography.Text strong>All pages</Typography.Text> option.
        On the “Placed Code in”, choose the{" "}
        <Typography.Text strong>Head</Typography.Text> option.
      </>
    ),
    image: wix4,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          Paste the code snippet in the text box. On the “Add Code to Pages”,
          choose the All pages option. On the “Placed Code in”, choose the Head
          option.
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: (
      <Space direction="vertical">
        <Typography.Text>6. Enter a name for your code.</Typography.Text>
        <Typography.Text>
          Tip: Give it a name that is easy to recognize so you can quickly
          identify it later.
        </Typography.Text>
      </Space>
    ),
    image: wix5,
    pdfTextCode: (
      <div
        style={{
          fontSize: 12,
          marginBottom: 20,
          marginTop: 20,
          lineHeight: 1.6,
        }}
      >
        <Text>Enter a name for your code.</Text>
        <Text>
          Tip: Give it a name that is easy to recognize so you can quickly
          identify it later.
        </Text>
      </div>
    ),
  },
  {
    instruction: (
      <>
        Click <Typography.Text strong>Apply</Typography.Text>.
      </>
    ),
    image: wix6,
    pdfText: "Click Apply.",
  },
];

export const WixInstructions = ({ generatedTag }) => {
  return <InstructionsBuilder title="Wix" steps={wixSteps(generatedTag)} />;
};

export const WixInstructionsPDF = ({ generatedTag }) => {
  return <GeneratedTagPDF title={"Wix"} steps={wixSteps(generatedTag)} />;
};
