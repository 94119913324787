import gql from "graphql-tag";

const UPSERT_REPROCESS_STATUS = gql`
  mutation upsertReprocessStatus(
    $key: String!
    $dateQueued: DateTime!
    $module: String!
  ) {
    upsertReprocessStatus(
      where: { key: $key }
      create: {
        key: $key
        module: $module
        dateQueued: $dateQueued
        status: QUEUED
      }
      update: {
        key: $key
        module: $module
        dateQueued: $dateQueued
        status: QUEUED
      }
    ) {
      id
      key
      module
      dateQueued
      dateProcessed
      dateFinished
      status
    }
  }
`;

export default UPSERT_REPROCESS_STATUS;
