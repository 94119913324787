import gql from "graphql-tag";

const GET_IAB_CATEGORY_GROUPS = gql`
  query iabCategories($orgs: OrgWhereInput) {
    iABCategoryGroups(
      first: 1000
      orderBy: updatedAt_DESC
      where: { orgs_some: $orgs }
    ) {
      id
      name
      iabCategories
      createdAt
    }
  }
`;

export default GET_IAB_CATEGORY_GROUPS;
