import gql from "graphql-tag";

const GET_CAMPAIGN = gql`
  query getCampaign(
    $skip: Int
    $first: Int
    $orgID: ID!
    $searchLineName: String
  ) {
    campaignsConnection(
      where: { name_contains: $searchLineName, orgs_some: { id: $orgID } }
      orderBy: createdAt_DESC
      skip: $skip
      first: $first
    ) {
      edges {
        cursor
        node {
          id
          name
          adProviderId
          demandSidePlatform
          clientOrg
          budgetTotal
          budgetByDay
          startDate
          endDate
          originalStartDate
          originalEndDate
          orgs {
            id
            name
          }
          logsKey
          phoneNumber
          forwardingNumber
          cpm
          rawCost
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      aggregate {
        count
      }
    }
    total: campaignsConnection(
      where: { name_contains: $searchLineName, orgs_some: { id: $orgID } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAIGN;
