import gql from "graphql-tag";

const UPDATE_LOCATION_SEGMENT = gql`
  mutation updateSegment(
    $id: ID
    $name: String
    $circles: [CircleCreateWithoutGeoTargetInput!]
    $polygons: [PolygonCreateWithoutGeoTargetInput!]
    $start: DateTime
    $end: DateTime
  ) {
    updateSegment(
      where: { id: $id }
      data: {
        name: $name
        circles: { create: $circles }
        polygons: { create: $polygons }
        start: $start
        end: $end
      }
    ) {
      id
      name
      start
      end
      circles {
        id
        coordinate {
          lat
          lng
        }
        radius
      }
      polygons {
        id
        geometry
      }
    }
  }
`;

export default UPDATE_LOCATION_SEGMENT;
