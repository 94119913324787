import gql from "graphql-tag";

const GET_ROLL_UP_PAID_SEARCH = gql`
  query getRollUpPaidSearch(
    $orgId: String!
    $startDate: DateTime
    $endDate: DateTime
    $advertiserIds: [String]
  ) {
    getRollUpPaidSearch(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      advertiserIds: $advertiserIds
    )
  }
`;

export default GET_ROLL_UP_PAID_SEARCH;
