import CryptoJS from "crypto-js";

export const encryptStringMD5 = value => {
  return CryptoJS.MD5(value).toString();
};

export const encryptStringSHA512 = value => {
  return CryptoJS.SHA512(value).toString(CryptoJS.enc.Hex);
};

export const encryptStringSHA256 = value => {
  return CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex);
};

export const encryptStringSHA3 = value => {
  return CryptoJS.SHA3(value).toString(CryptoJS.enc.Hex);
};

export const encryptStringSHA1 = value => {
  return CryptoJS.SHA1(value).toString(CryptoJS.enc.Hex);
};
