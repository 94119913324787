import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import "./styles.css";

const { Text, Title } = Typography;

const columns = [
  {
    title: "Region Group Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  // {
  //   title: "Type",
  //   dataIndex: "type",
  //   key: "type",
  // },
  {
    title: "Regions",
    dataIndex: "regions",
    key: "regions",
    render: regions =>
      regions.map(region => (
        <Tag color="green" key={region}>
          {region}
        </Tag>
      )),
  },
];

export const CampaignRequestorTargetingRegionTable = ({
  name,
  restField,
  fieldKey,
  form,
  setShowRegionsModal,
  editable = false,
  index,
}) => {
  const targetingRegions = form.getFieldValue("campaigns")[index]
    .targetingRegions;
  const targetingType = form.getFieldValue("campaigns")[index].targetingType;

  return (
    <div className="requestor-table-wrapper" style={{ marginTop: 20 }}>
      <div className="requestor-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>TARGETING: REGION GROUPS</Title>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "end" }}>
              {editable && targetingRegions && targetingRegions.length ? (
                <Button onClick={() => setShowRegionsModal(true)}>
                  Edit Regions
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {targetingRegions && targetingRegions.length ? (
        <Table
          rowKey={record => record.name}
          columns={columns}
          dataSource={targetingRegions}
          pagination={false}
          className="requestor-table"
          summary={() =>
            targetingRegions && targetingRegions.length ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  {targetingRegions.length} Region Groups
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {targetingRegions.reduce(
                    (acc, regionGroup) => acc + regionGroup.regions.length,
                    0
                  )}{" "}
                  Individual Regions
                </Table.Summary.Cell>
              </Table.Summary.Row>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <>
          <div style={{ padding: 20 }}>
            <Space
              direction="vertical"
              size="small"
              align="center"
              style={{ width: "100%" }}
            >
              {editable ? (
                <>
                  <Text>Select regions to target from your library</Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowRegionsModal(true);
                    }}
                  >
                    Select Regions
                  </Button>
                </>
              ) : (
                <Text>No regions selected</Text>
              )}
            </Space>
          </div>
        </>
      )}

      {/* Create hidden input for targetingRegions */}
      <Form.Item
        {...restField}
        name={[name, "targetingRegions"]}
        fieldKey={[fieldKey, "targetingRegions"]}
        hidden
        rules={[
          {
            required: targetingType === "REGION",
            message: "Please select at least one region group",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
