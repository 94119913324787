import { useContext } from "react";
import LocationContext from "./LocationProvider";

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
