import React from "react";
import { Modal, Card, Row, Col, Button, Spin, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

const ReprocessModal = ({
  visible,
  setVisible,
  reprocessNew,
  reprocessAll,
  reprocessStatuses,
  reporcessStatusLoading,
}) => {
  //console.log(reprocessStatuses);
  return (
    <Modal
      title={`Paid Search Admin Tools`}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={700}
    >
      <Row type="flex" style={{ justifyContent: "center" }}>
        {reporcessStatusLoading ? (
          <Spin />
        ) : (
          <>
            <Col xs={24} sm={12} md={11}>
              <Card title="Reprocess All">
                {reprocessStatuses.allStatus === "PROCESSING" ||
                reprocessStatuses.allStatus === "QUEUED" ||
                reprocessStatuses.allStatus === "FAILED" ? (
                  <Row type="flex" justify="center" align="middle">
                    <Tag
                      color={
                        reprocessStatuses.allStatus === "PROCESSING"
                          ? "processing"
                          : reprocessStatuses.allStatus === "FAILED"
                          ? "error"
                          : reprocessStatuses.allStatus === "QUEUED"
                          ? "warning"
                          : "success"
                      }
                      style={{ marginBottom: "25px" }}
                    >
                      {reprocessStatuses.allStatus}
                    </Tag>
                  </Row>
                ) : (
                  <></>
                )}

                <Row type="flex" justify="center" align="middle">
                  <ReloadOutlined
                    style={{ fontSize: "80px", marginBottom: "20px" }}
                  />
                  <div style={{ marginBottom: "20px" }}>
                    This will reprocess all available Paid Search
                  </div>
                  <Button
                    icon={<ReloadOutlined />}
                    disabled={
                      reprocessStatuses.allStatus === "PROCESSING" ||
                      reprocessStatuses.allStatus === "QUEUED"
                        ? true
                        : false
                    }
                    type="primary"
                    onClick={() => {
                      reprocessAll();
                    }}
                  >
                    Reprocess All Paid Search
                  </Button>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={11} offset={2}>
              <Card title="Process New">
                <Row type="flex" justify="center" align="middle">
                  <ReloadOutlined
                    style={{ fontSize: "80px", marginBottom: "20px" }}
                  />
                  <div style={{ marginBottom: "20px" }}>
                    This will only process paid search that has been newly added
                    and and don't have any existing data yet
                  </div>
                  <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => {
                      reprocessNew();
                    }}
                  >
                    Process New Paid Search
                  </Button>
                </Row>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
};

export default ReprocessModal;
