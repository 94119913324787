import gql from "graphql-tag";

const PAID_SEARCH_SHARE_ORGS = gql`
  query listOrgs($parentid: ID!, $skip: Int, $first: Int) {
    orgs(
      first: $first
      skip: $skip
      where: { OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }] }
      orderBy: name_ASC
    ) {
      id
      name
      dataConfig {
        googleCustomerId
        googleAnalytics {
          viewId
          isGA4
        }
        bing {
          accountId
          customerId
        }
      }
      paidSearchSummary {
        id
        config {
          processWithGoogleAds
          monthlyBudget
          hasLocationRevenue
          locationsKey
          hasCommerce
          commerceTags {
            id
            eventTags {
              appId
            }
          }
        }
      }
    }
  }
`;

export default PAID_SEARCH_SHARE_ORGS;
