import { Card, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";

const { Text } = Typography;

const historyConfig = {
  action: {
    DELETED: "deleted",
    CREATED: "created",
    UPDATED: "updated",
    PENDING_DELETE: "deleted",
    PENDING_CREATE: "created",
    PENDING_UPDATE: "updated",
  },
};

const camelCaseToReadable = value => {
  if (!value) return value;
  return (
    value
      // Insert a space before each uppercase letter
      .replace(/([A-Z])/g, " $1")
      // Convert the first character to uppercase and the rest to lowercase
      .toLowerCase()
  );
};

const valueFormatter = value => {
  // Check if value is date else check if value is number else check if value is object else value if array
  if (moment(value, moment.ISO_8601).isValid()) {
    return moment(value).format("ll");
  } else if (!isNaN(value)) {
    return value;
  } else if (typeof value === "object") {
    return JSON.stringify(value);
  } else if (Array.isArray(value)) {
    return value.join(", ");
  } else {
    return value;
  }
};

export const CampaignOrderQueueHistory = ({ currentCampaignOrder }) => {
  return (
    <Card title="CAMPAIGN ORDER HISTORY">
      {currentCampaignOrder &&
      currentCampaignOrder.histories &&
      currentCampaignOrder.histories.length > 0 ? (
        currentCampaignOrder.histories
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1; // a should come before b in the sorted order
            } else if (a.createdAt < b.createdAt) {
              return 1; // b should come before a in the sorted order
            } else {
              return 0; // both dates are equal
            }
          })
          .map((history, index) => {
            return (
              <div
                key={index}
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                  borderBottom: "1px solid #0000000f",
                }}
              >
                <Row>
                  <Col span={20}>
                    <div>
                      <span>&#9702;</span>{" "}
                      <strong>{history.author.name}</strong>{" "}
                      <span>{historyConfig.action[history.action]}</span>{" "}
                      <span>{camelCaseToReadable(history.data.fieldName)}</span>
                      {history.data.oldValue && (
                        <Text ellipsis>
                          {" "}
                          from{" "}
                          <strong>
                            {valueFormatter(history.data.oldValue)}
                          </strong>
                        </Text>
                      )}
                      {history.data.newValue && (
                        <Text ellipsis>
                          {" "}
                          to{" "}
                          <strong>
                            {valueFormatter(history.data.newValue)}
                          </strong>
                        </Text>
                      )}
                    </div>
                  </Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <Typography.Text type="secondary">
                      {moment(history.createdAt)
                        .utc()
                        .format("MM/DD/YYYY HH:mm [GMT]")}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            );
          })
      ) : (
        <></>
      )}
    </Card>
  );
};
