import React, { memo } from "react";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import { LoadingPage } from "../../../../../platform/shared/LoadingPage";
import ReportWrapper from "../../../../shared/ReportWrapper";
import SeoSummaryDetailsController from "./SeoSummaryDetailsController";

const SeoSummaryDetailsLoader = ({ reportKey, setReportData, domain }) => {
  const { data: signedUrl, loading: signedUrlLoading } = useQuery(
    GET_SIGNED_URL,
    {
      variables: {
        key: reportKey,
      },
    }
  );

  if (signedUrlLoading) return <LoadingPage />;

  return (
    <ReportWrapper
      signedUrl={
        signedUrl && signedUrl.getSignedURL ? signedUrl.getSignedURL : null
      }
      setReportData={setReportData}
    >
      <SeoSummaryDetailsController domain={domain} />
    </ReportWrapper>
  );
};

export default memo(SeoSummaryDetailsLoader);
