import gql from "graphql-tag";

const DELETE_ORG_DEAL_IDS = gql`
  mutation deleteManyDealIds($ids: [ID!]!) {
    deleteManyDealIds(where: { id_in: $ids }) {
      count
    }
  }
`;

export default DELETE_ORG_DEAL_IDS;
