import React from "react";
import { formatNumber } from "../../../../../../../../../core/utils/campaigns";
import { Chart, Geom, Tooltip, Coord } from "bizcharts";

const Donut = ({ data }) => {
  const chartData = data.map(item => ({
    ...item,
    value: item.NAME,
    IDENTIFIERS: item.REVENUE,
  }));

  const cols = {
    percent: {
      formatter: function(val) {
        val = val * 100;
        return formatNumber(val) + "%";
      },
    },
    IDENTIFIERS: { formatter: val => val.toLocaleString() },
  };

  return (
    <div>
      <Chart forceFit data={chartData} padding={"auto"} scale={cols}>
        <Coord type="theta" radius={0.75} innerRadius={0.65} />

        <Tooltip showTitle={false} />

        <Geom
          color={[
            "NAME",
            name => {
              const index = data.findIndex(el => el.NAME === name);
              if (data[index]) return data[index].color;
              else return "black";
            },
          ]}
          position={"IDENTIFIERS"}
          type="intervalStack"
        />
      </Chart>
    </div>
  );
};

export default Donut;
