import React from "react";
import { Row, Col, Progress } from "antd";
import { withRouter } from "react-router-dom";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
  StandardTableRowText,
} from "../../../../../../../../shared/globalStyling/styledText";
import { isEmpty } from "lodash";
import moment from "moment";
import StatusTag from "../../../../../../components/StatusTag";
import PaginationTable from "../../../../../../../../shared/PaginationTable";
import { formatCurrency } from "../../../../../../../../../core/utils/campaigns";

const Frag = React.Fragment;

const OrganizationsTable = ({
  data,
  history,
  possibleFields,
  selectedTableColumns,
  location,
  total,
  current,
  pageSize,
  orgId,
  id,
  summaryRangeDates,
  currencyFormat,
}) => {
  const showEndDateStatus = ["ENDED", "SUSPENDED", "UNKNOWN", "UNSPECIFIED"];

  const getColumn = {
    Name: field => {
      return {
        title: <TableColumnTitle text={"Name"} />,
        dataIndex: "name",
        key: "name",
        width: "15%",
        align: "left",

        render: (text, record) => {
          return (
            <Frag>
              <Row type="flex" justify="start">
                <Col>
                  <PrimaryTableRowText text={text} />
                </Col>
              </Row>
            </Frag>
          );
        },
      };
    },
    Flight: field => {
      return {
        title: <TableColumnTitle text={"Flight"} />,
        dataIndex: "startDate",
        key: "startDate",
        align: "left",
        width: "14%",
        footerContent: " ",
        render: (text, record) => {
          if (!record) {
            return <div />;
          }
          const isFiltered = !isEmpty(summaryRangeDates);
          const currentDate = moment().format("MM/DD/YY");
          // const startDate = record.startDate ? moment(record.startDate) : null;
          // const endDate = record.endDate ? moment(record.endDate) : null;
          const startDate =
            !isFiltered && record.startDate
              ? moment(record.startDate, "YYYY-MM-DD")
              : moment(summaryRangeDates[0]) || null;
          const endDate =
            !isFiltered && record.endDate
              ? moment(record.endDate, "YYYY-MM-DD")
              : moment(summaryRangeDates[1]) || null;
          const status = record.status ? record.status : "UNKNOWN";

          let flightProgress = 0;
          if (currentDate > endDate) flightProgress = 100;
          else if (currentDate < endDate && currentDate > startDate)
            flightProgress =
              (currentDate.diff(startDate, "minutes") /
                endDate.diff(startDate, "minutes")) *
              100;
          return (
            <Frag>
              <Row type="flex" justify="start" align="middle" gutter={8}>
                {record.startDate && (
                  <Col>
                    <FlightDateText
                      text={startDate ? startDate.format("MM/DD/YY") : null}
                    />
                  </Col>
                )}
                <Col>
                  <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
                </Col>
                {(showEndDateStatus.includes(status) && record.endDate) ||
                isFiltered ? (
                  <Col>
                    <FlightDateText
                      text={endDate ? endDate.format("MM/DD/YY") : null}
                    />
                  </Col>
                ) : null}
                <Col>
                  <StatusTag status={status} />
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Progress
                  id="main-campaign-table-progress"
                  type={"line"}
                  strokeWidth={3}
                  percent={flightProgress ? flightProgress : 0}
                  showInfo={false}
                  strokeColor={"#636363"}
                />
              </Row>
            </Frag>
          );
        },
      };
    },
    Performance: () => {
      return {
        title: <TableColumnTitle text={"Performance"} />,
        dataIndex: "performance",
        key: "performanceStatus",
        align: "left",
        width: "12%",
        footerStyle: "block",
        footerSums: [
          {
            property: "data.impressions",
            formatter: "",
            title: "Impressions",
          },
          {
            property: "data.clicks",
            formatter: "",
            title: "Clicks",
          },
        ],
        render: (text, { data }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(data) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Impressions:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={` ${Number(
                          parseInt(data.impressions).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Clicks:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={`${Number(
                          parseInt(data.clicks).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
    Commerce: () => {
      return {
        title: <TableColumnTitle text={"eCommerce"} />,
        dataIndex: "performance",
        key: "performanceStatus",
        align: "left",
        width: "12%",
        footerStyle: "block",
        footerSums: [
          {
            property: "data.conversions",
            formatter: "",
            title: "Transactions",
          },
          {
            property: "data.revenue",
            title: "Revenue",
            isCurrency: true,
          },
          {
            property: "data.spend",
            title: "Spend",
            isCurrency: true,
          },
        ],
        render: (text, { data }) => {
          return (
            <Row type="flex" justify="start" align="middle">
              <Col style={{ minWidth: "50px" }}>
                {!isEmpty(data) ? (
                  <>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Transactions:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={` ${Number(
                          parseInt(data.conversions).toFixed(0)
                        ).toLocaleString()}`}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Revenue:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={formatCurrency(data.revenue, currencyFormat)}
                      />
                    </Row>
                    <Row type="flex">
                      <PrimaryTableRowText text={`Spend:‏‏‎ ‎`} />
                      <StandardTableRowText
                        text={formatCurrency(data.spend, currencyFormat)}
                      />
                    </Row>
                  </>
                ) : (
                  <PrimaryTableRowText text={`-`} />
                )}
              </Col>
            </Row>
          );
        },
      };
    },
  };

  const fieldsToRender = Object.keys(possibleFields).filter(
    field => selectedTableColumns.indexOf(field) >= 0
  );

  const columns = fieldsToRender.map(key => {
    const field = possibleFields[key];
    return getColumn[field.title]
      ? getColumn[field.title](field)
      : getColumn.generic(field);
  });

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        summary={data}
        onRow={data => ({
          onClick: () => {
            const { id } = data;
            history.push(`/paid_search/campaign/${orgId}/${id}`);
          },
        })}
        data={data}
        paginationSize={pageSize}
        showSizeChanger
        size={"default"}
        columns={columns}
        scroll={{ x: 1200 }}
        total={total}
        current={current}
        pageSize={pageSize}
        bordered={false}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          const nextPage = location.pathname.split("/");
          nextPage[3] = id;
          nextPage[4] = paginationObject.current;
          const searchParams = new URLSearchParams(window.location.search);

          if (searchParams.get("sort")) searchParams.delete("sort");
          if (searchParams.get("pageSize")) searchParams.delete("pageSize");

          if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
            searchParams.append("pageSize", paginationObject.pageSize);
          }
          if (!isEmpty(sorterObject))
            searchParams.append(
              "sort",
              `${sorterObject.field}_${
                sorterObject.order === "ascend" ? "ASC" : "DESC"
              }`
            );

          history.push({
            pathname: nextPage.join("/"),
            search: `?${searchParams.toString()}`,
          });
        }}
        currencyFormat={currencyFormat}
      />
    </div>
  );
};

export default withRouter(OrganizationsTable);
