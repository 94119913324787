import React from "react";
import { Modal, Button, Space, Input, Form, Typography, message } from "antd";
import "./styles.css";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";

const { TextArea } = Input;
const { Text } = Typography;

const CampaignOrderQueueReviewMediaNotesDialog = ({
  mediaIds,
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm(); // Create form instance)
  const {
    updateManyMedias,
    loadingUpdateManyMedias,
  } = useCampaignOrderQueueContext();

  const handleOk = async () => {
    const values = form.getFieldsValue();
    // Update media
    try {
      message.loading("Updating media note...", 0);
      await updateManyMedias({
        variables: {
          data: {
            mediaNotes: values.note,
            status: "REJECTED",
          },
          where: {
            id_in: mediaIds,
          },
        },
      });

      setVisible(false);
      message.destroy();
      message.success("Media note updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media note");
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Modal
        visible={visible}
        bodyStyle={{ padding: 20 }}
        width={600}
        title="SEND NOTE"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingUpdateManyMedias}
            onClick={handleOk}
          >
            Send
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            note: "",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Text>Write a note to attach to the selected ad units</Text>
            <Form.Item name="note">
              <TextArea rows={4} placeholder="Enter note" />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </div>
  );
};

export default CampaignOrderQueueReviewMediaNotesDialog;
