import React, { useEffect, useState } from "react";
import { Button, message, Modal, Space, Spin, Table } from "antd";

import { useLocationContext } from "../../../core/components/locations/useLocationContext";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import moment from "moment";
import TargetingLocationForm from "../OrgConfig/components/Targeting/TargetingLocationForm";
import { transformLocationToGeoTarget } from "./CampaignRequestorUtil";

const CampaignRequestorLocationsDialog = ({
  visible,
  setVisible,
  audienceType,
  google,
  onAddSelections,
  selectedLocations,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showCreateLocation, setShowCreateLocation] = useState(false);

  const { selectedAdvertiser } = useCampaignRequestorContext();
  const {
    fetchLocations,
    locations,
    loadingLocations,
    createLocation,
  } = useLocationContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }
    const targets = locations
      .filter(location => selectedRowKeys.includes(location.id))
      .filter(location => {
        // Return only locations that are not disabled
        const selected = selectedLocations.find(
          selected => selected.name === location.name
        );
        return selected ? !selected.disabled : true;
      })
      .map(location => transformLocationToGeoTarget({ location }));

    onAddSelections(targets);

    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => {
      // Check record if record is in selectedLocations
      const selected = selectedLocations.find(
        location => location.name === record.name
      );

      return {
        disabled: selected && selected.disabled ? true : false,
      };
    },
  };

  const handleFetchLocations = () => {
    fetchLocations({
      variables: {
        where: {
          AND: [
            {
              org: { id: selectedAdvertiser.id },
            },
            {
              audienceType,
            },
          ],
        },
      },
    });
  };

  const handleCreateLocation = async location => {
    try {
      message.loading("Creating location...", 0);
      setShowCreateLocation(false);
      const {
        name,
        formattedAddress,
        city,
        state,
        zip,
        lat,
        lng,
        street,
        country,
        circles,
      } = location;

      // Create the location
      await createLocation({
        variables: {
          data: {
            name,
            street,
            city,
            state,
            lat,
            lng,
            country,
            formattedAddress,
            zip,
            org: { connect: { id: selectedAdvertiser.id } },
            audienceType,
            circles: {
              create: circles.map(circle => ({
                radius: circle.overlay.radius / 1000, // TODO: Make dynamic based on the radiusUnit
                coordinate: {
                  create: {
                    lat,
                    lng,
                  },
                },
              })),
            },
          },
        },
      });

      handleFetchLocations();

      message.destroy();
      message.success("Location created successfully!");
    } catch (error) {
      message.destroy();
      message.error("Error creating location. Please try again.");
    }
  };

  useEffect(() => {
    handleFetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLocations.length && locations.length) {
      const keys = [];
      selectedLocations.forEach(target => {
        // Find the location in the locations array by name
        const location = locations.find(
          location => location.name === target.name
        );
        if (location) {
          keys.push(location.id);
        }
      });
      setSelectedRowKeys(keys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocations, locations]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>
              {audienceType === "ATTRIBUTION" ? "Retail Stores" : "Locations"}
            </span>
            <div>
              {audienceType === "ADDRESS" && (
                <Button
                  style={{ marginRight: 8 }}
                  type="default"
                  onClick={() => setShowCreateLocation(true)}
                >
                  Create Location
                </Button>
              )}
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        {loadingLocations ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={[
              {
                title: `${
                  audienceType === "ATTRIBUTION" ? "Retail Store" : "Location"
                } Name`,
                dataIndex: "name",
                key: "name",
                ellipsis: true, // Enables ellipsis for text overflow
                width: 200, // Set a fixed width for the column
              },
              {
                title: "Street Address",
                dataIndex: "formattedAddress",
                key: "formattedAddress",
              },
              {
                title: "Created On",
                dataIndex: "createdAt",
                key: "createdAt",
                render: text => moment(text).format("MM/DD/YYYY hh:mma"),
              },
            ]}
            dataSource={locations}
          />
        )}
      </Modal>

      {showCreateLocation && (
        <TargetingLocationForm
          isFormOpen={showCreateLocation}
          google={google}
          closeForm={() => setShowCreateLocation(false)}
          submitForm={handleCreateLocation}
        />
      )}
    </>
  );
};

export default CampaignRequestorLocationsDialog;
