import React, { useMemo } from "react";
import moment from "moment";

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }
  return color;
};

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }
  return color;
};

const TagsMainController = ({ ...props }) => {
  const { dataMultiOrgTags, requestAssistance } = props;

  const formattedTags = useMemo(() => {
    if (!dataMultiOrgTags || !dataMultiOrgTags.eventsTargets) {
      return [];
    }

    const tags = dataMultiOrgTags.eventsTargets.map(tag => {
      const {
        transactions,
        pageViews,
        signups,
        name,
        id,
        tagUpdateDate,
        eventTags,
        orgs,
        isSignUps,
        createdAt,
      } = tag;
      const appId =
        eventTags && eventTags[0] && eventTags[0].appId && eventTags[0].appId[0]
          ? eventTags[0].appId[0]
          : "";
      return {
        id,
        name,
        advertiser: orgs && orgs[0] ? orgs[0].name : "",
        createdAt,
        measuredEvents: {
          pageViews,
          transactions,
          signups,
          isSignUps,
          date: tagUpdateDate
            ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
            : "Signal Unknown",
          colors: {
            transactions: typePicker(tagUpdateDate, transactions),
            pageViews: typePicker(tagUpdateDate, pageViews),
            signups: typePicker(tagUpdateDate, signups),
          },
          typeColors: {
            transactions: colorPicker(tagUpdateDate, transactions),
            pageViews: colorPicker(tagUpdateDate, pageViews),
            signups: colorPicker(tagUpdateDate, signups),
          },
        },
        appId,
      };
    });

    return tags.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
      const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
      return dateB - dateA;
    });
  }, [dataMultiOrgTags]);

  return React.cloneElement(props.children, {
    ...props.children.props,
    formattedTags,
    requestAssistance,
  });
};

export default TagsMainController;
