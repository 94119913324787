import React from "react";
import { Row, Checkbox, Typography } from "antd";
const { Title, Text } = Typography;
const styles = {
  deviceSelector: {
    borderRadius: "4px",
    padding: "10px",
    border: "1px solid #e8e8e8",
    margin: "15px 10px 10px 0",
  },
  title: {
    color: "#595959",
    margin: 0,
    fontWeight: 400,
    fontSize: "1.15em",
  },
};

const BasicsDevicesCard = ({ devices, onCheckBoxSelect, border }) => {
  return (
    <Row
      style={{
        border: border ? "1px solid #e8e8e8" : 0,
        borderTop: 0,
        padding: "15px",
        backgroundColor: "white",
      }}
    >
      <Title level={4} style={styles.title}>
        Devices
      </Title>
      <Text style={{ color: "#bababa" }} type="secondary">
        What devices do you want this campaign to display on?
      </Text>
      <Row style={{ display: "flex" }}>
        <div style={styles.deviceSelector}>
          <Checkbox
            checked={devices ? devices.includes("MOBILE") : false}
            onChange={e => {
              const { checked } = e.target;
              onCheckBoxSelect("MOBILE", devices, "devices", checked);
            }}
          >
            Mobile Phones
          </Checkbox>
        </div>
        <div style={styles.deviceSelector}>
          <Checkbox
            checked={devices ? devices.includes("TABLET") : false}
            onChange={e => {
              const { checked } = e.target;
              onCheckBoxSelect("TABLET", devices, "devices", checked);
            }}
          >
            Tablets
          </Checkbox>
        </div>
        <div style={styles.deviceSelector}>
          <Checkbox
            checked={devices ? devices.includes("DESKTOP") : false}
            onChange={e => {
              const { checked } = e.target;
              onCheckBoxSelect("DESKTOP", devices, "devices", checked);
            }}
          >
            Desktop
          </Checkbox>
        </div>
      </Row>
    </Row>
  );
};

export default BasicsDevicesCard;
