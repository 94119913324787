import gql from "graphql-tag";

const CAMPAIGN_ORDER_GEO_TARGETS = gql`
  query CampaignOrderDetailsGeotargets($ID: ID) {
    campaignOrder(where: { id: $ID }) {
      id
      geoTargets {
        vwiUpdated
        id
        name
        status
        orgs {
          id
          name
        }
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            province
            country
            lat
            lng
            audienceType
            hasNotes
            notes
          }
          coordinate {
            id
            lat
            lng
          }
        }
        polygons {
          id
          geometry
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
        }
      }
    }
  }
`;

export default CAMPAIGN_ORDER_GEO_TARGETS;
