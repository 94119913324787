import React from "react";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash/flowRight";
// Queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import LIST_INVOICE from "../../GraphQl/Mutations/LIST_INVOICE";
// Locals
import Controller from "./Controller";

const Loader = props => {
  const listInvoice = async providerId => {
    try {
      const response = await props.listInvoice({
        variables: { customerId: providerId.toString() },
      });
      if (response) {
        return response;
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  return (
    <Controller
      {...props}
      currentuser={props.currentUser}
      listInvoice={listInvoice}
    />
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(LIST_INVOICE, { name: "listInvoice" })
)(withApollo(Loader));
