import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

const CampaignDetailsPublisherController = ({
  children,
  publisherDataReport,
  currentUser,
  ...props
}) => {
  const config = currentUser.role.org.config;
  if (config && config.isDemo) {
    publisherDataReport.forEach((c, i) => {
      c.value = `Example Publisher -${i}`;
    });
  }

  const [data, setData] = useState(publisherDataReport);
  const [selectedPublisherDimension, setPublisherDimension] = useState(
    "impressions"
  );

  useEffect(() => {
    if (!isEmpty(publisherDataReport)) {
      setData(publisherDataReport);
    }
  }, [publisherDataReport]);

  return React.cloneElement(children, {
    publisherData: data,
    publisherDataReport,
    selectedPublisherDimension,
    setPublisherDimension,
    ...props,
  });
};

export default CampaignDetailsPublisherController;
