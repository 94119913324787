import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import ADVERTISER from "../../../GraphQl/Queries/ADVERTISER";
import DELETE_ADVERTISER from "../../../GraphQl/Mutations/DELETE_ADVERTISER";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";

const AdvertiserDetailsLoader = props => {
  const history = useHistory();
  const { match, currentUser } = props;
  const { params } = match;
  const { id } = params;
  const { permission } = currentUser;

  const { data, loading } = useQuery(ADVERTISER, {
    variables: { id },
  });

  const [deleteAdvertiser] = useMutation(DELETE_ADVERTISER, {
    variables: {
      where: { id },
    },
    onCompleted: () => {
      history.push("/advertiser/main/1");
    },
  });

  if (loading) return <OverlaySpinner />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertiser: data ? data.advertiser : null,
    deleteAdvertiser,
    permission: permission,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(AdvertiserDetailsLoader));
