import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Checkbox,
  Button,
  Menu,
  Dropdown,
  Input,
  Space,
  DatePicker,
  Tooltip,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import moment from "moment";
// Global styles
import {
  MenuItemText,
  ButtonText,
} from "../../../shared/globalStyling/styledText";
import AdvertiserFilter from "../../../shared/AdvertiserFilter";

const { RangePicker } = DatePicker;

const styles = {
  buttonL: {
    fontSize: "14px",
    width: "100%",
    height: "36px",
  },
  buttonS: {
    fontSize: "14px",
    width: "100%",
    height: "36px",
  },
  search: {
    height: "36px",
    //fontSize: "14px",
  },
  customRangePicker: {
    borderRight: "0.8px solid #e8e8e8",
    borderLeft: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    padding: "1rem 0",
  },
};

const DropDownStatusToggle = withRouter(({ location, history, orgConfig }) => {
  const statuses = [
    "Live",
    "Finished",
    "Attribution",
    "Queued",
    "Draft",
    "Pending",
    "Paused",
  ];

  if (!orgConfig.isSelfService) {
    statuses.splice(5, 1);
    statuses.splice(4, 1);
  }

  const params = new URLSearchParams(location.search);
  const selectedStatus = params.get("status");
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedStatus]}>
          {statuses.map((status, i) => {
            const isSelected = selectedStatus === status.toLowerCase();
            return (
              <Menu.Item
                key={`${status}-${i}`}
                onClick={() => {
                  if (selectedStatus) params.delete("status");
                  if (!isSelected)
                    params.append("status", status.toLowerCase());
                  history.push(`/campaigns/main/1?${params.toString()}`);
                }}
              >
                <Checkbox
                  checked={isSelected}
                  style={{
                    visibility: isSelected ? "visible" : "hidden",
                    paddingRight: "5px",
                  }}
                />
                <MenuItemText text={status} />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Button
        style={styles.buttonL}
        shape="round"
        type={!!selectedStatus ? "primary" : "default"}
        ghost={!!selectedStatus ? true : false}
      >
        <ButtonText text={"Status"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const DropDownKpiToggle = withRouter(({ location, history }) => {
  const params = new URLSearchParams(location.search);
  const selectedEcomm = params.get("ecommerce") ? true : false;
  const selectedSignups = params.get("signups") ? true : false;
  const selectedPageViews = params.get("pageViews") ? true : false;
  const selectedAttribution = params.get("attribution") ? true : false;
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedAttribution]}>
          <Menu.Item
            key={"WalkIns"}
            onClick={search => {
              if (params.get("attribution")) {
                params.delete("attribution");
                history.push(`/campaigns/main/1?${params.toString()}`);
              } else if (search) {
                params.append("attribution", true);
                history.push(`/campaigns/main/1?${params.toString()}`);
              }
            }}
          >
            <Checkbox
              checked={selectedAttribution}
              style={{
                visibility: selectedAttribution ? "visible" : "hidden",
                paddingRight: "5px",
              }}
            />
            <MenuItemText text={"Walk Ins"} />
          </Menu.Item>
          <Menu.Item
            key={"ECommerce"}
            onClick={search => {
              if (params.get("ecommerce")) {
                params.delete("ecommerce");
                history.push(`/campaigns/main/1?${params.toString()}`);
              } else if (search) {
                params.append("ecommerce", true);
                history.push(`/campaigns/main/1?${params.toString()}`);
              }
            }}
          >
            <Checkbox
              checked={selectedEcomm}
              style={{
                visibility: selectedEcomm ? "visible" : "hidden",
                paddingRight: "5px",
              }}
            />
            <MenuItemText text={"E Commerce"} />
          </Menu.Item>
          <Menu.Item
            key={"Sign Ups"}
            onClick={search => {
              if (params.get("signups")) {
                params.delete("signups");
                history.push(`/campaigns/main/1?${params.toString()}`);
              } else if (search) {
                params.append("signups", true);
                history.push(`/campaigns/main/1?${params.toString()}`);
              }
            }}
          >
            <Checkbox
              checked={selectedSignups}
              style={{
                visibility: selectedSignups ? "visible" : "hidden",
                paddingRight: "5px",
              }}
            />
            <MenuItemText text={"Sign Ups"} />
          </Menu.Item>
          <Menu.Item
            key={"Page Views"}
            onClick={search => {
              if (params.get("pageViews")) {
                params.delete("pageViews");
                history.push(`/campaigns/main/1?${params.toString()}`);
              } else if (search) {
                params.append("pageViews", true);
                history.push(`/campaigns/main/1?${params.toString()}`);
              }
            }}
          >
            <Checkbox
              checked={selectedPageViews}
              style={{
                visibility: selectedPageViews ? "visible" : "hidden",
                paddingRight: "5px",
              }}
            />
            <MenuItemText text={"Page Views"} />
          </Menu.Item>
        </Menu>
      }
    >
      <Button
        style={styles.buttonL}
        shape="round"
        type={
          !!selectedEcomm ||
          !!selectedAttribution ||
          !!selectedPageViews ||
          !!selectedSignups
            ? "primary"
            : "default"
        }
        ghost={
          !!selectedEcomm ||
          !!selectedAttribution ||
          !!selectedPageViews ||
          !!selectedSignups
            ? true
            : false
        }
      >
        <ButtonText text={"KPI"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const FiltersBar = withRouter(
  ({ orgs, match, tooltip, location, history, orgConfig, isDemo }) => {
    const [filterDate, setFilterDate] = useState([]);
    //first sort the orgs
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = a.name.toUpperCase();
      const bandB = b.name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
    if (orgs) {
      orgs.sort(compare);
    }

    const searchRef = useRef(null);
    const params = new URLSearchParams(location.search);
    const paramsDate = params.get("date");
    const status = params.get("status");
    const dateFormat = "YYYY/MM/DD";

    const disabledStatus = ["", ""];

    useEffect(() => {
      if (disabledStatus.includes(status)) {
        return;
      }

      if (paramsDate) {
        const dates = paramsDate.split("-");
        setFilterDate([
          moment(dates[0], dateFormat),
          moment(dates[1], dateFormat),
        ]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
      <Row className="filter-bar-container" gutter={[16, 16]}>
        <Col
          xs={{ span: 24, order: 2 }}
          md={{ span: 9, order: 1 }}
          xl={{ span: 4, order: 1 }}
        >
          <AdvertiserFilter isDemo={isDemo} />
        </Col>
        <Col
          xs={{ span: 24, order: 4 }}
          md={{ span: 4, order: 2, offset: 7 }}
          xl={{ span: 3, order: 2, offset: 0 }}
        >
          <DropDownStatusToggle orgConfig={orgConfig} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 4, order: 3 }}
          xl={{ span: 3, order: 3 }}
        >
          <DropDownKpiToggle />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 6, order: 5, offset: 5 }}
          xl={{ span: 4, order: 4, offset: 8 }}
        >
          <Input.Search
            placeholder={"Search"}
            ref={searchRef}
            style={styles.search}
            onSearch={search => {
              if (params.get("search")) params.delete("search");
              if (search) params.append("search", search);
              history.push(`/campaigns/main/1?${params.toString()}`);
            }}
            defaultValue={params.get("search")}
          />
        </Col>
        <Col
          xs={{ span: 24, order: 6 }}
          md={{ span: 4, order: 6 }}
          xl={{ span: 2, order: 5 }}
        >
          <Button
            style={styles.buttonS}
            type={"default"}
            shape="round"
            onClick={() => {
              searchRef.current.input.value = "";
              history.push(`/campaigns/main/1`);
            }}
            disabled={!params.toString()}
            icon={<CloseOutlined />}
          >
            Clear
          </Button>
        </Col>
        <Col
          xs={{ span: 24, order: 3 }}
          md={{ span: 9, order: 4 }}
          xl={{ span: 5, order: 6 }}
        >
          <Space direction="horizontal">
            <RangePicker
              disabled={disabledStatus.includes(status)}
              format={dateFormat}
              value={filterDate}
              onChange={dates => {
                if (!dates && paramsDate) {
                  params.delete("date");
                }

                if (!dates) {
                  history.push(`/campaigns/main/1?${params.toString()}`);
                  return;
                }

                const date1 = dates[0];
                const date2 = dates[1];

                if (paramsDate) {
                  params.delete("date");
                }

                if (date1 && date2) {
                  params.append(
                    "date",
                    `${date1.format(dateFormat)}-${date2.format(dateFormat)}`
                  );
                }
                history.push(`/campaigns/main/1?${params.toString()}`);
              }}
            />
            {tooltip ? (
              <Tooltip placement="right" title={tooltip}>
                <QuestionCircleOutlined style={{ color: "lightgray" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Space>
        </Col>
      </Row>
    );
  }
);

FiltersBar.propTypes = {
  orgs: PropTypes.array,
  selectedKey: PropTypes.string,
};

export { FiltersBar };
