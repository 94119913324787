import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: "40px",
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
  },
  header: {
    fontSize: 12,
    marginTop: 20,
    fontFamily: "Helvetica-Bold",
  },
  description: {
    fontSize: 12,
    marginBottom: 20,
    marginTop: 20,
  },
  code: {
    border: "1px solid #E4E4E4",
    color: "#666",
    fontSize: 11,
    lineHeight: 1.6,
    maxWidth: "100%",
    overflow: "auto",
    padding: "10",
    display: "block",
  },
});
const SignUpsInstructionPDF = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.title}>
            Instructions - Measuring Sign up Events
          </Text>
          <Text style={styles.header}>Tracking Sign-Ups</Text>
          <Text style={styles.description}>
            Execute this javascript on a Sign Up event. The static values above
            are supposed to represent the variables that pertain to the context
            of data you would pass in programmatically from your site. Swap out
            the static values with your own site’s form properties during a Sign
            Up event. The fastest way to start measuring Sign Ups is to place
            this snippet of javascript yourself, however our Integrations Team
            is here to provide assistance if needed.
          </Text>
          <div style={styles.code}>
            <Text>{"<script>"}</Text>
            <Text
              style={{ marginLeft: 15 }}
            >{`window.tracker("trackSelfDescribingEvent", {`}</Text>
            <Text
              style={{ marginLeft: 25 }}
            >{`schema: "iglu:com.mediajel.events/sign_up/jsonschema/1-0-2",`}</Text>
            <Text style={{ marginLeft: 25 }}>{`data: {`}</Text>
            <Text style={{ marginLeft: 35 }}>
              {`uuid: "1234", // user identifier - string,`}
            </Text>
            <Text style={{ marginLeft: 35 }}>
              {`firstName: “john”, // user first name - string,`}
            </Text>
            <Text style={{ marginLeft: 35 }}>
              {`lastName: “evans”, // user last name - string,`}
            </Text>
            <Text style={{ marginLeft: 35 }}>
              {`emailAddress: “evans@gmail.com”, // email address - string,`}
            </Text>
            <Text style={{ marginLeft: 35 }}>
              {`hashedEmailAddress: “evans@gmail.com”, // email address - string,`}
            </Text>
            <Text style={{ marginLeft: 35 }}>
              {`phoneNumber: “+13056863198” // optional, phone number - string,`}
            </Text>
            <Text style={{ marginLeft: 25 }}>{`}`}</Text>
            <Text style={{ marginLeft: 15 }}>{`});`}</Text>
            <Text style={{ marginLeft: 15 }}>{`</script`}</Text>
          </div>
        </View>
      </Page>
    </Document>
  );
};

export default SignUpsInstructionPDF;
