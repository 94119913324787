import gql from "graphql-tag";

const GET_ADGROUP_CREATIVES_LIBRARY = gql`
  query adgroupmedias($where: AdGroupCreativesWhereInput!) {
    adGroupCreativeses(where: $where) {
      id
      adProviderId
      name
      isAmplifyDefault
      updatedAt
      medias(where: { flag: true }) {
        id
        name
        size
        fileType
        height
        width
        key
        url
        type
        org {
          id
          name
        }
        sharedWith {
          name
        }
        mediaNotes
        mediaHasNotes
      }
      flag
      org {
        id
        name
      }
    }
  }
`;

export default GET_ADGROUP_CREATIVES_LIBRARY;
