import gql from "graphql-tag";

const CREATE_MAPPING_RECORD = gql`
  mutation createMappingRecord(
    $segment_id: Int!
    $active: Boolean!
    $data_segment_type_id: String!
    $data_category_id: Int!
    $data_provider_id: Int!
    $isPublic: Boolean!
    $memberId: Int!
  ) {
    addMappingRecord(
      data: {
        segment_id: $segment_id
        active: $active
        data_segment_type_id: $data_segment_type_id
        data_category_id: $data_category_id
        data_provider_id: $data_provider_id
        is_public: $isPublic
        member_id: $memberId
      }
    ) {
      id
      segment_id
      active
      data_segment_type_id
      data_category_id
      data_provider_id
      is_public
      error
    }
  }
`;

export default CREATE_MAPPING_RECORD;
