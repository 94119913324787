import React from "react";
import { Card, Row, Spin } from "antd";
import SignUpsLayout from "./components/SignUpsLayout";

const LoadingCard = () => {
  return (
    <Card>
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    </Card>
  );
};

const SignUpsTab = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  campaignOrder,
  chartData,
  bySignUpRangeDate,
  setSignUpRangeDate,
  dataSignUpRangeDate,
  setDataSignUpRangeDate,
  setbySignUpsAttributionWindow,
  setdataSignUpsDate,
  bySignUpsAttributionWindow,
  dataSignUpsDate,
  reportName,
  signupsTable,
  signupsTableLoading,
  attributionWindow,
  setCurrentIdx,
  setCurrentStartEndDate,
  setAttributionWindow,
}) => {
  if (chartsLoading) {
    return <LoadingCard />;
  }

  return (
    <SignUpsLayout
      colStyle={colStyle}
      selectedCampaign={selectedCampaign}
      campaignOrder={campaignOrder}
      chartData={chartData}
      bySignUpRangeDate={bySignUpRangeDate}
      setSignUpRangeDate={setSignUpRangeDate}
      dataSignUpRangeDate={dataSignUpRangeDate}
      setDataSignUpRangeDate={setDataSignUpRangeDate}
      setbySignUpsAttributionWindow={setbySignUpsAttributionWindow}
      setdataSignUpsDate={setdataSignUpsDate}
      bySignUpsAttributionWindow={bySignUpsAttributionWindow}
      dataSignUpsDate={dataSignUpsDate}
      reportName={reportName}
      signupsTable={signupsTable}
      signupsTableLoading={signupsTableLoading}
      setCurrentIdx={setCurrentIdx}
      setCurrentStartEndDate={setCurrentStartEndDate}
      attributionWindow={attributionWindow}
      setAttributionWindow={setAttributionWindow}
    />
  );
};

export default SignUpsTab;
