import React from "react";
import { graphql, useMutation, useQuery } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_LOCATIONS from "../../../GraphQl/Queries/GET_LOCATIONS";
import CREATE_GEOTARGET_LOCATION from "../../../GraphQl/Mutations/CREATE_GEOTARGET_LOCATION";
import CREATE_LOCATION_WITH_GEOTARGET from "../../../GraphQl/Mutations/CREATE_LOCATION_WITH_GEOTARGET";
import CREATE_RETAIL_LOCATION from "../../../GraphQl/Mutations/CREATE_RETAIL_LOCATION";
import DELETE_LOCATION from "../../../GraphQl/Mutations/DELETE_LOCATION_ADMIN";
import DELETE_AUDIENCE_LOCATION from "../../../GraphQl/Mutations/DELETE_AUDIENCE_LOCATION";
import ATTRIBUTION_AUDIENCES_ORG_CONFIG from "../../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_ORG_CONFIG";
import EDIT_LOCATION_ADMIN_WITH_GEOTARGETS from "../../../GraphQl/Mutations/EDIT_LOCATION_ADMIN_WITH_GEOTARGETS";
import EDIT_LOCATION from "../../../GraphQl/Mutations/EDIT_LOCATION_ADMIN";
import CART_PROVIDER from "../../../GraphQl/Introspection/CART_PROVIDER";
import CREATE_AUDIENCE_LOCATION from "../../../GraphQl/Mutations/CREATE_AUDIENCE_LOCATION";
import UPDATE_MANY_AUDIENCES from "../../../GraphQl/Mutations/UPDATE_MANY_AUDIENCES";
import UPDATE_AUDIENCE from "../../../GraphQl/Mutations/UPDATE_AUDIENCE";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";

const TargetingMainLoader = props => {
  const { currentUser } = props;
  const loggedInOrg = currentUser.role.org;

  const { loading: loadingLocation, data: locationData } = useQuery(
    GET_LOCATIONS,
    {
      variables: { id: loggedInOrg.id },
      fetchPolicy: "no-cache",
    }
  );

  const [createGeoTargetLocation] = useMutation(CREATE_GEOTARGET_LOCATION);

  const createLocationWithGeotarget = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      geoId,
      locationKey,
      audienceType,
      province,
      createdBy,
    } = data;
    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocationWithGeotarget({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          geoId,
          locationKey,
          audienceType,
          province,
          createdBy,
        },
        refetchQueries: [
          {
            query: GET_LOCATIONS,
            variables: { id: loggedInOrg.id },
          },
        ],
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const createLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      lat,
      lng,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      orgId,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationCreateInput!
      const response = await props.createLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          lat,
          lng,
          country,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          orgId,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.createLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [updateLocationWithGeotargets] = useMutation(
    EDIT_LOCATION_ADMIN_WITH_GEOTARGETS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_LOCATIONS,
          variables: { id: loggedInOrg.id },
        },
      ],
    }
  );

  const updateLocation = async data => {
    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      circles,
      polygons,
      start,
      end,
      isBase,
      cart,
      menuDataKey,
      locationKey,
      province,
      createdBy,
    } = data;

    try {
      // TODO: Enhance payload handling for this mutation
      // @params: data: LocationUpdateInput!
      const response = await props.updateLocation({
        variables: {
          name,
          description,
          street,
          city,
          state,
          country,
          lat,
          lng,
          email,
          url,
          phone,
          contact,
          formattedAddress,
          zip,
          id,
          circles,
          polygons,
          start,
          end,
          isBase,
          cart,
          menuDataKey,
          locationKey,
          province,
          createdBy,
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
          data: response.data.updateLocation,
        };
      }
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const { data: cartProvider } = useQuery(CART_PROVIDER);

  const [createSelectedAudienceLocation] = useMutation(
    CREATE_AUDIENCE_LOCATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
        },
        {
          query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
          variables: { TYPE: "ATTRIBUTION", ORGID: loggedInOrg.id },
        },
      ],
    }
  );

  const [updateManyAudiences] = useMutation(UPDATE_MANY_AUDIENCES);

  const [updateAudience] = useMutation(UPDATE_AUDIENCE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ATTRIBUTION_AUDIENCES_ORG_CONFIG,
        variables: { TYPE: "TARGETING", ORGID: loggedInOrg.id },
      },
    ],
  });

  const [deleteAudienceLocation] = useMutation(DELETE_AUDIENCE_LOCATION);

  const { data: attributionAudiences } = useQuery(
    ATTRIBUTION_AUDIENCES_ORG_CONFIG,
    {
      variables: { TYPE: "ATTRIBUTION", ORGID: loggedInOrg.id },
    }
  );

  if (loadingLocation) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentuser: props.currentUser,
    loggedInOrg,
    locationList: locationData,
    createGeoTargetLocation,
    createLocationWithGeotarget,
    createLocation,
    deleteLocation,
    updateLocationWithGeotargets,
    updateLocation,
    cartProvider,
    createSelectedAudienceLocation,
    updateManyAudiences,
    updateAudience,
    attributionAudiences,
    loadingLocation,
    deleteAudienceLocation,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(CREATE_LOCATION_WITH_GEOTARGET, {
    name: "createLocationWithGeotarget",
  }),
  graphql(CREATE_RETAIL_LOCATION, { name: "createLocation" }),
  graphql(EDIT_LOCATION, { name: "updateLocation" })
)(TargetingMainLoader);
