import React from "react";
import { Row, Menu, Dropdown, Button } from "antd";

const styles = {
  axisContainer: {
    position: "absolute",
    bottom: "-30%",
    height: "30%",
    width: "100%",
    padding: "3px",
    zIndex: "666",
  },
  chartTypeContainer: {
    position: "absolute",
    right: "-85%",
    height: "150px",
    bottom: "calc(50% - 75px)",
    width: "84%",
    padding: "1px",
    border: "1px solid #e8e8e8",
    background: "white",
    zIndex: "666",
  },
};
const ChartOptionsButtons = props => {
  const { parentstate, onChange, menuKeys, onStateChange, menudata } = props;
  const { yAxisLeft, yAxisRight, axisCount, chartType } = parentstate;
  const { Item } = Menu;
  const menuLeftAxis = (
    <Menu>
      {menuKeys.map((item, i) => {
        return (
          <Item
            onClick={e => {
              e.domEvent.stopPropagation();
              onChange("yAxisLeft", item, "yAxisLeftDisplay");
            }}
            key={i}
          >
            {item.toUpperCase()}
          </Item>
        );
      })}
    </Menu>
  );

  const menuRightAxis = (
    <Menu>
      {menuKeys.map((item, i) => {
        return (
          <Item
            onClick={e => {
              e.domEvent.stopPropagation();
              onChange("yAxisRight", item, "yAxisRightDisplay");
            }}
            key={i}
          >
            {item.toUpperCase()}
          </Item>
        );
      })}
    </Menu>
  );

  return (
    <React.Fragment>
      <div style={styles.axisContainer}>
        <Row type="flex" justify="center" gutter={5}>
          <Dropdown overlay={menuLeftAxis} placement="topLeft">
            <Button
              style={{ color: "white", backgroundColor: "#1665d8" }}
              onClick={e => e.stopPropagation()}
            >
              {yAxisLeft.toUpperCase()}
            </Button>
          </Dropdown>

          {axisCount === "dual" && (
            <Dropdown overlay={menuRightAxis} placement="topCenter">
              <Button
                style={{ color: "white", backgroundColor: "#34aa44" }}
                onClick={e => e.stopPropagation()}
              >
                {yAxisRight.toUpperCase()}
              </Button>
            </Dropdown>
          )}
        </Row>
      </div>
      <div style={styles.chartTypeContainer}>
        <Menu defaultSelectedKeys={[chartType]}>
          {menudata.map((item, i) => {
            const { chartType, axisCount, name, icon } = item;
            return (
              <Item
                key={chartType}
                onClick={e => {
                  e.domEvent.stopPropagation();
                  onStateChange("chartType", chartType);
                  onStateChange("axisCount", axisCount);
                }}
                style={{ border: "0 1pxsolid #e8e8e8" }}
              >
                {icon} {name}
              </Item>
            );
          })}
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default ChartOptionsButtons;
