import React from "react";
import { Chart, Coord, Geom, Tooltip, Legend } from "bizcharts";

const DonutChart = ({
  axisName = "value",
  position = "counts",
  showTooltip = false,
  chartData = [{ value: "Placeholder", counts: 10, color: "Blue" }],
  height = 300,
  totalCount,
  showLegend = true,
}) => {
  const cols = {
    percent: {
      formatter: val => {
        val = (val * 100).toFixed(2) + "%";
        return val;
      },
    },
    counts: {
      formatter: val => {
        const percentage = totalCount
          ? ` (${((val / totalCount) * 100).toFixed(2)}%)`
          : "";
        return `${val.toLocaleString()}${percentage}`;
      },
    },
  };

  return (
    <Chart
      height={height}
      data={chartData}
      scale={cols}
      padding={[20, 20, 20, 20]}
      forceFit
    >
      <Coord type="theta" radius={0.75} />
      <Tooltip showTitle={false} />
      <Geom
        type="intervalStack"
        position={position}
        color={[
          "value",
          value => {
            const item = chartData.find(d => d.value === value);
            return item ? item.color : "#000";
          },
        ]}
        style={{ lineWidth: 1, stroke: "#fff" }}
      ></Geom>
      {showLegend && (
        <Legend
          position="bottom"
          offsetY={-10}
          itemFormatter={val => {
            return val;
          }}
        />
      )}
    </Chart>
  );
};

export default DonutChart;
