import gql from "graphql-tag";

const LAUNCH_CAMPAIGN_DSP = gql`
  mutation launchCampaignDSP($dspType: DemandSidePlatform, $data: JSON!) {
    launchCampaignDSP(dspType: $dspType, data: $data) {
      id
      status
      dspConfig
      orgs {
        id
      }
    }
  }
`;

export default LAUNCH_CAMPAIGN_DSP;
