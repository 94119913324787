import gql from "graphql-tag";

const SIGNAL_TAGS = gql`
  query SignalTags(
    $wherePublisher: EditorialWhereInput!
    $whereAdvertiser: AdvertiserWhereInput!
    $whereImpressionTag: EventsTargetWhereInput!
    $whereMeasurementsTag: EventsTargetWhereInput!
    $publisherDomainTags: EventsTargetWhereInput!
  ) {
    editorials: editorials(where: $wherePublisher) {
      id
      name
      transactions
      customers
      clicks
      pageView
      trackMesurements
      clicksTag {
        id
        name
        pageViews
        eventTags(first: 1) {
          appId
        }
        orgs(first: 1) {
          id
          name
          website
        }
      }
      transactionsTag {
        id
        name
        transactions
        tagUpdateDate
        eventTags(first: 1) {
          appId
        }
        orgs(first: 1) {
          id
          name
        }
      }
    }
    advertisers: advertisers(where: $whereAdvertiser) {
      id
      name
      transactionsTags {
        id
        eventsTarget {
          id
          name
          transactions
          tagUpdateDate
          eventTags(first: 1) {
            appId
          }
          orgs(first: 1) {
            id
            name
          }
        }
      }
      transactionsTag {
        id
        name
        transactions
        tagUpdateDate
        eventTags(first: 1) {
          appId
        }
        orgs(first: 1) {
          id
          name
        }
      }
    }
    impressionTags: eventsTargets(where: $whereImpressionTag) {
      id
      name
      dsp
      impressionEnvironments
      eventTags(first: 1) {
        id
        appId
      }
    }
    measurementsTags: eventsTargets(where: $whereMeasurementsTag) {
      id
      name
      transactions
      eventTags(first: 1) {
        id
        appId
      }
      orgs(first: 1) {
        id
        name
      }
    }
    publisherDomainTags: eventsTargets(where: $publisherDomainTags) {
      id
      name
      pageViews
      eventTags(first: 1) {
        appId
      }
      orgs(first: 1) {
        id
        name
        website
      }
    }
  }
`;

export default SIGNAL_TAGS;
