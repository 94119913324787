import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";

// MUTATIONS

// QUERIES
import GET_ADGROUP_CREATIVES_LIBRARY from "../../../GraphQl/Queries/GET_ADGROUP_CREATIVES_LIBRARY";

const CreativeContext = createContext({});

export const CreativeProvider = props => {
  const { children } = props;

  const [
    fetchAdGroupCreatives,
    { data: dataAdGroupCreatives, loading: loadingAdGroupCreatives },
  ] = useLazyQuery(GET_ADGROUP_CREATIVES_LIBRARY, {});

  return (
    <CreativeContext.Provider
      value={{
        fetchAdGroupCreatives,
        adGroupCreatives:
          (dataAdGroupCreatives && dataAdGroupCreatives.adGroupCreativeses) ||
          [],
        loadingAdGroupCreatives,
      }}
      {...props}
    >
      {children}
    </CreativeContext.Provider>
  );
};

export default CreativeContext;
