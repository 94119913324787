import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";

// MUTATIONS

// QUERIES
import GET_AUDIENCE_DEMOGRAPHS from "../../GraphQl/Queries/GET_AUDIENCE_DEMOGRAPHS";
import GET_HASHED_EMAIL_LIST_WITH_COUNT from "../../GraphQl/Queries/GET_HASHED_EMAIL_LIST_WITH_COUNT";

const AudienceContext = createContext({});

export const AudienceProvider = props => {
  const { children } = props;

  const [
    fetchAudienceDemographs,
    { data: dataAudienceDemographs, loading: loadingAudienceDemographs },
  ] = useLazyQuery(GET_AUDIENCE_DEMOGRAPHS, {});

  const [
    fetchHashedEmailListWithCount,
    {
      data: dataHashedEmailListWithCount,
      loading: loadingHashedEmailListWithCount,
    },
  ] = useLazyQuery(GET_HASHED_EMAIL_LIST_WITH_COUNT, {
    fetchPolicy: "no-cache",
  });

  return (
    <AudienceContext.Provider
      value={{
        fetchAudienceDemographs,
        audienceDemographs:
          (dataAudienceDemographs &&
            dataAudienceDemographs.audienceDemographs) ||
          [],
        loadingAudienceDemographs,
        fetchHashedEmailListWithCount,
        hashedEmailListWithCount:
          (dataHashedEmailListWithCount &&
            dataHashedEmailListWithCount.getHashedEmailListWithCount &&
            dataHashedEmailListWithCount.getHashedEmailListWithCount
              .hashedEmailList) ||
          [],
        loadingHashedEmailListWithCount,
      }}
      {...props}
    >
      {children}
    </AudienceContext.Provider>
  );
};

export default AudienceContext;
