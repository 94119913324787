import gql from "graphql-tag";

const EDIT_CAMPAIGN_STATUS = gql`
  mutation updateCampaignOrder($id: ID!, $status: CampaignStatus!) {
    updateCampaignOrder(where: { id: $id }, data: { status: $status }) {
      id
      name
      status
    }
  }
`;

export default EDIT_CAMPAIGN_STATUS;
