import React from "react";
import { Row, Col, Typography } from "antd";

const { Text } = Typography;
const Frag = React.Fragment;
const styles = {
  cardRow: {
    padding: "15px",
    borderBottom: "1px solid #e8e8e8",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
};
const { cardRow, secondaryRow, textRow } = styles;

const ReviewOptimization = ({
  objectiveNewCustomers,
  objectiveExistingCustomers,
  retargetDayCap,
  kpi,
  kpiGoal,
  budget,
}) => {
  return (
    <Frag>
      <Row style={cardRow}>
        <Row style={secondaryRow}>
          <Col span={6}>
            <Row style={textRow}>
              <Text type="secondary">Campaign Model</Text>
            </Row>
            {objectiveNewCustomers === 100 && !objectiveExistingCustomers ? (
              <Text>Prospecting</Text>
            ) : !objectiveNewCustomers && objectiveExistingCustomers === 100 ? (
              <Text>Bring Back Visitors</Text>
            ) : (
              <div>
                <Text>Combination</Text>
                <br />
                <Text>Prospecting & Bring Back Visitors</Text>
              </div>
            )}
          </Col>
          <Col span={6}>
            <Row style={textRow}>
              <Text type="secondary">Retargeting</Text>
            </Row>
            {!retargetDayCap ? <Text>Not Enabled</Text> : <Text>Enabled</Text>}
          </Col>
          <Col span={6}>
            <Row style={textRow}>
              <Text type="secondary">Budget Allocation</Text>
            </Row>
            {!objectiveNewCustomers && !objectiveExistingCustomers ? (
              <Text>Automatic</Text>
            ) : (
              <Text>Manual</Text>
            )}
          </Col>
          <Col span={6}>
            <Row style={textRow}>
              <Text type="secondary">Optimization</Text>
            </Row>
            <Row>
              {kpi === "CPM" ? (
                <Text>CPM</Text>
              ) : kpi === "CPC" ? (
                <Text>CPC</Text>
              ) : (
                <Text>CPA</Text>
              )}
            </Row>
            <Row>
              <Text>Daily Budget: ${budget}</Text>
              <br />
              <Text>
                {kpi} Goal: ${kpiGoal}
              </Text>
            </Row>
          </Col>
        </Row>
      </Row>
    </Frag>
  );
};

export default ReviewOptimization;
