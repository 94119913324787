import React from "react";
import { determineUserAccess } from "../../permissions/userLevelAccess";
import { orgLevelCustomization } from "../../permissions/orgLevelCustomization";

const SeoController = props => {
  const { currentUser, currentUserError, currentUserLoading } = props;
  //Login User
  const user = currentUser ? currentUser.currentUser : null;
  //Dafault Table Config for SEO
  const tableConfig = {
    pageSize: 10,
    datakeys: [],
  };

  //User Permissions and Customization
  const userPermission = determineUserAccess(user.permission);
  const orgCustomization = orgLevelCustomization(
    user.role.org.dataConfig || {}
  );
  const currentOrgId = user.role.org.id;

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentUser: user,
    currentUserError,
    currentUserLoading,
    userPermission,
    orgCustomization,
    currentOrgId,
    tableConfig,
  });
};

export default SeoController;
