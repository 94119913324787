import React from "react";
import { Upload, message } from "antd";
import { Storage } from "aws-amplify";
import compose from "lodash/flowRight";
import { graphql } from "react-apollo";
import { getUploadedFileDimensions } from "../../../../core/utils/dataManipulating/images";
import PropTypes from "prop-types";
import CREATE_MEDIA_ADMIN from "../../../../core/GraphQl/Mutations/CREATE_MEDIA_ADMIN";
import dimensionValidation from "../../../shared/upload/dimensionValidation";
import { campaignTypeMap } from "../../../../core/utils/constants/constants";

const BannerUploader = ({
  adUnit,
  button,
  index,
  bulkCreateCreatives,
  type: campaignType,
}) => {
  const medias = [];
  const invalidMedias = [];
  const uploaderProps = {
    name: "file",
    action: null,
    multiple: true,
    beforeUpload: async (file, fileList) => {
      try {
        // TODO: Refactor code to a single function that will be called in DragUploader and ButtonUploader
        message.destroy();
        message.loading("Processing creatives...", 0);

        // Put uploaded image into assets S3 bucket
        const { name, size, type } = file;

        if (campaignType !== campaignTypeMap.VIDEO && type === "video/mp4") {
          message.destroy();
          message.error("File must be an image");
          return;
        }

        if (campaignType === campaignTypeMap.VIDEO && type !== "video/mp4") {
          message.destroy();
          message.error("File must be mp4");
          return;
        }

        // If Campaign type is VIDEO, allow files more than 2MB
        if (size > 2000000) {
          message.destroy();
          message.error("File can't exceed 2MB");
          return;
        }

        const fname = `${new Date().getTime()}-${file.name}`;
        const response = await Storage.put(fname, file, {
          contentType: "image/*",
        });
        const { key } = response;
        const url = await Storage.get(response.key, { expires: 600000 });

        // Get image width and height
        const {
          width: imageWidth,
          height: imageHeight,
        } = await getUploadedFileDimensions(file);

        let fileType = key.split(".")[1].toUpperCase();

        // Put S3 image into MySQL DB
        if (dimensionValidation(imageWidth, imageHeight) === false) {
          invalidMedias.push(file.uid);
        } else {
          medias.push({
            type: "STILL",
            key,
            url,
            width: imageWidth,
            height: imageHeight,
            name,
            size,
            fileType,
          });
        }

        const validFiles = fileList.filter(
          fList => !invalidMedias.includes(fList.uid)
        );

        if (medias.length === validFiles.length) {
          bulkCreateCreatives({ medias, invalidMedias, adUnitIndex: index });
        }
      } catch (error) {
        message.error("Error uploading file");
      }
    },
    showUploadList: false,
    accept: ".png, .jpeg, .jpg, .gif, .avif",
    customRequest: async () => {
      setTimeout(() => {
        return true;
      }, 0);
    },
  };
  return <Upload {...uploaderProps}>{button}</Upload>;
};

BannerUploader.propTypes = {
  image: PropTypes.object,
  onChangeArrayOfObj: PropTypes.func,
  adUnit: PropTypes.object,
  button: PropTypes.node,
  index: PropTypes.number,
  onChange: PropTypes.func,
  handleUpdateCampaignOrder: PropTypes.func,
};

export default compose(
  graphql(CREATE_MEDIA_ADMIN, { name: "createMediaMutation" })
)(BannerUploader);
