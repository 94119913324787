import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { RocketOutlined, BulbOutlined } from "@ant-design/icons";

import {
  onboardingItems,
  learningHubItems,
} from "../../.././core/utils/constants/constants";
const { Text } = Typography;

const styles = {
  text: {
    color: "#007BFF",
    textDecorationLine: "none",
    fontWeight: "bold",
  },
  textSecondary: {
    color: "grey",
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: "20px",
  },
  icon: { marginRight: 10, fontSize: "120%", color: "gray" },

  rowSpace: { marginTop: "40px" },
};

const KnowledgeBaseView = () => {
  return (
    <React.Fragment>
      <Card
        style={{
          width: "100%",
          padding: "50px",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 40, fontWeight: "bold", color: "#16223B" }}>
          MediaJel Knowledge Base
        </Text>
        <br />
        <Text style={{ color: "gray" }}>Browse the knowledge base below</Text>
      </Card>
      <br />
      <br />
      <Row>
        <Col span={16} offset={4}>
          <Card
            style={{
              width: "100%",
            }}
          >
            <span style={styles.header}>
              <RocketOutlined style={styles.icon} />
              Onboarding
            </span>

            <Row style={styles.rowSpace}>
              {onboardingItems.map((item, index) => (
                <Col
                  span={12}
                  style={{ paddingBottom: "30px", padding: "10px" }}
                >
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <span style={styles.text}>{item.title}</span>
                  </a>
                  <br />
                  <span style={styles.textSecondary}>{item.description}</span>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={16} offset={4}>
          <Card
            style={{
              width: "100%",
            }}
          >
            <span style={styles.header}>
              <BulbOutlined style={styles.icon} />
              Learning Hub
            </span>

            <Row style={styles.rowSpace}>
              {learningHubItems.map((item, index) => (
                <Col
                  span={12}
                  style={{ paddingBottom: "30px", padding: "10px" }}
                >
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <span style={styles.text}>{item.title}</span>
                  </a>
                  <br />
                  <span style={styles.textSecondary}>{item.description}</span>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </React.Fragment>
  );
};
export default KnowledgeBaseView;
