import React from "react";
import { Row, Col, Card } from "antd";
import TargetingCTRTable from "./TargetingCTRTable";
import TargetingRingChart from "./TargetingRingChart";

// Global Styling
import { CardTitleText } from "../../../../../shared/globalStyling/styledText";
//colors
import { colors } from "../../../../../../core/utils/constants/listOfColors";

const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const {
  xs,
  sm,
  md,
  lg,
  xl,
  xsPie,
  smPie,
  mdPie,
  lgPie,
  xlPie,
  keyColors,
  campaignCardStyleCreatives,
} = Style;

const TargetingBreakdownDetails = props => {
  const { selectedPublisherDimension, chartData, reachData } = props;

  const colorValuePairs = chartData.map((el, index) => {
    index = index + 3;
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Creative ${index}`,
      color: colors[index],
    };
  });

  const colorValuePairsReachData = reachData.map((el, index) => {
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Creative ${index}`,
      color: colors[index],
    };
  });

  if (colorValuePairsReachData) {
    //Sort the audience break down data appropriately
    colorValuePairsReachData.sort(function(a, b) {
      return b.IDENTIFIERS - a.IDENTIFIERS;
    });
  }

  const ringChartData = colorValuePairs.concat(colorValuePairsReachData);

  if (colorValuePairs.length === 0) return null;

  return (
    <Row gutter={35}>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={campaignCardStyleCreatives}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Device Targeting"} />}
        >
          <TargetingCTRTable chartData={colorValuePairsReachData} />
        </Card>
        <br />

        {true ? ( //TODO - Bring back distribution after seperation of household vs. individual
          <Card
            style={campaignCardStyleCreatives}
            headStyle={{ borderWidth: "3px", height: "66px" }}
            bodyStyle={{ padding: 0 }}
            title={<CardTitleText text={"Additional Network Distribution"} />}
          >
            <TargetingCTRTable chartData={colorValuePairs} />
          </Card>
        ) : null}
      </Col>
      <Col xs={xsPie} sm={smPie} md={mdPie} lg={lgPie} xl={xlPie}>
        <Card
          style={campaignCardStyleCreatives}
          bodyStyle={{ padding: 0, height: "100%" }}
          headStyle={{ borderWidth: "2px", height: "66px" }}
          title={<CardTitleText text={"Targeting Distribution"} />}
        >
          <TargetingRingChart
            data={ringChartData}
            keyColors={keyColors}
            height={322}
            selectedPublisherDimension={selectedPublisherDimension}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TargetingBreakdownDetails;
