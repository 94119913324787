import { Button, Popover } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";

const content = ({
  step,
  handleVisibleChange,
  handleStepChange,
  source,
  feature,
  handleOnFinishTimer,
}) => {
  return (
    <div>
      {step === 1 ? (
        <p>
          You can use these tools to draw polygons
          <br />
          or circles for your <b>{feature}.</b>
        </p>
      ) : (
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <img src={source} alt="demo" />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => handleVisibleChange(false)}
          style={{ marginRight: "12px" }}
        >
          Close
          {step === 1 && (
            <span style={{ marginLeft: 4 }}>
              <Timer onFinish={handleOnFinishTimer} />
            </span>
          )}
        </Button>
        {step === 1 && (
          <Button onClick={() => handleStepChange()} type="primary">
            See Demo
          </Button>
        )}
      </div>
    </div>
  );
};

const DrawingToolsDemoPopup = ({ style, source, feature }) => {
  const [visible, setVisible] = useState(false);
  const [step, setCurrentStep] = useState(1);
  const [expire, setExpire] = useState(false);
  const demoRef = useRef(null);

  const isInViewport = useIsInViewport(demoRef);

  const handleVisibleChange = newVisible => {
    if (!newVisible) {
      setExpire(true);
    }
    setVisible(newVisible);
  };

  const handleStepChange = () => {
    setCurrentStep(step === 1 ? 2 : 1);
  };

  const handleOnFinishTimer = () => {
    if (step === 1) {
      setVisible(false);
      setExpire(true);
    }
  };

  useEffect(() => {
    if (isInViewport) {
      setVisible(true);
    }
  }, [isInViewport]);

  return (
    <div ref={demoRef}>
      {visible && !expire && (
        <Popover
          ref={demoRef}
          content={content({
            step,
            handleVisibleChange,
            handleStepChange,
            handleOnFinishTimer,
            source,
            feature,
          })}
          title="Drawing Manager Tools"
          visible={visible && !expire}
        >
          <span style={style}></span>
        </Popover>
      )}
    </div>
  );
};

export default DrawingToolsDemoPopup;

const useIsInViewport = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

const Timer = ({ delayResend = "10", onFinish }) => {
  const [delay, setDelay] = useState(+delayResend);
  const seconds = Math.floor(delay % delayResend);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
      onFinish();
    }

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <>
      <span>{seconds ? seconds : 10}</span>
    </>
  );
};
