import React from "react";
import { graphql, useMutation, useQuery } from "react-apollo";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import CREATE_ORG_MEDIA from "../../../GraphQl/Mutations/CREATE_ORG_MEDIA";
import UPDATE_MANY_MEDIAS from "../../../GraphQl/Mutations/UPDATE_MANY_MEDIAS";
import GET_ORG_MEDIAS from "../../../GraphQl/Queries/GET_ORG_MEDIAS";
import GET_ADGROUP_CREATIVES from "../../../GraphQl/Queries/GET_ADGROUP_CREATIVES";
import GET_ADGROUP_CREATIVES_BY_MEDIA from "../../../GraphQl/Queries/GET_ADGROUP_CREATIVES_BY_MEDIA";
import DELETE_ADGROUP_CREATIVES from "../../../GraphQl/Mutations/DELETE_ADGROUP_CREATIVES";
import CREATE_ADGROUP_CREATIVES from "../../../GraphQl/Mutations/CREATE_ADGROUP_CREATIVES";
import UPDATE_MANY_AD_GROUP_CREATIVES from "../../../GraphQl/Mutations/UPDATE_MANY_AD_GROUP_CREATIVES";
import UPDATE_AD_GROUP_CREATIVES from "../../../GraphQl/Mutations/UPDATE_AD_GROUP_CREATIVES";
import CREATE_MANY_ORG_MEDIA from "../../../GraphQl/Mutations/CREATE_MANY_ORG_MEDIA";
import EDIT_ORG_MEDIA from "../../../GraphQl/Mutations/EDIT_ORG_MEDIA";

const CreativesMainLoader = props => {
  const { currentUser } = props;
  const loggedInOrg = currentUser.role.org;

  const [createOrgMedia] = useMutation(CREATE_ORG_MEDIA, {
    variables: {
      orgId: loggedInOrg.id,
    },
  });

  const [updateManyMedias] = useMutation(UPDATE_MANY_MEDIAS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
      {
        query: GET_ADGROUP_CREATIVES,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const { refetch: getAdGroupsByMedia } = useQuery(
    GET_ADGROUP_CREATIVES_BY_MEDIA
  );

  const [deleteAdGroupCreatives] = useMutation(DELETE_ADGROUP_CREATIVES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const [createAdGroupCreatives] = useMutation(CREATE_ADGROUP_CREATIVES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const [updateManyAdGroupCreatives] = useMutation(
    UPDATE_MANY_AD_GROUP_CREATIVES,
    {
      variables: {
        orgId: loggedInOrg.id,
      },
    }
  );

  const [updateAdGroupCreatives] = useMutation(UPDATE_AD_GROUP_CREATIVES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ADGROUP_CREATIVES,
        variables: {
          orgId: loggedInOrg.id,
        },
      },
    ],
  });

  const { data: adGroupCreatives } = useQuery(GET_ADGROUP_CREATIVES, {
    variables: {
      orgId: loggedInOrg.id,
    },
  });

  const validateAdGroupCreatives =
    adGroupCreatives && adGroupCreatives.adGroupCreativeses;

  const { data: orgMedias, refetch: refetchMedias } = useQuery(GET_ORG_MEDIAS, {
    variables: { orgId: loggedInOrg.id, type: "STILL" },
  });

  const validateOrgMedias = orgMedias && orgMedias.medias;

  const [createManyOrgMedia] = useMutation(CREATE_MANY_ORG_MEDIA, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id },
      },
    ],
  });

  const [updateOrgMedia] = useMutation(EDIT_ORG_MEDIA, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORG_MEDIAS,
        variables: { orgId: loggedInOrg.id, type: "STILL" },
      },
      { query: GET_ADGROUP_CREATIVES, variables: { orgId: loggedInOrg.id } },
    ],
  });

  const { data: orgVideoMedias, refetch: refetchVideoMedias } = useQuery(
    GET_ORG_MEDIAS,
    {
      variables: { orgId: loggedInOrg.id, type: "VIDEO" },
    }
  );

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentuser: currentUser,
    loggedInOrg,
    createOrgMedia,
    updateManyMedias,
    getAdGroupsByMedia,
    deleteAdGroupCreatives,
    createAdGroupCreatives,
    updateManyAdGroupCreatives,
    updateAdGroupCreatives,
    adGroupCreatives: validateAdGroupCreatives,
    orgMedias: validateOrgMedias,
    createManyOrgMedia,
    refetchMedias,
    updateOrgMedia,
    orgVideoMedias,
    refetchVideoMedias,
  });
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(CreativesMainLoader);
