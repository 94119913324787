import { Input, Row, Col /*, Select, Typography*/ } from "antd";
import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
// const { Text } = Typography;
// const { Option } = Select;

const FilterBar = ({ location, history }) => {
  const searchRef = useRef(null);
  const params = new URLSearchParams(location.search);

  return (
    <Row>
      {/* <Col>
        <Text style={{ marginRight: "10px" }} type="title">
          Attribution Window
        </Text>
        <Select defaultValue="7" style={{ width: 120, marginRight: "20px" }}>
          <Option value="7">Last 7 days</Option>
          <Option value="30">Last 30 days</Option>
          <Option value="90">Last 3 months</Option>
          <Option value="180">Last 6 months</Option>
          <Option value="365">Last 1 year</Option>
        </Select>
      </Col> */}
      <Col>
        <Input.Search
          placeholder={"Search Advertiser Funnels"}
          ref={searchRef}
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          allowClear
          onSearch={search => {
            if (params.get("search")) params.delete("search");
            if (search) {
              params.append("search", search);
              history.push(`/advertiser/main/1?${params.toString()}`);
            } else {
              history.push(`/advertiser/main/1`);
            }
          }}
          defaultValue={params.get("search")}
        />
      </Col>
    </Row>
  );
};

export default withRouter(FilterBar);
