import React from "react";
import TagsManagerLoader from "./TagsManagerLoader";
import TagsManagerController from "./TagsManagerController";
import TagsManagerView from "../../../../platform/ux/Tags/manager/TagsManagerView";
import TagsLoader from "../TagsLoader";
import TagsController from "../TagsController";
import TagsManagerImpressionsLoader from "./TagsManagerImpressionsLoader";
import TagsManagerImpressionsController from "./TagsManagerImpressionsController";
import TagsManagerPublisherController from "./TagsManagerPublisherController";
import TagsManagerPublisherLoader from "./TagsManagerPublisherLoader";
import TagsPublisherDomainManagerView from "../../../../platform/ux/Tags/manager/TagsPublisherDomainManagerView";

export const TagsManagerMeasurements = ({ headerSubmit = true }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerLoader>
          <TagsManagerController>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagsToCreate={"Measurement"}
            />
          </TagsManagerController>
        </TagsManagerLoader>
      </TagsController>
    </TagsLoader>
  );
};

export const TagsManagerImpressions = ({ headerSubmit, onSuccess }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerImpressionsLoader>
          <TagsManagerImpressionsController onSuccess={onSuccess}>
            <TagsManagerView
              headerSubmit={headerSubmit}
              tagsToCreate={"Impression"}
            />
          </TagsManagerImpressionsController>
        </TagsManagerImpressionsLoader>
      </TagsController>
    </TagsLoader>
  );
};

export const TagsManagerPublisherDomain = ({ headerSubmit, onSuccess }) => {
  return (
    <TagsLoader>
      <TagsController>
        <TagsManagerPublisherLoader>
          <TagsManagerPublisherController onSuccess={onSuccess}>
            <TagsPublisherDomainManagerView headerSubmit={headerSubmit} />
          </TagsManagerPublisherController>
        </TagsManagerPublisherLoader>
      </TagsController>
    </TagsLoader>
  );
};
