import moment from "moment";
import React, { useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

const SEOAnalyticsController = ({
  children,
  loading,
  error,
  data,
  orgConfig,
  advertiserParams,
  selectedStartDate,
  selectedEndDate,
  READ,
  WRITE,
  prevRangeData,
  ...props
}) => {
  const history = useHistory();
  const isGoogleAds = (orgConfig && orgConfig.processWithGoogleAds) || false;
  const { priceVisible, exportData } = orgConfig;

  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);
  const [selectedRangeKey, setSelectedRangeKey] = useState("Custom");

  const diffPcts = useMemo(() => {
    let dataDiffs = {
      sessions: 0,
      newUsers: 0,
      pagesOverSession: 0,
      bounceRate: 0,
      avgSessionDuration: 0,
      conversionRate: 0,
    };

    let roiDiffs = {
      revenue: 0,
      transactions: 0,
      avgOrderValue: 0,
      transactionsPerSession: 0,
      ecomConversionRate: 0,
    };

    const prev = prevRangeData
      ? prevRangeData.getRollUpSEOAnalytics
      : undefined;
    const now = data ? data : undefined;

    if (prev && now) {
      dataDiffs.sessions = now.data.sessions - prev.data.sessions;
      dataDiffs.newUsers = now.data.newUsers - prev.data.newUsers;
      dataDiffs.pagesOverSession =
        now.data.pagesOverSession - prev.data.pagesOverSession;
      dataDiffs.bounceRate = now.data.bounceRate - prev.data.bounceRate;
      dataDiffs.avgSessionDuration =
        now.data.avgSessionDuration - prev.data.avgSessionDuration;
      dataDiffs.conversionRate =
        now.data.conversionRate - prev.data.conversionRate;

      roiDiffs.revenue = now.roi.revenue - prev.roi.revenue;
      roiDiffs.transactions = now.roi.transactions - prev.roi.transactions;
      roiDiffs.avgOrderValue = now.roi.avgOrderValue - prev.roi.avgOrderValue;
      roiDiffs.transactionsPerSession =
        now.roi.transactionsPerSession - prev.roi.transactionsPerSession;
      roiDiffs.ecomConversionRate =
        now.roi.ecomConversionRate - prev.roi.ecomConversionRate;

      return {
        data: {
          sessions: (dataDiffs.sessions / now.data.sessions) * 100,
          newUsers: (dataDiffs.newUsers / now.data.newUsers) * 100,
          pagesOverSession:
            (dataDiffs.pagesOverSession / now.data.pagesOverSession) * 100,
          bounceRate: (dataDiffs.bounceRate / now.data.bounceRate) * 100,
          avgSessionDuration:
            (dataDiffs.avgSessionDuration / now.data.avgSessionDuration) * 100,
          conversionRate:
            (dataDiffs.conversionRate / now.data.conversionRate) * 100,
        },
        roi: {
          revenue: (roiDiffs.revenue / now.roi.revenue) * 100,
          transactions: (roiDiffs.transactions / now.roi.transactions) * 100,
          avgOrderValue: (roiDiffs.avgOrderValue / now.roi.avgOrderValue) * 100,
          transactionsPerSession:
            (roiDiffs.transactionsPerSession / now.roi.transactionsPerSession) *
            100,
          ecomConversionRate:
            (roiDiffs.ecomConversionRate / now.roi.ecomConversionRate) * 100,
        },
      };
    }
    return null;
  }, [prevRangeData, data]);

  const defaultSummaryDates =
    selectedStartDate && selectedEndDate
      ? [moment(selectedStartDate), moment(selectedEndDate)]
      : !isEmpty(data.byDate)
      ? [
          moment(new Date(data.byDate[0].xAxis))
            .startOf("d")
            .utc(),
          moment(new Date(data.byDate.at(-1).xAxis))
            .endOf("d")
            .utc(),
        ]
      : [
          moment()
            .startOf("d")
            .utc(),
          moment()
            .endOf("d")
            .utc(),
        ];

  const setStartAndEndDate = dates => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("startDate")) params.delete("startDate");
    if (params.get("endDate")) params.delete("endDate");
    if (params.get("advertiserId") && advertiserParams)
      params.delete("advertiserId");

    if (!isEmpty(dates)) {
      params.append("startDate", dates[0].format("YYYY-MM-DD"));
      params.append("endDate", dates[1].format("YYYY-MM-DD"));
      if (advertiserParams) params.append("advertiserId", advertiserParams);

      const pathname = window.location.pathname.toString();
      if (pathname === "/home/analytics/seo") {
        history.push(`${pathname}?${params.toString()}`);
      }
    }
  };

  const setBySummaryRangeDate = (dates, range) => {
    if (range === "All Time") {
      const params = new URLSearchParams(window.location.search);
      if (params.get("startDate")) params.delete("startDate");
      if (params.get("endDate")) params.delete("endDate");

      const pathname = window.location.pathname.toString();
      if (pathname === "/home/analytics/seo") {
        history.push(`${pathname}?${params.toString()}`);
      }
    } else {
      setControllerbySummaryRangeDate(dates);
      setStartAndEndDate(dates);
      setSelectedRangeKey(range);
    }
  };

  if (!bySummaryRangeDate.length) {
    const dates = [
      moment(defaultSummaryDates[0])
        .startOf("d")
        .utc(),
      moment(defaultSummaryDates[1])
        .endOf("d")
        .utc(),
    ];

    setControllerbySummaryRangeDate(dates);
  }

  return React.cloneElement(children, {
    history,
    loading,
    error,
    data,
    orgConfig,
    advertiserParams,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    priceVisible,
    exportData,
    defaultSummaryDates,
    isGoogleAds,
    READ,
    WRITE,
    diffPcts,
    selectedRangeKey,
    ...props,
  });
};

export default SEOAnalyticsController;
