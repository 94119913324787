import { useContext } from "react";
import CampaignRequestorContext from "./CampaignRequestorProvider";

export const useCampaignRequestorContext = () => {
  const context = useContext(CampaignRequestorContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
