import gql from "graphql-tag";

const EDITORIAL_TRANSACTIONS_CONNECTIONS = gql`
  query EditorialTransactionsConnections(
    $where: EditorialTransactionsWhereInput
    $skip: Int
    $first: Int
    $orderBy: EditorialTransactionsOrderByInput
  ) {
    editorialTransactionsesConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          orderId
          orderTime
          campaign
          pageURL
          total
          clicks
          domainUserId
          eventId
          fingerprint
          networkUserId
          useragent
          ipAddress
          attributionMethod
          attributionValue
          clicksList {
            viewTime
            pageURL
            domainUserId
            eventId
            fingerprint
            networkUserId
            useragent
            ipAddress
          }
        }
        cursor
      }
      aggregate {
        count
      }
    }
    full: editorialTransactionsesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default EDITORIAL_TRANSACTIONS_CONNECTIONS;
