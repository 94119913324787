import React, { useState } from "react";
import { Modal, Button, Space, Input, Form, Typography, message } from "antd";
import "./styles.css";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";

const { TextArea } = Input;
const { Text } = Typography;

const CampaignOrderQueueReviewNotesDialog = ({ visible, setVisible }) => {
  const [form] = Form.useForm(); // Create form instance)
  const {
    currentCampaignOrder,
    updateCampaignOrder,
    loadingUpdateCampaignOrder,
  } = useCampaignRequestorContext();

  const handleOk = async () => {
    const values = form.getFieldsValue();
    try {
      message.loading("Adding note...", 0);
      await updateCampaignOrder({
        variables: {
          where: {
            id: currentCampaignOrder.id,
          },
          data: {
            reviewNotes: values.note,
          },
        },
      });

      setVisible(false);
      message.destroy();
      message.success("Note added successfully");
    } catch (error) {
      message.destroy();
      message.error("Error adding note");
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [charCount, setCharCount] = useState(0);

  const handleTextChange = e => {
    setCharCount(e.target.value.length);
  };

  return (
    <div>
      <Modal
        visible={visible}
        bodyStyle={{ padding: 20 }}
        width={600}
        title="ADD NOTE"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingUpdateCampaignOrder}
            onClick={handleOk}
          >
            Send
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            note: currentCampaignOrder.reviewNotes || "",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Text>Write your note in the field below</Text>
            <Form.Item
              name="note"
              rules={[
                {
                  max: 300,
                  message: "Note cannot be longer than 300 characters",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter note"
                maxLength={300}
                onChange={handleTextChange}
              />
            </Form.Item>
          </Space>
          <Text type="secondary">{charCount}/300</Text>
        </Form>
      </Modal>
    </div>
  );
};

export default CampaignOrderQueueReviewNotesDialog;
