import React, { useEffect, useState } from "react";
import { Col, Row, Card, Table } from "antd";
import DetailBar from "../../components/DetailBar";
import ConversionLineChart from "../../components/ConversionsLineChart";
import ConversionBarChart from "../../components/ConversionsBarChart";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../shared/globalStyling/styledText";
import { useSEOConversionsContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOConversionsContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import { formatWholeNumber } from "../../../../../../core/utils/campaigns";
import moment from "moment";

const ConversionsView = ({ selectedDate, setSelectedDate, setReportData }) => {
  const {
    conversionsSummary,
    conversionsByDate,
    reportLoading,
    conversionsBySource,
    secondsToFormatted,
    startDateEnDate,
    onDateChange,
    keyEventsByTraffic,
  } = useSEOConversionsContext();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (
      selectedDate &&
      selectedDate.length > 0 &&
      startDateEnDate.length > 0 &&
      (!selectedDate[0].isSame(startDateEnDate[0]) ||
        !selectedDate[1].isSame(startDateEnDate[1])) &&
      !reportLoading
    ) {
      onDateChange(selectedDate);
    }
  }, [onDateChange, reportLoading, selectedDate, startDateEnDate]);

  useEffect(() => {
    if (
      !reportLoading &&
      conversionsByDate &&
      conversionsByDate.length > 0 &&
      initialLoad
    ) {
      const startDate = conversionsByDate[0].date;
      const endDate = conversionsByDate[conversionsByDate.length - 1].date;
      setSelectedDate([moment(startDate), moment(endDate)]);
      if (conversionsBySource.length > 0) {
        setReportData(
          conversionsBySource.map(item => ({
            "Traffic Source": item.trafficSource,
            Conversions: item.conversions,
            "New Users": item.newUsers,
            "Total Users": item.totalUsers,
            Engagement: item.userEngagement,
          }))
        );
      }
      setInitialLoad(false);
    }
  }, [
    conversionsByDate,
    conversionsBySource,
    initialLoad,
    reportLoading,
    setReportData,
    setSelectedDate,
  ]);

  useEffect(() => {
    if (conversionsByDate && conversionsByDate.length > 0 && !initialLoad) {
      setReportData(
        conversionsBySource.map(item => ({
          "Traffic Source": item.trafficSource,
          Conversions: item.conversions,
          "New Users": item.newUsers,
          "Total Users": item.totalUsers,
          Engagement: item.userEngagement,
        }))
      );
    }
  }, [conversionsByDate, conversionsBySource, initialLoad, setReportData]);

  if (reportLoading) return <LoadingPage />;

  const cols2 = {
    conversions: {
      min: 0,
      range: [0, 0.93],
    },
    date: {
      range: [0, 0.9],
    },
  };

  const columns = [
    {
      title: "Traffic Source",
      dataIndex: "trafficSource",
      key: "trafficSource",
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: text => formatWholeNumber(text),
    },
    {
      title: "New Users",
      dataIndex: "newUsers",
      key: "newUsers",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Total Users",
      dataIndex: "totalUsers",
      key: "totalUsers",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Engagement",
      dataIndex: "userEngagement",
      key: "userEngagement",
    },
  ];

  const keyEventsColumns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Organic Search",
      dataIndex: "organic",
      key: "organic",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Direct",
      dataIndex: "none",
      key: "none",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Referral",
      dataIndex: "referral",
      key: "referral",
      render: text => formatWholeNumber(text),
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      render: text => formatWholeNumber(text),
    },
    {
      title: "(not set)",
      dataIndex: "notSet",
      key: "notSet",
      render: text => formatWholeNumber(text),
    },
  ];

  const totalConversions = conversionsSummary
    ? conversionsSummary.values[0].value
    : 0;

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DetailBar dataToDisplay={conversionsSummary} />
        </Col>
        <Col span={12}>
          <Card title={"CONVERSIONS"}>
            <div style={{ marginBottom: "20px" }}>
              <KPITitleText text={"Conversions"} />
              <KpiSubText text={totalConversions} />
            </div>
            <ConversionLineChart data={conversionsByDate} scale={cols2} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title={"CONVERSIONS BY SOURCE"}>
            <div style={{ marginBottom: "20px" }}>
              <KPITitleText text={"Conversions"} />
              <KpiSubText text={totalConversions} />
            </div>
            <ConversionBarChart data={conversionsBySource} />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={"KEY EVENTS BY TRAFFIC SOURCES"}>
            <Table
              style={{ width: "100%" }}
              columns={keyEventsColumns}
              dataSource={keyEventsByTraffic}
              size={"default"}
              scroll={{ x: 300 }}
              pagination={false}
              summary={record => {
                const totalSummary = record.reduce(
                  (acc, item) => {
                    acc.total += item.total || 0;
                    acc.organic += item.organic || 0;
                    acc.none += item.none || 0;
                    acc.referral += item.referral || 0;
                    acc.cpc += item.cpc || 0;
                    acc.notSet += item.notSet || 0;
                    return acc;
                  },
                  {
                    total: 0,
                    organic: 0,
                    none: 0,
                    referral: 0,
                    cpc: 0,
                    notSet: 0,
                  }
                );

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total:</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {formatWholeNumber(totalSummary.total)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {formatWholeNumber(totalSummary.organic)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        {formatWholeNumber(totalSummary.none)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        {formatWholeNumber(totalSummary.referral)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        {formatWholeNumber(totalSummary.cpc)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        {formatWholeNumber(totalSummary.notSet)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={"TOTAL CONVERSIONS BY TRAFFIC SOURCES"}>
            <Table
              style={{ width: "100%" }}
              columns={columns}
              dataSource={conversionsBySource}
              size={"default"}
              scroll={{ x: 300 }}
              summary={record => {
                const totalSummary = {
                  conversions: 0,
                  newUsers: 0,
                  totalUsers: 0,
                  userEngagementSeconds: 0,
                };

                record.forEach(item => {
                  totalSummary.conversions += item.conversions;
                  totalSummary.newUsers += item.newUsers;
                  totalSummary.totalUsers += item.totalUsers;
                  totalSummary.userEngagementSeconds +=
                    item.userEngagementSeconds;
                });
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total:</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {formatWholeNumber(totalSummary.conversions)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {formatWholeNumber(totalSummary.newUsers)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        {formatWholeNumber(totalSummary.totalUsers)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        {`Avg: ` +
                          secondsToFormatted(
                            record.length > 0
                              ? totalSummary.userEngagementSeconds /
                                  record.length
                              : 0
                          )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConversionsView;
