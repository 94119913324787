import React from "react";
import { Row, Col, Progress } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import FiltersBar from "./components/FiltersBar";
import CampaignsTable from "./components/CampaignsTable";
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
  StandardTableRowText,
} from "../../../../../../../shared/globalStyling/styledText";
import StatusTag from "../../../../../components/StatusTag";
import { formatCurrency } from "../../../../../../../../core/utils/campaigns";
const Frag = React.Fragment;

const CampaignsTab = ({
  campaigns,
  summaryRangeDates,
  orgId,
  history,
  currencyFormat,
  id,
}) => {
  const showEndDateStatus = ["ENDED", "SUSPENDED", "UNKNOWN", "UNSPECIFIED"];
  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "15%",
      align: "left",

      render: (text, record) => {
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText text={text} />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Flight"} />,
      dataIndex: "startDate",
      key: "startDate",
      align: "left",
      width: "14%",
      footerContent: " ",
      render: (text, record) => {
        if (!record) {
          return <div />;
        }

        const isFiltered = !isEmpty(summaryRangeDates);
        const currentDate = moment().format("MM/DD/YY");
        // const startDate = record.startDate ? moment(record.startDate) : null;
        // const endDate = record.endDate ? moment(record.endDate) : null;
        const startDate =
          !isFiltered && record.startDate
            ? moment(record.startDate)
            : summaryRangeDates[0] || null;
        const endDate =
          !isFiltered && record.endDate
            ? moment(record.endDate)
            : summaryRangeDates[1] || null;
        const status = record.status ? record.status : "UNKNOWN";

        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start" align="middle" gutter={8}>
              {record.startDate && (
                <Col>
                  <FlightDateText
                    text={startDate ? startDate.format("MM/DD/YY") : null}
                  />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {(showEndDateStatus.includes(status) && record.endDate) ||
                (isFiltered && (
                  <Col>
                    <FlightDateText
                      text={endDate ? endDate.format("MM/DD/YY") : null}
                    />
                  </Col>
                ))}
              <Col>
                <StatusTag status={status} />
              </Col>
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Performance"} />,
      dataIndex: "performance",
      key: "performanceStatus",
      align: "left",
      width: "12%",
      footerStyle: "block",
      footerSums: [
        {
          property: "data.impressions",
          formatter: "",
          title: "Impressions",
        },
        {
          property: "data.clicks",
          formatter: "",
          title: "Clicks",
        },
      ],
      render: (text, { data }) => {
        return (
          <Row type="flex" justify="start" align="middle">
            <Col style={{ minWidth: "50px" }}>
              {!isEmpty(data) ? (
                <>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Impressions:‏‏‎ ‎`} />
                    <StandardTableRowText
                      text={` ${Number(
                        parseInt(data.impressions).toFixed(0)
                      ).toLocaleString()}`}
                    />
                  </Row>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Clicks:‏‏‎ ‎`} />
                    <StandardTableRowText
                      text={`${Number(
                        parseInt(data.clicks).toFixed(0)
                      ).toLocaleString()}`}
                    />
                  </Row>
                </>
              ) : (
                <PrimaryTableRowText text={`-`} />
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"eCommerce"} />,
      dataIndex: "performance",
      key: "performanceStatus",
      align: "left",
      width: "12%",
      footerStyle: "block",
      footerSums: [
        {
          property: "data.conversions",
          formatter: "",
          title: "Transactions",
        },
        {
          property: "data.revenue",
          title: "Revenue",
          isCurrency: true,
        },
        {
          property: "data.spend",
          title: "Spend",
          isCurrency: true,
        },
      ],
      render: (text, { data }) => {
        return (
          <Row type="flex" justify="start" align="middle">
            <Col style={{ minWidth: "50px" }}>
              {!isEmpty(data) ? (
                <>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Transactions:‏‏‎ ‎`} />
                    <StandardTableRowText
                      text={` ${Number(
                        parseInt(data.conversions).toFixed(0)
                      ).toLocaleString()}`}
                    />
                  </Row>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Revenue:‏‏‎ ‎`} />
                    <StandardTableRowText
                      text={formatCurrency(data.revenue, currencyFormat)}
                    />
                  </Row>
                  <Row type="flex">
                    <PrimaryTableRowText text={`Spend:‏‏‎ ‎`} />
                    <StandardTableRowText
                      text={formatCurrency(data.spend, currencyFormat)}
                    />
                  </Row>
                </>
              ) : (
                <PrimaryTableRowText text={`-`} />
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24}>
          <FiltersBar orgId={orgId} id={id} />
          <CampaignsTable
            campaigns={campaigns}
            columns={columns}
            currencyFormat={currencyFormat}
            onRow={data => ({
              onClick: () => {
                const { id } = data;
                history.push(`/paid_search/campaign/${orgId}/${id}/bing`);
              },
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export default CampaignsTab;
