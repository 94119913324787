import gql from "graphql-tag";

const EDITORIAL_DELETE = gql`
  mutation deleteEditorial($id: ID!) {
    deleteEditorial(where: { id: $id }) {
      id
      name
    }
  }
`;

export default EDITORIAL_DELETE;
