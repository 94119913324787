import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Checkbox, Col, Collapse, Card, Divider } from "antd";
import GlobalButton from "../../shared/globalStyling/Button";
import "./style.css";
import GET_USER_MSA_STATUS from "../../../core/GraphQl/Queries/GET_USER_MSA_STATUS";
import OverlaySpinner from "../../shared/OverlaySpinner";
const { Panel } = Collapse;

class MasterServicesAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    if (this.state.checked) {
      this.state.loading = true;
      try {
        const response = await this.props.updateUserMsaStatus({
          variables: {
            id: this.props.userId,
            msaStatus: "VERSION_2",
          },
          awaitRefetchQueries: true,
          refetchQueries: {
            query: GET_USER_MSA_STATUS,
            variables: { id: this.props.userId },
          },
        });

        if (response) {
          this.state.loading = false;
          window.location.reload("/home");
        }
      } catch (error) {
        console.log("Error updating MSA status: " + error);
      }
    }
  }

  render() {
    if (this.state.loading) return <OverlaySpinner />;
    return (
      <div
        style={{ backgroundColor: "rgb(128, 128, 128)", minHeight: "100vh" }}
      >
        <Row type="flex" justify="center">
          <Col xs={24} lg={16}>
            <Card style={{ borderRadius: "10px", marginTop: "30px" }}>
              <h2 className="text-center">MSA Conditions</h2>
              <div className="container" style={{ fontSize: "16px" }}>
                <Divider />

                <p>
                  We've updated our MSA for the terms of use of our platform.
                </p>
                <Collapse>
                  <Panel header="Click here to read">
                    <p>
                      BY CHECKING THE ACCEPTANCE BOX OR USING ALL OR ANY PORTION
                      OF THE SOFTWARE AND SERVICES, YOU ARE ACCEPTING ALL OF THE
                      TERMS AND CONDITIONS OF THIS AGREEMENT AS PUBLISHED ON
                      MEDIAJEL’S WEBSITE AT WWW.MEDIAJEL.COM (AS MAY BE
                      RELOCATED BY MEDIAJEL FROM TIME TO TIME). YOU AGREE THAT
                      THIS AGREEMENT IS ENFORCEABLE LIKE ANY WRITTEN AGREEMENT
                      SIGNED BY YOU AND LEGALLY BINDING BETWEEN YOU AND MEDIAJEL
                      INC. IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND
                      CONDITIONS, DO NOT UTILIZE ANY PORTION OF THE SERVICES. IN
                      THE EVENT YOU ARE REDIRECTED TO MEDIAJEL’S WEBSITE, YOU
                      AGREE THAT YOUR USE OF SUCH IS SUBJECT TO ANY TERMS OF
                      SERVICE AND POLICIES POSTED THEREON. MEDIAJEL MAY MODIFY
                      THIS AGREEMENT AS SET FORTH IN SECTION 14.2 (MODIFICATIONS
                      TO THIS AGREEMENT).
                    </p>
                    <Divider />
                    <p>
                      This Master Services Agreement (the “Agreement”) is
                      entered into by and between MediaJel, Inc., a Nevada
                      corporation (“MediaJel”), and you or the entity you
                      represent (“you” or “your”), effective as of the date you
                      checked the acceptance box on MediaJel’s website
                      (“Effective Date”), and will govern your use of the
                      Services (as defined below). MediaJel and you hereby agree
                      as follows:
                    </p>
                    <ol className="order-count">
                      <li className="order-count-list">
                        <strong>SCOPE OF SERVICES</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>General:</strong> MediaJel shall use
                            commercially reasonable efforts to provide access to
                            the MediaJel platform and related services to you
                            pursuant to the terms of this Agreement and any
                            related agreements between the parties, including
                            statements of work (collectively, the “Services”).
                            In the event of a conflict between the terms of this
                            Agreement and any other applicable agreement, this
                            Agreement shall control unless otherwise expressly
                            provided. You understand and agree that MediaJel may
                            provide Services directly or through affiliates,
                            agents, employees or subcontractors.
                          </li>
                          <li className="order-count-list">
                            <strong>Advertiser Agreement:</strong> Any statement
                            of work (“Advertiser Agreement”) entered into
                            hereunder shall represent each party’s good faith
                            estimate of the scope of work and items and time
                            required to complete the Services and deliverables
                            contracted for thereunder. Each Advertiser Agreement
                            will contain any necessary project-specific terms
                            and conditions including, but not limited to, cost
                            and payment provisions, acceptance criteria for
                            deliverables, change management procedures, and any
                            other terms necessitated by the scope and/or
                            requirements of the individual Advertiser Agreement.
                            The terms of this Agreement are hereby incorporated
                            by reference in any and all Advertiser Agreements
                            between the parties.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>TERMS OF SERVICE</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Provision of Services:</strong> Subject to
                            you meeting your payment obligations, MediaJel will
                            provide to you the Services as described herein
                            and/or in any Advertiser Agreements. This Agreement
                            applies to your use of the Services for yourself
                            and/or on behalf of any your clients.
                          </li>
                          <li className="order-count-list">
                            <strong>Your Obligations:</strong> You shall not
                            violate, or use the Services in a way that violates
                            (or causes MediaJel to violate) Section 5 of this
                            Agreement (Compliance) or any applicable law or
                            third party rights. You bear sole responsibility for
                            what your authorized users (“Users”) do with Your
                            Information (as defined below in Section 3.3), the
                            actions of Users within the Services, and for
                            adequate security, protection and backup of Your
                            Information when in your or your Users’,
                            representatives’ or agents’ possession or control.
                          </li>
                          <li className="order-count-list">
                            <strong>Service License and Limits:</strong> As
                            between MediaJel and you, MediaJel owns all rights,
                            title and interest in and to MediaJel IP (as defined
                            below) and the Services, including future
                            developments and enhancements. Notwithstanding the
                            foregoing, MediaJel grants to you a limited,
                            non-exclusive, non-transferable, non-sublicensable,
                            and revocable right to access and use the Services,
                            subject to the terms and conditions of this
                            Agreement. Except as otherwise provided in this
                            Section 2.3, MediaJel does not grant you any other
                            license whatsoever to any MediaJel IP, express or
                            implied. MediaJel reserves all rights not expressly
                            granted hereunder, including the right to
                            continually evolve the Services and all related
                            technologies therein. MediaJel’s intellectual
                            property includes MediaJel’s Confidential
                            Information (as defined in Section 11) and all
                            aspects of its technology, branding, and Services,
                            including any underlying software or applications
                            developed or created by it (collectively, “MediaJel
                            IP”). You grant MediaJel and its affiliates a
                            worldwide, perpetual, irrevocable, royalty-free
                            right to use and incorporate into the Services any
                            suggestion, input, enhancement request,
                            recommendation, correction, specification or other
                            feedback provided by you relating to the provision
                            of the Services.
                          </li>
                          <li className="order-count-list">
                            <strong>Service Guidelines:</strong> You shall not
                            (and shall not permit any User, affiliate or third
                            party to) make any use or disclosure of the MediaJel
                            platform (or any documentation relating to the
                            MediaJel platform that MediaJel generally provides
                            to its customers (the “Documentation”)) that is not
                            expressly permitted under this Agreement. Without
                            limiting the foregoing, except as otherwise
                            expressly permitted under this Agreement, you shall
                            not (and shall not permit any User, affiliate or
                            third party to): (i) reverse engineer, decompile,
                            disassemble, or otherwise attempt to discern the
                            underlying structures, algorithms, ideas, know-how
                            or any other information of or related to the
                            MediaJel platform or Services; (ii) modify,
                            translate, adapt or create derivative works based on
                            the MediaJel platform, the Documentation, or the
                            Services; (iii) make any copies of the MediaJel
                            platform or the Documentation; (iv) resell,
                            distribute, or sublicense the MediaJel platform or
                            the Documentation; (v) make the MediaJel platform
                            available on a timesharing or “service bureau”
                            basis, or otherwise allow any third party to use or
                            access the MediaJel platform or the Documentation;
                            (vi) remove or modify any proprietary marking or
                            restrictive legends placed on the MediaJel platform
                            or the Documentation; (vii) use the MediaJel
                            platform, the Documentation, or MediaJel data for
                            any purpose not expressly permitted in this
                            Agreement or in violation of applicable law; (viii)
                            introduce into the MediaJel platform any software,
                            virus, worm, “back door,” Trojan Horse, or similar
                            harmful code; (ix) use the MediaJel platform in
                            connection with any advertisements that do not
                            comply with Section 5.1; (x) attempt in any way to
                            alter, modify, eliminate, conceal, or otherwise
                            render inoperable or ineffective the website tags,
                            source codes, links, pixels, modules or other data
                            provided by or obtained from MediaJel that allows
                            MediaJel to measure ad performance and provide the
                            MediaJel platform and/or the Services; (xi) use the
                            MediaJel platform to target advertisements to
                            individuals younger than the minimum age as
                            determined by applicable law for the given target
                            industry (e.g., 21 years for alcohol) (“Underage
                            Individuals”), or (xii) use the MediaJel platform to
                            target advertisements to users generally accepted as
                            “sensitive” pursuant to Internet advertising
                            industry guidelines unless pursuant to an opt-in
                            policy. If you violate this Section 2.4, MediaJel
                            shall have the right to deem such violation a
                            material breach of this Agreement and reserves the
                            right, in its sole discretion, to deny you and your
                            Users access to the MediaJel platform, or any
                            portion thereof, and to remove your advertisements
                            from the MediaJel platform. Additionally, you agree
                            to pay or accept reduction of payment for all
                            damages caused during the use of the MediaJel
                            platform by AdWare, Malware, Botnets, or other
                            deceptive or fraudulent traffic that results in loss
                            of revenue for MediaJel. You agree that MediaJel’s
                            decision on said traffic will be final and MediaJel
                            will provide information pertaining to this decision
                            when available. Under no circumstances may you use
                            the Services for benchmarking or gathering data on
                            the performance of the Services or MediaJel systems
                            for competitive intelligence.
                          </li>
                          <li className="order-count-list">
                            <strong>Service Security:</strong> MediaJel shall
                            take steps to preserve the integrity and security of
                            Your Information as set forth in this Agreement.
                            However, MediaJel cannot guarantee that unauthorized
                            third parties will never be able to defeat such
                            security measures or use Your Information for
                            improper purposes. You understand and acknowledge
                            that you provide MediaJel with Your Information at
                            your own risk, and that MediaJel shall not be liable
                            to you for any damages relating to any unauthorized
                            access to Your Information by third-parties.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>USE OF DATA</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Publisher and End User Information:</strong>{" "}
                            You will have access to detailed information about
                            Publisher Sites through the Services, including but
                            not limited to their targeting and blocking
                            criteria. You will only permit your employees who
                            are directly involved in using the Services to
                            access such information, will only use this
                            information for the purpose of buying inventory
                            through the Services and will treat such information
                            as MediaJel’s Confidential Information. You may not
                            create targeting profiles or segments or make
                            advertising unit inventory available on the MediaJel
                            platform on the basis of the Publisher Sites that a
                            user has visited, the Services categories that apply
                            to the Publisher Sites, the content on the Publisher
                            Sites’ pages, or the general interest area(s)
                            covered by the Publisher Sites. In addition, and
                            without limiting any of your other obligations in
                            this Agreement, you shall not use any persistent
                            identifier to target users of the Publisher Sites or
                            make advertising unit inventory available that is
                            directed or targeted at Underage Individuals or that
                            you know or should know are used by a substantial or
                            disproportionately high ratio of Underage
                            Individuals. The foregoing does not prohibit you
                            from targeting your advertisements in real time on
                            the basis of the Publisher Site categories shown
                            through the Services, provided that you may not
                            retain such information and/or include it in a
                            targeting profile or segment.
                          </li>
                          <li className="order-count-list">
                            <strong>Services Data:</strong> Aside from the
                            Publisher Site and end user information described
                            above, you may not disclose or use the data
                            generated by your use of the Services, including but
                            not limited to ad inventory availability and volume
                            and pricing data obtained via the Services, for any
                            business purpose except if (a) such disclosure or
                            use complies with your privacy policies and this
                            Agreement, and (b) such disclosure or use does not
                            disclose data that describes or reflects the
                            performance of the Services (or any other MediaJel
                            services) to third parties, except for those who
                            have a need to know (e.g. to provide reporting to
                            your clients about such client’s advertising
                            transactions within the Services) and are under
                            confidentiality restrictions at least as restrictive
                            as those contained in this Agreement; provided,
                            however, that you will be fully responsible for the
                            improper or unauthorized disclosure or use of such
                            data by such third parties. In addition, except as
                            required by law, you are prohibited from disclosing,
                            either directly or indirectly, to any third parties
                            the identity of any sellers of ad inventory for
                            Publisher Sites and/or owners of the Publishers
                            Sites via the Services. You acknowledge that other
                            participants of the Services may have access to
                            information related to you (e.g., sellers have
                            access to information about the advertisers who
                            purchase their inventory).
                          </li>
                          <li className="order-count-list">
                            <strong>Your Information:</strong> You shall own any
                            data and/or information that you provide to MediaJel
                            via the Services (“Your Information”). Your
                            Information also includes any data that is
                            independently derived by you from a user’s “click”
                            or other interaction with your advertising unit and
                            not related to the owners of Publisher Sites and/or
                            sellers. You hereby grant MediaJel the right to use
                            and disclose Your Information (i) as aggregate
                            Service statistics; (ii) to provide, manage,
                            maintain and enhance the Services; (iii) as chosen
                            by you in relation to your use of the Services (e.g.
                            to be shared with third parties whose services you
                            elect to use via the Services); and (iv) as legally
                            necessary, including to enforce MediaJel’s rights
                            under this Agreement. Notwithstanding anything in
                            the Agreement to the contrary, you hereby grant to
                            MediaJel a nonexclusive, worldwide, personal,
                            royalty free, non-sublicensable and non-transferable
                            license to Your Information solely for the purpose
                            and only to the extent and for the time period
                            necessary for MediaJel (including its independent
                            consultants) to design, develop, test, operate,
                            enhance and maintain any deliverables and provide
                            the Services.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>COMPENSATION</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Payment Terms:</strong> MediaJel shall
                            invoice you, and you shall pay MediaJel, pursuant to
                            the terms set forth in any applicable Advertiser
                            Agreement. You will raise any questions or disputes
                            within fifteen (15) days following the date of
                            invoice. You are responsible for providing complete
                            and accurate billing and contact information to
                            MediaJel and promptly notifying MediaJel of any
                            changes to such information. Notwithstanding any of
                            the foregoing, you further understand and agree that
                            you will be responsible for the payment of any
                            amounts due hereunder whether or not you are paid by
                            third-party clients, if any, on behalf of whom you
                            are creating, delivering, disclosing or distributing
                            one or more advertisements through the Services. In
                            addition, in the event that you terminate this
                            Agreement and/or any Advertiser Agreement before the
                            end of a payment period, MediaJel will not prorate
                            any payments owed by you, including the Commitment
                            Amount (as defined below) or any other associated
                            fees. Except for taxes on MediaJel’s income, you are
                            responsible for paying all applicable sales, use or
                            other taxes or duties, tariffs, etc. applicable to
                            the Services. If MediaJel becomes concerned about
                            your ability to pay on time or at all, MediaJel may
                            bill in advance or require a deposit. You will pay
                            MediaJel all amounts in US dollars. MediaJel
                            reserves the right to charge the legal maximum
                            interest rate on any late payment.
                          </li>
                          <li className="order-count-list">
                            <strong>Commitment Amount:</strong> As applicable,
                            if you commit to a specified amount to pay on a
                            particular campaign set forth in an Advertiser
                            Agreement (“Commitment Amount”), and if the actual
                            spend for such campaign does not reach the
                            Commitment Amount, then you will still owe payment
                            for the full Commitment Amount and/or will not be
                            reimbursed or refunded for the difference.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>COMPLIANCE</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Compliance Requirements:</strong> Each party
                            will comply with all applicable laws and regulations
                            (MediaJel as to its provision of the Services and
                            you as to your use of the Services). Without
                            limiting the generality of the foregoing, you agree
                            that all advertisements with which you use the
                            Services (and any content that is linked to any such
                            advertisements) for yourself or on behalf of your
                            clients (a) will comply with all applicable laws and
                            regulations, including but not limited to Federal,
                            state and local regulated industry laws and
                            regulations (e.g., laws and regulations relating to
                            alcohol, cannabis, and gambling and Underage
                            Individuals thereto), HIPAA, and any other
                            applicable privacy laws, (b) will not contain
                            content or materials that are misleading, libelous,
                            obscene, invasive of others’ privacy, or hateful
                            (racially or otherwise), (c) will not introduce
                            viruses or other malware to the Services or
                            MediaJel’s systems or end users, (d) will not
                            infringe, violate or misappropriate any third
                            party’s intellectual property or other rights, (e)
                            will not facilitate or promote illegal activity, or
                            contain content that is illegal; (f) will not
                            contain content that is deceptive, misleading,
                            defamatory, obscene, distasteful, racially or
                            ethnically offensive, harassing, or that is
                            discriminatory based upon race, gender, color,
                            creed, age, sexual orientation, or disability; (g)
                            will not contain sexually suggestive, explicit, or
                            pornographic content; (h) will not infringe upon or
                            violate any right of any third party, including,
                            without limitation, any intellectual property,
                            privacy, or publicity rights; (i) will not spawn
                            additional windows or messages beyond the original
                            ad; (j) will not distribute adware, spyware, or
                            viruses; (k) will not auto-forward users’ browsers;
                            (l) will not resemble system dialogue boxes or error
                            messages; and (m) will not intentionally obscure or
                            falsify the source of the inventory or artificially
                            inflate the volume of such inventory in any way. You
                            will not use the Services in a manner that violates
                            your agreement with third parties or could
                            reasonably be expected to damage the Services or
                            reflect unfavorably on the reputation of MediaJel or
                            its clients. You will comply with any blocking
                            criteria that are associated with a bid request
                            placed in connection with the Services. MediaJel
                            reserves the right to refuse any advertisements
                            through the Services from you. You will not export,
                            re-export or transfer any portion of the Services
                            except as permitted by applicable export laws or
                            regulations.
                          </li>
                          <li className="order-count-list">
                            <strong>Privacy Compliance</strong> You will provide
                            MediaJel notice of a privacy policy detailing your
                            data collection, sharing, and use practices that
                            comply with all applicable laws and regulations.
                            MediaJel’s data collection, sharing, and use
                            practices regarding your data is set forth in this
                            Agreement. Without limitation of the foregoing, you
                            will comply with the applicable United States
                            Digital Advertising Alliance Self-Regulatory
                            Principles published at www.aboutads.info (“DAA
                            Self-Regulatory Principles”) and, as applicable,
                            with the corresponding DAA-designated
                            self-regulatory frameworks established in other
                            countries and/or regions. Without limiting the
                            foregoing, you will comply with the enhanced notice
                            obligations applicable to First Parties as defined
                            in the DAA Self-Regulatory Principles by providing a
                            disclosure that explains that data may be collected
                            about visitors’ use of your (or your client’s)
                            website(s) and/or application(s) for advertising and
                            other purposes and includes links to the DAA
                            AppChoices tool or the DAA webpage at
                            www.aboutads.info/choices as applicable. In
                            addition, you agree not to share, pass or transfer
                            any personally identifiable information or Sensitive
                            Data (as defined below) to MediaJel with the
                            exception of customer relationship management lists
                            submitted by you for targeting purposes via the
                            Services, provided that you obtained proper Consent
                            for MediaJel’s collection, use, and transfer of such
                            data for the Services. “Sensitive Data” means online
                            account access credentials or a first name or
                            initial and last name, in combination with a Social
                            Security number, driver’s license number, other
                            state or government identification number, medical
                            or health insurance information, biometric data, or
                            an account number, debit card number, or credit card
                            number in combination with any required security
                            code, access code, or password that would permit
                            access to or use of such individual’s card or
                            account. Moreover, you represent and warrant that to
                            the extent you provide any information regarding
                            devices or users to MediaJel, or permit MediaJel to
                            collect such information, it is shared, passed or
                            provided to MediaJel in compliance with all
                            applicable laws and regulations and with all
                            necessary rights, consents, and permissions. Without
                            limiting the foregoing, you will not transfer any
                            Precise Location Data to MediaJel, or enable
                            MediaJel to collect Precise Location Data from your
                            digital properties, without obtaining Consent for
                            such transfer for the purpose of MediaJel’s
                            collection, use, and transfer of such data for the
                            Services. For the purpose of this paragraph, the
                            terms “Precise Location Data” and “Consent” shall
                            have the meanings given to them in the DAA
                            Self-Regulatory Principles.
                          </li>
                          <li className="order-count-list">
                            <strong>Audits:</strong> MediaJel reserves the right
                            to audit your use of the Services and related
                            activities to ensure your compliance with the terms
                            of this Agreement and any applicable laws and
                            regulations. Any audit will occur upon reasonable
                            advance written notice to you, and will occur during
                            normal business hours in a manner designed not to
                            unreasonably interfere with your ordinary business
                            operations, and no more than once every twelve (12)
                            month period unless MediaJel finds, based on a
                            previous audit, that you materially breached this
                            Agreement or any applicable laws and regulations.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>TERM, SUSPENSION, AND TERMINATION</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Term:</strong> The term of this Agreement
                            shall begin on the Effective Date and continue for
                            one (1) year following the Effective Date with an
                            automatic one (1) year renewal term.
                          </li>
                          <li className="order-count-list">
                            <strong>Suspension:</strong> MediaJel may suspend
                            your use of the Services immediately upon written
                            notice to you (email being sufficient) if MediaJel:
                            (i) believes that you have violated Section 2.2,
                            2.3, 2.4, 3.1, 3.2, 5, 11, and/or 12 of this
                            Agreement, (ii) MediaJel receives complaints about
                            you, or (iii) you have failed to pay an invoice in
                            full within thirty (30) days of its due date or
                            within ten (10) days of receiving a late notice from
                            MediaJel.
                          </li>
                          <li className="order-count-list">
                            <strong>Termination:</strong> Either party may
                            terminate this Agreement: (i) immediately upon
                            written notice if the other party becomes insolvent,
                            or if there are proceedings instituted by or against
                            the other party in bankruptcy or under other laws of
                            or pertaining to insolvency, creditors’ rights or
                            reorganization, receivership or dissolution, or if
                            the other party makes an assignment for the benefit
                            of creditors; (ii) immediately upon written notice,
                            if the other party is in breach of any its
                            obligations under this Agreement or any applicable
                            Advertiser Agreement and such breach remains uncured
                            for thirty (30) days following written notice of
                            such breach from the non-breaching party; (iii) at
                            any time by providing the other party written notice
                            at least ninety (90) days prior to the termination
                            date designated in that notice, provided however
                            that any amounts set forth in an Advertiser
                            Agreement that become due and owing during the
                            period from the date of the notice until the
                            termination date shall continue to be due and owing
                            notwithstanding such notice of cancellation; and
                            (iv) upon forty-eight (48) hours written notice to
                            the other party provided that all Advertiser
                            Agreements have been effectively terminated. Upon
                            any such termination, your right to use the Services
                            shall immediately terminate.
                          </li>
                          <li className="order-count-list">
                            <strong>
                              Effect of Termination or Expiration:
                            </strong>{" "}
                            Advertiser Agreements entered into prior to the
                            effective date of the expiration or termination of
                            this Agreement will terminate upon such expiration
                            or termination unless as expressly otherwise
                            provided therein. The parties will not execute any
                            further Advertiser Agreements after any expiration
                            or termination of this Agreement. If an Advertiser
                            Agreement is terminated, you will pay MediaJel for
                            all deliverables delivered and Services performed
                            under that Advertiser Agreement prior to the date of
                            termination, as well as any charges and expenses for
                            the deliverables in development, if any. Promptly
                            after (and only after) receiving full payment
                            therefor, MediaJel will deliver to you any such
                            Deliverables in development (on an ‘as-is’ basis and
                            not subject to your acceptance).
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>REPRESENTATIONS AND WARRANTIES</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>You:</strong> You represent and warrant to
                            MediaJel that: (i) this Agreement and any applicable
                            Advertiser Agreements constitute the legal, valid
                            and binding obligations of you, enforceable against
                            you in accordance with their terms, subject to
                            bankruptcy, insolvency, reorganization and other
                            laws affecting creditors’ rights generally, and with
                            regard to equitable remedies, to the discretion of
                            the court before which proceedings to obtain those
                            remedies may be pending; (ii) if applicable, you
                            have all requisite corporate power and authority to
                            enter into this Agreement and to carry out the
                            transactions contemplated by this Agreement and any
                            applicable Advertiser Agreements, and that the
                            execution, delivery and performance of this
                            Agreement and any applicable Advertiser Agreements
                            and the consummation of the transactions
                            contemplated herein and therein have been duly
                            authorized by all requisite corporate action on the
                            part of you; (iii) your execution and delivery of
                            this Agreement and your performance or compliance
                            with the terms of this Agreement will not conflict
                            with, result in a breach of, constitute a default
                            under, or require the consent of any third party
                            under any license, sublicense, lease, contract,
                            agreement or instrument to which you are bound or to
                            which your properties are subject; (iv) to the best
                            of your knowledge, there are no currently pending or
                            threatened lawsuits, actions or any other legal or
                            administrative proceedings against you which, if
                            adversely determined against you, would have a
                            material adverse effect on your ability to perform
                            your obligations under this Agreement; (v) you have
                            the necessary rights, licenses and approvals (or
                            that you will obtain such rights and approvals)
                            prior to providing any and all Company Information
                            to MediaJel for MediaJel’s use; and (vi) you will
                            not, and will not attempt to or cause any other
                            party acting on your behalf to, decompile, reverse
                            engineer or otherwise attempt to derive, obtain or
                            modify the source code of the Services or any
                            MediaJel IP.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>DISCLAIMER.</strong> MEDIAJEL MAKES NO
                        REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS,
                        STATUTORY OR IMPLIED. THE SERVICES ARE MADE AVAILABLE
                        “AS IS” AND “AS AVAILABLE.” IN ADDITION, MEDIAJEL DOES
                        NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE
                        SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR
                        ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER
                        HARDWARE, SOFTWARE, SYSTEM OR DATA; (B) THE SERVICES
                        WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (C) ANY
                        STORED DATA OR REPORTING WILL BE ACCURATE, RELIABLE OR
                        FREE FROM LOSS; OR (D) THE SERVICES OR THE
                        INFRASTRUCTURE THAT MAKES THE SERVICES AVAILABLE WILL BE
                        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. MEDIAJEL
                        EXPRESSLY DISCLAIMS ANY OTHER REPRESENTATIONS AND
                        WARRANTIES, EXPRESS, STATUTORY OR IMPLIED, INCLUDING ANY
                        IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
                        TITLE, MERCHANTABILITY, NONINFRINGEMENT, COURSE OF
                        DEALING OR PERFORMANCE. ANY THIRD-PARTY TECHNOLOGY
                        DELIVERED TO YOU BY MEDIAJEL UNDER THIS AGREEMENT IS
                        WITHOUT WARRANTY OF ANY KIND FROM MEDIAJEL. THIS
                        DISCLAIMER INCLUDES ANY IMPLIED WARRANTIES OF
                        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
                        ANY WARRANTIES OF NONINFRINGEMENT OR OTHERWISE.
                      </li>
                      <li className="order-count-list">
                        <strong>LIMITATION OF LIABILITY.</strong> MEDIAJEL WILL
                        NOT BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL,
                        PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR
                        RELATED TO THIS AGREEMENT, HOWEVER CAUSED, AND UNDER
                        WHATEVER CAUSE OF ACTION OR THEORY OF LIABILITY EVEN IF
                        MEDIAJEL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. MEDIAJEL’S TOTAL AGGREGATE LIABILITY FOR ALL
                        CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT SHALL
                        NOT EXCEED THE AMOUNT CHARGED TO YOU BY MEDIAJEL UNDER
                        THIS AGREEMENT AND ANY ADVERTISER AGREEMENTS FOR THE SIX
                        (6) MONTHS PRIOR TO THE DATE THE LIABILITY FIRST AROSE.
                      </li>
                      <li className="order-count-list">
                        <strong>INDEMNIFICATION</strong>
                        <ol className="order-alphabet">
                          <li>
                            You will indemnify, defend, and hold harmless
                            MediaJel and its affiliates, directors, officers,
                            employees and agents (the “MediaJel Indemnified
                            Parties”) against any and all losses, liabilities,
                            judgments, awards and costs (including reasonable
                            legal fees and expenses) in any claim arising from,
                            in connection with, or based on allegations of, any
                            of the following:
                            <ol className="order-roman">
                              <li>
                                that any of Your Information infringes any third
                                party’s copyright or trade secrets or that your
                                use of the Services infringes any third party’s
                                copyright or trade secrets;
                              </li>
                              <li>
                                Your breach of any terms of this Agreement
                                and/or any Advertiser Agreement, including the
                                inaccuracy, untruthfulness, or breach of any
                                representation or warranty made by you under
                                this Agreement or any Advertiser Agreement;
                              </li>
                              <li>
                                any amounts, including taxes, interest, and
                                penalties, assessed against MediaJel that are
                                the obligation of you pursuant to this
                                Agreement;
                              </li>
                              <li>
                                Your failure to comply with any applicable laws,
                                and any fines or penalties imposed by law on
                                MediaJel due to any act or omission by you; and
                              </li>
                              <li>
                                data loss and/or security breach of your data or
                                systems resulting in unauthorized disclosure,
                                destruction, loss or alteration of MediaJel
                                Confidential Information in the possession or
                                control of you.
                              </li>
                            </ol>
                          </li>
                          <li>
                            You shall indemnify MediaJel from any costs and
                            expenses incurred in connection with the enforcement
                            of this Section 10.1.
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>CONFIDENTIALITY.</strong> “Confidential
                        Information” means any information relating to or
                        disclosed in the course of this Agreement and/or any
                        Advertiser Agreements which are or should reasonably be
                        understood to be confidential, including each party’s
                        intellectual property. The terms of this Agreement are
                        the Confidential Information of each party (not to be
                        disclosed by the other without the written consent of
                        the other) and data regarding the performance of the
                        Services and MediaJel systems is MediaJel’s Confidential
                        Information. The receiving party will use the same care
                        to protect Confidential Information as it uses for its
                        own similar information, but in no event less than
                        reasonable care, and will use Confidential Information
                        only for the purpose of fulfilling its obligations under
                        this Agreement. The receiving party shall not disclose
                        Confidential Information to the receiving party’s
                        employees, agents, consultants, subsidiaries, corporate
                        affiliates or any other third parties
                        (“Representatives”), except when disclosure is necessary
                        to perform the obligations under this Agreement,
                        provided that any such recipient shall be under the same
                        obligations of confidentiality as that of the receiving
                        party and shall be contractually bound to protect the
                        Confidential Information consistent with the terms of
                        this Agreement and the receiving party shall be fully
                        responsible for any unauthorized use or disclosure by
                        its Representatives of the disclosing party’s
                        Confidential Information. The receiving party will
                        promptly return or destroy the other party’s
                        Confidential Information upon request of the other
                        party, except that any archival copies may be retained,
                        subject to the continuation of the confidentiality
                        obligations pursuant to this Section 11. “Confidential
                        Information” does not include information that (a) is or
                        becomes part of the public domain through no fault of
                        the receiving party; (b) was legally in possession of
                        the receiving party prior to the time of its disclosure
                        by the disclosing party other than through a prior
                        disclosure by disclosing party; or (c) was independently
                        developed by the receiving party without violation of
                        this Section 11. The receiving party may disclose
                        Confidential Information if it is required to do so by
                        law, so long as the receiving party provides the
                        disclosing party with prompt notice and complies with
                        any protective order imposed on such disclosure.
                      </li>
                      <li className="order-count-list">
                        <strong>NON-SOLICITATION.</strong> Neither you nor any
                        of your representatives, including without limitation
                        your directors, officers, employees, agents and
                        advisors, shall, without the prior written approval of
                        MediaJel, directly or indirectly, solicit, induce or
                        attempt to induce or otherwise counsel, discuss, advise
                        or encourage any of MediaJel’s employees, service
                        providers or sellers or owners of Publisher Sites which
                        you encounter via the Services to leave or otherwise
                        terminate such person’s relationship with MediaJel or
                        any of its affiliates, as applicable, for a period of
                        one year following the effective termination or
                        expiration date of this Agreement.
                      </li>
                      <li className="order-count-list">
                        <strong>DATA PROCESSING.</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Key Definitions:</strong> The following
                            definitions shall apply to the provisions of this
                            Section 13:
                            <ol className="order-alphabet">
                              <li>
                                “Your Personal Information” means any Personal
                                Information other than Unique Identifiers
                                provided directly by you to MediaJel for
                                business purposes pursuant to the Agreements.
                                For the avoidance of doubt, Your Personal
                                Information shall not include MediaJel Personal
                                Information.
                              </li>
                              <li>
                                “Covered Personal Information” means both Your
                                Personal Information and MediaJel Personal
                                Information.
                              </li>
                              <li>
                                “MediaJel Personal Information” means any Unique
                                Identifiers collected or received by MediaJel in
                                the course of providing the Services.
                              </li>
                              <li>
                                “Personal Information” shall be interpreted
                                consistent with the Privacy Laws, and includes
                                at a minimum “personal information” as that term
                                is defined in the CCPA. Personal Information
                                does not include “aggregate consumer
                                information” or “deidentified” data as defined
                                in the State Privacy Laws.
                              </li>
                              <li>
                                “Portable Format” means to the extent
                                technically feasible a structured, commonly
                                used, machine readable, readily usable format
                                that allows the consumer to transmit the Covered
                                Personal Information to another entity or
                                controller without hindrance, as further
                                specified in the State Privacy Laws.
                              </li>
                              <li>
                                “Privacy Laws” means applicable statutes,
                                regulations or other laws pertaining to privacy
                                and information security, including, where
                                applicable, the State Privacy Laws.
                              </li>
                              <li>
                                “Unique Identifiers” means a persistent
                                identifier that can be used to recognize a
                                consumer or device over time and across
                                different services, including, but not limited
                                to a device identifier, an IP address, cookies,
                                beacons, pixel tags, mobile ad identifiers, or
                                similar technology, customer number, unique
                                pseudonym, user alias, or other hashed
                                persistent identifiers. Unique Identifiers
                                includes information processed through the nid
                                and csync cookies and the IDFA or AAID mobile
                                identifiers.
                              </li>
                              <li>
                                “State Privacy Laws” mean US state privacy laws
                                such as the California Consumer Privacy Act,
                                Cal. Civ. Code 1798.100 et seq., as amended (the
                                “CCPA”), or any regulations or guidance issued
                                pursuant thereto. The terms “aggregate consumer
                                information,” “business,” “business purposes,”
                                “consumer,” “controller,” “deidentified,”
                                “processing,” “processor,” “sale,” “sensitive
                                data,” “sensitive personal information,”
                                “service provider,” “sharing,” and “verifiable
                                consumer request” shall have the meanings given
                                to those terms in the State Privacy Laws.
                              </li>
                            </ol>
                          </li>
                          <li className="order-count-list">
                            <strong>Terms of Data Processing</strong>
                            <ol className="order-alphabet">
                              <li>
                                <strong>Compliance with Laws:</strong> MediaJel
                                shall comply with all applicable laws, including
                                Privacy Laws, with respect to its performance
                                under this Agreement and any Advertiser
                                Agreements and shall not cause you to violate
                                applicable law.
                              </li>
                              <li>
                                <strong>Relationship of the Parties:</strong>{" "}
                                The Parties acknowledge and agree that:
                                <ol className="order-roman">
                                  <li>
                                    MediaJel will process Your Personal
                                    Information in accordance with this
                                    Agreement for the duration of the provision
                                    of the Services to you. You disclose Your
                                    Personal Information to MediaJel for limited
                                    and specified purposes described in this
                                    Agreement and any Advertiser Agreement.
                                    MediaJel receives no monetary or other
                                    valuable consideration in exchange for Your
                                    Personal Information.
                                  </li>
                                  <li>
                                    As between MediaJel and You, MediaJel has
                                    all necessary rights, licenses, and
                                    authority to use, share, sell, resell,
                                    disclose, or create derivative works from
                                    MediaJel Personal Information. To the extent
                                    you own or claim ownership of any MediaJel
                                    Personal Information, you grant to MediaJel
                                    a nonexclusive, worldwide, royalty-free
                                    license to use, share, sell, resell,
                                    disclose, or create derivative works from
                                    MediaJel Personal Information.
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <strong>Compliance with Obligations:</strong>{" "}
                                MediaJel represents and warrants that MediaJel,
                                its employees, agents, subcontractors, and
                                sub-processors (a) understand and shall comply
                                with the State Privacy Laws while providing the
                                Services, (b) will provide the level of privacy
                                protection required by the State Privacy Laws,
                                and (c) shall provide you with all
                                reasonably-requested assistance to enable you to
                                fulfill your own obligations under the State
                                Privacy Laws.
                              </li>
                              <li>
                                <strong>Changes to State Privacy Laws:</strong>{" "}
                                The parties agree to cooperate in good faith to
                                enter into additional terms to address any
                                modifications, amendments, or updates to the
                                State Privacy Laws, including new laws that
                                become effective at the US state level from time
                                to time.
                              </li>
                              <li>
                                <strong>Retention:</strong> MediaJel shall
                                retain Your Personal Information only for as
                                long as necessary to fulfill its obligations
                                pursuant to this Agreement. any Advertiser
                                Agreements and applicable laws. Upon your
                                written request and following expiration or
                                termination of this Agreement, MediaJel will
                                return or, at your request, securely destroy any
                                of Your Personal Information in MediaJel ‘s
                                possession, custody, or control and certify in
                                writing that such return or secure destruction
                                has occurred.
                              </li>
                              <li>
                                <strong>Disclosure:</strong> To the extent you
                                direct MediaJel to disclose Covered Personal
                                Information to third parties designated by you
                                on your behalf, you shall ensure that such
                                disclosure and such third party’s processing of
                                such Covered Personal Information complies with
                                this Agreement and the Privacy Laws.
                              </li>
                            </ol>
                          </li>
                          <li className="order-count-list">
                            <strong>
                              Limitations on Use of Your Personal Information
                            </strong>
                            <ol className="order-alphabet">
                              <li>
                                <strong>CCPA Restrictions:</strong> MediaJel
                                agrees that it will not retain, use, sell, or
                                disclose any of Your Personal Information
                                provided by or on behalf of you for any purpose
                                other than (a) providing the Services under this
                                Agreement and any Advertiser Agreements and (b)
                                using Your Personal Information internally to
                                verify or maintain the quality or safety of the
                                Services, and to improve, upgrade, or enhance
                                the Services for you. MediaJel further
                                acknowledges that it is prohibited from (a)
                                retaining, using, or disclosing Your Personal
                                Information outside of the direct business
                                relationship with you, or (b) using Your
                                Personal Information to (i) build or modify
                                household or consumer profiles to use in
                                providing services to another business, or (ii)
                                correct or augment data acquired from another
                                source.
                              </li>
                              <li>
                                <strong>
                                  Compliance Remediation; Termination Right:
                                </strong>{" "}
                                MediaJel agrees to notify you without undue
                                delay if MediaJel determines that it can no
                                longer meet its obligations under State Privacy
                                Laws. Upon receiving notice from
                                Jake@mediajel.com in accordance with this
                                subsection, you may direct MediaJel to take
                                steps as reasonable and appropriate to remediate
                                unauthorized use of Your Personal Information or
                                terminate this Agreement upon thirty (30) days’
                                notice.
                              </li>
                              <li>
                                <strong>Subcontractors; Sub-processors:</strong>{" "}
                                MediaJel shall notify you of any intended
                                changes concerning the addition or replacement
                                of subcontractors or sub-processors. Further,
                                MediaJel shall ensure that MediaJel’s
                                subcontractors or sub-processors who collect,
                                process, store, or transmit Your Personal
                                Information on your behalf agree in writing to
                                the same restrictions and requirements that
                                apply to MediaJel in this Agreement with respect
                                to Your Personal Information, as well as to
                                comply with the Privacy Laws. MediaJel shall be
                                responsible and remain liable to you for
                                MediaJel’s subcontractors’ compliance with the
                                terms of this Agreement.
                              </li>
                            </ol>
                          </li>
                          <li className="order-count-list">
                            <strong>Consumer Requests </strong>
                            <ol className="order-alphabet">
                              <li>
                                <strong>Cooperation:</strong> MediaJel will
                                implement and maintain sufficient processes and
                                procedures to satisfy consumer requests to
                                access, correct, and/or delete Covered Personal
                                Information held by MediaJel.
                              </li>
                              <li>
                                <strong>
                                  Fulfillment of Consumer Requests:
                                </strong>{" "}
                                Within ten (10) calendar days of a written
                                request from you (email is sufficient), MediaJel
                                shall, as applicable:
                                <ol className="order-roman">
                                  <li>
                                    Securely erase or destroy, or cause to be
                                    erased or destroyed, specific pieces of Your
                                    Personal Information, including any copies
                                    of such Your Personal Information maintained
                                    by MediaJel’s subcontractor(s) or
                                    sub-processor(s).
                                  </li>
                                  <li>
                                    Provide information requested by you about
                                    MediaJel’s collection of Your Personal
                                    Information, including, without limitation,
                                    the categories of Your Personal Information
                                    that were collected and categories of
                                    subcontractors or sub-processors to whom
                                    MediaJel has disclosed Your Personal
                                    Information.
                                  </li>
                                  <li>
                                    Provide the specific pieces of Your Personal
                                    Information that MediaJel and/or one of its
                                    subcontractors or sub-processors has
                                    collected or otherwise obtained about the
                                    consumer on behalf of you in a Portable
                                    Format.
                                  </li>
                                  <li>
                                    Modify, and direct its subcontractors or
                                    sub-processors to modify, specific pieces of
                                    Your Personal Information.
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <strong>Referral of Direct Request:</strong>{" "}
                                MediaJel agrees to refer consumer requests
                                submitted directly to MediaJel for Your Personal
                                Information to you.
                              </li>
                            </ol>
                          </li>
                          <li className="order-count-list">
                            <strong>Security Controls</strong>
                            <ol className="order-alphabet">
                              <li>
                                <strong>Duty of Confidentiality:</strong>{" "}
                                MediaJel, its employees, agents, subcontractors,
                                and sub-processors are subject to a duty of
                                confidentiality with respect to the Covered
                                Personal Information.
                              </li>
                              <li>
                                <strong>Security Measures:</strong> MediaJel
                                shall implement and maintain reasonable security
                                measures, procedures, and practices appropriate
                                to the nature of the Covered Personal
                                Information to protect such Covered Personal
                                Information from unauthorized access,
                                destruction, use, modification, or disclosure
                                (“Security Measures”). Such Security Measures
                                shall meet or exceed applicable industry
                                standards (e.g., the Payment Card Industry (PCI)
                                Data Security Standard) and any obligations set
                                forth in this Agreement or applicable law. At a
                                minimum, Security Measures will include (i)
                                storing Covered Personal Information on servers
                                located in a physically secured location and
                                (ii) using firewalls, access controls and
                                similar security technology designed to protect
                                Covered Personal Information from unauthorized
                                disclosure.
                              </li>
                              <li>
                                <strong>Security Program:</strong> MediaJel
                                shall implement a comprehensive written security
                                program that includes industry-standard
                                administrative, technical, and physical
                                safeguards designed to ensure the
                                confidentiality, security, and integrity of
                                Covered Personal Information (“Security
                                Program”). Upon your reasonable request,
                                MediaJel will provide you with documentation
                                that demonstrates its compliance with this
                                Section.
                              </li>
                              <li>
                                <strong>Encryption:</strong> MediaJel will
                                ensure that Covered Personal Information in
                                MediaJel‘s control is sufficiently protected
                                against unauthorized access and use, including
                                by appropriate encryption, tokenization, or
                                other substantially similar safeguards.
                              </li>
                              <li>
                                <strong>Access Controls:</strong> MediaJel shall
                                implement appropriate access controls
                                restricting access to Covered Personal
                                Information to only such employees, agents,
                                subcontractors, and sub-processors as need to
                                know the information in order to perform their
                                obligations in furtherance of this Agreement.
                              </li>
                              <li>
                                <strong>Security Incident:</strong> MediaJel
                                will inform you within seventy-two (72) hours of
                                MediaJel's knowledge of any unauthorized access,
                                destruction, use, modification, or disclosure
                                (each, a “Security Incident”) of any of Your
                                Personal Information. MediaJel will provide you
                                with any information and cooperation reasonably
                                requested by you regarding such Security
                                Incident. MediaJel shall not provide notice of
                                such Security Incident without the prior written
                                consent of you unless required by applicable
                                law.
                              </li>
                            </ol>
                          </li>
                          <li className="order-count-list">
                            <strong>Inquiries</strong>
                            <ol className="order-alphabet">
                              <li>
                                <strong>
                                  Notification of Regulatory Inquiry:
                                </strong>{" "}
                                In the event that MediaJel receives any
                                regulatory inquiry or correspondence regarding
                                Your Personal Information in which MediaJel or
                                you is named (an “Inquiry”), MediaJel shall, to
                                the extent not prohibited by applicable law or
                                any regulatory authority:
                                <ol className="order-roman">
                                  <li>
                                    Notify you of such Inquiry in writing within
                                    three (3) calendar days of receiving such
                                    Inquiry;
                                  </li>
                                  <li>
                                    Provide you with all copies of documents and
                                    correspondence relating to the Inquiry
                                    without undue delay after receipt or
                                    delivery of such documents or
                                    correspondence;
                                  </li>
                                  <li>
                                    Provide you with a written certification at
                                    the conclusion of the Inquiry that action
                                    required by State Privacy Laws has been
                                    taken in response to such Inquiry;
                                  </li>
                                  <li>
                                    Not disclose any confidential information of
                                    you or any affiliated party to the
                                    applicable authority without your prior
                                    written consent.
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <strong>Response to Inquiry:</strong> MediaJel
                                shall take all other measures necessary to
                                respond to or otherwise address the Inquiry
                                adequately and in a timely manner.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li className="order-count-list">
                        <strong>MISCELLANEOUS</strong>
                        <ol className="order-count">
                          <li className="order-count-list">
                            <strong>Public Statements:</strong> You will make
                            not any public statement relating to the Agreement
                            without the prior written approval of MediaJel.
                            MediaJel may include your name and logo on its
                            marketing and promotional materials and customer
                            lists. In addition, MediaJel may include your name
                            and positive information metrics in case studies
                            that MediaJel shares with its potential customers,
                            provided that such information is covered by
                            confidentiality obligations.
                          </li>
                          <li className="order-count-list">
                            <strong>
                              Entire Agreement; Amendment; Survival:
                            </strong>{" "}
                            This Agreement, together with any applicable
                            Advertiser Agreements, represent the entire
                            agreement of the parties relating to the subject
                            matter herein, and supersedes all prior commitments,
                            negotiations and understandings with respect to your
                            participation in and use of the Services. In
                            addition, this Agreement supersedes any
                            click-through agreement on your or your affiliate’s
                            website, including but not limited to for the
                            purpose of registering an account to access
                            reporting and other usage information, whether or
                            not any such click-through agreement is 'accepted'
                            by MediaJel before, on or after the Effective Date.
                            This Agreement may be unilaterally modified by
                            MediaJel at any time as required by changes in
                            Federal or State laws, regulations, or rules, and/or
                            as required by changes to MediaJel’s costs, business
                            model, and/or internal policies and guidelines. If
                            any provision of this Agreement is unenforceable,
                            the validity of the remaining provisions will not be
                            affected. Sections 3, 4, and 8 through 12 of this
                            Agreement will survive termination or expiration of
                            this Agreement. All representations, warranties, and
                            indemnities, shall survive the termination or
                            expiration of this Agreement and shall remain in
                            full force and effect. All of a party’s rights and
                            privileges — to the extent they are fairly
                            attributable to events or conditions occurring or
                            existing on or prior to the termination and/or
                            expiration of this Agreement — shall survive
                            termination and shall be enforceable by that party.
                            Any claim arising out of or related to this
                            Agreement must be brought in the initiating party’s
                            individual capacity and not as a plaintiff or class
                            member in any class action or other similar
                            proceeding.
                          </li>
                          <li className="order-count-list">
                            <strong>Governing Law:</strong> This Agreement will
                            be governed and construed, to the extent applicable,
                            in accordance with California law.
                          </li>
                          <li className="order-count-list">
                            <strong>Counterparts:</strong> Multiple signature
                            pages, signatures delivered via scanned-in PDF copy
                            or fax, and electronic signatures will all
                            constitute originals and together will constitute
                            one and the same instrument.
                          </li>
                          <li className="order-count-list">
                            <strong>Force Majeure:</strong> Neither party will
                            be liable for failure or delay in performing its
                            obligations because of causes beyond its reasonable
                            control, including without limitation acts of God,
                            terrorism, war, riots, fire, earthquake, flood or
                            degradation or failure of third party networks or
                            communications infrastructure.
                          </li>
                          <li className="order-count-list">
                            <strong>Disputes:</strong> The parties agree to meet
                            and confer in good faith on all matters of common
                            interest or all controversies, claims, or disputes
                            (“Dispute”) which materially affect the performance
                            of either party under this Agreement. As soon as a
                            Dispute is recognized by either party, it will
                            communicate the substance of such Dispute to the
                            other party. Once a Dispute has been raised, the
                            parties will make all reasonable efforts to reach a
                            resolution within two (2) weeks after the Dispute
                            has been identified. During the pendency of any
                            Dispute, unless you instruct MediaJel to cease work
                            under the relevant Advertiser Agreement until
                            resolution of the Dispute, both parties shall
                            continue performing their respective duties and
                            obligations (including payment obligations) under
                            this Agreement and the relevant Advertiser
                            Agreement.
                          </li>
                          <li className="order-count-list">
                            <strong>Arbitration:</strong> All Disputes arising
                            out of or relating to this Agreement, which cannot
                            first be resolved in accordance with Section 14.6,
                            shall be resolved exclusively by submission to
                            binding arbitration in accordance with the
                            Commercial Arbitration Rules of the American
                            Arbitration Association. Any arbitration will be
                            conducted in Contra Costa County, California before
                            a single arbitrator. California law shall apply to
                            any Dispute, without giving effect to any conflict
                            or choice of law rule or provisions. Any arbitration
                            award may be entered in and enforced by any court
                            having jurisdiction thereof, and the parties consent
                            and commit themselves to the jurisdiction of the
                            courts of the State of California for purposes of
                            any enforcement of any arbitration award. Except as
                            may be required by law, neither a party nor an
                            arbitrator may disclose the existence, content, or
                            result of any arbitration hereunder without the
                            prior written consent of both parties. During the
                            pendency of any Dispute, unless you instruct
                            MediaJel to cease work under the relevant Advertiser
                            Agreement until resolution of the Dispute, both
                            parties shall continue performing their respective
                            duties and obligations (including payment
                            obligations) under this Agreement and the relevant
                            Advertiser Agreement.
                          </li>
                          <li className="order-count-list">
                            <strong>No Assignment:</strong> Neither you nor
                            MedjaJel may assign or otherwise transfer this
                            Agreement or rights hereunder without the prior
                            written consent of the other. Waiver by you or
                            MediaJel, respectively, as to any transaction shall
                            not operate as a waiver of the prohibition contained
                            herein or such party’s rights as to any subsequent
                            transaction. Notwithstanding the foregoing, either
                            party may assign this Agreement to (a) a parent or
                            subsidiary corporation or corporation owned by a
                            parent corporation; or (b) a person or entity which,
                            through merger, acquisition or otherwise, succeeds
                            to all or substantially all of such party’s
                            business, provided such assignee agrees in writing
                            to be bound by the terms and conditions of this
                            Agreement. Further notwithstanding the foregoing,
                            you or MediaJel, respectively, as assignor or
                            transferor shall remain fully responsible and liable
                            for the performance of all of its covenants and
                            obligations under this Agreement and any assignee,
                            transferee or successor, shall be jointly and
                            severally liable with assignor or transferee for the
                            performance of all such covenants and obligations.
                          </li>
                          <li className="order-count-list">
                            <strong>No Joint Venture:</strong> MediaJel is an
                            independent contractor and will determine the
                            method, details, and means of performing the
                            Services. Nothing contained in this Agreement will
                            be deemed or construed as creating a joint venture
                            or partnership between the parties. Except as
                            expressly set forth in this Agreement, no party is
                            by virtue of this Agreement authorized as an agent,
                            employee or legal representative of the other party.
                          </li>
                          <li className="order-count-list">
                            <strong>No Third-Party Beneficiary:</strong> This
                            Agreement is made and entered into for the sole
                            protection and benefit of the parties to this
                            Agreement and is not intended to convey any rights
                            or benefits to any third party, nor will this
                            Agreement be interpreted to convey any rights or
                            benefits to any person except the parties to this
                            Agreement, except as set forth in Section 10 hereof.
                          </li>
                          <li className="order-count-list">
                            <strong>
                              Representation of Counsel; Mutual Negotiation:
                            </strong>{" "}
                            Each party acknowledges it has had the opportunity
                            to be represented by counsel of its choice in
                            negotiating this Agreement. This Agreement will
                            therefore be deemed to have been negotiated and
                            prepared at the joint request, direction, and
                            construction of the parties, at arm’s length, with
                            the advice and participation of counsel, and will be
                            interpreted in accordance with its terms without
                            favor to either party.
                          </li>
                          <li className="order-count-list">
                            <strong>Severability:</strong> If any provision of
                            this Agreement shall be found to be void by a court
                            of law, such provision shall be deemed to be
                            severable from the other provisions of this
                            Agreement, and the remainder of this Agreement shall
                            be given effect, as if the parties had not included
                            the severed provision.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </Panel>
                </Collapse>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginTop: "1.5rem",
                  }}
                >
                  <Checkbox
                    checked={this.state.checked}
                    style={{ alignSelf: "center" }}
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                  >
                    I agree to the MSA Conditions
                  </Checkbox>
                  <GlobalButton
                    disabled={this.state.loading}
                    onClick={this.handleSubmit}
                    style={{ alignSelf: "center" }}
                  >
                    Done
                  </GlobalButton>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(MasterServicesAgreement);
