import gql from "graphql-tag";

const CREATE_USER_CONFIG = gql`
  mutation createUserConfig($data: UserConfigCreateInput!) {
    createUserConfig(data: $data) {
      id
      embeddableConfig {
        type
        ssoToken
        campaignOrder {
          id
          name
        }
        advertiser {
          id
          name
        }
        publisher {
          id
          name
        }
        widgetConfig {
          transactionChart
          transactionTable
        }
      }
    }
  }
`;

export default CREATE_USER_CONFIG;
