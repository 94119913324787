import React from "react";
import { Typography, Space, Checkbox } from "antd";

const LaunchCampaignConfigTargeting = ({
  countries,
  setCountries,
  countryISP,
  defaultCountryISP,
}) => {
  const handleChange = checkedValues => {
    setCountries(checkedValues);
  };
  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        width: "100%",
      }}
    >
      <Typography.Title level={4} style={{ margin: 0 }}>
        Targeting
      </Typography.Title>

      <Typography.Title level={5} style={{ margin: 0 }}>
        Country/ISP
      </Typography.Title>

      <Checkbox.Group
        options={countryISP}
        value={countries}
        defaultValue={[defaultCountryISP]} // USA
        onChange={handleChange}
      />
    </Space>
  );
};

export default LaunchCampaignConfigTargeting;
