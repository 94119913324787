import React from "react";
import { useLazyQuery, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

// CONTROLLERS
import Controller from "./Controller";

// QUERIES
import CAMPAIGN_ORDER_LIST from "../../GraphQl/Queries/CAMPAIGN_ORDER_LIST";
import GET_ADVERTISERS from "../../GraphQl/Queries/GET_ADVERTISERS";
import GET_EDITORIALS from "../../GraphQl/Queries/GET_EDITORIALS";

const Loader = props => {
  const [
    campaignOrderList,
    { data: campaignOrderListData, loading: campaignOrderListLoading },
  ] = useLazyQuery(CAMPAIGN_ORDER_LIST, {
    variables: {
      orgId: props.orgId,
      searchName: props.searchName,
    },
  });

  const [
    getAdvertisers,
    { data: advertiserListData, loading: advertiserListLoading },
  ] = useLazyQuery(GET_ADVERTISERS);

  const [
    getEditorials,
    { data: editorialListData, loading: editorialListLoading },
  ] = useLazyQuery(GET_EDITORIALS);

  return (
    <Controller
      {...props}
      campaignOrderList={campaignOrderList}
      campaignOrderListData={campaignOrderListData}
      campaignOrderListLoading={campaignOrderListLoading}
      getAdvertisers={getAdvertisers}
      advertiserListData={advertiserListData}
      advertiserListLoading={advertiserListLoading}
      getEditorials={getEditorials}
      editorialListData={editorialListData}
      editorialListLoading={editorialListLoading}
    >
      {props.children}
    </Controller>
  );
};

export default withApollo(withRouter(Loader));
