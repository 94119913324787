import gql from "graphql-tag";

const GET_ALL_ARTICLE_CATEGORIES = gql`
  query articleCategories {
    articleCategories {
      id
      createdAt
      updatedAt
      title
      articles {
        id
        title
        content
      }
      permission
      feature
    }
  }
`;

export default GET_ALL_ARTICLE_CATEGORIES;
