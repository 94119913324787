import gql from "graphql-tag";

const EDITORIAL_DOMAIN = gql`
  query EditorialDomain($where: EditorialWhereInput!) {
    editorials(where: $where) {
      id
      name
    }
  }
`;

export default EDITORIAL_DOMAIN;
