import gql from "graphql-tag";

const GET_ORG_DEAL_IDS = gql`
  query org($id: ID!) {
    org(where: { id: $id }) {
      id
      name
      dealIds {
        id
        name
        dealId
      }
    }
  }
`;

export default GET_ORG_DEAL_IDS;
