import { Typography } from "antd";
import React from "react";

import { formatDiffPct } from "../../utils/numbers";

const { Text } = Typography;

const PctDiff = ({ value }) => {
  if (value) {
    if (value < 0) {
      return <Text type="danger">{formatDiffPct(value)}%</Text>;
    }
    return <Text type="success">{formatDiffPct(value)}%</Text>;
  }

  return <Text type="secondary">N/A</Text>;
};

export default PctDiff;
