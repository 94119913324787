import { Card, Col, Row, Space, Table, Avatar, Tooltip, Button } from "antd";
import React from "react";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../../../shared/globalStyling/styledText";
import cnnaid from "../../../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../../../assets/icons/transactions/globephone.svg";
import moment from "moment";
import ImpressionSubTable from "./ImpressionsSubTable";

// Check if the string is a valid URL
// Reference: https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url/
const isValidUrl = urlString => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

const addProtocol = url => {
  // Test wheter the url has a protocol
  if (!/^(\w+:)?\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
};

const PageViewsTable = ({ data }) => {
  const columns = [
    {
      title: <TableColumnTitle text="Page URL" />,
      dataIndex: "pageUrl",
      key: "pageUrl",
      render: (text, record) => {
        const validUrl = isValidUrl(text);
        let url = text;
        if (!validUrl) {
          const urlSplitted = text.split("//");
          // if url can be spliited it probably has an incorrect http or https protocol
          if (urlSplitted.length > 1) {
            //Having an invalid protocol on the url
            if (urlSplitted[0] !== "http:" || urlSplitted[0] !== "https:") {
              //Add proper protocol to the url
              url = addProtocol(urlSplitted[1]);
            }
          }
        }

        return (
          <Space direction="vertical" size="small">
            <PrimaryTableRowText text={url} />
            <Button type="link" href={url} target="_blank">
              Visit Page
            </Button>
          </Space>
        );
      },
    },
    {
      title: <TableColumnTitle text="Page View Time" />,
      dataIndex: "pageViewTime",
      key: "pageViewTime",
      render: text => {
        return <PrimaryTableRowText text={text} />;
      },
    },
    {
      title: <TableColumnTitle text="Impressions" />,
      dataIndex: "totalImpressions",
      key: "totalImpressions",
      render: text => {
        return <PrimaryTableRowText text={text} />;
      },
    },
    {
      title: <TableColumnTitle text="First Impression" />,
      dataIndex: "firstImpressionTime",
      key: "firstImpressionTime",
      render: text => {
        return <PrimaryTableRowText text={text} />;
      },
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: (text, record) => {
        let attributionIcon = cnnaip;
        let tip = "";
        if (record.attributionMethod.hasOwnProperty("COOKIES")) {
          attributionIcon = cnnaid;
          tip =
            "Cookie Match - Deterministic match using browser based cookie / uuid match.";
        } else if (record.attributionMethod.hasOwnProperty("FINGERPRINT")) {
          attributionIcon = cnnafingerprint;
          tip =
            "User Match - Probablistic match using user fingerprint. (User fingerprint - The combination of user agent and ip address to produce a unique hash)";
        } else if (
          record.attributionMethod.hasOwnProperty("DEVICE_FINGERPRINT")
        ) {
          tip =
            "Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) combined with IP address";
        } else if (record.attributionMethod.hasOwnProperty("IP")) {
          tip = "IP Match - Probabilistic match using IP address.";
          attributionIcon = cnnaip;
        }

        const attributionIcn = () => {
          if (record.attributionMethod.hasOwnProperty("DEVICE_FINGERPRINT")) {
            return (
              <span
                role="img"
                className="anticon"
                style={{ verticalAlign: "-5px" }}
              >
                <GlobePhoneIcon
                  height="35"
                  width="35"
                  style={{
                    opacity: "1.0",
                  }}
                />
              </span>
            );
          } else {
            return <Avatar src={attributionIcon} />;
          }
        };
        return (
          <Tooltip title={tip}>
            <Avatar src={attributionIcn()} />
          </Tooltip>
        );
      },
    },
  ];

  const sortedData = data.sort((a, b) =>
    // sort by pageViewTime (date)
    moment(b.pageViewTime).diff(moment(a.pageViewTime))
  );
  return (
    <Row>
      <Col span={24}>
        <Card title="Page Views" bordered={false}>
          <Table
            scroll={{ x: 850, y: 400 }}
            columns={columns}
            dataSource={sortedData}
            rowKey={record => record.eventId}
            expandable={{
              expandedRowRender: record => {
                return <ImpressionSubTable impressions={record.impressions} />;
              },
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PageViewsTable;
