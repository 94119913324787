import {
  Modal,
  Row,
  Col,
  Input,
  DatePicker,
  Typography,
  notification,
  Select,
} from "antd";
import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { CheckCircleTwoTone } from "@ant-design/icons";

const { Title } = Typography;

const styles = {
  title: {
    color: "#595959",
    margin: 0,
    fontWeight: 500,
    fontSize: "1.15em",
  },
  titleSecondary: {
    color: "#a7a7a7",
    margin: 0,
    fontWeight: 500,
    fontSize: ".95em",
  },
  select: {
    width: "75%",
    minWidth: "150px",
  },
};

const { Option } = Select;

const BulkUpdateModal = ({
  toggleBulkUpdateModal,
  setToggleBulkUpdateModal,
  selectedRowKeys,
  updateBulkCampaignOrder,
  cartProvider,
  setCurrentCartProvider,
  currentCartProvider,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [bulkBudget, setBulkBudget] = useState("");
  const [bulkCPM, setBulkCPM] = useState("");
  const [bulkStartDate, setBulkStartDate] = useState(null);
  const [bulkEndDate, setBulkEndDate] = useState(null);

  const [transactionTarget, setTransactionTarget] = useState("");
  const [transactionsCart, setTransactionsCart] = useState(null);
  const [signUpCampaign, setSignUpCampaign] = useState(null);
  const [signUpConfig, setSignUpConfig] = useState("");

  const cartProviders =
    cartProvider &&
    cartProvider.__type &&
    cartProvider.__type.enumValues &&
    cartProvider.__type.enumValues;

  const getCartProvider =
    cartProviders &&
    cartProviders.filter(val => {
      return val.name !== "NOCART";
    });

  const handleOk = async () => {
    await setConfirmLoading(true);
    if (!isEmpty(selectedRowKeys)) {
      await selectedRowKeys.forEach(id => {
        updateBulkCampaignOrder({
          variables: {
            id: id,
            budget: bulkBudget !== "" ? parseFloat(bulkBudget) : undefined,
            cpm: bulkCPM !== "" ? parseFloat(bulkCPM) : undefined,
            startDate: bulkStartDate !== null ? bulkStartDate : undefined,
            endDate: bulkEndDate !== null ? bulkEndDate : undefined,
            transactionTarget:
              transactionTarget !== "" ? transactionTarget : undefined,
            transactionsConfig:
              transactionsCart !== null
                ? {
                    upsert: {
                      create: {
                        appId: transactionTarget,
                        cart: transactionsCart,
                        traffId: null,
                        gtmAccess: null,
                        gtmStatus: null,
                        tracksBasketItems: undefined,
                      },
                      update: {
                        appId: transactionTarget,
                        cart: transactionsCart,
                        traffId: null,
                        gtmAccess: null,
                        gtmStatus: null,
                        tracksBasketItems: undefined,
                      },
                    },
                  }
                : undefined,
            signUpCampaign: signUpConfig !== "" ? signUpCampaign : undefined,
            signUpConfig:
              signUpConfig !== ""
                ? {
                    upsert: {
                      create: {
                        appId: signUpConfig,
                      },
                      update: {
                        appId: signUpConfig,
                      },
                    },
                  }
                : undefined,
          },
        });
      });

      await setBulkBudget("");
      await setBulkCPM("");
      await setBulkStartDate(null);
      await setBulkEndDate(null);
      await setTransactionTarget("");
      await setTransactionsCart(null);
      await setSignUpCampaign(null);
      await setSignUpConfig("");

      await notification.open({
        message: "Successfully Update Campaigns",
        duration: 5,
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    }

    await setConfirmLoading(false);
    await setToggleBulkUpdateModal(false);
  };

  const handleCancel = () => {
    setToggleBulkUpdateModal(false);
  };

  return (
    <>
      <Modal
        title="Bulk Edit Campaign Order"
        visible={toggleBulkUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col span={24}>
            <Row>
              <Title level={4} style={styles.title}>
                Budget
              </Title>
            </Row>
            <Input
              type="number"
              min="0"
              value={bulkBudget}
              onChange={e => {
                setBulkBudget(e.target.value);
              }}
              placeholder="Budget"
              style={{ width: "75%", margin: "10px 0 10px 0" }}
              allowClear
            />
          </Col>
        </Row>

        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col span={24}>
            <Row>
              <Title level={4} style={styles.title}>
                CPM
              </Title>
            </Row>
            <Input
              type="number"
              min="0"
              value={bulkCPM}
              onChange={e => {
                setBulkCPM(e.target.value);
              }}
              placeholder="CPM"
              style={{ width: "75%", margin: "10px 0 10px 0" }}
              allowClear
            />
          </Col>
        </Row>

        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col xs={24}>
            <Row style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Title level={4} style={styles.titleSecondary}>
                  Start Date
                </Title>
                <DatePicker
                  style={{ margin: "10px 0 10px 0", width: "75%" }}
                  value={bulkStartDate ? moment(bulkStartDate) : null}
                  onChange={date => {
                    setBulkStartDate(date);
                  }}
                />
              </Col>
              <Col span={12}>
                <Title level={4} style={styles.titleSecondary}>
                  End Date
                </Title>
                <DatePicker
                  style={{ margin: "10px 0 10px 0", width: "75%" }}
                  value={bulkEndDate ? moment(bulkEndDate) : null}
                  onChange={date => {
                    setBulkEndDate(date);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col xs={24}>
            <Row style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Title level={4} style={styles.titleSecondary}>
                  E-Commerce Platform
                </Title>

                <Select
                  style={styles.select}
                  value={transactionsCart}
                  defaultValue={transactionsCart}
                  onClick={() => {
                    setCurrentCartProvider(getCartProvider);
                  }}
                  onChange={val => {
                    setTransactionsCart(val);
                  }}
                >
                  {currentCartProvider &&
                    currentCartProvider.map(val => {
                      const { name } = val;

                      return (
                        <Option
                          key={name}
                          value={name === "NOCART" ? null : name}
                        >
                          {name}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col span={12}>
                <Title level={4} style={styles.titleSecondary}>
                  Application Id
                </Title>

                <Input
                  type="text"
                  value={transactionTarget}
                  onChange={e => {
                    setTransactionTarget(e.target.value);
                  }}
                  placeholder="App Id"
                  style={{ width: "75%" }}
                  allowClear
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col xs={24}>
            <Title level={4} style={styles.titleSecondary}>
              Sign Up
            </Title>
            <Input
              type="text"
              value={signUpConfig}
              onChange={e => {
                setSignUpConfig(e.target.value);
                setSignUpCampaign(true);
              }}
              placeholder="Sign Up"
              style={{ width: "75%" }}
              allowClear
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BulkUpdateModal;
