import React, { useMemo } from "react";

const RollUpSEOController = ({
  children,
  data,
  refetch,
  loading,
  byRollUpRangeDate,
  setByRollUpRangeDate,
  prevRangeData,
  loadingPrevRangeData,
  ...props
}) => {
  const diffPcts = useMemo(() => {
    let diffs = {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      revenue: 0,
    };

    const prev = prevRangeData ? prevRangeData.getRollUpSEO : undefined;
    const now = data ? data.getRollUpSEO : undefined;
    if (prev && now) {
      diffs.sessions = now.sessions - prev.sessions;
      diffs.newUsers = now.newUsers - prev.newUsers;
      diffs.transactions = now.transactions - prev.transactions;
      diffs.conversionRate = now.conversionRate - prev.conversionRate;
      diffs.revenue = now.revenue - prev.revenue;

      return {
        sessions: (diffs.sessions / now.sessions) * 100,
        newUsers: (diffs.newUsers / now.newUsers) * 100,
        transactions: (diffs.transactions / now.transactions) * 100,
        conversionRate: (diffs.conversionRate / now.conversionRate) * 100,
        revenue: (diffs.revenue / now.revenue) * 100,
      };
    }
    return null;
  }, [prevRangeData, data]);

  return React.cloneElement(children, {
    ...props,
    data: data ? data.getRollUpSEO : null,
    loading,
    byRollUpRangeDate,
    setByRollUpRangeDate,
    prevRangeData,
    loadingPrevRangeData,
    diffPcts,
  });
};

export default RollUpSEOController;
