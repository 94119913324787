export function prepareCSV(report) {
  return [
    {
      Time: "Weekly",
      Impressions: report.weekly.impressions,
      Clicks: report.weekly.clicks,
      CTR: `${report.weekly.ctr || 0}%`,
      "Impressions % Change ": report.weekly.pctChangeImpressions.value,
      "Clicks % Change ": report.weekly.pctChangeClicks.value,
      "CTR % Change ": report.weekly.pctChangeCTR.value,
      "Current Date Range": report.weekly.currentDateRange,
      "Previous Date Range": report.weekly.prevDateRange,
    },
    {
      Time: "Monthly",
      Impressions: report.monthly.impressions,
      Clicks: report.monthly.clicks,
      CTR: `${report.monthly.ctr}%`,
      "Impressions % Change ": report.monthly.pctChangeImpressions.value,
      "Clicks % Change ": report.monthly.pctChangeClicks.value,
      "CTR % Change ": report.monthly.pctChangeCTR.value,
      "Current Date Range": report.monthly.currentDateRange,
      "Previous Date Range": report.monthly.prevDateRange,
    },
  ];
}
