import { useContext } from "react";
import CreativeContext from "./CreativeProvider";

export const useCreativeContext = () => {
  const context = useContext(CreativeContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
