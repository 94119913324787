import gql from "graphql-tag";

const GET_ORG_SUMMARIES = gql`
  query orgs($orgIds: [ID!]) {
    orgs(
      where: {
        id_in: $orgIds
        campaignOrders_some: { type_not: CPC, status_not_in: [PENDING, DRAFT] }
      }
    ) {
      orgSummary {
        id
        mediaSpendImpressionsServed
        mediaSpendClicks
        mediaSpendCTR
        goal
        roas
        budget
        impressions
        inAttributionCampaigns
        totalCampaigns
        liveCampaigns
        activeCampaigns
        totalWalkIns
        totalOrders
        totalObservations
        mediaSpendBudgetSpent
        totalRevenue
        attributionWindowReport
        updatedAt
      }
    }
  }
`;

export default GET_ORG_SUMMARIES;
