import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Typography,
  Checkbox,
  Tooltip,
  InputNumber,
} from "antd";
import moment from "moment";
// Local
import { SubOrgsLoader } from "../../../../core/shared/SubOrgsLoader";
import { OrgSelect } from "../../../shared/OrgSelect";
import { iabCategories } from "../../../../core/utils/constants/constants";

const { Title, Text } = Typography;
const Option = Select.Option;
const styles = {
  title: {
    color: "#595959",
    margin: 0,
    fontWeight: 500,
    fontSize: "1.15em",
  },
  titleSecondary: {
    color: "#a7a7a7",
    margin: 0,
    fontWeight: 500,
    fontSize: ".95em",
  },
};

const BasicsCard = ({
  onChange,
  campaignName,
  startDate,
  endDate,
  category,
  isRegulated,
  isLive,
  type,
  currentUser,
  advertiser,
  isEdit,
  description,
  canEdit,
  isTrafficker,
}) => {
  const [numberOfDays, setNumberOfDays] = useState(0);

  const handleStartDateChange = value => {
    if (value) {
      if (value.isAfter(endDate)) {
        onChange("endDate", null);
      }
    }

    onChange("startDate", value ? value.toISOString() : null);

    // Check first if the end date is set and end date is greater than 2 days after start date and if so don't change end date
    if (endDate && moment(endDate).isAfter(moment(value).add(2, "days"))) {
      return;
    }

    // Set end date 2 days after start date
    if (value) {
      onChange("endDate", value.add(2, "days").toISOString());
    }
  };

  const disabledEndDate = date => {
    if (!startDate) {
      // If no start date is selected, disable all dates
      return true;
    }

    // Disabled if date is before start date
    if (date.isBefore(startDate)) {
      return true;
    }

    const start = moment(startDate).startOf("day");
    const end = moment(startDate)
      .add(2, "days")
      .endOf("day");

    return date.isBetween(start, end, null, "[]");
  };

  // Set End Date based on the number of days inputted by the user
  const handleNumberOfDaysChange = value => {
    setNumberOfDays(value);
    if (value && value > 0) {
      const endDate = moment(startDate).add(value, "days");
      onChange("endDate", endDate.toISOString());
    }
  };

  useEffect(() => {
    // Update numberOfDays when both startDate and endDate are available
    if (startDate && endDate) {
      const daysDifference = moment(endDate).diff(startDate, "days");
      setNumberOfDays(daysDifference);
    }
  }, [startDate, endDate]);

  return (
    <div>
      <Row
        style={{
          padding: "15px",
        }}
      >
        <Col span={24}>
          <Row>
            <Title level={4} style={styles.title}>
              Name
            </Title>
          </Row>
          <Row>
            <Text
              type="secondary"
              style={{ marginTop: "10px", color: "#bababa" }}
            >
              This is the name you use to identify your campaign.
            </Text>
          </Row>
          <Input
            className="span-mobile"
            value={campaignName}
            onChange={e => {
              onChange("campaignName", e.target.value);
            }}
            //removed white spaces from the start and end of the string
            onBlur={e => {
              onChange("campaignName", e.target.value.trim());
            }}
            placeholder="Enter a name for this campaign"
            style={{ width: "75%", margin: "10px 0 10px 0" }}
            allowClear
            disabled={isLive && !canEdit}
            maxLength={100}
          />
          <br />
        </Col>
      </Row>
      <Row
        style={{
          padding: "15px",
        }}
      >
        <Col span={24}>
          <Row>
            <Title level={4} style={styles.title}>
              Description
            </Title>
          </Row>
          <Row>
            <Text
              type="secondary"
              style={{ marginTop: "10px", color: "#bababa" }}
            >
              Optional - This is the description you use to identify your
              campaign.
            </Text>
          </Row>
          <Input
            className="span-mobile"
            value={description}
            onChange={e => {
              onChange("description", e.target.value);
            }}
            placeholder="Optional - Enter a description for this campaign"
            style={{ width: "75%", margin: "10px 0 10px 0" }}
            allowClear
            disabled={isLive && !canEdit}
          />
          <br />
        </Col>
      </Row>
      <Row
        style={{
          padding: "15px",
        }}
      >
        <Col xs={24} md={24} lg={12}>
          <Title level={4} style={styles.title}>
            Type
          </Title>
          <Text
            type="secondary"
            style={{ marginTop: "10px", color: "#bababa" }}
          >
            What type of campaign is this?
          </Text>
          <Row>
            <Select
              className="span-mobile"
              style={{ width: 120, marginTop: 10 }}
              onChange={value => onChange("type", value)}
              options={[
                { label: "Display", value: 1, key: 1 },
                {
                  label: "External",
                  value: 7,
                  key: 7,
                },
                {
                  label: "Video",
                  value: 8,
                  key: 8,
                },
              ]}
              value={type}
            ></Select>
          </Row>
        </Col>

        <Col xs={24} md={24} lg={12}>
          <Title level={4} style={styles.title}>
            Advertiser
          </Title>
          <Text
            type="secondary"
            style={{ marginTop: "10px", color: "#bababa" }}
          >
            Who is this campaign promoting?
          </Text>
          <SubOrgsLoader
            className="span-mobile"
            orgId={
              currentUser &&
              currentUser.role &&
              currentUser.role.org &&
              currentUser.role.org.id
            }
            advertiser={advertiser}
            onChange={value => onChange("advertiser", value)}
            isLive={isLive && !canEdit}
          >
            <OrgSelect />
          </SubOrgsLoader>
        </Col>
      </Row>
      <Row
        style={{
          padding: "15px",
        }}
      >
        <Col xs={24} md={24} lg={24}>
          <Title level={4} style={styles.title}>
            Flight Dates
          </Title>
          <Text type="secondary" style={{ color: "#bababa" }}>
            You can stop or pause your campaign at anytime
          </Text>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24} md={24} lg={8}>
              <Title level={4} style={styles.titleSecondary}>
                Start Date
              </Title>
              <DatePicker
                className="span-mobile"
                style={{ margin: "10px 0 10px 0", width: "75%" }}
                value={startDate ? moment(startDate) : null}
                onChange={date => handleStartDateChange(date)}
                disabled={isLive && !canEdit}
                disabledDate={date =>
                  !isTrafficker ? date.isBefore(moment().startOf("day")) : false
                }
              />
              <br />
              <Text style={{ color: "#bababa" }} type="secondary">
                Clear field to start As Soon As Possible
              </Text>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Title level={4} style={styles.titleSecondary}>
                End Dates
              </Title>
              <DatePicker
                className="span-mobile"
                style={{ margin: "10px 0 10px 0", width: "75%" }}
                value={endDate ? moment(endDate) : null}
                disabled={isLive && !canEdit}
                onChange={date => {
                  onChange("endDate", date ? date.toISOString() : null);
                }}
                disabledDate={disabledEndDate}
              />
              <br />
              <Text style={{ color: "#bababa" }} type="secondary">
                Clear field to run continuously
              </Text>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Title level={4} style={styles.titleSecondary}>
                Number of Days
              </Title>
              <InputNumber
                style={{ margin: "10px 0 10px 0", width: "75%" }}
                className="span-mobile"
                value={numberOfDays}
                onChange={value => {
                  handleNumberOfDaysChange(parseFloat(value));
                }}
              />
              <br />
              <Text style={{ color: "#bababa" }} type="secondary">
                Enter a number to adjust the end date by that many days.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          padding: "15px",
        }}
      >
        <Col xs={24}>
          <Row type="flex" align="middle">
            <Col xs={24} md={24} lg={8}>
              <Title level={4} style={styles.title}>
                Categories
              </Title>
              <Text type="secondary" style={{ color: "#bababa" }}>
                What categories apply to this campaign?
              </Text>
            </Col>
            <Col xs={24} md={24} lg={16}>
              <Tooltip title="Why is this important? Some products, such as Cannabis, are banned or highly regulated by the federal or state gov't.  This allows us to take a closer look for compliance reasons.">
                <Checkbox
                  onChange={e => {
                    const { checked } = e.target;
                    onChange("isRegulated", checked);
                  }}
                  disabled={isLive && !canEdit}
                  checked={isRegulated}
                >
                  <Text type="secondary" style={{ color: "#bababa" }}>
                    Check here if this campaign is for a regulated product.
                  </Text>
                </Checkbox>
              </Tooltip>
            </Col>
          </Row>
          <Select
            className="span-mobile"
            value={category}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={category}
            onChange={value => {
              onChange("category", value);
            }}
            style={{ width: "75%", margin: "15px 0 15px 0" }}
            disabled={isLive && !canEdit}
          >
            {iabCategories
              .sort((a, b) => {
                if (a.category < b.category) {
                  return -1;
                }
                if (a.category > b.category) {
                  return 1;
                }
                return 0;
              })
              .map(option => {
                return (
                  <Option key={option.code} value={option.code}>
                    {option.category}
                  </Option>
                );
              })}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default BasicsCard;
