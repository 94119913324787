import gql from "graphql-tag";

const GET_ALL_SEGMENTS = gql`
  query segments($member_id: Int!) {
    getSegments(where: { member_id: $member_id }) {
      appnexusId
      code
      active
      short_name
      description
      member_id
      category
      price
      last_activity
      advertiser_id
      provider
    }
  }
`;

export default GET_ALL_SEGMENTS;
