const AudienceType = {
  Devices: "Devices",
  Individuals: "Individuals",
};

const SegmentType = {
  Include: "Include",
  Exclude: "Exclude",
};

const METRICS_ORDER_BY_VALUE = [
  "sci_estimated_income_range",
  "sci_length_of_residence",
  "sci_networth_range",
];

export { AudienceType, SegmentType, METRICS_ORDER_BY_VALUE };
