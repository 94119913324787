import gql from "graphql-tag";

const CART_PROVIDER = gql`
  query CartProvider {
    __type(name: "CartProvider") {
      name
      enumValues {
        name
      }
    }
  }
`;

export default CART_PROVIDER;
