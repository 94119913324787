import gql from "graphql-tag";

const DELETE_ADGROUP_CREATIVES = gql`
  mutation deleteAdgroup($id: ID) {
    deleteAdGroupCreatives(where: { id: $id }) {
      id
      name
    }
  }
`;

export default DELETE_ADGROUP_CREATIVES;
