import React from "react";
import moment from "moment";
import SummaryView from "../../../../../platform/ux/SeoRefactored/details/tabs/SummaryView";
import {
  formatCurrency2SigFig,
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../utils/campaigns";
import { isEmpty } from "lodash";

const SeoSummaryDetailsController = ({ reportData, setReportData, domain }) => {
  const seoReport = reportData.ChartData.byGoogleAnalytics[0].rowData;
  const rankingsReport = reportData.rankingReports;
  const filterReportsByDate = dateRange => {
    const filteredByDate = seoReport.filter(row => {
      const date = moment(row.date, "YYYYMMDD").format("YYYY-MM-DD");
      return (
        moment(date, "YYYY-MM-DD").isSameOrAfter(moment(dateRange[0])) &&
        moment(date, "YYYY-MM-DD").isSameOrBefore(moment(dateRange[1]))
      );
    });
    return filteredByDate;
  };
  const reportBuilder = report => {
    let byDate = [];
    const summaryReport = report.reduce(
      (acc, curr) => {
        const sessions = parseFloat(curr.sessions ? curr.sessions : 0);
        const newUsers = parseFloat(curr.newUsers ? curr.newUsers : 0);
        const revenue = parseFloat(curr.revenue ? curr.revenue : 0);
        const transactions = parseFloat(
          curr.transactions ? curr.transactions : 0
        );
        const goalCompletions = parseFloat(
          curr.goalCompletions ? curr.goalCompletions : 0
        );
        const goalValue = parseFloat(curr.goalValue ? curr.goalValue : 0);
        const bounces = parseFloat(curr.bounces ? curr.bounces : 0);

        //Incremental KPI's
        acc.sessions += sessions;
        acc.newUsers += newUsers;
        acc.revenue += revenue;
        acc.transactions += transactions;
        acc.goalCompletions += goalCompletions;
        acc.goalValue += goalValue;
        acc.bounces += bounces;
        acc.recordCount += 1;
        //KPI's with Averaging Formula
        acc.sessionDuration += parseFloat(curr.avgSessionDuration);
        acc.conversionRate += parseFloat(curr.conversionRate);
        acc.orderValue += parseFloat(curr.avgOrderValue);
        acc.pageView += parseFloat(curr.pageView);

        const avgSessionDuration = acc.sessionDuration / acc.recordCount;
        const avgConversionRate = acc.conversionRate / acc.recordCount;
        const avgOrderValue = acc.orderValue / acc.recordCount;
        const pagesPerSession = acc.pageView / acc.recordCount;
        //TODO: Check if this is showing accurate values
        acc.avgSessionDuration = avgSessionDuration;
        acc.avgConversionRate = avgConversionRate;
        acc.avgOrderValue = avgOrderValue;
        acc.pagesPerSession = pagesPerSession;

        const bounceRate = (acc.bounces / acc.sessions) * 100;
        const goalConversionRate = (acc.goalCompletions / acc.sessions) * 100;
        const ecomConversionRate = (acc.transactions / acc.sessions) * 100;
        //Formula Specific KPI's
        acc.bounceRate = bounceRate;
        acc.goalConversionRate = goalConversionRate;
        acc.ecomConversionRate = ecomConversionRate;

        if (curr.landingPages) {
          acc.landingPages.push(curr.landingPage);
        }

        const date = moment(curr.date, "YYYYMMDD").format("YYYY-MM-DD");
        byDate.push({
          date: moment(date)
            .utc(true)
            .format(),
          sessions,
          newUsers,
          pagesPerSession,
          revenue,
          transactions,
          goalCompletions,
          goalValue,
          bounces,
          avgSessionDuration,
          avgConversionRate,
          avgOrderValue,
          bounceRate,
          goalConversionRate,
          ecomConversionRate,
          landingPages: curr.landingPages,
        });

        return acc;
      },
      {
        sessions: 0,
        newUsers: 0,
        pageView: 0,
        pagesPerSession: 0,
        bounceRate: 0,
        sessionDuration: 0,
        avgSessionDuration: 0,
        conversionRate: 0,
        avgConversionRate: 0,
        orderValue: 0,
        avgOrderValue: 0,
        ecomConversionRate: 0,
        revenue: 0,
        transactions: 0,
        goalCompletions: 0,
        goalValue: 0,
        goalConversionRate: 0,
        landingPages: [],
        bounces: 0,
        recordCount: 0,
      }
    );

    const sortedByDate = byDate.sort(
      (a, b) => moment(a.date).unix() - moment(b.date).unix()
    );

    return {
      summary: summaryReport,
      byDate,
      dateRange: [
        !isEmpty(sortedByDate) ? sortedByDate[0].date : null,
        !isEmpty(sortedByDate)
          ? sortedByDate[sortedByDate.length - 1].date
          : null,
      ],
    };
  };

  const rankingsReportGenerate = (
    rankingsReport,
    isAllDevice = false,
    desktopRanking,
    mobileRanking,
    tabletRanking
  ) => {
    const {
      currentKeywords,
      lastMonthKeywords,
      lastWeekKeywords,
    } = rankingsReport;
    const keywordRankingsSummary = {
      keywordFirstPage: 0,
      keywordSecondPage: 0,
      keywordImprove: 0,
      totalKeywords: 0,
    };

    const keywordReportRankings = currentKeywords.map(keyword => {
      const lastMonthKeywordsMatch = lastMonthKeywords.find(
        mntKeyword => mntKeyword.keyword === keyword.keyword
      );
      const lastWeekKeywordsMatch = lastWeekKeywords.find(
        wkKeyword => wkKeyword.keyword === keyword.keyword
      );

      if (isAllDevice) {
        const desktopRank = desktopRanking.find(
          dskRank => dskRank.keyword === keyword.keyword
        );
        const mobileRank = mobileRanking.find(
          mblRank => mblRank.keyword === keyword.keyword
        );
        const tabletRank = tabletRanking.find(
          tblRank => tblRank.keyword === keyword.keyword
        );

        return {
          ...keyword,
          currentRank: formatNumber2SigFig(keyword.position),
          lastMonthRank: lastMonthKeywordsMatch
            ? formatNumber2SigFig(lastMonthKeywordsMatch.position)
            : null,
          lastWeekRank: lastWeekKeywordsMatch
            ? formatNumber2SigFig(lastWeekKeywordsMatch.position)
            : null,
          desktopRank: desktopRank
            ? formatNumber2SigFig(desktopRank.position)
            : null,
          mobileRank: mobileRank
            ? formatNumber2SigFig(mobileRank.position)
            : null,
          tabletRank: tabletRank
            ? formatNumber2SigFig(tabletRank.position)
            : null,
        };
      }
      return {
        ...keyword,
        currentRank: formatNumber2SigFig(keyword.position),
        lastMonthRank: lastMonthKeywordsMatch
          ? formatNumber2SigFig(lastMonthKeywordsMatch.position)
          : null,
        lastWeekRank: lastWeekKeywordsMatch
          ? formatNumber2SigFig(lastWeekKeywordsMatch.position)
          : null,
      };
    });

    keywordReportRankings.forEach(keyword => {
      if (keyword.currentRank <= 10) {
        keywordRankingsSummary.keywordFirstPage += 1;
      } else if (keyword.currentRank > 10) {
        keywordRankingsSummary.keywordSecondPage += 1;
      }
      if (keyword.currentRank < keyword.lastWeekRank) {
        keywordRankingsSummary.keywordImprove += 1;
      }
      keywordRankingsSummary.totalKeywords += 1;
    });

    return { keywordReportRankings, keywordRankingsSummary };
  };

  const rankingsReportBuilder = report => {
    if (!report) {
      return null;
    }
    const {
      keywordRankings,
      keywordRankingsDesktop,
      keywordRankingsMobile,
      keywordRankingsTablet,
    } = report;

    const desktopRankings = rankingsReportGenerate(keywordRankingsDesktop);
    const mobileRankings = rankingsReportGenerate(keywordRankingsMobile);
    const tabletRankings = rankingsReportGenerate(keywordRankingsTablet);
    const allDevicesRankings = rankingsReportGenerate(
      keywordRankings,
      true,
      keywordRankingsDesktop.currentKeywords,
      keywordRankingsMobile.currentKeywords,
      keywordRankingsTablet.currentKeywords
    );

    return {
      keywordReportRankings: allDevicesRankings.keywordReportRankings,
      keywordRankingsSummary: allDevicesRankings.keywordRankingsSummary,
      desktopKeywordReportRankings: desktopRankings.keywordReportRankings,
      desktopKeywordRankingsSummary: desktopRankings.keywordRankingsSummary,
      mobileKeywordReportRankings: mobileRankings.keywordReportRankings,
      mobileKeywordRankingsSummary: mobileRankings.keywordRankingsSummary,
      tabletKeywordReportRankings: tabletRankings.keywordReportRankings,
      tabletKeywordRankingsSummary: tabletRankings.keywordRankingsSummary,
    };
  };

  const formattedRankingsReport = rankingsReportBuilder(rankingsReport);
  const formattedReport = reportBuilder(seoReport);

  const generateDataSummary = summary => {
    return {
      visible: true,
      values: [
        {
          title: "Sessions",
          value: formatWholeNumber(summary.sessions),
          tooltip:
            "Sessions represent the total number of visits or interactions on a website within a specific time period. Each session can include multiple pageviews and interactions.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "New Users",
          value: formatWholeNumber(summary.newUsers),
          tooltip:
            "New Users are the individuals who visit a website for the first time during a given time frame. This is a valuable metric for assessing user acquisition.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Pages / Sessions",
          value: formatNumber2SigFig(summary.pagesPerSession),
          tooltip:
            "This metric calculates the average number of pages viewed during a single session on your website. It measures how engaging your content is to visitors.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Bounce Rate",
          value: `${formatNumber2SigFig(summary.bounceRate)}%`,
          tooltip:
            "Bounce Rate indicates the percentage of visitors who land on a webpage and leave without taking an action. An action is an engagement, such as a click. In a standard setup, scrolling is not considered an action. A high bounce rate can suggest that the webpage's content or design needs improvement, but not always.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Sessions",
          value: `${("0" + Math.floor(summary.avgSessionDuration / 60)).slice(
            -2
          )}:${(
            "0" +
            Math.round(
              summary.avgSessionDuration -
                Math.floor(summary.avgSessionDuration / 60) * 60
            )
          ).slice(-2)}`,
          tooltip:
            "This metric provides the average duration visitors spend on your website during a session, typically displayed in minutes and seconds. Longer average session durations often indicate more engaged users.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Goal Completions",
          value: formatWholeNumber(summary.goalCompletions),
          tooltip:
            "Goal Completions track specific actions or milestones that you define as conversions on your website. These can include form submissions, downloads, or other desired user interactions.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Goal Value",
          value: formatWholeNumber(summary.goalValue),
          tooltip:
            "Goal Value assigns a monetary value to each completed goal, helping you measure the financial impact of user conversions on your website.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Goal Conversion Rate",
          value: `${formatNumber2SigFig(summary.goalConversionRate)}%`,
          tooltip: "Goal Conversion Rate = (Goal Completions / Session) * 100",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const generateROISummary = summary => {
    return {
      visible: formattedReport.summary.transactions > 0,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(summary.revenue),
          tooltip:
            "Revenue represents the total income generated through your website, often attributed to e-commerce transactions, product sales, or services rendered online.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Conversions",
          value: formatCurrency2SigFig(summary.transactions),
          tooltip:
            "Online Orders quantify the number of completed purchase transactions made on your website, a critical metric for e-commerce businesses.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Order",
          value: formatCurrency2SigFig(summary.avgOrderValue),
          tooltip:
            "Average Order calculates the mean value of orders placed on your website, offering insights into customer spending patterns.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Ecom Conv. Rate",
          value: `${formatNumber2SigFig(summary.ecomConversionRate)}%`,
          tooltip:
            "Ecom Conversions Rate = (No. of Conversions / Sessions) * 100",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const generateKeywordRankingsSummary = rankings => {
    return {
      visible: true,
      values: [
        {
          title: "Total Keywords",
          value: formatWholeNumber(rankings.totalKeywords),
          tooltip: "Total Keywords represents the number of keywords tracked.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Keywords on Page 1",
          value: formatWholeNumber(rankings.keywordFirstPage),
          tooltip:
            "Keywords on First Page represents the number of keywords ranking on the first page of search results.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Keywords on Page 2",
          value: formatWholeNumber(rankings.keywordSecondPage),
          tooltip:
            "Keywords on Second Page represents the number of keywords ranking on the second page or higher of search results.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "IMPROVED RANKINGS",
          value: formatWholeNumber(rankings.keywordImprove),
          tooltip:
            "Keywords Improved represents the number of keywords that improved in ranking.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  return (
    <SummaryView
      summary={formattedReport.summary}
      byDate={formattedReport.byDate}
      dateRange={formattedReport.dateRange}
      generateDataSummary={generateDataSummary}
      generateROISummary={generateROISummary}
      reportBuilder={reportBuilder}
      filterReportsByDate={filterReportsByDate}
      setReportData={setReportData}
      rankingsReport={formattedRankingsReport}
      generateKeywordRankingsSummary={generateKeywordRankingsSummary}
      domain={domain}
    />
  );
};

export default SeoSummaryDetailsController;
