import gql from "graphql-tag";

const UPDATE_PAST_CAMPAIGN_ORDER_REVIEW = gql`
  mutation campaignOrderReview(
    $campaignOrderID: ID
    $review: String
    $stateLaw: String
    $scaleBudget: String
    $reviewerId: String
  ) {
    updateCampaignOrder(
      where: { id: $campaignOrderID }
      data: {
        campaignReviewProcess: {
          create: {
            review: $review
            stateLaw: $stateLaw
            scaleBudget: $scaleBudget
          }
        }
        reviewerId: $reviewerId
      }
    ) {
      id
      campaignReviewProcess {
        review
        stateLaw
        scaleBudget
      }
    }
  }
`;

export default UPDATE_PAST_CAMPAIGN_ORDER_REVIEW;
