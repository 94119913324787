import gql from "graphql-tag";

const UPDATE_CAMPAIGN_ORDER_REQUEST = gql`
  mutation updateCampaignOrder($campaignID: ID!, $isRequestChange: String) {
    updateCampaignOrder(
      where: { id: $campaignID }
      data: { isRequestChange: $isRequestChange }
    ) {
      isRequestChange
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_REQUEST;
