import React from "react";
import { PageContentLayout } from "../../shared/layout";
import { Card, Col, Row, Empty, Typography, Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { InvoiceView } from "./components";

//style
import {
  cardMargin,
  cardStyleHeight,
  colPadding,
  cardBody,
  cardData,
} from "../../../platform/shared/globalStyling/style";

const { Text } = Typography;

const PaymentView = props => {
  let {
    invoiceList,
    invoiceFilterList,
    tableLoading,
    totalPaid,
    totalUnpaid,
    totalOverdue,
    totalTransaction,
    handleFilter,
  } = props;

  return (
    <React.Fragment>
      <PageContentLayout>
        <Row type="flex">
          <Col xs={24} sm={12} md={12} lg={6} xl={6} style={colPadding}>
            <Card style={{ ...cardMargin, ...cardStyleHeight }}>
              <Row style={{ marginBottom: "10px" }}>
                <Text type="secondary">Total Overdue:</Text>
              </Row>
              <Row>
                <Text strong style={cardData}>
                  {totalOverdue}
                </Text>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} style={colPadding}>
            <Card style={{ ...cardMargin, ...cardStyleHeight }}>
              <Row style={{ marginBottom: "10px" }}>
                <Text type="secondary">Total Unpaid:</Text>
              </Row>
              <Row>
                <Text strong style={cardData}>
                  {totalUnpaid}
                </Text>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} style={colPadding}>
            <Card style={{ ...cardMargin, ...cardStyleHeight }}>
              <Row style={{ marginBottom: "10px" }}>
                <Text type="secondary">Total Paid:</Text>
              </Row>
              <Row>
                <Text strong style={cardData}>
                  {totalPaid}
                </Text>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6} xl={6} style={colPadding}>
            <Card style={{ ...cardMargin, ...cardStyleHeight }}>
              <Row style={{ marginBottom: "10px" }}>
                <Text type="secondary">Transactions</Text>
              </Row>
              <Row>
                <Text strong style={cardData}>
                  {totalTransaction}
                </Text>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={colPadding}>
            <Card
              style={cardMargin}
              bodyStyle={cardBody}
              title="Invoice Transactions"
              loading={tableLoading}
              extra={
                <Select
                  suffixIcon={<FilterOutlined />}
                  defaultValue="UNPAID"
                  onChange={handleFilter}
                  style={{ width: 120 }}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    {
                      value: "PAID",
                      label: "Paid",
                    },
                    {
                      value: "UNPAID",
                      label: "Unpaid",
                    },
                    {
                      value: "SCHEDULED",
                      label: "Scheduled",
                    },
                    {
                      value: "CANCELED",
                      label: "Canceled",
                    },
                    {
                      value: "REFUNDED",
                      label: "Refunded",
                    },
                  ]}
                />
              }
            >
              {invoiceList && invoiceList.length !== 0 ? (
                <InvoiceView
                  invoiceList={invoiceList}
                  invoiceFilterList={invoiceFilterList}
                  loading={tableLoading}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};
export default PaymentView;
