import gql from "graphql-tag";

const REGENERATE_TAG = gql`
  mutation regenerateTag($id: String!, $domain: String!, $cart: String!) {
    regenerateTag(id: $id, domain: $domain, cart: $cart) {
      id
      name
    }
  }
`;

export default REGENERATE_TAG;
