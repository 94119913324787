import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Row, Tooltip } from "antd";
import React, { useState } from "react";
import { ButtonText } from "./globalStyling/styledText";
import Button from "./globalStyling/Button";

const TemplateCSVDownloader = ({
  tooltipMessage,
  rows,
  templateName,
  title,
  styles,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const tooltipText = (
    <React.Fragment>
      <Row>{tooltipMessage}</Row>
    </React.Fragment>
  );

  const downloadTemplate = () => {
    setIsDownloading(true);

    setTimeout(() => {
      const csvContent =
        "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${templateName}.csv`);
      document.body.appendChild(link);
      link.click();

      setIsDownloading(false);
    }, 200);
  };

  return (
    <Tooltip placement="left" title={tooltipText}>
      <Button
        onClick={downloadTemplate}
        type="primary"
        shape="round"
        icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
        loading={isDownloading}
        style={styles ? styles : null}
      >
        <ButtonText text={title} />
      </Button>
    </Tooltip>
  );
};

export default TemplateCSVDownloader;
