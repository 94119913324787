import React, { useState } from "react";
import { Card, Row } from "antd";
// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  CardTitleText,
} from "../../../../../../shared/globalStyling/styledText";
import PaginationTable from "../../../../../../shared/PaginationTable";
// util functions
import {
  formatWholeNumber,
  // formatNumber4SigFig,
} from "../../../../../../../core/utils/campaigns";

const pageSize = 10;
const Frag = React.Fragment;

export const BreakdownByLineItem = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const tableData = data
    .map((record, index) => {
      const { CAMPAIGN_NAME, CAMPAIGN_ID } = record;
      if (!CAMPAIGN_NAME) {
        record.CAMPAIGN_NAME = CAMPAIGN_ID;
      }

      return {
        ...record,
        key: index,
      };
    })
    .filter(rec => {
      return rec.COUNT;
    });

  const cols = [
    {
      title: <TableColumnTitle text={"Line Item"} />,
      dataIndex: "CAMPAIGN_NAME",
      key: "location",
      align: "left",
      width: "30%",
      footerContent: "Totals + Averages",
      sorter: (a, b) => {
        if (a.CAMPAIGN_NAME > b.CAMPAIGN_NAME) return 1;
        if (a.CAMPAIGN_NAME < b.CAMPAIGN_NAME) return -1;
        return 0;
      },
      render: (text, record) => {
        return (
          <Frag>
            <Row>
              <PrimaryTableRowText text={text} />
            </Row>
          </Frag>
        );
      },
    },
    //Temporary - impressions are all displaying zeroes
    /*
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "IMPRESSIONS",
      key: "IMPRESSIONS",
      align: "left",
      width: "15%",
      footerSum: true,
      sorter: (a, b) => {
        return a.IMPRESSIONS - b.IMPRESSIONS;
      },
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
    */
    {
      title: <TableColumnTitle text={"Walk-Ins"} />,
      dataIndex: "COUNT",
      key: "COUNT",
      align: "left",
      width: "15%",
      footerSum: true,
      sorter: (a, b) => {
        return a.COUNT - b.COUNT;
      },
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Unique Walk-Ins"} />,
      dataIndex: "UNIQUE",
      key: "UNIQUE",
      align: "left",
      width: "15%",
      footerSum: true,
      sorter: (a, b) => {
        return a.UNIQUE - b.UNIQUE;
      },
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
  ];

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      headStyle={{ borderWidth: "3px" }}
      title={<CardTitleText text={"Visits - By Line Item"} />}
    >
      <PaginationTable
        rowKey={record => record.key}
        total={tableData.length}
        bordered={false}
        columns={cols}
        scroll={{ y: 300, x: 800 }}
        data={tableData}
        paginationSize={"default"}
        size={"default"}
        current={currentPage}
        pageSize={pageSize}
        footer={tableData.slice(
          currentPage * pageSize - pageSize,
          currentPage * pageSize
        )}
        onChange={paginationObject => {
          setCurrentPage(paginationObject.current);
        }}
      />
    </Card>
  );
};
