import React from "react";

const DemographicsMainController = props => {
  const {
    history,
    audienceSchema,
    audienceDemographs,
    saveAudiencesToApi,
    createAudienceDemograph,
    updateAudienceDemograph,
    refetchAudienceDemographs,
    deleteAudienceDemograph,
    refetchAudienceCount,
    refetchAudienceSchema,
    loadingPublishing,
    loadingAudienceCount,
    loadingAudienceSchema,
    loadingAudienceDemographs,
    orgId,
    userId,
    getAudienceDemographs,
  } = props;

  const handleSaveAudiencesToApi = async data => {
    return saveAudiencesToApi({ variables: { data: data } });
  };

  // Execute the mutation to create a new Audience
  const handleCreateAudience = async data => {
    return createAudienceDemograph({
      variables: {
        data: {
          name: data.name,
          description: data.description,
          reachCount: data.reachCount,
          tags: { set: data.tags },
          metrics: { set: data.metrics },
          createdBy: { connect: { id: userId } },
          org: { connect: { id: orgId } },
        },
      },
    });
  };

  const handleUpdateAudience = async data => {
    const { id = null } = data;

    if (!id) {
      return false;
    }

    delete data.id;

    return updateAudienceDemograph({
      variables: {
        id: id,
        data: { ...data },
      },
    });
  };

  const deleteAudience = async id => {
    return deleteAudienceDemograph({
      variables: {
        id: id,
      },
    });
  };

  const handleFetchAudienceDemographs = async (props = {}) => {
    const {
      nameSearch = undefined,
      isArchived = false,
      isPublished = undefined,
    } = props;
    getAudienceDemographs({
      variables: {
        whereParameters: {
          org: { id: orgId },
          name_contains: nameSearch,
          isArchived: isArchived,
          isPublished: isPublished,
        },
      },
    });
  };

  // Request Device Count of the Audience parameter selected by the user
  const fetchAudienceCount = async params => {
    return await refetchAudienceCount({
      metric: params,
    });
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        history,
        audienceSchema,
        audienceDemographs,
        refetchAudienceDemographs,
        refetchAudienceSchema,
        loadingPublishing,
        loadingAudienceCount,
        loadingAudienceSchema,
        loadingAudienceDemographs,
        handleSaveAudiencesToApi,
        handleCreateAudience,
        handleUpdateAudience,
        deleteAudience,
        fetchAudienceCount,
        handleFetchAudienceDemographs,
      })}
    </React.Fragment>
  );
};

export default DemographicsMainController;
