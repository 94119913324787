import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Tooltip,
  Popconfirm,
} from "antd";
import { isEmpty } from "lodash";
import BannersUploadTable from "./BannersUploadTable";
import BannerUploader from "./BannerUploader";
import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  LayoutOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

const Frag = React.Fragment;
const { Text, Title } = Typography;
const styles = {
  internalRow: {
    marginBottom: "50px",
  },
  title: {
    color: "#636363",
    margin: 0,
    fontWeight: "normal",
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
};

const CardTitle = ({ text, icon }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <LayoutOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{text}</h4>
      </Col>
    </Row>
  );
};

const Buttons = ({ onChangeArrayOfObj, adUnit, index, removeBanners }) => {
  return (
    <Row type="flex" justify="end" gutter={10}>
      <Col>
        <BannerUploader
          onChangeArrayOfObj={onChangeArrayOfObj}
          adUnit={adUnit}
          button={
            <Tooltip title="Accepted file types: .jpg, .jpeg, .png, .gif, and .mp4.">
              <Button shape="round" icon={<CloudUploadOutlined />}>
                Upload Banners
              </Button>
            </Tooltip>
          }
          index={index}
        />
      </Col>
      <Col>
        <Tooltip title="Delete selected banners.">
          <Button
            disabled={isEmpty(adUnit.selectedRows)}
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => removeBanners(index)}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

const CreativesModalCard = ({
  onChangeArrayOfObj,
  adUnits,
  addAdUnit,
  removeBanners,
  removeAdUnit,
  isAdmin,
}) => {
  return (
    <Frag>
      <Row>
        <Col span={24}>
          <Row gutter={12} style={styles.row}>
            <Col span={24}>
              <Card bordered={false} title={<CardTitle text="Creatives" />}>
                <Title style={styles.title} level={4}>
                  Group Banners Into Ad Units
                </Title>
                <Text type="secondary">
                  Ad Units are unique banner groupings within any campaign.
                </Text>
                <br />
                <Text type="secondary">
                  Each Ad Unit can have a unique combination of banners as well
                  as a different click-through link if desired.
                </Text>
              </Card>
            </Col>
          </Row>
          {adUnits
            ? adUnits.map((adUnit, index) => {
                return (
                  <Row key={`Ad Unit ${index + 1}`} style={styles.row}>
                    <Col span={24}>
                      <Card
                        title={`Ad Unit ${index + 1 < 10 ? "0" : ""}${index +
                          1}`}
                        extra={
                          <Tooltip
                            placement="bottom"
                            title="Click here to delete this Ad Unit."
                          >
                            {adUnits.length === 1 ? (
                              <Button icon={<CloseOutlined />} shape="circle" />
                            ) : (
                              <Popconfirm
                                title="Are you sure you wnat to delete this Ad Unit?"
                                onConfirm={() => {
                                  removeAdUnit(index);
                                }}
                              >
                                <Button
                                  icon={<CloseOutlined />}
                                  shape="circle"
                                />
                              </Popconfirm>
                            )}
                          </Tooltip>
                        }
                      >
                        <Row style={styles.internalRow}>
                          <Col span={12}>
                            <Row>
                              <Title style={styles.title} level={4}>
                                Name
                              </Title>
                            </Row>
                            <Row style={{ width: "90%" }}>
                              <Input
                                value={adUnit.name}
                                onChange={e => {
                                  onChangeArrayOfObj(
                                    "adUnits",
                                    index,
                                    "name",
                                    e.target.value
                                  );
                                }}
                                style={styles.input}
                                placeholder="Ad Unit Name"
                              />
                            </Row>
                          </Col>
                          <Col span={12}>
                            <Row>
                              <Title style={styles.title} level={4}>
                                Click-Through Link
                              </Title>
                            </Row>
                            <Row style={{ width: "90%" }}>
                              <Input
                                value={adUnit.clickthrough}
                                onChange={e => {
                                  onChangeArrayOfObj(
                                    "adUnits",
                                    index,
                                    "clickthrough",
                                    e.target.value
                                  );
                                }}
                                style={styles.input}
                                placeholder="Please add 'http://' or 'https://' for example 'http://www.example.com'"
                              />
                            </Row>
                            <Row>
                              <Text type="secondary">
                                Where users arrive after clicking a banner in
                                this Ad Unit.
                              </Text>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <Title style={styles.title} level={4}>
                              Banners
                            </Title>
                            <Text type="secondary">
                              These are the banners that will be displayed to
                              users
                            </Text>
                          </Col>
                          <Col span={12}>
                            <Buttons
                              icon2={"cloud-upload"}
                              adUnit={adUnit}
                              onChangeArrayOfObj={onChangeArrayOfObj}
                              index={index}
                              removeBanners={removeBanners}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Card bodyStyle={{ padding: 0 }}>
                            <BannersUploadTable
                              onChangeArrayOfObj={onChangeArrayOfObj}
                              adUnit={adUnit}
                              index={index}
                              isAdmin={isAdmin}
                            />
                          </Card>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                );
              })
            : []}
          {adUnits
            ? !(
                isEmpty(adUnits[adUnits.length - 1].clickthrough) ||
                isEmpty(adUnits[adUnits.length - 1].banners)
              ) && (
                <Row gutter={12} style={styles.row}>
                  <Col span={24}>
                    <Button block onClick={addAdUnit}>
                      <PlusCircleFilled />
                      Ad Unit {adUnits.length + 1 < 10 ? "0" : ""}
                      {adUnits.length + 1}
                    </Button>
                  </Col>
                </Row>
              )
            : []}
        </Col>
      </Row>
    </Frag>
  );
};

export default CreativesModalCard;
