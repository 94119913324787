import gql from "graphql-tag";
const PAID_SEARCH_CAMPAIGN = gql`
  query paidSearchCampaign($orgId: ID!, $campaignId: ID!) {
    org(where: { id: $orgId }) {
      id
      paidSearchSummary {
        id
        paidSearchBingCampaigns(where: { id: $campaignId }) {
          id
          name
          status
          startDate
          endDate
          adProviderId
          data {
            id
            impressions
            clicks
            cost
            revenue
            cpc
            cpm
            spend
            ctr
            roas
            conversions
            conversionRate
            byDate(orderBy: date_ASC) {
              conversions
              clicks
              impressions
              ctr
              cost
              cpc
              cpm
              spend
              revenue
              roas
              conversionRate
              date
              xAxis
            }
          }
        }
      }
    }
  }
`;

export default PAID_SEARCH_CAMPAIGN;
