import React from "react";
import BasketItemsTabView from "./BasketItemsTab";
import Loader from "../../../../../../core/components/campaigns/campaigndetails/basketItemsTabs/BasketItemsTabDataLoader";

// Feature level subroutes
const BasketItemsTab = props => {
  return (
    <Loader {...props}>
      <BasketItemsTabView />
    </Loader>
  );
};

export default BasketItemsTab;
