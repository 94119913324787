import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { defaultAttributionWindow } from "../../../../utils/constants/constants";

const BasketItemsController = ({
  children,
  url,
  expiresAt,
  refetch,
  chartData,
  setChartData,
  reportName,
  campaignOrder,
  currentUser,
  location,
  ...props
}) => {
  const [rawData, setRawData] = useState(null);
  const [loadingRawData, setLoadingRawData] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [attributionWindow, setAttributionWindow] = useState(365);

  const dataConfig =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.dataConfig;
  const defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const checkUrlValidity = useCallback(() => {
    const currentTime = moment().unix();
    const expirationTime = moment(expiresAt).unix();

    if (currentTime <= expirationTime) {
      setS3Url(url.toString());
      setIsValidUrl(true);
    } else {
      setIsValidUrl(false);
      refetch();
      setS3Url(null);
    }
  }, [expiresAt, url, refetch]);

  useEffect(() => {
    checkUrlValidity();
  }, [checkUrlValidity]);

  const fetchData = useCallback(async () => {
    if (!s3Url) return;

    setLoadingRawData(true);
    try {
      const response = await fetch(s3Url);
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const json = await response.json();
      if (
        defaultDisplayAttributionWindow !== 30 &&
        json.attributionWindowReports &&
        json.attributionWindowReports.length > 0
      ) {
        const report = json.attributionWindowReports.find(
          report => report.attributionWindow === defaultDisplayAttributionWindow
        );
        if (report) {
          json.data = report.data;
          json.count = report.count;
        }
      }
      setRawData(json);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingRawData(false);
    }
  }, [s3Url, defaultDisplayAttributionWindow]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (rawData && rawData.data && !chartData.basketItems) {
      setChartData({
        ...chartData,
        basketItems: rawData.data,
      });
    }
  }, [rawData, chartData, setChartData]);

  const childProps = isValidUrl
    ? {
        chartData: rawData,
        chartsLoading: loadingRawData,
        reportName,
        searchTerm,
        setSearchTerm,
        attributionWindow,
        setAttributionWindow,
        campaignOrderId: campaignOrder && campaignOrder.id,
        currentUser,
        ...props,
      }
    : {
        chartData,
        currentUser,
        ...props,
      };

  return React.cloneElement(children, childProps);
};

export default BasketItemsController;
