import React from "react";

const style = {
  container: {
    padding: "0 2% 2% 2%",
  },
};

const PageContentLayout = ({ children }) => (
  <div style={style.container}>{children}</div>
);
export default PageContentLayout;
