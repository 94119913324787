import React, { useState } from "react";
import { Tooltip, Row, Col } from "antd";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import SimpleTable from "../../../../../../../../shared/SimpleTable";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
  TooltipText,
} from "../../../../../../../../shared/globalStyling/styledText";

const columns = [
  {
    title: "",
    dataIndex: "color",
    width: "2rem",
    render: text => {
      return (
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: text,
              height: "15px",
              width: "15px",
              minWidth: "15px",
              borderRadius: "50px",
            }}
          />
        </Row>
      );
    },
  },
  {
    title: <TableColumnTitle text={"Product Name"} />,
    dataIndex: "NAME",
    width: "250px",
    align: "left",
    render: text => {
      return (
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            width: "100%",
          }}
        >
          <Col span={16}>
            {text.length > 45 ? (
              <Tooltip title={<TooltipText text={text} />}>
                <span>
                  <PrimaryTableRowText text={text.slice(0, 46).concat("...")} />
                </span>
              </Tooltip>
            ) : (
              <PrimaryTableRowText text={text} />
            )}
          </Col>
        </Row>
      );
    },
    sorter: (a, b) => a.NAME.length - b.NAME.length,
  },
  {
    title: <TableColumnTitle text={"Revenue"} />,
    dataIndex: "REVENUE",
    width: "70px",
    align: "center",
    render: text => {
      return <PrimaryTableRowText text={`$${text}`} />;
    },
    sorter: (a, b) => a.REVENUE - b.REVENUE,
  },
  {
    title: <TableColumnTitle text={"Quantity"} />,
    dataIndex: "QUANTITY",
    width: "70px",
    align: "left",
    render: text => {
      return <PrimaryTableRowText text={text} />;
    },
    sorter: (a, b) => a.QUANTITY - b.QUANTITY,
  },
];

const ReferrerTable = ({ referrerData }) => {
  const location = useLocation();
  const history = useHistory();
  try {
    const [currentPage, setCurrentPage] = useState(1);
    const params = new URLSearchParams(location.search);
    if (!params.get("revenuePageSize")) {
      params.delete("revenuePageSize");
      params.append("revenuePageSize", 10);
    }
    const pageSize = params.get("revenuePageSize");

    const transactionsWithKeys = referrerData.map((item, i) => {
      const record = { ...item };
      record.value = i;
      record.REVENUE = item.REVENUE.toFixed();
      return record;
    });

    return (
      <>
        <SimpleTable
          footer={transactionsWithKeys.slice(
            currentPage * 10 - 10,
            currentPage * 10
          )}
          rowKey="value"
          bordered={false}
          columns={columns}
          data={transactionsWithKeys}
          scroll={{ x: 200, y: 800 }}
          onChange={(paginationObject, filtersObject, sorterObject) => {
            setCurrentPage(paginationObject.current);
            if (params.get("revenuePageSize")) params.delete("revenuePageSize");
            if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
              params.append("revenuePageSize", paginationObject.pageSize);
            }
            history.push(`?${params.toString()}`);
          }}
          pagination={{
            pageSize,
            total: transactionsWithKeys.length,
            current: currentPage,
          }}
        />
      </>
    );
  } catch (err) {}
};

export default ReferrerTable;
