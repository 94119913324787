import React from "react";
import { useQuery } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import CURRENT_USER from "../../../core/GraphQl/Queries/CURRENT_USER";
import { LIST_ORGS } from "./AdvertiserFilter.queries";
import AdvertiserFilterView from "./AdvertiserFilter.view";
import moment from "moment";

export const advertiserIdParamKey = "advertiserId";
export const parentAdvertiserIdParamKey = "parentAdvertiserId";

/**
 * Smart Component
 * Gets data on its own
 */
function AdvertiserFilterContainer({ isSinglePage = false, isDemo = false }) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const currentAdvertiserId = params.get(`${advertiserIdParamKey}`);
  const currentParentAdvertiserId = params.get(`${parentAdvertiserIdParamKey}`);

  const { loading: loadingCurrentUser, data: currentUserData } = useQuery(
    CURRENT_USER
  );

  const currentUsersOrg = currentUserData
    ? currentUserData.currentUser.role.org.name
    : undefined;
  const currentUsersOrgID = currentUserData
    ? currentUserData.currentUser.role.org.id
    : undefined;

  const { loading, data: orgsData } = useQuery(LIST_ORGS, {
    variables: {
      parentid: currentUsersOrgID,
      first: 1000,
      startDate: moment()
        .subtract(6, "months")
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
      endDate: moment()
        .add(1, "days")
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
    },
    fetchPolicy: "cache-first",
  });

  const handleChangeAdvertiser = advertiserId => {
    try {
      params.delete(`${advertiserIdParamKey}`);
    } catch (e) {
      console.log(e);
    }
    let newParamCurrentAdvertiserIds = [];
    newParamCurrentAdvertiserIds = newParamCurrentAdvertiserIds.concat(
      advertiserId
    );
    params.append(
      `${advertiserIdParamKey}`,
      newParamCurrentAdvertiserIds.join("+")
    );
    history.push(`?${params.toString()}`);
  };

  const handleOnClear = () => {};

  const generatedProps = {
    isSinglePage,
    loading: loading || loadingCurrentUser,
    orgsList:
      (orgsData && orgsData.orgs.filter(org => org.name !== currentUsersOrg)) ||
      [],
    currentAdvertiserId: currentAdvertiserId
      ? currentAdvertiserId.split("+")
      : [],
    currentParentAdvertiserId: currentParentAdvertiserId
      ? currentParentAdvertiserId.split("+")
      : [],
    handleOnClear,
    handleChangeAdvertiser,
    isDemo,
  };

  return <AdvertiserFilterView {...generatedProps} />;
}

export default AdvertiserFilterContainer;
