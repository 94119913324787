import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Spin,
  message,
  // , Tooltip
} from "antd";
import ConfirmLocationsModal from "./../../CampaignLauncher/components/ConfirmLocationsModal";
import { PageContentLayout } from "./../../../shared/layout";
//Global Styles
// import Button from "./../../../shared/globalStyling/Button";

import {
  GeoTargetsTable,
  CardExtraButtons,
  FilterMenuButtons,
  ActionButtons,
  FilterModalPerformance,
  TargetDetailsModal,
  FilterModal,
} from "./geoTargetManagementComponents";

const styles = {
  map: {
    position: "relative",
    width: "100%",
    height: "25rem",
  },
  cardRow: {
    backgroundColor: "#f9f9f9",
    padding: "0px 3%",
  },
  cardBody: {
    borderWidth: "0 1px 0 1px",
    borderColor: "#e8e8e8",
    borderStyle: "solid",
  },
};
//const Frag = React.Fragment;

// Define constants for step values to improve readability
const STEP_TARGETING_ADDRESSES = 2;
const STEP_ATTRIBUTION_ADRESSES = 4;

export const GeoTargetManager = props => {
  const { step, refreshAttributionTargets, refetchGeoTarget } = props;

  const [loading, setLoading] = useState(false);

  const [
    filterModalPerformanceVisible,
    setFilterModalPerformanceVisible,
  ] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);

  const toggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const [addGeoTargetPopoverVisible, setAddGeoTargetPopoverVisible] = useState(
    false
  );

  const toggleAddGeoTargetPopover = () => {
    setAddGeoTargetPopoverVisible(!addGeoTargetPopoverVisible);
  };

  const addGeoTargetAreaToSelection = async () => {
    try {
      setLoading(true);
      await props.confirmTargetFromMap();
      await props.refetchGeoTarget();
    } catch (error) {
      message.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const editGeoTargetAreaToSelection = async () => {
    try {
      setLoading(true);
      await props.updateGeoTargetsConfirmed();
      await props.refetchGeoTarget();
    } catch (error) {
      message.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteGeoTargetAreas = async () => {
    await props.batchDeleteClicked();
    // TODO Once this will be used in the other parts of the app, we need to create a constants value for the step values.
    // When the current page is at Step 2 or in Targeting Address, we only need to trigger the refetchGeoTarget to refresh the table data so there would be no unncessary API calls
    if (step === STEP_TARGETING_ADDRESSES) {
      await refetchGeoTarget();
    } else if (step === STEP_ATTRIBUTION_ADRESSES) {
      // When the current page is at Step 4 or in Attribution Address, we need to trigger the refreshAttributionTargets to refresh the table data and the map data
      await refreshAttributionTargets();
    }
    message.success("Successfully deleted selected targets", 5);
  };

  return (
    <>
      {!props.enableHeader && (
        <Card
          title={`${props.targetType} Targets`}
          extra={
            props.isTrafficker && (
              <CardExtraButtons
                newGeoTarget={props.newGeoTarget}
                onCsvUploadComplete={props.onCsvUploadComplete}
                onGeoJsonUploadComplete={props.onGeoJsonUploadComplete}
                newGeoTargetChanged={props.newGeoTargetChanged}
                newGeoTargetConfirmed={props.newGeoTargetConfirmed}
                locationTargetsModalOpen={props.locationTargetsModalOpen}
                toggleAddGeoTargetPopover={toggleAddGeoTargetPopover}
                targetType={props.targetType}
                addGeoTargetPopoverVisible={addGeoTargetPopoverVisible}
                addGeoTargetLoading={props.addGeoTargetLoading}
                modal={props.modal}
              />
            )
          }
        />
      )}
      <Card
        title={
          <FilterMenuButtons
            onFilterClick={toggleFilterModal}
            geoLocationFiltersChanged={props.geoLocationFiltersChanged}
            toggleFilterModal={toggleFilterModal}
            filterModalVisible={filterModalVisible}
            filterAttributeChanged={props.filterAttributeChanged}
            filterValueChanged={props.filterValueChanged}
            filterAttribute={props.filterAttribute}
            filterValue={props.filterValue}
            targetType={props.targetType}
            onPerformanceClick={() => {
              setFilterModalPerformanceVisible(true);
            }}
          />
        }
        extra={
          <ActionButtons
            selectedForBatch={props.selectedForBatch}
            batchDeleteClicked={deleteGeoTargetAreas}
            updateGeoTargetsConfirmed={editGeoTargetAreaToSelection}
            editModalVisible={props.editLocationModalOpen}
            optionsToUpdateChanged={props.optionsToUpdateChanged}
            toggleEditModal={props.toggleEditModal}
            targetType={props.targetType}
            isTrafficker={props.isTrafficker}
          />
        }
        style={styles.cardRow}
        bordered={false}
      />
      <PageContentLayout style={styles.cardBody}>
        <Row style={{ backgroundColor: "white" }}>
          <Col xs={24}>
            <Spin size="large" spinning={loading}>
              <GeoTargetsTable
                onChange={props.onChange}
                geoTargets={props.locationTargets}
                chartAttributionData={props.chartAttributionData}
                selectedForBatchChanged={props.selectedForBatchChanged}
                targetType={props.targetType}
                geoTargetOnClick={props.geoTargetOnClick}
              />
            </Spin>
          </Col>
        </Row>
        <FilterModalPerformance
          filterModalPerformanceVisible={filterModalPerformanceVisible}
          onCancel={() => {
            setFilterModalPerformanceVisible(false);
          }}
        />
      </PageContentLayout>

      {false && (
        <FilterModal
          filterModalVisible={filterModalVisible}
          toggleFilterModal={toggleFilterModal}
          filterAttributeChanged={props.filterAttributeChanged}
          filterValueChanged={props.filterValueChanged}
          filterAttribute={props.filterAttribute}
          filterValue={props.filterValue}
          geoLocationFiltersChanged={props.geoLocationFiltersChanged}
          onCancel={toggleFilterModal}
        />
      )}
      <ConfirmLocationsModal
        showModal={props.locationTargetsModalOpen}
        onChange={props.onChange}
        tableData={props.confirmLocationsTableData}
        selectedModalLocations={props.selectedModalLocations}
        onConfirmLocationOk={props.onConfirmLocationOk}
        onLocationTargetsModalOpenChange={
          props.onLocationTargetsModalOpenChange
        }
        selectedModalLocationsChanged={props.selectedModalLocationsChanged}
      />
      <TargetDetailsModal
        loading={loading}
        targetDetailsModalOpen={props.targetDetailsModalOpen}
        toggleTargetDetailsModal={props.toggleTargetDetailsModal}
        onOk={addGeoTargetAreaToSelection}
        changeTargetDetails={props.changeTargetDetails}
        targetType={props.targetType}
      />
    </>
  );
};
