import gql from "graphql-tag";

const ENABLE_USER = gql`
  mutation enableDashboardUser($id: ID!) {
    enableDashboardUser(where: { id: $id }) {
      id
      name
      username
      email
      phone
      cognitoUserId
      config {
        id
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
      }
      roles(first: 5) {
        id
        roleItems(first: 20) {
          id
          feature
          actions
        }
        org {
          id
          name
          config {
            priceVisible
            providersVisible
            isPartner
            isTrafficker
            isSelfService
            hasInsights
            isAdmin
            isDemo
            exportData
            isProcessor
            canResetPasswords
            campaignSummary
            isPacing
            pastData
            segment
            technicalSupport
            articles
            amplify
            configurator
            billing
            sitesAppsTransactions
          }
          parentOrg {
            id
            name
          }
          logo {
            id
            key
          }
        }
      }
      avatar {
        id
        key
      }
    }
  }
`;

export default ENABLE_USER;
