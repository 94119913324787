import gql from "graphql-tag";

const CREATE_EVENT_TAG = gql`
  mutation createEventTag($data: CreateEventTagInput!) {
    createEventTag(data: $data) {
      id
      name
    }
  }
`;

export default CREATE_EVENT_TAG;
