import React, { useState } from "react";
import Papa from "papaparse";
import { Upload, Tooltip, message, Row } from "antd";
import Button from "../../../shared/globalStyling/Button";
import { zipObject } from "lodash";
import PropTypes from "prop-types";
// Global Styles
import { ButtonText } from "../../../shared/globalStyling/styledText";
import { UploadOutlined } from "@ant-design/icons";

const CsvAdressListUploadButton = ({
  onComplete,
  onGeoJsonComplete,
  showForm,
  onChange,
  locationTargetsModalOpen,
  disabled,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const customRequest = ({ file, onSuccess, onError, onComplete, keys }) => {
    setIsUploading(true);

    if (file.name.toLowerCase().includes("json")) {
      const reader = new FileReader();

      reader.onload = e => {
        onGeoJsonComplete(e.target.result);
        setIsUploading(false);
      };
      reader.readAsText(file);
    } else {
      Papa.parse(file, {
        skipEmptyLines: "greedy",
        error: function(err, file, inputElem, reason) {
          onError({ err, file, inputElem, reason });
        },
        complete: async results => {
          const rows = results.data;
          const headers = results.data.shift();
          if (
            !headers.includes("ADDRESS") &&
            !(headers.includes("LAT") && headers.includes("LON"))
          ) {
            message.error(
              "One or more required fields are missing, please check your CSV and try again."
            );
            setIsUploading(false);
            return;
          }
          const mappedData = rows.map(row => zipObject(headers, row));
          const response = await onComplete(mappedData);
          if (response) {
            message.error(response);
          } else {
            setIsUploading(false);
          }
          onSuccess("done", file);
        },
      });
    }
  };

  const tooltipText = (
    <React.Fragment>
      <Row>
        CSV or GeoJson accepted. Please add "name" and "radius" to GeoJson
        Properties if applicable.
      </Row>
      <Row>Required fields for CSV:</Row>
      <Row>"ADDRESS" OR "LAT" and "LONG"</Row>
      <Row>Optional fields:</Row>
      <Row>"NAME", "RADIUS" (in Meters)</Row>
    </React.Fragment>
  );

  return (
    <Upload
      accept={".csv,.json"}
      showUploadList={false}
      multiple={false}
      customRequest={params =>
        customRequest({ ...params, onComplete: onComplete })
      }
    >
      <Tooltip placement="left" title={tooltipText}>
        <Button
          onClick={showForm}
          disabled={disabled}
          type="primary"
          shape="round"
          // disabled={locationTargetsModalOpen}
          icon={<UploadOutlined />}
          loading={isUploading}
        >
          <ButtonText text={"Upload"} />
        </Button>
      </Tooltip>
    </Upload>
  );
};

export default CsvAdressListUploadButton;

CsvAdressListUploadButton.propTypes = {
  onComplete: PropTypes.func,
};
