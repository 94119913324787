import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import {
  Row,
  Tabs,
  Button,
  Popconfirm,
  Col,
  Empty,
  Tooltip,
  message,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import { useLazyQuery } from "react-apollo";

import { SegmentsForm } from "../../Segments/components";
import Targeting from "./targeting/Targeting";
import Charts from "./charts/Charts";

import {
  AudienceDetailsSegmentsTable,
  ExportDropdown,
  DetailBar,
  Demographics,
  Observations,
  TriggerAirflowDAG,
} from "./components";
import {
  layoutRowBorder,
  layoutRowSpacing,
} from "../../../shared/globalStyling/style";
import { buildDeviceTable, buildTapadBreakdownTable } from "../utils";

// Global Styles
import {
  ButtonText,
  PageTitleText,
  TabText,
  TooltipText,
} from "../../../shared/globalStyling/styledText";

import TRIGGER_AIRFLOW_DAG from "../../../../core/GraphQl/Queries/TRIGGER_AIRFLOW_DAG";
import CommerceDetailsView from "./tabs/commerce/CommerceDetailsView";
import GlobalButton from "../../../shared/globalStyling/Button";

const { TabPane } = Tabs;

const mergeSegments = (geoTargets, eventTags) => {
  const dataToReturn = [];

  geoTargets.forEach(target => {
    const {
      id,
      start,
      end,
      name,
      description,
      observations,
      polygons,
      circles,
      updatedAt,
    } = target;

    const segmentsRecord = {
      name,
      description,
      start,
      end,
      observations,
      polygons,
      circles,
      eventTags: [],
      key: id,
      updatedAt,
    };

    dataToReturn.push(segmentsRecord);
  });

  eventTags.forEach(target => {
    const {
      id,
      start,
      end,
      name,
      description,
      observations,
      eventTags,
      updatedAt,
    } = target;

    const segmentsRecord = {
      name,
      description,
      start,
      end,
      observations,
      polygons: [],
      circles: [],
      eventTags,
      updatedAt,
      key: id,
    };

    dataToReturn.push(segmentsRecord);
  });

  return dataToReturn;
};

const AudienceDetailsView = ({
  reportData,
  reportLoading,
  WRITE,
  orgConfig,
  ...props
}) => {
  const [triggerAirflowDAG] = useLazyQuery(TRIGGER_AIRFLOW_DAG, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data) {
        setTimeout(() => {
          window.location.reload();
        }, 60000);
      }
    },
    onError: error => {
      message.destroy();
      message.error("Error reprocessing");
    },
  });

  const [loading, setLoading] = useState(false);

  const refresh = () => {
    setLoading(true);
    message.loading("Reloading...", 60);
    try {
      triggerAirflowDAG({
        variables: {
          where: {
            dag_id: "retargeting-consolidated-audience-report",
            body: {
              conf: {
                args: `audienceid=${props.audience.id}`,
              },
            },
          },
        },
      });
    } catch (err) {
      message.destroy();
      message.error("Error reprocessing");
      return false;
    }
  };
  let processedAudienceData = [];
  let deviceTable = [];
  let targetBreakDownData = [];

  const { isAdmin, exportData, isDemo, isTrafficker } = orgConfig;

  const { audience } = props;
  let hasCommerce = false;
  if (audience) {
    const { geoTargets, eventsTargets, commerce } = audience;
    if (commerce && commerce.id) hasCommerce = true;
    deviceTable = buildDeviceTable(geoTargets, eventsTargets);
    processedAudienceData = mergeSegments(geoTargets, eventsTargets);
  }

  //Prepare charts for visualizations

  if (reportData && reportData.ChartData) {
    const { ChartData } = reportData;
    if (ChartData) {
      const {
        byZip,
        byCity,
        byReferrer,
        byDate,
        byDay,
        chartsBuilt,
      } = ChartData;

      if (byZip) {
        reportData.byZip = byZip.filter(fitem => {
          const { ZIP } = fitem;
          return ZIP;
        });
      } else {
        reportData.byZip = [];
      }

      if (byCity) {
        reportData.byCity = byCity
          .map(item => {
            item.xAxis = item.CITY;
            item.values = item.MATCHES;
            return item;
          })
          .filter(fitem => {
            return fitem.xAxis;
          });
      } else {
        reportData.byCity = [];
      }

      if (byReferrer) {
        reportData.byReferrer = byReferrer
          .map(item => {
            item.xAxis = item.REFERRER;
            item.values = item.MATCHES;
            return item;
          })
          .filter(fitem => {
            return fitem.xAxis;
          });
      } else {
        reportData.byReferrer = [];
      }

      if (byDay) {
        reportData.byDay = byDay.map(item => {
          const { MATCHES, day } = item;

          item.xAxis = day;
          item.values = MATCHES;

          return item;
        });
      } else {
        reportData.byDay = [];
      }

      if (byDate) {
        reportData.byDate = byDate.map(item => {
          const { EVENT_DATE, MATCHES } = item;
          const adjustedDate = new Date(EVENT_DATE);
          item.xAxis = adjustedDate.toISOString();
          item.values = MATCHES;
          item.impressions = MATCHES;

          return item;
        });
      } else {
        reportData.byDate = [];
      }

      reportData.chartsBuilt = chartsBuilt;
    }
  }

  //Process device graph break down

  if (reportData && reportData.TAPAD) {
    const { TAPAD } = reportData;
    targetBreakDownData = buildTapadBreakdownTable(TAPAD);
  }

  const {
    uniqueCount,
    deviceIdCount,
    geoTargets,
    eventsTargets,
    type,
    name,
    commerce,
  } = audience;
  const { byDate, byDay, byCity, byZip, byReferrer } = reportData;

  const tags = [];
  if (eventsTargets.length > 0) {
    for (const eventsTarget of eventsTargets) {
      const { eventTags, name, tag } = eventsTarget;
      if (!isEmpty(tag)) {
        if (eventTags.length > 0) {
          tags.push({ appId: eventTags[0].appId[0], name });
        }
      }
    }
  }

  let expandedDeviceCount = 0;
  if (geoTargets && !isEmpty(geoTargets)) {
    geoTargets.forEach(target => {
      const { expandedReach } = target;
      if (expandedReach) {
        expandedDeviceCount = expandedDeviceCount + expandedReach;
      }
    });
  }
  if (eventsTargets && !isEmpty(eventsTargets)) {
    eventsTargets.forEach(target => {
      const { expandedReach } = target;
      if (expandedReach) {
        expandedDeviceCount = expandedDeviceCount + expandedReach;
      }
    });
  }
  const audienceData = [uniqueCount, deviceIdCount, expandedDeviceCount];
  const isEmptyAudience = audienceData.every(item => item === 0);
  const isEmptyTargeting = deviceTable.every(data => data.IDENTIFIERS === 0);
  const isEmptyCharts =
    isEmpty(byDate) &&
    isEmpty(byDay) &&
    isEmpty(byCity) &&
    isEmpty(byZip) &&
    isEmpty(byReferrer);
  let tagName = name;
  if (type === "RETARGETING") {
    const tagNameFind = tags.find(t => t.appId === name);
    if (tagNameFind) tagName = tagNameFind.name;
  }
  const title = `${isDemo ? "Example Audiences" : tagName}${
    props.audience.description
      ? ` - ${
          props.audience.description.includes("undefined")
            ? props.audience.description.replace("undefined", "")
            : props.audience.description
        }`
      : ""
  }`;

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={
          <Row gutter={16}>
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Tooltip title={<TooltipText text={title} />}>
                <Row type="flex" align="middle">
                  <PageTitleText text={title} />
                </Row>
              </Tooltip>
            </Col>
          </Row>
        }
        {...(isEmptyAudience &&
        isEmptyTargeting &&
        isEmptyCharts &&
        !hasCommerce
          ? {}
          : {
              rightContent: (
                <Row gutter={16} align={"middle"}>
                  {isTrafficker && (
                    <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                      <Row>
                        <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                          <GlobalButton
                            style={{ width: "100%" }}
                            type="primary"
                            shape="round"
                            loading={loading}
                            icon={<ReloadOutlined />}
                            onClick={refresh}
                          >
                            <ButtonText text="Refresh Metrics" />
                          </GlobalButton>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {isTrafficker && (
                    <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                      <TriggerAirflowDAG
                        audienceId={audience.id}
                        disabled={loading}
                      />
                    </Col>
                  )}
                  {exportData ? (
                    <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                      <ExportDropdown
                        audience={props.audience}
                        disabled={loading}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            })}
      />
      <PageContentLayout>
        {isEmptyAudience &&
        isEmptyTargeting &&
        isEmptyCharts &&
        !hasCommerce ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "75vh" }}
          >
            <Empty
              description="Please refresh your browser to receive the latest updates."
              style={{ margin: "auto" }}
            />
          </Row>
        ) : (
          <>
            <Tabs
              //Tab layout chosen to support future growth
              onChange={selected => {}}
            >
              {!isEmptyAudience && (
                <TabPane tab={<TabText text={"Summary"} />} key="Charts">
                  <DetailBar
                    name={"name"}
                    selectedAudience={audience}
                    selectedProvider={{}}
                  />
                  {/*Body Code Here */}
                  <Targeting
                    chartData={targetBreakDownData}
                    chartsLoading={reportLoading}
                    reachData={deviceTable}
                  />
                  <Charts
                    chartData={reportData}
                    chartsLoading={reportLoading}
                    selectedAudience={audience}
                  />
                </TabPane>
              )}

              {hasCommerce && (
                <TabPane tab={<TabText text={"Transactions"} />}>
                  <CommerceDetailsView
                    id={commerce.id}
                    match={props.match}
                    location={props.location}
                    history={props.history}
                  />
                </TabPane>
              )}
            </Tabs>

            {isAdmin && !isDemo ? (
              <Row style={{ ...layoutRowBorder, ...layoutRowSpacing }}>
                <Col span={24}>
                  <SegmentsForm
                    submit={props.submitUpdateAudience}
                    name={props.name}
                    tags={props.tags}
                    changeValue={props.changeValue}
                    description={props.description}
                    buttonText={"Update Audience"}
                    checkSwitchMode={false}
                    type={props.type}
                    updateAudience={props.updateAudience}
                  />
                </Col>
              </Row>
            ) : null}
            <br />

            {isAdmin && !isDemo ? (
              <Row style={layoutRowSpacing}>
                <Col span={24}>
                  <PageHeaderLayout
                    rightContent={
                      WRITE && (
                        <React.Fragment>
                          <Button
                            icon={<PlusOutlined />}
                            style={{ marginRight: 10 }}
                            onClick={props.connectGeoTargets}
                            disabled
                          >
                            Add
                          </Button>
                          <Popconfirm
                            placement="topLeft"
                            title={`Are you sure you want to disconnect ${
                              (props.selectedGeoTargets || []).length
                            } selected segment${
                              (props.selectedGeoTargets || []).length > 1
                                ? "s"
                                : ""
                            }?`}
                            onConfirm={props.disconnectGeoTargets}
                            okText="Yes"
                            disabled={!(props.selectedGeoTargets || []).length}
                            cancelText="No"
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              style={{ marginRight: 10 }}
                              disabled={
                                !(props.selectedGeoTargets || []).length
                              }
                            >
                              Disconnect
                            </Button>
                          </Popconfirm>
                        </React.Fragment>
                      )
                    }
                    title={"Segments"}
                  />
                </Col>
                <Col span={24}>
                  <AudienceDetailsSegmentsTable
                    selectedGeoTargets={props.selectedGeoTargets}
                    segmentTargets={processedAudienceData || []}
                    selectTableRow={val =>
                      props.changeValue("selectedGeoTargets", val)
                    }
                  />
                </Col>
              </Row>
            ) : null}
            {isAdmin ? (
              <Row style={layoutRowSpacing}>
                <Demographics
                  data={reportData && reportData.demographic}
                  loading={reportLoading}
                />
              </Row>
            ) : null}
            {isAdmin ? (
              <Row>
                <Observations
                  data={reportData && reportData.observation}
                  loading={reportLoading}
                />
              </Row>
            ) : null}
          </>
        )}
      </PageContentLayout>
    </React.Fragment>
  );
};

export default AudienceDetailsView;
