import gql from "graphql-tag";
const BULK_CREATE_HASHED_EMAIL_ADDRESS = gql`
  mutation bulkCreateHashedEmailAddress(
    $emailListId: ID!
    $emails: [String!]
    $orgID: ID!
  ) {
    bulkCreateHashedEmailAddress(
      data: { emailListId: $emailListId, emails: $emails, orgID: $orgID }
    )
  }
`;

export default BULK_CREATE_HASHED_EMAIL_ADDRESS;
