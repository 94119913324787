import React from "react";
import { isEmpty } from "lodash";
import ChartOptionsButtons from "./ChartOptionsButtons";
import DualAxisArea from "../campaigndetails/tabs/overview/components/tabvisualization/charts/DualAxisArea";
import SingleAxisBarChart from "../campaigndetails/tabs/overview/components/tabvisualization/charts/SingleAxisBarChart";
import SingleAxisLineChart from "../campaigndetails/tabs/overview/components/tabvisualization/charts/SingleAxisLineChart";
import {
  AreaChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

const menudata = [
  {
    chartType: "SingleAxisLineChart",
    axisCount: "single",
    name: "Single Axis Line",
    icon: <LineChartOutlined />,
  },
  {
    chartType: "SingleAxisAreaChart",
    axisCount: "dual",
    name: "Dual Axis Area",
    icon: <AreaChartOutlined />,
  },
  {
    chartType: "SingleAxisBarChart",
    axisCount: "single",
    name: "Single Axis Bar",
    icon: <BarChartOutlined />,
  },
];

class ChartOptions extends React.Component {
  state = {
    yAxisLeft: "clicks",
    yAxisRight: "impressions",
    yAxisLeftDisplay: this.props.aggregateValues
      ? this.props.aggregateValues.clicks
      : 0,
    yAxisRightDisplay: this.props.aggregateValues
      ? this.props.aggregateValues.impressions
      : 0,
    filtered: ["ctr", "cost", "totalCost"],
    data: this.props.data,
    menuKeys: [],
    chartType: "SingleAxisBarChart",
    axisCount: "single",
    height: this.props.height,
  };

  componentDidMount = () => {
    const { data } = this.props;
    let menuKeys = [];
    if (!isEmpty(data)) {
      menuKeys = Object.keys(data[0]).filter(key => {
        return key !== "xAxis";
      });
    }
    const filteredFields = menuKeys.filter(item => {
      return item !== menuKeys[0] && item !== menuKeys[1];
    });

    this.setState({
      menuKeys,
      yAxisLeft: menuKeys[0],
      yAxisRight: menuKeys[1],
      yAxisLeftDisplay: this.props.aggregateValues
        ? this.props.aggregateValues[menuKeys[0]]
        : 0,
      yAxisRightDisplay: this.props.aggregateValues
        ? this.props.aggregateValues[menuKeys[1]]
        : 0,
      filtered: filteredFields,
      height: this.props.height,
    });
  };

  componentDidUpdate(prevProps) {
    const { height } = this.props;
    if (height !== prevProps.height) {
      this.setState({
        height,
      });
    }
  }

  switchChart = () => {
    const { chartType } = this.state;
    if (chartType === "DualAxisArea") {
      return <DualAxisArea state={this.state} />;
    } else if (chartType === "SingleAxisBarChart") {
      return <SingleAxisBarChart state={this.state} />;
    } else if (chartType === "SingleAxisLineChart") {
      return <SingleAxisLineChart state={this.state} />;
    } else return <DualAxisArea state={this.state} />;
  };

  onStateChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onChangeFiltered = (key, value, axis) => {
    const { aggregateValues } = this.props;

    const { filtered } = this.state;
    const filteredFields = filtered.filter(item => {
      return item !== value;
    });
    filteredFields.push(key);
    this.setState({
      [key]: value,
      filtered: filteredFields,
      [axis]: aggregateValues[value],
    });
  };

  render() {
    const { data } = this.state;

    if (isEmpty(data)) {
      return <div />;
    }
    const { menuKeys } = this.state;
    return (
      <div style={{ width: "100%" }}>
        {this.props.isSelected && (
          <ChartOptionsButtons
            parentstate={this.state}
            onChange={this.onChangeFiltered}
            menuKeys={menuKeys}
            onStateChange={this.onStateChange}
            menudata={menudata}
          />
        )}

        {this.switchChart()}
      </div>
    );
  }
}

export default ChartOptions;
