import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import GET_CAMPAIGN_REPORT from "../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";

const ReportLoader = props => {
  const [reportData, setReportsData] = useState({});
  const [reportLoading, setReportLoading] = useState(false);

  const [getReport] = useLazyQuery(GET_CAMPAIGN_REPORT, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const url =
        data &&
        data.getCampaignReportSignedURL &&
        data.getCampaignReportSignedURL.url;
      if (url)
        fetch(url).then(async response => {
          if (response) {
            try {
              await response.json().then(json => {
                setReportsData(json);
                setReportLoading(false);
              });
            } catch (err) {
              console.log(err);
              setReportLoading(false);
            }
          }
        });
    },
  });

  useEffect(() => {
    if (props.audience && !isEmpty(props.audience.reports)) {
      setReportLoading(true);
      getReport({
        variables: { reportId: props.audience.reports[0].id },
      });
    } else setReportsData({});
  }, [props.audience, getReport]);

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        reportData,
        reportLoading,
      })}
    </React.Fragment>
  );
};

export { ReportLoader };
