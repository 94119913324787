import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import { styles } from "../../../../shared/styles/chart";
import { cloneDeep } from "lodash";
import moment from "moment";
import { formatCurrency } from "../../../../../../../core/utils/campaigns";

const MultiSourceChart = ({
  data,
  colors,
  isByDay = false,
  isByMonth = false,
  currencyFormat,
}) => {
  const scale = {
    revenue: {
      type: "linear",
      alias: "revenue",
      formatter: val => {
        return formatCurrency(val, currencyFormat);
      },
    },
    date: {
      type: "time",
      alias: "date",
      formatter: date =>
        isByDay
          ? moment(date)
              .utc()
              .format("MMM DD YYYY")
          : moment(date)
              .utc()
              .format("MMMM YYYY"),
    },
  };

  let singleXAxis = true;
  for (const [idx, d] of data.entries()) {
    const dataLength = data.length - 1;
    if (
      idx !== dataLength &&
      moment(d.date).format("YYYY-MM-DD") !==
        moment(data[idx + 1].date).format("YYYY-MM-DD")
    ) {
      singleXAxis = false;
      break;
    }
  }

  if (singleXAxis) {
    const cloneD = cloneDeep(data);
    const duplicate = cloneD.map(d => {
      const date = moment(d.date)
        .utc()
        .format("YYYY-MM-DD");
      d.date = moment(date, "YYYY-MM-DD")
        .add(1, "minute")
        .format("YYYY-MM-DDThh:mm:ss");
      return d;
    });
    data = data.concat(duplicate);
  }
  return (
    <Chart
      padding="auto"
      data={data}
      style={styles.chart}
      scale={scale}
      forceFit
      height={400}
    >
      <Legend />
      <Tooltip showCrosshairs />
      <Axis name="date" position="bottom" />
      <Axis name="revenue" position="left" />
      <Geom
        type="point"
        shape="circle"
        position="date*revenue"
        color={["location", colors]}
        size={data.length < 400 ? 3 : 1.7}
      />
      <Geom
        type="line"
        position="date*revenue"
        color={["location", colors]}
        size={2}
      />
    </Chart>
  );
};

export default MultiSourceChart;
