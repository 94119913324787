import gql from "graphql-tag";

const polygonFields = gql`
  fragment PolygonFields on Polygon {
    id
    geometry
    location {
      id
      name
      formattedAddress
      zip
      street
      city
      county
      state
      country
      lat
      lng
    }
  }
`;

const circleFields = gql`
  fragment CircleFields on Circle {
    id
    radius
    location {
      id
      name
      formattedAddress
      zip
      street
      city
      county
      state
      country
      lat
      lng
    }
    coordinate {
      id
      lat
      lng
    }
  }
`;

export const LocationFields = gql`
  ${polygonFields}
  ${circleFields}
  fragment LocationFields on Location {
    id
    name
    description
    street
    city
    state
    province
    lat
    lng
    formattedAddress
    email
    locationKey
    phone
    contact
    url
    zip
    country
    county
    createdAt
    audienceType
    org {
      id
      name
    }
    geoframe {
      id
      name
      start
      end
      polygons {
        ...PolygonFields
      }
      circles {
        ...CircleFields
      }
    }
    circles {
      ...CircleFields
    }
    polygons {
      ...PolygonFields
    }
  }
`;
