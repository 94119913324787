import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import { message } from "antd";
import ORG_SEO from "../../../../GraphQl/Queries/ORG_SEO";
import ACQUIRED_LINK_MUTATION from "../../../../GraphQl/Mutations/ACQUIRED_LINK_MUTATION";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";

const SEOLinkMaintenanceContext = createContext();

export const SEOLinkMaintenanceProvider = ({ children }) => {
  const [linkAcquisitions, setLinkAcquisitions] = useState([]);

  const { id } = useParams();

  const { loading, data, refetch } = useQuery(ORG_SEO, {
    variables: { id: id },
    skip: !id,
  });

  const orgName =
    data &&
    data.orgSeoSummary &&
    data.orgSeoSummary.org &&
    data.orgSeoSummary.org.name;

  const [acquiredLinkMutation] = useMutation(ACQUIRED_LINK_MUTATION);

  useEffect(() => {
    if (
      data &&
      data.orgSeoSummary &&
      data.orgSeoSummary.linkAcquisition &&
      data.orgSeoSummary.linkAcquisition.acquiredLinks
    ) {
      const tableData = data.orgSeoSummary.linkAcquisition.acquiredLinks.map(
        link => ({
          key: link.id,
          url: link.sourceURL,
          type: link.contentType.replace(/_/g, " ").toLowerCase(),
          destinationURL: link.destinationURL,
          createdAt: link.createdAt,
        })
      );
      setLinkAcquisitions(tableData);
    } else {
      setLinkAcquisitions([]);
    }
  }, [data]);

  const createAcquiredLink = async (sourceUrl, destinationUrl, contentType) => {
    try {
      message.loading("Creating acquired link...");
      const result = await acquiredLinkMutation({
        variables: {
          data: {
            linkAcquisition: {
              upsert: {
                create: {
                  acquiredLinks: {
                    create: [
                      {
                        contentType: contentType
                          .toUpperCase()
                          .replace(/ /g, "_"),
                        destinationURL: destinationUrl,
                        sourceURL: sourceUrl,
                      },
                    ],
                  },
                },
                update: {
                  acquiredLinks: {
                    create: [
                      {
                        contentType: contentType
                          .toUpperCase()
                          .replace(/ /g, "_"),
                        destinationURL: destinationUrl,
                        sourceURL: sourceUrl,
                      },
                    ],
                  },
                },
              },
            },
          },
          where: {
            id: id,
          },
        },
      });

      if (result.data) {
        const newLinks =
          result.data.updateOrgSeoSummary.linkAcquisition.acquiredLinks;
        const newLinkData = newLinks.map(link => ({
          key: link.id,
          url: link.sourceURL,
          type: link.contentType.replace(/_/g, " ").toLowerCase(),
          destinationURL: link.destinationURL,
          createdAt: link.createdAt,
        }));
        setLinkAcquisitions(newLinkData);
        message.destroy();
        message.success("Link added successfully");
      }
    } catch (error) {
      console.error("Error adding link:", error);
      message.destroy();
      message.error("Failed to add link. Please try again.");
    }
  };

  const updateAcquiredLink = async (
    linkId,
    sourceUrl,
    destinationUrl,
    contentType
  ) => {
    try {
      message.loading("Updating acquired link...");
      const result = await acquiredLinkMutation({
        variables: {
          data: {
            linkAcquisition: {
              update: {
                acquiredLinks: {
                  update: {
                    where: { id: linkId },
                    data: {
                      contentType: contentType.toUpperCase().replace(/ /g, "_"),
                      destinationURL: destinationUrl,
                      sourceURL: sourceUrl,
                    },
                  },
                },
              },
            },
          },
          where: {
            id: id,
          },
        },
      });

      if (result.data) {
        await refetch();
        message.destroy();
        message.success("Link updated successfully");
      }
    } catch (error) {
      console.error("Error updating link:", error);
      message.destroy();
      message.error("Failed to update link. Please try again.");
    }
  };

  const deleteAcquiredLink = async linkId => {
    try {
      message.loading("Deleting acquired link...");
      const result = await acquiredLinkMutation({
        variables: {
          data: {
            linkAcquisition: {
              update: {
                acquiredLinks: {
                  delete: [
                    {
                      id: linkId,
                    },
                  ],
                },
              },
            },
          },
          where: {
            id: id,
          },
        },
      });

      if (result.data) {
        await refetch();
        message.destroy();
        message.success("Link deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      message.destroy();
      message.error("Failed to delete link. Please try again.");
    }
  };

  if (loading) {
    return <OverlaySpinner />;
  }

  return (
    <SEOLinkMaintenanceContext.Provider
      value={{
        id,
        orgName,
        linkAcquisitions,
        createAcquiredLink,
        updateAcquiredLink,
        deleteAcquiredLink,
      }}
    >
      {children}
    </SEOLinkMaintenanceContext.Provider>
  );
};

export const useSEOLinkMaintenanceContext = () => {
  const context = useContext(SEOLinkMaintenanceContext);
  if (!context) {
    throw new Error(
      "useSEOLinkMaintenanceContext must be used within a SEOLinkMaintenanceProvider"
    );
  }
  return context;
};
