import gql from "graphql-tag";
const CREATE_AUDIENCE_DEMOGRAPH = gql`
  mutation createAudienceDemograph($data: AudienceDemographCreateInput!) {
    createAudienceDemograph(data: $data) {
      id
      name
    }
  }
`;

export default CREATE_AUDIENCE_DEMOGRAPH;
