import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card, Tabs } from "antd";
import {
  AppstoreAddOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import TableSearch from "../../OrgConfig/components/FiltersTab";
import CreativeTab from "../../OrgConfig/components/CreativeTab";
import CreativeTable from "../../OrgConfig/components/CreativeTable";
import CreativeAdGroupTable from "../../OrgConfig/components/CreativeAdGroupTable";
import VideoTab from "../../OrgConfig/components/VideoTab";
import VideoTable from "../../OrgConfig/components/VideoTable";
import AdVideosGroupTable from "../../OrgConfig/components/AdVideosGroupTable";
import { determineUserAccess } from "../../../../core/permissions/userLevelAccess";

const { TabPane } = Tabs;
const CreativesMainView = ({
  onChangeStateValue,
  createOrgMedia,
  creativeName,
  onDeleteMedias,
  onLoadingDeleteMedias,
  onCreateAdgroup,
  orgMedias,
  bulkCreateOrgMedia,
  refetchMedias,
  updateOrgMedia,
  setDefaultAmplifyCreatives,
  adGroupCreatives,
  onDeleteAdgroup,
  onLoadingDeleteAdGroup,
  currentuser,
  selectedOrg,
  updateAdGroup,
  refetchVideoMedias,
  orgVideoMedias,
}) => {
  //Title shown on browser tab
  document.title = "Creatives Main View";
  const [searchText, setSearchText] = useState("");
  const [ids, setIds] = useState([]);
  const [renewedCreatives, setRenewedCreatives] = useState([]);
  const [creativeKeys, setCreativeKeys] = useState([]);
  const [videoCreativeIds, setVideoCreativeIds] = useState([]);
  const [videoCreativeKeys, setVideoCreativeKeys] = useState([]);

  const { permission } = currentuser;

  const userPermission = determineUserAccess(permission);

  const { amplify } = userPermission;
  const handleSearch = searchText => {
    setSearchText(searchText);
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<AppstoreAddOutlined />}
        title={"Creatives"}
      />
      <PageContentLayout>
        <Card>
          <Tabs type="card" defaultActiveKey="a">
            <TabPane
              tab={
                <span>
                  <FileImageOutlined />
                  Creatives
                </span>
              }
              key="a"
            >
              <TableSearch searchText={searchText} onSearch={handleSearch} />
              <CreativeTab
                onChangeStateValue={onChangeStateValue}
                createOrgMedia={createOrgMedia}
                creativeName={creativeName}
                onDeleteMedias={onDeleteMedias}
                onLoadingDeleteMedias={onLoadingDeleteMedias}
                ids={ids}
                onCreateAdgroup={onCreateAdgroup}
                setIds={setIds}
                setRenewedCreatives={setRenewedCreatives}
                orgMedias={orgMedias}
                creativeKeys={creativeKeys}
                bulkCreateOrgMedia={bulkCreateOrgMedia}
                refetchMedias={refetchMedias}
                renewedCreatives={renewedCreatives}
              />
              <CreativeTable
                orgMedias={renewedCreatives}
                setIds={setIds}
                setCreativeKeys={setCreativeKeys}
                updateOrgMedia={updateOrgMedia}
                searchText={searchText}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FileImageOutlined />
                  Ad Groups
                </span>
              }
              key="b"
            >
              <CreativeAdGroupTable
                setDefaultAmplifyCreatives={setDefaultAmplifyCreatives}
                adGroupCreatives={adGroupCreatives}
                onDeleteAdgroup={onDeleteAdgroup}
                onLoadingDeleteAdGroup={onLoadingDeleteAdGroup}
                amplify={amplify}
                selectedOrg={selectedOrg}
                updateAdGroup={updateAdGroup}
                orgMedias={renewedCreatives}
                setIds={setIds}
                setCreativeKeys={setCreativeKeys}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <VideoCameraOutlined />
                  Videos
                </span>
              }
              key="videoTab"
            >
              <VideoTab
                createOrgMedia={createOrgMedia}
                videoCreativeIds={videoCreativeIds}
                videoCreativeKeys={videoCreativeKeys}
                setVideoCreativeIds={setVideoCreativeIds}
                setVideoCreativeKeys={setVideoCreativeKeys}
                onChangeStateValue={onChangeStateValue}
                onCreateAdgroup={onCreateAdgroup}
                onDeleteMedias={onDeleteMedias}
                refetchMedias={refetchMedias}
                refetchVideoMedias={refetchVideoMedias}
              />
              <VideoTable
                orgMedias={orgVideoMedias}
                setVideoCreativeIds={setVideoCreativeIds}
                setVideoCreativeKeys={setVideoCreativeKeys}
                updateOrgMedia={updateOrgMedia}
                refetchVideoMedias={refetchVideoMedias}
              ></VideoTable>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <VideoCameraOutlined />
                  Ad Video Groups
                </span>
              }
              key="adVideoTab"
            >
              <AdVideosGroupTable
                amplify={amplify}
                selectedOrg={selectedOrg}
                adVideosGroups={adGroupCreatives}
                onDeleteAdgroup={onDeleteAdgroup}
              />
            </TabPane>
          </Tabs>
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default CreativesMainView;
