import React from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../../../../core/components/articles/Loader";
import Articles from "../containers/Articles";

const BlogRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route path={`${path}`} component={Loader} />
        <Route path={`${path}/category/:categoryId`} component={Articles} />
        <Route
          path={`${path}/category/:categoryId/article/:articleId`}
          component={Articles}
        />
      </Switch>
    </div>
  );
};

export default BlogRoutes;
