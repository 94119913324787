import gql from "graphql-tag";

const GET_TRANSACTION_IMPRESSIONS = gql`
  query getTransactionImpressions(
    $campaignOrderId: String!
    $reportName: String!
    $transactionId: String!
  ) {
    getTransactionImpressions(
      campaignOrderId: $campaignOrderId
      reportName: $reportName
      transactionId: $transactionId
    )
  }
`;

export default GET_TRANSACTION_IMPRESSIONS;
