import gql from "graphql-tag";
const GET_TOTAL_PAID_SEARCH_CAMPAIGNS = gql`
  query paidSearchCampaignsCount($where: PaidSearchCampaignWhereInput!) {
    paidSearchCampaignsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_TOTAL_PAID_SEARCH_CAMPAIGNS;
