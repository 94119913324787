import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import {
  Row,
  Col,
  Card,
  Input,
  Upload,
  Tooltip,
  Popconfirm,
  Modal,
  Form,
  Typography,
  message,
} from "antd";
import Button from "../../../shared/globalStyling/Button";
import { getUploadedFileDimensions } from "../../../../core/utils/dataManipulating/images";
import sizeValidation from "../../../shared/upload/sizeValidation";
import dimensionValidation from "../../../shared/upload/dimensionValidation";
import { multiImageZipKey } from "../../../../core/utils/dataloading/multiImageZipKey";
import { getImageType } from "../../../../core/utils/general/strings";
import BulkUploadResultsModal from "./creatives/BulkUploadResultModal";
import { invalidImageDimensionErrorMessage } from "../../../../core/utils/constants/constants";

import { isEmpty } from "lodash";
import {
  LoadingOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  DownloadOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";

const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "video/mp4",
];

const CreativeTab = ({
  onChangeStateValue,
  createOrgMedia,
  creativeName,
  onDeleteMedias,
  onLoadingDeleteMedias,
  ids,
  setIds,
  onCreateAdgroup,
  orgMedias,
  setRenewedCreatives,
  creativeKeys,
  bulkCreateOrgMedia,
  refetchMedias,
  renewedCreatives,
}) => {
  /* eslint-disable */
  useEffect(() => {
    const getNewOrgMedias =
      orgMedias &&
      orgMedias.map(val => {
        const urlOrgMedia = Storage.get(val.key, {
          expires: 600000,
        });

        urlOrgMedia.then(response => {
          return (val.url = response);
        });
        return val;
      });

    setRenewedCreatives(getNewOrgMedias);
  }, [orgMedias]);
  /* eslint-enable */

  const [
    showUploadResultsTableModal,
    setShowUploadResultsTableModal,
  ] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const [adGroupModal, setAdGroupModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  const [image, setImage] = useState();
  const [imageSize, setImageSize] = useState();
  const [imageResponse, setImageResponse] = useState();
  const [imageUrl, setImageUrl] = useState();

  const [form] = Form.useForm();
  const [formAdGroup] = Form.useForm();

  const [fileListBulkUpload] = useState([]);

  const [uploadResult, setUploadResults] = useState([]);

  const { Title } = Typography;

  const handleSubmit = async () => {
    setUploadModal(false);
    const { type, size } = image;
    const { width: imageWidth, height: imageHeight } = imageSize;
    const { key } = imageResponse;

    const url = imageUrl;

    const CapitalizeCreativeName =
      creativeName.charAt(0).toUpperCase() + creativeName.slice(1);

    if (dimensionValidation(imageWidth, imageHeight) === false) {
      setUploadModal(false);
      setUploadLoading(false);
      setImageUrl();
      onChangeStateValue("creativeName", "");
      return false;
    } else {
      await createOrgMedia({
        variables: {
          mediatype: "STILL",
          key,
          url,
          width: imageWidth,
          height: imageHeight,
          name: CapitalizeCreativeName,
          size,
          fileType: getImageType(type),
        },
      });

      await refetchMedias();

      message.success(`Successfully Uploaded Creative`);
    }

    setImageUrl();
    form.resetFields();
    onChangeStateValue("creativeName", "");
    // Put S3 image into MySQL DB
  };
  const results = [];
  const invalidFiles = [];
  const uploaderProps = {
    name: "file",
    action: null,
    listType: "picture-card",
    beforeUpload: async file => {
      setUploadLoading(true);
      // Put uploaded image into assets S3 bucket
      const { name, size } = file;
      setImage(file);

      if (sizeValidation(size) === false) {
        setUploadLoading(false);
        return false;
      }

      const fname = `${Date.now()}-${name}`;
      const response = await Storage.put(fname, file, {
        contentType: "image/*",
      });
      const url = await Storage.get(response.key, { expires: 600000 });

      setImageResponse(response);
      onChangeStateValue("creativeName", name.replace(/\.[^/.]+$/, ""));
      setImageUrl(url);
      setImageSize(await getUploadedFileDimensions(file));
      setUploadLoading(false);

      return false;
    },
    showUploadList: false,
    accept: ".png, .jpeg, .jpg, .mp4, .gif, .avif",
    customRequest: async () => {
      setTimeout(() => {
        return true;
      }, 0);
    },
  };

  const bulkUploaderProps = {
    name: "file",
    action: null,
    listType: "picture-card",
    fileList: fileListBulkUpload,
    multiple: true,
    accept: ".png, .jpeg, .jpg, .mp4, .gif, .avif",
    beforeUpload: async (file, fileList) => {
      try {
        message.destroy();

        setUploadComplete(false);
        setUploadResults([]);
        setBulkUploadModal(false);
        setUploadLoading(true);
        setShowUploadResultsTableModal(true);
        // Put uploaded image into assets S3 bucket
        const { name, size, type } = file;

        const {
          width: imageWidth,
          height: imageHeight,
        } = await getUploadedFileDimensions(file);

        const dimensionsResults = dimensionValidation(
          imageWidth,
          imageHeight,
          true
        );
        const sizeResults = sizeValidation(size);

        let result = {
          name: name,
          dimensions: `${imageWidth}x${imageHeight}`,
          fileSize: size,
          status: false,
        };

        if (!dimensionsResults || !sizeResults) {
          result.reason = invalidImageDimensionErrorMessage;
          result.sizeResult =
            sizeResults === false
              ? "Image must be smaller than 2MB!"
              : sizeResults;
          result.status = false;
        }

        if (allowedImageTypes.includes(type)) {
          const fname = `${Date.now()}-${name}`;
          const response = await Storage.put(fname, file, {
            contentType: "image/*",
          });
          const url = await Storage.get(response.key, { expires: 600000 });

          // Get image width and height
          // TODO Refactor to work smoothly with existing logic
          // We can merge the invalid files identified here and the one above
          // For now just to show working concept, we will keep it as is.
          if (
            dimensionValidation(imageWidth, imageHeight, true) === false ||
            sizeValidation(size) === false
          ) {
            invalidFiles.push(file.uid);
          } else {
            const { key } = response;
            results.push({
              type: "STILL",
              key,
              url,
              width: imageWidth,
              height: imageHeight,
              name: name.replace(/\.[^/.]+$/, ""),
              size,
              fileType: getImageType(type),
            });
            result.status = true;
          }
        } else {
          invalidFiles.push(file.uid);
        }

        const validFiles = fileList.filter(
          fList => !invalidFiles.includes(fList.uid)
        );

        if (results.length === validFiles.length) {
          message.destroy();
          await bulkCreateOrgMedia(results, invalidFiles, true);
          await refetchMedias();
        }

        setUploadResults(prevResults => [...prevResults, result]);
      } catch (error) {
        console.log(error);
      }
    },
    customRequest: async () => {
      // stop spinner after upload
      setUploadLoading(false);
      setUploadComplete(true);
    },
    showUploadList:
      fileListBulkUpload.length !== 0
        ? {
            showPreviewIcon: false,
            showRemoveIcon: false,
          }
        : false,
  };

  const handleCreateAdGroup = async () => {
    formAdGroup.resetFields();
    setAdGroupModal(false);

    const remakeIds = ids.map(idVal => {
      const getId = idVal;

      return { id: getId };
    });

    onChangeStateValue("adGroupMedias", remakeIds);
    onCreateAdgroup();
  };

  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleDeleteMedias = async () => {
    try {
      await onDeleteMedias(ids);
      await setRenewedCreatives(
        orgMedias.filter(item => !ids.includes(item.id))
      );
      await setIds([]);
    } catch (err) {
      message.error(`Error deleting media creative(s): ${err}`);
    }
  };

  return (
    <>
      <BulkUploadResultsModal
        visible={showUploadResultsTableModal}
        uploadResults={uploadResult}
        uploadInProgress={uploadLoading}
        uploadComplete={uploadComplete}
        onCancel={() => {
          setShowUploadResultsTableModal(false);
        }}
      />
      <Modal
        title="Create Media"
        visible={uploadModal}
        centered
        footer={null}
        onCancel={() => {
          setUploadModal(false);
          setImageUrl();
          form.resetFields();
          onChangeStateValue("creativeName", "");
        }}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            name: creativeName,
          }}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item
                  label=""
                  name="banner"
                  rules={[{ required: true }]}
                  valuePropName="file"
                >
                  <Upload {...uploaderProps}>
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" rules={[{ required: true }]}>
                <Input
                  placeholder="Name of Creative"
                  value={creativeName}
                  defaultValue={creativeName}
                  onChange={e => {
                    const CapitalizeCreativeName =
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1);
                    onChangeStateValue("creativeName", CapitalizeCreativeName);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Upload Creative(s)"
        visible={bulkUploadModal}
        centered
        footer={null}
        onCancel={() => {
          setBulkUploadModal(false);
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Upload {...bulkUploaderProps}>{uploadButton}</Upload>
        </div>
      </Modal>
      <Modal
        title={`Create Ad Group - ${
          ids && ids.length ? ids.length : 0
        } Creative(s) Selected`}
        visible={adGroupModal}
        centered
        footer={null}
        onCancel={() => {
          setAdGroupModal(false);
          formAdGroup.resetFields();
        }}
      >
        <Form
          layout="vertical"
          onFinish={handleCreateAdGroup}
          form={formAdGroup}
        >
          <Row type="flex" justify="space-around">
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input
                  placeholder="Name of Ad Group"
                  name="name"
                  value="replace this"
                  onChange={e => {
                    onChangeStateValue("adGroupName", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button htmlType="submit" type="primary">
                  Create
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row>
        <Col xs={24} lg={24}>
          <Card bodyStyle={{ padding: 5 }}>
            <Row align="middle" justify="space-between">
              <Col
                className="ant-col-align-self-center"
                xs={24}
                xl={4}
                style={{ paddingLeft: 15, paddingTop: 10 }}
              >
                <Title level={5}>
                  Creatives{" "}
                  {!isEmpty(creativeKeys)
                    ? `Selected (${creativeKeys && creativeKeys.length})`
                    : `Selected (0)`}
                </Title>
              </Col>
              <Col xs={24} xl={20}>
                <Row
                  align="right"
                  className="ant-row-flex-column ant-row-justify-center ant-row-justify-end"
                >
                  <Popconfirm
                    placement="topLeft"
                    title={`Are you sure you want delete the selected creatives?`}
                    onConfirm={() => handleDeleteMedias()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="bottom"
                      title={`Delete selected creatives`}
                    >
                      <Button
                        type="primary"
                        disabled={ids && ids.length > 0 ? false : true}
                        icon={
                          onLoadingDeleteMedias === true ? (
                            <LoadingOutlined />
                          ) : (
                            <DeleteOutlined />
                          )
                        }
                        // style={{ marginLeft: "auto" }}
                        ghost
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                  {/* <Button
                    type="primary"
                    icon={
                      uploadLoadingSubmit ? (
                        <LoadingOutlined />
                      ) : (
                        <UploadOutlined />
                      )
                    }
                    onClick={() => setUploadModal(true)}
                  >
                    Upload Creative
                  </Button> */}
                  <Button
                    type="primary"
                    icon={<CloudUploadOutlined />}
                    onClick={() => setBulkUploadModal(true)}
                  >
                    Upload Creative(s)
                  </Button>
                  <Button
                    type="primary"
                    icon={<FolderAddOutlined />}
                    disabled={ids && ids.length > 0 ? false : true}
                    onClick={() => setAdGroupModal(true)}
                  >
                    Create Ad Group
                  </Button>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    disabled={!creativeKeys || creativeKeys.length === 0}
                    onClick={() =>
                      multiImageZipKey(
                        "Creatives (" + creativeKeys.length + " images)",
                        creativeKeys
                      )
                    }
                  >
                    Download Creatives
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreativeTab;
