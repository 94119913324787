import React, { useState, useEffect, useCallback } from "react";
import { printPDF } from "../../../utils/reporting/index";
import { cloneDeep } from "lodash";
import { notification } from "antd";
import uuid from "uuid";
import { CheckCircleTwoTone } from "@ant-design/icons";
import GET_MESSAGE from "../../../GraphQl/Queries/GET_MESSAGE";
import GET_CONVERSATION from "../../../GraphQl/Queries/GET_CONVERSATION";
import moment from "moment";

const CampaignDetailsStateController = props => {
  const [message, setMessage] = useState("");
  const [, setContext] = useState("");
  const [, setUserID] = useState("");
  const [, setPersonality] = useState("0");
  const [currentOrgID] = useState(props.getOrgName);
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const [exportButtonDisable, setExportButtonDisable] = useState(true);
  const [exportButtonMenuItems, setExportButtonMenuItems] = useState([]);
  const { upsertReprocessStatus, triggerDag } = props;

  const dataConfig = props.dataConfig;

  const filterDataForProvider = useCallback(() => {
    let newData = props.campaignOrder.campaigns.find(
      campaign => campaign.demandSidePlatform === props.selectedProvider
    );
    return newData || props.campaignOrder;
  }, [props.selectedProvider, props.campaignOrder]);

  const [data, setData] = useState(
    cloneDeep(filterDataForProvider(props.campaignOrder))
  );

  useEffect(() => {
    setData(filterDataForProvider());
  }, [filterDataForProvider, props.campaignOrder, props.selectedProvider]);

  const [modalOpen, setModalOpen] = useState(0);

  const [providerIndex, setProviderIndex] = useState("All");

  const updateUrlWithProvider = provider => {
    let searchParams = new URLSearchParams(props.location.search);
    searchParams.delete("provider");
    searchParams.delete("tab");
    if (provider !== "All") searchParams.append("provider", provider);
    props.history.push(`${props.location.pathname}?${searchParams.toString()}`);
  };

  const toggleModal = type => {
    setModalOpen(type);
  };

  const generateReport = async name => {
    try {
      const print = await printPDF("printable-page", name);
      if (print) return true;
    } catch (e) {
      return e;
    }
  };

  const onDeleteCampaignOrder = async campaignOrderId => {
    await props.deleteCampaignOrder({
      variables: {
        campaignOrderId: props.campaignOrder.id,
      },
    });

    await sessionStorage.setItem("refreshTable", true);
    await props.history.push("/campaigns/main/1?status=draft");
    //window.location.reload();
  };

  const createMessageRemarks = async messageStatus => {
    try {
      const getListMessage = props.getListMessage;
      const selectedCampaignOrderId = data.id;
      const getCurrentConversationId = props.getCurrentConversationId;

      if (getListMessage.length === 0) {
        props.setLoadingCreateMessage(true);

        const createdMessage = data.orgs[0].roles.map(obj => {
          // if (obj.users.length === 1) {
          // Use org users first object user ID if it's available then if not, use the current user ID
          const getId =
            (obj.users.length && obj.users[0].id && obj.users[0].id) ||
            props.currentUser.id;
          props.createMessage({
            variables: {
              personality: messageStatus,
              context: selectedCampaignOrderId,
              message,
              currentOrgID: currentOrgID,
              userID: getId,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_MESSAGE,
                variables: {
                  getCampaignOrderID: selectedCampaignOrderId,
                },
              },
            ],
          });
          // }

          return true;
        });

        if (createdMessage) {
          notification.open({
            message: "Created a Remark!",
            description: "Successfully created a remark!",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            duration: 15,
          });

          setMessage("");
          setContext("");
          setUserID("");
          setPersonality("0");

          return true;
        }
      } else {
        props.setLoadingCreateMessage(true);

        const updateConversation = props.updateConversation({
          variables: {
            message,
            conversationID: getCurrentConversationId,
            currentOrgID: currentOrgID,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_CONVERSATION,
              variables: {
                getConversationId: getCurrentConversationId,
              },
            },
          ],
        });

        if (updateConversation) {
          notification.open({
            message: "Message Remark Added",
            description: "Successfully!",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
            duration: 15,
          });

          const selectedCampaignOrderName = data.name;

          props.createNotification({
            variables: {
              userID: data.requestorId,
              message:
                'New Message "' +
                message +
                '" on Campaign: ' +
                selectedCampaignOrderName,
            },
          });

          props.createNotification({
            variables: {
              userID: data.reviewerId,
              message:
                'New Message "' +
                message +
                '" on Campaign: ' +
                selectedCampaignOrderName,
            },
          });

          setMessage("");
          setContext("");
          setUserID("");
          setPersonality("0");

          return true;
        }
      }

      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const closeEditCampaign = async campaignID => {
    await props.updateCampaignOrderPending({
      variables: {
        campaignID: campaignID,
        isEditCampaign: false,
      },
    });

    props.updateCampaignOrderRequest({
      variables: {
        campaignID: campaignID,
        isRequestChange: "Campaign Changed for Review",
      },
    });

    notification.open({
      message: "Submitted Changes for requestor review",
      description: "Success!",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      duration: 15,
    });
  };

  const reprocessCurrentCampaign = async campaignId => {
    const generateId = uuid.v4();
    const statusKey = `display-campaign-${campaignId}`;

    await triggerDag({
      variables: {
        where: {
          dag_id: "reprocess-campaign",
          body: {
            dag_run_id: generateId,
            conf: {
              id: `id=${campaignId}`,
            },
          },
        },
      },
    });

    //Update Reprocess Status of a campaign
    //Each campaign needs to have a unique key to track the status
    //If no key is found, it will create a new status if found it will update the status
    await upsertReprocessStatus({
      variables: {
        key: statusKey,
        dateQueued: moment().format(),
        module: `display`,
      },
    });
  };

  const scrapeDSPCampaigns = async () => {
    const generateId = uuid.v4();
    await triggerDag({
      variables: {
        where: {
          dag_id: "scrape-dsp-campaigns",
          body: {
            dag_run_id: generateId,
            conf: {
              args: `id=${props.campaignOrder.id}`,
            },
          },
        },
      },
    });
  };

  return React.cloneElement(props.children, {
    campaignOrder: props.campaignOrder,
    modalOpen,
    generateReport,
    toggleModal,
    updateUrlWithProvider,
    onDeleteCampaignOrder,
    createMessageRemarks,
    closeEditCampaign,
    message,
    setMessage,
    data,
    providerIndex,
    setProviderIndex,
    reprocessCurrentCampaign,
    exportButtonLoading,
    setExportButtonLoading,
    exportButtonDisable,
    setExportButtonDisable,
    exportButtonMenuItems,
    setExportButtonMenuItems,
    dataConfig,
    pageViewReportsKey:
      props.pageViewReports.reports &&
      props.pageViewReports.reports[0] &&
      props.pageViewReports.reports[0].key,
    scrapeDSPCampaigns,
    ...props,
  });
};

export default CampaignDetailsStateController;
