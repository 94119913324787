import moment from "moment";

export const toByDay = byDate => {
  const initialValue = {
    clicks: 0,
    cost: 0,
    cpc: 0,
    cpm: 0,
    ctr: 0,
    ecpm: 0,
    impressions: 0,
    observations: 0,
    totalCost: 0,
    totalImpressions: 0,
    transactionCount: 0,
    transactionTotal: 0,
    walkIns: 0,
  };

  let arrMap = {
    0: { ...initialValue, xAxis: "Sunday" },
    1: { ...initialValue, xAxis: "Monday" },
    2: { ...initialValue, xAxis: "Tuesday" },
    3: { ...initialValue, xAxis: "Wednesday" },
    4: { ...initialValue, xAxis: "Thursday" },
    5: { ...initialValue, xAxis: "Friday" },
    6: { ...initialValue, xAxis: "Saturday" },
  };

  byDate.forEach(date => {
    const day = moment(date.xAxis).day();
    const prev = arrMap[day];
    arrMap[day] = {
      ...prev,
      clicks: prev.clicks + date.clicks || 0,
      cost: prev.cost + date.cost || 0,
      cpc: prev.cpc + date.cpc || 0,
      cpm: prev.cpm + date.cpm || 0,
      ctr: prev.ctr + date.ctr || 0,
      ecpm: prev.ecpm + date.ecpm || 0,
      impressions: prev.impressions + date.impressions || 0,
      observations: prev.observations + date.observations || 0,
      totalCost: prev.totalCost + date.totalCost || 0,
      totalImpressions: prev.totalImpressions + date.totalImpressions || 0,
      transactionCount: prev.transactionCount + date.transactionCount || 0,
      transactionTotal: prev.transactionTotal + date.transactionTotal || 0,
      walkIns: prev.walkIns + date.walkIns || 0,
    };
  });

  return Object.values(arrMap);
};

export const byDateOrgCompute = arr => {
  let byDateMap = {};
  arr.forEach(date => {
    const momentDate = moment(date.xAxis);
    const formatedDate = momentDate.format("YYYY-MM-DD");
    if (byDateMap[formatedDate]) {
      const previous = byDateMap[formatedDate];
      byDateMap[formatedDate] = {
        ...previous,
        xAxis: previous.xAxis,
        date: formatedDate,
        cost: previous.cost + date.cost,
        clicks: previous.clicks + date.clicks,
        impressions: previous.impressions + date.impressions,
        totalCost: previous.totalCost + date.totalCost,
        ctr: previous.ctr + date.ctr,
        cpc: previous.cpc + date.cpc,
        cpm: previous.cpm + date.cpm,
        ecpm: previous.ecpm + date.ecpm,
        transactionTotal:
          previous.transactionTotal + (date.transactionTotal || 0),
        totalImpressions:
          previous.totalImpressions + (date.totalImpressions || 0),
        transactionCount:
          previous.transactionCount + (date.transactionCount || 0),
        walkIns: previous.walkIns + (date.walkIns || 0),
        observations: previous.observations + (date.observations || 0),
      };
    } else {
      byDateMap[formatedDate] = {
        ...date,
        xAxis: formatedDate,
        date: formatedDate,
        timestamp: momentDate.unix(),
        transactionTotal: date.transactionTotal || 0,
        totalImpressions: date.totalImpressions || 0,
        transactionCount: date.transactionCount || 0,
        clicks: date.clicks || 0,
        cost: date.cost || 0,
        cpm: date.cpm || 0,
        cpc: date.cpc || 0,
        ctr: date.ctr || 0,
        ecpm: date.ecpm || 0,
        totalCost: date.totalCost || 0,
        impressions: date.impressions || 0,
        walkIns: date.walkIns || 0,
        observations: date.observations || 0,
      };
    }
  });
  return Object.values(byDateMap);
};

export const cleanFormatByDateReport = arr => {
  return arr.map(value => {
    const xAxis = !isNaN(value.xAxis)
      ? moment.unix(value.xAxis).format("YYYY-MM-DD")
      : moment(value.xAxis).format("YYYY-MM-DD");

    const date =
      value.xAxis.length === 7
        ? moment(value.xAxis * 1000).unix()
        : moment(value.xAxis).unix();

    return {
      ...value,
      xAxis: xAxis,
      date: date, // always retain this to use for processing
      transactionTotal: value.transactionTotal || 0,
      totalImpressions: value.totalImpressions || 0,
      transactionCount: value.transactionCount || 0,
      clicks: value.clicks || 0,
      cost: value.cost || 0,
      cpm: value.cpm || 0,
      cpc: value.cpc || 0,
      ctr: value.ctr || 0,
      ecpm: value.ecpm || 0,
      totalCost: value.totalCost || 0,
      impressions: value.impressions || 0,
      walkIns: value.walkIns || 0,
      observations: value.observations || 0,
    };
  });
};

export const filterByDate = (reportData, startDate, endDate) => {
  let filtered = reportData.filter(obj => {
    const objDate = obj.date;
    return objDate >= startDate && objDate <= endDate;
  });
  //sort
  return filtered.sort((a, b) => a.date >= b.date);
};

export const filterbyCampaignOrder = (arr, COIds) => {
  if (COIds) {
    const campaignFiltered = arr.filter(b => {
      return COIds.includes(b.campaignOrderId);
    });
    return campaignFiltered;
  }
  return arr;
};
