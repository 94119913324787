import gql from "graphql-tag";

const CREATE_GEOTARGET = gql`
  mutation createGeoTarget(
    $name: String
    $description: String!
    $start: DateTime
    $end: DateTime
    $tags: [String!]
    $circles: [CircleCreateInput!]
    $polygons: [PolygonCreateInput!]
    $orgid: ID
    $isBase: Boolean!
    $location: LocationCreateWithoutGeoframeInput
  ) {
    createGeoTarget(
      data: {
        name: $name
        description: $description
        start: $start
        end: $end
        isBase: $isBase
        circles: { create: $circles }
        polygons: { create: $polygons }
        orgs: { connect: { id: $orgid } }
        tags: { set: $tags }
        location: { create: $location }
      }
    ) {
      id
      name
      description
      start
      end
      isBase
      observations
      orgs {
        id
      }
      vwiUpdated
      vwiUpdatedOnSpot
      tags
      circles {
        id
        coordinate {
          lat
          lng
        }
        radius
        location {
          name
          formattedAddress
          zip
          street
          city
          county
          state
          country
          lat
          lng
        }
      }
      polygons {
        id
        geometry
      }
      location {
        name
        formattedAddress
        zip
        street
        city
        county
        state
        country
        lat
        lng
      }
    }
  }
`;

export default CREATE_GEOTARGET;
