import gql from "graphql-tag";

const GET_ORG_SUMMARY = gql`
  query org($loggedInOrg: ID, $orgName: String) {
    org(where: { id: $loggedInOrg, name: $orgName }) {
      name
      id
      level
      orgSummary {
        id
        impressions
        mediaSpendCTR
        totalCampaigns
        activeCampaigns
        liveCampaigns
        inAttributionCampaigns
        mediaSpendBudgetSpent
        mediaSpendImpressionsServed
        mediaSpendClicks
        budget
        goal
        roas
        totalWalkIns
        totalOrders
        totalObservations
        totalRevenue
        attributionWindowReport
        createdAt
        updatedAt
        reports(
          orderBy: createdAt_DESC
          first: 1
          where: { type: ATTRIBUTION }
        ) {
          key
          type
          id
          createdAt
        }
      }
    }
  }
`;

export default GET_ORG_SUMMARY;
