import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PaidSearchMainView from "../PaidSearchMainView";
import PaidSearchDetailsView from "../paidsearchdetails/PaidSearchDetailsView";
import PaidSearchCampaignView from "../paidsearchcampaign/PaidSearchCampaignView";

import PaidSearchMainLoader from "../../../../core/components/paidSearch/paidsearchmain/PaidSearchMainLoader";
import PaidSearchDetailsLoader from "../../../../core/components/paidSearch/paidsearchdetails/PaidSearchDetailsLoader";
import PaidSearchCampaignLoader from "../../../../core/components/paidSearch/paidsearchcampaign/PaidSearchCampaignLoader";
import PaidSearchCampaignBingLoader from "../../../../core/components/paidSearch/paidsearchcampaign/PaidSearchCampaignBingLoader";

// Feature level subroutes
const PaidSearchRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          path={`${path}/main/:pageNumber?`}
          exact
          render={() => (
            <PaidSearchMainLoader>
              <PaidSearchMainView />
            </PaidSearchMainLoader>
          )}
        />
        <Route
          exact
          path={`${path}/details/:id?/:pageNumber?`}
          render={() => (
            <PaidSearchDetailsLoader>
              <PaidSearchDetailsView />
            </PaidSearchDetailsLoader>
          )}
        />
        <Route
          exact
          path={`${path}/campaign/:id?/:campaignId?`}
          render={() => (
            <PaidSearchCampaignLoader>
              <PaidSearchCampaignView />
            </PaidSearchCampaignLoader>
          )}
        />
        <Route
          exact
          path={`${path}/campaign/:id?/:campaignId?/bing`}
          render={() => (
            <PaidSearchCampaignBingLoader>
              <PaidSearchCampaignView />
            </PaidSearchCampaignBingLoader>
          )}
        />
        {/* Fallback */}
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}/main/1`} />}
        />
      </Switch>
    </div>
  );
};

export default PaidSearchRoutes;
