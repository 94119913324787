import React from "react";
import SettingLoader from "../../../../../core/components/orgConfig/SettingsTab/SettingLoader";
import SettingController from "../../../../../core/components/orgConfig/SettingsTab/SettingController";
import SettingTab from "./SettingTab";

const SettingDetailsView = props => {
  return (
    <SettingLoader {...props}>
      <SettingController>
        <SettingTab />
      </SettingController>
    </SettingLoader>
  );
};

export default SettingDetailsView;
