export const buildGeoTargetPayload = ({ target, orgId }) => {
  const { circles, polygons, location } = target;

  if (location) {
    delete location.id;
    delete location.__typename;
  }

  return {
    circles: {
      create: circles.length
        ? circles.map(circle => {
            const circlePayload = {
              radius: circle.radius,
              coordinate: {
                create: {
                  lat: circle.coordinate.lat,
                  lng: circle.coordinate.lng,
                },
              },
            };

            if (circle.location) {
              circlePayload.location = {
                create: {
                  ...circle.location,
                },
              };
            }
            return circlePayload;
          })
        : [],
    },
    polygons: {
      create: polygons.length
        ? polygons.map(polygon => {
            const polygonPayload = {
              geometry: {
                coordinates: polygon.geometry.coordinates,
              },
            };

            if (polygon.location) {
              polygonPayload.location = {
                create: {
                  ...polygon.location,
                },
              };
            }

            return polygonPayload;
          })
        : [],
    },
    location: {
      create: {
        ...location,
      },
    },
    name: target.name || "",
    start: target.start || null,
    end: target.end || null,
    // orgs: {
    //   connect: {
    //     id: orgId,
    //   },
    // },
    status: "CREATED",
  };
};

export const buildGeoTargetsPayload = ({
  targets = [],
  originalTargets = [],
  orgId,
}) => {
  // Find the targets that were deleted
  const deletedTargets = originalTargets.filter(
    originalTarget =>
      !targets.some(target => target.name === originalTarget.name)
  );

  const newTargets = targets.filter(
    target =>
      !originalTargets.some(
        originalTarget => originalTarget.name === target.name
      )
  );

  const payload = {
    create: newTargets.map(target => buildGeoTargetPayload({ target, orgId })),
  };

  if (deletedTargets.length) {
    payload.delete = deletedTargets.map(target => ({
      id: target.id,
    }));
  }

  return payload;
};

export const transformLocationToGeoTarget = ({ location }) => {
  const {
    name,
    formattedAddress,
    city,
    state,
    zip,
    lat,
    lng,
    street,
    country,
    geoframe,
    org,
    audienceType,
    circles = [],
    polygons = [],
  } = location;

  const geoFrameCircles = geoframe && geoframe.circles ? geoframe.circles : [];
  const geoFramePolygons =
    geoframe && geoframe.polygons ? geoframe.polygons : [];

  const newCircles = geoFrameCircles.length ? geoFrameCircles : circles;
  const newPolygons = geoFramePolygons.length ? geoFramePolygons : polygons;

  return {
    circles: newCircles.map(circle => ({
      ...circle,
      location: {
        name: circle.location ? circle.location.name : name,
        formattedAddress: circle.location
          ? circle.location.formattedAddress
          : formattedAddress,
        street: circle.location ? circle.location.street : street,
        state: circle.location ? circle.location.state : state,
        city: circle.location ? circle.location.city : city,
        zip: circle.location ? circle.location.zip : zip,
        country: circle.location ? circle.location.country : country,
        lat: circle.location ? circle.location.lat : lat,
        lng: circle.location ? circle.location.lng : lng,
        audienceType,
      },
    })),
    polygons: newPolygons.map(polygon => ({
      ...polygon,
      location: {
        name: polygon.location ? polygon.location.name : name,
        formattedAddress: polygon.location
          ? polygon.location.formattedAddress
          : formattedAddress,
        street: polygon.location ? polygon.location.street : street,
        state: polygon.location ? polygon.location.state : state,
        city: polygon.location ? polygon.location.city : city,
        zip: polygon.location ? polygon.location.zip : zip,
        country: polygon.location ? polygon.location.country : country,
        lat: polygon.location ? polygon.location.lat : lat,
        lng: polygon.location ? polygon.location.lng : lng,
        audienceType,
      },
    })),
    location: {
      name: name,
      formattedAddress: formattedAddress,
      street: street,
      state: state,
      city: city,
      zip: zip,
      country: country,
      lat: lat,
      lng: lng,
      audienceType,
    },
    name: location.name,
    start: null,
    end: null,
    org,
  };
};

export const buildAdGroupPayload = ({
  adGroup,
  orgId,
  campaign,
  originalCampaigns = [],
}) => {
  let newAdGroupCreatives = [];
  let updatedAdGroupCreatives = [];
  let deletedAdGroupCreatives = [];

  if (adGroup.adGroupCreatives && adGroup.adGroupCreatives.length) {
    newAdGroupCreatives = adGroup.adGroupCreatives.filter(
      creative => !creative.id
    );
    updatedAdGroupCreatives = adGroup.adGroupCreatives.filter(
      creative => creative.id
    );
  }

  const matchedCampaign = originalCampaigns
    .filter(
      campaign =>
        campaign.adGroup &&
        campaign.adGroup.adGroupCreatives &&
        campaign.adGroup.adGroupCreatives.length
    )
    .find(c => c.id === campaign.id);

  if (matchedCampaign) {
    deletedAdGroupCreatives = matchedCampaign.adGroup.adGroupCreatives.filter(
      creative =>
        !campaign.adGroup.adGroupCreatives.find(c => c.name === creative.name)
    );
  }

  if (adGroup && adGroup.id) {
    return {
      update: {
        clickthrough: adGroup.clickthrough,
        adGroupCreatives: {
          create: newAdGroupCreatives.map(adGroupCreative => {
            return {
              name: adGroupCreative.name,
              startDate: adGroupCreative.startDate,
              endDate: adGroupCreative.endDate,
              medias: {
                create: adGroupCreative.medias.map(media => ({
                  fileType: media.fileType,
                  height: media.height,
                  key: media.key,
                  name: media.name,
                  size: media.size,
                  type: media.type,
                  url: media.url,
                  width: media.width,
                })),
              },
            };
          }),
          update: updatedAdGroupCreatives.map(adGroupCreative => {
            return {
              where: {
                id: adGroupCreative.id,
              },
              data: {
                startDate: adGroupCreative.startDate,
                endDate: adGroupCreative.endDate,
              },
            };
          }),
          delete: deletedAdGroupCreatives.map(adGroupCreative => ({
            id: adGroupCreative.id,
          })),
        },
      },
    };
  } else {
    return {
      create: {
        clickthrough: adGroup.clickthrough,
        adGroupCreatives: {
          create:
            adGroup.adGroupCreatives && adGroup.adGroupCreatives.length
              ? adGroup.adGroupCreatives.map(adGroupCreative => {
                  return {
                    name: adGroupCreative.name,
                    startDate: adGroupCreative.startDate,
                    endDate: adGroupCreative.endDate,
                    medias: {
                      create: adGroupCreative.medias.map(media => ({
                        fileType: media.fileType,
                        height: media.height,
                        key: media.key,
                        mediaHasNotes: media.mediaHasNotes,
                        mediaNotes: media.mediaNotes,
                        name: media.name,
                        org: media.org,
                        size: media.size,
                        type: media.type,
                        url: media.url,
                        width: media.width,
                        sharedWith: {
                          connect: {
                            id: orgId,
                          },
                        },
                      })),
                    },
                  };
                })
              : [],
        },
      },
    };
  }
};

export const calculateImpressions = ({ cpm, budget }) => {
  return budget && cpm ? Math.ceil((Number(budget) / Number(cpm)) * 1000) : 0;
};

export const calculateImpressionsByPerecent = ({ cpm, budget, percent }) => {
  return budget && cpm
    ? Math.ceil((Number(budget) / Number(cpm)) * 1000 * percent)
    : 0;
};
