import React, { useState } from "react";
import Papa from "papaparse";
import { Upload, Tooltip, message, Row } from "antd";
import Button from "../../../shared/globalStyling/Button";
import { zipObject } from "lodash";
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
// Global Styles
import { ButtonText } from "../../../shared/globalStyling/styledText";
import { OrganizationConfig } from "../configs/organizationConfig";

const OrganizationCsvLocationUpload = ({ onComplete, showForm, disabled }) => {
  const [isUploading, setIsUploading] = useState(false);

  const customRequest = ({ file, onSuccess, onError, onComplete, keys }) => {
    setIsUploading(true);
    if (file.name.toLowerCase().includes("csv")) {
      Papa.parse(file, {
        skipEmptyLines: "greedy",
        error: function(err, file, inputElem, reason) {
          onError({ err, file, inputElem, reason });
        },
        complete: async results => {
          const rows = results.data;
          const headers = results.data.shift();
          const isValidHeaders = !OrganizationConfig.csvTemplate.reuiredFields
            .map(r => headers.includes(r))
            .some(res => res === false);

          if (!isValidHeaders) {
            message.error(
              "One or more required fields are missing, please check your CSV and try again."
            );
            setIsUploading(false);
            return;
          }

          const mappedData = rows.map(row => zipObject(headers, row));
          const response = await onComplete(mappedData);
          if (response) {
            message.error(response);
          } else {
            setIsUploading(false);
          }
          onSuccess("done", file);
        },
      });
    } else {
      message.error("Invalid file type. Only accepts .csv");
      setIsUploading(false);
      return;
    }
  };

  const tooltipText = (
    <React.Fragment>
      <Row>Accept CSV file type.</Row>
    </React.Fragment>
  );

  return (
    <Upload
      accept={".csv"}
      showUploadList={false}
      multiple={false}
      customRequest={params =>
        customRequest({ ...params, onComplete: onComplete })
      }
    >
      <Tooltip placement="left" title={tooltipText}>
        <Button
          onClick={showForm}
          disabled={disabled}
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          loading={isUploading}
        >
          <ButtonText text={"Upload"} />
        </Button>
      </Tooltip>
    </Upload>
  );
};

export default OrganizationCsvLocationUpload;

OrganizationCsvLocationUpload.propTypes = {
  onComplete: PropTypes.func,
};
