/**
 *  @author Pacholo Amit
 *
 *  @description The WhiteLabel object manages the details that gets
 *  returned based on the domain name
 *
 **/

const WhiteLabel = () => {
  const getDomain = window.location.hostname;

  return (
    "https://mj-channel-partner-logo.s3.amazonaws.com/" + getDomain + ".png"
  );
};

export default WhiteLabel;
