import gql from "graphql-tag";

const TAG_ORGS = gql`
  query getTags($orgId: ID) {
    eventsTargets(
      where: {
        orgs_some: { OR: [{ parentOrg_some: { id: $orgId } }, { id: $orgId }] }
      }
      first: 1000
    ) {
      id
      name
      pageViews
      signups
      transactions
      basketItems
      cart
      eventTags {
        id
        appId
      }
      tag
      segmentId
      signups
      segmentName
      orgs {
        id
        name
        website
      }
      isAdvertiser
    }
  }
`;

export default TAG_ORGS;
