import React from "react";
import { Space, Typography } from "antd";

import img1 from "../../../../../../assets/tagsInstructions/wooCommerce/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/wooCommerce/img2.png";
import img3 from "../../../../../../assets/tagsInstructions/wooCommerce/img3.png";
import img4 from "../../../../../../assets/tagsInstructions/wooCommerce/img4.png";
import img5 from "../../../../../../assets/tagsInstructions/wooCommerce/img5.png";
import img6 from "../../../../../../assets/tagsInstructions/wooCommerce/img6.png";

import InstructionsBuilder from "./InstructionsBuilder";
import { Page, Text, Document } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";
import { WixInstructions, WixInstructionsPDF } from "./WixInstructions";

const wordPressSteps = generatedTag => [
  {
    instruction: (
      <>
        Login to{" "}
        <Typography.Link
          href="http://Wordpress.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordpress.com
        </Typography.Link>{" "}
        {">"} Install and activate the{" "}
        <Typography.Link
          href="https://wordpress.org/plugins/code-snippets/"
          target="_blank"
          rel="noopener noreferrer"
        >
          free Code Snippets plugin
        </Typography.Link>
      </>
    ),
    pdfText:
      "Login to Wordpress.com > Install and activate the free Code Snippets plugin",
  },
  {
    instruction: (
      <>
        Once the Code Snippets is installed. Click on the{" "}
        <Typography.Text strong>“Add New”</Typography.Text>
      </>
    ),
    image: img1,
    pdfText: "Once the Code Snippets is installed. Click on the “Add New”",
  },
  {
    instruction: (
      <>
        Click on{" "}
        <Typography.Text strong>
          “Functions (PHP)” — THIS IS VERY IMPORTANT
        </Typography.Text>
      </>
    ),
    image: img2,
    pdfText: "Click on “Functions (PHP)” — THIS IS VERY IMPORTANT",
  },
  {
    instruction: (
      <>
        Click on the{" "}
        <Typography.Text strong>“Run snippet everywhere”.</Typography.Text>
      </>
    ),
    image: img3,
    pdfText: "Click on the “Run snippet everywhere”.",
  },
  {
    instruction: `Name the snippet`,
    image: img4,
  },
  {
    instruction: `Paste the tag on the Code section`,
    image: img5,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          6. Paste the tag on the Code section
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: (
      <>
        <Typography.Text strong>Save</Typography.Text> and{" "}
        <Typography.Text strong>Activate</Typography.Text> the code snippet
      </>
    ),
    image: img6,
    pdfText: "Save and Activate the code snippet",
  },
];

export const WoocommerceInstructions = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <Space direction="vertical">
        <InstructionsBuilder
          title="Wordpress"
          steps={wordPressSteps(generatedTag)}
        />
        <WixInstructions generatedTag={generatedTag} />
      </Space>
    </React.Fragment>
  );
};

export const WoocommerceInstructionsPDF = ({ generatedTag }) => {
  return (
    <Document>
      <Page
        wrap={false}
        size="A4"
        style={{
          padding: "40px",
        }}
      >
        <Text
          style={{
            fontSize: 15,
            marginBottom: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          Cart Instruction for Woocommerce
        </Text>
        <GeneratedTagPDF
          title={"Wordpress"}
          steps={wordPressSteps(generatedTag)}
        />
        <WixInstructionsPDF generatedTag={generatedTag} />
      </Page>
    </Document>
  );
};
