import { message } from "antd";
import React from "react";
import { OrganizationConfig } from "../../../../platform/ux/OrgConfig/configs/organizationConfig";
import moment from "moment";
import { parseOverlayToGeoTarget } from "../../../utils/geoTargets";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";
import { MapConfig } from "../../../../platform/shared/maps/configs/mapConfigs";
import { isEmpty, camelCase, snakeCase } from "lodash";
import { defaultRadius } from "../../../utils/constants/constants";
import {
  getFormattedAddress,
  getGeoCodes,
} from "../../../../platform/shared/maps/utils/mapUtils";
import GET_LOCATIONS from "../../../GraphQl/Queries/GET_LOCATIONS";

// IMPORTANT NOTE: To the next dev that will work on the new screens please change this controller to a functional component
class TargetingMainController extends React.Component {
  state = {
    targetingLocations: [],
    selectedOrg: null,
    isTargetingPreviewModalOpen: false,
    targetingLocationsPreviewData: [],
    attributionAudienceName: "",
    parentState: {
      selectedLicense: {},
      zoomLevel: 10,
      initialLocation: {
        latitude: "",
        longitude: "",
      },
      polygons: [],
      name: "",
      street: "",
      city: "",
      zip: "",
      tags: [],
      start: "",
      end: "",
      lat: null,
      lng: null,
      formattedAddress: "",
      state: "",
      country: "",
      description: "",
      locationLoading: false,
      email: "",
      contact: "",
      phone: "",
      url: "",
      hrs: "",
      locationId: "",
      geoFrameId: "",
      tablet: window.matchMedia("(max-width: 1100px)").matches,
      locationLoaded: false,
      id: "",
      tempName: "",
      selectedGeoTargets: [],
      inProgressOverlay: [],
      cart: "",
      menuDataKey: "",
      geoframe: [],
      province: "",
    },
    selectedLocations: [],
    onLocationUpdate: false,
    targetingAudiences: [],
    dealIds: null,
    retailLocationList: [],
  };

  componentDidMount = () => {
    let loggedInOrg = this.props.loggedInOrg;

    this.setState({
      selectedOrg: {
        ...loggedInOrg,
      },
    });
  };

  componentDidUpdate(prevProps) {
    const {
      currentuser: { role },
      locationList,
      loadingTargetingAudiences,
      targetingAudiences,
      orgDealIds,
    } = this.props;

    if (!this.props.loadingLocation && !this.state.locationLoaded) {
      const locations =
        locationList && locationList.locations ? locationList.locations : [];

      this.setState({
        locationLoaded: true,
        retailLocationList: locations,
      });
    }

    if (this.state.onLocationUpdate === true) {
      this.props.client
        .query({
          query: GET_LOCATIONS,
          variables: { id: role.org.id },
          cachePolicy: "no-cache",
        })
        .then(res => {
          const getLocation = res && res.data && res.data.locations;

          this.setState({
            onLocationUpdate: false,
            retailLocationList: getLocation,
          });
        });
    }

    if (
      (!loadingTargetingAudiences && !this.state.targetingAudiences.length) ||
      (targetingAudiences &&
        prevProps.targetingAudiences !== targetingAudiences)
    ) {
      const { audiencesConnection } = targetingAudiences;
      if (
        audiencesConnection &&
        audiencesConnection.edges &&
        audiencesConnection.edges.length
      ) {
        const formattedTargetingAudiences = audiencesConnection.edges.map(
          edge => edge.node
        );

        this.setState({
          targetingAudiences: formattedTargetingAudiences,
        });
      }
    }

    if (
      (orgDealIds &&
        orgDealIds.org &&
        orgDealIds.org.dealIds &&
        !this.state.dealIds) ||
      (prevProps.orgDealIds &&
        prevProps.orgDealIds.org &&
        prevProps.orgDealIds.org.dealIds !== orgDealIds.org.dealIds)
    ) {
      this.setState({
        dealIds: orgDealIds.org.dealIds,
      });
    }
  }

  setAudienceAmplifyDefault = async record => {
    await this.props.updateManyAudiences({
      variables: {
        orgId: [record.orgs[0].id],
        isAmplifyDefault: false,
      },
    });
  };

  createTargetingLocation = async location => {
    message.loading("Action in progress..", 0);

    this.setState({
      parentState: {
        ...OrganizationConfig.csvTemplate.defaultValues,
        ...location,
        inProgressOverlay: location.circles,
      },
    });

    this.createOrgLocation();
  };

  createOrgLocation = async () => {
    const {
      currentuser: { id, role },
      createLocation,
      createLocationWithGeotarget,
    } = this.props;

    const { parentState, retailLocationList } = this.state;

    let tempRetailLocationList = retailLocationList.filter(() => {
      return true;
    });

    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      inProgressOverlay,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = null;

      if (circles.length !== 0 || polygons.length !== 0) {
        await this.props
          .createGeoTargetLocation({
            variables: {
              name: name,
              orgID: role.org.id,
              isBase: true,
              circles: circles,
              polygons: polygons,
              start: moment()
                .subtract(90, "days")
                .format(),
              end: moment().format(),
            },
          })
          .then(val => {
            geoId =
              val &&
              val.data &&
              val.data.createGeoTarget &&
              val.data.createGeoTarget.id;
          });
      }

      const data = {
        audienceType,
        cart: cart === "" ? "NOCART" : cart,
        circles,
        city,
        contact,
        country: country || "USA",
        description,
        email,
        end: moment().format(),
        formattedAddress: `${street},${formattedAddress}`,
        geoId,
        isBase: true,
        lat,
        lng,
        locationKey,
        menuDataKey,
        name,
        orgId: role.org.id,
        phone,
        polygons,
        start: moment()
          .subtract(90, "days")
          .format(),
        state,
        street,
        url,
        zip,
        province,
        createdBy: id,
      };

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await createLocationWithGeotarget(data)
          : await createLocation(data);

      if (response.status === "success") {
        tempRetailLocationList.unshift(response.data);

        this.setState({
          retailLocationList: tempRetailLocationList,
        });

        message.destroy();
        message.success(`Successfully created new Location`);

        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteLocation = async Id => {
    const { deleteLocation } = this.props;

    const { retailLocationList } = this.state;

    let tempRetailLocationList = retailLocationList.filter(function(location) {
      return location.id !== Id;
    });

    const response = await deleteLocation({
      variables: {
        Id,
      },
    });

    if (response) {
      this.setState({
        retailLocationList: tempRetailLocationList,
      });

      return true;
    } else {
      throw response.error;
    }
  };

  onCsvUploadComplete = async parsedData => {
    let results = [];
    const { countryType } = MapConfig;
    const formattedData = parsedData.map((data, key) => {
      const formattedKeyValue = {};
      Object.keys(data).map(key => {
        return (formattedKeyValue[camelCase(key)] = data[key]);
      });

      return formattedKeyValue;
    });

    results = this.getTableData(formattedData);

    Promise.all(results).then(values => {
      const { retailLocationList, selectedOrg } = this.state;
      const filteredLocations = retailLocationList
        .filter(
          location =>
            location && location.org && location.org.id === selectedOrg.id
        )
        .filter(location => location && location.audienceType === "TARGETING");

      const previewData = formattedData
        .map((data, index, array) => {
          const isDuplicate =
            array.findIndex(name => name.name === data.name) !== index;
          return isDuplicate ? { ...data, duplicate: true } : data;
        })
        .map((data, key) => {
          if (values[key].status === "OK") {
            const place = values[key].results[0];
            const { state, city, zip, country, address } = getFormattedAddress({
              result: place,
            });

            const matchedLocation = filteredLocations.find(
              location => location.name === data.name
            );

            return {
              ...data,
              id: key,
              matched: matchedLocation || data.duplicate ? false : true,
              selected: matchedLocation || data.duplicate ? false : true,
              state: state.toUpperCase(),
              city,
              zip,
              street: address,
              sourceAddress: data.address,
              formattedAddress: place.formatted_address,
              country: countryType[country],
              coordinate: place.geometry.location,
              errorMessage:
                matchedLocation || data.duplicate
                  ? "Location name already exists"
                  : "",
            };
          }

          return {
            ...data,
            id: key,
            matched: false,
            selected: false,
            sourceAddress: data.address,
            formattedAddress: "",
          };
        });

      this.setState({
        isTargetingPreviewModalOpen: true,
        targetingLocationsPreviewData: previewData,
      });
    });
  };

  getTableData = locations => {
    const results = [];
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      const fullAddress = `${location.address} ${location.city} ${location.state} ${location.zip}`
        .split(" ")
        .join("+");
      results.push(getGeoCodes(fullAddress));
    }

    return results;
  };

  onChangeStateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  onSubmitAudience = async type => {
    message.loading("Action in progress..", 0);

    const {
      createSelectedAudienceLocation,
      currentuser: { id, role },
    } = this.props;
    const { selectedLocations, attributionAudienceName } = this.state;

    try {
      const getAllGeotargetIds =
        selectedLocations &&
        selectedLocations.map(val => {
          const geotargets = val.geoframe;
          const geotargetsId = geotargets && geotargets.id;

          return {
            id: geotargetsId,
          };
        });

      const res = await createSelectedAudienceLocation({
        variables: {
          name: attributionAudienceName,
          type: type,
          geoTargets: getAllGeotargetIds,
          orgid: role.org.id,
          createdBy: id,
        },
      });

      if (res && res.data) {
        if (this.state.targetingAudiences.length === 1) {
          this.setAudienceAmplifyDefault(this.state.targetingAudiences[0]);
        }

        this.setState({
          selectedLocations: [],
        });
        message.destroy();
        message.success(`Successfully created audience`);
      } else {
        throw Error;
      }
    } catch (err) {
      message.destroy();
      message.error(`Failed to create audience`);
    }
  };

  updateTargetingLocation = async location => {
    this.setState({
      parentState: {
        inProgressOverlay: location.circles,
        ...location,
      },
    });

    this.updateOrgLocation();
  };

  updateOrgLocation = async () => {
    message.loading("Action in progress..", 0);

    const {
      updateLocation,
      updateLocationWithGeotargets,
      currentuser: { role, id: userId },
    } = this.props;

    const { parentState } = this.state;
    const {
      name,
      description,
      street,
      city,
      state,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      inProgressOverlay,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = "";

      await this.props
        .createGeoTargetLocation({
          variables: {
            name: name,
            orgID: role.org.id,
            isBase: true,
            circles: circles,
            polygons: polygons,
            start: moment()
              .subtract(90, "days")
              .format(),
            end: moment().format(),
          },
        })
        .then(val => {
          geoId =
            val &&
            val.data &&
            val.data.createGeoTarget &&
            val.data.createGeoTarget.id;
        });

      const data = {
        name,
        description,
        street,
        city,
        state,
        country: country || "USA",
        email,
        url,
        phone,
        contact,
        formattedAddress: `${formattedAddress}`,
        zip,
        id,
        cart,
        menuDataKey,
        geoId,
        locationKey,
        audienceType,
        province,
        updatedBy: userId,
      };

      if (data.country !== "USA" && data.country !== "CANADA") {
        delete data.state;
      } else {
        delete data.province;
      }

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await updateLocationWithGeotargets({
              variables: data,
            })
          : await updateLocation(data);

      if (!isEmpty(response)) {
        this.setState({
          onLocationUpdate: true,
        });

        this.resetParentState();
        message.destroy();
        message.success(`Targeting location successfully updated.`);
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      message.destroy();
      message.error("Failed to update targeting location.");
    }
  };

  onCloseTargetingPreviewModal = () => {
    this.setState({
      isTargetingPreviewModalOpen: false,
    });
  };

  deleteAudienceLocation = async audiences => {
    try {
      message.loading("Action in progress..", 0);
      const results = [];
      const { deleteAudienceLocation } = this.props;
      const { targetingAudiences } = this.state;

      audiences.forEach(audience => {
        const Id = audience.id;
        results.push(
          deleteAudienceLocation({
            variables: {
              Id,
            },
          })
        );
      });

      Promise.all(results).then(values => {
        let updatedTargetAudiences = targetingAudiences.filter(
          targetAudience => {
            return !values
              .filter(value => value && value.data)
              .map(value => value.data.deleteAudience.id)
              .includes(targetAudience.id);
          }
        );

        this.setState({
          targetingAudiences: updatedTargetAudiences,
        });

        message.destroy();
        message.success(`Audiences successfully deleted.`);
      });
    } catch (error) {
      message.destroy();
      message.success(`Failed to delete audience(s).`);
    }
  };

  resetParentState = () => {
    this.setState({
      shouldClearShapes: false,
      parentState: {
        selectedLicense: {},
        zoomLevel: 10,
        initialLocation: {
          latitude: "39.462407",
          longitude: "-105.642816",
        },
        polygons: [],
        name: "",
        street: "",
        city: "",
        zip: "",
        tags: [],
        start: "",
        end: "",
        lat: null,
        lng: null,
        formattedAddress: "",
        state: "",
        country: "",
        description: "",
        locationLoading: false,
        email: "",
        contact: "",
        phone: "",
        url: "",
        hrs: "",
        locationId: "",
        geoFrameId: "",
        tablet: window.matchMedia("(max-width: 1100px)").matches,
        locationLoaded: false,
        id: "",
        tempName: "",
        selectedGeoTargets: [],
        inProgressOverlay: [],
        cart: "",
        menuDataKey: "",
        refreshGeotargetDates: false,
        locationKey: "",
      },
    });
  };

  deleteBulkLocation = async () => {
    try {
      message.loading("Action in progress..", 0);
      const { retailLocationList } = this.state;
      const { deleteManyLocations } = this.props;
      const ids = this.state.selectedLocations.map((location, index) => {
        return location.id;
      });

      const response = await deleteManyLocations({
        variables: {
          ids: ids,
        },
      });

      if (response && response.data) {
        let updatedLocations = retailLocationList.filter(targetLocation => {
          return !this.state.selectedLocations
            .map(value => value.id)
            .includes(targetLocation.id);
        });

        this.setState({
          retailLocationList: updatedLocations,
          selectedLocations: [],
        });

        message.destroy();
        message.success(`Targeting locations successfully deleted.`);
      }
    } catch (error) {
      message.destroy();
      message.success(`Failed to delete targeting location(s).`);
    }
  };

  handleSubmitRegionGroup = async val => {
    const {
      createRegionGroup,
      currentuser: { role },
    } = this.props;

    return await createRegionGroup({
      variables: {
        name: val.name,
        regions: val.regions,
        orgID: role.org.id,
        isPresetList: val.isPresetList,
      },
    });
  };

  handleUpdateRegionGroup = async value => {
    const { updateRegionGroup } = this.props;

    const { id, name, regions, isPresetList } = value;

    return await updateRegionGroup({
      variables: {
        id,
        name,
        regions,
        isPresetList,
      },
    });
  };

  onDeleteRegionGroup = async Id => {
    await this.props.deleteRegionGroup({
      variables: {
        Id: Id,
      },
    });
  };

  handleSubmitIABCategoryGroup = async val => {
    const {
      createIABCategoryGroup,
      currentuser: { role },
    } = this.props;
    return await createIABCategoryGroup({
      variables: {
        name: val.name,
        iabCategories: val.iabCategories,
        orgID: role.org.id,
      },
    });
  };

  onDeleteIABCategoryGroup = async Id => {
    await this.props.deleteIABCategoryGroup({
      variables: {
        Id: Id,
      },
    });
  };

  createDealId = async ({ name, dealId }) => {
    try {
      const { createOrgDealId } = this.props;
      const result = await createOrgDealId({
        variables: {
          id: this.state.selectedOrg.id,
          data: {
            dealIds: {
              create: [
                {
                  name,
                  dealId,
                },
              ],
            },
          },
        },
      });

      const dealIds = result.data.updateOrg.dealIds;
      this.setState({
        dealIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  deleteDealIds = async ({ ids }) => {
    try {
      const { deleteOrgDealIds } = this.props;
      await deleteOrgDealIds({
        variables: {
          ids,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  updateDealId = async ({ id, name, dealId }) => {
    try {
      const { updateDealId } = this.props;
      const result = await updateDealId({
        variables: {
          id,
          data: {
            name,
            dealId,
          },
        },
      });
      const updatedDealId = result.data.updateDealId;
      const dealIds = this.state.dealIds.map(dealId => {
        if (dealId.id === updatedDealId.id) {
          return updatedDealId;
        }
        return dealId;
      });
      this.setState({
        dealIds,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  onCreateGeoTargetLocation = async ({ name, circles }) => {
    const { createGeoTargetLocation, loggedInOrg } = this.props;
    return await createGeoTargetLocation({
      variables: {
        name,
        orgID: loggedInOrg.id,
        isBase: true,
        circles: circles,
        polygons: [],
        start: moment()
          .subtract(90, "days")
          .format(),
        end: moment().format(),
      },
    });
  };

  getTargetLocationFormattedData = ({ location, circles, geoId }) => {
    const { loggedInOrg } = this.props;
    const {
      audienceType,
      city,
      country,
      formattedAddress,
      lat,
      lng,
      name,
      state,
      street,
      zip,
    } = location;

    return {
      audienceType,
      cart: "NOCART",
      circles,
      city,
      contact: "",
      country,
      description: "",
      email: "",
      end: moment().format(),
      formattedAddress,
      geoId,
      isBase: true,
      lat,
      lng,
      locationKey: "",
      menuDataKey: "",
      name,
      orgId: loggedInOrg.id,
      phone: "",
      polygons: [],
      start: moment()
        .subtract(90, "days")
        .format(),
      state,
      street,
      url: "",
      zip,
    };
  };

  createTargetLocation = async location => {
    const { circles } = parseOverlayToGeoTarget(location.circles);
    const { createLocationWithGeotarget, currentuser } = this.props;
    let geoId = null;

    try {
      if (circles.length) {
        const responseGeo = await this.onCreateGeoTargetLocation({
          name: location.name,
          circles,
        });

        if (responseGeo) {
          geoId = responseGeo.data.createGeoTarget.id;
        }

        const data = this.getTargetLocationFormattedData({ location, geoId });
        const response = await createLocationWithGeotarget({
          ...data,
          createdBy: currentuser.id,
        });

        message.destroy();
        if (response) {
          return response.data;
        } else {
          message.success("Failed to create Location.");
        }
      }
    } catch (error) {
      message.destroy();
      message.error("Failed to create Location.");
    }
  };

  getCircleOverlay = ({ coordinate }) => {
    const { google } = this.props;
    const position = new google.maps.LatLng(coordinate.lat, coordinate.lng);
    return {
      overlay: new google.maps.Circle({
        center: { lat: position.lat(), lng: position.lng() },
        radius: defaultRadius,
      }),
      type: "circle",
    };
  };

  bulkImportTargetingLocations = async data => {
    const locations = data
      .filter(location => location.selected)
      .map(location => {
        const circles = [
          this.getCircleOverlay({
            coordinate: location.coordinate,
          }),
        ];
        const {
          name,
          formattedAddress,
          radius,
          city,
          state,
          zip,
          street,
          country,
          coordinate,
          audienceType,
        } = location;
        return {
          ...OrganizationConfig.csvTemplate.defaultValues,
          name,
          formattedAddress,
          radius: Number(radius),
          city,
          state: state && snakeCase(state).toUpperCase(),
          zip,
          street,
          country,
          lat: coordinate.lat,
          lng: coordinate.lng,
          inProgressOverlay: [circles],
          circles,
          audienceType: audienceType || "TARGETING",
        };
      });

    if (locations.length) {
      message.loading("Action in progress...", 0);

      const results = await Promise.all(
        locations.map(location => this.createTargetLocation(location))
      );

      const ids = results
        .filter(result => result !== undefined)
        .map(result => result.id);

      const filteredLocations = this.state.retailLocationList.filter(
        location => !ids.includes(location.id)
      );

      this.setState({
        retailLocationList: [...results, ...filteredLocations],
      });
      message.success("Successfully created Location.");
    }
  };

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props.children.props,
      currentuser: this.props.currentuser,
      setAudienceAmplifyDefault: this.setAudienceAmplifyDefault,
      attributionAudienceName: this.state.attributionAudienceName,
      createTargetingLocation: this.createTargetingLocation,
      createLocation: this.createOrgLocation,
      deleteLocation: this.deleteLocation,
      google: this.props.google,
      locationList: this.state.retailLocationList,
      onChangeStateValue: this.onChangeStateValue,
      onCsvUploadComplete: this.onCsvUploadComplete,
      onSubmitAudience: this.onSubmitAudience,
      selectedLocations: this.state.selectedLocations,
      updateTargetingLocation: this.updateTargetingLocation,
      isTargetingPreviewModalOpen: this.state.isTargetingPreviewModalOpen,
      targetingLocationsPreviewData: this.state.targetingLocationsPreviewData,
      closeTargetingPreviewModal: this.onCloseTargetingPreviewModal,
      targetingAudiences: this.state.targetingAudiences,
      deleteAudienceLocation: this.deleteAudienceLocation,
      deleteBulkLocation: this.deleteBulkLocation,
      handleSubmitRegionGroup: this.handleSubmitRegionGroup,
      handleUpdateRegionGroup: this.handleUpdateRegionGroup,
      deleteRegionGroup: this.onDeleteRegionGroup,
      regionGroups: this.props.regionGroups,
      handleSubmitIABCategoryGroup: this.handleSubmitIABCategoryGroup,
      iABCategoryGroups: this.props.iABCategoryGroups,
      deleteIABCategoryGroup: this.props.onDeleteIABCategoryGroup,
      selectedOrg: this.state.selectedOrg,
      dealIds: this.state.dealIds,
      createDealId: this.createDealId,
      deleteDealIds: this.deleteDealIds,
      updateDealId: this.updateDealId,
      presetRegionGroups: this.props.presetRegionGroups,
      presetRegionGroupsLoading: this.props.presetRegionGroupsLoading,
      bulkImportTargetingLocations: this.bulkImportTargetingLocations,
    });
  }
}

export default GoogleApiWrapper(googleMapsConfig)(TargetingMainController);
