import gql from "graphql-tag";

const GET_ALL_SHARING_RECORDS = gql`
  query getSharingRecords {
    getSharingRecords {
      id
      data_member_id
      buyer_member_id
      segment_exposure
      segments
      error
    }
  }
`;

export default GET_ALL_SHARING_RECORDS;
