import gql from "graphql-tag";

const GET_ADMIN_IDS = gql`
  query GetAdminIds {
    getAdminIds {
      mediajelAdminId
      mediajelId
    }
  }
`;

export default GET_ADMIN_IDS;
