import React, { useState, useEffect } from "react";
import { Table, Row, Col, Tooltip, Progress, Input, Button } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../../shared/globalStyling/styledText";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";
import StatusText from "../../../Campaigns/shared/StatusText";

const { Search } = Input;

const CampaignOrderListTable = ({
  campaignOrderList,
  campaignOrderListLoading,
  setSearchName,
  onCampaignOrderListChange,
  changeValue,
  selectedCampaignListData,
  setIsCloningClicked,
  setCloneModal,
  defaultAdvertiser,
  handleUpdateCampaignOrder,
  handleCloneCampaignOrder,
}) => {
  const [selectedCloneLoading, setSelectedCloneLoading] = useState(false);

  // TODO: CLONING CAMPAIGN SHOULD BE HANDLED IN THE BACKEND
  useEffect(() => {
    if (!isEmpty(selectedCampaignListData)) {
      const getCampaignOrder =
        selectedCampaignListData && selectedCampaignListData.campaignOrders[0];

      changeValue("selectedCampaignOrderListId", getCampaignOrder.id);
      changeValue(
        "campaignName",
        `${getCampaignOrder.name} - ${new Date().getTime()}`
      );
      changeValue("description", getCampaignOrder.description);
      changeValue("type", getCampaignOrder.type === "CPC" ? 6 : 1); // If type is equal to display = 1 else type is equal to Paid Search = 6
      changeValue(
        "advertiser",
        getCampaignOrder.advertiser
          ? getCampaignOrder.advertiser
          : defaultAdvertiser
      );
      changeValue("region", getCampaignOrder.region);
      changeValue(
        "locationId",
        getCampaignOrder.signUpConfig &&
          getCampaignOrder.signUpConfig.locationId
      );
      changeValue("startDate", moment().format());
      changeValue(
        "endDate",
        moment()
          .add(2, "days")
          .format()
      );
      changeValue("isRegulated", getCampaignOrder.isRegulated);
      changeValue(
        "category",
        !isEmpty(getCampaignOrder.category) ? getCampaignOrder.category[0] : []
      );
      changeValue("budget", getCampaignOrder.budget);
      changeValue("cpm", getCampaignOrder.cpm);
      changeValue("targetingCriteria", getCampaignOrder.targetingCriteria);
      changeValue("geoTargets", getCampaignOrder.geoTargets);
      changeValue("cloneGeoTargetData", getCampaignOrder.geoTargets);
      changeValue(
        "adUnits",
        !isEmpty(getCampaignOrder.adUnits)
          ? getCampaignOrder.adUnits
          : [{ banners: [], clickthrough: null, name: null, selectedRows: [] }]
      );
      changeValue("awarenessCampaign", getCampaignOrder.awarenessCampaign);
      changeValue("transactionTarget", getCampaignOrder.transactionTarget);
      changeValue(
        "cart",
        getCampaignOrder.transactionsConfig
          ? getCampaignOrder.transactionsConfig.cart
          : ""
      );
      changeValue(
        "tracksBasketItems",
        getCampaignOrder.transactionsConfig
          ? getCampaignOrder.transactionsConfig.tracksBasketItems
          : false
      );
      changeValue(
        "traffId",
        getCampaignOrder.transactionsConfig
          ? getCampaignOrder.transactionsConfig.traffId
          : ""
      );
      changeValue(
        "gtmAccess",
        getCampaignOrder.transactionsConfig
          ? getCampaignOrder.transactionsConfig.gtmAccess
          : false
      );
      changeValue(
        "gtmStatus",
        getCampaignOrder.transactionsConfig
          ? getCampaignOrder.transactionsConfig.gtmStatus
          : ""
      );
      changeValue(
        "appId",
        getCampaignOrder.signUpConfig && getCampaignOrder.signUpConfig.appId
      );
      changeValue("attributionTargets", getCampaignOrder.attributionTargets);
      changeValue(
        "cloneAttributionTargets",
        getCampaignOrder.attributionTargets
      );
      changeValue("signUpCampaign", getCampaignOrder.signUpCampaign);
      changeValue("adUnits", getCampaignOrder.adUnits);
      if (getCampaignOrder.pageViewConfig) {
        changeValue("pageViewCampaign", true);
        changeValue("pageViewConfig", getCampaignOrder.pageViewConfig);
        changeValue("pageViewAppId", getCampaignOrder.pageViewConfig.appId);
        changeValue(
          "pageViewLocationId",
          getCampaignOrder.pageViewConfig.locationId
        );
      }
      setSelectedCloneLoading(false);
      setCloneModal(false);
      setTimeout(() => {
        handleCloneCampaignOrder();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeValue, defaultAdvertiser, selectedCampaignListData, setCloneModal]);

  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "40%",
      render: (text, record) => {
        return (
          <>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Status"} />,
      dataIndex: "status",
      key: "status",
      align: "left",
      width: "20%",
      render: (text, record) => {
        return (
          <Row type="flex" justify="start">
            <Col>
              <StatusText
                startDate={
                  record &&
                  moment(record.startDate)
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString()
                    ? moment(record.startDate)
                        .seconds(0)
                        .milliseconds(0)
                        .toISOString()
                    : "N/A"
                }
                endDate={
                  record &&
                  moment(record.endDate)
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString()
                    ? moment(record.endDate)
                        .seconds(0)
                        .milliseconds(0)
                        .toISOString()
                    : "N/A"
                }
                attributionWindow={
                  record && record.attributionWindow
                    ? record.attributionWindow
                    : "N/A"
                }
                statusCampaign={record && record.status}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Geotarget Count"} />,
      dataIndex: "geoTargets",
      key: "geoTargets",
      align: "left",
      width: "20%",
      render: (text, record) => {
        const geoTargetCount =
          record && record.geoTargets ? record.geoTargets.length : 0;
        return (
          <>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText text={geoTargetCount} />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Flight Date"} />,
      dataIndex: "startDate",
      key: "dateRange",
      align: "left",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.startDate);
        const endDate = moment(record.endDate);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <>
            <Row type="flex" justify="start">
              {record.startDate && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.endDate && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      key: "impressions",
      align: "left",
      width: "20%",
      render: (text, record) => {
        const getImpressions =
          record &&
          record.aggregateData &&
          record.aggregateData.overallData &&
          record.aggregateData.overallData.aggImpressions;

        return (
          <>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText text={formatWholeNumber(getImpressions)} />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      {campaignOrderListLoading === false && selectedCloneLoading === false ? (
        <>
          <Row style={{ background: "#f4f4f4" }}>
            <Col span="8">
              <p
                style={{
                  marginTop: "25px",
                  marginLeft: "30px",
                }}
              >
                Reminder: Choose an item to Clone
              </p>
            </Col>
            <Col span="8" offset="4">
              <Search
                placeholder="Search a Campaign Order"
                onSearch={e => {
                  setSearchName(e);
                }}
                style={{ width: 200, marginTop: "20px", marginBottom: "20px" }}
              />
              <Button
                onClick={() => {
                  setSearchName("");
                }}
                icon={<CloseOutlined />}
                style={{
                  marginLeft: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Clear
              </Button>
            </Col>
            <Col span="24" style={{ background: "white" }}>
              <Table
                scroll={{ y: 610 }}
                columns={columns}
                sortDirections={["ascend", "descend"]}
                dataSource={
                  !isEmpty(campaignOrderList)
                    ? campaignOrderList &&
                      campaignOrderList.campaignOrders.map(
                        (campaignOrder, i) => {
                          const record = { ...campaignOrder, key: i };
                          return record;
                        }
                      )
                    : []
                }
                rowSelection={{
                  type: "radio",
                  onSelect: selectedRowKeys => {
                    const getId = selectedRowKeys && selectedRowKeys.id;
                    setSelectedCloneLoading(true);
                    setIsCloningClicked(true);
                    changeValue("selectedCampaignOrderList", getId);
                    onCampaignOrderListChange(getId);
                  },
                }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <LoadingOutlined />
        </div>
      )}
    </>
  );
};

export default CampaignOrderListTable;
