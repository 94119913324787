import React from "react";
import { graphql, useQuery } from "react-apollo";
import compose from "lodash/flowRight";

import CURRENT_USER from "../.././GraphQl/Queries/CURRENT_USER";
import GET_ALL_ARTICLE_CATEGORIES from "../.././GraphQl/Queries/GET_ALL_ARTICLE_CATEGORIES";
import Articles from "../../.././platform/ux/Articles";
import OverlaySpinner from "../../.././platform/shared/OverlaySpinner";

const Loader = props => {
  const { data, error, loading } = useQuery(GET_ALL_ARTICLE_CATEGORIES);

  const userPermission = Object.entries(props.currentUser.permission)
    .map(([k, v]) => (v === true ? k : ""))
    .filter(v => v);

  const userFeatures = props.currentUser.features;

  const finalData = [];

  if (loading) {
    return <OverlaySpinner />;
  }
  if (error) {
    return `Error (Query: GET_ALL_ARTICLE_CATEGORIES)! ${error.message}`;
  }

  if (data.articleCategories) {
    data.articleCategories.map(category => {
      category.permission.map(p => {
        if (userPermission.includes(p)) {
          finalData.push(category);
        }
        return category;
      });

      category.feature.map(f => {
        if (userFeatures.includes(f)) {
          finalData.push(category);
        }

        return category;
      });

      return category;
    });
  }

  const arrayUniqueByKey = [
    ...new Map(finalData.map(item => [item["id"], item])).values(),
  ];

  return <Articles data={arrayUniqueByKey} user={props.currentUser} />;
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(Loader);
