import React, { memo } from "react";
import PageViewLoader from "../../../../../../core/components/campaigns/campaigndetails/pageVIew/PageViewLoader";
import PageViewController from "../../../../../../core/components/campaigns/campaigndetails/pageVIew/PageViewController";
import PageViewTabView from "./PageViewTabView";

const PageViewTab = ({
  reportKey,
  campaignOrder,
  setExportButtonLoading,
  setExportButtonDisable,
  setExportButtonMenuItems,
}) => {
  return (
    <React.Fragment>
      <PageViewLoader reportKey={reportKey}>
        <PageViewController>
          <PageViewTabView
            setExportButtonLoading={setExportButtonLoading}
            setExportButtonDisable={setExportButtonDisable}
            setExportButtonMenuItems={setExportButtonMenuItems}
            campaignOrder={campaignOrder}
          />
        </PageViewController>
      </PageViewLoader>
    </React.Fragment>
  );
};

export default memo(PageViewTab);
