import { Select, Tag } from "antd";
import React from "react";

function AdvertiserFilterView({
  isSinglePage,
  orgsList,
  loading,
  handleChangeAdvertiser,
  currentAdvertiserId,
  handleOnClear,
  isDemo = false,
}) {
  const handleChange = e => {
    handleChangeAdvertiser(e);
  };

  const tagRender = props => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const options = [];
  if (Array.isArray(orgsList) && orgsList.length) {
    for (let i = 0; i < orgsList.length; i++) {
      const value = `${orgsList[i].id}`;
      const label = `${
        !isDemo ? orgsList[i].name : `Example Organization ${i}`
      }`;
      options.push({
        value,
        label,
      });
    }
  }

  return (
    <Select
      showSearch
      mode="multiple"
      style={{
        width: "100%",
      }}
      placeholder="Filter by Advertiser"
      value={loading || !currentAdvertiserId ? [] : currentAdvertiserId}
      allowClear
      onClear={() => handleOnClear()}
      onChange={handleChange}
      loading={loading}
      tagRender={tagRender}
      maxTagCount="responsive"
      disabled={loading}
      options={options}
      filterOption={(input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    />
  );
}

export default AdvertiserFilterView;
