import React from "react";
import { Form, Input, Button, Select, Card } from "antd";
//locals from shared
import GooglePlacesInput from "../.././shared/GooglePlacesInput";
import YcListOrgsDropDown from "../.././shared/YcListOrgsDropDown";

const FormItem = Form.Item;
const Option = Select.Option;

const LocationForm = props => {
  const getLocationData = data => {
    props.onLocationRecieved(data);
  };

  const { createLocation, updateLocation } = props;
  const { name, description, hours, edit, type, license } = props.state;

  return (
    <Card>
      <Form>
        <FormItem>
          <YcListOrgsDropDown onChange={props.onChange} itemKey="orgId" />
        </FormItem>
        <FormItem>
          <Input
            placeholder="location name - optional"
            name="name"
            value={name}
            onChange={({ target }) => {
              props.onChange([target.name], target.value);
            }}
          />
        </FormItem>
        <FormItem>
          <Input
            placeholder="Description - optional"
            name="description"
            value={description}
            onChange={({ target }) => {
              props.onChange([target.name], target.value);
            }}
          />
        </FormItem>
        <FormItem>
          <Input
            placeholder="Hours - optional eg: 9 - 5 weekdays"
            name="hours"
            value={hours}
            onChange={({ target }) => {
              props.onChange([target.name], target.value);
            }}
          />
        </FormItem>
        <FormItem>
          <Select
            defaultValue={type ? type : "Type"}
            name="type"
            style={{ width: "100%" }}
            onChange={value => {
              props.onChange("type", value);
            }}
          >
            <Option value="DELIVERY">DELIVERY</Option>
            <Option value="DISPENSARY">DISPENSARY</Option>
            <Option value="DISTRIBUTION">DISTRIBUTION</Option>
            <Option value="EVENT">EVENT</Option>
            <Option value="HEADQUARTERS">HEADQUARTERS</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Select
            defaultValue={license ? license : "License"}
            name="license"
            style={{ width: "100%" }}
            onChange={value => {
              props.onChange("license", value);
            }}
          >
            <Option value="MEDICAL">MEDICAL</Option>
            <Option value="RECREATIONAL">RECREATIONAL</Option>
            <Option value="NOT_APPLICABLE">NOT_APPLICABLE</Option>
          </Select>
        </FormItem>

        <FormItem>
          <GooglePlacesInput getLocationData={getLocationData} />
        </FormItem>

        <FormItem>
          {edit ? (
            <Button onClick={updateLocation} type="primary">
              Update location
            </Button>
          ) : (
            <Button onClick={createLocation} type="primary">
              Add location
            </Button>
          )}
        </FormItem>
      </Form>
    </Card>
  );
};

export default LocationForm;
