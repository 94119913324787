import gql from "graphql-tag";

const GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS = gql`
  query CampaignRequestorAdvertisers(
    $first: Int
    $skip: Int
    $where: OrgWhereInput
    $orderBy: OrgOrderByInput
  ) {
    orgs(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      name
      cpm
    }
  }
`;

export default GET_CAMPAIGN_ORDER_REQUESTOR_ADVERTISERS;
