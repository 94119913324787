import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";

const SessionsChart = ({ data }) => {
  const scale = {
    date: {
      tickCount: 4,
    },
    value: {
      min: 0,
    },
  };

  return (
    <Chart
      height={300}
      data={data}
      scale={scale}
      padding={[10, 20, 50, 40]}
      forceFit
    >
      <Axis
        name="date"
        label={{
          formatter: text => text.slice(5), // Remove year from date
        }}
      />
      <Axis name="value" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom
        type="line"
        position="date*value"
        size={2}
        color="#1890ff"
        shape="smooth"
      />
    </Chart>
  );
};

export default SessionsChart;
