import React, { Component } from "react";
import { message } from "antd";

class Controller extends Component {
  state = {
    selectedRowKeys: [],
    selectedRows: [],
    modal: null,
    toggleSharing: false,
  };

  onRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.setState({ selectedRows });
  };

  onChange = (key, val) => {
    this.setState({ [key]: val });
  };

  onSubmitSharing = async ({ mode, data, batchOrgs }) => {
    message.loading(`Sharing in progress ...`);
    if (mode === "multipleSelection") {
      await Promise.all(
        data.map(async toSave => {
          let connectIds = [];
          let disconnectIds = [];
          const ids = toSave.displayValues;
          const thisSharedOrgIds =
            toSave.orgs.length > 0 ? toSave.orgs.map(s => s.id) : [];
          ids.forEach(id => {
            if (!thisSharedOrgIds.includes(id)) connectIds.push({ id });
          });
          thisSharedOrgIds.forEach(id => {
            if (!ids.includes(id)) disconnectIds.push({ id });
          });
          return await this.props.updateAudienceSharing({
            variables: {
              id: toSave.id,
              orgs: {
                connect: connectIds,
                disconnect: disconnectIds,
              },
            },
          });
        })
      );
    } else if (mode === "batchSelection") {
      await Promise.all(
        data.map(async toSave => {
          let connectIds = [];
          //let disconnectIds = [];
          const ids = batchOrgs;
          const thisSharedOrgIds =
            toSave.orgs.length > 0 ? toSave.orgs.map(s => s.id) : [];
          ids.forEach(id => {
            if (!thisSharedOrgIds.includes(id)) connectIds.push({ id });
          });
          // thisSharedOrgIds.forEach(id => {
          //   if (!ids.includes(id)) disconnectIds.push({ id });
          // });
          return await this.props.updateAudienceSharing({
            variables: {
              id: toSave.id,
              orgs: {
                connect: connectIds,
                //disconnect: disconnectIds,
              },
            },
          });
        })
      );
    }

    await this.props.refetch();
    message.success(`Sharing Complete`);
    this.setState({ toggleSharing: false });
    this.setState({ selectedRows: [], selectedRowKeys: [] });
  };

  onCancelSharing = () => {
    this.setState({ toggleSharing: false });
    this.setState({ toggleSharing: false });
    this.setState({ selectedRows: [], selectedRowKeys: [] });
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          onRowChange: this.onRowChange,
          onChange: this.onChange,
          onSubmitSharing: this.onSubmitSharing,
          onCancelSharing: this.onCancelSharing,
          ...this.state,
          ...this.props,
        })}
      </React.Fragment>
    );
  }
}

export default Controller;
