import React from "react";
//import { css, jsx } from "@emotion/react";
import { Row, Col, Card, Descriptions, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  formatCurrency2SigFig,
  formatNumber,
  formatNumber3SigFig,
} from "../../../../../core/utils/campaigns";
import PctDiff from "../components/PctDiff";

const RollUpDisplayView = ({
  loading,
  org,
  displayData,
  diffPcts,
  userPermission,
  currencyFormat,
}) => {
  if (!displayData && !loading) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Card title="Display">
        <Row gutter={[16, 16]}>
          <Col flex="auto">
            <Card
              loading={loading}
              title="Display"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Basic summary for Display / Programmatic advertising. Impressions, Clicks, CTR and Pacing. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {displayData && displayData.display && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label="Impressions">
                      {formatNumber(displayData.display.impressions)}
                      {diffPcts && (
                        <PctDiff value={diffPcts.display.impressions} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Clicks">
                      {formatNumber(displayData.display.clicks)}
                      {diffPcts && <PctDiff value={diffPcts.display.clicks} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="CTR">
                      {formatNumber3SigFig(displayData.display.ctr) + "%"}
                      {diffPcts && <PctDiff value={diffPcts.display.ctr} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Pace">
                      {formatNumber3SigFig(displayData.display.pace) || 0}%
                      {diffPcts && <PctDiff value={diffPcts.display.pace} />}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          <Col flex="auto">
            <Card
              loading={loading}
              title="Attribution"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Attribution summary for Display / Programmatic advertising. Transactions, Revenue, Spend and ROAS. (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {displayData && displayData.attribution && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    <Descriptions.Item label="Transactions">
                      {formatNumber(displayData.attribution.transactions)}
                      {diffPcts && (
                        <PctDiff value={diffPcts.attribution.transactions} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Revenue">
                      {formatCurrency2SigFig(
                        displayData.attribution.revenue,
                        currencyFormat
                      )}
                      {diffPcts && (
                        <PctDiff value={diffPcts.attribution.revenue} />
                      )}
                    </Descriptions.Item>
                    {userPermission && userPermission.priceVisible && (
                      <Descriptions.Item label="Spend">
                        {formatCurrency2SigFig(
                          displayData.attribution.spend,
                          currencyFormat
                        )}
                        {diffPcts && (
                          <PctDiff value={diffPcts.attribution.spend} />
                        )}
                      </Descriptions.Item>
                    )}
                    {userPermission && userPermission.priceVisible && (
                      <Descriptions.Item label="ROAS">
                        {formatNumber3SigFig(displayData.attribution.roas)}%
                        {diffPcts && (
                          <PctDiff value={diffPcts.attribution.roas} />
                        )}
                      </Descriptions.Item>
                    )}
                    {displayData.attribution &&
                      displayData.attribution.walkIns > 0 && (
                        <Descriptions.Item label="Walk-Ins">
                          {formatNumber(displayData.attribution.walkIns)}
                          {diffPcts && (
                            <PctDiff value={diffPcts.attribution.walkIns} />
                          )}
                        </Descriptions.Item>
                      )}
                    {displayData.attribution &&
                      displayData.attribution.signUps > 0 && (
                        <Descriptions.Item label="Sign Ups">
                          {formatNumber(displayData.attribution.signUps)}
                          {diffPcts && (
                            <PctDiff value={diffPcts.attribution.signUps} />
                          )}
                        </Descriptions.Item>
                      )}
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col>
          {/* <Col flex="auto">
            <Card
              loading={loading}
              title="Device Clicks"
              extra={
                <Tooltip
                  placement="topLeft"
                  title="Devices and Operating System data clicks summary (Default: Last 6 Months)"
                >
                  <QuestionCircleOutlined style={{ color: "lightgray" }} />
                </Tooltip>
              }
            >
              {displayData && displayData.deviceTypes && (
                <div>
                  <Descriptions
                    contentStyle={{ border: "0" }}
                    labelStyle={{ background: "none" }}
                    layout="horizontal"
                    bordered={true}
                    colon={false}
                    column={{ xs: 1, md: 1, sm: 1, lg: 1 }}
                  >
                    {displayData.deviceTypes.connectedTv.clicks && (
                      <Descriptions.Item label="Connected TV">
                        {formatNumber(
                          displayData.deviceTypes.connectedTv.clicks
                        )}
                        {diffPcts && diffPcts.deviceTypes && (
                          <PctDiff
                            value={diffPcts.deviceTypes.connectedTv.clicks}
                          />
                        )}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Mobile">
                      {formatNumber(displayData.deviceTypes.mobile.clicks)}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff value={diffPcts.deviceTypes.mobile.clicks} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Personal Computer">
                      {formatNumber(
                        displayData.deviceTypes.personalComputer.clicks
                      )}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff
                          value={diffPcts.deviceTypes.personalComputer.clicks}
                        />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="PC">
                      {formatNumber(displayData.deviceTypes.pc.clicks)}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff value={diffPcts.deviceTypes.pc.clicks} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {formatNumber(displayData.deviceTypes.phone.clicks)}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff value={diffPcts.deviceTypes.phone.clicks} />
                      )}
                    </Descriptions.Item>
                    {displayData.deviceTypes.other.clicks && (
                      <Descriptions.Item label="Other">
                        {formatNumber(displayData.deviceTypes.other.clicks)}
                        {diffPcts && diffPcts.deviceTypes && (
                          <PctDiff value={diffPcts.deviceTypes.other.clicks} />
                        )}
                      </Descriptions.Item>
                    )}
                    {displayData.deviceTypes.setTopBox.clicks && (
                      <Descriptions.Item label="Set Top Box">
                        {formatNumber(displayData.deviceTypes.setTopBox.clicks)}
                        {diffPcts && diffPcts.deviceTypes && (
                          <PctDiff
                            value={diffPcts.deviceTypes.setTopBox.clicks}
                          />
                        )}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Tablet">
                      {formatNumber(displayData.deviceTypes.tablet.clicks)}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff value={diffPcts.deviceTypes.tablet.clicks} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mobile Tablet">
                      {formatNumber(
                        displayData.deviceTypes.mobileTablet.clicks
                      )}
                      {diffPcts && diffPcts.deviceTypes && (
                        <PctDiff
                          value={diffPcts.deviceTypes.mobileTablet.clicks}
                        />
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Card>
          </Col> */}
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default RollUpDisplayView;
