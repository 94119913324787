import gql from "graphql-tag";

const ORG_SEO_UPDATE_SHARING_DETAILS = gql`
  mutation(
    $id: ID!
    $connect: [OrgWhereUniqueInput!]
    $disconnect: [OrgWhereUniqueInput!]
  ) {
    updateOrgSeoSummary(
      where: { id: $id }
      data: { sharedOrgs: { connect: $connect, disconnect: $disconnect } }
    ) {
      id
    }
  }
`;

export default ORG_SEO_UPDATE_SHARING_DETAILS;
