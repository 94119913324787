// ParentContext.js
import React, { createContext, useState, useContext } from "react";

const AudienceBuilderContext = createContext();

export const AudienceBuilderProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState([]);
  const [metricValue, setMetricValue] = useState(null);
  const [matchCharacteristics, setMatchCharacteristics] = useState(null);
  const [userSelections, setUserSelections] = useState([]);

  // Audience Table
  const [selectedDSP, setSelectedDSP] = useState(null);
  const [selectedAudiences, setSetSelectedAudiences] = useState([]);

  // Type Based Input
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [columnCount, setColumnCount] = useState([]);
  const [addAsNewCard, setAddAsNewCard] = useState(false);

  // Mobile
  const [activeTabKey, setActiveTabKey] = useState("1");

  return (
    <AudienceBuilderContext.Provider
      value={{
        inputValue,
        setInputValue,
        matchCharacteristics,
        setMatchCharacteristics,
        metricValue,
        setMetricValue,
        activeTabKey,
        setActiveTabKey,
        userSelections,
        setUserSelections,
        selectedAudiences,
        setSetSelectedAudiences,
        selectedDSP,
        setSelectedDSP,
        selectedCheckboxes,
        setSelectedCheckboxes,
        columnCount,
        setColumnCount,
        addAsNewCard,
        setAddAsNewCard,
      }}
    >
      {children}
    </AudienceBuilderContext.Provider>
  );
};

export const useAudienceBuilderContext = () => {
  const context = useContext(AudienceBuilderContext);
  if (!context) {
    throw new Error("useParentContext must be used within a ParentProvider");
  }
  return context;
};
