import gql from "graphql-tag";
const PAID_SEARCH_SHARING_DETAILS = gql`
  query($ids: [ID!]) {
    paidSearchSummaries(where: { id_in: $ids }) {
      id
      sharedOrgs {
        id
        name
      }
      org {
        id
        name
      }
    }
  }
`;

export default PAID_SEARCH_SHARING_DETAILS;
