import React from "react";
import { Card, Row, Col, Typography, Radio, Input } from "antd";
import { RiseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <RiseOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{titleText}</h4>
      </Col>
    </Row>
  );
};

const styles = {
  options: {
    border: "1px solid #ebebeb",
    borderRadius: "4px",
    marginBottom: "15px",
    width: "100%",
  },
  titleRow: {
    marginBottom: "10px",
  },
  secondaryText: {
    fontSize: "10px",
    marginLeft: "23px",
  },
  goalSecondaryText: {
    fontSize: "10px",
  },
  goalRow: {
    width: "75%",
    marginBottom: "15px",
  },
  inputStyle: {
    width: "120px",
  },
};

const goalDescriptionText = {
  cpm:
    "The CPM (Cost Per Mille) goal is a " +
    "guideline for how much your campaign " +
    "should spend maximum for every 1,000 displayed adds.",
  cpc:
    "The CPC (Cost Per Click) goal is a guidline " +
    "for how much your campaign should spend per click.",
  cpa:
    "The CPA (Cost Per Acquisition) goal is a guidline for " +
    "how much your campaign should spend per acquisition.",
};

const OptimizationTypeCard = ({
  kpi,
  budget,
  kpiGoal,
  onChange,
  border,
  tablet,
}) => {
  const optimizationType = kpi;
  const onRadioSelect = e => {
    const target = e.target.value;
    if (target === "CPM") onChange("kpi", "CPM");
    if (target === "CPC") onChange("kpi", "CPC");
    if (target === "CPA") onChange("kpi", "CPA");
  };
  return (
    <Card bordered={border} title={<Title titleText="Optimization Type" />}>
      <Row style={styles.titleRow}>
        <Col span={24}>
          <Text>How would you like to optimize your campaign budget?</Text>
          <br />
          <Text type="secondary">Choose from the 3 options below.</Text>
        </Col>
      </Row>
      <Radio.Group
        value={kpi}
        style={{ width: "100%" }}
        onChange={onRadioSelect}
      >
        <Row
          style={{
            ...styles.options,
            borderColor: kpi === "CPM" ? "#0078fe" : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="CPM">
              <Text>Cost Per Mille</Text>
              <br />
              <Text type="secondary" style={styles.secondaryText}>
                Optimize your budget to show as many ads as possible. Priced per
                1,000 views.
              </Text>
            </Radio>
          </Col>
        </Row>
        <Row
          style={{
            ...styles.options,
            borderColor: kpi === "CPC" ? "#0078fe" : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="CPC">
              <Text>Cost Per Click</Text>
              <br />
              <Text type="secondary" style={styles.secondaryText}>
                Optimize your budget to get as many clicks as possible.
              </Text>
            </Radio>
          </Col>
        </Row>
        <Row
          style={{
            ...styles.options,
            borderColor: kpi === "CPA" ? "#0078fe" : "#ebebeb",
          }}
        >
          <Col span={24}>
            <Radio style={{ width: "100%", padding: "15px" }} value="CPA">
              <Text>Cost Per Acquisition</Text>
              <br />
              <Text type="secondary" style={styles.secondaryText}>
                Optimize your budget to show as many conversions possible.
                Requires additional tracking.
              </Text>
            </Radio>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Card
              bodyStyle={
                tablet ? { minHeight: "236px" } : { minHeight: "194px" }
              }
            >
              <Text>{optimizationType} Goal</Text>
              <br />
              <Row style={styles.goalRow}>
                <Text type="secondary" style={styles.goalSecondaryText}>
                  {optimizationType === "CPM"
                    ? goalDescriptionText.cpm
                    : optimizationType === "CPC"
                    ? goalDescriptionText.cpc
                    : goalDescriptionText.cpa}
                </Text>
              </Row>
              <Row style={styles.goalRow}>
                <Input
                  style={styles.inputStyle}
                  addonBefore="$USD"
                  value={kpiGoal}
                  onChange={e => {
                    const { value } = e.target;
                    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                    if (
                      (!Number.isNaN(value) && reg.test(value)) ||
                      value === "" ||
                      value === "."
                    ) {
                      onChange("kpiGoal", value);
                    }
                  }}
                />
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              bodyStyle={
                tablet ? { minHeight: "194px" } : { maxHeight: "194px" }
              }
            >
              <Text>Daily Budget</Text>
              <br />
              <Row style={styles.goalRow}>
                <Text type="secondary" style={styles.goalSecondaryText}>
                  The amount you set as a daily budget will be the maximum
                  amount the campaign spends each day on average. Some days the
                  campaign will spend less.
                </Text>
              </Row>
              <Row style={styles.goalRow}>
                <Input
                  style={styles.inputStyle}
                  addonBefore="$USD"
                  value={budget}
                  onChange={e => {
                    const { value } = e.target;
                    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                    if (
                      (!Number.isNaN(value) && reg.test(value)) ||
                      value === "" ||
                      value === "."
                    ) {
                      onChange("budget", value);
                    }
                  }}
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </Radio.Group>
    </Card>
  );
};

export default OptimizationTypeCard;
