import React, { Component } from "react";
import { Storage } from "aws-amplify";
import {
  isEmpty,
  isArray,
  flatten,
  pullAt,
  cloneDeep,
  capitalize,
} from "lodash";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";
import { diff } from "../../../utils/general";

class Controller extends Component {
  state = {
    tablet: window.matchMedia("(max-width: 1100px)").matches,
    id: this.props.campaignOrder.id,
    status: this.props.campaignOrder.status,
    showModal: false,
    showEditModal: false,
    whichModal: "",
    // Step: Basics (top to bottom)
    campaignName: this.props.campaignOrder.name,
    brands: this.props.campaignOrder.brands,
    category: this.props.campaignOrder.category,
    isRegulated: this.props.campaignOrder.isRegulated,
    startDate: this.props.campaignOrder.startDate,
    endDate: this.props.campaignOrder.endDate,
    age: this.props.campaignOrder.age,
    language: this.props.campaignOrder.language,
    income: this.props.campaignOrder.income,
    segments: this.props.campaignOrder.segments,
    devices: this.props.campaignOrder.devices,
    tags: this.props.campaignOrder.tags,
    // Step: Details (top to bottom)
    objectiveNewCustomers: this.props.campaignOrder.objectiveNewCustomers,
    objectiveExistingCustomers: this.props.campaignOrder
      .objectiveExistingCustomers,
    retargetDayCap: this.props.campaignOrder.retargetDayCap,
    retargetTotalCap: this.props.campaignOrder.retargetTotalCap,
    kpi: this.props.campaignOrder.kpi,
    kpiGoal: this.props.campaignOrder.kpiGoal,
    budget: this.props.campaignOrder.budget,
    // Step: GeoTargeting (could be a circle, polygon, or string address ie. "California, USA")
    geoTargets: this.props.campaignOrder.geoTargets,
    selectedGeoTargets: [],
    confirmLocationsTableData: [],
    selectedLocations: [],
    // Attribution Targets
    attributionTargets: this.props.campaignOrder.attributionTargets,
    selectedAttributionTargets: [],
    // Step: Creatives
    adUnits: isEmpty(this.props.campaignOrder.adUnits)
      ? [
          {
            banners: [],
            clickthrough: null,
            name: null,
            selectedRows: [],
          },
        ]
      : this.props.campaignOrder.adUnits,
  };

  componentDidUpdate(prevProps) {
    if (this.props.campaignOrder !== prevProps.campaignOrder) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({
      id: this.props.campaignOrder.id,
      status: this.props.campaignOrder.status,
      campaignName: this.props.campaignOrder.name,
      brands: this.props.campaignOrder.brands,
      category: this.props.campaignOrder.category,
      isRegulated: this.props.campaignOrder.isRegulated,
      startDate: this.props.campaignOrder.startDate,
      endDate: this.props.campaignOrder.endDate,
      age: this.props.campaignOrder.age,
      language: this.props.campaignOrder.language,
      income: this.props.campaignOrder.income,
      segments: this.props.campaignOrder.segments,
      devices: this.props.campaignOrder.devices,
      tags: this.props.campaignOrder.tags,
      // Step: Details (top to bottom)
      objectiveNewCustomers: this.props.campaignOrder.objectiveNewCustomers,
      objectiveExistingCustomers: this.props.campaignOrder
        .objectiveExistingCustomers,
      retargetDayCap: this.props.campaignOrder.retargetDayCap,
      retargetTotalCap: this.props.campaignOrder.retargetTotalCap,
      kpi: this.props.campaignOrder.kpi,
      kpiGoal: this.props.campaignOrder.kpiGoal,
      budget: this.props.campaignOrder.budget,
      // Step: GeoTargeting (could be a circle, polygon, or string address ie. "California, USA")
      geoTargets: this.props.campaignOrder.geoTargets,
      // Step: Creatives
      adUnits: isEmpty(this.props.campaignOrder.adUnits)
        ? [
            {
              banners: [],
              clickthrough: null,
              name: null,
              selectedRows: [],
            },
          ]
        : this.props.campaignOrder.adUnits,
    });
  };

  addGeoTarget = async (geoTargets, isAttribution) => {
    const targets = await Promise.all(
      geoTargets.map(async target => {
        return await this.props.createGeoTarget({
          variables: {
            addresses: target.addresses,
            circles: !isArray(target.circles)
              ? {
                  address: target.circles.address,
                  radius: Math.round(target.circles.radius),
                  coordinate: {
                    create: {
                      lat: target.circles.coordinate.lat,
                      lng: target.circles.coordinate.lng,
                    },
                  },
                }
              : target.circles.map(circle => {
                  return {
                    address: circle.address,
                    radius: Math.round(circle.radius),
                    coordinate: {
                      create: {
                        lat: circle.coordinate.lat,
                        lng: circle.coordinate.lng,
                      },
                    },
                  };
                }),
            polygons: target.polygons.map(polygon => {
              return {
                coordinates: {
                  create: polygon.coordinates,
                },
              };
            }),
          },
        });
      })
    );

    const newGeoTargets = targets.map(target => {
      return target.data.createGeoTarget;
    });
    if (!isEmpty(newGeoTargets)) {
      isAttribution
        ? this.setState({
            attributionTargets: [
              ...this.state.attributionTargets,
              ...newGeoTargets,
            ],
          })
        : this.setState({
            geoTargets: [...this.state.geoTargets, ...newGeoTargets],
          });
    } else console.log("error");
  };

  onCheckBoxSelect = (value, array, state, checked) => {
    let returnArray = array.slice();
    if (checked) {
      returnArray.push(value);
    }
    if (!checked) {
      returnArray.splice(
        Array.findIndex(device => device === value),
        1
      );
    }
    this.setState({
      [state]: returnArray,
    });
  };

  handleChangeRadius = async (value, index) => {
    const updatedGeoTarget = cloneDeep(this.state.geoTargets[index]);
    const update = await this.props.updateGeoTarget({
      variables: {
        id: updatedGeoTarget.id,
        circles: updatedGeoTarget.circles.map(circle => {
          return {
            where: {
              id: circle.id,
            },
            data: {
              radius: Math.round(value * 1609.34),
            },
          };
        }),
      },
    });
    if (update) {
      updatedGeoTarget.circles = updatedGeoTarget.circles.map(circle => {
        return { ...circle, radius: Math.round(value * 1609.34) };
      });
      const newState = cloneDeep(this.state.geoTargets);
      newState.splice(index, 1, updatedGeoTarget);
      this.setState({ geoTargets: newState });
    }
  };

  removeGeoTargets = async () => {
    const newState = cloneDeep(this.state.geoTargets);
    const indicesToRemove = this.state.selectedGeoTargets.map(
      geoTarget => geoTarget.key
    );
    const removedGeoTargets = pullAt(newState, indicesToRemove);

    removedGeoTargets.forEach(geoTarget => {
      this.props.deleteGeoTarget({
        variables: {
          id: geoTarget.id,
        },
      });
    });
    this.setState({ geoTargets: newState, selectedGeoTargets: [] });
  };

  removeAttributionTargets = async () => {
    const newState = cloneDeep(this.state.attributionTargets);
    const indicesToRemove = this.state.selectedAttributionTargets.map(
      geoTarget => geoTarget.key
    );

    const removedGeoTargets = pullAt(newState, indicesToRemove);

    removedGeoTargets.forEach(geoTarget => {
      this.props.deleteGeoTarget({
        variables: {
          id: geoTarget.id,
        },
      });
    });
    this.setState({
      attributionTargets: newState,
      selectedAttributionTargets: [],
    });
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onChangeArrayOfObj = (key, index, innerkey, value) => {
    if (!!this.state[key] && this.state[key].constructor === Array) {
      const nextState = cloneDeep(this.state[key]);
      nextState[index][innerkey] = value;
      this.setState({
        [key]: nextState,
      });
    } else throw new Error("Incorrect usage, targeted state is not an array");
  };

  addAdUnit = () => {
    const nextState = this.state.adUnits;
    nextState.push({
      banners: [],
      clickthrough: null,
      name: null,
    });
    this.setState({ adUnits: nextState });
  };

  removeAdUnit = async index => {
    const slicedUnits = cloneDeep(this.state.adUnits);
    const removedAdUnit = slicedUnits.splice(index, 1);
    const remove = isEmpty(removedAdUnit[0].id)
      ? true
      : await this.props.deleteAdUnit({
          variables: {
            id: this.state.id,
            adUnitId: removedAdUnit[0].id,
          },
        });
    if (remove) this.setState({ adUnits: slicedUnits });
  };

  removeBanners = index => {
    const newState = this.state.adUnits.slice();
    const removedBanners = pullAt(
      newState[index].banners,
      newState[index].selectedRows.map(row => row.key)
    );
    this.setState({ adUnits: newState });
    // Clean removed images out of DB & S3
    removedBanners.forEach(async banner => {
      const remove = await Storage.remove(banner.key);
      if (remove) this.props.deleteMedia({ variables: { id: banner.id } });
    });
  };

  onSlider = (newCustomers, existingCustomers) => {
    this.setState({
      objectiveNewCustomers: newCustomers,
      objectiveExistingCustomers: existingCustomers,
    });
  };

  onCsvUploadComplete = async parsedData => {
    const geoCodedAddresses = await Promise.all(
      parsedData.map(async (location, index) => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const LOCATION_NAME = location["Addresses"];
            const basicString =
              "https://maps.googleapis.com/maps/api/geocode/json";
            const input = !isEmpty(LOCATION_NAME)
              ? LOCATION_NAME.split(" ").join("+")
              : "123+bla+bla";
            const fullInputString = `?address=${input}`;
            const request = `${basicString}${fullInputString}&key=${googleMapsConfig.apiKey}`;
            return fetch(request)
              .then(res => res.json())
              .then(result => {
                if (result.status === "OK") {
                  resolve([result.results[0]]);
                } else {
                  resolve([]);
                }
              });
          }, 60 * index);
        });
      })
    );
    let autoSelectedLocations = [];
    const tableData = flatten(
      geoCodedAddresses.map((result, index) => {
        const { LOCATION_NAME: givenAddress } = parsedData[index];
        return !isEmpty(result)
          ? result.map(address => {
              const { formatted_address } = address;
              return {
                key: index,
                isMatch: address.partial_match ? false : true,
                sourceAddress: givenAddress,
                foundAddress: formatted_address,
                lat: address.geometry.location.lat,
                lng: address.geometry.location.lng,
                isPartial: address.partial_match ? true : false,
              };
            })
          : {
              key: index,
              sourceAddress: !isEmpty(givenAddress)
                ? givenAddress
                : "No address was given...",
              notFound: true,
            };
      })
    );

    tableData.forEach(address => {
      if (address.isMatch) {
        autoSelectedLocations.push(address);
      }
    });
    const tableDataSorted = () => {
      //Using filter to move falsy isMatch to the top of list while maintaining thier original order.
      const noMatch = tableData.filter(el => !el.isMatch);
      const match = tableData.filter(el => el.isMatch);
      // Then concat the falsy array before the truthy
      const sortedData = noMatch.concat(match);
      return sortedData;
    };
    this.setState({
      showModal: true,
      confirmLocationsTableData: tableDataSorted(),
      selectedLocations: autoSelectedLocations,
    });
  };

  onConfirmLocationOk = () => {
    const { selectedLocations } = this.state;
    const geoTargets = selectedLocations.map(address => {
      return {
        addresses: [address.foundAddress],
        circles: {
          radius: address.radius ? address.radius * 1609.34 : 1609.34,
          address: address.foundAddress,
          coordinate: {
            lat: address.lat,
            lng: address.lng,
          },
        },
        polygons: [],
      };
    });

    this.addGeoTarget(geoTargets, false);
    this.setState({
      selectedLocations: [],
      confirmLocationsTableData: [],
      showModal: false,
    });
  };

  onConfirmAttributionTargetOk = () => {
    const { selectedLocations } = this.state;
    const geoTargets = selectedLocations.map(address => {
      return {
        addresses: [address.foundAddress],
        circles: {
          radius: address.radius ? address.radius * 1609.34 : 1609.34,
          address: address.foundAddress,
          coordinate: {
            lat: address.lat,
            lng: address.lng,
          },
        },
        polygons: [],
      };
    });

    this.addGeoTarget(geoTargets, true);
    this.setState({
      selectedLocations: [],
      confirmLocationsTableData: [],
      showModal: false,
    });
  };

  handleComplete = async whichModal => {
    const {
      id,
      status,
      campaignName,
      language,
      brands,
      category,
      age,
      income,
      segments,
      tags,
      devices,
      objectiveNewCustomers,
      objectiveExistingCustomers,
      retargetDayCap,
      retargetTotalCap,
      kpi,
      kpiGoal,
      budget,
      startDate,
      endDate,
      geoTargets,
      attributionTargets,
      adUnits,
      isRegulated,
    } = this.state;
    // Diff the changes
    const previousCampaignOrder = this.props.campaignOrder;
    const difference = diff(
      {
        id: previousCampaignOrder.id,
        status: previousCampaignOrder.status,
        campaignName: previousCampaignOrder.name,
        language: previousCampaignOrder.language,
        brands: previousCampaignOrder.brands,
        category: previousCampaignOrder.category,
        isRegulated: previousCampaignOrder.isRegulated,
        age: previousCampaignOrder.age,
        income: previousCampaignOrder.income,
        segments: previousCampaignOrder.segments,
        tags: previousCampaignOrder.tags,
        devices: previousCampaignOrder.devices,
        objectiveNewCustomers: previousCampaignOrder.objectiveNewCustomers,
        objectiveExistingCustomers:
          previousCampaignOrder.objectiveExistingCustomers,
        retargetDayCap: previousCampaignOrder.retargetDayCap,
        retargetTotalCap: previousCampaignOrder.retargetTotalCap,
        kpi: previousCampaignOrder.kpi,
        kpiGoal: previousCampaignOrder.kpiGoal,
        budget: previousCampaignOrder.budget,
        startDate: previousCampaignOrder.startDate,
        endDate: previousCampaignOrder.endDate,
        geoTargets: previousCampaignOrder.geoTargets,
        attributionTargets: previousCampaignOrder.attributionTargets,
        adUnits: previousCampaignOrder.adUnits.map(adUnit => {
          return {
            banners: adUnit.banners,
            clickthrough: adUnit.clickthrough,
            name: adUnit.name,
          };
        }),
      },
      {
        id,
        status,
        campaignName,
        language,
        brands,
        category,
        isRegulated,
        age,
        income,
        segments,
        tags,
        devices,
        objectiveNewCustomers,
        objectiveExistingCustomers,
        retargetDayCap,
        retargetTotalCap,
        kpi,
        kpiGoal,
        budget,
        startDate,
        endDate,
        geoTargets,
        attributionTargets,
        adUnits: adUnits.map(adUnit => {
          return {
            banners: adUnit.banners,
            clickthrough: adUnit.clickthrough,
            name: adUnit.name,
          };
        }),
      }
    );
    // Update the CampaignOrder
    const update = await this.props.updateCampaignOrder({
      variables: {
        id,
        status,
        geoTargets: geoTargets.map(geoTarget => {
          return { id: geoTarget.id };
        }),
        attributionTargets: attributionTargets.map(attribution => {
          return { id: attribution.id };
        }),
        previousAdUnits: adUnits.reduce((acc, adUnit) => {
          if (adUnit.id) {
            const newAcc = acc.slice();
            newAcc.push({ id: adUnit.id });
            return newAcc;
          }
          return null;
        }, []),
        adUnits: adUnits.map(adUnit => {
          return {
            clickthrough: adUnit.clickthrough ? adUnit.clickthrough : "",
            name: adUnit.name ? adUnit.name : "",
            banners: {
              connect: adUnit.banners.map(media => {
                return { id: media.id };
              }),
            },
          };
        }),
        campaignName,
        language: language.map(l => l.toUpperCase()),
        devices,
        objectiveNewCustomers,
        objectiveExistingCustomers,
        retargetDayCap,
        retargetTotalCap,
        kpi,
        kpiGoal: +kpiGoal,
        budget: +budget,
        startDate,
        endDate,
        brands,
        category,
        isRegulated,
        age,
        income,
        segments,
        tags,
      },
    });
    // Add changelog event
    if (
      update &&
      (!isEmpty(difference.different) ||
        !isEmpty(difference.missing_from_first) ||
        !isEmpty(difference.missing_from_second))
    ) {
      const description = `${
        isEmpty(difference.different)
          ? ""
          : `\nChanged:\n${difference.different
              .map(
                diff =>
                  `${diff
                    .split(".")
                    .map(word =>
                      isNaN(Number(word))
                        ? capitalize(word)
                        : `#${Number(word) + 1}`
                    )
                    .join(" ")}`
              )
              .join(",\n")}`
      }${
        isEmpty(difference.missing_from_first)
          ? ""
          : `\nAdded:\n${difference.missing_from_first
              .map(
                diff =>
                  `${diff
                    .split(".")
                    .map(word =>
                      isNaN(Number(word))
                        ? capitalize(word)
                        : `#${Number(word) + 1}`
                    )
                    .join(" ")}`
              )
              .join(",\n")}`
      }${
        isEmpty(difference.missing_from_second)
          ? ""
          : `\nRemoved:\n${difference.missing_from_second
              .map(
                diff =>
                  `${diff
                    .split(".")
                    .map(word =>
                      isNaN(Number(word))
                        ? capitalize(word)
                        : `#${Number(word) + 1}`
                    )
                    .join(" ")}`
              )
              .join(",\n")}`
      }`;

      this.props.updateCampaignOrderChangelog({
        variables: {
          id,
          description,
          userId: this.props.currentUser.id,
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          removeAttributionTargets: this.removeAttributionTargets,
          onConfirmAttributionTargetOk: this.onConfirmAttributionTargetOk,
          addGeoTarget: this.addGeoTarget,
          onCheckBoxSelect: this.onCheckBoxSelect,
          handleChangeRadius: this.handleChangeRadius,
          removeGeoTargets: this.removeGeoTargets,
          onChange: this.onChange,
          onChangeArrayOfObj: this.onChangeArrayOfObj,
          onSlider: this.onSlider,
          addMedia: this.addMedia,
          onCsvUploadComplete: this.onCsvUploadComplete,
          onConfirmLocationOk: this.onConfirmLocationOk,
          addAdUnit: this.addAdUnit,
          removeAdUnit: this.removeAdUnit,
          removeBanners: this.removeBanners,
          handleComplete: this.handleComplete,
          ...this.state,
          ...this.props,
        })}
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper(googleMapsConfig)(Controller);
