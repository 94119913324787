export function getImageType(value) {
  const imageType = value.split("/")[1];

  if (!imageType) return "";

  switch (imageType) {
    case "png":
      return "PNG";
    case "jpg":
      return "JPG";
    case "jpeg":
      return "JPEG";
    case "gif":
      return "GIF";
    case "avif":
      return "AVIF";
    default:
      return imageType.toUpperCase();
  }
}
