export const layoutRowSpacing = {
  marginBottom: "2%",
};
export const layoutRowBorder = {
  backgroundColor: "#fff",
  border: "1px solid #e8e8e8",
};
export const cardStyle = {
  margin: 5,
  height: 500,
  backgroundColor: "#fff",
  padding: 5,
};
export const cardStyleNoHeight = {
  margin: 5,
  backgroundColor: "#fff",
  padding: 5,
};
export const cardStyleRight = {
  margin: 5,
  height: 555,
  backgroundColor: "#fff",
  padding: 5,
};
export const cardStyleFooter = {
  margin: 5,
  height: 50,
  backgroundColor: "#fff",
  padding: 5,
};

export const bottomCardStyle = {
  margin: 5,
  height: 800,
  backgroundColor: "#fff",
  padding: 5,
};
export const bottomCardTopRight = {
  margin: 5,
  height: 300,
  backgroundColor: "#fff",
  padding: 5,
};
export const bottomCardBottomRight = {
  margin: 5,
  minHeight: 490,
  backgroundColor: "#fff",
  padding: 5,
};
export const container = {
  borderRight: "0.8px solid #e8e8e8",
  height: "100%",
  width: "100%",
  position: "absolute",
};
export const divider = {
  margin: "0 24px",
  padding: "0",
};
export const logo = {
  marginBottom: "40px",
};
export const subMenu = {
  color: "rgba(255, 255, 255, 0.65)",
  textDecoration: "none",
};
export const itemNotSelected = {
  backgroundColor: "#001529",
};
export const spanNotSelected = {
  color: "white",
};
export const blackSpanNotSelected = {
  color: "rgba(0, 0, 0, 0.65)",
};
export const tableHeader = {
  borderRight: "0.8px solid #e8e8e8",
  borderLeft: "0.8px solid #e8e8e8",
  backgroundColor: "#FAFAFA",
};

export const cardMargin = {
  marginTop: "8px",
  marginBottom: "8px",
};

export const cardStyleHeight = {
  height: "100px",
};

export const colPadding = {
  paddingLeft: "15px",
  paddingRight: "15px",
};

export const title = {
  marginTop: -20,
  textAlign: "center",
  fontSize: 14,
  fontWeight: "bold",
};

export const header = {
  textAlign: "center",
  fontSize: 16,
  fontStyle: "italic",
  fontWeight: "bold",
};

export const label = {
  textAlign: "center",
  fontSize: 14,
};

export const content = {
  textAlign: "center",
  fontSize: 20,
  fontWeight: "bold",
};

export const btn = {
  marginTop: "20px",
};

export const btnS = {
  fontSize: "14px",
  width: "80px",
  margin: "10px",
  padding: "0 10px",
  height: "36px",
};

export const cardBody = {
  padding: "8px",
};

export const cardData = {
  textAlign: "right",
  marginTop: -10,
  fontSize: "20pt",
};

export const mainTitleIcon = {
  marginRight: "10px",
  color: "lightgrey",
  fontSize: 20,
};

export const search = {
  fontSize: "14px",
  width: "180px",
  height: "36px",
  margin: "12px 10px 12px 0",
};

export const mainTable = {
  backgroundColor: "#fff",
  border: "1px solid #e8e8e8",
};

export const primaryTextStyle = {
  fontWeight: 700,
  fontFamily: "roboto",
  fontSize: "105%",
  color: "rgb(99, 99, 99)",
};
