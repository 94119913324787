import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import "../../CampaignRequestor/styles.css";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CampaignOrderQueueDealIdsDialog from "./CampaignOrderQueueDealIdsDialog";
import { sspPartners } from "../../../../core/utils/constants/constants";

const { Text } = Typography;

export const CampaignOrderQueueReviewLaunchSummary = () => {
  const [showDealIdsDialog, setShowDealIdsDialog] = useState(false);
  const [sspOtions, setSspOptions] = useState([]);
  const [showDealIdsLookup, setShowDealIdsLookup] = useState(false);
  const {
    updateCampaignOrder,
    loadingUpdateCampaignOrder,
    currentCampaignOrder,
  } = useCampaignRequestorContext();

  const history = useHistory();
  const [form] = Form.useForm();

  const handleRemoveDealId = data => {
    const dealIds = form.getFieldValue("dealIds");
    const newDealIds = dealIds.filter(d => d.id !== data.id);
    form.setFieldsValue({ dealIds: newDealIds });
  };

  const handleLaunch = async () => {
    // Validate form
    try {
      await form.validateFields();
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }

    try {
      const values = await form.validateFields();

      // Get selected SSP

      message.loading("Launching campaign...", 0);
      await updateCampaignOrder({
        variables: {
          where: { id: currentCampaignOrder.id },
          data: {
            dspConfig: {
              dspType: values.dspType,
              dealIds: values.dealIds,
              frequencyCap: values.frequencyCap,
              maxBidCPM: values.maxBidCPM,
              sspType: values.sspType,
            },
          },
        },
      });
      message.destroy();
      message.success("Campaign launched successfully");
      // Redirect to launched campaigns
      history.push("/campaigns/v2/orderqueue/launched");
    } catch (error) {
      message.destroy();
      message.error("Failed to launch campaign");
    }
  };

  useEffect(() => {
    if (currentCampaignOrder && currentCampaignOrder.dspConfig) {
      const dealIds = currentCampaignOrder.dspConfig.dealIds;
      if (dealIds && dealIds.length > 0) {
        setShowDealIdsLookup(true);
      }

      form.setFieldsValue({
        dspType: currentCampaignOrder.dspConfig.dspType,
        sspType: currentCampaignOrder.dspConfig.sspType,
        maxBidCPM: currentCampaignOrder.dspConfig.maxBidCPM,
        frequencyCap: currentCampaignOrder.dspConfig.frequencyCap,
        dealIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  useEffect(() => {
    setSspOptions(
      sspPartners.map(value => ({
        value,
        label: value,
      }))
    );
  }, []);
  return (
    <>
      <Card title="LAUNCH DETAILS">
        <Form form={form} layout="vertical">
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Campaign Status</Text>
            <Text type="secondary">
              {currentCampaignOrder.status === "APPROVED"
                ? "Pending Launch"
                : "Pending Update"}
            </Text>
          </Space>

          <Divider />
          <Space direction="vertical" size={2} style={{ width: "100%" }}>
            <Text>DSP</Text>
            <Text type="secondary">
              <small> Demand-Side Platform </small>
            </Text>
            <Form.Item
              name="dspType"
              rules={[
                {
                  required: true,
                  message: "Please select a DSP",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={[
                  { value: "NEXXEN", label: "NEXXEN" },
                  { value: "LIQUIDM", label: "LIQUIDM" },
                  { value: "JAMLOOP", label: "JAMLOOP" },
                ]}
              />
            </Form.Item>

            <Text>SSP</Text>
            <Text type="secondary">
              <small> Supply-Side Platform </small>
            </Text>
            <Form.Item name="sspType">
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={sspOtions}
              />
            </Form.Item>

            <Text>Maximum Bid</Text>
            <Text type="secondary">
              <small> Max bid per CPM </small>
            </Text>
            <Form.Item name="maxBidCPM">
              <Input
                addonBefore="$USD"
                type="number"
                style={{ width: "75%" }}
              />
            </Form.Item>

            <Text>Frequency Cap</Text>
            <Text type="secondary">
              <small> Max daily impressions per device </small>
            </Text>
            <Form.Item name="frequencyCap">
              <Input type="number" style={{ width: "75%" }} />
            </Form.Item>

            <Text>Deal IDs</Text>
            <Text type="secondary">
              <small> Optional: Find Deal IDs to use for this campaign </small>
            </Text>

            {showDealIdsLookup && (
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%", marginBottom: 4 }}
              >
                {form.getFieldValue("dealIds").map((data, index) => (
                  <Tag
                    closable
                    onClose={() => handleRemoveDealId(data)}
                    key={index}
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    className="deal-id-tag"
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "4px 8px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.name}
                      </span>
                      <span
                        style={{
                          fontSize: 12,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.dealId}
                      </span>
                    </span>
                  </Tag>
                ))}
              </Space>
            )}

            <Button
              style={{ width: "100%" }}
              onClick={() => setShowDealIdsDialog(true)}
            >
              Deal ID Lookup
            </Button>

            <Divider />

            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={loadingUpdateCampaignOrder}
              onClick={() => handleLaunch()}
            >
              Launch
            </Button>

            {/* <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => handleLaunch()}>
                    <span>Manual</span>
                  </Menu.Item>
                  <Menu.Item>
                    <span>Programmatic</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                style={{ width: "100%" }}
                type="primary"
                disabled={loadingUpdateCampaignOrder}
              >
                Launch
              </Button>
            </Dropdown> */}
          </Space>

          {/* Create hidden Input for dealIds */}
          <Form.Item name="dealIds">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Card>

      {showDealIdsDialog && (
        <CampaignOrderQueueDealIdsDialog
          visible={showDealIdsDialog}
          setVisible={setShowDealIdsDialog}
          selectedDealIds={form.getFieldValue("dealIds")}
          onAddSelections={dealIds => {
            form.setFieldsValue({ dealIds });
            setShowDealIdsLookup(true);
          }}
        />
      )}
    </>
  );
};
