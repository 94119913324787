import JSZip from "jszip";
import { saveAs } from "file-saver";
import { fetchAWSBlob } from "./fetchAWSBlob";

// Takes in creative data that contains a key into the AWS S3 bucket and downloads each image, zips, them up, and downloads the zip.
export const multiImageZipKey = async (name, keys) => {
  const zip = new JSZip();
  const folder = zip.folder(`${name}`);
  const failedDownloads = [];

  await Promise.all(
    keys.map(async (key, i) => {
      try {
        const blob = await fetchAWSBlob(key);
        folder.file(key, blob, { binary: true });
      } catch (error) {
        // Intentional console, will remove later
        console.info(`Error downloading ${key}:`, error);
        failedDownloads.push(key);
      }
    })
  );

  if (failedDownloads.length > 0) {
    // Inform the user about failed downloads
    return { success: false, failedDownloads };
  }

  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, `${name}`);
    },
    e => {
      // Intentional console, will remove later
      console.log("error", e);
    }
  );

  return { success: true, failedDownloads: 0 };
};
