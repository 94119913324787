import React from "react";
import AudienceCommerceLoader from "../../../../../../core/components/audiences/details/commerce/AudienceCommerceLoader";
import CommerceTab from "./CommerceTab";

const CommerceDetailsView = props => {
  return (
    <AudienceCommerceLoader {...props}>
      <CommerceTab />
    </AudienceCommerceLoader>
  );
};

export default CommerceDetailsView;
