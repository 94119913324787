import React from "react";
import { Card, Col, Row, Space, Typography } from "antd";

import "./styles.css";
import { CampaignRequestorWalkInTable } from "./CampaignRequestorWalkInTable";
import { CampaignRequestorKpiTable } from "./CampaignRequestorKpiTable";

const { Text } = Typography;

const CampaignRequestorReviewAttribution = ({ form }) => {
  const awarenessCampaign = form.getFieldValue("awarenessCampaign");

  return (
    <Card title="ATTRIBUTION REPORTING">
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Regulated Industry</Text>
              <Text>{awarenessCampaign ? "Yes" : "No"}</Text>
            </Space>
          </Col>
        </Row>

        <CampaignRequestorKpiTable form={form} />
        <CampaignRequestorWalkInTable form={form} />
      </Space>
    </Card>
  );
};

export default CampaignRequestorReviewAttribution;
