import React, { useState } from "react";
import {
  Popconfirm,
  message,
  Empty,
  Space,
  Modal,
  Input,
  Typography,
  notification,
  Tooltip,
  Button,
} from "antd";
import PaginationTable from "../../../../shared/PaginationTable";
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleTwoTone,
  FileSearchOutlined,
} from "@ant-design/icons";
import Loader from "../../../../shared/Loader";
import moment from "moment";

const ModalNotes = ({
  isModalOpen,
  isModal2Open,
  setIsModalOpen,
  setIsModal2Open,
  addLocationNotes,
  locationSelected,
  locationNotesView,
  setLocationNoteView,
}) => {
  const { TextArea } = Input;

  return (
    <>
      <Modal
        title="Add Notes"
        visible={isModalOpen}
        onOk={() => {
          addLocationNotes({
            variables: {
              id: locationSelected,
              notes: locationNotesView,
              hasNotes: true,
            },
          });
          setIsModalOpen(false);
          notification.open({
            message: "Creative remark submitted",
            description: "Success!",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <TextArea
          rows={4}
          placeholder="Add Notes"
          defaultValue={locationNotesView}
          value={locationNotesView}
          onChange={val => {
            setLocationNoteView(val.target.value);
          }}
        />
      </Modal>
      <Modal
        title="View Notes"
        visible={isModal2Open}
        onOk={() => {
          setIsModal2Open(false);
        }}
        onCancel={() => setIsModal2Open(false)}
      >
        <Typography>{locationNotesView}</Typography>
      </Modal>
    </>
  );
};

export default function TargetingLocationListView({
  deleteLocation,
  editLocation,
  onChangeStateValue,
  hideRowSelection,
  targetingLocationList,
  audienceType = "TARGETING",
  hiddenColumns,
  isAdmin,
  addLocationNotes,
  status,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [locationSelected, setLocationSelected] = useState("");
  const [locationNotesView, setLocationNoteView] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const handleDeleteLocation = async record => {
    setLoading(true);
    if (record.audienceType === "ADDRESS") {
      await deleteLocation(record);
    } else {
      await deleteLocation(record.id);
    }
    setLoading(false);
  };

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => (
        <Tooltip title={name}>
          <span>{name}</span>
        </Tooltip>
      ),
    },
    {
      title: "Address",
      dataIndex: "formattedAddress",
      key: "formattedAddress",
      render: (formattedAddress, record) => {
        // Check if the address is available and not null
        if (formattedAddress) {
          // Remove leading commas and spaces from the address
          const cleanedAddress = formattedAddress.trim().replace(/^, */, "");

          return record.geoframe === null ? (
            <>
              {" "}
              <Tooltip title="No Geo Target">{cleanedAddress}</Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={cleanedAddress}>{cleanedAddress}</Tooltip>
              {cleanedAddress}
            </>
          );
        } else {
          return null; // Handle the case where the address is null or undefined
        }
      },
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
      render: (lat, record) => lat,
    },
    {
      title: "Longitude",
      dataIndex: "lng",
      key: "lng",
      render: (lng, record) => lng,
    },
    {
      title: "Radius (in miles)",
      dataIndex: "radius",
      key: "radius",
      align: "right",
      render: (radius, record) => {
        if (record.geoframe && record.geoframe.circles.length) {
          const radius = record.geoframe.circles[0].radius * 1000;
          return radius.toLocaleString();
        }
        return radius ? radius.toLocaleString() : 0;
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
    {
      title: "Options",
      dataIndex: "option",
      key: "option",
      render: (button, record) => {
        return (
          <Space wrap>
            {/* Edit Location Button */}
            <Tooltip title="Edit Location">
              <EditOutlined
                onClick={() => editLocation(record)}
                style={{ color: "#4A4A4A", cursor: "pointer" }}
              />
            </Tooltip>

            {/* View Remarks Button */}
            {record.hasNotes === true && isAdmin === false && (
              <Tooltip title="View Remarks">
                <FileSearchOutlined
                  onClick={() => {
                    setLocationSelected(record.locationId);
                    setLocationNoteView(record.notes);
                    setIsModal2Open(true);
                  }}
                  style={{ color: "#4A4A4A", cursor: "pointer" }}
                />
              </Tooltip>
            )}

            {/* Delete Location Button */}
            <Tooltip title="Delete Location">
              <Popconfirm
                title="Are you sure you want to delete this Location?"
                onConfirm={() => {
                  handleDeleteLocation(record);
                }}
                onCancel={() => {
                  message.warning("Cancelled");
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  // Reject Column
  if (status === "PENDING") {
    columns.splice(columns.length - 1, 0, {
      title: "Reject",
      dataIndex: "reject",
      key: "reject",
      render: (button, record) => {
        if (record.hasNotes !== true && isAdmin === true) {
          return (
            <Button
              type="primary"
              onClick={() => {
                setLocationSelected(record.mediaId);
                setLocationNoteView(record.mediaNotes);
                setIsModalOpen(true);
              }}
            >
              Reject with Note
            </Button>
          );
        } else {
          return null;
        }
      },
    });
  }

  if (hiddenColumns) {
    columns = columns.filter(col => !hiddenColumns.includes(col.key));
  }

  const filteredLocations = targetingLocationList
    ? targetingLocationList.filter(
        location => location.audienceType === audienceType
      )
    : [];

  const table = (
    <div>
      {loading && <Loader />}
      <ModalNotes
        isModalOpen={isModalOpen}
        isModal2Open={isModal2Open}
        setIsModalOpen={setIsModalOpen}
        setIsModal2Open={setIsModal2Open}
        setLocationNoteView={setLocationNoteView}
        addLocationNotes={addLocationNotes}
        locationSelected={locationSelected}
        locationNotesView={locationNotesView}
      />
      <PaginationTable
        columns={columns}
        data={filteredLocations}
        bordered={false}
        rowSelection={
          !hideRowSelection
            ? {
                type: "checkbox",
                getCheckboxProps: record => ({
                  disabled: record.geoframe === null,
                }),
                onChange: (selectedRowKeys, selectedRows) => {
                  onChangeStateValue("selectedLocations", selectedRows);
                },
              }
            : null
        }
        paginationSize={"default"}
        scroll={{ x: 1200 }}
        size={"default"}
        total={filteredLocations && filteredLocations.length}
        current={currentPage}
        pageSize={pageSize}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          setPageSize(paginationObject.pageSize);
          setCurrentPage(paginationObject.current);
        }}
        rowKey="id"
      />
    </div>
  );

  return targetingLocationList && targetingLocationList.length ? (
    table
  ) : (
    <Empty />
  );
}
