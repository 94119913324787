function hextToRed(hex) {
  return parseInt(cutHex(hex).substring(0, 2), 16);
}

function hextToGreen(hex) {
  return parseInt(cutHex(hex).substring(2, 4), 16);
}

function hextToBlue(hex) {
  return parseInt(cutHex(hex).substring(4, 6), 16);
}

function cutHex(hex) {
  return hex.charAt(0) === "#" ? hex.substring(1, 7) : hex;
}

export function updatePrimaryColor(color) {
  document.documentElement.style.setProperty("--ant-primary-color", color);
  document.documentElement.style.setProperty("--primary-red", hextToRed(color));
  document.documentElement.style.setProperty(
    "--primary-green",
    hextToGreen(color)
  );
  document.documentElement.style.setProperty(
    "--primary-blue",
    hextToBlue(color)
  );
}

export function updateSidebarColor(color) {
  document.documentElement.style.setProperty("--mj-sidebar-color", color);
  document.documentElement.style.setProperty("--sidebar-red", hextToRed(color));
  document.documentElement.style.setProperty(
    "--sidebar-green",
    hextToGreen(color)
  );
  document.documentElement.style.setProperty(
    "--sidebar-blue",
    hextToBlue(color)
  );
}
