import gql from "graphql-tag";

const PAID_SEARCH_UPDATE_SHARING_DETAILS = gql`
  mutation(
    $id: ID!
    $connect: [OrgWhereUniqueInput!]
    $disconnect: [OrgWhereUniqueInput!]
  ) {
    updatePaidSearchSummary(
      where: { id: $id }
      data: { sharedOrgs: { connect: $connect, disconnect: $disconnect } }
    ) {
      id
    }
  }
`;

export default PAID_SEARCH_UPDATE_SHARING_DETAILS;
