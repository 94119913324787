import React from "react";
import { withRouter } from "react-router-dom";
// import data manipulating function
import {
  audiencesTableData,
  sharingRecordTableData,
  createCodeFromUserName,
  // isDataSharedAlready,
  parseDataCategories,
  getAllSegmentsFromSharingRecord,
} from "../../../utils/dataManipulating/audiences";

class Controller extends React.Component {
  state = {
    tablet: window.matchMedia("(max-width: 1100px)").matches,
    title: "Audiences",
    isSegmentSelected: false,
    isSharingRecordSelected: false,
    detailsCardData: [],
    selectedSharingRecord: [],
    selectedSegments: [],
    modalVisible: false,
    whichModal: "",
    selectedAction: "",
    //variables of information coming from the back-end api call. Clarification if name differs from payload returned from AppNexus.
    //memberId is member_id; audienceName is short_name; appNexusId is id;
    appNexusId: 0,
    audienceName: "",
    memberId: 10415, //memberId is the id associated with the provider of segments.  Eventually this needs to be gotten from the users org and not hard coded.
    priceCategories: [],
    priceCategory: 0,
    provider: "",
    description: "",
    price: 0,
    code: "",
    last_activity: "",
    recommend: false,
    status: false, //active or inactive
    shared: false,
    type: "Audience",
  };

  componentDidMount = () => {
    const { taxonomy } = this.props;
    this.setState({
      priceCategories: parseDataCategories(taxonomy),
    });
  };

  onAudiencesTableRowClick = record => {
    this.setState({
      detailsCardData: record,
      description: record.description,
      audienceName: record.audienceName,
    });
  };

  onAudiencesTableRowSelect = (record, selected) => {
    let segments = this.state.selectedSegments;
    if (selected) {
      segments.push({
        id: record.id,
      });
    }
    if (!selected) {
      segments.splice(segments.findIndex(id => id.id === record.id), 1);
    }

    this.setState({
      selectedSegments: segments,
      isSegmentSelected: segments.length === 0 ? false : true,
    });
  };

  onDeleteSegmentPopConfirmClick = () => {
    const { deleteSegment } = this.props;
    const { memberId, detailsCardData } = this.state;

    deleteSegment({
      variables: {
        memberId: memberId,
        segmentId: Number(detailsCardData.id),
      },
    });
  };

  onDeleteSharingRecordPopConfirmClick = () => {
    const { deleteSharingRecord } = this.props;
    const { selectedSharingRecord } = this.state;

    deleteSharingRecord({
      variables: {
        recordId: selectedSharingRecord.recordId,
      },
    });
  };

  onMapSegmentToPriceOk = () => {
    const { addMappingRecord } = this.props;
    const { status, type, priceCategory, shared } = this.state;
    const { id } = this.state.detailsCardData;

    addMappingRecord({
      variables: {
        segment_id: Number(id),
        active: status,
        data_segment_type_id: type,
        data_category_id: priceCategory,
        data_provider_id: 10415,
        isPublic: shared,
        memberId: 10415,
      },
    });
  };

  onSharingRecordRowClick = record => {
    this.setState({
      selectedSharingRecord: record,
      isSharingRecordSelected: true,
    });
  };

  onEditSegmentOk = () => {
    const { editSegment } = this.props;
    const { detailsCardData, audienceName, description } = this.state;

    editSegment({
      variables: {
        memberId: 10415,
        segmentId: Number(detailsCardData.id),
        short_name: audienceName,
        code: detailsCardData.code,
        price: 0,
        description: description,
      },
    });
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onCreateSegmentButtonClick = () => {
    this.setState({
      modalVisible: true,
      whichModal: "createSegment",
    });
  };

  onCreateSegmentClick = () => {
    const { createSegment } = this.props;
    const { audienceName, description } = this.state;

    createSegment({
      variables: {
        short_name: audienceName,
        code: createCodeFromUserName(audienceName),
        description: description,
        memberId: 10415,
        price: 0,
      },
    });
  };

  onRemoveSegmentFromSharingRecordPopConfirmOk = () => {
    const { selectedSharingRecord, selectedSegments } = this.state;
    const { sharingRecords, editSharingRecord } = this.props;

    const allSegments = getAllSegmentsFromSharingRecord(
      sharingRecords,
      selectedSharingRecord
    );
    let segments = [];
    allSegments.forEach(seg => segments.push({ id: seg.segment_id }));

    segments.splice(
      segments.findIndex(segment => segment.segment_id === selectedSegments.id),
      1
    );

    editSharingRecord({
      variables: {
        id: selectedSharingRecord.recordId,
        segment_id: segments,
      },
    });
  };

  onAddSharingRecordOrSegmentOk = whichAction => {
    const { memberId, selectedSharingRecord, selectedSegments } = this.state;
    const { addSharingRecordWithSegments, editSharingRecord } = this.props;
    const { recordId } = selectedSharingRecord;

    if (whichAction === "edit") {
      editSharingRecord({
        variables: {
          id: recordId,
          segment_id: selectedSegments,
        },
      });
    }
    if (whichAction === "create") {
      addSharingRecordWithSegments({
        variables: {
          buyer_member_id: memberId,
          segment_exposure: "list",
          segment_id: selectedSegments,
        },
      });
    }
  };

  render() {
    const {
      props,
      state,
      onAudiencesTableRowClick,
      onChange,
      onCreateSegment,
      onAddSharingRecordOrSegmentOk,
      onDeleteSegmentPopConfirmClick,
      onCreateSegmentClick,
      onCreateSegmentButtonClick,
      onEditSegmentOk,
      onDeleteSharingRecordPopConfirmClick,
      onSharingRecordRowClick,
      onMapSegmentToPriceOk,
      onAudiencesTableRowSelect,
      onRemoveSegmentFromSharingRecordPopConfirmOk,
    } = this;

    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          ...props,
          ...state,
          audiencesTableData,
          onAudiencesTableRowClick,
          onCreateSegment,
          onChange,
          onAddSharingRecordOrSegmentOk,
          sharingRecordTableData,
          onDeleteSegmentPopConfirmClick,
          onCreateSegmentClick,
          onCreateSegmentButtonClick,
          onEditSegmentOk,
          onDeleteSharingRecordPopConfirmClick,
          onSharingRecordRowClick,
          onMapSegmentToPriceOk,
          getAllSegmentsFromSharingRecord,
          onAudiencesTableRowSelect,
          onRemoveSegmentFromSharingRecordPopConfirmOk,
        })}
      </React.Fragment>
    );
  }
}

export default withRouter(Controller);
