import gql from "graphql-tag";

// TODO: This should happen on the backend however we need to refactor campaign launcher first to make this happen
const CAMPAIGN_ORDER_SLACK_NOTIFICATION = gql`
  mutation campaignOrderSlackNotification($id: ID!, $userId: ID!) {
    campaignOrderSlackNotification(data: { id: $id, userId: $userId })
  }
`;

export default CAMPAIGN_ORDER_SLACK_NOTIFICATION;
