import React, { useState } from "react";
import moment from "moment";
import { Card, Row, Select, Empty, Tabs } from "antd";
import _, { isEmpty, startCase } from "lodash";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import DatePickerField from "./components/DatePickerField";

// Global Styles
import {
  MenuItemTextColor,
  TabText,
} from "../../../../shared/globalStyling/styledText";

import { useByDateFilter } from "./hooks/ByDateFilter";

const Frag = React.Fragment;
const { Option } = Select;
const colStyle = {
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

const TabPane = Tabs.TabPane;

const SummaryChart = ({
  byDay,
  bySummaryRangeDate,
  setBySummaryRangeDate = () => {},
  reportData,
  showTransactions,
  showLocations,
  showKeywords,
  showPrices,
  defaultSummaryDates,
  defaultYValue = "clicks",
  defaultXValue = "impressions",
}) => {
  // map the chart and return the dates with total summary
  let validOptions = [
    "clicks",
    "impressions",
    "ctr",
    "spend",
    "cpc",
    "cpm",
    "revenue",
    "transactions",
    "items",
  ];

  const transactionOptions = [
    "conversions",
    "revenue",
    "roas",
    "conversionRate",
  ];
  const keywordsOptions = ["keywords"];
  const locationsOptions = ["locations"];

  const showPricesExclude = ["roas", "cpc", "spend"];

  reportData = reportData.sort(
    (a, b) => moment(a.xAxis).unix() - moment(b.xAxis).unix()
  );
  const {
    rangeKey,
    setRangeKey,
    filteredData,
    startDateEndDate,
    setCustomDateRange,
  } = useByDateFilter(reportData);

  if (showTransactions) {
    validOptions = validOptions.concat(transactionOptions);
  }

  if (showLocations) {
    validOptions = validOptions.concat(locationsOptions);
  }

  if (showKeywords) {
    validOptions = validOptions.concat(keywordsOptions);
  }

  if (!showPrices) {
    _.remove(validOptions, c => {
      return showPricesExclude.includes(c);
    });
  }

  const [leftY, setLeftY] = useState(startCase(defaultYValue));
  const [rightY, setRightY] = useState(startCase(defaultXValue));
  const DateSelector = () => {
    // map xAxis dates
    return (
      <>
        <Row>
          <DatePickerField
            setCustomDateRange={setCustomDateRange}
            data={filteredData}
            defaultPickerValue={startDateEndDate}
            defaultValue={startDateEndDate}
            defaultSummaryDates={defaultSummaryDates}
            value={
              isEmpty(bySummaryRangeDate)
                ? startDateEndDate
                : bySummaryRangeDate
            }
            onChange={v => {
              const dates = [moment(v.dates[0]), moment(v.dates[1])];
              setBySummaryRangeDate(dates);
            }}
            dropDownValue={rangeKey}
            setDropDownValue={setRangeKey}
            has6MonDisables={false}
          />
        </Row>
      </>
    );
  };

  const YAxisSelector = ({
    displayDateSelector,
    displayRightSelector,
    data,
  }) => {
    const keys = Object.keys(data[0])
      .filter(el => validOptions.includes(el))
      .map(option => {
        return option === "cpc" ||
          option === "cpm" ||
          option === "ctr" ||
          option === "ecpm"
          ? option.toUpperCase()
          : startCase(option);
      });
    return (
      <Row style={{ marginBottom: "2%" }} type="flex" justify="space-between">
        <>
          <Select
            onSelect={value => setLeftY(value)}
            style={{
              width: "10em",
            }}
            defaultValue={leftY}
          >
            {keys
              .filter(op => op !== rightY)
              .map(option => {
                return (
                  <Option key={option} value={option}>
                    <MenuItemTextColor text={option} color={"#7dafff"} />
                  </Option>
                );
              })}
          </Select>
          {displayDateSelector ? <DateSelector /> : null}
          {displayRightSelector ? (
            <Select
              onSelect={value => {
                setRightY(value);
              }}
              style={{ width: "10em" }}
              defaultValue={rightY}
            >
              {keys
                .filter(op => op !== leftY)
                .map(option => {
                  return (
                    <Option key={option} value={option}>
                      <MenuItemTextColor text={option} color={"#92d5a7"} />
                    </Option>
                  );
                })}
            </Select>
          ) : null}
        </>
      </Row>
    );
  };

  // if chartdata is equal to empty
  const emptyCharts = isEmpty(reportData);
  if (emptyCharts)
    return (
      <Card
        style={colStyle.container}
        bodyStyle={{ padding: 18 }}
        title={"Summary Chart"}
      >
        <Row type="flex" justify="center" align="middle">
          <DateSelector />
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Row>
      </Card>
    );

  return (
    <div>
      <Card
        style={colStyle.container}
        bodyStyle={{ padding: 18 }}
        title={"Summary Chart"}
      >
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
          onChange={activeKey => {
            if (activeKey === "4") setLeftY("Clicks");
            if (activeKey !== "4") {
              setLeftY("Impressions");
              setRightY("Transaction Count");
            }
          }}
        >
          {!isEmpty(reportData) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                <Frag>
                  <YAxisSelector
                    displayRightSelector={true}
                    displayDateSelector={true}
                    data={reportData}
                  />
                  <Row
                    style={{ marginBottom: "2%" }}
                    type="flex"
                    justify="center"
                  ></Row>
                  {filteredData.length && validOptions.length > 1 && (
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? defaultYValue || "" : leftY}
                      rightY={isEmpty(rightY) ? defaultXValue || "" : rightY}
                      dualChartData={filteredData}
                    />
                  )}
                </Frag>

                {(filteredData.length === 0 ||
                  (bySummaryRangeDate &&
                    bySummaryRangeDate.length > 0 &&
                    filteredData.length === 0)) && <Empty />}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDay) ? (
            <TabPane tab={<TabText text={"Day Of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDay.length === 0 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDay.length && (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={false}
                      data={byDay}
                    />
                    <Row
                      style={{ marginBottom: "2%" }}
                      type="flex"
                      justify="center"
                    ></Row>
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? defaultYValue : leftY}
                      rightY={isEmpty(rightY) ? defaultXValue : rightY}
                      dualChartData={byDay}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default SummaryChart;
