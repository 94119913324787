import gql from "graphql-tag";
const PAID_SEARCH_SUMMARY_ORG = gql`
  query paidSearchSummaryOrgs($id: ID!) {
    paidSearchSummary(where: { id: $id }) {
      id
      startDate
      endDate
      status
      failedChannel
      config {
        monthlyBudget
        processWithGoogleAds
        hasLocationRevenue
        locationsKey
        googleCustomerId
        googleViewId
        googleViewIdIsGa4
        bingAccountId
        bingCustomerId
        hasCommerce
        commerceTags {
          id
        }
      }
      overallBing {
        id
      }
      overallData {
        id
      }
      overallCommerce {
        id
      }
      org {
        id
        name
        dataConfig {
          googleCustomerId
          googleAnalytics {
            viewId
            isGA4
          }
          bing {
            accountId
            customerId
          }
        }
        eventsTarget {
          id
          name
        }
      }
    }
  }
`;

export default PAID_SEARCH_SUMMARY_ORG;
