import React from "react";
import { Row } from "antd";
//local
import GeoTargetsReviewList from "./GeoTargetsReviewList";

const Frag = React.Fragment;
const styles = {
  cardBody: {
    padding: 0,
  },
  cardRow: {
    padding: "15px",
  },
  secondaryRow: {
    padding: "15px 15px 15px 0",
  },
  textRow: {
    marginBottom: "10px",
  },
  title: {
    color: "#636363",
    margin: 0,
    fontWeight: "normal",
  },
  bannerNameText: {
    maxWidth: "90%",
  },
  bannerCard: {
    height: "150px",
    width: "150px",
  },
  banner: {
    height: "75px",
    width: "75px",
  },
  map: {
    position: "relative",
    height: "300px",
    width: "100%",
  },
  titleStyles: {
    iconStyle: {
      color: "#9b9b9b",
      fontSize: "22px",
    },
    textStyle: {
      color: "#9b9b9b",
    },
  },
};
const { cardRow } = styles;
const ReviewAttribution = ({ geoTargets }) => {
  return (
    <Frag>
      <Row style={cardRow}>
        <GeoTargetsReviewList geoTargets={geoTargets} />
      </Row>
    </Frag>
  );
};

export default ReviewAttribution;
