import React from "react";
import { useQuery } from "@apollo/react-hooks";
import PaidSearchAggregatedController from "./PaidSearchAggregatedController";

import PAID_SEARCH_AGGREGATED from "../../../../GraphQl/Queries/PAID_SEARCH_AGGREGATED";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";

const PaidSearchAggregatedLoader = ({ children, ...props }) => {
  const { data, loading: loadingAggregated, error: errorAggregated } = useQuery(
    PAID_SEARCH_AGGREGATED,
    {
      variables: {
        id: props.id,
      },
    }
  );

  if (errorAggregated) return console.log(errorAggregated);
  if (loadingAggregated) return <OverlaySpinner />;

  return (
    <PaidSearchAggregatedController data={data.paidSearchSummary} {...props}>
      {children}
    </PaidSearchAggregatedController>
  );
};

export default PaidSearchAggregatedLoader;
