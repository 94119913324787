import gql from "graphql-tag";
const AUDIENCE_COMMERCE_TRANSACTIONS = gql`
  query audienceCommerceTransactionsConnection(
    $first: Int
    $skip: Int
    $where: AudienceCommerceTransactionWhereInput
  ) {
    transactions: audienceCommerceTransactionsConnection(
      first: $first
      skip: $skip
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          orderId
          orderedOn
          revenue
          baseTotal
          tax
          totalItems
          items {
            name
            quantity
            price
            sku
          }
        }
      }
      aggregate {
        count
      }
    }
    full: audienceCommerceTransactionsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default AUDIENCE_COMMERCE_TRANSACTIONS;
