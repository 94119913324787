import React, { useState, useEffect } from "react";
import { message, notification } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import { computeROAS } from "../../../utils/campaigns";

const PaidSearchMainController = ({
  paidSearchOrgs,
  possibleFields,
  defaultColumns,
  childOrgs,
  childOrgsLoading,
  selectedRowKeys,
  setSelectedRowKeys,
  sharedOrgsDetails,
  getSharedDetails,
  sharedOrgsLoading,
  setSelectedRows,
  selectedRows,
  updatePaidSearchSharedDetails,
  currentOrgId,
  total,
  current,
  pageSize,
  reprocessPaidSearch,
  getReprocessStatus,
  reprocessStatus,
  reporcessStatusLoading,
  updateReprocessStatus,
  currentUser,
  filter,
  eventTags,
  launchPaidSearch,
  refetch,
  ...props
}) => {
  const [data, setData] = useState(paidSearchOrgs);
  const [selectedTableColumns, setSelectedTableColumns] = useState(
    defaultColumns
  );
  const [sharedOrgsToggle, setSharedOrgsToggle] = useState(false);
  const [sharedOrgs, setSharedOrgs] = useState([]);
  const [toBeSavedData, setToBeSavedData] = useState([]);
  const [reprocessStatuses, setReprocessStatuses] = useState({
    allStatus: "FINISHED",
    //reprocessNewStatus: "FINISHED",
  });

  const onRowChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const onCancelSharedModal = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSharedOrgs([]);
    setToBeSavedData([]);
    setSharedOrgsToggle(false);
  };

  const getCurrentStatus = async () => {
    await getReprocessStatus({
      variables: {
        keys: ["paid-search-all"],
      },
    });
  };

  const onSubmitSharedModal = async () => {
    message.loading(`Sharing in progress ...`);
    await Promise.all(
      toBeSavedData.map(async paid => {
        let connectIds = [];
        let disconnectIds = [];
        const ids = paid.values;
        const thisSharedOrg = sharedOrgs.find(o => o.id === paid.id).sharedOrgs;
        const thisSharedOrgIds = thisSharedOrg.map(s => s.id);
        ids.forEach(id => {
          if (!thisSharedOrgIds.includes(id)) connectIds.push({ id });
        });
        thisSharedOrgIds.forEach(id => {
          if (!ids.includes(id)) disconnectIds.push({ id });
        });

        return await updatePaidSearchSharedDetails({
          variables: {
            id: paid.id,
            connect: connectIds,
            disconnect: disconnectIds,
          },
        });
      })
    );
    message.success(`Sharing Complete`);
    onCancelSharedModal();
  };

  const reprocessAll = async () => {
    try {
      await reprocessPaidSearch({
        variables: {
          where: {
            dag_id: "paid-search",
            body: {},
          },
        },
      });

      await updateReprocessStatus({
        variables: {
          key: "paid-search-all",
          dateQueued: moment()
            .utc()
            .format(),
          module: "paid-search",
        },
      });

      setReprocessStatuses({
        allStatus: "QUEUED",
      });

      notification.open({
        message: "Reprocessing Paid Search...",
        description:
          "Please wait for a while. The reprocess paid search are now on queue. This may take some time. ",
        icon: <FileSyncOutlined />,
        duration: 20,
      });
    } catch (err) {
      message.error("Failed Processing Paid Search");
    }
  };

  const reprocessNew = () => {
    try {
      reprocessPaidSearch({
        variables: {
          where: {
            dag_id: "paid-search-trigger",
            body: {
              conf: {
                function: "function=getNew",
                orgId: "",
              },
            },
          },
        },
      });

      notification.open({
        message: "Reprocessing Paid Search...",
        description:
          "Please wait for a while. The process of new paid search are now on queue. This may take some time. ",
        icon: <FileSyncOutlined />,
        duration: 20,
      });
    } catch (err) {
      message.error("Failed Processing Paid Search");
    }
  };

  const launchPaidSearchEvent = async values => {
    let data = {
      startDate: moment(),
      endDate: moment(),
      status: "PENDING",
      org: {
        connect: {
          id: values.organization,
        },
      },
      config: {
        create: {
          monthlyBudget: values.monthlyBudget
            ? Number(values.monthlyBudget)
            : 0,
          googleCustomerId: values.googleAdsID,
          googleViewId: values.googleAnalyticsID,
          bingAccountId: values.bingAccountID,
          bingCustomerId: values.bingCustomerID,
          googleViewIdIsGa4: values.googleAnalyticsViewIdIsGA4,
        },
      },
    };

    if (values.processWithGoogleAds) {
      data.config.create.processWithGoogleAds = values.processWithGoogleAds;
    }
    if (values.hasLocationRevenue) {
      data.config.create.hasLocationRevenue = values.hasLocationRevenue;
      data.config.create.locationsKey = {
        set: values.locationsKey,
      };
    }
    if (values.hasCommerce) {
      data.config.create.hasCommerce = values.hasCommerce;
      data.config.create.commerceTags = {
        connect: {
          id: values.commerceTags,
        },
      };
    }
    await launchPaidSearch({
      variables: {
        data: data,
      },
    });

    await refetch();

    //After initiating the launching of paid search trigger reprocess,
    //this will remove the need to wait for the main pipeline to process. This approach allows data to be displayed significantly earlier.
    await reprocessPaidSearch({
      variables: {
        where: {
          dag_id: "paid-search-trigger",
          body: {
            conf: {
              function: "function=getOrg",
              orgId: `orgId=${values.organization}`,
            },
          },
        },
      },
    });
  };
  useEffect(() => {
    if (filter === "month") {
      const toUpdate = paidSearchOrgs.map(org => {
        // Check if org has aggregateData and byDate
        if (org.aggregateData && org.aggregateData.byDate) {
          const aggregate = org.aggregateData.byDate.reduce(
            (acc, curr) => {
              acc.clicks += curr.clicks;
              acc.conversions += curr.conversions;
              acc.impressions += curr.impressions;
              acc.revenue += curr.revenue;
              acc.spend += curr.spend;
              return acc;
            },
            {
              clicks: 0,
              conversions: 0,
              impressions: 0,
              revenue: 0,
              spend: 0,
            }
          );
          return {
            ...org,
            aggregateData: {
              clicks: aggregate.clicks,
              conversions: aggregate.conversions,
              impressions: aggregate.impressions,
              revenue: aggregate.revenue,
              roas: computeROAS(aggregate.revenue, aggregate.spend),
              spend: aggregate.spend,
              byDate: org.aggregateData.byDate,
            },
          };
        } else {
          return org;
        }
      });
      setData(toUpdate);
    } else {
      setData(paidSearchOrgs);
    }
  }, [filter, paidSearchOrgs]);

  useEffect(() => {
    if (!_.isNil(sharedOrgsDetails)) {
      const modifiedSharedOrgs =
        !_.isNil(sharedOrgsDetails.paidSearchSummaries) &&
        sharedOrgsDetails.paidSearchSummaries.map(o => {
          const savedValue = [];
          if (o.sharedOrgs.length) {
            o.sharedOrgs.forEach(s => {
              savedValue.push(s.id);
            });
          }
          const defaultDisabled = [o.org.id, currentOrgId];
          o.displayValues = [...defaultDisabled, ...savedValue];
          o.displayValues = [...new Set(o.displayValues)];
          o.values = savedValue;
          return o;
        });
      setSharedOrgs(modifiedSharedOrgs);
      setToBeSavedData(modifiedSharedOrgs);
    }
  }, [currentOrgId, sharedOrgsDetails]);

  useEffect(() => {
    setSharedOrgs([]);
    setToBeSavedData([]);
  }, [selectedRowKeys]);

  useEffect(() => {
    let allStatus = "FINISHED";

    if (reprocessStatus) {
      const { reprocessStatuses } = reprocessStatus;
      if (reprocessStatuses.length > 0) {
        reprocessStatuses.forEach(reprocess => {
          const { key, status } = reprocess;
          if (key === "paid-search-all") {
            allStatus = status;
          }
        });
      }

      setReprocessStatuses({
        allStatus,
      });
    }
  }, [reprocessStatus]);
  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        setSelectedTableColumns,
        setData,
        data,
        selectedTableColumns,
        possibleFields,
        selectedRowKeys,
        childOrgs,
        childOrgsLoading,
        onRowChange,
        sharedOrgs,
        setSharedOrgs,
        getSharedDetails,
        sharedOrgsLoading,
        selectedRows,
        onCancelSharedModal,
        setSharedOrgsToggle,
        sharedOrgsToggle,
        onSubmitSharedModal,
        setToBeSavedData,
        toBeSavedData,
        currentOrgId,
        total,
        current,
        pageSize,
        reprocessNew,
        reprocessAll,
        reprocessStatuses,
        getCurrentStatus,
        reporcessStatusLoading,
        isAdmin: currentUser.isAdmin ? currentUser.isAdmin : false,
        filter,
        currentUser,
        eventTags,
        launchPaidSearchEvent,
        ...props,
      })}
    </React.Fragment>
  );
};

export default PaidSearchMainController;
