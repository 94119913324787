/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "react-apollo";
import { isEmpty } from "lodash";
import {
  Button,
  Empty,
  Col,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import GET_ARTICLE from "../../../../core/GraphQl/Queries/GET_ARTICLE";
import GET_ALL_ARTICLE_CATEGORIES from "../../../../core/GraphQl/Queries/GET_ALL_ARTICLE_CATEGORIES";
import CREATE_ARTICLE_CATEGORY from "../../../../core/GraphQl/Mutations/CREATE_ARTICLE_CATEGORY";
import UPDATE_ARTICLE_CATEGORY from "../../../../core/GraphQl/Mutations/UPDATE_ARTICLE_CATEGORY";
import DELETE_ARTICLE_CATEGORY from "../../../../core/GraphQl/Mutations/DELETE_ARTICLE_CATEGORY";
import CREATE_ARTICLE from "../../../../core/GraphQl/Mutations/CREATE_ARTICLE";
import UPDATE_ARTICLE from "../../../../core/GraphQl/Mutations/UPDATE_ARTICLE";
import DELETE_ARTICLE from "../../../../core/GraphQl/Mutations/DELETE_ARTICLE";
import mjlogo from "../../../../core/mj-assets/logo/LOGO_TRANSPARENT.png";

import ArticleContent from "../components/ArticleContent";
import FormRichText from "../components/FormRichText";
import CategoryModal from "./CategoryModal";
import {
  mediajelAdminOrgIdProduction,
  mediajelAdminOrgIdStaging,
} from "../../../../core/utils/constants/constants";

const { Content, Sider } = Layout;

const { SubMenu } = Menu;

const { confirm } = Modal;

const Articles = ({ data, user }) => {
  // constants
  const list = data.map(item => ({ ...item, isEdit: false }));
  const hasPermission =
    (user && user.defaultRole.org.id === mediajelAdminOrgIdStaging) ||
    (user && user.defaultRole.org.id === mediajelAdminOrgIdProduction);
  const history = useHistory();
  const categoryIdMatch = useRouteMatch("/articles/category/:categoryId");
  const articleIdMatch = useRouteMatch(
    "/articles/category/:categoryId/article/:articleId"
  );
  // states
  const [currentCategory, setCurrentCategory] = useState(null);
  const [article, setArticle] = useState({
    id: "",
    title: "",
    content: "",
    rawContent: "",
    show: false,
  });
  const [categories, setCategories] = useState(list);
  const [categoryName, setCategoryName] = useState("");
  const [categoryFeature, setCategoryFeature] = useState([]);
  const [categoryPermission, setCategoryPermission] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [articleId, setArticleId] = useState("");

  // Mutations
  const [createArticleCategory] = useMutation(CREATE_ARTICLE_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ALL_ARTICLE_CATEGORIES,
      },
    ],
    onCompleted: () => {
      setCategories(list);
    },
  });
  const [createArticle] = useMutation(CREATE_ARTICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ALL_ARTICLE_CATEGORIES,
        variables: { categoryId },
      },
    ],
    onCompleted: data => {
      setCategories(list);
      const { id, title, content } = data.createArticle;

      setArticle({
        ...article,
        id,
        title,
        content: <ArticleContent title={title} content={content} />,
        rawContent: content,
        show: false,
      });
    },
  });
  const [updateArticleCategory] = useMutation(UPDATE_ARTICLE_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ALL_ARTICLE_CATEGORIES,
      },
    ],
    onCompleted: () => {
      setCategories(list);
    },
  });

  const [updateArticle] = useMutation(UPDATE_ARTICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ARTICLE,
        variables: { categoryId: currentCategory ? currentCategory.id : "" },
      },
    ],
    onCompleted: data => {
      setCategories(list);
      const { id, title, content } = data.updateArticle;

      setArticle({
        ...article,
        id,
        title,
        content: <ArticleContent title={title} content={content} />,
        rawContent: content,
        show: false,
      });
    },
  });
  const [deleteArticleCategory] = useMutation(DELETE_ARTICLE_CATEGORY, {
    ...(categories.length > 0
      ? {
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_ALL_ARTICLE_CATEGORIES,
            },
          ],
        }
      : {}),
    onCompleted: () => {
      setCategories(list);
    },
  });
  const [deleteArticle] = useMutation(DELETE_ARTICLE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ALL_ARTICLE_CATEGORIES,
      },
    ],
    onCompleted: () => {
      history.push(`/articles/category/${categoryId}`);
      setCategories(list);
      setArticle({
        id: "",
        title: "",
        content: "",
        rawContent: "",
        show: false,
      });
    },
  });

  // Handlers
  const handleParamsMatch = () => {
    if (articleIdMatch && articleId !== articleIdMatch.params.articleId) {
      setArticleId(articleIdMatch.params.articleId);
    }
    if (categoryIdMatch && categoryId !== categoryIdMatch.params.categoryId) {
      setCategoryId(categoryIdMatch.params.categoryId);
    }
  };
  const getCurrentArticle = (currentCategory, articleId) => {
    const currentArticle = currentCategory.articles.find(
      article => article.id === articleId
    );

    if (!currentArticle) {
      return;
    }

    const { content, id, title } = currentArticle;
    setArticle({
      id,
      title,
      content: <ArticleContent title={title} content={content} />,
      rawContent: content,
      show: false,
    });
  };
  const handleObject = f => {
    if (f.hasOwnProperty("value")) {
      return f.value;
    }
    return f;
  };
  const handleCategoryMutation = () => {
    if (isEdit) {
      const feature = categoryFeature.map(f => handleObject(f));
      const permission = categoryPermission.map(f => handleObject(f));

      updateArticleCategory({
        variables: {
          id: currentCategory.id,
          title: categoryName,
          feature,
          permission,
        },
      });
      return;
    }

    createArticleCategory({
      variables: {
        title: categoryName,
        feature: categoryFeature,
        permission: categoryPermission,
      },
    });
  };
  const handleSaveArticle = () => {
    const content =
      typeof article.content === "object"
        ? article.rawContent
        : article.content;

    if (!article.id) {
      createArticle({
        variables: {
          title: article.title,
          content,
          categoryId,
        },
      });
    } else {
      updateArticle({
        variables: {
          id: articleId,
          title: article.title,
          content,
        },
      });
    }
  };
  const handleOnCancel = () => {
    confirm({
      title: "Are you sure?",
      content: "Your article will not be saved.",
      onOk() {
        getCurrentArticle(currentCategory, articleId);
      },
      onCancel() {},
    });
  };
  const handleDeleteCategory = () => {
    if (!isEmpty(currentCategory.articles)) {
      confirm({
        title:
          "You can not delete a category with Articles in it. Delete all articles first before proceeding.",
      });
      return;
    }

    confirm({
      title: `Are you sure you want to delete this Category: ${currentCategory.title}`,
      onOk() {
        deleteArticleCategory({
          variables: { id: currentCategory ? currentCategory.id : "" },
        });
      },
    });
  };
  const handleDeleteArticle = () => {
    confirm({
      title: "Are you sure you want to delete this Article?",
      onOk() {
        deleteArticle({
          variables: { id: article.id },
        });
      },
      onCancel() {},
    });
  };
  const showAddCategoryModal = () => {
    setCategoryName();
    setCategoryFeature([]);
    setCategoryPermission([]);
    setIsModalOpen(true);
    setIsEdit(false);
  };
  const showEditCategoryModal = () => {
    setIsModalOpen(true);
    setIsEdit(true);
  };
  const handleOk = () => {
    handleCategoryMutation();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleInputChange = e => {
    setCategoryName(e.target.value);
  };
  const handleFeatureChange = e => {
    setCategoryFeature(e);
  };
  const handlePermissionChange = e => {
    setCategoryPermission(e);
  };
  // Use Effects
  useEffect(() => {
    handleParamsMatch();
  }, []);

  useEffect(() => {
    handleParamsMatch();
  }, [categoryIdMatch, articleIdMatch]);

  useEffect(() => {
    if (categoryId) {
      const categoryResult = list.find(category => category.id === categoryId);
      if (!categoryResult) {
        return;
      }

      setCategoryName(categoryResult.title);
      setCategoryFeature(
        categoryResult.feature.map(feature => ({
          label: feature,
          value: feature,
        }))
      );
      setCategoryPermission(
        categoryResult.permission.map(permission => ({
          label: permission,
          value: permission,
        }))
      );
      setCurrentCategory(categoryResult);
    }
  }, [categoryId]);

  useEffect(() => {
    if (currentCategory && articleId && articleId !== "") {
      getCurrentArticle(currentCategory, articleId);
    }
  }, [articleId]);

  useEffect(() => {
    if (currentCategory) {
      getCurrentArticle(currentCategory, articleId);
    }
  }, [currentCategory]);

  return (
    <>
      <CategoryModal
        category={currentCategory}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        categoryName={categoryName}
        categoryFeature={categoryFeature}
        categoryPermission={categoryPermission}
        handleInputChange={handleInputChange}
        handleFeatureChange={handleFeatureChange}
        handlePermissionChange={handlePermissionChange}
        isEdit={isEdit}
      />
      <div>
        <Layout style={{ minHeight: "100vh", height: "100%" }}>
          <Sider
            width={200}
            className="site-layout-background"
            collapsible={false}
          >
            <Row type="flex" align="center">
              <Col style={{ margin: "20px 0" }}>
                {!user && (
                  <Link to="/home">
                    <img src={mjlogo} alt="MediaJel" width={80} />
                  </Link>
                )}
              </Col>
            </Row>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[currentCategory ? currentCategory.id : ""]}
              style={{
                border: "none",
                marginBottom: "30px",
              }}
            >
              {categories.map(category => (
                <SubMenu
                  key={category.id}
                  title={
                    <span>
                      <span>{category.title}</span>
                    </span>
                  }
                  onTitleClick={() => {
                    history.push(`/articles/category/${category.id}`);
                  }}
                >
                  {category.articles &&
                    category.articles !== [] &&
                    category.articles.map(article => (
                      <Menu.Item
                        key={article.id}
                        onClick={() => {
                          history.push(
                            `/articles/category/${category.id}/article/${article.id}`
                          );
                        }}
                      >
                        {article.title}
                      </Menu.Item>
                    ))}
                </SubMenu>
              ))}
            </Menu>
            <Row justify="center">
              {hasPermission && (
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Button
                    block
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showAddCategoryModal}
                  >
                    Add Category
                  </Button>
                  <Button
                    block
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      setArticle({
                        id: "",
                        title: "",
                        content: "",
                        rawContent: "",
                        show: true,
                      })
                    }
                  >
                    Add Article
                  </Button>
                </Space>
              )}
            </Row>
          </Sider>
          <Layout>
            <Menu mode="horizontal">
              {hasPermission && (
                <>
                  <Menu.Item
                    key="edit category"
                    onClick={showEditCategoryModal}
                    icon={<EditOutlined />}
                  >
                    Edit Category
                  </Menu.Item>
                  <Menu.Item
                    key="delete category"
                    onClick={handleDeleteCategory}
                    icon={<DeleteOutlined />}
                  >
                    Delete Category
                  </Menu.Item>
                  {article.id && (
                    <>
                      <Menu.Item
                        key="edit article"
                        onClick={() => setArticle({ ...article, show: true })}
                        icon={<EditOutlined />}
                      >
                        Edit Article
                      </Menu.Item>
                      <Menu.Item
                        key="edit article"
                        onClick={handleDeleteArticle}
                        icon={<DeleteOutlined />}
                      >
                        Delete Article
                      </Menu.Item>
                    </>
                  )}
                </>
              )}
            </Menu>

            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                overflow: "auto",
              }}
            >
              {categories.length > 0 ? (
                <Col>
                  {article.show ? (
                    <Row type="flex">
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ width: "100%", paddingBottom: "20px" }}
                      >
                        <Col span={18}>
                          <h4 style={{ marginBottom: "0" }}>Title</h4>
                          <Input
                            placeholder="Title"
                            value={article.title}
                            onChange={({ target }) =>
                              setArticle({ ...article, title: target.value })
                            }
                          />
                        </Col>
                        <Col style={{ display: "flex", gap: 3 }}>
                          <Button
                            type="danger"
                            onClick={handleOnCancel}
                            icon={<CloseOutlined />}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            onClick={handleSaveArticle}
                            icon={<SaveOutlined />}
                            disabled={!(article.title && article.content)}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                      <Col>
                        <FormRichText
                          onChange={setArticle}
                          name="content"
                          value={
                            typeof article.content === "object"
                              ? article.rawContent
                              : article.content
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    article.content
                  )}
                </Col>
              ) : (
                <Empty
                  description={
                    hasPermission
                      ? "Add Category to start! 🤩"
                      : "No Article published at the moment. 😞"
                  }
                  style={{ marginTop: 250 }}
                />
              )}
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default Articles;
