import gql from "graphql-tag";
const GET_USER_MSA_STATUS = gql`
  query users($id: ID) {
    users(where: { id: $id }) {
      id
      msaStatus
    }
  }
`;

export default GET_USER_MSA_STATUS;
