import gql from "graphql-tag";
const UPDATE_MANY_MEDIAS = gql`
  mutation updateManyMedias(
    $ids: [ID!]!
    $data: MediaUpdateManyMutationInput!
  ) {
    updateManyMedias(where: { id_in: $ids }, data: $data) {
      count
    }
  }
`;

export default UPDATE_MANY_MEDIAS;
