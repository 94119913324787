import React from "react";
import { graphql, withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";

import compose from "lodash/flowRight";
import Controller from "../transactionImpressions/TransactionImpressionsController";
import GET_TRANSACTION_IMPRESSIONS from "../../../../GraphQl/Queries/GET_TRANSACTION_IMPRESSIONS";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import { Row, Spin } from "antd";

const TransactionImpressionsLoader = ({
  children,
  campaignOrderId,
  reportName,
  transactionId,
  isDemo,
}) => {
  const { loading, data } = useQuery(GET_TRANSACTION_IMPRESSIONS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      campaignOrderId,
      reportName,
      transactionId,
    },
  });

  if (loading) {
    return (
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  let impressions = data && data.getTransactionImpressions;

  if (isDemo) {
    impressions = impressions.map(i => {
      i.CREATIVEURL = "";
      return i;
    });
  }
  return <Controller impressions={impressions}>{children}</Controller>;
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withApollo(TransactionImpressionsLoader));
