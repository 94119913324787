import React from "react";
import { withApollo } from "react-apollo";
import { Row, Col, Select } from "antd";

import useUniversalTag from "./hooks/useUniversalTag";
import TagComponent from "./components/TagComponent";
import SelectOptions from "./components/SelectOptions";
import TagActions from "./components/TagActions";

const SelectVersion = ({ version, setVersion }) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Col>
        <h5>Version</h5>
        <Select
          placeholder="Version"
          value={version}
          onChange={value => setVersion(value)}
          style={{ width: "200px" }}
        >
          <Select.Option value="Stable">Stable</Select.Option>
          <Select.Option value="Beta">Beta</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};

const UniversalTagBase = ({ collectorUrl }) => {
  const {
    appId,
    setAppId,
    generatedTag,
    version,
    setVersion,
    environment,
    setEnvironment,
    setGeneratedTag,
    generateSnippet,
    withRetailId,
    setWithRetailId,
    retailId,
    setRetailId,
  } = useUniversalTag({ collectorUrl });

  const reset = () => setGeneratedTag(null);
  const onKeyDown = event => event.key === "Enter" && generateSnippet();

  return (
    <div>
      <h3>Universal Tag Tracker</h3>
      <p>
        Fill out the following fields to generate a JavaScript script tag to be
        entered into the code on the website you would like to track. This tag
        is meant to be placed within the html header and will track page views
        and ecommerce on a given domain. This tag is essential for tracking the
        user journey throughout the target domain. Select the version you want
        to generate for the universal tag script. Stable uses the production
        version of the tag while Beta uses the staging version of the tag. If
        you are unsure which to choose, please use the Stable version. Select
        the cart provider used by the client website we want to integrate with.
      </p>
      <SelectVersion version={version} setVersion={setVersion} />
      <SelectOptions
        version={version}
        setEnvironment={setEnvironment}
        environment={environment}
        setAppId={setAppId}
      />
      <TagComponent
        generatedTag={generatedTag}
        environment={environment}
        appId={appId}
        setAppId={setAppId}
        onKeyDown={onKeyDown}
        withRetailId={withRetailId}
        setWithRetailId={setWithRetailId}
        retailId={retailId}
        setRetailId={setRetailId}
      />

      <Row type="flex" justify="center" style={{ width: "100%" }}>
        <Col style={{ padding: "0 10px" }}>
          <TagActions
            reset={reset}
            generateSnippet={generateSnippet}
            generatedTag={generatedTag}
          />
        </Col>
      </Row>
    </div>
  );
};

export const UniversalTag = withApollo(UniversalTagBase);
