import gql from "graphql-tag";
import { CampaignOrderQueueSummaryFields } from "../Fragments/CampaignOrderFragments";

const GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_READY = gql`
  ${CampaignOrderQueueSummaryFields}

  query campaignOrdersQueueReady(
    $name: String
    $status: [CampaignStatus!] = [APPROVED, LIVE_APPROVED, LIVE]
    $orgId: ID!
    $orderBy: CampaignOrderOrderByInput = updatedAt_DESC
    $skip: Int = 0
    $after: String
    $before: String
    $first: Int = 10
    $last: Int
    $isLaunchProgrammatically: Boolean
    $createAtStartDate: DateTime
    $createdAtEndDate: DateTime
    $endDate_gte: DateTime
    $endDate_lte: DateTime
  ) {
    campaignOrdersConnection: campaignOrdersConnection(
      where: {
        status_in: $status
        OR: [
          {
            AND: [
              { name_contains: $name }
              { isLaunchProgrammatically: $isLaunchProgrammatically }
              { createdAt_gte: $createAtStartDate }
              { createdAt_lte: $createdAtEndDate }
              { endDate_gte: $endDate_gte }
              { endDate_lte: $endDate_lte }
              {
                orgs_some: {
                  OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }]
                }
              }
            ]
          }
        ]
      }
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...CampaignOrderQueueSummaryFields
        }
      }
      aggregate {
        count
      }
    }

    full: campaignOrdersConnection(
      where: {
        status_in: $status
        OR: [
          {
            AND: [
              { name_contains: $name }
              { isLaunchProgrammatically: $isLaunchProgrammatically }
              { createdAt_gte: $createAtStartDate }
              { createdAt_lte: $createdAtEndDate }
              { endDate_gte: $endDate_gte }
              { endDate_lte: $endDate_lte }
              {
                orgs_some: {
                  OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }]
                }
              }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_READY;
