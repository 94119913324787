import React from "react";
import { Input, Select, Row, Col, Tag, Switch } from "antd";

const Text = Input.TextArea;
const Option = Select.Option;
const Frag = React.Fragment;
const typeOptions = [
  "Audience",
  "Brand Safety",
  "Fraud Detection",
  "Viewability",
  "Contextual",
  "Geolocation",
  "B2B",
  "Purchase",
  "Interest",
  "Demo",
  "In-Market",
];

const styles = {
  headerMain: {
    color: "#87898b",
  },
  headerSecondary: {
    margin: "5px 0 0 0",
    color: "#d4d5d7",
  },
};

const ModalForm = ({
  onChange,
  recordData,
  audienceName,
  priceCategories,
  modal,
}) => {
  return (
    <Frag>
      <Row>
        <Col style={{ padding: "24px" }} span={17}>
          <Row type="flex" justify="space-between">
            <Col span={14}>
              <h5 style={styles.headerMain}>Audience Name</h5>
              <Input
                disabled={modal === "mapPrice" ? true : false}
                defaultValue={audienceName}
                style={{ width: "75%" }}
                onChange={e => {
                  onChange("audienceName", e.target.value);
                }}
              />
              <h5 style={styles.headerSecondary}>
                How you Identify the audience
              </h5>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              span={10}
            >
              <h5 style={styles.headerMain}>Type</h5>
              <Select
                disabled={modal === "mapPrice" ? true : false}
                defaultValue="Audience"
                style={{ width: "100%" }}
                onSelect={value => {
                  onChange("type", value);
                }}
              >
                {typeOptions.map((type, index) => {
                  return (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  );
                })}
              </Select>
              <h5 style={styles.headerSecondary}>
                Helps buyers to identify segments
              </h5>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <h5 style={styles.headerMain}>Audience Description</h5>
              <Text
                disabled={modal === "mapPrice" ? true : false}
                defaultValue={recordData.description}
                onChange={e => {
                  onChange("description", e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col span={15}>
              <h5 style={styles.headerMain}>Price Category</h5>
              <Select
                disabled={modal === "editSegment" ? true : false}
                style={{ width: "75%" }}
                onSelect={value => {
                  onChange("priceCategory", value);
                }}
                defaultValue={recordData.data_cat_raw_id}
              >
                {priceCategories.map((cat, index) => {
                  const { price, id } = cat;
                  return (
                    <Option value={id} key={index}>
                      {price}
                    </Option>
                  );
                })}
              </Select>
              <h5 style={styles.headerSecondary}>Only showing valid options</h5>
            </Col>
            <Col span={9}>
              <h5 style={styles.headerMain}>Data Provider</h5>
              <Tag>MediaJel</Tag>
              <h5 style={styles.headerSecondary}>Provided by AppNexus</h5>
            </Col>
          </Row>
        </Col>
        <Col style={{ borderLeft: "1px solid #e8e8e8" }} span={7}>
          <Row
            style={{
              borderBottom: "1px solid #e8e8e8",
              padding: "24px 10px 24px 10px",
            }}
          >
            <h6 style={{ ...styles.headerMain, margin: 0 }}>Audience Status</h6>
            <h6 style={{ ...styles.headerSecondary, margin: "0 0 5px 0" }}>
              Audience status: Active or Disabled
            </h6>
            <Switch
              disabled={modal === "editSegment" ? true : false}
              checkedChildren="Active"
              unCheckedChildren="Disabled"
              onClick={checked => {
                onChange("status", checked);
              }}
              defaultChecked={recordData.active}
            />
          </Row>
          <Row
            style={{
              borderBottom: "1px solid #e8e8e8",
              padding: "24px 10px 24px 10px",
            }}
          >
            <h6 style={{ ...styles.headerMain, margin: 0 }}>Share Audience</h6>
            <h6 style={{ ...styles.headerSecondary, margin: "0 0 5px 0" }}>
              Share this audience with Data Buyers
            </h6>
            <Switch
              disabled={modal === "editSegment" ? true : false}
              checkedChildren="Shared"
              unCheckedChildren="Disabled"
              onClick={checked => {
                onChange("shared", checked);
              }}
              defaultChecked={recordData.isPublic}
            />
          </Row>
          <Row style={{ padding: "24px 10px 24px 10px" }}>
            <h6 style={styles.headerMain}>Recommend Audience</h6>
            <Switch
              disabled={true}
              checkedChildren="Public"
              unCheckedChildren="Private"
              onClick={checked => {
                onChange("recommend", checked);
              }}
              defaultChecked={recordData.isRecommended}
            />
            <h6 style={{ ...styles.headerSecondary, margin: "5px 0 0 0" }}>
              Recommend audience to Data Buyers
            </h6>
          </Row>
        </Col>
      </Row>
    </Frag>
  );
};

export default ModalForm;
