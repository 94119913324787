import { message } from "antd";
import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";

const EditorialMainController = ({
  data,
  eventsTargets,
  isSelfService,
  orgsSharing,
  createEditorial,
  refetch,
  updateEditorialSharing,
  updateEditorialMeasurements,
  allEditorials,
  locationData,
  attributionAudiences,
  currentOrgId,
  ...props
}) => {
  const onCreateEditorial = async values => {
    message.loading("Creating Editorial...", 10);
    try {
      await createEditorial({
        variables: {
          data: {
            clicksTag: { connect: { id: values.clicksTag } },
            name: values.name,
            domain: values.domain,
            url: values.url,
            trackMesurements: values.trackMesurements,
            // transactionsTag: {
            //   connect: {
            //     id: values.transactionsTags[0].eventsTarget.connect.id,
            //   },
            // },
            // trackMeasurements: values.transactionsTags[0].trackMeasurements,
            transactionsTags: {
              create: values.transactionsTags.map(tag => ({
                eventsTarget: {
                  connect: {
                    id: tag.eventsTarget.connect.id,
                  },
                },
                trackMesurements: tag.trackMesurements,
              })),
            },
            startDate: values.startDate,
            endDate: values.endDate,
            sharedOrgs: {
              create: [
                {
                  isAdmin: false,
                  isOwner: true,
                  isClickEvents: true,
                  isTransactionsEvents: false,
                  org: {
                    connect: {
                      id: values.selectedPublisherDomainOrgId,
                    },
                  },
                },
                ...values.transactionsTags.map(tag => ({
                  isAdmin: false,
                  isOwner: true,
                  isClickEvents: false,
                  isTransactionsEvents: true,
                  org: {
                    connect: {
                      id: tag.eventsTarget.connect.orgId,
                    },
                  },
                })),
              ],
            },
            locations: {
              connect: values.locations.connect.map(location => ({
                id: location.id,
              })),
            },
          },
        },
      });
      await refetch();
      message.destroy();
      message.success("Editorial Created", 0.5);
    } catch (error) {
      message.destroy();
      message.error("Error Creating Editorial");
      console.error(error);
    }
  };

  const onShareEditorial = async values => {
    message.loading("Sharing Editorial(s)...", 10);
    for (const key of Object.keys(values)) {
      const { editorialId, sharedOrgs } = values[key];
      const toDelete = [];
      const toAdd = [];

      //Initial Editorial Data
      const editorialData = data.find(editorial => {
        return editorial.id === editorialId;
      });

      const exisingSharedOrgs = [];
      editorialData.sharedOrgs.forEach(shared => {
        if (
          !shared.isAdmin &&
          !sharedOrgs.find(o => o === shared.org.id) &&
          orgsSharing.find(o => o.id === shared.org.id)
        ) {
          toDelete.push({ id: shared.id });
        } else if (!shared.isAdmin) exisingSharedOrgs.push(shared.org.id);
      });

      sharedOrgs.forEach(shared => {
        const existingShared = exisingSharedOrgs.find(o => o === shared);
        if (!existingShared) {
          toAdd.push({
            isAdmin: false,
            isOwner: true,
            org: { connect: { id: shared } },
          });
        }
      });

      await updateEditorialSharing({
        variables: {
          id: editorialId,
          sharedOrgs: {
            create: toAdd,
            delete: toDelete,
          },
        },
      });
      await refetch();
    }
    message.destroy();
    message.success("Editorial(s) Shared", 0.5);
  };

  let publishers = [];
  if (allEditorials) {
    allEditorials.forEach(editorial => {
      if (editorial.transactionsTag) {
        editorial.transactionsTag.orgs.forEach(org => {
          if (!publishers.find(p => p.id === org.id)) {
            publishers.push(org);
          }
        });
      }
    });
  }
  publishers = publishers.sort((a, b) => a.name.localeCompare(b.name));

  const groupedDataParents = [];
  const groupedDataChild = [];
  //Segregate the data into parent and child publishers
  data.forEach(editorial => {
    // Having no optional url means that the editorial is a parent publisher
    if (!editorial.url) {
      groupedDataParents.push(editorial);
    } else {
      groupedDataChild.push(editorial);
    }
  });

  // Assign child publishers to their proper parent.
  groupedDataChild.forEach(editorial => {
    // To be considered a parent, the child publisher should have the same clicksTag and at least one matching transactionsTag
    const parent = groupedDataParents.find(
      g =>
        g.clicksTag &&
        editorial.clicksTag &&
        g.clicksTag.id === editorial.clicksTag.id &&
        g.transactionsTags &&
        editorial.transactionsTags &&
        g.transactionsTags.length > 0 &&
        editorial.transactionsTags.length > 0 &&
        g.transactionsTags.some(gTag =>
          editorial.transactionsTags.some(
            eTag => gTag.eventsTarget.id === eTag.eventsTarget.id
          )
        )
    );

    if (parent) {
      if (!parent.children) parent.children = [];
      parent.children.push(editorial);
    } else {
      // If there is no parent, then the child publisher is a parent
      groupedDataParents.push(editorial);
    }
  });

  // Filter locations
  const filteredLocations =
    locationData && locationData.locations
      ? locationData.locations.filter(
          location =>
            location.audienceType === "ATTRIBUTION" &&
            location.org.id === currentOrgId
        )
      : [];

  // Filter location groups. Only show audiences with geoTargets
  const edges =
    attributionAudiences && attributionAudiences.audiencesConnection
      ? attributionAudiences.audiencesConnection.edges
      : undefined;

  const filteredAudiences = edges
    ? edges.filter(
        edge =>
          edge.node && edge.node.geoTargets && edge.node.geoTargets.length > 0
      )
    : [];

  // Share Editorial filter Logic
  const getFilteredOrgsRow = (row, orgs) => {
    let filteredRow;
    let filteredSelection;

    // get clickEventsOrg and transactionsEventsOrg
    const clickEventsOrg = row.sharedOrgs.find(
      org => org.isClickEvents === true && org.isTransactionsEvents === false
    );
    const transactionsEventsOrg = row.sharedOrgs.find(
      org => org.isTransactionsEvents === true && org.isClickEvents === false
    );

    // filter orgs based on clickEventsOrg
    if (clickEventsOrg) {
      filteredRow = clickEventsOrg.org.id;
      filteredSelection = orgs.filter(org =>
        org.parentOrg.some(parent => parent.id === filteredRow)
      );
      const filteredRowOrg = orgs.find(org => org.id === filteredRow);
      if (filteredRowOrg) {
        filteredSelection.push(filteredRowOrg);
      }
    } else {
      filteredSelection = orgs;
    }

    // add transactionsEventsOrg to filteredSelection if not already present
    if (transactionsEventsOrg) {
      const additionalOrgId = transactionsEventsOrg.org.id;
      if (!filteredSelection.some(org => org.id === additionalOrgId)) {
        const additionalOrg = orgs.find(org => org.id === additionalOrgId);
        if (additionalOrg) {
          filteredSelection.push(additionalOrg);
        }
      }
    }
    return filteredSelection;
  };
  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        data: groupedDataParents,
        tags: eventsTargets,
        onCreateEditorial,
        onShareEditorial,
        isSelfService,
        orgsSharing,
        publishers,
        getFilteredOrgsRow,
        filteredLocations,
        filteredAudiences,
        currentOrgId,
        google: props.google,
      })}
    </React.Fragment>
  );
};

export default GoogleApiWrapper(googleMapsConfig)(EditorialMainController);
