import React from "react";
import PropTypes from "prop-types";
import { message, Row, Col, Input, Select, Button, DatePicker } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { stateList } from "../../../../core/utils/constants/constants";
const { RangePicker } = DatePicker;

const styles = {
  div: {
    flex: 1,
    bottomBorder: 0,
    padding: 10,
  },
  input: {
    minWidth: "80px",
  },
};

const SegmentsForm = ({
  submit,
  name,
  tags,
  startDate,
  endDate,
  description,
  changeValue,
  length,
  hasInProgressShape,
  buttonText,
  checkSwitchMode,
  checkTabSegment,
  type,
  history,
  tempName,
  city,
  street,
  zip,
  state,
  lat,
  lng,
}) => (
  <>
    <Row gutter={[16, 16]} className="filter-bar-container-no-border">
      <Col xs={24} lg={6}>
        <Input
          placeholder="Name"
          style={{ width: "100%" }}
          value={name}
          onChange={event => {
            changeValue("name", event.target.value);
          }}
        />
      </Col>

      <Col xs={24} lg={6}>
        <Input
          placeholder="Description"
          style={styles.input}
          value={description}
          onChange={event => {
            changeValue("description", event.target.value);
          }}
        />
      </Col>
      {checkSwitchMode === true &&
        window.location.pathname.includes("geo-targets") === false && (
          <Col xs={24} lg={4}>
            <RangePicker
              style={{ width: "100%" }}
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={["Start Time", "End Time"]}
              value={
                startDate && endDate ? [moment(startDate), moment(endDate)] : []
              }
              onChange={value => {
                changeValue("startDate", moment(value[0]).format());
                changeValue("endDate", moment(value[1]).format());
              }}
            />
          </Col>
        )}

      {checkSwitchMode === false && (
        <Col xs={24} lg={4}>
          <Select
            value={type}
            placeholder="Type"
            style={{ width: "100%" }}
            onChange={value => {
              changeValue("type", value);
              if (window.location.pathname.includes("create-audience")) {
                history.push(
                  `/audiences/create-audience/new/1/updatedAt_DESC/%7B%7D?type=${value}`
                );
              }
            }}
          >
            {/**  to do introspection to get enums dynamically **/}
            <Select.Option key={1} value="ATTRIBUTION">
              Attribution
            </Select.Option>
            <Select.Option key={2} value="TARGETING">
              Targeting
            </Select.Option>
            <Select.Option key={3} value="RETARGETING">
              Event Target
            </Select.Option>
          </Select>
        </Col>
      )}

      <Col xs={{ span: 24, push: 0 }} lg={{ span: 5, push: 3 }}>
        <Button
          type="primary"
          shape="round"
          style={{ width: "100%" }}
          onClick={async () => {
            message.loading(`Processing ${buttonText}..`, 0);
            const response = await submit();

            if (type === undefined) {
              changeValue("type", null);
            }

            if (response) {
              message.destroy();
              message.success(`${buttonText} success!`);
              await localStorage.setItem("fromCreateForm", true);
              // Go back to audiences list page
              history.push("/audiences");
            } else {
              message.destroy();
              message.error(`Could not ${buttonText}`);
            }
          }}
          disabled={
            isEmpty(name) ||
            (window.location.pathname.includes("geo-targets") &&
              checkSwitchMode === true &&
              isEmpty(state)) ||
            (window.location.pathname.includes("geo-targets") &&
              checkSwitchMode === true &&
              isEmpty(tempName)) ||
            // (window.location.pathname.includes("geo-targets") &&
            //   checkSwitchMode === true &&
            //   typeof lat === "undefined") ||
            // (window.location.pathname.includes("geo-targets") &&
            //   checkSwitchMode === true &&
            //   typeof lng === "undefined") ||
            (checkTabSegment === "geotargets" &&
              length === 0 &&
              hasInProgressShape === false) ||
            (checkSwitchMode === false && length < 1)
          }
        >
          {buttonText}
        </Button>
      </Col>
    </Row>

    {typeof lat !== "undefined" &&
      window.location.pathname.includes("geo-targets") &&
      checkSwitchMode && (
        <b>
          Location: Latitude: {lat} - Longitude: {lng}
        </b>
      )}

    {checkSwitchMode && window.location.pathname.includes("geo-targets") && (
      <Row type="flex">
        <Col style={styles.div}>
          <Input
            placeholder="Location"
            style={styles.input}
            value={tempName}
            onChange={event => {
              changeValue("tempName", event.target.value);
            }}
          />
        </Col>
        <Col style={styles.div}>
          <Input
            placeholder="Street"
            style={styles.input}
            value={street}
            onChange={event => {
              changeValue("street", event.target.value);
            }}
          />
        </Col>
        <Col style={styles.div}>
          <Input
            placeholder="City"
            style={styles.input}
            value={city}
            onChange={event => {
              changeValue("city", event.target.value);
            }}
          />
        </Col>
        <Col style={styles.div}>
          <Select
            showSearch
            placeholder="Select State"
            style={{ width: "100%", borderRadius: "4px" }}
            onChange={value => {
              changeValue("state", value);
            }}
          >
            {stateList.map(item => (
              <Select.Option key={item} value={item}>
                {item.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col style={styles.div}>
          <Input
            placeholder="Zip"
            style={styles.input}
            value={zip}
            onChange={event => {
              changeValue("zip", event.target.value);
            }}
          />
        </Col>
      </Row>
    )}
  </>
);

SegmentsForm.propTypes = {
  submit: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  length: PropTypes.number,
  hasInProgressShape: PropTypes.bool,
  checkSwitchMode: PropTypes.bool,
  type: PropTypes.string,
};

export default SegmentsForm;
