import React from "react";
import { Card, Row, Col, Typography, Input } from "antd";
import { RiseOutlined } from "@ant-design/icons";
const { Text } = Typography;

const styles = {
  options: {
    border: "1px solid #ebebeb",
    borderRadius: "4px",
    marginBottom: "15px",
    width: "100%",
  },
  titleRow: {
    color: "#595959",
    margin: 0,
    fontWeight: 500,
    fontSize: "1.15em",
  },
  secondaryText: {
    color: "#bababa",
    margin: 0,
    fontSize: ".95em",
  },
  goalSecondaryText: {
    fontSize: "10px",
  },
  goalRow: {
    marginBottom: "15px",
  },
  inputStyle: {
    width: "200px",
  },
};

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <RiseOutlined />
        <span> {titleText}</span>
      </Col>
    </Row>
  );
};

export const BudgetCard = ({
  budget,
  onChange,
  border,
  isLive,
  canEdit,
  setBudgetState,
}) => (
  <Card bordered={border} title={<Title titleText="Budget" />}>
    <Row>
      <Col span={24}>
        <Text type="titleRow" style={styles.titleRow}>
          Total Budget
        </Text>
        <br />
        <Row style={styles.goalRow}>
          <Text type="secondary" style={styles.secondaryText}>
            The amount you set as the overall budget will be the maximum amount
            that the campaign will spend over the course of its flight dates.
          </Text>
        </Row>
        <Row style={styles.goalRow}>
          <Input
            style={styles.inputStyle}
            className="span-mobile"
            addonBefore="$USD"
            value={budget}
            //readOnly={campaigns && campaigns.length > 1}
            min={0}
            max={1000000} // This doesn't seem to be working as expected
            type="number"
            onChange={e => {
              let { value } = e.target;
              if (value > 1000000) {
                value = 1000000;
              }
              onChange("budget", value);
              setBudgetState(value);
            }}
            disabled={isLive && !canEdit}
            allowClear
          />
        </Row>
        {/**<Row gutter={[12, 12]} style={styles.goalRow}>
          {campaigns &&
            campaigns.length > 1 &&
            campaigns.map((c, i) => (
              <Col span={12} key={c.id}>
                <div>
                  <Text>{c.name}</Text>
                </div>
                <Input
                  style={styles.inputStyle}
                  addonBefore="$USD"
                  value={c.budgetTotal}
                  onChange={e => {
                    const { value } = e.target;
                    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                    if (
                      (!Number.isNaN(value) && reg.test(value)) ||
                      value === "" ||
                      value === "."
                    ) {
                      let total = 0;
                      const newCampaigns = campaigns.map((c2, i2) => {
                        if (i === i2) {
                          total += Number(value);
                          return { ...c2, budgetTotal: value };
                        }
                        total += Number(c2.budgetTotal);
                        return c2;
                      });
                      onChange("campaigns", newCampaigns);
                      onChange("budget", total.toFixed(2));
                    }
                  }}
                  allowClear
                />
              </Col>
            ))}
          </Row> */}
      </Col>
      {/* <Col span={12}>
        <Text>Daily Budget</Text>
        <br />
        <Row style={styles.goalRow}>
          <Text type="secondary" style={styles.goalSecondaryText}>
            The amount you set as a daily budget will be the maximum
            amount the campaign spends each day on average. Some days the
            campaign will spend less.
          </Text>
        </Row>
        <Row style={styles.goalRow}>
          <Input
            style={styles.inputStyle}
            addonBefore="$USD"
            value={budget}
            onChange={e => {
              const { value } = e.target;
              const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
              if (
                (!Number.isNaN(value) && reg.test(value)) ||
                value === "" ||
                value === "."
              ) {
                onChange("budget", value);
              }
            }}
          />
        </Row>
      </Col> */}
    </Row>
  </Card>
);
