import React, { useEffect, useState } from "react";
import EditorialDetailsPageViewLoader from "../../../../../../core/components/editorial/editorialdetails/pageviews/EditorialDetailsPageViewLoader";
import EditorialDetailsPageViewController from "../../../../../../core/components/editorial/editorialdetails/pageviews/EditorialDetailsPageViewController";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import ErrorPage from "../../../../../shared/ErrorPage";
import DetailBar from "./components/DetailBar";
import DualAxisChart from "../../../../../shared/charts/DualAxisChart";
import { summaryScalePageViews } from "../../../utils/summaryScale";
import moment from "moment";
import { Col, Row } from "antd";
import PageViewsTable from "./components/PageViewsTable";

const EditorialPageViewDetailsView = props => {
  return (
    <EditorialDetailsPageViewLoader {...props}>
      <EditorialDetailsPageViewController>
        <PageViewsTab />
      </EditorialDetailsPageViewController>
    </EditorialDetailsPageViewLoader>
  );
};

const PageViewsTab = ({
  reportData,
  pageViewsListNormalized,
  reportLoading,
  reportError,
  startDate,
  endDate,
  filterByDate,
}) => {
  const [summaryData, setSummaryData] = useState();
  const [pageViewList, setPageViewList] = useState();

  useEffect(() => {
    if (reportData) setSummaryData(reportData.summary);
    if (pageViewsListNormalized) setPageViewList(pageViewsListNormalized);
  }, [reportData, pageViewsListNormalized]);

  if (reportLoading || !reportData) return <LoadingPage />;
  if (reportError) return <ErrorPage />;
  const { byDates } = reportData;

  const dropDownOptions = [
    { key: "clicks", display: "Impressions" },
    { key: "pageViews", display: "Page Views" },
    { key: "customers", display: "Customers" },
  ];

  return (
    <React.Fragment>
      <DetailBar startDate={startDate} endDate={endDate} {...summaryData} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DualAxisChart
            id={"pageViews"}
            byDate={byDates}
            xAxis="date"
            options={dropDownOptions}
            defaultLeftYValue={dropDownOptions[0]}
            defaultRightYValue={dropDownOptions[1]}
            customScale={summaryScalePageViews}
            has6MonDisables={false}
            allTimeDateRange={[
              moment(startDate).format("YYYY-MM-DD"),
              moment(endDate).format("YYYY-MM-DD"),
            ]}
            onDatePickerChange={({ dates }) => {
              const result = filterByDate(dates[0], dates[1]);
              if (result) {
                setSummaryData(result.summary);
                setPageViewList(result.pageViewsList);
              }
            }}
          />
        </Col>
        <Col xs={24}>
          <PageViewsTable data={pageViewList} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EditorialPageViewDetailsView;
