import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import moment from "moment";

const LinksAcquiredLineChart = ({ data, scale }) => {
  const chartData = data.map(item => ({
    date: moment(item.date).format("YYYY-MM-DD"),
    value: item.value,
    types: item.types,
  }));

  return (
    <Chart
      height={400}
      data={chartData}
      scale={scale}
      forceFit
      padding={[30, 20, 60, 40]}
    >
      <Axis name="date" />
      <Axis name="value" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom
        type="point"
        position="date*value"
        size={4}
        shape="circle"
        tooltip={[
          "date*value*types",
          (date, value, types) => {
            const typesList = Object.entries(types || {})
              .map(([type, count]) => `${type}: ${count}`)
              .join(", ");
            return {
              title: "Details:",
              value: `Date: ${date}<br />Total Links: ${value}<br/>Types: ${typesList ||
                "None"}`,
            };
          },
        ]}
      />
      {chartData.length > 1 && (
        <Geom
          type="line"
          position="date*value"
          size={2}
          shape="smooth"
          tooltip={false}
        />
      )}
    </Chart>
  );
};

export default LinksAcquiredLineChart;
