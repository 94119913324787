import React, { useState } from "react";
import QRCode from "qrcode.react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Row, Col, Card, Form, Input, Button, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";

const gridStyle = {
  width: "50%",
  textAlign: "center",
  minHeight: "90px",
};

const SetupMFAForm = ({
  form: {
    validateFields,
    getFieldDecorator,
    getFieldError,
    getFieldsError,
    isFieldTouched,
  },
  history,
}) => {
  const [authCode, setAuthCode] = useState(null);
  Form.useForm();

  const setupTOTP = async e => {
    // To setup TOTP, first you need to get a `authorization code` from Amazon Cognito
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      Auth.setupTOTP(user).then(code => {
        // TODO: figure out issuer
        const str =
          "otpauth://totp/AWSCognito:" +
          user.username +
          "?secret=" +
          code +
          "&issuer=" +
          user.signInUserSession.accessToken.payload.iss;
        setAuthCode(str);
      });
    } catch (e) {
      message.error("Not authenticated!");
    }
  };

  const verifyTOTPToken = async e => {
    e.preventDefault();
    // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
    // Use the generated one-time password to verify the setup
    validateFields(async (err, values) => {
      if (!err) {
        try {
          const user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (user)
            Auth.verifyTotpToken(user, values.code)
              .then(() => {
                // set TOTP as the preferred MFA method
                Auth.setPreferredMFA(user, "TOTP").then(() =>
                  history.push("/account")
                );
              })
              .catch(err => {
                // Token is not verified set error
              });
        } catch (e) {
          message.error("Not authenticated!");
        }
      }
    });
  };

  const antFormHasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const codeError = isFieldTouched("code") && getFieldError("code");

  return (
    <Card bodyStyle={{ padding: "5%" }}>
      {authCode ? (
        <Form onSubmit={verifyTOTPToken}>
          <Row justify="center" type="flex">
            <Col>
              <Form.Item>
                <QRCode value={authCode} size={200} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" type="flex">
            <Col>
              <Form.Item validateStatus={codeError ? "error" : ""}>
                {getFieldDecorator("code", {
                  rules: [
                    {
                      required: true,
                      message: "Code is required",
                      trigger: "blur",
                    },
                  ],
                })(<Input addonBefore={<LockOutlined />} placeholder="Code" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" type="flex">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={antFormHasErrors(getFieldsError())}
                >
                  Complete Setup
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <React.Fragment>
          <Row justify="center" type="flex">
            <div style={{ maxWidth: "800px", textAlign: "center" }}>
              <Form.Item>
                <Typography.Title level={4}>
                  Time Based One Time MFA
                </Typography.Title>
              </Form.Item>
              <Form.Item>
                <Typography.Paragraph>
                  TOTP-based two-factor authentication involves generating a
                  temporary, unique passcode that only works for a certain
                  amount of time, typically 30-60 seconds. After generating the
                  passcode, a user must type it in manually to authenticate for
                  access.
                </Typography.Paragraph>
              </Form.Item>
              <Form.Item>
                <Card hoverable={false}>
                  <Card.Grid style={gridStyle}>Android</Card.Grid>
                  <Card.Grid style={gridStyle}>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.google.com/accounts/answer/1066447?hl=en"
                      >
                        Android Google Authenticator
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.authy.authy&amp;hl=en"
                      >
                        Authy 2-Factor Authentication
                      </a>
                    </div>
                  </Card.Grid>

                  <Card.Grid style={gridStyle}>iPhone</Card.Grid>
                  <Card.Grid style={gridStyle}>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                      >
                        iPhone Google Authenticator
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://itunes.apple.com/us/app/authy/id494168017?mt=8"
                      >
                        Authy 2-Factor Authentication
                      </a>
                    </div>
                  </Card.Grid>
                  <Card.Grid style={gridStyle}>Windows</Card.Grid>
                  <Card.Grid style={gridStyle}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.windowsphone.com/en-us/store/app/authenticator/e7994dbc-2336-4950-91ba-ca22d653759b"
                    >
                      Windows Phone Authenticator
                    </a>
                  </Card.Grid>
                </Card>
              </Form.Item>
            </div>
          </Row>
          <Row justify="center" type="flex">
            <Col>
              <Form.Item>
                <Button onClick={setupTOTP}>Start Setup</Button>
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Card>
  );
};

export default withRouter(SetupMFAForm);
