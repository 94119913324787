import gql from "graphql-tag";

const ADD_CREATIVE_NOTES = gql`
  mutation updateCreativeNote(
    $id: ID!
    $mediaNotes: String
    $mediaHasNotes: Boolean
  ) {
    updateMedia(
      where: { id: $id }
      data: { mediaNotes: $mediaNotes, mediaHasNotes: $mediaHasNotes }
    ) {
      id
      mediaHasNotes
      mediaNotes
    }
  }
`;

export default ADD_CREATIVE_NOTES;
