import React from "react";
import { Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const REMOVE_COLUMN = (onRemove, style) => ({
  title: () => <div style={style}>REMOVE</div>,
  key: "remove",
  width: "10%",
  render: value => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CloseOutlined style={{ color: "red" }} onClick={() => onRemove(value)} />
    </div>
  ),
});

const RemovableList = props => {
  const { dataSource, dataColumns, onRemove, style } = props;
  const columns = [...dataColumns, REMOVE_COLUMN(onRemove, style)];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      bordered
      {...{ pagination: false }}
    />
  );
};

RemovableList.defaultProps = {
  dataColumns: [],
};

export default RemovableList;
