import { Radio } from "antd";
import React from "react";

const MapTypes = ({ mapTypeId, setMapTypeId }) => {
  const onChange = e => {
    setMapTypeId(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={mapTypeId}>
      <Radio value={"hybrid"}>Heatmap</Radio>
      <Radio value={"roadmap"}>Polygons/Shapes</Radio>
    </Radio.Group>
  );
};

export default MapTypes;
