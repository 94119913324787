import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Card, Col, Row, Popover, Tag, Divider } from "antd";
import { AreaChartOutlined, WarningTwoTone } from "@ant-design/icons";
import { CardTitle } from "../../CampaignLauncher/components";
import { CampaignStatusSteps } from "./CampaignStatusSteps";
import { CampaignStatusApprovalForm } from "./CampaignStatusApprovalForm";
import { CampaignOrderQueueLaunchManually } from "./CampaignOrderQueueLaunchManually";

const styles = {
  cardBody: {
    padding: 0,
  },
  cardRow: {
    padding: "15px",
    borderBottom: "1px solid #e8e8e8",
  },
  card: {
    marginBottom: "20px",
  },
  titleStyles: {
    iconStyle: {
      color: "#000000", //Previous Color for Reference: #9b9b9b,
      fontSize: "22px",
    },
    textStyle: {
      color: "#000000",
    },
  },
};

// TODO: Refactor campaign status should not trigger mutation evertime dropdown is clicked
const CampaignStatus = ({
  isProcessor,
  checkIsEditCampaign,
  selectedCampaignOrder: { campaignReviewProcess, status, adUnits, geoTargets },
  isCampaignDetails,
  handleSubmitCampaignOrderReview,
  handleLaunchCampaignOrderManually,
  dspStatus,
}) => {
  const [selectedStateLaw, setSelectedStateLaw] = useState(null);
  const [selectedScaleBudget, setSelectedScaleBudget] = useState(null);

  const [isOpenPopOver, setIsOpenPopOver] = useState(false);
  const { card, cardBody, cardRow, titleStyles } = styles;

  const getCampaignStateLaw =
    campaignReviewProcess && campaignReviewProcess.stateLaw;
  const getCampaignScaleBudget =
    campaignReviewProcess && campaignReviewProcess.scaleBudget;

  useEffect(() => {
    if (campaignReviewProcess) {
      setSelectedStateLaw(campaignReviewProcess.stateLaw);
      setSelectedScaleBudget(campaignReviewProcess.scaleBudget);
    }
  }, [campaignReviewProcess]);

  return (
    <Row type="flex" gutter={12}>
      <Col span={24}>
        <Card
          style={card}
          title={
            <CardTitle
              titleText={
                <Col>
                  <span>
                    <AreaChartOutlined /> Campaign Status
                  </span>

                  {checkIsEditCampaign === true ? (
                    <Tag color="orange" style={{ marginLeft: "15px" }}>
                      REQUEST CHANGE ON CAMPAIGN
                    </Tag>
                  ) : null}
                </Col>
              }
              iconType="mail"
              gutter={10}
              styles={titleStyles}
            />
          }
          bodyStyle={cardBody}
        >
          <Row style={{ float: "right", marginTop: "-100px" }}>
            {status !== "APPROVED" ? (
              <Popover
                placement="bottomRight"
                content={
                  <>
                    Please click the 'Edit Campaign' and{" "}
                    {isProcessor === true ? " Add " : " View "} Notes on the
                    Creative or Location Tab
                  </>
                }
                title={
                  <>
                    <WarningTwoTone twoToneColor="orange" /> The Campaign is
                    {getCampaignStateLaw === "rejected" ||
                    getCampaignScaleBudget === "rejected"
                      ? " Rejected"
                      : " Partially Approved"}
                    .
                  </>
                }
                visible={
                  getCampaignStateLaw === "partiallyApproved" ||
                  getCampaignScaleBudget === "partiallyApproved" ||
                  getCampaignStateLaw === "rejected" ||
                  getCampaignScaleBudget === "rejected"
                    ? true
                    : isEmpty(getCampaignStateLaw) &&
                      isEmpty(getCampaignScaleBudget)
                    ? false
                    : status === "APPROVED"
                    ? false
                    : false
                }
                open={isOpenPopOver}
                onOpenChange={() => {
                  setIsOpenPopOver(true);
                }}
              ></Popover>
            ) : null}
          </Row>
          <Row style={{ marginTop: "100px" }}>
            <CampaignStatusSteps
              status={status}
              selectedStateLaw={selectedStateLaw}
              selectedScaleBudget={selectedScaleBudget}
            />
          </Row>
          {/* TODO: What is isCampaignDetails? */}
          {isProcessor === true && isCampaignDetails === false ? (
            <Row style={cardRow}>
              <>
                <h3
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px",
                    marginLeft: "15px",
                  }}
                >
                  <Divider />
                  {["PENDING", "LIVE_PENDING"].includes(status) && (
                    <>
                      <CampaignStatusApprovalForm
                        selectedStateLaw={selectedStateLaw}
                        selectedScaleBudget={selectedScaleBudget}
                        adUnits={adUnits}
                        geoTargets={geoTargets}
                        setSelectedScaleBudget={setSelectedScaleBudget}
                        setSelectedStateLaw={setSelectedStateLaw}
                        handleSubmitCampaignOrderReview={
                          handleSubmitCampaignOrderReview
                        }
                      />
                    </>
                  )}

                  {["APPROVED", "LIVE_APPROVED"].includes(status) &&
                    dspStatus !== "IN_PROGRESS" && (
                      <CampaignOrderQueueLaunchManually
                        stateLaw={selectedStateLaw}
                        scaleBudget={selectedScaleBudget}
                        handleLaunchCampaignOrderManually={
                          handleLaunchCampaignOrderManually
                        }
                        style={{ marginTop: "20px", marginBottom: "40px" }}
                        status={status}
                      />
                    )}
                </h3>
              </>
            </Row>
          ) : null}
        </Card>
      </Col>
    </Row>
  );
};

export default CampaignStatus;
