import gql from "graphql-tag";

const LINE_ITEM_UPDATE_CAMPAIGN_ORDER = gql`
  mutation campaignOrdersyncCampaign(
    $campaignOrderId: ID
    $name: String
    $demandSidePlatform: DemandSidePlatform
    $clientOrg: String
    $budgetTotal: Float
    $budgetByDay: Float
    $startDate: DateTime
    $endDate: DateTime
    $originalStartDate: DateTime
    $originalEndDate: DateTime
    $orgId: ID
    $phoneNumber: String
    $forwardingNumber: String
    $cpm: Float
    $rawCost: Float
  ) {
    updateCampaignOrder(
      where: { id: $campaignOrderId }
      data: {
        campaigns: {
          create: {
            name: $name
            adProviderId: null
            demandSidePlatform: $demandSidePlatform
            clientOrg: $clientOrg
            budgetTotal: $budgetTotal
            budgetByDay: $budgetByDay
            startDate: $startDate
            endDate: $endDate
            originalStartDate: $originalStartDate
            originalEndDate: $originalEndDate
            orgs: { connect: { id: $orgId } }
            phoneNumber: $phoneNumber
            forwardingNumber: $forwardingNumber
            cpm: $cpm
            rawCost: $rawCost
          }
        }
      }
    ) {
      id
      name
      campaigns {
        id
        name
        adProviderId
        demandSidePlatform
        clientOrg
        budgetTotal
        budgetByDay
        startDate
        endDate
        originalStartDate
        originalEndDate
        orgs {
          id
          name
        }
        phoneNumber
        forwardingNumber
        cpm
        rawCost
      }
    }
  }
`;

export default LINE_ITEM_UPDATE_CAMPAIGN_ORDER;
