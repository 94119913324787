import gql from "graphql-tag";

const EDIT_ORG_SELF = gql`
  mutation updateOrg(
    $id: ID!
    $name: String!
    $description: String
    $website: String
    $domain: String
    $platformDomain: String
    $tagDomain: String
    $level: PartnerLevel!
    $logoId: MediaWhereUniqueInput
    $signInLogoId: MediaWhereUniqueInput
    $zone: String
    $chatWootId: String
    $cpm: String
    $roasFormat: RoasFormat
    $defaultDisplayAttributionWindow: Int
    $defaultPaidSearchAttributionWindow: Int
    $defaultSEOAttributionWindow: Int
    $currency: Currency = USD
  ) {
    updateOrg(
      data: {
        name: $name
        description: $description
        website: $website
        domain: $domain
        platformDomain: $platformDomain
        tagDomain: $tagDomain
        logo: { connect: $logoId }
        signInLogo: { connect: $signInLogoId }
        level: $level
        zone: $zone
        chatWootId: $chatWootId
        cpm: $cpm
        dataConfig: {
          create: {
            roasFormat: $roasFormat
            currency: { connect: { currency: $currency } }
            defaultDisplayAttributionWindow: $defaultDisplayAttributionWindow
            defaultPaidSearchAttributionWindow: $defaultPaidSearchAttributionWindow
            defaultSEOAttributionWindow: $defaultSEOAttributionWindow
          }
        }
      }
      where: { id: $id }
    ) {
      id
      domain
      config {
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
      }
      dataConfig {
        reTargeting
        appIds
        tradeDeskIdentifier
        storageBucket
        semrushEnable
        semrushConfig {
          projectId
          rootDomainUrl
          rootKeyword
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        roasFormat
        defaultDisplayAttributionWindow
        defaultPaidSearchAttributionWindow
        defaultSEOAttributionWindow
        currency {
          currency
          conversionValue
        }
        tagProcessingConfig {
          processTag
        }
        paidSearchDataConfig {
          isEnabled
        }
      }
      paymentConfig {
        id
        providerId
        paymentProvider
      }
      brands(first: 2) {
        name
        logo {
          key
        }
      }
      name
      description
      locations(first: 5) {
        street
        city
        state
      }
      logo {
        id
        key
        url
      }
      signInLogo {
        id
        key
        url
      }
      roles(first: 50, where: { isMaster: true }) {
        id
        name
        roleItems(first: 15) {
          id
          feature
          actions
        }
        users(first: 30) {
          id
        }
        isMaster
      }
      level
      website
      platformDomain
      tagDomain
      parentOrg {
        name
        id
      }
      zone
      chatWootId
      cpm
    }
  }
`;

export default EDIT_ORG_SELF;
