import React, { useEffect } from "react";
import Logo from "../../../assets/logo/logo.png";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MessageInput,
  MessageList,
  Message,
  Avatar,
  MainContainer,
  ChatContainer,
} from "@chatscope/chat-ui-kit-react";
import useAIChatHandler from "../../../core/components/ai/hooks/useAIChatHandler";
import useChatInterfaceMessages from "./hooks/useChatInterfaceMessages";

const AIMainView = () => {
  const { messages, sendMessage, subscribeToNewMessages } = useAIChatHandler();
  const { chatMessages, sendChatMessage } = useChatInterfaceMessages({
    messages,
    onSendChatMessage: sendMessage,
  });

  // Subscribe to New messages on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => subscribeToNewMessages(), []);

  const handleSendMessage = async (_innerHTML, textContent) => {
    sendChatMessage(textContent);
  };

  return (
    <div style={{ height: "90vh" }}>
      <MainContainer>
        <ChatContainer>
          <MessageList autoScrollToBottom scrollBehavior="smooth">
            {chatMessages.map((message, index) => (
              <Message key={index} model={message}>
                <Avatar src={Logo} size="md" />
              </Message>
            ))}
          </MessageList>

          <MessageInput
            onSend={handleSendMessage}
            attachButton={false}
            placeholder="Type a message..."
          ></MessageInput>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default AIMainView;
