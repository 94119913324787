import gql from "graphql-tag";

const ADD_LOCATION_NOTES = gql`
  mutation updateLocationNotes($id: ID!, $notes: String, $hasNotes: Boolean) {
    updateLocation(
      where: { id: $id }
      data: { notes: $notes, hasNotes: $hasNotes }
    ) {
      id
      hasNotes
      notes
    }
  }
`;

export default ADD_LOCATION_NOTES;
