import React, { useState, useEffect } from "react";
import { Modal, Switch, Button, Typography } from "antd";

const { Text } = Typography;

const TrafficSourceModal = ({
  onUpdate,
  initialSources,
  initialCombineLowTraffic,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trafficSources, setTrafficSources] = useState(initialSources);
  const [combineLowTraffic, setCombineLowTraffic] = useState(
    initialCombineLowTraffic
  );

  useEffect(() => {
    setTrafficSources(initialSources);
    setCombineLowTraffic(initialCombineLowTraffic);
  }, [initialSources, initialCombineLowTraffic]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onUpdate(trafficSources, combineLowTraffic);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTrafficSources(initialSources);
    setCombineLowTraffic(initialCombineLowTraffic);
  };

  const handleSourceToggle = (source, checked) => {
    setTrafficSources(prev => ({
      ...prev,
      [source]: { ...prev[source], visible: checked },
    }));
  };

  const formatSourceName = source => {
    if (source.toLowerCase() === "sms") {
      return "SMS";
    }
    return source.charAt(0).toUpperCase() + source.slice(1);
  };

  return (
    <>
      <Button onClick={showModal}>Traffic Source Setup</Button>
      <Modal
        title="TRAFFIC SOURCE SETUP"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={350}
      >
        <Text style={{ display: "block", marginBottom: "16px" }}>
          Toggle which sources should appear in reports:
        </Text>
        {Object.entries(trafficSources).map(([source, { visible }]) => (
          <div
            key={source}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <Switch
              checked={visible}
              onChange={checked => handleSourceToggle(source, checked)}
              style={{ marginRight: "12px" }}
            />
            <Text strong>{formatSourceName(source)}</Text>
          </div>
        ))}
        <div style={{ marginTop: "16px", marginBottom: "16px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "4px",
            }}
          >
            <Switch
              checked={combineLowTraffic}
              onChange={checked => setCombineLowTraffic(checked)}
              style={{ marginRight: "12px", marginTop: "4px" }}
            />
            <div>
              <Text strong style={{ display: "block" }}>
                Combine Low-Traffic Sources
              </Text>
              <Text
                type="secondary"
                style={{ fontSize: "12px", display: "block", marginTop: "4px" }}
              >
                Combine all traffic sources reporting under 5% of total traffic
                into 'Other Sources'
              </Text>
            </div>
          </div>
        </div>
        <Button type="primary" onClick={handleOk} style={{ width: "100%" }}>
          Update
        </Button>
      </Modal>
    </>
  );
};

export default TrafficSourceModal;
