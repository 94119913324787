import gql from "graphql-tag";

const LIST_CAMPAIGNORDERS_CONNECTION = gql`
  query campaignOrdersConnection(
    $first: Int
    $skip: Int
    $orderBy: CampaignOrderOrderByInput
    $where: CampaignOrderWhereInput!
    $currentUsersOrg: ID!
  ) {
    campaignOrdersConnection(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          status
          signUpCampaign
          startDate
          endDate
          attributionWindow
          budget
          cpm
          aggregateData {
            overallData {
              aggEvents
              aggCost
              aggTotalCost
              aggImpressions
              aggClicks
              aggVWI
              totalObservations
              signupsOverallData
            }
            dayPacingData {
              aggEvents
              aggCost
              aggTotalCost
              aggImpressions
              aggClicks
              aggVWI
              totalObservations
            }
            byDate
            topCreatives
            topPublishers
          }
          pacingData {
            id
            pace
            impressionsLeft
            expectedImpressions
            roas
            ctr
            dailyAvgImpressions
            dailyAvgImpressionsLeft
            daysIntoFlight
            daysLeft
          }
          createdAt
          updatedAt
          campaigns(first: 30) {
            cpm
            data
          }
          attributionTargets {
            id
          }
          transactionTarget
          transactionsConfig {
            appId
            cart
            traffId
            gtmAccess
            gtmStatus
            tracksBasketItems
          }
          isRequestChange
          orgs(first: 25, where: { parentOrg_some: { id: $currentUsersOrg } }) {
            id
            name
          }
        }
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(first: 5000, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default LIST_CAMPAIGNORDERS_CONNECTION;
