import { useEffect, useState } from "react";
import moment from "moment";

const getThisQuarter = moment()
  .utc()
  .startOf("quarter");

const getPreviousQuarter = moment()
  .utc()
  .subtract(1, "Q")
  .startOf("quarter");

export const ranges = {
  Yesterday: [
    moment()
      .utc()
      .subtract(1, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last 7 Days": [
    moment()
      .utc()
      .subtract(7, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last Week": [
    moment()
      .utc()
      .subtract(1, "week")
      .startOf("week"),
    moment()
      .utc()
      .subtract(1, "week")
      .endOf("week"),
  ],
  "This Month": [
    moment()
      .utc()
      .startOf("month")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last Month": [
    moment()
      .utc()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .utc()
      .startOf("month")
      .subtract(1, "month")
      .endOf("month"),
  ],
  "Last 30 Days": [
    moment()
      .utc()
      .subtract(30, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "This Quarter": [getThisQuarter.startOf("day"), moment().endOf("day")],
  "Last Quarter": [getPreviousQuarter.startOf("day"), moment().endOf("day")],
  "All Time": [],
};

/**
 * This hook is for byDate charts
 * Mainly applicable for objects with xAxis as date
 */
function useByDateFilter(data) {
  const [rangeKey, setRangeKey] = useState("Custom");
  const [startDateEndDate, setStartDateEndDate] = useState(
    ranges["Last 7 Days"]
  );
  const [filteredData, setFilteredData] = useState(data);
  const [customDateRange, setCustomDateRange] = useState([]);

  useEffect(() => {
    if (rangeKey && Object.keys(ranges).indexOf(rangeKey) >= 0) {
      setRangeKey(rangeKey);
    }
  }, [data, rangeKey]);

  useEffect(() => {
    function filterData(startDate, endDate) {
      // filter data to get only between range dates
      const x = data.filter(obj => {
        if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
        return obj.date >= startDate && obj.date <= endDate;
      });
      return x;
    }

    if (customDateRange && customDateRange.length === 2) {
      try {
        const getSelectedStartDate = moment(customDateRange[0]).unix();
        const getSelectedEndDate = moment(customDateRange[1]).unix();
        setFilteredData(filterData(getSelectedStartDate, getSelectedEndDate));
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, customDateRange]);

  useEffect(() => {
    if (data && data.length > 0) {
      function filterData(startDate, endDate) {
        // filter data to get only between range dates
        const x = data.filter(obj => {
          if (typeof obj.date === "string") obj.date = moment(obj.date).unix();
          return obj.date >= startDate && obj.date <= endDate;
        });
        return x;
      }
      const getDate = data.map(a => ({
        timestamp: a.date,
        date: a.xAxis,
      }));

      // sort them by desc dates
      getDate.sort(function(a, b) {
        return a.timestamp - b.timestamp;
      });

      // get startdate and end date
      const startDate = getDate[0].date; // last 30 days
      const endDate = getDate.slice(-1)[0].date;

      // combined start date & end date
      const startEndDate = [moment.utc(startDate), moment.utc(endDate)];
      setStartDateEndDate(startEndDate);
      setFilteredData(
        filterData(startEndDate[0].unix(), startEndDate[1].unix())
      );
    }
  }, [data]);

  return {
    rangeKey,
    setRangeKey,
    filteredData,
    customDateRange,
    setCustomDateRange,
    setStartDateEndDate,
    startDateEndDate,
  };
}

export { useByDateFilter };
