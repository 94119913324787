import React from "react";
import SIGNAL_TAGS from "../../../GraphQl/Queries/SIGNAL_TAGS";
import { useMutation, useQuery } from "react-apollo";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import IMPRESSION_TAG_CREATE from "../../../GraphQl/Mutations/IMPRESSION_TAG_CREATE";
import GET_DSP from "../../../GraphQl/Queries/GET_DSP";

//This loader handles all reusable queries for SEO
const SignalTagsMainLoader = props => {
  const { currentUser } = props;
  const currentOrgId = currentUser.role.org.id;

  const wherePublisher = {
    AND: [
      {
        clicksTag: {
          id_not: null,
        },
      },
      {
        transactionsTag: {
          id_not: null,
        },
      },
    ],
    OR: [
      {
        sharedOrgs_some: {
          org: {
            id: currentOrgId,
          },
        },
      },
      {
        sharedOrgs_some: {
          org: {
            parentOrg_some: { id: currentOrgId },
          },
        },
      },
    ],
  };

  const whereAdvertiser = {
    OR: [
      {
        sharedOrgs_some: {
          org: {
            id: currentOrgId,
          },
        },
      },
      {
        sharedOrgs_some: {
          org: {
            parentOrg_some: { id: currentOrgId },
          },
        },
      },
    ],
  };

  const whereImpressionTag = {
    orgs_some: {
      id: currentOrgId,
    },
    isAdvertiser: true,
  };

  const whereMeasurementsTag = {
    OR: [
      {
        orgs_some: {
          id: currentOrgId,
        },
      },
      {
        orgs_some: {
          parentOrg_some: {
            id: currentOrgId,
          },
        },
      },
    ],
  };

  const publisherDomainTags = {
    OR: [
      {
        orgs_some: {
          id: currentOrgId,
        },
      },
      {
        orgs_some: {
          parentOrg_some: {
            id: currentOrgId,
          },
        },
      },
    ],
    isPublisherDomain: true,
  };

  const { data, loading, refetch } = useQuery(SIGNAL_TAGS, {
    variables: {
      wherePublisher,
      whereAdvertiser,
      whereImpressionTag,
      whereMeasurementsTag,
      publisherDomainTags,
    },
    fetchPolicy: "no-cache",
  });

  const [createImpressionTag] = useMutation(IMPRESSION_TAG_CREATE, {
    onCompleted: () => {
      refetch();
    },
  });

  const { data: advertisers, loading: dspLoading } = useQuery(GET_DSP);

  if (loading || !data || dspLoading) return <OverlaySpinner />;
  return React.cloneElement(props.children, {
    ...props.children.props,
    data,
    loading,
    refetch,
    createImpressionTag,
    currentOrgId,
    advertisers,
  });
};

export default SignalTagsMainLoader;
