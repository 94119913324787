import gql from "graphql-tag";
import { CampaignOrderQueueSummaryFields } from "../Fragments/CampaignOrderFragments";

const GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_PENDING = gql`
  ${CampaignOrderQueueSummaryFields}

  query campaignOrdersQueue(
    $name: String
    $status: [CampaignStatus!]
    $orgId: ID!
    $orderBy: CampaignOrderOrderByInput = updatedAt_DESC
    $skip: Int = 0
    $after: String
    $before: String
    $first: Int = 10
    $last: Int
    $isLaunchProgrammatically: Boolean
    $startDate: DateTime
    $endDate: DateTime
    $currentDate: DateTime
  ) {
    campaignOrdersConnection: campaignOrdersConnection(
      where: {
        status_in: $status
        AND: [
          { name_contains: $name }
          { isLaunchProgrammatically: $isLaunchProgrammatically }
          { createdAt_gte: $startDate }
          { createdAt_lte: $endDate }
          { endDate_gte: $currentDate }
          {
            orgs_some: {
              OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }]
            }
          }
        ]
      }
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...CampaignOrderQueueSummaryFields
        }
      }
    }
    full: campaignOrdersConnection(
      where: {
        status_in: $status
        AND: [
          { name_contains: $name }
          { isLaunchProgrammatically: $isLaunchProgrammatically }
          { createdAt_gte: $startDate }
          { createdAt_lte: $endDate }
          { endDate_gte: $currentDate }
          {
            orgs_some: {
              OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAIGN_ORDER_QUEUE_SUMMARY_PENDING;
