import { useContext } from "react";
import OrgContext from "./OrgProvider";

export const useOrgContext = () => {
  const context = useContext(OrgContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
