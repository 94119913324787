import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TargetingMainLoader from "../../../../core/components/targeting/main/TargetingMainLoader";
import TargetingMainController from "../../../../core/components/targeting/main/TargetingMainController";
import TargetingMainView from "../main/TargetingMainView";

const TargetingRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <TargetingMainLoader {...props}>
            <TargetingMainController>
              <TargetingMainView />
            </TargetingMainController>
          </TargetingMainLoader>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default TargetingRoutes;
