import React from "react";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import compose from "lodash/flowRight";
import { determineUserAccess } from "../../../permissions/userLevelAccess";

import PaidSearchCampaignBingController from "./PaidSearchCampaignBingController";

import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import PAID_SEARCH_CAMPAIGN_BING from "../../../GraphQl/Queries/PAID_SEARCH_CAMPAIGN_BING";

import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";

const PaidSearchCampaignLoader = props => {
  const { match, currentUser } = props;
  const { params } = match;
  const { id, campaignId } = params;

  const config = currentUser.permission;
  const orgConfig = determineUserAccess(config);

  const {
    data: paidSearchCampaign,
    loading: loadingCampaign,
    error: errorCampaign,
  } = useQuery(PAID_SEARCH_CAMPAIGN_BING, {
    variables: { orgId: id, campaignId: campaignId },
  });

  if (errorCampaign) {
    console.error(errorCampaign);
  }

  if (loadingCampaign) return <OverlaySpinner />;

  return (
    <PaidSearchCampaignBingController
      paidSearchCampaign={paidSearchCampaign}
      orgConfig={orgConfig}
      {...props}
    >
      {props.children}
    </PaidSearchCampaignBingController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(PaidSearchCampaignLoader));
