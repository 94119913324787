import gql from "graphql-tag";
const UPDATE_USER_CAMPAIGN_TABLE_DISPLAY_COLUMNS = gql`
  mutation updateUser($id: ID, $displayColumns: [String!]) {
    updateUser(
      where: { id: $id }
      data: { campaignTableDisplayColumns: { set: $displayColumns } }
    ) {
      id
      campaignTableDisplayColumns
    }
  }
`;

export default UPDATE_USER_CAMPAIGN_TABLE_DISPLAY_COLUMNS;
