import React from "react";
import { Tabs } from "antd";
import { TabText } from "../../../../../shared/globalStyling/styledText";
import SummaryTab from "./tabs/summary/SummaryTab";
import CampaignsTab from "./tabs/campaigns/CampaignsTab";

const { TabPane } = Tabs;

const GoogleTab = ({
  history,
  selectedTab,
  location,
  summary,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  orgConfig,
  revenueLocations,
  config,
  onChangeLocationDropdown,
  onChangeMonthRange,
  defaultSummaryDates,
  kpiPercentage,
  selectedLocations,
  dataConfig,
  campaigns,
  possibleFields,
  selectedTableColumns,
  orgId,
  total,
  current,
  pageSize,
  currencyFormat,
  roasFormat,
  id,
}) => {
  return (
    <Tabs
      activeKey={selectedTab}
      onChange={selected => {
        let searchParams = new URLSearchParams(location.search);
        if (searchParams.get("subTab")) searchParams.delete("subTab");
        searchParams.append("subTab", selected);
        history.push(`${location.pathname}?${searchParams.toString()}`);
      }}
    >
      <TabPane tab={<TabText text={"Summary"} />} key="summary">
        <SummaryTab
          data={summary}
          bySummaryRangeDate={bySummaryRangeDate}
          setBySummaryRangeDate={setBySummaryRangeDate}
          orgConfig={orgConfig}
          revenueLocations={revenueLocations}
          config={config}
          onChangeLocationDropdown={onChangeLocationDropdown}
          onChangeMonthRange={onChangeMonthRange}
          defaultSummaryDates={defaultSummaryDates}
          kpiPercentage={kpiPercentage}
          selectedLocations={selectedLocations}
          dataConfig={dataConfig}
          currencyFormat={currencyFormat}
          roasFormat={roasFormat}
        />
      </TabPane>
      <TabPane tab={<TabText text={"Campaigns"} />} key="campaign">
        <CampaignsTab
          data={campaigns}
          possibleFields={possibleFields}
          history={history}
          selectedTableColumns={selectedTableColumns}
          orgId={orgId}
          total={total}
          current={current}
          pageSize={pageSize}
          summaryRangeDates={bySummaryRangeDate}
          defaultRangeDates={defaultSummaryDates}
          currencyFormat={currencyFormat}
          roasFormat={roasFormat}
          id={id}
        />
      </TabPane>
    </Tabs>
  );
};

export default GoogleTab;
