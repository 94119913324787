import React from "react";

// Ant Design
import { Button, List, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

const { Title } = Typography;

const SearchResultsView = props => {
  const {
    subCategories,
    searchValues,
    onCategoryClick,
    listItemStyle,
    selectedCategory,
    selectedStyle,
    selectedFontStyle,
    titleFontStyle,
    setSearchValues,
  } = props;

  return (
    <div style={{ marginTop: "1.5em", marginLeft: "1.5em" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <Title level={5}>
          {subCategories && subCategories.length === 1 ? "result" : "results"}{" "}
          found for <b>{searchValues}</b>
        </Title>
        <Button onClick={() => setSearchValues("")}>Clear</Button>
      </div>

      <List
        size="large"
        dataSource={subCategories}
        renderItem={item => (
          <List.Item
            style={{
              cursor: "pointer",
              ...listItemStyle,
              ...(selectedCategory && selectedCategory.name === item.name
                ? selectedStyle
                : {}),
            }}
            onClick={() => {
              onCategoryClick(item);
            }}
          >
            <List.Item.Meta
              title={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    titleFontStyle,
                    ...(selectedCategory && selectedCategory.name === item.name
                      ? selectedFontStyle
                      : {}),
                  }}
                >
                  <span>{item.name}</span>
                  <span>
                    {item.categoryName && item.categoryName.toUpperCase()}
                    <span style={{ marginRight: "0.5em" }}></span>
                    <RightOutlined />
                  </span>
                </span>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default SearchResultsView;
