import React from "react";
import { withApollo } from "react-apollo";
import { Row, Col } from "antd";

import useVASTTag from "./hooks/useVASTTag";
import TagComponent from "./components/TagComponent";
import TagActions from "./components/TagActions";

const VASTTagBase = () => {
  const {
    adId,
    setAdId,
    adSystem,
    setAdSystem,
    adTitle,
    setAdTitle,
    duration,
    setDuration,
    trackingEvent,
    setTrackingEvent,
    clickThrough,
    setClickThrough,
    mediaFile,
    setMediaFile,
    generatedTag,
    setGeneratedTag,
    generateSnippet,
    vast,
  } = useVASTTag();

  const reset = () => setGeneratedTag(null);
  const onKeyDown = event => event.key === "Enter" && generateSnippet();

  return (
    <div>
      <h3>VAST Tag Tracker</h3>
      <p>
        Fill out the following fields to generate a VAST tag to be entered into
        the code on the website you would like to track. VAST stands for video
        ad serving templates. VAST is defined by the{" "}
        <a
          href="https://www.iab.com/guidelines/vast/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Interactive Advertising Bureau
        </a>{" "}
        (IAB) as a template for structuring ad tags. These tags serve both video
        and audio ads to media players using an XML schema. VAST tags also
        transfer the critical metadata about said ads from the ad server to the
        media player.
      </p>
      <p>
        Simply, a VAST tag is a third-party ad script generated by an ad server
        specifically for displaying video ads. Essentially, VAST enables video
        players and ad servers to sync up so that advertisers can serve their
        ads onto multiple ad inventories within various video ad players.
      </p>
      <TagComponent
        generatedTag={generatedTag}
        adId={adId}
        setAdId={setAdId}
        adSystem={adSystem}
        setAdSystem={setAdSystem}
        adTitle={adTitle}
        setAdTitle={setAdTitle}
        duration={duration}
        setDuration={setDuration}
        trackingEvent={trackingEvent}
        setTrackingEvent={setTrackingEvent}
        clickThrough={clickThrough}
        setClickThrough={setClickThrough}
        mediaFile={mediaFile}
        setMediaFile={setMediaFile}
        vast={vast}
        onKeyDown={onKeyDown}
      />

      <Row type="flex" justify="center" style={{ width: "100%" }}>
        <Col style={{ padding: "0 10px" }}>
          <TagActions
            reset={reset}
            generateSnippet={generateSnippet}
            generatedTag={generatedTag}
          />
        </Col>
      </Row>
    </div>
  );
};

export const VASTTag = withApollo(VASTTagBase);
