import React from "react";
import moment from "moment";
import { Card, Col, Form, Input, Row, Space, Table, Typography } from "antd";
import { campaignTypeMap } from "../../../core/utils/constants/constants";
import { formatCurrency2SigFig } from "../../../core/utils/campaigns";

import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import "./styles.css";

const { Title, Text } = Typography;

const CampaignRequestorReviewDetails = ({ form, editable = false }) => {
  const { selectedAdvertiser } = useCampaignRequestorContext();
  const name = form.getFieldValue("name");
  const description = form.getFieldValue("description");
  const campaignType = form.getFieldValue("type");
  const startDate = form.getFieldValue("startDate");
  const endDate = form.getFieldValue("endDate");
  const budget = form.getFieldValue("budget");
  const campaigns = form.getFieldValue("campaigns");

  return (
    <Card title="DETAILS & BUDGET ALLOCATION">
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Advertiser</Text>
              <Text>{selectedAdvertiser && selectedAdvertiser.name}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Campaign Name</Text>
              <Text>{name}</Text>
            </Space>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Description</Text>
              <Text>{description}</Text>
            </Space>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Campaign Type</Text>
              <Text>{campaignTypeMap[campaignType]}</Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Duration</Text>
              <Text>{`${moment(startDate).format("MM/DD/YYYY")} - ${moment(
                endDate
              ).format("MM/DD/YYYY")}`}</Text>
            </Space>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Text type="secondary">Campaign Budget</Text>
              <Form.Item name="budget">
                <Text>{formatCurrency2SigFig(budget)}</Text>
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <div className="requestor-table-wrapper" style={{ marginTop: 20 }}>
              <div className="requestor-table-header">
                <Row>
                  <Col span={12}>
                    <Title level={5}>BUDGET ALLOCATION</Title>
                  </Col>
                </Row>
              </div>
              <Form.List name="campaigns">
                {fields => {
                  return (
                    <Table
                      rowKey="id"
                      columns={[
                        {
                          title: "#",
                          dataIndex: "index",
                          key: "index",
                          render: (text, record, index) => index + 1,
                        },
                        {
                          title: "Line Item Name",
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: "Duration",
                          dataIndex: "duration",
                          key: "duration",
                          render: (_, record) =>
                            `${moment(record.startDate).format(
                              "MM/DD/YYYY"
                            )} - ${moment(record.endDate).format(
                              "MM/DD/YYYY"
                            )}`,
                        },
                        {
                          title: "Imps.",
                          dataIndex: "impressions",
                          key: "impressions",
                          render: text => (text || 0).toLocaleString(),
                        },
                        {
                          title: "Budget",
                          dataIndex: "budget",
                          key: "budget",
                          width: "20%",
                          render: (_, __, index) => {
                            return (
                              <>
                                <Space size={0}>
                                  <Form.Item
                                    {...fields[index]}
                                    name={[index, "budgetTotal"]}
                                    fieldKey={[index, "budgetTotal"]}
                                  >
                                    <Input
                                      style={{
                                        width: "100%",
                                      }}
                                      prefix="$"
                                      disabled={
                                        campaigns.length === 1 || !editable
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...fields[index]}
                                    name={[index, "budgetPercentage"]}
                                    fieldKey={[index, "budgetPercentage"]}
                                  >
                                    <Input
                                      style={{
                                        width: "100%",
                                      }}
                                      suffix="%"
                                      disabled={
                                        campaigns.length === 1 || !editable
                                      }
                                    />
                                  </Form.Item>
                                </Space>
                              </>
                            );
                          },
                        },
                      ]}
                      dataSource={campaigns || []}
                      pagination={false}
                      className="requestor-table"
                      summary={() => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3}>
                            {campaigns.length} Line Items
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {campaigns
                              .reduce(
                                (acc, campaign) => acc + campaign.impressions,
                                0
                              )
                              .toLocaleString()}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} s>
                            {formatCurrency2SigFig(
                              campaigns.reduce(
                                (acc, campaign) =>
                                  acc + parseFloat(campaign.budgetTotal),
                                0
                              )
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    />
                  );
                }}
              </Form.List>
            </div>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default CampaignRequestorReviewDetails;
