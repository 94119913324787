import React from "react";
import { Menu, Input, Typography } from "antd";

function ModalContents() {
  const [menuKey, setMenuKey] = React.useState("REST");

  const curl = `curl 'https://graphql.dmp.cnna.io/' -H 'Accept-Encoding: gzip, deflate, br' -H 'Content-Type: application/json' -H 'Accept: application/json' -H 'Connection: keep-alive' -H 'DNT: 1' --data-binary '{"query":"mutation optout { createOptout(data: {ipAddress: \\"IPADDRESSVALUE\\", maid: \\"MAIDVALUE\\", token: \\"TOKENVALUE\\"}) {    id    token    maid    ipAddress  } } "}' --compressed`;

  return (
    <div>
      <Menu
        onClick={e => {
          setMenuKey(e.key);
        }}
        mode="horizontal"
        selectedKeys={[menuKey]}
      >
        <Menu.Item key="REST">REST API</Menu.Item>
        <Menu.Item key="CURL">CURL</Menu.Item>
      </Menu>
      <br />
      <div>
        <h1>Pre-requisites:</h1>
        <li>
          <ul>
            <b>Token</b> - Please get in touch with us to get this
          </ul>
          <ul>
            <b>Endpoint URL</b> - the URL endpoint of our GraphQL server is{" "}
            <a
              href="http://graphql.dmp.cnna.io/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://graphql.dmp.cnna.io/
            </a>
          </ul>
        </li>
        <br />
        <h1>Arguments:</h1>
        <p>
          Our Opt-out API Endpoint takes in MAID or IP address as the
          identifiers to process opt outs. Both of these are optional so you may
          send both or one of them for a single request. The TOKEN param is a
          token given to you from the mediajel team to access our endpoint.
        </p>
        <li>
          <ul>
            <b>@REQUIRED | TOKEN</b> - Each partner is assigned a unique token
            that are unique.
          </ul>
          <ul>
            <b>@OPTIONAL | MAID</b> - better known as mobile app Ids. if a user
            decides to opt-out by submitting their MAID, the value should be
            substituted here.
          </ul>
          <ul>
            <b>@OPTIONAL | IP Address</b> - if a user decides to opt-out by
            submitting their IP address, the value should be substituted here.
          </ul>
        </li>{" "}
      </div>
      {menuKey === "REST" && (
        <div>
          <h3>REST API Usage Guide:</h3>
          <br />
          <p>
            Please refer below to our{" "}
            <a
              href="https://codesandbox.io/s/mediajel-rest-api-example-bgkrq?file=/src/index.js"
              rel="noopener noreferrer"
              target="_blank"
            >
              Code sandbox Link
            </a>{" "}
            It provides a drag-and-drop solution on using our GraphQL API
            endpoint using the native javascript fetch API
          </p>
          <iframe
            src="https://codesandbox.io/embed/mediajel-rest-api-example-bgkrq?fontsize=14&hidenavigation=1&initialpath=%2Findex.js&theme=dark&editorsize=65&hidedevtools=0&codemirror=1&highlights=6,10&view=split"
            style={{
              width: "100%",
              height: "70vh",
              border: "0",
              borderRadius: "4px",
              overflow: "hidden",
            }}
            title="Mediajel-Rest-API-Example"
            allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
            sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
          ></iframe>
        </div>
      )}
      {menuKey === "CURL" && (
        <div>
          <h3>CURL Usage Guide:</h3>
          <br />
          <p>
            You May send us Opt-outs through CURL. Please copy and paste the
            curl command below and swap out the values of <b>"MAIDVALUE"</b>,{" "}
            <b>"IPADDRESSVALUE"</b>, and <b>"TOKENVALUE."</b>
          </p>
          <div style={{ position: "relative" }}>
            <Input.TextArea
              rows={4}
              value={curl}
              style={{
                color: "#bde6fb",
                backgroundColor: "#191f22",
                fontSize: 16,
              }}
            />
            <Typography.Paragraph
              copyable={{ text: curl }}
              style={{ position: "absolute", top: "-30px", right: "20px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalContents;
