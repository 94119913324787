import React from "react";
import { withRouter } from "react-router-dom";
import PaginationTable from "../../../shared/PaginationTable";
import { Row, Col, Tooltip, Progress, Spin } from "antd";
import moment from "moment";
import { formatWholeNumber } from "../../../../core/utils/campaigns";

// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../shared/globalStyling/styledText";

const Frag = React.Fragment;

const SegmentsAudienceTable = ({
  combineSyncSegments,
  countCombineSegments,
  onPage,
  history,
  orderByFilter,
  whereFilter,
  selectTableRow,
  selectedGeoTargets,
  location,
  loadingGeoTargets,
}) => {
  const [pageSize, setPageSize] = React.useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState(
    selectedGeoTargets.map(row => row.id)
  );

  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: text => {
        if (!text) {
          return <div />;
        }
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "start",
      key: "daterange",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start">
              {record.start && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.end && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Devices"} />,
      dataIndex: "uniqueDevices",
      key: "uniqueDevices",
      width: "15%",
      render: (text, record) =>
        record.uniqueDevices ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },
    {
      title: <TableColumnTitle text={"Observations"} />,
      dataIndex: "observations",
      key: "observations",
      width: "15%",
      render: (text, record) =>
        record.observations ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },
    {
      title: <TableColumnTitle text={"Type"} />,
      dataIndex: "type",
      key: "type",
      width: "20%",
      render: (text, record) =>
        record.__typename ? (
          <PrimaryTableRowText text={record.__typename} />
        ) : (
          <PrimaryTableRowText text="-" />
        ),
    },
  ];

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedRows = combineSyncSegments.filter(row =>
      newSelectedRowKeys.includes(row.id)
    );
    selectTableRow(selectedRows, true, true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: `${String(selectedRowKeys.length).length * 2}`,
  };

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <Spin spinning={loadingGeoTargets}>
        <PaginationTable
          loadingGeoTargets={loadingGeoTargets}
          rowSelection={rowSelection}
          data={combineSyncSegments.map(segments => {
            return { ...segments, key: segments.id };
          })}
          total={countCombineSegments}
          columns={columns}
          scroll={{ x: 700 }}
          pageSize={pageSize}
          paginationSize={"default"}
          size={"default"}
          onChange={pagination => {
            const { current } = pagination;
            let newPageSize = pagination.pageSize;

            setPageSize(newPageSize);
            if (location.pathname.includes("audiences")) {
              const query = new URLSearchParams(location.search);
              history.push(
                `/audiences/create-audience/new/${current}/${orderByFilter}/${whereFilter}?type=${query.get(
                  "type"
                )}&pageSize=${newPageSize}`
              );
            } else {
              history.push(
                `/segments/new/${current}/${orderByFilter}/${whereFilter}`
              );
            }
          }}
          current={onPage}
          bordered={false}
        />
      </Spin>
    </div>
  );
};

export default withRouter(SegmentsAudienceTable);
