import gql from "graphql-tag";

const DELETE_MEDIA = gql`
  mutation deleteMedia($id: ID!) {
    deleteMedia(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_MEDIA;
