import { useEffect, useState } from "react";
import moment from "moment";

const getThisQuarter = moment()
  .utc()
  .startOf("quarter");

const getPreviousQuarter = moment()
  .utc(true)
  .subtract(1, "Q")
  .startOf("quarter");

export const ranges = {
  Yesterday: [
    moment()
      .subtract(1, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last 7 Days": [
    moment()
      .subtract(7, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last Week": [
    moment()
      .subtract(1, "week")
      .startOf("week"),
    moment()
      .utc()
      .subtract(1, "week")
      .endOf("week"),
  ],
  "This Month": [
    moment()
      .startOf("month")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .startOf("month")
      .subtract(1, "month")
      .endOf("month"),
  ],
  "Last 30 Days": [
    moment()
      .subtract(30, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "This Quarter": [getThisQuarter.startOf("day"), moment().endOf("day")],
  "Last Quarter": [
    getPreviousQuarter.startOf("day"),
    getThisQuarter.startOf("day"),
  ],
  "All Time": [],
};
function filterData(data, startDate, endDate, xAxis = "date") {
  // filter data to get only between range dates
  const x = data.filter(obj => {
    return (
      moment(obj[xAxis], "YYYY-MM-DD").isSameOrAfter(startDate) &&
      moment(obj[xAxis], "YYYY-MM-DD").isSameOrBefore(endDate)
    );
  });
  return x;
}

/**
 * This hook is for byDate charts
 * Mainly applicable for objects with xAxis as date
 */
function useByDateFilter(data, xAxis = "date", id, defaultStartEndDate) {
  const [rangeKey, setRangeKey] = useState("Custom");
  const [startDateEndDate, setStartDateEndDate] = useState(
    ranges["Last 7 Days"]
  );
  const [filteredData, setFilteredData] = useState(data);
  const [customDateRange, setCustomDateRange] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [currentId, setCurrentId] = useState();

  useEffect(() => {
    if (rangeKey && Object.keys(ranges).indexOf(rangeKey) >= 0) {
      setRangeKey(rangeKey);
    }
  }, [data, rangeKey]);

  //Date ranges change for the chart
  useEffect(() => {
    if (customDateRange && customDateRange.length === 2) {
      try {
        const utcStartDate = moment(customDateRange[0], "YYYY-MM-DD");
        const utcEndDate = moment(customDateRange[1], "YYYY-MM-DD");

        let filtered = filterData(data, utcStartDate, utcEndDate, xAxis);
        //Add blank data if no data is present for the date
        if (filtered.length === 0) {
          for (
            var m = utcStartDate;
            m.diff(utcEndDate, "days") <= 0;
            m.add(1, "days")
          ) {
            let blankData = {};
            blankData[xAxis] = m.format("YYYY-MM-DD");
            filtered.push(blankData);
          }
          //setEmptyData(true);
        } else setEmptyData(false);
        setFilteredData(filtered);
        setStartDateEndDate(customDateRange);
      } catch (e) {
        console.log(e);
      }
    } else {
      setEmptyData(false);
      setFilteredData(data);
    }
  }, [data, customDateRange, xAxis]);

  //Initial Render of the chart
  useEffect(() => {
    if (data && data.length > 0 && (!currentId || currentId !== id)) {
      const sortedByDate = data.sort(
        (a, b) =>
          moment(a[xAxis])
            .utc(true)
            .unix() -
          moment(b[xAxis])
            .utc(true)
            .unix()
      );

      // get startdate and end date
      const startDate = sortedByDate[0][xAxis]; // last 30 days
      const endDate = sortedByDate.slice(-1)[0][xAxis];

      // combined start date & end date
      const startEndDate = [moment(startDate), moment(endDate)];

      if (defaultStartEndDate && defaultStartEndDate.length === 2) {
        setStartDateEndDate(defaultStartEndDate);
      } else {
        setStartDateEndDate(startEndDate);
      }

      setFilteredData(
        filterData(data, startEndDate[0], startEndDate[1], xAxis)
      );
      setCurrentId(id);
    }
  }, [currentId, data, defaultStartEndDate, id, xAxis]);

  return {
    rangeKey,
    setRangeKey,
    filteredData,
    customDateRange,
    setCustomDateRange,
    setStartDateEndDate,
    startDateEndDate,
    emptyData,
  };
}

export { useByDateFilter };
