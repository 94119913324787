import React from "react";
import CommerceTab from "./CommerceTab";
import PaidSearchCommerceLoader from "../../../../../../core/components/paidSearch/paidsearchdetails/CommerceTab/PaidSearchCommerceLoader";

const CommerceDetailsView = props => {
  return (
    <PaidSearchCommerceLoader {...props}>
      <CommerceTab />
    </PaidSearchCommerceLoader>
  );
};

export default CommerceDetailsView;
