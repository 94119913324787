import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";

const ConversionLineChart = ({ data, scale }) => {
  return (
    <Chart height={400} data={data} scale={scale} forceFit>
      <Axis
        name=""
        title={{
          position: "end",
          offset: 15,
          textStyle: {
            fontSize: "12",
            textAlign: "center",
            fill: "#999",
            fontWeight: "bold",
            rotate: 0,
            autoRotate: true,
          },
        }}
      />
      <Axis
        name=""
        title={{
          position: "end",
          offset: 5.5,
          textStyle: {
            fontSize: "12",
            textAlign: "right",
            fill: "#999",
            fontWeight: "bold",
            rotate: 0,
          },
        }}
      />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom
        type="lineStack"
        position="date*conversions"
        size={2}
        tooltip={[
          "date*conversions",
          (date, conversions) => {
            return {
              name: "conversions",
              conversions: conversions,
              title: date,
            };
          },
        ]}
      />
    </Chart>
  );
};

export default ConversionLineChart;
