import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import FirstPartyDataMainLoader from "../../../../core/components/firstPartyData/main/FirstPartyDataMainLoader";
import FirstPartyDataMainController from "../../../../core/components/firstPartyData/main/FirstPartyDataMainController";
import FirstPartyDataMainView from "../main/FirstPartyDataMainView";
import FirstPartyDataDetailView from "../main/FirstPartyDataDetailView";

const FirstPartyDataRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <FirstPartyDataMainLoader {...props}>
            <FirstPartyDataMainController>
              <FirstPartyDataMainView />
            </FirstPartyDataMainController>
          </FirstPartyDataMainLoader>
        )}
      />
      <Route
        path={`${path}/details/:id`}
        exact
        render={() => (
          <FirstPartyDataMainLoader {...props}>
            <FirstPartyDataMainController>
              <FirstPartyDataDetailView />
            </FirstPartyDataMainController>
          </FirstPartyDataMainLoader>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default FirstPartyDataRoutes;
