import gql from "graphql-tag";

const CREATE_MESSAGE = gql`
  mutation createMessage(
    $personality: String
    $context: String
    $message: String!
    $userID: ID
    $currentOrgID: String
  ) {
    createMessage(
      data: {
        user: { connect: { id: $userID } }
        conversation: { create: { user: { connect: { id: $userID } } } }
        context: $context
        message: $message
        personality: $personality
        currentOrgID: $currentOrgID
      }
    ) {
      id
      isUser
      user {
        name
        roles {
          org {
            name
          }
        }
      }
      currentOrgID
      conversation {
        id
        messages {
          user {
            name
          }
          message
        }
      }
      message
      context
      personality
    }
  }
`;

export default CREATE_MESSAGE;
