import React from "react";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import TargetingDetails from "./components/TargetingDetails";

const Targeting = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  chartData,
  reachData,
}) => {
  const isEmptyData = reachData.every(data => data.IDENTIFIERS === 0);
  if (chartsLoading)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Card>
    );

  if (isEmpty(reachData) || isEmptyData) return <div />;

  const filteredData = reachData.filter(data => data.IDENTIFIERS !== 0);

  return (
    <TargetingDetails
      colStyle={colStyle}
      selectedCampaign={selectedCampaign}
      chartData={chartData}
      reachData={filteredData}
    />
  );
};

export default Targeting;
