import React from "react";
import { Card, Input } from "antd";

const AddSharingRecordModalForm = ({ onChange }) => {
  return (
    <Card>
      <h4>Input the id of the buyer.</h4>
      <Input onChange={e => onChange("memberId", Number(e.target.value))} />
    </Card>
  );
};

export default AddSharingRecordModalForm;
