import React, { useEffect, useState } from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { Card, DatePicker, Row } from "antd";
import { ExtraInfo } from "./ExtraInfo";
import {
  CardText,
  CardTitleText,
} from "../../../../../../shared/globalStyling/styledText";
import { formatWholeNumberNaN } from "../../../../../../../core/utils/campaigns";

const { RangePicker } = DatePicker;

const DateChart = ({
  data: byDate,
  geoDates: { start, end },
  customLabel,
  showTotalVisits = true,
  total,
  explanation = "",
}) => {
  const geoDatesDiff = end.diff(start, "days");
  const startDate = start.format("MMMM DD, YYYY");
  const endDate = end.format("MMMM DD, YYYY");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Transforming data to the required format
    const formattedData = Object.keys(byDate).map(date => ({
      Date: date,
      Count: byDate[date],
    }));
    setData(formattedData);
    setFilteredData(formattedData); // Initially set filteredData to full dataset
  }, [byDate]);

  const handleDateChange = dates => {
    if (dates && dates.length === 2) {
      // Filter data based on selected date range
      const filtered = data.filter(item => {
        const date = new Date(item.Date);
        return date >= dates[0] && date <= dates[1];
      });
      setFilteredData(filtered);
    } else {
      // If dates is null or not a valid array, reset filteredData to full dataset
      setFilteredData(data);
    }
  };

  return (
    <Card
      title={
        <CardTitleText
          text={`${customLabel ? customLabel : "Walk Ins"}: By Date`}
        />
      }
      style={{
        height: "34.6em",
      }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2%",
      }}
      headStyle={{ borderWidth: "2px" }}
      bordered
      extra={
        explanation ? (
          <ExtraInfo placement={"top"} title={explanation} />
        ) : (
          false
        )
      }
    >
      <Row type="flex" justify="center" style={{ marginBottom: "1%" }}>
        <CardText
          text={`${startDate} - ${endDate} (${geoDatesDiff} days) 
              ${
                showTotalVisits ? `- ${formatWholeNumberNaN(total)} Visits` : ""
              }`}
        />
      </Row>
      <Row type="flex" justify="center">
        <RangePicker onChange={handleDateChange} />
      </Row>
      <Chart
        height={350}
        data={filteredData}
        scale={{ value: { min: 0 } }}
        forceFit
      >
        <Axis name="Date" />
        <Axis name="Count" />
        <Tooltip crosshairs={{ type: "y" }} />
        <Geom type="lineStack" position="Date*Count" size={0} color="#b2d5ff" />
        <Geom type="areaStack" position="Date*Count" color="#b2d5ff" size={0} />
      </Chart>
    </Card>
  );
};

export default DateChart;
