import React from "react";
import { Select, Checkbox, Row, Col } from "antd";

const MultipleSelectDropdown = ({
  options = [],
  onChange,
  selectedLocations,
}) => {
  return (
    <div>
      <Select
        size="large"
        style={{ width: "250px" }}
        placeholder="Locations"
        dropdownRender={() => (
          <Row style={{ padding: 10 }}>
            <Col xs={24}>
              <Checkbox.Group
                onChange={onChange}
                defaultValue={[...options, "Others"]}
              >
                <Row>
                  {options.map(option => {
                    return (
                      <Col span={24} key={option}>
                        <Checkbox
                          value={option}
                          key={option}
                          disabled={
                            selectedLocations &&
                            selectedLocations.length === 1 &&
                            option === selectedLocations[0]
                          }
                        >
                          {option}
                        </Checkbox>
                      </Col>
                    );
                  })}
                  <Col span={24} key={"Others"}>
                    <Checkbox
                      value="Others"
                      key={"Others"}
                      disabled={
                        selectedLocations &&
                        selectedLocations.length === 1 &&
                        selectedLocations[0] === "Others"
                      }
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};

export default MultipleSelectDropdown;
