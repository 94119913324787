import React from "react";
import { Input, Checkbox, Row, Col } from "antd";
import CopyableTextField from "../../../../../../shared/CopyableTextField";

const GeneratedTagComponent = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <CopyableTextField value={generatedTag} />
      <br />
    </React.Fragment>
  );
};

const AppIdInputField = ({
  appId,
  setAppId,
  onKeyDown,
  withRetailId,
  setWithRetailId,
  retailId,
  setRetailId,
}) => {
  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Col style={{ padding: "5px 0" }}>
        <h4>Enter the AppId of where the tag will be located</h4>
        <p>
          AppId definition: The name of the Application or Website. eg: MediaJel
          -- Must only contain alphanumeric characters and no spaces.
        </p>
      </Col>
      <Col span={24} style={{ padding: "5px 0" }}>
        <Input
          placeholder="AppId"
          value={appId}
          onChange={({ target }) => setAppId(target.value)}
          onKeyDown={onKeyDown}
        />
      </Col>
      <Col span={24} style={{ padding: "5px 0" }}>
        {appId !== "LiquidM" && (
          <Checkbox
            checked={withRetailId}
            onChange={({ target }) => setWithRetailId(target.checked)}
          >
            <h5>With Retail Segregation</h5>
          </Checkbox>
        )}
        {withRetailId && (
          <Input
            placeholder="RetailId"
            value={retailId}
            onChange={({ target }) => setRetailId(target.value)}
            onKeyDown={onKeyDown}
          />
        )}
      </Col>
      <Col style={{ padding: "5px 0" }}>
        <p>
          After proper placement of this tag, the tracker will be available via
          the global window object and can be used to invoke any of the
          supported methods in the javascript tracker. Note: If this tag is used
          within the HTML body tag, its scope will be limited to the page on
          which it resides.
        </p>
      </Col>
    </Row>
  );
};

const TagComponent = ({
  generatedTag,
  appId,
  setAppId,
  onKeyDown,
  environment,
  withRetailId,
  setWithRetailId,
  retailId,
  setRetailId,
}) => {
  return generatedTag ? (
    <GeneratedTagComponent generatedTag={generatedTag} />
  ) : (
    <AppIdInputField
      appId={appId}
      setAppId={setAppId}
      onKeyDown={onKeyDown}
      environment={environment}
      withRetailId={withRetailId}
      setWithRetailId={setWithRetailId}
      retailId={retailId}
      setRetailId={setRetailId}
    />
  );
};

export default TagComponent;
