import React from "react";
import { useQuery } from "@apollo/react-hooks";
import PaidSearchBingController from "./PaidSearchBingController";
import PAID_SEARCH_BING from "../../../../GraphQl/Queries/PAID_SEARCH_BING";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";

const PaidSearchBingLoader = ({ children, ...props }) => {
  const id = props.id;
  const searchParams = new URLSearchParams(props.location.search);
  let bingWhere = {
    AND: [],
  };

  const searchBing = searchParams.get("searchBing");
  if (searchBing) bingWhere["AND"].push({ name_contains: searchBing });

  const statusBing = searchParams.get("statusBing");
  if (statusBing) bingWhere["AND"].push({ status: statusBing.toUpperCase() });

  const excludedBing = searchParams.get("excludedBing");
  if (excludedBing) bingWhere["AND"].push({ name_not_contains: excludedBing });

  let { data, loading: loadingBing, error: errorBing } = useQuery(
    PAID_SEARCH_BING,
    {
      variables: {
        id: props.id,
        bingWhere,
      },
    }
  );

  if (errorBing) return console.log(errorBing);
  if (loadingBing) return <OverlaySpinner />;

  if (props.orgConfig.isDemo) {
    data.paidSearchSummary.paidSearchBingCampaigns = data.paidSearchSummary.paidSearchBingCampaigns.map(
      (c, i) => {
        c.name = `Bing Campaign Example ${i}`;
        return c;
      }
    );
  }

  return (
    <PaidSearchBingController data={data.paidSearchSummary} {...props} id={id}>
      {children}
    </PaidSearchBingController>
  );
};

export default PaidSearchBingLoader;
