import { message } from "antd";
import {
  invalidImageDimensionErrorMessage,
  validDimensions,
} from "../../../core/utils/constants/constants";

const dimensionValidation = (imageWidth, imageHeight, bulkUpload = false) => {
  const isValidDimension = validDimensions.some(
    dim => dim.width === imageWidth && dim.height === imageHeight
  );

  if (isValidDimension) {
    return true;
  } else {
    if (!bulkUpload) {
      message.error(invalidImageDimensionErrorMessage);
    }
    return false;
  }
};

export default dimensionValidation;
