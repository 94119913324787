// Packages
import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import GET_ATTRIBUTION_REPORTS_FOR_ALL_CAMPAIGNS from "../GraphQl/Queries/GET_ATTRIBUTION_REPORTS_FOR_ALL_CAMPAIGNS.js";
import GET_AUDIENCE_DEVICES from "../GraphQl/Queries/GET_AUDIENCE_DEVICES";
import GET_AUDIENCE_DEVICES_S3 from "../GraphQl/Queries/GET_AUDIENCE_DEVICES_S3";
import DISTRIBUTE_AUDIENCE_FROM_S3 from "../GraphQl/Queries/DISTRIBUTE_AUDIENCE_FROM_S3";
import REQUEST_DISPLAY_REPORT_FULL from "../GraphQl/Mutations/REQUEST_DISPLAY_REPORT_FULL";
import { notification, Progress, message } from "antd";
import GET_CAMPAIGN_REPORTS from "../GraphQl/Queries/GET_CAMPAIGN_REPORTS";
import { buildCSV } from "../utils/campaigns";
import { isEmpty } from "lodash";
import JSZip from "jszip";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import useRegisterSnowplow from "../../platform/shared/snowplow/";

export const GlobalContext = React.createContext({
  csvExportLoading: false,
  getReportsClicked: () => {},
  getAudiencesDevices: () => {},
  requestDisplayReportFull: () => {},
});

const openNotification = percentage => {
  notification.open({
    key: "progress",
    message: "Exporting data from campaigns to CSV.",
    duration: percentage && percentage < 100 ? 0 : 1,
    description: <Progress percent={percentage || 0} />,
  });
};

const GlobalContextComponent = ({ children, currentUser, client }) => {
  useRegisterSnowplow({ currentUser });
  const [csvExportLoading, setCsvExportLoading] = useState(false);

  const [getReports] = useLazyQuery(GET_ATTRIBUTION_REPORTS_FOR_ALL_CAMPAIGNS, {
    fetchPolicy: "no-cache",
    onCompleted: async data => {
      if (data && data.campaignOrders) {
        let lineItems = [];
        let ids = data.campaignOrders.reduce((acc, order) => {
          if (order.reports.length) {
            acc.push(order.reports[0].key);
          }
          return acc;
        }, []);

        if (ids.length < 1) {
          message.error("No Campaigns with Attribution data were selected");
          return;
        }

        const query = await client.query({
          query: GET_CAMPAIGN_REPORTS,
          variables: {
            reportId: ids,
          },
          cachePolicy: "no-cache",
        });

        if (query) {
          for (const [
            i,
            url,
          ] of query.data.getCampaignReportSignedURLs.entries()) {
            openNotification(Math.round((100 * (i + 1)) / ids.length));
            const response = await fetch(url.url);
            const json = await response.json();
            lineItems = lineItems.concat(
              json.walkinByLineItemCreativeDate || []
            );
          }
        }
        buildCSV(lineItems, currentUser.role.org.name);
      } else {
        message.error("Something went wrong. Please try again later.");
      }
      setCsvExportLoading(false);
    },
    onError: error => {
      message.destroy();
      message.error("Something went wrong. Please try again later.");
      console.log(error);
      setCsvExportLoading(false);
    },
  });

  const [getAudienceIds] = useLazyQuery(GET_AUDIENCE_DEVICES, {
    fetchPolicy: "no-cache",
    onCompleted: (data, ...rest) => {
      if (
        data &&
        data.getAudienceDevices &&
        data.getAudienceDevices.deviceIds.length > 0
      ) {
        buildCSV(
          data.getAudienceDevices.deviceIds.map(row => {
            return { "Device ID": row };
          }),
          currentUser.role.org.name,
          "Audience"
        );
        message.destroy();
        message.success("Device IDs exported");
        setCsvExportLoading(false);
        return;
      }
      message.destroy();
      message.error("No Device IDs found. Contact Mediajel");
      setCsvExportLoading(false);
    },
    onError: error => {
      message.destroy();
      message.error("We are unable to export the Device IDs");
      console.log(error);
      setCsvExportLoading(false);
    },
  });

  const [getAudienceIdsS3] = useLazyQuery(GET_AUDIENCE_DEVICES_S3, {
    fetchPolicy: "no-cache",
    onCompleted: (data, ...rest) => {
      if (
        data &&
        data.getAudienceDevicesFromS3 &&
        data.getAudienceDevicesFromS3.deviceIds.length > 0
      ) {
        buildCSV(
          data.getAudienceDevicesFromS3.deviceIds.map(row => {
            return { "Device ID": row };
          }),
          currentUser.role.org.name,
          "Devices"
        );
        message.destroy();
        message.success("Device IDs exported");
        setCsvExportLoading(false);
        return;
      }
      message.destroy();
      message.error("No Device IDs found. Contact Mediajel");
      setCsvExportLoading(false);
    },
    onError: error => {
      message.destroy();
      message.error("We are unable to export the Device IDs");
      console.log(error);
      setCsvExportLoading(false);
    },
  });

  const [distributeAudienceIdsS3] = useLazyQuery(DISTRIBUTE_AUDIENCE_FROM_S3, {
    fetchPolicy: "no-cache",
    onCompleted: (data, ...rest) => {
      if (data && data.activateAudienceDevicesFromS3) {
        const { activateAudienceDevicesFromS3 } = data;
        if (activateAudienceDevicesFromS3) {
          const {
            ttdIdsSentTTD,
            devicesSentTTD,
            devicesSentLiquidM,
          } = activateAudienceDevicesFromS3;
          if (ttdIdsSentTTD && devicesSentTTD && devicesSentLiquidM) {
            message.destroy();
            message.success(
              "All Device IDs have been successfully distributed"
            );
            setCsvExportLoading(false);
          } else if (devicesSentLiquidM && !ttdIdsSentTTD && !devicesSentTTD) {
            message.destroy();
            message.warning(
              `Devices sent to deregulated Tier01 - Not all providers have received this audience. Please ensure this adveriser is propery configured for regulated tier 01 and re-actiate this audience.`,
              4
            );
            setCsvExportLoading(false);
          } else if (!devicesSentLiquidM && !ttdIdsSentTTD && !devicesSentTTD) {
            message.destroy();
            message.error("We are unable to distribute this audience");
            setCsvExportLoading(false);
          }
        } else {
          message.destroy();
          message.error("We are unable to distribute this audience");
          setCsvExportLoading(false);
        }
      }
    },
    onError: error => {
      message.destroy();
      message.error("We are unable to distribute this audience");
      console.log(error);
      setCsvExportLoading(false);
    },
  });

  const distributeAudiencesFromS3 = variables => {
    message.loading("Distributing Audience", 0);
    try {
      setCsvExportLoading(true);
      distributeAudienceIdsS3(variables);
    } catch (err) {
      message.destroy();
      message.error("Error exporting device ids");
      return false;
    }
  };

  const [displayReportFull] = useMutation(REQUEST_DISPLAY_REPORT_FULL);

  const getReportsClicked = variables => {
    setCsvExportLoading(true);
    getReports(variables);
  };

  const getAudiencesDevices = variables => {
    message.loading("Generating report...", 0);
    try {
      setCsvExportLoading(true);
      getAudienceIds(variables);
    } catch (err) {
      message.destroy();
      message.error("Error exporting device ids");
      return false;
    }
  };

  const getAudiencesDevicesFromS3 = variables => {
    message.loading("Retrieving Device Ids...", 0);
    try {
      setCsvExportLoading(true);
      getAudienceIdsS3(variables);
    } catch (err) {
      message.destroy();
      message.error("Error exporting device ids");
      return false;
    }
  };

  const requestDisplayReportFull = async (variables, exportName) => {
    message.loading("Generating report...", 0);

    try {
      const response = await displayReportFull(variables);
      if (response) {
        if (isEmpty(response.data.getFullDisplayReport)) {
          message.destroy();
          message.error("Selected Campaign is a Blank Campaign Report");
          return false;
        }
        const zip = new JSZip();
        const folder = zip.folder(exportName);
        folder.file(
          `${exportName}.csv`,
          Papa.unparse(response.data.getFullDisplayReport)
        );
        zip.generateAsync({ type: "blob" }).then(
          blob => {
            saveAs(blob, `${exportName}.zip`);
            message.destroy();
            message.success("Campaign Report downloaded");

            return true;
          },
          function(e) {
            message.destroy();
            message.error("Error: Could not write Campaign Report");
            console.log("error", e);
            return false;
          }
        );
      }
    } catch (E) {
      message.destroy();
      message.error("Error: Could not write Campaign Report");
      console.log("CSV error:", E);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        csvExportLoading,
        getReportsClicked,
        getAudiencesDevices,
        requestDisplayReportFull,
        getAudiencesDevicesFromS3,
        distributeAudiencesFromS3,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalContextWrapper = withApollo(GlobalContextComponent);
