import moment from "moment";
import { isEmpty, flatten } from "lodash";
import { formatCurrency2SigFig } from "../../../../../core/utils/campaigns";
import { startCase } from "lodash";
import Papa from "papaparse";

const DATE_FORMAT = "YYYY-MM-DD";

function ctrAsPct(value) {
  if (isFinite(value) && !isNaN(value)) {
    return `${Number(value).toFixed(3)}%`;
  }
  return "0%";
}

// remove cost
export function prepareByDateDisplayExport(data, rangeDate) {
  if (!isEmpty(rangeDate)) {
    // filter campaigns data to get only between range dates
    const startDate = moment(rangeDate[0]).format();
    const endDate = moment(rangeDate[1])
      .add(+1, "days")
      .format();
    data = data.filter(row => {
      return row.xAxis >= startDate && row.xAxis <= endDate;
    });
  }

  return data.map(d => ({
    Date: exportDateFormat(d.xAxis) || "--",
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
    Week: exportDateFormat(moment(d.xAxis).startOf("week")),
  }));
}

export function prepareByDayDisplayExport(data) {
  return data.map(d => ({
    Date: d.xAxis || "--",
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
  }));
}

export function prepareByDateSummaryExport(data, rangeDate, rangeType) {
  if (!isEmpty(rangeDate)) {
    let startDate = moment(rangeDate[0]).format();
    // filter campaigns data to get only between range dates
    if (rangeType !== "Custom") {
      startDate = moment(rangeDate[0])
        .subtract(1, "day")
        .format();
    }
    const endDate = moment(rangeDate[1]).format();

    data = data.filter(row => {
      return (
        moment(row.xAxis).format() >= startDate &&
        moment(row.xAxis).format() <= endDate
      );
    });
  }
  data =
    !isEmpty(data) &&
    data.sort((a, b) => moment(a.xAxis).unix() - moment(b.xAxis).unix());

  return data.map(d => ({
    Date: exportUnixToDateFormat(d.xAxis) || "--",
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
    Orders: d.transactionCount || 0,
    Revenue: formatCurrency2SigFig(d.transactionTotal) || 0,
    Observations: d.observations || 0,
    "Walk-Ins": d.walkIns || 0,
    Week: exportUnixToDateFormat(d.xAxis, true),
  }));
}

export function exportDateFormat(date) {
  return moment(date).format("YYYY-MM-DD");
}

/**   
 * Function to convert Unix Value to date format
  @param {String} date - expecting a unix value but sometimes it receives a date string format YYYY-MM-DD
  @param {Boolean} isWeek - flag to determine return false
  @return {String} returns a formatted date value YYYY-MM-DD
*/
export function exportUnixToDateFormat(date, isWeek = false) {
  // Validation to check the value if it is a Unix
  // or already a date to prevent Invalid date when converting the valu
  const dateValue = /^\d+$/.test(date)
    ? moment.unix(date)
    : moment(date, DATE_FORMAT);

  return !isWeek
    ? dateValue.format(DATE_FORMAT)
    : dateValue.startOf("week").format(DATE_FORMAT);
}

export function prepareByDeviceTypeDisplayExport(data) {
  return data.map(d => ({
    "Device Type": d.xAxis || "Unrecognized", // there are some blank xAxis
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
  }));
}

/**
 * Prepare Creative Data to be exported
 * @param {*} data { xAxis,clicks,impressions,ctr,creativeUrl,percentctr }
 * @param xAxis Creative Name
 * @returns
 */
export function prepareByCreativeDisplayExport(data) {
  return data.map(d => ({
    Name: d.xAxis || "--", // there are some blank xAxis
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
    "Creative URL": d.creativeUrl || "--",
  }));
}

/**
 *
 * @param {*} data  xAxis,clicks,impressions,ctr,imageUrl,extra
 */
export function prepareByPublisherDisplayExport(data) {
  return data.map(d => ({
    Name: d.xAxis || "--", // there are some blank xAxis
    Impressions: d.impressions || 0,
    Clicks: d.clicks || 0,
    CTR: ctrAsPct(d.ctr),
    "Image URL": d.imageUrl || "--",
    Extra: `${stringifyPusblisherExtra(d.extra)}` || "--",
  }));
}

/**
 * Making the Extra obj more readable in export
 * @param {*} obj
 * @returns
 */
export function stringifyPusblisherExtra(obj) {
  let extra = "";
  try {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "string") {
        extra += `${startCase(key)}: ${value} \n`;
      } else {
        // convert the value to string
        const firstKey = startCase(key);
        extra += `${firstKey}: `;
        for (const [k, v] of Object.entries(value)) {
          extra += `${k}: ${JSON.stringify(v)}`; // json stringify for safety
        }
        extra += "\n";
      }
    }
  } catch (err) {
    extra = "";
    console.log(err);
  }

  return extra;
}

/**
 *
 * @param {*} data  transactionId,transactionTotal,transactionTax,transactionShipping,
 * transaction,transactionCity,transactionZip,transactionState,
 * insertionOrder,attributionMethod,
 * completeFirstImpression,completeLastImpression,impressions,pageViews,totalImpressions
 * @returns
 */
export function prepareTransactionsWithEventsExport(
  data,
  dateRange,
  filteredData
) {
  // to fix issue where, if the
  let dataToExport;
  if (filteredData && !isEmpty(filteredData)) {
    dataToExport = filteredData;
  }
  // } else if (!isEmpty(dateRange)) {
  //   // filter transactions data to get only between range datess
  //   const startDate = moment(dateRange[0]).format();
  //   const endDate = moment(dateRange[1]).format();

  //   dataToExport = data.filter(obj => {
  //     return (
  //       moment(obj.transaction).format() >= startDate &&
  //       moment(obj.transaction).format() <= endDate
  //     );
  //   });
  // } else {
  //   dataToExport = data;
  // }

  dataToExport =
    !isEmpty(dataToExport) &&
    dataToExport.sort((a, b) =>
      moment(a.transaction).diff(moment(b.transaction))
    );
  return dataToExport.map(d => ({
    "Transaction ID": d.transactionId || "--", // there are some blank xAxis
    "Transaction Total": d.transactionTotal || 0,
    "Transaction Tax": d.transactionTax || 0,
    "Transaction Shipping": d.transactionShipping || 0,
    "Transaction Date": d.transaction || "--",
    "Transaction City": d.transactionCity || "--",
    "Transaction Zip": d.transactionZip || "--",
    "Transaction State": d.transactionState || "--",
    "Insertion Order": d.insertionOrder || "--",
    "Attribution Method":
      toStringAttributionMethod(d.attributionMethod) || "--",
    Impressions: JSON.stringify(d.impressions) || "--",
    "Complete First Impression":
      JSON.stringify(d.completeFirstImpression) || "--",
    "First Creative": JSON.stringify(d.completeFirstCreative) || "--",
    "Total Impressions": d.totalImpressions || 0,
    "Transaction Url": d.transactionPageUrl,
  }));
}

export function prepareTransactionsWithEventsDailyTotalExport(data) {
  let dataToExport;
  if (data && !isEmpty(data)) {
    dataToExport = data;
  }

  dataToExport =
    !isEmpty(dataToExport) &&
    dataToExport.sort((a, b) =>
      moment(a.transaction).diff(moment(b.transaction))
    );

  return dataToExport.map(d => ({
    "Transaction Date": d.transactionDate,
    "Number of Transactions": d.numberOfTransactions || 0,
    "Transaction Total": d.transactionTotal || 0,
    "Transaction Tax": d.transactionTax || 0,
    "Transaction Shipping": d.transactionShipping || 0,
  }));
}

export function prepareTransactionEventsCreatives(data) {
  const transactionsImpressions =
    data &&
    data.map(val => {
      return val && val.impressions;
    });

  const flattenTransactions = flatten(transactionsImpressions);

  const filteredData = flattenTransactions.reduce(
    (accumulator, currentItem) => {
      if (
        !accumulator.find(item => item.CREATIVEID === currentItem.CREATIVEID)
      ) {
        accumulator.push(currentItem);
      }
      return accumulator;
    },
    []
  );

  const duplicateCounts = {};

  data.forEach(item => {
    const uniqueCreativeIds = new Set(
      item.impressions.map(impression => impression.CREATIVEID)
    );
    uniqueCreativeIds.forEach(id => {
      duplicateCounts[id] = duplicateCounts[id] ? duplicateCounts[id] + 1 : 1;
    });
  });

  const transactionTotalCounts = Object.entries(duplicateCounts).map(
    ([creativeId, totalTransactions]) => ({
      creativeId,
      totalTransactions,
    })
  );

  const ImpressionCounts = {};

  data.forEach(obj => {
    obj.impressions.forEach(impression => {
      const creativeId = impression.CREATIVEID;
      ImpressionCounts[creativeId] = (ImpressionCounts[creativeId] || 0) + 1;
    });
  });

  const impressionTotalCounts = Object.entries(
    ImpressionCounts
  ).map(([creativeId, impressions]) => ({ creativeId, impressions }));

  const mergedCounts = transactionTotalCounts.map(firstObj => {
    const matchingSecondObj = impressionTotalCounts.find(
      secondObj => secondObj.creativeId === firstObj.creativeId
    );
    return { ...firstObj, ...matchingSecondObj };
  });

  const mergedTransactions = filteredData.map(item => {
    const matchingItem = mergedCounts.find(
      arrItem => arrItem.creativeId === item.CREATIVEID
    );
    return {
      ...item,
      ...(matchingItem && {
        totalTransactions: matchingItem.totalTransactions,
        impressions: matchingItem.impressions,
      }),
    };
  });

  const formatTransactionData = mergedTransactions.map(val => {
    return {
      "CREATIVE ID": val.CREATIVEID,
      IMPRESSIONS: val.impressions,
      "TOTAL TRANSACTIONS": val.totalTransactions,
      "CREATIVE URL": val.CREATIVEURL,
      "TARGET URL": val.TARGETURL,
    };
  });

  return formatTransactionData;
}

export function prepareTransactionEventsPublishers(data) {
  const transactionsImpressions =
    data &&
    data.map(val => {
      return val && val.impressions;
    });

  const flattenTransactions = flatten(transactionsImpressions);

  const filteredData = flattenTransactions.reduce(
    (accumulator, currentItem) => {
      if (
        !accumulator.find(
          item => item.IMPRESSION_SITENAME === currentItem.IMPRESSION_SITENAME
        )
      ) {
        accumulator.push(currentItem);
      }
      return accumulator;
    },
    []
  );

  const duplicateCounts = {};

  data.forEach(item => {
    const uniqueCreativeIds = new Set(
      item.impressions.map(impression => impression.IMPRESSION_SITENAME)
    );
    uniqueCreativeIds.forEach(id => {
      duplicateCounts[id] = duplicateCounts[id] ? duplicateCounts[id] + 1 : 1;
    });
  });

  const transactionTotalCounts = Object.entries(duplicateCounts).map(
    ([siteName, totalTransactions]) => ({
      siteName,
      totalTransactions,
    })
  );

  const ImpressionCounts = {};

  data.forEach(obj => {
    obj.impressions.forEach(impression => {
      const siteName = impression.IMPRESSION_SITENAME;
      ImpressionCounts[siteName] = (ImpressionCounts[siteName] || 0) + 1;
    });
  });

  const impressionTotalCounts = Object.entries(
    ImpressionCounts
  ).map(([siteName, impressions]) => ({ siteName, impressions }));

  const mergedCounts = transactionTotalCounts.map(firstObj => {
    const matchingSecondObj = impressionTotalCounts.find(
      secondObj => secondObj.siteName === firstObj.siteName
    );
    return { ...firstObj, ...matchingSecondObj };
  });

  const mergedTransactions = filteredData.map(item => {
    const matchingItem = mergedCounts.find(
      arrItem => arrItem.siteName === item.IMPRESSION_SITENAME
    );
    return {
      ...item,
      ...(matchingItem && {
        totalTransactions: matchingItem.totalTransactions,
        impressions: matchingItem.impressions,
      }),
    };
  });

  const formatTransactionData = mergedTransactions.map(val => {
    return {
      "SITE NAME": val.IMPRESSION_SITENAME,
      IMPRESSIONS: val.impressions,
      "TOTAL TRANSACTIONS": val.totalTransactions,
    };
  });

  return formatTransactionData;
}

export function prepareTransactionsWithEventsExportSimplified(filteredData) {
  // to fix issue where, if the
  let dataToExport;
  if (filteredData && !isEmpty(filteredData)) {
    dataToExport = filteredData;
  }

  dataToExport =
    !isEmpty(dataToExport) &&
    dataToExport.sort((a, b) =>
      moment(a.transaction).diff(moment(b.transaction))
    );

  return dataToExport.map(d => ({
    "Transaction ID": d.transactionId || "--", // there are some blank xAxis
    "Transaction Total": d.transactionTotal || 0,
    "Transaction Tax": d.transactionTax || 0,
    "Transaction Shipping": d.transactionShipping || 0,
    "Transaction Date": d.transaction || "--",
    "Transaction City": d.transactionCity || "--",
    "Transaction Zip": d.transactionZip || "--",
    "Transaction State": d.transactionState || "--",
    "Insertion Order": d.insertionOrder || "--",
    "Attribution Method":
      toStringAttributionMethod(d.attributionMethod) || "--",
    "Total Impressions": d.totalImpressions || 0,
    "Transaction Url": d.transactionPageUrl || "",
  }));
}

export function toStringAttributionMethod(data) {
  let str = "";
  try {
    if (data.IP) {
      str += `IP: ${data.IP}`;
    } else {
      // just stringify for now
      // TODO check other attribution method values
      str += replaceJSONStrClutter(JSON.stringify(data));
    }
  } catch (err) {
    console.log(err);
  }
  return str;
}

export function replaceJSONStrClutter(str) {
  return str.replace(/[[]{\}]/g, "");
}

/**
 *
 * @param {*} data  xAxis,OBSERVATIONS,TO_DATE
 */
export function prepareByDateObservationsExport(data) {
  data =
    !isEmpty(data) &&
    data.sort((a, b) => {
      return (
        new Date(b[["TO_DATE(TIMESTAMP)"]]) -
        new Date(a[["TO_DATE(TIMESTAMP)"]])
      );
    });

  return data.map(d => ({
    Date: exportDateFormat(d.xAxis) || "--",
    Observations: d.OBSERVATIONS || 0,
    "To Date": d["TO_DATE(TIMESTAMP)"] || 0,
  }));
}

/**
 *
 * @param {*} data  attributionMethod, impressions, inserionOrder,
 *  pageViews, signUpId, totalImpressions
 */
export function prepareSignUpsWithImpressions(data, dateRange) {
  if (!isEmpty(dateRange)) {
    // filter transactions data to get only between range dates
    const startDate = moment(dateRange[0]).format();
    const endDate = moment(dateRange[1])
      .add(+1, "days")
      .format();

    data = data.filter(obj => {
      return (
        moment(obj.signups_tstamp).format() >= startDate &&
        moment(obj.signups_tstamp).format() <= endDate
      );
    });
  }

  data =
    !isEmpty(data) &&
    data.sort((a, b) =>
      moment(a.signups_tstamp).diff(moment(b.signups_tstamp))
    );

  return data.map(d => {
    return {
      "Signup ID": d.signupId,
      "Signup Time": d.signups_tstamp,
      "Insertion Order": d.insertionOrder,
      "Attribution Method": JSON.stringify(d.attributionMethod),
      Impressions: JSON.stringify(d.impressions),
      "Complete First Impression":
        JSON.stringify(d.completeFirstImpression) || "--",
      "First Creative": JSON.stringify(d.completeFirstCreative) || "--",
      "Total Impressions": d.totalImpressions || 0,
    };
  });
}

export function prepareInstallsWithImpressions(data) {
  // const dateToFilterBy = '2021-08-24'; //Campaign start date
  const dateToFilterBy = "2021-11-05";

  const filteredByDate = data.filter(item => {
    return moment(moment.unix(item.installs_tstamp)).isAfter(dateToFilterBy);
  });

  return filteredByDate.map(d => ({
    "Install ID / Nonce": d.installId,
    "Insertion Order ": d.insertionOrder,
    "Install time": d.installs_tstamp
      ? moment.unix(d.installs_tstamp).format("MM/DD/YYYY")
      : "N/A",
    "Attribution Method": JSON.stringify(d.attributionMethod),
    Impressions: JSON.stringify(d.impressions),
    "Page Views": JSON.stringify(d.pageViews),
    "Total Impressions": d.totalImpressions || 0,
  }));
}

export function preparefirstTransactionsWithImpressions(data) {
  //const dateToFilterBy = '2021-08-24'; //Campaign start date
  const dateToFilterBy = "2021-11-05";

  const filteredByDate = data.filter(item => {
    return moment(moment.unix(item.installs_tstamp)).isAfter(dateToFilterBy);
  });

  return filteredByDate.map(d => ({
    "First Transaction ID / Nonce": d.installId,
    "Insertion Order ": d.insertionOrder,
    "First transaction time": d.installs_tstamp
      ? moment.unix(d.installs_tstamp).format("MM/DD/YYYY")
      : "N/A",
    "Attribution Method": JSON.stringify(d.attributionMethod),
    Impressions: JSON.stringify(d.impressions),
    "Adjust event key": "First Order",
    "Total Impressions": d.totalImpressions || 0,
  }));
}

export function prepareOnspotWalkInReports(data) {
  // Extract data from the JSON object
  const startDate = data.geoStartDate || "N/A";
  const endDate = data.geoEndDate || "N/A";

  const attributionData = data.attribution || {};
  const dayOfWeekData = attributionData.dayOfWeek || [];
  const hourOfDayData = attributionData.hourOfDay || [];
  const frequencyData = attributionData.frequency || {};

  const observationsData = data.observations || {};
  const observationsByDate = observationsData.byDate || {};
  const observationsByDay = observationsData.byDay || [];
  const observationsByHour = observationsData.byHour || [];
  const observationsUniqueCount = observationsData.uniqueCount || 0;
  const observationsNonUniqueCount = observationsData.nonUniqueCount || 0;

  // Define CSV headers for each table
  const countsHeaders = ["Walk Ins", "Observations", "Unique Observations"];
  const byDateHeaders = ["Date", "Count"];
  const dayOfWeekHeaders = ["Day", "Count"];
  const hourOfDayHeaders = ["Hour", "Count"];
  const frequencyHeaders = [
    "1",
    "2",
    "3",
    "4 to 7",
    "8 to 15",
    "16 to 25",
    "26 or more",
  ];

  const formatDayOfWeekData = dayOfWeekData.map(week => {
    return {
      Count: week.count,
      Day: week.day,
    };
  });

  const hourMapping = {
    0: "12 am",
    1: "1 am",
    2: "2 am",
    3: "3 am",
    4: "4 am",
    5: "5 am",
    6: "6 am",
    7: "7 am",
    8: "8 am",
    9: "9 am",
    10: "10 am",
    11: "11 am",
    12: "12 pm",
    13: "1 pm",
    14: "2 pm",
    15: "3 pm",
    16: "4 pm",
    17: "5 pm",
    18: "6 pm",
    19: "7 pm",
    20: "8 pm",
    21: "9 pm",
    22: "10 pm",
    23: "11 pm",
  };

  const formatHourOfDayData = hourOfDayData.map(item => ({
    Hour: hourMapping[item.hour],
    Count: item.count,
  }));

  const formatObservationsbyDateData =
    Object.keys(observationsByDate).length > 0
      ? Object.entries(observationsByDate).map(([date, count]) => ({
          Date: date,
          Count: count,
        }))
      : [];

  const formatObservationsDayOfWeekData = observationsByDay.map(week => {
    return {
      Count: week.count,
      Day: week.day,
    };
  });

  const formatObservationsHourOfDayData = observationsByHour.map(item => {
    return {
      Hour: hourMapping[item.hour],
      Count: item.count,
    };
  });

  // Convert data to CSV format using Papa Parse
  const countsCSV = Papa.unparse({
    fields: countsHeaders,
    data: [
      attributionData.count,
      observationsNonUniqueCount,
      observationsUniqueCount,
    ],
  });

  const walkInDayOfWeekCSV = Papa.unparse({
    fields: dayOfWeekHeaders,
    data: formatDayOfWeekData,
  });

  const walkInHourOfDayCSV = Papa.unparse({
    fields: hourOfDayHeaders,
    data: formatHourOfDayData,
  });

  const walkInfrequencyCSV = Papa.unparse({
    fields: frequencyHeaders,
    data: [
      [
        frequencyData.one,
        frequencyData.two,
        frequencyData.three,
        frequencyData.fourToSeven,
        frequencyData.eightToFifteen,
        frequencyData.SixteenToTwentyFive,
        frequencyData.TwentySixOrMore,
      ],
    ],
  });

  const observationsByDateCSV = Papa.unparse({
    fields: byDateHeaders,
    data: formatObservationsbyDateData,
  });

  const observationsDayOfWeekCSV = Papa.unparse({
    fields: dayOfWeekHeaders,
    data: formatObservationsDayOfWeekData,
  });

  const observationsHourOfDayCSV = Papa.unparse({
    fields: hourOfDayHeaders,
    data: formatObservationsHourOfDayData,
  });

  // Combine CSV data into one CSV file with beautified format
  const combinedCSV = `
  *** Start Date: ${startDate} / End Date: ${endDate} ***

   *** Counts ***
   ${countsCSV}

   *** Walk Ins: Frequency Times of Visit ***
   ${walkInfrequencyCSV}

   *** Walk Ins: Day of Week ***
   ${walkInDayOfWeekCSV}

   *** Walk Ins: Hour of Day ***
   ${walkInHourOfDayCSV}

   *** Unique Observations: Day of Week ***
   ${observationsDayOfWeekCSV}

   *** Unique Observations: Hour of Day ***
   ${observationsHourOfDayCSV}

   *** Unique Observations: By Date ***
   ${observationsByDateCSV}
 `;

  return combinedCSV;
}

// Remove line items temporarily
// export function prepareByDateDisplayLineItemsExport(data, rangeDate) {
//   if (!isEmpty(rangeDate)) {
//     // filter campaigns data to get only between range dates
//     const startDate = moment(rangeDate[0]).format();
//     const endDate = moment(rangeDate[1])
//       .add(+1, "days")
//       .format();
//     data = data.filter(row => {
//       return row.xAxis >= startDate && row.xAxis <= endDate;
//     });
//   }

//   return data.map(d => ({
//     Name: d.xAxis || "--",
//     Impressions: d.impressions || 0,
//     Clicks: d.clicks || 0,
//     CTR: ctrAsPct(d.ctr),
//     "Creative URL": d.creativeUrl || "--",
//   }));
// }
export function exportDropdownKeyToText(key) {
  const walkInText = "Walk In - ";
  const ObservationsText = "Observations - ";
  const DisplayText = "Display - ";
  const TransactionText = "Transaction - ";
  const SummaryText = "Summary - ";
  const byDayText = "By Day of Week";
  const byDateText = "By Date";
  const SignUps = "Signups - ";

  switch (key) {
    case "walkInByDayOfWeek":
      key = walkInText + byDayText;
      break;
    case "walkInByDate":
      key = walkInText + byDateText;
      break;
    case "walkinByCreative":
      key = walkInText + "By Creative";
      break;
    case "walkinByLineItem":
      key = walkInText + "By LineItem";
      break;
    case "walkinByLineItemCreativeDate":
      key = walkInText + "By LineItem Creative Date";
      break;
    case "walkInByCity":
      key = walkInText + "By City";
      break;
    case "walkinByLocationComplete":
      key = walkInText + "By Location Complete";
      break;
    case "observationsByDate":
      key = ObservationsText + byDateText;
      break;
    case "observationsByGeoTarget":
      key = ObservationsText + "By GeoTarget";
      break;
    case "byDate":
      key = DisplayText + byDateText;
      break;
    case "byDay":
      key = DisplayText + byDayText;
      break;
    case "deviceType":
      key = DisplayText + "By Device Type";
      break;
    case "creatives":
      key = DisplayText + "By Creatives";
      break;
    case "publishers":
      key = DisplayText + "By Publishers";
      break;
    case "transactionsWithEvents":
      key = TransactionText + "With Events";
      break;
    case "transactionsWithEventsDailyTotal":
      key = TransactionText + "With Events (Daily Total)";
      break;
    case "transactionEventsCreatives":
      key = TransactionText + "With Events - (Creatives)";
      break;
    case "transactionEventsPublishers":
      key = TransactionText + "With Events - (Publishers)";
      break;
    case "completeAttributionSummary":
      key = SummaryText + byDateText;
      break;
    case "signUpsWithImpressions":
      key = SignUps + "With Impressions";
      break;
    case "lineItems":
      key = DisplayText + "Line Items";
      break;
    case "onspotWalkInReports":
      key = walkInText + "V2 Reports";
      break;
    default:
      // do nothing
      return key;
  }
  // walkInByDayOfWeek
  return key;
}

export function detailBarTotalCompute(
  dateRangedData,
  bySummaryRangeDate,
  chartData
) {
  const totals = {
    impressions: 0,
    clicks: 0,
    walkIns: 0,
    observations: 0,
    transactions: 0,
    transactionTotalRevenue: 0,
    signUps: 0,
  };

  if (!isEmpty(dateRangedData)) {
    totals.impressions = dateRangedData.reduce((a, b) => a + b.impressions, 0);
    totals.clicks = dateRangedData.reduce((a, b) => a + b.clicks, 0);
    totals.walkIns = dateRangedData.reduce((a, b) => a + b.walkIns, 0);
    totals.observations = dateRangedData.reduce(
      (a, b) => a + b.observations,
      0
    );
    totals.transactions = dateRangedData.reduce(
      (a, b) => a + b.transactionCount,
      0
    );
    totals.transactionTotalRevenue = dateRangedData.reduce(
      (a, b) => a + b.transactionTotal,
      0
    );
    totals.observations = dateRangedData.reduce(
      (a, b) => a + b.observations,
      0
    );
    totals.signUps = dateRangedData.reduce((a, b) => a + b.signUps, 0);
    return totals;
  } else if (!isEmpty(bySummaryRangeDate)) {
    return totals;
  }

  try {
    totals.impressions = chartData.overallData
      ? chartData.overallData.impressions
      : 0;
    totals.clicks = chartData.overallData ? chartData.overallData.clicks : 0;
    totals.observations = chartData.overallData
      ? chartData.totalObservations
      : 0;
    totals.walkIns = chartData.overallData ? chartData.totalWalkIns : 0;
    totals.transactions = chartData.overallData
      ? chartData.transactionsOverallData.transactionsTotalCount
      : 0;
    totals.transactionTotalRevenue = chartData.overallData
      ? chartData.transactionsOverallData.transactionTotalRevenue
      : 0;
    totals.signUps = chartData.overallData ? chartData.totalSignups : 0;
  } catch (err) {
    console.log(err);
  }

  return totals;
}

export const cleanFormatByDateReport = arr => {
  if (!arr) {
    return [];
  }
  return arr.map(date => ({
    ...date,
    xAxis: moment(date.xAxis).format("YYYY-MM-DD"),
    date: moment(date.xAxis).unix(), // always retain this to use for processing
    clicks: date.clicks || 0,
    ctr: date.ctr || 0,
    impressions: date.impressions || 0,
  }));
};

export const processPercentage = (dates, currentData, allData, cpm) => {
  let daysDiff = moment(dates[1]).diff(moment(dates[0]), "days") || 0;
  if (daysDiff > 0) daysDiff++;
  const datesToCompare =
    [
      moment(dates[0]).subtract(daysDiff, "days"),
      moment(dates[1]).subtract(daysDiff, "days"),
    ] || [];

  const filterByDate =
    allData &&
    allData.filter(obj => {
      if (typeof obj.xAxis === "string") obj.xAxis = moment(obj.xAxis).unix();
      return (
        obj.xAxis >= datesToCompare[0].unix() &&
        obj.xAxis <= datesToCompare[1].unix()
      );
    });
  const previous =
    filterByDate &&
    filterByDate.length > 0 &&
    filterByDate.reduce(
      (conso, obj) => {
        conso.impressions = conso.impressions + Number(obj.impressions);
        conso.clicks = conso.clicks + Number(obj.clicks);
        conso.transactions = conso.transactions + Number(obj.transactionCount);
        conso.walkIns = conso.walkIns + Number(obj.walkIns);
        conso.observations = conso.observations + Number(obj.observations);
        conso.revenue = conso.revenue + Number(obj.transactionTotal);
        conso.signUps = conso.signUps + Number(obj.signUps);

        return conso;
      },
      {
        impressions: 0,
        clicks: 0,
        transactions: 0,
        walkIns: 0,
        observations: 0,
        revenue: 0,
        signUps: 0,
      }
    );

  const current =
    currentData.length > 0 &&
    currentData.reduce(
      (conso, obj) => {
        conso.impressions = conso.impressions + Number(obj.impressions);
        conso.clicks = conso.clicks + Number(obj.clicks);
        conso.transactions = conso.transactions + Number(obj.transactionCount);
        conso.walkIns = conso.walkIns + Number(obj.walkIns);
        conso.observations = conso.observations + Number(obj.observations);
        conso.revenue = conso.revenue + Number(obj.transactionTotal);
        conso.signUps = conso.signUps + Number(obj.signUps);

        return conso;
      },
      {
        impressions: 0,
        clicks: 0,
        transactions: 0,
        walkIns: 0,
        observations: 0,
        revenue: 0,
        signUps: 0,
      }
    );

  if (previous && current) {
    const mediaSpend = (previous.impressions / 1000) * cpm;
    const mediaSpendCurrent = (current.impressions / 1000) * cpm;

    const roas = previous.revenue
      ? Number(((previous.revenue / mediaSpend) * 100).toFixed(1)) || 0
      : 0;
    const roasCurrent = current.revenue
      ? Number((current.revenue / mediaSpendCurrent) * 100).toFixed(1) || 0
      : 0;

    previous.roas = roas;
    previous.spend = mediaSpend;
    current.roas = roasCurrent;
    current.spend = mediaSpendCurrent;

    return {
      percentages: {
        impressions: calculatePercentageDifference(
          current.impressions,
          previous.impressions
        ),
        clicks: calculatePercentageDifference(current.clicks, previous.clicks),
        transactions: calculatePercentageDifference(
          current.transactions,
          previous.transactions
        ),
        walkIns: calculatePercentageDifference(
          current.walkIns,
          previous.walkIns
        ),
        observations: calculatePercentageDifference(
          current.observations,
          previous.observations
        ),
        revenue: calculatePercentageDifference(
          current.revenue,
          previous.revenue
        ),
        signUps: calculatePercentageDifference(
          current.signUps,
          previous.signUps
        ),
        spend: calculatePercentageDifference(current.spend, previous.spend),
        roas: calculatePercentageDifference(current.roas, previous.roas),
      },
      previousValues: previous,
      ranges: `
            ${datesToCompare[0].format("MMM DD YYYY")} -
            ${datesToCompare[1].format("MMM DD YYYY")}
          `,
    };
  }

  return {
    percentages: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      walkIns: 0,
      observations: 0,
      revenue: 0,
      signUps: 0,
      roas: 0,
      spend: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      transactions: 0,
      walkIns: 0,
      observations: 0,
      revenue: 0,
      signUps: 0,
      roas: 0,
      spend: 0,
    },
    ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
  };
};

export const processPercentageDisplay = (dates, currentData, allData) => {
  let daysDiff =
    moment(dates[1], "MM/DD/YYYY").diff(
      moment(dates[0], "MM/DD/YYYY"),
      "days"
    ) || 0;
  if (daysDiff > 0) daysDiff++;
  const datesToCompare =
    [
      moment(dates[0], "MM/DD/YYYY").subtract(daysDiff, "days"),
      moment(dates[1], "MM/DD/YYYY").subtract(daysDiff, "days"),
    ] || [];

  const filterByDate = allData
    ? allData.filter(obj => {
        if (typeof obj.xAxis === "string") obj.xAxis = moment(obj.xAxis).unix();
        return (
          obj.xAxis >= datesToCompare[0].unix() &&
          obj.xAxis <= datesToCompare[1].unix()
        );
      })
    : [];

  const previous =
    filterByDate.length > 0 &&
    filterByDate.reduce(
      (conso, obj) => {
        conso.impressions = conso.impressions + Number(obj.impressions);
        conso.clicks = conso.clicks + Number(obj.clicks);

        return conso;
      },
      {
        impressions: 0,
        clicks: 0,
      }
    );

  const current =
    currentData.length > 0 &&
    currentData.reduce(
      (conso, obj) => {
        conso.impressions = conso.impressions + Number(obj.impressions);
        conso.clicks = conso.clicks + Number(obj.clicks);

        return conso;
      },
      {
        impressions: 0,
        clicks: 0,
      }
    );

  if (previous && current) {
    const prevCtr = (previous.clicks / previous.impressions) * 100 || 0;
    const currCtr = (current.clicks / current.impressions) * 100 || 0;
    previous.ctr = prevCtr;
    current.ctr = currCtr;
    return {
      percentages: {
        impressions: calculatePercentageDifference(
          current.impressions,
          previous.impressions
        ),
        clicks: calculatePercentageDifference(current.clicks, previous.clicks),
        ctr: calculatePercentageDifference(current.ctr, previous.ctr),
      },
      previousValues: previous,
      ranges: `
          ${datesToCompare[0].format("MMM DD YYYY")} -
          ${datesToCompare[1].format("MMM DD YYYY")}
        `,
    };
  }

  return {
    percentages: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
    },
    previousValues: {
      impressions: 0,
      clicks: 0,
      ctr: 0,
    },
    ranges: `
        ${datesToCompare[0].format("MMM DD YYYY")} -
        ${datesToCompare[1].format("MMM DD YYYY")}
      `,
  };
};

export const calculatePercentageDifference = (currentNumber, lastNumber) => {
  const increase =
    parseFloat(((currentNumber - lastNumber) / lastNumber) * 100).toFixed(1) ||
    0;

  if (increase < 0) {
    return (
      -parseFloat(((lastNumber - currentNumber) / lastNumber) * 100).toFixed(
        1
      ) || 0
    );
  }

  return increase;
};
