import gql from "graphql-tag";

const DELETE_EVENTS_TARGET = gql`
  mutation deleteEventsTarget($id: ID!) {
    deleteEventsTarget(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_EVENTS_TARGET;
