import gql from "graphql-tag";

const EDIT_LOCATION = gql`
  # TODO: Enhance payload handling for this mutation
  # @params: data: LocationUpdateInput!
  mutation updateLocation(
    $id: ID!
    $name: String!
    $description: String!
    $street: String!
    $city: String!
    $state: StateType
    $country: CountryType!
    $email: String!
    $url: String!
    $phone: String!
    $contact: String!
    $formattedAddress: String
    $zip: String!
    $cart: CartProvider
    $menuDataKey: String
    $locationKey: String
    $province: String
    $audienceType: AudienceType
  ) {
    updateLocation(
      data: {
        name: $name
        description: $description
        street: $street
        city: $city
        state: $state
        country: $country
        email: $email
        url: $url
        phone: $phone
        contact: $contact
        formattedAddress: $formattedAddress
        zip: $zip
        transConfig: {
          upsert: {
            create: { cart: $cart, menuDataKey: $menuDataKey }
            update: { cart: $cart, menuDataKey: $menuDataKey }
          }
        }
        locationKey: $locationKey
        province: $province
        audienceType: $audienceType
      }
      where: { id: $id }
    ) {
      id
      name
      description
      street
      city
      state
      province
      lat
      lng
      formattedAddress
      email
      phone
      contact
      url
      locationKey
      zip
      audienceType
      catalogs(first: 5) {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
      geoframe {
        id
        name
        start
        end
        polygons {
          id
          geometry
        }
        circles {
          id
          coordinate {
            lat
            lng
          }
          radius
        }
      }
      transConfig {
        id
        cart
        menuDataKey
      }
    }
  }
`;

export default EDIT_LOCATION;
