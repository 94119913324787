import gql from "graphql-tag";

const UPDATE_CAMPAIGN_ORDER_CHANGELOG = gql`
  mutation updateCampaignOrderChangeLog(
    $id: ID!
    $description: String!
    $userId: ID!
  ) {
    updateCampaignOrder(
      data: {
        changeLog: {
          create: {
            description: $description
            changedBy: { connect: { id: $userId } }
          }
        }
      }
      where: { id: $id }
    ) {
      id
      changeLog {
        id
        description
        createdAt
        changedBy {
          username
          avatar {
            key
          }
        }
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_CHANGELOG;
