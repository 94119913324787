export const getMobileOperatingSystem = userAgent => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad/.test(userAgent) && !window.MSStream) {
    return "iPad";
  }

  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return "iPhone";
  }

  if (/iPod/.test(userAgent) && !window.MSStream) {
    return "iPod";
  }

  if (/CrOS/.test(userAgent) && !window.MSStream) {
    return "Chromebook Computer";
  }

  if (/Macintosh/.test(userAgent)) {
    return "Apple Computer";
  }

  if (/Windows NT/.test(userAgent)) {
    return "Windows Computer";
  }

  //Default Response
  return "Computer";
};

export const determineAdServer = str => {
  //detects if page served is a known ad server
  if (
    str.includes("amazon-adsystem") ||
    str.includes("mopub") ||
    str.includes("adsbynimbus") ||
    str.includes("smartadserver") ||
    str.includes("localhost") ||
    str.includes("googlesyndication") ||
    str.includes("doubleclick") ||
    str.includes("inner-active.mobi") ||
    str.includes("nexage") ||
    str.includes("adcolony") ||
    str.includes("sdk")
  ) {
    return "Ad Server";
  } else if (
    str.includes("itunes") ||
    str.includes("play.google") ||
    str.includes("apps.apple.com")
  ) {
    return "Application";
  }

  return "Website";
};
