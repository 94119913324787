import { transformAudienceSchema } from "../utils/transformAudienceSchema";

/**
 * Retrieves the existing metric value from the selected metrics based on the given data mapping.
 *
 * @param {Object} dataMapping - An object containing a `statara` property used to match the metric.
 * @param {Array} selectedMetrics - An array of metric objects, where each object contains a `key` property.
 *
 * @returns {Object|undefined} The metric object from `selectedMetrics` that matches the `statara` value of `dataMapping`, or `undefined` if no match is found.
 */
export const getExistingMetricValue = (dataMapping, selectedMetrics) => {
  return selectedMetrics.find(data => data.key === dataMapping.statara);
};

/**
 * Extracts the primary metric from a metrics object.
 * Assumes that the backend only sends one metric, which is located at the first position.
 *
 * @param {Object} metrics - An object containing a `name` property and a `mappings` array of metric mappings.
 *
 * @returns {Object} An object containing:
 *  - `name`: The name of the metric.
 *  - `mappings`: The first element from the `mappings` array.
 *  - `key`: The first value found in the flattened `mappings` array.
 */
export const extractPrimaryMetric = metrics => {
  // Pop the first element of the array since the backend only sends one metric
  const flattenedArray = metrics.mappings.flatMap(obj => Object.values(obj));
  return {
    name: metrics.name,
    mappings: metrics.mappings[0],
    key: flattenedArray[0],
  };
};

/**
 * Finds and returns the matched dimension and its corresponding category.
 *
 * @param {Array} dimensions - An array of dimension objects, where each object contains a `mappings` property that is an array of mapping objects.
 * @param {Array} categories - An array of category objects, where each object contains a `name` property.
 * @param {string} metricKey - The key to match against the `providerSubCategory` property in the mappings array of each dimension.
 *
 * @returns {Object} An object containing the matched dimension and its corresponding category:
 *  - `matchedDimension`: The dimension object from `dimensions` that contains a mapping with a `providerSubCategory` equal to `metricKey`.
 *  - `category`: The category object from `categories` whose `name` matches the `category` property of the matched dimension.
 */
export const findCategoryAndSubCategory = (
  dimensions,
  categories,
  metricKey
) => {
  const matchedDimension = dimensions.find(item => {
    return (
      Array.isArray(item.mappings) &&
      item.mappings.some(mapping => mapping.providerSubCategory === metricKey)
    );
  });

  const newCategories = transformAudienceSchema(categories);

  return {
    matchedDimension: matchedDimension,
    category: Object.values(newCategories.groupedCategories).find(
      category => category.name === matchedDimension.category
    ),
  };
};

/**
 * Finds the selected subcategory within the given categories based on a metric key.
 *
 * This function searches through a list of categories, each containing a list of datapoints.
 * It looks for a datapoint that has a `mappings` array which includes at least one mapping
 * object with a `providerSubCategory` matching the provided `metricKey`.
 *
 * @param {Array} categories - An array of category objects. Each category object should have
 *                             a `datapoints` property which is an array of datapoint objects.
 * @param {string} metricKey - The key used to match against the `providerSubCategory` property
 *                             in the mapping objects within the datapoints.
 * @returns {Object|undefined} The first datapoint object that contains a mapping with a
 *                             `providerSubCategory` matching the `metricKey`. Returns `undefined`
 *                             if no matching datapoint is found.
 *
 * const metricKey = 'sai_activist_score';
 * const result = findSelectedSubCategory(categories, metricKey);
 * // result will be the datapoint object containing the mapping with providerSubCategory 'metric2'
 */
export const findSelectedSubCategory = (categories, metricKey) => {
  const selectedSubCategory = categories
    .flatMap(item => item.datapoints)
    .find(
      datapoint =>
        Array.isArray(datapoint.mappings) &&
        datapoint.mappings.some(
          mapping => mapping.providerSubCategory === metricKey
        )
    );

  return selectedSubCategory;
};
