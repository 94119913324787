import React from "react";
import EmailTab from "../../OrgConfig/components/EmailTab";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { Card, Tabs, Tooltip } from "antd";
import {
  UsergroupAddOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";

const TagsMainView = ({
  activateHashedEmailList,
  createHashedEmailList,
  updateHashedEmailList,
  bulkCreateHashedEmailAddress,
  hashedEmailLists,
  deleteHashedEmailList,
  refetchHashedEmailLists,
  loadingHashedEmailList,
  processDeviceIds,
}) => {
  //Title shown on browser tab
  document.title = "First Party Data Main View";

  const instructions =
    "After creating an Email List, make sure to click 'Activate Emails'. After the emails have been activated, you can now process the device IDs.";

  const titleDisplay = (
    <>
      <span style={{ marginRight: "0.4em" }}>EMAILS</span>
      <Tooltip title={instructions} placement="right">
        <QuestionCircleOutlined />
      </Tooltip>
    </>
  );

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<UsergroupAddOutlined />}
        title={"Audience"}
      />
      <PageContentLayout>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="First Party Data" key="1">
            <Card
              title={titleDisplay}
              bordered={false}
              style={{ minHeight: "700px" }}
            >
              <EmailTab
                activateHashedEmailList={activateHashedEmailList}
                createHashedEmailList={createHashedEmailList}
                updateHashedEmailList={updateHashedEmailList}
                bulkCreateHashedEmailAddress={bulkCreateHashedEmailAddress}
                hashedEmailLists={
                  !isEmpty(hashedEmailLists) ? hashedEmailLists : []
                }
                deleteHashedEmailList={deleteHashedEmailList}
                refetchHashedEmailLists={refetchHashedEmailLists}
                loadingHashedEmailList={loadingHashedEmailList}
                processDeviceIds={processDeviceIds}
              />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default TagsMainView;
