import React, { useState } from "react";
import { Empty, Tag, Spin, Input, Typography } from "antd";
import PaginationTable from "../../../../shared/PaginationTable";
import Button from "../../../../shared/globalStyling/Button";
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../shared/globalStyling/styledText";
import moment from "moment";

const { Text } = Typography;

export default function TargetingLocationAudiencesList({
  targetingAudiences,
  setSelectedRows,
  setAudienceAmplifyDefault,
  amplify,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);

  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "300px",
      render: (name, record) => {
        return (
          <>
            <PrimaryTableRowText text={name} />{" "}
            {record.isAmplifyDefault && amplify ? (
              <Tag color="green">Amplify Default</Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Locations",
      dataIndex: "geoTargets",
      key: "geoTargets",
      align: "left",
      render: (text, record) => {
        return record.geoTargets.map(target => {
          const location = target.name;

          return target.location ? (
            <Tag color="blue" key={target.id}>
              {location}
            </Tag>
          ) : null;
        });
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: text => {
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
    {
      title: <TableColumnTitle text={"Options"} />,
      dataIndex: "amplifySelect",
      key: "amplifySelect",
      width: "60px",
      align: "center",
      render: (text, record) => {
        return (
          <Button
            size="small"
            disabled={record.isAmplifyDefault}
            onClick={e => {
              e.stopPropagation();
              selectDefaultCreative(record);
            }}
          >
            Select as Amplify Default
          </Button>
        );
      },
    },
  ];

  const selectDefaultCreative = async record => {
    setLoading(true);
    await setAudienceAmplifyDefault(record);
    setLoading(false);
  };

  // hide column of amplify
  if (!amplify) {
    columns.pop();
  }

  // Filtering logic
  const filteredData = targetingAudiences.filter(audience =>
    audience.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const table = (
    <div>
      <Input
        placeholder="Search by name"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        style={{
          marginBottom: 16,
          width: 300, // Adjust the width as needed
          marginLeft: "0.4em",
        }}
      />
      <Spin tip="Loading..." spinning={isLoading}>
        <PaginationTable
          columns={columns}
          data={filteredData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )}
          bordered={false}
          paginationSize={"default"}
          scroll={{ x: 1200 }}
          size={"default"}
          total={filteredData && filteredData.length}
          current={currentPage}
          pageSize={pageSize}
          rowSelection={{
            type: "checkbox",
            getCheckboxProps: record => ({}),
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRows(selectedRows);
            },
          }}
          onChange={(paginationObject, filtersObject, sorterObject) => {
            setPageSize(paginationObject.pageSize);
            setCurrentPage(paginationObject.current);
          }}
          rowKey="id"
        />
      </Spin>
      <Text type="secondary" style={{ marginLeft: "0.4em" }}>
        Number of Rows: {targetingAudiences.length}
      </Text>
    </div>
  );

  return targetingAudiences && targetingAudiences.length ? table : <Empty />;
}
