import gql from "graphql-tag";

const DELETE_AUDIENCE_DEMOGRAPH = gql`
  mutation deleteAudienceDemograph($id: ID!) {
    deleteAudienceDemograph(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_AUDIENCE_DEMOGRAPH;
