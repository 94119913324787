import React from "react";
import { Row, Table, Card } from "antd";
import { isEmpty } from "lodash";

const style = {
  tableHeaderStyle: {
    color: "rgb(144, 142, 142)",
    fontWeight: 0,
    fontSize: "0.8em",
  },
};

const columns = [
  {
    title: () => <div style={style.tableHeaderStyle}>#</div>,
    width: "5%",
    dataIndex: "order",
    key: "order",
  },
  {
    title: () => <div style={style.tableHeaderStyle}>TARGET</div>,
    width: "20%",
    dataIndex: "target",
    key: "target",
  },
  {
    title: () => <div style={style.tableHeaderStyle}>ADDRESS</div>,
    width: "40%",
    dataIndex: "address",
    key: "address",
    render: (text, record, index) => {
      return (
        <div>
          <Row
            display="flex"
            align="middle"
            justify="center"
            style={{ textAlign: "center" }}
          >
            <div>{record.name}</div>
          </Row>
          <Row
            display="flex"
            align="middle"
            justify="center"
            style={{ fontSize: "10px", textAlign: "center" }}
          >
            <div>{record.address}</div>
          </Row>
        </div>
      );
    },
  },
  {
    title: () => <div style={style.tableHeaderStyle}>RADIUS</div>,
    key: "radius",
    width: "30%",
    render: (text, record) => {
      return (
        <div>
          {record.radius ? `${Math.round(record.radius / 1609.34)} miles` : "-"}
        </div>
      );
    },
  },
];

const getDataSource = geoTargets => {
  return geoTargets.map((elem, index) => {
    // If there is a polygon it is a geoframe
    // If all circles have same radius it is a place
    // If there is only an address it is a region
    let radius = isEmpty(elem.circles)
      ? null
      : elem.circles.every(circle => circle.radius === elem.circles[0].radius)
      ? elem.circles[0].radius
      : null;
    let target = "";
    let address = "";
    let name = "";
    let hasAddresses =
      elem && !isEmpty(elem.addresses) && elem.addresses.length > 1;
    if (hasAddresses) {
      target = "Region";
      name = elem.addresses[0];
      address = "Region";
      radius = null;
      // TODO use geojson mapping to figure out type of region
    } else if (radius && (!isEmpty(elem.circles) && hasAddresses)) {
      target = "Place";
      name = elem.circles[0].name;
      address = elem.addresses[0];
    } else if (radius && (!isEmpty(elem.circles) && hasAddresses)) {
      target = "Place";
      name = elem.circles[0].name;
      address = elem.addresses[0];
    } else if (
      radius &&
      (!isEmpty(elem.circles) && !isEmpty(elem.circles[0].address))
    ) {
      target = "Place";
      name = elem.circles[0].name;
      address = elem.circles.length > 1 ? "Multiple" : elem.circles[0].address;
    } else if (
      !isEmpty(elem.polygons) ||
      !elem.radius ||
      (!isEmpty(elem.circles) && isEmpty(elem.circles[0].address))
    ) {
      target = "GeoFrame";
      address = `${
        !isEmpty(elem.polygons) ? `${elem.polygons.length} Polygons` : ""
      }
      ${isEmpty(elem.circles) || isEmpty(elem.polygons) ? "" : " & "}
      ${!isEmpty(elem.circles) ? `${elem.circles.length} Circles` : ""}`;
      radius = null;
    }
    return {
      order: index + 1,
      key: index,
      radius,
      name,
      address,
      target,
    };
  });
};

const GeoTargetsReviewList = ({ geoTargets }) => {
  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Table
        pagination={false}
        dataSource={geoTargets ? getDataSource(geoTargets) : []}
        columns={columns}
        style={style.tableHeaderStyle}
        scroll={{ y: 300 }}
      />
    </Card>
  );
};

export default GeoTargetsReviewList;
