import { Card, Skeleton, Spin } from "antd";
import React from "react";

export const LoadingPage = ({ useSpinner = false, ...props }) => {
  const LoadingProps = () =>
    useSpinner ? (
      <Spin tip="Loading" {...props} />
    ) : (
      <Skeleton active paragraph={{ rows: 10 }} {...props} />
    );
  return (
    <Card>
      <LoadingProps />
    </Card>
  );
};
