import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Checkbox,
  Button,
  Menu,
  Dropdown,
  Input,
  Tooltip,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import {
  MenuItemText,
  ButtonText,
} from "../../../../../../../../shared/globalStyling/styledText";

const styles = {
  search: {
    fontSize: "14px",
    width: "100%",
  },
  excluded: {
    fontSize: "14px",
    width: "100%",
  },
  buttonS: {
    fontSize: "14px",
    width: "100%",
    height: "36px",
  },
  buttonL: {
    fontSize: "14px",
    width: "100%",
    height: "36px",
  },
};

const DropDownStatusToggle = withRouter(({ location, history, orgId, id }) => {
  const statuses = ["Ongoing", "Finished", "Unspecified"];
  const values = {
    Ongoing: "Serving",
    Finished: "Ended",
    Unspecified: "Unspecified",
  };

  const params = new URLSearchParams(location.search);
  const selectedStatus = params.get("status");
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedStatus]}>
          {statuses.map((status, i) => {
            const isSelected = selectedStatus === values[status].toLowerCase();
            return (
              <Menu.Item
                key={`${status}-${i}`}
                onClick={() => {
                  if (selectedStatus) params.delete("status");
                  if (!isSelected)
                    params.append("status", values[status].toLowerCase());
                  history.push(
                    `/paid_search/details/${id}/1?${params.toString()}`
                  );
                }}
              >
                <Checkbox
                  checked={isSelected}
                  style={{ visibility: isSelected ? "visible" : "hidden" }}
                />
                <MenuItemText text={status} />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Button
        style={styles.buttonL}
        shape="round"
        type={!!selectedStatus ? "primary" : "default"}
        ghost={!!selectedStatus ? true : false}
      >
        <ButtonText text={"Status"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const FiltersBar = withRouter(({ match, location, history, orgId, id }) => {
  const searchRef = useRef(null);
  const excludedRef = useRef(null);
  const params = new URLSearchParams(location.search);
  // returning warning for unused variable, but becomes a bug if removed
  //eslint-disable-next-line
  let excluded = "";

  const onSearch = (search, excluded) => {
    if (params.get("search")) params.delete("search");
    if (search) params.append("search", search);

    if (params.get("excluded")) params.delete("excluded");
    if (excluded) params.append("excluded", excluded);

    history.push(`/paid_search/details/${id}/1?${params.toString()}`);
  };

  const handleKeyPress = (event, type) => {
    // trigger search on enter for excluded input
    if (event.key === "Enter") {
      const search = searchRef.current.input.value;
      const excluded = excludedRef.current.input.value;
      onSearch(search, excluded);
    }
  };

  const hasActiveFilters = () => {
    const status = params.get("status");
    const search = params.get("search");
    const excluded = params.get("excluded");

    return status || search || excluded;
  };

  return (
    <Row className="filter-bar-container" align={"middle"} gutter={[16, 16]}>
      <Col xs={24} md={1}>
        <Row justify={"center"} align={"middle"}>
          <Tooltip
            title={
              "The Campaign Table works with the datepicker on the Summary Chart"
            }
            arrowPointAtCenter={true}
            placement="bottom"
            align="middle"
          >
            <QuestionCircleOutlined type="question-circle" />
          </Tooltip>
        </Row>
      </Col>
      <Col xs={24} md={6} lg={5} xl={3}>
        <DropDownStatusToggle orgId={orgId} id={id} />
      </Col>
      <Col
        xs={24}
        md={{ span: 6, offset: 1 }}
        lg={{ span: 5, offset: 5 }}
        xl={{ span: 4, offset: 10 }}
      >
        <Input
          placeholder="Excluded"
          style={styles.excluded}
          onChange={val => {
            excluded = val.target.value;
          }}
          onKeyPress={e => handleKeyPress(e, "excluded")}
          ref={excludedRef}
          defaultValue={params.get("excluded")}
        />
      </Col>
      <Col xs={24} md={6} lg={5} xl={4}>
        <Input.Search
          placeholder={"Search"}
          ref={searchRef}
          style={styles.search}
          onSearch={() =>
            onSearch(
              searchRef.current.input.value,
              excludedRef.current.input.value
            )
          }
          onKeyPress={e => handleKeyPress(e, "search")}
          defaultValue={params.get("search")}
        />
      </Col>
      <Col xs={24} md={4} lg={3} xl={2}>
        <Button
          style={styles.buttonS}
          shape="round"
          type={"default"}
          onClick={() => {
            searchRef.current.input.value = "";
            excludedRef.current.input.value = "";
            history.push(
              `/paid_search/details/${id}/1?tab=google&subTab=campaign`
            );
          }}
          disabled={!hasActiveFilters()}
          icon={<CloseOutlined />}
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
});

FiltersBar.propTypes = {
  orgs: PropTypes.array,
  selectedKey: PropTypes.string,
};

export default FiltersBar;
