import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
const isSmallScreen = window.matchMedia("(max-width: 1023.5px)").matches;

function FormRichText({ onChange, name, value }) {
  const editorRef = useRef();
  const handleChange = newVal => {
    onChange(state => ({
      ...state,
      [name]: newVal,
    }));
  };
  return (
    <>
      <input
        id="my-file"
        type="file"
        name="my-file"
        style={{ display: "none" }}
        onChange={() => {}}
      />
      <Editor
        apiKey="slbqyo24dx6pxr5quapt16l55vhgf8r1at0j0ptjzvjxn7up"
        outputFormat="text"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={value}
        onEditorChange={handleChange}
        init={{
          height: 675,
          selector: "textarea#open-source-plugins",
          auto_focus: true,
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
          editimage_cors_hosts: ["picsum.photos"],
          menubar: false,
          toolbar:
            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image link anchor preview fullscreen | ltr rtl",
          // toolbar_sticky: true,
          toolbar_sticky_offset: isSmallScreen ? 102 : 108,
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          extended_valid_elements: "a[href|target=_blank]",
          importcss_append: true,
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          image_caption: true,
          file_picker_callback: (callback, raw, meta) => {
            if (meta.filetype === "image") {
              const input = document.getElementById("my-file");
              input.click();
              input.onchange = () => {
                const file = input.files[0];
                const reader = new FileReader();
                reader.onload = e => {
                  callback(e.target.result, {
                    alt: file.name,
                  });
                };
                reader.readAsDataURL(file);
              };
            }
          },
          link_target_list: [
            { title: "None", value: "" },
            { title: "Same page", value: "_self" },
            { title: "New page", value: "_blank" },
          ],
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image editimage table configurepermanentpen",
          skin: useDarkMode ? "oxide-dark" : "oxide",
          content_css: useDarkMode ? "dark" : "default",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
        }}
      />
    </>
  );
}

export default FormRichText;
