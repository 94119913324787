import gql from "graphql-tag";

const UPDATE_ORG_SEND_EMAIL_CONFIG = gql`
  mutation updateOrgSendEmailConfig(
    $id: ID!
    $campaignOrders: String
    $email: String
    $frequency: String
    $dayOfWeek: String
  ) {
    updateOrgSendEmailConfig(
      where: { id: $id }
      data: {
        campaignOrders: $campaignOrders
        email: $email
        frequency: $frequency
        dayOfWeek: $dayOfWeek
      }
    ) {
      campaignOrders
      email
      frequency
      dayOfWeek
    }
  }
`;

export default UPDATE_ORG_SEND_EMAIL_CONFIG;
