import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";

const RequestAssistance = ({ onRequestAssistance }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <Button
        type="secondary"
        shape="round"
        hidden={true} //Temporary hidden until new requirements is given
        onClick={() => {
          onRequestAssistance();
          setIsOpen(!isOpen);
        }}
      >
        Request Assistance
      </Button>
      <Modal
        visible={isOpen}
        title={<Typography.Text strong>ASSISTANCE REQUESTED</Typography.Text>}
        footer={
          <Button type="primary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        }
        onCancel={() => setIsOpen(false)}
      >
        Our Integrations Team has received your request and will respond to the
        email associated with you account.
      </Modal>
    </React.Fragment>
  );
};

export default RequestAssistance;
