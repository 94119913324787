import React, { useContext } from "react";
import { Dropdown, Menu } from "antd";
import { isEmpty } from "lodash";
import { ExportOutlined } from "@ant-design/icons";
// Global Styles
import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { GlobalContext } from "../../../../../core/context/GlobalContext";
import { saveGeoJSONFromGeoTargets } from "../../../../../core/utils/campaigns";
const Item = Menu.Item;

const ExportDropdown = ({ audience, disabled }) => {
  const geoTargets = audience && audience.geoTargets && audience.geoTargets;
  const eventsTargets =
    audience && audience.eventsTargets && audience.eventsTargets;
  const name = audience && audience.name && audience.name;
  const id = audience && audience.id && audience.id;

  let hasGeometry = false,
    hasEventTags = false;

  if (geoTargets && !isEmpty(geoTargets)) {
    hasGeometry = true;
  }

  if (eventsTargets && !isEmpty(eventsTargets)) {
    hasEventTags = true;
  }

  const context = useContext(GlobalContext);

  const menu = (
    <Menu>
      {hasGeometry ? (
        <Item key="export-device-ids">
          <div
            onClick={async () => {
              await context.getAudiencesDevices({
                variables: { audienceId: id },
              });
            }}
          >
            DEVICES
          </div>
        </Item>
      ) : null}

      {hasGeometry ? (
        <Item key="export-geometry">
          <div
            onClick={async () => {
              saveGeoJSONFromGeoTargets(geoTargets, name);
            }}
          >
            GEOJSON
          </div>
        </Item>
      ) : null}
      {hasEventTags ? (
        <Item key="export-tapad-devices">
          <div
            onClick={async () => {
              context.getAudiencesDevicesFromS3({
                variables: { audienceId: id, type: "DEVICE" },
              });
            }}
          >
            DEVICE IDS
          </div>
        </Item>
      ) : null}
      {hasEventTags ? (
        <Item key="export-tapad-apn-ids">
          <div
            onClick={async () => {
              context.getAudiencesDevicesFromS3({
                variables: { audienceId: id, type: "APN" },
              });
            }}
          >
            APN IDS
          </div>
        </Item>
      ) : null}
      {hasEventTags ? (
        <Item key="export-tapad-ttd-ids">
          <div
            onClick={async () => {
              context.getAudiencesDevicesFromS3({
                variables: { audienceId: id, type: "TTD" },
              });
            }}
          >
            TTD IDS
          </div>
        </Item>
      ) : null}
      {hasEventTags ? (
        <Item key="distribute-all-ids">
          <div
            onClick={async () => {
              context.distributeAudiencesFromS3({
                variables: { audienceId: id, type: "DEVICE" },
              });
            }}
          >
            ACTIVATE
          </div>
        </Item>
      ) : null}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      overlayStyle={{ top: "500px" }}
      trigger={["click"]}
      placement="bottomCenter"
      disabled={disabled}
    >
      <Button
        type="primary"
        icon={<ExportOutlined />}
        loading={context.csvExportLoading}
        style={{ width: "100%" }}
        ghost
      >
        <ButtonText text={"Export Data"} />
      </Button>
    </Dropdown>
  );
};

export { ExportDropdown };
