import React, { useState, useEffect } from "react";
import { Card, Col, message, Modal, Row } from "antd";
import { cardMargin, cardBody } from "../../../../shared/globalStyling/style";
import Button from "../../../../shared/globalStyling/Button";

import { hasIsAmplifyDefaultSelection } from "../../../../../core/utils/orgSettings";
import { DeleteOutlined } from "@ant-design/icons";
import TargetingLocationAudiencesList from "./TargetingLocationAudiencesList";

export default function TargetingLocationAudiences({
  deleteAudienceLocation,
  style,
  targetingAudiences,
  title,
  setAudienceAmplifyDefault,
  amplify,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [filteredAudiences, setFilteredAudiences] = useState([]);

  const handleDeleteAudienceLocation = () => {
    if (hasIsAmplifyDefaultSelection(selectedRows)) {
      return message.error(
        "One of the selection is an Amplify Default and can't be deleted. Please update the selection and try again."
      );
    }
    deleteAudienceLocation(selectedRows);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    handleDeleteAudienceLocation(selectedRows);
    setSelectedRows([]);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (targetingAudiences && targetingAudiences.length) {
      setFilteredAudiences(
        targetingAudiences.filter(
          audience => audience.orgs && audience.orgs.length === 1
        )
      );
    }
  }, [targetingAudiences]);

  return (
    <div>
      {open && selectedRows.length && (
        <Modal
          title="Confirm Action"
          visible={open}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>
            Are you sure you want to delete ({selectedRows.length}) audience(s)?
          </p>
        </Modal>
      )}

      <Row gutter={[18, 18]}>
        <Col xs={24} lg={24}>
          <Card
            style={style ? { ...cardMargin, ...style.card } : { cardMargin }}
            bodyStyle={
              style ? { ...cardBody, ...style.cardBody } : { cardBody }
            }
            title={title}
            extra={
              <div style={style ? style.cardExtra : null}>
                <Button
                  disabled={!selectedRows.length}
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={showModal}
                >
                  Delete
                </Button>
              </div>
            }
          >
            <TargetingLocationAudiencesList
              setAudienceAmplifyDefault={setAudienceAmplifyDefault}
              targetingAudiences={filteredAudiences}
              setSelectedRows={setSelectedRows}
              amplify={amplify}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
