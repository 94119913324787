import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table, Tag, Typography } from "antd";

import { useTagContext } from "../../../core/components/tags/useTagContext";
import moment from "moment";
import { formatWholeNumberNaN } from "../../../core/utils/campaigns";

const { Text } = Typography;

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }

  return color;
};

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }

  return color;
};

const columns = [
  {
    title: "Tag Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  {
    title: "Advertiser",
    dataIndex: "orgName",
    key: "orgName",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
    render: (_, { orgs }) => {
      if (orgs && orgs.length) {
        return orgs[0].name;
      }
      return "";
    },
  },
  {
    title: "Measured Events",
    dataIndex: "meassuredEvents",
    key: "meassuredEvents",
    width: 500,
    render: (_, { pageViews, transactions, signups, tagUpdateDate }) => {
      const events = [
        {
          name: "Page Views",
          value: pageViews,
          date: tagUpdateDate,
        },
        {
          name: "Transactions",
          value: transactions,
          date: tagUpdateDate,
        },
        {
          name: "Signups",
          value: signups,
          date: tagUpdateDate,
        },
      ];

      return events
        .filter(event => event.value)
        .map(event => (
          <Space key={event.name} size="middle">
            <Tag color={colorPicker(event.date, event.value)}>
              <Space direction="vertical" size={0}>
                <Text type={typePicker(event.date, event.value)} strong>
                  {event.name}
                </Text>
                <Text type={typePicker(event.date, event.value)} strong>
                  {formatWholeNumberNaN(event.value)}
                </Text>
                <Text type={typePicker(event.date, event.value)} disabled>
                  {event.date
                    ? moment(event.date).format("YYYY/MM/DD HH:mm:ss")
                    : "Signal Unknown"}
                </Text>
              </Space>
            </Tag>
          </Space>
        ));
    },
  },
  {
    title: "Tag ID",
    dataIndex: "tagId",
    key: "tagId",
    render: (_, { eventTags }) => {
      if (eventTags && eventTags.length) {
        return eventTags[0].appId;
      }
      return "";
    },
  },
];

const CampaignRequestorTagsDialog = ({
  visible,
  setVisible,
  form,
  eventType,
  onAddSelections,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { fetchTags, tags, loadingTags } = useTagContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }
    const eventTarget = tags.filter(tag => selectedRowKeys.includes(tag.id))[0];
    onAddSelections(eventTarget);
    setVisible(false);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: !record[eventType],
    }),
  };

  useEffect(() => {
    fetchTags({
      variables: {
        where: {
          OR: [
            {
              orgs_some: {
                id: form.getFieldValue("advertiserId"),
              },
            },
          ],
          AND: [
            {
              OR: [
                {
                  isDisabled: false,
                },
                {
                  isDisabled: null,
                },
              ],
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>Tags</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1400}
      >
        {loadingTags ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tags}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignRequestorTagsDialog;
