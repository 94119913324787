import React from "react";
import { Modal, Spin, Row, Typography, Col, Button, List } from "antd";
const { Text, Title } = Typography;

/**
 *
 * @param {*} modalToggle - to toggle the modal visibility
 * @returns
 */
const ExportAbilityModal = ({
  exportAbilityEmptyData,
  exportAllToggle,
  exportAbilityError,
  modalToggle,
  loading,
  selectedRows,
  onCancel,
  onSubmit,
}) => {
  const showModalDetails = () => {
    if (exportAbilityEmptyData) {
      return (
        <Text type="danger">
          There is no available data in your campaign. Please contact MediaJel.
        </Text>
      );
    }
    if (exportAbilityError) {
      return (
        <Text type="danger">
          There is an error while getting export files. Please refresh the page.
        </Text>
      );
    }

    if (exportAllToggle && loading) {
      return (
        <Title level={5}>
          <Row justify="center">
            <Spin />
          </Row>
          <p>{`We are processing your request. Please wait.`}</p>
        </Title>
      );
    }

    if (exportAllToggle && !loading) {
      return (
        <Title level={5}>
          <p>{`You are about to export ALL campaigns. This process will take a
          long time.
          `}</p>
        </Title>
      );
    }

    if (!exportAllToggle && loading) {
      return (
        <div>
          <Title level={5}>Campaigns to be exported:</Title>
          <Row justify="center">
            <Spin />
          </Row>
        </div>
      );
    }

    if (!exportAllToggle && !loading) {
      return (
        <div>
          <Title level={5}>Campaigns to be exported:</Title>
          <Row justify="center">
            <List
              dataSource={selectedRows}
              renderItem={item => (
                <List.Item>
                  {`- `}
                  {item.name}
                </List.Item>
              )}
              style={{ height: "10rem", overflow: "auto" }}
            />
          </Row>
        </div>
      );
    }
  };

  return (
    <Modal
      title={`Export Ability`}
      visible={modalToggle}
      onCancel={onCancel}
      footer={[
        <Button
          disabled={loading}
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          {"Cancel"}
        </Button>,
        <Button
          disabled={loading}
          key="submit"
          type="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          {"Export"}
        </Button>,
      ]}
    >
      <Row key={"1"} style={{ marginBottom: `10px` }}>
        <Col xs={24}>{showModalDetails()}</Col>
        <div>
          <Text strong>
            {exportAllToggle
              ? ``
              : selectedRows
              ? `Total of ${selectedRows.length} campaign${
                  selectedRows.length === 1 ? "" : "s"
                } to export.`
              : `No selected campaigns.`}
          </Text>
        </div>
      </Row>
    </Modal>
  );
};

export default ExportAbilityModal;
