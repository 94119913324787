import { message, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";

const LaunchCampaignConfigSupplySites = ({
  fetchOptions,
  debounceTimeout = 800,
  publisherId,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = name => {
      if (name) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        try {
          fetchOptions({ name, publisherId }).then(newOptions => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            setFetching(false);
            setOptions(newOptions);
          });
        } catch (error) {
          setFetching(false);
          message.error("Failed to fetch sites. Try again");
        }
      }
    };
    return debounce(loadOptions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      {...props}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <span>
            <Spin size="small" style={{ marginRight: 4 }} />
            Please wait this might take a while
          </span>
        ) : null
      }
      options={options}
      style={{ width: 500, maxHeight: 200, overflow: "auto" }}
    />
  );
};

export default LaunchCampaignConfigSupplySites;
