import gql from "graphql-tag";

const ORG_DETAILS_UPDATE = gql`
  mutation updateOrg($data: OrgUpdateInput!, $where: OrgWhereUniqueInput!) {
    updateOrg(where: $where, data: $data) {
      id
    }
  }
`;

export default ORG_DETAILS_UPDATE;
