import gql from "graphql-tag";

const GET_HASHED_EMAILS = gql`
  query hashedEmailLists($id: ID!) {
    hashedEmailLists(where: { id: $id }) {
      id
      name
      activated
      tag
      deviceCount
      matchRate
      isProcessedByStatera
      dspSegmentIds
      csvFileName
      hashedEmails {
        id
      }
      createdAt
      uploadedAt
      processedAt
      orgs {
        name
      }
    }
  }
`;

export default GET_HASHED_EMAILS;
