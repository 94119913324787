import React from "react";
import {
  settingCreateColumnVisibilityObject,
  settingSelectedTableColumns,
} from "../../../utils/orgSettings";

class CampaignsMainViewStateController extends React.Component {
  state = {
    data: this.props.campaigns,
    openUpdateModal: false,
    openExportAbilityModal: false,
    selectedTableColumns: this.props.defaultColumns,
    selectedRowKeys: [],
    selectedRows: [],
  };

  componentDidMount() {
    //All table options are still available - table preferences via sessionStorage
    //Default view is applied every new session

    const sessionValues = JSON.parse(sessionStorage.getItem("defaultColumns"));

    const { orgTableConfig } = this.props;

    if (orgTableConfig) {
      // Session values not present - set the defaults
      const adjustedColumns = settingSelectedTableColumns(
        orgTableConfig.getOrgTableConfig,
        true
      );

      this.setState({
        selectedTableColumns: adjustedColumns,
      });
    } else if (!orgTableConfig && sessionValues) {
      this.setState({
        selectedTableColumns: sessionValues,
      });
    }
  }

  onRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    this.setState({ selectedRows });
  };

  toggleUpdateModal = () => {
    this.setState({ openUpdateModal: !this.state.openUpdateModal });
  };

  toggleExportAbilityModal = () => {
    this.setState({
      openExportAbilityModal: !this.state.openExportAbilityModal,
    });
  };

  onTableColChange = async key => {
    let selectedKeys = [...this.state.selectedTableColumns];
    let index = selectedKeys.indexOf(key);
    const {
      currentUser,
      updateOrgTableConfig,
      orgTableConfig,
      orgTableConfigRefetch,
    } = this.props;

    if (index >= 0) {
      selectedKeys.splice(index, 1);
    } else {
      selectedKeys = [...selectedKeys, key];
    }
    this.setState({ selectedTableColumns: selectedKeys });

    sessionStorage.setItem("defaultColumns", JSON.stringify(selectedKeys));

    const isShowObject = settingCreateColumnVisibilityObject(selectedKeys);

    orgTableConfig.getOrgTableConfig = isShowObject;

    // Org-level campaign table display column settings
    await updateOrgTableConfig({
      variables: {
        id: currentUser.role.org.id,
        ...isShowObject,
      },
    });

    await orgTableConfigRefetch();
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          onTableColChange: this.onTableColChange,
          toggleUpdateModal: this.toggleUpdateModal,
          toggleExportAbilityModal: this.toggleExportAbilityModal,
          onRowChange: this.onRowChange,
          ...this.state,
          ...this.props,
        })}
      </React.Fragment>
    );
  }
}

export default CampaignsMainViewStateController;
