import React from "react";
import { graphql, withApollo } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";

import compose from "lodash/flowRight";
import Controller from "../signupImpressions/SignupImpressionsController";
import GET_SIGNUP_IMPRESSIONS from "../../../../GraphQl/Queries/GET_SIGNUP_IMPRESSIONS";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import { Row, Spin } from "antd";

const SignupImpressionsLoader = ({
  children,
  campaignOrderId,
  reportName,
  signupId,
}) => {
  const { loading, data } = useQuery(GET_SIGNUP_IMPRESSIONS, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      campaignOrderId,
      reportName,
      signupId,
    },
  });

  if (loading) {
    return (
      <Row type="flex" justify="center" align="middle">
        <Spin size="large" />
      </Row>
    );
  }

  const impressions = data && data.getSignupImpressions;

  return <Controller impressions={impressions}>{children}</Controller>;
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withApollo(SignupImpressionsLoader));
