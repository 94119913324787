import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Modal } from "antd";

const QuickDocs = ({ title, children, open, onCancel, additonalStyle }) => {
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [rightSpacing, setRightSpacing] = React.useState(0);

  useEffect(() => {
    if (isMobile) {
      setWidth("100%");
      setHeight("100vh");
      setRightSpacing("0px");
    } else if (isTablet) {
      setWidth("50vw");
      setHeight("100vh");
      setRightSpacing("0px");
    } else {
      setWidth("17vw");
      setHeight("80vh");
      setRightSpacing("24px");
    }
  }, [isMobile, isTablet]);

  return (
    <Modal
      title={title}
      visible={open}
      mask={false}
      onCancel={onCancel}
      width={width}
      cancelButtonProps={{ style: { display: "none" } }}
      style={{ right: rightSpacing, position: "fixed", ...additonalStyle }}
      bodyStyle={{ height, overflow: "auto" }}
      autoFocusButton={null}
      footer={null}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
      transitionName=""
      maskTransitionName=""
    >
      {children}
    </Modal>
  );
};

export default QuickDocs;
