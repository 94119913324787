import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Tag } from "antd";
import moment from "moment";

const tagStatusConfig = {
  LIVE: {
    backgroundColor: "#00DE5133",
    color: "#00DE51",
    text: "Live",
    width: "50px",
  },
  FINISHED: {
    backgroundColor: "#b9b6b633",
    color: "#b9b6b6",
    text: "Finished",
    width: "78px",
  },
  QUEUED: {
    backgroundColor: "#faad1433",
    color: "#faad14",
    text: "Queued",
    width: "70px",
  },
  ATTRIBUTION: {
    backgroundColor: "#e1f0e5",
    color: "#9cada0",
    text: "In Attr.",
    width: "70px",
  },
  DRAFT: {
    backgroundColor: "lightgray",
    color: "#9cada0",
    text: "Draft",
    width: "55px",
  },
  PENDING: {
    backgroundColor: "#1890ff",
    width: "70px",
    color: "white",
    text: "Pending",
  },
  PAUSED: {
    backgroundColor: "orange",
    width: "70px",
    color: "white",
    text: "Paused",
  },
};

const TagWithConfig = ({ backgroundColor, color, text, width }) => (
  <Tag
    style={{
      width,
      backgroundColor,
      color,
      border: 0,
      borderRadius: "25px",
    }}
  >
    <Row justify="space-around" type="flex" align="middle">
      <div
        style={{
          width: "5px",
          height: "5px",
          borderRadius: "50px",
          backgroundColor: color,
        }}
      />
      {text}
    </Row>
  </Tag>
);

export const deriveStatus = (
  startDate,
  endDate,
  attributionWindow,
  statusCampaign,
  checkStatus
) => {
  /**
   * Dates are modified to be able to be checked with
   * the moment comparison functions.
   *
   * Setting the endDate to be the end of day and startDate
   * to be the start of day is needed to make it a "range"
   *
   * Also, setting up the current date in the middle will avoid the edge case
   * of checking it start of day or end of day.
   */
  const currentDateTime = moment()
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
  startDate = startDate
    ? moment(startDate, moment.ISO_8601)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
    : null;
  endDate = endDate
    ? moment(endDate, moment.ISO_8601)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0)
    : null;
  if (currentDateTime.isBetween(startDate, endDate)) {
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    return "LIVE";
  }
  if (currentDateTime.isAfter(endDate)) {
    const attributionWindowEndDate = moment(endDate).add(
      attributionWindow,
      "days"
    );
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    if (currentDateTime.isAfter(attributionWindowEndDate)) return "FINISHED";
    return "ATTRIBUTION";
  }
  if (currentDateTime.isBefore(startDate)) {
    if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
    if (statusCampaign === "PENDING" || checkStatus === "PENDING")
      return "PENDING";
    if (statusCampaign === "PAUSED" || checkStatus === "PAUSED")
      return "PAUSED";
    return "QUEUED";
  }
  if (statusCampaign === "DRAFT" || checkStatus === "DRAFT") return "DRAFT";
  if (statusCampaign === "PENDING" || checkStatus === "PENDING")
    return "PENDING";
  if (statusCampaign === "PAUSED" || checkStatus === "PAUSED") return "PAUSED";
};

const StatusTag = ({
  startDate,
  endDate,
  attributionWindow,
  statusCampaign,
  checkStatus,
}) => {
  const status = useMemo(
    () =>
      deriveStatus(
        startDate,
        endDate,
        attributionWindow,
        statusCampaign,
        checkStatus
      ),
    [startDate, endDate, attributionWindow, statusCampaign, checkStatus]
  );

  return <TagWithConfig {...tagStatusConfig[status]} />;
};

StatusTag.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  attributionWindow: PropTypes.number,
};

export default StatusTag;
