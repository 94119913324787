//Component is self contained and manages state internally for impressions sub table data

import React, { useState, useEffect } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { Timeline, Row, Col, Tooltip, Avatar, Modal } from "antd";

import {
  DesktopOutlined,
  TabletOutlined,
  MobileOutlined,
  AppleOutlined,
  AndroidOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import {
  determineAdServer,
  getMobileOperatingSystem,
} from "../../transactions/components/utils";

const { Item } = Timeline;

const ImpressionSubTable = ({ impressions }) => {
  const [impressionsData, setImpressionsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImpression, setCurrentImpression] = useState("");

  const showModal = impression => {
    if (impression.creativeUrl) {
      setIsModalVisible(true);
      setCurrentImpression(impression);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const sortedImpressions =
      !isEmpty(impressions) &&
      impressions.sort((a, b) =>
        moment(a.impressionTime)
          .utc(true)
          .diff(moment(b.impressionTime).utc(true))
      );

    setImpressionsData(sortedImpressions);
  }, [impressions]);

  const makeTimeLine = impressionsData.map((impression, i) => {
    const {
      impressionTime,
      useragent,
      pageUrl,
      urlHost,
      referrer,
      appName,
      appStoreId,
      siteId,
      siteName,
      creativeId,
      creativeUrl,
    } = impression;

    let appSiteName = "";
    let appSiteLink = "";
    let appSiteNameTooltip = "";
    let applicationPlatform = "";
    let OS = "";

    OS = getMobileOperatingSystem(useragent);

    const publisher =
      impression.publisher && impression.publisher !== "N/A"
        ? `Published by - ${decodeURI(impression.publisher).replace(
            /[+_]/g,
            " "
          )}`
        : "";

    //First case checked - no pub, site or app macros resolve at all - TTD, CENTRO and LiquidM legacy tags revert to page url defaults
    if (
      (!appStoreId || appStoreId === "N/A") &&
      (!appName || appName === "N/A") &&
      (!siteId || siteId === "N/A") &&
      (!siteName || siteName === "N/A")
    ) {
      //No new macros resolve - we process with out of the box page view information - often only have ad server level information
      appSiteName = urlHost ? `Served on ${decodeURIComponent(urlHost)}` : "";
      appSiteLink = referrer
        ? `Referred by ${decodeURIComponent(referrer).substring(0, 60)}`
        : "";
      appSiteNameTooltip = pageUrl
        ? decodeURIComponent(pageUrl).substring(0, 60)
        : "";
      //Chacks the available page and referrer information to determine application platform
      applicationPlatform = determineAdServer(urlHost);
      //Even if we determine the page url to be an ad server - the referrer may still give better resolution
      if (referrer && applicationPlatform !== "Application") {
        applicationPlatform = determineAdServer(referrer);
      }
    } else if (appStoreId && appName) {
      //Highly confident we have a mobile application based on new macros
      appSiteName = `Served on - ${decodeURIComponent(appName)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop()}`;
      appSiteLink = `${decodeURIComponent(appStoreId)}`;
      applicationPlatform = "Application";
    } else if (siteName && siteId && !siteId) {
      //No url from IMPRESSION_SITE_ID suggests mobile application
      appSiteName = `Served on - ${decodeURIComponent(siteName)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop()}`;

      console.log("Maybe there id no IMPRESSION_SITE_ID", siteId);
      appSiteLink = referrer ? `Referred by ${referrer.substring(0, 30)}` : "";
      applicationPlatform = "Application";
    } else if (siteName && appName) {
      //suggests website - better resolution than page level information
      const tempSiteName = decodeURIComponent(siteName)
        .replace(/[+_]/g, " ")
        .split(":")
        .pop();

      appSiteName = `Served on - ${tempSiteName}`;
      appSiteLink = referrer ? referrer : "https://" + tempSiteName.trim();
      applicationPlatform = "Website";
    }

    let appTypeIcon = () => {
      if (appStoreId && appName) {
        if (appStoreId.includes("itunes")) {
          return <AppleOutlined />;
        } else if (appStoreId.includes("google")) {
          return <AndroidOutlined />;
        } else {
          return <MobileOutlined />;
        }
      } else if (siteName && siteId && !siteId) {
        return <MobileOutlined />;
      } else if (applicationPlatform.includes("Ad Server")) {
        return <MobileOutlined />;
      } else if (applicationPlatform.includes("Application")) {
        if (urlHost.includes("itunes") || referrer.includes("itunes")) {
          return <AppleOutlined />;
        } else if (
          urlHost.includes("play.google") ||
          referrer.includes("play.google")
        ) {
          return <AndroidOutlined />;
        } else return <MobileOutlined />;
      }

      return <DesktopOutlined />;
    };

    let icon = () => {
      if (
        OS === "Unknown" ||
        OS === "Chromebook Computer" ||
        OS === "Windows Computer" ||
        OS === "Computer"
      ) {
        return <DesktopOutlined />;
      }

      if (OS === "iPhone" || OS === "Android" || OS === "Windows Phone") {
        return <MobileOutlined />;
      }

      if (OS === "iPad") {
        return <TabletOutlined />;
      }
    };

    return (
      <Item key={i}>
        <Row>
          <Col span={6}>
            <div>
              <Avatar
                shape="square"
                alt={creativeId}
                size={50}
                icon={<FileImageOutlined />}
                onClick={() => showModal(impression)}
                src={creativeUrl === "" || !creativeUrl ? false : creativeUrl}
              />
            </div>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`Impression served - ${moment(impressionTime)
                .utc(true)
                .format("MM/DD/YY HH:mm:ss")}`}
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {icon()}
              <Tooltip title={useragent ? useragent : ""}>{`  ${OS}`}</Tooltip>
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              <Tooltip
                title={appSiteNameTooltip ? appSiteNameTooltip : ""}
              >{`${appSiteName}`}</Tooltip>
              <br />
              <a
                href={`${appSiteLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {applicationPlatform === "Website"
                  ? `Visit Website`
                  : `Visit Application`}
              </a>
              <br />
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "105%",
                fontWeight: 700,
                color: "#636363",
              }}
            >
              {`${applicationPlatform}`} {appTypeIcon()}
              <br />
              {`${publisher}`}
            </div>
          </Col>
        </Row>
      </Item>
    );
  });

  return (
    <div style={{ marginRight: 100 }}>
      <Modal
        title={currentImpression.creativeId}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImpression.creativeUrl}
          alt={currentImpression.creativeId}
          width="100%"
        />
      </Modal>
      <Timeline mode={"left"} reverse={true}>
        {makeTimeLine}
      </Timeline>
    </div>
  );
};

export default ImpressionSubTable;
