import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";

// MUTATIONS

// QUERIES
import EVENTS_TARGETS from "../../GraphQl/Queries/EVENTS_TARGETS";

const TagContext = createContext({});

export const TagProvider = props => {
  const { children } = props;

  const [fetchTags, { data: dataTags, loading: loadingTags }] = useLazyQuery(
    EVENTS_TARGETS
  );

  return (
    <TagContext.Provider
      value={{
        fetchTags,
        tags: dataTags ? dataTags.eventsTargets : [],
        loadingTags,
      }}
      {...props}
    >
      {children}
    </TagContext.Provider>
  );
};

export default TagContext;
