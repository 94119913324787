import React from "react";
import { Row, Col, Card } from "antd";
import CityTable from "./LocationCityTable";
import LocationZipTable from "./LocationZipTable";
import HeatMapCard from "./HeatMapCard";

// Global Styling
import { CardTitleText } from "../../../../../shared/globalStyling/styledText";
//colors
import { colors } from "../../../../../../core/utils/constants/listOfColors";

const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  cardStyleGeneral: {
    backgroundColor: "#fff",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 8,
    offset: 0,
  },
  lg: {
    span: 8,
    offset: 0,
  },
  xl: {
    span: 8,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const { xs, sm, md, lg, xl, cardStyleGeneral } = Style;

const LocationDetails = props => {
  const { cityData, zipData, uniqueCount } = props;

  //const parsedData = prepareCTR(publisherData);
  const colorCityData = cityData.map((el, index) => {
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Unknown City`,
      color: colors[index],
    };
  });

  const colorZipData = zipData.map((el, index) => {
    index = index + 20;
    if (el.ZIP) {
      return {
        ...el,
        value: el.ZIP,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Unknown Zip Code`,
      color: colors[index],
    };
  });

  if (uniqueCount > 500000) {
    return (
      <Row gutter={35}>
        <Col span={12}>
          <Card
            style={cardStyleGeneral}
            headStyle={{ borderWidth: "3px", height: "66px" }}
            bodyStyle={{ padding: 0, height: "530px" }}
            title={<CardTitleText text={"Top 25 Cities"} />}
          >
            <CityTable cityData={colorCityData} />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={cardStyleGeneral}
            headStyle={{ borderWidth: "3px", height: "66px" }}
            bodyStyle={{ padding: 0, height: "530px" }}
            title={<CardTitleText text={"Top 25 Zip Codes"} />}
          >
            <LocationZipTable zipData={colorZipData} />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={35}>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={cardStyleGeneral}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0, minHeight: "547px" }}
          title={<CardTitleText text={"Top 25 Cities"} />}
        >
          <CityTable cityData={colorCityData} />
        </Card>
      </Col>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={cardStyleGeneral}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0, minHeight: "547px" }}
          title={<CardTitleText text={"Top 25 Zip Codes"} />}
        >
          <LocationZipTable zipData={colorZipData} />
        </Card>
      </Col>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={cardStyleGeneral}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0, minHeight: "547px" }}
          title={
            <CardTitleText text={"National Breakdown - Unique Visitors"} />
          }
        >
          <HeatMapCard
            latLngs={[]}
            zipData={colorZipData}
            cityData={colorCityData}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default LocationDetails;
