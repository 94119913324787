import React from "react";
import { Popover, Row, Col, Button as AntdButton } from "antd";
import CsvAdressListUploadButton from "../../../CampaignLauncher/components/CsvAdressListUploadButton";
import { AddGeoTargetContent } from "./AddGeoTargetContent";
import Button from "../../../../shared/globalStyling/Button";

//Global Styles
import { EnvironmentOutlined } from "@ant-design/icons";
import TemplateCSVDownloader from "../../../../shared/TemplateCSVDownloader";
import { ButtonText } from "../../../../shared/globalStyling/styledText";
import { CampaignConfig } from "../../shared/configs/campaignConfig";

const style = {
  button: { fontSize: "14px", marginRight: 10 },
};

export const CardExtraButtons = props => {
  const { csvTemplate } = CampaignConfig;
  const title = (
    <Row type="flex" align="middle" justify="space-between">
      <Col>Add Single {props.targetType} Target</Col>
      <Col>
        <AntdButton
          type="link"
          onClick={() => {
            props.newGeoTargetConfirmed("clear");
            props.toggleAddGeoTargetPopover();
          }}
        >
          Cancel
        </AntdButton>
      </Col>
    </Row>
  );

  return (
    <Row style={{ height: "100%", padding: 0 }}>
      <Popover
        content={
          <AddGeoTargetContent
            isPopover={true}
            newGeoTarget={props.newGeoTarget}
            newGeoTargetChanged={props.newGeoTargetChanged}
            newGeoTargetConfirmed={props.newGeoTargetConfirmed}
            toggleAddGeoTargetPopover={props.toggleAddGeoTargetPopover}
            type={props.targetType}
          />
        }
        placement={props.modal ? "bottomRight" : "rightBottom"}
        title={title}
        trigger="click"
        visible={props.addGeoTargetPopoverVisible}
      >
        <Button
          type="primary"
          shape="round"
          style={style.button}
          loading={props.addGeoTargetLoading}
          onClick={() => {
            props.toggleAddGeoTargetPopover();
          }}
        >
          Add Target
        </Button>
      </Popover>
      <CsvAdressListUploadButton
        onComplete={props.onCsvUploadComplete}
        onGeoJsonComplete={props.onGeoJsonUploadComplete}
        locationTargetsModalOpen={props.locationTargetsModalOpen}
      />
      <TemplateCSVDownloader
        tooltipMessage="CSV Template for Targeting Audiences"
        rows={csvTemplate.rows}
        templateName={csvTemplate.templateName}
        title="Sample Template"
      />
      {false && (
        <Button type="primary" shape="round" icon={<EnvironmentOutlined />}>
          <ButtonText text={"Add Geo-Targets"} />
        </Button>
      )}
    </Row>
  );
};
