import React, { useEffect, useState } from "react";
import ErrorPage from "../../platform/shared/ErrorPage";
import { LoadingPage } from "../../platform/shared/LoadingPage";

//Report Wrapper to fetch the report data from S3. Use this before rendering the controller or before the render of UI.
//To get the signed url from the server, use the query GET_SIGNED_URL
const ReportWrapper = ({ signedUrl, ...props }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();

  useEffect(() => {
    if (signedUrl) {
      const s3Url = new URL(signedUrl);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportData(json);
              setReportLoading(false);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [signedUrl]);

  if (reportLoading) return <LoadingPage />;

  if (reportError) return <ErrorPage />;

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        reportData,
      })}
    </React.Fragment>
  );
};

export default ReportWrapper;
