import gql from "graphql-tag";
const PAID_SEARCH_ORGS_CONNECTION = gql`
  query PaidSearchSummariesConnection(
    $first: Int
    $skip: Int
    $where: PaidSearchSummaryWhereInput
    $aggregateDataWhereInput: PaidSeachAggregatedDateUnitWhereInput
    $aggregateDataFirst: Int
    $orderBy: PaidSearchSummaryOrderByInput
  ) {
    paidSearchSummariesConnection(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          startDate
          endDate
          status
          failedChannel
          config {
            monthlyBudget
            processWithGoogleAds
          }
          overallData {
            id
          }
          aggregateData {
            impressions
            clicks
            revenue
            conversions
            spend
            roas
            byDate(
              first: $aggregateDataFirst
              orderBy: date_DESC
              where: $aggregateDataWhereInput
            ) {
              impressions
              clicks
              revenue
              conversions
              spend
              xAxis
              date
            }
          }
          paidSearchCampaigns(where: { status: SERVING }) {
            id
          }
          paidSearchBingCampaigns(where: { status: SERVING }) {
            id
          }
          overallBing {
            id
          }
          org {
            id
            name
            dataConfig {
              googleCustomerId
              bing {
                customerId
                accountId
              }
            }
          }
        }
      }
      aggregate {
        count
      }
    }
    full: paidSearchSummariesConnection(first: 3000, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default PAID_SEARCH_ORGS_CONNECTION;
