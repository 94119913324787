import React, { Component } from "react";
import { Auth } from "aws-amplify";

class Controller extends Component {
  state = {
    loading: false,
    user: {
      preferredMFA: "NOMFA",
      username: "",
      attributes: {
        email: "",
        phone_number: "",
      },
    },
  };

  componentDidMount = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user) {
      // Currently there are two types of Auth'd users: those from AWS Amplify and those from a federated login (3rd party OAUTH)
      // Federated logins have different user objects returned
      // TODO: This whole page should pull from the DB instead. When a field in the form is updated it needs to update the Cognito pool too.
      if (user.preferredMFA) this.setState({ user });
      else
        this.setState({
          user: {
            preferredMFA: null,
            attributes: {
              email: user.email,
              phone_number: user.phone_number || "",
            },
          },
        });
    }
  };

  updateUser = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user) this.setState({ user });
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          ...this.props,
          user: this.state.user,
          updateUser: this.updateUser,
        })}
      </React.Fragment>
    );
  }
}

export default Controller;
