import React, { useState, useEffect, useCallback, useRef } from "react";
import { ftToKm, kmToFt, checkLatLonMatch } from "../../../utils/campaigns";
import {
  defaultRadius,
  maxRadius,
  minRadius,
  stateMap,
} from "../../../utils/constants/constants";
import moment from "moment";
import { message } from "antd";
import { isEmpty, differenceBy, cloneDeep } from "lodash";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";
import * as turf from "@turf/turf";
import { parseOverlayToGeoTarget } from "../../../utils/geoTargets";
import { MapConfig } from "../../../../platform/shared/maps/configs/mapConfigs";
import {
  getFormattedAddress,
  getGeoCodes,
} from "../../../../platform/shared/maps/utils/mapUtils";
import { camelCase } from "lodash";
import { OrganizationConfig } from "../../../../platform/ux/OrgConfig/configs/organizationConfig";

const GeotargetManagerController = ({ children, ...props }) => {
  // TODO: move these to modal loader/controller
  const [locationTargetsModalOpen, setLocationTargetsModalOpen] = useState(
    false
  );
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [targetDetailsModalOpen, setTargetDetailsModalOpen] = useState(false);
  const [targets, setTargets] = useState(cloneDeep(props.targets));
  const [selectedModalLocations, setSelectedModalLocations] = useState([]);
  const [selectedForBatch, setSelectedForBatch] = useState([]);
  const [confirmLocationsTableData, setConfirmLocationsTableData] = useState(
    []
  );

  const [optionsToUpdate, setOptionsToUpdate] = useState({});

  const [geoLocationFilters, setGeoLocationFilters] = useState({});
  const [filterAttribute, setFilterAttribute] = useState("name");
  const [filterValue, setFilterValue] = useState("");

  const [newGeoTarget, setNewGeoTarget] = useState({});
  const [addGeoTargetLoading, setAddGeoTargetLoading] = useState(false);

  const [targetingLocations, setTargetingLocations] = useState(
    props.targetingLocations
  );

  const [targetingAudiences, setTargetingAudiences] = useState(
    props.audiencesTargeting
  );

  const [previewTargetingLocations, setPreviewTargetingLocations] = useState(
    []
  );

  const [selectedTargetingLocations, setSelectedTargetingLocations] = useState(
    []
  );

  const [geoTargets, setGeoTargets] = useState([]);
  const [eventTargets, setEventTargets] = useState([]);

  const prevValueRefGeoTarget = useRef();

  useEffect(() => {
    const { targetingLocations } = props;
    if (targetingLocations) {
      setTargetingLocations(targetingLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.targetingLocations]);

  useEffect(() => {
    const { eventTagsData } = props;
    if (eventTagsData) {
      setEventTargets(eventTagsData.orgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventTagsData]);

  useEffect(() => {
    const { audiencesTargeting } = props;

    if (audiencesTargeting && audiencesTargeting.edges) {
      const { edges } = audiencesTargeting;
      const formattedTargetingAudiences = edges
        .filter(edge => edge && edge.node)
        .map(edge => edge.node);
      setTargetingAudiences(formattedTargetingAudiences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.audiencesTargeting]);

  // This is used in the Step 2 Targeting Locations
  useEffect(() => {
    // TODO: Refactor all of how geotargets are handled in this component for better manageability and scalability
    // Isolate all of the geotargeting logic (Step 2 and Step 4) or improve code readability
    // Affected components of this is Adding of Targeting Locations and GeoTarget

    // This code serves as initialization and a fix when adding new Targeting Locations
    if (
      props.geoTargetData &&
      props.geoTargetData.length > 0 &&
      geoTargets &&
      !geoTargets.length
    ) {
      setGeoTargets(props.geoTargetData);
    }

    // Compare previous and current geoTargetData to determine if the data has changed
    // this is the only way I've come up to fix the geotarget circles refresh issue
    if (
      prevValueRefGeoTarget.current &&
      props.geoTargetData &&
      prevValueRefGeoTarget.current.length !== props.geoTargetData.length
    ) {
      setGeoTargets(props.geoTargetData);
    }

    // Save current state value for reference
    prevValueRefGeoTarget.current = props.geoTargetData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.geoTargetData]);

  const filterAttributionTargets = useCallback(
    targets => {
      // temporary filter feature for GeoTargetsTable. This will get moved to the backend
      if (targets && targets.length) {
        if (geoLocationFilters.value && geoLocationFilters.value.length) {
          return targets.filter(target => {
            let attribute;
            if (!isEmpty(target.circles) && target.circles[0].location) {
              attribute =
                target.circles[0].location[geoLocationFilters.type] ||
                target[geoLocationFilters.type];
            } else {
              attribute = target[geoLocationFilters.type];
            }
            attribute = (attribute || "").toString().toLowerCase();
            return attribute.includes(
              geoLocationFilters.value.toString().toLowerCase()
            );
          });
        }
        return targets;
      }
      return [];
    },
    [geoLocationFilters]
  );

  useEffect(() => {
    setTargets(filterAttributionTargets(cloneDeep(props.targets)));
  }, [geoLocationFilters, filterAttributionTargets, props.targets]);

  const parseAddressComponents = address_components => {
    // Takes 'address_components' array returned from googleapis
    // and turns it into a map as they don't always come back the same.
    // This allows you to look for the key in the map
    // instead of looking for indexes that don't exist in the original array
    let addressComponents = {};
    if (address_components.length) {
      address_components.map(item => {
        let type = item.types[0];
        addressComponents = { ...addressComponents, [type]: item };
        return item;
      });
    }
    return addressComponents;
  };

  const getComponentName = (comps, attr) => {
    // Gets name from addressComponents object if it exists
    // looks up country/state if need
    // returns "" if doesn't exist
    let name = (comps[attr] || {}).long_name || "";
    if (attr === "country" || attr === "administrative_area_level_1")
      name = stateMap[name] || "";
    return name;
  };

  const buildAddress = (address, location, index) => {
    // essentially a util function that formats the data returned from the geocoded CSV
    const { formatted_address } = address;
    const comps = parseAddressComponents(address.address_components);
    const streetAddress = [
      getComponentName(comps, "street_number"),
      getComponentName(comps, "route"),
    ].join(" ");
    let radius = location["RADIUS"];
    let errorMessage;

    const flightLength = buildFlightLength({
      start: location["START_DATE"],
      end: location["END_DATE"],
    });

    if (!radius) {
      radius = defaultRadius;
      errorMessage = `No radius provided; set to ${defaultRadius}ft.`;
    } else if (radius > maxRadius) {
      radius = maxRadius;
      errorMessage = `Provided radius too high; set to ${maxRadius}ft.`;
    } else if (radius < minRadius) {
      radius = minRadius;
      errorMessage = `Provided radius too low; set to ${minRadius}ft.`;
    }
    const latlonGiven = location["LAT"] && location["LON"];
    const check = checkLatLonMatch(
      location["LAT"],
      location["LON"],
      address.geometry.location.lat,
      address.geometry.location.lng
    );
    return {
      radius,
      errorMessage,
      isMatch: address.partial_match ? false : true,
      isPartial: address.partial_match ? true : false,
      key: index,
      sourceAddress: latlonGiven
        ? `Lat: ${location["LAT"] || "-"}, Long: ${location["LON"] || "-"}`
        : location["ADDRESS"],
      foundAddress: latlonGiven
        ? check
          ? formatted_address
          : ""
        : formatted_address,
      lat: latlonGiven
        ? Number(location["LAT"])
        : address.geometry.location.lat,
      lng: latlonGiven
        ? Number(location["LON"])
        : address.geometry.location.lng,
      name: location["NAME"] || streetAddress,
      zip: getComponentName(comps, "postal_code"),
      street: getComponentName(comps, "route"),
      city: getComponentName(comps, "locality").length
        ? getComponentName(comps, "locality")
        : getComponentName(comps, "political"),
      county: getComponentName(comps, "administrative_area_level_2"),
      state: getComponentName(comps, "administrative_area_level_1"),
      country: getComponentName(comps, "country"),
      currentID: location["ID"] || null,
      flightLength,
    };
  };

  const getTargetingLocationTableData = locations => {
    const results = [];
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      const fullAddress = `${location.address} ${location.city} ${location.state} ${location.zip}`
        .split(" ")
        .join("+");
      results.push(getGeoCodes(fullAddress));
    }

    return results;
  };

  const getTableData = async (parsedData, action) => {
    // this geocodes new locations and formats the data for the confirm locations modal
    let tableData = await Promise.all(
      parsedData.map(async (location, index) => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const basicString =
              "https://maps.googleapis.com/maps/api/geocode/json";
            let urlParams = `?address=null`;
            if (location["LAT"] && location["LON"]) {
              urlParams = `?latlng=${location["LAT"]},${location["LON"]}`;
            } else {
              let address = location["ADDRESS"] || "";
              address = address.replace(/[^a-zA-Z0-9 ,]/g, " "); // removes special characters that might break geocoder
              if (!isEmpty(address)) {
                urlParams = `?address=${address.split(" ").join("+")}`;
              }
            }
            const request = `${basicString}${urlParams}&key=${googleMapsConfig.apiKey}`;
            return fetch(request)
              .then(res => res.json())
              .then(result => {
                if (result.status === "OK") {
                  resolve(buildAddress(result.results[0], location, index));
                } else {
                  resolve({ key: index, notFound: true });
                }
              });
          }, index * 15);
        });
      })
    );

    if (action === "create") {
      let existingTargets = targets.reduce((acc, target) => {
        // formats existing attributionTargets for easy compare/dedup
        if (target && target.circles.length) {
          const location = { ...target.circles[0].location };
          return [
            ...acc,
            { ...location, foundAddress: location.formattedAddress },
          ];
        }
        return acc;
        // return target
      }, []);
      // removes options from CSV upload that already exist in the DB

      tableData = differenceBy(
        tableData,
        existingTargets,
        data => `${data.lat},${data.lng}`
      );
    }
    //Using filter to move falsy isMatch to the top of list while maintaining thier original order.
    const noMatch = tableData.filter(el => !el.isMatch);
    const match = tableData.filter(el => el.isMatch);
    // Then concat the falsy array before the truthy
    return noMatch.concat(match);
  };

  const onCsvUploadComplete = async parsedData => {
    // This consumes the JSON generated from the CSV and formats it to be accepted by the Modal
    const tableData = await getTableData(parsedData, "create");
    setAddGeoTargetLoading(false);
    setLocationTargetsModalOpen(true);
    setConfirmLocationsTableData(tableData);
    setSelectedModalLocations(tableData.filter(loc => loc.isMatch));
  };

  const batchDeleteClicked = async () => {
    try {
      const {
        status,
        createCampaignHistory,
        currentUser,
        campaignOrderId,
        updateGeoTargetData,
      } = props;

      if (["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(status)) {
        for (let i = 0; i < selectedForBatch.length; i++) {
          const address = selectedForBatch[i];
          await updateGeoTargetData({
            variables: {
              id: address.id,
              data: {
                status: "PENDING_DELETE",
              },
            },
          });

          createCampaignHistory({
            variables: {
              action: "PENDING_DELETE",
              property: "TARGETING_ADDRESS",
              data: {
                id: address.id,
                name: address.name,
              },
              authorId: currentUser.id,
              campaignOrderId,
            },
          });
        }
      } else {
        let ids = selectedForBatch.map(item => {
          return { id: item.key };
        });

        // Wait for the delete to complete before refetching
        await updateGeoTargets({ delete: ids }, "delete");
      }

      setSelectedForBatch([]);
      setGeoTargets([]);
    } catch (error) {
      return error;
    }
  };

  const confirmTargetFromMap = async () => {
    await updateGeoTargets(
      { create: [buildNewGeoTarget(newGeoTarget)] },
      "add"
    );

    toggleTargetDetailsModal(false);
  };

  const addTargetFromMapClicked = targets => {
    const { status } = props;
    let { addresses, ...target } = targets[0];
    let newTarget;
    const targetStatus = ["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(
      status
    )
      ? "PENDING_CREATE"
      : "CREATED";

    if (!isEmpty(target.circles)) {
      newTarget = {
        ...target,
        status: targetStatus,
        circles: {
          ...target.circles[0],
          radius: (target.circles[0].radius || defaultRadius) / 1000,
          location: {
            ...target.circles[0].location,
            audienceType: "ATTRIBUTION",
          },
        },
      };
    } else if (!isEmpty(target.polygons)) {
      const polyState = target.polygons[0].location.state.replace(/\s/g, "_");
      newTarget = {
        status: targetStatus,
        ...target,
        polygons: {
          ...target.polygons[0],
          location: {
            ...target.polygons[0].location,
            state: polyState,
          },
        },
      };
    }

    let updatedGeoTarget = newTarget || target;
    const location = updatedGeoTarget.circles.location;
    if (location && location.country === "USA") {
      // Replace spaces with underscores
      const state = location.state.replace(/\s/g, "_");
      updatedGeoTarget = {
        ...updatedGeoTarget,
        circles: {
          ...updatedGeoTarget.circles,
          location: {
            ...updatedGeoTarget.circles.location,
            state: state,
          },
        },
      };
    }

    setNewGeoTarget(updatedGeoTarget);
    toggleTargetDetailsModal(true);
  };

  const formatNewTarget = ({ target, circle }) => {
    return {
      circles: {
        coordinate: {
          lat: circle.coordinate.lat,
          lng: circle.coordinate.lng,
        },
        radius: circle.radius,
        location: {
          name: target.name,
          formattedAddress: target.formattedAddress,
          street: target.street,
          state: target.state,
          city: target.city,
          zip: target.zip,
          county: target.county,
          country: target.country,
          lat: target.lat,
          lng: target.lng,
          audienceType: "ADDRESS",
          province: target.province,
        },
      },
      polygons: [],
    };
  };

  const handleAddTargetFromAddresses = async targets => {
    try {
      message.loading("Adding Targeting Addresses...", 0);
      const geoTargets = [];
      const {
        updateCampaignOrderWithGeoLocations,
        campaignOrderId,
        createCampaignHistory,
        status,
        currentUser,
        handleUpdateCampaignOrder,
      } = props;

      const targetStatus = ["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(
        status
      )
        ? "PENDING_CREATE"
        : "CREATED";

      if (!campaignOrderId) {
        message.destroy();
        return message.error("Please save the campaign order first.");
      }

      targets.forEach(target => {
        const { geoframe } = target;
        const circle = geoframe.circles[0];

        const newTarget = buildNewGeoTarget(
          formatNewTarget({ target, circle })
        );
        geoTargets.push({ ...newTarget, status: targetStatus });

        let action = null;

        switch (status) {
          case "LIVE":
          case "LIVE_PENDING":
          case "LIVE_APPROVED":
            action = "PENDING_CREATE";

            break;

          default:
            action = "CREATED";
            break;
        }

        createCampaignHistory({
          variables: {
            action,
            property: "TARGETING_ADDRESS",
            data: {
              name: target.name,
            },
            authorId: currentUser.id,
            campaignOrderId,
          },
        });
      });

      if (!geoTargets.length) {
        message.destroy();
        message.warning("No new addresses to add.");
        return;
      }

      await handleUpdateCampaignOrder({ step: "targeting" });

      const result = await updateCampaignOrderWithGeoLocations({
        variables: {
          campaignOrderID: campaignOrderId,
          geoTargets: {
            create: geoTargets,
          },
        },
      });

      const data = result && result.data;

      if (
        data &&
        data.updateCampaignOrder &&
        data.updateCampaignOrder.geoTargets
      ) {
        setGeoTargets(data.updateCampaignOrder.geoTargets);
        message.destroy();
        message.success("Targeting Addresses Successfully Added.");
      }
    } catch (error) {
      message.destroy();
      message.error(error.message);
    }
  };

  const handleUpdateTargtingAddress = async target => {
    const { createCampaignHistory } = props;
    const { geoframe } = target;
    const circle = geoframe.circles[0];
    const newTarget = formatNewTarget({ target, circle });
    setNewGeoTarget(newTarget);

    await updateGeoTargets(
      {
        delete: [
          {
            id: target.id,
          },
        ],
        create: [buildNewGeoTarget(newTarget)],
      },
      "edit"
    );

    const changeHistories = getTargetingAddressesUpdatedProperties(
      geoTargets,
      target
    );
    if (changeHistories.length) {
      changeHistories.forEach(variables => {
        createCampaignHistory({
          variables,
        });
      });
    }
  };

  const getTargetingAddressesUpdatedProperties = (geoTargets, target) => {
    const { currentUser, campaignOrderId, status } = props;
    const matchedGeoTarget = geoTargets.find(
      geoTarget => geoTarget.id === target.id
    );

    if (!matchedGeoTarget) {
      return []; // No match found
    }

    const location = matchedGeoTarget.circles[0].location;

    const propertiesToCompare = [
      "name",
      "radius",
      "formattedAddress",
      "city",
      "state",
      "zip",
    ];
    let changes = [];

    let action = null;
    switch (status) {
      case "LIVE":
      case "LIVE_PENDING":
      case "LIVE_APPROVED":
        action = "PENDING_UPDATE";
        break;

      default:
        action = "UPDATED";
        break;
    }

    propertiesToCompare.forEach(property => {
      // Special handling for 'radius', as it's nested inside 'circles[0]'

      // For other properties
      if (location[property] !== target[property]) {
        changes.push({
          action,
          property: "TARGETING_ADDRESS",
          data: {
            property,
            oldValue: location[property],
            newValue: target[property],
          },
          authorId: currentUser.id,
          campaignOrderId,
        });
      }
    });

    return changes;
  };

  const toggleTargetDetailsModal = open => {
    if (!open) {
      setNewGeoTarget({});
    }
    setTargetDetailsModalOpen(!targetDetailsModalOpen);
  };

  const changeTargetDetails = (attr, value) => {
    const updatedGeoTarget = { ...newGeoTarget };

    if (attr === "name") {
      if (Object.keys(updatedGeoTarget.circles).length) {
        updatedGeoTarget.circles = {
          ...updatedGeoTarget.circles,
          name: value,
          location: {
            ...updatedGeoTarget.circles.location,
            name: value,
          },
        };
      } else if (Object.keys(updatedGeoTarget.polygons).length) {
        updatedGeoTarget.polygons = {
          ...updatedGeoTarget.polygons,
          name: value,
          location: {
            ...updatedGeoTarget.polygons.location,
            name: value,
          },
        };
      }
    }

    setNewGeoTarget({ ...updatedGeoTarget, [attr]: value });
  };

  const buildFlightLength = dates => {
    if (isEmpty(dates) || !dates.start || !dates.end)
      return { start: null, end: null };
    let start = moment(dates.start);
    let end = moment(dates.end);
    if (!start._isValid && !end._isValid) return { start: null, end: null };
    return { start: start.format(), end: end.format() };
  };

  const checkAreaIsValid = feature => {
    try {
      let area = turf.area(feature);
      return !(area > 2880648);
    } catch {
      // turf.area fails because polygon is malformed
      return false;
    }
  };

  const onGeoJsonUploadComplete = data => {
    try {
      let json = JSON.parse(data);
      if (json.features && json.features.length) {
        let tooBig = 0;
        let targets = json.features.reduce((acc, feature, i) => {
          if (checkAreaIsValid(feature)) {
            const flightLength = buildFlightLength(feature.properties);
            let newTarget = buildNewGeoTarget({
              name: feature.properties.name,
              polygons: feature.geometry.type === "Polygon" ? [feature] : [],
              circles:
                feature.geometry.type === "Point"
                  ? [
                      {
                        coordinate: feature.geometry.coordinates,
                        radius: ftToKm(feature.properties.radius || minRadius),
                      },
                    ]
                  : [],
              flightLength,
            });
            return [...acc, newTarget];
          } else {
            tooBig++;
            return acc;
          }
        }, []);
        if (tooBig) {
          message.warning(
            `${tooBig} ${props.targetType} Targets are either too big or are malformed. Please check your GeoJson file and try again.`
          );
        }
        updateGeoTargets({ create: targets }, "add");
      } else {
        message.error(
          "No geometry found. Please ensure that your file is formatted in proper GeoJSON and try again."
        );
        return null;
      }
    } catch {
      message.error(
        "No geometry found. Please ensure that your file is formatted in proper GeoJSON and try again."
      );
      return null;
    }
  };

  const updateFromExistingAudience = async geoTargetsToAdd => {
    const { campaignOrder } = props;
    const { startDate, endDate } =
      campaignOrder === null ? props : campaignOrder;
    const targets = [];
    geoTargetsToAdd.forEach(geoTargetToAdd => {
      //Inherit dates from campaign order - note: these can be edited later
      geoTargetToAdd.flightLength = {
        start: startDate,
        end: endDate,
      };
      // If the selected geotarget is from the retail location in org config
      if (geoTargetToAdd.location) {
        if (geoTargetToAdd.circles && geoTargetToAdd.circles.length) {
          geoTargetToAdd.circles = geoTargetToAdd.circles.map(c => {
            return {
              ...c,
              location: {
                ...geoTargetToAdd.location,
              },
            };
          });
        }
      }
      targets.push(buildNewGeoTarget(geoTargetToAdd));
    });
    await updateGeoTargets({ create: targets }, "add");

    // Refetch the attribution targets from the backend in order to update the attribution targets table
    await props.refreshAttributionTargets();
  };

  const updateGeoTargets = async (geoTargets, action) => {
    const {
      createCampaignHistory,
      currentUser,
      campaignOrderId,
      status,
      step,
    } = props;

    try {
      // TODO: remove ui related action or components
      message.destroy();
      message.loading("Updating campaign order..", 0);

      // Get geotargets and map
      geoTargets.create &&
        geoTargets.create.map(item => {
          // Get circles object
          const getCircles = Object.values(item.circles.create);
          const getPolygons = Object.values(item.polygons.create);

          return {
            ...item,
            circles: isEmpty(getCircles)
              ? []
              : getCircles.filter(circ => {
                  //locate location and coordinate __typname and remove

                  if (circ.hasOwnProperty("location")) {
                    const loc = circ.location.create;
                    delete loc.__typename;
                  }

                  if (circ.hasOwnProperty("coordinate")) {
                    const coor = circ.coordinate.create;
                    delete coor.__typename;
                  }

                  if (circ && circ.create && circ.create.hasOwnProperty("id")) {
                    delete circ.create.id;
                  }

                  return true;
                }),
            polygons: isEmpty(getPolygons)
              ? []
              : getPolygons.filter(pol => {
                  //locate location  __typname and remove

                  if (pol.hasOwnProperty("location")) {
                    const loc = pol.location.create;
                    delete loc.__typename;
                  }

                  return true;
                }),
          };
        });
    } catch (error) {
      message.error(error);
    }

    try {
      const count = geoTargets.create
        ? geoTargets.create.length
        : geoTargets.delete.length;
      const chunks = Math.ceil(count / 100);
      const campaignOrder = props.campaignOrderID
        ? props.campaignOrderID
        : await props.handleSubmitTargeting();
      if (campaignOrder) {
        const response = await Promise.all(
          new Array(chunks).fill(undefined).map(async (_, i) => {
            // TODO: Why handle the update campaign order twice while you can update the locations in one go in handleSubmitTargeting?
            return props.updateCampaignOrderWithLocations({
              variables: {
                campaignOrderID: campaignOrder.id,
                geoTargets: {
                  create: (geoTargets.create || []).slice(i * 100, i + 1 * 100),
                  delete: (geoTargets.delete || []).slice(i * 100, i + 1 * 100),
                },
              },
            });
          })
        );

        if (response) {
          // if geoTargets.create
          if (
            props.audienceTargetingToAdd &&
            props.audienceGeoTargetsToAdd &&
            geoTargets.create
          ) {
            // look for isBase geotarget parent
            let baseTargets =
              props.audienceTargetingToAdd &&
              props.audienceTargetingToAdd.length > 0
                ? props.audienceTargetingToAdd
                : props.audienceGeoTargetsToAdd;

            baseTargets = baseTargets.map(item => {
              return {
                id: item.id,
                name: item.name,
                isBase: null,
                start: item.flightLength.start,
                end: item.flightLength.end,
              };
            });

            // step 4 done look for attributionTargets and attach
            let geotargetsList =
              response &&
              response[0] &&
              response[0].data &&
              response[0].data.updateCampaignOrder &&
              response[0].data.updateCampaignOrder.attributionTargets
                ? response[0].data.updateCampaignOrder.attributionTargets
                : response &&
                  response[0] &&
                  response[0].data &&
                  response[0].data.updateCampaignOrder &&
                  response[0].data.updateCampaignOrder.geoTargets
                ? response[0].data.updateCampaignOrder.geoTargets
                : [];

            if (baseTargets) {
              const attachThis = baseTargets.map(base => {
                const children = geotargetsList.filter(child => {
                  return (
                    child.name === base.name &&
                    child.start === base.start &&
                    child.end === base.end
                  );
                });

                return {
                  geoTargets: {
                    connect: children.map(child => ({
                      id: child.id,
                    })),
                  },
                  id: base.id,
                };
              });

              // for attachment
              if (attachThis.length > 0) {
                attachThis.forEach(attach => {
                  props.attachFligthsGeoTarget({
                    variables: attach,
                  });
                });
              }
            }
          }

          let historyAction = null;
          let geoTargetActionProp = null;

          message.destroy();
          if (action === "add") {
            geoTargetActionProp = "create";
            message.success(
              `${props.targetType} target${
                geoTargets.create.length > 1 ? `s` : ``
              } ${geoTargets.create.length > 1 ? `have` : `has`} been added!`
            );

            historyAction = "PENDING_CREATE";
          }
          if (action === "delete") {
            geoTargetActionProp = "delete";
            message.success(
              `The selected ${props.targetType} target${
                geoTargets.delete.length > 1 ? `s` : ``
              } ${geoTargets.delete.length > 1 ? `have` : `has`} been deleted.`
            );

            historyAction = "PENDING_DELETE";
          }
          if (action === "edit") {
            setGeoTargets(response[0].data.updateCampaignOrder.geoTargets);
            message.success(
              `The selected ${props.targetType} target${
                geoTargets.delete.length > 1 ? `s` : ``
              } ${geoTargets.delete.length > 1 ? `have` : `has`} been edited.`
            );

            historyAction = "PENDING_DELETE";
          }

          if (
            ["LIVE", "LIVE_PENDING"].includes(status) &&
            geoTargets[geoTargetActionProp]
          ) {
            let property = "";

            switch (step) {
              case 2:
                property = "TARGETING_ADDRESS";
                break;
              case 4:
                property = "WALK_IN_LOCATIONS";
                break;
              default:
                break;
            }

            switch (geoTargetActionProp) {
              case "create":
                geoTargets[geoTargetActionProp].forEach(target => {
                  createCampaignHistory({
                    variables: {
                      action: historyAction,
                      property,
                      data: {
                        name: target.name,
                      },
                      authorId: currentUser.id,
                      campaignOrderId,
                    },
                  });
                });
                break;

              case "delete":
                selectedForBatch.forEach(target => {
                  if (["LIVE", "LIVE_PENDING"].includes(status)) {
                    createCampaignHistory({
                      variables: {
                        action: historyAction,
                        property: property,
                        data: {
                          id: target.id,
                          name: target.name,
                        },
                        authorId: currentUser.id,
                        campaignOrderId,
                      },
                    });
                  }
                });

                break;

              default:
                break;
            }
          }
        }
      }
      await props.refreshAttributionTargets();
    } catch (error) {
      message.destroy();
      message.error(error && error.message);
    }
  };

  const updateGeoTargetsConfirmed = async () => {
    if (optionsToUpdate.address) {
      // if it's a new address it has to re-geocode and display the confirm locations modal
      const currentAddress = selectedForBatch[0]; // this is only possile while updating a single location
      const toGeoCode = {
        ADDRESS: optionsToUpdate.address,
        NAME: optionsToUpdate.name || currentAddress.name,
        RADIUS: optionsToUpdate.radius || kmToFt(currentAddress.radius),
        ID: currentAddress.key,
        START_DATE: !isEmpty(optionsToUpdate.flightLength)
          ? optionsToUpdate.flightLength.start
          : null,
        END_DATE: !isEmpty(optionsToUpdate.flightLength)
          ? optionsToUpdate.flightLength.end
          : null,
      };
      const tableData = await getTableData([toGeoCode], "edit");
      setLocationTargetsModalOpen(true);
      setConfirmLocationsTableData(tableData);
      setSelectedModalLocations(tableData.filter(loc => loc.isMatch));
    } else {
      // otherwise it uses the existing locations updates accordingly
      let newTargets = [];
      let ids = selectedForBatch.map(item => {
        let target = cloneDeep(targets[item.index]);
        if (target && target.circles.length) {
          let address = target.circles[0] || {};
          let newTarget = {
            circles: {
              radius: ftToKm(optionsToUpdate.radius) || address.radius,
              coordinate: {
                lat: address.coordinate.lat,
                lng: address.coordinate.lng,
              },
            },
            polygons: [],
            flightLength: optionsToUpdate.flightLength,
          };
          if (address.location) {
            let { __typename, ...location } = address.location;
            location = {
              ...location,
              name: (optionsToUpdate.name || "").length
                ? optionsToUpdate.name
                : location.name,
            };
            newTargets.push(
              buildNewGeoTarget({
                ...newTarget,
                circles: { ...newTarget.circles, location },
              })
            );
          } else {
            newTargets.push(
              buildNewGeoTarget({
                ...newTarget,
                name: (optionsToUpdate.name || "").length
                  ? optionsToUpdate.name
                  : target.name,
              })
            );
          }
        } else if (target && target.polygons.length) {
          let polygon = target.polygons[0] || {};
          let updatedLocation = {};
          const polygons = [...target.polygons];

          if (polygon.location) {
            updatedLocation = {
              ...polygon.location,
              name: optionsToUpdate.name
                ? optionsToUpdate.name
                : polygon.location.name,
            };

            polygons[0] = {
              ...polygon,
              location: updatedLocation,
            };
          }

          newTargets.push(
            buildNewGeoTarget({
              ...target,
              polygons,
              name: optionsToUpdate.name,
              flightLength: optionsToUpdate.flightLength,
            })
          );
        }
        return { id: item.key };
      });
      updateGeoTargets({ delete: ids, create: newTargets }, "edit");
      setSelectedForBatch([]);
    }

    toggleEditModal(false);
  };

  const toggleEditModal = open => {
    if (!open) {
      setOptionsToUpdate({});
    }
    setEditLocationModalOpen(open);
  };

  const optionsToUpdateChanged = (key, value) => {
    setOptionsToUpdate({ ...optionsToUpdate, [key]: value });
  };

  const selectedModalLocationsChanged = selected => {
    // This is redundant but I'd like to keep it decoupled from the onChange function.
    setSelectedModalLocations(selected);
  };

  const selectedForBatchChanged = selected => {
    // This is redundant but I'd like to keep it decoupled from the onChange function
    setSelectedForBatch(selected);
  };

  const onLocationTargetsModalOpenChange = open => {
    if (!open) {
      setSelectedModalLocations([]);
      setConfirmLocationsTableData([]);
    }
    setLocationTargetsModalOpen(open);
  };

  const buildNewGeoTarget = target => {
    return {
      circles: {
        create: target.circles.length
          ? target.circles.map(circle => buildCircles(circle))
          : buildCircles(target.circles),
      },
      polygons: {
        create: target.polygons.length
          ? target.polygons.map(polygon => buildPolygons(polygon))
          : buildPolygons(target.polygons),
      },
      name: target.name || "",
      start: !isEmpty(target.flightLength) ? target.flightLength.start : null,
      end: !isEmpty(target.flightLength) ? target.flightLength.end : null,
      orgs: { connect: { id: props.currentUser.role.org.id } },
      status: target.status || "CREATED",
    };
  };

  const buildCircles = circle => {
    if (!circle.coordinate) return [];
    if (!circle.coordinate.lat) {
      // is array format from geojson
      // converts to expected object format
      circle.coordinate = {
        lat: circle.coordinate[1],
        lng: circle.coordinate[0],
      };
    }
    let newCircle = {
      radius: circle.radius,
      coordinate: {
        create: circle.coordinate,
      },
    };
    if (circle.location) {
      return {
        ...newCircle,
        location: {
          create: circle.location,
        },
      };
    }
    return newCircle;
  };

  const buildPolygons = polygon => {
    if (!polygon.geometry) return [];
    let coordinates = { coordinates: polygon.geometry.coordinates };
    if (!isEmpty(coordinates)) {
      coordinates.coordinates[0].push(coordinates.coordinates[0][0]);
      return {
        location: {
          create: polygon.location,
        },
        geometry: coordinates,
      };
    }
    return [];
  };

  const onConfirmLocationOk = async () => {
    const toDelete = [];
    const geoTargets = selectedModalLocations
      .filter(address => address.isMatch && address.foundAddress)
      .map(address => {
        let location = {
          name: address.name,
          formattedAddress: address.foundAddress,
          zip: address.zip,
          street: address.street,
          city: address.city,
          county: address.county,
          state: address.state,
          country: address.country,
          lat: address.lat,
          lng: address.lng,
        };

        if (!location.state.length) {
          delete location.state;
        }
        if (address.currentID) {
          // ID of the location being edited
          toDelete.push({ id: address.currentID });
        }
        return buildNewGeoTarget({
          circles: {
            radius: ftToKm(address.radius),
            coordinate: {
              lat: address.lat,
              lng: address.lng,
            },
            location,
          },
          polygons: [],
          name: address.name,
          flightLength: address.flightLength,
        });
      });

    // Iterate through the array and validate that each location includes a country.
    // Note that this validation may evolve to include additional fields in the future.
    for (let index = 0; index < geoTargets.length; index++) {
      if (
        geoTargets[index].circles.create.location.create.country.trim() === ""
      ) {
        return message.error(
          "No valid country information found for this selection. Please select another location."
        );
      }
    }

    if (toDelete.length) {
      // this means we're editing the address of an existing location
      updateGeoTargets({ delete: toDelete, create: geoTargets }, "edit");
      setSelectedForBatch([]);
    } else {
      if (geoTargets && geoTargets.length) {
        updateGeoTargets({ create: geoTargets }, "add");
      } else {
        message.error("No valid address selected.");
        return;
      }
    }
    setLocationTargetsModalOpen(false);
    setConfirmLocationsTableData([]);
    setSelectedModalLocations([]);
  };

  const geoLocationFiltersChanged = clear => {
    if (clear) {
      setGeoLocationFilters({});
      setFilterAttribute("name");
      setFilterValue("");
    } else {
      setGeoLocationFilters({ type: filterAttribute, value: filterValue });
    }
  };

  const filterAttributeChanged = val => {
    setFilterAttribute(val);
  };

  const filterValueChanged = val => {
    setFilterValue(val);
  };

  const newGeoTargetChanged = (type, value) => {
    if (!type) {
      setNewGeoTarget({});
    } else {
      setNewGeoTarget({ ...newGeoTarget, [type]: value });
    }
  };

  const newGeoTargetConfirmed = clear => {
    if (!clear) {
      setAddGeoTargetLoading(true);
      onCsvUploadComplete([newGeoTarget]);
    }
    setNewGeoTarget({});
  };

  const createTargetLocation = async location => {
    const { circles } = parseOverlayToGeoTarget(location.circles);
    const { createLocationWithGeotarget } = props;
    let geoId = null;

    try {
      if (circles.length) {
        const responseGeo = await onCreateGeoTargetLocation({
          name: location.name,
          circles,
        });

        if (responseGeo) {
          geoId = responseGeo.data.createGeoTarget.id;
        }

        const data = getTargetLocationFormattedData({ location, geoId });
        const response = await createLocationWithGeotarget({
          variables: data,
        });

        message.destroy();
        if (response) {
          setTargetingLocations([
            ...targetingLocations,
            response.data.createLocation,
          ]);
          message.success("Successfully created Location.");
          return response.data.createLocation;
        } else {
          message.success("Failed to create Location.");
        }
      }
    } catch (error) {
      message.destroy();
      message.error("Failed to create Location.");
    }
  };

  const updateTargetLocation = async location => {
    try {
      const { circles } = parseOverlayToGeoTarget(location.circles);
      const { updateLocationWithGeotargets, updateSegment } = props;
      let geoId = null;
      const { geoframe, name, start, end, polygons } = location;

      if (geoframe) {
        const responseSegment = await updateSegment({
          variables: {
            id: geoframe.id,
            name,
            start,
            end,
            circles,
            polygons,
          },
        });

        if (responseSegment) {
          geoId = responseSegment.data.updateSegment.id;
        }

        const data = getTargetLocationFormattedData({
          location,
          circles,
          geoId,
        });

        const response = await updateLocationWithGeotargets({
          variables: { ...data, id: location.id },
        });

        message.destroy();

        if (response) {
          message.success(`Successfully updated Location`);
        } else {
          message.success(`Failed to update Location`);
        }
      }
    } catch (error) {
      message.destroy();
    }
  };

  const deleteTargetLocation = async Id => {
    message.loading("Action in progress..", 0);

    try {
      const { deleteLocation } = props;
      const response = await deleteLocation({
        variables: {
          Id,
        },
      });

      message.destroy();

      if (response) {
        const updatedTargetingLocations = targetingLocations.filter(
          location => {
            return location.id !== Id;
          }
        );

        setTargetingLocations(updatedTargetingLocations);
        message.success(`Successfully deleted Location`);
      } else {
        message.success(`Failed to delete Location`);
      }
    } catch (error) {
      message.destroy();
    }
  };

  const getTargetLocationFormattedData = ({ location, circles, geoId }) => {
    const { currentUser } = props;
    const {
      audienceType,
      city,
      country,
      formattedAddress,
      lat,
      lng,
      name,
      state,
      street,
      zip,
    } = location;

    return {
      audienceType,
      cart: "NOCART",
      circles,
      city,
      contact: "",
      country,
      description: "",
      email: "",
      end: moment().format(),
      formattedAddress,
      geoId,
      isBase: true,
      lat,
      lng,
      locationKey: "",
      menuDataKey: "",
      name,
      orgId: currentUser.role.org.id,
      phone: "",
      polygons: [],
      start: moment()
        .subtract(90, "days")
        .format(),
      state,
      street,
      url: "",
      zip,
    };
  };

  const onCreateGeoTargetLocation = async ({ name, circles }) => {
    const { createGeoTargetLocation, currentUser } = props;
    return await createGeoTargetLocation({
      variables: {
        name,
        orgID: currentUser.role.org.id,
        isBase: true,
        circles: circles,
        polygons: [],
        start: moment()
          .subtract(90, "days")
          .format(),
        end: moment().format(),
      },
    });
  };

  const onTargetingLocationCsvUpload = async parsedData => {
    let results = [];
    const { countryType } = MapConfig;
    const formattedData = parsedData.map((data, key) => {
      const formattedKeyValue = {};
      Object.keys(data).map(key => {
        return (formattedKeyValue[camelCase(key)] = data[key]);
      });

      return formattedKeyValue;
    });

    results = getTargetingLocationTableData(formattedData);

    Promise.all(results).then(values => {
      const previewData = formattedData.map((data, key) => {
        if (values[key].status === "OK") {
          const place = values[key].results[0];
          const { state, city, zip, country, address } = getFormattedAddress({
            result: place,
          });

          return {
            ...data,
            id: key,
            matched: true,
            selected: true,
            state: state.toUpperCase(),
            city,
            zip,
            street: address,
            sourceAddress: data.address,
            formattedAddress: place.formatted_address,
            country: countryType[country],
            coordinate: place.geometry.location,
          };
        }

        return {
          ...data,
          id: key,
          matched: false,
          selected: false,
          sourceAddress: data.address,
          formattedAddress: "",
        };
      });

      setPreviewTargetingLocations(previewData);
    });
  };

  const bulkImportTargetingLocations = async data => {
    const results = [];
    const locations = (data || previewTargetingLocations)
      .filter(location => location.selected)
      .map(location => {
        const circles = [
          getCircleOverlay({
            coordinate: location.coordinate,
          }),
        ];

        const {
          name,
          formattedAddress,
          radius,
          city,
          state,
          zip,
          street,
          country,
          coordinate,
          audienceType,
        } = location;

        return {
          ...OrganizationConfig.csvTemplate.defaultValues,
          name,
          formattedAddress,
          radius: Number(radius),
          city,
          state,
          zip,
          street,
          country,
          lat: coordinate.lat,
          lng: coordinate.lng,
          inProgressOverlay: [circles],
          circles,
          audienceType: audienceType || "TARGETING",
        };
      });

    if (locations && locations.length) {
      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        results.push(createTargetLocation(location));
      }

      message.loading("Action in progress..", 0);

      setPreviewTargetingLocations([]);

      Promise.all(results).then(values => {
        if (values) {
          const ids = values
            .filter(value => value !== undefined)
            .map(value => value.id);

          const filteredLocations = targetingLocations.filter(
            location => !ids.includes(location.id)
          );
          setTargetingLocations([...filteredLocations, ...values]);
        }
      });
    }
  };

  const getCircleOverlay = ({ coordinate }) => {
    const { google } = props;
    const position = new google.maps.LatLng(coordinate.lat, coordinate.lng);

    return {
      overlay: new google.maps.Circle({
        center: { lat: position.lat(), lng: position.lng() },
        radius: defaultRadius,
      }),
      type: "circle",
    };
  };

  const selectTargetingLocationPreview = id => {
    const updatedLocation = previewTargetingLocations.find(
      location => location.id === id
    );

    setPreviewTargetingLocations([
      ...previewTargetingLocations.filter(location => location.id !== id),
      { ...updatedLocation, selected: !updatedLocation.selected },
    ]);
  };

  const deleteAudienceLocation = async audiences => {
    try {
      message.loading("Action in progress..", 0);
      const results = [];
      const { deleteAudienceLocation } = props;

      audiences.forEach(audience => {
        const Id = audience.id;
        results.push(
          deleteAudienceLocation({
            variables: {
              Id,
            },
          })
        );
      });

      Promise.all(results).then(values => {
        let updatedTargetAudiences = targetingAudiences.filter(
          targetAudience => {
            return !values
              .filter(value => value && value.data)
              .map(value => value.data.deleteAudience.id)
              .includes(targetAudience.id);
          }
        );
        setTargetingAudiences(updatedTargetAudiences);
        message.destroy();
        message.success(`Audiences successfully deleted.`);
      });
    } catch (error) {
      message.destroy();
      message.success(`Failed to delete audience(s).`);
    }
  };

  const handleDeleteGeoTarget = async target => {
    const { id, name } = target;

    try {
      message.loading("Action in progress..", 0);
      const {
        deleteGeoTarget,
        status,
        createCampaignHistory,
        currentUser,
        campaignOrderId,
        updateGeoTargetData,
      } = props;
      let response = null;
      let reponseProp = null;

      if (
        ["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(status) &&
        target.status !== "PENDING_CREATE"
      ) {
        response = await updateGeoTargetData({
          variables: {
            id,
            data: {
              status: "PENDING_DELETE",
            },
          },
        });

        reponseProp = "updateGeoTarget";
        createCampaignHistory({
          variables: {
            action: "PENDING_DELETE",
            property: "TARGETING_ADDRESS",
            data: {
              id,
              name,
            },
            authorId: currentUser.id,
            campaignOrderId,
          },
        });
      } else {
        response = await deleteGeoTarget({
          variables: {
            id,
          },
        });
        reponseProp = "deleteGeoTarget";
      }

      if (response && response.data && response.data[reponseProp]) {
        const updatedTargetingLocations = geoTargets.filter(location => {
          return location.id !== id;
        });

        setGeoTargets(updatedTargetingLocations);
        message.destroy();
        message.success(`Successfully deleted Location`);
        return response.data.deleteGeoTarget;
      } else {
        throw new Error("Failed to delete Location.");
      }
    } catch (error) {
      message.destroy();
      message.error(error.message);
      return null;
    }
  };

  return React.cloneElement(children, {
    ...props,
    batchDeleteClicked,
    onCsvUploadComplete,
    selectedModalLocations,
    confirmLocationsTableData,
    selectedForBatch,
    updateGeoTargetsConfirmed,
    filterAttributeChanged,
    filterValueChanged,
    filterAttribute,
    addTargetFromMapClicked,
    confirmTargetFromMap,
    targetDetailsModalOpen,
    toggleTargetDetailsModal,
    changeTargetDetails,
    newGeoTarget,
    newGeoTargetChanged,
    newGeoTargetConfirmed,
    addGeoTargetLoading,
    onGeoJsonUploadComplete,
    filterValue,
    geoLocationFiltersChanged,
    locationTargetsModalOpen,
    locationTargets: targets,
    optionsToUpdateChanged,
    editLocationModalOpen,
    toggleEditModal,
    onConfirmLocationOk,
    selectedModalLocationsChanged,
    onLocationTargetsModalOpenChange,
    selectedForBatchChanged,
    updateFromExistingAudience,
    createTargetLocation,
    targetingLocations,
    deleteTargetLocation,
    updateTargetLocation,
    onTargetingLocationCsvUpload,
    previewTargetingLocations,
    setPreviewTargetingLocations,
    bulkImportTargetingLocations,
    selectTargetingLocationPreview,
    targetingAudiences,
    deleteAudienceLocation,
    selectedTargetingLocations,
    setSelectedTargetingLocations,
    handleAddTargetFromAddresses,
    updateGeoTargets,
    handleUpdateTargtingAddress,
    handleDeleteGeoTarget,
    geoTargets,
    eventTargets,
  });
};

export default GeotargetManagerController;
