import React from "react";
import { graphql, useLazyQuery, useQuery, useMutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";

import qs from "qs";

//queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import LIST_ORGS_ADMIN from "../../GraphQl/Queries/LIST_ORGS_ADMIN";
import AUDIENCES_CONNECTION from "../../GraphQl/Queries/AUDIENCES_CONNECTION";
import REPROCESS_AUDIENCE from "../../GraphQl/Queries/REPROCESS_AUDIENCE";
import UPDATE_AUDIENCE_SHARING from "../../GraphQl/Mutations/UPDATE_AUDIENCE_SHARING";

//local
import Controller from "./Controller";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";

import { UPDATEDAT_DESC } from "../../../platform/ux/Audiences/utils";
import { determineUserAccess } from "../../permissions/userLevelAccess";

//CONSTANTS
const allowedOrderBys = {
  name_ASC: true,
  name_DESC: true,
};

const getAudiencesToRender = data => {
  const { edges } = data;
  const Audiences = edges.map(item => {
    return item.node;
  });

  return Audiences;
};

const Loader = props => {
  const { tableConfig } = props;
  const { pageNumber } = props.match.params;
  const params = qs.parse(props.location.search);
  const { orderBy, type, searchTerm } = params;
  const config = props.currentUser.permission;
  const org = props.currentUser.role.org.name;
  const orgId = props.currentUser.role.org.id;
  const userPermission = determineUserAccess(config);

  const getQueryVariables = () => {
    const urlParams = new URLSearchParams(props.location.search);
    const pageSize = urlParams.get("pageSize")
      ? parseInt(urlParams.get("pageSize"))
      : 10;
    const isNewPage = props.location.pathname.includes("new");
    const page = parseInt(pageNumber, 10);
    const skip = isNewPage ? (page - 1) * pageSize : 0;
    const first = isNewPage ? pageSize : 100;

    let currentRole = props.currentUser.role.org.name;
    let orgsSome = {
      orgs_some: {
        OR: [
          { name: props.currentUser.role.org.name },
          { parentOrg_some: { name: props.currentUser.role.org.name } },
        ],
      },
    };

    if (currentRole === "MediaJelAdmin" || currentRole === "MediaJel") {
      orgsSome = {};
    }

    let orderBy = UPDATEDAT_DESC;
    const sort = urlParams.get("sort");
    if (sort && allowedOrderBys[sort]) orderBy = sort;

    const where = {
      AND: [
        orgsSome,
        {
          type,
        },
        { type_not: "SEO" },
      ],
    };

    if (searchTerm) {
      where["AND"].push({
        OR: [
          {
            name_contains: searchTerm,
          },
          {
            description_contains: searchTerm,
          },
          {
            eventsTargets_some: {
              name_contains: searchTerm,
            },
          },
        ],
      });
    }
    return { first, skip, orderBy: orderBy, where };
  };

  const [reprocessAudience] = useLazyQuery(REPROCESS_AUDIENCE, {
    variables: {
      id: orgId.toString(),
    },
  });

  const [updateAudienceSharing] = useMutation(UPDATE_AUDIENCE_SHARING);

  const { loading, error, data, refetch } = useQuery(AUDIENCES_CONNECTION, {
    variables: getQueryVariables(),
  });

  const { loading: orgsLoading, data: orgsData } = useQuery(LIST_ORGS_ADMIN, {
    variables: { parentid: orgId, first: 1000 },
  });

  if (loading || orgsLoading) {
    return <OverlaySpinner />;
  }

  if (error) return `Error (Query: AUDIENCES_CONNECTION)! ${error.message}`;
  const onPage = parseInt(pageNumber, 10);
  const { audiencesConnection, full } = data;
  const count = full.aggregate.count;
  const audiences = getAudiencesToRender(audiencesConnection);

  return (
    <Controller
      {...props}
      audiences={audiences}
      tableConfig={tableConfig}
      getQueryVariables={getQueryVariables}
      onPage={onPage}
      count={count}
      orderByFilter={orderBy}
      org={org}
      orgConfig={userPermission}
      reprocessAudience={reprocessAudience}
      orgs={orgsData ? orgsData.orgs : []}
      orgsLoading={orgsLoading}
      updateAudienceSharing={updateAudienceSharing}
      refetch={refetch}
    />
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(Loader));
