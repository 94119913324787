import { Space, Typography } from "antd";
import img1 from "../../../../../../assets/tagsInstructions/bigCommerce/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/bigCommerce/img2.png";
import img3 from "../../../../../../assets/tagsInstructions/bigCommerce/img3.png";
import img4 from "../../../../../../assets/tagsInstructions/bigCommerce/img4.png";
import img5 from "../../../../../../assets/tagsInstructions/bigCommerce/img5.png";

import React from "react";
import InstructionsBuilder from "./InstructionsBuilder";
import { Page, Text, Document } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";
import {
  WordPressInstructions,
  WordPressInstructionsPDF,
} from "./WordPressInstructions";
import { WixInstructions, WixInstructionsPDF } from "./WixInstructions";

const { Link } = Typography;

const bigCommerceSteps = generatedTag => [
  {
    instruction: (
      <>
        Login to{" "}
        <Link
          href="https://login.bigcommerce.com/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bigcommerce
        </Link>{" "}
        dashboard.
      </>
    ),
    pdfText: "Login to Bigcommerce dashboard.",
  },
  {
    instruction: "On the navigation sidebar, go to Channel Manager.",
    image: img1,
  },
  {
    instruction: "On the Quick Links inside Storefronts, click on the Scripts.",
    image: img2,
  },
  {
    instruction: "Click on Create Scripts on the top right corner.",
    image: img3,
  },
  {
    instruction: (
      <>
        Follow these steps and choose the following options.
        <ul>
          <li>Add a Script name to identify the script.</li>
          <li>
            On the Placement, choose Header instead of{" "}
            <Typography.Text code>Footer</Typography.Text>.
          </li>
          <li>
            To trigger on every page, choose the{" "}
            <Typography.Text code>All pages</Typography.Text> option on the
            location.
          </li>
          <li>
            Choose <Typography.Text code>Essential</Typography.Text> on the
            Script Category.
          </li>
          <li>
            Choose <Typography.Text code>Script</Typography.Text> on the Script
            Script Type instead of <Typography.Text code>URL</Typography.Text>.
          </li>
        </ul>
      </>
    ),
    image: img4,
    pdfTextCode: (
      <div
        style={{
          fontSize: 12,
          marginBottom: 20,
          marginTop: 20,
          lineHeight: 1.6,
        }}
      >
        <Text>5. Follow these steps and choose the following options.</Text>
        <Text style={{ marginLeft: 20 }}>
          a. Add a Script name to identify the script.
        </Text>
        <Text style={{ marginLeft: 20 }}>
          b. On the Placement, choose Header instead of Footer.
        </Text>
        <Text style={{ marginLeft: 20 }}>
          c. To trigger on every page, choose the All pages option on the
          location.
        </Text>
        <Text style={{ marginLeft: 20 }}>
          d. Choose Essential on the Script Category.
        </Text>
        <Text style={{ marginLeft: 20 }}>
          e. Choose Script on the Script Type instead of URL.
        </Text>
      </div>
    ),
  },
  {
    instruction: "Paste the tag on the textbox below the Script Contents.",
    image: img5,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          6. Paste the tag on the textbox below the Script Contents.
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: "Click Save.",
  },
];

export const BigCommerceInstructions = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <Space direction="vertical">
        <InstructionsBuilder
          title="BigCommerce"
          steps={bigCommerceSteps(generatedTag)}
        />
        <WordPressInstructions generatedTag={generatedTag} />
        <WixInstructions generatedTag={generatedTag} />
      </Space>
    </React.Fragment>
  );
};

export const BigCommerceInstructionsPDF = ({ generatedTag }) => {
  return (
    <Document>
      <Page
        wrap={false}
        size="A4"
        style={{
          padding: "40px",
        }}
      >
        <Text
          style={{
            fontSize: 15,
            marginBottom: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          Cart Instruction for BigCommerce
        </Text>
        <GeneratedTagPDF
          title={"BigCommerce"}
          steps={bigCommerceSteps(generatedTag)}
        />
        <WordPressInstructionsPDF generatedTag={generatedTag} />
        <WixInstructionsPDF generatedTag={generatedTag} />
      </Page>
    </Document>
  );
};
