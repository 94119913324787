import React from "react";
import { Tabs, Tooltip } from "antd";

// COMPONENTS
import TargetingLocations from "./TargetingLocations";
import TargetingLocationAudiences from "./TargetingLocationAudiences";
import RegionGroup from "../RegionGroup";
import { TargetingDealIds } from "./TargetingDealIds";
//import MiscellaneousTab from "../MiscellaneousTab";

export default function TargetingLocationTab({
  attributionAudienceName,
  createTargetingLocation,
  deleteLocation,
  google,
  locationList,
  onChangeStateValue,
  onCsvUploadComplete,
  onSubmitAudience,
  selectedLocations,
  updateTargetingLocation,
  isTargetingPreviewModalOpen,
  targetingLocationsPreviewData,
  closeTargetingPreviewModal,
  bulkImportTargetingLocations,
  selectTargetingLocationPreview,
  targetingAudiences,
  deleteAudienceLocation,
  deleteBulkLocation,
  handleSubmitRegionGroup,
  handleUpdateRegionGroup,
  deleteRegionGroup,
  regionGroups,
  selectedOrg,
  isSelfService,
  setAudienceAmplifyDefault,
  isPreviewOrg,
  amplify,
  dealIds,
  createDealId,
  deleteDealIds,
  updateDealId,
  presetRegionGroups,
  presetRegionGroupsLoading,
}) {
  const { TabPane } = Tabs;

  return (
    <Tabs type="card" defaultActiveKey="location">
      <TabPane
        tab={
          <Tooltip
            title={
              "Have the ability to add specific locations to target for your advertising or marketing efforts. This includes creating custom audiences based on these locations, and managing your location data through the import and export of CSV files. You can also download a sample template as a guide. Additionally, you have the ability to delete any location from your targeting locations list as per your preference."
            }
          >
            <span>Addresses</span>
          </Tooltip>
        }
        key="location"
      >
        <TargetingLocations
          attributionAudienceName={attributionAudienceName}
          createTargetingLocation={createTargetingLocation}
          deleteLocation={deleteLocation}
          google={google}
          locationList={locationList}
          onChangeStateValue={onChangeStateValue}
          onCsvUploadComplete={onCsvUploadComplete}
          onSubmitAudience={onSubmitAudience}
          selectedLocations={selectedLocations}
          updateTargetingLocation={updateTargetingLocation}
          isTargetingPreviewModalOpen={isTargetingPreviewModalOpen}
          targetingLocationsPreviewData={targetingLocationsPreviewData}
          closeTargetingPreviewModal={closeTargetingPreviewModal}
          bulkImportTargetingLocations={bulkImportTargetingLocations}
          selectTargetingLocationPreview={selectTargetingLocationPreview}
          deleteBulkLocation={deleteBulkLocation}
          targetingAudiences={targetingAudiences}
          selectedOrg={selectedOrg}
        />
      </TabPane>
      <TabPane
        tab={
          <Tooltip
            title={
              "The ability to to create an audience from a targeting location "
            }
          >
            <span>Audiences</span>
          </Tooltip>
        }
        key="audience"
      >
        <TargetingLocationAudiences
          title="Targeting Audiences"
          targetingAudiences={targetingAudiences}
          deleteAudienceLocation={deleteAudienceLocation}
          setAudienceAmplifyDefault={setAudienceAmplifyDefault}
          amplify={amplify}
        />
      </TabPane>
      {isSelfService || isPreviewOrg ? (
        <TabPane
          tab={
            <Tooltip
              title={
                "Is a set of one or multiple regions that can be selected and used together in certain actions. This allows you to group similar regions together and apply the same actions to all regions within the group at once. "
              }
            >
              <span>Region Groups</span>
            </Tooltip>
          }
          key="region-group"
        >
          <RegionGroup
            handleSubmitRegionGroup={handleSubmitRegionGroup}
            handleUpdateRegionGroup={handleUpdateRegionGroup}
            regionGroups={regionGroups}
            deleteRegionGroup={deleteRegionGroup}
            presetRegionGroups={presetRegionGroups}
            presetRegionGroupsLoading={presetRegionGroupsLoading}
          ></RegionGroup>
        </TabPane>
      ) : null}

      <TabPane tab="Deal Ids" key="deal-ids">
        <TargetingDealIds
          dealIds={dealIds}
          createDealId={createDealId}
          deleteDealIds={deleteDealIds}
          updateDealId={updateDealId}
        />
      </TabPane>

      {/* <TabPane tab={<span>Category</span>} key="iab-categories">
        <MiscellaneousTab
          handleSubmitIABCategoryGroup={handleSubmitIABCategoryGroup}
          iABCategoryGroups={iABCategoryGroups}
          deleteIABCategoryGroup={deleteIABCategoryGroup}
        ></MiscellaneousTab>
      </TabPane> */}
    </Tabs>
  );
}
