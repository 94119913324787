export const orgsProcessCbb = list => {
  //processor for data aquired from the cbb working july 10, 2018
  //assumes a consistent shape of json files
  //used to transform data from the cbb to match the shape of Org in the yocanna db
  //there are currently no images that come from the cbb data
  //as a placeholder we insert the YO icon
  const logo = "cjhmd9fvpl4vc0b62hq5anqe4";

  let license = "NOT_APPLICABLE";
  const processed = list.map(item => {
    if (item.Designation.indexOf("Adult-Use") >= 0) {
      license = "RECREATIONAL";
    } else {
      license = "MEDICAL";
    }

    const record = {
      name: item.Business,
      dba: item.DBA ? item.DBA : null,
      description: "n/a",
      type: "DISPENSARY",
      level: "STANDARD",
      website: "N/A",
      logo,
      licenseType: license,
      licenseVerified: item.Status === "Active" ? true : false,
      licenseExpires: new Date(item.Expiration),
      phone: item.Phone,
      region: ["NORTHERN_CALIFORNIA"],
    };
    return record;
  });

  return processed;
};

export const StrainsProcessCbr = list => {
  //processor for data aquired from the cbb working july 10, 2018
  //assumes a consistent shape of json files
  //used to transform data from the cbb to match the shape of Org in the yocanna db
  //there are currently no images that come from the cbb data
  //as a placeholder we insert the YO icon
  const image = "cjhmd9fvpl4vc0b62hq5anqe4";
  const processed = list.map(item => {
    const record = {
      name: item.name,
      description: "",
      featuredImageId: image,
      mediaIds: image,
      effects: item.effects,
    };

    return record;
  });

  return processed;
};

//this should use introspection

export const CatalogProcessorWM = list => {
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  //alert(extractContent("<p>Hello</p><a href='http://w3c.org'>W3C</a>"));

  const catSubSelector = str => {
    let record = {};

    const cat = str.toUpperCase();
    if (cat === "INDICA" || cat === "SATIVA" || cat === "HYBRID") {
      record = { cat: "FLOWER", sub: cat };
    } else if (cat === "DRINK") {
      record = { cat: "BEVERAGE", sub: "HYBRID" };
    } else if (cat === "TOPICALS") {
      record = { cat: "TOPICAL", sub: "HYBRID" };
    } else if (cat === "TINCTURE") {
      record = { cat: "TINCTURE_SUBLINGUAL", sub: "HYBRID" };
    } else if (cat === "PREROLL") {
      record = { cat: "PRE_ROLL", sub: "HYBRID" };
    } else {
      record = { cat, sub: "HYBRID" };
    }

    return record;
  };

  const processed = [];
  list.forEach(item => {
    const categories = catSubSelector(item.title);
    item.items.forEach(catitem => {
      const { name, prices } = catitem;
      const description = extractContent(catitem.body);
      let price = 0;
      if (prices.gram) {
        price = parseFloat(prices.gram);
      } else if (prices.unit) {
        price = parseFloat(prices.unit);
      } else parseFloat(0);

      const record = {
        name,
        description,
        category: categories.cat,
        subcategory: categories.sub,
        price,
      };
      processed.push(record);
    });
  });

  return processed;
};

export const CatalogProcessorWM02 = list => {
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  alert(extractContent("<p>Hello</p><a href='http://w3c.org'>W3C</a>"));

  const catSubSelector = str => {
    let record = {};

    const cat = str.toUpperCase();
    if (cat === "INDICA" || cat === "SATIVA" || cat === "HYBRID") {
      record = { cat: "FLOWER", sub: cat };
    } else if (cat === "DRINK") {
      record = { cat: "BEVERAGE", sub: "HYBRID" };
    } else if (cat === "TOPICALS") {
      record = { cat: "TOPICAL", sub: "HYBRID" };
    } else if (cat === "TINCTURE") {
      record = { cat: "TINCTURE_SUBLINGUAL", sub: "HYBRID" };
    } else if (cat === "PREROLL") {
      record = { cat: "PRE_ROLL", sub: "HYBRID" };
    } else {
      record = { cat, sub: "HYBRID" };
    }

    return record;
  };

  const processed = [];
  list.forEach(item => {
    const categories = catSubSelector(item.category.name);
    const { name, prices } = item;
    let price = 0;
    if (prices.ounce) {
      price = parseFloat(prices.ounce[0].price);
    } else if (prices.gram) {
      price = parseFloat(prices.gram[0].price);
    } else if (prices.unit) {
      price = parseFloat(prices.unit.price);
    } else parseFloat(0);
    const record = {
      name,
      description: "",
      category: categories.cat,
      subcategory: categories.sub,
      price,
    };
    processed.push(record);
  });

  return processed;
};

export const locationsProcessLicensed = list => {};

//old geocoder - needs testing - depends on having valid address
export const geocoder = (ln, cb, key) => {
  const { address, city, state } = ln;
  const protocol = "https://";
  const url = address + "," + city + "," + state;
  const api = "maps.googleapis.com/maps/api/geocode/json?address=";
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      const location = JSON.parse(xhr.responseText).results[0].geometry
        .location;
      cb(location);
    }
  };
  xhr.open("GET", protocol + api + url + key, true);
};
