import React from "react";
import { Row, Col, Card, Select } from "antd";
import TransactionPublisherRingChart from "./TransactionPublisherRingChart";
import { prepareCTR } from "../../../../../../../../core/utils/campaigns";
// Global Styling
import {
  CardTitleText,
  MenuItemText,
  TableColumnTitle,
} from "../../../../../../../shared/globalStyling/styledText";
//colors
import { colors } from "../../../../../../../../core/utils/constants/listOfColors";
import TransactionPublisherTable from "./TransactionPublisherTable";

const { Option } = Select;
const Style = {
  campaignCardStyle: {
    margin: 5,
    padding: 2,
    height: 340,
    backgroundColor: "#fff",
  },
  campaignCardStyleCreatives: {
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 10,
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 24,
    offset: 0,
  },
  md: {
    span: 16,
    offset: 0,
  },
  lg: {
    span: 16,
    offset: 0,
  },
  xl: {
    span: 16,
    offset: 0,
  },
  xsPie: {
    span: 24,
    offset: 0,
  },
  smPie: {
    span: 24,
    offset: 0,
  },
  mdPie: {
    span: 8,
    offset: 0,
  },
  lgPie: {
    span: 8,
    offset: 0,
  },
  xlPie: {
    span: 8,
    offset: 0,
  },
};

const {
  xs,
  sm,
  md,
  lg,
  xl,
  xsPie,
  smPie,
  mdPie,
  lgPie,
  xlPie,
  keyColors,
  campaignCardStyle,
  campaignCardStyleCreatives,
} = Style;

const options = [
  {
    value: "impressions",
    text: "Impressions",
  },
  {
    value: "totalTransactions",
    text: "Transactions",
  },
];

const Extra = ({ setPublisherDimension }) => {
  return (
    <Row type="flex" justify="space-between" align="middle">
      <Col style={{ paddingRight: "10px" }} span={8}>
        <CardTitleText text={"Breakdown"} />
      </Col>
      <Col span={16}>
        <Row type="flex" justify="end" align="middle">
          <span style={{ marginRight: "10px" }}>
            <TableColumnTitle text={"Show By:"} />
          </span>
          <Select
            style={{ width: "60%" }}
            defaultValue={options[0].value}
            onChange={value => {
              setPublisherDimension(value);
            }}
          >
            {options.map((option, index) => {
              return (
                <Option key={index} value={option.value}>
                  <MenuItemText text={option.text} />
                </Option>
              );
            })}
          </Select>
        </Row>
      </Col>
    </Row>
  );
};

const TransactionPublisherDetails = props => {
  const {
    transactionsPublishers,
    publisherDimension,
    setPublisherDimension,
  } = props;

  const formatTransactionData =
    transactionsPublishers &&
    transactionsPublishers.map(val => {
      return {
        imageUrl: val.mediaSource ? val.mediaSource : "", //TODO IMAGE URL OF PUBLISHER
        impressions: val.impressions,
        totalTransactions: val.totalTransactions,
        xAxis: val.title ? val.title : val.IMPRESSION_SITENAME,
      };
    });

  const parsedData = prepareCTR(formatTransactionData);
  const colorValuePairs = parsedData.map((el, index) => {
    if (el.xAxis) {
      return {
        ...el,
        value: el.xAxis,
        color: colors[index],
      };
    } else if (el.value) {
      return {
        ...el,
        color: colors[index],
      };
    }
    return {
      ...el,
      value: `Creative ${index}`,
      color: colors[index],
    };
  });

  return (
    <Row gutter={35}>
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <Card
          style={campaignCardStyleCreatives}
          headStyle={{ borderWidth: "3px", height: "66px" }}
          bodyStyle={{ padding: 0 }}
          title={<CardTitleText text={"Publishers"} />}
        >
          <TransactionPublisherTable publisherData={colorValuePairs} />
        </Card>
      </Col>
      <Col xs={xsPie} sm={smPie} md={mdPie} lg={lgPie} xl={xlPie}>
        <Card
          style={campaignCardStyleCreatives}
          bodyStyle={{ padding: 0, height: "100%" }}
          headStyle={{ borderWidth: "2px", height: "66px" }}
          title={<Extra setPublisherDimension={setPublisherDimension} />}
        >
          <TransactionPublisherRingChart
            data={colorValuePairs}
            keyColors={keyColors}
            height={campaignCardStyle.height}
            selectedPublisherDimension={publisherDimension}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TransactionPublisherDetails;
