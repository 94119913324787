import gql from "graphql-tag";

const UPDATE_GEOTARGET_NEW = gql`
  mutation updateGeoTarget($id: ID!, $data: GeoTargetUpdateInput!) {
    updateGeoTarget(where: { id: $id }, data: $data) {
      vwiUpdated
      id
      name
      status
      orgs {
        id
        name
      }
      circles {
        id
        radius
        location {
          id
          name
          formattedAddress
          zip
          street
          city
          county
          state
          country
          lat
          lng
          audienceType
          hasNotes
          notes
        }
        coordinate {
          id
          lat
          lng
        }
      }
      polygons {
        id
        geometry
        location {
          id
          name
          formattedAddress
          zip
          street
          city
          county
          state
          country
          lat
          lng
        }
      }
    }
  }
`;

export default UPDATE_GEOTARGET_NEW;
