import React from "react";
import { Input, Space, Row } from "antd";

const { Search } = Input;

const style = {
  container: {
    height: "50px",
    border: "0.5px solid #e8e8e8",
    background: "#FAFAFA",
    padding: "8px 30px",
  },
};
const SearchBar = ({ onSearch }) => {
  return (
    <div style={style.container}>
      <Row>
        <Space align="center">
          <Search placeholder="Search Org" allowClear onSearch={onSearch} />
        </Space>
      </Row>
    </div>
  );
};

export default SearchBar;
