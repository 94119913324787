import gql from "graphql-tag";

const ATTACH_FLIGHTS_GEOTARGET = gql`
  mutation updateGeoTarget($id: ID!, $geoTargets: GeoTargetUpdateManyInput!) {
    updateGeoTarget(where: { id: $id }, data: { flights: $geoTargets }) {
      id
      name
      start
      end
      isBase
      flights {
        name
        start
        end
        isBase
      }
    }
  }
`;

export default ATTACH_FLIGHTS_GEOTARGET;
