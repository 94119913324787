import gql from "graphql-tag";

export const CampaignOrderQueueSummaryFields = gql`
  fragment CampaignOrderQueueSummaryFields on CampaignOrder {
    id
    createdAt
    endDate
    type
    status
    name
    description
    isLaunchProgrammatically
    dspConfig
    advertiser {
      id
      name
    }
  }
`;
