import { Button, Form, Modal, Select, Input, message } from "antd";
import React, { useState } from "react";
import { providers } from "../../../../../core/utils/constants/constants";

const RegenerateTag = ({ cart, regenerateTag, domain, carts }) => {
  const [openRegenerateModal, setOpenRegenerateModal] = useState(false);
  const [form] = Form.useForm();

  const GetCartPlatform = cart => {
    //Newly created tag
    const selectedCart = carts.find(provider => provider.value === cart);
    //if no cart is found check legacy carts
    if (!selectedCart) {
      const legacyCart = providers.find(
        provider => provider.legacyCtr === cart
      );
      if (!legacyCart) {
        return "thirdparty"; //Mark unsupported carts as thirdparty
      }
      return legacyCart.value;
    } else {
      return selectedCart.value;
    }
  };

  return (
    <>
      <Button
        shape="round"
        type="primary"
        onClick={() => setOpenRegenerateModal(!openRegenerateModal)}
      >
        Regenerate Tag
      </Button>
      <Modal
        onCancel={() => setOpenRegenerateModal(false)}
        onOk={() => {
          form.validateFields().then(async values => {
            message.loading("Regenerating Tag");
            await regenerateTag(values);
            form.resetFields();

            message.destroy();
            message.success("Tag Regenerated");

            setOpenRegenerateModal(false);
          });
        }}
        okText="Regenerate"
        title="Regenerate Tag"
        visible={openRegenerateModal}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            cart: GetCartPlatform(cart),
            domain,
          }}
        >
          <Form.Item
            name={"cart"}
            rules={[
              {
                required: true,
                message: "Cart Platform Required",
              },
            ]}
            label={"Cart Platform"}
          >
            <Select
              placeholder="Select Cart Platform"
              options={carts.map(providers => ({
                value: providers.value,
                label: providers.label,
              }))}
            />
          </Form.Item>
          <Form.Item
            name={"domain"}
            label="Tag Domain"
            rules={[
              {
                required: true,
                message: "Tag Domain Required",
              },
            ]}
          >
            <Input placeholder="Tag Domain" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RegenerateTag;
