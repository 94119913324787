import React from "react";
import { Radio, Space, Button, Dropdown, Menu } from "antd";

const menu = ({ currentLocationId, locations, setCurrentLocationId }) => {
  return (
    <Menu>
      <Radio.Group
        onChange={e => setCurrentLocationId(e.target.value)}
        value={currentLocationId}
      >
        <Space direction="vertical">
          <Menu.Item>
            <Radio value={""}>
              <b>Default View</b>
            </Radio>
          </Menu.Item>
          {locations &&
            locations.map(location => (
              <Menu.Item key={location && location.id}>
                <Radio value={location && location.id}>
                  {location && location.name}
                </Radio>
              </Menu.Item>
            ))}
        </Space>
      </Radio.Group>
    </Menu>
  );
};

const LocationsDropdown = ({
  title,
  currentLocationId,
  locations,
  setCurrentLocationId,
}) => {
  return (
    <div>
      <Dropdown
        trigger={["click"]}
        overlay={menu({ currentLocationId, locations, setCurrentLocationId })}
        placement="bottomRight"
        arrow
      >
        <Button>{title}</Button>
      </Dropdown>
    </div>
  );
};

export default LocationsDropdown;
