const countryISP = [
  {
    label: "USA",
    value: "227",
    key: "USA",
  },
  {
    label: "CANADA",
    value: "40",
    key: "CANADA",
  },
  {
    label: "UNITED KINGDOM",
    value: "226",
    key: "UK",
  },
  {
    label: "SPAIN",
    value: "200",
    key: "SPAIN",
  },
];

export const LiquidMConstant = {
  countryISP,
  defaultCountryISP: "227",
};
