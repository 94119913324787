import React from "react";
import { Chart, Axis, Tooltip, Geom } from "bizcharts";
import { isEmpty } from "lodash";
import {
  getLeftRightScale,
  formatWholeNumber,
  formatNumber4SigFig,
  isUnixTimestamp,
} from "../../../../../../../../../core/utils/campaigns";
import moment from "moment";

const DualAxisArea = ({ data, leftY, rightY, showDayOnly = false }) => {
  //Most key values are a single word and will match if converted to lower case
  let LeftAxis = leftY.toLowerCase();
  let RightAxis = rightY.toLowerCase();
  const axisData = data.map(item => {
    const keyValue = Object.entries(item);

    //Key values that contain spaces are multiple words and must be converted to camel case
    //Ie: Total Cost -> totalCost
    if (leftY.includes(" ")) {
      //converts to camel case by making the first letter lower case and removing spaces
      LeftAxis = `${leftY.charAt(0).toLowerCase()}${leftY.slice(1)}`.replace(
        / /g,
        ""
      );
    }
    if (rightY.includes(" ")) {
      //converts to camel case by making the first letter lower case and removing spaces
      RightAxis = `${rightY.charAt(0).toLowerCase()}${rightY.slice(1)}`.replace(
        / /g,
        ""
      );
    }

    const left = keyValue[keyValue.findIndex(pair => pair[0] === LeftAxis)];
    const right = keyValue[keyValue.findIndex(pair => pair[0] === RightAxis)];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === "xAxis")];

    return {
      left: left && left[1] ? left[1] : "",
      right: right && right[1] ? right[1] : "",
      xAxis: isUnixTimestamp(x[1])
        ? moment
            .unix(x[1])
            .utc()
            .format("YYYY-MM-DD")
        : moment(x[1])
            .utc()
            .format("YYYY-MM-DD"),
    };
  });
  const scale = getLeftRightScale(data, LeftAxis, RightAxis);

  const formatXaxis = (x, format) => {
    if (x === "Sun" || x === "Sunday") return "Sunday";
    if (x === "Mon" || x === "Monday") return "Monday";
    if (x === "Tue" || x === "Tuesday") return "Tuesday";
    if (x === "Wed" || x === "Wednesday") return "Wednesday";
    if (x === "Thu" || x === "Thursday") return "Thursday";
    if (x === "Fri" || x === "Friday") return "Friday";
    if (x === "Sat" || x === "Saturday") return "Saturday";
    return moment(x).format(format);
  };

  const styles = {
    chart: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const gridRight = {
    lineStyle: {
      stroke: "#e8e8e8",
      lineDash: [2, 4],
    },
  };

  const gridLeft = {
    lineStyle: {
      stroke: "#e8e8e8",
      lineDash: [1, 0],
    },
  };

  const rightLabel = {
    textStyle: {
      fill: "#92d5a7",
    },
  };

  const leftLabel = {
    textStyle: {
      fill: "#7dafff",
    },
  };

  if (isEmpty(data)) {
    return <div />;
  }

  return (
    <Chart
      height={400}
      forceFit
      data={axisData}
      scale={scale}
      padding="auto"
      style={styles.chart}
      onTooltipChange={e => {
        let items = e.items;
        const origin1 = items[0];
        const origin2 = items[1];
        items.splice(0);
        items.push({
          name: origin1.name,
          size: origin1.size,
          showMarker: origin1.showMarker,
          title: showDayOnly
            ? formatXaxis(origin1.point._origin.xAxis, "dddd")
            : formatXaxis(origin1.point._origin.xAxis, "dddd MMMM D"),
          marker: origin1.marker,
          color: origin1.color,
          value:
            origin1.name === "Click through ratio (%)"
              ? `${formatNumber4SigFig(origin1.value)}%`
              : formatWholeNumber(origin1.value),
          x: origin1.x,
          y: origin1.y,
          point: origin1.point,
        });
        if (origin2) {
          items.push({
            name: origin2.name,
            size: origin2.size,
            showMarker: origin2.showMarker,
            title: "",
            marker: origin2.marker,
            color: origin2.color,
            value:
              origin2.name === "Click through ratio (%)"
                ? `${formatNumber4SigFig(origin2.value)}%`
                : formatWholeNumber(origin2.value),
            x: origin2.x,
            y: origin2.y,
            point: origin2.point,
          });
        }
      }}
    >
      <Axis
        name="xAxis"
        label={{
          formatter: xAxis =>
            showDayOnly
              ? formatXaxis(xAxis, "dddd")
              : formatXaxis(xAxis, "M/D/YY"),
        }}
      />

      <Axis
        name="left"
        grid={gridLeft}
        label={leftY === "None" ? null : leftLabel}
      />
      <Axis
        visible={rightY === "None" ? false : true}
        name="right"
        grid={gridRight}
        label={rightY === "None" ? null : rightLabel}
      />

      <Tooltip title={true} />
      <Geom type="lineStack" position={`xAxis*left`} color="#b2d5ff" size={0} />
      <Geom type="areaStack" position={`xAxis*left`} color="#b2d5ff" size={0} />
      <Geom
        type="lineStack"
        position={`xAxis*right`}
        color="#89d2d4"
        size={0}
      />
      <Geom
        type="areaStack"
        position={`xAxis*right`}
        color="#89d2d4"
        size={0}
      />
    </Chart>
  );
};

export default DualAxisArea;
