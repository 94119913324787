export const legacyOrgMap = {
  METEORA: "Legacy",
  AMOBEE: "MediaJel",
  APPNEXUS: "MediaJel",
  RTBIQ: "MediaJel",
};

export const handleHideProvider = campaigns => {
  return campaigns.map(campaign => {
    return {
      ...campaign,
      demandSidePlatform: legacyOrgMap[campaign.demandSidePlatform]
        ? legacyOrgMap[campaign.demandSidePlatform]
        : "MediaJel",
    };
  });
};
