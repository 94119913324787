import React from "react";
import View from "./TransactionsTab";
import Loader from "../../../../../../core/components/campaigns/campaigndetails/transactionsTab/TransactionsTabDataLoader";

// Feature level subroutes
const TransactionsTab = props => {
  return (
    <Loader {...props}>
      <View />
    </Loader>
  );
};

export default TransactionsTab;
