import React from "react";
import { Modal } from "antd";

export const FilterModalPerformance = ({
  filterModalPerformanceVisible,
  onCancel,
}) => {
  return (
    <Modal
      title="Filter"
      visible={filterModalPerformanceVisible}
      onCancel={onCancel}
    ></Modal>
  );
};
