import gql from "graphql-tag";
const CURRENT_USER = gql`
  query currentUser {
    currentUser @client {
      id
      username
      email
      cognitoUserId
      isAuthenticated
      avatar
      features
      notifications
      defaultRole
      role
      isAdmin
      isDemo
      isPreviewOrg
      permission
      ssoToken
      campaignTableDisplayColumns
    }
  }
`;

export default CURRENT_USER;
