import React from "react";
import { Row, Col } from "antd";
import { TableColumnTitle } from "../../../../../../shared/globalStyling/styledText";

const styles = {
  columnHeader: {
    height: "56px",
    borderBottom: "1px solid #e8e8e8",
    paddingLeft: "10px",
  },
  leftColumn: {
    border: "0.8px solid #e8e8e8",
  },
  rightColumn: {
    paddingTop: "1px",
    border: "0.8px solid #e8e8e8",
    borderLeft: "none",
  },
  metricBlock: {
    height: "50%",
  },
  metricBlockTitle: {
    marginTop: "15%",
    paddingLeft: "10px",
    fontSize: 12,
    fontWeight: "bold",
    color: "#808080",
  },
  metricBlockValue: {
    fontSize: 24,
    color: "black",
    paddingLeft: "10px",
  },
};

const PublisherExpandedCard = ({ record }) => {
  if (!record) return null;

  const { extra } = record;

  if (!extra) {
    return (
      <Row>
        <Col>Please refresh your browser to receive the latest updates.</Col>
      </Row>
    );
  }

  const { title, deviceType } = extra;

  return (
    <div>
      <Row type="flex">
        <Col span={20} style={styles.columnHeader}>
          <Row type="flex" align="middle">
            <TableColumnTitle text={`${title}`} />
          </Row>
        </Col>
        <Col span={4} style={styles.columnHeader}>
          {deviceType ? (
            <Row style={{ padding: 2 }} type="flex" align="middle">
              <TableColumnTitle text={` ${deviceType}`} />
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export { PublisherExpandedCard };
