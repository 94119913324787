import React, { useState, useEffect } from "react";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import {
  collectSegmentsFromProviders,
  isUnixTimestamp,
} from "../../../../utils/campaigns";

const DisplayTabChartsController = ({
  children,
  url,
  expiresAt,
  refetch,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  chartData,
  setChartData,
  providersTab,
  providerIndex,
  campaignOrder,
  hasInsights,
  userPermission,
  setPastReportId,
  setUsePastReportId,
  displayReportsConnection,
  currentReportId,
  setPastReportIdx,
  pastReportIdx,
  usePastReportId,
  technicalSupport,
  ...props
}) => {
  const [rawData, setRawData] = useState(null);
  const [loadingRawData, setLoadingRawData] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [selectedSegmentsDimension, setSegmentsDimension] = useState("ais");
  const [permission, setPermission] = useState(userPermission);

  const chartDataProvider = chartData && chartData.byProvider;
  const getLiquidMVideoLogs =
    chartData &&
    chartData.byProvider &&
    chartData.byProvider.liquid &&
    chartData.byProvider.liquidm.videoLogs;

  // VideoLogs
  let byProvider = chartDataProvider ? getLiquidMVideoLogs : [];

  const handleSelectPastReport = idx => {
    setPastReportIdx(idx);
  };

  const handleAfterChangePastReport = () => {
    setPastReportId(
      displayReportsConnection.campaignReportsConnection.edges[
        pastReportIdx - 1
      ].node.id
    );
    setUsePastReportId(true);
  };

  const handleUseDefaultReportId = () => {
    setUsePastReportId(false);
    setPastReportIdx(1);
    refetch(currentReportId);
  };

  //Time check happens first

  useEffect(() => {
    const currentTime = new Date().toUTCString();
    if (currentTime <= expiresAt) {
      //url is valid - set url
      setS3Url(url);
      setIsValidUrl(true);
    } else {
      //url is valid - refetch
      setIsValidUrl(false);
      refetch();
      setS3Url(null);
    }
  }, [expiresAt, s3Url, setS3Url, refetch, url]);

  useEffect(() => {
    if (!isNil(userPermission)) {
      setPermission(userPermission);
    }
  }, [userPermission]);

  //If check happens - we have a url (from reload or first load)
  useEffect(() => {
    if (s3Url && loadingRawData)
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setRawData(json);
              setLoadingRawData(false);
            });
          } catch (err) {
            setLoadingRawData(false);
          }
        }
      });
  }, [s3Url, loadingRawData]);

  useEffect(() => {
    if (rawData && !chartData.byDate && campaignOrder.version >= 1.3) {
      let all, data, displayReports;
      if (rawData.displayReports) {
        const {
          byProvider,
          byDate,
          byDay,
          creatives,
          deviceType,
          publishers,
          chartsBuilt,
        } = rawData;
        displayReports = rawData.displayReports;
        const providerAll = !isEmpty(byProvider)
          ? Object.entries(byProvider)[0][1]
          : null;
        all = {
          byDate,
          byDay,
          creatives,
          deviceType,
          publishers,
          chartsBuilt,
          lineItems: providerAll && providerAll.lineItems,
          videoLogs: providerAll && providerAll.videoLogs,
          segments: providerAll && providerAll.segments,
        };
        data =
          providerIndex === "All"
            ? all
            : displayReports && displayReports[parseInt(providerIndex)][1];
      } else {
        displayReports = rawData;
        all = rawData[0][1];
        data =
          providerIndex === "All"
            ? rawData[0][1]
            : providersTab && providersTab[parseInt(providerIndex)][1];
      }
      setChartData({
        byDate: data.byDate || [],
        byDay: data.byDay || [],
        publishers: data.publishers || [],
        creatives: data.creatives || [],
        deviceType: data.deviceType || [],
        displayReports,
        chartsBuilt: data.chartsBuilt,
        videoLogs: data.videoLogs || [],
        segments: data.segments || [],
        byProvider,
        ...chartData,
      });
    } else if (campaignOrder.version < 1.3 && chartData && chartData.byDate) {
      // setRawData(chartData);
      setLoadingRawData(false);
    }
  }, [
    rawData,
    setChartData,
    chartData,
    campaignOrder.version,
    providerIndex,
    byProvider,
    providersTab,
  ]);

  useEffect(() => {
    if (rawData) {
      let all, data, displayReports;
      if (rawData.displayReports) {
        const {
          byProvider,
          byDate,
          byDay,
          creatives,
          deviceType,
          publishers,
          chartsBuilt,
        } = rawData;
        displayReports = rawData.displayReports;
        const providerAll = !isEmpty(byProvider)
          ? Object.entries(byProvider)[0][1]
          : null;
        all = {
          byDate,
          byDay,
          creatives,
          deviceType,
          publishers,
          chartsBuilt,
          lineItems: providerAll && providerAll.lineItems,
          videoLogs: providerAll && providerAll.videoLogs,
          segments: collectSegmentsFromProviders(displayReports),
        };
        data =
          providerIndex === "All"
            ? all
            : displayReports && displayReports[parseInt(providerIndex)][1];
      } else {
        all = rawData[0][1];
        displayReports = rawData;
        data =
          providerIndex === "All"
            ? rawData[0][1]
            : providersTab && providersTab[parseInt(providerIndex)][1];
      }
      setDisplayData({
        byDate: data.byDate || [],
        byDay: data.byDay || [],
        publishers: data.publishers || [],
        creatives: data.creatives || [],
        deviceType: data.deviceType || [],
        videoLogs: all.videoLogs || [],
        segments: data.segments || [],
        chartsBuilt: data.chartsBuilt,
        displayReports,
        byProvider,
      });
      setLoadingRawData(false);
    }
  }, [byProvider, providerIndex, providersTab, rawData]);

  useEffect(() => {
    if (displayData && displayData.byDate && displayData.byDate.length) {
      const startByDate = displayData.byDate[0].xAxis;
      const endByDate = displayData.byDate[displayData.byDate.length - 1].xAxis;

      const start = !isEmpty(displayData.byDate)
        ? isUnixTimestamp(startByDate)
          ? moment.unix(startByDate)
          : moment(startByDate)
        : null;
      const end = !isEmpty(displayData.byDate)
        ? isUnixTimestamp(endByDate)
          ? moment.unix(endByDate)
          : moment(endByDate)
        : null;

      // set the date range on the selected date in calendar
      setByCampaignRangeDate([start, end]);
      // set the data into filtered ones with the selected range dates
      setDataCampaignRangeDate(displayData.byDate);
    }
  }, [displayData, setByCampaignRangeDate, setDataCampaignRangeDate]);

  if (isValidUrl === true || (campaignOrder.version < 1.3 && chartData)) {
    return React.cloneElement(children, {
      chartData: campaignOrder.version < 1.3 ? chartData : displayData,
      chartsLoading: loadingRawData,
      isValidUrl,
      byCampaignRangeDate,
      setByCampaignRangeDate,
      dataCampaignRangeDate,
      setDataCampaignRangeDate,
      providerIndex: props.providerIndex,
      hasInsights,
      selectedSegmentsDimension,
      userPermission: permission,
      setSegmentsDimension,
      displayReportsConnection,
      handleSelectPastReport,
      handleAfterChangePastReport,
      currentReportId,
      pastReportIdx,
      usePastReportId,
      technicalSupport,
      handleUseDefaultReportId,
    });
  } else {
    return React.cloneElement(children, {
      chartData: null,
      isValidUrl,
      byCampaignRangeDate,
      setByCampaignRangeDate,
      dataCampaignRangeDate,
      setDataCampaignRangeDate,
      providersIndex: props.providersIndex,
      selectedSegmentsDimension,
      userPermission: permission,
      setSegmentsDimension,
      displayReportsConnection,
      handleAfterChangePastReport,
      handleSelectPastReport,
      currentReportId,
    });
  }
};

export default DisplayTabChartsController;
