import React from "react";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import Controller from "./CampaignDetailsPublisherController";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";

const CampaignDetailsPublisherLoader = ({ children, ...props }) => {
  return <Controller {...props}>{children}</Controller>;
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(CampaignDetailsPublisherLoader);
