import gql from "graphql-tag";

const ADVERTISER = gql`
  query advertiser($id: ID!) {
    advertiser(where: { id: $id }) {
      id
      name
      startDate
      endDate
      budget
      cpm
      transactions
      customers
      revenue
      spend
      roas
      totalImpressions
      reports(
        where: { type: ADVERTISER_TRANSACTIONS }
        orderBy: createdAt_DESC
        first: 1
      ) {
        id
        key
        type
      }
      impressionsTag {
        id
        dsp
      }
    }
  }
`;

export default ADVERTISER;
