import React from "react";

import { useLazyQuery } from "react-apollo";
import { Col, Row, message, notification } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";

import TRIGGER_AIRFLOW_DAG from "../../../../../core/GraphQl/Queries/TRIGGER_AIRFLOW_DAG";

import Button from "../../../../shared/globalStyling/Button";
import { ButtonText } from "../../../../shared/globalStyling/styledText";

function TriggerAirflowDAG({ audienceId, disabled }) {
  const [
    triggerAirflowDAG,
    { loading: triggerAirflowDAGLoading },
  ] = useLazyQuery(TRIGGER_AIRFLOW_DAG, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data) {
        message.destroy();
        notification.open({
          message: "Reprocessing Device Matches...",
          description:
            "Please wait for a while to Reprocess Device Matches. It may take some time. Try to click the Refresh Metrics and Check if the Reprocessing is done",
          icon: <FileSyncOutlined />,
          duration: 20,
        });
      }
    },
    onError: error => {
      message.destroy();
      message.error("Error reprocessing");
    },
  });

  const trigger = dag_id => {
    message.loading("Reprocessing...", 0);
    try {
      triggerAirflowDAG({
        variables: {
          where: {
            dag_id,
            body: {
              conf: {
                args: `audienceid=${audienceId}`,
              },
            },
          },
        },
      });
    } catch (err) {
      message.destroy();
      message.error("Error reprocessing");
      return false;
    }
  };

  return (
    <Row gutter={16}>
      <Col className="ant-col-xs-100 ant-col-md-flex-auto">
        <Button
          type="primary"
          loading={triggerAirflowDAGLoading}
          onClick={() => trigger("retargeting-snowplow-devices")}
          style={{ width: "100%" }}
          ghost
          disabled={disabled}
        >
          <ButtonText text="Reprocess Device Matches" />
        </Button>
      </Col>
      <Col className="ant-col-xs-100 ant-col-md-flex-auto">
        <Button
          type="primary"
          loading={triggerAirflowDAGLoading}
          onClick={() => trigger("retargeting-audience-extension")}
          ghost
          style={{ width: "100%" }}
          disabled={disabled}
        >
          <ButtonText text="Reprocess Extended Device Matches" />
        </Button>
      </Col>
    </Row>
  );
}

export { TriggerAirflowDAG };
