export const columnsObj = {
  name: {
    title: "Name",
    width: 360,
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: true,
  },
  startDate: {
    title: "Start Date",
    dataIndex: "startDate",
    key: "startDate",
    width: 240,
    sorter: true,
  },
  endDate: {
    title: "End Date",
    dataIndex: "endDate",
    key: "endDate",
    width: 240,
    sorter: true,
  },
  budget: {
    title: "Budget",
    dataIndex: "budget",
    key: "budget",
    width: 240,
    sorter: true,
  },
  cpm: {
    title: "CPM",
    dataIndex: "cpm",
    key: "cpm",
    width: 240,
    sorter: true,
  },
  impressionsGoal: {
    title: "Impressions Goal",
    dataIndex: "impressionsGoal",
    key: "impressionsGoal",
    width: 240,
  },
};
