import React from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { Avatar, Spin } from "antd";

class AvatarS3 extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ready: false,
      url: "",
    };
  }

  componentDidMount = () => {
    const { imgKey } = this.props;
    if (imgKey)
      Storage.get(imgKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    else
      this.setState({
        ready: true,
      });
  };

  componentDidUpdate(prevProps) {
    // If props are updated, such as from new data getting added, will recalculate
    if (this.props.imgKey !== prevProps.imgKey) {
      this.setState({
        ready: false,
      });
      Storage.get(this.props.imgKey)
        .then(result =>
          this.setState({
            ready: true,
            url: result,
          })
        )
        .catch(err => console.log(err));
    }
  }

  render() {
    if (!this.state.ready) {
      return <Spin size="small" />;
    }

    return <Avatar {...this.props.avatarProps} src={this.state.url} />;
  }
}

export default AvatarS3;

AvatarS3.propTypes = {
  imgKey: PropTypes.string,
  avatarProps: PropTypes.object,
};
