import React from "react";
import { Row, Col } from "antd";
import { isEmpty, upperFirst } from "lodash";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { PageHeaderLayout } from "../../../../shared/layout";
import OverlaySpinner from "../../../../shared/OverlaySpinner";

const dayOrder = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
};

const LineChart = ({ xAxis, yAxis, key, data }) => {
  let chartData = Object.values(data);
  if (xAxis === "DAY")
    chartData = chartData.sort((a, b) => {
      if (dayOrder[a.DAY] > dayOrder[b.DAY]) return 1;
      else return -1;
    });
  return (
    <Chart height={400} data={chartData} forceFit>
      <Axis name={xAxis} />
      <Axis name={yAxis} />
      <Tooltip />
      <Geom type="interval" position={`${xAxis}*${yAxis}`} />
    </Chart>
  );
};

const Observations = ({ data, loading }) => {
  return (
    (!isEmpty(data) || loading) && (
      <Row>
        <PageHeaderLayout title="Observations" />
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #e8e8e8",
            padding: "20px",
          }}
        >
          {loading ? (
            <Col>
              <OverlaySpinner />
            </Col>
          ) : (
            <React.Fragment>
              {Object.entries(data).map(([key, value]) => {
                return (
                  <Col span={7} key={key} style={{ minWidth: "400px" }}>
                    <Row
                      style={{
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      {key
                        .split("_")
                        .map(str => upperFirst(str))
                        .join(" ")}
                    </Row>
                    <LineChart
                      height={250}
                      data={value}
                      xAxis={Object.keys(value[0])[0]}
                      yAxis={Object.keys(value[0])[1]}
                    />
                  </Col>
                );
              })}
            </React.Fragment>
          )}
        </Row>
      </Row>
    )
  );
};

export { Observations };
