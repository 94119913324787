import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Card, Col, Button } from "antd";
import { ArrowLeftOutlined, LikeTwoTone } from "@ant-design/icons";

const CampaignLauncherViewSuccess = props => {
  const styles = {
    div: {
      height: "50vh",
    },
    col: {
      minWidth: "300px",
    },
    button: {
      width: "200px",
      fontSize: "14px",
      margin: "10px",
    },
    rowOne: {
      marginBottom: 5,
    },
    rowTwo: {
      margin: "20px 0 20px 0",
    },
    rowThree: {
      textAlign: "center",
      margin: "20px 0 20px 0",
    },
    rowFour: {
      textAlign: "center",
    },
    rowFive: {
      margin: "30px 0 20px 0",
    },
    icon: {
      fontSize: "45px",
    },
    span: {
      fontSize: "20px",
      textAlign: "center",
    },
  };

  return (
    <div style={styles.div}>
      <Row justify="center" align="middle" type="flex">
        <Col span={10} style={styles.col}>
          <Card>
            <Row gutter={16} justify="center" type="flex" style={styles.rowOne}>
              <LikeTwoTone
                style={styles.icon}
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </Row>
            <Row gutter={24} justify="center" type="flex" style={styles.rowTwo}>
              <span style={styles.span}>
                Your Campaign has been submitted for audit.
              </span>
            </Row>
            <Row gutter={24} justify="center" type="flex">
              <Row style={styles.rowThree}>
                Upon verification of compliance and optimization, your Campaign
                will go live.
              </Row>
            </Row>
            <Row justify="center" type="flex" style={styles.rowFive}>
              <Button type="primary" shape="round" style={styles.button}>
                <NavLink to="/campaigns">
                  <ArrowLeftOutlined /> Back to Campaigns
                </NavLink>
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CampaignLauncherViewSuccess;
