import React, { useEffect, useState } from "react";
import { Card, Row, Select, Empty, Tabs, Col } from "antd";
import { isEmpty, startCase, isNil, remove } from "lodash";
import DualAxisAreaChart from "./components/DualAxisAreaChart";
import DatePickerField from "./components/DatePickerField";

// Global Styles
import {
  MenuItemTextColor,
  TabText,
} from "../../../../../shared/globalStyling/styledText";
import { useByDateFilter } from "../../../paidsearchdetails/components/charts/hooks/ByDateFilter";

const Frag = React.Fragment;
//const { RangePicker } = DatePicker;
const { Option } = Select;

const TabPane = Tabs.TabPane;

// Styles
const colStyle = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};
const SummaryChart = ({
  byDates,
  byDay,
  bySummaryRangeDate,
  setBySummaryRangeDate,
  dataSummaryRangeDate,
  setDataSummaryRangeDate,
  reportData,
  showTransactions,
  showLocations,
  showKeywords,
  signUpsData,
  setDataSignUpRangeDate,
  showPrices,
  currencyFormat,
}) => {
  // map the chart and return the dates with total summary
  let validOptions = ["clicks", "impressions", "ctr", "spend", "cpc", "cpm"];

  const transactionOptions = [
    "conversions",
    "revenue",
    "roas",
    "conversionRate",
  ];
  const keywordsOptions = ["keywords"];
  const locationsOptions = ["locations"];

  const showPricesExclude = ["roas", "cpc", "spend"];

  const {
    rangeKey,
    setRangeKey,
    filteredData,
    startDateEndDate,
    setCustomDateRange,
  } = useByDateFilter(byDates);

  useEffect(() => {
    setDataSummaryRangeDate(filteredData);
  }, [filteredData, setDataSummaryRangeDate]);

  if (showTransactions) {
    validOptions = validOptions.concat(transactionOptions);
  }

  if (showLocations) {
    validOptions = validOptions.concat(locationsOptions);
  }

  if (showKeywords) {
    validOptions = validOptions.concat(keywordsOptions);
  }

  if (!showPrices) {
    remove(validOptions, c => {
      return showPricesExclude.includes(c);
    });
  }

  if (!isNil(reportData)) {
    const {
      clicks,
      impressions,
      ctr,
      spend,
      cpc,
      cpm,
      conversions,
      revenue,
      roas,
      conversionRate,
    } = reportData;

    const toRemove = [];
    clicks === 0 && toRemove.push("clicks");
    impressions === 0 && toRemove.push("impressions");
    ctr === 0 && toRemove.push("ctr");
    spend === 0 && toRemove.push("spend");
    cpc === 0 && toRemove.push("cpc");
    cpm === 0 && toRemove.push("cpm");
    conversions === 0 && toRemove.push("conversions");
    revenue === 0 && toRemove.push("revenue");
    roas === 0 && toRemove.push("roas");
    conversionRate === 0 && toRemove.push("roas");

    remove(validOptions, c => {
      return toRemove.includes(c);
    });
  }

  const [leftY, setLeftY] = useState(startCase(validOptions[0]));
  const [rightY, setRightY] = useState(startCase(validOptions[1]));
  // // Sort Dates
  // byDates = byDates.sort(
  //   (a, b) => moment(a.xAxis).unix() - moment(b.xAxis).unix()
  // );

  const DateSelector = ({ data }) => {
    // map xAxis dates

    return (
      <DatePickerField
        setCustomDateRange={setCustomDateRange}
        data={filteredData}
        defaultPickerValue={startDateEndDate}
        defaultValue={startDateEndDate}
        value={
          isEmpty(bySummaryRangeDate) ? startDateEndDate : bySummaryRangeDate
        }
        onChange={v => {
          setBySummaryRangeDate(v.dates);
        }}
        dropDownValue={rangeKey}
        setDropDownValue={setRangeKey}
        has6MonDisables={false}
      />
    );
  };

  const YAxisSelector = ({
    displayDateSelector,
    displayRightSelector,
    data,
  }) => {
    const keys = Object.keys(data[0])
      .filter(el => validOptions.includes(el))
      .map(option => {
        return option === "cpc" ||
          option === "cpm" ||
          option === "ctr" ||
          option === "ecpm"
          ? option.toUpperCase()
          : startCase(option);
      })
      .filter(el => el !== "Creativeurl");
    return (
      <Row gutter={[8, 8]} justify={"space-between"}>
        <Col xs={24} md={5} lg={4} xl={3}>
          <Select
            onSelect={value => setLeftY(value)}
            style={{
              width: "100%",
            }}
            defaultValue={leftY}
          >
            {keys
              .filter(op => op !== rightY)
              .map(option => {
                return (
                  <Option key={option} value={option}>
                    <MenuItemTextColor text={option} color={"#7dafff"} />
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col xs={24} md={14} lg={10} xl={8}>
          {displayDateSelector ? <DateSelector data={byDates} /> : null}
        </Col>
        <Col xs={24} md={5} lg={4} xl={3}>
          {displayRightSelector ? (
            <Select
              onSelect={value => {
                setRightY(value);
              }}
              style={{ width: "100%" }}
              defaultValue={rightY}
            >
              {keys
                .filter(op => op !== leftY)
                .map(option => {
                  return (
                    <Option key={option} value={option}>
                      <MenuItemTextColor text={option} color={"#92d5a7"} />
                    </Option>
                  );
                })}
            </Select>
          ) : null}
        </Col>
      </Row>
    );
  };

  // if chartdata is equal to empty
  const emptyCharts = isEmpty(byDates);
  if (emptyCharts)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <DateSelector data={filteredData} />
        </Row>
        {filteredData && filteredData.length <= 0 && (
          <>
            <Row type="flex" justify="center" align="middle">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          </>
        )}
      </Card>
    );

  return (
    <div>
      <Card style={colStyle.container} bodyStyle={{ padding: 18 }}>
        <Tabs
          // tabBarExtraContent={operations}
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
          onChange={activeKey => {
            if (activeKey === "4") setLeftY("Clicks");
            if (activeKey !== "4") {
              setLeftY("Impressions");
              setRightY("Transaction Count");
            }
          }}
        >
          {!isEmpty(byDates) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                <Frag>
                  <YAxisSelector
                    displayRightSelector={true}
                    displayDateSelector={true}
                    data={byDates}
                  />
                  <Row
                    style={{ marginBottom: "2%" }}
                    type="flex"
                    justify="center"
                  ></Row>
                  {filteredData.length !== 0 && validOptions.length > 1 && (
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? validOptions[0] || "" : leftY}
                      rightY={isEmpty(rightY) ? validOptions[1] || "" : rightY}
                      dualChartData={filteredData}
                      currencyFormat={currencyFormat}
                    />
                  )}
                </Frag>

                {(filteredData.length === 0 ||
                  (bySummaryRangeDate &&
                    bySummaryRangeDate.length &&
                    filteredData.length === 0)) && <Empty />}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDay) ? (
            <TabPane tab={<TabText text={"Day Of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDay.length === 0 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDay.length !== 0 ? (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={false}
                      data={byDay}
                    />
                    <Row
                      style={{ marginBottom: "2%" }}
                      type="flex"
                      justify="center"
                    ></Row>
                    <DualAxisAreaChart
                      leftY={isEmpty(leftY) ? "Impressions" : leftY}
                      rightY={isEmpty(rightY) ? "Clicks" : rightY}
                      dualChartData={byDay}
                    />
                  </Frag>
                ) : null}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
    </div>
  );
};

export default SummaryChart;
