import { Popover, Steps } from "antd";
import React, { useEffect, useState } from "react";

const { Step } = Steps;

const customDot = (dot, { index, title }) => (
  <Popover
    content={
      <span>
        Step {index} Status: {title}
      </span>
    }
  >
    {dot}
  </Popover>
);

export const CampaignStatusSteps = ({
  status,
  selectedStateLaw,
  selectedScaleBudget,
}) => {
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    switch (status) {
      case "APPROVED":
        setCurrentStep(1);
        break;
      case "LIVE":
        setCurrentStep(2);
        break;
      default:
        setCurrentStep(0);
    }
  }, [status]);

  const getStepLaunchTitle = () => {
    if (!selectedStateLaw && !selectedScaleBudget) return "Launch Approved";
    if (selectedStateLaw === "approved" && selectedScaleBudget === "approved")
      return "Launch Approved";
    if (selectedStateLaw === "rejected" || selectedScaleBudget === "rejected")
      return "Campaign Rejected";
    return "Campaign Approved but with modifications";
  };

  const getStepLaunchDescription = () => {
    if (
      (!selectedStateLaw && !selectedScaleBudget) ||
      (selectedStateLaw === "approved" && selectedScaleBudget === "approved")
    )
      return "Your campaign is now cleared for takeoff.";
    return "Please review creatives and locations.";
  };

  return (
    <>
      <Steps
        current={currentStep}
        progressDot={customDot}
        style={{ marginBottom: "15px" }}
      >
        <Step
          title="Campaign in Progress"
          description="Your submission is undergoing review as we evaluate the campaign."
        />

        <Step
          title={getStepLaunchTitle()}
          description={getStepLaunchDescription()}
        />
        <Step
          title="Campaign Live"
          description="Your campaign is now in full flight."
        />
      </Steps>
    </>
  );
};
