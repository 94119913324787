import gql from "graphql-tag";

const REFRESH_AUDIENCE_OBSERVATIONS = gql`
  mutation refreshAudienceObservations($audienceId: ID!) {
    refreshAudienceObservations(audienceId: $audienceId) {
      id
      name
      description
      geoTargets {
        #   (where: $where, orderBy: $orderBy, first: $first, skip: $skip)
        id
        name
        description
        start
        end
        observations
        orgs {
          id
        }
        vwiUpdated
        vwiUpdatedOnSpot
        tags
        circles {
          id
          radius
          location {
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      orgs {
        id
      }
      tags
      updatedAt
    }
  }
`;

export default REFRESH_AUDIENCE_OBSERVATIONS;
