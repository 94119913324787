const pagination = {
  defaultCurrent: 1,
  pageSize: 10,
  showSizeChanger: false, // This disables the page size options
  defaultPageSize: 10,
  total: 0,
};

export const TableConfig = {
  pagination,
};
