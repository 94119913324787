import { isEmpty } from "lodash";
import { formatNumber } from "../../../../../core/utils/campaigns";

export const summaryScale = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["impressions"] : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "pageViews"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pageViews"] : 0,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "uniqueUsers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["uniqueUsers"] : 0,
            alias: "Unique Users",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["impressions"] : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "pageViews"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pageViews"] : 0,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "uniqueUsers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["uniqueUsers"] : 0,
            alias: "Unique Users",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};
