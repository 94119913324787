// Libraries
import React from "react";
import { Row, Col } from "antd";
import { isEmpty, get } from "lodash";

// Components
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import DetailBar from "./components/DetailBar";
import SummaryChart from "./components/charts/SummaryChart";
import ExportCSVButton from "./components/ExportCSVButton";
import StatusTag from "../components/StatusTag";
import ExportExcelButton from "./components/ExportExcelButton";
import { orgLevelCustomization } from "../../../../core/permissions/orgLevelCustomization";

const PaidSearchCampaignView = ({
  campaign,
  setBySummaryRangeDate,
  generateReport,
  orgConfig,
  bySummaryRangeDate,
  currentUser,
}) => {
  const orgCustomization = orgLevelCustomization(
    currentUser.role.org.dataConfig || {}
  );
  const { currencyFormat } = orgCustomization;
  // Set Document Title Dynamically
  document.title = `${campaign.name} - Campaigns`;
  const { data } = campaign;
  const { priceVisible, exportData } = orgConfig;
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={campaign.name}
        tag={<StatusTag status={campaign.status} />}
      />
      <PageContentLayout>
        <Row gutter={[16, 16]} justify={"end"}>
          {exportData && (
            <React.Fragment>
              <Col xs={24} md={7} lg={4}>
                <ExportCSVButton
                  campaignName={campaign.name}
                  generateReport={generateReport}
                />
              </Col>
              <Col xs={24} md={7} lg={4}>
                <ExportExcelButton
                  campaignName={campaign.name}
                  generateReport={generateReport}
                />
              </Col>
            </React.Fragment>
          )}
          <Col xs={24}>
            <DetailBar
              impressions={data.impressions ? data.impressions : 0}
              clicks={data.clicks ? data.clicks : 0}
              phoneCalls={data.phoneCalls ? data.phoneCalls : 0}
              searches={data.keywords ? data.keywords : 0}
              locations={data.locations ? data.locations : 0}
              ctr={data.ctr ? data.ctr : 0}
              cpc={data.cpc ? data.cpc : 0}
              roas={data.roas ? data.roas : 0}
              revenue={data.revenue ? data.revenue : 0}
              spend={data.spend ? data.spend : 0}
              showPrices={priceVisible}
              transactions={data.conversions ? data.conversions : 0}
              conversionRate={data.conversionRate ? data.conversionRate : 0}
              currencyFormat={currencyFormat}
            />
          </Col>
          <Col xs={24}>
            <SummaryChart
              byDates={isEmpty(data.byDate) ? [] : data.byDate}
              reportData={data}
              dataSummaryRangeDate={data.byDate}
              setDataSummaryRangeDate={() => {}}
              setBySummaryRangeDate={setBySummaryRangeDate}
              showTransactions={get(data, "conversions", 0) > 0}
              showKeywords={get(data, "keywords", 0) > 0}
              showLocations={get(data, "locations", 0) > 0}
              showPrices={priceVisible}
              bySummaryRangeDate={bySummaryRangeDate}
              currencyFormat={currencyFormat}
            />
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default PaidSearchCampaignView;
