import QuickDocs from "../../../../QuickDocs/QuickDocs";
import React from "react";
import { Typography } from "antd";

const TagIntegrationsDoc = ({ open, onCancel }) => {
  return (
    <QuickDocs
      open={open}
      onCancel={onCancel}
      title="Tag installation instructions"
    >
      To measure the success of your Display campaigns, you need to install our
      generated tags in the Header section of your website.{" "}
      <b>Without these tags, we can't measure important metrics.</b> Test the
      tags periodically by clicking "Test Tag". Don't remove the tags once
      installed. And avoid making major website changes during an active
      campaign, as it may disrupt the tags' tracking abilities.
      <br />
      <br />
      <b>How to install tags: </b>
      <ul>
        <li>
          <Typography.Link
            href="https://docs.google.com/document/d/1RHDxFnpwVRaYn--XQOlG-RkKACbs0pPB/edit?usp=sharing&ouid=115685225735130004052&rtpof=true&sd=true"
            target={"_blank"}
          >
            via Google Tag manager
          </Typography.Link>
        </li>
        <li>
          <Typography.Link
            href="https://docs.google.com/document/d/1RVFbm4WHbgXzCl5I198wKlPtPc_Uo7L7/edit?usp=sharing&ouid=115685225735130004052&rtpof=true&sd=true"
            target={"_blank"}
          >
            via Shopify
          </Typography.Link>
        </li>
        <li>
          <Typography.Link
            href="https://docs.google.com/document/d/1Nkqty9_iooPdeB87IewmrpG0cpAKDPc2/edit?usp=sharing&ouid=115685225735130004052&rtpof=true&sd=true"
            target={"_blank"}
          >
            via Wordpress/Woocommerce
          </Typography.Link>
        </li>
        <li>
          <Typography.Link
            href="https://docs.google.com/document/d/1CbWX_eD201P-ZwK8xAMg8TwTqA_xsJC-/edit?usp=sharing&ouid=115685225735130004052&rtpof=true&sd=true"
            target={"_blank"}
          >
            via Wix
          </Typography.Link>
        </li>
      </ul>
      <br />
      For more custom integrations where your website's menu is not under the
      cart platforms listed, please check our custom integrations documentation
      here. It is recommended that you have a developer on your team to help you
      out. This documentation will give you code snippets that you can install
      on your website to track KPI's such as transactions for your custom menu
      or signups.
      <br />
      <br />
      <ul>
        <li>
          <Typography.Link
            href="https://docs.google.com/document/d/1VAAjSJw618fq1BY7t7hJTcncItIDi2TF/edit?usp=sharing&ouid=115685225735130004052&rtpof=true&sd=true"
            target={"_blank"}
          >
            Custom integrations instructions
          </Typography.Link>
        </li>
      </ul>
    </QuickDocs>
  );
};

export default TagIntegrationsDoc;
