import gql from "graphql-tag";

const PAID_SEARCH_UPDATE_CONFIG = gql`
  mutation($id: ID!, $data: PaidSearchSummaryUpdateInput!) {
    updatePaidSearchSummary(where: { id: $id }, data: $data) {
      id
      config {
        processWithGoogleAds
        monthlyBudget
        hasLocationRevenue
        locationsKey
      }
    }
  }
`;

export default PAID_SEARCH_UPDATE_CONFIG;
