import gql from "graphql-tag";
const GET_CAMPAIGN_REPORT = gql`
  query getCampaignReportSignedURL($reportId: ID!) {
    getCampaignReportSignedURL(reportId: $reportId) {
      url
      expiresAt
      error
    }
  }
`;

export default GET_CAMPAIGN_REPORT;
