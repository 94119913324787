import React from "react";
import { useQuery } from "@apollo/react-hooks";
import LIST_ORGS_ADMIN from "../GraphQl/Queries/LIST_ORGS_ADMIN";
import PropTypes from "prop-types";

export const SubOrgsLoader = ({ children, orgId, ...props }) => {
  const { loading: subOrgsLoading, data: subOrgsData } = useQuery(
    LIST_ORGS_ADMIN,
    {
      variables: { parentid: orgId, first: 1000 },
      skip: !orgId,
    }
  );

  return React.cloneElement(children, {
    subOrgsLoading,
    subOrgsData: subOrgsData && subOrgsData.orgs,
    orgId,
    ...props,
  });
};

SubOrgsLoader.propTypes = {
  children: PropTypes.element.isRequired,
  orgId: PropTypes.string.isRequired,
};
