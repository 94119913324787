import React from "react";
import { Col, Row } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import Button from "../../shared/globalStyling/Button";
import { AudienceTable, AudienceFilterBar } from "./components";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { determineUserAccess } from "../../../core/permissions/userLevelAccess";
import AudienceShare from "./components/AudienceShare";

const AudienceLayout = props => {
  const { history, currentUser, toggleSharing, selectedRows, orgs } = props;
  // user permission
  const getPermission = currentUser && currentUser.permission;
  const orgConfig = determineUserAccess(getPermission);
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Audiences"}
        titleIcon={
          <UsergroupAddOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Row gutter={16}>
            <Col className="ant-col-xs-100 ant-col-md-flex-auto">
              <Button
                type="primary"
                ghost
                disabled={props.selectedRowKeys.length < 1 || orgConfig.isDemo}
                style={{ width: "100%" }}
                onClick={() => props.onChange("toggleSharing", true)}
              >
                Share Audience
              </Button>
            </Col>
            {orgConfig.isTrafficker && (
              <Col className="ant-col-xs-100 ant-col-md-flex-auto">
                <Button
                  type="primary"
                  ghost
                  style={{ width: "100%" }}
                  onClick={() => {
                    history.push(
                      `/audiences/create-audience/new/1/updatedAt_DESC/%7B%7D?type=ATTRIBUTION`
                    );
                  }}
                >
                  Create Audience
                </Button>
              </Col>
            )}
          </Row>
        }
      />
      <PageContentLayout>
        <AudienceFilterBar />
        <AudienceTable {...props} />
        <AudienceShare
          selectedRows={selectedRows}
          modalToggle={toggleSharing}
          onCancel={props.onCancelSharing}
          onSubmit={props.onSubmitSharing}
          orgs={orgs}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default AudienceLayout;
