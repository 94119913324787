import React from "react";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import PublisherDetails from "./components/PublisherDetails";
import CampaignDetailsPublishersLoader from "../../../../../../core/components/campaigns/campaigndetails/publishers/CampaignDetailsPublisherLoader";

const Publishers = ({
  colStyle,
  selectedCampaign,
  chartsLoading,
  publisherData,
}) => {
  if (chartsLoading)
    return (
      <Card>
        <Row type="flex" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Card>
    );
  if (isEmpty(publisherData)) return <div />;

  return (
    <CampaignDetailsPublishersLoader publisherDataReport={publisherData}>
      <PublisherDetails
        colStyle={colStyle}
        selectedCampaign={selectedCampaign}
      />
    </CampaignDetailsPublishersLoader>
  );
};

export default Publishers;
