import gql from "graphql-tag";

const GET_ADGROUP_CREATIVE = gql`
  query adgroupmedia($adgroupId: ID!) {
    adGroupCreatives(where: { id: $adgroupId }) {
      id
      adProviderId
      name
      medias {
        id
        name
        size
        fileType
        height
        width
        key
        url
        type
        org {
          id
          name
        }
        sharedWith {
          name
        }
        mediaNotes
        mediaHasNotes
      }
      flag
      org {
        id
        name
      }
    }
  }
`;

export default GET_ADGROUP_CREATIVE;
