import gql from "graphql-tag";
const PAID_SEARCH_COMMERCE_TRANSACTION = gql`
  query PaidSearchCommerceTransactionsesConnection(
    $first: Int
    $skip: Int
    $where: PaidSearchCommerceTransactionsWhereInput
  ) {
    transactions: paidSearchCommerceTransactionsesConnection(
      first: $first
      skip: $skip
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          key
          orderId
          lastClick
          totalClick
          orderedOn
          total
          attributionMethod
          attrbutionValue
          clicks {
            clickTime
            campaign
            pageUrl
          }
        }
      }
      aggregate {
        count
      }
    }
    full: paidSearchCommerceTransactionsesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default PAID_SEARCH_COMMERCE_TRANSACTION;
