import React from "react";
import { Button, Badge, message } from "antd";
import {
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
  EnvironmentOutlined,
  LayoutOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import { errorType } from "../../../../core/utils/constants/constants";

const style = {
  wrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "start",
    cursor: "pointer",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "10px",
  },
  stepText: {
    color: "#c2c2c2",
    margin: 0,
  },
  stepTextComplete: {
    color: "#2acb70",
    margin: 0,
  },
  text: {
    color: "#929292",
    margin: 0,
  },
  textChecked: {
    margin: 0,
  },
  button: {
    width: "60px",
    height: "60px",
  },
  badge: {
    color: "#2acb70",
    position: "relative",
    left: "80px",
    top: "-5px",
  },
};

const Menu = ({
  onStepChange,
  step,
  type,
  //required for Basic step to be complete
  campaignName,
  startDate,
  endDate,
  budget,
  //required for Targeting step to be complete.
  //geoTargets,
  devices,
  //required for Creatives step to be complete.
  adUnits,
  //required for Attribution step to be complete.
  attributionTargets,
  transactionTarget,
  geoTargetData,
  isEdit,
  isLaunchCampaign,
  isLive,
  targetingCriteria,
  cart,
  cpm,
  signUpCampaign,
  campaignOrder,
  awarenessCampaign,
  region,
}) => {
  const buttons = [
    {
      key: "basics",
      icon: <EditOutlined />,
      stepText: "Step 01",
      text: "Basics",
      step: 1,
      isCompleted:
        (!isEmpty(campaignName) &&
          !isEmpty(startDate) &&
          budget > 0 &&
          cpm > 0) ||
        (!isEmpty(campaignOrder) && type === 7) // 7 Means External
          ? true
          : false,
    },
    {
      key: "targeting",
      icon: <SearchOutlined />,
      stepText: "Step 02",
      text: "Targeting",
      step: 2,
      isCompleted:
        (geoTargetData && geoTargetData.length > 0) ||
        !isEmpty(region) ||
        type === 7 // 7 Means External
          ? true
          : false,
    },
    {
      key: "media",
      icon: <LayoutOutlined />,
      stepText: "Step 03",
      text: "Creatives",
      step: 3,
      isCompleted:
        adUnits && adUnits.length > 0
          ? (!isEmpty(adUnits[0].banners) &&
              !isEmpty(adUnits[0].clickthrough) &&
              !isEmpty(adUnits[0].name)) ||
            type === 7 // 7 Means External
            ? true
            : false
          : false,
    },
    {
      key: "attribution",
      icon: <EnvironmentOutlined />,
      stepText: type === 2 ? "Step 03" : "Step 04",
      text: "Attribution",
      step: 4,
      isCompleted:
        awarenessCampaign === true ||
        !isEmpty(cart) ||
        signUpCampaign === true ||
        !isEmpty(attributionTargets) ||
        type === 7 // 7 Means External
          ? true
          : false,
    },
    {
      key: "review",
      icon: <CheckOutlined />,
      stepText: type === 2 ? "Step 04" : "Step 05",
      text: "Review",
      step: 5,
      isCompleted: false,
    },
  ];

  const buttonsFiltered = buttons.filter(button => {
    return button.key;
  });

  const handleStepChange = async step => {
    try {
      await onStepChange(step);
      message.success("Campaign Order has been updated");
    } catch (error) {
      if (error.message === errorType.campaignOrderDuplicate) {
        message.error("Campaign Name already Exists!");
      } else {
        message.error(error.message);
      }
    }
  };

  return (
    <ul style={style.wrapper} className="mobile-button-wrapper">
      {buttonsFiltered.map(button => {
        const {
          key,
          icon,
          stepText,
          text,
          step: buttonStep,
          isCompleted,
          isDisabled,
        } = button;
        if (type === 2 && buttonStep === 3) return null;
        const isStep = buttonStep === step ? true : false;
        return (
          <li key={key} style={style.buttonWrapper}>
            {isCompleted ? (
              <Badge
                offset={[-7, 7]}
                count={
                  <CheckCircleOutlined theme="filled" style={style.badge} />
                }
              >
                <Button
                  type={isStep ? "primary" : "primary"}
                  ghost={false}
                  style={style.button}
                  icon={icon}
                  shape="circle"
                  disabled={isDisabled || false}
                  size="large"
                  onClick={() => handleStepChange(buttonStep)}
                />
              </Badge>
            ) : (
              <Button
                type={isStep ? "primary" : "default"}
                ghost={isStep ? true : false}
                style={style.button}
                icon={icon}
                disabled={isDisabled || false}
                shape="circle"
                size="large"
                onClick={() => handleStepChange(buttonStep)}
              />
            )}
            <div
              style={style.textWrapper}
              onClick={() => (isDisabled ? null : handleStepChange(buttonStep))}
            >
              <h4 style={isCompleted ? style.stepTextComplete : style.stepText}>
                {stepText}
              </h4>
              <h4 style={isStep ? style.textChecked : style.text}>{text}</h4>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
