import React, { useState } from "react";
import { HistoryOutlined } from "@ant-design/icons";
import {
  Tabs,
  Card,
  Select,
  Row,
  Spin,
  DatePicker,
  Slider,
  Col,
  Button,
  Empty,
  Grid,
} from "antd";
import { isEmpty, capitalize } from "lodash";
import moment from "moment";
import DualAxisArea from "./components/tabvisualization/charts/DualAxisArea";
import SingleAxisBarChart from "./components/tabvisualization/charts/SingleAxisBarChart";
import Creatives from "../../components/creatives/Creatives";
// import LineItems from "../../components/LineItems";
import Publishers from "../../components/publishers/Publishers";
//import VideoLogs from "../../components/VideoLogs";
import SegmentsDetails from "../../components/segments/SegmentsDetails";
// Global Styles
import {
  MenuItemTextColor,
  TabText,
  TooltipText,
} from "../../../../../shared/globalStyling/styledText";
import VideoCompletionRate from "../../components/VideoCompletionRate";
import {
  isDayOfWeekString,
  isUnixTimestamp,
} from "../../../../../../core/utils/campaigns";

const { Option } = Select;
const { RangePicker } = DatePicker;
const Frag = React.Fragment;
const colStyle = {
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};
const orderedDays = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const OverviewTab = ({
  chartData,
  chartsLoading,
  selectedCampaign,
  hasInsights,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  selectedSegmentsDimension,
  setSegmentsDimension,
  userPermission,
  displayReportsConnection,
  handleSelectPastReport,
  handleAfterChangePastReport,
  pastReportIdx,
  technicalSupport,
}) => {
  //TODO - DUAL AXIS CHART CONTROLLERS SHOULD BE BROKEN OUT OF THIS COMPONENT

  //State controllers for right and left axis toggle
  const [leftY, setLeftY] = useState("Impressions");
  const [rightY, setRightY] = useState("Clicks");

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const aggregate = displayReportsConnection
    ? displayReportsConnection.campaignReportsConnection.aggregate
    : null;
  const edges = displayReportsConnection
    ? displayReportsConnection.campaignReportsConnection.edges
    : null;

  const sliderDateFormat = "M/DD/YY HH:MM";
  const lastNodeCreatedAt = !isEmpty(edges)
    ? moment(edges[edges.length - 1].node.createdAt).format(sliderDateFormat)
    : null;
  const firstNodeCreatedAt = !isEmpty(edges)
    ? moment(edges[0].node.createdAt).format(sliderDateFormat)
    : null;

  let byDate,
    byDay,
    deviceType,
    chartsBuilt,
    creatives,
    publishers,
    videoLogs,
    segments;
  const { segment } = userPermission;

  try {
    if (chartData) {
      byDate = chartData.byDate.map(date => {
        return {
          ...date,
          xAxis: isUnixTimestamp(date.xAxis)
            ? moment.unix(date.xAxis).toISOString()
            : moment(new Date(date.xAxis)).toISOString(),
        };
      });
      byDay = chartData.byDay;

      if (!isEmpty(byDay)) {
        byDay = chartData.byDay.map(day => {
          return {
            ...day,
            xAxis: isDayOfWeekString(day.xAxis)
              ? moment().isoWeekday(day.xAxis)
              : day.xAxis,
          };
        });
        byDay.sort((a, b) => {
          return orderedDays[a.xAxis] - orderedDays[b.xAxis];
        });
      }

      videoLogs =
        chartData.videoLogs &&
        chartData.videoLogs.filter(
          log =>
            log.video_firstquartile > 0 &&
            log.video_midpoint > 0 &&
            log.video_thirdquartile > 0 &&
            log.video_complete > 0
        );

      deviceType =
        chartData.deviceType &&
        chartData.deviceType.map(device => {
          if (!Object.keys(device).includes("xAxis")) {
            device["xAxis"] = "Unknown";
          }
          return device;
        });

      segments = chartData.segments;
      chartsBuilt = chartData.chartsBuilt;
      creatives = chartData.creatives;
      publishers = chartData.publishers;
    }
  } catch (e) {
    console.log(e);
  }

  const marks = {
    1: <>{firstNodeCreatedAt}</>,
  };

  marks[aggregate && aggregate.count] = (
    <div style={{ width: 160 }}>{lastNodeCreatedAt}</div>
  );

  const TabPane = Tabs.TabPane;
  const emptyCharts = isEmpty(byDate) && isEmpty(byDay);
  const operations = (
    <div style={{ width: "100%" }}>
      <Row align="middle" justify="end">
        <Col flex="160px">
          <Row justify="end">
            <Col>
              <span style={{ fontSize: 10 }}>
                {chartsBuilt && !chartsLoading ? (
                  <TooltipText
                    text={`Updated ${moment(chartsBuilt).calendar()}`}
                  />
                ) : (
                  <Spin />
                )}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  // Date selector start date and end date for campaign impressions
  const DateSelector = ({ data }) => {
    // map xAxis dates
    const getDate = data.map(a => a.xAxis);

    // sort them by desc dates
    getDate.sort(function(a, b) {
      return moment(a.date) - moment(b.date);
    });

    // get startdate and end date
    const startDate = getDate[0];
    const endDate = getDate.slice(-1)[0];

    // combined start date & end date
    const startEndDate = [moment(startDate), moment(endDate)];

    if (!isEmpty(byCampaignRangeDate)) {
      byCampaignRangeDate = [
        moment(byCampaignRangeDate[0]).utc(),
        moment(byCampaignRangeDate[1]).utc(),
      ];
    }

    // find the dates between startdate and enddate
    // const findBetweenDates = current => {
    //   if (current <= moment(startDate).add(-2, "days")) {
    //     return true;
    //   } else if (current >= moment(endDate).add(+2, "days")) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };

    return (
      <RangePicker
        //disabledDate={findBetweenDates}
        defaultPickerValue={startEndDate}
        defaultValue={startEndDate}
        value={
          isEmpty(byCampaignRangeDate) ? startEndDate : byCampaignRangeDate
        }
        onChange={v => {
          // console.log(246, v);
          // get selected range dates
          const getSelectedStartDate =
            v && v.length > 0 ? v[0].utc() : startEndDate[0];
          const getSelectedEndDate =
            v && v.length > 0 ? v[1].utc() : startEndDate[1];

          // filter data to get only between range dates
          const getFilteredData = data.filter(obj => {
            const xAxisTimestamp = moment(obj.xAxis).unix();
            return (
              xAxisTimestamp >= moment(getSelectedStartDate).unix() &&
              xAxisTimestamp <= moment(getSelectedEndDate).unix()
            );
          });

          // set the date range on the selected date in calendar
          setByCampaignRangeDate(v);
          // set the data into filtered ones with the selected range dates
          setDataCampaignRangeDate(getFilteredData);
        }}
      />
    );
  };

  const YAxisSelector = ({
    displayDateSelector,
    displayRightSelector,
    data,
  }) => {
    const validOptions = ["clicks", "impressions", "ctr"];

    const keys = Object.keys(data[0])
      .filter(el => validOptions.includes(el))
      .map(option => {
        return option === "cpc" ||
          option === "cpm" ||
          option === "ctr" ||
          option === "ecpm"
          ? option.toUpperCase()
          : option === "totalCost"
          ? "Total Cost"
          : capitalize(option);
      })
      .filter(el => el !== "Creativeurl");

    return (
      <Row style={{ marginBottom: "2%" }} type="flex" justify="space-between">
        <Frag>
          <Select
            onSelect={value => setLeftY(value)}
            style={{
              width: screens.xs === true ? "100%" : "10em",
              minWidth: "10em",
              marginBottom: screens.xs === true ? "0.4em" : "",
            }}
            defaultValue={leftY}
          >
            {keys
              .filter(op =>
                displayRightSelector ? op !== rightY : op !== null
              )
              .map(option => {
                return (
                  <Option key={option} value={option}>
                    <MenuItemTextColor text={option} color={"#7dafff"} />
                  </Option>
                );
              })}
          </Select>
          {displayDateSelector ? <DateSelector data={byDate} /> : null}
          {displayRightSelector ? (
            <Select
              onSelect={value => {
                setRightY(value);
              }}
              style={{
                width: screens.xs === true ? "100%" : "10em",
                minWidth: "10em",
                marginTop: screens.xs === true ? "0.4em" : "",
                marginBottom: screens.xs === true ? "0.4em" : "",
              }}
              defaultValue={rightY}
            >
              {keys
                .filter(op => op !== leftY)
                .map(option => {
                  return (
                    <Option key={option} value={option}>
                      <MenuItemTextColor text={option} color={"#92d5a7"} />
                    </Option>
                  );
                })}
            </Select>
          ) : null}
        </Frag>
      </Row>
    );
  };

  if (chartsLoading)
    return (
      <Card style={{ height: "300px" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: "300px" }}
        >
          <Spin size="large" />
        </Row>
      </Card>
    );
  return (
    <div>
      {technicalSupport ? (
        <Row gutter={[16, 16]} style={{ padding: "8px" }}>
          <Col xs={{ span: 18, push: 3 }} lg={{ push: 1, span: 17 }}>
            {edges && (
              <Slider
                min={1}
                marks={marks}
                value={pastReportIdx}
                onChange={value => {
                  handleSelectPastReport(value);
                }}
                tipFormatter={value => {
                  const node = !isEmpty(edges) ? edges[value - 1].node : null;
                  return !isEmpty(node)
                    ? moment(node.createdAt).format(sliderDateFormat)
                    : null;
                }}
                max={aggregate ? aggregate.count : 0}
              />
            )}
          </Col>
          <Col xs={24} lg={{ span: 4, push: 2 }}>
            <Button
              style={{ width: "100%" }}
              shape="round"
              icon={<HistoryOutlined />}
              type="primary"
              onClick={() => handleAfterChangePastReport()}
            >
              Select Report
            </Button>
          </Col>
        </Row>
      ) : null}
      <Card bodyStyle={{ padding: 0 }}>
        <Tabs
          tabBarExtraContent={operations}
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
          onChange={activeKey => {
            if (activeKey === "4") setLeftY("Impressions");
            if (activeKey !== "4") {
              setLeftY("Impressions");
              setRightY("Clicks");
            }
          }}
        >
          {emptyCharts && (
            <TabPane>
              <Card style={{ height: "300px" }}>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ height: "300px" }}
                >
                  <Col>
                    <Empty />
                  </Col>
                </Row>
              </Card>
            </TabPane>
          )}
          {!isEmpty(byDate) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDate.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDate.length !== 1 && (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={true}
                      data={byDate}
                    />
                    <DualAxisArea
                      leftY={isEmpty(leftY) ? "Impressions" : leftY}
                      rightY={isEmpty(rightY) ? "Clicks" : rightY}
                      data={
                        !dataCampaignRangeDate && !dataCampaignRangeDate.length
                          ? byDate.sort((a, b) => {
                              if (
                                moment(new Date(a.xAxis)).isBefore(
                                  new Date(b.xAxis)
                                )
                              )
                                return -1;
                              return 1;
                            })
                          : dataCampaignRangeDate
                      }
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDay) ? (
            <TabPane tab={<TabText text={"Day Of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDay.length === 1 && (
                  <Card bordered={false}>
                    No By Day Chart Is Available At This Time...
                  </Card>
                )}
                {byDay.length !== 1 && (
                  <Frag>
                    <YAxisSelector
                      displayRightSelector={true}
                      displayDateSelector={false}
                      data={byDay}
                    />
                    <DualAxisArea
                      leftY={isEmpty(leftY) ? "Impressions" : leftY}
                      rightY={isEmpty(rightY) ? "Clicks" : rightY}
                      data={byDay}
                      showDayOnly={true}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(deviceType) ? (
            <TabPane tab={<TabText text={"Device Type"} />} key="4">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                <YAxisSelector
                  data={deviceType}
                  displayRightSelector={false}
                  displayDateSelector={false}
                />
                <SingleAxisBarChart
                  leftY={isEmpty(leftY) ? "Impressions" : leftY}
                  data={deviceType}
                />
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
      {creatives || !isEmpty(creatives) ? (
        <div style={{ marginTop: 10 }}>
          <Creatives
            colStyle={colStyle}
            selectedCampaign={selectedCampaign}
            creativeData={creatives}
            chartsLoading={chartsLoading}
          />
        </div>
      ) : null}

      {hasInsights ? (
        <div style={{ marginTop: 10 }}>
          <Publishers
            colStyle={colStyle}
            selectedCampaign={selectedCampaign}
            publisherData={publishers}
            chartsLoading={chartsLoading}
          />
        </div>
      ) : null}

      {segment && !isEmpty(segments) ? (
        <div style={{ marginTop: 10 }}>
          <SegmentsDetails
            colStyle={colStyle}
            selectedCampaign={selectedCampaign}
            segmentsData={segments}
            selectedSegmentsDimension={selectedSegmentsDimension}
            setSegmentsDimension={setSegmentsDimension}
          />
        </div>
      ) : null}

      {/* {videoLogs && !isEmpty(videoLogs) ? (
        <div style={{ marginTop: 10 }}>
          <Card title="Video Logs" bordered={false} bodyStyle={{ padding: 0 }}>
            <VideoLogs
              videoLogs={videoLogs && videoLogs}
              selectedCampaign={selectedCampaign}
            />
          </Card>
        </div>
      ) : null} */}

      {videoLogs && !isEmpty(videoLogs) ? (
        <div style={{ marginTop: 10 }}>
          <Card
            title="Video Completion Rate"
            bordered={false}
            bodyStyle={{ padding: 0 }}
          >
            <VideoCompletionRate chartData={videoLogs} chartsLoading={false} />
          </Card>
        </div>
      ) : null}
    </div>
  );
};

export default OverviewTab;
