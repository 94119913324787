import gql from "graphql-tag";

const ENABLE_ORG = gql`
  mutation enableOrg($id: ID!) {
    enableOrg(where: { id: $id }) {
      id
      domain
      brands(first: 5) {
        name
        logo {
          key
        }
      }
      name
      config {
        priceVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        providersVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
      }
      dataConfig {
        reTargeting
        appIds
        tradeDeskIdentifier
        storageBucket
        googleCustomerId
        seo
        semrushEnable
        semrushConfig {
          projectId
          rootDomainUrl
          rootKeyword
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        googleAnalytics {
          viewId
          isGA4
        }
      }
      description
      locations(first: 5) {
        street
        city
        state
      }
      logo {
        id
        key
      }
      signInLogo {
        id
        key
      }
      roles(first: 50, where: { isMaster: true }) {
        id
        name
        roleItems(first: 15) {
          id
          feature
          actions
        }
        users(first: 30) {
          id
        }
        isMaster
      }
      level
      website
      platformDomain
      tagDomain
      parentOrg {
        name
        id
      }
    }
  }
`;

export default ENABLE_ORG;
