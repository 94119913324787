import React from "react";
import { Form, Row, Select, Col, Modal } from "antd";

const RegionForm = ({
  isFormOpen,
  searchRegionLiquidMLoading,
  regionOptions,
  debouncedSearchRegionLiquidM,
  selectedRegions,
  setSelectedRegions,
  changeValue,
  setIsFormOpen,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={isFormOpen}
      form={form}
      title="Add Regions"
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            //Remove dupes on selected regions
            const uniqueRegions = [
              ...new Set([...selectedRegions, ...values.region]),
            ];
            setSelectedRegions(uniqueRegions);
            changeValue("region", uniqueRegions);
            setIsFormOpen(false);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={() => {
        setIsFormOpen(false);
      }}
    >
      <Form form={form} layout="vertical">
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              label="Region"
              name="region"
              rules={[
                {
                  required: true,
                  message: "Please select a region",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Regions"
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debouncedSearchRegionLiquidM}
                notFoundContent={null}
                loading={searchRegionLiquidMLoading}
                options={regionOptions}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RegionForm;
