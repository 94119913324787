import gql from "graphql-tag";
const UPDATE_AUDIENCE = gql`
  mutation updateAudience(
    $id: ID!
    $name: String!
    $description: String!
    $type: AudienceType!
    $isAmplifyDefault: Boolean
  ) {
    updateAudience(
      where: { id: $id }
      data: {
        name: $name
        description: $description
        type: $type
        isAmplifyDefault: $isAmplifyDefault
      }
    ) {
      id
      name
      description
      type
    }
  }
`;

export default UPDATE_AUDIENCE;
