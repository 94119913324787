import { Typography } from "antd";
import React from "react";
import InstructionsBuilder from "./InstructionsBuilder";
import { Text } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";

import img1 from "../../../../../../assets/tagsInstructions/wordPress/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/wordPress/img2.png";
import img3 from "../../../../../../assets/tagsInstructions/wordPress/img3.png";
import img4 from "../../../../../../assets/tagsInstructions/wordPress/img4.png";
import img5 from "../../../../../../assets/tagsInstructions/wordPress/img5.png";
import img6 from "../../../../../../assets/tagsInstructions/wordPress/img6.png";

const { Link } = Typography;
const wordPressStep = generatedTag => [
  {
    instruction: (
      <>
        Login to{" "}
        <Link
          href="https://wordpress.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordpress.com
        </Link>{" "}
        {">"} Install and activate the{" "}
        <Link
          href="https://wordpress.org/plugins/code-snippets/"
          target="_blank"
          rel="noopener noreferrer"
        >
          free Code Snippets plugin
        </Link>
        .
      </>
    ),
    pdfText:
      "Login to Wordpress.com > Install and activate the free Code Snippets plugin.",
  },
  {
    instruction: "Once the Code Snippets is installed. Click on the “Add New”.",
    image: img1,
  },
  {
    instruction: (
      <>
        Click on <Typography.Text strong>“Content (HTML)”</Typography.Text>. -{" "}
        <Typography.Text strong type="danger">
          VERY IMPORTANT
        </Typography.Text>
      </>
    ),
    image: img2,
    pdfText: "Click on “Content (HTML)”. - VERY IMPORTANT",
  },
  {
    instruction: "Make sure to choose the “Display in site <head> section”.",
    image: img3,
  },
  {
    instruction: "Name the snippet.",
    image: img4,
  },
  {
    instruction: "Paste the tag here.",
    image: img5,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          6. Paste the tag here.
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: (
      <>
        <Typography.Text strong>Save</Typography.Text> and{" "}
        <Typography.Text strong>Activate</Typography.Text> the code snippet
      </>
    ),
    image: img6,
    pdfText: "Save and Activate the code snippet.",
  },
];

export const WordPressInstructions = ({ generatedTag }) => {
  return (
    <InstructionsBuilder
      title="WordPress"
      steps={wordPressStep(generatedTag)}
    />
  );
};

export const WordPressInstructionsPDF = ({ generatedTag }) => {
  return (
    <GeneratedTagPDF title="WordPress" steps={wordPressStep(generatedTag)} />
  );
};
