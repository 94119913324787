import React from "react";
import { useQuery, useMutation } from "react-apollo";
// import { Redirect, withRouter } from "react-router-dom";
//locals core
import CAMPAIGN_ORDER_ATTRIBUTION_TARGETS from "../../../GraphQl/Queries/CAMPAIGN_ORDER_ATTRIBUTION_TARGETS";
import CAMPAIGN_ORDER_GEO_TARGETS from "../../../GraphQl/Queries/CAMPAIGN_ORDER_GEO_TARGETS";
import { UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS } from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS";
import { UPDATE_CAMPAIGN_ORDER_WITH_ATTRIBUTION_LOCATIONS } from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_WITH_ATTRIBUTION_LOCATIONS";
import { buildGeoTargetsTableData } from "../../../utils/campaigns";
//locals platform
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { isEmpty } from "lodash";
import { buildGeoTargetsTableDataWithAttribution } from "../../../utils/campaigns";
import DELETE_GEOTARGET from "../../../GraphQl/Mutations/DELETE_GEOTARGET";
import TAG_TRACKING_GET_EVENTS_ORGS from "../../../GraphQl/Queries/TAG_TRACKING_GET_EVENTS_ORGS";
import UPDATE_GEOTARGET_NEW from "../../../GraphQl/Mutations/UPDATE_GEOTARGET_NEW";

const GeotargetManagerLoader = ({ children, ...props }) => {
  const campaignOrderId = props.campaignOrder && props.campaignOrder.id;

  const [updateCampaignOrderWithAttributionLocations] = useMutation(
    UPDATE_CAMPAIGN_ORDER_WITH_ATTRIBUTION_LOCATIONS,
    {
      update(cache, { data: { updateCampaignOrder } }) {
        cache.writeQuery({
          query: CAMPAIGN_ORDER_ATTRIBUTION_TARGETS,
          variables: { ID: campaignOrderId },
          data: { campaignOrder: updateCampaignOrder },
          fetchPolicy: "no-cache",
        });
      },
    }
  );

  const [updateCampaignOrderWithGeoLocations] = useMutation(
    UPDATE_CAMPAIGN_ORDER_WITH_GEO_LOCATIONS,
    {
      update(cache, { data: { updateCampaignOrder } }) {
        cache.writeQuery({
          query: CAMPAIGN_ORDER_GEO_TARGETS,
          variables: { ID: campaignOrderId },
          data: { campaignOrder: updateCampaignOrder },
          fetchPolicy: "no-cache",
        });
      },
    }
  );

  const [deleteGeoTarget] = useMutation(DELETE_GEOTARGET, {
    variables: {
      id: props.id,
    },
  });

  const [updateGeoTargetData] = useMutation(UPDATE_GEOTARGET_NEW, {
    variables: {
      id: props.id,
      status: props.status,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CAMPAIGN_ORDER_GEO_TARGETS,
        variables: {
          ID: props.campaignOrderId,
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  // TODO: Route not protected - can access if path is known by any dashboard user

  const {
    loading,
    error,
    data: geoTargetData,
    refetch: refetchGeoTarget,
  } = useQuery(CAMPAIGN_ORDER_GEO_TARGETS, {
    variables: { ID: campaignOrderId },
    skip: !campaignOrderId,
    fetchPolicy: "network-only",
  });

  const { data: eventTagsData } = useQuery(TAG_TRACKING_GET_EVENTS_ORGS, {
    variables: { orgId: props.orgID },
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingAttr,
    error: errorAttr,
    data: dataAttr,
    refetch: refreshAttributionTargets,
  } = useQuery(CAMPAIGN_ORDER_ATTRIBUTION_TARGETS, {
    variables: { ID: campaignOrderId },
    skip: !campaignOrderId,
    fetchPolicy: "no-cache",
  });

  if (loading || loadingAttr) {
    return <OverlaySpinner />;
  }

  if (error || errorAttr) {
    const message = error ? error.message : errorAttr ? errorAttr.message : "";
    return `Error (Query: CAMPAIGN_ORDER_GEO_TARGETS)! ${message}`;
  }

  const attributionTargets = !isEmpty(dataAttr)
    ? buildGeoTargetsTableDataWithAttribution(
        dataAttr.campaignOrder,
        props.chartAttributionData
      )
    : [];

  // TODO: Data loader should not be responsible for building table data
  const geoTargets = !isEmpty(geoTargetData)
    ? buildGeoTargetsTableData(geoTargetData.campaignOrder.geoTargets)
    : [];

  return React.cloneElement(children, {
    updateCampaignOrderWithGeoLocations: updateCampaignOrderWithGeoLocations,
    updateCampaignOrderWithAttributionLocations: updateCampaignOrderWithAttributionLocations,
    campaignOrderId: campaignOrderId,
    geoTargetData: geoTargets, // geoTargets namespace is used by campaignOrder, renamed to avoid overwriting
    refetchGeoTarget: refetchGeoTarget,
    attributionTargets: attributionTargets,
    campaignOrder: props.campaignOrder,
    deleteGeoTarget: deleteGeoTarget,
    eventTagsData: eventTagsData,
    refreshAttributionTargets: refreshAttributionTargets,
    updateGeoTargetData: updateGeoTargetData,
    ...props,
  });
};

export default GeotargetManagerLoader;
