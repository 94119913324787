import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
//local
import BannersUploadTable from "./BannersUploadTable";
import moment from "moment";

const { Text, Title } = Typography;
const Frag = React.Fragment;
const styles = {
  row: {
    marginBottom: "15px",
  },
  internalRow: {
    marginBottom: "50px",
  },
  title: {
    color: "#8f8f8f",
    margin: 0,
    fontSize: "1em",
    fontWeight: 150,
  },
  input: {
    width: "100%",
    marginBottom: "10px",
  },
};

const ReviewCreatives = ({
  adUnits,
  isAdmin,
  status,
  addCreativeNotes,
  startDate,
  endDate,
  adUnitsKey,
  setAdUnitBannerIds,
  tableKey,
}) => {
  return (
    <Frag>
      {adUnits.map((adUnit, index) => {
        const adUnitStartDate = moment(adUnit.startDate || startDate).format(
          "MM/DD/YYYY"
        );
        const adUnitEndDate = moment(adUnit.endDate || endDate).format(
          "MM/DD/YYYY"
        );

        return (
          <Row key={`Ad Unit ${index + 1}`} style={styles.row}>
            <Col span={24}>
              <Card
                title={
                  <Col>
                    <FileImageOutlined />
                    <span>
                      {" "}
                      {` Ad Unit ${index + 1 < 10 ? "0" : ""}${index + 1}`}
                    </span>
                  </Col>
                }
              >
                <Row style={styles.internalRow}>
                  <Col span={12}>
                    <Row>
                      <Title style={styles.title} level={4}>
                        Name
                      </Title>
                    </Row>
                    <Row style={{ width: "90%" }}>
                      <Text>{adUnit.name}</Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ width: "90%" }}>
                      <Title style={styles.title} level={4}>
                        Click-Through Link
                      </Title>
                    </Row>
                    <Row>
                      <Text>{adUnit.clickthrough}</Text>
                    </Row>
                  </Col>
                </Row>
                <Row style={styles.internalRow}>
                  <Col span={12}>
                    <Row>
                      <Title style={styles.title} level={4}>
                        Start Date
                      </Title>
                    </Row>
                    <Row style={{ width: "90%" }}>
                      <Text>{adUnitStartDate}</Text>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ width: "90%" }}>
                      <Title style={styles.title} level={4}>
                        End Date
                      </Title>
                    </Row>
                    <Row>
                      <Text>{adUnitEndDate}</Text>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xs={24}>
                    <Title style={styles.title} level={4}>
                      Banners
                    </Title>
                    <Text type="secondary">
                      These are the banners that will be displayed to users
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Card bodyStyle={{ padding: 0 }}>
                      <BannersUploadTable
                        isReview={true}
                        adUnit={adUnit}
                        index={index}
                        isAdmin={isAdmin}
                        status={status}
                        addCreativeNotes={addCreativeNotes}
                        setAdUnitBannerIds={setAdUnitBannerIds}
                        tableKey={tableKey}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        );
      })}
    </Frag>
  );
};

export default ReviewCreatives;
