import React, { useEffect, useMemo, useState } from "react";
import {
  formatWholeNumber,
  formatNumber2SigFig,
  formatCurrency2SigFig,
  computePacing,
  computeDailyRemainingSpend,
  computeCTR,
  computeCPC,
} from "../../../utils/campaigns";
import moment from "moment";

const PaidSearchAnalyticsController = ({
  children,
  data,
  prevRangeData,
  error,
  refetch,
  loading,
  orgConfig,
  advertiserParams,
  defaultSummaryDates,
  orgId,
  pacingData,
  pacingGoogleData,
  pacingBingData,
  pacingAllCampaignsData,
  pacingSingleCampaignsData,
  getPacing,
  getPacingGoogle,
  getPacingBing,
  getPacingAllCampaigns,
  getSingleCampaigns,
  pacingGoogleLoading,
  pacingBingLoading,
  pacingLoading,
  pacingAllCampaignsLoading,
  pacingSingleCampaignsLoading,
  ...props
}) => {
  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);
  const [pacingReport, setPacingReport] = useState(
    (pacingData && pacingData.getPaidSearchPacing) || []
  );
  const [pacingGoogleReport, setPacingGoogleReport] = useState(
    (pacingGoogleData && pacingGoogleData.getPaidSearchPacingGoogle) || []
  );
  const [pacingBingReport, setPacingBingReport] = useState(
    (pacingBingData && pacingBingData.getPaidSearchPacingBing) || []
  );

  const diffPcts = useMemo(() => {
    let dataDiff = {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      roas: 0,
      revenue: 0,
      cost: 0,
    };

    const prev = prevRangeData
      ? prevRangeData.getRollUpPaidSearchAnalytics
      : undefined;
    const now = data ? data.getRollUpPaidSearchAnalytics : undefined;

    if (prev && now) {
      dataDiff.impressions = now.impressions - prev.impressions;
      dataDiff.clicks = now.clicks - prev.clicks;
      dataDiff.ctr = now.ctr - prev.ctr;
      dataDiff.cpc = now.cpc - prev.cpc;
      dataDiff.roas = now.roas - prev.roas;
      dataDiff.revenue = now.revenue - prev.revenue;
      dataDiff.cost = now.cost - prev.revenue;

      return {
        data: {
          impressions: (dataDiff.impressions / now.impressions) * 100,
          clicks: (dataDiff.clicks / now.clicks) * 100,
          ctr: (dataDiff.ctr / now.ctr) * 100,
          cpc: (dataDiff.cpc / now.cpc) * 100,
          roas: (dataDiff.roas / now.roas) * 100,
          revenue: (dataDiff.revenue / now.revenue) * 100,
          cost: (dataDiff.cost / now.cost) * 100,
        },
      };
    }
    return null;
  }, [prevRangeData, data]);

  useEffect(() => {
    if (pacingData && pacingData.getPaidSearchPacing)
      setPacingReport(pacingData.getPaidSearchPacing);
  }, [pacingData]);

  useEffect(() => {
    if (pacingGoogleData && pacingGoogleData.getPaidSearchPacingGoogle)
      setPacingGoogleReport(pacingGoogleData.getPaidSearchPacingGoogle);
  }, [pacingGoogleData]);

  useEffect(() => {
    if (pacingBingData && pacingBingData.getPaidSearchPacingBing)
      setPacingBingReport(pacingBingData.getPaidSearchPacingBing);
  }, [pacingBingData]);

  const setStartAndEndDate = dates => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("startDate")) params.delete("startDate");
    if (params.get("endDate")) params.delete("endDate");
    if (params.get("advertiserId") && advertiserParams)
      params.delete("advertiserId");

    params.append("startDate", dates[0].utc().format());
    params.append("endDate", dates[1].utc().format());
    if (advertiserParams) params.append("advertiserId", advertiserParams);

    const pathname = window.location.pathname.toString();
    if (pathname === "/home/analytics/paid-search") {
      props.history.push(`${pathname}?${params.toString()}`);
    }
  };

  const setBySummaryRangeDate = (dates, range) => {
    if (range === "All Time") {
      const params = new URLSearchParams(window.location.search);
      if (params.get("startDate")) params.delete("startDate");
      if (params.get("endDate")) params.delete("endDate");

      const pathname = window.location.pathname.toString();
      if (pathname === "/home/analytics/paid-search") {
        props.history.push(`${pathname}?${params.toString()}`);
      }
    } else {
      setControllerbySummaryRangeDate(dates);
      setStartAndEndDate(dates);
    }
  };

  if (!bySummaryRangeDate.length) {
    const dates = [
      moment(defaultSummaryDates[0])
        .startOf("d")
        .utc(),
      moment(defaultSummaryDates[1])
        .endOf("d")
        .utc(),
    ];

    setControllerbySummaryRangeDate(dates);
  }
  const generatePacingReport = () => {
    let totalImpressions = 0,
      totalClicks = 0,
      totalBudget = 0,
      totalSpend = 0;
    const data = pacingReport.map(data => {
      const { overallPacingData, org, config } = data;
      totalImpressions += overallPacingData
        ? Number(overallPacingData.impressions)
        : 0;
      totalClicks += overallPacingData ? Number(overallPacingData.clicks) : 0;
      totalBudget += config ? Number(config.monthlyBudget) : 0;
      totalSpend += overallPacingData ? Number(overallPacingData.spend) : 0;
      return {
        Advertiser: org.name,
        "Day of Month": overallPacingData
          ? formatWholeNumber(overallPacingData.dayOfMonth)
          : moment()
              .startOf("d")
              .utc()
              .date(),
        "Day in Month": overallPacingData
          ? formatWholeNumber(overallPacingData.dayInMonth)
          : moment()
              .startOf("d")
              .utc()
              .daysInMonth(),
        Impressions: overallPacingData
          ? formatWholeNumber(overallPacingData.impressions)
          : 0,
        Clicks: overallPacingData
          ? formatWholeNumber(overallPacingData.clicks)
          : 0,
        Budget: config ? formatCurrency2SigFig(config.monthlyBudget) : `$0`,
        Spend: overallPacingData
          ? formatCurrency2SigFig(overallPacingData.spend)
          : `$0`,
        Pacing: overallPacingData
          ? `${formatNumber2SigFig(overallPacingData.pace)}%`
          : `0%`,
        CTR: overallPacingData
          ? `${formatNumber2SigFig(overallPacingData.ctr)}%`
          : `0%`,
        CPC: overallPacingData
          ? `${formatNumber2SigFig(overallPacingData.cpc)}%`
          : `0%`,
        "Target Pacing": "0.0%",
        "Daily Remaining Spend": overallPacingData
          ? formatCurrency2SigFig(overallPacingData.dailyRemainSpend)
          : `$0`,
      };
    });
    data.push({
      Advertiser: "Total",
      "Day of Month": "",
      "Day in Month": "",
      Impressions: formatWholeNumber(totalImpressions),
      Clicks: formatWholeNumber(totalClicks),
      Budget: formatCurrency2SigFig(totalBudget),
      Spend: formatCurrency2SigFig(totalSpend),
      Pacing: `${formatNumber2SigFig(computePacing(totalSpend, totalBudget))}%`,
      CTR: `${formatNumber2SigFig(computeCTR(totalClicks, totalImpressions))}%`,
      CPC: `${formatNumber2SigFig(computeCPC(totalSpend, totalClicks))}%`,
      "Target Pacing": "0%",
      "Daily Remaining Spend": formatCurrency2SigFig(
        computeDailyRemainingSpend(totalSpend, totalBudget)
      ),
    });

    return data;
  };

  return React.cloneElement(children, {
    ...props,
    data: data ? data.getRollUpPaidSearchAnalytics : null,
    diffPcts,
    loading,
    error,
    orgConfig,
    bySummaryRangeDate,
    setBySummaryRangeDate,
    defaultSummaryDates,
    pacingReport,
    pacingGoogleReport,
    pacingBingReport,
    pacingAllCampaignsData,
    pacingSingleCampaignsData,
    setPacingReport,
    generatePacingReport,
    getPacing,
    getPacingGoogle,
    getPacingBing,
    getPacingAllCampaigns,
    getSingleCampaigns,
    pacingLoading,
    pacingGoogleLoading,
    pacingBingLoading,
    pacingAllCampaignsLoading,
    pacingSingleCampaignsLoading,
  });
};

export default PaidSearchAnalyticsController;
