import React from "react";
import DataSet from "@antv/data-set";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";

const TrafficByChannelLineChart = ({
  data,
  mediums,
  breakdownBy,
  visibleSources,
}) => {
  const colors = {};
  mediums.forEach(m => {
    colors[m.medium] = m.color;
  });
  colors["Other Sources"] = "#808080"; // "Other Sources" color

  const transformedData = data.map(day => {
    const transformedDay = { date: day.date };
    visibleSources.forEach(source => {
      transformedDay[source] = day[source] ? day[source][breakdownBy] || 0 : 0;
    });
    return transformedDay;
  });

  const dv = new DataSet.View().source(transformedData);
  dv.transform({
    type: "fold",
    fields: visibleSources,
    key: "channel",
    value: "traffic",
  });

  const scale = {
    traffic: {
      min: 0,
      alias: breakdownBy === "pageViews" ? "Page Views" : "Sessions",
    },
    date: {
      range: [0, 1],
    },
  };

  return (
    <Chart height={400} data={dv} scale={scale} forceFit>
      <Legend />
      <Axis name="date" />
      <Axis name="traffic" />
      <Tooltip crosshairs={{ type: "y" }} />
      <Geom
        type="line"
        position="date*traffic"
        size={0}
        color={["channel", channel => colors[channel] || "#808080"]}
      />
      <Geom
        type="point"
        position="date*traffic"
        size={data.length > 400 ? 1.7 : 3}
        shape={"circle"}
        color={["channel", channel => colors[channel] || "#808080"]}
        style={{ stroke: "#fff", lineWidth: 1 }}
      />
    </Chart>
  );
};

export default TrafficByChannelLineChart;
