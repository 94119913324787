import gql from "graphql-tag";

const UPDATE_CAMPAIGN_DSP = gql`
  mutation updateCampaignDSP($dspType: DemandSidePlatform!, $data: JSON!) {
    updateCampaignDSP(dspType: $dspType, data: $data) {
      id
      status
      dspConfig
      orgs {
        id
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_DSP;
