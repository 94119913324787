import React from "react";
import { Card, Select, Row, Col, Typography } from "antd";

const { Text } = Typography;
const { Option } = Select;

const frequency = [
  {
    key: "weekly",
    value: "WEEKLY",
    text: "Weekly",
  },
  {
    key: "biWeekly",
    value: "BIWEEKLY",
    text: "Every 2 Weeks",
  },
  {
    key: "monthly",
    value: "MONTHLY",
    text: "Once a month",
  },
];

const payment = [
  {
    key: "paypal",
    value: "PAYPAL",
    text: "PayPal",
  },
];

const styles = {
  row: {
    marginBottom: "15px",
  },
};

const PaymentCard = props => {
  const { row } = styles;
  return (
    <Card>
      <Row style={row}>
        <Text type="secondary">Payment</Text>
      </Row>
      <Row style={row}>
        <Col span={24}>
          <Select style={{ width: "100%" }}>
            {payment.map(type => {
              const { key, value, text } = type;
              return (
                <Option key={key} value={value}>
                  {text}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row style={row}>
        <Text type="secondary">Bill Frequency</Text>
      </Row>
      <Row>
        <Col span={24}>
          <Select style={{ width: "100%" }}>
            {frequency.map(freq => {
              const { key, value, text } = freq;
              return (
                <Option key={key} value={value}>
                  {text}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentCard;
