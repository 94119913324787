import gql from "graphql-tag";
const UPDATE_USER_MSA_STATUS = gql`
  mutation updateUser($id: ID, $msaStatus: MSAVersion) {
    updateUser(where: { id: $id }, data: { msaStatus: $msaStatus }) {
      id
      msaStatus
    }
  }
`;

export default UPDATE_USER_MSA_STATUS;
