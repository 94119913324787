import React, { useEffect, useState } from "react";
import { Tooltip, Typography, Space, Select } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

const TargetingAudienceSearch = ({
  addresses,
  audiences,
  selectedTargetingLocations,
  handleAddTargetFromAddresses,
}) => {
  const { Text } = Typography;
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSearch = value => {
    if (audiences && audiences.length) {
      const matchedOptions = audiences.filter(audience =>
        audience.name.toLowerCase().match(value.toLowerCase())
      );
      return matchedOptions && addresses;
    }
  };

  const handleSelect = id => {
    const matchedAudience = audiences.find(audience => audience.id === id);
    const matchedAddresses = getAudienceValidLocation(matchedAudience);
    handleAddTargetFromAddresses(matchedAddresses);
    setSelectedValue(null); // Clear the value
  };

  const getAudienceValidLocation = audience => {
    const { geoTargets } = audience;
    if (geoTargets) {
      const locations = geoTargets
        .filter(target => target.location && target.id)
        .map(target => {
          return addresses.find(address => address.id === target.location.id);
        })
        .filter(location => location && location.audienceType === "TARGETING");

      return locations;
    } else {
      return [];
    }
  };

  const renderItem = (title, count, id) => ({
    key: id,
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <Tooltip title="Number of Locations">
          <span>
            <EnvironmentOutlined /> {count}
          </span>
        </Tooltip>
      </div>
    ),
  });

  useEffect(() => {
    if (audiences && audiences.length && addresses && addresses.length) {
      setOptions(
        audiences
          .filter(audience => audience.type === "TARGETING")
          .reduce((acc, item) => {
            const orgName = item.orgs[0].name; // Extract org name from orgs array
            if (Array.isArray(acc)) {
              // Add safeguard to check if acc is an array
              const existingGroup = acc.find(group => group.label === orgName);
              const locations = getAudienceValidLocation(item);
              const newItem = renderItem(item.name, locations.length, item.id); // Create a new item with id and name properties
              if (existingGroup) {
                existingGroup.options.push(newItem); // Push the new item to options array of existing group
              } else {
                acc.push({
                  label: orgName,
                  options: [newItem], // Create a new options array with the new item
                });
              }
            }
            return acc; // Return the accumulated value in each iteration
          }, [])
      ); // Initialize acc with an empty array as the initial value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences, addresses, selectedTargetingLocations]);

  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          width: "100%",
        }}
      >
        <Text strong>Audiences</Text>
        <Select
          showSearch
          placeholder="Search and Select Audiences"
          style={{
            width: "100%",
          }}
          value={selectedValue}
          defaultActiveFirstOption={false}
          showArrow={true}
          notFoundContent={"No Records found."}
          filterOption={(inputValue, option) => {
            return (
              (option &&
                option.label &&
                typeof option.label === "string" &&
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0) ||
              (option &&
                option.value &&
                typeof option.value === "string" &&
                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0)
            );
          }}
          options={options}
          onSelect={(_, option) => {
            handleSelect(option.key);
          }}
          onSearch={handleSearch}
        ></Select>
      </Space>
    </div>
  );
};

export default TargetingAudienceSearch;
