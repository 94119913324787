import React from "react";
import { Row, Col } from "antd";
import DetailBar from "./components/DetailBar";
import { isEmpty } from "lodash";
import SummaryChart from "../../../../components/charts/SummaryChart";
import LocationChart from "../../../../components/charts/LocationChart";
import {
  defaultCurrencyFormat,
  defaultRoasFormat,
} from "../../../../../../../../core/utils/constants/constants";

const SummaryTab = ({
  data,
  setBySummaryRangeDate,
  orgConfig,
  bySummaryRangeDate,
  setData,
  revenueLocations,
  config,
  onChangeLocationDropdown,
  onChangeMonthRange,
  defaultSummaryDates,
  kpiPercentage,
  selectedLocations,
  currencyFormat = defaultCurrencyFormat,
  roasFormat = defaultRoasFormat,
}) => {
  const { overallData } = data;
  const {
    byDate,
    conversions,
    keywords,
    locations,
    impressions,
    clicks,
    phoneCalls,
    ctr,
    cpc,
    roas,
    revenue,
    spend,
    conversionRate,
  } = overallData;
  const hasLocationRevenue = (config && config.hasLocationRevenue) || false;
  const locationsKey = (config && config.locationsKey) || [];
  const isGoogleAds = (config && config.processWithGoogleAds) || false;
  const { priceVisible } = orgConfig;
  return (
    <>
      <Row gutter={35}>
        <Col xs={24}>
          <DetailBar
            impressions={impressions || 0}
            clicks={clicks || 0}
            phoneCalls={phoneCalls || 0}
            searches={keywords || 0}
            locations={locations || 0}
            transactions={conversions || 0}
            ctr={ctr || 0}
            cpc={cpc || 0}
            roas={roas || 0}
            revenue={revenue || 0}
            spend={spend || 0}
            conversionRate={conversionRate || 0}
            showPrices={priceVisible}
            kpiPercentage={kpiPercentage}
            currencyFormat={currencyFormat}
            roasSelectedFormat={roasFormat}
          />
        </Col>
        <Col xs={24}>
          <SummaryChart
            byDates={isEmpty(byDate) ? [] : byDate}
            reportData={byDate}
            dataSummaryRangeDate={byDate}
            bySummaryRangeDate={bySummaryRangeDate}
            setBySummaryRangeDate={setBySummaryRangeDate}
            showTransactions={conversions && conversions !== 0}
            showKeywords={keywords && keywords !== 0}
            showLocations={locations && locations !== 0}
            showPrices={priceVisible}
            setData={setData}
            isGoogleAds={isGoogleAds}
            locationsKey={locationsKey}
            onChangeLocationDropdown={onChangeLocationDropdown}
            defaultSummaryDates={defaultSummaryDates}
            selectedLocations={selectedLocations}
            currencyFormat={currencyFormat}
          />
        </Col>
        {revenueLocations && hasLocationRevenue && (
          <Col xs={24}>
            <LocationChart
              data={revenueLocations}
              onChangeMonthRange={onChangeMonthRange}
              currencyFormat={currencyFormat}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default SummaryTab;
