import React, { useState } from "react";

// Global Styling
import {
  TableColumnTitle,
  PrimaryTableRowText,
} from "../../../../../shared/globalStyling/styledText";
import { formatWholeNumber } from "../../../../../../core/utils/campaigns";
import uuid from "uuid";
import SimpleTable from "../../../../../shared/SimpleTable";

const SegmentsTable = ({ segmentsData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: <TableColumnTitle text={"Audience Targeting"} />,
      dataIndex: segmentsData.retargeting_name
        ? "retargeting_name"
        : "audience_id",
      key: segmentsData.retargeting_name ? "retargeting_name" : "audience_id",
      width: "250px",
      align: "left",
      sorter: (a, b) => a.retargeting_name.localeCompare(b.retargeting_name),
      render: retargeting_name => {
        return (
          <PrimaryTableRowText
            text={retargeting_name || "Audience Name Not Available"}
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "ais",
      footerSum: true,
      key: "ais",
      width: "130px",
      align: "left",
      sorter: (a, b) => a.ais - b.ais,
      render: ais => (
        <PrimaryTableRowText text={ais && formatWholeNumber(ais)} />
      ),
    },
    {
      title: <TableColumnTitle text={"Clicks"} />,
      footerSum: true,
      dataIndex: "clicks",
      key: "clicks",
      width: "100px",
      align: "left",
      sorter: (a, b) => a.clicks - b.clicks,
      render: clicks => (
        <PrimaryTableRowText text={clicks && formatWholeNumber(clicks)} />
      ),
    },
    {
      title: <TableColumnTitle text={"CTR"} />,
      footerContent: "-",
      dataIndex: "ctr",
      key: "ctr",
      width: "80px",
      align: "left",
      sorter: (a, b) => a.ctr - b.ctr,
      render: ctr => (
        <PrimaryTableRowText text={ctr && `${(ctr * 10).toFixed(2)}%`} />
      ),
    },
  ];

  return (
    <SimpleTable
      footer={
        segmentsData &&
        segmentsData.slice(currentPage * 10 - 10, currentPage * 10)
      }
      bordered={false}
      columns={columns}
      data={segmentsData.map(segments => {
        return {
          ...segments,
          key: `${segments.id}${uuid.v4()}`,
          audience_id:
            segments.audience_id === null
              ? segments.retargeting_name
              : segments.audience_id,
        };
      })}
      pagination={{
        pageSize: 10,
        total: segmentsData && segmentsData.length,
        current: currentPage,
      }}
      scroll={{ x: 200 }}
      onChange={(paginationObject, filtersObject, sorterObject) => {
        setCurrentPage(paginationObject.current);
      }}
    />
  );
};

export default SegmentsTable;
