import gql from "graphql-tag";

const LIST_USERS = gql`
  query users($loggedInOrg: ID!) {
    users(
      orderBy: createdAt_DESC
      where: { OR: [{ roles_some: { org: { id: $loggedInOrg } } }] }
    ) {
      id
      name
      username
      email
      phone
      cognitoUserId
      config {
        id
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        horizontalNavigation
        amplify
        configurator
        billing
        sitesAppsTransactions
        embeddableConfig {
          type
          ssoToken
          campaignOrder {
            id
            name
          }
          advertiser {
            id
            name
          }
          publisher {
            id
            name
          }
          widgetConfig {
            transactionChart
            transactionTable
          }
        }
        isBeta
      }
      roles(first: 5) {
        id
        roleItems(first: 20) {
          id
          feature
          actions
        }
        org {
          id
          name
          level
          platformDomain
          config {
            priceVisible
            providersVisible
            isPartner
            isTrafficker
            isSelfService
            hasInsights
            isAdmin
            isDemo
            exportData
            isProcessor
            canResetPasswords
            campaignSummary
            isPacing
            pastData
            segment
            technicalSupport
            articles
            amplify
            configurator
            billing
            sitesAppsTransactions
          }
          parentOrg {
            id
            name
            platformDomain
            level
          }
          logo {
            id
            key
          }
        }
      }
      avatar {
        id
        key
      }
      ssoToken
    }
  }
`;

export default LIST_USERS;
