import gql from "graphql-tag";

const GET_AUDIT_NOTIFICATIONS = gql`
  query getAuditNotifications($orgID: ID!) {
    campaignOrders(
      where: {
        OR: [
          {
            status_not: DRAFT
            orgs_some: {
              OR: [{ id: $orgID }, { parentOrg_some: { id: $orgID } }]
            }
          }
          { status: DRAFT, orgs_some: { id: $orgID } }
        ]
      }
    ) {
      id
      status
      name
      orgs {
        id
        name
        phone
        logo {
          id
          key
        }
      }
    }
  }
`;

export default GET_AUDIT_NOTIFICATIONS;
