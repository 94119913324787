import React from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "../../../../core/components/orgs";

const OrgsRoutes = props => {
  const { path } = props.match;
  return (
    <div>
      <Switch>
        <Route path={`${path}/:org?/:user?`} component={Loader} />
      </Switch>
    </div>
  );
};

export default OrgsRoutes;
