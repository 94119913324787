import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import "antd/dist/antd.css";

const widgetLabels = {
  transactionChart: "Transaction Chart",
  transactionTable: "Transaction Table",
};

const SSSOUserConfigurationToggler = ({
  allowedWidgets,
  setAllowedWidgets,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const allowedWidgetsArray = Object.entries(allowedWidgets);
    const data = allowedWidgetsArray.map(([key, value]) => {
      return {
        key,
        widget: widgetLabels[key],
        value,
      };
    });
    setData(data);
  }, [allowedWidgets]);
  const columns = [
    {
      title: "Allowed Widget",
      dataIndex: "widget",
      key: "widget",
      width: "60%",
    },
    {
      title: "",
      dataIndex: "switch",
      key: "switch",
      width: "15%",
      render: (_, record) => (
        <Switch
          checked={record.value}
          onChange={checked => {
            setAllowedWidgets({
              ...allowedWidgets,
              [record.key]: checked,
            });
          }}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="key"
    />
  );
};

export default SSSOUserConfigurationToggler;
