import gql from "graphql-tag";

const LIST_CAMPAIGNORDERS_CONTRACT_TERMS = gql`
  query campaignOrdersContractTerms(
    $first: Int
    $skip: Int
    $orderBy: CampaignOrderOrderByInput
    $where: CampaignOrderWhereInput!
  ) {
    campaignOrdersConnection(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          status
          startDate
          endDate
          budget
          cpm
          createdAt
          updatedAt
        }
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default LIST_CAMPAIGNORDERS_CONTRACT_TERMS;
