import React, { useMemo } from "react";
import { determineUserAccess } from "../../../../permissions/userLevelAccess";

const RollUpDisplayController = ({
  children,
  org,
  data,
  refetch,
  loading,
  byRollUpRangeDate,
  setByRollUpRangeDate,
  prevRangeData,
  loadingPrevRangeData,
  ...props
}) => {
  const userPermission = determineUserAccess(props.currentUser.permission);
  const diffPcts = useMemo(() => {
    let displayDiffs = {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      pace: 0,
    };

    let attributionDiffs = {
      transactions: 0,
      revenue: 0,
      spend: 0,
      roas: 0,
      walkIns: 0,
      signUps: 0,
    };

    // let deviceTypeDiffs = {
    //   mobile: {
    //     clicks: 0,
    //   },
    //   personalComputer: {
    //     clicks: 0,
    //   },
    //   phone: {
    //     clicks: 0,
    //   },
    //   tablet: {
    //     clicks: 0,
    //   },
    //   connectedTv: {
    //     clicks: 0,
    //   },
    //   pc: {
    //     clicks: 0,
    //   },
    //   other: {
    //     clicks: 0,
    //   },
    //   setTopBox: {
    //     clicks: 0,
    //   },
    //   mobileTablet: {
    //     clicks: 0,
    //   },
    // };

    const prev = prevRangeData ? prevRangeData.getRollUpDisplay : undefined;
    const now = data ? data.getRollUpDisplay : undefined;
    if (prev && now) {
      displayDiffs.clicks = now.display.clicks - prev.display.clicks;
      displayDiffs.impressions =
        now.display.impressions - prev.display.impressions;
      displayDiffs.ctr = now.display.ctr - prev.display.ctr;
      displayDiffs.pace = now.display.pace - prev.display.pace;

      attributionDiffs.transactions =
        now.attribution.transactions - prev.attribution.transactions;
      attributionDiffs.revenue =
        now.attribution.revenue - prev.attribution.revenue;
      attributionDiffs.spend = now.attribution.spend - prev.attribution.spend;
      attributionDiffs.roas = now.attribution.roas - prev.attribution.roas;
      attributionDiffs.walkIns =
        now.attribution.walkIns - prev.attribution.walkIns;
      attributionDiffs.signUps =
        now.attribution.signups - prev.attribution.signUps;

      // for (const key in deviceTypeDiffs) {
      //   deviceTypeDiffs[key].clicks =
      //     now.deviceTypes[key].clicks - prev.deviceTypes[key].clicks;
      // }

      return {
        display: {
          impressions:
            (displayDiffs.impressions / now.display.impressions) * 100,
          clicks: (displayDiffs.clicks / now.display.clicks) * 100,
          ctr: (displayDiffs.ctr / now.display.ctr) * 100,
          pace: (displayDiffs.pace / now.display.pace) * 100,
        },
        attribution: {
          transactions:
            (attributionDiffs.transactions / now.attribution.transactions) *
            100,
          revenue: (attributionDiffs.revenue / now.attribution.revenue) * 100,
          spend: (attributionDiffs.spend / now.attribution.spend) * 100,
          roas: (attributionDiffs.roas / now.attribution.roas) * 100,
          walkIns: (attributionDiffs.walkIns / now.attribution.walkIns) * 100,
          signUps: (attributionDiffs.signUps / now.attribution.signUps) * 100,
        },
        // deviceTypes: {
        //   mobile: {
        //     clicks:
        //       (deviceTypeDiffs.mobile.clicks / now.deviceTypes.mobile.clicks) *
        //       100,
        //   },
        //   personalComputer: {
        //     clicks:
        //       (deviceTypeDiffs.personalComputer.clicks /
        //         now.deviceTypes.personalComputer.clicks) *
        //       100,
        //   },
        //   phone: {
        //     clicks:
        //       (deviceTypeDiffs.phone.clicks / now.deviceTypes.phone.clicks) *
        //       100,
        //   },
        //   tablet: {
        //     clicks:
        //       (deviceTypeDiffs.tablet.clicks / now.deviceTypes.tablet.clicks) *
        //       100,
        //   },
        //   connectedTv: {
        //     clicks:
        //       (deviceTypeDiffs.connectedTv.clicks /
        //         now.deviceTypes.connectedTv.clicks) *
        //       100,
        //   },
        //   pc: {
        //     clicks:
        //       (deviceTypeDiffs.pc.clicks / now.deviceTypes.pc.clicks) * 100,
        //   },
        //   other: {
        //     clicks:
        //       (deviceTypeDiffs.other.clicks / now.deviceTypes.other.clicks) *
        //       100,
        //   },
        //   setTopBox: {
        //     clicks:
        //       (deviceTypeDiffs.setTopBox.clicks /
        //         now.deviceTypes.setTopBox.clicks) *
        //       100,
        //   },
        //   mobileTablet: {
        //     clicks:
        //       (deviceTypeDiffs.mobileTablet.clicks /
        //         now.deviceTypes.mobileTablet.clicks) *
        //       100,
        //   },
        // },
      };
    }
    return null;
  }, [prevRangeData, data]);

  return React.cloneElement(children, {
    ...props,
    displayData: data ? data.getRollUpDisplay : null,
    org,
    loading,
    byRollUpRangeDate,
    setByRollUpRangeDate,
    prevRangeData,
    loadingPrevRangeData,
    diffPcts,
    userPermission,
  });
};

export default RollUpDisplayController;
