import React from "react";
import { graphql, Query, useLazyQuery } from "react-apollo";
import compose from "lodash/flowRight";
import GET_CAMPAIGN_REPORT from "../../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";
import GET_SIGNUP_PAGINATION from "../../../../GraphQl/Queries/GET_SIGNUP_PAGINATION";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import Controller from "./SignUpsTabController";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";

const SignUpsTabDataLoader = ({ children, dataConfig, ...props }) => {
  const reportId = props.signUpReportId ? props.signUpReportId : "null";
  const searchParams = new URLSearchParams(props.location.search);
  const pageSize = searchParams.get("pageSize")
    ? parseInt(searchParams.get("pageSize"))
    : 10;

  const [
    getSignupsTable,
    { loading: signupsTableLoading, data: signupsTable },
  ] = useLazyQuery(GET_SIGNUP_PAGINATION, {
    fetchPolicy: "cache-first",
  });

  try {
    return (
      <Query
        query={GET_CAMPAIGN_REPORT}
        variables={{
          reportId: reportId,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <OverlaySpinner />;
          if (error)
            return `Error (Query: GET_CAMPAIGN_REPORT)! ${error.message}`;

          const { url, expiresAt } = data.getCampaignReportSignedURL;
          if (!url) {
            return null;
          }

          const s3url = new URL(url);
          const pathnames = s3url.pathname.split("/");
          const reportName = pathnames && pathnames[3];

          return (
            <Controller
              dataConfig={dataConfig}
              refetch={refetch}
              url={url}
              expiresAt={expiresAt}
              reportName={reportName}
              pageSize={pageSize}
              getSignupsTable={getSignupsTable}
              signupsTableLoading={signupsTableLoading}
              signupsTable={
                signupsTable ? signupsTable.getSignupPagination : null
              }
              {...props}
            >
              {children}
            </Controller>
          );
        }}
      </Query>
    );
  } catch (err) {
    console.log("err", err);
  }
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(SignUpsTabDataLoader);
