import gql from "graphql-tag";
const GET_CAMPAIGN_REPORTS = gql`
  query getCampaignReportSignedURLs($reportId: [ID!]!) {
    getCampaignReportSignedURLs(reportId: $reportId) {
      url
      expiresAt
      error
    }
  }
`;

export default GET_CAMPAIGN_REPORTS;
