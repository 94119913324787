import gql from "graphql-tag";
const MOVE_USER_TO_OTHER_ORG = gql`
  mutation moveDashboardUser(
    $orgId: String!
    $roleId: String!
    $username: String!
    $userId: String!
  ) {
    moveDashboardUser(
      orgId: $orgId
      roleId: $roleId
      username: $username
      userId: $userId
    )
  }
`;

export default MOVE_USER_TO_OTHER_ORG;
