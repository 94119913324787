import gql from "graphql-tag";

const PAID_SEARCH_LAUNCH = gql`
  mutation($data: PaidSearchSummaryCreateInput!) {
    createPaidSearchSummary(data: $data) {
      id
    }
  }
`;

export default PAID_SEARCH_LAUNCH;
