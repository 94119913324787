import React from "react";
import { Row, Col, Card } from "antd";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../../shared/globalStyling/styledText";
import {
  formatWholeNumber,
  formatCurrency2SigFig,
} from "../../../../../../../core/utils/campaigns";

const DetailBar = ({ transactions = 0, revenue = 0, items = 0 }) => {
  const styles = {
    container: {
      backgroundColor: "#fff",
      borderTop: "solid 1px #e8e8e8",
      marginBottom: "1%",
    },
    secondaryContainer: {
      padding: "3% 3% 1% 3%",
      margin: 0,
    },
    title: {
      marginBottom: "10px",
    },
    keyColors: {
      spent: "#1665d8",
      remaining: "#34aa44",
      budget: "#030303",
      total: "#bec0c3",
      value: "#99999",
    },
  };
  return (
    <>
      <Row style={styles.container} wrap={false}>
        <Col flex={4}>
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Transactions"} />
            </div>
            <KpiSubText text={`${formatWholeNumber(transactions)}`} />
          </Card>
        </Col>
        <Col flex={4}>
          <Card
            style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
            bodyStyle={{ borderLeft: 0 }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Revenue"} />
            </div>
            <KpiSubText text={`${formatCurrency2SigFig(revenue)}`} />
          </Card>
        </Col>
        {items !== 0 && (
          <Col flex={4}>
            <Card
              style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
              bodyStyle={{ borderLeft: 0 }}
            >
              <div style={styles.title}>
                <KPITitleText text={"Items"} />
              </div>
              <KpiSubText text={`${formatWholeNumber(items)}`} />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default DetailBar;
