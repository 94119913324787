import gql from "graphql-tag";

const AUDIENCES_CONNECTION = gql`
  query geoTargetsConnection(
    $first: Int
    $skip: Int
    $orderBy: AudienceOrderByInput
    $where: AudienceWhereInput
  ) {
    audiencesConnection(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          description
          startDate
          endDate
          observationCount
          deviceIdCount
          uniqueCount
          geoTargets {
            id
            name
            observations
            uniqueDevices
            expandedReach
            updatedAt
          }
          eventsTargets {
            id
            name
            eventTags {
              appId
            }
            observations
            uniqueDevices
            expandedReach
            updatedAt
            tag
          }
          orgs {
            id
            name
          }
          tags
          type
          createdAt
          updatedAt
        }
      }
      aggregate {
        count
      }
    }
    full: audiencesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default AUDIENCES_CONNECTION;
