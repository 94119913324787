import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import MainController from "./MainController";
import LIST_ORGS_ADMIN from "../../../GraphQl/Queries/LIST_ORGS_ADMIN";
import moment from "moment";

const MainDataLoader = ({ children, ...props }) => {
  const loggedInRole = props.currentUser.role;
  const orgId = loggedInRole.org.id;
  const hasFeaturePaidSearch =
    loggedInRole.roleItems.filter(r => r.feature === "PAID_SEARCH").length > 0;
  const hasFeatureSEO =
    loggedInRole.roleItems.filter(r => r.feature === "SEO").length > 0;

  const [startDateEndDate, setStartDateEndDate] = useState(null);
  const [productOn, setProductOn] = useState({
    display: true,
    sem: hasFeaturePaidSearch,
    seo: hasFeatureSEO,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRangeKey, setDateRangeKey] = useState("All");
  const [loadProgress, setLoadProgress] = useState(0);
  const [totals, setTotals] = useState({
    impressions: null,
    revenue: null,
    spend: null,
    roas: null,
  });

  const searchParams = new URLSearchParams(props.location.search);
  const startDateParams = searchParams.get("startDate");
  const endDateParams = searchParams.get("endDate");

  const {
    loading: loadingOrgsList,
    data: orgsList,
    refetch: refetchOrgsList,
  } = useQuery(LIST_ORGS_ADMIN, {
    variables: { parentid: orgId, first: 1000 },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    setLoadProgress(Object.values(productOn).filter(v => v === true).length);
    setTotals({
      impressions: null,
      revenue: null,
      spend: null,
      roas: null,
    });

    if (startDate && endDate) {
      if (searchParams.get("startDate")) searchParams.delete("startDate");
      if (searchParams.get("endDate")) searchParams.delete("endDate");

      searchParams.append("startDate", startDate);
      searchParams.append("endDate", endDate);

      const pathname = window.location.pathname.toString();
      if (pathname === "/home") {
        props.history.push(`${pathname}?${searchParams.toString()}`);
      }
    }
    // eslint-disable-next-line
  }, [ startDate, endDate, productOn ]);

  useEffect(() => {
    if (
      startDateEndDate &&
      startDateEndDate.length &&
      startDateEndDate[0] &&
      startDateEndDate[1]
    ) {
      setStartDate(startDateEndDate[0]);
      setEndDate(startDateEndDate[1]);
    } else if (startDateParams && endDateParams) {
      const dates = [
        moment(new Date(startDateParams)).utc(),
        moment(new Date(endDateParams)).utc(),
      ];
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setStartDateEndDate(dates);
    } else if (
      !startDateEndDate ||
      (!startDateEndDate[0] && startDateEndDate[1])
    ) {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [endDateParams, startDateEndDate, startDateParams]);

  return (
    <MainController
      {...props}
      startDateEndDate={startDateEndDate}
      setStartDateEndDate={setStartDateEndDate}
      startDate={startDate}
      endDate={endDate}
      dateRangeKey={dateRangeKey}
      setDateRangeKey={setDateRangeKey}
      loadingOrgsList={loadingOrgsList}
      orgsList={orgsList ? orgsList.orgs : []}
      refetchOrgsList={refetchOrgsList}
      setLoadProgress={setLoadProgress}
      loadProgress={loadProgress}
      totals={totals}
      setTotals={setTotals}
      productOn={productOn}
      setProductOn={setProductOn}
      hasFeaturePaidSearch={hasFeaturePaidSearch}
      hasFeatureSEO={hasFeatureSEO}
    >
      {children}
    </MainController>
  );
};

export default MainDataLoader;
