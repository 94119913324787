import gql from "graphql-tag";

const GET_ORG_SEND_EMAIL_CONFIG = gql`
  query getOrgSendEmailConfig($id: ID) {
    getOrgSendEmailConfig(where: { id: $id }) {
      campaignOrders
      email
      frequency
      dayOfWeek
    }
  }
`;

export default GET_ORG_SEND_EMAIL_CONFIG;
