import React from "react";
import { Popconfirm, message, Empty, Tag } from "antd";
import PaginationTable from "../../../shared/PaginationTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function RegionGroupListView({
  regionGroups: { regionGroups },
  currentPage,
  onDeleteRegionGroup,
  toggleForm,
  setCurrentRegion,
}) {
  const handleEdit = region => {
    setCurrentRegion(region);
    toggleForm(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => name,
    },
    {
      title: "Regions",
      dataIndex: "regions",
      key: "regions",
      width: "60%",
      render: regions => {
        return regions.map((region, index) => {
          return (
            <Tag color="blue" key={index}>
              {region}
            </Tag>
          );
        });
      },
    },
    {
      title: "Preset List",
      dataIndex: "isPresetList",
      key: "isPresetList",
      width: "10%",
      sorter: (a, b) => (a.isPresetList ? 1 : -1) - (b.isPresetList ? 1 : -1),
      render: isPresetList => {
        return isPresetList ? "Yes" : "No";
      },
    },
    {
      title: "",
      key: "edit",
      width: "5%",
      render: (_, region) => {
        return (
          <EditOutlined
            onClick={() => handleEdit(region)}
            style={{ marginRight: 8 }}
          />
        );
      },
    },
    {
      title: "",
      key: "delete",
      width: "5%",
      render: (_, location) => (
        <Popconfirm
          title="Are you sure you want to delete this Region Group?"
          onConfirm={e => {
            onDeleteRegionGroup(location.id);
          }}
          onCancel={e => {
            message.warning("cancelled");
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {regionGroups && regionGroups.length > 0 ? (
        <PaginationTable
          columns={columns}
          data={regionGroups.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )}
          scroll={{ x: 800, y: 600 }}
          bordered={false}
          paginationSize={"default"}
          size={"default"}
          current={currentPage}
          pageSize={10}
          rowKey="id"
        />
      ) : (
        <Empty />
      )}
    </>
  );
}
