import gql from "graphql-tag";

const GET_MESSAGE = gql`
  query getMessage($getCampaignOrderID: String) {
    messages(where: { context: $getCampaignOrderID }, orderBy: createdAt_DESC) {
      id
      user {
        name
        roles {
          org {
            name
          }
        }
      }
      conversation {
        id
      }
      isUser
      personality
      context
      message
      currentOrgID
    }
  }
`;

export default GET_MESSAGE;
