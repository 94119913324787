import React from "react";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "./aws-exports";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable, from } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import "./admin/platform/shared/globalStyling/styles.scss";
import { PermifyProvider } from "@permify/react-role";
import { PermifyContext } from "@permify/react-role";
import { BrowserRouter as Router } from "react-router-dom";
import { RetryLink } from "@apollo/link-retry";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

//Locals
import AccountLoader from "./admin/core/AccountLoader";
import defaultState from "./admin/core/resolvers/defaults";
import CURRENT_USER from "./admin/core/GraphQl/Queries/CURRENT_USER";
//Constants
import { errorType } from "./admin/core/utils/constants/constants";
import { H } from "highlight.run";

const allowedEnvironments = ["production", "staging", "dojo"];
const getDomain = window.location.hostname;

// Highlight.run configuration
if (allowedEnvironments.includes(process.env.REACT_APP_ENV)) {
  H.init("odzyp4gp", {
    serviceName: "mediajel-dashboard",
    tracingOrigins: true,
    reportConsoleErrors: true,
    version: "commit:abcdefg12345",
    enablePerformanceRecording: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

// AWS Amplify configuration
Amplify.configure({
  ...aws_exports,
  Analytics: {
    disabled: true,
  },
  storage: window.sessionStorage,
  // Enable multiple devices and sessions
  // so users can sign in from multiple browsers or devices
  // and stay signed in even if they close their browser or device
  authenticationCloseDelay: 0,
  sessionExpiration: 604800, // 7 days
  cookieStorage: {
    domain: window.location.origin,
    path: "/",
    expires: 365,
    secure: true,
    sameSite: "none",
  },
  userPool: {
    // Enable remember user devices so users can sign in
    // from multiple devices or browsers and stay signed in
    rememberLastUser: true,
    deviceTracking: {
      enable: true,
      deviceOnlyRememberedOnUserPrompt: false,
    },
  },
  // Enable refresh tokens to allow users to stay signed in
  // even if their session expires
  oauth: {
    refreshTokens: true,
  },
});

// Amplify.Logger.LOG_LEVEL = "DEBUG";

const httpLink = new HttpLink({
  uri:
    getDomain === process.env.REACT_APP_URL_CHECK
      ? process.env.REACT_APP_HTTP_URI_PROD_STAGE
      : process.env.REACT_APP_HTTP_URI,
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(
    getDomain === process.env.REACT_APP_URL_CHECK
      ? process.env.REACT_APP_WS_URI_PROD_STAGE
      : process.env.REACT_APP_WS_URI,
    {}
  )
);

const request = async operation => {
  try {
    let currentCreds = await Auth.currentCredentials();
    const { identityId } = currentCreds;
    const user = await Auth.currentAuthenticatedUser();
    const { aws_cognito_region, aws_user_pools_id } = aws_exports;
    const provider = `cognito-idp.${aws_cognito_region}.amazonaws.com/${aws_user_pools_id}`;
    const session = await Auth.currentSession();

    operation.setContext({
      headers: {
        jwtToken: session.idToken.jwtToken,
        identity: identityId,
        provider: provider,
        username: user.username,
      },
    });
  } catch (err) {
    // We ignore the error. This means we have a guest user.
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

/**
 *  Retry link is used to attempt an operation multiple times if it fails due to a network or server error
 *
 * {@link https://www.apollographql.com/docs/react/api/link/apollo-link-retry/}
 *
 */
const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
  delay: {
    initial: 300, // 300 ms delay before first retry attempt
    max: Infinity, // No max delay for subsequent attempts
    jitter: true, // Randomize the delay by 0-100ms
  },
});

const link = from([
  onError(({ graphQLErrors, networkError, forward, operation, response }) => {
    if (networkError) {
      // Add log statements to intercept in highlight.io
      console.error({
        "GraphQL network error": networkError,
        "GraphQL operation": operation,
        "GraphQL response": response,
      });

      // Retry the request, returning the new observable
      return forward(operation);
    }
    if (graphQLErrors) {
      try {
        //graphQLErrors is an array of errors
        for (let err of graphQLErrors) {
          const graphQlErrorPath = err.path[0];
          // check errorType
          // TODO: Instead of checking all possible valid error types, we should check for the invalid ones
          // less code, less maintenance
          if (!errorType[graphQlErrorPath]) {
            console.error(`[GraphQL error]`, graphQLErrors);
            client.resetStore().then(() => Auth.signOut());
          }
        }
      } catch (err) {
        // do nothing
      }
    }
  }),
  retryLink,
  requestLink,
  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value - in this case we use ws
  // * The Link to use for an operation if the function returns a "falsy" value - in this case we use http
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  ),
]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  resolvers: {
    Mutation: {
      signOut: (_, variables, { client }) =>
        Auth.signOut().then(() => client.resetStore()),
      updateUser: (_, variables, { cache }) => {
        const data = {
          currentUser: { ...variables, __typename: "currentUser" },
        };
        cache.writeQuery({ query: CURRENT_USER, data });

        return data;
      },
    },
  },
});

cache.writeData({ data: defaultState });

const App = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <PermifyProvider>
          <PermifyContext.Consumer>
            {({ setUser }) => <AccountLoader setUser={setUser} />}
          </PermifyContext.Consumer>
        </PermifyProvider>
      </ApolloProvider>
    </Router>
  );
};

export default App;
