import gql from "graphql-tag";

const ORG_SEO_SUMMARIES = gql`
  query orgSeoSummaries($orgId: ID) {
    orgSeoSummaries(
      where: {
        AND: [{ org: { dataConfig: { seo: true } } }]
        OR: [
          { org: { parentOrg_some: { id: $orgId } } }
          { sharedOrgs_some: { id: $orgId } }
          { org: { id: $orgId } }
        ]
      }
    ) {
      id
      createdAt
      updatedAt
      startDate
      endDate
      eCommerceTracking
      enhancedECommerceTracking
      seoSummaryData
      key
      commerceKey
      org {
        id
        name
      }
    }
  }
`;

export default ORG_SEO_SUMMARIES;
