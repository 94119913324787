import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

const CardTitle = ({ titleText, iconType, styles, gutter }) => {
  const textStyle = styles ? styles.textStyle : null;
  return (
    <Row type="flex" gutter={gutter} align="middle">
      <Col>
        {/* <Icon TODO
          style={{
            display: "flex",
            alignSelf: "center",
            color: iconStyle ? iconStyle.color : "",
            fontSize: iconStyle ? iconStyle.fontSize : "",
          }}
          type={iconType}
          size="large"
        /> */}
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: textStyle ? textStyle.color : "" }}>
          {titleText}
        </h4>
      </Col>
    </Row>
  );
};

CardTitle.propTypes = {
  iconType: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    iconStyle: PropTypes.shape({
      color: PropTypes.string.isRequired,
      fontSize: PropTypes.string.isRequired,
    }),
    textStyle: PropTypes.shape({
      color: PropTypes.string.isRequired,
    }),
  }),
  gutter: PropTypes.number.isRequired,
};

export default CardTitle;
