import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Card, Form, Button, Col, Row, message, Divider } from "antd";
//locals from shared
import SingleImageUploader from "../../shared/images/SingleImageUploader";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
// Utility imports

const AccountView = ({ currentUser, editUserSelf, user, updateUser }) => {
  const { id, username } = currentUser;
  Form.useForm({
    name: "user_form",
  });

  const [isUpdate, setIsUpdate] = useState(false);

  const handleSubmit = async values => {
    try {
      setIsUpdate(true);
      const editUser = await editUserSelf({
        variables: {
          id,
          avatar: values.avatar.id
            ? { connect: { id: values.avatar.id } }
            : null,
        },
      });
      if (editUser) {
        message.success("Successfully updated the user profile");
        setIsUpdate(false);
      }
    } catch (err) {
      console.log(err);
      setIsUpdate(false);
      message.error("Error updating user profile");
    }
  };
  return (
    <React.Fragment>
      <PageHeaderLayout title="User Profile" />
      <PageContentLayout>
        <Row justify="center" type="flex" align="middle">
          <Col xs={24} lg={16} xl={12}>
            <Card>
              <Form initialValues={{ ...currentUser }} onFinish={handleSubmit}>
                <Row type="flex" align="middle" justify="center">
                  <Col>
                    <Form.Item
                      name="avatar"
                      valuePropName="image"
                      getValueFromEvent={(statekey, media) => media}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Avatar is required!")
                                ),
                        },
                      ]}
                    >
                      <SingleImageUploader statekey="avatar" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Username">
                      <div style={{ fontWeight: "bold" }}>
                        {username ? username : ""}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Email Address">
                      <div style={{ fontWeight: "bold" }}>
                        {user.attributes.email ? user.attributes.email : ""}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Phone Number">
                      <div style={{ fontWeight: "bold" }}>
                        {" "}
                        {user.attributes.phone_number
                          ? user.attributes.phone_number.substring(2)
                          : ""}
                      </div>
                    </Form.Item>
                  </Col>

                  {/* {user.preferredMFA ? (
                    <Col span={24}>
                      <Form.Item label="MFA">
                        <RadioGroupMFA user={user} updateUser={updateUser} />
                      </Form.Item>
                    </Col>
                  ) : null} */}
                </Row>

                <Divider />

                <Form.Item>
                  <Row type="flex" justify="space-around" gutter={10}>
                    <React.Fragment>
                      <Col>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={isUpdate}
                          icon={
                            !isUpdate ? <SaveOutlined /> : <LoadingOutlined />
                          }
                        >
                          Update Profile
                        </Button>
                      </Col>
                    </React.Fragment>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default AccountView;
