import { possibleFields } from "../../utils/constants/constants";

export const settingSelectedTableColumns = (
  orgTableConfig,
  removeSpace = false
) => {
  let defaultSelectedColumns = [];

  Object.keys(possibleFields).forEach((key, index) => {
    const field = possibleFields[key];

    if (field.configKey) {
      if (orgTableConfig[field.configKey])
        defaultSelectedColumns.push(
          removeSpace ? field.title.replace(" ", "") : field.title
        );
    } else {
      defaultSelectedColumns.push(
        removeSpace ? field.title.replace(" ", "") : field.title
      );
    }
  });

  return defaultSelectedColumns;
};

export const hasIsAmplifyDefaultSelection = (data, value = true) => {
  if (Array.isArray(data)) {
    return data.some(
      object =>
        object.hasOwnProperty("isAmplifyDefault") &&
        object.isAmplifyDefault === value
    );
  } else {
    return (
      data.hasOwnProperty("isAmplifyDefault") && data.isAmplifyDefault === value
    );
  }
};

export const settingCreateColumnVisibilityObject = array => {
  if (array && !array.length === 0) return;

  const referenceArray = [
    "Name",
    "Status",
    "DaysRemaining",
    "StartDate",
    "EndDate",
    "Impressions",
    "Walkins",
    "Performance",
    "Pacing",
    "Sales",
    "SignUps",
    "Budget",
    "Attribution",
  ];

  const object = {};

  referenceArray.forEach(item => {
    const itemRemoveSpace = item.replace(" ", "");
    object[`isShow${itemRemoveSpace}`] = array.includes(itemRemoveSpace);
  });

  return object;
};
