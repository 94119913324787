import React, { useEffect, useState } from "react";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";
import { Card, Col, Row, Table } from "antd";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import DonutChart from "../../../../shared/charts/DonutChart";

const TopInventoryTypes = ({ data = [] }) => {
  const [inventoryType, setInventoryTypes] = useState(data);

  useEffect(() => {
    if (data) {
      setInventoryTypes(data);
    }
  }, [data]);
  const columns = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
                marginLeft: "-16px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Inventory Type"} />,
      dataIndex: "type",
      key: "type",
      render: text => {
        return <PrimaryTableRowText text={text ? text : ""} />;
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      key: "impressions",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Transactions"} />,
      dataIndex: "transactions",
      key: "transactions",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
  ];

  return (
    <Row>
      <Col span={18}>
        <Card title="Top Inventory Types">
          <Table
            dataSource={inventoryType}
            columns={columns}
            pagination={{
              position: ["none", "bottomCenter"],
              total: inventoryType.length,
              responsive: true,
              showSizeChanger: false,
              showLessItems: true,
              size: "default",
            }}
            rowKey={({ type }) => type}
            scroll={{ x: 200, y: 400 }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card
          title="Breakdown - Top Inventory Types"
          style={{ height: "100%" }}
        >
          <DonutChart
            axisName="type"
            position="transactions"
            chartData={inventoryType}
            showTooltip
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TopInventoryTypes;
