import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Popconfirm, Row, Space, message } from "antd";

import Button from "../../../../shared/globalStyling/Button";

import React, { useState } from "react";
import { TargetingDealIdForm } from "./TargetingDealIdForm";
import { TargetingDealIdsTable } from "./TargetingDealIdsTable";

export const TargetingDealIds = ({
  dealIds,
  createDealId,
  deleteDealIds,
  updateDealId,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedDealIdKeys, setSelectedDealIdKeys] = useState([]);
  const [selectedDealId, setSelectedDealId] = useState(null);

  const toggleForm = val => {
    setIsFormOpen(val);
    setSelectedDealId(null);
  };
  const handleDeleteDealIds = async ({ ids }) => {
    try {
      message.loading("Deleting Deal Id(s)...");

      await deleteDealIds({ ids });
      setSelectedDealIdKeys([]);

      message.destroy();
      message.success("Deal Id(s) deleted successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to delete Deal Id(s)!");
    }
  };

  return (
    <div>
      {isFormOpen && (
        <TargetingDealIdForm
          isFormOpen={isFormOpen}
          selectedDealId={selectedDealId}
          createDealId={createDealId}
          setIsFormOpen={setIsFormOpen}
          updateDealId={updateDealId}
        />
      )}
      <Row gutter={[18, 18]}>
        <Col xs={24} lg={24}>
          <Card
            title="Deal Ids"
            extra={
              <Space>
                <Button
                  onClick={() => toggleForm(true)}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Create
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete these deal ids?"
                  onConfirm={() =>
                    handleDeleteDealIds({ ids: selectedDealIdKeys })
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    disabled={selectedDealIdKeys.length === 0}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Space>
            }
          >
            <Row
              align="right"
              className="ant-row-justify-center ant-row-justify-end"
            >
              <Col span={24}>
                <TargetingDealIdsTable
                  dealIds={dealIds}
                  setSelectedDealIdKeys={setSelectedDealIdKeys}
                  handleDeleteDealIds={handleDeleteDealIds}
                  setSelectedDealId={setSelectedDealId}
                  setIsFormOpen={setIsFormOpen}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
