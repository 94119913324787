import gql from "graphql-tag";

const UPDATE_EVENTS_TARGET = gql`
  mutation updateEventsTarget(
    $id: ID
    $name: String!
    $description: String
    $start: DateTime
    $end: DateTime
  ) {
    updateEventsTarget(
      where: { id: $id }
      data: { name: $name, description: $description, start: $start, end: $end }
    ) {
      id
      name
      description
      start
      end
      eventTags {
        id
        data
      }
      observations
    }
  }
`;

export default UPDATE_EVENTS_TARGET;
