import React, { useState } from "react";
import { cloneDeep, unset } from "lodash";
import {
  computeCPM,
  computeSpend,
  computeCTR,
  computeCPC,
  computeROAS,
  computeConversionRate,
} from "../../../utils/paidSearch/campaigns";

import {
  formatWholeNumber,
  formatNumber,
  formatCurrency2SigFig,
} from "../../../utils/campaigns";

import moment from "moment";

const PaidSearchCampaignController = ({
  paidSearchCampaign,
  orgConfig,
  ...props
}) => {
  const [campaign, setCampaign] = useState(
    paidSearchCampaign.org.paidSearchSummary.paidSearchCampaigns[0] || []
  );
  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);

  const setBySummaryRangeDate = dates => {
    let cloneCampaign = cloneDeep(
      paidSearchCampaign.org.paidSearchSummary.paidSearchCampaigns[0]
    );
    const cByDate = cloneCampaign.data.byDate;
    setControllerbySummaryRangeDate(dates);

    const start = Array.isArray(dates)
      ? dates[0]
      : dates && dates.dates && dates.dates[0]
      ? dates.dates[0]
      : null;
    const end = Array.isArray(dates)
      ? dates[1]
      : dates && dates.dates && dates.dates[1]
      ? dates.dates[1]
      : null;

    const filterByDate = cByDate.filter(obj => {
      const objDate =
        typeof obj.date === "string" ? moment(obj.date).unix() : obj.date;
      const startDate = start ? start.startOf("day").unix() : 0;
      const endDate = end ? end.endOf("day").unix() : Number.MAX_SAFE_INTEGER;

      return objDate >= startDate && objDate <= endDate;
    });

    cloneCampaign.data.byDate = filterByDate;
    if (filterByDate && filterByDate.length > 0) {
      const consolidatedCount = filterByDate.reduce((conso, obj) => ({
        clicks: conso.clicks + obj.clicks,
        impressions: conso.impressions + obj.impressions,
        keywords: conso.keywords + obj.keywords,
        locations: conso.locations + obj.locations,
        conversions: conso.conversions + obj.conversions,
        cost: conso.cost + obj.cost,
        revenue: conso.revenue + obj.revenue,
      }));

      const cpm = computeCPM(
        consolidatedCount.cost,
        consolidatedCount.impressions
      );
      const spend = computeSpend(consolidatedCount.impressions, cpm);
      const ctr = computeCTR(
        consolidatedCount.clicks,
        consolidatedCount.impressions
      );
      const cpc = computeCPC(consolidatedCount.cost, consolidatedCount.clicks);
      const roas = computeROAS(consolidatedCount.revenue, spend);
      const conversionRate = computeConversionRate(
        consolidatedCount.clicks,
        consolidatedCount.conversions
      );

      cloneCampaign.data.clicks = consolidatedCount.clicks;
      cloneCampaign.data.impressions = consolidatedCount.impressions;
      cloneCampaign.data.conversions = consolidatedCount.conversions;
      cloneCampaign.data.keywords = consolidatedCount.keywords;
      cloneCampaign.data.locations = consolidatedCount.locations;
      cloneCampaign.data.cost = consolidatedCount.cost;
      cloneCampaign.data.revenue = consolidatedCount.revenue;
      cloneCampaign.data.cpm = cpm;
      cloneCampaign.data.spend = spend;
      cloneCampaign.data.ctr = ctr;
      cloneCampaign.data.cpc = cpc;
      cloneCampaign.data.roas = roas;
      cloneCampaign.data.conversionRate = conversionRate;
    } else {
      cloneCampaign.data.clicks = 0;
      cloneCampaign.data.impressions = 0;
      cloneCampaign.data.conversions = 0;
      cloneCampaign.data.keywords = 0;
      cloneCampaign.data.locations = 0;
      cloneCampaign.data.cost = 0;
      cloneCampaign.data.revenue = 0;
      cloneCampaign.data.cpm = 0;
      cloneCampaign.data.spend = 0;
      cloneCampaign.data.ctr = 0;
      cloneCampaign.data.cpc = 0;
      cloneCampaign.data.roas = 0;
      cloneCampaign.data.conversionRate = 0;
    }

    setCampaign(cloneCampaign);
  };

  const generateReport = key => {
    let data = [];
    const priceVisibleExclude = ["ROAS", "CPC", "Spend"];

    switch (key) {
      case "byDate":
        data = campaign.data.byDate.map(d => {
          const row = {
            Date: moment(d.xAxis).format("M/D/YYYY"),
            Clicks: formatWholeNumber(d.clicks),
            Impressions: formatWholeNumber(d.impressions),
            Transactions: formatWholeNumber(d.conversions),
            CTR: `${formatNumber(d.ctr)}%`,
            CPC: formatCurrency2SigFig(d.cpc),
            Revenue: formatCurrency2SigFig(d.revenue),
            Spend: formatCurrency2SigFig(d.spend),
            ROAS: `${formatNumber(d.roas)}%`,
          };

          !orgConfig.priceVisible &&
            priceVisibleExclude.forEach(exclude => {
              unset(row, exclude);
            });

          return row;
        });
        data.push({
          Date: `Total`,
          Clicks: formatWholeNumber(campaign.data.clicks),
          Impressions: formatWholeNumber(campaign.data.impressions),
          Transactions: formatWholeNumber(campaign.data.conversions),
          CTR: `${formatNumber(campaign.data.ctr)}%`,
          CPC: formatCurrency2SigFig(campaign.data.cpc),
          Revenue: formatCurrency2SigFig(campaign.data.revenue),
          Spend: formatCurrency2SigFig(campaign.data.spend),
          ROAS: `${formatNumber(campaign.data.roas)}%`,
        });
        break;
      default:
        console.log(`report generation not found`);
    }
    return data;
  };
  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        setCampaign,
        setBySummaryRangeDate,
        generateReport,
        campaign,
        orgConfig,
        bySummaryRangeDate,
        ...props,
      })}
    </React.Fragment>
  );
};

export default PaidSearchCampaignController;
