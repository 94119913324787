import React from "react";
import { Row, Tooltip } from "antd";
import {
  KPITitleText,
  KpiPercentageText,
  KpiSubText,
} from "../../../../shared/globalStyling/styledText";
import { QuestionCircleOutlined } from "@ant-design/icons";

const styles = {
  container: {
    padding: "16px",
    backgroundColor: "white",
  },
  title: {
    marginBottom: "10px",
  },
};

const NewDetailBarCard = ({
  title,
  value,
  tooltip,
  prevValue,
  prevPercentage,
  prevRanges,
}) => {
  return (
    <div style={styles.container} className="new-detail-bar-card">
      <div style={styles.title}>
        <Row justify="space-between" align="top" wrap={true}>
          <KPITitleText text={title} />
          {tooltip && (
            <Tooltip
              title={tooltip}
              arrowPointAtCenter={true}
              placement="rightBottom"
            >
              <QuestionCircleOutlined type="question-circle" />
            </Tooltip>
          )}
        </Row>
      </div>
      <KpiSubText text={value} />
      <KpiPercentageText
        text={prevValue}
        prev={prevPercentage}
        ranges={prevRanges}
      />
    </div>
  );
};

export default NewDetailBarCard;
