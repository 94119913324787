import { DollarCircleOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import React from "react";
import { formatCurrency2SigFig } from "../../../../../core/utils/campaigns";
import { METRIC_CARD_HEIGHT } from "./utils";

const { Text } = Typography;

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    height: `${METRIC_CARD_HEIGHT}px`,
  },
  flexColumnCentered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
  },
};

function BudgetCard({ orgSummary, isLoading, orgConfig }) {
  return (
    <Card loading={isLoading} bordered={false}>
      <div style={styles.flexRow}>
        <DollarCircleOutlined
          style={{
            fontSize: "clamp(1.2rem, 3vw, 56px)",
          }}
        />
        <div style={styles.flexColumnCentered}>
          <Text
            type="secondary"
            style={{ fontSize: "clamp(0.8rem, 1.2vw, 14px)" }}
          >
            Budget Total
          </Text>
          <Text strong style={{ fontSize: "clamp(1rem, 1.8vw, 18px)" }}>
            {formatCurrency2SigFig(orgSummary ? orgSummary.budget : 0)}
          </Text>
        </div>
      </div>
    </Card>
  );
}

export default BudgetCard;
