import gql from "graphql-tag";

const CREATE_AUDIENCE_LOCATION = gql`
  mutation createAudience(
    $name: String
    $type: AudienceType
    $geoTargets: [GeoTargetWhereUniqueInput!]
    $orgid: ID
    $createdBy: ID
  ) {
    createAudience(
      data: {
        name: $name
        type: $type
        geoTargets: { connect: $geoTargets }
        orgs: { connect: { id: $orgid } }
        createdBy: { connect: { id: $createdBy } }
      }
    ) {
      id
      name
      geoTargets {
        id
        name
        location {
          id
          name
          formattedAddress
          street
          state
          city
          zip
          lat
          lng
        }
        polygons {
          geometry
        }
        circles {
          radius
          coordinate {
            lat
            lng
          }
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
          }
        }
      }
      eventsTargets {
        id
      }
      type
      createdAt
      updatedAt
    }
  }
`;

export default CREATE_AUDIENCE_LOCATION;
