import gql from "graphql-tag";

const UPDATE_MANY_CREATIVES = gql`
  mutation updateManyCreatives(
    $data: CreativeUpdateManyMutationInput!
    $where: CreativeWhereInput!
  ) {
    updateManyCreatives(where: $where, data: $data) {
      count
    }
  }
`;

export default UPDATE_MANY_CREATIVES;
