import gql from "graphql-tag";

const GET_HASHED_EMAILS = gql`
  query hashedEmailAddresses($orgs: OrgWhereInput) {
    hashedEmailAddresses(
      first: 1000
      orderBy: updatedAt_DESC
      where: { orgs_some: $orgs }
    ) {
      id
      email
      createdAt
    }
  }
`;

export default GET_HASHED_EMAILS;
