import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message } from "antd";
import moment from "moment";
import compose from "lodash/flowRight";
import Controller from "./Controller";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import GET_ORG_SUMMARY from "../../GraphQl/Queries/GET_ORG_SUMMARY";
import REFRESH_ORG_SUMMARY from "../../GraphQl/Mutations/REFRESH_ORG_SUMMARY";

import OverlaySpinner from "../../../platform/shared/OverlaySpinner";

const Loader = props => {
  const loggedInRole = props.currentUser.role;
  const orgId = loggedInRole.org.id;

  const [isLoading, setIsLoading] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [poll, setPoll] = useState(0);

  const { loading, error, data } = useQuery(GET_ORG_SUMMARY, {
    variables: { loggedInOrg: orgId },
    pollInterval: poll, // workaround until subscription works
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (!data.org) return;
      if (!data.org.orgSummary) return;
      if (!data.org.orgSummary.updatedAt) return;
      const updatedAt = data.org.orgSummary.updatedAt;
      if (moment(updatedAt).isAfter(moment(lastRefresh))) {
        setIsLoading(false);
        message.success("Refresh Done.");
        setPoll(0);
      } else if (lastRefresh !== null) {
        // stop poll after 5000ms polls (5 seconds)
        if (poll >= 20000) {
          message.warning("Unable to get latest data.");
          setIsLoading(false);
          setPoll(0);
        } else {
          setPoll(poll + 1500); // increase polling time to avoid network overload
        }
      }
    },
  });

  const [onRefreshOrgSummary] = useMutation(REFRESH_ORG_SUMMARY, {
    variables: {
      orgID: orgId,
    },
    onCompleted: () => {
      setLastRefresh(moment());
      setPoll(1000);
    },
  });

  if (loading && poll === 0) {
    return <OverlaySpinner />;
  }

  return (
    <Controller
      errorOrgSummary={error}
      onRefreshOrgSummary={onRefreshOrgSummary}
      orgSummary={data && data.org ? data.org.orgSummary : null}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      loggedInRole={loggedInRole}
      {...props}
    >
      {props.children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(withApollo(Loader)));
