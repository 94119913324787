import React from "react";
import { graphql, useMutation } from "react-apollo";
import compose from "lodash/flowRight";
import { Query } from "react-apollo";
import { isEmpty } from "lodash";

// Locals core
import LIST_USERS from "../../.././GraphQl/Queries/LIST_USERS_ADMIN";
import CREATE_USER from "../../../GraphQl/Mutations/CREATE_USER";
import EDIT_USER from "../../../GraphQl/Mutations/EDIT_USER";
import DISABLE_USER from "../../../GraphQl/Mutations/DISABLE_USER";
import ENABLE_USER from "../../../GraphQl/Mutations/ENABLE_USER";
import DELETE_USER from "../../../GraphQl/Mutations/DELETE_USER";
import GET_USER from "../../../GraphQl/Mutations/GET_USER";
import RESENT_TEMPORARY_PASSWORD from "../../../GraphQl/Mutations/RESENT_TEMPORARY_PASSWORD";
import RESET_PASSWORD from "../../../GraphQl/Mutations/RESET_PASSWORD";
import MOVE_USER_TO_OTHER_ORG from "../../../GraphQl/Mutations/MOVE_USER_TO_OTHER_ORG";
import CREATE_USER_CONFIG from "../../../GraphQl/Mutations/CREATE_USER_CONFIG";
import UPDATE_USER_EMBEDDABLE_CONFIG from "../../../GraphQl/Mutations/UPDATE_USER_EMBEDDABLE_CONFIG";

// Local
import UsersController from "./UsersController";
import OverlaySpinner from "../../../.././platform/shared/OverlaySpinner";

// Constant
import { errorType } from "../../../utils/constants/constants";

const UsersLoader = props => {
  const createUser = async data => {
    const { username, name, phone, email, avatar, features, config } = data;
    // TODO: Needs refactor to accomodate multiple roles

    const roleItems = Object.entries(features).map(([feature, permission]) => {
      const record = {
        feature: feature,
        actions: { set: permission },
      };

      return record;
    });

    // Adds ENABLED feature with READ and WRITE
    roleItems.push({
      feature: "ENABLED",
      actions: { set: ["READ", "WRITE"] },
    });
    try {
      const response = await props.createDashboardUser({
        variables: {
          username,
          name,
          phone: phone,
          loggedInOrg: props.selectedOrg.id,
          email,
          avatar: isEmpty(avatar) ? null : avatar.id,
          roleItems,
          config,
        },
        refetchQueries: [
          {
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          },
        ],
      });

      if (response) {
        return {
          status: "success",
          error: null,
        };
      }

      return response;
    } catch (err) {
      const { usernameDuplication } = errorType;
      if (err.message === usernameDuplication) {
        return {
          status: "failure",
          error: usernameDuplication,
        };
      } else {
        return {
          status: "failure",
          error: err,
        };
      }
    }
  };

  const disableUser = async data => {
    // TODO: currently grabs first role a user has - needs refactor to accomodate multiple roles
    const roleId = data.roles[0].id;

    try {
      const response = await props.disableDashboardUser({
        variables: {
          id: data.id,
          roleId,
        },
        update: (store, { data: { disableDashboardUser } }) => {
          const data = store.readQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          });
          const indexToUpdate = data.users.findIndex(
            user => user.id === disableDashboardUser.id
          );
          data.users.splice(indexToUpdate, 1, disableDashboardUser);
          store.writeQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
            data,
          });
        },
      });

      if (response) {
        return { status: "success", error: null };
      }
    } catch (err) {
      return { status: "failure", error: err };
    }
  };

  const enableUser = async data => {
    // TODO: currently grabs first role a user has - needs refactor to accomodate multiple roles
    try {
      const response = await props.enableDashboardUser({
        variables: {
          id: data.id,
        },
        update: (store, { data: { enableDashboardUser } }) => {
          const data = store.readQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          });
          const indexToUpdate = data.users.findIndex(
            user => user.id === enableDashboardUser.id
          );
          data.users.splice(indexToUpdate, 1, enableDashboardUser);
          store.writeQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
            data,
          });
        },
      });

      if (response) {
        return { status: "success", error: null };
      }
    } catch (err) {
      return { status: "failure", error: err };
    }
  };

  const deleteUser = async data => {
    // TODO: currently grabs first role a user has - needs refactor to accomodate multiple roles
    const roleId = data.roles[0].id;

    try {
      const response = await props.deleteDashboardUser({
        variables: {
          id: data.id,
          roleId,
        },
        update: (store, { data: { deleteDashboardUser } }) => {
          const data = store.readQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          });
          data.users = data.users.filter(
            user => user.id !== deleteDashboardUser.id
          );
          store.writeQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
            data,
          });
        },
      });

      if (response) {
        return { status: "success", error: null };
      }
    } catch (err) {
      return { status: "failure", error: err };
    }
  };

  const updateUser = async data => {
    const {
      username,
      phone,
      email,
      id,
      features,
      roles,
      name,
      avatar,
      config,
      ssoToken,
    } = data;
    // TODO: currently grabs first role a user has - needs refactor to accomodate multiple roles

    const roleId = roles[0].id;

    const roleitems = Object.entries(features).map(([feature, permission]) => {
      const record = {
        feature: feature,
        actions: { set: permission },
      };

      return record;
    });

    const updatedConfig = config;
    delete updatedConfig.__typename;
    delete updatedConfig.id;
    delete updatedConfig.embeddableConfig;

    try {
      const response = await props.editDashboardUser({
        variables: {
          id,
          username,
          phone: phone,
          email,
          orgId: props.selectedOrg.id,
          roleId,
          roleitems,
          avatar: isEmpty(avatar) ? null : avatar.id,
          name,
          config: updatedConfig,
          ssoToken,
        },
        update: (store, { data: { editDashboardUser } }) => {
          const data = store.readQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          });

          const indexToUpdate = data.users.findIndex(
            user => user.id === editDashboardUser.id
          );

          data.users.splice(indexToUpdate, 1, editDashboardUser);
          store.writeQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
            data,
          });
        },
      });

      if (response) {
        return {
          status: "success",
          error: null,
        };
      }

      return response;
    } catch (err) {
      return {
        status: "failure",
        error: err,
      };
    }
  };

  const getUser = async data => {
    try {
      const response = await props.getDashboardUser({
        variables: { username: data.username },
      });
      const status = response.data.getDashboardUser.UserStatus;

      return status;
    } catch (err) {
      return null;
    }
  };

  const resentTemporaryPassword = async username => {
    try {
      const response = await props.resendTempPasswordDashboardUser({
        variables: { username: username },
      });

      return response.data.resendTempPasswordDashboardUser;
    } catch (err) {
      return null;
    }
  };

  const resetPassword = async ({ username, permanent }) => {
    try {
      const response = await props.setPasswordDashboardUser({
        variables: { username, permanent },
      });

      return response.data.setPasswordDashboardUser;
    } catch (err) {
      return null;
    }
  };

  const moveUser = async (orgId, roleId, username, userId) => {
    try {
      const response = await props.moveDashboardUser({
        variables: {
          orgId: orgId,
          roleId: roleId,
          username: username,
          userId: userId,
        },
        update: (store, { data: { moveDashboardUser } }) => {
          const data = store.readQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
          });
          data.users = data.users.filter(
            user => user.id !== moveDashboardUser.id
          );
          store.writeQuery({
            query: LIST_USERS,
            variables: {
              loggedInOrg: props.selectedOrg.id,
            },
            data,
          });
        },
      });

      return response.data.moveDashboardUser;
    } catch (err) {
      return null;
    }
  };

  const [createUserConfig] = useMutation(CREATE_USER_CONFIG, {
    variables: {
      data: props.data,
    },
  });

  const [updateUserEmbeddableConfig] = useMutation(
    UPDATE_USER_EMBEDDABLE_CONFIG,
    {
      variables: {
        id: props.id,
        data: props.data,
      },
    }
  );

  return (
    <Query query={LIST_USERS} variables={{ loggedInOrg: props.selectedOrg.id }}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div style={{ height: "300px" }}>
              <OverlaySpinner />;
            </div>
          );
        if (error) return `Error (Query: LIST_USERS)! ${error.message}`;
        return (
          <UsersController
            users={data.users.map((user, i) => {
              const record = { ...user, key: i };
              return record;
            })}
            createUser={createUser}
            updateUser={updateUser}
            disableUser={disableUser}
            enableUser={enableUser}
            deleteUser={deleteUser}
            selectedOrg={props.selectedOrg}
            currentRole={props.currentRole}
            currentuser={props.currentuser}
            getUser={getUser}
            resentTemporaryPassword={resentTemporaryPassword}
            resetPassword={resetPassword}
            moveUser={moveUser}
            orgs={props.organizations}
            createUserConfig={createUserConfig}
            updateUserEmbeddableConfig={updateUserEmbeddableConfig}
          />
        );
      }}
    </Query>
  );
};

export default compose(
  graphql(CREATE_USER, { name: "createDashboardUser" }),
  graphql(EDIT_USER, { name: "editDashboardUser" }),
  graphql(ENABLE_USER, { name: "enableDashboardUser" }),
  graphql(DISABLE_USER, { name: "disableDashboardUser" }),
  graphql(DELETE_USER, { name: "deleteDashboardUser" }),
  graphql(GET_USER, { name: "getDashboardUser" }),
  graphql(RESENT_TEMPORARY_PASSWORD, {
    name: "resendTempPasswordDashboardUser",
  }),
  graphql(RESET_PASSWORD, { name: "setPasswordDashboardUser" }),
  graphql(MOVE_USER_TO_OTHER_ORG, { name: "moveDashboardUser" })
)(UsersLoader);
