import React from "react";
import { Tabs } from "antd";
import { TabText } from "../../../../shared/globalStyling/styledText";
import SeoSummaryDetailsLoader from "../../../../../core/components/seoRefactored/seoDetails/tabs/SeoSummaryDetailsLoader";

const { TabPane } = Tabs;

//Component unused until multi channel is implemented again
const ChannelTabs = ({
  isMultiChannel = false, // Currently not used until multi channel is implemented again
  hasGoogle = false,
  hasCommerce = false, // Unused until multi channel is implemented again
  googleReportKey,
  commerceKey,
  setReportData, // Set Report Data on each tab change
  domain,
}) => {
  if (isMultiChannel) {
    const tabs = [];

    if (hasGoogle) {
      tabs.push({
        title: "Google",
        key: googleReportKey,
        content: (
          <SeoSummaryDetailsLoader
            reportKey={googleReportKey}
            setReportData={setReportData}
            domain={domain}
          />
        ),
      });
    }
    if (hasCommerce) {
      tabs.push({
        title: "Commerce",
        key: commerceKey,
        content: (
          <SeoSummaryDetailsLoader
            reportKey={commerceKey}
            setReportData={setReportData}
            domain={domain}
          />
        ),
      });
    }

    return (
      <Tabs>
        {tabs.map(tab => (
          <TabPane tab={<TabText text={tab.title} key={tab.key} />}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    );
  }

  //Show only the Summary View with no tab if not multi channel
  return (
    <SeoSummaryDetailsLoader
      reportKey={googleReportKey}
      setReportData={setReportData}
      domain={domain}
    />
  );
};

export default ChannelTabs;
