import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import React, { useEffect } from "react";

export const TargetingDealIdForm = ({
  isFormOpen,
  setIsFormOpen,
  createDealId,
  selectedDealId,
  updateDealId,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedDealId) {
      form.setFieldsValue({
        name: selectedDealId.name,
        dealId: selectedDealId.dealId,
      });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDealId]);

  const handleSubmit = async () => {
    const isFormValid = await checkFormValidity();
    if (!isFormValid) return;

    const values = form.getFieldsValue();
    if (!selectedDealId) {
      handleCreate(values);
    } else {
      handleUpdate(values);
    }
    form.resetFields();
  };

  const handleCreate = async values => {
    try {
      message.loading("Creating Deal Id...", 0);
      setIsFormOpen(false);
      form.resetFields();
      await createDealId(values);
      message.destroy();
      message.success("Deal Id created successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to create Deal Id!");
    }
  };

  const handleUpdate = async values => {
    try {
      message.loading("Updating Deal Id...", 0);
      setIsFormOpen(false);
      form.resetFields();
      await updateDealId({
        id: selectedDealId.id,
        name: values.name,
        dealId: values.dealId,
      });
      message.destroy();
      message.success("Deal Id updated successfully!");
    } catch (error) {
      message.destroy();
      message.error("Failed to update Deal Id!");
    }
  };

  const handleCancel = () => {
    setIsFormOpen(false);
    form.resetFields();
  };

  const checkFormValidity = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Modal
        visible={isFormOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="modalForm"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>,
        ]}
      >
        <Row gutter={18} justify="center">
          <Col span={24}>
            <Form
              name="form-name"
              form={form}
              layout="vertical"
              onFinish={() => handleSubmit()}
              autoComplete="off"
              validateTrigger="onBlur"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                  {
                    max: 100,
                    message: "Name cannot be greater than 100 characters!",
                  },
                ]}
              >
                <Input name="name" autoComplete="no" />
              </Form.Item>
              <Form.Item
                label="Deal Id"
                name="dealId"
                rules={[
                  {
                    required: true,
                    message: "Deal id is required!",
                  },
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message:
                      "Deal id must be alphanumeric and cannot contain spaces or special characters!",
                  },
                  {
                    max: 100,
                    message: "Deal id cannot be greater than 100 characters!",
                  },
                ]}
              >
                <Input name="dealId" autoComplete="no" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
