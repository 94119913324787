import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { Row, Col, Popconfirm, Modal, Card } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  GeoTargetsBaseTable,
  SegmentsFilterBar,
  SegmentsForm,
} from "../Segments/components";
import MapDrawing from "../../shared/maps/MapDrawing";
import Button from "../../shared/globalStyling/Button";
import {
  parseOverlayToGeoTarget,
  flattenGeoTargets,
  isEqualGeometry,
} from "../../../core/utils/geoTargets";
import { determineUserAccess } from "../../../core/permissions/userLevelAccess";

const styles = {
  mapCard: {
    border: "0.8px solid #e8e8e8",
    backgroundColor: "#FAFAFA",
    minHeight: "400px",
    marginRight: "8px",
    marginBottom: "8px",
  },
  switchLayout: {
    flex: 1,
    bottomBorder: 0,
    padding: 10,
  },
  switch: {
    backgroundColor: "#1890ff",
    fontWeight: "bold",
  },
  card: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

const SegmentsGeoTargetView = props => {
  const {
    selectedGeoTargets,
    inProgressOverlay,
    history,
    deleteGeoTargets,
    match,
    selectedRowKeys,
    selectTableRow,
    WRITE,
    checkSwitchMode,
    changeValue,
    updateSelectedRowStartEndDate,
    changeLocationMap,
  } = props;

  let geoTargets = props.geoTargets;
  const getPermission = props.currentUser && props.currentUser.permission;

  const orgConfig = determineUserAccess(getPermission);
  const { isDemo } = orgConfig;

  if (isDemo) {
    geoTargets.forEach((c, i) => {
      c.name = `Example Geo-Target ${i}`;
    });
  }

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await setIsModalVisible(false);
    return props.submitForm();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    changeValue("activeKeyTab", "geotargets");
    changeValue("checkTabSegment", "geotargets");
    changeValue("checkSwitchMode", true);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Modal
        title={
          selectedGeoTargets.length === 1
            ? "Update Geo-Target"
            : "Create Geo-Target"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90%"}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <div style={styles.mapCard}>
              <MapDrawing
                zoom={5}
                shapes={selectedGeoTargets}
                overlayChanged={val => {
                  props.changeValue("inProgressOverlay", val);
                }}
                shouldClearShapes={props.shouldClearShapes}
                clearedCallback={props.toggleShouldClearShapes}
                toolOptions={{
                  fillColor: "#BCDCF9",
                  fillOpacity: 0.5,
                  strokeWeight: 3,
                  strokeColor: "#57ACF9",
                  clickable: true,
                  editable: true,
                  zIndex: 1,
                }}
                drawing={WRITE}
                changeLocation={place => {
                  changeLocationMap(place);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              style={styles.card}
              title={
                selectedGeoTargets.length === 1
                  ? "Update Geo-Target"
                  : "Create Geo-Target"
              }
            >
              <SegmentsForm
                submit={handleOk}
                name={props.name}
                startDate={props.startDate}
                endDate={props.endDate}
                changeValue={props.changeValue}
                description={props.description}
                length={props.selectedGeoTargets.length}
                {...props}
                hasInProgressShape={!isEmpty(props.inProgressOverlay)}
                checkSwitchMode={props.checkSwitchMode}
                checkTabSegment={props.checkTabSegment}
                buttonText={
                  selectedGeoTargets.length === 1
                    ? "Update Segment"
                    : "Create Geo-Target"
                }
              />
            </Card>
          </Col>
        </Row>
      </Modal>
      <PageHeaderLayout
        title={"Segments - Geo-Targets"}
        titleIcon={
          <DeleteOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <Row
          style={{
            marginTop: "8px",
          }}
        >
          <Col span={24}>
            <PageHeaderLayout
              rightContent={
                WRITE &&
                !isDemo && (
                  <>
                    <Popconfirm
                      placement="topLeft"
                      title={`Are you sure you want to delete ${
                        props.selectedGeoTargets.length
                      } selected segment${
                        props.selectedGeoTargets.length > 1 ? "s" : ""
                      }?`}
                      onConfirm={deleteGeoTargets}
                      okText="Yes"
                      disabled={!props.selectedGeoTargets.length}
                      cancelText="No"
                    >
                      <Button
                        shape="round"
                        icon={<DeleteOutlined />}
                        style={{ marginRight: 10 }}
                        disabled={!props.selectedGeoTargets.length}
                      >
                        Remove
                      </Button>
                    </Popconfirm>
                    {props.selectedGeoTargets.length === 1 ? (
                      <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        style={{ marginRight: 10, width: "auto" }}
                        onClick={showModal}
                      >
                        Update GeoTarget
                      </Button>
                    ) : (
                      <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        style={{ marginRight: 10, width: "auto" }}
                        onClick={showModal}
                      >
                        Create Geo-Target
                      </Button>
                    )}
                  </>
                )
              }
              title={"Geo-Target Segments"}
            />
            <SegmentsFilterBar history={history} match={match} />
            <GeoTargetsBaseTable
              selectedRowKeys={selectedRowKeys}
              selectTableRow={selectTableRow}
              updateSelectedRowStartEndDate={updateSelectedRowStartEndDate}
              hasInProgressShape={
                checkSwitchMode === true &&
                selectedGeoTargets.length < 2 &&
                !isEqualGeometry(
                  parseOverlayToGeoTarget(inProgressOverlay, true),
                  flattenGeoTargets(selectedGeoTargets)
                )
              }
              {...props}
              geoTargets={geoTargets}
              isDemo={isDemo}
            />
          </Col>
        </Row>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default SegmentsGeoTargetView;
