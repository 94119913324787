import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

const CampaignDetailsCreativeController = ({
  children,
  creativeDataReport,
  currentUser,
  ...props
}) => {
  const config = currentUser.role.org.config;
  if (config && config.isDemo) {
    creativeDataReport.forEach((c, i) => {
      c.value = `Example Creative 320 X 480 -${i}`;
    });
  }

  const [data, setData] = useState(creativeDataReport);
  const [selectedCreativeDimension, setCreativeDimension] = useState(
    "impressions"
  );

  useEffect(() => {
    if (!isEmpty(creativeDataReport)) {
      setData(creativeDataReport);
    }
  }, [creativeDataReport]);

  return React.cloneElement(children, {
    creativeData: data,
    creativeDataReport,
    selectedCreativeDimension,
    setCreativeDimension,
    ...props,
  });
};

export default CampaignDetailsCreativeController;
