import React from "react";
import { Layout } from "antd";

// Local imports
import { BugTwoTone, BulbTwoTone } from "@ant-design/icons";

const { Footer } = Layout;
const style = {
  footer: {
    textAlign: "center",
    backgroundColor: "white",
  },

  spanStyle: {
    marginLeft: 20,
    marginRight: 20,
  },

  icon: { marginRight: 10 },
};

const { REACT_APP_BUG_REPORT, REACT_APP_FEATURE_REQUEST } = process.env;

class FooterLayout extends React.PureComponent {
  render() {
    return (
      <Footer style={style.footer}>
        <a
          href={REACT_APP_BUG_REPORT}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={style.spanStyle}>
            <BugTwoTone style={style.icon} />
            Report a Bug
          </span>
        </a>
        <a
          href={REACT_APP_FEATURE_REQUEST}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={style.spanStyle}>
            <BulbTwoTone style={style.icon} />
            Feature Request
          </span>
        </a>
      </Footer>
    );
  }
}

export default FooterLayout;
