import gql from "graphql-tag";
const UPDATE_CAMPAIGN_ORDER_TO_PARENT_ORGS = gql`
  mutation updateCampaignOrder($id: ID!, $orgId: ID!) {
    updateCampaignOrder(
      data: { orgs: { connect: { id: $orgId } } }
      where: { id: $id }
    ) {
      id
      name
      orgs {
        id
        name
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_ORDER_TO_PARENT_ORGS;
