import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Progress, Tooltip } from "antd";
import PaginationTable from "../../../shared/PaginationTable";
import moment from "moment";
import { isEmpty, isNil } from "lodash";

import {
  formatWholeNumber,
  isDemoAudience,
} from "../../../../core/utils/campaigns";

// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../shared/globalStyling/styledText";
const Frag = React.Fragment;

const AudienceTable = ({
  audiences,
  count,
  onPage,
  onRowChange,
  history,
  selectedRowKeys,
  location,
  currentUser,
  refetch,
}) => {
  const { isDemo } = currentUser;
  const params = new URLSearchParams(location.search);
  const getSortOrder = key => {
    const sortParams = params.get("sort");
    const splitSort = sortParams && sortParams.split("_");

    return sortParams && splitSort[0] === key
      ? splitSort[1] === "DESC"
        ? "descend"
        : "ascend"
      : false;
  };

  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "20%",
      render: (text, record) => {
        if (record.type === "RETARGETING") {
          const { eventsTargets } = record;
          const tags = [];
          if (eventsTargets.length > 0) {
            for (const eventsTarget of eventsTargets) {
              //Only look for friendly name if it is a self service tag
              if (!isEmpty(eventsTarget.tag)) {
                const { eventTags, name } = eventsTarget;
                if (eventTags.length > 0) {
                  tags.push({ appId: eventTags[0].appId[0], name });
                }
              }
            }
          }
          const tagName = tags.find(t => t.appId === text);
          if (tagName) text = tagName.name;
        }
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </Frag>
        );
      },
      sorter: true,
      sortOrder: getSortOrder("name"),
      footerContent: "Totals + Averages",
    },
    {
      title: <TableColumnTitle text={"Type"} />,
      dataIndex: "type",
      key: "type",
      align: "left",
      width: "20%",
      render: (text, record) => {
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={isEmpty(record.type) ? null : record.type}
                />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "startDate",
      key: "daterange",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.startDate);
        const endDate = moment(record.endDate);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start">
              {record.startDate && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.endDate && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => {
        if (!text) {
          return "";
        }
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => {
        const dateA = a.createdAt ? moment(a.createdAt).unix() : 0;
        const dateB = b.createdAt ? moment(b.createdAt).unix() : 0;
        return dateA - dateB;
      },
      sortOrder: getSortOrder("createdAt"),
    },
    {
      title: "Devices",
      dataIndex: "deviceIdCount",
      key: "deviceIdCount",
      width: "10%",
      render: (deviceIdCount, row) => {
        //uses segments as truth source - duplicates between segments possible
        //Could in future use top level deviceIdCount - meant to be de duplicated aggregate
        let count = 0;
        if (row.geoTargets && !isEmpty(row.geoTargets)) {
          const { geoTargets } = row;
          geoTargets.forEach(target => {
            const { uniqueDevices } = target;
            if (uniqueDevices) {
              count = count + uniqueDevices;
            }
          });
        }
        if (row.eventsTargets && !isEmpty(row.eventsTargets)) {
          const { eventsTargets } = row;
          eventsTargets.forEach(target => {
            const { uniqueDevices } = target;
            if (uniqueDevices) {
              count = count + uniqueDevices;
            }
          });
        }
        return (
          <PrimaryTableRowText
            text={
              <Tooltip
                title={
                  "Hardware devices - matched directly to first party data"
                }
              >
                {formatWholeNumber(count)}
              </Tooltip>
            }
          />
        );
      },
    },
    {
      title: "Expanded Devices",
      dataIndex: "expandedDeviceIdCount",
      key: "expandedDeviceIdCount",
      width: "10%",
      render: (text, row) => {
        //uses segments as truth source - duplicates between segments possible
        //Could in future use top level deviceIdCount - meant to be de duplicated aggregate
        let count = 0;
        if (row.geoTargets && !isEmpty(row.geoTargets)) {
          const { geoTargets } = row;
          geoTargets.forEach(target => {
            const { expandedReach } = target;
            if (expandedReach) {
              count = count + expandedReach;
            }
          });
        }
        if (row.eventsTargets && !isEmpty(row.eventsTargets)) {
          const { eventsTargets } = row;
          eventsTargets.forEach(target => {
            const { expandedReach } = target;
            if (expandedReach) {
              count = count + expandedReach;
            }
          });
        }
        return (
          <PrimaryTableRowText
            text={
              <Tooltip
                title={
                  "Hardware devices - cross device extension from identity graph signal matches"
                }
              >
                {formatWholeNumber(count)}
              </Tooltip>
            }
          />
        );
      },
    },
  ];

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys,
    columnWidth: "5%",
  };

  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;

  if (isDemo && audiences) {
    audiences = isDemoAudience(audiences);
  }

  useEffect(() => {
    const fromCreateForm = localStorage.getItem("fromCreateForm");
    if (fromCreateForm) {
      // Refetch all Audience
      refetch();
      // Clear value to prevent issues with back button
      localStorage.removeItem("fromCreateForm");
    }
  }, [refetch]);

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        data={audiences}
        rowSelection={rowSelection}
        columns={columns}
        scroll={{ x: 700 }}
        pageSize={pageSize}
        onRow={data => {
          return {
            onClick: () => {
              history.push(`/audiences/details/${data.id}`);
            },
          };
        }}
        onChange={(paginationObject, filtersObject, sorterObject, extra) => {
          const currentParams = new URLSearchParams(window.location.search);
          const newParams = new URLSearchParams();

          if (!isEmpty(paginationObject)) {
            newParams.append("pageSize", paginationObject.pageSize);
          }

          if (!isEmpty(sorterObject.column)) {
            newParams.append(
              "sort",
              `${sorterObject.field}_${
                isNil(sorterObject.order) || sorterObject.order === "ascend"
                  ? "ASC"
                  : "DESC"
              }`
            );
          }

          if (currentParams.get("type")) {
            newParams.append("type", currentParams.get("type"));
          }

          if (currentParams.get("searchTerm")) {
            newParams.append("searchTerm", currentParams.get("searchTerm"));
          }

          history.push(
            `/audiences/new/${paginationObject.current}?${newParams.toString()}`
          );
        }}
        total={count}
        current={onPage}
        bordered={false}
      />
    </div>
  );
};

export default withRouter(AudienceTable);
