import React from "react";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
// Queries
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import GET_BILLING_INVOICES from "../../GraphQl/Queries/GET_BILLING_INVOICES";
import GET_BILLING_DETAILS from "../../GraphQl/Queries/GET_BILLING_DETAILS";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";

// Locals
import Controller from "./Controller";

const Loader = props => {
  const { children, currentUser, match } = props;
  const currentOrgId = currentUser.role.org.id;
  const { params } = match;

  const { id } = params;

  const where = {
    org: {
      id: currentOrgId.toString(),
    },
    ...(id && { id: id }),
  };

  const { data: invoiceList, loading: invoiceLoading } = useQuery(
    GET_BILLING_INVOICES,
    {
      variables: {
        where: where,
      },
    }
  );

  const { data: billingDetails, loading: billingDetailsLoading } = useQuery(
    GET_BILLING_DETAILS,
    {
      variables: {
        orgId: currentOrgId.toString(),
      },
    }
  );

  if (invoiceLoading || billingDetailsLoading) return <OverlaySpinner />;

  return (
    <Controller
      {...props}
      currentuser={props.currentUser}
      invoiceList={invoiceList}
      billingDetails={billingDetails}
    >
      {children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(Loader));
