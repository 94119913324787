import gql from "graphql-tag";
const UPDATE_USER = gql`
  mutation updateUser(
    $isAuthenticated: Boolean!
    $id: ID
    $username: String!
    $email: String!
    $cognitoUserId: String!
    $avatar: String
    $features: [String!]!
    $notifications: [Object!]!
    $defaultRole: JSON!
    $role: JSON!
    $isAdmin: Boolean
    $isDemo: Boolean
    $isPreviewOrg: Boolean
    $permission: JSON!
    $msaStatus: MSAVersion
    $ssoToken: String
    $articles: Boolean
    $campaignTableDisplayColumns: [String!]
  ) {
    updateUser(
      isAuthenticated: $isAuthenticated
      id: $id
      username: $username
      email: $email
      cognitoUserId: $cognitoUserId
      avatar: $avatar
      features: $features
      notifications: $notifications
      defaultRole: $defaultRole
      role: $role
      isAdmin: $isAdmin
      isDemo: $isDemo
      isPreviewOrg: $isPreviewOrg
      permission: $permission
      msaStatus: $msaStatus
      ssoToken: $ssoToken
      articles: $articles
      campaignTableDisplayColumns: $campaignTableDisplayColumns
    ) @client
  }
`;

export default UPDATE_USER;
