/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  ConfigProvider,
  Button,
  Tooltip,
  Modal,
  Input,
  Space,
  Typography,
  notification,
  Spin,
} from "antd";
import S3Image from "../components/S3Image";
import { Storage } from "aws-amplify";
import moment from "moment";

import BannerUploader from "./BannerUploader";
import {
  CheckCircleTwoTone,
  CloudUploadOutlined,
  DeleteOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

const Frag = React.Fragment;

const ModalNotes = ({
  isModalOpen,
  isModal2Open,
  setIsModalOpen,
  setIsModal2Open,
  addCreativeNotes,
  mediaSelected,
  mediaNoteView,
  setMediaNoteView,
}) => {
  const { TextArea } = Input;

  return (
    <>
      <Modal
        title="Add Notes"
        visible={isModalOpen}
        onOk={() => {
          addCreativeNotes({
            variables: {
              id: mediaSelected,
              mediaNotes: mediaNoteView,
              mediaHasNotes: true,
            },
          });
          setIsModalOpen(false);
          notification.open({
            message: "Creative remark submitted",
            description: "Success!",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <TextArea
          rows={4}
          placeholder="Add Notes"
          defaultValue={mediaNoteView}
          value={mediaNoteView}
          onChange={val => {
            setMediaNoteView(val.target.value);
          }}
        />
      </Modal>
      <Modal
        title="View Notes"
        visible={isModal2Open}
        onOk={() => {
          setIsModal2Open(false);
        }}
        onCancel={() => setIsModal2Open(false)}
        footer={[
          <Button
            onClick={() => {
              setIsModal2Open(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <Typography>{mediaNoteView}</Typography>
      </Modal>
    </>
  );
};

const VideoPreviewCell = ({ videoUrl }) => {
  return (
    <div
      style={{
        width: "100px", // Adjust the width as needed
        height: "75px", // Adjust the height as needed
      }}
    >
      <video
        src={videoUrl}
        type="video/mp4"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        muted
        autoPlay
        loop
      />
    </div>
  );
};

const BannersUploadTable = ({
  onChangeArrayOfObj,
  adUnit,
  index,
  isReview,
  isAdmin,
  addCreativeNotes,
  status,
  isTrafficker,
  handlePlayPauseCreatives,
  isShowRowSelection,
  handleRemoveCreativeById,
  pendingDeleteCreativeId,
  setAdUnitBannerIds,
  tableKey,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [mediaSelected, setMediaSelected] = useState("");
  const [mediaNoteView, setMediaNoteView] = useState("");

  const [medias, setMedias] = useState(adUnit ? adUnit.banners : []);

  const rowSelection = {
    setAdUnitBannerIds,
    onChange: (selectedRowKeys, selectedRows) => {
      const bannerIds = selectedRows.map(row => {
        return row.id;
      });
      setAdUnitBannerIds(bannerIds);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  // The purpose of this is it renews the token of the video urls
  const processImages = async image => {
    const key = image.key || image.media.key;

    return Storage.get(key, { expires: 600000 });
  };

  const columns = [
    {
      title: "",
      dataIndex: "preview",
      key: "preview",
      render: (_, record) => {
        if (!record) {
          return (
            <>
              <Spin />
            </>
          );
        } else if (record.fileType === "MP4") {
          return <VideoPreviewCell videoUrl={record.videoUrl} />;
        } else if (
          record &&
          record.name &&
          record.name.includes("creative_url_")
        ) {
          return (
            <img src={record.imgKey} alt="preview" height="40" width="40" />
          );
        } else {
          return <S3Image imgKey={record.imgKey} />;
        }
      },
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ASPECT",
      dataIndex: "aspect",
      key: "aspect",
    },
    {
      title: "DATE ADDED",
      dataIndex: "dateAdded",
      key: "dateAdded",
    },
    {
      title: "FILE SIZE",
      dataIndex: "fileSize",
      key: "fileSize",
      render: text => {
        const kB = Math.round(text / 1000);
        return <div>{kB}KB</div>;
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status, record, index) => {
        if (!record.adId && !["LIVE", "LIVE_APPROVED"].includes(status)) {
          return (
            <>
              <Tooltip title="Delete Banner">
                <Button
                  shape="round"
                  icon={<DeleteOutlined />}
                  disabled={pendingDeleteCreativeId === record.id}
                  onClick={() => {
                    handleRemoveCreativeById({
                      creativeId: record.id,
                      adUnitId: adUnit.id,
                    });
                  }}
                ></Button>
              </Tooltip>
            </>
          );
        }

        switch (status) {
          case "PAUSED":
            return (
              <Tooltip title="Start Ad">
                <Button
                  icon={<PauseOutlined />}
                  shape="round"
                  onClick={() =>
                    handlePlayPauseCreatives({
                      creative: record,
                      status: "PENDING_UPDATE",
                    })
                  }
                ></Button>
              </Tooltip>
            );

          default:
            return (
              <Tooltip title="Pause Ad">
                <Button
                  type="primary"
                  icon={<PlayCircleOutlined />}
                  shape="round"
                  onClick={() =>
                    handlePlayPauseCreatives({
                      creative: record,
                      status: "PAUSED",
                    })
                  }
                ></Button>
              </Tooltip>
            );
        }
      },
    },
  ];

  if (["PENDING", "LIVE_PENDING"].includes(status)) {
    const optionColumn = {
      title: "OPTION",
      dataIndex: "option",
      key: "option",
      width: "23%",
      render: (button, record) => {
        return record.status !== "PENDING_DELETE" ? (
          <Space wrap>
            {(!record.mediaHasNotes || (record.mediaHasNotes && isAdmin)) && (
              <Button
                type={record.mediaHasNotes && isAdmin ? "danger" : "primary"}
                onClick={() => {
                  setMediaSelected(record.mediaId);
                  setMediaNoteView(record.mediaNotes);
                  setIsModalOpen(true);
                }}
              >
                Reject with Note
              </Button>
            )}
            {record.mediaHasNotes && !isAdmin && (
              <Button
                type="danger"
                onClick={() => {
                  setMediaSelected(record.mediaId);
                  setMediaNoteView(record.mediaNotes);
                  setIsModal2Open(true);
                }}
              >
                VIEW REMARKS
              </Button>
            )}
          </Space>
        ) : null;
      },
    };

    // Insert the OPTION column second to the last in the columns array
    const lastIndex = columns.length; // Get the current last index of the array
    columns.splice(lastIndex - 1, 0, optionColumn); // Insert before the last column
  }

  if (isReview) {
    const statusColumnIndex = columns.findIndex(
      column => column.key === "status"
    );
    columns.splice(statusColumnIndex, 1);
  }

  const rowSelections = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      onChangeArrayOfObj("adUnits", index, "selectedRows", selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    rowClassName: record => {
      if (record.status === "PENDING_CREATE") {
        return "tableSuccessRow";
      } else if (record.status === "PENDING_DELETE" || record.mediaHasNotes) {
        return "tableDangerRow";
      }
    },
    getCheckboxProps: record => {
      return {
        disabled: record.adId,
      };
    },
  };

  const refreshBanner = async banner => {
    const refreshUrl = await processImages(banner);
    return { ...banner, videoUrl: refreshUrl };
  };

  const fetchData = useCallback(async () => {
    if (adUnit && adUnit.banners) {
      let refreshedVideoBanners = await Promise.all(
        adUnit.banners.map(refreshBanner)
      );

      refreshedVideoBanners = refreshedVideoBanners
        .filter(banner =>
          !isReview ? banner.status !== "PENDING_DELETE" : true
        )
        .map((banner, index) => {
          const data = banner.media || banner;
          return {
            key: index,
            adId: banner.adId,
            preview: data.url,
            name: data.name,
            aspect: `${data.width} x ${data.height}`,
            fileType: data.fileType,
            fileSize: data.size,
            imgKey: data.key,
            id: banner.id || data.id,
            mediaId: data.id,
            mediaNotes: data.mediaNotes,
            mediaHasNotes: data.mediaHasNotes,
            status: banner.status || data.status,
            videoUrl: banner.videoUrl,
            dateAdded: (() => {
              // Scenario 1: Date is in Epoch Unix Timestamp format
              const timestampMatch = data.key.match(/^\d+/);
              const timestampString = timestampMatch ? timestampMatch[0] : "";
              const timestamp = parseInt(timestampString, 10);
              let date;

              if (!isNaN(timestamp)) {
                date = moment(timestamp);
              } else {
                // Scenario 2: Date is in a string format
                const dateStringMatch = data.key.match(
                  /(?:Mon|Tue|Wed|Thu|Fri|Sat|Sun) .+ \(.+\)/
                );
                const dateString = dateStringMatch ? dateStringMatch[0] : "";
                date = moment(
                  dateString,
                  "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
                );
              }

              if (date.isValid()) {
                return date.format("MM/DD/YYYY");
              } else {
                return "Date not available.";
              }
            })(),
          };
        });
      setMedias(refreshedVideoBanners);
    }
  }, [adUnit]);

  useEffect(() => {
    if (adUnit.selectedRows && !adUnit.selectedRows.length) {
      setSelectedRowKeys([]);
    }
    if (adUnit && adUnit.banners) {
      fetchData();
    }
  }, [adUnit]);

  const renderEmpty = () => {
    return (
      <Frag>
        <div style={{ paddingBottom: "20px" }}> No banners uploaded yet</div>
        <BannerUploader
          onChangeArrayOfObj={onChangeArrayOfObj}
          adUnit={adUnit}
          button={
            isTrafficker ? (
              <Tooltip title="Accepted file types: .jpg, .jpeg, .png, and .gif">
                <Button shape="round" icon={<CloudUploadOutlined />}>
                  Upload Banners
                </Button>
              </Tooltip>
            ) : null
          }
          index={index}
        />
      </Frag>
    );
  };

  return isReview ? (
    <>
      <ModalNotes
        isModalOpen={isModalOpen}
        isModal2Open={isModal2Open}
        setIsModalOpen={setIsModalOpen}
        setIsModal2Open={setIsModal2Open}
        setMediaNoteView={setMediaNoteView}
        addCreativeNotes={addCreativeNotes}
        mediaSelected={mediaSelected}
        mediaNoteView={mediaNoteView}
      />
      <Table
        key={tableKey}
        scroll={{ x: true }}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={medias}
        pagination={false}
        rowClassName={record => {
          switch (record.status) {
            case "PENDING_CREATE":
              return "tableSuccessRow";
            case "PENDING_DELETE":
              return "tableDangerRow";
            case "PAUSED":
            case "PENDING_UPDATE":
              return "tableWarningRow";
            default:
              if (record.mediaHasNotes) {
                return "tableDangerRow";
              }
              break;
          }
        }}
      />
    </>
  ) : (
    <>
      <ModalNotes
        isModalOpen={isModalOpen}
        isModal2Open={isModal2Open}
        setIsModalOpen={setIsModalOpen}
        setIsModal2Open={setIsModal2Open}
        setMediaNoteView={setMediaNoteView}
        addCreativeNotes={addCreativeNotes}
        mediaSelected={mediaSelected}
        mediaNoteView={mediaNoteView}
      />
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={medias}
          rowSelection={isShowRowSelection ? rowSelections : null}
          pagination={false}
        />
      </ConfigProvider>
    </>
  );
};

export default BannersUploadTable;
