import gql from "graphql-tag";

const CAMPAIGN_ORDER_LIST = gql`
  query campaignOrdersList($orgId: ID, $searchName: String) {
    campaignOrders(
      where: {
        AND: [
          {
            OR: [
              {
                orgs_some: {
                  OR: [{ id: $orgId }, { parentOrg_some: { id: $orgId } }]
                }
              }
            ]
          }
          { status_not: DRAFT }
          { status_not: PENDING }
          { name_contains: $searchName }
        ]
      }
      orderBy: createdAt_DESC
    ) {
      id
      name
      status
      startDate
      endDate
      aggregateData {
        overallData {
          aggImpressions
        }
      }
      geoTargets {
        id
      }
    }
  }
`;

export default CAMPAIGN_ORDER_LIST;
