import gql from "graphql-tag";

const EDIT_MAPPING_RECORD = gql`
  mutation EditMappingRecord(
    $segment_id: Int
    $data_provider_id: Int
    $data_category_id: Int
    $active: Boolean
    $is_public: Boolean
    $data_segment_type_id: String
    $member_id: Int!
    $recordId: Int!
  ) {
    editMappingRecord(
      data: {
        segment_id: $segment_id
        data_provider_id: $data_provider_id
        data_category_id: $data_category_id
        active: $active
        is_public: $is_public
        data_segment_type_id: $data_segment_type_id
        member_id: $member_id
      }
      where: { id: $recordId }
    ) {
      id
      segment_id
      active
      data_segment_type_id
      data_category_id
      data_provider_id
      is_public
      error
    }
  }
`;

export default EDIT_MAPPING_RECORD;
