import gql from "graphql-tag";

const GET_CHAT = gql`
  query GET_CHAT($from: String!) {
    chat(from: $from) {
      id
      message
      role
    }
  }
`;

export default GET_CHAT;
