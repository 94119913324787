import React from "react";
import { graphql, useQuery } from "react-apollo";
import { flowRight as compose } from "lodash";
import GET_CAMPAIGN_REPORT from "../../../../GraphQl/Queries/GET_CAMPAIGN_REPORT";
import CURRENT_USER from "../../../../GraphQl/Queries/CURRENT_USER";
import Controller from "./BasketItemsTabController";
import OverlaySpinner from "../../../../../platform/shared/OverlaySpinner";

const BasketItemsTabDataLoader = ({ children, ...props }) => {
  const reportId = props.basketItemsReportId || null;

  const { loading, error, data, refetch } = useQuery(GET_CAMPAIGN_REPORT, {
    variables: { reportId },
  });

  if (loading) return <OverlaySpinner />;
  if (error) return <div>Error loading campaign report: {error.message}</div>;

  const { url, expiresAt } = data.getCampaignReportSignedURL;
  if (!url) return null;

  const s3url = new URL(url);
  const pathnames = s3url.pathname.split("/");
  const reportName = pathnames.length > 3 ? pathnames[3] : "";

  return (
    <Controller
      refetch={refetch}
      url={url}
      expiresAt={expiresAt}
      reportName={reportName}
      {...props}
    >
      {children}
    </Controller>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => ({
      currentUser: ownProps.currentUser || currentUser,
    }),
  })
)(BasketItemsTabDataLoader);
