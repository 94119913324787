import React, { useState, useEffect } from "react";
import { Card, Row, Col, Divider } from "antd";
import WhiteLabel from "../../shared/WhiteLabel";

function PrivacyPolicyView() {
  const [companyName, setCompanyName] = useState("");
  const [imageURL, setImageURL] = useState("");

  const result = WhiteLabel();

  function extractHostname(url) {
    let hostname;
    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }
    hostname = hostname.split(":")[0];
    hostname = hostname.split("?")[0];
    return hostname;
  }

  useEffect(() => {
    const extractRootDomainNoExt = url => {
      let domain = extractHostname(url),
        splitArr = domain.split("."),
        arrLen = splitArr.length;

      if (arrLen === 2) {
        domain = splitArr[0];
      } else if (arrLen > 2) {
        domain = splitArr[arrLen - 2];

        if (
          splitArr[arrLen - 2].length === 2 &&
          splitArr[arrLen - 1].length === 2
        ) {
          domain = splitArr[arrLen - 3];
        }
      }
      return domain;
    };
    setImageURL(result);
    setCompanyName(extractRootDomainNoExt(window.location.hostname));
  }, [result]);

  return (
    <div>
      <Row type="flex" justify="space-around" gutter={[24, 16]} align="middle">
        <Col xs={16} md={8}>
          <div style={{ paddingTop: "13px", display: "flex", height: "10vh" }}>
            <img src={imageURL} style={{ maxHeight: "100%" }} alt="logo" />
          </div>
        </Col>
        {/* Blank Space */}
        <Col xs={16} md={8}>
          <></>
        </Col>
      </Row>

      <Divider />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="1. INTRODUCTION" hoverable>
            <p style={{ fontSize: "18px" }}>
              {companyName} is committed to protecting privacy on the internet.
              The purpose of this privacy policy (“Privacy Policy” or “Policy”)
              is to explain how we collect, use, and share personal information
              (as defined under the California Consumer Privacy Act) we obtain
              through your use of the {companyName} website (the “Site”). This
              Privacy Policy only covers information collected when you visit
              the Site and via direct, voluntary communications between you and{" "}
              {companyName}, and does not cover any information collected at any
              other website, application, or otherwise by us (unless
              specifically stated), including when you call us, write to us by
              mail, or communicate with us in any manner other than through use
              of the Site. We maintain our website to provide information about
              our company and services to potential clients and partners, and
              anyone else in the general public who is interested in learning
              more about {companyName} and our offerings. We also provide
              information on a variety of topics of interest within the
              advertising space for entertainment and learning purposes.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />

      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="2. INFORMATION WE COLLECT" hoverable>
            <p style={{ fontSize: "18px" }}>
              When you visit the Site, we automatically collect certain
              information about your device, including your IP address, mobile
              device identifier or other unique identifier, browser and computer
              type, access time, the web page you came from, the URL you go to
              next, and the web page(s) that you access during your visit.
              Additionally, as you browse the Site, we collect information about
              the individual web pages that you view, what websites or search
              terms referred you to the Site, and information about how you
              interact with the Site. We refer to this automatically-collected
              information as “Device Information.”
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="3. HOW WE COLLECT DEVICE INFORMATION" hoverable>
            <p style={{ fontSize: "18px" }}>
              Pages on this site may include embedded content, like YouTube
              videos, for example. Embedded content from other websites behaves
              in the exact same way as if you visited the other website.
              <br />
              <br />
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged-in to
              that website. Below you can find a list of the services we use:
              <br />
              <br />
              <b>FACEBOOK</b>
              <br />
              <br />
              The Facebook page plugin is used to display our Facebook timeline
              on our site. Facebook has its own cookie and privacy policies over
              which we have no control. There is no installation of cookies from
              Facebook and your IP is not sent to a Facebook server until you
              consent to it. See their privacy policy here:{" "}
              <a
                href="https://www.facebook.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook Privacy Policy.
              </a>
              <br />
              <br />
              <b>TWITTER</b>
              <br />
              <br />
              We use the Twitter API to display our tweets timeline on our site.
              Twitter has its own cookie and privacy policies over which we have
              no control. Your IP is not sent to a Twitter server until you
              consent to it. See their privacy policy here:{" "}
              <a
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter Privacy Policy.
              </a>
              <br />
              <br />
              <b>YOUTUBE</b>
              <br />
              <br />
              We use YouTube videos embedded on our site. YouTube has its own
              cookie and privacy policies over which we have no control. There
              is no installation of cookies from YouTube and your IP is not sent
              to a YouTube server until you consent to it. See their privacy
              policy here:{" "}
              <a
                href="https://www.youtube.com/static?template=privacy_guidelines"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube Privacy Policy.
              </a>
              <br />
              <br />
              <b>CONSENT CHOICE</b>
              <br />
              <br />
              We provide you with the choice to accept this or not, we prompt
              consent boxes for all embedded content, and no data is transferred
              before you consented to it.
              <br />
              <br />
              <ul>
                <li>YouTube</li>
                <li>Facebook</li>
                <li>Twitter</li>
              </ul>
              <br />
              <br />
              <b>COOKIES:</b> are data files that are placed on your device or
              computer and often include an anonymous unique identifier.
              <br />
              <br />
              <b>LOG FILES:</b> track actions occurring on the Site, and collect
              data including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
              <br />
              <br />
              <b>PIXEL DATA:</b> are electronic files used to record information
              about how you browse the Site. More specifically, pixel tags can
              collect information such as the IP address of the computer that
              downloaded the page on which the tag appears; the time (and length
              of time) the page containing the pixel tag was viewed; the type of
              browser that retrieved the pixel tag; and the identification
              number of any cookie previously placed by that server on your
              computer.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="4. COOKIES" hoverable>
            <p style={{ fontSize: "18px" }}>
              This site uses cookies – small text files that are placed on your
              machine to help the site provide a better user experience. In
              general, cookies are used to retain user preferences, store
              information for things like shopping carts, and provide anonymized
              tracking data to third party applications like Google Analytics.
              Cookies generally exist to make your browsing experience better.
              However, you may prefer to disable cookies on this site and on
              others. The most effective way to do this is to disable cookies in
              your browser. We suggest consulting the help section of your
              browser.
              <br />
              <br />
              <b>NECESSARY COOKIES (ALL SITE VISITORS)</b>
              <br />
              <br />
              <ul>
                <li>
                  <b>cfduid:</b>Is used for our CDN CloudFlare to identify
                  individual clients behind a shared IP address and apply
                  security settings on a per-client basis. See more information
                  on privacy here:{" "}
                  <a
                    href="https://www.cloudflare.com/privacypolicy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CloudFlare Privacy Policy.
                  </a>
                </li>
                <li>
                  <b>PHPSESSID:</b> To identify your unique session on the
                  website.
                </li>
              </ul>
              <b>NECESSARY COOKIES (ADDITIONAL FOR LOGGED IN CUSTOMERS)</b>
              <br />
              <br />
              <ul>
                <li>
                  <b>wp-auth: </b> Used by WordPress to authenticate logged-in
                  visitors, password authentication and user verification.
                </li>
                <li>
                  <b>wordpress_logged_in_hash:</b>
                  Used by WordPress to authenticate logged-in visitors, password
                  authentication and user verification.
                </li>
                <li>
                  <b>wordpress_test_cookie:</b>
                  Used by WordPress to ensure cookies are working correctly.
                </li>
                <li>
                  <b>wp-settings-[UID]:</b>
                  WordPress sets a few wp-settings-[UID] cookies. The number on
                  the end is your individual user ID from the users database
                  table. This is used to customize your view of admin interface,
                  and possibly also the main site interface.
                </li>
                <li>
                  <b>wp-settings-[UID]:</b>
                  WordPress also sets a few wp-settings-time-[UID] cookies. The
                  number on the end is your individual user ID from the users
                  database table. This is used to customize your view of admin
                  interface, and possibly also the main site interface.
                </li>
              </ul>
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="5. HOW WE USE THE INFORMATION WE COLLECT" hoverable>
            <p style={{ fontSize: "18px" }}>
              We collect data to operate effectively and provide users the best
              experience with our Site and services. We may use the information
              we collect in the following ways:
              <br />
              <br />
              <ul>
                <li>
                  To improve our website user experience, products, or
                  offerings.
                </li>
                <li>To customize the website according to user’s interests.</li>
                <li>
                  To internally gauge our current and prospective audience.
                </li>
                <li>To respond to information requests.</li>
                <li>To comply with regulatory and legal obligations</li>
                <li>
                  To periodically send promotional email about company news, new
                  offerings, promotions, special offers or other information
                  which we think you may find interesting using the email
                  address which you voluntarily provided.
                </li>
              </ul>
              {companyName} takes data privacy very seriously. We do not sell,
              trade or otherwise transfer to outside parties our users’
              personally identifiable information. This does not include trusted
              third parties who assist us in operating our website, conducting
              our business in the implementation of advertising campaigns or
              servicing the user, so long as those parties agree to keep this
              information confidential.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="6. HOW WE PROTECT YOUR INFORMATION" hoverable>
            <p style={{ fontSize: "18px" }}>
              {companyName} has implemented commercially reasonable and
              appropriate security measures to maintain the safety of personal
              information when submitted. However, due to the inherent open
              nature of the Internet, we cannot guarantee that communication
              between users and {companyName} or information stored on{" "}
              {window.location.hostname}, or our servers, will be free from
              unauthorized access by third parties such as hackers, and use of
              our website demonstrates an assumption of this risk. We have put
              in place reasonable physical, electronic and managerial procedures
              to safeguard the information we collect to the best of our
              ability.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="7. CONSENT" hoverable>
            <p style={{ fontSize: "18px" }}>
              Use of this site signifies your consent, as well as the consent of
              the company or business for whom you use the site and whose
              information you submit (if any), to this online privacy policy,
              including the collection and use of the information by{" "}
              {companyName}, as described in this privacy policy. Continued
              access and use of this site without acceptance of the terms of
              this privacy policy relieves {companyName} from any responsibility
              to the user.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="8. CONTACT US" hoverable>
            <p style={{ fontSize: "18px" }}>
              If you have any questions about this privacy policy, please
              contact us by e-mail at marketing@{companyName}.com, or by mail
              using the details provided below:
              <br />
              <br />
              <b>{companyName}, Inc.</b>
              {/* <br />
              1475 N. Broadway, Suite 420
              <br />
              Walnut Creek, CA, 94596 */}
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row type="flex" justify="center" gutter={[24, 16]}>
        <Col xs={24} md={16}>
          <Card title="9. AMENDMENTS" hoverable>
            <p style={{ fontSize: "18px" }}>
              We may amend this Privacy Policy from time to time. When we amend
              this Privacy Policy, we will update this page accordingly and
              require you to accept the amendments in order to be permitted to
              continue using our services.
            </p>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </div>
  );
}

export default PrivacyPolicyView;
