import gql from "graphql-tag";

const DELETE_ADVERTISER = gql`
  mutation deleteAdvertiser($where: AdvertiserWhereUniqueInput!) {
    deleteAdvertiser(where: $where) {
      id
      name
    }
  }
`;

export default DELETE_ADVERTISER;
