import React, { useState } from "react";
import { cloneDeep, unset } from "lodash";
import moment from "moment";

import {
  formatWholeNumber,
  formatNumber,
  formatCurrency2SigFig,
} from "../../../../utils/campaigns";

const PaidSearchCommerceController = ({
  children,
  paidSearchSummary,
  orgName,
  totalTransactions,
  paidSearchTransactions,
  pageNumber,
  location,
  history,
  startDate,
  endDate,
  attribution,
  hasDateParams,
  attributionWindow,
  ...props
}) => {
  const [commerce, setCommerce] = useState(
    paidSearchSummary.overallCommerce || []
  );
  const [bySummaryRangeDate, setControllerbySummaryRangeDate] = useState([]);

  const transactions = paidSearchTransactions.transactions.edges || [];
  const { orgConfig } = props;

  const setByCommerceRangeDate = dates => {
    setStartDateEndDate(dates);
    setControllerbySummaryRangeDate(dates);
  };

  const attributionDropdownChange = attribution => {
    const byDates = cloneDeep(paidSearchSummary.overallCommerce.byDate);
    const dates =
      bySummaryRangeDate.length === 0
        ? [
            moment(byDates[0].xAxis).utc(),
            moment(byDates[byDates.length - 1].xAxis).utc(),
          ]
        : bySummaryRangeDate;
    setAttribution(attribution, dates);
    setControllerbySummaryRangeDate(dates);
  };

  const attributionWindowDropdownChange = attributionW => {
    const byDates = cloneDeep(paidSearchSummary.overallCommerce.byDate);
    const dates =
      bySummaryRangeDate.length === 0
        ? [
            moment(byDates[0].xAxis).utc(),
            moment(byDates[byDates.length - 1].xAxis).utc(),
          ]
        : bySummaryRangeDate;
    setAttributionWindow(attributionW, dates);
    setControllerbySummaryRangeDate(dates);
  };

  const setStartDateEndDate = dates => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.get("startDate")) searchParams.delete("startDate");
    if (searchParams.get("endDate")) searchParams.delete("endDate");
    searchParams.append("startDate", dates[0]);
    searchParams.append("endDate", dates[1]);
    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  const setAttribution = (attribution, dates) => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.get("attribution")) searchParams.delete("attribution");
    searchParams.append("attribution", attribution);
    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  const setAttributionWindow = (attributionW, dates) => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.get("attributionWindow"))
      searchParams.delete("attributionWindow");
    searchParams.append("attributionWindow", attributionW);
    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  const processDatePickerChange = dates => {
    let cloneCommerce = cloneDeep(paidSearchSummary.overallCommerce);

    const cByDate = cloneCommerce.byDate;
    const filterByDate = cByDate.filter(obj => {
      if (
        moment(obj.xAxis)
          .utc()
          .isBetween(dates[0].subtract(1, "day"), dates[1].add(1, "day"))
      ) {
        let source = obj;
        if (attribution) {
          if (attribution === "COOKIES") {
            source = obj.byAttribution.cookies;
          } else if (attribution === "IP") {
            source = obj.byAttribution.ipAddresses;
          } else if (attribution === "FINGERPRINT") {
            source = obj.byAttribution.fingerprint;
          } else if (attribution === "DEVICE_FINGERPRINT") {
            source = obj.byAttribution.deviceFingerprint;
          }
          obj.clicks = source.clicks;
          obj.revenue = source.revenue;
          obj.conversions = source.conversions;
        }
        return true;
      }
      return false;
    });
    cloneCommerce.byDate = filterByDate;
    if (filterByDate && filterByDate.length > 0) {
      const consolidatedCount = filterByDate.reduce(
        (conso, obj) => {
          let source = obj;
          if (attribution) {
            if (obj.byAttribution) {
              if (attribution === "COOKIES") {
                source = obj.byAttribution.cookies;

                source.uniqueUsers = {
                  ipAddresses: [],
                  fingerprints: [],
                  userIds: obj.uniqueUsers.userIds,
                  deviceFingerprint: [],
                };
              } else if (attribution === "IP") {
                source = obj.byAttribution.ipAddresses;

                source.uniqueUsers = {
                  ipAddresses: obj.uniqueUsers.ipAddresses,
                  fingerprints: [],
                  userIds: [],
                  deviceFingerprint: [],
                };
              } else if (attribution === "FINGERPRINT") {
                source = obj.byAttribution.fingerprint;

                source.uniqueUsers = {
                  ipAddresses: [],
                  fingerprints: obj.uniqueUsers.fingerprints,
                  userIds: [],
                  deviceFingerprint: [],
                };
              } else if (attribution === "DEVICE_FINGERPRINT") {
                source = obj.byAttribution.deviceFingerprint;

                source.uniqueUsers = {
                  ipAddresses: [],
                  fingerprints: [],
                  userIds: [],
                  deviceFingerprint: obj.uniqueUsers.deviceFingerprint,
                };
              }
            } else {
              source = {
                clicks: 0,
                revenue: 0,
                conversions: 0,
                uniqueUsers: {
                  ipAddresses: [],
                  fingerprints: [],
                  userIds: [],
                  deviceFingerprint: [],
                },
              };
            }
          }

          return {
            clicks: conso.clicks + source.clicks,
            revenue: conso.revenue + source.revenue,
            conversions: conso.conversions + source.conversions,
            ip: [...conso.ip, ...source.uniqueUsers.ipAddresses],
            fingerprint: [
              ...conso.fingerprint,
              ...source.uniqueUsers.fingerprints,
            ],
            cookies: [...conso.cookies, ...source.uniqueUsers.userIds],
            deviceFingerprint: [
              ...conso.deviceFingerprint,
              ...source.uniqueUsers.deviceFingerprint,
            ],
          };
        },
        {
          clicks: 0,
          revenue: 0,
          conversions: 0,
          ip: [],
          fingerprint: [],
          cookies: [],
          deviceFingerprint: [],
        }
      );
      consolidatedCount.ip = [...new Set(consolidatedCount.ip)];
      consolidatedCount.fingerprint = [
        ...new Set(consolidatedCount.fingerprint),
      ];
      consolidatedCount.cookies = [...new Set(consolidatedCount.cookies)];
      consolidatedCount.deviceFingerprint = [
        ...new Set(consolidatedCount.deviceFingerprint),
      ];
      cloneCommerce.clicks = consolidatedCount.clicks;
      cloneCommerce.revenue = consolidatedCount.revenue;
      cloneCommerce.conversions = consolidatedCount.conversions;
      cloneCommerce.customer =
        consolidatedCount.ip.length +
        consolidatedCount.fingerprint.length +
        consolidatedCount.deviceFingerprint.length +
        consolidatedCount.cookies.length;
    } else {
      cloneCommerce.clicks = 0;
      cloneCommerce.revenue = 0;
      cloneCommerce.conversions = 0;
      cloneCommerce.customer = 0;
    }
    setCommerce(cloneCommerce);
  };

  const generateReport = () => {
    const priceVisibleExclude = ["ROAS", "CPC", "Spend"];
    const data = commerce.byDate.map(d => {
      const row = {
        Date: moment(d.xAxis).format("M/D/YYYY"),
        Clicks: formatWholeNumber(d.clicks),
        Impressions: formatWholeNumber(d.impressions),
        Transactions: formatWholeNumber(d.conversions),
        Customer: `${formatWholeNumber(d.customer)}`,
        Revenue: formatCurrency2SigFig(d.revenue),
        Spend: formatCurrency2SigFig(d.spend),
        ROAS: `${formatNumber(d.roas)}%`,
      };

      !orgConfig.priceVisible &&
        priceVisibleExclude.forEach(exclude => {
          unset(row, exclude);
        });
      return row;
    });
    const totalRow = {
      Date: `Total`,
      Clicks: formatWholeNumber(commerce.clicks),
      Impressions: formatWholeNumber(commerce.impressions),
      Transactions: formatWholeNumber(commerce.conversions),
      Customer: formatWholeNumber(commerce.customer),
      Revenue: formatCurrency2SigFig(commerce.revenue),
      Spend: formatCurrency2SigFig(commerce.spend),
      ROAS: `${formatNumber(commerce.roas)}%`,
    };
    !orgConfig.priceVisible &&
      priceVisibleExclude.forEach(exclude => {
        unset(totalRow, exclude);
      });
    data.push(totalRow);

    return data;
  };

  if (hasDateParams && !bySummaryRangeDate.length) {
    const dates = [moment(startDate).utc(), moment(endDate).utc()] || [];
    setControllerbySummaryRangeDate(dates);

    processDatePickerChange(dates);
  }

  return React.cloneElement(children, {
    commerce,
    generateReport,
    setByCommerceRangeDate,
    attributionDropdownChange,
    attributionWindowDropdownChange,
    orgName: orgName || "",
    orgConfig,
    transactions,
    totalTransactions,
    pageNumber,
    location,
    history,
    startDate,
    endDate,
    attribution,
    attributionWindow,
  });
};

export default PaidSearchCommerceController;
