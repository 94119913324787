import React, { useEffect, useState } from "react";
import { Typography, Select, Button, Table, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import LaunchCampaignConfigSupplySites from "./LaunchCampaignConfigSupplySites";

const LaunchCampaignConfigPublisherTable = ({
  selectedPublishers,
  fetchPublisherSites,
  setSelectedPublishers,
  isInclude,
  publishers,
  setIsLaunchCampaignDisabled,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (publishers && publishers.length) {
      setOptions(
        publishers.map(publisher => ({
          value: publisher.id,
          label: publisher.name,
        }))
      );
    }
  }, [publishers]);

  const handleSelectPublisher = ({ id, isInclude }) => {
    const publisher = publishers.find(pub => pub.id === id);
    if (
      !selectedPublishers.find(
        selected =>
          selected.id === publisher.id && selected.isInclude === isInclude
      )
    ) {
      setSelectedPublishers([
        ...selectedPublishers,
        { ...publisher, urls: [], isInclude },
      ]);
      setValue("");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return `${name} (${record.urls.length})`;
      },
    },
    {
      title: "Urls",
      dataIndex: "urls",
      key: "urls",
      render: (urls, record) => {
        return (
          <LaunchCampaignConfigSupplySites
            mode="multiple"
            value={urls}
            placeholder="Search Sites"
            fetchOptions={fetchPublisherSites}
            onChange={newSites => {
              const updatedPublishers = JSON.parse(
                JSON.stringify(selectedPublishers)
              );
              const index = selectedPublishers.findIndex(
                pub => pub.id === record.id && pub.isInclude === isInclude
              );
              updatedPublishers[index] = {
                ...record,
                urls: newSites,
              };
              setSelectedPublishers(updatedPublishers);
            }}
            publisherId={record.id}
          />
        );
      },
    },
    {
      title: "",
      key: "delete",

      render: (_, record) => {
        const handleRemovePublisher = () => {
          setSelectedPublishers(
            selectedPublishers.filter(
              publisher =>
                publisher.id !== record.id || publisher.isInclude !== isInclude
            )
          );
        };
        return (
          <Button
            onClick={handleRemovePublisher}
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  // Create a custom body row component that wraps a row in a tooltip
  // TODO: State change should not happen in render
  const CustomTableRow = ({ index, className, style, children, record }) => {
    const duplicates =
      selectedPublishers.length && record
        ? selectedPublishers.filter(
            item => item.id === record.id && item.urls.length === 0
          )
        : [];
    const showTooltip = duplicates.length > 1;
    setIsLaunchCampaignDisabled(showTooltip);

    return (
      <Tooltip
        title={
          showTooltip ? "URLs value is required at least for one of them" : ""
        }
      >
        <tr className={className} style={style}>
          {children}
        </tr>
      </Tooltip>
    );
  };

  return (
    <>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {isInclude ? "Include" : "Exclude"} Publishers
      </Typography.Title>

      <Select
        value={value}
        showSearch
        placeholder="Select Publisher"
        optionFilterProp="children"
        onChange={id => handleSelectPublisher({ id, isInclude })}
        filterOption={(input, option) =>
          (option && option.label ? option.label : "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        options={options}
        style={{ width: 300 }}
      />

      <Table
        dataSource={selectedPublishers.filter(
          pub => pub.isInclude === isInclude
        )}
        columns={columns}
        rowSelection={null}
        paginationSize={"default"}
        scroll={{ x: 1200 }}
        pageSize={10}
        onChange={paginationObject => {
          setCurrentPage(paginationObject.current);
        }}
        rowKey="id"
        rowClassName={record => {
          const duplicates = selectedPublishers.filter(
            item => item.id === record.id && item.urls.length === 0
          );
          return duplicates.length > 1 ? "bg-warning" : "";
        }}
        components={{
          body: {
            row: CustomTableRow,
          },
        }}
        onRow={record => ({
          record: record,
        })}
        current={currentPage}
      />
    </>
  );
};

export default LaunchCampaignConfigPublisherTable;
