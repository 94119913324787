import React from "react";
import ContractTermsView from "./ContractTermsView";
import ContractTermsLoader from "../../../../../core/components/home/ContractTerms/ContractTermsLoader";

const ContractTerms = props => {
  return (
    <ContractTermsLoader {...props}>
      <ContractTermsView />
    </ContractTermsLoader>
  );
};

export default ContractTerms;
