import React, { useState } from "react";
import { Row, Col, Table, Progress, Modal } from "antd";
import S3LoaderVideo from "../../../../../../shared/S3LoaderVideo";
import { TableColumnTitle } from "../../../../../../shared/globalStyling/styledText";
//UNTESTED FOR VIDEO DISPLAY - made to be defensive to also show image

const styles = {
  columnHeader: {
    height: "56px",
    borderBottom: "1px solid #e8e8e8",
    paddingLeft: "10px",
  },
  leftColumn: {
    border: "0.8px solid #e8e8e8",
  },
  rightColumn: {
    paddingTop: "1px",
    border: "0.8px solid #e8e8e8",
    borderLeft: "none",
  },
  metricBlock: {
    height: "50%",
  },
  metricBlockTitle: {
    marginTop: "15%",
    paddingLeft: "10px",
    fontSize: 12,
    fontWeight: "bold",
    color: "#808080",
  },
  metricBlockValue: {
    fontSize: 24,
    color: "black",
    paddingLeft: "10px",
  },
};

const CreativeExpandedCard = ({ record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fullUrl, setFullUrl] = useState(false);

  if (!record) return null;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const targetUrlWithoutParams = url => {
    if (!url) return "";
    return url.split("?")[0];
  };

  return (
    <div>
      {record.creativeUrl && (
        <>
          <Row type="flex">
            <img
              src={record.creativeUrl}
              alt={record.value}
              width="50%"
              onClick={showModal}
            />
          </Row>
          <Modal
            title={record.value}
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
          >
            {record.targetUrl ? (
              <>
                <div>
                  <div>
                    <span>Click-through Link: </span>{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={record.targetUrl}
                    >
                      {!fullUrl
                        ? targetUrlWithoutParams(record.targetUrl)
                        : record.targetUrl}
                    </a>
                  </div>
                  <div
                    onClick={() => setFullUrl(!fullUrl)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginTop: "5px",
                    }}
                  >
                    Show {fullUrl ? "less" : "more"}
                  </div>
                </div>
                <a
                  style={{ display: "inline-block", paddingTop: "1rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={record.targetUrl}
                  title={record.value}
                >
                  <img
                    src={record.creativeUrl}
                    alt={record.value}
                    width="100%"
                  />
                </a>
              </>
            ) : (
              <img src={record.creativeUrl} alt={record.value} width="100%" />
            )}
          </Modal>
        </>
      )}
      {record.media && record.media.key ? (
        <Row type="flex">
          <Col span={14} style={styles.leftColumn}>
            <Row style={styles.columnHeader} type="flex" align="middle">
              <TableColumnTitle text={"VIDEO DETAILS"} />
            </Row>
            <Row type="flex" style={{ minHeight: "105px" }} justify="center">
              <Col span={12} style={{ minWidth: "210px" }}>
                <Row type="flex" align="middle" style={{ height: "100%" }}>
                  <div style={{ margin: "5px" }}>
                    {record.media && record.media.key ? (
                      <S3LoaderVideo
                        videoKey={record.media.key}
                        videoProps={{ width: 210 }}
                      />
                    ) : null}
                  </div>
                </Row>
              </Col>
              <Col span={12} style={{ minWidth: "130px" }}>
                <Row
                  type="flex"
                  style={{ height: "100%", marginBottom: "10px" }}
                >
                  <Col span={12} style={styles.metricBlock}>
                    <div style={styles.metricBlockTitle}>COMPLETED</div>
                    <div style={styles.metricBlockValue}>
                      {Math.round(record.impressions * 0.22).toLocaleString()}
                    </div>
                  </Col>
                  <Col span={12} style={styles.metricBlock}>
                    <div style={styles.metricBlockTitle}>MUTES</div>
                    <div style={styles.metricBlockValue}>
                      {Math.round(record.impressions * 0.5).toLocaleString()}
                    </div>
                  </Col>
                  <Col span={12} style={styles.metricBlock}>
                    <div style={styles.metricBlockTitle}>PAUSES</div>
                    <div style={styles.metricBlockValue}>
                      {Math.round(record.impressions * 0.32).toLocaleString()}
                    </div>
                  </Col>
                  <Col span={12} style={styles.metricBlock}>
                    <div style={styles.metricBlockTitle}>RESUMES</div>
                    <div style={styles.metricBlockValue}>
                      {Math.round(record.impressions * 0.12).toLocaleString()}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={10} style={styles.rightColumn}>
            <Table
              pagination={false}
              components={{
                header: {
                  cell: prop => {
                    return (
                      <th
                        style={{
                          padding: "10 10",
                          borderBottom: "1px solid #e8e8e8",
                        }}
                      >
                        {prop.children}
                      </th>
                    );
                  },
                },
                body: {
                  cell: prop => {
                    return (
                      <td style={{ padding: "10px 10px" }}>{prop.children}</td>
                    );
                  },
                },
              }}
              dataSource={[
                {
                  key: "1",
                  completed: 25,
                  viewers: Math.round(record.impressions * 0.61),
                  progress: Math.round(
                    ((record.impressions * 0.61) / record.impressions) * 100
                  ),
                },
                {
                  key: "2",
                  completed: 50,
                  viewers: Math.round(record.impressions * 0.43),
                  progress: Math.round(
                    ((record.impressions * 0.43) / record.impressions) * 100
                  ),
                },
                {
                  key: "3",
                  completed: 75,
                  viewers: Math.round(record.impressions * 0.3),
                  progress: Math.round(
                    ((record.impressions * 0.3) / record.impressions) * 100
                  ),
                },
                {
                  key: "4",
                  completed: 100,
                  viewers: Math.round(record.impressions * 0.22),
                  progress: Math.round(
                    ((record.impressions * 0.22) / record.impressions) * 100
                  ),
                },
              ]}
              columns={[
                {
                  title: <TableColumnTitle text={"COMPLETED"} />,
                  dataIndex: "completed",
                  key: "completed",
                  render: text => <div>{text.toLocaleString()}%</div>,
                },
                {
                  title: <TableColumnTitle text={"VIEWERS"} />,
                  dataIndex: "viewers",
                  key: "viewers",
                  render: text => <div>{text.toLocaleString()}</div>,
                },
                {
                  title: "",
                  dataIndex: "progress",
                  key: "progress",
                  render: text => <Progress percent={text} />,
                },
              ]}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export { CreativeExpandedCard };
