/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Typography, Space, Input, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TweenOneGroup } from "rc-tween-one";

const LaunchCampaignConfigSettings = ({ dealIds, setDealIds }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current.focus();
    }
  }, []);
  const handleClose = removedTag => {
    const newDealId = dealIds.filter(tag => tag !== removedTag);
    setDealIds(newDealId);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = e => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && dealIds.indexOf(inputValue) === -1) {
      setDealIds([...dealIds, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        key={tag}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = dealIds.map(forMap);
  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        width: "100%",
      }}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        Deal IDs
      </Typography.Title>

      <div
        style={{
          marginBottom: 8,
        }}
      >
        {dealIds.length ? (
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
            }}
            onEnd={e => {
              if (e.type === "appear" || e.type === "enter") {
                e.target.style = "display: inline-block";
              }
            }}
            leave={{
              opacity: 0,
              width: 0,
              scale: 0,
              duration: 200,
            }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        ) : null}
      </div>
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 150,
            padding: 4,
          }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{
            padding: 4,
            background: "#fff",
            borderStyle: "dashed",
            width: 150,
          }}
        >
          <PlusOutlined /> Add Deal Id
        </Tag>
      )}
    </Space>
  );
};

export default LaunchCampaignConfigSettings;
