import React from "react";
import { Card, Image, Row, Space, Typography } from "antd";
import { CopyBlock } from "react-code-blocks";

const { Paragraph, Title } = Typography;
const InstructionsBuilder = ({ title, steps }) => {
  return (
    <React.Fragment>
      <Title level={5}>{title}</Title>
      <Space direction="vertical">
        {steps.map((step, index) => (
          <>
            <Paragraph key={index}>
              {index + 1}. {step.instruction}
            </Paragraph>
            {step.code && (
              <Card>
                <CopyBlock
                  text={step.code}
                  language="javascript"
                  wrapLongLines
                  theme="dracula"
                />
              </Card>
            )}
            {step.image && (
              <Row justify="middle" align="center">
                <Image src={step.image} />
              </Row>
            )}
          </>
        ))}
      </Space>
    </React.Fragment>
  );
};

export default InstructionsBuilder;
