import React from "react";
import Referrers from "../referrers/Referrers";

const colStyle = {
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};

const OverviewTab = ({ chartData, chartsLoading }) => {
  return (
    <div>
      <Referrers
        colStyle={colStyle}
        referrerData={chartData}
        chartsLoading={chartsLoading}
      />
    </div>
  );
};

export default OverviewTab;
