import { isEmpty } from "lodash";
import {
  formatCurrency2SigFig,
  formatNumber,
} from "../../../../core/utils/campaigns";

export const summaryScale = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 1000,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : leftY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 1000,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["transactions"] : 0,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["revenue"] : 0,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : rightY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};

export const summaryScalePageViews = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 0,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "pageViews"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pageViews"] : 0,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Unique Users",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "pageViews"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pageViews"] : 0,
            alias: "Page Views",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};

export const summaryScaleSignUps = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 0,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "signUps"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["signUps"] : 0,
            alias: "Sign Ups",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Unique Users",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["clicks"] : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "signUps"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["signUps"] : 0,
            alias: "Sign Ups",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "customers"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["customers"] : 0,
            alias: "Customers",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};
