import gql from "graphql-tag";

const GET_ORG_CPM = gql`
  query org($loggedInOrg: ID) {
    org(where: { id: $loggedInOrg }) {
      id
      name
      cpm
    }
  }
`;

export default GET_ORG_CPM;
