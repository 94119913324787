import React from "react";
import { Chart, Geom } from "bizcharts";
import DataSet from "@antv/data-set";

import { getScale } from "../../core/utils/campaigns";
import { isEmpty } from "lodash";
const Frag = React.Fragment;

const MiniChart = ({ data, yAxisLeft }) => {
  let filledData = data;

  // If there isn't enough data to build a graph, fills with dummy data: two before and two after
  if (isEmpty(data) || data.length < 4) {
    filledData = Array(2)
      .fill({})
      .map((_, i) => {
        return {
          [yAxisLeft]: 0,
          xAxis: `Apr 20th ${1800 + i}`,
          cost: 0,
          totalCost: 0,
        };
      })
      .concat(filledData)
      .concat(
        Array(2)
          .fill({})
          .map((_, i) => {
            return {
              [yAxisLeft]: 0,
              xAxis: `Apr 20th ${9000 + i}`,
              cost: 0,
              totalCost: 0,
            };
          })
      );
  }
  const filtered = ["cost", "totalCost"];
  const ds = new DataSet();
  const dv = ds.createView().source(filledData);
  dv.transform({
    type: "fold",
    fields: filtered,
    key: "type",
    value: "value",
  });
  const scale = getScale(filledData);

  return (
    <Frag>
      <Chart height={50} width={100} data={dv} scale={scale} padding={0}>
        <Geom type="area" color="#9CB9E9" position={`xAxis*${yAxisLeft}`} />
        <Geom
          type="line"
          position={`xAxis*${yAxisLeft}`}
          color="#006ae1"
          shape="dotSmooth"
          size={2}
        />
      </Chart>
    </Frag>
  );
};

export default MiniChart;
