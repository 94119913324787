import gql from "graphql-tag";

const GET_CONVERSATION = gql`
  query conversation($getConversationId: ID) {
    conversation(where: { id: $getConversationId }) {
      id
      messages {
        message
        currentOrgID
      }
    }
  }
`;

export default GET_CONVERSATION;
