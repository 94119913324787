import React from "react";
import { Card, Button, Tooltip, Table, Popconfirm } from "antd";
import {
  CloseOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
const Frag = React.Fragment;

const columns = [
  {
    title: "Member Id",
    dataIndex: "memberId",
    key: "memberId",
  },
  {
    title: "Record Id",
    dataIndex: "recordId",
    key: "recordId",
  },
];

const SharingRecordsCard = ({
  onChange,
  tableData,
  popConfirmDelete,
  onRowClick,
  isSegmentSelected,
  isSharingRecordSelected,
  onAddSharingRecordOrSegmentOk,
  sharedSegmentsData,
  popConfirmRemove,
}) => {
  const sharedSegments = () => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "",
        dataIndex: "remove",
        key: "remove",
        render: (text, record) => {
          return (
            <Tooltip
              title={
                "Click here to remove this Audience from this sharing record."
              }
              arrowPointAtCenter={true}
              placement="bottom"
            >
              <Popconfirm
                title="Remove this audience?"
                onConfirm={e => {
                  popConfirmRemove();
                }}
              >
                <Button
                  onClick={() => {
                    let selectedRecord = [];
                    selectedRecord.push(record);
                    onChange("selectedSegments", selectedRecord);
                  }}
                  style={{ borderWidth: "0" }}
                  icon={<CloseOutlined />}
                  type="danger"
                  ghost={true}
                />
              </Popconfirm>
            </Tooltip>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={sharedSegmentsData}
        pagination={false}
      />
    );
  };

  return (
    <Card
      title="Sharing Records"
      extra={
        <Frag>
          <Tooltip
            title={
              isSharingRecordSelected
                ? "Click here to delete selected sharing record(s)."
                : "Select a sharing record to delete."
            }
            arrowPointAtCenter={true}
            placement="bottom"
          >
            <Popconfirm
              title="Delete this segment?"
              onConfirm={e => {
                popConfirmDelete();
              }}
            >
              <Button
                style={{ borderWidth: "0" }}
                icon={<CloseOutlined />}
                disabled={isSharingRecordSelected ? false : true}
                type="danger"
                ghost={true}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title={
              isSegmentSelected && isSharingRecordSelected
                ? "Click here to add your audiences to the selected sharing record."
                : isSegmentSelected && !isSharingRecordSelected
                ? "One more step. Select a sharing record to add your audience(s) to. If one doesn't exist use the plus button to create one."
                : !isSegmentSelected && isSharingRecordSelected
                ? "One more step. Select one or more audiences to add to a sharing record."
                : "Click here to add audiences to a sharing record."
            }
            arrowPointAtCenter={true}
          >
            <Popconfirm
              title="Add selected audience(s) to this sharing record?"
              onConfirm={e => {
                onAddSharingRecordOrSegmentOk("edit");
              }}
            >
              <Button
                ghost={
                  isSegmentSelected && isSharingRecordSelected ? false : true
                }
                style={{ borderWidth: "0" }}
                icon={<EditOutlined />}
                disabled={
                  isSegmentSelected && isSharingRecordSelected ? false : true
                }
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip
            title={
              isSegmentSelected
                ? "Click here to create a sharing record to add selected audiences to."
                : "You must first select an audience to share."
            }
            arrowPointAtCenter={true}
          >
            <Button
              ghost={isSegmentSelected ? false : true}
              style={{ borderWidth: "0" }}
              icon={<PlusCircleOutlined />}
              disabled={isSegmentSelected ? false : true}
              onClick={() => {
                onChange("modalVisible", true);
                onChange("whichModal", "createSharing");
              }}
            />
          </Tooltip>
        </Frag>
      }
    >
      <Table
        pagination={false}
        columns={columns}
        dataSource={tableData}
        onExpand={(expanded, record) => {
          onChange("selectedSharingRecord", record);
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: e => {
              onRowClick(record);
            },
          };
        }}
        expandedRowRender={sharedSegments}
        expandRowByClick={true}
      />
    </Card>
  );
};

export default SharingRecordsCard;
