import gql from "graphql-tag";
const UPDATE_HASHED_EMAIL_LIST = gql`
  mutation updateHashedEmailList($id: ID!, $activated: Boolean) {
    updateHashedEmailList(where: { id: $id }, data: { activated: $activated }) {
      id
      name
    }
  }
`;

export default UPDATE_HASHED_EMAIL_LIST;
