import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreativesMainLoader from "../../../../core/components/creatives/main/CreativesMainLoader";
import CreativesMainController from "../../../../core/components/creatives/main/CreativesMainController";
import CreativesMainView from "../main/CreativesMainView";

const CreativesRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <CreativesMainLoader {...props}>
            <CreativesMainController>
              <CreativesMainView />
            </CreativesMainController>
          </CreativesMainLoader>
        )}
      />
      {/* Fallback */}
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default CreativesRoutes;
