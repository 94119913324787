import { Card, Tooltip, Row } from "antd";
import React from "react";
import {
  KPITitleText,
  KpiPercentageText,
  KpiSubText,
} from "./globalStyling/styledText";
import { QuestionCircleOutlined } from "@ant-design/icons";

const styles = {
  title: {
    marginBottom: "10px",
  },
  card: { height: "100%", minHeight: "10em" },
  body: {},
};
const DetailBarCard = ({
  title,
  value,
  tooltip,
  prevValue,
  prevPercentage,
  prevRanges,
}) => {
  return (
    <Card style={styles.card} bodyStyle={styles.body}>
      <div style={styles.title}>
        <Row justify="space-between" align="top" wrap={true}>
          <KPITitleText text={title} />
          {tooltip && (
            <Tooltip
              title={tooltip}
              arrowPointAtCenter={true}
              placement="rightBottom"
            >
              <QuestionCircleOutlined type="question-circle" />
            </Tooltip>
          )}
        </Row>
      </div>
      <KpiSubText text={value} />
      <KpiPercentageText
        text={prevValue}
        prev={prevPercentage}
        ranges={prevRanges}
      />
    </Card>
  );
};

export default DetailBarCard;
