import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Typography } from "antd";
import LineItemsTable from "./LineItemsTable";
const { Text } = Typography;

const SelectorLineItems = ({
  changeValue,
  selectedCampaignLineItemsData,
  selectedCampaignLineItemsLoading,
  selectedCampaignOrderList,
  setIsLineItemCreate,
  urlCampaignOrderID,
  setIsCloningClicked,
  currentPagination,
  setPagination,
  total,
  pagination,
  setSearchLineName,
  searchLineName,
  onUpdateCampaignOrderLineItem,
  isLive,
  canEdit,
}) => {
  const [cloneModal, setCloneModal] = useState(false);

  const isSelectorChange = option => {
    if (option === "cloneLineItem") {
      setIsLineItemCreate(true);
      setCloneModal(true);
    } else if (option === "newLineItem") {
      setIsLineItemCreate(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.classList.contains("action")) {
        setIsLineItemCreate(true);
      }
    };

    // Attach the listeners on component mount.
    window.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsLineItemCreate]);

  return (
    <>
      <Row
        style={{
          padding: "30px",
        }}
      >
        <Text
          type="secondary"
          style={{ marginBottom: "10px", color: "#bababa" }}
        >
          Optional: Create a new line item or sync an existing line item to this
          campaign order.
        </Text>
        <Col span={24}>
          <Button
            style={{ marginRight: "8px" }}
            onClick={() => isSelectorChange("newLineItem")}
          >
            Create New Line Item
          </Button>
          <Button onClick={() => isSelectorChange("cloneLineItem")}>
            Sync Line Item to Campaign Order
          </Button>
        </Col>
      </Row>

      <Modal
        title="List of Line Items"
        centered
        visible={cloneModal}
        onCancel={() => setCloneModal(false)}
        closable={true}
        footer={null}
        width={1000}
      >
        <LineItemsTable
          changeValue={changeValue}
          selectedCampaignLineItemsData={selectedCampaignLineItemsData}
          selectedCampaignLineItemsLoading={selectedCampaignLineItemsLoading}
          setCloneModal={setCloneModal}
          setIsCloningClicked={setIsCloningClicked}
          currentPagination={currentPagination}
          setPagination={setPagination}
          total={total}
          pagination={pagination}
          searchLineName={searchLineName}
          setSearchLineName={setSearchLineName}
          onUpdateCampaignOrderLineItem={onUpdateCampaignOrderLineItem}
        />
      </Modal>
    </>
  );
};

export default SelectorLineItems;
