import React from "react";
import { Avatar, Card, List } from "antd";
import placeholder from "../../../../../../../../assets/icons/photo/placeholder.png";
import { ExtraInfo } from "../../../attribution/components/ExtraInfo";

const Top = ({ data, title, topCount = 10, imageIndex = "", currentUser }) => {
  const topData = data.slice(0, topCount);
  const avatarImg = item => {
    if (imageIndex !== "") {
      if (item[imageIndex]) {
        return item[imageIndex];
      } else {
        return placeholder;
      }
    }
    return "";
  };

  const explanation = `Some app images in Top ${topCount} ${title} are unable to be displayed.`;

  return (
    <>
      <Card
        title={`Top ${topCount} ${title}`}
        extra={<ExtraInfo placement="topRight" title={explanation} />}
      >
        <List
          itemLayout="horizontal"
          dataSource={topData}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={avatarImg(item)} />}
                title={currentUser.isDemo ? `${title} Example` : item.xAxis}
              />
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default Top;
