const unitedStatesGroups = [
  {
    NEW_ENGLAND: [
      "9836: Connecticut (Region, Connecticut, United States)",
      "33127: Maine (Region, Maine, United States)",
      "20535: Massachusetts (Region, Massachusetts, United States)",
      "26231: New Hampshire (Region, New Hampshire, United States)",
      "33277: Rhode Island (Region, Rhode Island, United States)",
      "16748: Vermont (Region, Vermont, United States)",
    ],
    MID_ADLANTIC: [
      "38627: Delaware (Region, Delaware, United States)",
      "11471: Maryland (Region, Maryland, United States)",
      "7435: New Jersey (Region, New Jersey, United States)",
      "1912: New York (Region, New York, United States)",
      "8280: Pennsylvania (Region, Pennsylvania, United States)",
      "1096144: Washington,  DC (Hagerstown) / DMA 511 (DMA, United States)",
    ],
    SOUTH_EAST: [
      "7519: Alabama (Region, Alabama, United States)",
      "7465: Florida (Region, Florida, United States)",
      "36389: Kentucky (Region, Kentucky, United States)",
      "12440: Mississippi (Region, Mississippi, United States)",
      "8606: North Carolina (Region, North Carolina, United States)",
      "9397: South Carolina (Region, South Carolina, United States)",
      "14641: Tennessee (Region, Tennessee, United States)",
      "10907: Virginia (Region, Virginia, United States)",
      "38628: West Virginia (Region, West Virginia, United States)",
    ],
    MID_WEST: [
      "9792: Illinois (Region, Illinois, United States)",
      "6100: Indiana (Region, Indiana, United States)",
      "25263: Iowa (Region, Iowa, United States)",
      "20408: Kansas (Region, Kansas, United States)",
      "7027: Michigan (Region, Michigan, United States)",
      "22778: Minnesota (Region, Minnesota, United States)",
      "22829: Missouri (Region, Missouri, United States)",
      "33155: Nebraska (Region, Nebraska, United States)",
      "31522: North Dakota (Region, North Dakota, United States)",
      "5081: Ohio (Region, Ohio, United States)",
      "38725: South Dakota (Region, South Dakota, United States)",
      "15689: Wisconsin (Region, Wisconsin, United States)",
    ],
    SOUTH_WEST: [
      "10867: Arizona (Region, Arizona, United States)",
      "9576: New Mexico (Region, New Mexico, United States)",
      "30059: Oklahoma (Region, Oklahoma, United States)",
      "22313: Texas (Region, Texas, United States)",
    ],
    WEST: [
      "38772: Alaska (Region, Alaska, United States)",
      "3469: California (Region, California, United States)",
      "23003: Colorado (Region, Colorado, United States)",
      "38609: Hawaii (Region, Hawaii, United States)",
      "38638: Idaho (Region, Idaho, United States)",
      "38715: Montana (Region, Montana, United States)",
      "5979: Nevada (Region, Nevada, United States)",
      "4930: Oregon (City, Ohio, United States)",
      "26026: Utah (Region, Utah, United States)",
      "11811: Washington (Region, Washington, United States)",
      "38778: Wyoming (Region, Wyoming, United States)",
    ],
    ROCKY_MOUNTAIN_STATES: [
      "23003: Colorado (Region, Colorado, United States)",
      "38638: Idaho (Region, Idaho, United States)",
      "38715: Montana (Region, Montana, United States)",
      "26026: Utah (Region, Utah, United States)",
      "38778: Wyoming (Region, Wyoming, United States)",
    ],
    GREAT_PLAINS: [
      "20408: Kansas (Region, Kansas, United States)",
      "25263: Iowa (Region, Iowa, United States)",
      "22778: Minnesota (Region, Minnesota, United States)",
      "22829: Missouri (Region, Missouri, United States)",
      "33155: Nebraska (Region, Nebraska, United States)",
      "31522: North Dakota (Region, North Dakota, United States)",
      "38725: South Dakota (Region, South Dakota, United States)",
    ],
    GULF_COAST: [
      "7519: Alabama (Region, Alabama, United States)",
      "7465: Florida (Region, Florida, United States)",
      "27223: Louisiana (Region, Louisiana, United States)",
      "12440: Mississippi (Region, Mississippi, United States)",
      "22313: Texas (Region, Texas, United States)",
    ],
    PACIFIC_NORTHWEST: [
      "4930: Oregon (City, Ohio, United States)",
      "11811: Washington (Region, Washington, United States)",
    ],
    APPALACHIAN_REGION: [
      "36389: Kentucky (Region, Kentucky, United States)",
      "38628: West Virginia (Region, West Virginia, United States)",
      "7519: Alabama (Region, Alabama, United States)",
      "11558: Georgia (Region, Georgia, United States)",
      "11471: Maryland (Region, Maryland, United States)",
      "12440: Mississippi (Region, Mississippi, United States)",
      "1912: New York (Region, New York, United States)",
      "8606: North Carolina (Region, North Carolina, United States)",
      "5081: Ohio (Region, Ohio, United States)",
      "8280: Pennsylvania (Region, Pennsylvania, United States)",
      "9397: South Carolina (Region, South Carolina, United States)",
      "14641: Tennessee (Region, Tennessee, United States)",
      "10907: Virginia (Region, Virginia, United States)",
    ],
  },
];

const canadaGroups = [];

const canadaRegions = [
  "31949: Alberta (Region, Alberta, Canada)",
  "29726: British Columbia (Region, British Columbia, Canada)",
  "38765: Manitoba (Region, Manitoba, Canada)",
  "38844: New Brunswick (Region, New Brunswick, Canada)",
  "35312: Newfoundland and Labrador (Region, Newfoundland and Labrador, Canada)",
  "34711: Nova Scotia (Region, Nova Scotia, Canada)",
  "18637: Ontario (Region, Ontario, Canada)",
  "39214: Prince Edward Island (Region, Prince Edward Island, Canada)",
  "15923: Quebec (Region, Quebec, Canada)",
  "38837: Saskatchewan (Region, Saskatchewan, Canada)",
  "39722: Northwest Territories (Region, Northwest Territories, Canada)",
  "40357: Nunavut (Region, Nunavut, Canada)",
  "39817: Yukon (Region, Yukon, Canada)",
];

const ukRegions = [
  "3148: England (Region, England, United Kingdom)",
  "10195: Wales (Region, Wales, United Kingdom)",
  "22825: Scotland (Region, Scotland, United Kingdom)",
  "29409: Northern Ireland (Region, Northern Ireland, United Kingdom)",
  "39286: East Riding of Yorkshire (Region, England, United Kingdom)",
  "38941: North Yorkshire (Region, England, United Kingdom)",
  "38801: Cumbria (Region, England, United Kingdom)",
  "91643: City of London (Region, England, United Kingdom)",
  "39173: Northumberland (Region, England, United Kingdom)",
  "14984: Gloucestershire (Region, England, United Kingdom)",
  "39864: South Gloucestershire (Region, South Gloucestershire, United Kingdom)",
  "38943: Leicestershire (Region, England, United Kingdom)",
  "32455: Essex (Region, England, United Kingdom)",
];

const spainRegions = [
  "38649: Basque Country (Region, Basque Country, Spain)",
  "38645: Galicia (Region, Galicia, Spain)",
  "38731: Asturias (Region, Principality of Asturias, Spain)",
  "38700: Principality of Asturias (Region, Principality of Asturias, Spain)",
  "38693: Aragon (Region, Aragon, Spain)",
  "38755: Cantabria (Region, Cantabria, Spain)",
  "38803: Cantabria (Region, Cantabria, Spain)",
  "28709: Balearic Islands (Region, Balearic Islands, Spain)",
  "33368: Balearic Islands (Region, Balearic Islands, Spain)",
  "20367: Extremadura (Region, Extremadura, Spain)",
  "20757: Castille and León (Region, Castille and León, Spain)",
  "15206: Canary Islands (Region, Canary Islands, Spain)",
  "38979: La Rioja (Region, La Rioja, Spain)",
  "39068: La Rioja (Region, La Rioja, Spain)",
  "31128: Castille-La Mancha (Region, Castille-La Mancha, Spain)",
  "38696: Murcia (Region, Murcia, Spain)",
  "38718: Murcia (Region, Murcia, Spain)",
  "18353: Valencia (Region, Valencia, Spain)",
  "29744: Valencia (Region, Valencia, Spain)",
  "11510: Madrid (Region, Madrid, Spain)",
  "19550: Madrid (Region, Madrid, Spain)",
  "39034: Araba / Álava (Region, Basque Country, Spain)",
  "14962: Catalonia (Region, Catalonia, Spain)",
  "23177: Andalusia (Region, Andalusia, Spain)",
  "38592: Navarre (Region, Navarre, Spain)",
  "38794: Navarre (Region, Navarre, Spain)",
  "39928: Ceuta (Region, Ceuta, Spain)",
  "39846: Melilla (Region, Melilla, Spain)",
];

export const RegionConfig = {
  USA: {
    name: "227: United States",
    groups: unitedStatesGroups,
    regions: Array.from(
      new Set(
        unitedStatesGroups

          .map(group => Object.values(group))
          .flat()
          .flat()
      )
    ).sort(),
  },

  CANADA: {
    name: "40: Canada",
    groups: canadaGroups,
    regions: canadaRegions,
  },
  UK: {
    name: "226: United Kingdom",
    regions: ukRegions,
  },
  SPAIN: {
    name: "200: Spain",
    regions: spainRegions,
  },
};
