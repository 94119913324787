import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Cart",
    dataIndex: "cart",
    key: "cart",
  },
  {
    title: "Page Views",
    dataIndex: "pageViews",
    key: "pageViews",
    render: value => {
      return (
        <span>
          {value ? (
            <CheckOutlined
              style={{ marginRight: 4, color: "green" }}
            ></CheckOutlined>
          ) : (
            <CloseOutlined
              style={{ marginRight: 4, color: "red" }}
            ></CloseOutlined>
          )}
          {value}
        </span>
      );
    },
  },
  {
    title: "Transactions",
    dataIndex: "transactions",
    key: "transactions",
    render: value => {
      return (
        <span>
          {value ? (
            <CheckOutlined
              style={{ marginRight: 4, color: "green" }}
            ></CheckOutlined>
          ) : (
            <CloseOutlined
              style={{ marginRight: 4, color: "red" }}
            ></CloseOutlined>
          )}
          {value}
        </span>
      );
    },
  },
  {
    title: "Signups",
    dataIndex: "signups",
    key: "signups",
    render: value => {
      return (
        <span>
          {value ? (
            <CheckOutlined
              style={{ marginRight: 4, color: "green" }}
            ></CheckOutlined>
          ) : (
            <CloseOutlined
              style={{ marginRight: 4, color: "red" }}
            ></CloseOutlined>
          )}
          {value}
        </span>
      );
    },
  },
];

export const CampaignLauncherViewTagActivities = ({ activities }) => {
  const [dataSouce, setDataSource] = useState([]);

  useEffect(() => {
    if (activities) {
      const tags = [];
      Object.keys(activities)
        .filter(key => activities[key].event)
        .forEach(key => {
          const tag = activities[key];
          tags.push({
            key: key,
            name: tag.event ? tag.event.name : "",
            cart: tag.event ? tag.event.cart : "",
            pageViews: tag.activities ? tag.activities.pageViews : 0,
            transactions: tag.activities ? tag.activities.transactions : 0,
            signups: tag.activities ? tag.activities.signups : 0,
          });
        });

      setDataSource(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  return (
    <div>
      <Table columns={columns} dataSource={dataSouce} />
    </div>
  );
};
