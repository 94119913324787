import React, { useEffect, useState } from "react";
import { AutoComplete, Typography, Space } from "antd";

const TargetingAddressSearch = ({
  addresses,
  selectedTargetingLocations,
  handleAddTargetFromAddresses,
  targetingLocationList,
}) => {
  const { Text } = Typography;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [targetingLocationAudience, setTargetingLocationAudience] = useState(
    []
  );

  useEffect(() => {
    if (targetingLocationList && targetingLocationList.length) {
      setTargetingLocationAudience(
        targetingLocationList.filter(
          location => location.audienceType === "ADDRESS"
        )
      );
    } else {
      setTargetingLocationAudience(targetingLocationList);
    }
  }, [targetingLocationList]);

  const getFilteredOptions = options => {
    return options
      .filter(address => address.audienceType === "TARGETING")
      .filter(address => {
        // Remove addresses that are already in the targetingLocationAudience
        const matchedAddress = targetingLocationAudience.find(
          location => location.name === address.name
        );
        return !matchedAddress;
      })
      .reduce((acc, item) => {
        const orgName = item.org.name; // Extract org name from orgs array
        if (Array.isArray(acc)) {
          // Add safeguard to check if acc is an array
          const existingGroup = acc.find(group => group.label === orgName);
          // const locations = getAudienceValidLocation(item);
          const newItem = renderItem(item.name, 0, item.id); // Create a new item with id and name properties
          if (existingGroup) {
            existingGroup.options.push(newItem); // Push the new item to options array of existing group
          } else {
            acc.push({
              label: orgName,
              options: [newItem], // Create a new options array with the new item
            });
          }
        }
        return acc; // Return the accumulated value in each iteration
      }, []);
  };

  const handleSearch = value => {
    if (addresses && addresses.length) {
      const matchedOptions = addresses.filter(address =>
        address.name.toLowerCase().match(value.toLowerCase())
      );
      if (matchedOptions) {
        setOptions(getFilteredOptions(matchedOptions));
      }
    }
  };

  const handleSelect = id => {
    const matchedAddress = addresses.find(address => address.id === id);
    handleAddTargetFromAddresses([matchedAddress]);
    setValue("");
  };

  const handleChange = data => {
    setValue(data);
  };

  const renderItem = (title, count, id) => ({
    key: id,
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
      </div>
    ),
  });

  useEffect(() => {
    if (addresses && addresses.length) {
      setOptions(getFilteredOptions(addresses));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, selectedTargetingLocations, targetingLocationAudience]);

  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          width: "100%",
        }}
      >
        <Text strong>Locations</Text>
        <AutoComplete
          value={value}
          options={options}
          onSelect={(_, option) => {
            handleSelect(option.key);
          }}
          onSearch={handleSearch}
          onChange={handleChange}
          placeholder="Search and Select Address"
          style={{
            width: "100%",
          }}
        />
      </Space>
    </div>
  );
};

export default TargetingAddressSearch;
