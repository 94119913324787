import gql from "graphql-tag";

const GET_SUMMARY_DEVICE_CLICKS = gql`
  query getSummaryDeviceClicks(
    $orgId: String!
    $parentOrgId: String
    $advertiserIds: [String]
    $campaignOrderIds: [String]
  ) {
    getSummaryDeviceClicks(
      orgId: $orgId
      parentOrgId: $parentOrgId
      advertiserIds: $advertiserIds
      campaignOrderIds: $campaignOrderIds
    )
  }
`;

export default GET_SUMMARY_DEVICE_CLICKS;
