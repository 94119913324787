import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table } from "antd";
import { useDealIdContext } from "../../../../core/components/dealIds/useDealIdContext";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";

const CampaignOrderQueueDealIdsDialog = ({
  visible,
  setVisible,
  onAddSelections,
  selectedDealIds,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { fetchDealIds, dealIds, loadingDealIds } = useDealIdContext();
  const { currentCampaignOrder } = useCampaignRequestorContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    const selectedDealIds = dealIds.filter(dealId =>
      selectedRowKeys.includes(dealId.id)
    );
    onAddSelections(selectedDealIds);
    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFetchDealIds = () => {
    fetchDealIds({
      variables: {
        id: currentCampaignOrder.advertiser.id,
      },
    });
  };

  useEffect(() => {
    handleFetchDealIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDealIds) {
      const keys = [];
      selectedDealIds.forEach(selectedDealId => {
        const dealId = dealIds.find(d => d.id === selectedDealId.id);
        if (dealId) {
          keys.push(dealId.id);
        }
      });
      setSelectedRowKeys(keys);
    }
  }, [dealIds, selectedDealIds]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>DEAL IDS</span>
            <div>
              <Button
                type="default"
                disabled={selectedRowKeys.length === 0}
                onClick={() => handleAddSelections()}
              >
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        {loadingDealIds ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey={record => record.id}
            rowSelection={rowSelection}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Deal ID",
                dataIndex: "dealId",
                key: "dealId",
              },
            ]}
            dataSource={dealIds}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignOrderQueueDealIdsDialog;
