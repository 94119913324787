import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Row, Col, Tooltip } from "antd";
import moment from "moment";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";
// Global Styles
import {
  PrimaryTableRowText,
  StandardTableRowText,
} from "../../../../shared/globalStyling/styledText";
const Frag = React.Fragment;

export const AudienceDetailsSegmentsTable = withRouter(
  ({ segmentTargets, selectTableRow, selectedGeoTargets }) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "19%",
        render: (text, record) => {
          if (!text) {
            return "";
          }
          return (
            <Frag>
              <Row type="flex" justify="start">
                <Col>
                  <PrimaryTableRowText
                    text={
                      text.length > 35 ? (
                        <Tooltip title={text}>
                          {text.slice(0, 36).concat("...")}
                        </Tooltip>
                      ) : (
                        text
                      )
                    }
                  />
                </Col>
              </Row>
            </Frag>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "19%",
        render: (text, record) => {
          if (!text) {
            return "";
          }
          return (
            <Frag>
              <Row type="flex" justify="start">
                <Col>
                  <StandardTableRowText
                    text={
                      text.length > 51 ? (
                        <Tooltip title={text}>
                          {text.slice(0, 51).concat("...")}
                        </Tooltip>
                      ) : (
                        text
                      )
                    }
                  />
                </Col>
              </Row>
            </Frag>
          );
        },
      },
      {
        title: "Date Range",
        dataIndex: "start",
        key: "daterange",
        width: "19%",
        render: (text, record) => {
          return (
            <div style={{ minWidth: "80px" }}>
              {record.start ? moment(record.start).format("MM/DD/YY") : ""}
              {" - "}
              {record.end ? moment(record.end).format("MM/DD/YY") : ""}
            </div>
          );
        },
      },
      {
        title: "Number of Targets",
        dataIndex: "end",
        key: "shapes",
        width: "19%",
        render: (text, record) => {
          const shapeCount =
            record.polygons.length +
            record.circles.length +
            record.eventTags.length;
          return <div style={{ minWidth: "80px" }}>{shapeCount}</div>;
        },
      },
      {
        title: "Observations",
        dataIndex: "observations",
        key: "observations",
        width: "19%",
        render: (text, row) => {
          return <PrimaryTableRowText text={formatWholeNumber(text)} />;
        },
      },
      {
        title: "Last updated",
        dataIndex: "updatedAt",
        key: "updatedAt",
        width: "19%",
        render: (text, row) => {
          return (
            <PrimaryTableRowText
              text={text ? moment(text).format("MMM Do") : ""}
            />
          );
        },
      },
    ];

    const rowSelection = {
      onSelect: (record, selected, selectedRows) => {
        return selectTableRow(selectedRows);
      },
      selectedRowKeys: selectedGeoTargets.map(row => row.key),
      columnWidth: "5%",
    };

    return (
      <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
        <Table
          rowKey={record => record.key}
          rowSelection={rowSelection}
          dataSource={segmentTargets}
          columns={columns}
          scroll={{ x: 700 }}
          pageSize={10}
          bordered={false}
        />
      </div>
    );
  }
);
