import gql from "graphql-tag";
import { LocationFields } from "../Fragments/LocationFragments";

const CREATE_LOCATION = gql`
  ${LocationFields}
  mutation createLocation($data: LocationCreateInput!) {
    createLocation(data: $data) {
      ...LocationFields
    }
  }
`;

export default CREATE_LOCATION;
