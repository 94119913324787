import gql from "graphql-tag";
const PAID_SEARCH_AGGREGATED = gql`
  query paidSearchSummaryAggregated($id: ID!) {
    paidSearchSummary(where: { id: $id }) {
      id
      startDate
      endDate
      aggregateData {
        impressions
        clicks
        cost
        revenue
        cpc
        cpm
        ctr
        roas
        conversions
        conversionRate
        byDate(orderBy: date_ASC) {
          impressions
          clicks
          cost
          revenue
          cpc
          cpm
          ctr
          roas
          spend
          conversions
          conversionRate
          date
          xAxis
        }
      }
    }
  }
`;

export default PAID_SEARCH_AGGREGATED;
