import * as turf from "@turf/turf";
import { isEmpty } from "lodash";

// CONFIGS
import { MapConfig } from "../configs/mapConfigs";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";

export const getFocusPoints = ({ currentLocationId, locations, points }) => {
  if (currentLocationId) {
    const currentLocation = locations.find(
      location => location.id === currentLocationId
    );

    if (currentLocation) {
      return points.filter(point => point.name === currentLocation.name);
    }
  }
  return points;
};

export const getLatLngBounds = ({ latLngs, google }) => {
  // this function finds the farthest ne and sw coordinates of the return geocodes,
  // gets the bounds, and finds the center.
  const lats = latLngs.map(pos => pos.location.lat());
  const lngs = latLngs.map(pos => pos.location.lng());

  const sw = new google.maps.LatLng(Math.min(...lats), Math.min(...lngs));
  const ne = new google.maps.LatLng(Math.max(...lats), Math.max(...lngs));

  return new google.maps.LatLngBounds(sw, ne);
};

export const getShapes = ({ google, geoTargets, map, latLngs }) => {
  const newShapes = [];
  const points = [];

  geoTargets &&
    geoTargets.forEach(geoTarget => {
      geoTarget &&
        geoTarget.circles &&
        geoTarget.circles.forEach((circle, i) => {
          let count = 0;

          points.push({
            id: geoTarget.id,
            name: geoTarget.name,
            location: new google.maps.LatLng(
              circle.coordinate.lat,
              circle.coordinate.lng
            ),
          });

          const overlayCircle = new google.maps.Circle({
            center: {
              lat: circle.coordinate.lat,
              lng: circle.coordinate.lng,
            },
            radius: circle.radius * 1000, // KM -> M,
            ...MapConfig.circleOverlay,
          });

          overlayCircle.setMap(map);

          if (latLngs) {
            const locationData = latLngs.find(
              loc => loc.GEOTARGETID === geoTarget.id
            );

            if (locationData) {
              count = locationData.COUNT;
            }
          }

          newShapes.push({
            id: geoTarget.id,
            name: geoTarget.name,
            type: "circle",
            overlay: overlayCircle,
            count,
          });
        });
      geoTarget &&
        geoTarget.polygons &&
        geoTarget.polygons.forEach(polygon => {
          let pathCoordinates;
          let count = 0;

          if (polygon.geometry && polygon.geometry.coordinates) {
            polygon.geometry.coordinates.forEach(coordinates => {
              const features = turf.featureCollection(
                coordinates.map(coordinatePair => turf.point(coordinatePair))
              );
              const center = turf.center(features);
              coordinates.forEach(([lng, lat]) =>
                points.push({
                  id: geoTarget.id,
                  name: geoTarget.name,
                  location: new google.maps.LatLng(
                    center.geometry.coordinates[1],
                    center.geometry.coordinates[0]
                  ),
                })
              );
            });
          }
          if (!isEmpty(polygon.geometry)) {
            pathCoordinates = polygon.geometry.coordinates[0].map(coord => {
              return { lat: coord[1], lng: coord[0] };
            });
          }

          const overlayPolygons = new google.maps.Polygon({
            paths: pathCoordinates || polygon.coordinates,
            ...MapConfig.polygonOverlay,
          });

          overlayPolygons.setMap(map);

          if (latLngs) {
            const locationData = latLngs.find(
              loc => loc.GEOTARGETID === geoTarget.id
            );

            if (locationData) {
              count = locationData.COUNT;
            }
          }

          newShapes.push({
            id: geoTarget.id,
            name: geoTarget.name,
            type: "polygon",
            overlay: overlayPolygons,
            count,
          });
        });
    });

  return { newShapes, points };
};

export const drawOverlay = ({
  currentLocationId,
  google,
  geoTargets,
  map,
  points,
}) => {
  const focusPoints = getFocusPoints({
    currentLocationId,
    locations: geoTargets,
    points,
  });
  const bounds = getLatLngBounds({ latLngs: focusPoints, google });

  if (geoTargets && geoTargets.length) map.fitBounds(bounds);
};

export const infoWindowLayout = ({ name, count, observations }) => {
  return (
    `<div>` +
    `<h5>Location: ${name}</h5>` +
    `<h5 style={{ marginBottom: 0 }}>Walk-ins: ${count}</h5>` +
    `<h5 style={{ marginBottom: 0 }}>Observations: ${observations}</h5>` +
    `</div>`
  );
};

export const attachShapeInfoWindow = ({
  observationsByGeoTarget,
  points,
  newShapes,
  google,
  map,
}) => {
  newShapes.forEach(shape => {
    const currentGeoTaraget = observationsByGeoTarget.find(
      geoTarget => geoTarget.GEO_TARGET_ID === shape.id
    );
    const currentPoint = points.find(point => point.id === shape.id);

    const { name, count } = shape;
    const { overlay } = shape;

    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowLayout({
        name,
        count,
        observations: currentGeoTaraget ? currentGeoTaraget.TOTAL : 0,
      }),
      position: currentPoint.location,
      disableAutoPan: true,
    });

    overlay.addListener("mouseover", () => {
      infoWindow.open(map);
    });

    overlay.addListener("mouseout", () => {
      setTimeout(() => {
        infoWindow.close();
      }, 100);
    });
  });
};

// compute heat weight based on the count of walk ins
// if count is lesser than 9, increase base multiplier based on largest count
// so we would retain the circular shape without fiddling with the maxIntensity
export const computeHeatMapWeight = (count, largestCount) => {
  const multiplier = count < 9 ? largestCount * 2 : largestCount / 2;
  return count * multiplier;
};

// TODO: Refactor to be confurable received latlang and address as params
// DEPRECEATED: Not configurable instead use getGeoCodes()
export const getGeoCode = async ({ latLng, google }) => {
  try {
    const geocoder = new google.maps.Geocoder();
    return await geocoder.geocode({
      latLng,
    });
  } catch (error) {
    return null;
  }
};

export const getGeoCodes = async address => {
  return new Promise(resolve => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapsConfig.apiKey}`;

    return fetch(url)
      .then(res => res.json())
      .then(result => {
        resolve(result);
      });
  });
};

export const getFormattedAddress = ({ result }) => {
  const { formattedAddress } = MapConfig;
  const [
    address,
    state,
    city,
    zip,
    county,
    country,
  ] = formattedAddress.map(property =>
    getGeoCodeAddressComponent({ property, result })
  );

  return { address, state, city, zip, county, country };
};

export const getGeoCodeAddressComponent = ({ property, result }) => {
  switch (property) {
    case "address":
      const streetNumberComponent = result.address_components.find(res =>
        res.types.includes("street_number")
      );
      const streetComponent = result.address_components.find(res =>
        res.types.includes("route")
      );

      return `${streetNumberComponent ? streetNumberComponent.long_name : ""} ${
        streetComponent ? streetComponent.long_name : ""
      }`;

    case "state":
      const stateComponent = result.address_components.find(res =>
        res.types.includes("administrative_area_level_1")
      );
      return `${stateComponent ? stateComponent.long_name : ""}`;

    case "zip":
      const zipComponent = result.address_components.find(res =>
        res.types.includes("postal_code")
      );
      return `${zipComponent ? zipComponent.long_name : ""}`;

    case "city":
      const cityComponent = result.address_components.find(res =>
        res.types.includes("locality")
      );
      return `${cityComponent ? cityComponent.long_name : ""}`;

    case "county":
      const countyComponent = result.address_components.find(res =>
        res.types.includes("administrative_area_level_2")
      );
      return `${countyComponent ? countyComponent.long_name : ""}`;

    case "country":
      const countryComponent = result.address_components.find(res =>
        res.types.includes("country")
      );
      return `${countryComponent ? countryComponent.short_name : ""}`;

    default:
      break;
  }
  return "";
};
