import gql from "graphql-tag";

const LIST_CAMPAIGNORDERS_CONNECTION = gql`
  query campaignOrdersConnection($where: CampaignOrderWhereInput!) {
    campaignOrdersConnection(where: $where) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          budget
          orgs {
            id
            name
          }
        }
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default LIST_CAMPAIGNORDERS_CONNECTION;
