import gql from "graphql-tag";

const CREATE_AUDIENCE = gql`
  mutation createAudience(
    $name: String!
    $description: String!
    $type: AudienceType!
    $tags: [String!]
    $geoTargets: [GeoTargetWhereUniqueInput!]!
    $eventsTargets: [EventsTargetWhereUniqueInput!]!
    $orgid: ID!
  ) {
    createAudience(
      data: {
        name: $name
        description: $description
        type: $type
        tags: { set: $tags }
        geoTargets: { connect: $geoTargets }
        eventsTargets: { connect: $eventsTargets }
        orgs: { connect: { id: $orgid } }
      }
    ) {
      id
      name
      description
      tags
      geoTargets {
        id
      }
      eventsTargets {
        id
      }
      type
    }
  }
`;

export default CREATE_AUDIENCE;
