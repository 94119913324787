import React from "react";
import { Select } from "antd";

const SelectCampaigns = ({
  selectedCIDs,
  setSelectedCIDs,
  campaignDataOptions,
}) => {
  const handleChange = e => {
    setSelectedCIDs(e);
  };

  const options = [];
  for (let i = 0; i < campaignDataOptions.length; i++) {
    const value = `${campaignDataOptions[i].id}`;
    const label = `${campaignDataOptions[i].name}`;
    options.push({
      value,
      label,
    });
  }

  return (
    <Select
      showSearch
      mode="multiple"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: "100%" }}
      maxTagTextLength={12}
      placeholder="Campaign"
      value={selectedCIDs}
      onChange={handleChange}
      options={options}
    />
  );
};

export default SelectCampaigns;
