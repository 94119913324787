const csvTemplate = {
  rows: [
    ["NAME", "RADIUS", "ADDRESS", "LAT", "LON"],
    ["MediaJel", "50", "1601 N Main St Suite", "14.7980323", "121.0449656"],
  ],
  templateName: "MJ_GeoTarget_Template",
};

export const CampaignConfig = {
  csvTemplate,
};
