import { isEmpty, omitBy, cloneDeep } from "lodash";
import moment from "moment";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import JSZip from "jszip";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import * as turf from "@turf/turf";

import {
  campaignsText,
  defaultCurrencyFormat,
} from "../utils/constants/constants";
import placeholder from "../../assets/icons/photo/placeholder.png";

const {
  exCreative,
  exLocation,
  exCampaign,
  exAudiences,
  exPublisher,
} = campaignsText;

//Format Currency helpers converts the value on USD to specified currecny format on OrgConfig
export const formatCurrency = (
  number,
  currencyFormat = defaultCurrencyFormat
) => {
  try {
    let convertedValue = number;
    if (currencyFormat.key !== "USD") {
      convertedValue = number * currencyFormat.conversionValue;
    }
    const currency = new Intl.NumberFormat("en-US", {
      style: "decimal",
      currency: currencyFormat.key,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: "narrowSymbol",
    }).format(convertedValue);
    return `${currencyFormat.display}${currency}`;
  } catch (err) {
    console.log(err);
  }
};
export const formatCurrency2SigFig = (
  number,
  currencyFormat = defaultCurrencyFormat
) => {
  let convertedValue = number;
  if (currencyFormat.key !== "USD") {
    convertedValue = number * currencyFormat.conversionValue;
  }

  const currency = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: currencyFormat.key,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(convertedValue);
  return `${currencyFormat.display}${currency}`;
};
export const formatNumber = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

export const formatWholeNumber = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};
export const formatWholeNumberNaN = number => {
  return isNaN(number)
    ? 0
    : new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number);
};
export const formatNumber2SigFig = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};
export const formatNumber3SigFig = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(number);
};
export const formatNumber4SigFig = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(number);
};
export const formatNumber5SigFig = number => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  }).format(number);
};

export const countSigFigs = value => {
  return Number(value)
    .toExponential()
    .replace(/^([0-9]+)\.?([0-9]+)?e[+\-0-9]*$/g, "$1$2").length;
};

export const needsDataCallback = campaign => {
  if (!isEmpty(campaign.placements)) {
    if (
      campaign.placements[campaign.placements.length - 1]["byAdExchanges"]
        .length &&
      campaign.placements[campaign.placements.length - 1]["byDates"].length &&
      campaign.placements[campaign.placements.length - 1]["byDayOfWeek"]
        .length &&
      campaign.placements[campaign.placements.length - 1]["byDevice"].length
    ) {
      return campaign;
    }
  } else {
    return null;
  }
  const needsDataArray = campaign.placements.filter(placement => {
    if (
      !isEmpty(placement["byAdExchanges"]) &&
      !isEmpty(placement["byDates"]) &&
      !isEmpty(placement["byDayOfWeek"]) &&
      !isEmpty(placement["byDevice"])
    ) {
      return placement;
    }
    return null;
  });
  if (needsDataArray.length) {
    return campaign;
  }
  return null;
};

export const clicksFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;

      if (aggData) {
        const { aggClicks, aggEvents } = aggData;
        if (aggClicks) {
          count = count + aggClicks;
        }

        if (aggEvents.click) {
          count = count + aggEvents.click;
        }
      }
    }
  });

  return count;
};

export const creativeDataFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;

      if (aggData) {
        const { aggClicks, aggEvents } = aggData;
        if (aggClicks) {
          count = count + aggClicks;
        }

        if (aggEvents.click) {
          count = count + aggEvents.click;
        }
      }
    }
  });

  return count;
};

export const isDemoWalkins = chartData => {
  if (chartData && chartData.creatives) {
    chartData.creatives.forEach((c, i) => {
      c.value = `${exCreative} - ${i}`;
      c.xAxis = `${exCreative} - ${i}`;
      c.creativeUrl = placeholder;
    });
  }

  if (chartData && chartData.publishers) {
    chartData.publishers.forEach((c, i) => {
      c.value = `${exPublisher} - ${i}`;
      c.xAxis = `${exPublisher} - ${i}`;
      c.creativeUrl = placeholder;
    });
  }

  if (chartData && chartData.walkinByLocationComplete) {
    chartData.walkinByLocationComplete.forEach((c, i) => {
      c.LOCATION = `${exLocation} - ${i}`;
    });
  }

  if (chartData && chartData.walkinByCreative) {
    chartData.walkinByCreative.forEach((c, i) => {
      c.PROVIDER_CREATIVE_NAME = `${exCreative} - ${i}`;
    });
  }

  if (chartData && chartData.walkinByLineItem) {
    chartData.walkinByLineItem.forEach((c, i) => {
      c.CAMPAIGN_NAME = `${exCampaign} - ${i}`;
    });
  }

  return chartData;
};

export const isDemoAudience = audiences => {
  if (audiences) {
    audiences.forEach((c, i) => {
      c.name = `${exAudiences} - ${i}`;
    });
  }

  return audiences;
};

export const impressionsFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggImpressions } = aggData;
        if (aggImpressions) {
          count = count + aggImpressions;
        }
      }
    }
  });

  return count;
};

export const ctrFromAggregateData = aggregateData => {
  let ctr = 0;
  let clicks = 0;
  let impressions = 0;

  if (aggregateData) {
    const { overallData } = aggregateData;

    if (overallData) {
      const { aggEvents, aggImpressions, aggClicks } = overallData;

      if (aggClicks && aggImpressions) {
        clicks = clicks + aggClicks;
        impressions = impressions + aggImpressions;
      }

      if (aggEvents.click && aggImpressions) {
        clicks = clicks + aggEvents.click;
        impressions = impressions + aggImpressions;
      }
    }
  }
  if (clicks && impressions) {
    ctr = clicks / impressions;
  }

  return ctr * 100;
};

export const ecpmFromAggregateData = aggregateData => {
  let ecpm = 0;
  let cost = 0;
  let impressions = 0;

  if (aggregateData) {
    const { overallData } = aggregateData;
    if (overallData) {
      const { aggImpressions, aggCost } = overallData;
      if (aggCost && aggImpressions) {
        cost = cost + aggCost;
        impressions = impressions + aggImpressions;
      }
    }
  }

  if (cost && impressions) {
    ecpm = (cost * 1000) / impressions;
  }

  return ecpm;
};

export const cpmFromPlacementsAggregate = placements => {
  let cpm = 0;
  let totalcost = 0;
  let impressions = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggImpressions, aggTotalCost } = aggData;
        if (aggTotalCost && aggImpressions) {
          totalcost = totalcost + aggTotalCost;
          impressions = impressions + aggImpressions;
        }
      }
    }
  });

  if (totalcost && impressions) {
    cpm = (totalcost * 1000) / impressions;
  }

  return cpm;
};

export const totalSpentFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggTotalCost } = aggData;
        if (aggTotalCost) {
          count = count + aggTotalCost;
        }
      }
    }
  });

  return count;
};

export const dailyTotalSpentFromAggregate = aggregateData => {
  if (isEmpty(aggregateData)) return 0;
  const { dayPacingData } = aggregateData;
  if (dayPacingData) return dayPacingData.aggTotalCost;
  return 0;
};

export const totalSpentFromAggregate = aggregateData => {
  if (isEmpty(aggregateData)) return 0;
  const { overallData } = aggregateData;
  if (overallData) return overallData.aggTotalCost;
  return 0;
};

export const totalbudget = data => {
  let totalbudget = 0;
  data.forEach(item => {
    totalbudget = totalbudget + item.budgetTotal;
  });

  return formatCurrency(totalbudget);
};

export const totalSpent = data => {
  let totalspent = 0;
  data.forEach(item => {
    const spentTotalByPlacements = totalSpentFromPlacementsAggregate(
      item.placements
    );
    totalspent = totalspent + spentTotalByPlacements;
  });

  return formatCurrency(totalspent);
};

export const totalImpressions = data => {
  let totalImpressions = 0;
  data.forEach(item => {
    const totalCampaignImpressions = totalImpressionsFromPlacementsAggregate(
      item.placements
    );
    totalImpressions = totalImpressions + totalCampaignImpressions;
  });

  return totalImpressions;
};

export const totalCost = data => {
  let totalCost = 0;
  data.forEach(item => {
    const totalCampaignCost = totalCostFromPlacementsAggregate(item.placements);
    totalCost = totalCost + totalCampaignCost;
  });

  return totalCost;
};

export const totalImpressionCost = data => {
  let totalCost = 0;
  data.forEach(item => {
    const totalCampaignCost = totaImpressionCostFromPlacementsAggregate(
      item.placements
    );
    totalCost = totalCost + totalCampaignCost;
  });

  return totalCost;
};

export const cpm = data => {
  /*
 CPM is the cost for every 1,000th impression when you are buying ad impressions (CPM buying method).
 eCPM is the cost for every 1,000th ad impression, regardless of what buying method are being used
 (fixed price, CPM, CPC, CPA or CPO). The main difference between CPM and eCPM is the data you use
 to calculate it.
 */

  // cpm = (totalcost * 1000) / impressions

  const impressions = totalImpressions(data);
  const totalcost = totalImpressionCost(data);

  if (!impressions || impressions === 0) {
    return formatNumber(0);
  }

  const cpm = (totalcost * 1000) / impressions;

  return formatNumber(cpm);
};

export const ecpm = data => {
  /*
 CPM is the cost for every 1,000th impression when you are buying ad impressions (CPM buying method).
 eCPM is the cost for every 1,000th ad impression, regardless of what buying method are being used
 (fixed price, CPM, CPC, CPA or CPO). The main difference between CPM and eCPM is the data you use
 to calculate it.
 */

  // ecpm = (cost * 1000) / impressions
  const impressions = totalImpressions(data);
  const cost = totalCost(data);

  if (!impressions || impressions === 0) {
    return formatNumber(0);
  }

  const ecpm = (cost * 1000) / impressions;

  return formatNumber(ecpm);
};

export const totalImpressionsFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggImpressions } = aggData;
        if (aggImpressions) {
          count = count + aggImpressions;
        }
      }
    }
  });

  return count;
};

export const totaImpressionCostFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggCost } = aggData;
        if (aggCost) {
          count = count + aggCost;
        }
      }
    }
  });

  return count;
};

export const totalCostFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggTotalCost } = aggData;
        if (aggTotalCost) {
          count = count + aggTotalCost;
        }
      }
    }
  });

  return count;
};

export const costFromPlacementsAggregate = placements => {
  let count = 0;
  placements.forEach(placement => {
    if (placement.aggregateData) {
      const { aggData } = placement.aggregateData;
      if (aggData) {
        const { aggCost } = aggData;
        if (aggCost) {
          count = count + aggCost;
        }
      }
    }
  });

  return count;
};

export const flattenPlacements = placements => {
  //Takes an array of placements containing time series data
  //Flattens into a single depth array by fields we are interested in
  // startDay,endDay,startDate,endDate,aggEvents,aggImpressions,aggCost,

  if (!placements) {
    return [];
  }
  const flattened = [];

  placements.forEach(placement => {
    const { timeSeriesData } = placement;
    timeSeriesData.forEach(dataunit => {
      const {
        aggCost,
        aggEvents,
        aggImpressions,
        aggClicks,
        start,
        end,
        deviceData,
        exchangeData,
        aggTotalCost,
      } = dataunit;
      const startDay = moment(start).format("dddd");
      const endDay = moment(end).format("dddd");
      const startDate = moment(start).format("MMM Do YY");
      const endDate = moment(end).format("MMM Do YY");

      const record = {
        startDay,
        endDay,
        startDate,
        endDate,
        aggEvents,
        aggImpressions,
        aggCost,
        aggTotalCost,
        deviceData,
        exchangeData,
        aggClicks,
      };
      flattened.push(record);
    });
  });

  return flattened;
};

export const flattenPlacementsByTime = placements => {
  //Takes an array of placements containing time series data
  //Flattens into a single depth array by fields we are interested in

  if (!placements) {
    return [];
  }
  const flattened = [];

  placements.forEach(placement => {
    const { timeSeriesData } = placement;
    timeSeriesData.forEach(dataunit => {
      const {
        aggCost,
        aggImpressions,
        aggClicks,
        start,
        aggTotalCost,
      } = dataunit;

      const record = {
        time: moment(start).format("YYYY/MM/DD hh:mm"),
        clicks: aggClicks,
        impressions: aggImpressions,
        cost: aggCost,
        totalCost: aggTotalCost,
      };

      flattened.push(record);
    });
  });

  const sortedbydate = flattened.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(a.time) - new Date(b.time);
  });

  return sortedbydate;
};

export const getXDimensionLabels = (data, xDimensionKey) => {
  // function to retrieve A set of labels for the charts x axis
  // currently being used for time only
  const arrayToReturn = [];
  data.forEach(item => {
    if (!arrayToReturn.includes(item[xDimensionKey])) {
      arrayToReturn.push(item[xDimensionKey]);
    }
  });
  return arrayToReturn;
};

export const sortByXLabels = (xDimensionLabels, flattened, key) => {
  const arrayToReturn = [];

  xDimensionLabels.forEach(label => {
    const sorted = flattened.filter(item => {
      return item[key] === label;
    });

    arrayToReturn.push(sorted);
  });

  return arrayToReturn;
};

export const aggregateValues = (sorteddata, xAxisKey) => {
  const chartArray = [];

  sorteddata.forEach(arrayreductionvalue => {
    let xAxis = "";
    let cost = 0;
    let clicks = 0;
    let impressions = 0;
    let totalCost = 0;
    arrayreductionvalue.forEach(arraymember => {
      cost = cost + arraymember.aggCost;
      totalCost = totalCost + arraymember.aggTotalCost;
      xAxis = arraymember[xAxisKey];
      if (arraymember.aggClicks) {
        clicks = arraymember.aggClicks
          ? clicks + arraymember.aggClicks
          : clicks;
      }
      if (arraymember.aggEvents.click) {
        clicks = arraymember.aggEvents.click
          ? clicks + arraymember.aggEvents.click
          : clicks;
      }

      impressions = impressions + arraymember.aggImpressions;
    });

    chartArray.push({ xAxis, cost, clicks, impressions, totalCost });
  });

  return chartArray;
};

export const prepareChartXDimension = (flattened, xDimensionKey) => {
  const xDimensionLabels = getXDimensionLabels(flattened, xDimensionKey);
  const sortedByDimension = sortByXLabels(
    xDimensionLabels,
    flattened,
    xDimensionKey
  );
  const chartData = aggregateValues(sortedByDimension, xDimensionKey);

  return chartData;
};

export const getXDimensionLabelsByTargetDimension = (data, xDimensionKey) => {
  // function to retrieve A set of labels for the charts x axis
  // currently being used for Device and Exchange only
  const arrayToReturn = [];
  data.forEach(item => {
    const deviceData = item[xDimensionKey];
    deviceData.forEach(deviceUnit => {
      if (!arrayToReturn.includes(deviceUnit.value)) {
        arrayToReturn.push(deviceUnit.value);
      }
    });
  });
  return arrayToReturn;
};

export const sortByXLabelsTargetDimension = (
  xDimensionLabels,
  flattened,
  key
) => {
  const arrayToReturn = [];

  xDimensionLabels.forEach(label => {
    const sortedArray = [];
    flattened.forEach(item => {
      const test = item[key].filter(filteredItem => {
        return filteredItem.value === label;
      });

      sortedArray.push(...test);
    });
    arrayToReturn.push(sortedArray);
  });

  return arrayToReturn;
};

export const calculateChartValues = sorteddata => {
  const chartArray = [];

  sorteddata.forEach(arrayreductionvalue => {
    let xAxis = "";
    let cost = 0;
    let clicks = 0;
    let impressions = 0;
    let totalCost = 0;

    arrayreductionvalue.forEach(arraymember => {
      cost = cost + arraymember.cost;
      totalCost = totalCost + arraymember.totalCost;
      xAxis = arraymember.value;
      clicks = arraymember.events.click
        ? clicks + arraymember.events.click
        : clicks;
      clicks = arraymember.clicks ? clicks + arraymember.clicks : clicks;

      impressions = impressions + arraymember.impressions;
    });

    chartArray.push({ xAxis, cost, clicks, impressions, totalCost });
  });

  return chartArray;
};

export const prepareChartByTargetDimension = (flattened, xDimensionKey) => {
  const xDimensionLabels = getXDimensionLabelsByTargetDimension(
    flattened,
    xDimensionKey
  );
  const sortedByDimension = sortByXLabelsTargetDimension(
    xDimensionLabels,
    flattened,
    xDimensionKey
  );
  const chartData = calculateChartValues(sortedByDimension, xDimensionKey);
  return chartData;
};

export const ecpmSingleValue = (impressions, cost) => {
  /*
 eCPM is the cost for every 1,000th ad impression, regardless of what buying method are being used
 (fixed price, CPM, CPC, CPA or CPO).
  */

  // ecpm = (cost * 1000) / impressions
  if (cost === 0) {
    return 0;
  }
  const ecpm = (cost * 1000) / impressions;

  return formatNumber(ecpm);
};

export const cpmSingleValue = (impressions, totalcost) => {
  /*
 CPM is the cost for every 1,000th impression when you are buying ad impressions (CPM buying method).
  */

  // cpm = (totalcost * 1000) / impressions

  if (totalcost === 0) {
    return 0;
  }

  const cpm = (totalcost * 1000) / impressions;

  return formatNumber(cpm);
};

export const cpcSingleValue = (clicks, totalcost) => {
  /*
 CPC is the cost per click - clicks vs. total cost.
  */

  // cpc = totalcost / clicks;

  const cpc = totalcost / clicks;

  return formatNumber(cpc);
};

export const ctrSingleValue = (clicks, impressions) => {
  /*
 CTR = clicks / impressions
  */

  const ctr = clicks / impressions;

  return ctr * 100;
};

export const calculateDimensions = data => {
  const dataWithCalculations = data.map(item => {
    const { clicks, impressions, totalCost, cost } = item;
    //for each one this does not work for a single missing click
    //if no clicks on 1
    if (!clicks) {
      item.cpm = cpmSingleValue(impressions, totalCost);
      item.ecpm = ecpmSingleValue(impressions, cost);
      item.clicks = 0;
      item.ctr = 0;
      item.cpc = 0;
      return item;
    }

    item.ctr = ctrSingleValue(clicks, impressions);
    item.cpc = cpcSingleValue(clicks, totalCost);
    item.cpm = cpmSingleValue(impressions, totalCost);
    item.ecpm = ecpmSingleValue(impressions, cost);
    return item;
  });

  return dataWithCalculations;
};

export const getMax = (data, key) => {
  return Math.max(
    ...data.map(item => {
      return item[key] || 0;
    })
  );
};

export const getLeftRightScale = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "cost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: "Cost ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "clicks") : 1000,
            alias: "Clicks",
          }
        : leftY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "impressions") : 1000,
            alias: "Impressions",
          }
        : leftY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: "Total Cost ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "ctr"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ctr") : 1000,
            alias: "Click through ratio (%)",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : leftY === "cpc"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpc") : 1000,
            alias: "Cost per click ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "cpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpm") : 1000,
            alias: "Cost per milli ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "ecpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ecpm") : 1000,
            alias: "Estimated cost per milli ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "transactionCount"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionCount") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "cost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: "Cost ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "clicks") : 1000,
            alias: "Clicks",
          }
        : rightY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "impressions") : 1000,
            alias: "Impressions",
          }
        : rightY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: "Total Cost ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "ctr"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ctr") : 1000,
            alias: "Click through ratio (%)",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : rightY === "cpc"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpc") : 1000,
            alias: "Cost per click ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "cpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpm") : 1000,
            alias: "Cost per milli ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "ecpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ecpm") : 1000,
            alias: "Estimated cost per milli ($)",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "transactionCount"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionCount") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
      formatter: val => {
        return val;
      },
    },
  };
  return scale;
};

export const getLeftRightScaleOverview = (data, leftY, rightY) => {
  const scale = {
    left:
      leftY === "count"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCount") : 1000,
            alias: "Count",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "unique"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalUnique") : 1000,
            alias: "Unique",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
      formatter: val => {
        return `${val}`;
      },
    },
  };
  return scale;
};

export const getScale = data => {
  const scale = {
    cost: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
      alias: "Cost ($)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },
    clicks: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "clicks") : 1000,
      alias: "Clicks",
    },
    impressions: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "impressions") : 1000,
      alias: "Impressions",
    },
    totalCost: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
      alias: "Total Cost ($)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },
    ctr: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "ctr") : 1000,
      alias: "Click through ratio (%)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },
    cpc: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "cpc") : 1000,
      alias: "Cost per click ($)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },
    cpm: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "cpm") : 1000,
      alias: "Cost per milli ($)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },
    ecpm: {
      type: "linear",
      min: 0,
      max: !isEmpty(data) ? getMax(data, "ecpm") : 1000,
      alias: "Estimated cost per milli ($)",
      formatter: val => {
        return `${formatNumber(val)}`;
      },
    },

    xAxis: {
      tickCount: 8,
      formatter: val => {
        return `${val}`;
      },
    },
    time: {
      type: "time",
      tickCount: 8,
      mask: "MM/DD h A",
    },
  };

  return scale;
};

export const parseCreativeTableData = data =>
  data.map((item, i) => ({
    ...item,
    key: i,
    clicks: item.clicks && formatNumber(item.clicks),
    impressions: item.impressions && formatNumber(item.impressions),
    cost: item.cost && formatCurrency2SigFig(item.cost),
    totalCost: item.totalCost && formatCurrency2SigFig(item.totalCost),
    cpc: item.cpc && formatCurrency2SigFig(item.cpc || 0),
    ctr: item.ctr && `${formatNumber(item.ctr || 0)}%`,
  }));

/**
 * @function prepareCTR - parse data for campaings creative CTR chart & table
 * @param {Object} data - creativeData
 * @returns {Object}
 */
export function prepareCTR(data) {
  let totalctr = 0;

  return data
    .slice(0)
    .filter(item => item.impressions !== 0)
    .map(item => {
      totalctr += item.ctr = ctrSingleValue(item.clicks, item.impressions);
      if (item.clicks && item.totalCost)
        item.cpc = cpcSingleValue(item.clicks, item.totalCost);
      return item;
    })
    .map(item => {
      const increase = totalctr - (totalctr - item.ctr);
      item.percentctr = (increase / totalctr) * 100;
      return item;
    })
    .sort((a, b) => b.impressions - a.impressions);
}

export const getOverallPacing = (
  startDate,
  endDate,
  dailyGoal,
  currentOverall
) => {
  // Calculates the overall pacing, which is how a given metric is performing over time
  if (!dailyGoal || !currentOverall || !startDate || !endDate) return 0;
  if (moment().isBefore(startDate)) return null;
  let runTime = moment().isBetween(startDate, endDate)
    ? moment().diff(moment(startDate), "days")
    : moment(endDate).diff(moment(startDate), "days");
  const onPaceAmount = dailyGoal * runTime;
  return Number(((currentOverall / onPaceAmount) * 100).toFixed());
};

export const getDailyPacing = (dailyGoal, dailyTotalSpent) => {
  // Calculates the pacing for the previous day, which is how a given metric is performing over time on the previous day
  if (isEmpty(dailyTotalSpent) || isEmpty(dailyGoal)) return 0;
  return Number(((dailyTotalSpent / dailyGoal) * 100).toFixed());
};

export const getPacingGoal = (startDate, endDate, goal) => {
  // Calculates the pacing goal, which is the amount a certain metric is predicted to be per day when on track to meet the end goal
  const campaignLength = moment(endDate).diff(moment(startDate), "days");
  if (campaignLength < 1 || !goal) return 0;
  return goal / campaignLength;
};

export const getCTRFromAggregate = aggregate => {
  if (!aggregate.aggClicks || !aggregate.aggImpressions) return 0;
  return ((aggregate.aggClicks / aggregate.aggImpressions) * 100).toFixed(2);
};

export const filterPrice = (possibleFields, campaignOrders) => {
  // Filters price related columns from possibleFields and removes price data from campaignOrders
  // - Currently manages Budget and Spent - TODO - move Budget to common columns pending team collaboration
  possibleFields = omitBy({ ...possibleFields }, item => {
    return item.isPriceRelated;
  });

  campaignOrders = [...campaignOrders].map(c => {
    let campaign = cloneDeep(c);
    return {
      ...campaign,
      budgetTotal: 0,
      aggregateData: campaign.aggregateData
        ? {
            ...campaign.aggregateData,
            overallData: {
              ...campaign.aggregateData.overallData,
              aggTotalCost: 0,
              aggCost: 0,
            },
            dayPacingData: {
              ...campaign.aggregateData.dayPacingData,
              aggTotalCost: 0,
              aggCost: 0,
            },
          }
        : null,
    };
  });

  return { possibleFields, campaignOrders };
};

export const filterTrafficker = (possibleFields, campaignOrders) => {
  // Filters trafficker related columns from possibleFields
  // - Currently manages Pacing
  possibleFields = omitBy({ ...possibleFields }, item => {
    return item.isTraffickerRelated;
  });

  return { possibleFields, campaignOrders };
};

export const filterNames = (possibleFields, campaignOrders) => {
  //does not alter possibleFields - obfuscates names only
  campaignOrders = [...campaignOrders].map((c, i) => {
    c.name = `Example Campaign Order ${i}`;
    let campaign = cloneDeep(c);
    return {
      ...campaign,
    };
  });

  return { possibleFields, campaignOrders };
};

export const calculateImpressionsGoal = campaignOrder => {
  const { cpm, budget } = campaignOrder;
  let impressionGoal = 0;

  if (cpm && budget) {
    impressionGoal = (budget / cpm) * 1000;
  }

  return impressionGoal;
};

export const calculateBudgetSpent = campaignOrder => {
  let impressions = 0;
  const { cpm, budget } = campaignOrder;

  try {
    impressions = campaignOrder.aggregateData.overallData.aggImpressions;
  } catch (e) {
    impressions = 0;
  }
  const budgetSpent = cpm * (impressions / 1000);
  return parseInt(budgetSpent > budget ? budget : budgetSpent).toFixed(0);
};

export const campaignOrderPreCalculate = campaignOrder => {
  return {
    ...campaignOrder,
    aggregateData: campaignOrder.aggregateData
      ? {
          ...campaignOrder.aggregateData,
          overallData: {
            ...campaignOrder.aggregateData.overallData,
            lift: !isEmpty(campaignOrder.aggregateData.overallData)
              ? (campaignOrder.aggregateData.overallData.aggVWI /
                  campaignOrder.aggregateData.overallData.totalObservations) *
                  100 || 0
              : 0,
          },
          dayPacingData: {
            ...campaignOrder.aggregateData.dayPacingData,
            lift: !isEmpty(campaignOrder.aggregateData.dayPacingData)
              ? (campaignOrder.aggregateData.dayPacingData.aggVWI /
                  campaignOrder.aggregateData.dayPacingData.totalObservations) *
                  100 || 0
              : 0,
          },
        }
      : null,
    impressionsGoal: calculateImpressionsGoal(campaignOrder),
    budgetSpent: calculateBudgetSpent(campaignOrder),
  };
};

export const kmToFt = radius => {
  return Number(radius) * 3280.8;
};

export const ftToKm = radius => {
  return Number(radius) / 3280.8;
};

export const metersToMiles = radius => {
  return Number(radius) * 0.000621371;
};

export const kmToMiles = radius => {
  return Number(radius) * 0.621371;
};

export const checkLatLonMatch = (lat1, lon1, lat2, lon2) => {
  return (
    Number(lat1) === Number(lat2.toFixed(countSigFigs(lat1))) &&
    Number(lon1) === Number(lon2.toFixed(countSigFigs(lon1)))
  );
};

export const buildCSV = (
  data,
  org,
  titlePrefix = "Global_Campaign_Attribution"
) => {
  let today = new Date()
    .toLocaleDateString("en-US")
    .split("/")
    .join("_");

  const zip = new JSZip();
  const folder = zip.folder(`${titlePrefix}_${org}_${today}`);
  folder.file(`${titlePrefix}_${org}_${today}.csv`, Papa.unparse(data));
  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, `${titlePrefix}_${org}_${today}`);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
};

export const getCostForLineItemsByCampaignOrder = (
  campaignOrder,
  currentUser
) => {
  let priceVisible =
    currentUser &&
    currentUser.role &&
    currentUser.role.org.config &&
    currentUser.role.org.config.priceVisible;
  return campaignOrder.campaigns.map(campaign => {
    let lineItems = (campaign.lineItems || []).map(item => {
      let effectiveCPM = item.cpm || campaign.cpm || campaignOrder.cpm; // inherits CPM from parent

      if (!effectiveCPM) return item;
      if (isEmpty(item.aggregateData)) return item;

      let {
        aggTotalCost: cost,
        aggImpressions: impressions,
      } = item.aggregateData.overallData;
      let revenue = (effectiveCPM * impressions) / 1000;
      if (priceVisible) {
        let profit = revenue - cost;
        return {
          ...item,
          cpm: effectiveCPM,
          revenue,
          cost,
          profit,
        };
      }
      return {
        ...item,
        cpm: effectiveCPM,
        spent: revenue, //Client spend per line item
      };
    });
    return { ...campaign, lineItems };
  });
};

export const isDemoCampaignOrder = campaignOrder => {
  //used to change names of line items, campaign orders for demo accounts

  const name = "Example Campaign Order";
  const lineitem = "Example Line item - 320x480";

  campaignOrder.name = name;

  campaignOrder.campaigns.forEach(c => {
    c.name = name;
    c.lineItems.forEach(l => {
      l.name = lineitem;
    });
  });

  return campaignOrder;
};

export const joinCreativeData = (reportData, prismaData) => {
  const prismaDataMap = {};
  prismaData.forEach(row => (prismaDataMap[row.adProviderId] = row));
  return reportData.map(row => {
    return { ...row, ...(prismaDataMap[row.id] ? prismaDataMap[row.id] : []) };
  });
};

export const hideChartDataPrice = (chartData, chartKeys) => {
  const output = cloneDeep(chartData);
  // kludge - turns off for overallData
  chartKeys.forEach(key => {
    if (key === "overallData") {
      return [];
    }

    if (output[key]) {
      output[key] = output[key].map(row => {
        const {
          totalCost,
          cost,
          cpc,
          cpm,
          ecpm,
          aggCost,
          aggTotalCost,
          DATACOST,
          MEDIACOST,
          ...remainingOptions
        } = row;
        return remainingOptions;
      });
    }
  });

  return output;
};

export const hasExpectedData = (
  chartData,
  requiredChartKeys,
  someChartKeys
) => {
  for (const key of requiredChartKeys) {
    if (!chartData[key]) return false;
    if (typeof chartData[key] === "number" && chartData[key] < 1) return false;
    if (Array.isArray(chartData[key]) && isEmpty(chartData[key])) return false;
    if (chartData[key] === Object(chartData[key]) && isEmpty(chartData[key]))
      return false;
  }

  if (!isEmpty(someChartKeys)) {
    for (const key of someChartKeys) {
      if (chartData[key]) {
        if (typeof chartData[key] === "number" && chartData[key] > 0)
          return true;
        if (Array.isArray(chartData[key]) && !isEmpty(chartData[key]))
          return true;
        if (
          chartData[key] === Object(chartData[key]) &&
          !isEmpty(chartData[key])
        )
          return true;
      }
    }
  }

  return false;
};

export const buildGeoTargetsTableData = geoTargets => {
  // Format data for GeoTargetsTable
  return geoTargets.map((elem, index) => {
    if (elem.circles.length) {
      let location = elem.circles[0].location;
      let radius = elem.circles[0].radius || 0;
      let circle = {
        ...elem,
        index: index,
        key: elem.id,
        name: elem.name || "Circle",
        type: "Circle",
        radius,
      };
      if (location) {
        let formattedAddress = location.formattedAddress || "";
        let streetAddress = formattedAddress.split(",")[0];
        return {
          ...circle,
          name: location.name || "",
          state: location.state || "",
          zip: location.zip || "",
          city: location.city || "",
          address: streetAddress,
        };
      }
      return circle;
    }
    if (elem.polygons.length) {
      const location = elem.polygons[0].location;
      const polygon = {
        ...elem,
        index: index,
        key: elem.id,
        name: elem.name || "Polygon",
        type: "Polygon",
      };
      if (location) {
        const formattedAddress = location.formattedAddress || "";
        const streetAddress = formattedAddress.split(",")[0];
        return {
          ...polygon,
          name: location.name || "",
          state: location.state || "",
          zip: location.zip || "",
          city: location.city || "",
          address: streetAddress,
        };
      }
      return polygon;
    }
    return null;
  });
};

export const buildGeoTargetsTableDataWithAttribution = (
  campaignOrder,
  chartData
) => {
  if (!campaignOrder) return [];
  // Merge attribution data with targets for GeoTargetsTable
  const geoTargets = campaignOrder.attributionTargets;
  const impressions =
    isEmpty(campaignOrder.aggregateData) ||
    isEmpty(campaignOrder.aggregateData.overallData)
      ? 0
      : campaignOrder.aggregateData.overallData.aggImpressions;
  const attributionData = (chartData || {}).attributionByLocation || {};
  const observationsData = (chartData || {}).observationsByLocation || {};
  const totalWalkIns = (chartData || {}).totalWalkIns || 0;

  const endDate = moment(campaignOrder.endDate)
    .add(campaignOrder.attributionWindow, "days")
    .format();

  return geoTargets.map((elem, index) => {
    let walkIns = 0;
    let uniques = 0;
    let walkInRate = 0;
    let avgImp = 0;
    let observations = 0;
    let attributionKey = elem.id;
    // (location.lat || 0).toString() + (location.lng || 0).toString();
    if (attributionData[attributionKey]) {
      walkIns = attributionData[attributionKey][0].COUNT;
      uniques = attributionData[attributionKey][0].UNIQUE;
      avgImp = impressions / walkIns;
      if (totalWalkIns) walkInRate = walkIns / totalWalkIns;
    }
    if (observationsData[attributionKey]) {
      observations = observationsData[attributionKey][0].TOTAL;
    }
    let updated = elem.vwiUpdated
      ? new Date(elem.vwiUpdated).toLocaleDateString("en-US")
      : null;
    if (elem.circles.length) {
      let location = elem.circles[0].location;
      let radius = elem.circles[0].radius || 0;
      let circle = {
        ...elem,
        index: index,
        key: elem.id,
        name: elem.name || "Circle",
        type: "Circle",
        start: elem.start || campaignOrder.startDate,
        end: elem.end || endDate,
        walkIns,
        uniques,
        walkInRate,
        avgImp,
        radius,
        observations,
        updated,
      };
      if (location) {
        const formattedAddress = location.formattedAddress || "";
        const streetAddress = formattedAddress.split(",")[0];
        return {
          ...circle,
          name: location.name || "",
          state: location.state || "",
          zip: location.zip || "",
          city: location.city || "",
          address: streetAddress,
        };
      }
      return circle;
    }
    if (elem.polygons.length) {
      let location = elem.polygons[0].location;

      let updated = elem.vwiUpdated
        ? new Date(elem.vwiUpdated).toLocaleString()
        : null;
      const polygon = {
        ...elem,
        index: index,
        key: elem.id,
        name: elem.name || "Polygon",
        type: "Polygon",
        start: elem.start || campaignOrder.startDate,
        end: elem.end || endDate,
        updated,
      };

      if (location) {
        const formattedAddress = location.formattedAddress || "";
        return {
          ...polygon,
          name: location.name || "",
          state: location.state || "",
          zip: location.zip || "",
          city: location.city || "",
          address: formattedAddress.split(",")[0],
        };
      }

      return polygon;
    }
    return null;
    //
    // let walkIns = 0;
    // let uniques = 0;
    // let walkInRate = 0;
    // let avgImp = 0;
    // let observations = 0;
    // let attributionKey = elem.id;
    // // (location.lat || 0).toString() + (location.lng || 0).toString();
    // if (attributionData[attributionKey]) {
    //   walkIns = attributionData[attributionKey][0].COUNT;
    //   uniques = attributionData[attributionKey][0].UNIQUE;
    //   avgImp = impressions / walkIns;
    //   if (totalWalkIns) walkInRate = walkIns / totalWalkIns;
    // }
    // if (observationsData[attributionKey]) {
    //   observations = observationsData[attributionKey][0].TOTAL;
    // }
    // let updated = elem.vwiUpdated
    //   ? new Date(elem.vwiUpdated).toLocaleString()
    //   : null;
    // let children = [...elem.circles, ...elem.polygons].map(
    //   (item, childIndex) => {
    //     let location = item.location || {};
    //     let radius = item.radius || 0;
    //     let type = item["__typename"];
    //     if (location.formattedAddress) {
    //       let formattedAddress = location.formattedAddress || "";
    //       let streetAddress = formattedAddress.split(",")[0];
    //
    //       return {
    //         // ...elem,
    //         index: childIndex,
    //         key: `${elem.id}-${type}-${childIndex}`,
    //         name: location.name || `${type} ${childIndex + 1}`,
    //         state: location.state || "",
    //         zip: location.zip || "",
    //         city: location.city || "",
    //         address: streetAddress,
    //         radius,
    //         type,
    //       };
    //     } else {
    //       return {
    //         index: childIndex,
    //         key: `${elem.id}-${type}-${childIndex}`,
    //         name: `${type} ${childIndex + 1}`,
    //         radius,
    //         type,
    //       };
    //     }
    //   }
    // );
    // return {
    //   ...elem,
    //   index: index,
    //   key: elem.id,
    //   name: elem.name || `Geo Target ${index + 1}`,
    //   type: elem["__typename"],
    //   updated,
    //   walkIns,
    //   uniques,
    //   walkInRate,
    //   avgImp,
    //   observations,
    //   children,
    // };
  });
};

export const fillNamesWalkInByLineItemCreativeDates = (
  creatives,
  campaigns,
  walkInByLineItemCreativeDates
) => {
  const nameMap = {};
  creatives.forEach(creative => (nameMap[creative.id] = creative.value));
  campaigns[0].lineItems.forEach(
    lineItem => (nameMap[lineItem.adProviderId] = lineItem.name)
  );
  nameMap[campaigns[0].adProviderId] = campaigns[0].name;
  return walkInByLineItemCreativeDates.map(row => {
    return {
      INSERTION_ORDER_NAME:
        nameMap[row.INSERTION_ORDER_ID] || row.INSERTION_ORDER_NAME,
      LINE_ITEM_NAME: nameMap[row.LINE_ITEM_ID] || row.LINE_ITEM_NAME,
      CREATIVE_NAME: nameMap[row.CREATIVE_ID] || row.CREATIVE_NAME,
      CONVERSIONDATE: row.CONVERSIONDATE,
      COUNT: row.COUNT,
      UNIQUE: row.UNIQUE,
    };
  });
};

export const diffTargetAudiences = (current, next) => {
  const currentAudienceLookUp = {};
  const connect = [];
  current.forEach(audience => {
    currentAudienceLookUp[audience.id] = true;
  });
  next.forEach(audience => {
    if (currentAudienceLookUp[audience.id]) {
      delete currentAudienceLookUp[audience.id];
    } else {
      connect.push({ id: audience.id });
    }
  });
  const disconnect = Object.keys(currentAudienceLookUp).map(key => {
    return { id: key };
  });
  return { connect, disconnect };
};

export const saveGeoJSONFromGeoTargetsReveal = (geoTargets, audienceName) => {
  //export const saveGeoJSONFromGeoTargets = (geoTargets, audienceName) => {
  let today = new Date()
    .toLocaleDateString("en-US")
    .split("/")
    .join("_");

  const zip = new JSZip();
  const folder = zip.folder(`${audienceName} Geometry ${today}`);
  geoTargets.forEach((geoTarget, i) => {
    const geoJSON = [
      ...geoTarget.polygons.map(polygon => {
        return {
          type: "Polygon",
          coordinates: polygon.geometry.coordinates,
          id: geoTarget.id,
        };
      }),
      ...geoTarget.circles.map(({ radius, coordinate }) => {
        const center = [coordinate.lng, coordinate.lat];
        const options = { steps: 20, units: "kilometers" };
        return turf.circle(center, radius, options);
      }),
    ];

    folder.file(`GeoTarget-${i}.geojson`, JSON.stringify(geoJSON[0]));
  });

  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, `${audienceName} Geometry ${today}`);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
};

export const saveGeoJSONFromGeoTargets = (geoTargets, audienceName) => {
  let today = new Date()
    .toLocaleDateString("en-US")
    .split("/")
    .join("_");

  const zip = new JSZip();
  const folder = zip.folder(`${audienceName} Geometry ${today}`);
  geoTargets.forEach((geoTarget, i) => {
    const geoJSON = {
      type: "FeatureCollection",
      features: [
        ...geoTarget.polygons.map(polygon => {
          let coords = uniqWith(polygon.geometry.coordinates[0], isEqual);
          coords.push(coords[0]);
          return {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [coords],
            },
          };
        }),
        ...geoTarget.circles.map(({ radius, coordinate }) => {
          const center = [coordinate.lng, coordinate.lat];
          const options = { steps: 20, units: "kilometers" };
          return turf.circle(center, radius, options);
        }),
      ],
    };

    folder.file(`GeoTarget-${i}.geojson`, JSON.stringify(geoJSON));
  });

  zip.generateAsync({ type: "blob" }).then(
    blob => {
      saveAs(blob, `${audienceName} Geometry ${today}`);
      return true;
    },
    function(e) {
      console.log("error", e);
      return false;
    }
  );
};

export const getSummaryScale = (
  data,
  leftY,
  rightY,
  currencyFormat = defaultCurrencyFormat
) => {
  const scale = {
    left:
      leftY === "cost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: `Cost (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency(val, currencyFormat)}`;
            },
          }
        : leftY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "clicks") : 1000,
            alias: "Clicks",
          }
        : leftY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "impressions") : 1000,
            alias: "Impressions",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : leftY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: `Total Cost (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "ctr"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ctr") : 1000,
            alias: "Click through ratio (%)",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : leftY === "cpc"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpc") : 1000,
            alias: `Cost per click (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "cpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpm") : 1000,
            alias: `Cost per milli (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "ecpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ecpm") : 1000,
            alias: `Estimated cost per milli (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "transactionCount"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionCount") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: `Total Cost (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "transactionTotal"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionTotal") : 1000,
            alias: "Transaction Total",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "walkIns"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "walkIns") : 1000,
            alias: "Walk Ins",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : leftY === "observations"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "observations") : 1000,
            alias: "Observations",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : leftY === "conversions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversions") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "keywords"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "keywords") : 1000,
            alias: "Searches",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "locations"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "locations") : 1000,
            alias: "Locations Clicks",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "revenue") : 1000,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "spend"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "spend") : 1000,
            alias: "Spend",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : leftY === "roas"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "roas") : 1000,
            alias: "Roas",
            formatter: val => {
              return `${formatNumber(val)}%`;
            },
          }
        : leftY === "conversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversionRate") : 1000,
            alias: "Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}%`;
            },
          }
        : leftY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversions") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "items"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "items") : 1000,
            alias: "Items",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "sessions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["sessions"] : 1000,
            alias: "Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "pagesPerSession"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pagesPerSession"] : 0,
            alias: "Pages / Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "bounceRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["bounceRate"] : 0,
            alias: "Bounce Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "avgSessionDuration"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgSessionDuration"] : 0,
            alias: "Average Session Duration",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : leftY === "goalConversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["goalConversionRate"] : 0,
            alias: "Goal Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    right:
      rightY === "cost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: `Cost (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "clicks"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "clicks") : 1000,
            alias: "Page Views",
          }
        : rightY === "impressions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "impressions") : 1000,
            alias: "Impressions",
          }
        : rightY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: `Total Cost (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "ctr"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ctr") : 1000,
            alias: "Click through ratio (%)",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : rightY === "cpc"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpc") : 1000,
            alias: `Cost per click (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "cpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "cpm") : 1000,
            alias: `Cost per milli (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "ecpm"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "ecpm") : 1000,
            alias: `Estimated cost per milli (${currencyFormat.symbol})`,
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "transactionCount"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionCount") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "totalCost"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "totalCost") : 1000,
            alias: "Total Cost",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "transactionTotal"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "transactionTotal") : 1000,
            alias: "Transaction Total",
            formatter: val => {
              return `${formatCurrency2SigFig(val)}`;
            },
          }
        : rightY === "walkIns"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "walkIns") : 1000,
            alias: "Walk Ins",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : rightY === "observations"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "observations") : 1000,
            alias: "Observations",
            formatter: val => {
              return `${formatNumber4SigFig(val)}`;
            },
          }
        : rightY === "conversions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversions") : 1000,
            alias: "Transactions Count",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "keywords"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "keywords") : 1000,
            alias: "Searches",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "locations"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "locations") : 1000,
            alias: "Locations Clicks",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "revenue"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "revenue") : 1000,
            alias: "Revenue",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "spend"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "spend") : 1000,
            alias: "Spend",
            formatter: val => {
              return `${formatCurrency2SigFig(val, currencyFormat)}`;
            },
          }
        : rightY === "roas"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "roas") : 1000,
            alias: "Roas",
            formatter: val => {
              return `${formatNumber(val)}%`;
            },
          }
        : rightY === "conversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversionRate") : 1000,
            alias: "Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}%`;
            },
          }
        : rightY === "transactions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "conversions") : 1000,
            alias: "Transactions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "items"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? getMax(data, "items") : 1000,
            alias: "Items",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "sessions"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["sessions"] : 1000,
            alias: "Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "pagesPerSession"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["pagesPerSession"] : 0,
            alias: "Pages / Sessions",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "bounceRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["bounceRate"] : 0,
            alias: "Bounce Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "avgSessionDuration"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["avgSessionDuration"] : 0,
            alias: "Average Session Duration",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : rightY === "goalConversionRate"
        ? {
            type: "linear",
            min: 0,
            max: !isEmpty(data) ? data["goalConversionRate"] : 0,
            alias: "Goal Conversion Rate",
            formatter: val => {
              return `${formatNumber(val)}`;
            },
          }
        : {},
    xAxis: {
      tickCount: 8,
    },
  };
  return scale;
};

/**
 * Formats X-Axis to a default day title OR a date title fallback with formatting
 *
 * @param {string} xAxis - xAxis title received.
 * @param {string} format - Date format specified to date fallback.
 * @return {string}
 */
export const formatXaxis = (xAxis, format) => {
  // You can move this outside as a global var. So that anyone can reference it
  const daysNameList = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // Match the whole day name or just a substring of it
  const matchedDay = daysNameList.find(
    (val, i) => val === xAxis || val.substring(0, 3) === xAxis
  );
  return matchedDay || moment(xAxis).format(format);
};

export const prepareTransactions = (chartData, cart, version) => {
  const output = cloneDeep(chartData);
  //Temporarily only handles shopify
  if (cart === "SHOPIFY" && version < 1.3) {
    if (output && output.transactionsWithEvents) {
      output.transactionsWithEvents.map(row => {
        let total = row.transactionTotal;
        if (row.transactionId < 400000000) {
          total = total / 100;
        }
        return row[(row.transactionTotal = total)];
      });
    }
  }

  return output;
};

export const sessionTimer = number => {
  // Hours, minutes and seconds
  var hrs = ~~(number / 3600);
  var mins = ~~((number % 3600) / 60);
  var secs = ~~number % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

export const computeSpend = (impressions, cpm) => {
  const spend = (impressions / 1000) * cpm;
  return Number.isFinite(spend) && !isNaN(spend) ? spend : 0;
};

export const computeROAS = (
  revenue = 0,
  spend = 0,
  format = "PERCENT",
  hasSpecialSymbol = true,
  currencyFormat
) => {
  let roas = revenue / spend;
  if (!isFinite(roas) || isNaN(roas) || !roas) roas = 0;
  switch (format) {
    case "PERCENT":
      roas = `${formatWholeNumberNaN(roas * 100)}%`;
      if (!hasSpecialSymbol) roas = parseFloat(roas * 100).toFixed(2);
      break;
    case "FLAT":
      roas = `${parseFloat(roas).toFixed(2)}x`;
      if (!hasSpecialSymbol) roas = parseFloat(roas).toFixed(2);
      break;
    case "DOLLAR":
      roas = formatCurrency2SigFig(revenue - spend, currencyFormat);
      if (!hasSpecialSymbol) roas = parseFloat(revenue - spend).toFixed(2);
      break;
    default:
      break;
  }
  return roas;
};

export const computeROASDisplay = (
  revenue = 0,
  spend = 0,
  format = "PERCENT",
  currencyFormat
) => {
  let roas = revenue / spend;
  if (!isFinite(roas) || isNaN(roas) || !roas) roas = 0;
  switch (format) {
    case "PERCENT":
      roas = `${formatWholeNumberNaN(roas * 100)}%`;
      break;
    case "FLAT":
      roas = `${parseFloat(roas).toFixed(2)}x`;
      break;
    case "DOLLAR":
      roas = formatCurrency2SigFig(revenue - spend, currencyFormat);
      break;
    default:
      break;
  }
  return roas;
};

export const computePacing = (spend, budget) => {
  const daysInMonth = moment()
    .utc()
    .daysInMonth();
  const daysOfMonth = moment()
    .utc()
    .date();
  const pacing = (spend / ((budget / daysInMonth) * daysOfMonth)) * 100;
  return Number.isFinite(pacing) && !isNaN(pacing) ? pacing : 0;
};

export const computeDailyRemainingSpend = (spend, budget) => {
  const daysInMonth = moment()
    .utc()
    .daysInMonth();
  const daysOfMonth = moment()
    .utc()
    .date();

  const remainSpend = (budget - spend) / (daysInMonth - daysOfMonth);
  return Number.isFinite(remainSpend) && !isNaN(remainSpend) ? remainSpend : 0;
};

export const computeCTR = (clicks, impressions, isPercent = true) => {
  const raw = clicks / impressions;
  if (!isPercent) return Number.isFinite(raw) && !isNaN(raw) ? raw : 0;
  return Number.isFinite(raw) && !isNaN(raw) ? raw * 100 : 0;
};

export const computeCPC = (cost, clicks) => {
  const cpc = cost / clicks;
  return Number.isFinite(cpc) && !isNaN(cpc) ? cpc : 0;
};

export const isUnixTimestamp = input => {
  // Try to parse the input as both string and number
  const parsedDateFromString = moment.unix(input);
  const parsedDateFromNumber = moment.unix(Number(input));

  // Check if at least one of the parsed dates is valid
  return (
    (parsedDateFromString.isValid() || parsedDateFromNumber.isValid()) &&
    (typeof input === "string" || typeof input === "number")
  );
};

export const isDayOfWeekString = input => {
  // Convert input to lowercase to handle case-insensitivity
  const lowercaseInput = typeof input === "string" && input.toLowerCase();

  // Use .isoWeekday() to check if input is a day of the week
  const dayOfWeek = moment().isoWeekday(lowercaseInput, true); // The second parameter is for strict parsing

  return !isNaN(dayOfWeek); // If it's a number, it's a day of the week
};

/**
 *
 * @param {*} providers
 * @returns
 */
export const collectSegmentsFromProviders = providers => {
  let allSegments = [];

  if (!providers || providers.length === 0) return allSegments;

  for (const provider in providers) {
    if (
      providers[provider][1].segments &&
      Array.isArray(providers[provider][1].segments)
    ) {
      allSegments = allSegments.concat(providers[provider][1].segments);
    }
  }

  return allSegments;
};
