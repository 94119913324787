const defaultState = {
  currentUser: {
    __typename: "currentUser",
    isAuthenticated: false,
    id: "notloggedin",
    username: `Guest`,
    emai: "guest@gmail.com",
    cognitoUserId: `Guest-${Date.now()}`,
    avatar: false,
    role: null,
    features: [],
    notifications: [],
    iAdmin: false,
  },
};

export default defaultState;
