import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NewPassword from "../ux/Accounts/components/NewPassword";
import OptOutView from "../ux/OptOut/OptOutView";
import PrivacyPolicyView from "../ux/PrivacyPolicy/PrivacyPolicyView";
import RecoverPassword from "../ux/Accounts/components/RecoverPassword";
import SignIn from "../ux/Accounts/components/SignIn";
import ResultPage from "../shared/ResultPage";

export const publicRoutes = [
  {
    path: "/opt-out",
    main: OptOutView,
  },
  {
    path: "/privacy-policy",
    main: PrivacyPolicyView,
  },
];

const PublicRoutes = props => {
  return (
    <Switch>
      <Route path="/signin" exact component={SignIn} />
      <Route
        path="/page-not-found"
        exact
        render={() => (
          <ResultPage
            status="500"
            title="Something went wrong"
            subTitle="Please contact your administrator."
          />
        )}
      />
      <Route path="/recover" exact component={RecoverPassword} />
      <Route path="/new" exact component={NewPassword} />
      {publicRoutes.map(route => (
        <Route key={route.path} path={route.path} component={route.main} />
      ))}
      <Redirect from="/" to="/signin" />
    </Switch>
  );
};

export default PublicRoutes;
