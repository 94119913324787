import React, { Fragment } from "react";
import { Card, Row, Col } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
// Global Styles
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../shared/globalStyling/styledText";
import {
  formatWholeNumber,
  formatWholeNumberNaN,
} from "../../../../../../core/utils/campaigns";
import { styles } from "./AttributionStyles.style";
import VisitByDateCardV2 from "./components/VisitByDateCardV2";
import VisitByDayOfWeekCardV2 from "./components/VisitByDayOfWeekCardV2";
import VisitByHourOfDayCardV2 from "./components/VisitByHourOfDayCardV2";
import VisitByFrequencyCardV2 from "./components/VisitByFrequencyCardV2";
import Text from "antd/lib/typography/Text";
import { defaultOnspotWalkInReportData } from "../../../../../../core/utils/constants/constants";
import { ExtraInfo } from "./components";

const AttributionReport = ({ campaign, chartData }) => {
  const impressions =
    isEmpty(campaign.aggregateData) ||
    isEmpty(campaign.aggregateData.overallData)
      ? 0
      : campaign.aggregateData.overallData.aggImpressions;

  const onspotWalkInReports =
    !isEmpty(chartData) && !isEmpty(chartData.onspotWalkInReports)
      ? chartData.onspotWalkInReports
      : defaultOnspotWalkInReportData(campaign);

  return (
    <Fragment>
      <WalkInReportV2
        styles={styles}
        onspotWalkInReports={onspotWalkInReports}
        impressions={impressions}
      />
    </Fragment>
  );
};

const WalkInReportV2 = ({
  styles,
  onspotWalkInReports: {
    geoStartDate,
    geoEndDate,
    attribution,
    observations,
    updatedAt,
  },
  impressions,
}) => {
  const walkInsCount = (attribution && attribution.count) || 0;
  const walkInsFrequency = attribution && attribution.frequency;
  const walkInsDayOfWeek = attribution && attribution.dayOfWeek;
  const walkInsHourOfDay = attribution && attribution.hourOfDay;

  const uniqueObservationsCount = observations && observations.uniqueCount;
  const nonUniqueObservationsCount =
    observations && observations.nonUniqueCount;
  const observationsByDate = observations && observations.byDate;
  const observationsByDay = observations && observations.byDay;
  const observationsByHour = observations && observations.byHour;

  return (
    <Fragment>
      {!isEmpty(updatedAt) ? (
        <Row type="flex" justify="end">
          <Text disabled style={{ fontSize: "11px" }}>
            Updated: {moment(updatedAt).calendar()}
          </Text>{" "}
        </Row>
      ) : null}
      <Row type="flex" style={styles.overviewBar}>
        <Col xl={6} lg={6} xs={24}>
          <Card
            style={{ height: "10em", borderRight: 0, borderTop: 0 }}
            bodyStyle={{
              borderLeft: 0,
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Impressions"} />
            </div>
            <KpiSubText text={formatWholeNumber(impressions)} />
          </Card>
        </Col>
        <Col xl={6} lg={6} xs={24}>
          <Card
            style={{ height: "10em", borderRight: 0, borderTop: 0 }}
            bodyStyle={{
              borderLeft: 0,
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Walk Ins"} />
              <span>
                <ExtraInfo
                  title={
                    "Displays the total count of distinct devices observed within the specified boundaries that received impressions prior to visiting."
                  }
                  placement={"top"}
                />
              </span>
            </div>
            <KpiSubText text={formatWholeNumberNaN(walkInsCount)} />
          </Card>
        </Col>
        <Col xl={6} lg={6} xs={24}>
          <Card
            style={{ height: "10em", borderRight: 0, borderTop: 0 }}
            bodyStyle={{
              borderLeft: 0,
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Observations"} />
              <span>
                <ExtraInfo
                  title={
                    "Displays devices observed within the specified boundaries over the specified date range."
                  }
                  placement={"top"}
                />
              </span>
            </div>
            <KpiSubText
              text={formatWholeNumberNaN(nonUniqueObservationsCount)}
            />
          </Card>
        </Col>
        <Col xl={6} lg={6} xs={24}>
          <Card
            style={{ height: "10em", borderRight: 0, borderTop: 0 }}
            bodyStyle={{
              borderLeft: 0,
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <div style={styles.title}>
              <KPITitleText text={"Unique Observations"} />
              <span>
                <ExtraInfo
                  title={
                    "Displays the number of unique devices observed within the specified boundaries over the specified date range."
                  }
                  placement={"top"}
                />
              </span>
            </div>
            <KpiSubText text={formatWholeNumberNaN(uniqueObservationsCount)} />
          </Card>
        </Col>
      </Row>
      <div style={{ marginTop: "3%" }}>
        {!isEmpty(walkInsDayOfWeek) ||
        !isEmpty(walkInsFrequency) ||
        !isEmpty(walkInsHourOfDay) ? (
          <h2>Walk Ins</h2>
        ) : null}
        <Row gutter={30} style={{ marginTop: "2.5%" }}>
          {(!isEmpty(walkInsDayOfWeek) && (
            <Col xl={12} lg={12} xs={24}>
              <VisitByDayOfWeekCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={walkInsDayOfWeek}
                fullDayName={true}
                showTotalVisits={false}
                explanation={
                  "Displays the number of unique devices observed within the specified boundaries that received impressions prior to visiting, segmented by each day of the week."
                }
              />
            </Col>
          )) ||
            null}
          {(!isEmpty(walkInsFrequency) && (
            <Col xl={12} lg={12} xs={24}>
              <VisitByFrequencyCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={walkInsFrequency}
                explanation={
                  "Shows the number of times a unique device was observed within the specified boundaries after receiving impressions, over the specified date range."
                }
              />
            </Col>
          )) ||
            null}
        </Row>
        {(!isEmpty(walkInsHourOfDay) && (
          <Row gutter={30} style={{ marginTop: "2%" }}>
            <Col xs={24}>
              <VisitByHourOfDayCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={walkInsHourOfDay}
                showTotalVisits={false}
                explanation={
                  "Unique devices seen within the specified boundaries that also received impressions prior to visiting for each hour in the day aggregated for 24 hours."
                }
              />
            </Col>
          </Row>
        )) ||
          null}
      </div>
      <div style={{ marginTop: "3%" }}>
        {!isEmpty(observationsByDate) ||
        !isEmpty(observationsByDay) ||
        !isEmpty(observationsByHour) ? (
          <h2>Observations</h2>
        ) : null}
        {(!isEmpty(observationsByDate) && (
          <Row gutter={30} style={{ marginTop: "2.5%" }}>
            <Col xs={24}>
              <VisitByDateCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={observationsByDate}
                total={uniqueObservationsCount}
                customLabel={"Unique Observations"}
                explanation={
                  "Displays the number of unique devices observed within the specified boundaries, aggregated by date."
                }
              />
            </Col>
          </Row>
        )) ||
          null}
        {(!isEmpty(observationsByDay) && (
          <Row gutter={30} style={{ marginTop: "2%" }}>
            <Col xs={24}>
              <VisitByDayOfWeekCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={observationsByDay}
                fullDayName={false}
                customLabel={"Unique Observations"}
                explanation={
                  "Displays the number of unique devices observed within the specified boundaries, segmented by each day of the week."
                }
              />
            </Col>
          </Row>
        )) ||
          null}
        {(!isEmpty(observationsByHour) && (
          <Row gutter={30} style={{ marginTop: "2%" }}>
            <Col xs={24}>
              <VisitByHourOfDayCardV2
                geoDates={{
                  start: moment(geoStartDate),
                  end: moment(geoEndDate),
                }}
                data={observationsByHour}
                customLabel={"Unique Observations"}
                explanation={
                  "Displays the number of unique devices observed within the specified boundaries for each hour in the day aggregated for 24 hours. "
                }
              />
            </Col>
          </Row>
        )) ||
          null}
      </div>
    </Fragment>
  );
};

export default AttributionReport;
