import React from "react";
import { Progress, Row, Col, Card } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";

// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiFlightDate,
} from "../../../../../../shared/globalStyling/styledText";

import { formatWholeNumber } from "../../../../../../../core/utils/campaigns";

//TODO - break all styles out and store in central location
const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};

const TopBar = ({ transactions, startDate, endDate }) => {
  //Break into utils
  const getProgress = () => {
    const total = () => {
      var a = moment(endDate, moment.DATETIME_LOCAL_MS);
      var b = moment(startDate, moment.DATETIME_LOCAL_MS);
      const total = a.diff(b, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(startDate, moment.DATETIME_LOCAL_MS);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();
    if (progressDays > totaldays || progressDays === totaldays) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;
    return Math.floor(result);
  };

  const currentposition = getProgress();

  return (
    <Row style={styles.container} gutter={12}>
      <Col flex="1 1 200px">
        <Card
          style={{
            height: "10em",
            borderLeft: 0,
            borderTop: 0,
          }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Flight Dates"} />
          </div>
          <div style={styles.datesContainer}>
            <div style={styles.dates}>
              <KpiFlightDate
                text={`${moment(startDate, moment.DATETIME_LOCAL_MS).format(
                  "MM/DD/YYYY"
                )}`}
              />
            </div>
            <div>
              <ArrowRightOutlined style={{ color: "#636363" }} />
            </div>
            <div style={styles.dates}>
              <KpiFlightDate
                text={`${moment(endDate, moment.DATETIME_LOCAL_MS).format(
                  "MM/DD/YYYY"
                )}`}
              />
            </div>
          </div>
          <Progress
            showInfo={false}
            percent={currentposition}
            status={currentposition !== 100 ? "active" : "normal"}
            strokeColor={"#636363"}
          />
        </Card>
      </Col>
      <Col flex="1 1 200px">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Sign Ups"} />
          </div>
          <KpiSubText text={`${formatWholeNumber(transactions)}`} />
        </Card>
      </Col>
    </Row>
  );
};
export default TopBar;
