import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    fontSize: 15,
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
  },
  header: {
    fontSize: 12,
    marginTop: 20,
    fontFamily: "Helvetica-Bold",
  },
  description: {
    fontSize: 12,
    marginBottom: 20,
    marginTop: 20,
  },
  code: {
    border: "1px solid #E4E4E4",
    color: "#666",
    fontSize: 11,
    lineHeight: 1.6,
    maxWidth: "100%",
    overflow: "auto",
    padding: "10",
    display: "block",
  },
});

const GeneratedTagPDF = ({ title, steps }) => {
  return (
    <View>
      <Text style={styles.header}>{title}</Text>
      {steps.map((step, index) => {
        return (
          <>
            {step.pdfTextCode ? (
              step.pdfTextCode
            ) : (
              <Text key={index} style={styles.description}>
                {index + 1}. {step.pdfText ? step.pdfText : step.instruction}
              </Text>
            )}
            {step.image && <Image src={step.image} />}
          </>
        );
      })}
    </View>
  );
};

export default GeneratedTagPDF;
