import React from "react";

// Ant Design
import { List } from "antd";
import { RightOutlined } from "@ant-design/icons";

const SubCategoryList = props => {
  const {
    list,
    onCategoryClick,
    listItemStyle,
    titleFontStyle,
    selectedStyle,
    selectedCategory,
    selectedFontStyle,
  } = props;

  return (
    <>
      {list && list.length > 0 ? (
        <List
          size="large"
          dataSource={list}
          renderItem={item => (
            <List.Item
              style={{
                cursor: "pointer",
                ...listItemStyle,
                ...(selectedCategory && selectedCategory.name === item.name
                  ? selectedStyle
                  : {}),
              }}
              onClick={() => {
                onCategoryClick(item);
              }}
            >
              <List.Item.Meta
                title={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      titleFontStyle,
                      ...(selectedCategory &&
                      selectedCategory.name === item.name
                        ? selectedFontStyle
                        : {}),
                    }}
                  >
                    <span>{item.name}</span>
                    <RightOutlined />
                  </span>
                }
              />
            </List.Item>
          )}
        />
      ) : null}
    </>
  );
};

export default SubCategoryList;
