import React from "react";
import { Table, Switch, Divider, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { orgFeaturesInfo } from "../../../../core/utils/constants/constants";

const PermissionToggler = props => {
  const style = {
    container: {
      margin: "5px",
    },
  };
  const columns = [
    {
      title: () =>
        props.disabled ? (
          "Features"
        ) : (
          <span>
            Features
            <Tooltip
              title={
                <span>
                  Please select at least one feature to create an organization!
                  <br></br>This Features will be shown in your sidebar.
                </span>
              }
              arrowPointAtCenter={true}
              placement="leftBottom"
            >
              <QuestionCircleOutlined
                type="question-circle"
                style={{ marginLeft: "10px" }}
              />
            </Tooltip>
          </span>
        ),
      width: "60%",
      key: "feature",
      dataIndex: "feature",
      render: (text, record) => {
        let feature = "";

        switch (text) {
          case "CAMPAIGNS":
            feature = "DISPLAY";
            break;
          case "DEMOGRAPHIC":
            feature = "DEMOGRAPH";
            break;
          case "EDITORIAL":
            feature = "ATTENTION SIGNALS";
            break;
          default:
            feature = text.replace(/_/g, " ");
        }
        return <div>{feature}</div>;
      },
    },
    {
      title: "Permissions",
      width: "15%",
      dataIndex: "feature",
      key: "write",

      render: text => (
        <Tooltip title={orgFeaturesInfo[text]} key={text}>
          <Switch
            checked={
              props.currentPermissions[text]
                ? props.currentPermissions[text].includes("WRITE")
                : false
            }
            name={`${text}-WRITE`}
            disabled={
              props.disabled ||
              !props.loggedInPermissions[text].includes("WRITE")
            }
            onChange={checked => {
              props.onSwitchChange(checked, `${text}-WRITE`);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <div style={style.container}>
      <Table
        columns={columns}
        bordered
        dataSource={
          props.loggedInPermissions
            ? Object.keys(props.loggedInPermissions)
                .filter(feature => feature !== "ENABLED")
                .map((feature, i) => {
                  return { feature, key: i };
                })
            : []
        }
        pagination={false}
      />
      <Divider />
    </div>
  );
};

export default PermissionToggler;
