import React from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import LauncherMap from "../../../shared/maps/LauncherMap";
import GeotTargetList from "./GeoTargetList";
import CsvAdressListUploadButton from "./CsvAdressListUploadButton";
import { isEmpty } from "lodash";
import { DeleteOutlined, EnvironmentOutlined } from "@ant-design/icons";

const Frag = React.Fragment;
const { Text } = Typography;

const Title = ({ titleText }) => {
  return (
    <Row type="flex" gutter={10} align="middle">
      <Col>
        <EnvironmentOutlined
          style={{
            display: "flex",
            alignSelf: "center",
            color: "#9b9b9b",
            fontSize: "22px",
          }}
          size="large"
        />
      </Col>
      <Col>
        <h4 style={{ margin: 0, color: "#9b9b9b" }}>{titleText}</h4>
      </Col>
    </Row>
  );
};

const Extra = ({ onChange, onCsvUploadComplete }) => {
  return (
    <div>
      {/* GeoFrame Library to be scoped */}
      <Button
        type="primary"
        shape="round"
        icon={<EnvironmentOutlined />}
        style={{ marginRight: "10px" }}
        disabled
      >
        Geoframe Library
      </Button>
      <CsvAdressListUploadButton
        onComplete={onCsvUploadComplete}
        onChange={onChange}
      />
    </div>
  );
};

const Buttons = ({ removeGeoTargets, selectedGeoTargets }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        size="large"
        shape="circle"
        icon={<DeleteOutlined />}
        disabled={isEmpty(selectedGeoTargets) ? true : false}
        onClick={removeGeoTargets}
      />
    </div>
  );
};

const styles = {
  map: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  mapCard: {
    padding: 0,
    borderBottom: "1px solid #e8e8e8",
  },
  input: {
    position: "relative",
    height: "100%",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #1AA89D",
  },
  outerContainer: {
    width: "100%",
    height: "80vh",
  },
  innerContainer: {
    padding: 4,
    backgroundColor: "#fff",
  },
  tableHeaderRow: {
    marginBottom: "20px",
  },
};

const GeoTargetsModalCard = props => {
  const {
    addGeoTarget,
    removeGeoTargets,
    geoTargets,
    handleChangeRadius,
    onChange,
    onCsvUploadComplete,
    selectedGeoTargets,
  } = props;

  return (
    <Frag>
      <Card
        bordered={false}
        title={<Title titleText="Geo Targeting" />}
        bodyStyle={styles.mapCard}
      >
        <LauncherMap
          geoTargets={geoTargets}
          addGeoTarget={addGeoTarget}
          zoom={10}
          styles={styles}
        />
      </Card>
      <Row
        gutter={16}
        style={{ paddingTop: "15px" }}
        justify="start"
        type="flex"
      >
        <Col span={24}>
          <Card
            bordered={false}
            title={<Title titleText="Geo-Targets" iconType="layout" />}
            extra={
              <Extra
                onCsvUploadComplete={onCsvUploadComplete}
                onChange={onChange}
              />
            }
          >
            <Row style={styles.tableHeaderRow}>
              <Col span={20}>
                <Text>
                  These are the locations that you have added to this campaign.
                </Text>
                <br />
                <Text type="secondary">You can edit or remove them.</Text>
              </Col>
              <Col span={4}>
                <Buttons
                  removeGeoTargets={removeGeoTargets}
                  selectedGeoTargets={selectedGeoTargets}
                />
              </Col>
            </Row>
            <Row>
              <GeotTargetList
                geoTargets={geoTargets}
                onRadiusChange={handleChangeRadius}
                onChange={onChange}
                selectedTargets={selectedGeoTargets}
              />
            </Row>
          </Card>
        </Col>
      </Row>
    </Frag>
  );
};

export default GeoTargetsModalCard;
