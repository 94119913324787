import { message } from "antd";
import React from "react";
import { errorType } from "../../../utils/constants/constants";

// IMPORTANT NOTE: To the next dev that will work on the new screens please change this controller to a functional component
class CreativesMainController extends React.Component {
  state = {
    selectedOrg: null,
    creativeName: "",
    onLoadingDeleteMedias: false,
    onLoadingDeleteAdGroup: false,
  };

  componentDidMount = () => {
    let loggedInOrg = this.props.loggedInOrg;

    this.setState({
      selectedOrg: {
        ...loggedInOrg,
      },
    });
  };
  onChangeStateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  onDeleteMedias = async ids => {
    const { updateManyMedias } = this.props;

    try {
      message.loading("Action in progress..", 0);

      this.setState({
        onLoadingDeleteMedias: true,
      });

      await updateManyMedias({
        variables: {
          ids,
          data: { flag: false },
        },
      });
      message.destroy();
      message.success(`${ids.length} Creatives(s) successfully deleted.`);
      this.setState({
        onLoadingDeleteMedias: false,
      });

      // Get all adgroups associated with this Creative
      const adGroupCreativesResult = await this.props.getAdGroupsByMedia({
        media: ids,
      });

      // Check each adgroup if all creatives is deleted, return the adgroup ids that meets the condition for deletion
      const mappedAdGroupCreativeIds = adGroupCreativesResult.data.adGroupCreativeses
        .filter(obj => obj.medias.every(media => !media.flag))
        .map(obj => obj.id);

      // Delete all adgroups that has no creatives
      if (mappedAdGroupCreativeIds) {
        mappedAdGroupCreativeIds.map(x =>
          this.props.deleteAdGroupCreatives({ variables: { id: x } })
        );
      }
    } catch (err) {
      message.destroy();
      if (err.message === errorType.deleteMedia) {
        // TODO need to clarify if statement appies
        message.error(`Cannot Delete Campaign, Already Synced with a Creative`);
      }
    } finally {
      return [];
    }
  };

  onCreateAdgroup = async () => {
    message.loading("Action in progress..", 0);

    const {
      currentuser: {
        role,
        permission: { amplify },
      },
    } = this.props;

    const orgs = [role.org.id];

    try {
      // TODO: Add `createdBy` property when creating ad group
      await this.props.createAdGroupCreatives({
        variables: {
          name: this.state.adGroupName,
          medias: this.state.adGroupMedias,
          org: orgs.map(o => {
            return { id: o };
          }),
        },
      });

      // Must only set the first created adgroup if only amplify is turned on
      if (
        this.props.adGroupCreatives &&
        this.props.adGroupCreatives.length === 1 &&
        amplify
      ) {
        this.setAdGroupAsAmplifyDefault(this.props.adGroupCreatives[0]);
      }
      message.destroy();
      message.success(`Successfully Created Ad Group`);
    } catch (err) {
      console.log("error creating adgroup");
    }
  };

  setAdGroupAsAmplifyDefault = async record => {
    await this.props.updateManyAdGroupCreatives({
      variables: {
        orgId: record.org[0].id,
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAdGroupCreatives({
      variables: {
        id: record.id,
        data: { isAmplifyDefault: true },
      },
    });
  };

  bulkCreateOrgMedia = async (
    medias,
    invalidMedias,
    calledFromOrgConfig = false
  ) => {
    try {
      if (!calledFromOrgConfig) {
        message.loading("Uploading creatives...", 0);
      }

      const { createManyOrgMedia } = this.props;
      const chunkSize = 10;
      const totalChunks = Math.ceil(medias.length / chunkSize);

      for (let i = 0; i < totalChunks; i++) {
        const startIndex = i * chunkSize;
        const endIndex = startIndex + chunkSize;
        const chunkMedias = medias.slice(startIndex, endIndex);

        const data = {
          media: {
            create: chunkMedias,
          },
        };

        const result = await createManyOrgMedia({
          variables: {
            id: this.state.selectedOrg.id,
            data,
          },
        });

        if (!(result && result.data)) {
          throw new Error("Failed to upload media");
        }
      }

      if (!calledFromOrgConfig) {
        message.destroy();
        if (invalidMedias.length) {
          message.error(
            `Failed to upload ${invalidMedias.length} creative(s).`
          );
        }
        message.success(`${medias.length} Creative(s) successfully uploaded`);
      }
    } catch (error) {}
  };

  setAdGroupAsAmplifyDefault = async record => {
    await this.props.updateManyAdGroupCreatives({
      variables: {
        orgId: record.org[0].id,
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAdGroupCreatives({
      variables: {
        id: record.id,
        data: { isAmplifyDefault: true },
      },
    });
  };

  setAdGroupAsAmplifyDefault = async record => {
    await this.props.updateManyAdGroupCreatives({
      variables: {
        orgId: record.org[0].id,
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAdGroupCreatives({
      variables: {
        id: record.id,
        data: { isAmplifyDefault: true },
      },
    });
  };

  onDeleteAdgroup = async ids => {
    try {
      message.loading("Action in progress..", 0);
      this.setState({
        onLoadingDeleteAdGroup: true,
      });
      const results = [];
      ids.forEach(id => {
        results.push(
          this.props.deleteAdGroupCreatives({
            variables: {
              id: id,
            },
          })
        );
      });

      Promise.all(results).then(values => {
        message.destroy();
        message.success(`${values.length} Ad group(s) successfully deleted.`);
        this.setState({
          onLoadingDeleteAdGroup: false,
        });
      });
    } catch (err) {
      message.destroy();
      message.success("Failed to delete ad group(s).");
      console.log(err);
    }
  };

  updateAdGroup = async ({ adGroup, type }) => {
    try {
      message.loading("Updating Ad Group..", 0);
      const { updateAdGroupCreatives } = this.props;
      const { id, name, medias } = adGroup;
      await updateAdGroupCreatives({
        variables: {
          id,
          data: {
            name,
            medias: {
              [type]: medias.map(id => ({ id })),
            },
          },
        },
      });
      message.destroy();
      message.success(`Successfully updated ad group`);
    } catch (error) {
      message.destroy();
      message.error("Failed to update ad group.");
    }
  };

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props.children.props,
      currentuser: this.props.currentuser,
      onChangeStateValue: this.onChangeStateValue,
      createOrgMedia: this.props.createOrgMedia,
      creativeName: this.state.creativeName,
      onDeleteMedias: this.onDeleteMedias,
      onLoadingDeleteMedias: this.state.onLoadingDeleteMedias,
      onCreateAdgroup: this.onCreateAdgroup,
      orgMedias: this.props.orgMedias,
      bulkCreateOrgMedia: this.bulkCreateOrgMedia,
      refetchMedias: this.props.refetchMedias,
      updateOrgMedia: this.props.updateOrgMedia,
      setAdGroupAsAmplifyDefault: this.setAdGroupAsAmplifyDefault,
      adGroupCreatives: this.props.adGroupCreatives,
      onDeleteAdgroup: this.onDeleteAdgroup,
      onLoadingDeleteAdGroup: this.state.onLoadingDeleteAdGroup,
      selectedOrg: this.state.selectedOrg,
      updateAdGroup: this.updateAdGroup,
      refetchVideoMedias: this.props.refetchVideoMedias,
      orgVideoMedias: this.props.orgVideoMedias,
    });
  }
}

export default CreativesMainController;
