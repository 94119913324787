import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import moment from "moment";
import {
  styles,
  gridLeft,
  gridRight,
  leftLabel,
  rightLabel,
} from "../../../shared/styles/chart";
import { daysWeek } from "../../../../core/utils/constants/constants";
import { getSummaryScale } from "../../../../core/utils/campaigns";

const DualAxisAreaChart = ({
  dualChartData,
  leftY,
  rightY,
  currencyFormat,
  xAxis,
  customScale,
  isRightYBar = false,
}) => {
  let axisData = dualChartData.map(item => {
    const keyValue = Object.entries(item);

    const left = keyValue[keyValue.findIndex(pair => pair[0] === leftY)];
    const right = keyValue[keyValue.findIndex(pair => pair[0] === rightY)];
    const x = keyValue[keyValue.findIndex(pair => pair[0] === xAxis)];

    return {
      left: left && left[1] ? left[1] : "",
      right: right && right[1] ? right[1] : "",
      xAxis: x[1],
    };
  });

  const scale = customScale
    ? customScale(dualChartData, leftY, rightY, currencyFormat)
    : getSummaryScale(dualChartData, leftY, rightY, currencyFormat);

  const formatXaxis = (day, format) => {
    if (day === daysWeek.Sun || day === daysWeek.Sunday) return daysWeek.Sunday;
    if (day === daysWeek.Mon || day === daysWeek.Monday) return daysWeek.Monday;
    if (day === daysWeek.Tue || day === daysWeek.Tuesday)
      return daysWeek.Tuesday;
    if (day === daysWeek.Wed || day === daysWeek.Wednesday)
      return daysWeek.Wednesday;
    if (day === daysWeek.Thu || day === daysWeek.Thursday)
      return daysWeek.Thursday;
    if (day === daysWeek.Fri || day === daysWeek.Friday) return daysWeek.Friday;
    if (day === daysWeek.Sat || day === daysWeek.Saturday)
      return daysWeek.Saturday;
    return moment(day)
      .utc(true)
      .format(format);
  };

  const RightChart = () => {
    if (isRightYBar) {
      return (
        <React.Fragment>
          <Geom
            type={"interval"}
            opacity={0.5}
            position={`xAxis*right`}
            color={"#89d2d4"}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Geom
          type="lineStack"
          position={`xAxis*right`}
          color="#89d2d4"
          size={0}
        />
        <Geom
          type="point"
          position={`xAxis*right`}
          color="#89d2d4"
          shape="circle"
          size={dualChartData.length > 400 ? 1.7 : 3}
        />
      </React.Fragment>
    );
  };
  return (
    <div>
      <Chart
        height={400}
        padding="auto"
        style={styles.chart}
        data={axisData}
        scale={scale}
        forceFit
        onTooltipChange={e => {
          let items = e.items;
          const origin1 = items[0];
          const origin2 = items[1];
          items.splice(0);
          items.push({
            name: origin1.name,
            size: origin1.size,
            showMarker: origin1.showMarker,
            title: formatXaxis(origin1.point._origin.xAxis, "dddd MMMM D"),
            marker: origin1.marker,
            color: origin1.color,
            value: origin1.value,
            x: origin1.x,
            y: origin1.y,
            point: origin1.point,
          });
          if (origin2) {
            items.push({
              name: origin2.name,
              size: origin2.size,
              showMarker: origin2.showMarker,
              title: "",
              marker: origin2.marker,
              color: origin2.color,
              value: origin2.value,
              x: origin2.x,
              y: origin2.y,
              point: origin2.point,
            });
          }
        }}
      >
        <Axis
          name="xAxis"
          label={{
            formatter: xAxis => formatXaxis(xAxis, "M/D/YY"),
          }}
        />

        <Axis
          name="left"
          grid={gridLeft}
          label={leftY === "None" ? null : leftLabel}
        />
        <Axis
          visible={rightY === "None" ? false : true}
          name="right"
          grid={gridRight}
          label={rightY === "None" ? null : rightLabel}
        />

        <Tooltip title={true} />
        <Legend
          position="bottom-center"
          visible={true}
          items={[
            {
              value: scale.left.alias,
              marker: {
                symbol: "circle",
                fill: "#b2d5ff",
                radius: 5,
                lineWidth: 3,
              },
            },
            {
              value: scale.right.alias,
              marker: {
                symbol: "circle",
                fill: "#89d2d4",
                radius: 5,
                lineWidth: 3,
              },
            },
          ]}
        />
        <Geom
          type="lineStack"
          position={`xAxis*left`}
          color="#b2d5ff"
          size={0}
        />
        <Geom
          type="point"
          position={`xAxis*left`}
          color="#b2d5ff"
          shape="circle"
          size={dualChartData.length > 400 ? 1.7 : 3}
        />
        <RightChart />
      </Chart>
    </div>
  );
};

export default DualAxisAreaChart;
