import React from "react";
import { Row } from "antd";
import SearchWithClear from "../../../../shared/SearchWithClear";

const FiltersBar = () => {
  return (
    <Row className="filter-bar-container" justify={{ xs: "center", lg: "end" }}>
      <SearchWithClear />
    </Row>
  );
};

export default FiltersBar;
