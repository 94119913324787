import React, { useMemo } from "react";
import { determineUserAccess } from "../../../../permissions/userLevelAccess";

const RollUpPaidSearchController = ({
  children,
  org,
  data,
  refetch,
  loading,
  byRollUpRangeDate,
  setByRollUpRangeDate,
  prevRangeData,
  loadingPrevRangeData,
  ...props
}) => {
  const orgConfig = determineUserAccess(props.currentUser.permission);
  const diffPcts = useMemo(() => {
    let diffs = {
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      revenue: 0,
    };

    const prev = prevRangeData ? prevRangeData.getRollUpPaidSearch : undefined;
    const now = data ? data.getRollUpPaidSearch : undefined;
    if (prev && now) {
      diffs.clicks = now.clicks - prev.clicks;
      diffs.impressions = now.impressions - prev.impressions;
      diffs.ctr = now.ctr - prev.ctr;
      diffs.cpc = now.cpc - prev.cpc;
      diffs.revenue = now.revenue - prev.revenue;

      return {
        impressions: (diffs.impressions / now.impressions) * 100,
        clicks: (diffs.clicks / now.clicks) * 100,
        ctr: (diffs.ctr / now.ctr) * 100,
        cpc: (diffs.cpc / now.cpc) * 100,
        revenue: (diffs.revenue / now.revenue) * 100,
      };
    }
    return null;
  }, [prevRangeData, data]);

  return React.cloneElement(children, {
    ...props,
    data: data ? data.getRollUpPaidSearch : null,
    org,
    loading,
    byRollUpRangeDate,
    setByRollUpRangeDate,
    prevRangeData,
    loadingPrevRangeData,
    diffPcts,
    orgConfig,
  });
};

export default RollUpPaidSearchController;
