import React from "react";
import { Row, Input, Select, Button } from "antd";

const InputGroup = Input.Group;
const { Option } = Select;

export const FilterContent = ({
  geoLocationFiltersChanged,
  filterAttributeChanged,
  filterValueChanged,
  toggleFilterModal,
  filterAttribute,
  filterValue,
  isPopover,
}) => {
  return (
    <Row style={{ width: 300 }}>
      <InputGroup compact>
        <Select
          value={filterAttribute}
          style={{ width: "30%" }}
          onChange={value => {
            filterAttributeChanged(value);
          }}
          dropdownMatchSelectWidth={false}
        >
          <Option value="name">Name</Option>
          <Option value="street">Street Address</Option>
          <Option value="zip">Zip</Option>
          <Option value="city">City</Option>
          <Option value="state">State</Option>
        </Select>
        <Input
          value={filterValue}
          onChange={e => {
            filterValueChanged(e.target.value);
          }}
          style={{ width: "70%" }}
        />
      </InputGroup>
      {isPopover && (
        <Button
          style={{ float: "right", marginTop: 10 }}
          type="primary"
          onClick={() => {
            geoLocationFiltersChanged();
            toggleFilterModal();
          }}
        >
          Set Filter
        </Button>
      )}
    </Row>
  );
};
