import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatCurrency2SigFig,
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../utils/campaigns";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import moment from "moment";

const SEOTrafficContext = createContext();

function hslToHex(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const randomColors = () => {
  const h = Math.floor(Math.random() * (360 - 0 + 1) + 0);
  const s = 44;
  const l = 70;

  return hslToHex(h, s, l);
};

const calculateTrafficTotals = trafficData => {
  return trafficData.reduce(
    (acc, item) => {
      acc.pageViews += item.pageViews || 0;
      acc.sessions += item.sessions || 0;
      return acc;
    },
    { pageViews: 0, sessions: 0 }
  );
};

export const SEOTrafficProvider = ({ reportKey = "-", children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  //Traffic by Channel
  const [trafficByChannelSummary, setTrafficByChannelSummary] = useState();
  const [trafficByChannelByDate, setTrafficByChannelByDate] = useState([]);
  const [mediums, setMediums] = useState([]);

  // Totals
  const [totalPageViews, setTotalPageViews] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  // New Vs Returning
  const [newVsReturning, setNewVsReturning] = useState({
    total: 0,
    new: 0,
    returning: 0,
  });
  // Sessions Chart
  const [sessions, setSessions] = useState(0);
  const [sessionsByDate, setSessionsByDate] = useState([]);
  // Bounce Rate Chart
  const [bounceRate, setBounceRate] = useState(0);
  // Landing Page Report
  const [topLandingPages, setTopLandingPages] = useState([]);
  // Top Cities Report
  const [topCities, setTopCities] = useState([]);
  // Revenue By Traffic Source Report
  const [revenueByTrafficSummary, setRevenueByTrafficSummary] = useState();
  const [revenueByTraffic, setRevenueByTraffic] = useState([]);

  // Revenue By Date
  const [revenueByDateSummary, setRevenueByDateSummary] = useState();
  const [revenueByDate, setRevenueByDate] = useState([]);
  const [startDateEnDate, setStartDateEnDate] = useState([]);
  const [revenueByTrafficSource, setRevenueByTrafficSource] = useState([]);
  const [
    revenueByTrafficSourceSummary,
    setRevenueByTrafficSourceSummary,
  ] = useState();

  const calculateBounceRate = (sessions, engagedSessions) => {
    if (sessions <= 0 || engagedSessions > sessions) {
      console.error("Invalid data for bounce rate calculation:", {
        sessions,
        engagedSessions,
      });
      return 0;
    }
    const bounceRate = 100 - (engagedSessions / sessions) * 100;
    return Math.max(0, Math.min(100, bounceRate)); // Ensure result is between 0 and 100
  };

  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  // Fetch the report from S3 on the signed URL has finished
  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportData(json);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  useEffect(() => {
    if (reportData) {
      const {
        trafficByChannelReport,
        newVsReturningReport,
        topLandingPageByDateReport,
        topCitiesByDateReport,
        revenueByTrafficSourceReport,
        revenueByDateReport,
      } = reportData;

      // Find the latest date
      const endDate = trafficByChannelReport.byDate.reduce((latest, curr) => {
        const currDate = moment(curr.date, "YYYY-MM-DD");
        return !latest || currDate.isAfter(latest) ? currDate : latest;
      }, null);

      if (endDate) {
        // Traffic By Channel
        setTrafficByChannelSummary(
          generateTrafficSummary(trafficByChannelReport.total || [])
        );
        setTrafficByChannelByDate(
          generateTrafficByDate(
            (trafficByChannelReport.byDate || []).filter(d => {
              const eDate = moment(endDate).clone();
              return moment(d.date).isAfter(eDate.subtract(30, "days"));
            }),
            trafficByChannelReport.total || []
          )
        );

        // New Vs Returning Chart
        setNewVsReturning({
          total:
            (newVsReturningReport.new || 0) +
            (newVsReturningReport.returning || 0),
          new: newVsReturningReport.new || 0,
          returning: newVsReturningReport.returning || 0,
        });

        // Sessions Chart
        const sessionData = trafficByChannelReport.byDate
          .filter(d =>
            moment(d.date).isAfter(moment(endDate).subtract(30, "days"))
          )
          .map(d => ({
            date: d.date,
            value: d.mediums.reduce(
              (total, medium) => total + Number(medium.sessions),
              0
            ),
          }));

        const totalSessions = sessionData.reduce(
          (total, d) => total + d.value,
          0
        );

        setSessions(totalSessions);
        setSessionsByDate(sessionData);

        // Bounce Rate Chart
        const totSessions = revenueByDateReport.byDate.reduce(
          (sum, day) => sum + (day.sessions || 0),
          0
        );
        const totalEngagedSessions = revenueByDateReport.byDate.reduce(
          (sum, day) => sum + (day.engagedSessions || 0),
          0
        );

        const calculatedBounceRate = calculateBounceRate(
          totSessions,
          totalEngagedSessions
        );

        setBounceRate(calculatedBounceRate);
        setTotalSessions(totSessions);

        // Top Landing Pages
        setTopLandingPages(topLandingPageByDateReport.topLandingPages || []);

        // Top Cities
        setTopCities(topCitiesByDateReport.topCities || []);

        // Revenue By Traffic Source
        if (revenueByTrafficSourceReport) {
          setRevenueByTrafficSummary(
            generateRevenueByTrafficSummary(
              revenueByTrafficSourceReport.total || {}
            )
          );
          setRevenueByTraffic(revenueByTrafficSourceReport.trafficSource || []);

          setRevenueByTrafficSource(
            generateRevenueByTrafficSource(
              revenueByTrafficSourceReport.byDate || []
            )
          );
          const updatedRevenueByTraffic =
            revenueByTrafficSourceReport.trafficSource || [];
          setRevenueByTraffic(updatedRevenueByTraffic);

          setRevenueByTrafficSourceSummary(
            generateRevenueBySource(updatedRevenueByTraffic)
          );
        }

        setReportLoading(false);
        const eDate = moment(endDate).clone();
        setStartDateEnDate([eDate.subtract(29, "days"), endDate]);

        // Calculate totals
        const { pageViews, sessions } = calculateTrafficTotals(
          trafficByChannelReport.total || []
        );
        setTotalPageViews(pageViews);
        setTotalSessions(sessions);
      } else {
        setReportError("No valid data found in the report");
        setReportLoading(false);
      }
    }
  }, [reportData]);

  useEffect(() => {
    if (revenueByTraffic.length > 0) {
      setRevenueByTrafficSourceSummary(
        generateRevenueBySource(revenueByTraffic)
      );
    }
  }, [revenueByTraffic]);

  const onDateChange = dates => {
    const {
      trafficByChannelReport,
      newVsReturningReport,
      revenueByDateReport,
      topLandingPageByDateReport,
      topCitiesByDateReport,
      revenueByTrafficSourceReport,
    } = reportData;

    // Revenue By Date
    const byDateRevenueFiltered = revenueByDateReport.byDate.filter(d => {
      return (
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
      );
    });

    const revenueByDateSummary = byDateRevenueFiltered.reduce(
      (acc, curr) => {
        acc.revenue += Number(curr.revenue);
        acc.conversions += Number(curr.conversions);
        acc.transactions += Number(curr.transactions);
        acc.sessions += Number(curr.sessions);
        acc.averageOrder = acc.revenue / acc.transactions;
        acc.econConvRate = (acc.transactions / acc.sessions) * 100;
        acc.engagedSessions = Number(curr.engagedSessions);
        acc.bounceRate =
          acc.sessions > 0
            ? 100 - (acc.engagedSessions / acc.sessions) * 100
            : 0;
        return acc;
      },
      {
        revenue: 0,
        conversions: 0,
        averageOrder: 0,
        econConvRate: 0,
        transactions: 0,
        sessions: 0,
        engagedSessions: 0,
        bounceRate: 0,
      }
    );

    setRevenueByDate(byDateRevenueFiltered);
    setRevenueByDateSummary(generateRevenueByDateSummary(revenueByDateSummary));

    // Traffic By Channel
    const trafficByChannelByDateFiltered = trafficByChannelReport.byDate.filter(
      d => {
        return (
          moment(d.date).isSameOrAfter(moment(dates[0])) &&
          moment(d.date).isSameOrBefore(moment(dates[1]))
        );
      }
    );

    const trafficByChannelSummary = trafficByChannelByDateFiltered.reduce(
      (acc, d) => {
        d.mediums.forEach(m => {
          const channelExist = acc.findIndex(c => c.medium === m.medium);
          if (channelExist === -1) {
            acc.push({
              medium: m.medium,
              sessions: Number(m.sessions),
            });
          } else {
            acc[channelExist].sessions += Number(m.sessions);
          }
        });
        return acc;
      },
      []
    );

    setTrafficByChannelSummary(generateTrafficSummary(trafficByChannelSummary));
    setTrafficByChannelByDate(
      generateTrafficByDate(
        trafficByChannelByDateFiltered,
        trafficByChannelSummary
      )
    );

    // New Vs Returning
    const newVsReturningByDate = newVsReturningReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    const newTotal = newVsReturningByDate.reduce(
      (acc, curr) => acc + curr.new,
      0
    );
    const returningTotal = newVsReturningByDate.reduce(
      (acc, curr) => acc + curr.returning,
      0
    );
    setNewVsReturning({
      total: newTotal + returningTotal,
      new: newTotal,
      returning: returningTotal,
    });

    // Sessions Chart
    const sessionData = trafficByChannelReport.byDate
      .filter(
        d =>
          moment(d.date).isSameOrAfter(moment(dates[0])) &&
          moment(d.date).isSameOrBefore(moment(dates[1]))
      )
      .map(d => ({
        date: d.date,
        value: d.mediums.reduce(
          (total, medium) => total + Number(medium.sessions),
          0
        ),
      }));

    const totalSessions = sessionData.reduce((total, d) => total + d.value, 0);

    setSessions(totalSessions);
    setSessionsByDate(sessionData);

    // Bounce Rate Chart
    const filteredData = revenueByDateReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );

    const totSessions = filteredData.reduce(
      (sum, day) => sum + (day.sessions || 0),
      0
    );
    const totalEngagedSessions = filteredData.reduce(
      (sum, day) => sum + (day.engagedSessions || 0),
      0
    );

    const calculatedBounceRate = calculateBounceRate(
      totSessions,
      totalEngagedSessions
    );

    setBounceRate(calculatedBounceRate);
    setTotalSessions(totSessions);

    // Top Landing Pages
    const topLandingPagesFiltered = topLandingPageByDateReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    setTopLandingPages(
      topLandingPagesFiltered
        .reduce((acc, curr) => {
          const pageExist = acc.findIndex(
            c => c.landingPage === curr.landingPage
          );
          if (pageExist === -1) {
            acc.push({
              landingPage: curr.landingPage,
              screenPageViews: Number(curr.screenPageViews),
            });
          } else {
            acc[pageExist].screenPageViews += Number(curr.screenPageViews);
          }
          return acc;
        }, [])
        .sort((a, b) => b.screenPageViews - a.screenPageViews)
        .slice(0, 10)
    );

    // Top Cities
    const topCitiesFiltered = topCitiesByDateReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );
    setTopCities(
      topCitiesFiltered
        .reduce((acc, curr) => {
          const cityExist = acc.findIndex(c => c.city === curr.city);
          if (cityExist === -1) {
            acc.push({
              city: curr.city,
              sessions: Number(curr.sessions),
            });
          } else {
            acc[cityExist].sessions += Number(curr.sessions);
          }
          return acc;
        }, [])
        .sort((a, b) => b.sessions - a.sessions)
        .slice(0, 10)
    );

    // Revenue By Traffic Source
    const revenueByTrafficSourceFiltered = revenueByTrafficSourceReport.byDate.filter(
      d =>
        moment(d.date).isSameOrAfter(moment(dates[0])) &&
        moment(d.date).isSameOrBefore(moment(dates[1]))
    );

    const updatedRevenueByTraffic = revenueByTrafficSourceFiltered.reduce(
      (acc, curr) => {
        const mediumExists = acc.findIndex(
          c => c.medium === curr.sessionMedium
        );

        if (mediumExists === -1) {
          acc.push({
            medium: curr.sessionMedium,
            revenue: Number(curr.totalRevenue),
            conversions: Number(curr.conversions),
            transactions: Number(curr.transactions),
            averageOrder:
              curr.transactions !== 0
                ? Number(curr.totalRevenue) / Number(curr.transactions)
                : 0,
            sessions: Number(curr.sessions),
            econConvRate:
              (Number(curr.transactions) / Number(curr.sessions)) * 100,
          });
        } else {
          acc[mediumExists].revenue += Number(curr.totalRevenue);
          acc[mediumExists].conversions += Number(curr.conversions);
          acc[mediumExists].transactions += Number(curr.transactions);
          acc[mediumExists].sessions += Number(curr.sessions);
          acc[mediumExists].averageOrder =
            acc[mediumExists].transactions !== 0
              ? acc[mediumExists].revenue / acc[mediumExists].transactions
              : 0;
          acc[mediumExists].econConvRate =
            (acc[mediumExists].transactions / acc[mediumExists].sessions) * 100;
        }
        return acc;
      },
      []
    );

    setRevenueByTraffic(updatedRevenueByTraffic);

    // Update revenueByTrafficSummary
    setRevenueByTrafficSummary(
      generateRevenueByTrafficSummary({
        revenue: updatedRevenueByTraffic.reduce(
          (sum, item) => sum + item.revenue,
          0
        ),
        conversions: updatedRevenueByTraffic.reduce(
          (sum, item) => sum + item.conversions,
          0
        ),
        transactions: updatedRevenueByTraffic.reduce(
          (sum, item) => sum + item.transactions,
          0
        ),
        sessions: updatedRevenueByTraffic.reduce(
          (sum, item) => sum + item.sessions,
          0
        ),
      })
    );

    setReportLoading(false);
    setStartDateEnDate(dates);

    const revenueByTrafficSourceByDateFiltered = generateRevenueByTrafficSource(
      revenueByTrafficSourceReport.byDate.filter(
        d =>
          moment(d.date).isSameOrAfter(moment(dates[0])) &&
          moment(d.date).isSameOrBefore(moment(dates[1]))
      )
    );
    setRevenueByTrafficSource(revenueByTrafficSourceByDateFiltered);
  };

  const generateTrafficSummary = totals => {
    const mediums = (totals || []).map(m => ({
      medium:
        m.medium === "(none)"
          ? "direct"
          : m.medium === "(not set)"
          ? "not set"
          : m.medium,
      color: randomColors(),
    }));
    setMediums(mediums);
    return {
      visible: true,
      values: (totals || []).map(channel => ({
        title:
          channel.medium === "(none)"
            ? "direct"
            : channel.medium === "(not set)"
            ? "not set"
            : channel.medium,
        sessions: Number(channel.sessions) || 0,
        pageViews: Number(channel.pageViews) || 0,
        prevValue: 0,
        prevPercentage: 0,
        prevRanges: null,
      })),
    };
  };

  const generateTrafficByDate = (byDates, summary) => {
    const mediums = summary.map(m =>
      m.medium === "(none)"
        ? "direct"
        : m.medium === "(not set)"
        ? "not set"
        : m.medium
    );

    return byDates.map(date => {
      const byDate = {
        date: moment(date.date, "YYYYMMDD").format("YYYY-MM-DD"),
      };

      date.mediums.forEach(m => {
        const mediumName =
          m.medium === "(none)"
            ? "direct"
            : m.medium === "(not set)"
            ? "not set"
            : m.medium;
        byDate[mediumName] = {
          sessions: m.sessions,
          pageViews: m.pageViews,
        };
      });

      // Add missing mediums with 0 sessions and pageViews
      mediums.forEach(m => {
        if (!(m in byDate)) {
          byDate[m] = { sessions: 0, pageViews: 0 };
        }
      });

      return byDate;
    });
  };

  const generateRevenueByTrafficSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(totals.revenue),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Order",
          value: formatCurrency2SigFig(totals.averageOrder),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Ecom Conv. Rate",
          value: formatNumber2SigFig(totals.econConvRate) + "%",
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const generateRevenueByDateSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(totals.revenue),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Average Order",
          value: formatCurrency2SigFig(totals.averageOrder),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Ecom Conv. Rate",
          value: formatNumber2SigFig(totals.econConvRate) + "%",
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const generateRevenueByTrafficSource = byDateData => {
    const revenueByTrafficSourceMap = new Map();

    byDateData.forEach(item => {
      const { date, sessionMedium, totalRevenue } = item;
      if (!revenueByTrafficSourceMap.has(date)) {
        revenueByTrafficSourceMap.set(date, {
          date,
          total: 0,
          mediums: new Map(),
        });
      }

      const dateEntry = revenueByTrafficSourceMap.get(date);
      dateEntry.total += Number(totalRevenue);

      const mediumName =
        sessionMedium === "(none)"
          ? "direct"
          : sessionMedium === "(not set)"
          ? "not set"
          : sessionMedium;

      if (!dateEntry.mediums.has(mediumName)) {
        dateEntry.mediums.set(mediumName, 0);
      }
      dateEntry.mediums.set(
        mediumName,
        dateEntry.mediums.get(mediumName) + Number(totalRevenue)
      );
    });

    return Array.from(revenueByTrafficSourceMap.values())
      .map(({ date, total, mediums }) => ({
        date,
        total,
        ...Object.fromEntries(mediums),
      }))
      .sort((a, b) => moment(a.date).diff(moment(b.date)));
  };

  const generateRevenueBySource = revenueData => {
    const mediums = revenueData.map(m => ({
      medium:
        m.medium === "(none)"
          ? "direct"
          : m.medium === "(not set)"
          ? "not set"
          : m.medium,
      color: randomColors(),
    }));

    return {
      visible: true,
      mediums: mediums,
      values: revenueData.map(source => ({
        title:
          source.medium === "(none)"
            ? "direct"
            : source.medium === "(not set)"
            ? "not set"
            : source.medium,
        value: formatCurrency2SigFig(source.revenue),
        sessions: formatWholeNumber(source.sessions),
        conversions: formatWholeNumber(source.conversions),
        transactions: formatWholeNumber(source.transactions),
        averageOrder: formatCurrency2SigFig(source.averageOrder),
        econConvRate: formatNumber2SigFig(source.econConvRate) + "%",
        prevValue: 0,
        prevPercentage: 0,
        prevRanges: null,
      })),
    };
  };

  return (
    <SEOTrafficContext.Provider
      value={{
        reportLoading,
        topLandingPages,
        topCities,
        newVsReturning,
        sessions,
        trafficByChannelSummary,
        trafficByChannelByDate,
        mediums,
        sessionsByDate,
        bounceRate,
        revenueByTrafficSummary,
        revenueByTraffic,
        revenueByDateSummary,
        revenueByDate,
        reportError,
        startDateEnDate,
        onDateChange,
        revenueByTrafficSource,
        revenueByTrafficSourceSummary,
        totalPageViews,
        totalSessions,
      }}
    >
      {children}
    </SEOTrafficContext.Provider>
  );
};

export const useSEOTrafficContext = () => {
  const context = useContext(SEOTrafficContext);
  if (!context) {
    throw new Error(
      "useSEOTrafficContext must be used within a SEOTrafficProvider"
    );
  }
  return context;
};
