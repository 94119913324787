import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  message,
  Spin,
  notification,
} from "antd";
import { LockFilled } from "@ant-design/icons";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";

import UPDATE_USER from "../../../.././core/GraphQl/Mutations/UPDATE_USER";
import AUTHENTICATED_USER from "../../../../core/GraphQl/Mutations/AUTHENTICATED_USER";
import GET_SIGN_IN_LOGO from "../../../../core/GraphQl/Mutations/GET_SIGN_IN_LOGO";

import {
  getTopRole,
  getRolesFeatures,
} from "../../../../core/utils/dataManipulating/organization";

import WhiteLabel from "../../../shared/WhiteLabel";
import { usePermify } from "@permify/react-role";

const result = WhiteLabel();
const logo = result;

function antFormHasErrors(fieldsError) {
  return fieldsError.map(fe => fe.errors).flat().length > 0;
}

const SignIn = ({ authenticatedUser, updateUser, history, getOrgLogo }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { search } = window.location;
  const { setUser: setPermifyUser } = usePermify();

  useEffect(() => {
    localStorage.removeItem("userData");
  }, []);

  const [form] = Form.useForm();

  const { validateFields, getFieldsError, resetFields } = form;

  const loginUser = async user => {
    try {
      const response = await authenticatedUser({
        variables: {},
      });
      const {
        authenticatedUser: {
          id,
          username,
          config,
          email,
          cognitoUserId,
          avatar,
          roles,
          notifications,
          ssoToken,
          campaignTableDisplayColumns,
        },
      } = response.data;

      let features = getRolesFeatures(roles);

      if (features.includes("ENABLED")) {
        const enabledFeatures = features.filter(feature => {
          return feature !== "ENABLED";
        });

        const role = getTopRole(roles);
        const isAdmin = config ? config.isAdmin : false;
        const isDemo = config ? config.isDemo : false;

        const isPreviewOrg = false;

        const userData = {
          isAuthenticated: true,
          id,
          username,
          email,
          cognitoUserId,
          avatar,
          features: enabledFeatures,
          notifications,
          defaultRole: role,
          role,
          isAdmin,
          isDemo,
          isPreviewOrg,
          permission: config,
          ssoToken,
          campaignTableDisplayColumns:
            campaignTableDisplayColumns && campaignTableDisplayColumns.length
              ? campaignTableDisplayColumns
              : [],
          config,
        };

        let userRoles = [];

        if (config) {
          userRoles = Object.keys(userData.config)
            .filter(key => key.startsWith("is"))
            .filter(key => userData.config[key]);
        }

        setPermifyUser({
          id,
          roles: userRoles,
          permissions: ["default"],
        });

        await updateUser({
          variables: { ...userData },
        });

        //If Some of the features on home page is added. Turn this on to redirect home page when logging in
        //history.push("/home");
        const searchParams = new URLSearchParams(search);
        const redirectUrl = searchParams.get("redirectUrl");
        if (redirectUrl) {
          history.push(redirectUrl);
        }

        notification.close("sessionExpired");
        message.success("Success! You're now signed in!");
      } else {
        Auth.signOut();
        setLoading(false);
        message.error("MOJO is not enabled for this user");
      }
    } catch (err) {
      console.log(err);
      message.error("Failure signing in", err);
      setLoading(false);
    }
  };

  const handleSubmit = values => {
    setLoading(true);
    // Ensure that current user is signed out.
    Auth.signOut().then(() => {
      if (!Auth || typeof Auth.signIn !== "function") {
        setLoading(false);
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }

      Auth.signIn(values.username, values.password)
        .then(user => {
          if (
            user.challengeName === "SMS_MFA" ||
            user.challengeName === "SOFTWARE_TOKEN_MFA"
          ) {
            setUser(user);
            setLoading(false);
          } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setLoading(false);
            history.push({
              pathname: "/new",
              state: { username: values.username, password: values.password },
            });
            message.warning(
              "You are currently using a temporary password, you are required to change your password"
            );
          } else if (user.challengeName === "MFA_SETUP") {
            setLoading(false);
            history.push("/setup");
          } else {
            loginUser(user);
          }
        })
        .catch(err => {
          if (err.code === "UserNotConfirmedException") {
            // NOTE: This error occurs when on user registration the confirmation step wasn't completed
            //   Auth.resendSignUp(username).then(() => {
            //     console.log('code resent successfully');
            // }).catch(e => {
            //     console.log(e);
            // });
            message.warning("Sign up confirmation not completed!");
          } else if (err.code === "PasswordResetRequiredException") {
            history.push("/recover");
            message.warning(err.message);
          } else {
            message.error(err.message);
          }
          Auth.signOut();
          setLoading(false);
        });
    });
  };

  /*
      const googleSignIn = async ({
        tokenObj: { accessToken, expiresIn },
        profileObj: { name, email },
      }) => {
        console.log("accessToken", accessToken, expiresIn);
        try {
          setLoading(true);
          await Auth.signOut();
          const response = await Auth.federatedSignIn(
            "google",
            {
              token: accessToken,
              expires_at: expiresIn,
            },
            {
              name,
              email,
            }
          );
          console.log("response", response);
          if (response) {
            const user = await Auth.currentUserCredentials();
            loginUser(user);
          } else throw new Error(response);
        } catch (err) {
          console.log("err", err);
          await Auth.signOut();
          setLoading(false);
        }
      };

      */
  const submitMFA = () => {
    setLoading(true);

    validateFields((err, { code }) => {
      if (!err) {
        if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          Auth.confirmSignIn(
            user, // Return object from Auth.signIn()
            code, // Confirmation code
            user.challengeName // MFA Type e.g. SMS, TOTP.
          )
            .then(data => loginUser(data))
            .catch(err => console.log(err));
        } else {
          Auth.signOut().then(() => {
            setLoading(false);
            message.warning(
              "Something went wrong. Please sign in again or contact an administrator."
            );
          });
        }
      }
    });
  };

  return (
    <div style={styles.landingPage}>
      <Card
        hoverable
        style={{ padding: 20 }}
        cover={
          logo === "" ? (
            <Spin />
          ) : (
            <img
              alt="example"
              src={logo}
              onClick={() => {
                resetFields();
                setUser(null);
                history.push("/");
              }}
            />
          )
        }
      >
        <Form form={form} onFinish={handleSubmit}>
          <React.Fragment>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  pattern: /^[A-Za-z0-9]+$/,
                  message:
                    "Username must be all one word and not contain spaces",
                },
              ]}
            >
              <Input addonBefore={<LockFilled />} placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  trigger: "blur",
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                addonBefore={<LockFilled />}
                placeholder="Password"
              />
            </Form.Item>
            {user ? (
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Enter the MFA code",
                    trigger: "blur",
                  },
                ]}
              >
                <Input addonBefore={<LockFilled />} placeholder="Code" />
              </Form.Item>
            ) : null}

            <Row type="flex" justify="center">
              {user ? (
                <Form.Item>
                  <Button
                    style={{ background: "black", borderColor: "grey" }}
                    type="primary"
                    onClick={submitMFA}
                    disabled={antFormHasErrors(getFieldsError())}
                    loading={loading}
                  >
                    Confirm Code
                  </Button>
                </Form.Item>
              ) : (
                <Col>
                  <Form.Item>
                    <Row type="flex" justify="center">
                      <Col>
                        <Button
                          htmlType="submit"
                          style={{ background: "black", borderColor: "grey" }}
                          type="primary"
                          disabled={antFormHasErrors(getFieldsError())}
                          loading={loading}
                        >
                          Sign In
                        </Button>
                      </Col>
                    </Row>
                    <Link style={{ color: "black" }} to="/recover">
                      Forgot password?
                    </Link>
                  </Form.Item>
                </Col>
              )}
            </Row>
            {/*<Row type="flex" justify="center">
                  <Form.Item>
                    <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      responseType="id_token"
                      buttonText=""
                      render={renderProps => (
                        <Button
                          onClick={() => {
                            renderProps.onClick();
                            setLoading(true);
                          }}
                          loading={loading}
                        >
                          <Icon type="google" /> Google Sign In
                        </Button>
                      )}
                      onSuccess={googleSignIn}
                      onFailure={error => {
                        console.log(error);
                        setLoading(false);
                        return;
                      }}
                    />
                  </Form.Item>
                  </Row>*/}
          </React.Fragment>
        </Form>
      </Card>
    </div>
  );
};

const styles = {
  landingPage: {
    top: 20,
    position: "relative",
    margin: " auto",
    width: "300px",
    padding: "5px",
  },
};

export default compose(
  graphql(UPDATE_USER, { name: "updateUser" }),
  graphql(AUTHENTICATED_USER, { name: "authenticatedUser" }),
  graphql(GET_SIGN_IN_LOGO, { name: "getOrgLogo" })
)(SignIn);

//-----------------------------------------------------------
// Do not remove - Ids for google sign in component

// PRODUCTION CLIENT ID (https://www.mediajel.io)
// clientId="810550991106-rae6cbmorg8di1c6c8k07dhtkfh4ntd9.apps.googleusercontent.com"
// STAGING CLIENT ID (https://mediajel.ninja
// clientId="215446100042-0424geevhr1jm5bpkv0nu8sjnvn471qa.apps.googleusercontent.com"
// DEV CLIENT ID (http://localhost:3000)
//clientId="522939381756-j3jkej82l9vqn7d8hmq8r6f4g1p14e12.apps.googleusercontent.com"

//-----------------------------------------------------------
