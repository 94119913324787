import React from "react";
import { Card, Steps, Typography } from "antd";
import { isEmpty } from "lodash";

const { Step } = Steps;
const { Text } = Typography;

const StepDescription = ({ subSteps, title }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {subSteps.map((subStep, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "5px",
            }}
            key={index}
          >
            <Text type="secondary" style={{ fontWeight: "500" }}>
              {title[index]}
            </Text>
            <Text
              type="secondary"
              style={
                isEmpty(subStep) || subStep === "None Added"
                  ? { fontStyle: "italic" }
                  : {}
              }
            >
              {isEmpty(subStep) ? "None Added" : subStep.replace(/,/gi, ", ")}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

const StepsCard = ({ stepsToComplete }) => {
  return (
    <Card
      bodyStyle={{ margin: "20px 0 20px 0" }}
      style={{
        overflowY: "auto",
        maxHeight: "700px",
      }}
    >
      <Steps
        direction="vertical"
        // progressDot={true}
        labelPlacement={"vertical"}
      >
        {stepsToComplete.map((step, index) => {
          return (
            <Step
              key={index}
              title={step.title}
              description={
                <StepDescription
                  title={step.descriptionTitle}
                  subSteps={step.description}
                />
              }
              status={step.complete ? "process" : "wait"}
            />
          );
        })}
      </Steps>
    </Card>
  );
};

export default StepsCard;
