import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import {
  formatNumber2SigFig,
  formatWholeNumberNaN,
} from "../../../../utils/campaigns";

const SEOKeywordsContext = createContext();

export const SEOKeywordsProvider = ({ reportKey = "-", children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  const [summaryDetails, setSummaryDetails] = useState();
  const [rankings, setRankings] = useState();
  const [selectedDevice, setSelectedDevice] = useState("all");
  const [keywordTableRankings, setKeywordTableRankings] = useState();

  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  // Fetch the report from S3 on the signed URL has finished
  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              setReportData(json);
            });
          } catch (err) {
            console.log(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  // Transform data once the report has loaded
  useEffect(() => {
    if (reportData) {
      const {
        keywordRankings,
        keywordRankingsDesktop,
        keywordRankingsMobile,
        keywordRankingsTablet,
      } = reportData;

      const desktopRankings = rankingsReportGenerate(keywordRankingsDesktop);
      const mobileRankings = rankingsReportGenerate(keywordRankingsMobile);
      const tabletRankings = rankingsReportGenerate(keywordRankingsTablet);
      const allDevicesRankings = rankingsReportGenerate(
        keywordRankings,
        true,
        keywordRankingsDesktop.currentKeywords,
        keywordRankingsMobile.currentKeywords,
        keywordRankingsTablet.currentKeywords
      );

      const formattedRankingsReport = {
        keywordReportRankings: allDevicesRankings.keywordReportRankings,
        keywordRankingsSummary: allDevicesRankings.keywordRankingsSummary,
        desktopKeywordReportRankings: desktopRankings.keywordReportRankings,
        desktopKeywordRankingsSummary: desktopRankings.keywordRankingsSummary,
        mobileKeywordReportRankings: mobileRankings.keywordReportRankings,
        mobileKeywordRankingsSummary: mobileRankings.keywordRankingsSummary,
        tabletKeywordReportRankings: tabletRankings.keywordReportRankings,
        tabletKeywordRankingsSummary: tabletRankings.keywordRankingsSummary,
      };

      setRankings(formattedRankingsReport);
      setSummaryDetails(
        generateKeywordRankingsSummary(
          allDevicesRankings.keywordRankingsSummary
        )
      );
      setKeywordTableRankings(allDevicesRankings.keywordReportRankings);
      setReportLoading(false);
    }
  }, [reportData]);

  useEffect(() => {
    if (rankings) {
      if (selectedDevice === "all") {
        setSummaryDetails(
          generateKeywordRankingsSummary(rankings.keywordRankingsSummary)
        );
        setKeywordTableRankings(rankings.keywordReportRankings);
      } else if (selectedDevice === "desktop") {
        setSummaryDetails(
          generateKeywordRankingsSummary(rankings.desktopKeywordRankingsSummary)
        );
        setKeywordTableRankings(rankings.desktopKeywordReportRankings);
      } else if (selectedDevice === "mobile") {
        setSummaryDetails(
          generateKeywordRankingsSummary(rankings.mobileKeywordRankingsSummary)
        );
        setKeywordTableRankings(rankings.mobileKeywordReportRankings);
      } else if (selectedDevice === "tablet") {
        setSummaryDetails(
          generateKeywordRankingsSummary(rankings.tabletKeywordRankingsSummary)
        );
        setKeywordTableRankings(rankings.tabletKeywordReportRankings);
      }
    }
  }, [rankings, selectedDevice]);

  const onSearchKeywordRankings = (value, searchType) => {
    if (value) {
      let filteredRankings;
      if (searchType === "include") {
        filteredRankings = rankings.keywordReportRankings.filter(item =>
          item.keyword.toLowerCase().includes(value.toLowerCase())
        );
      } else if (searchType === "exclude") {
        filteredRankings = rankings.keywordReportRankings.filter(
          item => !item.keyword.toLowerCase().includes(value.toLowerCase())
        );
      }
      setKeywordTableRankings(filteredRankings);
    } else {
      resetKeywordTableRankings();
    }
  };

  const resetKeywordTableRankings = () => {
    if (rankings) {
      if (selectedDevice === "all") {
        setKeywordTableRankings(rankings.keywordReportRankings);
      } else if (selectedDevice === "desktop") {
        setKeywordTableRankings(rankings.desktopKeywordReportRankings);
      } else if (selectedDevice === "mobile") {
        setKeywordTableRankings(rankings.mobileKeywordReportRankings);
      } else if (selectedDevice === "tablet") {
        setKeywordTableRankings(rankings.tabletKeywordReportRankings);
      }
    }
  };

  //Utitlity function to summarize and consolidate the rankings report by devices/all devices
  const rankingsReportGenerate = (
    rankingsReport,
    isAllDevice = false,
    desktopRanking,
    mobileRanking,
    tabletRanking
  ) => {
    const {
      currentKeywords,
      lastMonthKeywords,
      lastWeekKeywords,
    } = rankingsReport;
    const keywordRankingsSummary = {
      keywordFirstPage: 0,
      keywordSecondPage: 0,
      keywordImprove: 0,
      totalKeywords: 0,
    };

    const keywordReportRankings = currentKeywords.map(keyword => {
      const lastMonthKeywordsMatch = lastMonthKeywords.find(
        mntKeyword => mntKeyword.keyword === keyword.keyword
      );
      const lastWeekKeywordsMatch = lastWeekKeywords.find(
        wkKeyword => wkKeyword.keyword === keyword.keyword
      );

      //All devices requires additional data for desktop, mobile and tablet to be added on the report
      if (isAllDevice) {
        const desktopRank = desktopRanking.find(
          dskRank => dskRank.keyword === keyword.keyword
        );
        const mobileRank = mobileRanking.find(
          mblRank => mblRank.keyword === keyword.keyword
        );
        const tabletRank = tabletRanking.find(
          tblRank => tblRank.keyword === keyword.keyword
        );

        return {
          ...keyword,
          currentRank: formatNumber2SigFig(keyword.position),
          lastMonthRank: lastMonthKeywordsMatch
            ? formatNumber2SigFig(lastMonthKeywordsMatch.position)
            : null,
          lastWeekRank: lastWeekKeywordsMatch
            ? formatNumber2SigFig(lastWeekKeywordsMatch.position)
            : null,
          desktopRank: desktopRank
            ? formatNumber2SigFig(desktopRank.position)
            : null,
          mobileRank: mobileRank
            ? formatNumber2SigFig(mobileRank.position)
            : null,
          tabletRank: tabletRank
            ? formatNumber2SigFig(tabletRank.position)
            : null,
        };
      }
      return {
        ...keyword,
        currentRank: formatNumber2SigFig(keyword.position),
        lastMonthRank: lastMonthKeywordsMatch
          ? formatNumber2SigFig(lastMonthKeywordsMatch.position)
          : null,
        lastWeekRank: lastWeekKeywordsMatch
          ? formatNumber2SigFig(lastWeekKeywordsMatch.position)
          : null,
      };
    });

    keywordReportRankings.forEach(keyword => {
      if (keyword.currentRank <= 10) {
        keywordRankingsSummary.keywordFirstPage += 1;
      } else if (keyword.currentRank > 10) {
        keywordRankingsSummary.keywordSecondPage += 1;
      }
      if (keyword.currentRank < keyword.lastWeekRank) {
        keywordRankingsSummary.keywordImprove += 1;
      }
      keywordRankingsSummary.totalKeywords += 1;
    });

    return { keywordReportRankings, keywordRankingsSummary };
  };

  const generateKeywordRankingsSummary = rankings => {
    return {
      visible: true,
      values: [
        {
          title: "Total Keywords",
          value: formatWholeNumberNaN(rankings.totalKeywords),
          tooltip: "Total Keywords represents the number of keywords tracked.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Keywords on Page 1",
          value: formatWholeNumberNaN(rankings.keywordFirstPage),
          tooltip:
            "Keywords on First Page represents the number of keywords ranking on the first page of search results.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Keywords on Page 2",
          value: formatWholeNumberNaN(rankings.keywordSecondPage),
          tooltip:
            "Keywords on Second Page represents the number of keywords ranking on the second page or higher of search results.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "IMPROVED RANKINGS",
          value: formatWholeNumberNaN(rankings.keywordImprove),
          tooltip:
            "Keywords Improved represents the number of keywords that improved in ranking.",
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  return (
    <SEOKeywordsContext.Provider
      value={{
        reportData,
        reportLoading,
        reportError,
        generateKeywordRankingsSummary,
        summaryDetails,
        rankings,
        keywordTableRankings,
        selectedDevice,
        setSelectedDevice,
        onSearchKeywordRankings,
        resetKeywordTableRankings,
      }}
    >
      {children}
    </SEOKeywordsContext.Provider>
  );
};

export const useSEOKeywordContext = () => {
  const context = useContext(SEOKeywordsContext);
  if (!context) {
    throw new Error(
      "SEOKeywordsContext must be used within a SEOTrafficProvider"
    );
  }
  return context;
};
