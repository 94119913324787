import React from "react";
import ReactDOM from "react-dom";
// import PropTypes from "prop-types";
import { GoogleApiWrapper } from "google-maps-react";
import { isEmpty, isEqual } from "lodash";
import googleMapsConfig from "./googlemaps";
import currentLocationIcon from "./assets/currentLocation.png";
import ClearAllButton from "./assets/antdesign/ClearAllShapesButton";
import ClearSelectedButton from "./assets/antdesign/ClearSelectedShapesButton";
import CrossHairs from "./assets/antdesign/CrossHairs";
import { message } from "antd";
import * as turf from "@turf/turf";

const scale = (inputY, yRange, xRange) => {
  const [xMin, xMax] = xRange;
  const [yMin, yMax] = yRange;

  const percent = (inputY - yMin) / (yMax - yMin);
  const outputX = percent * (xMax - xMin) + xMin;

  return outputX;
};

const heatMapColorforValue = value => {
  var h = (1.0 - value) * 240;
  return "hsl(" + h + ", 100%, 50%)";
};

const styles = {
  map: {
    height: 560,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  input: {
    width: "99.5%",
    borderRadius: 4,
    margin: "3px",
    fontSize: "20px",
  },
  inputContainer: {},
  outerContainer: { height: "100%", backgroundColor: "#FFFFFF" },
  mapContainer: {
    height: "560px",
    width: "100%",
    position: "relative",
  },
};

class MapPolygonDrawing extends React.Component {
  state = {
    overlays: [],
    previousOverlays: [],
    selectedShape: null,
    households: [],
  };

  componentDidMount() {
    this.drawMap();
    // TODO: Location check
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.shouldClearShapes === false &&
      this.props.shouldClearShapes === true
    ) {
      this.deleteAllShape();
      if (this.props.clearedCallback) this.props.clearedCallback();
    }
    if (!isEqual(prevProps.shapes, this.props.shapes)) {
      await this.initializeShapes();
      return true;
    }
    if (
      this.props.drawing &&
      prevProps.shapes.length &&
      this.props.shapes.length
    ) {
      if (prevProps.shapes.length === 1 && this.props.shapes.length === 2) {
        this.drawingManager.setMap(null);
        this.map.controls[
          this.props.google.maps.ControlPosition.RIGHT_BOTTOM
        ].clear();
      }
      if (prevProps.shapes.length === 2 && this.props.shapes.length === 1) {
        this.initDrawingTools();
      }
    }
    if (this.props.households !== prevProps.households) {
      this.drawHouseholds();
    }
  }

  resetCenter = centerLocation => {
    this.deleteMarkers();
    let map = this.map;
    const { google, zoom } = this.props;
    const icon = {
      url: currentLocationIcon,
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };

    this.markers.push(
      new google.maps.Marker({
        map: map,
        icon: icon,
        title: "My location",
        position: centerLocation,
      })
    );

    this.map.panTo(centerLocation);
    this.map.setZoom(zoom);
  };

  drawHouseholds = async () => {
    const { google, shapes, households } = this.props;
    const newHouseholds = [];
    let min = Infinity,
      max = -Infinity;
    // const bounds = new google.maps.LatLngBounds();
    const zips = Object.values(households).reduce((acc, { zipcode }) => {
      Object.entries(zipcode).forEach(([zip, count]) => {
        if (acc[zip] === undefined) acc[zip] = true;
        if (count < min) min = count;
        if (count > max) max = count;
      });
      return acc;
    }, {});
    let geocodedZips = await Promise.all(
      Object.keys(zips).map(async (zip, index) => {
        return new Promise(resolve => {
          setTimeout(async () => {
            const basicString =
              "https://maps.googleapis.com/maps/api/geocode/json";
            let urlParams = `?address=${zip},US`;
            const request = `${basicString}${urlParams}&key=${googleMapsConfig.apiKey}`;
            return fetch(request)
              .then(res => res.json())
              .then(({ results }) => {
                // NOTE: unused rectangle bounding zip
                // const rectangle = new google.maps.Rectangle({
                //   fillColor: "#BCDCF9",
                //   fillOpacity: 0.1,
                //   strokeWeight: 1,
                //   strokeColor: "#57ACF9",
                //   clickable: false,
                //   editable: false,
                //   zIndex: 1,
                //   editable: false,
                //   map: this.map,
                //   bounds: {
                //     north: results[0].geometry.viewport.northeast.lat,
                //     south: results[0].geometry.viewport.southwest.lat,
                //     east: results[0].geometry.viewport.northeast.lng,
                //     west: results[0].geometry.viewport.southwest.lng,
                //   },
                // });
                // const circle = new google.maps.Circle({
                //   fillColor: "#BCDCF9",
                //   fillOpacity: 0.1,
                //   strokeWeight: 1,
                //   strokeColor: "#57ACF9",
                //   clickable: false,
                //   editable: false,
                //   zIndex: 1,
                //   map: this.map,
                //   center: results[0].geometry.location,
                //   radius: 1000,
                // });
                // newHouseholds.push(circle);
                resolve([zip, results]);
              });
          }, index * 15);
        });
      })
    );
    let zipLookup = Object.fromEntries(geocodedZips);
    shapes.forEach(async geoTarget => {
      const { id } = geoTarget;
      Object.entries(households[id].zipcode).forEach(([zipcode, count]) => {
        geoTarget.circles.forEach((circle, i) => {
          const latlng = new google.maps.LatLng(
            circle.coordinate.lat,
            circle.coordinate.lng
          );
          var flightPath = new google.maps.Polyline({
            path: [latlng, zipLookup[zipcode][0].geometry.location],
            geodesic: true,
            strokeColor: heatMapColorforValue(scale(count, [min, max], [0, 1])),
            strokeOpacity: scale(count, [min, max], [0.4, 1]),
            strokeWeight: scale(count, [min, max], [0.4, 5]),
          });
          flightPath.setMap(this.map);
          newHouseholds.push(flightPath);
        });

        geoTarget.polygons.forEach(polygon => {
          const features = turf.featureCollection(
            polygon.geometry.coordinates[0].map(coordinate =>
              turf.point(coordinate)
            )
          );

          const center = turf.center(features);
          var flightPath = new google.maps.Polyline({
            path: [
              {
                lat: center.geometry.coordinates[1],
                lng: center.geometry.coordinates[0],
              },
              zipLookup[zipcode][0].geometry.location,
            ],
            geodesic: true,
            strokeColor: heatMapColorforValue(scale(count, [min, max], [0, 1])),
            strokeOpacity: scale(count, [min, max], [0.4, 1]),
            strokeWeight: scale(count, [min, max], [0.4, 3]),
          });
          flightPath.setMap(this.map);
          newHouseholds.push(flightPath);
        });
      });
    });
    this.state.households.forEach(household => household.setMap(null));
    this.setState({ households: newHouseholds });
  };

  initializeShapes = () => {
    const { google, shapes } = this.props;
    const newShapes = [];
    const bounds = new google.maps.LatLngBounds();

    if (this.state.previousOverlays.length < 1) {
      // returning errors so added a checker
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }
      this.map.controls[
        this.props.google.maps.ControlPosition.RIGHT_BOTTOM
      ].clear();
      this.initDrawingTools();
    }

    shapes.forEach(geoTarget => {
      if (!geoTarget) return;

      if (geoTarget && geoTarget.circles) {
        geoTarget.circles.forEach((circle, i) => {
          const latlng = new google.maps.LatLng(
            circle.coordinate.lat,
            circle.coordinate.lng
          );

          bounds.extend(latlng);

          newShapes.push({
            type: "circle",
            overlay: new google.maps.Circle({
              ...this.props.toolOptions,
              editable: false,
              map: this.map,
              center: {
                lat: circle.coordinate.lat,
                lng: circle.coordinate.lng,
              },
              radius: circle.radius * 1000, // KM -> M
            }),
          });
        });
      }

      if (geoTarget && geoTarget.polygons) {
        geoTarget.polygons.forEach(polygon => {
          let coordinates;

          if (!isEmpty(polygon.geometry)) {
            coordinates = polygon.geometry.coordinates[0].map(coord => {
              const latlng = new google.maps.LatLng(coord[1], coord[0]);
              bounds.extend(latlng);
              return { lat: coord[1], lng: coord[0] };
            });
          } else {
            polygon.coordinates.forEach(coord => {
              const latlng = new google.maps.LatLng(coord.lat, coord.lng);
              bounds.extend(latlng);
            });
          }

          newShapes.push({
            type: "polygon",
            overlay: new google.maps.Polygon({
              paths: coordinates || polygon.coordinates,
              ...this.props.toolOptions,
              editable: false,
              map: this.map,
            }),
          });
        });
      }
    });
    const { previousOverlays } = this.state;
    previousOverlays.forEach(({ overlay }) => {
      overlay.setMap(null);
    });
    if (shapes.length > 0) this.map.fitBounds(bounds, 10);
    if (shapes.length === 1) this.map.setZoom(15);
    newShapes.forEach(shape => {
      google.maps.event.addListener(shape.overlay, "click", e => {
        this.setSelection(shape);
      });
    });

    // Note this bit is logic dependent on the concept of Audiences. To release  as a re-usable packaged it should be changed.
    if (this.props.shapes.length === 1) {
      this.setState({ overlays: newShapes });
      if (this.props.overlayChanged) this.props.overlayChanged(newShapes);
    } else {
      this.setState({ previousOverlays: newShapes });
    }
  };

  createShape = e => {
    this.clearSelection();
    const { google } = this.props;
    const { overlays } = this.state;
    e.overlay.setEditable(false);

    google.maps.event.addListener(e.overlay, "click", () => {
      this.setSelection(e);
    });

    const overlaysClone = [...overlays];
    overlaysClone.push(e);
    if (this.checkOverMaxArea(overlaysClone)) {
      e.overlay.setMap(null);
      return message.error(
        `Total area of shapes exceeds limit of ${this.props.maxArea}m²`
      );
    }

    this.setState({ overlays: overlaysClone });
    if (this.props.overlayChanged) this.props.overlayChanged(overlaysClone);
  };

  deleteAllShape = () => {
    const { overlays } = this.state;
    overlays.forEach(shape => {
      shape.overlay.setMap(null);
    });
    if (this.props.overlayChanged) this.props.overlayChanged([]);
    this.setState({
      overlays: [],
      selectedShape: null,
    });
  };

  deleteSelectedShape = () => {
    const { overlays, selectedShape } = this.state;
    const nextState = [...overlays];
    nextState.forEach((shape, i) => {
      if (selectedShape === shape.overlay) {
        shape.overlay.setMap(null);
        nextState.splice(i, 1);
      }
    });
    if (this.props.overlayChanged) this.props.overlayChanged(nextState);
    this.setState({
      overlays: nextState,
      selectedShape: null,
    });
  };

  clearSelection = () => {
    const { overlays } = this.state;
    overlays.forEach(shape => {
      shape.overlay.setEditable(false);
    });
  };

  updateAfterEdit = newOverlay => {
    const { selectedShape } = this.state;
    const overlays = [...this.state.overlays];

    overlays.forEach((shape, i) => {
      if (shape.overlay === selectedShape) {
        overlays.splice(i, 1, newOverlay);
      }
    });

    if (this.props.overlayChanged) this.props.overlayChanged(overlays);
    this.setState({
      selectedShape: newOverlay.overlay,
      overlays,
    });
  };

  setSelection = e => {
    const { overlay, type } = e;
    const { google } = this.props;
    this.clearSelection();

    overlay.setEditable(true);
    this.setState({
      selectedShape: overlay,
    });

    if (type === "circle") {
      //Add circle listeners for available edit events
      google.maps.event.addListener(overlay, "radius_changed", () => {
        this.updateAfterEdit({ type: "circle", overlay });
      });
      google.maps.event.addListener(overlay, "center_changed", () => {
        this.updateAfterEdit({ type: "circle", overlay });
      });
    } else if (type === "polygon") {
      //Add polygon listeners for available edit events
      google.maps.event.addListener(overlay.getPath(), "insert_at", () => {
        this.updateAfterEdit({ type: "polygon", overlay });
      });

      google.maps.event.addListener(overlay.getPath(), "set_at", () => {
        this.updateAfterEdit({ type: "polygon", overlay });
      });

      google.maps.event.addListener(overlay.getPath(), "remove_at", () => {
        this.updateAfterEdit({ type: "polygon", overlay });
      });
    }
  };

  initDrawingTools = () => {
    //Drawing manager setup
    const isEnableDrawingManager = this.props.enableDrawingManager !== false;
    const { google } = this.props;
    if (isEnableDrawingManager) {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        polygonOptions: this.props.toolOptions,
        circleOptions: this.props.toolOptions,
        rectangleOptions: this.props.toolOptions,
      });
      this.drawingManager.setMap(this.map);

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        this.createShape
      );
    }

    // Clear all shapes button setup
    const clearShapes = ReactDOM.findDOMNode(this.refs.clearShapesButton);
    if (clearShapes) {
      clearShapes.style.margin = "10px";
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        clearShapes
      );

      if (this.props.shapes.length < 1) {
        this.deleteAllShape();
      }

      clearShapes.addEventListener("click", async () => {
        this.deleteAllShape();
      });
    }

    // Clear selected shapes button setup
    const clearSelectedButton = ReactDOM.findDOMNode(
      this.refs.clearSelectedButton
    );
    if (clearSelectedButton) {
      clearSelectedButton.style.margin = "10px";
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        clearSelectedButton
      );
      clearSelectedButton.addEventListener("click", async () => {
        this.deleteSelectedShape();
      });
    }
  };

  drawMap = () => {
    const { latitude, longitude } = this.props.initialLocation
      ? this.props.initialLocation
      : this.props.currentLocation;
    const { google, zoom } = this.props;
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);

    this.currentLocation = new google.maps.LatLng({ lat, lng });
    let currentLocation = this.currentLocation;
    this.markers = [];

    //Set up map
    this.map = new google.maps.Map(this.mapNode, {
      center: {
        lat,
        lng,
      },
      mapTypeId: this.props.mapType === "satellite" ? "satellite" : "roadmap",
      zoom,
      mapTypeControl: true,
      scrollwheel: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      streetViewControl: false,
      minZoom: 2,
      maxZoom: 19,
    });
    let map = this.map;
    map.setTilt(0);

    if (this.props.drawing) this.initDrawingTools();

    //if there is a location already geocoded display it on the map
    const { shapes } = this.props;

    if (
      shapes &&
      shapes[0] !== null &&
      shapes.length > 0 &&
      ((shapes &&
        typeof shapes[0].circles !== "undefined" &&
        shapes[0].circles.length) ||
        (shapes &&
          typeof shapes[0].polygons !== "undefined" &&
          shapes[0].polygons.length))
    )
      this.initializeShapes();

    //Custom current location icon

    const icon = {
      url: currentLocationIcon,
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };

    this.markers.push(
      new google.maps.Marker({
        map: map,
        icon: icon,
        title: "current location",
        position: currentLocation,
      })
    );

    // Custom searchbox setup.

    const input = document.getElementById("seach-input");
    const searchBox = new google.maps.places.SearchBox(input);

    map.addListener("bounds_changed", function() {
      searchBox.setBounds(map.getBounds());
    });

    const centerLocation = ReactDOM.findDOMNode(this.refs.centerButton);
    centerLocation.style.marginTop = "9px";
    centerLocation.style.margin = "8px";
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerLocation);

    centerLocation.addEventListener("click", async () => {
      this.resetCenter(currentLocation);
    });

    searchBox.addListener("places_changed", () => {
      let places = searchBox.getPlaces();

      if (places.length === 0) {
        console.log("there are no places");
        return;
      }

      this.deleteMarkers();

      places.forEach(place => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        //Add default places icons for groups of found locations ie: restaurants

        let icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        //Add custom Icon for a single location

        if (places.length === 1) {
          icon = {
            url: currentLocationIcon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };
        }

        this.markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        map.panTo(place.geometry.location);
        //zoom location when selected
        this.map.setZoom(18);
      });
      if (this.props.changeLocation) {
        this.props.changeLocation(places);
      }
    });
  };

  deleteMarkers = () => {
    let markers = this.markers;
    markers.forEach(function(marker) {
      marker.setMap(null);
    });
    markers = [];
  };

  checkOverMaxArea = overlays => {
    const { google } = this.props;
    const selectedArea = overlays.reduce((acc, curr) => {
      if (!curr) return acc;
      const shape = curr.overlay;
      if (curr.type === "polygon") {
        return (
          acc + google.maps.geometry.spherical.computeArea(shape.getPath())
        );
      } else if (curr.type === "circle") {
        return acc + (Math.PI * shape.getRadius()) ** 2;
      }
      return acc;
    }, 0);
    return selectedArea > this.props.maxArea;
  };

  mapMounted = node => {
    this.mapNode = node;
  };

  render() {
    const isSearchBarVisible = this.props.isSearchBarVisible !== false; // Defaults to true unless explicitly set to false
    const isClearButtonVisible = this.props.isClearButtonVisible !== false;
    return (
      <div style={this.props.styles.outerContainer || styles.outerContainer}>
        {isSearchBarVisible && (
          <div
            style={this.props.styles.inputContainer || styles.inputContainer}
          >
            <input
              style={this.props.styles.input || styles.input}
              id="search-input"
              type="input"
              placeholder={this.props.inputPlaceholder || "Search an area..."}
            />
          </div>
        )}
        <div style={this.props.styles.mapContainer || styles.mapContainer}>
          <div
            ref={this.mapMounted}
            style={this.props.styles.map || styles.map}
          />
          <div style={{ visibility: "hidden" }}>
            <CrossHairs ref="centerButton" />
            {isClearButtonVisible && (
              <>
                <ClearAllButton ref="clearShapesButton" />
                <ClearSelectedButton ref="clearSelectedButton" />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MapPolygonDrawing.defaultProps = {
  currentLocation: {
    latitude: "39.8097343",
    longitude: "-98.5556199",
  },
  maxArea: 2880648,
  toolOptions: {
    fillColor: "#BCDCF9",
    fillOpacity: 0.5,
    strokeWeight: 3,
    strokeColor: "#57ACF9",
    clickable: true,
    editable: true,
    zIndex: 1,
  },
  drawing: true,
  styles: {},
};

export default GoogleApiWrapper(googleMapsConfig)(MapPolygonDrawing);
