import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";

// MUTATIONS
import UPDATE_MEDIA from "../../../GraphQl/Mutations/UPDATE_MEDIA";
import UPDATE_MEDIAS from "../../../GraphQl/Mutations/UPDATE_MEDIAS";

// QUERIES
import GET_CAMPAIGN_ORDERS_QUEUE from "../../../GraphQl/Queries/GET_CAMPAIGN_ORDERS_QUEUE";
import { useCampaignRequestorContext } from "../../campaignRequestor/useCampaignRequestorContext";
import GET_LIQUIDM_PUBLISHERS from "../../../GraphQl/Queries/GET_LIQUIDM_PUBLISHERS";

const CampaignOrderQueueContext = createContext({});

export const CampaignOrderQueueProvider = props => {
  const { children } = props;
  const [campaignOrders, setCampaignOrders] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const {
    currentCampaignOrder,
    fetchCampaignOrder,
  } = useCampaignRequestorContext();

  // QUERIES
  const [
    fetchCampaignOrdersQueue,
    { data: dataCampaignOrdersQueue, loading: loadingCampaignOrdersQueue },
  ] = useLazyQuery(GET_CAMPAIGN_ORDERS_QUEUE, {
    fetchPolicy: "no-cache",
  });

  const [
    fetchLiquidMSuppySidePlatforms,
    {
      data: liquidMSupplySidePlatforms,
      loading: liquidMSupplySidePlatformsLoading,
    },
  ] = useLazyQuery(GET_LIQUIDM_PUBLISHERS);

  // MUTATIONS
  const [updateMedia, { loading: loadingUpdateMedia }] = useMutation(
    UPDATE_MEDIA,
    {
      onCompleted: () => {
        fetchCampaignOrder({
          variables: {
            where: {
              id: currentCampaignOrder.id,
            },
          },
        });
      },
    }
  );

  const [updateManyMedias, { loading: loadingUpdateManyMedias }] = useMutation(
    UPDATE_MEDIAS,
    {
      onCompleted: () => {
        fetchCampaignOrder({
          variables: {
            where: {
              id: currentCampaignOrder.id,
            },
          },
        });
      },
    }
  );

  useEffect(() => {
    if (
      dataCampaignOrdersQueue &&
      dataCampaignOrdersQueue.campaignOrdersConnection
    ) {
      setCampaignOrders(
        dataCampaignOrdersQueue.campaignOrdersConnection.edges.map(
          edge => edge.node
        )
      );
      setPagination({
        ...pagination,
        total: dataCampaignOrdersQueue.full.aggregate.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCampaignOrdersQueue]);

  return (
    <CampaignOrderQueueContext.Provider
      value={{
        campaignOrders,
        loadingCampaignOrdersQueue,
        pagination,
        loadingUpdateMedia,
        loadingUpdateManyMedias,
        liquidMSupplySidePlatforms:
          (liquidMSupplySidePlatforms &&
            liquidMSupplySidePlatforms.liquidMPublishers) ||
          [],
        liquidMSupplySidePlatformsLoading,
        fetchLiquidMSuppySidePlatforms,
        fetchCampaignOrdersQueue,
        setPagination,
        updateMedia,
        updateManyMedias,
      }}
      {...props}
    >
      {children}
    </CampaignOrderQueueContext.Provider>
  );
};

export default CampaignOrderQueueContext;
