import gql from "graphql-tag";

const DELETE_GEOTARGET = gql`
  mutation deleteGeoTarget($id: ID!) {
    deleteGeoTarget(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_GEOTARGET;
