import gql from "graphql-tag";

const GET_ORG_TABLE_CONFIG = gql`
  query getOrgTableConfig($id: ID) {
    getOrgTableConfig(where: { id: $id }) {
      isShowDaysRemaining
      isShowStartDate
      isShowEndDate
      isShowImpressions
      isShowWalkins
      isShowPerformance
      isShowPacing
      isShowSales
      isShowSignUps
      isShowBudget
      isShowAttribution
    }
  }
`;

export default GET_ORG_TABLE_CONFIG;
