import gql from "graphql-tag";
import { LocationFields } from "../Fragments/LocationFragments";

const LOCATIONS = gql`
  ${LocationFields}
  query locations($where: LocationWhereInput) {
    locations(where: $where, orderBy: updatedAt_DESC) {
      ...LocationFields
    }
  }
`;

export default LOCATIONS;
