import { useEffect, useState } from "react";
import moment from "moment";

const getThisQuarter = moment()
  .utc()
  .startOf("quarter");

const getPreviousQuarter = moment()
  .utc()
  .subtract(1, "Q")
  .startOf("quarter");

export const ranges = {
  Yesterday: [
    moment()
      .utc()
      .subtract(1, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last 7 Days": [
    moment()
      .utc()
      .subtract(7, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "This Month": [
    moment()
      .utc()
      .startOf("month")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last Month": [
    moment()
      .utc()
      .subtract(1, "month")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "Last 30 Days": [
    moment()
      .utc()
      .subtract(30, "days")
      .startOf("day"),
    moment().endOf("day"),
  ],
  "This Quarter": [getThisQuarter.startOf("day"), moment().endOf("day")],
  "Last Quarter": [getPreviousQuarter.startOf("day"), moment().endOf("day")],
};

function filterDataByDate(data, startDate, endDate) {
  const x = data.filter(obj => {
    let date = obj.xAxis;
    if (typeof date === "string") date = moment(date).unix();
    if (date.toString().length < 10) {
      date = date * 1000;
    }
    return date >= startDate && date <= endDate;
  });
  return x;
}

/**
 * This hook is for byDate charts
 * Mainly applicable for objects with xAxis as date
 */
function useByDateFilter(data) {
  const [rangeKey, setRangeKey] = useState("Last 7 Days");
  const [startDateEndDate, setStartDateEndDate] = useState(
    ranges["Last 7 Days"]
  );
  const [filteredData, setFilteredData] = useState(data);
  const [customDateRange, setCustomDateRange] = useState([]);

  useEffect(() => {
    if (rangeKey && Object.keys(ranges).indexOf(rangeKey) >= 0) {
      const getSelectedStartDate = ranges[rangeKey][0].unix();
      const getSelectedEndDate = ranges[rangeKey][1].unix();
      setFilteredData(
        filterDataByDate(data, getSelectedStartDate, getSelectedEndDate)
      );
    }
  }, [data, rangeKey]);

  useEffect(() => {
    if (customDateRange && customDateRange.length === 2) {
      try {
        const getSelectedStartDate = moment(customDateRange[0]).unix();
        const getSelectedEndDate = moment(customDateRange[1]).unix();
        setFilteredData(
          filterDataByDate(data, getSelectedStartDate, getSelectedEndDate)
        );
        setRangeKey("Custom");
      } catch (e) {
        console.log(e);
      }
    }
  }, [data, customDateRange]);

  useEffect(() => {
    if (data && data.length > 0) {
      const getDate = data.map(a => ({
        timestamp: a.date,
        date: a.xAxis,
      }));

      // sort them by desc dates
      getDate.sort(function(a, b) {
        return a.timestamp - b.timestamp;
      });

      // get startdate and end date
      const startDate = getDate[0].date; // last 30 days
      const endDate = getDate.slice(-1)[0].date;

      // combined start date & end date
      const startEndDate = [moment(startDate), moment(endDate)];
      setStartDateEndDate(startEndDate);
      setFilteredData(
        filterDataByDate(data, startEndDate[0].unix(), startEndDate[1].unix())
      );
      setRangeKey("Custom");
    }
  }, [data]);

  if (
    startDateEndDate.length &&
    (startDateEndDate[0].year() === 1970 || startDateEndDate[1].year() === 1970)
  ) {
    setStartDateEndDate(ranges["Last 7 Days"]);
  }

  return {
    rangeKey,
    setRangeKey,
    filteredData,
    customDateRange,
    setCustomDateRange,
    setStartDateEndDate,
    startDateEndDate,
  };
}

export { useByDateFilter };
