import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SignalTagsLoader from "../../../../core/components/signalTags/SignalTagsLoader";
import SignalTagsController from "../../../../core/components/signalTags/SignalTagsController";
import SignalTagsMainLoader from "../../../../core/components/signalTags/main/SignalTagsMainLoader";
import SignalTagsMainController from "../../../../core/components/signalTags/main/SignalTagsMainController";
import SignalTagsMainView from "../main/SignalTagsMainView";

// Feature level subroutes
const SignalRoutes = props => {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        path={`${path}/main/:pageNumber?`}
        exact
        render={() => (
          <SignalTagsLoader {...props}>
            <SignalTagsController>
              <SignalTagsMainLoader>
                <SignalTagsMainController>
                  <SignalTagsMainView />
                </SignalTagsMainController>
              </SignalTagsMainLoader>
            </SignalTagsController>
          </SignalTagsLoader>
        )}
      />
      <Route
        path={`${path}`}
        render={() => <Redirect to={`${path}/main/1`} />}
      />
    </Switch>
  );
};

export default SignalRoutes;
