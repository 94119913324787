import React, { useState, useEffect } from "react";
import { mapValues, groupBy } from "lodash";
import { Input } from "antd";
import { useHistory, useLocation } from "react-router";
import { PrimaryTableRowText } from "../../../../../../shared/globalStyling/styledText";
import PaginationTable from "../../../../../../shared/PaginationTable";

const BasketItemsTable = ({
  orderIdVal,
  setOrderIdVal,
  aggregatedData,
  currentPage,
  setCurrentPage,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [transactionsWithKeys, setTransactionsWithKeys] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get("pageSize")) {
      params.set("pageSize", 10);
    }

    const updatedTransactionsWithKeys = Object.entries(aggregatedData).map(
      ([key, data], i) => {
        const byName = mapValues(groupBy(data, "NAME"), list => {
          let QUANTITY = 0;
          let TOTAL = 0;
          list.forEach(obj => {
            QUANTITY += obj.QUANTITY;
            TOTAL += obj.PRICE * obj.QUANTITY;
          });

          return {
            DATE: list[0].DATE,
            NAME: list[0].NAME,
            PRICE: list[0].PRICE,
            QUANTITY,
            TOTAL,
          };
        });

        return {
          key: i,
          name: key,
          data: Object.values(byName),
        };
      }
    );

    setTransactionsWithKeys(updatedTransactionsWithKeys);
  }, [aggregatedData, location.search]);

  const handleSearch = s => {
    const params = new URLSearchParams(location.search);
    params.set("orderId", s || "");
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleInputChange = e => {
    setOrderIdVal(e.target.value);
  };

  const handlePaginationChange = paginationObject => {
    setPageSize(paginationObject.pageSize);
    setCurrentPage(paginationObject.current);
  };

  const expandedRowRender = record => (
    <div style={{ marginLeft: "3rem" }}>
      {record.data.map((item, i) => (
        <div key={i}>
          <p style={{ fontWeight: 500 }}>{`${i + 1}. ${item.NAME}`}</p>
          <p>{`Quantity: ${item.QUANTITY}`}</p>
          <p>{`Price Per Item: $${item.PRICE}`}</p>
          <p>{`Total: $${item.TOTAL}`}</p>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Input.Search
        style={{ margin: "5px 5px" }}
        placeholder="Search basket item by Order Id"
        allowClear
        onSearch={handleSearch}
        value={orderIdVal}
        onChange={handleInputChange}
      />
      <PaginationTable
        bordered={false}
        columns={[
          {
            title: "",
            dataIndex: "name",
            key: "key",
            align: "left",
            ellipsis: {
              showTitle: false,
            },
            render: id => <PrimaryTableRowText text={id} />,
          },
        ]}
        rowKey={record => record.key}
        data={transactionsWithKeys}
        style={{ width: "100%" }}
        scroll={{ x: 200 }}
        showSizeChanger
        size="default"
        total={transactionsWithKeys.length}
        current={currentPage}
        pageSize={pageSize}
        onChange={handlePaginationChange}
        expandedRowRender={expandedRowRender}
      />
    </>
  );
};

export default BasketItemsTable;
