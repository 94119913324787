import React from "react";
import { Tabs, Card, Spin } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import SingleAxisArea from "./components/SingleAxisArea";
import Location from "../locations/Location";
import Referrers from "../referrers/Referrers";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";

// Global Styles
import {
  TabText,
  TooltipText,
  CardTitleText,
} from "../../../../shared/globalStyling/styledText";

const Frag = React.Fragment;
const colStyle = {
  cssStyle: {
    backgroundColor: "#fff",
    padding: "10px",
  },
  histogram: {
    width: "auto",
  },
  divider: { height: "6em" },
};
const orderedDays = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const OverviewTab = ({ chartData, chartsLoading, selectedAudience }) => {
  const { uniqueCount } = selectedAudience;
  const { byDate, byDay, chartsBuilt, byCity, byZip, byReferrer } = chartData;

  if (byCity) {
    //Sort the city data appropriately
    byCity.sort(function(a, b) {
      return b.MATCHES - a.MATCHES;
    });
  }

  if (byZip) {
    //Sort the zip data appropriately
    byZip.sort(function(a, b) {
      return b.MATCHES - a.MATCHES;
    });
  }

  if (byReferrer) {
    //Sort the zip data appropriately
    byReferrer.sort(function(a, b) {
      return b.MATCHES - a.MATCHES;
    });
  }

  const TabPane = Tabs.TabPane;
  const emptyCharts = isEmpty(byDate) && isEmpty(byDay);
  const operations = (
    <span style={{ fontSize: 10 }}>
      {chartsBuilt && !chartsLoading ? (
        <TooltipText text={`Updated ${moment(chartsBuilt).calendar()}`} />
      ) : (
        <Spin />
      )}
    </span>
  );

  if (emptyCharts) return null;

  return (
    <div style={{ padding: "38px 0" }}>
      <Card
        bodyStyle={{ padding: 0 }}
        title={
          <CardTitleText
            text={`Audience Signals  ${formatWholeNumber(uniqueCount)}`}
          />
        }
      >
        <Tabs
          tabBarExtraContent={operations}
          destroyInactiveTabPane={true}
          defaultActiveKey="2"
          style={colStyle.cssStyle}
        >
          {!isEmpty(byDate) ? (
            <TabPane tab={<TabText text={"By Date"} />} key="2">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDate.length === 1 && (
                  <Card bordered={false}>
                    No By Date Chart Is Available At This Time...
                  </Card>
                )}
                {byDate.length !== 1 && (
                  <Frag>
                    <SingleAxisArea
                      leftY={"Values"}
                      data={byDate.sort((a, b) => {
                        if (moment(a.xAxis).isBefore(b.xAxis)) return -1;
                        return 1;
                      })}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
          {!isEmpty(byDay) ? (
            <TabPane tab={<TabText text={"Day Of Week"} />} key="3">
              <Card bordered={false} bodyStyle={{ padding: 0 }}>
                {byDay.length === 1 && (
                  <Card bordered={false}>
                    No By Day Chart Is Available At This Time...
                  </Card>
                )}
                {byDay.length !== 1 && (
                  <Frag>
                    <SingleAxisArea
                      leftY={"Values"}
                      data={byDay.sort((a, b) => {
                        return orderedDays[a.xAxis] - orderedDays[b.xAxis];
                      })}
                    />
                  </Frag>
                )}
              </Card>
            </TabPane>
          ) : null}
        </Tabs>
      </Card>
      {true ? (
        <div style={{ marginTop: 10 }}>
          <Location
            colStyle={colStyle}
            locationCity={byCity}
            locationZip={byZip}
            chartsLoading={chartsLoading}
            uniqueCount={uniqueCount}
          />
        </div>
      ) : null}
      {true ? (
        <div style={{ marginTop: 10 }}>
          <Referrers
            colStyle={colStyle}
            referrerData={byReferrer}
            chartsLoading={chartsLoading}
          />
        </div>
      ) : null}
      {/*hasInsights ? (
        <div style={{ marginTop: 10 }}>
          <Publishers
            colStyle={colStyle}
            selectedCampaign={selectedCampaign}
            publisherData={publishers}
            chartsLoading={chartsLoading}
          />
        </div>
      ) : null*/}
      {/*lineItems || !isEmpty(lineItems) ? (
        <div style={{ marginTop: 10 }}>
          <Card title="Line Items" bordered={false} bodyStyle={{ padding: 0 }}>
            <LineItems
              lineItems={lineItems ? lineItems : creatives}
              selectedCampaign={selectedCampaign}
            />
          </Card>
        </div>
      ) : null*/}
    </div>
  );
};

export default OverviewTab;
