import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { Card, Select, message, Col, Alert } from "antd";
import { FundProjectionScreenOutlined } from "@ant-design/icons";

// COMPONENTS
import LaunchCampaignLiquidMConfig from "./LaunchCampaignLiquidMConfig";
import { LaunchCampaignJamLoopConfig } from "./LaunchCampaignJamLoopConfig";
import ResultPage from "../../../shared/ResultPage";

const LaunchCampaignConfig = ({
  client,
  publishers,
  selectedCampaignOrder,
  handleSubmitCampaignOrder,
  launchCampaignDSPLoading,
  dspStatus,
}) => {
  const [isLaunchCampaignDisabled, setIsLaunchCampaignDisabled] = useState(
    false
  );
  const [dspType, setDspType] = useState("LIQUIDM");
  useEffect(() => {
    if (selectedCampaignOrder && selectedCampaignOrder.dspConfig) {
      setDspType(selectedCampaignOrder.dspConfig.name);
    }
  }, [selectedCampaignOrder]);

  const handleSubmitCampaign = ({ dspConfig }) => {
    try {
      handleSubmitCampaignOrder({
        dspType,
        dspConfig,
      });
    } catch (error) {
      message.destroy();
      message.error(error.message, 5);
    }
  };

  return (
    <Card
      style={{}}
      title={
        <div style={{ position: "relative" }}>
          <Col>
            <span>
              <FundProjectionScreenOutlined /> Launch Settings
            </span>
          </Col>
        </div>
      }
    >
      {dspStatus === "IN_PROGRESS" ? (
        // Notify user that the campaign is in progress
        <ResultPage
          title={"Campaign is in progress"}
          subTitle={`This may take a while. Please refresh the page after a few minutes.`}
        ></ResultPage>
      ) : (
        <>
          {dspStatus === "FAILED" && (
            <Alert
              style={{ marginBottom: 16 }}
              message={`Failed to ${
                selectedCampaignOrder.status === "APPROVED"
                  ? "launch"
                  : "update"
              }. Please try again.`}
              type="error"
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 8,
              gap: 8,
            }}
          >
            <Select
              value={dspType}
              style={{
                width: 120,
              }}
              onChange={value => setDspType(value)}
              disabled={
                selectedCampaignOrder && selectedCampaignOrder.status === "LIVE"
              }
              options={[
                {
                  value: "LIQUIDM",
                  label: "LIQUIDM",
                },
                {
                  value: "JAMLOOP",
                  label: "JAMLOOP",
                },
              ]}
            />
          </div>
          <div>
            {dspType === "LIQUIDM" && (
              <LaunchCampaignLiquidMConfig
                client={client}
                publishers={publishers}
                selectedCampaignOrder={selectedCampaignOrder}
                handleSubmitCampaignOrder={handleSubmitCampaignOrder}
                isLaunchCampaignDisabled={isLaunchCampaignDisabled}
                setIsLaunchCampaignDisabled={setIsLaunchCampaignDisabled}
                handleSubmitCampaign={handleSubmitCampaign}
                launchCampaignDSPLoading={launchCampaignDSPLoading}
              />
            )}

            {dspType === "JAMLOOP" && (
              <LaunchCampaignJamLoopConfig
                selectedCampaignOrder={selectedCampaignOrder}
                isLaunchCampaignDisabled={isLaunchCampaignDisabled}
                handleSubmitCampaign={handleSubmitCampaign}
              />
            )}
          </div>
        </>
      )}
    </Card>
  );
};

export default withApollo(LaunchCampaignConfig);
