import React from "react";
import { Button, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//global Styles
import { ButtonText, BreadCrumbText } from "./globalStyling/styledText";

const PageHeader = props => {
  const { title, showForm, data } = props;

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, textAlign: "left", bottomBorder: 0, padding: 10 }}>
        <div>
          <h2>{title ? title : ""}</h2>
        </div>
        <Breadcrumb.Item>
          <Link to="/">
            <BreadCrumbText text={"Home"} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <BreadCrumbText text={title} />
        </Breadcrumb.Item>
        {props.data.subroute
          ? props.data.subroute.map((subroute, i) => (
              <Breadcrumb.Item key={`${i - subroute}`}>
                <BreadCrumbText text={subroute} />
              </Breadcrumb.Item>
            ))
          : null}
      </div>
      <div
        style={{ flex: 1, textAlign: "right", bottomBorder: 0, padding: 10 }}
      >
        {props.hidebutton ? null : (
          <Button onClick={showForm} type="primary">
            <ButtonText text={data.name} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default PageHeader;

PageHeader.defaultProps = {
  data: { subroute: "" },
};

PageHeader.propTypes = {
  title: PropTypes.string,
  showForm: PropTypes.func,
  create: PropTypes.func,
  hidebutton: PropTypes.bool,
  data: PropTypes.shape({
    subroute: PropTypes.arrayOf(PropTypes.string),
  }),
};
