import React from "react";
import { Card, Row, Typography } from "antd";
import OrgDisplay from "../../../shared/OrgDisplay";

const { Text } = Typography;

const styles = {
  row: {
    marginBottom: "15px",
  },
};

const OrganizationDetails = ({ data, tablet }) => {
  // TODO: should only be one Org, convert orgs --> org in schema.
  const org = data[0];
  const { row } = styles;
  return (
    <Card
      size={tablet ? "small" : "default"}
      title={
        <Row type="flex" justify="center">
          <h4
            style={{
              margin: 0,
              color: "#9b9b9b",
              fontSize: "16px",
            }}
          >
            Organization Details
          </h4>
        </Row>
      }
    >
      <Row type="flex" justify="center" style={row}>
        <OrgDisplay imgKey={org.logo && org.logo.key} />
      </Row>
      <Row type="flex" justify="center" style={row}>
        <Text>{org.name}</Text>
      </Row>
      <Row type="flex" justify="center" style={row}>
        <Text>{org.phone}</Text>
      </Row>
    </Card>
  );
};

export default OrganizationDetails;
