import gql from "graphql-tag";

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $description: String!
    $street: String!
    $city: String!
    $state: StateType!
    $lat: Float!
    $lng: Float!
    $hours: String!
    $country: CountryType!
  ) {
    createLocation(
      data: {
        name: $name
        description: $description
        street: $street
        city: $city
        state: $state
        lat: $lat
        lng: $lng
        hours: $hours
        country: $country
        catalogs: { create: { name: $name } }
        formattedAddress: "placeholder address"
        county: "EXAMPLE COUNTY"
        zip: "98765"
      }
    ) {
      id
      name
      description
      street
      city
      state
      lat
      lng
      catalogs {
        id
      }
      hours
      createdAt
      org {
        id
        name
      }
    }
  }
`;

export default CREATE_LOCATION;
