import gql from "graphql-tag";

const GET_SUB_ORGS_TAGS = gql`
  query getSubOrgsTags($orgId: ID) {
    orgs(
      where: { OR: [{ parentOrg_some: { id: $orgId } }, { id: $orgId }] }
      first: 1000
    ) {
      id
      name
      eventsTarget {
        id
        name
        cart
        eventTags {
          id
          appId
        }
        tag
        segmentId
        segmentName
      }
    }
  }
`;

export default GET_SUB_ORGS_TAGS;
