import React, { useState } from "react";
import { Typography, Space, Divider, Row, Col, Button, message } from "antd";

import GET_LIQUIDM_PUBLISHER_SITES from "../../../../core/GraphQl/Queries/GET_LIQUIDM_PUBLISHERS_SITES";
import LaunchCampaignConfigPublisherTable from "./LaunchCampaignConfigPublisherTable";
import GET_LIQUIDM_TRAFFIC_SETS from "../../../../core/GraphQl/Queries/GET_LIQUIDM_TRAFFIC_SETS";
import { UploadOutlined } from "@ant-design/icons";
import { LaunchCampaignConfigSupplyTemplateModal } from "./LaunchCampaignConfigSupplyTemplateModal";

const LaunchCampaignConfigSupply = ({
  publishers,
  selectedPublishers,
  setSelectedPublishers,
  setIsLaunchCampaignDisabled,
  client,
}) => {
  const [showSupplyTemplateModal, setShowSupplyTemplateModal] = useState(false);

  const fetchPublisherSites = async ({ name, publisherId }) => {
    const { data } = await client.query({
      query: GET_LIQUIDM_PUBLISHER_SITES,
      variables: {
        page: {
          limit: 20,
        },
        filter: {
          publisherId,
          name,
        },
      },
    });

    return data.liquidMPublisherSites.map(site => ({
      value: site.id,
      label: site.name,
    }));
  };

  const fetchSupplyTemplateOptions = async ({ name }) => {
    try {
      const params = {
        page: {
          limit: 20,
        },
        filter: {
          name,
        },
      };
      const response = await fetchSupplyTemplates(params);
      if (response && response.data) {
        return response.data.map(trafficSet => ({
          value: trafficSet.id,
          label: trafficSet.attributes.name,
        }));
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to get templates.");
    }
  };

  const fetchSupplyTemplateSelected = async params => {
    try {
      message.loading("Adding template to supply...", 0);

      const response = await fetchSupplyTemplates(params);
      const { data, included } = response;

      if (data && data.length) {
        const relationships = data[0].relationships;
        const meta = data[0].meta;
        const includedPublishers = relationships["incl-publishers"].data.map(
          pub => {
            let publisher = publishers.find(p => p.id === pub.id);
            if (!publisher) {
              publisher = included
                .filter(incl => incl.type === "publishers")
                .find(p => p.id === pub.id);

              if (publisher) {
                publisher = {
                  id: publisher.id,
                  name: publisher.attributes.name,
                };
              }
            }

            return {
              ...publisher,
              isInclude: true,
              urls: meta.incl_publishers_to_sites_map[pub.id].map(site => {
                const siteId = site.toString();
                return {
                  value: siteId,
                  key: siteId,
                  label: siteId,
                };
              }),
            };
          }
        );

        const excludedPublishers = relationships["excl-publishers"].data.map(
          pub => {
            let publisher = publishers.find(p => p.id === pub.id);
            if (!publisher) {
              publisher = included
                .filter(incl => incl.type === "publishers")
                .find(p => p.id === pub.id);

              if (publisher) {
                publisher = {
                  id: publisher.id,
                  name: publisher.attributes.name,
                };
              }
            }

            return {
              ...publisher,
              isInclude: false,
              urls: meta.excl_publishers_to_sites_map[pub.id].map(site => {
                const siteId = site.toString();
                return {
                  value: siteId,
                  key: siteId,
                  label: siteId,
                };
              }),
            };
          }
        );

        setSelectedPublishers([...includedPublishers, ...excludedPublishers]);
        message.destroy();
        message.success("Template added to supply.");
      }
    } catch (error) {
      console.error(error);
      message.destroy();
      message.error("Failed to get templates.");
    }
  };

  const fetchSupplyTemplates = async params => {
    try {
      const { data } = await client.query({
        query: GET_LIQUIDM_TRAFFIC_SETS,
        variables: {
          params,
        },
      });

      const { liquidMTrafficSets } = data;
      const response = {};

      if (liquidMTrafficSets && liquidMTrafficSets.included) {
        response.included = liquidMTrafficSets.included;
      }

      if (liquidMTrafficSets && liquidMTrafficSets.data) {
        return { ...response, data: liquidMTrafficSets.data };
      }

      return null;
    } catch (error) {
      console.error(error);
      message.error("Failed to get templates.");
    }
  };

  return (
    <div>
      {showSupplyTemplateModal && (
        <LaunchCampaignConfigSupplyTemplateModal
          visible={showSupplyTemplateModal}
          setVisible={setShowSupplyTemplateModal}
          fetchSupplyTemplateOptions={fetchSupplyTemplateOptions}
          fetchSupplyTemplateSelected={fetchSupplyTemplateSelected}
        />
      )}
      <Space
        direction="vertical"
        size="small"
        style={{
          width: "100%",
        }}
      >
        <Row type="flex">
          <Col span={18}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Supply
            </Typography.Title>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={() => setShowSupplyTemplateModal(true)}
            >
              Choose Template
            </Button>
          </Col>
        </Row>

        {/* INCLUDE PUBLISHERS TABLE*/}
        <LaunchCampaignConfigPublisherTable
          publishers={publishers}
          selectedPublishers={selectedPublishers}
          setSelectedPublishers={setSelectedPublishers}
          setIsLaunchCampaignDisabled={setIsLaunchCampaignDisabled}
          isInclude={true}
          fetchPublisherSites={fetchPublisherSites}
        />

        <Divider />

        {/* EXLCUDE PUBLISHER TABLE */}
        <LaunchCampaignConfigPublisherTable
          publishers={publishers}
          selectedPublishers={selectedPublishers}
          setSelectedPublishers={setSelectedPublishers}
          setIsLaunchCampaignDisabled={setIsLaunchCampaignDisabled}
          isInclude={false}
          fetchPublisherSites={fetchPublisherSites}
        />
      </Space>
    </div>
  );
};

export default LaunchCampaignConfigSupply;
