import React, { useEffect, useState } from "react";
import {
  Modal,
  Carousel,
  Button,
  Space,
  Input,
  Tag,
  Form,
  message,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import "./styles.css";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";

const { TextArea } = Input;

const CampaignOrderQueueReviewMediaDialog = ({
  activeMedias,
  visible,
  setVisible,
  isReviewMode,
  selectedMediaIndex = 0,
}) => {
  const [form] = Form.useForm(); // Create form instance)
  const [isNoteDisabled, setIsNoteDisabled] = useState(true);
  const [renewMedias, setRenewMedias] = useState([activeMedias]);
  const [activeRenewMedia, setActiveRenewMedia] = useState(activeMedias[0]);
  const { updateMedia, loadingUpdateMedia } = useCampaignOrderQueueContext();

  // Carousel reference to control it programmatically
  const carouselRef = React.useRef(null);

  const handleFormValuesChange = changedValues => {
    if (changedValues.note) {
      setIsNoteDisabled(false);
    } else {
      setIsNoteDisabled(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePrev = () => {
    // Reset form
    form.resetFields();

    carouselRef.current.prev();

    // Get the previous slide index
    const currentSlide = carouselRef.current.innerSlider.state.currentSlide;
    const slideCount = carouselRef.current.innerSlider.state.slideCount;
    let prevSlideIndex = currentSlide - 1;

    // Check if current slide is the first slide
    if (prevSlideIndex < 0) {
      prevSlideIndex = slideCount - 1;
    }
    const media = renewMedias[prevSlideIndex];
    form.setFieldsValue({ note: media.mediaNotes || "" });
    setIsNoteDisabled(!media.mediaNotes);
    setActiveRenewMedia(media);
  };

  const handleNext = () => {
    // Reset form
    form.resetFields();

    carouselRef.current.next();

    // Get the next slide index
    const currentSlide = carouselRef.current.innerSlider.state.currentSlide;
    const slideCount = carouselRef.current.innerSlider.state.slideCount;
    let nextSlideIndex = currentSlide + 1;
    // // Check if current slide is the last slide
    if (nextSlideIndex === slideCount) {
      nextSlideIndex = 0;
    }
    const media = renewMedias[nextSlideIndex];
    form.setFieldsValue({ note: media.mediaNotes });
    setIsNoteDisabled(!media.mediaNotes);
    setActiveRenewMedia(media);
  };

  const handleSubmitNote = async values => {
    // Get current slide index
    const currentSlideIndex =
      carouselRef.current.innerSlider.state.currentSlide;

    let media = renewMedias[currentSlideIndex];

    // Update media
    try {
      message.loading("Updating media note...", 0);
      const result = await updateMedia({
        variables: {
          data: {
            mediaNotes: values.note,
            status: "REJECTED",
          },
          where: {
            id: media.id,
          },
        },
      });

      // Update media note
      media = {
        ...media,
        mediaNotes: result.data.updateMedia.mediaNotes,
        status: result.data.updateMedia.status,
      };
      // Update renewMedias
      const updatedRenewMedias = [...renewMedias];
      updatedRenewMedias[currentSlideIndex] = media;
      setRenewMedias(updatedRenewMedias);
      setActiveRenewMedia(media);

      message.destroy();
      message.success("Media note updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media note");
    }
  };

  const handleApprove = async () => {
    // Get current slide index
    const currentSlideIndex =
      carouselRef.current.innerSlider.state.currentSlide;

    let media = renewMedias[currentSlideIndex];

    // Update media
    try {
      message.loading("Updating media status...", 0);
      const result = await updateMedia({
        variables: {
          data: {
            status: "APPROVED",
          },
          where: {
            id: media.id,
          },
        },
      });

      // Update media note
      media.status = result.data.updateMedia.status;

      // Update renewMedias
      const updatedRenewMedias = [...renewMedias];
      updatedRenewMedias[currentSlideIndex] = media;
      setRenewMedias(updatedRenewMedias);
      handleNext();

      message.destroy();
      message.success("Media status updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media status");
    }
  };

  useEffect(() => {
    if (activeMedias.length) {
      const results = [];
      activeMedias.forEach(media => {
        results.push(Storage.get(media.key));
      });

      Promise.all(results).then(urls => {
        const medias = urls.map((url, index) => {
          return {
            ...activeMedias[index],
            url,
          };
        });
        setRenewMedias(medias);
        setIsNoteDisabled(!medias[selectedMediaIndex].mediaNotes);
        setActiveRenewMedia(medias[selectedMediaIndex]);
        form.setFieldsValue({ note: medias[selectedMediaIndex].mediaNotes });
        if (carouselRef.current) {
          setTimeout(() => {
            carouselRef.current.goTo(selectedMediaIndex);
          }, 200);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMedias]);

  return (
    <div>
      <Modal
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        bodyStyle={{ padding: 20 }}
        width={500}
        height={600}
      >
        <Form
          form={form}
          onValuesChange={handleFormValuesChange}
          initialValues={{
            note: "",
          }}
          onFinish={handleSubmitNote}
        >
          <div style={{ height: 480 }}>
            <Carousel ref={carouselRef}>
              {renewMedias.map((media, index) => (
                <Space direction="vertical" size="middle">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 18,
                    }}
                  >
                    <Tag
                      style={{
                        background: "rgba(0, 0, 0, 0.80)",
                        color: "white",
                        padding: 6,
                        borderRadius: 16,
                      }}
                    >
                      {media.name}
                    </Tag>
                    <Tag
                      style={{
                        background: "rgba(0, 0, 0, 0.80)",
                        color: "white",
                        padding: 6,
                        borderRadius: 16,
                      }}
                    >
                      {media.width}x{media.height}
                    </Tag>
                    <Tag
                      style={{
                        background: "rgba(0, 0, 0, 0.80)",
                        color: "white",
                        padding: 6,
                        borderRadius: 16,
                      }}
                    >
                      {media.fileType}
                    </Tag>
                    <Tag
                      style={{
                        background: "rgba(0, 0, 0, 0.80)",
                        color: "white",
                        padding: 6,
                        borderRadius: 16,
                      }}
                    >
                      {media.size} KB
                    </Tag>
                  </div>
                  <div className="media-carousel-img__wrapper">
                    <img
                      alt="media"
                      key={index}
                      src={media.url}
                      className="media-carousel"
                    />
                  </div>
                </Space>
              ))}
            </Carousel>
          </div>

          {isReviewMode && (
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Form.Item name="note">
                <TextArea rows={4} placeholder="Enter note" />
              </Form.Item>
              <div style={{ display: "flex", gap: 8 }}>
                <Button
                  style={{ flexGrow: 1 }}
                  htmlType="submit"
                  disabled={isNoteDisabled || loadingUpdateMedia}
                >
                  Send Note
                </Button>
                {activeRenewMedia.status !== "APPROVED" ? (
                  <Button
                    style={{ flexGrow: 1 }}
                    type="primary"
                    onClick={handleApprove}
                    disabled={loadingUpdateMedia}
                  >
                    Approve
                  </Button>
                ) : (
                  <Button
                    style={{
                      flexGrow: 1,
                      backgroundColor: "#389e0d",
                      color: "white",
                    }}
                    disabled={true}
                  >
                    Approved
                  </Button>
                )}
              </div>
            </Space>
          )}

          <Button
            style={{ position: "absolute", top: "50%", left: 0 }}
            icon={<LeftOutlined />}
            disabled={loadingUpdateMedia}
            onClick={handlePrev}
          />
          <Button
            style={{ position: "absolute", top: "50%", right: 0 }}
            icon={<RightOutlined />}
            disabled={loadingUpdateMedia}
            onClick={handleNext}
          />
        </Form>
      </Modal>
    </div>
  );
};

export default CampaignOrderQueueReviewMediaDialog;
