import React from "react";
import { Space, Typography } from "antd";
import InstructionsBuilder from "./InstructionsBuilder";
import { Page, Text, Document } from "@react-pdf/renderer";
import GeneratedTagPDF from "../pdf/GeneratedTagPDF";

import img1 from "../../../../../../assets/tagsInstructions/shopify/img1.png";
import img2 from "../../../../../../assets/tagsInstructions/shopify/img2.png";

const shopifySteps = generatedTag => [
  {
    instruction: (
      <>
        Login to{" "}
        <Typography.Link
          href="http://shopify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Shopify
        </Typography.Link>{" "}
      </>
    ),
    pdfText: "Login to Shopify",
  },
  {
    instruction:
      "Now navigate to customer events to create a custom pixel for your Shopify store to track all the events.",
    image: img1,
  },
  {
    instruction: (
      <>
        Click on <Typography.Text strong>Add custom pixel</Typography.Text>{" "}
        {">"} Give the pixel a name {">"} Click on{" "}
        <Typography.Text strong>Add pixel</Typography.Text> {">"} Paste the tag
        on the <Typography.Text strong>Code Section</Typography.Text>
      </>
    ),
    image: img2,
    pdfText:
      "Click on Add custom pixel > Give the pixel a name > Click on Add pixel > Paste the tag on the Code Section",
  },
  {
    indtruction: `Copy and paste the tag below`,
    code: generatedTag,
    pdfTextCode: (
      <>
        <Text
          style={{
            fontSize: 12,
            marginBottom: 20,
            marginTop: 20,
            lineHeight: 1.6,
          }}
        >
          4. Copy and paste the tag below
        </Text>
        <div
          style={{
            border: "1px solid #E4E4E4",
            color: "#666",
            fontSize: 11,
            lineHeight: 1.6,
            maxWidth: "100%",
            overflow: "auto",
            padding: "10",
            display: "block",
          }}
        >
          <Text>{generatedTag}</Text>
        </div>
      </>
    ),
  },
  {
    instruction: `Click on Save.`,
  },
];
export const ShopifyInstructions = ({ generatedTag }) => {
  return (
    <React.Fragment>
      <Space direction="vertical">
        <InstructionsBuilder steps={shopifySteps(generatedTag)} />
      </Space>
    </React.Fragment>
  );
};

export const ShopifyInstructionsPDF = ({ generatedTag }) => {
  return (
    <Document>
      <Page
        wrap={false}
        size="A4"
        style={{
          padding: "40px",
        }}
      >
        <Text
          style={{
            fontSize: 15,
            marginBottom: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          Cart Instruction for Shopify
        </Text>
        <GeneratedTagPDF steps={shopifySteps(generatedTag)} />
      </Page>
    </Document>
  );
};
