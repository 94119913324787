import gql from "graphql-tag";
const UPDATE_MEDIAS = gql`
  mutation updateManyMedias(
    $data: MediaUpdateManyMutationInput!
    $where: MediaWhereInput
  ) {
    updateManyMedias(data: $data, where: $where) {
      count
    }
  }
`;

export default UPDATE_MEDIAS;
