import gql from "graphql-tag";
import { CampaignGeoTargetFields } from "../Fragments/CampaignRequestorFragments";

const GET_CAMPAIGN_ORDER_BY_ID = gql`
  ${CampaignGeoTargetFields}
  query campaignOrder($id: ID!) {
    campaignOrder(where: { id: $id }) {
      id
      type
      status
      name
      description
      geoTargets {
        id
        name
        status
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
            hasNotes
            notes
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      attributionTargets {
        id
        name
        status
        circles {
          id
          radius
          location {
            id
            name
            formattedAddress
            zip
            street
            city
            county
            state
            country
            lat
            lng
            hasNotes
            notes
          }
          coordinate {
            lat
            lng
          }
        }
        polygons {
          id
          geometry
        }
      }
      adUnits {
        id
        name
        clickthrough
        startDate
        endDate
        banners {
          id
          media {
            id
            key
            name
            height
            width
            url
            size
            fileType
            mediaNotes
            mediaHasNotes
          }
          status
        }
      }
      orgs {
        id
        name
        phone
        roles {
          users {
            id
            name
          }
          isMaster
        }
        logo {
          id
          key
        }
      }
      brands {
        id
        name
      }
      language
      category
      age
      income
      targetAudiences {
        id
        name
      }
      attributionAudiences {
        id
        name
      }
      tags
      devices
      isRegulated
      objectiveExistingCustomers
      objectiveNewCustomers
      retargetDayCap
      retargetTotalCap
      kpi
      kpiGoal
      startDate
      endDate
      budget
      campaigns {
        id
        demandSidePlatform
        adProviderId
        clientOrg
        name
        startDate
        endDate
        budgetTotal
        budgetByDay
        cpm
        targetingType
        targetingRegions {
          id
          name
          isActive
          isPresetList
          regions
          createdAt
        }
        targetingLocations {
          id
          name
          type
          isAmplifyDefault
          geoTargets {
            ...CampaignGeoTargetFields
          }
          eventsTargets {
            id
          }
          createdAt
          updatedAt
        }
        targetingCountries {
          id
          code
          name
        }
        audienceType
        firstPartyDataAudiences {
          id
          name
          activated
          deviceCount
          matchRate
          isProcessedByStatera
          createdAt
          dspSegmentIds
          # hashedEmailsCount
        }
        adGroup {
          id
          clickthrough
          adGroupCreatives {
            id
            name
            startDate
            endDate
            createdAt
            updatedAt
            medias {
              id
              name
              size
              fileType
              height
              width
              length
              key
              url
              type
            }
          }
        }
      }
      createdAt
      cpm
      targetingCriteria
      transactionTarget
      awarenessCampaign
      transactionsConfig {
        id
        cart
        traffId
        gtmAccess
        gtmStatus
        appId
        tag
      }
      campaignChecklistProcess {
        processOne
        processOneText
        processTwo
        processTwoText
        processThree
        processThreeText
        processFour
        processFourText
        processFive
        processFiveText
      }
      isRequestChange
      advertiser {
        id
        name
        domain
        website
      }
      requestorId
      region
      isAmplify
      campaignReviewProcess {
        id
        review
        stateLaw
        scaleBudget
      }
      reviewerId
      dspConfig
      version
      requesterVersion
    }
  }
`;

export default GET_CAMPAIGN_ORDER_BY_ID;
