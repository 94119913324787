import gql from "graphql-tag";

const LIST_ORGS_ADMIN_CAMPAIGNS = gql`
  query listOrgs($parentid: ID!, $skip: Int, $first: Int) {
    orgs(
      first: $first
      skip: $skip
      where: { OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }] }
    ) {
      id
      domain
      config {
        providersVisible
        isDemo
        isAdmin
        isPartner
        isTrafficker
        exportData
        isSelfService
        hasInsights
        priceVisible
        isProcessor
        canResetPasswords
        campaignSummary
        isPacing
        pastData
        segment
        technicalSupport
        articles
        amplify
        configurator
        billing
        sitesAppsTransactions
      }
      dataConfig {
        reTargeting
        appIds
        tradeDeskIdentifier
        storageBucket
        googleCustomerId
        attributionWindow
        menuId
        dspAdvertiserId
        seo
        semrushEnable
        semrushConfig {
          projectId
          rootDomainUrl
          rootKeyword
          competitor1stRootDomainUrl
          competitor2ndRootDomainUrl
        }
        googleAnalytics {
          viewId
        }
      }
      paymentConfig {
        id
        providerId
        paymentProvider
      }
      brands(first: 2) {
        name
        logo {
          key
        }
      }
      name
      description
      locations(first: 5) {
        street
        city
        state
      }
      logo {
        id
        key
        url
      }
      signInLogo {
        id
        key
        url
      }
      roles(first: 50, where: { isMaster: true }) {
        id
        name
        roleItems(first: 15) {
          id
          feature
          actions
        }
        users(first: 30) {
          id
        }
        isMaster
      }
      level
      website
      parentOrg(
        where: {
          OR: [{ parentOrg_some: { id: $parentid } }, { id: $parentid }]
        }
      ) {
        name
        id
      }
      isAllTimeFiltered
      zone
    }
  }
`;

export default LIST_ORGS_ADMIN_CAMPAIGNS;
