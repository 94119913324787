import React from "react";
import LIST_ORGS_ADMIN from "../../../GraphQl/Queries/LIST_ORGS_ADMIN";
import ORG_SEO_SHARING_DETAILS from "../../../GraphQl/Queries/ORG_SEO_SHARING_DETAILS";
import ORG_SEO_UPDATE_SHARING_DETAILS from "../../../GraphQl/Mutations/ORG_SEO_UPDATE_SHARING_DETAILS";
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import ORG_SEO_SUMMARIES from "../../../GraphQl/Queries/ORG_SEO_SUMMARIES";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";

const SeoMainLoader = props => {
  const { currentOrgId, userPermission, currentUser, orgCustomization } = props;

  const { data: childOrgs = null, loading: childOrgsLoading } = useQuery(
    LIST_ORGS_ADMIN,
    {
      variables: { parentid: currentOrgId, first: 1000 },
    }
  );

  const { data, loading, error } = useQuery(ORG_SEO_SUMMARIES, {
    variables: { orgId: currentOrgId },
  });

  const [
    getSharedDetails,
    { data: sharedOrgsDetails, loading: sharedOrgsLoading },
  ] = useLazyQuery(ORG_SEO_SHARING_DETAILS, {
    fetchPolicy: "no-cache",
  });

  const [updateSEOSharedDetails] = useMutation(ORG_SEO_UPDATE_SHARING_DETAILS, {
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    userPermission,
    currentUser,
    currentOrgId,
    orgCustomization,
    seo: data && data.orgSeoSummaries ? data.orgSeoSummaries : [],
    childOrgs: childOrgs && childOrgs.orgs ? childOrgs.orgs : [],
    childOrgsLoading,
    getSharedDetails,
    sharedOrgsLoading,
    sharedOrgsDetails,
    updateSEOSharedDetails,
    seoLoading: loading,
    seoError: error,
  });
};

export default SeoMainLoader;
