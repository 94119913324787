import gql from "graphql-tag";

const DELETE_MANY_HASHED_EMAIL_ADDRESS = gql`
  mutation deleteManyHashedEmailAddresses($id: ID!) {
    deleteManyHashedEmailAddresses(
      where: { hashedEmailList_every: { id: $id } }
    ) {
      count
    }
  }
`;

export default DELETE_MANY_HASHED_EMAIL_ADDRESS;
