import React from "react";
import { Geom, Chart, Axis, Tooltip } from "bizcharts";
import { Card, Row, Spin } from "antd";
import { isEmpty } from "lodash";

const VideoCompletionRate = ({ chartData, chartsLoading }) => {
  // check chartData if it's empty before doing any computations
  if (isEmpty(chartData)) return <div />;

  const complete = chartData.reduce(
    (total, obj) => obj.video_complete + total,
    0
  );
  const firstQuartile = chartData.reduce(
    (total, obj) => obj.video_firstquartile + total,
    0
  );
  const midPoint = chartData.reduce(
    (total, obj) => obj.video_midpoint + total,
    0
  );
  const thirdQuartile = chartData.reduce(
    (total, obj) => obj.video_thirdquartile + total,
    0
  );

  const data = [
    {
      percentage: "25%",
      views: firstQuartile,
    },
    {
      percentage: "50%",
      views: midPoint,
    },
    {
      percentage: "75%",
      views: thirdQuartile,
    },
    {
      percentage: "100%",
      views: complete,
    },
  ];

  return (
    <>
      {chartsLoading ? (
        <>
          <Card>
            <Row type="flex" justify="center" align="middle">
              <Spin size="large" />
            </Row>
          </Card>
        </>
      ) : (
        <>
          <Chart height={400} data={data} forceFit>
            <Axis name="percentage" />
            <Axis name="views" />
            <Tooltip
              crosshairs={{
                type: "y",
              }}
            />
            <Geom type="interval" position="percentage*views" />
          </Chart>
        </>
      )}
    </>
  );
};
export default VideoCompletionRate;
