import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import { UniversalTag } from "./views/universal-tag/UniversalTag";
import { CardTitleText } from "../../../shared/globalStyling/styledText";
import {
  ArrowLeftOutlined,
  GlobalOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { VASTTag } from "./views/vast-tag/VASTTag";

const styles = {
  largeButton: {
    width: "150px",
    height: "150px",
  },
};

const TagBlock = ({ position, icon, text, onClick }) => (
  <Col style={{ padding: "0 10px" }}>
    <Button onClick={() => onClick(position)} style={styles.largeButton}>
      {icon}
      <div>{text}</div>
    </Button>
  </Col>
);

export const EventTagCard = ({
  campaignOrderId,
  orgId,
  collectorUrl,
  hostedTracker,
}) => {
  const [selected, setSelected] = useState(null);

  if (!selected)
    return (
      <Row style={{ width: "100%", padding: "30px" }}>
        <Row
          style={{ width: "100%", marginBottom: "10px" }}
          type="flex"
          justify="center"
          align="middle"
        >
          <CardTitleText text={"Add New Tag"} />
        </Row>
        <Row
          style={{ width: "100%" }}
          type="flex"
          justify="center"
          align="middle"
        >
          <TagBlock
            position={1}
            icon={
              <GlobalOutlined style={{ fontSize: 50, marginBottom: "5px" }} />
            }
            text={"Universal Tag"}
            onClick={setSelected}
          />
          <TagBlock
            position={2}
            icon={
              <VideoCameraOutlined
                style={{ fontSize: 50, marginBottom: "5px" }}
              />
            }
            text={"VAST Tag"}
            onClick={setSelected}
          />
        </Row>
      </Row>
    );

  if (selected === 1)
    return (
      <Row style={{ padding: "30px" }} type="flex" justify="center">
        <Col span={16}>
          <Row type="flex" justify="end">
            <Col>
              <Button
                style={{ border: 0, marginRight: "10%" }}
                icon={<ArrowLeftOutlined />}
                onClick={() => setSelected(null)}
              />
            </Col>
          </Row>
          <UniversalTag collectorUrl={collectorUrl} />
        </Col>
      </Row>
    );
  if (selected === 2)
    return (
      <Row style={{ padding: "30px" }} type="flex" justify="center">
        <Col span={16}>
          <Row type="flex" justify="end">
            <Col>
              <Button
                style={{ border: 0, marginRight: "10%" }}
                icon={<ArrowLeftOutlined />}
                onClick={() => setSelected(null)}
              />
            </Col>
          </Row>
          <VASTTag />
        </Col>
      </Row>
    );
};
