import React from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "./googlemaps";
import currentLocationIcon from "./assets/currentLocation.png";
import crossHairs from "./assets/crosshairs.png";
import { Row, Col, Button, message, Tooltip } from "antd";
import { isEmpty, isEqual } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import {
  getShapes,
  drawOverlay,
  getGeoCode,
  getFormattedAddress,
} from "./utils/mapUtils";
import { MapConfig } from "./configs/mapConfigs";
import * as turf from "@turf/turf";
import DrawingToolsDemoPopup from "./DrawingToolsDemoPopup";
import source from "../../../assets/maps/demo.gif";

// This google maps component is self contained.
// This means that any references to Google specific Components
// such as markers, overlays (polygons) will remian within the local state

// Currently this allows for 2 different sources of truth for the overlay:
// 1) Local State which is used to hold an in progress geoTarget
// 2) Controller GeoTarget state which contains finished geoTargets

// const Option = Select.Option;

//Sets a static initial location to the SF Bay Area
const defaultLocation = {
  latitude: "39.8097343",
  longitude: "-98.5556199",
};

class LauncherMap extends React.Component {
  state = {
    searchBarValue: "",
    selectedPlaces: [],
    currentGeoTarget: [],
    previousGeoTargets: [],
    inProgressShapes: [],
    defaultLocation: this.props.defaultLocation || defaultLocation,
  };

  componentDidMount() {
    this.getUserLocation();
    this.drawMap();
  }

  componentDidUpdate(prev) {
    const {
      currentLocationId,
      geoTargets,
      google,
      disabledDrawingManagerTool,
    } = this.props;
    if (
      !isEqual(prev.geoTargets, geoTargets) ||
      prev.currentLocationId !== currentLocationId
    ) {
      this.state.previousGeoTargets.forEach(shape => {
        shape.overlay.setMap(null);
      });

      const { newShapes, points } = getShapes({
        google,
        geoTargets,
        map: this.map,
      });

      drawOverlay({
        currentLocationId,
        google,
        geoTargets,
        map: this.map,
        points,
      });
      this.setState({
        previousGeoTargets: newShapes,
      });
    }

    if (prev.disabledDrawingManagerTool !== disabledDrawingManagerTool) {
      this.drawMap();
    }
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          this.setState({
            defaultLocation: {
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            },
          });
        },
        err => console.log(err),
        { enableHighAccuracy: true }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  drawMap = () => {
    const { latitude, longitude } = this.state.defaultLocation;
    const {
      google,
      zoom,
      currentLocationId,
      geoTargets,
      disabledDrawingManagerTool,
      disabledSearch,
    } = this.props;

    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    let currentLocation = new google.maps.LatLng({ lat, lng });

    //Set up map
    this.map = new google.maps.Map(this.mapNode, {
      center: { lat, lng },
      mapTypeId: "roadmap",
      zoom,
      mapTypeControl: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControl: false,
      fullscreenControl: false,
      minZoom: 2,
      maxZoom: 21,
    });
    let map = this.map;

    // Display Controller GeoTargets

    const { newShapes, points } = getShapes({
      google,
      geoTargets,
      map: this.map,
    });

    drawOverlay({
      currentLocationId,
      google,
      geoTargets,
      map: this.map,
      points,
    });

    this.setState({
      previousGeoTargets: newShapes,
    });

    //Custom current location icon
    // TODO: Refactor into react component state
    this.markers = [];

    const icon = {
      url: currentLocationIcon,
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };

    this.markers.push(
      new google.maps.Marker({
        map: map,
        icon: icon,
        title: "current location",
        position: currentLocation,
      })
    );

    //Drawing manager setup

    const toolOptions = {
      fillColor: "#BCDCF9",
      fillOpacity: 0.5,
      strokeWeight: 3,
      strokeColor: "#57ACF9",
      clickable: false,
      // editable: false,
      zIndex: 1,
    };

    if (!disabledDrawingManagerTool) {
      const { disablePolygonCreationTool } = this.props;

      const drawingModes = disablePolygonCreationTool
        ? [google.maps.drawing.OverlayType.CIRCLE]
        : [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
          ];

      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes,
        },
        polygonOptions: toolOptions,
        circleOptions: toolOptions,
        rectangleOptions: toolOptions,
      });

      drawingManager.setMap(this.map);

      google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        this.drawShapeFromManager
      );
    }

    // Custom searchbox setup.

    if (!disabledSearch) {
      const input = document.getElementById("search-input");

      var options = {
        componentRestrictions: MapConfig.componentRestrictions, // This includes the country restriction
      };

      const searchBox = new google.maps.places.Autocomplete(input, options);

      map.addListener("bounds_changed", function() {
        searchBox.setBounds(map.getBounds());
      });

      searchBox.addListener("place_changed", () => {
        let place = searchBox.getPlace();

        if (!place) {
          console.log("there are no places");
          return;
        }

        this.deleteMarkers();

        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        //Add default places icons for groups of found locations ie: restaurants

        let icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        //Add custom Icon for a single location

        if (place) {
          icon = {
            url: currentLocationIcon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };
        }

        this.markers.push(
          new google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        map.panTo(place.geometry.location);
        this.map.setZoom(15);
      });
    }
  };

  resetCenter = () => {
    const { map } = this;
    const { google, zoom } = this.props;
    const { latitude, longitude } = this.state.defaultLocation;
    if (latitude && longitude) {
      const home = new google.maps.LatLng({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
      map.panTo(home);
      map.setZoom(zoom);
    } else
      message.warn(
        "Location is disabled, please enable to use this functionality"
      );
  };

  deleteMarkers = () => {
    let markers = this.markers;
    markers.forEach(function(marker) {
      marker.setMap(null);
    });
    markers = [];
  };

  deleteAllShape = () => {
    this.state.currentGeoTarget.forEach(shape => {
      shape.overlay.setMap(null);
    });
    this.setState({
      currentGeoTarget: [],
      searchBarValue: "",
      selectedPlaces: [],
    });
  };

  deleteSearchMarkers = () => {
    let markers = this.markers;
    markers.forEach(function(marker) {
      marker.setMap(null);
    });
    markers = [];
  };

  isTooLarge = shape => {
    const { google } = this.props;
    if (shape.type === "polygon") {
      return (
        google.maps.geometry.spherical.computeArea(shape.overlay.getPath()) >
        2880648
      );
    } else {
      // is Circle
      return shape.overlay.getRadius() > 957.56918132089;
    }
  };

  drawShapeFromManager = e => {
    const newShape = e;

    if (this.state.currentGeoTarget.length) {
      newShape.overlay.setMap(null);
      message.warning(
        "Only one location is allowed per Geo Target. Please add your current Geo Target and try again."
      );
    } else {
      const newShapes = this.state.currentGeoTarget.slice();
      newShape.overlay.fillColor = "#ffff99";
      newShape.overlay.strokeColor = "#e5e500";
      newShapes.push(newShape);
      this.setState({ currentGeoTarget: newShapes });
    }
  };

  addGeoTarget = async () => {
    const { google } = this.props;
    const { countryType } = MapConfig;
    const newGeoLocation = [
      {
        addresses: [],
        circles: [],
        polygons: [],
      },
    ];

    for (let i = 0; i < this.state.currentGeoTarget.length; i++) {
      const shape = this.state.currentGeoTarget[i];
      let address,
        state,
        zip,
        county,
        country,
        city = "";
      if (shape.type === "circle") {
        // GET ADDRESS
        const { results } = await getGeoCode({
          latLng: shape.overlay.center,
          google,
        });

        if (results && results.length) {
          const result = results[0];
          const formattedAddress = getFormattedAddress({
            result,
          });

          address = formattedAddress.address;
          state = formattedAddress.state;
          zip = formattedAddress.zip;
          city = formattedAddress.city;
          county = formattedAddress.county;
          country = formattedAddress.country;
        }

        newGeoLocation[0].circles.push({
          coordinate: {
            lat: shape.overlay.center.lat(),
            lng: shape.overlay.center.lng(),
          },
          radius: shape.overlay.radius,
          name: shape.name,
          location: {
            name: "",
            formattedAddress: address,
            street: address,
            state: state.toUpperCase(),
            city,
            zip,
            county,
            country: countryType[country] || "",
            lat: shape.overlay.center.lat(),
            lng: shape.overlay.center.lng(),
          },
        });
      }

      if (shape.type === "region")
        newGeoLocation[0].addresses.push(shape.address);
      if (shape.type === "polygon") {
        const coordinates = [];

        for (let i = 0; i < shape.overlay.getPath().getLength(); i++) {
          let lng = shape.overlay
            .getPath()
            .getAt(i)
            .lng();
          let lat = shape.overlay
            .getPath()
            .getAt(i)
            .lat();
          coordinates.push([lng, lat]);
        }

        // GET ADDRESS
        const features = turf.featureCollection(
          coordinates.map(coordinatePair => turf.point(coordinatePair))
        );
        const center = turf.center(features);
        const latLng = new google.maps.LatLng(
          center.geometry.coordinates[1],
          center.geometry.coordinates[0]
        );

        const { results } = await getGeoCode({
          latLng,
          google,
        });

        if (results && results.length) {
          const result = results[0];
          const formattedAddress = getFormattedAddress({
            result,
          });

          address = formattedAddress.address;
          state = formattedAddress.state;
          zip = formattedAddress.zip;
          city = formattedAddress.city;
          county = formattedAddress.county;
          country = formattedAddress.country;
        }

        // address, state, zip, city, county, country

        let geometry = { coordinates: [coordinates] };
        newGeoLocation[0].polygons.push({
          geometry,
          location: {
            name: "",
            formattedAddress: address,
            street: address,
            state: state.toUpperCase(),
            city,
            zip,
            county,
            country: countryType[country] || "",
            lat: center.geometry.coordinates[0],
            lng: center.geometry.coordinates[1],
          },
        });
      }
    }

    this.props.addGeoTarget(newGeoLocation, this.props.isAttribution);
    this.deleteAllShape();
  };

  addRegion = () => {
    const { google } = this.props;
    const newShapes = this.state.currentGeoTarget.slice();
    const place = this.state.selectedPlaces[0];
    newShapes.push({
      type: "region",
      overlay: new google.maps.Marker({
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        map: this.map,
        title: place.name,
      }),
      address: place.formatted_address,
      name: place.name,
    });
    this.setState({ currentGeoTarget: newShapes });
  };

  drawCirclesFromPlaces = value => {
    const { google } = this.props;
    this.state.inProgressShapes.forEach(shape => {
      shape.overlay.setMap(null);
    });
    const newShapes = [];
    this.state.selectedPlaces.forEach(place => {
      newShapes.push({
        type: "circle",
        overlay: new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: this.map,
          center: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          radius: value * 1609.34,
        }),
        address: place.formatted_address,
        name: place.name,
      });
    });
    this.setState({ inProgressShapes: newShapes });
  };

  addPlace = () => {
    const { google } = this.props;
    const shapesToRemove = this.state.inProgressShapes.slice();
    shapesToRemove.forEach(shape => {
      shape.overlay.setMap(null);
    });
    this.setState({
      currentGeoTarget: [
        ...this.state.currentGeoTarget,
        ...shapesToRemove.map(shape => {
          return {
            type: "circle",
            overlay: new google.maps.Circle({
              strokeColor: "#e5e500",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#ffff99",
              fillOpacity: 0.35,
              map: this.map,
              center: {
                lat: shape.overlay.center.lat(),
                lng: shape.overlay.center.lng(),
              },
              radius: shape.overlay.radius,
            }),
            address: shape.address,
            name: shape.name,
          };
        }),
      ],
      inProgressShapes: [],
    });
  };

  handleInputChange = e => {
    this.setState({ searchBarValue: e.target.value });
  };

  isRegion = () =>
    this.state.selectedPlaces.some(location =>
      location.types.some(type => type === "political")
    );

  mapMounted = node => {
    this.mapNode = node;
  };

  render() {
    const { styles, disabledSearch, disabledDrawingManagerTool } = this.props;
    // const isRegionSelected = this.isRegion();
    return (
      <div
        id="walk-in-location"
        style={{
          backgroundColor: "#fff",
          position: "relative",
          ...styles.outerContainer,
        }}
      >
        {!disabledDrawingManagerTool && (
          <div>
            <DrawingToolsDemoPopup
              style={{
                right: 0,
                left: 0,
                margin: "auto",
                top: "15px",
              }}
              source={source}
              feature="Attribution Target Locations"
            />
          </div>
        )}

        {!disabledSearch && (
          <div>
            <input
              style={{
                width: "99.5%",
                borderRadius: 4,
                margin: "3px",
                padding: "5px",
              }}
              id="search-input"
              type="input"
              placeholder="Search an area..."
            />
          </div>
        )}

        {window.matchMedia("(max-width: 1100px)").matches ? (
          <React.Fragment>
            <Row
              gutter={24}
              style={{
                width: "50%",
                borderRadius: 4,
                zIndex: 99,
                position: "absolute",
                padding: "10px",
                top: "36px",
                left: "30px",
                // background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {
                // <Col span={24} style={{ textAlign: "center" }}>
                //   <Input
                //     style={{ width: "100%", borderRadius: 4 }}
                //     id="search-input"
                //     type="input"
                //     placeholder="Search an area..."
                //     onChange={this.handleInputChange}
                //     allowClear
                //     value={this.state.searchBarValue}
                //   />
                //   <Row>
                //     <Col
                //       span={12}
                //       style={{ textAlign: "center", paddingTop: "10px" }}
                //     >
                //       {isRegionSelected ? (
                //         <Button type="primary" onClick={this.addRegion}>
                //           Add Region
                //         </Button>
                //       ) : (
                //         <Select
                //           // TODO: THis needs to clear when a new address is selected
                //           onChange={this.drawCirclesFromPlaces}
                //           style={{ width: 120 }}
                //           placeholder="Radius"
                //           disabled={
                //             isEmpty(this.state.selectedPlaces) || isRegionSelected
                //           }
                //         >
                //           <Option value={1}>{"1 Mile"}</Option>
                //           <Option value={3}>{"3 Miles"}</Option>
                //           <Option value={5}>{"5 Miles"}</Option>
                //           <Option value={7}>{"7 Miles"}</Option>
                //           <Option value={10}>{"10 Miles"}</Option>
                //         </Select>
                //       )}
                //     </Col>
                //     {!isRegionSelected && (
                //       <Col
                //         span={12}
                //         style={{ textAlign: "center", paddingTop: "10px" }}
                //       >
                //         <Button
                //           type="primary"
                //           onClick={this.addPlace}
                //           disabled={isEmpty(this.state.inProgressShapes)}
                //         >
                //           Add Place
                //         </Button>
                //       </Col>
                //     )}
                //   </Row>
                // </Col>
              }
            </Row>
            {!disabledDrawingManagerTool && (
              <Row
                type="flex"
                gutter={14}
                style={{
                  width: "210px",
                  borderRadius: 4,
                  zIndex: 99,
                  position: "absolute",
                  padding: "10px",
                  top: "100px",
                  left: "35px",
                  background: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <Col>
                  <Button
                    style={{
                      textAlign: "center",
                    }}
                    type="primary"
                    onClick={this.addGeoTarget}
                    disabled={isEmpty(this.state.currentGeoTarget)}
                  >
                    Add GeoTarget
                  </Button>
                </Col>
                <Col>
                  <Tooltip title="Clears in progress shapes">
                    <Button
                      style={{
                        textAlign: "center",
                      }}
                      type="normal"
                      icon={<CloseOutlined />}
                      onClick={this.deleteAllShape}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row
              gutter={24}
              style={{
                width: "75%",
                borderRadius: 4,
                zIndex: 99,
                position: "absolute",
                padding: "10px",
                top: "36px",
                left: "30px",
                // background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {
                // <Col span={16} style={{ textAlign: "center" }}>
                //   <Input
                //     style={{ width: "100%", borderRadius: 4 }}
                //     id="search-input"
                //     type="input"
                //     placeholder="Search an area..."
                //     onChange={this.handleInputChange}
                //     allowClear
                //     value={this.state.searchBarValue}
                //   />
                // </Col>
                // <Col span={4} style={{ textAlign: "center" }}>
                //   {isRegionSelected ? (
                //     <Button type="primary" onClick={this.addRegion}>
                //       Add Region
                //     </Button>
                //   ) : (
                //     <Select
                //       // TODO: THis needs to clear when a new address is selected
                //       onChange={this.drawCirclesFromPlaces}
                //       style={{ width: 120 }}
                //       placeholder="Radius"
                //       disabled={
                //         isEmpty(this.state.selectedPlaces) || isRegionSelected
                //       }
                //     >
                //       <Option value={1}>{"1 Mile"}</Option>
                //       <Option value={3}>{"3 Miles"}</Option>
                //       <Option value={5}>{"5 Miles"}</Option>
                //       <Option value={7}>{"7 Miles"}</Option>
                //       <Option value={10}>{"10 Miles"}</Option>
                //     </Select>
                //   )}
                // </Col>
                // {!isRegionSelected && (
                //   <Col span={4} style={{ textAlign: "center" }}>
                //     <Button
                //       type="primary"
                //       onClick={this.addPlace}
                //       disabled={isEmpty(this.state.inProgressShapes)}
                //     >
                //       Add Place
                //     </Button>
                //   </Col>
                // )}
              }
            </Row>
            {!disabledDrawingManagerTool && (
              <Row
                type="flex"
                gutter={14}
                style={{
                  width: "210px",
                  borderRadius: 4,
                  zIndex: 99,
                  position: "absolute",
                  padding: "10px",
                  top: "36px",
                  right: "30px",
                  background: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <Col>
                  <Button
                    style={{
                      textAlign: "center",
                    }}
                    type="primary"
                    onClick={this.addGeoTarget}
                    disabled={isEmpty(this.state.currentGeoTarget)}
                  >
                    Add GeoTarget
                  </Button>
                </Col>
                <Col>
                  <Tooltip title="Clears in progress shapes">
                    <Button
                      style={{
                        textAlign: "center",
                      }}
                      type="normal"
                      icon={<CloseOutlined />}
                      onClick={this.deleteAllShape}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
        <Tooltip title="Move to current location">
          <Button
            onClick={this.resetCenter}
            style={{
              width: "40px",
              height: "40px",
              padding: "5px",
              position: "absolute",
              bottom: "120px",
              right: "10px",
              zIndex: 99,
            }}
          >
            <img src={crossHairs} alt="my location" style={{ height: 20 }} />
          </Button>
        </Tooltip>
        <div ref={this.mapMounted} style={styles.map} />
      </div>
    );
  }
}

LauncherMap.propTypes = {
  zoom: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  addGeoTarget: PropTypes.func.isRequired,
  geoTargets: PropTypes.array.isRequired,
  disabledDrawingManagerTool: PropTypes.bool,
};

export default GoogleApiWrapper(googleMapsConfig)(LauncherMap);
