import React from "react";
import { withRouter } from "react-router-dom";
import PaginationTable from "../../../shared/PaginationTable";
import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";
import { formatWholeNumber } from "../../../../core/utils/campaigns";
import { isEmpty } from "lodash";

// Global Styles
import {
  TableColumnTitle,
  PrimaryTableRowText,
  FlightDateText,
} from "../../../shared/globalStyling/styledText";

const Frag = React.Fragment;

const ReTargetingTable = ({
  onPage,
  history,
  countEventsTargets,
  orderByFilter,
  whereFilter,
  eventsTargets,
  changeValue,
  loadingEventsTargets,
  selectTableRowRetargeting,
  selectedRetargeting,
  afterEventsTargetSelected,
  pageSize,
  ...props
}) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Name"} />,
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: text => {
        if (!text) {
          return <div />;
        }
        return (
          <Frag>
            <Row type="flex" justify="start">
              <Col>
                <PrimaryTableRowText
                  text={
                    text.length > 35 ? (
                      <Tooltip title={text}>
                        {text.slice(0, 36).concat("...")}
                      </Tooltip>
                    ) : (
                      text
                    )
                  }
                />
              </Col>
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Date Range"} />,
      dataIndex: "start",
      key: "daterange",
      width: "20%",
      render: (text, record) => {
        if (!text) {
          return <div />;
        }
        const currentDate = moment();
        const startDate = moment(record.start);
        const endDate = moment(record.end);
        let flightProgress = 0;
        if (currentDate > endDate) flightProgress = 100;
        else if (currentDate < endDate && currentDate > startDate)
          flightProgress =
            (currentDate.diff(startDate, "minutes") /
              endDate.diff(startDate, "minutes")) *
            100;
        return (
          <Frag>
            <Row type="flex" justify="start">
              {record.start && (
                <Col>
                  <FlightDateText text={startDate.format("MM/DD/YY")} />
                </Col>
              )}
              <Col>
                <FlightDateText text={<span>&nbsp; &#8594; &nbsp;</span>} />
              </Col>
              {record.end && (
                <Col>
                  <FlightDateText text={endDate.format("MM/DD/YY")} />
                </Col>
              )}
            </Row>
            <Row type="flex" justify="start">
              <Progress
                id="main-campaign-table-progress"
                type={"line"}
                strokeWidth={3}
                percent={flightProgress ? flightProgress : 0}
                showInfo={false}
                strokeColor={"#636363"}
              />
            </Row>
          </Frag>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Event Tags"} />,
      dataIndex: "eventTags",
      key: "eventTags",
      width: "15%",
      render: (text, record) =>
        record.eventTags ? (
          <PrimaryTableRowText text={record.eventTags.length} />
        ) : (
          <div />
        ),
    },
    {
      title: <TableColumnTitle text={"Devices"} />,
      dataIndex: "uniqueDevices",
      key: "uniqueDevices",
      width: "15%",
      render: (text, record) =>
        record.uniqueDevices ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },
    {
      title: <TableColumnTitle text={"Observations"} />,
      dataIndex: "observations",
      key: "observations",
      width: "15%",
      render: (text, record) =>
        record.observations ? (
          <PrimaryTableRowText text={formatWholeNumber(text)} />
        ) : (
          <PrimaryTableRowText text={0} />
        ),
    },
  ];

  const rowSelection = {
    type: "checkbox",
    onSelect: (record, selected, selectedRows) => {
      if (record !== selectedRows[0]) {
        changeValue("afterEventsTargetSelected", true);
      }

      if (record === selectedRows[0] || selectedRows[0] === undefined) {
        changeValue("afterEventsTargetSelected", false);
      }

      return selectTableRowRetargeting(record, selected);
    },
    selectedRowKeys: selectedRetargeting.map(row => row.id),
    columnWidth: `${String(selectedRetargeting.length).length * 2}`,
    hideDefaultSelections: true,
    selections: [
      {
        text: "Select current page",
        onSelect: changableRowKeys => {
          selectTableRowRetargeting(eventsTargets, true, true);
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "#fff", border: "1px solid #e8e8e8" }}>
      <PaginationTable
        rowSelection={rowSelection}
        data={eventsTargets.map(events => {
          return { ...events, key: events.id };
        })}
        columns={columns}
        scroll={{ x: 700 }}
        pageSize={pageSize}
        onChange={async paginationObject => {
          changeValue("activeKeyTab", "retargeting");
          const { current } = paginationObject;

          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.get("pageSize")) searchParams.delete("pageSize");

          if (!isEmpty(paginationObject) && paginationObject.pageSize > 10) {
            searchParams.append("pageSize", paginationObject.pageSize);
          }

          history.push(
            `/segments/new/${current}/${orderByFilter}/${whereFilter}?${searchParams.toString()}`
          );
        }}
        total={countEventsTargets}
        current={onPage}
        bordered={false}
        loading={props.loadingEventsTarget}
      />
    </div>
  );
};

export default withRouter(ReTargetingTable);
