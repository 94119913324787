import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Button, message, Spin } from "antd";
import {
  SaveOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { errorType } from "../../../../core/utils/constants/constants";

const style = {
  button: { fontSize: "12px", width: "90px" },
  navigation: {
    marginTop: "0px",
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "100%",
  },
};

const NavigationButtons = ({
  currentStep,
  onStepChange,
  handleUpdateCampaignOrder,
  disabled,
  isLive,
  isEdit,
  complete,
  onSubmitLoad,
  canEdit = true,
}) => {
  const handleSaveCampaign = async () => {
    try {
      let step = null;
      switch (currentStep) {
        case 1:
          step = "basics";
          break;
        case 2:
          step = "targeting";
          break;
        case 3:
          step = "creatives";
          break;

        case 4:
          step = "attributions";
          break;

        case 5:
          step = "review";
          break;

        default:
          break;
      }

      await handleUpdateCampaignOrder({ step });

      message.success(
        isEdit
          ? "Campaign Order has been updated"
          : "Campaign Order has been saved"
      );
    } catch (error) {
      if (error.message === errorType.campaignOrderDuplicate) {
        message.error("Campaign Name already Exists!");
      } else {
        message.error(error.message);
      }
    }
  };

  const handleStepChange = async step => {
    try {
      await onStepChange(step);
      message.success("Campaign Order has been updated");
    } catch (error) {
      if (error.message === errorType.campaignOrderDuplicate) {
        message.error("Campaign Name already Exists!");
      } else {
        message.error(error.message);
      }
    }
  };

  return (
    <Card size="small" style={style.navigation}>
      <Row type="flex" justify="center">
        <Button
          type="primary"
          ghost={true}
          style={{ fontSize: "12px", width: "80%", marginBottom: "12px" }}
          shape="round"
          disabled={disabled || (isLive && !canEdit) || onSubmitLoad === true}
          icon={<SaveOutlined />}
          onClick={() => handleSaveCampaign()}
        >
          {onSubmitLoad === false ? (
            !isEdit ? (
              "Save Campaign"
            ) : (
              "Update Campaign"
            )
          ) : (
            <Spin size="small" />
          )}
        </Button>
      </Row>
      <Row type="flex" justify="space-around">
        <Button
          style={style.button}
          shape="round"
          onClick={() => {
            handleStepChange(currentStep - 1);
          }}
          disabled={currentStep === 1}
          icon={<CaretLeftOutlined />}
        >
          Back
        </Button>
        <Button
          style={style.button}
          type={complete ? "ghost" : "primary"}
          shape="round"
          onClick={() => {
            handleStepChange(currentStep + 1);
          }}
          disabled={disabled}
          icon={<CaretRightOutlined />}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};

NavigationButtons.propTypes = {
  onStepChange: PropTypes.func.isRequired,
  handleUpdateCampaignOrder: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default NavigationButtons;
