export function getImageDimensionsByURL(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);
    xhr.onreadystatechange = function() {
      if (this.readyState === this.DONE) {
        const contentLength = xhr.getResponseHeader("Content-Length");
        const size = parseInt(contentLength, 10);
        if (!isNaN(size)) {
          const image = new Image();
          image.src = url;
          image.onload = function() {
            resolve({
              height: image.height,
              width: image.width,
              size: size,
              isValidImage: true,
            });
          };
          image.onerror = function() {
            reject(new Error("Could not load image"));
          };
        } else {
          reject(new Error("Could not determine size of image"));
        }
      }
    };
    xhr.send();
  });
}
