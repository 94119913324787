import React from "react";
import { Form, Input, Col, Row, Modal } from "antd";
import Button from "../../../shared/globalStyling/Button";

export default function CreateAudienceForm({
  attributionAudienceName,
  closeForm,
  isFormOpen,
  onChangeStateValue,
  submitForm,
}) {
  const [form] = Form.useForm();

  return (
    <Modal
      title={"Create Audience"}
      visible={isFormOpen}
      centered
      footer={null}
      onCancel={() => {
        closeForm(false);
        form.resetFields();
      }}
    >
      <Form layout="vertical" onFinish={submitForm} form={form}>
        <Row type="flex" justify="space-around">
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input
                placeholder={"Name of Audience"}
                name="name"
                value={attributionAudienceName}
                onChange={e => {
                  onChangeStateValue("attributionAudienceName", e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button htmlType="submit" type="primary">
                Create
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
