import React from "react";
import { Tabs } from "antd";
import { TabText } from "../../../../../shared/globalStyling/styledText";
import SummaryTab from "./tabs/summary/SummaryTab";
import CampaignTab from "./tabs/campaigns/CampaignsTab";

const { TabPane } = Tabs;

const BingTab = ({
  bing,
  bingCampaigns,
  kpiPercentage,
  orgConfig,
  setBySummaryRangeDate,
  bySummaryRangeDate,
  orgId,
  history,
  selectedTab,
  location,
  currencyFormat,
  roasFormat,
  id,
}) => {
  return (
    <>
      <Tabs
        activeKey={selectedTab}
        onChange={selected => {
          let searchParams = new URLSearchParams(location.search);
          if (searchParams.get("subTab")) searchParams.delete("subTab");
          searchParams.append("subTab", selected);
          history.push(`${location.pathname}?${searchParams.toString()}`);
        }}
      >
        <TabPane tab={<TabText text={"Summary"} />} key="summary">
          <SummaryTab
            data={bing}
            kpiPercentage={kpiPercentage}
            orgConfig={orgConfig}
            setBySummaryRangeDate={setBySummaryRangeDate}
            bySummaryRangeDate={bySummaryRangeDate}
            currencyFormat={currencyFormat}
            roasFormat={roasFormat}
          />
        </TabPane>
        <TabPane tab={<TabText text={"Campaigns"} />} key="campaign">
          <CampaignTab
            campaigns={bingCampaigns}
            orgId={orgId}
            history={history}
            currencyFormat={currencyFormat}
            id={id}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default BingTab;
