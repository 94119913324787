// searchUtils.js

export const filterAndMapCategories = (groupedCategories, searchValues) => {
  if (!groupedCategories || !searchValues) {
    return {
      filteredCategories: [],
      filteredSubCategories: [],
    };
  }

  const filteredCategories = Object.values(groupedCategories).filter(
    item =>
      item.name.toLowerCase().includes(searchValues.toLowerCase()) ||
      item.description.toLowerCase().includes(searchValues.toLowerCase()) ||
      item.datapoints.some(
        dp =>
          dp.name.toLowerCase().includes(searchValues.toLowerCase()) ||
          dp.description.toLowerCase().includes(searchValues.toLowerCase())
      )
  );

  const mappedItemsWithCategoryName = filteredCategories.map(item => {
    let newItem = item;

    newItem.datapoints = item.datapoints.map(sub => ({
      ...sub,
      categoryName: item.name,
    }));

    return newItem;
  });

  const subCategories = mappedItemsWithCategoryName
    .map(item => item.datapoints)
    .flat();

  const filteredSubCategories = subCategories.filter(item =>
    item.name.toLowerCase().includes(searchValues.toLowerCase())
  );

  return {
    filteredCategories: filteredCategories,
    filteredSubCategories: filteredSubCategories,
  };
};
