import gql from "graphql-tag";

const GET_CAMPIGN_ORDER_OVERALL_EXPORT = gql`
  query campaignOrders($ids: [ID!]) {
    campaignOrders(where: { id_in: $ids }, orderBy: createdAt_DESC) {
      id
      name
      createdAt
      reports(
        where: { type: CAMPIGN_ORDER_OVERALL_EXPORT }
        orderBy: createdAt_DESC
      ) {
        id
        key
        createdAt
      }
    }
  }
`;

export default GET_CAMPIGN_ORDER_OVERALL_EXPORT;
