import gql from "graphql-tag";
const PAID_SEARCH_PACING_CAMPAIGNS = gql`
  query paidSearchPacingCampaigns(
    $orgId: String!
    $advertiserIds: [String!]!
    $date: String
    $status: String
  ) {
    getPaidSearchPacingCampaigns(
      orgId: $orgId
      advertiserIds: $advertiserIds
      date: $date
      status: $status
    ) {
      id
      name
      budget
      campaigns {
        id
        orgId
        name
        date
        dayOfMonth
        dayInMonth
        impressions
        clicks
        spend
        pace
        ctr
        cpc
        targetPacing
        monthlyBudget
        dailyRemainSpend
      }
    }
  }
`;

export default PAID_SEARCH_PACING_CAMPAIGNS;
