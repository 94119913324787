import React from "react";
import View from "./OverviewTab";
import Loader from "../../../../../../core/components/campaigns/campaigndetails/displayTab/DisplayTabDataLoader";

// Feature level subroutes
const DisplayTab = props => {
  return (
    <Loader {...props}>
      <View />
    </Loader>
  );
};

export default DisplayTab;
