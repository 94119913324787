import React from "react";
import { determineUserAccess } from "../../../permissions/userLevelAccess";

const MainController = ({
  children,
  data,
  startDateEndDate,
  setStartDateEndDate,
  setDateRangeKey,
  dateRangeKey,
  loadingOrgsList,
  orgsList,
  setLoadProgress,
  loadProgress,
  totals,
  setTotals,
  startDate,
  endDate,
  productOn,
  setProductOn,
  hasFeatureSEO,
  hasFeaturePaidSearch,
  ...props
}) => {
  const userPermission = determineUserAccess(props.currentUser.permission);
  return React.cloneElement(children, {
    ...props,
    startDateEndDate,
    setStartDateEndDate,
    setDateRangeKey,
    dateRangeKey,
    orgsList,
    loadingOrgsList,
    setLoadProgress,
    loadProgress,
    totals,
    setTotals,
    startDate,
    endDate,
    productOn,
    setProductOn,
    userPermission,
    hasFeatureSEO,
    hasFeaturePaidSearch,
  });
};

export default MainController;
