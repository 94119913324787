import React, { useState } from "react";
import { Progress, Row, Col, Card, Tooltip, Dropdown, Menu } from "antd";
import moment from "moment";
import { ExtraInfo } from "../../attribution/components";
// Global Styles
import {
  KPITitleText,
  KpiSubText,
  KpiFlightDate,
} from "../../../../../../../platform/shared/globalStyling/styledText";

import {
  formatCurrency2SigFig,
  formatWholeNumberNaN,
  computeROAS,
} from "../../../../../../../core/utils/campaigns";
import {
  ArrowRightOutlined,
  QuestionCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  roasFormat,
  defaultRoasFormat,
} from "../../../../../../../core/utils/constants/constants";

//TODO - break all styles out and store in central location
const styles = {
  container: {
    backgroundColor: "#fff",
    borderTop: "solid 1px #e8e8e8",
    marginBottom: "1%",
  },
  secondaryContainer: {
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  title: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "35%",
    borderLeft: "solid 1px #e8e8e8",
    padding: "3% 3% 1% 3%",
    margin: 0,
  },
  datesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  keyColors: {
    spent: "#1665d8",
    remaining: "#34aa44",
    budget: "#030303",
    total: "#bec0c3",
    value: "#99999",
  },
};

const DetailBar4ColV2 = ({
  data,
  priceVisible,
  dataConfig,
  currencyFormat,
}) => {
  const [roasFormatSelected, setRoasFormatSelected] = useState(
    (dataConfig && dataConfig.roasFormat) || defaultRoasFormat.key
  );

  const getProgress = () => {
    const total = () => {
      var a = moment(data.startDate);
      var b = moment(data.endDate);
      const total = b.diff(a, "days");
      return total;
    };

    const progress = () => {
      var a = moment();
      var b = moment(data.startDate);
      const progress = a.diff(b, "days");
      return progress;
    };

    const totaldays = total();
    const progressDays = progress();

    if (progressDays <= 0) {
      return 100;
    }

    // % increase = Increase ÷ Original Number × 100.
    const daysleft = totaldays - progressDays;
    const daysin = totaldays - daysleft;
    const result = (daysin / totaldays) * 100;

    return Math.floor(result);
  };

  const currentposition = getProgress();

  return (
    <Row style={styles.container} wrap={true}>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Flight Dates"} />
            <span>
              <ExtraInfo
                title={
                  "Flight Dates refers to the Start & End Dates of the individual campaign"
                }
                placement={"top"}
              />
            </span>
          </div>
          <div style={styles.datesContainer}>
            <div style={styles.dates}>
              <KpiFlightDate
                text={
                  data
                    ? moment(data.startDate).format("MM/DD/YYYY")
                    : moment().format("MM/DD/YYYY")
                }
              />
            </div>
            <div>
              <ArrowRightOutlined style={{ color: "#636363" }} />
            </div>
            <div style={styles.dates}>
              <KpiFlightDate
                text={
                  data
                    ? moment(data.endDate).format("MM/DD/YYYY")
                    : moment().format("MM/DD/YYYY")
                }
              />
            </div>
          </div>
          <Progress
            showInfo={false}
            percent={currentposition}
            status={currentposition !== 100 ? "active" : "normal"}
            strokeColor={"#636363"}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Transactions"} />
          </div>
          <KpiSubText
            text={data ? formatWholeNumberNaN(data.transactions) : "0"}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Influenced Sales"} />
            <span>
              <ExtraInfo
                title={
                  "Multi-Touch Attribution (MTA). Using device-level data, this MTA model shows impressions have been served to device identifiers that have also made transactions. This model does not take into account other factors that may have influenced the purchase such as search, newsletters or other marketing techniques. MTA should also not be confused with other models. ie: Last-Interaction or Position-based"
                }
                placement={"top"}
              />
            </span>
          </div>
          <KpiSubText
            text={formatCurrency2SigFig(
              data ? data.transactionRevenue : 0,
              currencyFormat
            )}
          />
        </Card>
      </Col>
      <Col flex="auto">
        <Card
          style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
          bodyStyle={{ borderLeft: 0 }}
        >
          <div style={styles.title}>
            <KPITitleText text={"Customers"} />
          </div>
          <KpiSubText
            text={data ? formatWholeNumberNaN(data.customers) : "0"}
          />
        </Card>
      </Col>

      {!priceVisible ? null : (
        <>
          <Col lg={4} xs={24}>
            <Card
              style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
              bodyStyle={{ borderLeft: 0 }}
            >
              <div style={styles.title}>
                <KPITitleText text={"Spend"} />
              </div>
              <KpiSubText
                text={formatCurrency2SigFig(
                  data ? data.spend : 0,
                  currencyFormat
                )}
              />
            </Card>
          </Col>
          <Col lg={4} xs={24}>
            <Card
              style={{ height: "10em", borderLeft: 0, borderTop: 0 }}
              bodyStyle={{ borderLeft: 0 }}
            >
              <div style={{ marginBottom: "10px" }}>
                <Row align="top">
                  <Col xs={19}>
                    <KPITitleText text={"ROAS"} />
                  </Col>
                  <Col xs={4}>
                    <Dropdown
                      placement="bottomLeft"
                      overlay={
                        <Menu>
                          {roasFormat.map(d => {
                            return (
                              <Menu.Item
                                key={d.key}
                                onClick={() => {
                                  setRoasFormatSelected(d.key);
                                }}
                              >
                                {d.name}
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      }
                    >
                      <MoreOutlined />
                    </Dropdown>
                  </Col>
                  <Col xs={1}>
                    <Tooltip
                      title={`ROAS Format can be changed by pressing the more icon besides this tooltip. 
                          Select the desired format of the ROAS on the dropdown. If the account is admin it can be changed in the org config`}
                      arrowPointAtCenter={true}
                      placement="rightBottom"
                    >
                      <QuestionCircleOutlined
                        type="question-circle"
                        style={{ margin: "0 auto" }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </div>
              <KpiSubText
                text={computeROAS(
                  data.transactionRevenue,
                  data.spend,
                  roasFormatSelected
                )}
              />
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};
export default DetailBar4ColV2;
