import React from "react";
import { Table, Card, Tooltip, Avatar, Skeleton } from "antd";
import { withRouter } from "react-router-dom";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import moment from "moment";
import { formatCurrency2SigFig } from "../../../../../core/utils/campaigns";
import cnnaid from "../../../../../assets/icons/transactions/cnnaid.png";
import cnnaip from "../../../../../assets/icons/transactions/cnnaip.png";
import cnnafingerprint from "../../../../../assets/icons/transactions/cnnafingerprint.png";
import { ReactComponent as GlobePhoneIcon } from "../../../../../assets/icons/transactions/globephone.svg";
import ImpressionSubTable from "./ImpressionsTimeLine";

const TransactionsTable = ({
  data = [],
  loading = false,
  total,
  topImpressions,
  dsp,
}) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Order"} />,
      index: "transactionId",
      key: "transactionId",
      render: (text, { transactionId }) => {
        return <PrimaryTableRowText text={transactionId} />;
      },
    },
    {
      title: <TableColumnTitle text={"Last Impression"} />,
      dataIndex: "completeLastImpression",
      key: "completeLastImpression",
      align: "left",
      width: "20%",
      sorter: (a, b) => {
        let getA = null;
        let getB = null;

        a.impressions.forEach(impression => {
          const { IMPRESSION_TIME } = impression;

          if (!getA) {
            getA = IMPRESSION_TIME;
          } else if (getA < IMPRESSION_TIME) {
            getA = IMPRESSION_TIME;
          } else {
            //None
          }
        });

        b.impressions.forEach(impression => {
          const { IMPRESSION_TIME } = impression;

          if (!getB) {
            getB = IMPRESSION_TIME;
          } else if (getB < IMPRESSION_TIME) {
            getB = IMPRESSION_TIME;
          } else {
            //None
          }
        });

        return new Date(getA) - new Date(getB);
      },
      render: (item, row) => {
        let latestDate = null;
        if (row && row.impressions) {
          const { impressions } = row;
          impressions.forEach(impression => {
            const { IMPRESSION_TIME } = impression;
            if (!latestDate) {
              latestDate = IMPRESSION_TIME;
            } else if (latestDate < IMPRESSION_TIME) {
              latestDate = IMPRESSION_TIME;
            } else {
              //do nothing
            }
          });
        } else if (row && row.latestDate) {
          latestDate = row.latestDate;
        }

        return (
          <PrimaryTableRowText
            text={
              latestDate
                ? moment(latestDate, moment.DATETIME_LOCAL_MS).format(
                    "MM/DD/YY - HH:mm"
                  )
                : ""
            }
          />
        );
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      key: "impressions",
      align: "left",
      width: "17%",
      sorter: (a, b) => a.impressions.length - b.impressions.length,
      render: (impressions, row) => {
        const { totalImpressions } = row;
        let count = 0;
        if (impressions) {
          if (totalImpressions && totalImpressions > impressions.length) {
            count = totalImpressions;
          } else {
            count = impressions.length;
          }
        } else {
          count = totalImpressions;
        }

        return <PrimaryTableRowText text={count} />;
      },
    },
    {
      title: <TableColumnTitle text={"Ordered on"} />,
      dataIndex: "transaction",
      key: "TRANSACTION_TIME",
      align: "left",
      width: "15%",
      sorter: (a, b) =>
        moment(a.transaction, moment.DATETIME_LOCAL_MS).unix() -
        moment(b.transaction, moment.DATETIME_LOCAL_MS).unix(),
      render: transaction => (
        <PrimaryTableRowText
          text={moment(transaction, moment.DATETIME_LOCAL_MS).format(
            "MM/DD/YY - HH:mm"
          )}
        />
      ),
    },
    {
      title: <TableColumnTitle text={"Total"} />,
      dataIndex: "transactionTotal",
      key: "total",
      align: "left",
      width: "12%",
      sorter: (a, b) => a.transactionTotal - b.transactionTotal,
      render: total => (
        <PrimaryTableRowText
          text={
            total && !isNaN(total)
              ? formatCurrency2SigFig(total)
              : formatCurrency2SigFig(0)
          }
        />
      ),
    },
    {
      title: <TableColumnTitle text={""} />,
      dataIndex: "attributionMethod",
      key: "attributionMethod",
      align: "left",
      width: "8%",
      render: col => {
        let record;

        record = col;
        if (typeof col === "string") {
          record = JSON.parse(col);
        }

        let method = "";
        let attributionIcon = cnnaip;
        let tip = "";
        //Single object with 1 key
        if (record) {
          method = Object.keys(record)[0];
          if (method === "COOKIE") {
            attributionIcon = cnnaid;
            tip =
              "Cookie Match - Deterministic match using browser based cookie / uuid match.";
          } else if (method === "FINGERPRINT") {
            attributionIcon = cnnafingerprint;
            tip =
              "User Match - Probablistic match using user fingerprint. (User fingerprint - The combination of user agent and ip address to produce a unique hash)";
          } else if (method === "DEVICE_FINGERPRINT") {
            tip =
              "Device Match - Probabilistic match using user device model information (Device model, OS, OS version ) combined with IP address";
          } else if (method === "IP") {
            tip = "IP Match - Probabilistic match using IP address.";
            attributionIcon = cnnaip;
          }
        }
        const attributionIcn = () => {
          if (method === "DEVICE_FINGERPRINT") {
            return (
              <span
                role="img"
                className="anticon"
                style={{ verticalAlign: "-5px" }}
              >
                <GlobePhoneIcon
                  height="35"
                  width="35"
                  style={{
                    opacity: "1.0",
                  }}
                />
              </span>
            );
          } else {
            return <Avatar src={attributionIcon} />;
          }
        };
        return <Tooltip title={tip}>{attributionIcn()}</Tooltip>;
      },
    },
  ];
  return (
    <React.Fragment>
      <Card title={"Transactions"}>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                position: ["none", "bottomCenter"],
                total: total ? total : data.length,
                responsive: true,
                showSizeChanger: false,
                showLessItems: true,
                size: "default",
              }}
              rowKey={({ transactionId }) => transactionId}
              scroll={{ x: 1200, y: 1700 }}
              expandedRowRender={record => {
                const { impressions } = record;
                return (
                  <ImpressionSubTable
                    impressions={impressions ? impressions : []}
                    record={record}
                    topImpressions={topImpressions}
                    dsp={dsp}
                  />
                );
              }}
            />
          </>
        )}
      </Card>
    </React.Fragment>
  );
};

export default withRouter(TransactionsTable);
