import React from "react";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import { Select } from "antd";
import OverlaySpinner from "./OverlaySpinner";

import LIST_ORGS_ADMIN_DROPDOWN from "../.././core/GraphQl/Queries/LIST_ORGS_ADMIN_DROPDOWN";
const Option = Select.Option;
const YcListOrgsDropDown = props => {
  if (props.listOrgsDropDown.loading) {
    return <OverlaySpinner />;
  }

  const list = props.listOrgsDropDown.orgs;

  return (
    <Select
      showSearch
      defaultValue="Organizations"
      name="category"
      style={{ width: "100%" }}
      onChange={value => {
        props.onChange(props.itemKey, value);
      }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {list.map((org, i) => {
        return (
          <Option key={i} value={org.id}>
            {org.name}
          </Option>
        );
      })}
    </Select>
  );
};

export default compose(
  graphql(LIST_ORGS_ADMIN_DROPDOWN, { name: "listOrgsDropDown" })
)(YcListOrgsDropDown);
