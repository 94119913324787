import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isEmpty, pullAt, cloneDeep, uniqueId } from "lodash";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../platform/shared/maps/googlemaps";
import {
  campaignTypeMap,
  stepsKeyValue,
} from "../../utils/constants/constants";
import { diffTargetAudiences } from "../../utils/campaigns";
import { message, notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  defaultIabCategory,
  mediajelOrgIdStaging,
  mediajelOrgIdProduction,
  providers,
} from "../../utils/constants/constants";
import GET_CAMPAIGN from "../../GraphQl/Queries/GET_CAMPAIGN";
class Controller extends Component {
  state = {
    type: 1,
    campaignOrderId: null,
    status: "DRAFT",
    showModal: false,
    showEditModal: false,
    whichModal: "",
    tablet: window.matchMedia("(max-width: 1100px)").matches,
    // Step: Basics (top to bottom)
    campaignName: "",
    description: "",
    advertiser: {
      id: this.props.currentUser.role.org.id,
      name: this.props.currentUser.role.org.name,
    },
    brands: [],
    category: "IAB7-5", //Alternative Medicine
    isRegulated: true,
    startDate: null,
    endDate: null,
    age: [],
    language: [],
    income: [],
    targetAudiences: [],
    devices: [],
    tags: [],
    cpm: 7,
    // Step: Details (top to bottom)
    objectiveNewCustomers: null,
    objectiveExistingCustomers: null,
    retargetDayCap: null,
    retargetTotalCap: null,
    kpi: "CPM",
    kpiGoal: null,
    budget: null,
    // Step: GeoTargeting (could be a circle, polygon, or string address ie. "California, USA")
    attributionWindow: 30,
    geoTargets: [],
    // Step: Creatives
    loading: false,
    adUnits: [
      { banners: [], name: null, clickthrough: null, selectedRows: [] },
    ],
    transactionTarget: "",
    traffId: "",
    locationId: "",
    gtmAccess: false,
    tracksBasketItems: false,
    gtmStatus: "",
    cart: "",
    mapOpen: true,
    isModalVisible: false,
    isModalVisibleTargeting: false,
    audienceGeoTargetsToAdd: [],
    audienceTargetingToAdd: [],
    attributionAudiences: [],
    retargetingAudiences: [],
    isLaunchCampaign: null,
    awarenessCampaign: false,
    signUpCampaign: false,
    pageViewCampaign: false,
    pageViewAppId: null,
    pageViewLocationId: null,
    targetingCriteria: null,
    isSucessfullySubmitted: false,
    onSubmitLoad: false,
    campaigns: [],
    appId: "",
    requestorId: "",
    selectedCampaignOrderListId: "",
    selectedCampaignOrderList: [],
    cloneGeoTargetData: [],
    cloneAttributionTargets: [],
    onCreateLineItemCampaignLoading: false,
    region: [],
    generatedTag: null,
    segmentId: null,
    segmentName: null,
    originalState: null,
    eventTagerts: [],
    changeHistories: [], // Array to store history of all changes
    isStatePristine: true, // Flag to check if the state is pristine
  };

  componentDidMount() {
    const getSessionIsCampaign = sessionStorage.getItem("isLaunchCampaign");

    const { getSubOrgsTagsData, campaignOrder } = this.props;

    this.setState({
      isLaunchCampaign: getSessionIsCampaign,
    });

    if (!isEmpty(campaignOrder)) {
      const {
        campaignOrder: {
          id,
          name,
          description,
          status,
          brands,
          category,
          isRegulated,
          startDate,
          endDate,
          age,
          language,
          income,
          targetAudiences,
          devices,
          tags,
          objectiveExistingCustomers,
          objectiveNewCustomers,
          retargetDayCap,
          retargetTotalCap,
          kpi,
          kpiGoal,
          budget,
          cloneGeoTargetData,
          geoTargets,
          adUnits,
          advertiser,
          type,
          attributionWindow,
          cpm,
          transactionTarget,
          awarenessCampaign,
          targetingCriteria,
          campaigns,
          signUpCampaign,
          transactionsConfig,
          pageViewConfig,
          signUpConfig,
          region,
        },
        currentUser: {
          role: {
            org: { id: orgId, name: orgName },
          },
        },
      } = this.props;

      const tag = transactionsConfig && transactionsConfig.tag;
      this.setState({
        campaignOrderId: id,
        status,
        showModal: false,
        campaignName: name,
        description,
        brands,
        category:
          category && category.length ? category : [defaultIabCategory.code],
        isRegulated,
        startDate,
        endDate,
        age,
        language,
        income,
        targetAudiences,
        devices,
        tags,
        objectiveNewCustomers,
        objectiveExistingCustomers,
        retargetDayCap,
        retargetTotalCap,
        kpi,
        kpiGoal,
        budget,
        campaigns,
        geoTargets,
        cloneGeoTargetData,
        adUnits: isEmpty(adUnits)
          ? [
              {
                banners: [],
                clickthrough: null,
                name: null,
                selectedRows: [],
              },
            ]
          : adUnits,
        advertiser: isEmpty(advertiser)
          ? { id: orgId, name: orgName }
          : advertiser,
        type: campaignTypeMap[type],
        attributionWindow,
        cpm,
        awarenessCampaign,
        region,
        signUpCampaign,
        pageViewCampaign: pageViewConfig && pageViewConfig.appId ? true : false,
        pageViewAppId:
          pageViewConfig && pageViewConfig.appId ? pageViewConfig.appId : null,
        pageViewLocationId:
          pageViewConfig && pageViewConfig.locationId
            ? pageViewConfig.locationId
            : null,
        targetingCriteria,
        transactionTarget,
        traffId:
          transactionsConfig !== null ? transactionsConfig.traffId : null,
        locationId: signUpConfig !== null ? signUpConfig.locationId : null,
        gtmAccess:
          transactionsConfig !== null ? transactionsConfig.gtmAccess : null,
        gtmStatus:
          transactionsConfig !== null ? transactionsConfig.gtmStatus : null,
        tracksBasketItems:
          transactionsConfig !== null
            ? transactionsConfig.tracksBasketItems
            : null,
        cart: transactionsConfig !== null ? transactionsConfig.cart : null,
        appId: transactionsConfig !== null ? transactionsConfig.appId : null,
        generatedTag: tag ? transactionsConfig.tag.generatedTag : null,
        segmentId: tag ? transactionsConfig.tag.segmentId : null,
        segmentName: tag ? transactionsConfig.tag.segmentName : null,
        tagName: tag ? transactionsConfig.tag.name : null,
      });
    }

    if (getSubOrgsTagsData && getSubOrgsTagsData.orgs.length) {
      const eventTagerts = [];
      getSubOrgsTagsData.orgs.forEach(org => {
        const { eventsTarget } = org;
        eventsTarget.forEach(event => {
          eventTagerts.push({
            name: event.name,
            org: org.name,
            cart: {
              appId: event.eventTags[0].appId[0],
              cart: event.cart,
              name: event.name,
              generatedTag: (event.tag && event.tag.generatedTag) || null,
              segmentId: event.segmentId,
              segmentName: event.segmentName,
            },
          });
        });
      });
      this.setState({ eventTagerts });
    }
  }

  componentDidUpdate() {
    this.updateOriginalState();
  }

  updateOriginalState() {
    const { isStatePristine, originalState } = this.state;

    if (isStatePristine && !originalState) {
      // Set the orginal state excluding the changeHistories and isStatePristine and originalState
      const duplicateState = { ...this.state };
      delete duplicateState.changeHistories;
      delete duplicateState.isStatePristine;
      delete duplicateState.originalState;
      delete duplicateState.getSubOrgsTagsData;
      this.setState({ originalState: duplicateState });
    }
  }

  onCampaignOrderListChange = getId => {
    this.props.selectedCampaignList({
      variables: {
        id: getId,
      },
    });
  };

  changeValue = (key, value) => {
    this.setState({ [key]: value });
  };

  onCheckBoxSelect = (value, array, state, checked) => {
    let returnArray = array;
    if (checked) {
      returnArray.push(value);
    }
    if (!checked) {
      returnArray.splice(
        returnArray.findIndex(device => device === value),
        1
      );
    }
    this.setState({
      [state]: returnArray,
    });
  };

  handleChangeRadius = async (value, index) => {
    const updatedGeoTarget = { ...this.state.geoTargets[index] };
    const update = await this.props.updateGeoTarget({
      variables: {
        id: updatedGeoTarget.id,
        name: updatedGeoTarget.name,
        circles: updatedGeoTarget.circles.map(circle => {
          return {
            where: {
              id: circle.id,
            },
            data: {
              radius: Math.round(value * 1609.34),
            },
          };
        }),
      },
    });
    if (update) {
      updatedGeoTarget.circles = updatedGeoTarget.circles.map(circle => {
        return { ...circle, radius: Math.round(value * 1609.34) };
      });
      const newState = cloneDeep(this.state.geoTargets);
      newState.splice(index, 1, updatedGeoTarget);
      this.setState({ geoTargets: newState });
    }
  };

  transCart = value => {
    const provider = providers.find(provider => provider.value === value);
    return provider ? provider.legacyCtr : null;
  };

  onChange = (key, value) => {
    if (key === "cart") {
      value = this.transCart(value) || value;
    }

    const { originalState } = this.state;
    let oldValue = originalState[key];
    let newValue = value;

    switch (key) {
      case "advertiser":
        oldValue = originalState[key].name;
        newValue = value.name;
        break;

      case "category":
        // Check if oldValue is type array then get the first index
        if (originalState[key].constructor === Array) {
          oldValue = originalState[key][0];
        }
        break;

      default:
        break;
    }

    if (oldValue === newValue) {
      // remove the property from changeHistories
      const changeHistories = this.state.changeHistories.filter(
        change => change.property !== key
      );
      this.setState({ [key]: value, changeHistories });
      return;
    }

    // Update the changeHistories
    const changeRecord = {
      dataProperty: key,
      oldValue: oldValue ? oldValue.toString() : oldValue,
      newValue: newValue ? newValue.toString() : newValue,
    };

    // Check if property already exists in changeHistories, if so overwrite it, else add it
    const changeHistories = this.state.changeHistories;
    const index = changeHistories.findIndex(
      change => change.property === changeRecord.property
    );
    if (index !== -1) {
      changeHistories[index] = changeRecord;
    } else {
      changeHistories.push(changeRecord);
    }

    this.setState({
      [key]: value,
      isStatePristine: false,
      changeHistories,
    });
  };

  onChangeArrayOfObj = (key, index, innerkey, value) => {
    if (!!this.state[key] && this.state[key].constructor === Array) {
      const nextState = cloneDeep(this.state[key]);
      nextState[index][innerkey] = value;

      const changeRecord = {
        dataProperty: `${key} ${innerkey}`,
        oldValue: this.state.originalState[key][index][innerkey],
        newValue: value,
      };

      const changeHistories = this.state.changeHistories;
      const matchedHistoryIndex = changeHistories.findIndex(
        change => change.property === changeRecord.property
      );

      if (matchedHistoryIndex !== -1) {
        changeHistories[matchedHistoryIndex] = changeRecord;
      } else {
        changeHistories.push(changeRecord);
      }

      this.setState({
        [key]: nextState,
        changeHistories,
      });
    } else throw new Error("Incorrect usage, targeted state is not an array");
  };

  addAdUnit = () => {
    const nextState = this.state.adUnits;
    nextState.push({
      banners: [],
      clickthrough: null,
      name: null,
    });
    this.setState({ adUnits: nextState });
  };

  resetAdUnit = async () => {
    const nextState = this.state.adUnits;

    if (!isEmpty(nextState[0].banners)) {
      const medias = [];
      nextState[0].banners.forEach((media, ind) => {
        medias.push({ ...media, key: ind });
      });

      nextState[0].selectedRows = medias;
      this.removeBanners(0);
    }
    if (!isEmpty(nextState[0].id)) {
      await this.props.deleteAdUnit({
        variables: {
          id: this.state.campaignOrderId,
          adUnitId: nextState[0].id,
        },
      });
      this.setState({
        adUnits: [
          {
            banners: [],
            clickthrough: null,
            name: null,
          },
        ],
      });
      return true;
    }
    nextState[0] = {
      banners: [],
      clickthrough: null,
      name: null,
    };

    this.setState({ adUnits: nextState });
  };
  removeAdUnit = async index => {
    const slicedUnits = cloneDeep(this.state.adUnits);
    const removedAdUnit = slicedUnits.splice(index, 1);
    const remove = isEmpty(removedAdUnit[0].id)
      ? true
      : await this.props.deleteAdUnit({
          variables: {
            id: this.state.campaignOrderId,
            adUnitId: removedAdUnit[0].id,
          },
        });
    if (remove) this.setState({ adUnits: slicedUnits });
  };

  removeBanners = index => {
    const newState = this.state.adUnits.slice();
    pullAt(
      newState[index].banners,
      newState[index].selectedRows.map(row => row.key)
    );
    this.setState({ adUnits: newState });
  };

  removeBannerMultiple = async adUnit => {
    const {
      deleteManyCreatives,
      updateCreativeStatus,
      createCampaignHistory,
      currentUser,
      updateCampaignOrderReview,
    } = this.props;
    const { status, campaignOrderId, adUnits } = this.state;
    const results = [];

    try {
      const creativeIds = adUnit.selectedRows
        .filter(adUnit => adUnit.hasOwnProperty("id"))
        .filter(adUnit => adUnit.id !== undefined)
        .map(adUnit => adUnit.id);

      // TODO: CONTINUE REIMPLEMENT ON UPDATE CAMPAIGN FEATURE BRANCH
      if (["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(status)) {
        creativeIds.forEach(id => {
          results.push(
            updateCreativeStatus({
              variables: {
                id,
                status: "PENDING_DELETE",
              },
            })
          );
        });

        return Promise.all(results).then(response => {
          const updatedAdUnits = adUnits.map(adUnit => {
            const banners = adUnit.banners.map(banner =>
              banner.id ? banner : banner.media ? banner.media : banner
            );
            return {
              ...adUnit,
              banners: banners.filter(
                banner => !creativeIds.includes(banner.id)
              ),
              selectedRows: [],
            };
          });

          adUnit.selectedRows.forEach(banner => {
            createCampaignHistory({
              variables: {
                action: "PENDING_DELETE",
                property: "CREATIVE",
                data: {
                  property: "banner",
                  name: banner.name,
                },
                authorId: currentUser.id,
                campaignOrderId,
              },
            });
          });

          this.setState({ adUnits: updatedAdUnits });
        });
      } else {
        if (adUnit.selectedRows) {
          if (creativeIds.length) {
            await deleteManyCreatives({
              variables: {
                ids: creativeIds,
              },
            });
          }

          const updatedAdUnits = this.state.adUnits.map(adUnit => {
            const banners = adUnit.banners.map(banner =>
              banner.id ? banner : banner.media ? banner.media : banner
            );
            return {
              ...adUnit,
              banners: banners.filter(
                banner => !creativeIds.includes(banner.id)
              ),
              selectedRows: [],
            };
          });

          this.setState({ adUnits: updatedAdUnits });

          message.destroy();
          message.success("Creative(s) Successfully Deleted.");
        }
      }

      if (status === "LIVE") {
        await updateCampaignOrderReview({
          variables: {
            campaignOrderId: campaignOrderId,
            status: "LIVE_PENDING",
            stateLaw: null,
            scaleBudget: null,
            reviewerId: currentUser.id,
          },
        });
      }

      if (["LIVE", "LIVE_PENDING"].includes(status)) {
        adUnit.selectedRows.forEach(banner => {
          createCampaignHistory({
            variables: {
              action: "PENDING_DELETE",
              property: "CREATIVE",
              data: {
                property: "banner",
                name: banner.name,
              },
              authorId: currentUser.id,
              campaignOrderId,
            },
          });
        });
      }
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  };

  onSlider = (newCustomers, existingCustomers) => {
    this.setState({
      objectiveNewCustomers: newCustomers,
      objectiveExistingCustomers: existingCustomers,
    });
  };

  onStepChange = async value => {
    let searchParams = new URLSearchParams(this.props.location.search);
    if (value && value.toString() === searchParams.get("step")) {
      return;
    }
    // get current step value from search params
    const currentStep = searchParams.get("step");

    const { campaignOrderId } = this.state;

    try {
      if (!campaignOrderId) {
        searchParams.append("step", value);
        const campaignOrder = await this.handleUpdateCampaignOrder({
          step: "basics",
        });

        this.props.history.push(
          `${this.props.location.pathname}/${
            campaignOrder.id
          }?${searchParams.toString()}`
        );
      } else {
        if (searchParams.has("step")) {
          searchParams.set("step", value);
        } else {
          searchParams.append("step", value);
        }

        const stepValue = stepsKeyValue[currentStep];
        if (stepValue) {
          this.handleUpdateCampaignOrder({
            step: stepsKeyValue[currentStep || "1"],
          });
        }

        this.props.history.push(
          `${this.props.location.pathname}?${searchParams.toString()}`
        );
      }
    } catch (error) {
      throw error;
    }
  };

  onConfirmLocationOk = () => {
    const { selectedLocations } = this.state;
    const geotarget = {
      circles: selectedLocations.map(address => {
        return {
          radius: address.radius ? address.radius * 1609.34 : 1609.34,
          address: address.foundAddress,
          coordinate: {
            lat: address.lat,
            lng: address.lng,
          },
        };
      }),
      polygons: [],
      addresses: [],
    };
    this.addGeoTarget(geotarget);
    this.setState({
      showModal: false,
    });
  };

  handleLocations = geoTargets => {
    if (isEmpty(geoTargets)) return null;

    const processedGeoTargets = geoTargets.map(geoTarget => {
      delete geoTarget.id;
      delete geoTarget.__typename;

      let circles = geoTarget.circles;
      let polygons = geoTarget.polygons;

      if (!isEmpty(circles)) {
        circles = circles.map(circle => {
          delete circle.id;
          delete circle.__typename;
          delete circle.coordinate.__typename;

          let location = null;
          if (circle.location) {
            location = {
              name: circle.location.name,
              formattedAddress: circle.location.formattedAddress,
              zip: circle.location.zip,
              street: circle.location.street,
              city: circle.location.city,
              lat: circle.location.lat,
              lng: circle.location.lng,
              audienceType: circle.location.audienceType,
              state: circle.location.state,
            };
          }

          // Return the Circle Object
          return {
            ...(location ? { location: { create: location } } : {}),
            radius: circle.radius,
            coordinate: {
              create: {
                lat: circle.coordinate.lat,
                lng: circle.coordinate.lng,
              },
            },
          };
        });
      }

      if (!isEmpty(polygons)) {
        polygons = polygons.map(polygon => {
          delete polygon.id;
          delete polygon.__typename;

          return {
            geometry: {
              coordinates: polygon.geometry.coordinates,
            },
          };
        });
      }

      // Return the GeoTarget Object
      return {
        name: geoTarget.name || null,
        start: geoTarget.start || null,
        end: geoTarget.end || null,
        circles: {
          create: circles,
        },
        ...(polygons ? { polygons: { create: polygons } } : {}),
      };
    });

    return processedGeoTargets;
  };

  handleSubmitBasics = async () => {
    this.setState({ onSubmitLoad: true });

    const {
      createCampaignOrder,
      currentUser,
      orgID,
      updateCampaignOrder,
      setIsCloningClicked,
    } = this.props;
    const {
      advertiser,
      budget,
      campaignName,
      campaignOrderId,
      category,
      cpm,
      description,
      endDate,
      isRegulated,
      kpi,
      kpiGoal,
      startDate,
      status,
      type,
      attributionWindow,
    } = this.state;
    let advertiserId = advertiser ? advertiser.id : null;

    setIsCloningClicked(false);
    if (currentUser.permission) {
      const { permission } = currentUser;
      const { isSelfService, isProcessor, isTrafficker } = permission;

      if ((isSelfService && !isProcessor && !isTrafficker) || !advertiserId) {
        advertiserId =
          process.env.REACT_APP_ENV === "production"
            ? mediajelOrgIdProduction
            : mediajelOrgIdStaging;
      }
    }

    const data = {
      name: campaignName,
      description,
      budget: +budget,
      startDate,
      endDate,
      type: campaignTypeMap[type],
      cpm,
      requestorId: this.props.loggedInId,
      status,
      kpi,
      kpiGoal: +kpiGoal,
      isRegulated,
      attributionWindow: parseInt(attributionWindow),
      createdBy: {
        connect: {
          id: currentUser.id,
        },
      },
      orgs: {
        connect: {
          id: orgID,
        },
      },
      category: {
        set: category,
      },
      advertiser: {
        connect: {
          id: advertiserId,
        },
      },
      campaignChecklistProcess: {
        create: {
          processOne: false,
          processOneText: [],
          processTwo: false,
          processTwoText: [],
          processThree: false,
          processThreeText: [],
          processFour: false,
          processFourText: [],
          processFive: false,
          processFiveText: null,
        },
      },
    };

    if (!campaignOrderId) {
      try {
        data.adUnits = {
          create: [
            {
              name: "",
              clickthrough: "",
            },
          ],
        };

        const response = await createCampaignOrder({
          variables: {
            data: {
              ...data,
              status: "DRAFT",
              name:
                campaignName ||
                `[DRAFT ${uniqueId("#")}-${new Date().getTime()}]`,
            },
          },
        });

        const campaignOrder = response.data.createCampaignOrder;

        const searchParams = new URLSearchParams(this.props.location.search);
        this.props.history.push(
          `${this.props.location.pathname}/${
            campaignOrder.id
          }?${searchParams.toString()}`
        );

        this.setState({ onSubmitLoad: false });
        return campaignOrder;
      } catch (error) {
        this.setState({ onSubmitLoad: false });
        throw error;
      }
    } else {
      try {
        const response = await updateCampaignOrder({
          variables: {
            where: {
              id: campaignOrderId,
            },
            data,
          },
        });

        this.setState({ onSubmitLoad: false });
        return response.data.updateCampaignOrder;
      } catch (error) {
        this.setState({ onSubmitLoad: false });
        throw error;
      }
    }
  };

  // TODO: Handle update geo targets
  handleSubmitTargeting = async () => {
    this.setState({ onSubmitLoad: true });
    const {
      targetingCriteria,
      region,
      campaignOrderId,
      originalState,
    } = this.state;
    const { updateCampaignOrder } = this.props;

    if (JSON.stringify(region) !== JSON.stringify(originalState.region)) {
      // Update changeHistories
      const changeHistories = this.state.changeHistories;
      const index = changeHistories.findIndex(
        change => change.property === "region"
      );
      if (index !== -1) {
        changeHistories[index].newValue = region;
      } else {
        changeHistories.push({
          dataProperty: "region",
          oldValue: originalState.region,
          newValue: region,
        });
      }

      this.setState({
        changeHistories,
      });
    } else {
      // remove the property from changeHistories
      const changeHistories = this.state.changeHistories.filter(
        change => change.property !== "region"
      );
      this.setState({ changeHistories });
    }

    const data = {
      targetingCriteria,
      region: {
        set: region,
      },
    };

    try {
      const response = await updateCampaignOrder({
        variables: {
          where: {
            id: campaignOrderId,
          },
          data,
        },
      });

      this.setState({ onSubmitLoad: false });
      return response.data.updateCampaignOrder;
    } catch (error) {
      this.setState({ onSubmitLoad: false });
      return null;
    }
  };

  handleSubmitCreatives = async () => {
    this.setState({ onSubmitLoad: true });
    const { adUnits, campaignOrderId, startDate, endDate } = this.state;
    const { updateCampaignOrder } = this.props;

    try {
      const adUnitsToUpdate = adUnits.map(adUnit => {
        return {
          id: adUnit.id,
          name: adUnit.name,
          clickthrough: adUnit.clickthrough,
          startDate: adUnit.startDate || startDate,
          endDate: adUnit.endDate || endDate,
        };
      });

      const newMedias = [];
      adUnits.forEach(adUnit => {
        adUnit.banners.forEach(banner => {
          if (!banner.id) {
            newMedias.push(banner.media);
          } else if (!banner.media) {
            newMedias.push(banner);
          }
        });
      });

      const response = await updateCampaignOrder({
        variables: {
          where: {
            id: campaignOrderId,
          },
          data: {
            adUnits: {
              create: adUnitsToUpdate
                .filter(adUnit => !adUnit.id)
                .map(adUnit => {
                  return {
                    name: adUnit.name,
                    clickthrough: adUnit.clickthrough,
                    startDate: adUnit.startDate,
                    endDate: adUnit.endDate,
                    banners: {
                      create: newMedias.map(media => {
                        return {
                          media: {
                            create: {
                              fileType: media.fileTypes,
                              height: media.height,
                              key: media.key,
                              name: media.name,
                              size: media.size,
                              type: media.type || "STILL",
                              url: media.url,
                              width: media.width,
                            },
                          },
                        };
                      }),
                    },
                  };
                }),
              update: adUnitsToUpdate
                .filter(adUnit => adUnit.id)
                .map(adUnit => {
                  return {
                    where: {
                      id: adUnit.id,
                    },
                    data: {
                      name: adUnit.name,
                      clickthrough: adUnit.clickthrough,
                      startDate: adUnit.startDate,
                      endDate: adUnit.endDate,
                    },
                  };
                }),
            },
          },
        },
      });

      this.setState({ onSubmitLoad: false });
      return response.data.updateCampaignOrder;
    } catch (error) {
      this.setState({ onSubmitLoad: false });
      return null;
    }
  };
  handleSubmitAttribution = async () => {
    this.setState({ onSubmitLoad: true });
    const { updateCampaignOrder, campaignOrder } = this.props;

    const {
      appId,
      awarenessCampaign,
      campaignOrderId,
      cart,
      generatedTag,
      gtmAccess,
      gtmStatus,
      locationId,
      segmentId,
      segmentName,
      signUpCampaign,
      tagName,
      tags,
      targetAudiences,
      tracksBasketItems,
      traffId,
      transactionTarget,
      pageViewCampaign,
      pageViewAppId,
      pageViewLocationId,
    } = this.state;

    const data = {
      awarenessCampaign,
      signUpCampaign,
      tags,
      targetAudiences: diffTargetAudiences(
        campaignOrder && campaignOrder.targetAudiences
          ? campaignOrder.targetAudiences
          : [],
        targetAudiences
      ),
      signUpConfig: {
        upsert: {
          create: {
            appId,
            locationId,
          },
          update: {
            appId,
            locationId,
          },
        },
      },
      transactionTarget,
    };

    if (pageViewCampaign) {
      data.pageViewConfig = {
        upsert: {
          create: {
            appId: pageViewAppId,
            locationId: pageViewLocationId,
          },
          update: {
            appId: pageViewAppId,
            locationId: pageViewLocationId,
          },
        },
      };
    } else if (!pageViewCampaign && pageViewAppId) {
      //Disabled Page View on the toggle
      data.pageViewConfig = {
        upsert: {
          create: {
            appId: null,
            locationId: null,
          },
          update: {
            appId: null,
            locationId: null,
          },
        },
      };
    }

    if (cart && transactionTarget) {
      data.transactionsConfig = {
        upsert: {
          create: {
            appId: transactionTarget,
            cart: cart.toUpperCase(),
            traffId,
            gtmAccess,
            gtmStatus,
            tracksBasketItems,
            tag: {
              generatedTag,
              segmentId,
              segmentName,
              name: tagName,
            },
          },
          update: {
            appId: transactionTarget,
            cart: cart.toUpperCase(),
            traffId,
            gtmAccess,
            gtmStatus,
            tracksBasketItems,
            tag: {
              generatedTag,
              segmentId,
              segmentName,
              name: tagName,
            },
          },
        },
      };
    }

    //Rempve transaction config if cart and transaction target is empty
    if (campaignOrder.transactionsConfig && !cart && !transactionTarget) {
      data.transactionsConfig = {
        delete: true,
      };
    }

    try {
      const result = await updateCampaignOrder({
        variables: {
          where: {
            id: campaignOrderId,
          },
          data,
        },
      });

      this.setState({ onSubmitLoad: false });
      return result.data.updateCampaignOrder;
    } catch (error) {
      this.setState({ onSubmitLoad: false });
    }
  };

  handleUpdateCampaignOrder = async ({ step }) => {
    const { status, campaignOrderId, changeHistories } = this.state;
    const { currentUser, createCampaignHistory } = this.props;
    let campaignOrderResult = null;

    try {
      switch (step) {
        case "basics":
          campaignOrderResult = await this.handleSubmitBasics();
          break;

        case "targeting":
          campaignOrderResult = await this.handleSubmitTargeting();
          break;

        case "creatives":
          campaignOrderResult = await this.handleSubmitCreatives();
          break;

        case "attributions":
          campaignOrderResult = await this.handleSubmitAttribution();
          break;

        default:
          break;
      }

      let action = null;
      switch (status) {
        case "LIVE":
        case "LIVE_PENDING":
        case "LIVE_APPROVED":
          action = "PENDING_UPDATE";
          break;

        default:
          action = "UPDATED";
          break;
      }

      if (!campaignOrderId) {
        await createCampaignHistory({
          variables: {
            action: "CREATED",
            property: "BASICS",
            data: {
              name: campaignOrderResult.name,
            },
            authorId: currentUser.id,
            campaignOrderId: campaignOrderResult.id,
          },
        });
        changeHistories.shift();
      }

      changeHistories.forEach(
        ({ property, dataProperty, oldValue, newValue }) => {
          createCampaignHistory({
            variables: {
              action,
              property: property || "BASICS",
              data: {
                property: dataProperty,
                oldValue,
                newValue,
              },
              authorId: currentUser.id,
              campaignOrderId: campaignOrderId || campaignOrderResult.id,
            },
          });
          // Update original state to reflect the changes
          const originalState = { ...this.state.originalState };
          originalState[property] = this.state[property];
          this.setState({ originalState });
        }
      );

      this.setState({
        changeHistories: [],
        originalState: null,
        isStatePristine: true,
      });

      return campaignOrderResult;
    } catch (error) {
      throw error;
    }
  };

  handleCloneCampaignOrder = async () => {
    this.setState({ onSubmitLoad: true });
    const {
      createCampaignOrder,
      currentUser,
      orgID,
      setIsCloningClicked,
      createCampaignHistory,
    } = this.props;
    try {
      const {
        advertiser,
        budget,
        campaignName,
        category,
        cpm,
        description,
        endDate,
        isRegulated,
        kpi,
        kpiGoal,
        startDate,
        type,
        attributionWindow,
        targetingCriteria,
        region,
        adUnits,
        cloneAttributionTargets,
        appId,
        awarenessCampaign,
        cart,
        generatedTag,
        gtmAccess,
        gtmStatus,
        locationId,
        segmentId,
        segmentName,
        signUpCampaign,
        tagName,
        tags,
        targetAudiences,
        cloneGeoTargetData,
        tracksBasketItems,
        traffId,
        transactionTarget,
        pageViewCampaign,
        pageViewAppId,
        pageViewLocationId,
      } = this.state;
      let advertiserId = advertiser ? advertiser.id : null;
      advertiserId = null;

      if (currentUser.permission) {
        const { permission } = currentUser;
        const { isSelfService, isProcessor, isTrafficker } = permission;

        if ((isSelfService && !isProcessor && !isTrafficker) || !advertiserId) {
          advertiserId =
            process.env.REACT_APP_ENV === "production"
              ? mediajelOrgIdProduction
              : mediajelOrgIdStaging;
        }
      }

      const data = {
        name: campaignName,
        description,
        budget: +budget,
        startDate,
        endDate,
        type: campaignTypeMap[type],
        cpm,
        requestorId: this.props.loggedInId,
        status: "DRAFT",
        kpi,
        kpiGoal: +kpiGoal,
        isRegulated,
        attributionWindow: parseInt(attributionWindow),
        createdBy: {
          connect: {
            id: currentUser.id,
          },
        },
        orgs: {
          connect: {
            id: orgID,
          },
        },
        category: {
          set: category,
        },
        advertiser: {
          connect: {
            id: advertiserId,
          },
        },
        campaignChecklistProcess: {
          create: {
            processOne: false,
            processOneText: [],
            processTwo: false,
            processTwoText: [],
            processThree: false,
            processThreeText: [],
            processFour: false,
            processFourText: [],
            processFive: false,
            processFiveText: null,
          },
        },

        // Targeting
        targetingCriteria,
        region: {
          set: region,
        },
        geoTargets: {
          create: this.handleLocations(cloneGeoTargetData),
        },

        // Creatives
        adUnits: {
          create: adUnits.map(adUnit => {
            return {
              name: adUnit.name,
              clickthrough: adUnit.clickthrough,
              banners: {
                create: adUnit.banners.map(banner => {
                  return {
                    media: {
                      create: {
                        fileType: banner.media.fileTypes,
                        height: banner.media.height,
                        key: banner.media.key,
                        name: banner.media.name,
                        size: banner.media.size,
                        type: banner.media.type || "STILL",
                        url: banner.media.url,
                        width: banner.media.width,
                      },
                    },
                  };
                }),
              },
            };
          }),
        },
        // Attribution
        attributionTargets: {
          create: this.handleLocations(cloneAttributionTargets),
        },
        awarenessCampaign,
        signUpCampaign,
        tags,
        targetAudiences,
        signUpConfig: {
          create: {
            appId,
            locationId,
          },
        },
        transactionTarget,
      };

      if (pageViewCampaign) {
        data.pageViewConfig = {
          create: {
            appId: pageViewAppId,
            locationId: pageViewLocationId,
          },
        };
      } else if (!pageViewCampaign && pageViewAppId) {
        //Disabled Page View on the toggle
        data.pageViewConfig = {
          upsert: {
            create: {
              appId: null,
              locationId: null,
            },
            update: {
              appId: null,
              locationId: null,
            },
          },
        };
      }

      if (cart && transactionTarget) {
        data.transactionsConfig = {
          create: {
            appId: transactionTarget,
            cart: cart.toUpperCase(),
            traffId,
            gtmAccess,
            gtmStatus,
            tracksBasketItems,
            tag: {
              generatedTag,
              segmentId,
              segmentName,
              name: tagName,
            },
          },
        };
      }

      const response = await createCampaignOrder({
        variables: {
          data,
        },
      });

      const campaignOrderResult = response.data.createCampaignOrder;

      const searchParams = new URLSearchParams(this.props.location.search);
      this.props.history.push(
        `${this.props.location.pathname}/${
          campaignOrderResult.id
        }?${searchParams.toString()}`
      );

      setIsCloningClicked(false);
      this.setState({ onSubmitLoad: false });

      createCampaignHistory({
        variables: {
          action: "CREATED",
          property: "BASICS",
          data: {
            property: "campaignOrder",
            name: campaignOrderResult.name,
          },
          authorId: currentUser.id,
          campaignOrderId: campaignOrderResult.id,
        },
      });

      return campaignOrderResult;
    } catch (error) {
      setIsCloningClicked(false);
    }
  };

  handleOrderSubmit = async () => {
    this.setState({ onSubmitLoad: true });
    const {
      updateCampaignOrder,
      campaignOrder,
      currentUser,
      campaignOrderSlackNotification,
    } = this.props;
    const {
      campaignOrderId,
      language,
      brands,
      age,
      income,
      devices,
      objectiveNewCustomers,
      objectiveExistingCustomers,
      retargetDayCap,
      retargetTotalCap,
      type,
    } = this.state;

    const status =
      campaignOrder.status === "DRAFT" ? "PENDING" : campaignOrder.status;
    let data = {
      language: language.map(l => l.toUpperCase()),
      brands,
      age,
      income,
      devices,
      objectiveNewCustomers,
      objectiveExistingCustomers,
      retargetDayCap,
      retargetTotalCap,
      status,
    };

    // Check if type is external
    if (type === 7) {
      data = {
        ...data,
        status: "APPROVED",
        campaignReviewProcess: {
          stateLaw: "approved",
          scaleBudget: "approved",
        },
        reviewerId: currentUser.id,
      };
    }

    if (["LIVE", "LIVE_APPROVED"].includes(campaignOrder.status)) {
      data = {
        ...data,
        status: "LIVE_PENDING",
        campaignReviewProcess: {
          upsert: {
            create: { stateLaw: null, scaleBudget: null },
            update: { stateLaw: null, scaleBudget: null },
          },
        },
        reviewerId: currentUser.id,
      };
    }

    try {
      const result = await updateCampaignOrder({
        variables: {
          where: {
            id: campaignOrderId,
          },
          data,
        },
      });

      if (result && status === "PENDING") {
        this.setState({
          campaignOrderId: null,
          status,
          showModal: false,
          campaignName: "",
          description: "",
          brands: [],
          category: "",
          startDate: null,
          endDate: null,
          age: [],
          language: [],
          income: [],
          targetAudiences: [],
          devices: [],
          tags: [],
          objectiveNewCustomers: null,
          objectiveExistingCustomers: null,
          retargetDayCap: null,
          retargetTotalCap: null,
          kpi: "CPM",
          kpiGoal: null,
          budget: null,
          geoTargets: [],
          selectedGeoTargets: [],
          isRegulated: true,
          adUnits: [
            { banners: [], clickthrough: null, name: null, selectedRows: [] },
          ],
          advertiser: { id: null, name: null },
          type: 1,
          cpm: 7,
          awarenessCampaign: false,
          signUpCampaign: false,
          appId: "",
          targetingCriteria: null,
          requestorId: "",
          region: [],
        });
      }

      // TODO: Slack notif and create campaign history must be moved consolidated on the backend
      if (["PENDING", "LIVE_PENDING", "LIVE_APPROVED"].includes(status)) {
        campaignOrderSlackNotification({
          variables: {
            id: campaignOrderId,
            userId: currentUser.id,
          },
        });
      }

      this.onStepChange(6, false);
      this.setState({ onSubmitLoad: false });
      return result.data.updateCampaignOrder;
    } catch (error) {
      this.setState({ onSubmitLoad: false });
      return null;
    }
  };

  getUpdateBlock = (item, cpm) => {
    if (item["__typename"] === "Creative") {
      return {
        campaigns: {
          update: {
            where: { id: item.campaignId },
            data: {
              lineItems: {
                update: {
                  where: { id: item.parentId },
                  data: {
                    adUnits: {
                      update: {
                        where: { id: item.adUnitId },
                        data: {
                          banners: {
                            update: {
                              where: { id: item.key.split("-")[0] },
                              data: {
                                cpm: cpm,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    } else if (item["__typename"] === "LineItem") {
      return {
        campaigns: {
          update: {
            where: { id: item.parentId },
            data: {
              lineItems: {
                update: {
                  where: { id: item.id },
                  data: { cpm: cpm },
                },
              },
            },
          },
        },
      };
    } else if (item["__typename"] === "Campaign") {
      return {
        campaigns: {
          update: {
            where: { id: item.id },
            data: { cpm: cpm },
          },
        },
      };
    } else {
      return { cpm: cpm };
    }
  };

  insertionOrdersUpdate = () => {
    return {};
  };

  handleCpmBlur = (item, val) => {
    let cpm = parseFloat(val.replace(/\$\s?|(,*)/g, "")) || null;
    if (isEmpty(item.id)) {
      this.setState({ cpm });
    } else
      this.props.updateCampaignOrderWithCPM({
        variables: {
          campaignOrderID: this.state.campaignOrderId,
          data: this.getUpdateBlock(item, cpm),
        },
      });
  };

  onCreateLineItemCampaign = () => {
    const {
      campaignName,
      startDate,
      endDate,
      cpm,
      budget,
      selectedCampaignOrderListId,
    } = this.state;

    this.setState({
      onCreateLineItemCampaignLoading: true,
    });

    this.props
      .createLineItemCampaign({
        variables: {
          name: campaignName,
          adProviderId: null, // TO DO NOTE: THAT THIS NEED TO CHANGE ESPECIALLY WHEN LAUNCHING CAMPAIGN ALSO NEEDED DSP WHEN adProvider is Assigned
          clientOrg: null, // TO DO NOTE: THAT THIS NEED TO CHANGE ESPECIALLY WHEN LAUNCHING CAMPAIGN ALSO NEEDED DSP WHEN adProvider is Assigned
          orgId: this.props.orgID,
          campaignOrderId: !isEmpty(selectedCampaignOrderListId)
            ? selectedCampaignOrderListId
            : this.props.urlCampaignOrderID,
          startDate: startDate,
          endDate: endDate,
          cpm: parseFloat(cpm),
          budgetTotal: parseFloat(budget),
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_CAMPAIGN,
            variables: {
              id: !isEmpty(selectedCampaignOrderListId)
                ? selectedCampaignOrderListId
                : this.props.urlCampaignOrderID,
              orgID: this.props.orgID,
            },
          },
        ],
      })
      .then(val => {
        this.setState({
          onCreateLineItemCampaignLoading: false,
        });
        notification.open({
          message: "Successfully Created Line item!",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          duration: 15,
        });
      });
  };

  onUpdateCampaignOrderLineItem = (
    name,
    demandSidePlatform,
    clientOrg,
    budgetTotal,
    budgetByDay,
    startDate,
    endDate,
    originalStartDate,
    originalEndDate,
    phoneNumber,
    forwardingNumber,
    cpm,
    rawCost
  ) => {
    this.props
      .updateCampaignOrderLineItem({
        variables: {
          campaignOrderId: this.props.urlCampaignOrderID,
          name,
          demandSidePlatform,
          clientOrg,
          budgetTotal,
          budgetByDay,
          startDate,
          endDate,
          originalStartDate,
          originalEndDate,
          orgId: this.props.orgID,
          phoneNumber,
          forwardingNumber,
          cpm,
          rawCost,
        },
      })
      .then(val => {
        notification.open({
          message:
            "Sucessfully Synced the Selected Line Item to the Campaign Order!",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          duration: 15,
        });
      });
  };

  // TODO: Move this logic to handleSubmitCreatives
  bulkCreateCreatives = async ({ medias, invalidMedias = [], adUnitIndex }) => {
    try {
      message.loading("Uploading Creatives...", 0);

      const {
        createManyCampaignCreatives,
        createManyAdUnits,
        createCampaignHistory,
        currentUser,
      } = this.props;
      const { status, campaignOrderId } = this.state;
      let newAdUnit = null;
      let currentAdUnit = this.state.adUnits[adUnitIndex];

      if (!currentAdUnit.id) {
        newAdUnit = await createManyAdUnits({
          variables: {
            id: campaignOrderId,
            data: {
              adUnits: {
                create: [
                  {
                    name: currentAdUnit.name || "",
                    clickthrough: currentAdUnit.clickthrough || "",
                  },
                ],
              },
            },
          },
        });

        if (newAdUnit && newAdUnit.data) {
          const adUnits = newAdUnit.data.updateCampaignOrder.adUnits;
          this.setState({
            adUnits,
          });
          currentAdUnit = adUnits[adUnitIndex];
        }
      }

      const chunkSize = 10;
      const totalChunks = Math.ceil(medias.length / chunkSize);

      for (let i = 0; i < totalChunks; i++) {
        const startIndex = i * chunkSize;
        const endIndex = startIndex + chunkSize;
        const chunkMedias = medias.slice(startIndex, endIndex);

        const data = {
          banners: {
            create: chunkMedias.map(media => ({
              media: {
                create: media,
              },
              status: ["LIVE", "LIVE_PENDING", "LIVE_APPROVED"].includes(status)
                ? "PENDING_CREATE"
                : "CREATED",
            })),
          },
        };

        const result = await createManyCampaignCreatives({
          variables: {
            id: currentAdUnit.id,
            data,
          },
        });

        if (result && result.data) {
          let action = null;

          switch (status) {
            case "LIVE":
            case "LIVE_PENDING":
            case "LIVE_APPROVED":
              action = "PENDING_CREATE";

              break;

            default:
              action = "CREATED";
              break;
          }

          const changeHistories = [];

          data.banners.create.forEach(banner => {
            changeHistories.push({
              action,
              property: "CREATIVE",
              data: {
                property: "media",
                name: banner.media.create.name,
              },
              authorId: currentUser.id,
              campaignOrderId,
            });
          });

          changeHistories.forEach(variables => {
            createCampaignHistory({
              variables,
            });
          });

          this.setState({
            adUnits: this.state.adUnits.map((adUnit, index) => {
              if (index === adUnitIndex) {
                return {
                  ...adUnit,
                  banners: [...result.data.updateAdUnit.banners],
                };
              } else {
                return adUnit;
              }
            }),
          });
        } else {
          throw new Error("Failed to upload creatives");
        }
      }

      message.destroy();
      if (invalidMedias.length) {
        message.error(`Failed to upload ${invalidMedias.length} creative(s).`);
      }
      message.success(`${medias.length} Creative(s) successfully uploaded`);
    } catch (error) {
      message.destroy();
      message.error(error.message);
    }
  };

  handleGetBasicTagsActivities = async () => {
    const { campaignOrderTagsActivities, campaignOrder, orgID } = this.props;
    try {
      campaignOrderTagsActivities({
        variables: {
          id: campaignOrder.id,
          orgId: orgID,
        },
      });
    } catch (error) {
      throw error;
    }
  };

  handleUpdateStatusCreatives = async ({ creatives, status }) => {
    const { updateManyCreatives, createCampaignHistory } = this.props;
    const ids = creatives.map(creative => creative.id);
    try {
      const results = await updateManyCreatives({
        variables: {
          where: {
            id_in: ids,
          },
          data: {
            status,
          },
        },
      });

      // Update adUnits state with the updated status on if the adUnits is in the selectedRows
      const updatedAdUnits = this.state.adUnits.map(adUnit => {
        const banners = adUnit.banners.map(banner => {
          if (ids.includes(banner.id)) {
            return {
              ...banner,
              status,
            };
          }

          return banner;
        });

        return {
          ...adUnit,
          banners,
        };
      });

      // TODO: This should be moved to the backend, create a db trigger to handle this
      creatives.forEach(creative => {
        createCampaignHistory({
          variables: {
            action: "PENDING_UPDATE",
            property: "CREATIVE",
            data: {
              property: "status",
              name: creative.name,
              newValue: status,
            },
            authorId: this.props.currentUser.id,
            campaignOrderId: this.state.campaignOrderId,
          },
        });
      });

      this.setState({ adUnits: updatedAdUnits });
      return results;
    } catch (error) {
      return error;
    }
  };

  removeCreativeById = async ({ creativeId, adUnitId }) => {
    const { deleteCreative } = this.props;

    try {
      const result = await deleteCreative({
        variables: {
          id: creativeId,
        },
      });

      if (result && result.data) {
        this.setState({
          adUnits: this.state.adUnits.map(adUnit => {
            if (adUnit.id === adUnitId) {
              return {
                ...adUnit,
                banners: adUnit.banners.filter(
                  banner => banner.id !== creativeId
                ),
              };
            }

            return adUnit;
          }),
        });
      }

      return result;
    } catch (error) {
      return error;
    }
  };

  render() {
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, {
          addGeoTarget: this.addGeoTarget,
          onCheckBoxSelect: this.onCheckBoxSelect,
          handleChangeRadius: this.handleChangeRadius,
          removeGeoTargets: this.removeGeoTargets,
          onChange: this.onChange,
          onChangeArrayOfObj: this.onChangeArrayOfObj,
          onSlider: this.onSlider,
          handleCpmBlur: this.handleCpmBlur,
          addMedia: this.addMedia,
          onStepChange: this.onStepChange,
          handleUpdateCampaignOrder: this.handleUpdateCampaignOrder,
          addAdUnit: this.addAdUnit,
          removeBanners: this.removeBanners,
          removeAdUnit: this.removeAdUnit,
          resetAdUnit: this.resetAdUnit,
          handleOrderSubmit: this.handleOrderSubmit,
          changeValue: this.changeValue,
          onCampaignOrderListChange: this.onCampaignOrderListChange,
          onCreateLineItemCampaign: this.onCreateLineItemCampaign,
          onUpdateCampaignOrderLineItem: this.onUpdateCampaignOrderLineItem,
          removeBannerMultiple: this.removeBannerMultiple,
          bulkCreateCreatives: this.bulkCreateCreatives,
          handleSubmitTargeting: this.handleSubmitTargeting,
          handleCloneCampaignOrder: this.handleCloneCampaignOrder,
          handleGetBasicTagsActivities: this.handleGetBasicTagsActivities,
          handleUpdateStatusCreatives: this.handleUpdateStatusCreatives,
          removeCreativeById: this.removeCreativeById,
          ...this.state,
          ...this.props,
        })}
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper(googleMapsConfig)(withRouter(Controller));
