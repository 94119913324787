import gql from "graphql-tag";

const GET_SIGNUP_PAGINATION = gql`
  query getSignupPagination(
    $campaignOrderId: String!
    $reportName: String!
    $index: Int!
    $limit: Int!
    $startDateTimestamp: Int
    $endDateTimestamp: Int
    $attributionWindow: Int
  ) {
    getSignupPagination(
      campaignOrderId: $campaignOrderId
      reportName: $reportName
      index: $index
      limit: $limit
      startDateTimestamp: $startDateTimestamp
      endDateTimestamp: $endDateTimestamp
      attributionWindow: $attributionWindow
    )
  }
`;

export default GET_SIGNUP_PAGINATION;
