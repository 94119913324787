import React from "react";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import { Card, Row, Select, Spin, Table } from "antd";

const TopPageViewTable = ({
  loading = false, //Loading state
  title = "Top 10 Pages", //Title of the table
  topData = [], //Data to be display for top 10 page view pages
  setSelectedTop, //Function to set the selected top
  selectedTop = "top10", //Selected top
}) => {
  const columns = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Page"} />,
      index: "value",
      key: "value",
      render: (text, { value }) => {
        return <PrimaryTableRowText text={value} />;
      },
    },
    {
      title: <TableColumnTitle text={"Transactions"} />,
      index: "counts",
      key: "counts",
      render: (text, { counts }) => {
        return <PrimaryTableRowText text={counts} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <Card
        title={title}
        extra={
          <Select
            style={{ width: "100%" }}
            defaultValue={"top5"}
            options={[
              { label: "Top 5", value: "top5" },
              { label: "Top 10", value: "top10" },
              { label: "Top 20", value: "top20" },
              { label: "All", value: "all" },
            ]}
            onChange={value => {
              setSelectedTop(value);
            }}
          />
        }
      >
        <Spin spinning={loading}>
          <Table
            scroll={{ x: 800, y: 400 }}
            dataSource={topData}
            rowKey={record => record.value}
            pagination={
              selectedTop !== "top10" && selectedTop !== "top5"
                ? {
                    position: ["none", "bottomCenter"],
                    total: topData.length,
                    responsive: true,
                    showSizeChanger: false,
                    showLessItems: true,
                    size: "default",
                  }
                : false
            }
            columns={columns}
          />
        </Spin>
      </Card>
    </React.Fragment>
  );
};

export default TopPageViewTable;
