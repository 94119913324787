import React from "react";
import SummaryTab from "./SummaryTab";
import PaidSearchAggregatedLoader from "../../../../../../core/components/paidSearch/paidsearchdetails/AggregatedTab/PaidSearchAggregatedLoader";

const AggregatedDetailsView = props => {
  return (
    <PaidSearchAggregatedLoader {...props}>
      <SummaryTab />
    </PaidSearchAggregatedLoader>
  );
};

export default AggregatedDetailsView;
