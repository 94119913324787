import gql from "graphql-tag";

const DELETE_ORG = gql`
  mutation deleteOrg($Id: ID!) {
    deleteOrg(where: { id: $Id }) {
      id
    }
  }
`;

export default DELETE_ORG;
