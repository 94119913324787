import React, { useState } from "react";
import { Tooltip, Row, Col } from "antd";
import PaginationTable from "../../../../../shared/PaginationTable";

// Global Styling
import {
  PrimaryTableRowText,
  TooltipText,
} from "../../../../../shared/globalStyling/styledText";
import { formatWholeNumber } from "../../../../../../core/utils/campaigns";
import uuid from "uuid";

const TargetBreakDownTable = ({ chartData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
              }}
            />
          </Row>
        );
      },
    },
    {
      dataIndex: "value",
      key: "value",
      width: "250px",
      align: "left",
      footerContent: "Totals",
      render: (text, record) => {
        if (text) {
          return (
            <Row
              type="flex"
              justify="start"
              align="middle"
              style={{
                width: "100%",
              }}
            >
              <Col span={24}>
                {text.length > 45 ? (
                  <Tooltip title={<TooltipText text={text} />}>
                    <span>
                      <PrimaryTableRowText
                        text={text.slice(0, 46).concat("...")}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <PrimaryTableRowText text={text} />
                )}
              </Col>
            </Row>
          );
        } else {
          return <div />;
        }
      },
    },
    {
      dataIndex: "IDENTIFIERS",
      footerSum: true,
      key: "identifiers",
      width: "130px",
      align: "left",
      render: identifiers => (
        <PrimaryTableRowText
          text={identifiers && formatWholeNumber(identifiers)}
        />
      ),
    },
  ];

  return (
    <PaginationTable
      showHeader={false}
      bordered={false}
      columns={columns}
      data={chartData}
      scroll={{ x: 200, y: 400 }}
      paginationSize={"default"}
      size={"default"}
      total={chartData.length}
      current={currentPage}
      pageSize={10}
      onChange={(paginationObject, filtersObject, sorterObject) => {
        setCurrentPage(paginationObject.current);
      }}
      rowKey={record => `${record.id}${uuid.v4()}`}
    />
  );
};

export default TargetBreakDownTable;
