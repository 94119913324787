import { Button, Card, Result } from "antd";
import React from "react";

//Simple Card to display Error Page
const ErrorPage = ({
  errorCode = "500",
  errorMessage = "Sorry, something went wrong.",
}) => {
  return (
    <Card>
      <Result
        status={errorCode}
        title={errorCode}
        subTitle={errorMessage}
        extra={
          <Button onClick={() => window.location.reload()} type="primary">
            Reload
          </Button>
        }
      />
    </Card>
  );
};

export default ErrorPage;
