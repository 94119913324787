import React from "react";
import { Modal } from "antd";
//local
import ModalForm from "./ModalForm";
import AddSharingRecordModalForm from "./AddSharingRecordModalForm";

const EditAudienceModal = ({
  onChange,
  visible,
  modal,
  onAddSharingRecordOrSegmentOk,
  onEditSegmentOk,
  recordData,
  audienceName,
  onCreateSegmentClick,
  priceCategories,
  onMapSegmentToPriceOk,
}) => {
  return (
    <Modal
      centered
      width={750}
      bodyStyle={{ padding: 0 }}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => {
        onChange("modalVisible", false);
      }}
      onOk={
        modal === "editSegment"
          ? () => {
              onChange("modalVisible", false);
              onEditSegmentOk();
            }
          : modal === "createSharing"
          ? () => {
              onChange("modalVisible", false);
              onAddSharingRecordOrSegmentOk("create");
            }
          : modal === "createSegment"
          ? () => {
              onChange("modalVisible", false);
              onCreateSegmentClick();
            }
          : modal === "mapPrice"
          ? () => {
              onChange("modalVisible", false);
              onMapSegmentToPriceOk();
            }
          : () => onChange("modalVisible", false)
      }
      title={
        modal === "editSegment"
          ? "Edit Segment"
          : modal === "createSharing"
          ? "Create A sharing record or add a segment to an existing one"
          : modal === "createSegment"
          ? "Create Segment"
          : modal === "mapPrice"
          ? "Map segment to a pricing category"
          : ""
      }
    >
      {modal === "editSegment" ||
      modal === "createSegment" ||
      modal === "mapPrice" ? (
        <ModalForm
          onChange={onChange}
          recordData={recordData}
          audienceName={audienceName}
          priceCategories={priceCategories}
          modal={modal}
        />
      ) : (
        <AddSharingRecordModalForm
          onChange={onChange}
          onOk={onAddSharingRecordOrSegmentOk}
        />
      )}
    </Modal>
  );
};

export default EditAudienceModal;
